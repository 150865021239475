import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {SessionInterface} from '../../_interfaces/Session';
import {DeleteAdminSessions, DeleteUserSessions, GetAdminSessions, GetUserSessions, InitUserSessionsRows} from '../actions/sessions.actions';
import {ApiResponse} from '../../../app-shared-elements/_interfaces/ApiRequest';
import {HTTP_STATUS} from '../../../app-shared-elements/_enums/status.enum';
import {HttpClient} from '@angular/common/http';
import {TooltipStatusEnum} from '../../../app-shared-elements/_enums/tooltip-status.enum';
import {NotificationsService} from '../../../app-shared-elements/_services/notifications.service';

export interface SessionsStateModel {
    sessions: SessionInterface[];
    // adminSessions: SessionInterface[];
    sessionsRows: SessionInterface[];
    adminSessionsRows: SessionInterface[];
}

const SESSIONS_STATE_TOKEN = new StateToken<SessionsStateModel>('sessions');

@State<SessionsStateModel>({
    name: SESSIONS_STATE_TOKEN,
    defaults: {
        sessions: [],
        // adminSessions: [],
        sessionsRows: [],
        adminSessionsRows: []
    }
})
@Injectable()
export class SessionState {
    constructor(
        private http: HttpClient,
        private notificationsService: NotificationsService
    ) {
    }

    @Selector()
    static getSessions(state: SessionsStateModel): SessionInterface[] {
        return state.sessions;
    }

    @Selector()
    static getUserSessionsRows(state: SessionsStateModel): SessionInterface[] {
        return state.sessionsRows;
    }

    @Selector()
    static getAdminSessionsRows(state: SessionsStateModel): SessionInterface[] {
        return state.adminSessionsRows;
    }

    @Action(GetUserSessions)
    async getUserSessions(ctx: StateContext<SessionsStateModel>, payload: GetUserSessions): Promise<void> {
        const state = ctx.getState();
        let result: ApiResponse;
        if (payload.userId) {
            result = await this.http.get('/api/control/users/sessions', {params: {userId: payload.userId}}).toPromise() as ApiResponse;
        } else {
            result = await this.http.get('api/users/sessions').toPromise() as ApiResponse;

        }

        if (result && result.status === HTTP_STATUS.SUCCESS) {

            ctx.setState({
                ...state,
                sessions: result.data,
                sessionsRows: (result.data as SessionInterface[]).map(session => {
                    return {
                        created: session.created,
                        id: session.id,
                        ip: session.ip,
                        userAgent: session.userAgent,
                        isOnline: session.isOnline
                    };
                }).sort((a, b) => b.created - a.created)
            });
        } else {
            ctx.setState({
                ...state,
                sessions: []
            });
        }

        // ctx.dispatch(new InitUserSessionsRows());
    }

    @Action(GetAdminSessions)
    async getAdminSessions(ctx: StateContext<SessionsStateModel>): Promise<void> {

        const result: ApiResponse = await this.http.get('/api/control/admins/sessions').toPromise().catch(e => console.log(e)) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                sessions: result.data,
                sessionsRows: (result.data as SessionInterface[]).map(session => {
                    return {
                        created: session.created,
                        id: session.id,
                        ip: session.ip,
                        userAgent: session.userAgent,
                        isOnline: session.isOnline
                    };
                }).sort((a, b) => b.created - a.created)
            });
        } else {
            ctx.setState({
                ...state,
                sessions: []
            });
        }

        // ctx.dispatch(new InitUserSessionsRows());
    }

    @Action(InitUserSessionsRows)
    async initUserSessionsRows(ctx: StateContext<SessionsStateModel>): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            sessionsRows: state.sessions.map(session => {
                return {
                    created: session.created,
                    id: session.id,
                    ip: session.ip,
                    userAgent: session.userAgent,
                    isOnline: session.isOnline
                };
            }).sort((a, b) => b.created - a.created)
        });
    }


    @Action(DeleteUserSessions)
    async deleteUserSessions(ctx: StateContext<SessionsStateModel>, action: DeleteUserSessions): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = await this.http.post('/api/users/delete-session', {...action.payload}).toPromise().catch(e => e);
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                sessionsRows: state.sessionsRows.filter(row => row.id !== action.payload.ids[0])
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(DeleteAdminSessions)
    async deleteAdminSessions(ctx: StateContext<SessionsStateModel>, payload: DeleteAdminSessions): Promise<void> {
        const state = ctx.getState();

        const deleteSessionDto = {
            ids: payload.ids,
            adminId: payload.adminId
        };

        const result: ApiResponse = await this.http.post('/api/control/admins/delete-session', {...deleteSessionDto}).toPromise().catch(e => e);
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                adminSessionsRows: state.adminSessionsRows.filter(row => row.id !== payload.ids[0])
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserLogsComponent} from './user-logs/user-logs.component';
import {DeviceLogsComponent} from './device-logs/device-logs.component';
import {AppSharedElementsModule} from '../app-shared-elements/app-shared-elements.module';
import {JournalsRoutingModule} from './journals-routing.module';
import {EventsLogComponent} from './event-logs/events-log.component';
import {MailingLogComponent} from './mailing-logs/mailing-log.component';
import {ReportsLogsComponent} from './report-logs/reports-logs.component';


@NgModule({
    declarations: [
        UserLogsComponent,
        DeviceLogsComponent,
        EventsLogComponent,
        MailingLogComponent,
        ReportsLogsComponent
    ],
    imports: [
        CommonModule,
        AppSharedElementsModule,
        JournalsRoutingModule
    ],
    exports: [
        UserLogsComponent,
        DeviceLogsComponent,
        EventsLogComponent,
        MailingLogComponent,
        ReportsLogsComponent
    ]
})
export class JournalsSharedModule {
}

export enum DrawingToolsIconsEnum {
    image = './assets/design/icons/mnemonic/image.svg',
    value = './assets/design/icons/mnemonic/value.svg',
    text = './assets/design/icons/mnemonic/text.svg',
    chart = './assets/design/icons/mnemonic/chart.svg',
    singleArrow = './assets/design/icons/mnemonic/single-arrow.svg',
    doubleArrow = './assets/design/icons/mnemonic/double-arrow.svg',
    rectangle = './assets/design/icons/mnemonic/rectangle.svg',
    ellipse = './assets/design/icons/mnemonic/ellipse.svg',
    defaultImage = './assets/design/icons/mnemonic/default-img.svg',
    temperature = './assets/design/icons/mnemonic/temperature.svg',
    humidity = './assets/design/icons/mnemonic/humidity.svg',
    dbm = './assets/design/icons/mnemonic/dbm.svg',

    line = './assets/design/icons/mnemonic/line.svg',
}

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ConfigurationState } from '../_store/states/configuration.state';
import { LanguageState } from '../_store/states/language.state';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    constructor(private store: Store) {}

    getCurrentMessage(errorStatus: number): string {
        const errorDescribes = this.store.selectSnapshot(ConfigurationState.getDescribeConfigurationErrors);
        const currentLang = this.store.selectSnapshot(LanguageState.getLanguage);
        if (!errorDescribes[errorStatus]) {
            return '';
        }
        return errorDescribes ? errorDescribes[errorStatus][currentLang] : '';
    }
}

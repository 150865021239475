<div *ngIf="currentInvoice$ |  async" class="invoice-print">

    <div class="invoice-print__wrapper">


        <div class="invoice-print__header">

            <div class="invoice-print__header-invoice">

                <h1 [translate]="'billing.invoice.print.title'"></h1>

                <h2 [translate]="'billing.invoice.print.invoice'"
                    [translateParams]="{number: (currentInvoice$ | async)?.invoiceNumber, date: (currentInvoice$ | async)?.dateInvoice}"></h2>

            </div>

            <div class="invoice-print__header-logo">

                <svg-icon [src]="INVOICE_LOGO_PATH"></svg-icon>


            </div>

        </div>


        <div *ngIf="(currentInvoice$ | async)?.payerDescription" class="invoice-print__items">

            <div class="invoice-print__item">

                <h3 [translate]="'billing.invoice.print.provider'"></h3>

                <ng-container *ngFor="let item of invoiceInfoArray">

                    <div class="invoice-print__info">

                        <b [translate]="item.name"></b>

                        <div>{{(currentInvoice$ | async)?.providerDescription[item.value]}}</div>

                    </div>

                </ng-container>


            </div>


            <div class="invoice-print__item">

                <h3 [translate]="'billing.invoice.print.payer'"></h3>

                <ng-container *ngFor="let item of payerInvoiceArray">

                    <div class="invoice-print__info">

                        <b [translate]="item.name"></b>

                        <div>{{(currentInvoice$ | async)?.payerDescription[item.value]}}</div>

                    </div>

                </ng-container>


            </div>

        </div>

        <app-invoice-table [currentInvoice]="currentInvoice$ | async" [tableWidth]="'100%'"></app-invoice-table>

        <div class="invoice-print__signature">

            <div class="invoice-print__signature-item">
                <b [translate]="'billing.invoice.print.written'"></b>
                <img *ngIf="(currentInvoice$ | async)?.providerDescription?.signature"
                     class="invoice-print__signature-img"
                     [src]="(currentInvoice$ | async)?.providerDescription?.signature | safeHtml: safePipeEnum.resourceUrl"
                     alt="img">
            </div>
            <div class="invoice-print__signature-item">
                <b [translate]="'billing.invoice.print.director'"></b>
                <div>{{(currentInvoice$ | async)?.providerDescription?.director}}</div>
            </div>

        </div>


    </div>

</div>

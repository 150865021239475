<ng-container *ngIf="isEdit; else show">
    <div (click)="change()"
         class="toggle-button"
         [class.toggle-button--disabled]="isDisabled"
         [class.toggle-button--wait]="isWait"
         [class.toggle-button--right]="isToggle"
    >
        <div class="toggle-button__dot"
             [class.toggle-button__dot--right]="isToggle"
        >
            <div [translate]="isToggle ? 'On' : 'Off'"></div>
        </div>
    </div>
</ng-container>

<ng-template #show>

    <div [class.toggle-button--show]="!isEdit && isToggle"
         [class.toggle-button__text--wait]="isWait && isToggle"
         [class.toggle-button__text--waits]="isWait && !isToggle"
         [translate]="isToggle ? 'On' : 'Off'"></div>

</ng-template>

<div  class="icon-select"
    appClickOutside
    [isActiveClickOutside]="isShowPopup"
    (clickOutside)="isShowPopup = false">
    <div (click)="isShowPopup = !isShowPopup" class="icon-select__wrapper">
        <div class="icon-select__value">
            <div [ngSwitch]="value.value.iconType" class="icon-select__value-icon">

                <ng-template [ngSwitchCase]="notificationTransportType.VIBER">
                    <svg-icon [src]="VIBER_ICON_PATH"></svg-icon>

                </ng-template>

                <ng-template [ngSwitchCase]="notificationTransportType.TELEGRAM">
                    <svg-icon [src]="TELEGRAM_ICON_PATH"></svg-icon>
                </ng-template>


                <ng-template [ngSwitchCase]="notificationTransportType.EMAIL">
                    <svg-icon [src]="EMAIL_ICON_PATH"></svg-icon>

                </ng-template>


            </div>

            <!-- <div class="icon-select__value-text">{{value.text}}</div> -->
        </div>
        <div class="icon-select__arrow"
             [class.icon-select__arrow--revert]="isShowPopup">
            <svg-icon [src]="ARROW_ICON_PATH"></svg-icon>

        </div>
    </div>
    <ul *ngIf="isShowPopup" class="icon-select__list"
        [class.icon-select__list--show]="isShowPopup">
        <ng-container *ngFor="let option of options">
            <li *ngIf="option.key !== currentOption?.key" (click)="selectOption(option); isShowPopup = false"
                class="icon-select__item">

                <div [ngSwitch]="option.value.iconType" class="icon-select__item-icon">

                    <ng-template [ngSwitchCase]="notificationTransportType.VIBER">
                        <svg-icon [src]="VIBER_ICON_PATH"></svg-icon>


                    </ng-template>

                    <ng-template [ngSwitchCase]="notificationTransportType.TELEGRAM">
                        <svg-icon [src]="TELEGRAM_ICON_PATH"></svg-icon>
                    </ng-template>

                    <ng-template [ngSwitchCase]="notificationTransportType.EMAIL">
                        <svg-icon [src]="EMAIL_ICON_PATH"></svg-icon>


                    </ng-template>


                </div>
                <span class="icon-select__item-text">{{option.value.text}}</span>
            </li>
        </ng-container>
    </ul>
</div>

<div class="radio-btn"
     (change)="change()"
     [class.radio-btn--user]="isUserRadio"
     [class.radio-btn--active]="isActive"
     [class.radio-btn--disabled]="isDisabled">
    <div class="radio-btn__wrapper">
        <input type="radio"
               [id]="title"
               [checked]="value"
        >
        <label
            *ngIf="title"
            class="radio-btn__title"
            [class.radio-btn__title--wait]="isWait"
            [class.radio-btn__title--error]="isError"
            [for]="title"
            [translate]="title"
        ></label>
    </div>
</div>

import { NoteInfoInterface } from '../../_interfaces/note-info.interface';

export class AddNote {
    static readonly type = '[NoteNotification] AddNote';

    constructor(public data: NoteInfoInterface) {}
}

export class RemoveNote {
    static readonly type = '[NoteNotification] RemoveNote';

    constructor(public info?: NoteInfoInterface) {}
}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class RestrictionValidateService {
    constructor() {}

    public floatSignValidation(variableType: string, value: string) {
        if (!variableType.includes('float')) {
            return null;
        }

        const floatData = variableType.split('_');

        if (floatData.length <= 1) {
            return true;
        }

        const reg = new RegExp(`^[-+]?[0-9]+[.,]?([0-9]{1,${floatData[1]}})?$`);
        return reg.test(value);
    }

    public validateValue(inputValue: string, restriction: string) {
        const value = parseFloat(inputValue);

        if (isNaN(value)) {
            return false;
        }

        if (!restriction) {
            return true;
        }

        const validateRestriction = (restriction) => {
            const constraintType = this.getRestrictionType(restriction);
            switch (constraintType) {
                case 'range':
                    return this.validateRange(value, restriction);
                case 'list':
                    return this.validateList(value, restriction);
                default:
                    return false;
            }
        };

        return restriction.split(';').some(validateRestriction);
    }

    private getRestrictionType(value: string): 'range' | 'list' {
        if (value.includes(':')) {
            return 'range';
        }

        if (value.includes('|')) {
            return 'list';
        }

        return 'list';
    }

    private validateRange(value: number, restrictionValue: string) {
        const [min, max] = restrictionValue.split(':').map(parseFloat);

        return value >= min && value <= max;
    }

    private validateList(value: number, restrictionValue: string) {
        const valuesList = restrictionValue.split('|');

        return valuesList.some((val) => parseFloat(val) === value);
    }
}

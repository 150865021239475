import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppTemplateComponent} from './app-template.component';
import {MainComponent} from './main/main.component';
import {NavComponent} from './nav/nav.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {FooterComponent} from './footer/footer.component';
import {ToggleBarService} from './_services/toggle-bar.service';
import {AppSharedElementsModule} from '../app-shared-elements/app-shared-elements.module';
import {TranslateModule} from '@ngx-translate/core';
import {AppRoutingModule} from './app-routing.module';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MobileComponent} from './mobile/mobile.component';
import {MobileFooterComponent} from './mobile/mobile-footer/mobile-footer.component';
import {MobileNavComponent} from './mobile/mobile-nav/mobile-nav.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {ProfileMenuComponent} from './nav/profile-menu/profile-menu.component';


@NgModule({
    declarations: [
        AppTemplateComponent,
        MainComponent,
        NavComponent,
        SidebarComponent,
        FooterComponent,
        MobileComponent,
        MobileFooterComponent,
        MobileNavComponent,
        NotFoundComponent,
        ProfileMenuComponent,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppSharedElementsModule,
        TranslateModule,
        ClipboardModule
    ],
    providers: [ToggleBarService],
    exports: [
        AppTemplateComponent,
        TranslateModule
    ]
})
export class AppTemplateModule {
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { ChartState } from 'src/app/groups/_store/states/chart.state';
import { ArchivePreloaderData } from '../_interfaces/archive-preloader-data.interface';

@Component({
    selector: 'app-archive-perloader',
    templateUrl: './archive-perloader.component.html',
    styleUrls: ['./archive-perloader.component.scss']
})
export class ArchivePerloaderComponent implements OnInit, OnDestroy {

    @Select(ChartState.getArchivePreloaderData) data$: Observable<ArchivePreloaderData>;

    @Input() count;

    @Output() destroyEvent: EventEmitter<void> = new EventEmitter<void>();

    array: number[] = [];

    destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
    ) {
        this.data$.pipe(takeUntil(this.destroy), skip(1)).subscribe((data: ArchivePreloaderData) => {
            if (!data || !data.currentResponse) {
                // this.array = [];
                // this.cancelPreloader();
                return;
            }
            this.array.push(data.currentResponse);
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();

        this.array = [];
    }

    cancelPreloader(): void {
        this.destroyEvent.next();
    }
}

import { DeviceTypeEnum } from 'src/app/app-shared-elements/_enums/device-type.enum';
import { CreationType, RegistratorSyncStatus } from 'src/app/app-shared-elements/_enums/registrator-sync-status.enu';
import { CheckboxPropertiesInterface, IconInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { SelectOptionInterface } from 'src/app/app-shared-elements/_interfaces/select-option.interface';
import { UnitInterface } from 'src/app/app-shared-elements/_interfaces/unit.interface';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { AlarmTypeEnum } from 'src/app/events/_enums/alarm.enum';
import { Device } from '../../app-shared-elements/_interfaces/Device';
import { DetailsDeviceTabsEnum } from '../_enums/details-device-tabs.enum';

export interface DetailsDeviceInterface {
    registrator: DetailDeviceSensorInterface;
    sensors: DetailDeviceSensorInterface[];
    coordinators: DetailDeviceSensorInterface[];
    dataloggers: DetailDeviceSensorInterface[];
}

export interface DetailsDeviceRows {
    id: string;
    deviceId?: any;
    variableName: string;
    name: string;
    value: any;
    type: string;
    comment?: string;
    statusAlarm: boolean;
    statusAcknowledgeable: boolean;
    event?: string;
    deviceType?: CreationType;
    tooltipValue: any; // {'cellName': value}
    unit: string;
    unitId: string;
    variableId: string;
    unitOptions: SelectOptionInterface<string, string, { cellName: string; data: UnitInterface }>[];
    status: AlarmTypeEnum;
    styles: {
        variableName: any;
    };
    isDisable?: boolean;
    postIcons: IconInterface<DetailsDeviceIconActionEnum>[];
    rowTabs: DetailsDeviceTabsEnum;
    checkboxProperties: CheckboxPropertiesInterface;
    isSelectDisabled?: boolean;
    internalId: string;
    disableClick: boolean;
}

export interface DetailDeviceSensorInterface extends Device {
    id: string;
    internalId: string;
    name: string;
    defaultName: string;
    software: string;
    isActive: boolean;
    // devices: Device[];
    registratorId: string;
    login: string;
    variables: Variable[];
    type: DeviceTypeEnum;
    lastActive: string;
    userId: string;
    created: number;

    // Only client
    status: AlarmTypeEnum;
    periodArchive: number;
    isConnect: boolean;
    signal: any;
    battery: any;
    isSocketActive: boolean;
    timeReceipt: number;
    roleName?: string;
    roleId?: string;
    currentCoordinatorId: string;
    syncStatus?: RegistratorSyncStatus;
    creationType: CreationType;
    isAction: boolean;
    isFocusInputChangeTimeOfSensor?: boolean;
    isKioskMode?: boolean;
}

export enum DetailsDeviceIconActionEnum {
    info = 'info',
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../_services/auth.service';

/**
 * Сервис, реализующий интерфейс ангуляра CanActivate,
 * используется при определении защищенных роутов.
 */
@Injectable()
export class LoginGuardService {
    constructor(
        private authService: AuthService,
        private route: Router,
    ) {}

    async canActivate(): Promise<boolean> {
        if (await this.authService.isAuthenticated()) {
            await this.route.navigate(['']);
            return false;
        }

        return true;
    }
}

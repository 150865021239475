<div class="info">
    <div class="info__wrapper">

        <app-user-profile-info [type]="typeClient.Admin"
                               [gmt]="gmt$ | async"
                               [userInfo]="userInfo$ | async"
                               [userInfoSystem]="userInfoSystem$ | async"
                               [userInfoMailing]="userInfoMailing$ | async"
                               [isEditable]="(profileUser$ | async)?.id === (user$ | async)?.id"
                               [ln]="ln$ | async"></app-user-profile-info>

        <div class="info__table-heading" [translate]="'admin.userProfile.tableTitle'"></div>

        <app-tables *ngIf="(sessionsRows$ | async)"
                [isPagination]="false"
                [currentTableColumns]="userService.sessionTableColumns"
                [rows]="(sessionsRows$ | async)"
                [type]="tableTypeEnum.light"
                (actionEvent)="eventFromTable($event)"></app-tables>
    </div>
</div>

import { SelectOptionInterface } from '../../../../app-shared-elements/_interfaces/select-option.interface';
import { Device } from '../../../../app-shared-elements/_interfaces/Device';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DownloadRegistratorLog, GetAdminRegistratorLogs } from '../actions/admin-registrator-logs.actions';
import { ApiResponse } from '../../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../../app-shared-elements/_enums/status.enum';
import { ColumnsTableInterface, ColumnTypeEnum } from '../../../../app-shared-elements/_interfaces/ColumnsTable';
import { GetRegistratorsOptions } from '../../../admin-devices/_store/actions/admin-devices.actions';
import { DevicesState } from '../../../../device-dashboard/_store/states/user-devices.state';
import { ColumnsActionTypeEnum } from '../../../../app-shared-elements/_enums/columns-action-type.enum';
import { NotificationsService } from '../../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../../app-shared-elements/_enums/tooltip-status.enum';

export interface AdminRegistratorLogsStateModel {
    registratorLogs: string[];
    registratorsOptions: SelectOptionInterface<string, string, Device>[];
    columns: ColumnsTableInterface[];
}

const ADMIN_REGISTRATOR_LOGS_STATE = new StateToken<AdminRegistratorLogsStateModel>('registratorLogs');

const initColumnsTable: ColumnsTableInterface[] = [
    {
        title: 'registratorLogs.date',
        grow: false,
        small: true,
        maxWidth: '150px',
        minWidth: '150px',
        type: ColumnTypeEnum.text,
        name: 'date',
    },
    {
        title: 'events.logicalEvents.table.actions',
        grow: false,
        small: true,
        maxWidth: '150px',
        minWidth: '150px',
        type: ColumnTypeEnum.action,
        name: 'edit',
        actionBtns: [ColumnsActionTypeEnum.download],
    },
];

@State<AdminRegistratorLogsStateModel>({
    name: ADMIN_REGISTRATOR_LOGS_STATE,
    defaults: {
        registratorLogs: [],
        registratorsOptions: [],
        columns: initColumnsTable,
    },
})
@Injectable()
export class AdminRegistratorLogsState {
    constructor(private http: HttpClient, private store: Store, private notificationService: NotificationsService) {}

    @Selector()
    static getRegistratorLogs(state: AdminRegistratorLogsStateModel): string[] {
        return state.registratorLogs;
    }

    @Selector()
    static getRegistratorOptions(state: AdminRegistratorLogsStateModel): SelectOptionInterface<string, string, Device>[] {
        return state.registratorsOptions;
    }

    @Selector()
    static getColumns(state: AdminRegistratorLogsStateModel): ColumnsTableInterface[] {
        return state.columns;
    }

    @Action(GetAdminRegistratorLogs)
    async getAdminRegistratorLogs(ctx: StateContext<AdminRegistratorLogsStateModel>, payload: GetAdminRegistratorLogs): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/control/devices/list-logs', {
                params: {
                    registratorId: payload.registratorId,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.REGISTRATOR_IS_OFFLINE) {
            this.notificationService.onEmit(TooltipStatusEnum.error, false, 'events.logicalEvents.offlineRegistrator');

            ctx.setState({
                ...state,
                registratorLogs: [],
            });

            return;
        }

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                registratorLogs: result.data.map((item) => {
                    return {
                        date: item,
                    };
                }),
            });
        }
    }

    @Action(GetRegistratorsOptions)
    getRegistratorsOptions(ctx: StateContext<AdminRegistratorLogsStateModel>): void {
        const registrators: Device[] = this.store.selectSnapshot(DevicesState.getRegistrators);

        const allRegistrators = {
            key: null,
            value: 'detailsDevice.allRegistrators',
            type: 'text',
            property: null,
        };

        const state = ctx.getState();

        ctx.setState({
            ...state,
            registratorsOptions: [
                allRegistrators,
                ...registrators
                    .map((r) => {
                        return {
                            key: r.id,
                            value: r.name ?? r.defaultName,
                            type: 'text',
                            property: r,
                        };
                    })
                    .sort((a, b) => (a.value > b.value ? 1 : -1)),
            ],
        });
    }

    @Action(DownloadRegistratorLog)
    async downloadRegistratorLog(ctx: StateContext<AdminRegistratorLogsStateModel>, payload: DownloadRegistratorLog): Promise<void> {
        const result: any = await this.http
            .get('/api/control/devices/log', {
                responseType: 'blob',
                observe: 'response',
                params: {
                    registratorId: payload.registratorId,
                    filename: payload.filename,
                },
            })
            .toPromise()
            .catch((e) => console.log(e));

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.OK && !result.body?.status) {
            const dataStr = window.URL.createObjectURL(result.body);
            const a = document.createElement('a');
            a.href = dataStr;
            a.download = payload.filename + '.json';
            a.click();
            window.URL.revokeObjectURL(dataStr);
            a.remove();

            ctx.setState({
                ...state,
            });
        }
    }
}

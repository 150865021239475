import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MustMatch } from '../_helpers/must-math.validator';
import { ApiResponse } from '../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../app-shared-elements/_enums/status.enum';
import { ValidatePassword } from '../_helpers/strong-validator-password';
import { SocketService } from '../../app-shared-elements/_services/socket.service';
import { VariableValueTypeEnum } from '../../groups/_enums/variable-value-type.enum';
import { AuthSignUp, SetUserToken } from '../_store/actions/auth.actions';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '../_store/states/auth.state';
import { AuthSignUpInterface } from '../_interfaces/auth.interface';
import { Observable } from 'rxjs';
import { ConfigurationServerInterface } from 'src/app/app-shared-elements/_interfaces/configuration-server.interface';
import { ConfigurationState } from 'src/app/app-shared-elements/_store/states/configuration.state';
import { CONSTANT_KEY } from 'src/app/admin/system-settings/_enums/system-settings.enum';
import { DateTime } from 'luxon';
import { FormTypeEnum } from '../../app-shared-elements/_enums/form-type.enum';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
    @Select(ConfigurationState.getConfigurationServer) configurationServer$: Observable<ConfigurationServerInterface>;

    formArray: { name: FormTypeEnum; title: string; placeholder: string; isVisible: boolean; validateName: VariableValueTypeEnum }[] = [
        {
            name: FormTypeEnum.password,
            title: 'auth.registration.password',
            placeholder: 'auth.registration.passwordPlaceholder',
            isVisible: false,
            validateName: VariableValueTypeEnum.password,
        },
        {
            name: FormTypeEnum.repeat,
            title: 'auth.registration.repeat',
            placeholder: 'auth.registration.repeatPlaceholder',
            isVisible: false,
            validateName: VariableValueTypeEnum.password,
        },
    ];

    formTypeEnum = FormTypeEnum;

    success = false;

    modelPassword = '';
    modelEmail = '';
    private registerWindow: any;
    strength = -1;
    isVisiblePassword = false;
    alreadyExist = false;

    form: FormGroup;
    isEmptyForm = false;

    successMessage: string;
    successTitle: string;

    readonly GOOGLE_LOGIN_ICON_PATH = './assets/design/icons/auth/google.svg';

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private store: Store,
        private socketService: SocketService,
    ) {}

    ngOnInit(): void {
        this.form = this.formBuilder.group(
            {
                name: new FormControl(''),
                email: new FormControl('', [Validators.required, Validators.pattern(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,10})$/)]),
                phone: new FormControl('', Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)),
                password: new FormControl('', [Validators.required, ValidatePassword.bind(this)]),
                repeat: new FormControl('', Validators.required),
            },
            {
                validator: MustMatch('password', 'repeat'),
            },
        );
    }

    googleRegister(): void {
        this.registerWindow = window.open(`${window.location.origin}/api/auth/google`, '', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no');
        window.addEventListener('message', async (event) => {
            await this.onLogin(event.data);
        });
    }

    private async onLogin(user): Promise<void> {
        const token = JSON.parse(user).access_token;
        if (token) {
            await this.store.dispatch(new SetUserToken(token)).toPromise();
        }
        this.registerWindow.close();
        window.removeEventListener('message', this.onLogin);
        await this.socketService.socketAuthentication();
        await this.router.navigate(['/']);
    }

    async signUp(): Promise<void> {
        if (this.form.status === 'VALID') {
            const data: AuthSignUpInterface = {
                login: this.form.value.email,
                password: this.form.value.password,
                phone: this.form.value.phone,
                name: this.form.value.name,
                timeZone: DateTime.local().zoneName,
            };
            await this.store.dispatch(new AuthSignUp(data)).toPromise();
            const result: ApiResponse = this.store.selectSnapshot(AuthState.getAuthStatus);

            if (result.status === HTTP_STATUS.SUCCESS) {
                this.initSuccessMessage();
                this.success = true;
            }

            if (result.status === HTTP_STATUS.EMAIL_ALREADY_EXISTS) {
                this.alreadyExist = true;
            }
        }

        this.form.value.email === '' || this.form.value.password === '' || this.form.value.repeat === '' ? (this.isEmptyForm = true) : (this.isEmptyForm = false);

        return;
    }

    initSuccessMessage(): void {
        const configurationServer = this.store.selectSnapshot(ConfigurationState.getConfigurationServer);

        if (configurationServer[CONSTANT_KEY.REQUIRE_CONFIRM_BY_ADMIN] && configurationServer[CONSTANT_KEY.REQUIRE_CONFIRM_EMAIL]) {
            this.successTitle = 'auth.registration.successAdminTitle';
            this.successMessage = 'auth.registration.successAdminEmailMessage';
        } else if (configurationServer[CONSTANT_KEY.REQUIRE_CONFIRM_BY_ADMIN] && !configurationServer[CONSTANT_KEY.REQUIRE_CONFIRM_EMAIL]) {
            this.successTitle = 'auth.registration.successAdminTitle';
            this.successMessage = 'auth.registration.successAdminMessage';
        } else if (configurationServer[CONSTANT_KEY.REQUIRE_CONFIRM_EMAIL] && !configurationServer[CONSTANT_KEY.REQUIRE_CONFIRM_BY_ADMIN]) {
            this.successTitle = 'auth.registration.successfullTitle';
            this.successMessage = 'auth.registration.successfullMessage';
        } else {
            this.successTitle = 'auth.registration.successTitle';
            this.successMessage = 'auth.registration.successMessage';
        }
    }

    moveLogin(): void {
        this.router.navigate(['/login']);
    }

    lookChangePasswordInput(): void {
        if (!this.modelPassword.length) {
            this.strength = 0;
        }
    }
}

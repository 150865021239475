import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {ProfileState} from '../../../profile/_store/states/profile.state';
import {Observable} from 'rxjs';
import {User} from '../../../app-shared-elements/_interfaces/user.interface';
import {filter, first} from 'rxjs/operators';
import {SetUserIdForEventLogsFromAdmin} from '../../../journals/_store/actions/technologic-events.action';

@Component({
    selector: 'app-admin-profile-event-log',
    templateUrl: './admin-profile-event-log.component.html',
    styleUrls: ['./admin-profile-event-log.component.scss']
})
export class AdminProfileEventLogComponent implements OnInit, OnDestroy {

    @Select(ProfileState.getProfileUser) profileUser$: Observable<User>;

    constructor(private store: Store) {
        this.profileUser$.pipe(filter(user => !!user), first()).subscribe(user => {
            this.store.dispatch(new SetUserIdForEventLogsFromAdmin(user.id));
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.store.dispatch(new SetUserIdForEventLogsFromAdmin(null));
    }

}

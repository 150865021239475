export interface BillingTransactionsInterface {
    id: string;
    amountMoney: number | string;
    totalMoney: number | string;
    typeTransaction: TypeTransactionEnum;
    payload: InvoicePayloadTransactionInterface;
    description: string;
    created: Date;
    userId: string;
}

export interface BillingTransactionsRowInterface extends BillingTransactionsInterface {
    details: string;
    styles?: {
        amountMoney: any;
    };
}

export enum TypeTransactionEnum {
    SPOT = 1,
    CASHLESS = 2,
    INVOICE_PAYMENT = 3,
    CORRECTION = 4,
}

export class InvoicePayloadTransactionInterface {
    dateInvoice: string;
    invoiceNumber: string;
    invoiceId: string;
}

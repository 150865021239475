<div class="settings-header">
    <div class="settings-header__wrapper">
        <div class="settings-header__header-icon">
            <svg-icon [src]="EDIT_CONFOIGURATOR_ICON_PATH"></svg-icon>

            <span
                [translate]="(event$ | async)?.isActive && (event$ | async)?.id ? 'events.configurator.revision' : 'groups.settings.edit'"></span>
        </div>
        <div class="settings-header__header-title">{{title}}</div>
        <div (click)="closeEmmit()" class="settings-header__header-close">
            <svg-icon [src]="CLOSE_ICON_PATH"></svg-icon>
        </div>
    </div>
</div>

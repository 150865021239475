import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { ProfileBlocksType } from 'src/app/app-shared-elements/_interfaces/UserInfo';
import { SetCurrentProfileBlock } from '../actions/profile-blocks.actions';

export interface ProfileBlocksStateModel {
    blocks: {
        [property in ProfileBlocksType]: boolean;
    };
}

const PROFILE_BLOCKS_STATE = new StateToken<ProfileBlocksStateModel>('profileBlocksState');

@State<ProfileBlocksStateModel>({
    name: PROFILE_BLOCKS_STATE,
    defaults: {
        blocks: {
            userProfileInfo: true,
            userProfileInfoMailing: true,
            userProfileInfoSystem: true,
        },
    },
})
@Injectable()
export class ProfileBlocksState {
    constructor() {}

    @Selector()
    static getBlocks(state: ProfileBlocksStateModel): { [property in ProfileBlocksType]: boolean } {
        return state.blocks;
    }

    @Action(SetCurrentProfileBlock)
    setCurrentProfileBlock(ctx: StateContext<ProfileBlocksStateModel>, payload: SetCurrentProfileBlock): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            blocks: {
                ...state.blocks,
                [payload.property]: !state.blocks[payload.property],
            },
        });
    }
}

<div class="popup">
    <div class="popup__wrapper">
        <div class="popup__header">
            <div class="popup__title" [translate]="'canDeactivatePopup.confirmAction'"></div>
        </div>
        <div class="popup__body">
            <div class="popup__messages" [translate]="'canDeactivatePopup.message'"></div>
            <div class="popup__btns">
                <button class="popup__btn btn btn--light" 
                    (click)="eventEmit.emit(false)"
                    [translate]="'canDeactivatePopup.cancel'"></button>
                <button class="popup__btn btn btn--red" 
                    (click)="eventEmit.emit(true)"
                    [translate]="'canDeactivatePopup.move'"></button>
            </div>
        </div>
    </div>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { AlignItemsEnum, ColumnsTableInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { SetIsEditMode } from 'src/app/app-shared-elements/_store/actions/table.actions';
import { SelectTypeEnum } from '../../../_enums/select-type.enum';
import { AlarmTypeEnum } from '../../../../events/_enums/alarm.enum';
import { SelectOptionInterface } from 'src/app/app-shared-elements/_interfaces/select-option.interface';

@Component({
    selector: 'app-column-select',
    templateUrl: './column-select.component.html',
    styleUrls: ['./column-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnSelectComponent implements OnInit, OnDestroy {
    _value;

    @Input() options: any;

    @Input()
    set value(value) {
        this._value = value;
    }

    get value(): any {
        return this._value;
    }

    @Input() cell: ColumnsTableInterface;
    @Input() row: any;

    @Output() dataChanged = new EventEmitter();

    alignItemsEnum = AlignItemsEnum;
    selectTypeEnum = SelectTypeEnum;
    alarmTypeEnum = AlarmTypeEnum;

    currentOption;

    private isEditMode;

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.currentOption = this.options?.find((o) => this.value && this.value.length && o.value.includes(this.value));
    }

    ngOnDestroy(): void {
        if (this.isEditMode) {
            this.store.dispatch(new SetIsEditMode(false));
        }
    }

    setEditmode(event: boolean): void {
        this.isEditMode = event;
        this.store.dispatch(new SetIsEditMode(!!event));
    }

    setNewOption(option: SelectOptionInterface): void {
        this.dataChanged.emit(option);
    }
}

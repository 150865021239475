import { Component, Input, OnInit } from '@angular/core';
import { ResourceAction } from '../_enums/permission.enum';

@Component({
    selector: 'app-premission-helper',
    templateUrl: './premission-helper.component.html',
    styleUrls: ['./premission-helper.component.scss']
})
export class PremissionHelperComponent implements OnInit {

    @Input() currentResource: ResourceAction;

    constructor() { }

    ngOnInit(): void {
    }

}

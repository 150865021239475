import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {UserAuthGuardService} from '../auth/user-auth-guard.service';
import {ProfileComponent} from './profile.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {RouteGuardService} from "../app-shared-elements/_guards/route-guard-service.service";


const userRoutes: Routes = [
    {
        path: '',
        data: {title: 'Profile'},
        component: ProfileComponent,
        canActivate: [UserAuthGuardService],
        children: [

            {
                path: '',
                data: {title: 'User Profile'},
                component: UserProfileComponent,
                canActivate: [UserAuthGuardService],
                canDeactivate: [RouteGuardService],
            }
        ]
    },
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild(userRoutes),
    ]
})
export class ProfileRoutingModule { }

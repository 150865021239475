import {CreateStatusMessages, StatusMessagesRowInterface, UpdateStatusMessages} from '../../_interface/status-messages.interface';

export class GetStatusMessages {
    static readonly type = '[StatusMessages] GetStatusMessages';
}

export class GetStatusMessagesRows {
    static readonly type = '[StatusMessages] GetStatusMessagesRows';
}

export class AddNewMessage {
    static readonly type = '[StatusMessages] AddNewMessage';
}

export class CreateStatusMessage {
    static readonly type = '[StatusMessages] CreateStatusMessage';

    constructor(public data: CreateStatusMessages) {
    }
}

export class UpdateStatusMessage {
    static readonly type = '[StatusMessages] UpdateStatusMessage';

    constructor(public data: UpdateStatusMessages) {
    }
}

export class DeleteStatusMessage {
    static readonly type = '[StatusMessages] DeleteStatusMessage';

    constructor(public id: string) {
    }
}

export class CloseEditingStatusMessageRow {
    static readonly type = '[StatusMessages] CloseEditingStatusMessageRow';

    constructor(public id: string, public savedRow?: StatusMessagesRowInterface) {
    }
}

export class SetEditingStatusMessageRow {
    static readonly type = '[StatusMessages] SetEditingStatusMessageRow';

    constructor(public id: string) {
    }
}

export class UpdatedStatusMessageRows {
    static readonly type = '[StatusMessages] UpdatedStatusMessageRows';

    constructor(public isEdit: boolean) {
    }
}

import {Injectable} from '@angular/core';
import {InputListInterface} from "../../../app-shared-elements/_interfaces/input-list.interface";

@Injectable({
    providedIn: 'root'
})
export class MailMessageService {

    constructor() {
    }

    getSubjectInputList(variables: string[]): InputListInterface[] {
        if (!variables) {
            return;
        }
        const result: InputListInterface[] = [];
        variables.map(variable => {
            if (!variable) {
                return;
            }
            result.push({
                title: variable,
                type: variable,
                disableLength: 250 - variable.length,
                isWithoutInput: true
            });
        });
        return result;
    }

    getInputList(variables: string[]): InputListInterface[] {
        if (!variables) {
            return;
        }
        const result: InputListInterface[] = [];
        variables.map(variable => {
            if (!variable) {
                return;
            }
            result.push({
                title: variable,
                type: variable,
                disableLength: 250 - variable.length,
                isWithoutInput: true
            });
        });

        return result;
    }
}

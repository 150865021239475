import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputConstructorTypeEnum } from '../_enums/input-constructor-type.enum';

@Component({
    selector: 'app-input-constructor',
    templateUrl: './input-constructor.component.html',
    styleUrls: ['./input-constructor.component.scss'],
})
export class InputConstructorComponent implements OnInit {
    @Input() label: string;
    @Input() disabled: boolean;
    @Input() value: number | string;
    @Input() isWithoutInput: boolean;
    @Input() type: InputConstructorTypeEnum;
    @Input() isDeleteInput: boolean;

    readonly ADD_ICON_PATH = './assets/design/icons/add.svg';
    readonly DELETE_ICON_PATH = './assets/design/icons/delete.svg';

    isDisabled = true;
    @Output() newValueEvent = new EventEmitter<{ type: InputConstructorTypeEnum; value: number | string }>();

    constructor() {}

    ngOnInit(): void {}

    change(): void {
        if (+this.value > 0) {
            this.isDisabled = false;
        }
    }

    deleteValue(): void {}

    applyNewValue(): void {
        if (this.isWithoutInput) {
            this.newValueEvent.emit({
                type: this.type,
                value: null,
            });
            return;
        }

        if (this.isDisabled) {
            return;
        }

        this.newValueEvent.emit({
            type: this.type,
            value: this.value,
        });
    }
}

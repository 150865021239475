import { Injectable } from '@angular/core';
import { TypeFilterEnum } from 'src/app/app-shared-elements/filters/enums/type-filter.enum';

import { DropdownFilterOptionInterface } from 'src/app/app-shared-elements/filters/interfaces/filter-option.interface';
import { ReportSettingsDateInterface, ReportSettingsInterface } from '../_interfaces/ReportSettings';
import { ScheduleTypeEnum } from '../_enums/schedule-type.enum';
import {
    ColumnsTableInterface,
    ColumnTypeEnum,
    ColumnValueAlignEnum,
    IconInterface,
} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { DataTypeService } from '../../app-shared-elements/_services/data-type.service';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { ReportRowIconActionEnum } from '../_interfaces/ReportRow';
import { Device } from '../../app-shared-elements/_interfaces/Device';

@Injectable({
    providedIn: 'root',
})
export class ReportsListService {
    dropDownFilterOptions: DropdownFilterOptionInterface[] = [
        { key: 'name', value: 'reports.dropdownFilters.name', type: TypeFilterEnum.text, property: 'name' },
        { key: 'owner', value: 'reports.dropdownFilters.owner', type: TypeFilterEnum.text, property: 'owner' },
        { key: 'device', value: 'reports.dropdownFilters.device', type: TypeFilterEnum.select, property: 'device' },
    ];

    reportsListColumns: ColumnsTableInterface[] = [
        {
            title: 'reports.list.table.act',
            grow: false,
            small: true,
            maxWidth: '85px',
            minWidth: '85px',
            type: ColumnTypeEnum.icon,
            name: 'isActive',
        },
        {
            title: 'reports.list.table.name',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'name',
            // isClick: true,
            postText: true,
        },
        {
            title: 'reports.list.table.owner',
            grow: true,
            small: false,
            maxWidth: '200px',
            type: ColumnTypeEnum.text,
            name: 'owner',
            align: ColumnValueAlignEnum.left,
            tooltip: true,
            isClick: true,
        },
        {
            title: 'reports.list.table.device',
            grow: true,
            small: false,
            maxWidth: '240px',
            type: ColumnTypeEnum.text,
            name: 'device',
            align: ColumnValueAlignEnum.left,
        },
        {
            title: 'reports.list.table.period',
            grow: true,
            small: false,
            maxWidth: '240px',
            minWidth: '215px',
            type: ColumnTypeEnum.text,
            name: 'period',
            isMultilang: true,
        },
        {
            title: 'reports.list.table.nextDatePrint',
            grow: false,
            small: false,
            maxWidth: '240px',
            minWidth: '215px',
            type: ColumnTypeEnum.date,
            isShortDate: true,
            name: 'date',
            // isSettings: true,
            // isShowDelete: true
        },
        {
            title: 'reports.list.table.reportId',
            maxWidth: '170px',
            minWidth: '160px',
            grow: false,
            type: ColumnTypeEnum.text,
            name: 'id',
            isClick: true,
            substr: 12,
            preIcons: true,
        },
        {
            title: 'devices.actions',
            grow: false,
            small: true,
            maxWidth: '100px',
            minWidth: '85px',
            type: ColumnTypeEnum.action,
            name: 'edit',
            actionBtns: [ColumnsActionTypeEnum.actionBtnsEdit, ColumnsActionTypeEnum.actionBtnsDelete],
        },
    ];

    constructor(private dataTypeService: DataTypeService) {}

    parseReportDate(date: ReportSettingsDateInterface): string {
        return this.dataTypeService.reformatDate(+date.dateFrom) + ' - ' + this.dataTypeService.reformatDate(+date.dateTo);
    }

    parseNextDateNextPrint(schedule: ScheduleTypeEnum): number | null {
        const today = new Date();
        let year = today.getFullYear();
        const month = today.getMonth();

        switch (schedule) {
            case ScheduleTypeEnum.lastYear:
                year = today.getFullYear();
                return new Date(year + 1, 0, 1).getTime();

            case ScheduleTypeEnum.lastQuarter:
                let nextDate: Date;
                // month === 0 ? return today.setMonth() :
                if (month >= 0 && month <= 2) {
                    nextDate = new Date(today.setMonth(today.getMonth() + ((0 + 3 - today.getMonth()) % 3)));
                }

                if (month >= 3 && month <= 5) {
                    nextDate = new Date(today.setMonth(today.getMonth() + ((3 + 3 - today.getMonth()) % 3)));
                }

                if (month >= 6 && month <= 8) {
                    nextDate = new Date(today.setMonth(today.getMonth() + ((6 + 3 - today.getMonth()) % 3)));
                }

                if (month >= 9 && month <= 11) {
                    nextDate = new Date(today.setMonth(today.getMonth() + ((9 + 3 - today.getMonth()) % 3)));
                }
                nextDate.setDate(1);
                nextDate.setHours(0);
                nextDate.setMinutes(0);
                nextDate.setSeconds(0);
                return nextDate.getTime();

            case ScheduleTypeEnum.lastMonth:
                const nextMonth = new Date(today.setMonth(today.getMonth() + 1));
                nextMonth.setDate(1);
                nextMonth.setHours(0);
                nextMonth.setMinutes(0);
                nextMonth.setSeconds(0);
                return nextMonth.getTime();

            case ScheduleTypeEnum.lastWeek:
                const nextMonday = new Date();
                nextMonday.setDate(nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7));
                nextMonday.setHours(0);
                nextMonday.setMinutes(0);
                return new Date(nextMonday.getFullYear(), nextMonday.getMonth(), nextMonday.getDate()).getTime();

            case ScheduleTypeEnum.lastDay:
                const nextDay = new Date(today.setDate(today.getDate() + 1));
                nextDay.setHours(0);
                nextDay.setMinutes(0);
                nextDay.setSeconds(0);
                return nextDay.getTime();

            case ScheduleTypeEnum.currentDay:
                const nexttDay = new Date(today.setDate(today.getDate() + 1));
                nexttDay.setHours(0);
                nexttDay.setMinutes(0);
                nexttDay.setSeconds(0);
                return nexttDay.getTime();

            default:
                return null;
        }
    }

    parseTypeScheduleToText(schedule: ScheduleTypeEnum): string {
        switch (schedule) {
            case ScheduleTypeEnum.lastYear:
                return 'reports.periodSelect.lastYear';
            case ScheduleTypeEnum.lastQuarter:
                return 'reports.periodSelect.lastQuarter';
            case ScheduleTypeEnum.lastMonth:
                return 'reports.periodSelect.lastMonth';
            case ScheduleTypeEnum.lastWeek:
                return 'reports.periodSelect.lastWeek';
            case ScheduleTypeEnum.lastDay:
                return 'reports.periodSelect.lastDay';
            case ScheduleTypeEnum.currentDay:
                return 'reports.periodSelect.currentDay';
        }
    }

    parseReportPeriod(report: ReportSettingsInterface, defaultReport: Device): string {
        if (defaultReport) {
            return null;
        }

        return report.date.schedule !== ScheduleTypeEnum.customSchedule
            ? this.parseTypeScheduleToText(report.date.schedule)
            : this.parseReportDate(report.date);
    }

    getPreIcons(report: ReportSettingsInterface): IconInterface<ReportRowIconActionEnum>[] {
        if (!report) {
            return [];
        }

        return [
            {
                path: './assets/design/icons/table-operations/copy.svg',
                action: ReportRowIconActionEnum.copyBuffer,
                cellNames: ['id'],
            },
        ];
    }
}

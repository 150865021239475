<div class="multiname">
    <div class="multiname__input-wrapper"
         [style.width.px]="width"
    >

        <input type="text" class="multiname__input"
               [class.multiname__input--editing]="isEdited"
               [class.multiname__input--error]="!name || !name.length || isError"
               maxlength="70"
               [disabled]="isDisabled"
               [placeholder]="'events.configurator.placeholderName' | translate"
               [(ngModel)]="name"
               (input)="isEdited = true; editModeEvent.emit(true); setUpdateName($event.target.value)"
               (change)="setNewName($event.target.value)">

        <div *ngIf="isEdited && isShowBtn" class="multiname__settings">
            <span (click)="setOk()" class="multiname__settings-ok">
                <svg-icon [src]="OK_ICON_PATH"></svg-icon>

            </span>
            <span (click)="cancel()" class="multiname__settings-cancel">
                <svg-icon [src]="CANCEL_ICON_PATH"></svg-icon>

            </span>
        </div>
    </div>
</div>

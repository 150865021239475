import { Component, OnInit } from '@angular/core';
import { AcceptSender } from '../../mailing/_store/actions/mailing-sender.actions';
import { Select, Store } from '@ngxs/store';
import { SenderUserRowInterface } from '../../mailing/_interfaces/sender-user.interface';
import { Observable } from 'rxjs';
import { MailingSendersState } from '../../mailing/_store/states/mailing-sender.state';
import { UserState } from '../_store/states/user.state';
import { User } from '../_interfaces/user.interface';
import { LanguageState } from '../_store/states/language.state';
import { LangEnum } from '../_enums/lang.enum';
import { DataTypeService } from '../_services/data-type.service';

@Component({
    selector: 'app-malling-invite',
    templateUrl: './malling-invite.component.html',
    styleUrls: ['./malling-invite.component.scss'],
})
export class MallingInviteComponent implements OnInit {
    @Select(MailingSendersState.getInviteSenders) senders$: Observable<SenderUserRowInterface[]>;
    @Select(UserState.getUser) user$: Observable<User>;
    @Select(UserState.getUserTimezoneGMT) gmt$: Observable<string>;
    @Select(LanguageState.getLanguage) ln$: Observable<LangEnum>;

    constructor(private store: Store, public dataTypeService: DataTypeService) {}

    ngOnInit(): void {}

    async applyInvite(row: SenderUserRowInterface): Promise<void> {
        await this.store.dispatch(new AcceptSender({ id: row.id, isAccept: true })).toPromise();
    }

    async cancelInvite(row: SenderUserRowInterface): Promise<void> {
        await this.store.dispatch(new AcceptSender({ id: row.id, isAccept: true })).toPromise();
    }
}

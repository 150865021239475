<div class="column">
    <div class="column-ackn">
        <div class="column-ackn__wrapper"
             [class.column-ackn__wrapper--flex]="!value?.ts">
            <ng-container *ngIf="value?.ts || value && !value.isAcknowledgeable; else showBtn">

                <div class="column-act__date">
                    {{value.ts | luxonDate:(user$ | async).dateFormat || dataTypeService.dateFormat : (gmt$ | async) || dataTypeService.GMT : (ln$ | async)}}
                </div>

                <div *ngIf="value && value.acknowledgedByUser && value.acknowledgedByUser.length"
                     class="column-ackn__user">{{value.acknowledgedByUser}}</div>

                <ng-container *ngIf="value?.acknowledgedType === acknowledgedDeviceLogType.DELETE">

                    <div class="column-ackn__label">
                        <span [translate]="'logs.events.deleted'"></span>
                        <div class="column-ackn__tooltip">
                            <app-tooltip [side]="tooltipSideEnum.left" [template]="true">
                                <div class="column-ackn__tooltip-text"
                                     [translate]="'events.logicalEvents.table.completionByTrash'"></div>
                            </app-tooltip>
                        </div>
                    </div>

                </ng-container>

            </ng-container>
            <ng-template #showBtn>
                <button class="column-ackn__btn btn btn--ackn"
                        [translate]="value?.title || 'events.logicalEvents.ackn'"
                        [disabled]="value?.isDisabledBtn"
                        (click)="emitClickBtn()"
                ></button>
            </ng-template>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { CloneDeepService } from 'src/app/app-shared-elements/_services/clone-deep.service';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { RoleRow } from '../../_interfaces/role-row.interface';
import { RoleInterface } from '../../../../app-shared-elements/_interfaces/role.interface';
import { TreeRoleItemInterface } from '../../_interfaces/tree-role-item.interface';
import { RolesService } from '../../_services/roles.service';
import {
    CancelChangeCurrentRole,
    CancelRoleOption,
    ChangeActiveRole,
    ChangeTree,
    CheckCurrentUseRole,
    ClearCurrentRole,
    CreateRole,
    DeleteRole,
    GetCurrentRole,
    GetRoles,
    GetTreeFromServer,
    InitTree,
    SaveRoles,
    SetCurrentRole,
    SetIsAdminRoles,
} from '../actions/roles.actions';
import { HttpClient } from '@angular/common/http';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { GetRolePermissionsForAdmin } from 'src/app/app-shared-elements/_store/actions/permissions.actions';
import { SelectOptionInterface } from '../../../../app-shared-elements/_interfaces/select-option.interface';
import { TranslateService } from '@ngx-translate/core';

export interface RolesStateModel {
    roles: RoleInterface[];
    rolesRows: RoleRow[];
    currentRole: RoleInterface;
    isRolesLoad: boolean;
    treeView: TreeRoleItemInterface[];
    rolesOptions: SelectOptionInterface<string, string, RoleInterface>[];
    treePermissionsFromServer: any;
    isLoadTreePermissions: boolean;
    isAdminRoles: boolean;
    currentUseRole: { count: number };
    currentUserRole: RoleInterface;
}

const ROLES_TOKEN = new StateToken<RolesStateModel>('rolesState');

const ROOT_ROLE = 'Root';

@State({
    name: ROLES_TOKEN,
    defaults: {
        roles: [],
        currentRole: null,
        isRolesLoad: false,
        rolesRows: [],
        treeView: [],
        rolesOptions: [],
        treePermissionsFromServer: null,
        isLoadTreePermissions: false,
        isAdminRoles: false,
        currentUseRole: null,
        currentUserRole: null,
    },
})
@Injectable()
export class RolesState {
    constructor(
        private rolesService: RolesService,
        private notificationsService: NotificationsService,
        private cloneDeepService: CloneDeepService,
        private http: HttpClient,
        private translateService: TranslateService,
    ) {}

    @Selector()
    static getRoles(state: RolesStateModel): RoleInterface[] {
        return state.roles;
    }

    @Selector([RolesState.getRoles])
    static getRolesRows(state: RolesStateModel, roles: RoleInterface[]): RoleRow[] {
        return state.rolesRows;
    }

    @Selector()
    static getCurrentRole(state: RolesStateModel): RoleInterface {
        return state.currentRole;
    }

    @Selector([RolesState.getRoles])
    static getRolesOptions(state: RolesStateModel, roles: RoleInterface[]): SelectOptionInterface<string, string, RoleInterface>[] {
        return state.rolesOptions;
    }

    @Selector([RolesState.getCurrentRole])
    static getCurrentRolesOption(state: RolesStateModel, role: RoleInterface): SelectOptionInterface<string, string, RoleInterface> {
        return {
            type: 'text',
            property: role,
            key: role.id,
            value: role.name,
        };
    }

    @Selector()
    static getTreeView(state: RolesStateModel): TreeRoleItemInterface[] {
        return state.treeView;
    }

    @Selector()
    static getTreePermissions(state: RolesStateModel): any {
        return state.treePermissionsFromServer;
    }

    @Selector()
    static getIsAdinRoles(state: RolesStateModel): boolean {
        return state.isAdminRoles;
    }

    @Selector()
    static getIsCurrentRoleUsed(state: RolesStateModel): { count: number } {
        return state.currentUseRole;
    }

    @Selector()
    static getCurrentUserRole(state: RolesStateModel): RoleInterface {
        return state.currentUserRole;
    }

    @Action(GetTreeFromServer)
    async getTreeFromServer(ctx: StateContext<RolesStateModel>, payload: GetTreeFromServer): Promise<void> {
        const state = ctx.getState();
        const result = (await this.http
            .get(`api/control/${state.isAdminRoles ? 'admin-role' : 'device-role'}/tree`)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                treePermissionsFromServer: result.data,
                isLoadTreePermissions: true,
            });
        } else {
            ctx.setState({
                ...state,
                isLoadTreePermissions: false,
            });
        }
    }

    @Action(GetRoles)
    async getRoles(ctx: StateContext<RolesStateModel>): Promise<void> {
        const state = ctx.getState();
        const result = (await this.http
            .get(`api/control/${state.isAdminRoles ? 'admin-role' : 'device-role'}`)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            const defaultRole = await this.translateService.get('permissions.default').toPromise();
            const roles: RoleInterface[] = await Promise.all(
                result.data
                    .filter((r) => r.name !== ROOT_ROLE)
                    .map(async (role) => {
                        role.name = role.name === RolesService.defaultRole ? defaultRole : role.name;
                        role.description = `<span class="permissions">${await this.rolesService.getDescriptionForCurrentRole(role.permissions)}</span>`;
                        return role;
                    }),
            );
            // console.log(roles);
            ctx.setState({
                ...state,
                roles,
                rolesRows: roles.map((role): RoleRow => {
                    return {
                        isActive: true,
                        role: role.name,
                        description: role.description,
                        id: role.id,
                        disabledActions: role.name === RolesService.rootRole ? [ColumnsActionTypeEnum.actionBtnsEdit] : [],
                    };
                }),
                rolesOptions: roles
                    .filter((r) => r.name !== RolesService.rootRole)
                    .map((role) => {
                        return {
                            type: 'text',
                            property: role,
                            key: role.id,
                            value: role.name,
                        };
                    }),
            });
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(CreateRole)
    async createRole(ctx: StateContext<RolesStateModel>, payload: CreateRole): Promise<void> {
        const state = ctx.getState();

        const result = (await this.http
            .post(`api/control/${state.isAdminRoles ? 'admin-role' : 'device-role'}`, { name: payload.name })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, 'admin.roles.created');
            ctx.setState({
                ...state,
                roles: [...state.roles, result.data],
                rolesRows: [
                    ...state.rolesRows,
                    {
                        isActive: true,
                        role: result.data.name,
                        description: result.data.description,
                        id: result.data.id,
                        disabledActions: result.data.name === RolesService.rootRole ? [ColumnsActionTypeEnum.actionBtnsEdit] : [],
                    },
                ],
            });

            await ctx.dispatch(new GetRolePermissionsForAdmin()).toPromise();
        } else {
            // // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(SetCurrentRole)
    setCurrentRole(ctx: StateContext<RolesStateModel>, payload: SetCurrentRole): void {
        const state = ctx.getState();

        if (!payload.roleId) {
            ctx.setState({
                ...state,
                currentRole: null,
            });

            return;
        }

        ctx.setState({
            ...state,
            currentRole: this.cloneDeepService.cloneObject(state.roles.find((role) => role.id === payload.roleId)),
        });
    }

    @Action(InitTree)
    initTree(ctx: StateContext<RolesStateModel>): void {
        const state = ctx.getState();

        const treeView: TreeRoleItemInterface[] = this.rolesService.initTree(state.treePermissionsFromServer, state.currentRole);

        ctx.setState({
            ...state,
            treeView,
        });
    }

    @Action(SaveRoles)
    async saveRoles(ctx: StateContext<RolesStateModel>, payload: SaveRoles): Promise<void> {
        const state = ctx.getState();
        const currentRole = this.rolesService.changeCurrentRole(state.currentRole, payload.tree);
        const result: ApiResponse = (await this.http
            .put(`api/control/${state.isAdminRoles ? 'admin-role' : 'device-role'}`, { ...currentRole })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            const savedRole = result.data;

            savedRole.description = `<span class="permissions">${await this.rolesService.getDescriptionForCurrentRole(savedRole.permissions)}</span>`;
            ctx.setState({
                ...state,
                currentRole: null,
                roles: state.roles.map((role) => (role.id === result.data.id ? savedRole : role)),
            });
        } else {
            // // this.notificationsService.onEmit(TooltipStatusEnum.error, false);

            ctx.setState({
                ...state,
                currentRole: null,
            });
        }
    }

    @Action(ChangeActiveRole)
    async changeActiveRole(ctx: StateContext<RolesStateModel>, payload: ChangeActiveRole): Promise<void> {
        const state = ctx.getState();
        const currentRole = this.cloneDeepService.cloneObject(state.roles.find((role) => role.id === payload.roleId));
        let result: ApiResponse;

        if (currentRole) {
            currentRole.isActive = payload.isActive;
            result = (await this.http
                .put(`api/control/${state.isAdminRoles ? 'admin-role' : 'device-role'}`, { ...currentRole })
                .toPromise()
                .catch((e) => console.log(e))) as ApiResponse;
        }

        if (!currentRole || !result || result.status !== HTTP_STATUS.SUCCESS) {
            // // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
            ctx.setState({
                ...state,
            });
        } else {
            const savedRole = result.data;
            savedRole.description = await this.rolesService.getDescriptionForCurrentRole(savedRole.permissions);

            ctx.setState({
                ...state,
                roles: state.roles.map((role) => (role.id === result.data.id ? savedRole : role)),
            });
        }
    }

    @Action(ChangeTree)
    changeTree(ctx: StateContext<RolesStateModel>, payload: ChangeTree): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            treeView: payload.tree,
        });
    }

    @Action(CancelChangeCurrentRole)
    cancelChangeCurrentRole(ctx: StateContext<RolesStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentRole: { ...state.roles.find((role) => role.id === state.currentRole.id) },
        });
    }

    @Action(DeleteRole)
    async deleteRole(ctx: StateContext<RolesStateModel>, payload: DeleteRole): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .delete(`api/control/${state.isAdminRoles ? 'admin-role' : 'device-role'}`, { params: { roleId: payload.roleId } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
                roles: state.roles.filter((role) => role.id !== payload.roleId),
                rolesRows: state.rolesRows.filter((row) => row.id !== payload.roleId),
            });

            await ctx.dispatch(new GetRolePermissionsForAdmin()).toPromise();
        } else {
            // // this.notificationsService.onEmit(TooltipStatusEnum.error, false);

            ctx.setState({
                ...state,
            });
        }
    }

    @Action(SetIsAdminRoles)
    setIsAdminRoles(ctx: StateContext<RolesStateModel>, payload: SetIsAdminRoles): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isAdminRoles: payload.isAdmin,
        });
    }

    @Action(CheckCurrentUseRole)
    async checkCurrentUseRole(ctx: StateContext<RolesStateModel>, payload: CheckCurrentUseRole): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/control/device-role/count-use-role', {
                params: {
                    roleId: payload.roleId,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                currentUseRole: result.data,
            });
        }
    }

    @Action(GetCurrentRole)
    async getCurrentRole(ctx: StateContext<RolesStateModel>, payload: GetCurrentRole): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/control/device-role/one', {
                params: {
                    id: payload.id,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();
        // console.log(result);
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                currentUserRole: result.data,
            });
        }
    }

    @Action(ClearCurrentRole)
    clearCurrentRole(ctx: StateContext<RolesStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentUserRole: null,
        });
    }

    @Action(CancelRoleOption)
    cancelRoleOption(ctx: StateContext<RolesStateModel>) {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentRole: null,
        });
    }
}

import {RoleInterface} from "../../../app-shared-elements/_interfaces/role.interface";

export interface UpdateSocketRoleInterface {
    action: ActionRoleUpdate;

    roles: RoleInterface[];
}

export enum ActionRoleUpdate {
    UPDATE = '+', // update/insert role
    DELETE = '-', // delete role
    ALL = '*' // replace all existing roles
}

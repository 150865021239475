import { Component, OnDestroy, OnInit } from '@angular/core';
import { BillingAccountInfoTypeEnum } from '../_enums/billing-account-info-type.enum';
import { BillingInvoiceInterface } from '../_interfaces/billing-invoice.interface';
import { ServicesInvoiceEnum } from '../_interfaces/billing-service-invoice.interface';
import { ActivatedRoute } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { ClearCurrentInvoice, GetCurrentInvoiceFromServer } from '../_store/actions/billing-invoice.actions';
import { BillingInvoiceState } from '../_store/states/billing-invoice.state';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LangEnum } from '../../app-shared-elements/_enums/lang.enum';
import { takeUntil, tap } from 'rxjs/operators';
import { SafePipeEnum } from '../../app-shared-elements/_enums/safe-pipe.enum';

@Component({
    selector: 'app-billing-invoice-print',
    templateUrl: './billing-invoice-print.component.html',
    styleUrls: ['./billing-invoice-print.component.scss'],
})
export class BillingInvoicePrintComponent implements OnInit, OnDestroy {
    @Select(BillingInvoiceState.getCurrentViewInvoice) currentInvoice$: Observable<BillingInvoiceInterface>;

    token: string;
    currentInvoice: BillingInvoiceInterface;

    INVOICE_LOGO_PATH = './assets/design/icons/invoice/logo.svg';
    safePipeEnum = SafePipeEnum;

    servicesInvoiceEnum = ServicesInvoiceEnum;

    invoiceInfoArray: { name: string; value: BillingAccountInfoTypeEnum }[] = [
        {
            name: 'billing.invoice.print.company',
            value: BillingAccountInfoTypeEnum.name,
        },
        {
            name: 'billing.invoice.print.rr',
            value: BillingAccountInfoTypeEnum.paymentAccount,
        },
        {
            name: 'billing.invoice.print.edrpou',
            value: BillingAccountInfoTypeEnum.edrpou,
        },
        {
            name: 'billing.invoice.print.address',
            value: BillingAccountInfoTypeEnum.address,
        },
        {
            name: 'billing.invoice.print.phone',
            value: BillingAccountInfoTypeEnum.phone,
        },
        {
            name: 'billing.invoice.print.email',
            value: BillingAccountInfoTypeEnum.email,
        },
    ];

    payerInvoiceArray: { name: string; value: string }[] = [
        {
            name: 'billing.invoice.print.payerName',
            value: BillingAccountInfoTypeEnum.name,
        },
        {
            name: 'billing.invoice.print.rr',
            value: BillingAccountInfoTypeEnum.paymentAccount,
        },
        {
            name: 'billing.invoice.print.edrpou',
            value: BillingAccountInfoTypeEnum.edrpou,
        },
        {
            name: 'billing.invoice.print.address',
            value: BillingAccountInfoTypeEnum.address,
        },
        {
            name: 'billing.invoice.print.phone',
            value: BillingAccountInfoTypeEnum.phone,
        },
        {
            name: 'billing.invoice.print.email',
            value: BillingAccountInfoTypeEnum.email,
        },
    ];

    destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private activateRoute: ActivatedRoute,
        private store: Store,
        private translateService: TranslateService,
    ) {
        this.currentInvoice$.pipe(takeUntil(this.destroy)).subscribe((currentInvoice) => {
            if (!currentInvoice) {
                return;
            }
            this.currentInvoice = currentInvoice;
        });

        const params: any = this.activateRoute?.snapshot?.queryParams;
        if (params.token) {
            this.token = params.token;
        }
    }

    async ngOnInit(): Promise<void> {
        if (this.token) {
            console.log('process-query:1/2');
            await this.store
                .dispatch(new GetCurrentInvoiceFromServer(this.token))
                .pipe(
                    tap(() => {
                        this.translateService.use(this.currentInvoice?.lang ?? LangEnum.ua);
                        console.log('process-query:2/3');

                        setTimeout(() => {
                            console.log('process-query:3/3');
                        }, 1000);
                        setTimeout(() => {
                            console.log(this.token);
                        }, 2000);
                    }),
                )
                .toPromise();
        }
    }

    ngOnDestroy(): void {
        this.store.dispatch(new ClearCurrentInvoice());
    }
}

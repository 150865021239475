import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, SelectorOptions, State, StateContext, StateToken, Store } from '@ngxs/store';
import { first } from 'rxjs/operators';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { Group } from 'src/app/app-shared-elements/_interfaces/Group';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { EventModeEnum, LimitLogicEventUpdateDto, LogicEvent, LogicEventType } from '../../logical-events/_interface/LogicEvent';
import { AlarmTypeEnum } from '../../_enums/alarm.enum';
import { ConditionType, OperandConstantType } from '../../_enums/condition-type.enum';
import { EventSaveStatus } from '../../_enums/event-save-state.enum';
import { EventMessage } from '../../_interfaces/EventMessage';
import { ExpressionInterface } from '../../_interfaces/Expression';
import { LogicalEventRowInterface } from '../../_interfaces/LogicalEventRow';
import { LogicalEventsService } from '../../_services/logical-events.service';
import { ExpressionService } from '../../_services/expression.service';
import {
    ChangeActivitiesEvents,
    ChangeActivityMultiEvent,
    ChangeSavedStatusEvent,
    CopyLogicalEvent,
    CreateEvent,
    DeleteLogicalEvents,
    DeleteMultiEvent,
    GetLogicalEvent,
    GetLogicalEvents,
    GetSystemDelay,
    InitAllVariableOption,
    InitLogicalEventsRows,
    InitMessageOptions,
    SetAlarmExpressionString,
    SetAttentionExpressionString,
    SetCurrentLogicEvent,
    SetErrorExpressionMessage,
    SetEventMode,
    SetFilterVariablesByDevice,
    SetLogicalEventParams,
    SetLogicEvent,
    SetSuccessUpdateEvent,
    ToggleLogicalEventsAccordion,
    UpdateCurrentLogicEvent,
    UpdateEvent,
    UpdateLogicalEventsRows,
    UpdateLogicEventFromSocket,
    ValidExpression,
} from '../actions/logical-events.actions';
import { LogicalMessagesState } from './messages-catalog.state';
import { MethodPermission, ResourceAction } from '../../../app-shared-elements/_enums/permission.enum';
import { PermissionService } from '../../../app-shared-elements/_services/permission.service';
import { Pagination, Params, ParamsFilterTypeEnum } from '../../../app-shared-elements/_interfaces/params.interface';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { ActiveEvents } from '../../_interfaces/ActiveEvents';
import { ActiveEventsState } from 'src/app/app-shared-elements/_store/states/active-events.state';
import { DataTypeService } from 'src/app/app-shared-elements/_services/data-type.service';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { MultiEventState } from './multi-event.state';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { UserState } from '../../../app-shared-elements/_store/states/user.state';
import { AuthState } from '../../../auth/_store/states/auth.state';

export interface LogicalEventsStateModel {
    logicalEvents: LogicEvent[];
    logicalEvent: LogicEvent;
    currentLogicalEvent: LogicEvent;
    isSuccesUpdate: boolean;
    errorExpressionMessage: string;
    filterVariableByDevice: string;
    currentRegistratorForTable: Device;
    isValidExpression: boolean;
    allVariablesOptions: SelectOptionInterface<string, string, Variable>[];
    filterGroup: Group;
    eventsRows: LogicalEventRowInterface[];
    systemDelay: number;
    messagesOptions: SelectOptionInterface[];
    params: Params;
    currentEventMode: EventModeEnum;
    currentResultVariable: Variable;
    alarmExpressionString: string;
    attentionExpressionString: string;
}

const LOGICAL_EVENTS_TOKEN = new StateToken<LogicalEventsStateModel>('logicalEvents');

const initialPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

const initialParams: Params = {
    pagination: initialPagination,
    sorted: null,
    filter: [
        {
            property: 'name',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true,
        },
        {
            property: 'status',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true,
        },
        {
            property: 'registratorId',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true,
        },
    ],
};

const initialNewLogicalEvent: LogicEvent = {
    isAcknowledgeable: false,
    type: AlarmTypeEnum.attention,
    notifications: false,
    id: undefined,
    expression: {
        conditions: [
            {
                type: ConditionType.Equal,
                value: [
                    {
                        type: ConditionType.Constant,
                        value: '0',
                        operandType: OperandConstantType.INT,
                    },
                    {
                        type: ConditionType.Constant,
                        value: '0',
                        operandType: OperandConstantType.INT,
                    },
                ],
            },
        ],
    },
    eventAlertMessageId: null,
    name: '',
    resultVariableId: undefined,
    // resultVariable: null,
    registratorId: null,
    isActive: true,
    savedStatus: EventSaveStatus.SAVED,
    logicEventType: null,
};

@State<LogicalEventsStateModel>({
    name: LOGICAL_EVENTS_TOKEN,
    defaults: {
        logicalEvents: [],
        logicalEvent: null,
        currentLogicalEvent: null,
        // currentGroups: [],
        isSuccesUpdate: false,
        errorExpressionMessage: null,
        filterVariableByDevice: null,
        currentRegistratorForTable: null,
        isValidExpression: false,
        allVariablesOptions: [],
        filterGroup: null,
        eventsRows: [],
        systemDelay: null,
        messagesOptions: [],
        params: initialParams,
        currentEventMode: EventModeEnum.simple,
        currentResultVariable: null,
        alarmExpressionString: null,
        attentionExpressionString: null,
    },
})
@Injectable()
export class LogicalEventsState {
    constructor(
        private logicalEventsService: LogicalEventsService,
        private notificationService: NotificationsService,
        private expressionService: ExpressionService,
        private store: Store,
        private translateService: TranslateService,
        private http: HttpClient,
        private permissionService: PermissionService,
        private dataTypeService: DataTypeService,
    ) {}

    @Selector()
    static getLogicalEvents(state: LogicalEventsStateModel): LogicEvent[] {
        return state.logicalEvents.sort((a, b) => (new Date(b.created).getTime() > new Date(a.created).getTime() ? 1 : -1));
    }

    @Selector()
    static getLogicalEvent(state: LogicalEventsStateModel): LogicEvent {
        return state.logicalEvent;
    }

    @Selector()
    static getCurrentLogicalEvent(state: LogicalEventsStateModel): LogicEvent {
        // console.log(state.currentLogicalEvent);
        return state.currentLogicalEvent;
    }

    @Selector()
    static getCurrentExpression(state: LogicalEventsStateModel): ExpressionInterface {
        return state.currentLogicalEvent.expression;
    }

    @Selector()
    static getSuccessUpdateEvent(state: LogicalEventsStateModel): boolean {
        return state.isSuccesUpdate;
    }

    @Selector()
    static getErrorExpressionMessage(state: LogicalEventsStateModel): string {
        return state.errorExpressionMessage;
    }

    @Selector()
    static getFilterVariablesByDevice(state: LogicalEventsStateModel): string {
        return state.filterVariableByDevice;
    }

    @Selector()
    static getValidExpressionCurrentEvent(state: LogicalEventsStateModel): boolean {
        return state.isValidExpression;
    }

    @Selector()
    static getAllVariablesOptions(state: LogicalEventsStateModel): SelectOptionInterface<string, string, Variable>[] {
        return state.logicalEvent && state.logicalEvent.registratorId ? state.allVariablesOptions.filter((o) => o.property.originRegistratorId === state.logicalEvent.registratorId) : state.allVariablesOptions;
    }

    @Selector()
    static getFilterGroup(state: LogicalEventsStateModel): Group {
        return state.filterGroup;
    }

    @Selector()
    static getSystemDelay(state: LogicalEventsStateModel): number {
        return state.systemDelay;
    }

    @Selector()
    static getParams(state: LogicalEventsStateModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }

    @Selector()
    static getCurrentEventMode(state: LogicalEventsStateModel): EventModeEnum {
        return state.currentEventMode;
    }

    @Selector()
    static getLogicalEventsRows(state: LogicalEventsStateModel): LogicalEventRowInterface[] {
        return state.eventsRows.sort((a, b) => (new Date(b.created).getTime() > new Date(a.created).getTime() ? 1 : -1));
    }

    @Selector([MultiEventState.getCurrentMultiEvent])
    static getCurrentResultVariable(state: LogicalEventsStateModel, currentMultiEvent: LimitLogicEventUpdateDto): Variable {
        let currentOption;

        switch (state.currentEventMode) {
            case EventModeEnum.simple:
                currentOption = state.allVariablesOptions.find((option) => option.key === currentMultiEvent.variableId);
                return currentOption.property;
            case EventModeEnum.advance:
                currentOption = state.allVariablesOptions.find((option) => option.key === state.currentLogicalEvent.resultVariableId);
                return currentOption.property;
        }
    }

    @SelectorOptions({ injectContainerState: false, suppressErrors: true })
    @Selector([LogicalMessagesState.getLogicalMessages])
    static getMessageOptions(messages: EventMessage[]): SelectOptionInterface[] {
        return messages.map((message) => {
            return {
                key: message.id,
                value: message.message,
                type: 'text',
                property: message.registratorId,
            };
        });
    }

    @Selector()
    static getAlarmExpressionString(state: LogicalEventsStateModel): string {
        return state.alarmExpressionString;
    }

    @Selector()
    static getAttentionExpressionString(state: LogicalEventsStateModel): string {
        return state.attentionExpressionString;
    }

    @Action(CreateEvent)
    async createEvent(ctx: StateContext<LogicalEventsStateModel>, payload: CreateEvent): Promise<void> {
        const headers = new HttpHeaders({ registratorId: payload.event.registratorId });

        const result: ApiResponse = (await this.http
            .post('api/logic-events', { ...payload.event }, { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
                currentLogicalEvent: state.currentLogicalEvent,
                logicalEvents: [...state.logicalEvents, result.data],
            });

            ctx.dispatch(new SetSuccessUpdateEvent(true));
        } else if (result && result.status === HTTP_STATUS.INVALID_EVENT_EXPRESSION) {
            const message = 'events.logicalEvents.invalidExpression';
            this.notificationService.onEmit(TooltipStatusEnum.error, false, message);
            ctx.dispatch(new SetSuccessUpdateEvent(false));
            ctx.dispatch(new SetErrorExpressionMessage(result && result.data ? result.data : null));
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
            ctx.dispatch(new SetSuccessUpdateEvent(false));
            ctx.dispatch(new SetErrorExpressionMessage(result && result.data ? result.data : null));
        }
    }

    @Action(UpdateEvent)
    async updateEvent(ctx: StateContext<LogicalEventsStateModel>, payload: UpdateEvent): Promise<void> {
        const state = ctx.getState();
        const headers = new HttpHeaders({ registratorId: payload.event.registratorId });

        const result: ApiResponse = (await this.http
            .put('api/logic-events', { ...payload.event }, { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.dispatch(new SetSuccessUpdateEvent(true));
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
            ctx.dispatch(new SetSuccessUpdateEvent(false));
            ctx.dispatch(new SetErrorExpressionMessage(result && result.data ? result.data : null));
        }
    }

    @Action(UpdateLogicEventFromSocket)
    async pdateLogicEventFromSocket(ctx: StateContext<LogicalEventsStateModel>, payload: UpdateLogicEventFromSocket): Promise<void> {
        const state = ctx.getState();
        console.log(payload.data);
        switch (payload.data.action) {
            case 'delete':
                ctx.setState({
                    ...state,
                    logicalEvents: state.logicalEvents.filter((e) => e.id !== payload.data.logicEventId),
                });
                break;
            case 'update':
                ctx.setState({
                    ...state,
                    logicalEvents: state.logicalEvents.map((event) => (event.id === payload.data.data.id ? { ...payload.data.data } : event)),
                });
                break;
            case 'create':
                ctx.setState({
                    ...state,
                    logicalEvents: [payload.data.data, ...state.logicalEvents],
                });
                break;
        }

        await ctx.dispatch(new UpdateLogicalEventsRows()).toPromise;
    }

    @Action(CopyLogicalEvent)
    async copyLogicalEvent(ctx: StateContext<LogicalEventsStateModel>, payload: CopyLogicalEvent): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post('/api/logic-events/copy', { eventId: payload.eventId })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                logicalEvents: [...state.logicalEvents, result.data],
            });
        }
    }

    @Action(GetLogicalEvents)
    async getLogicalEvents(ctx: StateContext<LogicalEventsStateModel>, payload: GetLogicalEvents): Promise<void> {
        const state = ctx.getState();
        const registratorsId: string = this.permissionService.getAllowedRegistratorIds(payload.ids, ResourceAction.LOGIC_EVENT, [MethodPermission.READ]).join(',');

        if (!registratorsId || !registratorsId.length) {
            return;
        }

        const currentUserId = this.store.selectSnapshot(UserState.getAdminUserId);
        let result: ApiResponse;

        const headers = currentUserId ? new HttpHeaders({ registratorId: registratorsId }).set('currentUserId', currentUserId) : new HttpHeaders({ registratorId: registratorsId });

        if (this.store.selectSnapshot(AuthState.getIsAdmin)) {
            currentUserId && currentUserId.length
                ? (result = (await this.http
                      .get('api/logic-events', {
                          params: { registratorsId },
                          headers,
                      })
                      .toPromise()
                      .catch((e) => console.log(e))) as ApiResponse)
                : (result = (await this.http
                      .get('api/control/logic-events', {
                          params: { registratorsId },
                          headers,
                      })
                      .toPromise()
                      .catch((e) => console.log(e))) as ApiResponse);
        } else {
            result = (await this.http
                .get('api/logic-events', {
                    params: { registratorsId },
                    headers,
                })
                .toPromise()
                .catch((e) => console.log(e))) as ApiResponse;
        }

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                logicalEvents: result.data,
            });
        } else {
            ctx.setState({
                ...state,
                logicalEvents: [],
            });
        }
    }

    @Action(GetLogicalEvent)
    async getLogicalEvent(ctx: StateContext<LogicalEventsStateModel>, payload: GetLogicalEvent): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .get(`api/logic-events/${payload.eventId}`)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                logicalEvent: result.data as LogicEvent,
            });
        }
    }

    @Action(DeleteLogicalEvents)
    async deleteLogicalEvents(ctx: StateContext<LogicalEventsStateModel>, payload: DeleteLogicalEvents): Promise<void> {
        const state = ctx.getState();
        const logicalEvents: LogicEvent[] = this.store.selectSnapshot(LogicalEventsState.getLogicalEvents);
        const registratorIds = logicalEvents.map((event) => (payload.ids.includes(event.id) ? event.registratorId : null)).filter((item) => !!item);

        const headers = new HttpHeaders({ registratorId: registratorIds ? registratorIds.join(',') : '' });

        const result: ApiResponse = (await this.http
            .delete(`api/logic-events`, {
                params: { events: JSON.stringify(payload.ids) },
                headers,
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false, 'events.logicalEvents.tooltipDeleted');

            ctx.setState({
                ...state,
                logicalEvents: state.logicalEvents.filter((event) => !payload.ids.includes(event.id)),
                eventsRows: state.eventsRows.filter((row) => !payload.ids.includes(row.id)),
            });
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(ChangeActivitiesEvents)
    async changeActivitiesEvents(ctx: StateContext<LogicalEventsStateModel>, payload: ChangeActivitiesEvents): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .put('api/logic-events/active', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            if (result.data.length) {
                ctx.setState({
                    ...state,
                    logicalEvents: state.logicalEvents.map((event) => {
                        const index = result.data.findIndex((item) => item.id === event.id);
                        if (index !== -1) {
                            return {
                                ...result.data[index],
                            };
                        }

                        return {
                            ...event,
                        };
                    }),
                });

                await ctx.dispatch(new UpdateLogicalEventsRows()).toPromise();
            }
        } else if (result.status === HTTP_STATUS.REGISTRATOR_IN_SYNC) {
            const messageSync = await this.translateService.get('devices.registratorIsSync').toPromise();
            this.notificationService.onEmit(TooltipStatusEnum.error, false, messageSync);
        } else if (result.status === HTTP_STATUS.REGISTRATOR_IS_OFFLINE || result.status === HTTP_STATUS.EVENTS_ACTIVE_FOR_REGISTRATOR) {
            const messageSync = await this.translateService.get('events.logicalEvents.offlineRegistrator').toPromise();
            this.notificationService.onEmit(TooltipStatusEnum.error, false, messageSync);
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(SetCurrentLogicEvent)
    setCurrentLogicEvent(ctx: StateContext<LogicalEventsStateModel>, payload: SetCurrentLogicEvent): void {
        const state = ctx.getState();
        if (!state || !state.logicalEvents.length || !payload.id) {
            return;
        }

        const currentLogicalEvent = state.logicalEvents.find((event) => event.id === payload.id) || initialNewLogicalEvent;
        ctx.setState({
            ...state,
            currentLogicalEvent,
        });
    }

    @Action(SetLogicEvent)
    setCurrentFullLogicEvent(ctx: StateContext<LogicalEventsStateModel>, payload: SetLogicEvent): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentLogicalEvent: payload.event,
        });
    }

    @Action(UpdateCurrentLogicEvent)
    updateCurrentLogicEvent(ctx: StateContext<LogicalEventsStateModel>, payload: UpdateCurrentLogicEvent): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            currentLogicalEvent: { ...payload.logicEvent },
        });

        ctx.dispatch(new ValidExpression());
    }

    @Action(SetSuccessUpdateEvent)
    setSuccessUpdateEvent(ctx: StateContext<LogicalEventsStateModel>, payload: SetSuccessUpdateEvent): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isSuccesUpdate: payload.value,
        });
    }

    @Action(SetErrorExpressionMessage)
    async setErrorExpressionMessage(ctx: StateContext<LogicalEventsStateModel>, payload: SetErrorExpressionMessage): Promise<void> {
        const state = ctx.getState();
        if (!payload.message) {
            ctx.setState({
                ...state,
                errorExpressionMessage: null,
            });

            return;
        }

        let message;
        if (typeof payload.message !== 'string') {
            message = await this.expressionService.getErrorValidateExpressionMessage(payload.message);
        } else {
            message = payload.message;
        }

        ctx.setState({
            ...state,
            errorExpressionMessage: message,
        });
    }

    @Action(SetFilterVariablesByDevice)
    setFilterVariablesByDevice(ctx: StateContext<LogicalEventsStateModel>, payload: SetFilterVariablesByDevice): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            filterVariableByDevice: payload.deviceId,
        });
    }

    @Action(ValidExpression)
    validExpression(ctx: StateContext<LogicalEventsStateModel>, payload: ValidExpression): void {
        const state = ctx.getState();

        const expression: ExpressionInterface = payload.expression ? payload.expression : state.currentLogicalEvent.expression;

        if (!this.expressionService.validateExpression(expression)) {
            ctx.setState({
                ...state,
            });

            this.translateService
                .get('events.expressions.expressionException.doNotMatсhTypes')
                .pipe(first())
                .subscribe((message) => {
                    this.store.dispatch(new SetErrorExpressionMessage(message));
                });
        } else {
            this.store.dispatch(new SetErrorExpressionMessage(null));
        }
    }

    @Action(InitAllVariableOption)
    async initAllVariableOption(ctx: StateContext<LogicalEventsStateModel>, payload: InitAllVariableOption): Promise<void> {
        const state = ctx.getState();

        let allVariablesOptions: SelectOptionInterface<string, string, Variable>[] = [];

        payload.devices.map(async (device) => {
            device.variables.map(async (variable) => {
                const type = await this.translateService.get('detailsDevice.types.' + variable.type).toPromise();
                allVariablesOptions.push({
                    key: variable.id,
                    value: `${variable.customName ?? variable.name} (${device.name ? device.name : device.defaultName}) <${type}>`,
                    property: variable,
                    type: 'text',
                });
            });
        });

        allVariablesOptions = allVariablesOptions.sort((a, b) => (a.property.internalId > b.property.internalId ? 1 : -1));

        ctx.setState({
            ...state,
            allVariablesOptions,
        });
    }

    @Action(ChangeSavedStatusEvent)
    async changeSavedStatusEvent(ctx: StateContext<LogicalEventsStateModel>, payload: ChangeSavedStatusEvent): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            logicalEvents: state.logicalEvents.map((event) => {
                const currentEvent = payload.data.items.find((f) => f.id === event.id);

                if (currentEvent) {
                    return {
                        ...event,
                        savedStatus: currentEvent.savedStatus,
                    };
                }
                // const currentItem = payload.data;
                //
                // if (!(currentItem.registratorId === event.registratorId && currentItem.type === EventSaveFromSocketTypeEnum.event && currentItem.items.length)) {
                //     event.savedStatus = EventSaveStatus.SAVED;
                //     return event;
                // }
                //
                // const itemIndex = currentItem.items.findIndex((item) => item.id === event.id);
                //
                // if (itemIndex === -1) {
                //     event.savedStatus = EventSaveStatus.SAVED;
                //     return event;
                // }
                //
                // event.savedStatus = currentItem.items[itemIndex].savedStatus;

                return event;
            }),
        });

        await ctx.dispatch(new UpdateLogicalEventsRows()).toPromise();
    }

    @Action(GetSystemDelay)
    async getSystemDelay(ctx: StateContext<LogicalEventsStateModel>, payload: GetSystemDelay): Promise<void> {
        const state = ctx.getState();

        if (!payload.registratorId) {
            return;
        }

        const headers = new HttpHeaders({ registratorId: payload.registratorId });
        const result: ApiResponse = (await this.http
            .get(`api/logic-events/delay/${payload.registratorId}`, { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                systemDelay: result.data || 0,
            });
        }
    }

    @Action(InitMessageOptions)
    initMessageOptions(ctx: StateContext<LogicalEventsStateModel>, payload: InitMessageOptions): void {
        const state = ctx.getState();
        if (!payload.messages || !payload.messages.length) {
            ctx.setState({
                ...state,
                messagesOptions: [],
            });

            return;
        }

        ctx.setState({
            ...state,
            messagesOptions: payload.messages.map((message) => {
                return {
                    key: message.id,
                    value: message.message,
                    type: 'text',
                    property: message.message,
                };
            }),
        });
    }

    @Action(SetLogicalEventParams)
    setLogicalEventParams(ctx: StateContext<LogicalEventsStateModel>, payload: SetLogicalEventParams): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: payload.params,
        });
    }

    @Action(SetEventMode)
    setCurrentEventMode(ctx: StateContext<LogicalEventsStateModel>, payload: SetEventMode): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentEventMode: payload.eventMode,
        });
    }

    @Action(DeleteMultiEvent)
    async deleteMultiEvent(ctx: StateContext<LogicalEventsStateModel>, payload: DeleteMultiEvent): Promise<void> {
        const result = (await this.http
            .delete(`/api/logic-events-limit/limit`, { params: { variableId: payload.row.resultVariableId } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false, 'events.logicalEvents.tooltipDeleted');

            ctx.setState({
                ...state,
                // logicalEvents: state.logicalEvents.filter(e => e.resultVariableId !== payload.row.resultVariableId),
                // eventsRows: state.eventsRows.filter(e => e.resultVariableId !== payload.row.resultVariableId)
            });
            await ctx.dispatch(new UpdateLogicalEventsRows()).toPromise();
        } else {
            ctx.setState({ ...state });
        }
    }

    @Action(ChangeActivityMultiEvent)
    async changeActivityMultiEvent(ctx: StateContext<LogicalEventsStateModel>, payload: ChangeActivityMultiEvent): Promise<void> {
        const result = (await this.http
            .post('/api/logic-events-limit/active-limit', {
                variableId: payload.row.resultVariableId,
                limitIsActive: payload.isActive,
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
                logicalEvents: state.logicalEvents.map((e) => {
                    if (e.resultVariableId === payload.row.resultVariableId && e.logicEventType !== LogicEventType.default) {
                        // e.isActive = payload.isActive;
                        e.limitIsActive = payload.isActive;
                    }
                    return e;
                }),
            });

            await ctx.dispatch(new UpdateLogicalEventsRows()).toPromise();
        } else if (result.status === HTTP_STATUS.REGISTRATOR_IS_OFFLINE || result.status === HTTP_STATUS.EVENTS_ACTIVE_FOR_REGISTRATOR) {
            const messageSync = await this.translateService.get('events.logicalEvents.offlineRegistrator').toPromise();
            this.notificationService.onEmit(TooltipStatusEnum.error, false, messageSync);
        } else {
            ctx.setState({ ...state });
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(InitLogicalEventsRows)
    async initLogicalEventsRows(ctx: StateContext<LogicalEventsStateModel>): Promise<void> {
        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        const eventsStatus: ActiveEvents[] = this.store.selectSnapshot(ActiveEventsState.getActiveEvents);
        const state = ctx.getState();

        const rows = await this.logicalEventsService.initRows(state.logicalEvents, eventsStatus, devices);
        ctx.setState({
            ...state,
            eventsRows: rows,
            params: {
                ...state.params,
                pagination: {
                    ...state.params.pagination,
                    totalItems: rows && rows.length ? rows.filter((e) => !!e && (!e.logicEventType || e.logicEventType === LogicEventType.default)).length : 0,
                },
            },
        });
    }

    @Action(UpdateLogicalEventsRows)
    async updateLogicalEventsRows(ctx: StateContext<LogicalEventsStateModel>): Promise<void> {
        const updateTitle = await this.translateService.get('events.logicalEvents.update').toPromise();
        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        const eventsStatus: ActiveEvents[] = this.store.selectSnapshot(ActiveEventsState.getActiveEvents);
        const state = ctx.getState();
        let currentDevice: Device;
        ctx.setState({
            ...state,
            eventsRows: state.eventsRows
                .map((row: LogicalEventRowInterface) => {
                    let currentEvents: ActiveEvents;
                    if (eventsStatus && eventsStatus.length) {
                        currentEvents = eventsStatus.find((item) => item.registratorId === row.registratorId);
                    }

                    if (row.isMainAcordion) {
                        const currentRegistrator = devices.find((f) => f.id === row.registratorId);
                        const eventsByVariableId = state.logicalEvents.filter((e) => e.resultVariableId === row.resultVariableId && e.logicEventType !== LogicEventType.default);
                        if (!eventsByVariableId || !eventsByVariableId.length) {
                            return;
                        }

                        const childStates: string[] = eventsByVariableId.map((e) => {
                            return this.logicalEventsService.getEventState(currentEvents, e);
                        });

                        return {
                            ...row,
                            savedStatus: this.logicalEventsService.getMultiEventMainSavedStatus(eventsByVariableId),
                            isActive: eventsByVariableId[0].limitIsActive,
                            state: eventsByVariableId[0].limitIsActive ? this.logicalEventsService.getStateForMultiEvent(childStates) : 'NA',
                            styles: {
                                state: eventsByVariableId[0].limitIsActive ? this.logicalEventsService.getStatusStyles(this.logicalEventsService.getStateForMultiEvent(childStates)) : '',
                            },
                            postIcons: this.logicalEventsService.getPostIcons(null, true, row, currentRegistrator),
                            preIcons: this.logicalEventsService.getPreIcons(null),
                            disabledActions: this.logicalEventsService.getDisabledActions(eventsByVariableId[0], currentDevice),
                            accordionChildRows: [
                                ...row.accordionChildRows.map((subRow) => {
                                    const currentEvent = eventsByVariableId.find((e) => e.id === subRow.id);
                                    currentDevice = devices.find((d) => d.variables.find((v) => v.id === currentEvent?.resultVariableId));

                                    return {
                                        ...subRow,
                                        eventAlertMessageId: currentEvent.eventAlertMessageId,
                                        expression: currentEvent.expression,
                                        expressionString: this.logicalEventsService.getExpressionString(currentEvent),
                                        isAcknowledgeable: currentEvent.isAcknowledgeable,
                                        isActive: currentEvent.isActive && currentEvent.limitIsActive,
                                        limitIsActive: currentEvent.isActive,
                                        // name: currentEvent.name || '',
                                        resultVariableId: currentEvent.resultVariableId,
                                        resultVariableName: this.logicalEventsService.getResultVariableName(currentEvent.resultVariableId, currentDevice),
                                        savedStatus: currentEvent.savedStatus,
                                        state:
                                            currentEvent.savedStatus === EventSaveStatus.ERROR
                                                ? ''
                                                : currentEvent.isActive && currentEvent.limitIsActive
                                                ? this.logicalEventsService.getEventState(currentEvents, currentEvent)
                                                : 'NA',
                                        styles: {
                                            state: currentEvent.isActive && currentEvent.limitIsActive ? this.logicalEventsService.getStatusStyles(this.logicalEventsService.getEventState(currentEvents, currentEvent)) : '',
                                        },
                                        tooltipValue: {
                                            resultVariableName: currentDevice ? currentDevice.name ?? currentDevice.defaultName : '',
                                            expressionString: `${updateTitle} ${this.dataTypeService.reformatDate(new Date(currentEvent.updated))}`,
                                        },
                                        errorStatus: currentEvent.errorStatus,
                                        postIcons: this.logicalEventsService.getPostIcons(currentEvent, false, row, currentDevice),
                                        preIcons: this.logicalEventsService.getPreIcons(state.logicalEvents.find((e) => e.id === subRow.id)),
                                    };
                                }),
                            ],
                        };
                    }

                    const event = state.logicalEvents.find((e) => e.id === row.id);
                    console.log(event);
                    if (!event) {
                        return;
                    }
                    currentDevice = devices.find((d) => d.variables.find((v) => v.id === event.resultVariableId));

                    return {
                        ...row,
                        eventAlertMessageId: event.eventAlertMessageId,
                        expression: event.expression,
                        expressionString: this.logicalEventsService.getExpressionString(event),
                        isAcknowledgeable: event.isAcknowledgeable,
                        isActive: event.isActive && event.limitIsActive,
                        name: event.name || '',
                        resultVariableId: event.resultVariableId,
                        resultVariableName: this.logicalEventsService.getResultVariableName(event.resultVariableId, currentDevice),
                        savedStatus: event.savedStatus,
                        state: event.savedStatus === EventSaveStatus.ERROR ? '' : event.isActive && event.limitIsActive ? this.logicalEventsService.getEventState(currentEvents, event) : 'NA',
                        styles: {
                            state: event.isActive && event.limitIsActive ? this.logicalEventsService.getStatusStyles(this.logicalEventsService.getEventState(currentEvents, event)) : '',
                        },
                        tooltipValue: {
                            resultVariableName: currentDevice ? currentDevice.name ?? currentDevice.defaultName : '',
                            expressionString: `${updateTitle} ${this.dataTypeService.reformatDate(new Date(event.updated))}`,
                        },
                        errorStatus: event.errorStatus,
                        postIcons: this.logicalEventsService.getPostIcons(event, false, null, currentDevice),
                        preIcons: this.logicalEventsService.getPreIcons(event),
                        disabledActions: event.isActive ? [ColumnsActionTypeEnum.actionBtnsEdit] : [ColumnsActionTypeEnum.view],
                    };
                })
                .filter((row) => !!row),
        });
    }

    @Action(ToggleLogicalEventsAccordion)
    toggleLogicalEventsAccordion(ctx: StateContext<LogicalEventsStateModel>, payload: ToggleLogicalEventsAccordion): void {
        const state = ctx.getState();
        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        ctx.setState({
            ...state,
            eventsRows: state.eventsRows.map((row) => {
                const currentDevice = devices.find((d) => d.variables.find((v) => v.id === row.resultVariableId));
                if (row.accordionChildIds && JSON.stringify(row.accordionChildIds) === JSON.stringify(payload.childIds)) {
                    row.toggle = !row.toggle;
                } else {
                    return row;
                }

                return {
                    ...row,
                    accordionChildRows: row.accordionChildRows.map((childRows) => {
                        return {
                            ...childRows,
                            accordionChildHide: !childRows.accordionChildHide,
                        };
                    }),
                    postIcons: this.logicalEventsService.getPostIcons(null, true, row, currentDevice),
                };
            }),
        });
    }

    @Action(SetAlarmExpressionString)
    setAlarmExpression(ctx: StateContext<LogicalEventsStateModel>, payload: SetAlarmExpressionString): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            alarmExpressionString: payload.alarm,
        });
    }

    @Action(SetAttentionExpressionString)
    setAttentionExpression(ctx: StateContext<LogicalEventsStateModel>, payload: SetAttentionExpressionString): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            attentionExpressionString: payload.attention,
        });
    }
}

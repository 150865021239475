import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { GetReports } from 'src/app/reports/_store/actions/reports.actions';
import { ReportsState } from 'src/app/reports/_store/states/reports.state';
import { MailingGroupsEditBlockEnum } from '../../_enums/mailing-groups-edit-block.enum';
import { MailingAddEventItemInterface, MailingAddReportItemInterface } from '../../_interfaces/mailing-groups-edit.interface';
import {
    GroupEditReportsRowInterface,
    NotificationReportInterface,
    NotificationReportNotGroupInterface,
    NotificationReportResponseInterface,
} from '../../_interfaces/mailing-reports.interfaces';
import {
    AddMailingReport,
    DeleteReportInGroupEdit,
    GetMailingReport,
    InitGroupEditReportsPopupRows,
    InitMailingReportRows,
    UpdateActivityReportInGroupEdit,
} from '../actions/mailing-group-report.actions';

export interface MailingGroupReportStateModel {
    groupEditReports: NotificationReportResponseInterface;
    groupEditReportsRows: GroupEditReportsRowInterface[];
    groupEditReportsPopupRows: MailingAddReportItemInterface[];
}

const MAILING_GROUP_REPORT_STATE_TOKEN = new StateToken<MailingGroupReportStateModel>('MailingGroupReportState');

@State<MailingGroupReportStateModel>({
    name: MAILING_GROUP_REPORT_STATE_TOKEN,
    defaults: {
        groupEditReports: null,
        groupEditReportsRows: [],
        groupEditReportsPopupRows: [],
    },
})
@Injectable()
export class MailingGroupReportState {
    constructor(
        private http: HttpClient,
        private store: Store,
        private notificationsService: NotificationsService,
    ) {}

    @Selector()
    static getGroupEditReportRows(state: MailingGroupReportStateModel): GroupEditReportsRowInterface[] {
        return state.groupEditReportsRows;
    }

    @Selector()
    static getGroupEditReportsPopupRows(state: MailingGroupReportStateModel): MailingAddEventItemInterface[] {
        return state.groupEditReportsPopupRows;
    }

    @Action(GetMailingReport)
    async getMailingReport(ctx: StateContext<MailingGroupReportStateModel>, payload: GetMailingReport): Promise<void> {
        const result: ApiResponse<NotificationReportResponseInterface> = (await this.http
            .get('/api/notification-report', { params: { ...payload } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<NotificationReportResponseInterface>;
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                groupEditReports: result.data,
            });
        }

        await ctx.dispatch(new GetReports()).toPromise();
        ctx.dispatch(new InitMailingReportRows());
        ctx.dispatch(new InitGroupEditReportsPopupRows());
    }

    @Action(InitMailingReportRows)
    initMailingReportRows(ctx: StateContext<MailingGroupReportStateModel>): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            groupEditReportsRows: state.groupEditReports.reportIsInGroup.map((nReport) => {
                const currrentDevice = this.store.selectSnapshot(DevicesState.getDevices).find((d) => d.id === nReport.deviceId);
                const currentReport = this.store.selectSnapshot(ReportsState.getReportsList).find((r) => r.id === nReport.reportId);
                return {
                    ...nReport,
                    device: currrentDevice ? currrentDevice.name ?? currrentDevice.defaultName : '',
                    name: currentReport ? currentReport.nameReport : '',
                };
            }),
        });
    }

    @Action(InitGroupEditReportsPopupRows)
    initGroupEditEventsPopupRows(ctx: StateContext<MailingGroupReportStateModel>, payload: InitGroupEditReportsPopupRows): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            groupEditReportsPopupRows: state.groupEditReports.reportIsNotGroup.map((nReport: NotificationReportNotGroupInterface) => {
                return {
                    ...nReport,
                    checked: false,
                    type: MailingGroupsEditBlockEnum.reports,
                };
            }),
        });
    }

    @Action(AddMailingReport)
    async addMailingReport(ctx: StateContext<MailingGroupReportStateModel>, payload: AddMailingReport): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/notification-report', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                groupEditReports: {
                    ...state.groupEditReports,
                    reportIsInGroup: [result.data, ...state.groupEditReports.reportIsInGroup].filter((item) => !!item),
                },
            });
        }

        ctx.dispatch(new InitGroupEditReportsPopupRows());
    }

    @Action(DeleteReportInGroupEdit)
    async deleteReportInGroupEdit(ctx: StateContext<MailingGroupReportStateModel>, payload: DeleteReportInGroupEdit): Promise<void> {
        const result: ApiResponse<void> = (await this.http
            .delete('/api/notification-report', { params: { id: payload.id } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<void>;
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            const currentReportInInGroup = state.groupEditReports.reportIsInGroup.find((r) => r.id === payload.id);
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                groupEditReports: {
                    ...state.groupEditReports,
                    reportIsInGroup: state.groupEditReports.reportIsInGroup.filter((r) => r.id !== payload.id),
                    reportIsNotGroup: [
                        ...state.groupEditReports.reportIsNotGroup,
                        { id: payload.id, name: currentReportInInGroup.nameReport },
                    ],
                },
            });
        } else {
            ctx.setState({
                ...state,
            });

            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }

        ctx.dispatch(new InitMailingReportRows());
        ctx.dispatch(new InitGroupEditReportsPopupRows());
    }

    @Action(UpdateActivityReportInGroupEdit)
    async UpdateActivityReportInGroupEdit(
        ctx: StateContext<MailingGroupReportStateModel>,
        payload: UpdateActivityReportInGroupEdit,
    ): Promise<void> {
        const result: ApiResponse<NotificationReportInterface> = (await this.http
            .put('/api/notification-report', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<NotificationReportInterface>;
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                groupEditReports: {
                    ...state.groupEditReports,
                    reportIsInGroup: state.groupEditReports.reportIsInGroup.map((r) => (r.id === result.data.id ? result.data : r)),
                },
            });
        } else {
            ctx.setState({
                ...state,
            });

            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }

        ctx.dispatch(new InitMailingReportRows());
    }
}

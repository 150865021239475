import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    AddNewUser,
    ChangeDisabledAuthUser,
    ChangeUserActivity,
    ClearUsers,
    CloseEditingUserRow,
    ConfirmUser,
    CreateUser,
    DeleteUser,
    ExportUser,
    GetUsers,
    ImportUser,
    ImportUsers,
    InitUsersRows,
    RecoveryUser,
    SetEditingUserRow,
    SetUsersParams,
    ToggleImportPopup,
    UpdateUser,
    UpdateUserEmail,
} from '../actions/users.actions';
import { ApiResponse } from '../../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../../app-shared-elements/_enums/status.enum';
import { NotificationsService } from '../../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../../app-shared-elements/_enums/tooltip-status.enum';
import { Pagination, Params, ParamsSorted, ParamsSortedOrderEnum } from '../../../../app-shared-elements/_interfaces/params.interface';
import { DropdownFilterOptionInterface } from '../../../../app-shared-elements/filters/interfaces/filter-option.interface';
import { TypeFilterEnum } from '../../../../app-shared-elements/filters/enums/type-filter.enum';
import { usersFiltersCheckbox, usersFiltersDropdown } from '../../_data/users-data';
import { CloneDeepService } from '../../../../app-shared-elements/_services/clone-deep.service';
import { ColumnsTableInterface, ColumnTypeEnum } from '../../../../app-shared-elements/_interfaces/ColumnsTable';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { UsersRowInterface } from '../../_interfaces/users-row.interface';
import { UsersService } from '../../_services/users.service';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { ParamsService } from '../../../../app-shared-elements/_services/params.service';
import { CompanyState } from 'src/app/admin/company/_store/states/company.state';
import { GetCompanies } from 'src/app/admin/company/_store/actions/company.actions';
import { Router } from '@angular/router';
import { MailingCellFieldTypeEnum } from '../../../../mailing/_enums/mailing-cell-field-type.enum';
import { TableNamesEnum } from '../../../../app-shared-elements/_enums/table-names.enum';
import { DisabledTypeEnum } from '../../../../app-shared-elements/_enums/disabled-type.enum';

export interface UsersStateModel {
    users: User[];
    usersRows: UsersRowInterface[];
    userTableColumns: ColumnsTableInterface[];
    dropDownFilterOptions: DropdownFilterOptionInterface[];
    params: Params;
    isEditingMode: boolean;
    isShowExportPopup: boolean;
}

const USERS_TOKEN = new StateToken<UsersStateModel>('users');

const initUsersTableColumns: ColumnsTableInterface[] = [
    {
        title: 'table.users.act',
        grow: false,
        small: true,
        maxWidth: '96px',
        type: ColumnTypeEnum.icon,
        name: 'activeStatus',
    },
    {
        title: 'table.users.connect',
        maxWidth: '108px',
        minWidth: '108px',
        type: ColumnTypeEnum.text,
        name: 'status',
        styles: true,
    },
    {
        title: 'table.users.email',
        grow: true,
        small: false,
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.input,
        isInputCheckbox: true,
        name: 'login',
        isClick: true,
        preIcons: true,
        postIcons: true,
        styles: true,
        isReadOnly: true,
        tooltip: true,
    },
    {
        title: 'table.users.name',
        grow: true,
        small: false,
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.input,
        name: 'userName',
    },
    {
        title: 'table.users.phone',
        grow: true,
        small: false,
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.input,
        maxWidth: '180px',
        name: 'phone',
    },
    {
        title: 'table.users.company',
        grow: true,
        small: false,
        type: null,
        isCustomType: true,
        isFullWrapperBtn: true,
        maxWidth: '240px',
        name: 'company',
        isMultilang: true,
        optionsName: 'companyOptions',
        currentValueForSelect: 'companyName',
        placeholderForSelectColumn: 'company.chooseCompany',
    },
    {
        title: 'table.users.lastActiveDate',
        grow: false,
        small: false,
        type: ColumnTypeEnum.acknowledge,
        name: 'lastActive',
        sort: true,
        sortField: 'updated',
    },

    {
        title: 'table.users.registrationDate',
        grow: false,
        small: false,
        type: ColumnTypeEnum.date,
        name: 'created',
        sort: true,
        sortField: 'created',
    },
    {
        title: 'events.logicalEvents.table.actions',
        grow: false,
        small: true,
        maxWidth: '108px',
        minWidth: '108px',
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.btns,
        tableName: TableNamesEnum.usersListTable,
        name: 'edit',
        isFullWrapperBtn: true,
        actionBtns: [ColumnsActionTypeEnum.recovery, ColumnsActionTypeEnum.export, ColumnsActionTypeEnum.actionBtnsEdit, ColumnsActionTypeEnum.actionBtnsDelete],
    },
];

const initialConfigPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

const initialCurrentSort: ParamsSorted[] = [
    {
        property: 'created',
        order: ParamsSortedOrderEnum.DESC,
    },
];

const dropDownFilterOptions: DropdownFilterOptionInterface[] = [
    { key: 'login', value: 'users.filtersDropdown.login', type: TypeFilterEnum.text, property: 'login' },
    { key: 'name', value: 'users.filtersDropdown.name', type: TypeFilterEnum.text, property: 'name' },
    { key: 'phone', value: 'users.filtersDropdown.phone', type: TypeFilterEnum.text, property: 'phone' },
    { key: 'created', value: 'users.filtersDropdown.date', type: TypeFilterEnum.datetime, property: 'created' },
    { key: 'companyId', value: 'users.filtersDropdown.companyName', type: TypeFilterEnum.select, property: 'companyId' },
    { key: 'type', value: 'users.filtersDropdown.typeCreate', type: TypeFilterEnum.select, property: 'type' },
];

export const initialParams: Params = {
    pagination: initialConfigPagination,
    filter: [...usersFiltersCheckbox, ...usersFiltersDropdown],
    sorted: initialCurrentSort,
};

@State({
    name: USERS_TOKEN,
    defaults: {
        users: [],
        usersRows: [],
        userTableColumns: initUsersTableColumns,
        dropDownFilterOptions,
        params: initialParams,
        isEditingMode: false,
        isShowExportPopup: false,
    },
})
@Injectable()
export class UsersState {
    constructor(
        private http: HttpClient,
        private notificationsService: NotificationsService,
        private cloneDeepService: CloneDeepService,
        private usersService: UsersService,
        private paramsService: ParamsService,
        private store: Store,
        private router: Router,
    ) {}

    @Selector()
    static getUsers(state: UsersStateModel): User[] {
        return state.users;
    }

    @Selector()
    static getUsersRows(state: UsersStateModel): UsersRowInterface[] {
        return state.usersRows;
    }

    @Selector()
    static getUserTableColumns(state: UsersStateModel): ColumnsTableInterface[] {
        return state.userTableColumns;
    }

    @Selector()
    static getDropDownFilterOptions(state: UsersStateModel): DropdownFilterOptionInterface[] {
        return state.dropDownFilterOptions;
    }

    @Selector()
    static getParams(state: UsersStateModel): Params {
        return state.params;
    }

    @Selector()
    static getIsEditingMode(state: UsersStateModel): boolean {
        return state.isEditingMode;
    }

    @Selector()
    static getIsShowImportPopup(state: UsersStateModel): boolean {
        return state.isShowExportPopup;
    }

    @Action(GetUsers)
    async getUsers(ctx: StateContext<UsersStateModel>, payload: GetUsers): Promise<void> {
        const state = ctx.getState();

        const params: Params = {
            ...state.params,
            pagination: {
                ...state.params.pagination,
                itemsPerPage: payload.total ? 99999999 : state.params.pagination.itemsPerPage,
            },
            filter: this.paramsService.parseParamsFilterForServer(state.params.filter),
            sorted: state.params.sorted && state.params.sorted.length ? state.params.sorted : initialCurrentSort,
        };

        const companyIdFilter = params.filter.find((f) => f.property === 'companyId');
        this.router.navigate([], {
            queryParams: {
                companyId: companyIdFilter && companyIdFilter.value ? companyIdFilter.value : null,
            },
            queryParamsHandling: 'merge',
        });

        const result: ApiResponse = (await this.http
            .get('/api/control/users', { headers: { params: encodeURIComponent(JSON.stringify(params)) } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                users: result.data.items,
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.total,
                    },
                },
            });

            await ctx.dispatch(new InitUsersRows()).toPromise();
        }
    }

    @Action(InitUsersRows)
    async initUsersRows(ctx: StateContext<UsersStateModel>): Promise<void> {
        const state = ctx.getState();
        const companies = this.store.selectSnapshot(CompanyState.getCompanies);
        if (!companies || !companies.length) {
            await this.store.dispatch(new GetCompanies()).toPromise();
        }
        // console.log(state.usersRows);
        ctx.setState({
            ...state,
            usersRows: this.usersService.initTable(state.users, this.store.selectSnapshot(CompanyState.getCompanies)),
            isEditingMode: false,
        });
    }

    @Action(DeleteUser)
    async deleteUser(ctx: StateContext<UsersStateModel>, payload: DeleteUser): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .delete('/api/control/users/delete', {
                params: {
                    id: payload.id,
                },
            })
            .toPromise()
            .catch((e) => e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                users: state.users.filter((u) => u.id !== payload.id),
                usersRows: state.usersRows.filter((u) => u.id !== payload.id),
            });
        }

        await ctx.dispatch(new GetUsers()).toPromise();
    }

    @Action(ChangeUserActivity)
    async changeUserActivity(ctx: StateContext<UsersStateModel>, payload: ChangeUserActivity): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post('/api/control/users/change-active', { ...payload.data })
            .toPromise()
            .catch((e) => e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                users: state.users.map((u) => (u.id === result.data.id ? result.data : u)),
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }

        await ctx.dispatch(new GetUsers()).toPromise();
    }

    @Action(ConfirmUser)
    async confirmUser(ctx: StateContext<UsersStateModel>, payload: ConfirmUser): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post(`/api/control/users/confirm`, { id: payload.id })
            .toPromise()
            .catch((e) => e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,

                users: state.users.map((u) => (u.id === result.data.id ? result.data : u)),
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }

        await ctx.dispatch(new InitUsersRows()).toPromise();
    }

    @Action(SetUsersParams)
    setUsersParams(ctx: StateContext<UsersStateModel>, payload: SetUsersParams): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            params: this.cloneDeepService.cloneObject(payload.params),
        });

        ctx.dispatch(new GetUsers()).toPromise();
    }

    @Action(ClearUsers)
    clearUsers(ctx: StateContext<UsersStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            users: [],
            usersRows: [],
            params: initialParams,
        });
    }

    @Action(RecoveryUser)
    async recoveryUser(ctx: StateContext<UsersStateModel>, payload: RecoveryUser): Promise<void> {
        const result: ApiResponse = (await this.http
            .post(`/api/control/users/recovery`, { id: payload.id })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                users: state.users.map((u) => (u.id === result.data.id ? result.data : u)),
            });

            await ctx.dispatch(new GetUsers()).toPromise();
        }
    }

    @Action(CreateUser)
    async createUser(ctx: StateContext<UsersStateModel>, payload: CreateUser): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/control/users/create-user-manually', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
                users: [result.data, ...state.users],
                usersRows: [
                    {
                        ...result.data,
                        isEditing: false,
                        require: {
                            email: true,
                        },
                    },
                    ...state.usersRows.filter((row) => !row.isNew),
                ],
                isEditingMode: false,
            });
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }

        await ctx.dispatch(new GetUsers()).toPromise();
    }

    @Action(AddNewUser)
    async addNewUser(ctx: StateContext<UsersStateModel>): Promise<void> {
        const state = ctx.getState();
        const companies = this.store.selectSnapshot(CompanyState.getCompanies);

        if (!companies || !companies.length) {
            await this.store.dispatch(new GetCompanies()).toPromise();
        }

        const emptyUser: UsersRowInterface = {
            disabledActions: null,
            lastActive: {
                ts: null,
                isAcknowledgeable: false,
                acknowledgedByUser: '',
                title: '',
            },
            userName: '',
            preIcons: null,
            postIcons: null,
            columnType: ColumnTypeEnum.select,
            companyOptions: this.usersService.getCompanyOptions(companies),
            companyName: '',
            login: '',
            phone: '',
            isActive: true,
            status: 'Na',
            id: '',
            isEditing: true,
            isNew: true,
            require: {
                login: true,
                userName: true,
            },
            disabledType: DisabledTypeEnum.usersTable,
        };

        ctx.setState({
            ...state,
            usersRows: [emptyUser, ...state.usersRows],
            isEditingMode: true,
        });
    }

    @Action(UpdateUser)
    async updateUser(ctx: StateContext<UsersStateModel>, payload: UpdateUser): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/control/users/update-user-manually', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                users: [result.data, ...state.users],
                usersRows: [
                    {
                        ...result.data,
                        isEditing: false,
                        status: result.data.isOnline ? 'online' : 'offline',
                    },
                    ...state.usersRows.filter((row) => row.id !== result.data.id),
                ],
                isEditingMode: false,
            });

            await ctx.dispatch(new GetUsers()).toPromise();

            return;
        }

        ctx.setState({
            ...state,
            usersRows: state.usersRows.filter((row) => !row.isNew),
        });
    }

    @Action(SetEditingUserRow)
    setEditingUserRow(ctx: StateContext<UsersStateModel>, payload: SetEditingUserRow): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            usersRows: state.usersRows.map((row) => ({ ...row, isEditing: row.id === payload.id })),
            isEditingMode: true,
        });
    }

    @Action(CloseEditingUserRow)
    closeEditingUserRow(ctx: StateContext<UsersStateModel>, payload: CloseEditingUserRow): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            usersRows: state.usersRows.map((row) => ({
                ...(payload.savedRow && payload.savedRow.id === row.id ? payload.savedRow : row),
                isEditing: false,
            })),
            isEditingMode: false,
        });

        ctx.dispatch(new GetUsers());
    }

    @Action(ImportUsers)
    async importUsers(ctx: StateContext<UsersStateModel>, payload: ImportUsers): Promise<void> {
        const fd = new FormData();
        fd.append('file', payload.file);

        const result: ApiResponse = (await this.http
            .post('/api/control/users/create-user-csv', fd)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                users: [result.data, ...state.users],
            });
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }

        ctx.dispatch(new GetUsers());
    }

    @Action(UpdateUserEmail)
    async updateUserEmail(ctx: StateContext<UsersStateModel>, payload: UpdateUserEmail): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/control/users/update-user-login', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
                users: [result.data, ...state.users],
                usersRows: [
                    {
                        ...result.data,
                        isEditing: false,
                        require: {
                            email: true,
                        },
                    },
                    ...state.usersRows.filter((row) => row.id !== result.data.id),
                ],
                isEditingMode: false,
            });
        }
    }

    @Action(ImportUser)
    async importUser(ctx: StateContext<UsersStateModel>, payload: ImportUser): Promise<void> {
        const fd = new FormData();
        fd.append('file', payload.file);
        const result: ApiResponse = (await this.http
            .post(`/api/control/export-import-user/import`, fd, {
                params: {
                    password: payload.password,
                    requestId: payload.requestId,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        // console.log(result);

        if (result && result.status === HTTP_STATUS.DATA_ALREADY_EXISTS) {
            this.notificationsService.onEmit(TooltipStatusEnum.error, true, 'importExport.user.userExist');
            ctx.dispatch(new ToggleImportPopup(false));
        }

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
            });

            ctx.dispatch(new InitUsersRows());
        }
    }

    @Action(ExportUser)
    async exportUser(ctx: StateContext<UsersStateModel>, payload: ExportUser): Promise<void> {
        const result = await this.http
            .get('/api/control/export-import-user/export', {
                responseType: 'blob',
                observe: 'response',
                params: {
                    userId: payload.userId,
                    password: payload.password,
                    requestId: payload.requestId,
                },
            })
            .toPromise()
            .catch((e) => console.log(e));

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.OK) {
            const url = window.URL.createObjectURL(result.body);
            const a = document.createElement('a');
            a.href = url;
            a.download = result.headers.get('content-disposition').split('filename=')[1];
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();

            ctx.setState({
                ...state,
            });
        }
    }

    @Action(ToggleImportPopup)
    toggleImportPopup(ctx: StateContext<UsersStateModel>, payload: ToggleImportPopup): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isShowExportPopup: payload.toggle,
        });
    }

    @Action(ChangeDisabledAuthUser)
    async changeDisabledAuthUser(ctx: StateContext<UsersStateModel>, payload: ChangeDisabledAuthUser): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/control/users/disable-auth', payload.data)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
            });

            ctx.dispatch(new InitUsersRows());
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }
    }
}

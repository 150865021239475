import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContainerService {

    public wrapperHeight: BehaviorSubject<number> = new BehaviorSubject(null);


    constructor() { }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class TableGroupService {
    constructor(private translateService: TranslateService) {}

    async getTitle(title: string): Promise<string> {
        return await this.translateService.get(title).toPromise();
    }

    convertToCSV(objArray: any): any {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        for (let i = 0; i < array.length; i++) {
            let line = '';
            // tslint:disable-next-line:forin
            for (const index in array[i]) {
                if (line !== '') {
                    line += ',';
                }

                line += array[i][index];
            }

            str += line + '\r\n';
        }

        return str;
    }
}

import { TypeClient } from '../../../admin/users/_enum/type-client.enum';
import { User } from '../../../app-shared-elements/_interfaces/user.interface';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatePassword } from 'src/app/auth/_helpers/strong-validator-password';
import { Select, Store } from '@ngxs/store';
import { ChangeUserPassword, ChangeUserUpdateValue, ReConfirmTransportNotification, UpdateAdmin, UpdateUser } from 'src/app/app-shared-elements/_store/actions/user.actions';
import { UserProfileInfoTypeElementEnum, UserProfileinfoTypeEnum } from '../../_enums/user-profile-info-type.enum';
import { VariableValueTypeEnum } from 'src/app/groups/_enums/variable-value-type.enum';
import { SelectTypeEnum } from 'src/app/app-shared-elements/_enums/select-type.enum';
import { ProfileBlocksType, UserProfileInfo } from 'src/app/app-shared-elements/_interfaces/UserInfo';
import { DataTypeService } from 'src/app/app-shared-elements/_services/data-type.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LangEnum } from 'src/app/app-shared-elements/_enums/lang.enum';
import { ChangeLanguage } from '../../../app-shared-elements/_store/actions/language.actions';
import { TranslateService } from '@ngx-translate/core';
import { SocketService } from '../../../app-shared-elements/_services/socket.service';
import { ProfileState } from '../../_store/states/profile.state';
import { GenerationAuthenticationKey, UnBindAuthenticationKey, VerificationAuthenticationKey } from '../../_store/actions/authentication.actions';
import { AuthenticationState } from '../../_store/states/authentication.state';
import { StatusTwoFactorAuthenticationEnum } from '../../../app-shared-elements/_enums/status-two-factor-authentication.enum';
import { StateReset } from 'ngxs-reset-plugin';
import { UserState } from 'src/app/app-shared-elements/_store/states/user.state';
import { LanguageState } from '../../../app-shared-elements/_store/states/language.state';
import { ConfigurationState } from '../../../app-shared-elements/_store/states/configuration.state';
import { ConfigurationServerInterface } from '../../../app-shared-elements/_interfaces/configuration-server.interface';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { ProfileService } from '../../_services/profile.service';
import { NotificationTransportResourceEnum, UserNotificationTransport } from '../../_interfaces/user-notification-transport.interface';
import { UserNotificationTransportStatusEnum } from '../../_enums/user-notification-transport-status.enum';
import { NotificationTransportType } from 'src/app/mailing/_interfaces/notification-log.interface';
import { UpdateCurrentAdminDtoInterface, UpdateUserDtoInterface } from '../../_interfaces/update-user-dto.interface';
import { ValidationService } from 'src/app/app-shared-elements/_services/validation.service';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { SetCurrentProfileBlock } from '../../_store/actions/profile-blocks.actions';
import { ProfileBlocksState } from '../../_store/states/profile-blocks.state';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-user-profile-info',
    templateUrl: './user-profile-info.component.html',
    styleUrls: ['./user-profile-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileInfoComponent implements OnInit {
    @Select(ProfileState.getProfileUser) profileUser$: Observable<User>;
    @Select(UserState.getUser) currentUser$: Observable<User>;

    @Input() gmt: string;
    @Input() userInfo: UserProfileInfo[] = [];
    @Input() userInfoMailing: UserNotificationTransport[] = [];
    @Input() userInfoSystem: UserProfileInfo[] = [];
    @Input() ln: LangEnum;
    @Input() isEditable: boolean;

    @Output() isCanMoveTabEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Select(AuthenticationState.getQrSecret) qrSecret$: Observable<string>;
    @Select(AuthenticationState.getSecret) secret$: Observable<string>;
    @Select(ConfigurationState.getConfigurationServer) configuration$: Observable<ConfigurationServerInterface>;
    @Select(UserState.getUserTimezoneGMT) gmt$: Observable<string>;
    @Select(LanguageState.getLanguage) ln$: Observable<LangEnum>;
    @Select(ProfileBlocksState.getBlocks) blocks$: Observable<{ [property in ProfileBlocksType]: boolean }>;

    @Select(UserState.getUserUpdateValues) updatedValues$: Observable<UpdateUserDtoInterface>;

    user: User;

    currentLn: SelectOptionInterface<LangEnum>;

    isEditMode = false;

    isEmptyForm;
    strength = -1;
    newValue;
    modelPassword = '';
    repeatPassword = '';
    isVisiblePassword = false;
    popupChangePassword = false;
    popupAuthentication = false;
    openVerification = false;
    popupDeleteVerification = false;
    qrCodePopup = false;
    isDisabledSave = true;
    private changedLn: LangEnum;

    verificationCode = '';
    deleteCode = '';
    linkForQrCode = '';

    formPassword: FormGroup;
    errorRepeat: boolean;

    @Input() type: TypeClient;

    variableValueTypeEnum = VariableValueTypeEnum;

    userProfileInfoTypeEnum = UserProfileinfoTypeEnum;
    selectTypeEnum = SelectTypeEnum;
    userProfileInfoTypeElementEnum = UserProfileInfoTypeElementEnum;
    userNotificationTransportStatusEnum = UserNotificationTransportStatusEnum;
    notificationTransportType = NotificationTransportType;
    notificationTransportResourceEnum = NotificationTransportResourceEnum;

    readonly ACT_ICON_PATH = './assets/design/icons/table-operations/activity.svg';
    readonly CHANGE_PASSWORD_ICON_PATH = './assets/design/icons/users/change-password.svg';
    readonly TWO_FACTOR_ICON_PATH = './assets/design/icons/users/two-factor-profile.svg';
    readonly ARROW_ICON_PATH = './assets/design/icons/nav/nav-arrow.svg';
    readonly ARROW_ICON_UP = './assets/design/icons/group/arrow-up.svg';
    readonly ARROW_ICON_DOWN = '/assets/design/icons/group/arrow-down.svg';

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private formBuilder: FormBuilder,
        private profileService: ProfileService,
        public dataTypeService: DataTypeService,
        private socketService: SocketService,
        private ngZone: NgZone,
        private translateService: TranslateService,
        private store: Store,
        private validationService: ValidationService,
        private notficationsService: NotificationsService,
        public router: Router,
        private config: PrimeNGConfig,
    ) {}

    ngOnInit(): void {
        this.isCanMoveTabEvent.emit(true);
        this.profileUser$.pipe(takeUntil(this.destroy)).subscribe((user) => {
            if (!user || this.isEditMode) {
                return;
            }
            this.user = user;
            this.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    // this.setUserInfo(user);
                }, 300);
            });
        });
    }

    changeData(type: UserProfileinfoTypeEnum, value: any): void {
        const data = this.store.selectSnapshot(UserState.getUserUpdateValues);

        this.store.dispatch(
            new ChangeUserUpdateValue({
                ...data,
                [type]: value,
            }),
        );
    }

    changeTransport(type: NotificationTransportType, value: string): void {
        const data = this.store.selectSnapshot(UserState.getUserUpdateValues);

        this.store.dispatch(
            new ChangeUserUpdateValue({
                ...data,
                notification: data.notification.map((n) => {
                    if (n.transportType === type) {
                        return {
                            ...n,
                            transport: value,
                            isValid: true,
                        };
                    }
                    return { ...n, isValid: true };
                }),
            }),
        );
    }

    async changePassword(): Promise<any> {
        if (this.formPassword.value.password !== this.formPassword.value.repeat) {
            this.errorRepeat = true;
            return;
        }

        if (this.formPassword.status === 'VALID') {
            const data: { id: number | string; password: string } = {
                id: this.user.id,
                password: this.formPassword.value.password.trim(),
            };
            await this.store.dispatch(new ChangeUserPassword(data, this.type)).toPromise();
            this.strength = -1;
        }

        this.formPassword.value.password === '' || this.formPassword.value.repeat === '' ? (this.isEmptyForm = true) : (this.isEmptyForm = false);

        this.popupChangePassword = false;
        this.errorRepeat = false;
        this.socketService.logoutSubscribe.emit();

        return;
    }

    showPasswordPopup(): void {
        if (this.isEditMode) {
            return;
        }

        this.repeatPassword = '';
        this.modelPassword = '';
        this.popupChangePassword = true;
        this.formPassword = new FormGroup({
            password: new FormControl('', [Validators.required, ValidatePassword.bind(this)]),
            repeat: new FormControl('', Validators.required),
        });
    }

    lookChangePasswordInput(): void {
        if (!this.modelPassword.length) {
            this.strength = 0;
        }
    }

    openAuthentication(): void {
        switch (this.store.selectSnapshot(UserState.getUser).statusTwoFactorAuthentication) {
            case StatusTwoFactorAuthenticationEnum.ENABLED:
                this.popupDeleteVerification = true;
                return;
            case StatusTwoFactorAuthenticationEnum.DISABLED:
            case StatusTwoFactorAuthenticationEnum.IN_VERIFICATION:
                this.popupAuthentication = true;
                return;
        }
    }

    async closeAuthenticationPopup(): Promise<void> {
        this.store.dispatch(new StateReset(AuthenticationState));
        await this.socketService.getUserRequest();
        this.popupAuthentication = false;
        this.openVerification = false;
        this.verificationCode = '';
    }

    async closeDeleteVerificationPopup(): Promise<void> {
        await this.socketService.getUserRequest();
        this.popupDeleteVerification = false;
    }

    async generation(): Promise<void> {
        await this.store.dispatch(new GenerationAuthenticationKey());
        this.openVerification = true;
    }

    async verificationKey(): Promise<void> {
        await this.store.dispatch(new VerificationAuthenticationKey(this.verificationCode));
        await this.closeAuthenticationPopup();
    }

    async unBindToken(): Promise<void> {
        await this.store.dispatch(new UnBindAuthenticationKey(this.deleteCode));
        this.popupDeleteVerification = false;
        this.deleteCode = '';
    }

    async changeTransportActive(event: UserNotificationTransport, value: boolean): Promise<void> {
        if (!event?.transport) {
            return;
        }
        const data = this.store.selectSnapshot(UserState.getUserUpdateValues);
        const user = this.store.selectSnapshot(UserState.getUser);
        this.isCanMoveTabEvent.emit(true);

        await this.store
            .dispatch(
                new UpdateUser({
                    name: user.name,
                    phone: user.phone,
                    timeBlocked: user.timeBlocked,
                    sessionLife: user.sessionLife,
                    dateFormat: user.dateFormat,
                    dateTimeZone: user.dateTimeZone,
                    isAlarmSound: user.isAlarmSound,
                    lang: user.lang,
                    notification: [
                        ...data.notification.map((n) => {
                            if (n.transportType === event.transportType) {
                                return {
                                    ...n,
                                    isActive: value,
                                };
                            }
                            return n;
                        }),
                    ],
                }),
            )
            .toPromise();
    }

    changeResource(type: NotificationTransportType, resource: NotificationTransportResourceEnum, value: boolean): void {
        const data = this.store.selectSnapshot(UserState.getUserUpdateValues);

        this.store.dispatch(
            new ChangeUserUpdateValue({
                ...data,
                notification: data.notification.map((n) => {
                    if (n.transportType === type) {
                        return {
                            ...n,
                            resource: value ? Array.from(new Set([...n.resource, resource])) : n.resource.filter((r) => r !== resource),
                        };
                    }
                    return { ...n, isValid: true };
                }),
            }),
        );
    }

    async changeSystemData(value: string | LangEnum, type: UserProfileinfoTypeEnum): Promise<void> {
        if (type === UserProfileinfoTypeEnum.lang) {
            this.changedLn = value as LangEnum;
        }

        const data = this.store.selectSnapshot(UserState.getUserUpdateValues);
        this.store.dispatch(
            new ChangeUserUpdateValue({
                ...data,
                [type]: value,
            }),
        );
    }

    async repeatConfirm(item: UserNotificationTransport): Promise<void> {
        this.store.dispatch(new ReConfirmTransportNotification(item.transportType));
    }

    async updateUser(): Promise<void> {
        const data: UpdateUserDtoInterface = this.store.selectSnapshot(UserState.getUserUpdateValues);
        const userProfileInfo: UserProfileInfo[] = this.store.selectSnapshot(ProfileState.getUserProfileInfo);
        const userMailingInfo: UserNotificationTransport[] = this.store.selectSnapshot(ProfileState.getUserProfileInfoMailing);
        this.isCanMoveTabEvent.emit(true);

        if (this.profileService.isNoValid(data, userProfileInfo, userMailingInfo)) {
            this.notficationsService.onEmit(TooltipStatusEnum.error, false, 'profile.inValidData');
            return;
        }

        if (this.changedLn) {
            this.translateService.use(this.changedLn);
            this.translateService.get('primeng').subscribe((res) => this.config.setTranslation(res));
            this.store.dispatch(new ChangeLanguage(this.changedLn));
            localStorage.setItem('ln', this.changedLn.toLowerCase());
            this.changedLn = null;
        }

        if (this.type === TypeClient.User) {
            await this.store.dispatch(new UpdateUser(data)).toPromise();
        } else {
            const adminData: UpdateCurrentAdminDtoInterface = {
                name: data.name,
                phone: data.phone,
                dateFormat: data.dateFormat ?? this.user.dateFormat,
                dateTimeZone: data.dateTimeZone ?? this.user.dateTimeZone,
                timeBlocked: +data.timeBlocked,
                sessionLife: +data.sessionLife,
            };

            await this.store.dispatch(new UpdateAdmin(adminData)).toPromise();
        }
    }

    generateQrCode(type: NotificationTransportType): void {
        const serverConfiguration = this.store.selectSnapshot(ConfigurationState.getConfigurationServer);

        switch (type) {
            case NotificationTransportType.TELEGRAM:
                this.linkForQrCode = 'https://t.me/' + serverConfiguration.telegramBotName;
                break;
            case NotificationTransportType.VIBER:
                this.linkForQrCode = 'https://t.me/' + serverConfiguration.telegramBotName;
                break;
        }

        this.qrCodePopup = true;
    }

    setCurrentBlock(property: ProfileBlocksType): void {
        this.store.dispatch(new SetCurrentProfileBlock(property));
    }

    checkNodes(event): void {}
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserSensorInterface } from '../_interface/user-devices.interface';
import { AlarmTypeEnum } from '../../../events/_enums/alarm.enum';
import { DataTypeService } from '../../../app-shared-elements/_services/data-type.service';
import { Select } from '@ngxs/store';
import { UserState } from '../../../app-shared-elements/_store/states/user.state';
import { Observable } from 'rxjs';
import { LanguageState } from '../../../app-shared-elements/_store/states/language.state';
import { LangEnum } from '../../../app-shared-elements/_enums/lang.enum';
import { User } from '../../../app-shared-elements/_interfaces/user.interface';
import { DeviceTypeEnum } from '../../../app-shared-elements/_enums/device-type.enum';
import { UserDeviceIconEnum } from '../_enum/user-device-icon.enum';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { ActionListItemInterface } from 'src/app/app-shared-elements/_interfaces/action-list-item.interface';
import { TypeClient } from 'src/app/admin/users/_enum/type-client.enum';
import { AuthState } from '../../../auth/_store/states/auth.state';
import { Router } from '@angular/router';
import { ActionListItemEnum } from '../../../app-shared-elements/_enums/action-list-item.enum';
import { EventSaveStatus } from '../../../events/_enums/event-save-state.enum';

@Component({
    selector: 'app-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesComponent implements OnInit {
    @Select(UserState.getUser) user$: Observable<User>;
    @Select(UserState.getUserTimezoneGMT) gmt$: Observable<string>;
    @Select(LanguageState.getLanguage) ln$: Observable<LangEnum>;
    @Select(AuthState.getIsAdmin) isAdmin$: Observable<boolean>;

    @Input() device: UserSensorInterface;
    @Input() registrator: UserSensorInterface;
    @Input() typeClient: TypeClient;
    @Input() isShowFilter: boolean;

    typeClientModel = TypeClient;

    @Output() openDeviceEvent: EventEmitter<UserSensorInterface> = new EventEmitter<Device>();
    @Output() openCurrentUserEvent: EventEmitter<UserSensorInterface> = new EventEmitter<Device>();
    @Output() moveToSmthEvent: EventEmitter<UserSensorInterface> = new EventEmitter<Device>();
    @Output() parseActionEvent: EventEmitter<{
        event: ActionListItemInterface;
        device: UserSensorInterface;
    }> = new EventEmitter<{
        event: ActionListItemInterface;
        device: UserSensorInterface;
    }>();

    alarmTypeEnum = AlarmTypeEnum;
    deviceTypeEnum = DeviceTypeEnum;
    userDeviceIconEnum = UserDeviceIconEnum;
    eventSaveStatus = EventSaveStatus;
    actionListItemEnum = ActionListItemEnum;

    readonly WAIT_ICON_PATH = './assets/design/icons/devices/wait.svg';
    readonly ERROR_ICON_PATH = './assets/design/icons/devices/error.svg';

    constructor(
        public dataTypeService: DataTypeService,
        public router: Router,
    ) {}

    ngOnInit(): void {}

    openDevice(device: UserSensorInterface): void {
        this.openDeviceEvent.emit(device);
    }

    openCurrentUser(device: UserSensorInterface): void {
        this.openCurrentUserEvent.emit(device);
    }

    moveToSmth(device: UserSensorInterface): void {
        this.moveToSmthEvent.emit(device);
    }

    parseAction(event: ActionListItemInterface, device: UserSensorInterface): void {
        this.parseActionEvent.emit({ event, device });
    }
}

import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { DetailDeviceSensorInterface, DetailsDeviceRows } from '../../_interface/details-device.interface';
import { UpdateAlarmSettingsInterface } from '../../_interface/update-alarm-settings.interface';

export class InitDetailsDeviceOptions {
    static readonly type = '[DetailsDevice] InitDetailsDeviceOptions';
}

export class InitDetailsDeviceObject {
    static readonly type = '[DetailsDevice] InitDetailsDeviceObject';

    constructor(public registratorId: string) {}
}

export class UpdateDetailsDevice {
    static readonly type = '[DetailsDevice] UpdateDetailsDevice';

    constructor(public devices: Device[]) {}
}

export class InitDetailsDeviceRegistratorRow {
    static readonly type = '[DetailsDevice] InitDetailsDeviceRegistratorRow';

    constructor() {}
}

export class InitDetailsDeviceSensorRow {
    static readonly type = '[DetailsDevice] InitDetailsDeviceSensorRow';

    constructor(public currentSensorId: string) {}
}

export class InitDetailsDeviceCoordinatorRow {
    static readonly type = '[DetailsDevice] InitDetailsDeviceCoordinatorRow';

    constructor(public currentCoordinatorId: string) {}
}

export class InitDetailsDeviceDataloggerRow {
    static readonly type = '[DetailsDevice] InitDetailsDeviceDataloggerRow';

    constructor(public currentDataloggerId: string) {}
}

export class UpdateDetailsDeviceRow {
    static readonly type = '[DetailsDevice] UpdateDetailsDeviceRow';

    constructor(public row: DetailsDeviceRows) {}
}

export class ClearDetailsDevice {
    static readonly type = '[DetailsDevice] ClearDetailsDevice';
}

export class SetIsChangeModeDetailDevice {
    static readonly type = '[DetailsDevice] SetIsChangeModeDetailDevice';

    constructor(public value: boolean) {}
}

export class SetCurrentAction {
    static readonly type = '[DetailsDevice] SetCurrentAction';

    constructor(public device?: DetailDeviceSensorInterface, public value?: boolean) {}
}

export class GetRoleInfoRows {
    static readonly type = '[DetailsDevice] GetRoleInfoRows';
}

export class UpdateDetailsDeviceUnit {
    static readonly type = '[DetailsDevice] UpdateDetailsDeviceUnit';

    constructor(public data: { variableId: string; unitId: string }) {}
}

export class SetRegistratorStats {
    static readonly type = '[DetailsDevice] SetDeviceStats';
}

export class UpdateDeviceAlarmSettings {
    static readonly type = '[DetailsDevice] UpdateDeviceAlarmSettings';

    constructor(public data: UpdateAlarmSettingsInterface) {}
}


export class GetSettingsList {
    static readonly type = '[ExportImport] GetSettingsList';
}

export class GetImportSettings {
    static readonly type = '[ExportImport] GetImport';

    constructor(public object: {}) {
    }
}

export class GetExportSettings {
    static readonly type = '[ExportImport] GetExportSettings';
    constructor(public list: string[]) {
    }
}

export class SetImportFile {
    static readonly type = '[ExportImport] SetImportFile';
    constructor(public file: File) {
    }
}

export class ClearImportState {
    static readonly type = '[ExportImport] ClearImportState';
}

export class ChangeExportSettings {
    static readonly type = '[ExportImport] ChangeExportSettings';
    constructor(public list: string[]) {

    }
}


import { ComponentRef, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentsGeneratorService } from '../_services/components-generator.service';
import { CanDeactivatePopapComponent } from '../can-deactivate-popap/can-deactivate-popap.component';
import { PreloaderWrappersEnum } from '../_enums/preloader-wrappers.enum';
import { first } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { SetCurrentTabByPath } from '../_store/actions/tabs.actions';
import { AuthService } from '../../auth/_services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class RouteGuardService {
    private popup: ComponentRef<CanDeactivatePopapComponent>;

    constructor(
        private componentsGeneratorService: ComponentsGeneratorService,
        private router: Router,
        private store: Store,
        private authService: AuthService,
    ) {}

    async canDeactivate(component): Promise<boolean> {
        if (!component.isCanMoveTab && (await this.authService.isAuthenticated())) {
            const newComponent = await this.componentsGeneratorService.create(CanDeactivatePopapComponent, PreloaderWrappersEnum.content);
            this.popup = newComponent as ComponentRef<CanDeactivatePopapComponent>;

            const result = await new Promise<boolean>((res) => {
                this.popup.instance.eventEmit.pipe(first()).subscribe((value) => res(value));
            });

            await this.popup.destroy();
            this.popup = null;

            if (!result) {
                this.store.dispatch(new SetCurrentTabByPath(this.router.url));
            }
            return result;
        }
        return true;
    }
}

export enum ObjectTypeEnum {
    all = 'all',
    text = 'text',
    iText = 'i-text',

    circle = 'circle',
    defaultImage = 'default-image',
    image = 'image',
    path = 'path',
    chart = 'chart',
    ellipse = 'ellipse',
    group = 'group',
    rect = 'rect',
    canvas = 'canvas',

    line = 'line',
}

import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { Device } from '../../_interfaces/Device';
import { SocketService } from '../socket.service';
import { ActiveEvents } from '../../../events/_interfaces/ActiveEvents';
import { SocketEvent } from '../../_enums/socket-event.enum';
import { DeviceTypeEnum } from '../../_enums/device-type.enum';
import { HTTP_STATUS } from '../../_enums/status.enum';
import { ApiResponse } from '../../_interfaces/ApiRequest';
import { ConnectEnum } from '../../_enums/connect.enum';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UsersService } from '../../../admin/users/_services/users.service';
import { User } from '../../../app-shared-elements/_interfaces/user.interface';
import { CoreValueVariable, Variable } from '../../_interfaces/Variable';
import { Select, Store } from '@ngxs/store';
import { ActiveEventsState } from '../../_store/states/active-events.state';
import { DeleteDeviceFromWs, SetDeviceFromWs } from 'src/app/device-dashboard/_store/actions/user-devices.actions';
import { TypeClient } from 'src/app/admin/users/_enum/type-client.enum';
import { CreationType, RegistratorSyncStatus } from '../../_enums/registrator-sync-status.enu';
import { UserState } from '../../_store/states/user.state';
import { QueueService } from '../queue.service';
import { PerformDeviceService } from './perform-device.service';
import { ActiveEventsService } from '../../../events/_services/active-events.service';
import { VariablesNameEnum } from '../../_enums/variables-name.enum';

export type ReqInfoInterface = {
    isArchiveLoading: boolean;
    minTs?: number;
    maxTs?: number;
    result?: number;
    devicesInternalId: string[];
};

export type DeviceInformation = {
    registratorId: string;
    isConnect?: boolean;
    syncStatus?: RegistratorSyncStatus;
    isArchiveLoading?: boolean;
    ts: number;
    daysArchivesLeft?: number;
    currentInternalDevicesId?: string[];
    requestInfo?: ReqInfoInterface;
    loadArchiveDevicesInternalId?: string[];
    ids?: string[]; // connected devices internal id
    isWorkExpedition?: boolean;

    isAuthorize?: true | false | undefined;
};

export enum TypeProcessDevices {
    DEVICE = 'device',
    VARIABLE = 'variable',
    DELETE_DEVICE = 'delete_device',
    DEVICE_INFORMATION = 'device_information',
}

export class DevicePerformanceInterface {
    count: number;
    time: number;
}

export type QueueProcessDevices = {
    type: TypeProcessDevices;
    variables?: Variable[];
    devices?: Device[];
    deviceInformation?: DeviceInformation[];
    deleteId?: string;
    isForce: boolean;
    isForceEmit: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class DeviceDynamicUpdateService {
    private subscribes: Subscription[] = [];

    private storageDevices: Device[] = [];

    private activePromiseAllDevices: Promise<Device[]>;

    private timeoutEmmit;

    private readonly timeoutInterval = 1000;
    private readonly intervalConnectCheck = 3000;
    private activeEvents: ActiveEvents[] = [];
    emitDevicesId = new Set<string>();

    private readonly cacheTime = 1000 * 60 * 60; // ms

    private intervalCheckActiveSubscribe: Subscription;

    timeoutPerformance;
    countOperation = 0;
    currentTimePerformance = 0;

    subscribeOnVariables: Set<string> = new Set<string>();
    isSubscribeOnVariables = false;

    public isLoadDevices = false;
    private isFirstLoadDevice = false;
    private isFirstEmitDevices = true;

    @Select(ActiveEventsState.getActiveEvents) activeEvents$: Observable<ActiveEvents[]>;

    performance$ = new BehaviorSubject<DevicePerformanceInterface>(null);

    constructor(
        private socketService: SocketService,
        private http: HttpClient,
        private activeEventsService: ActiveEventsService,
        private userService: UsersService,
        private store: Store,
        private ngZone: NgZone,
        public queueProcessDevices: QueueService<QueueProcessDevices, Device[]>,
        private performDeviceService: PerformDeviceService,
    ) {
        this.queueProcessDevices.start(async (item) => {
            const time = performance.now();
            let data: any;
            switch (item.type) {
                case TypeProcessDevices.DEVICE:
                    data = await this.handleDevices(item.devices, item.isForce, item.isForceEmit, item.devices?.map((d) => d.id));
                    break;
                case TypeProcessDevices.DELETE_DEVICE:
                    const devices = this.storageDevices.filter(
                        (device) => device.id !== item.deleteId && device.registratorId !== item.deleteId,
                    );
                    data = await this.handleDevices(devices, item.isForce, item.isForceEmit);
                    break;

                case TypeProcessDevices.VARIABLE:
                    data = await this.handleVariables(item.variables);
                    break;

                case TypeProcessDevices.DEVICE_INFORMATION:
                    data = await this.setDeviceInformation(item.deviceInformation);
                    break;
            }
            this.monitorPerformance(performance.now() - time);
            return data;
        });
        // setInterval(() => {
        //     console.log('current queue', this.queueProcessDevices.getLength())
        // }, 1000)

        this.socketService.errorConnectSubscribe.subscribe(async (status) => {
            // console.log(status,' dissssss')

            switch (status) {
                case ConnectEnum.FIRST_CONNECT:
                    await this.init();
                    if (!this.isLoadDevices) {
                        await this.getAllDevice();
                        this.emitDevices();
                        // this.socketService.incrementGlobalPreloaderLoaded('loadDevice');
                    }
                    break;
                case ConnectEnum.ERROR_CONNECT:
                    await this.setOffline();
                    break;
                case ConnectEnum.RECONNECTED:
                    this.isSubscribeOnVariables = false;

                    await this.getAllDevice(false);
                    break;
            }
        });

        this.socketService.logoutSubscribe.subscribe(() => {
            this.destroy();
        });

        this.ngZone.runOutsideAngular(() => {
            setTimeout(async () => {
                if (this.userService.getTypeClient() === TypeClient.User) {
                    await this.init();
                }
            });
        });
    }

    public destroy(): void {
        this.subscribes.forEach((sub) => {
            if (sub) {
                sub.unsubscribe();
            }
        });

        this.subscribes = [];
        this.activePromiseAllDevices = null;
        if (this.intervalCheckActiveSubscribe) {
            this.intervalCheckActiveSubscribe.unsubscribe();
        }
        this.activeEvents = [];
        this.isLoadDevices = false;
        this.isFirstLoadDevice = false;
        this.isFirstEmitDevices = true;
        this.isSubscribeOnVariables = false;
        this.queueProcessDevices.destroy();
        this.activeEventsService.isInit = false;
        this.store.dispatch(new SetDeviceFromWs([]));
        this.storageDevices = [];
    }

    public async init(): Promise<void> {
        if (this.userService.getTypeClient() === TypeClient.Admin) {
            this.destroy();
            return;
        }
        this.monitorPerformance(0);
        this.destroy();
        this.activeEventsService.isInit = true;

        this.intervalCheckActiveSubscribe = interval(this.intervalConnectCheck).subscribe(() => {
            this.checkLastActive();
        });

        this.subscribes.push(
            this.socketService.fromEvent(SocketEvent.DYNAMIC_VARIABLE_UPDATE).subscribe((variables: Variable[]) => {
                this.processVariables(variables);
            }),
        );

        this.subscribes.push(
            this.socketService.fromEvent(SocketEvent.DYNAMIC_DEVICE_UPDATE).subscribe((devices: Device[]) => {
                this.processDevices(devices, false);
            }),
        );

        this.subscribes.push(
            this.socketService.fromEvent(SocketEvent.DYNAMIC_DEVICE_INFO).subscribe(async (data: DeviceInformation[]) => {
                await this.queueProcessDevices.execute(this.queueProcessDevices.getRandom(10000, 1000000), {
                    type: TypeProcessDevices.DEVICE_INFORMATION,
                    deviceInformation: data,
                    isForce: false,
                    isForceEmit: false,
                });
            }),
        );

        this.subscribes.push(
            this.activeEvents$.subscribe(async (activeEvents: ActiveEvents[]) => {
                if (this.activeEventsService.isInit) {
                    this.activeEvents = activeEvents;
                    await this.processDevices(null, false, true);
                }
            }),
        );

        this.subscribes.push(
            this.socketService.fromEvent(SocketEvent.DYNAMIC_DEVICE_DELETE).subscribe(async (registratorId) => {
                await this.deleteFromStorage(registratorId);

                if (registratorId) {
                    this.store.dispatch(new DeleteDeviceFromWs(registratorId));
                }
            }),
        );

        this.subscribes.push(
            this.socketService.errorConnectSubscribe.subscribe(async (status: ConnectEnum) => {
                switch (status) {
                    case ConnectEnum.RECONNECTED:
                        await this.getAllDevice();
                        this.intervalCheckActiveSubscribe = interval(this.intervalConnectCheck).subscribe(() => {
                            this.checkLastActive();
                        });

                        break;

                    case ConnectEnum.DISCONNECT:
                        this.intervalCheckActiveSubscribe.unsubscribe();
                        // await this.setOffline();

                        break;
                }
            }),
        );
    }

    monitorPerformance(time: number): void {
        this.currentTimePerformance += +time.toFixed(1);
        this.countOperation++;
        if (this.timeoutPerformance) {
            return;
        }

        this.timeoutPerformance = setTimeout(() => {
            this.timeoutPerformance = null;
            this.performance$.next({
                time: +this.currentTimePerformance.toFixed(1),
                count: this.countOperation,
            });
            this.countOperation = 0;

            this.currentTimePerformance = 0;
        }, 5000);
    }

    async handleDevices(devices: Device[], isForce = false, isForceEmit = false, emmitOnlyDevices?: string[]): Promise<Device[]> {
        if (!devices) {
            devices = JSON.parse(JSON.stringify(this.storageDevices));
        }

        const storageDevices: Device[] = JSON.parse(JSON.stringify(isForce ? [] : [...this.storageDevices]));
        devices = this.pushRegistrator(storageDevices, devices);
        devices = await this.performDeviceService.setValue(devices);
        devices = (await this.performDeviceService.setConnect(devices)).all;
        devices = (await this.performDeviceService.setStatus(devices, this.activeEvents)).all;

        const idsNewDevices = devices.map((device) => device.id);

        const performStorage = storageDevices.filter((device) => !idsNewDevices.includes(device.id));
        performStorage.push(...devices);

        this.storageDevices = performStorage;
        // this.setSubscribeOnVariables();
        this.emitDevices(emmitOnlyDevices);
        return devices;
    }

    pushRegistrator(storage: Device[], devices: Device[]): Device[] {
        const filterDevicesRegistrator = devices.filter((d) => d.type === DeviceTypeEnum.registrator);
        for (const d of storage.filter((d) => d.type === DeviceTypeEnum.registrator)) {
            if (!filterDevicesRegistrator.find((d2) => d.id === d2.id)) {
                devices.push(d);
            }
        }
        return devices;
    }


    private handleCoreVariables(variables: Variable[], existsVariable: CoreValueVariable[]): CoreValueVariable[] {
        const updatedVariables: Set<string> = new Set<string>();
        const notUpdatedProperty = ['id', 'originDeviceId', 'originVariableId', 'originVariableId', 'originRegistratorId', 'deviceId'];
        const perform: CoreValueVariable[] = existsVariable.map((variable) => {
            const receivedVariable = variables.find(
                (variableRec) => variableRec.originVariableId === variable.id,
            );
            if (!receivedVariable) {
                return variable;
            }

            for (const key of Object.keys(receivedVariable)) {
                if (!notUpdatedProperty.includes(key)) {
                    variable[key] = receivedVariable[key];
                    updatedVariables.add(variable.id);
                }
            }

            return variable;
        });
        
        const createVariables: CoreValueVariable[] = variables.filter((variable) => !updatedVariables.has(variable.id)).map((variable) => {
            return {
                id: variable.id,
                internalId: variable.internalId,
                deviceId: variable.deviceId,
                originRegistratorId: variable.originRegistratorId,
                currentValue: variable.currentValue,
                name: variable.name,
            };
        })

        return [...perform, ...createVariables];
    }

    async handleVariables(variables: Variable[]): Promise<Device[]> {
        const devices: Device[] = JSON.parse(JSON.stringify(this.storageDevices));
        const updatedDevices: Set<string> = new Set<string>();

        const notUpdatedProperty = [
            'id',
            'originDeviceId',
            'originVariableId',
            'originVariableId',
            'originRegistratorId',
            'deviceId',
            'originDeviceName',
            'originRegistratorName',
        ];
        const perform: Device[] = devices.map((device) => {
            let lastUpdateVariable: Variable;
            device.variables = device.variables.map((variable) => {
                const receivedVariable = variables.find(
                    (variableRec) => variableRec.id === variable.id || variableRec.id === variable.originVariableId,
                );
                if (!receivedVariable) {
                    return variable;
                }

                updatedDevices.add(device.id);
                for (const key of Object.keys(receivedVariable)) {
                    if (!notUpdatedProperty.includes(key)) {
                        variable[key] = receivedVariable[key];
                        if (variable.name === VariablesNameEnum.SleepStatus) {
                            continue;
                        }
                        lastUpdateVariable = variable;
                    }
                }

                return variable;
            });

            if(device.creationType !== CreationType.ORIGIN){
                device.coreVariables = this.handleCoreVariables(variables, device.coreVariables ?? []);
            }

            if (lastUpdateVariable) {
                if (
                    this.performDeviceService.parseDateTime(lastUpdateVariable.lastActive) >
                    this.performDeviceService.parseDateTime(device.lastActive)
                ) {
                    device.lastActive = lastUpdateVariable.lastActive;
                }
                device.isConnect = true;
                device.isSocketActive = true;
            }

            return device;
        });

        return await this.handleDevices(perform, false, false, [...updatedDevices]);
    }

    private async processDevices(devices: Device[], isForce = false, isForceEmit = false): Promise<Device[]> {
        return await this.queueProcessDevices.execute(this.queueProcessDevices.getRandom(10000, 1000000), {
            type: TypeProcessDevices.DEVICE,
            devices,
            isForce,
            isForceEmit,
        });
    }

    private async processVariables(variables: Variable[]): Promise<Device[]> {
        return await this.queueProcessDevices.execute(this.queueProcessDevices.getRandom(10000, 1000000), {
            type: TypeProcessDevices.VARIABLE,
            variables,
            isForce: false,
            isForceEmit: false,
        });
    }

    public async deleteDevice(id): Promise<boolean> {
        const result: ApiResponse = await this.deleteDeviceRequest(id);
        if (!result || result.status !== HTTP_STATUS.SUCCESS) {
            return false;
        }
        await this.deleteFromStorage(id);

        return true;
    }

    private async deleteFromStorage(id): Promise<void> {
        await this.queueProcessDevices.execute(this.queueProcessDevices.getRandom(10000, 1000000), {
            type: TypeProcessDevices.DELETE_DEVICE,
            deleteId: id,
            isForce: true,
            isForceEmit: true,
        });
    }

    public async changeActiveDevice(id: string, isActive: boolean): Promise<Device> {
        const result = await this.updateDeviceRequest(id, isActive);
        if (!result || result.status !== HTTP_STATUS.SUCCESS) {
            return null;
        }

        const updateDevice = result.data;

        let deviceFromStorage: Device = null;
        const updatedStorage = this.storageDevices.map((device) => {
            if (device.id === id) {
                device.isActive = updateDevice.isActive;
                deviceFromStorage = device;
            }
            return device;
        });

        await this.processDevices(updatedStorage);

        return deviceFromStorage;
    }

    public async getAllDevice(fromCache = true): Promise<Device[]> {
        if (this.userService.getTypeClient() === TypeClient.Admin) {
            this.destroy();
            return [];
        }

        if (this.activePromiseAllDevices) {
            return this.activePromiseAllDevices;
        }

        if (fromCache && this.storageDevices.length && this.performDeviceService.checkOnCache(this.storageDevices, this.cacheTime)) {
            const user: User = this.store.selectSnapshot(UserState.getUser);
            if (user) {
                const availableDevices: string[] = [...(user.available_devices ?? []), ...(user.available_registrators ?? [])];

                const idsStorage: string[] = this.storageDevices.map((device) => device.id);

                const check = availableDevices.find((id) => !idsStorage.includes(id));

                if (!check) {
                    return this.storageDevices;
                }
            }
        }

        this.activePromiseAllDevices = new Promise<Device[]>(async (resolve) => {
            let devices: Device[] = await this.getDevicesRequest();
            if (!devices) {
                resolve(null);
            }

            devices = this.performDeviceService.setTimeReceipt(devices);

            resolve(await this.processDevices(devices, true));
        });

        this.activePromiseAllDevices.finally(() => (this.activePromiseAllDevices = null));

        this.isLoadDevices = !!this.activePromiseAllDevices;

        return this.activePromiseAllDevices;
    }

    public async getOwnerRegistrator(fromCache = false): Promise<Device[]> {
        if (fromCache) {
            const user = this.userService.getUserFromToken();
            const ownerRegistrators: Device[] = this.storageDevices.filter(
                (device) => device.type === DeviceTypeEnum.registrator && device.login === user.login,
            );

            if (this.storageDevices.length && this.performDeviceService.checkOnCache(ownerRegistrators, this.cacheTime)) {
                return ownerRegistrators;
            }
        }

        let devices: Device[] = await this.getDevicesRequest(true);

        if (!devices) {
            return null;
        }

        const ids: string[] = devices.map((device) => device.id);

        this.storageDevices = this.storageDevices.filter((device) => !(ids.includes(device.id) || ids.includes(device.registratorId)));
        devices = this.performDeviceService.setTimeReceipt(devices);

        return (await this.processDevices(devices)).filter((device: Device) => device.type === DeviceTypeEnum.registrator);
    }

    async setDeviceInformation(items: DeviceInformation[]): Promise<Device[]> {
        items = items.sort((a, b) => a.ts - b.ts);
        const perform: Device[] = this.storageDevices.map((device) => {
            const dataItems = items.filter((i) => i.registratorId === device.id || i.registratorId === device.registratorId);
            if (!dataItems.length) {
                return device;
            }
            dataItems.forEach((data) => {
                if (device.id === data.registratorId) {
                    if (data.ids !== undefined) {
                        device.ids = data.ids;
                        device.lastActive = new Date().toISOString();
                    }

                    if (data.isWorkExpedition !== undefined) {
                        device.isWorkExpedition = data.isWorkExpedition;
                    }

                    if (data.syncStatus !== undefined) {
                        device.syncStatus = data.syncStatus;
                    }

                    if (data.isArchiveLoading !== undefined) {
                        device.isArchiveLoading = data.isArchiveLoading;
                        if (device.isArchiveLoading) {
                            const internalsId = Array.isArray(data.loadArchiveDevicesInternalId) ? data.loadArchiveDevicesInternalId : [];
                            device.loadArchiveDevicesName = internalsId.map(
                                (id) =>
                                    this.storageDevices.find(
                                        (d) => (d.registratorId === device.id || d.id === device.id) && d.internalId === id,
                                    )?.name,
                            );
                        } else {
                            device.loadArchiveDevicesName = [];
                        }
                    }

                    if (data.daysArchivesLeft !== undefined) {
                        device.daysArchivesLeft = data.daysArchivesLeft;
                    }

                    if (data.isConnect !== undefined) {
                        device.isConnect = data.isConnect;
                        device.isSocketActive = data.isConnect;
                        // device.syncStatus = RegistratorSyncStatus.IS_SYNC;
                    }
                }

                if (device.registratorId === data.registratorId) {
                    if (data.isConnect === false) {
                        device.isConnect = false;
                        device.isSocketActive = false;
                    }

                    if (data.ids !== undefined && data.ids.includes(device.internalId)) {
                        device.lastActive = new Date().toISOString();
                    }
                }

                if(device.creationType !== CreationType.ORIGIN){
                    device.variables = device.variables.map((variable) => {
                        if(variable.originRegistratorId === data.registratorId){
                            variable.isSocketActive = data.isConnect;
                        }
                        return variable;
                    });

                }
            });

            return device;
        });

        return await this.handleDevices(
            perform,
            false,
            false,
            items.map((r) => r.registratorId),
        );
    }

    emitDevices(devicesId?: string[]): void {
        if (!devicesId) {
            clearTimeout(this.timeoutEmmit);
            this.timeoutEmmit = null;
            this.emitDevicesId.clear();
            this.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    // console.log(this.storageDevices, 'test1');
                    this.store.dispatch(new SetDeviceFromWs(this.storageDevices, this.performDeviceService.isSetStatusesByEvents));
                });
            });

            return;
        }

        devicesId.forEach((id) => this.emitDevicesId.add(id));

        if (this.timeoutEmmit) {
            return;
        }

        this.ngZone.runOutsideAngular(() => {
            this.timeoutEmmit = setTimeout(() => {
                const devicesForEmmitId: string[] = [...this.emitDevicesId];
                this.emitDevicesId.clear();
                this.timeoutEmmit = null;
                // console.log(
                //     this.storageDevices.filter((d) => devicesForEmmitId.includes(d.id) || devicesForEmmitId.includes(d.registratorId)),
                //     'test2',
                // );

                this.store.dispatch(
                    new SetDeviceFromWs(
                        this.storageDevices.filter((d) => devicesForEmmitId.includes(d.id) || devicesForEmmitId.includes(d.registratorId)),
                        this.performDeviceService.isSetStatusesByEvents,
                    ),
                );
            });
        });
    }

    setSubscribeOnVariables(): void {
        const subscribeOnVariables: Set<string> = new Set<string>();

        this.storageDevices.forEach((device) => {
            device.variables.forEach((variable) => {
                subscribeOnVariables.add(variable.originVariableId || variable.id);
            });
        });

        const isEqual = JSON.stringify([...subscribeOnVariables].sort()) === JSON.stringify([...this.subscribeOnVariables].sort());

        if (this.isSubscribeOnVariables && isEqual) {
            return;
        }

        this.subscribeOnVariables = subscribeOnVariables;
        this.socketService.emit(SocketEvent.SUBSCRIBE_ON_VARIABLES, [...subscribeOnVariables]);
        this.isSubscribeOnVariables = true;
    }

    private async checkLastActive(): Promise<void> {
        const isUpdate = (await this.performDeviceService.setConnect(this.storageDevices)).isUpdate;

        if (isUpdate) {
            await this.processDevices(null);
        }
    }

    async setOffline(): Promise<void> {
        this.queueProcessDevices.destroy();
        const devices = this.storageDevices.map((device) => {
            device.isConnect = false;
            return device;
        });

        this.storageDevices = devices;
        setTimeout(() => {
            this.emitDevices();
        }, 500);
    }

    private async getDevicesRequest(isOwner: boolean = false): Promise<Device[]> {
        const params: any = {};

        if (isOwner) {
            params.isOwner = String(1);
        }

        let user: Partial<User>;

        if (!this.store.selectSnapshot(UserState.getUser)) {
            user = await this.socketService.getUser();
        }

        const registratorIds = user && user.available_registrators ? user.available_registrators : [];

        const headers = new HttpHeaders({ registratorId: registratorIds.join(',') });
        const result = await this.http
            .get<ApiResponse>('/api/devices', {
                params,
                headers,
            })
            .toPromise()
            .catch((e) => console.log(e));

        if (!this.isFirstLoadDevice) {
            this.isFirstLoadDevice = true;

            this.socketService.incrementGlobalPreloaderLoaded('loadDevice');
        }

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            return result.data;
        }

        return null;
    }

    private async getRelatedDevicesByIdRequest(deviceId: string): Promise<Device[]> {
        const user: User = this.store.selectSnapshot(UserState.getUser);
        const registratorIds = user.available_registrators || [];

        const headers = new HttpHeaders({ registratorId: registratorIds.join(',') });

        const result = await this.http
            .get<ApiResponse>('/api/devices/related', {
                params: { deviceId },
                headers,
            })
            .toPromise()
            .catch((e) => console.log(e));

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            return result.data;
        }

        return null;
    }

    private async updateDeviceRequest(id: string, isActive: boolean): Promise<any> {
        const user: User = this.store.selectSnapshot(UserState.getUser);
        const registratorIds = user.available_registrators || [];

        const headers = new HttpHeaders({ registratorId: registratorIds.join(',') });

        return await this.http
            .put('/api/devices', { id, isActive }, { headers })
            .toPromise()
            .catch((e) => {
                console.log(e);
            });
    }

    private async deleteDeviceRequest(id: string): Promise<any> {
        const user: User = this.store.selectSnapshot(UserState.getUser);
        const registratorIds = user.available_registrators || [];

        const headers = new HttpHeaders({ registratorId: registratorIds.join(',') });

        return await this.http
            .delete('api/devices', { params: { id }, headers })
            .toPromise()
            .catch((e) => console.log(e));
    }
}

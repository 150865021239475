<div class="operations">
    <div class="operations__wrapper">
        <div class="operations__list">
            <div *ngFor="let operation of tableOperations$ | async" class="operations__item"
                (click)="setOperation(operation)">
                <svg-icon [src]="operation.svgPath"></svg-icon>
            </div>
        </div>
    </div>
</div>

<div class="note"
     [class.note--error]="noteInfo.status === tooltipStatusEnum.error"
     [class.note--load]="noteInfo.status === tooltipStatusEnum.load"
     [class.note--sleep]="noteInfo.status === tooltipStatusEnum.sleep"
     [class.note--update]="noteInfo.status === tooltipStatusEnum.update">
    <div class="note__wrapper">
        <div class="note__icon">

            <svg-icon [src]="NOTE_ICON_PATH"></svg-icon>

        </div>

        <div *ngIf="!noteInfo.message" class="note__message">

            <ng-container [ngSwitch]="noteInfo.status">

                <ng-template [ngSwitchCase]="tooltipStatusEnum.update">
                    <span [translate]="!router.url.includes('details/datalogger-dashboard')
                    ? 'notifications.save'
                    : 'notifications.saveServer'"></span>
                </ng-template>

                <ng-template [ngSwitchCase]="tooltipStatusEnum.error">
                    <span [translate]="'notifications.error'"></span>
                </ng-template>

                <ng-template [ngSwitchCase]="tooltipStatusEnum.load">
                    <span [translate]="'notifications.load'"></span>
                </ng-template>

            </ng-container>

        </div>

        <div *ngIf="noteInfo.message" class="note__message" [innerHTML]="noteInfo.message | translate"></div>
        <div *ngIf="noteInfo.action" (click)="close()" class="note__close">
            <svg-icon [src]="CLOSE_ICON_PATH"></svg-icon>
        </div>
    </div>
</div>

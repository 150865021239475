import { Injectable } from '@angular/core';
import {
    ColumnIconType,
    ColumnsTableInterface,
    ColumnTypeEnum,
    ColumnValueAlignEnum,
} from '../../../app-shared-elements/_interfaces/ColumnsTable';
import { CustomTooltipTypeEnum } from '../../../app-shared-elements/_enums/custom-tooltip-type.enum';
import { TooltipSideEnum } from '../../../app-shared-elements/_enums/tooltip-side.enum';
import { PaymentStatusEnum } from '../../../billing/_interfaces/billing-invoice.interface';
import { FinanceDebtorsInterface } from '../_interfaces/finance-debtors.interface';

@Injectable({
    providedIn: 'root',
})
export class FinanceDebtorsService {
    debtorsTableColumn: ColumnsTableInterface[] = [
        {
            title: 'adminFinances.debtors.invoice',
            grow: true,
            maxWidth: '260px',
            minWidth: '200px',
            type: ColumnTypeEnum.text,
            name: 'accountNumber',
        },
        {
            title: 'adminFinances.debtors.debtors',
            grow: true,
            type: ColumnTypeEnum.text,
            styles: true,
            name: 'name',
            isClick: true,
        },
        {
            title: 'adminFinances.debtors.status',
            grow: true,
            type: ColumnTypeEnum.text,
            name: 'status',
            tooltip: true,
            align: ColumnValueAlignEnum.center,
            isMultilang: true,
            icon: ColumnIconType.tooltip,
            styles: true,
            customTooltipType: CustomTooltipTypeEnum.status,
            tooltipMaxWidth: '250px',
            tooltipSide: TooltipSideEnum.bottom,
        },
        {
            title: 'adminFinances.debtors.balance',
            grow: true,
            align: ColumnValueAlignEnum.right,
            type: ColumnTypeEnum.text,
            name: 'balance',
        },
        {
            title: 'adminFinances.debtors.arrears',
            align: ColumnValueAlignEnum.right,
            grow: true,
            type: ColumnTypeEnum.text,
            name: 'debt',
        },
    ];

    constructor() {}

    getDebtorsStatus(invoice: FinanceDebtorsInterface): string | Date {
        switch (invoice.paymentStatus) {
            case PaymentStatusEnum.NOT_COMPLETE:
                return '-';
            case PaymentStatusEnum.AWAITING_PAYMENT:
                return 'billing.invoice.status.awaitPaid';
            case PaymentStatusEnum.PAYMENT_NOT_PAID:
                return 'billing.invoice.status.paymentNotPaid';
            case PaymentStatusEnum.LATE_PAYMENT:
                return 'billing.invoice.status.latePaid';
            case PaymentStatusEnum.PAID:
                return 'billing.invoice.status.paid';
        }
    }
}

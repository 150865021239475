import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { DeviceTypeEnum } from 'src/app/app-shared-elements/_enums/device-type.enum';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { CloneDeepService } from 'src/app/app-shared-elements/_services/clone-deep.service';
import { DeviceDynamicUpdateService } from 'src/app/app-shared-elements/_services/dynamic/device-dynamic-update.service';
import { LangService } from 'src/app/app-shared-elements/_services/lang.service';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import {
    ChangeDeviceActivity,
    ClearStateDevices,
    DeleteDevice,
    DeleteDeviceFromWs,
    DeleteVirtualDevice,
    GetAssociatedLogicEvents,
    GetDevices,
    GetUnitsForUser,
    GetUserDevicePosition,
    InitRegistratorOptions,
    InitUserDevicesArray,
    IsShowDeletePopup,
    SetCurrentDevice,
    SetCurrentRegistrator,
    SetDeviceConfiguration,
    SetDeviceFromWs,
    SetIsAcknowledgementDeviceLog,
    SetOfflineRegistrator,
    SyncDevice,
    UpdateDevicesPosition,
    UpdateDevicesState,
    UpdateUserDeviceArrayStatus,
    UpdateUserDevicePosition,
    UpdateUserDevicesArray,
    UpdateVariable,
    UpdateVirtualDevice,
} from '../actions/user-devices.actions';
import { UserRegistratorInterface } from '../../user-devices/_interface/user-devices.interface';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { DeviceService } from '../../_services/device.service';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { CreationType, RegistratorSyncStatus } from 'src/app/app-shared-elements/_enums/registrator-sync-status.enu';
import { SetIsLoadGroup } from 'src/app/groups/_store/actions/groups.actions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { UserState } from 'src/app/app-shared-elements/_store/states/user.state';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { UnitInterface } from 'src/app/app-shared-elements/_interfaces/unit.interface';
import { TypeFilterEnum } from 'src/app/app-shared-elements/filters/enums/type-filter.enum';
import { DropdownFilterOptionInterface } from 'src/app/app-shared-elements/filters/interfaces/filter-option.interface';
import { ColumnsTableInterface, ColumnTypeEnum, ColumnValueAlignEnum } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { Router } from '@angular/router';
import { UserDeviceParamsState } from './user-device-params.state';
import { DeviceDashboardPositionInterface } from '../../_interfaces/device-dashboard-position.interface';
import { LogicEvent } from '../../../events/logical-events/_interface/LogicEvent';
import { VariablesNameEnum } from 'src/app/app-shared-elements/_enums/variables-name.enum';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { DataloggerModificationEnum } from '../../../details-device-container/_enums/datalogger-modification.enum';
import { ActiveEventsState } from '../../../app-shared-elements/_store/states/active-events.state';
import { ConfigurationVariableSavedEnum } from '../../../app-shared-elements/_interfaces/Variable';
import { GetLogicEventsStatuses } from '../../../app-shared-elements/_store/actions/active-events.actions';

export interface DevicesStateModel {
    devices: Device[];
    userDevicesArray: UserRegistratorInterface[];
    userDeviceTableColumns: ColumnsTableInterface[];
    dropDownFilterDevice: DropdownFilterOptionInterface[];
    updateUserDevicesArray: UserRegistratorInterface;
    registratorOptions: SelectOptionInterface[];
    currentRegistratorId: string;
    currentDeviceId: string;
    isLoadDevices: boolean;
    units: UnitInterface[];
    isInitedTable: boolean;
    isSetStatusesByActiveEvents: boolean;
    errorListEvent: LogicEvent[];
    showDeletePopup: boolean;
}

const DEVICE_STATE_TOKEN = new StateToken<DevicesStateModel>('devices');

const initDevicesTableColumns: ColumnsTableInterface[] = [
    {
        title: 'devices.type',
        grow: false,
        // small: true,
        maxWidth: '8%',
        minWidth: '8%',
        type: ColumnTypeEnum.icon,
        name: 'type',
    },
    {
        title: 'devices.name',
        grow: true,
        small: false,
        type: ColumnTypeEnum.text,
        name: 'name',
        align: ColumnValueAlignEnum.left,
        isClick: true,
    },
    {
        title: 'devices.login',
        grow: false,
        small: true,
        maxWidth: '200px',
        minWidth: '200px',
        type: ColumnTypeEnum.text,
        name: 'login',
        tooltip: true,
    },
    {
        title: 'devices.statusOn',
        grow: false,
        small: true,
        maxWidth: '140px',
        minWidth: '85px',
        type: ColumnTypeEnum.text,
        name: 'isConnect',
    },
    {
        title: 'devices.signal',
        grow: false,
        small: true,
        maxWidth: '180px',
        minWidth: '100px',
        type: ColumnTypeEnum.text,
        name: 'signal',
    },
    {
        title: 'devices.battery',
        grow: false,
        small: true,
        maxWidth: '160px',
        minWidth: '120px',
        type: ColumnTypeEnum.text,
        name: 'battery',
    },
    {
        title: 'devices.status',
        grow: false,
        small: true,
        maxWidth: '180px',
        minWidth: '120px',
        type: ColumnTypeEnum.text,
        name: 'status',
        isClick: true,
    },
    {
        title: 'devices.lastAct',
        grow: false,
        small: false,
        maxWidth: '200px',
        minWidth: '200px',
        type: ColumnTypeEnum.date,
        name: 'lastActive',
        // isSettings: true
    },
    {
        title: 'devices.actions',
        maxWidth: '100px',
        minWidth: '100px',
        type: ColumnTypeEnum.text,
        name: 'actions',
        // isSettings: true
    },
];

const initDropDownFilterDevices: DropdownFilterOptionInterface[] = [
    { key: 'name', value: 'devices.dropdownFilters.name', type: TypeFilterEnum.text, property: 'name' },
    { key: 'login', value: 'devices.dropdownFilters.login', type: TypeFilterEnum.text, property: 'login' },
    { key: 'connect', value: 'devices.dropdownFilters.connect', type: TypeFilterEnum.text, property: 'connect' },
    { key: 'status', value: 'devices.dropdownFilters.status', type: TypeFilterEnum.text, property: 'status' },
];

@State<DevicesStateModel>({
    name: DEVICE_STATE_TOKEN,
    defaults: {
        devices: [],
        userDevicesArray: [],
        userDeviceTableColumns: initDevicesTableColumns,
        dropDownFilterDevice: initDropDownFilterDevices,
        registratorOptions: [],
        updateUserDevicesArray: null,
        currentRegistratorId: null,
        currentDeviceId: null,
        isLoadDevices: false,
        units: [],
        isInitedTable: false,
        isSetStatusesByActiveEvents: false,
        errorListEvent: [],
        showDeletePopup: false,
    },
})
@Injectable()
export class DevicesState {
    constructor(
        private deviceUpdateService: DeviceDynamicUpdateService,
        private notificationsService: NotificationsService,
        private langService: LangService,
        private cloneDeepService: CloneDeepService,
        private store: Store,
        public deviceService: DeviceService,
        private http: HttpClient,
        private translateService: TranslateService,
        private router: Router,
    ) {}

    @Selector()
    static getDevices(state: DevicesStateModel): Device[] {
        return state.devices;
    }

    @Selector()
    static getUserDeviceArray(state: DevicesStateModel): UserRegistratorInterface[] {
        return state.userDevicesArray;
    }

    @Selector()
    static getUserDeviceTableColumns(state: DevicesStateModel): ColumnsTableInterface[] {
        return state.userDeviceTableColumns;
    }

    @Selector()
    static getDropDownFilterDevices(state: DevicesStateModel): DropdownFilterOptionInterface[] {
        return state.dropDownFilterDevice;
    }

    @Selector()
    static getCurrentRegistratorId(state: DevicesStateModel): string {
        return state.currentRegistratorId;
    }

    @Selector()
    static getCurrentRegistrator(state: DevicesStateModel): Device {
        return state.devices.find((device) => device.id === state.currentRegistratorId);
    }

    @Selector()
    static getIsInitedTable(state: DevicesStateModel): boolean {
        return state.isInitedTable;
    }

    @Selector()
    static getRegistrators(state: DevicesStateModel): Device[] {
        return state.devices.filter((device) => device.type === DeviceTypeEnum.registrator && device.creationType !== CreationType.MNEMONIC).sort((a, b) => (a.id > b.id ? 1 : -1));
    }

    @Selector()
    static getAllRegistrators(state: DevicesStateModel): Device[] {
        return state.devices.filter((device) => device.type === DeviceTypeEnum.registrator);
    }

    @Selector()
    static getMnemonicDevices(state: DevicesStateModel): Device[] {
        return state.devices.filter((device) => device.creationType === CreationType.MNEMONIC);
    }

    @Selector([DevicesState.getCurrentRegistrator])
    static getDevicesByRegistratorId(state: DevicesStateModel): Device[] {
        return state.devices.filter((device) => (device.type === DeviceTypeEnum.registrator ? device.id === state.currentRegistratorId : device.registratorId === state.currentRegistratorId));
    }

    @Selector()
    static getIsDeviceSyncStatus(state: DevicesStateModel): boolean {
        return !!state.devices.find((d) => d.syncStatus === RegistratorSyncStatus.NOT_SYNC);
    }

    @Selector([UserState.getUser])
    static getOwnerRegistrators(state: DevicesStateModel, user: User): Device[] {
        return state.devices.filter((d) => d.type === DeviceTypeEnum.registrator && d.creationType !== CreationType.MNEMONIC && d.login === user.login);
    }

    @Selector([UserState.getUser])
    static getOwnerDevices(state: DevicesStateModel, user: User): Device[] {
        return state.devices.filter((d) => d.creationType !== CreationType.MNEMONIC && d.login === user.login);
    }

    @Selector()
    static getCurrentDevice(state: DevicesStateModel): Device {
        return state.devices.find((device) => device.id === state.currentDeviceId) || null;
    }

    @Selector([DevicesState.getCurrentDevice])
    static getCurrentModification(state: DevicesStateModel, currentDevice: Device): DataloggerModificationEnum {
        return currentDevice.variables.find((f) => f.name === VariablesNameEnum.Modification)?.currentValue ?? null;
    }

    @Selector()
    static getIsLoadDevices(state: DevicesStateModel): boolean {
        return state.isLoadDevices;
    }

    @Selector()
    static getUnitsForUser(state: DevicesStateModel): UnitInterface[] {
        return state.units;
    }

    @Selector()
    static getIsSetStatusesByActiveEvents(state: DevicesStateModel): boolean {
        return state.isSetStatusesByActiveEvents;
    }

    @Selector()
    static getErrorListEvent(state: DevicesStateModel): LogicEvent[] {
        return state.errorListEvent.sort((a, b) => (new Date(a.created).getTime() > new Date(b.created).getTime() ? 1 : -1));
    }

    @Selector()
    static getIsShowDeletePopup(state: DevicesStateModel): boolean {
        return state.showDeletePopup;
    }

    @Selector()
    static getRegistratorOptions(state: DevicesStateModel): SelectOptionInterface[] {
        return state.registratorOptions;
    }

    @Selector()
    static getVirtualDevices(state: DevicesStateModel): Device[] {
        return state.devices.filter((device) => device.creationType === CreationType.VIRTUAL);
    }

    @Selector()
    static getDataloggers(state: DevicesStateModel): Device[] {
        return state.devices.filter((device) => device.type === DeviceTypeEnum.datalogger);
    }

    @Action(GetDevices)
    async getDevices(ctx: StateContext<DevicesStateModel>, payload: GetDevices): Promise<void> {
        const state = ctx.getState();
        const result = await this.deviceUpdateService.getAllDevice(payload.fromCache);
        if (result) {
            ctx.setState({
                ...state,
                devices: result,
                isLoadDevices: true,
            });
        } else {
            ctx.setState({
                ...state,
                isLoadDevices: false,
            });
        }
    }

    @Action(UpdateDevicesState)
    updateDevicesState(ctx: StateContext<DevicesStateModel>, payload: UpdateDevicesState): void {
        const state = ctx.getState();

        if (payload.devices) {
            ctx.setState({
                ...state,
                devices: payload.devices,
                isLoadDevices: true,
            });
        } else {
            ctx.setState({
                ...state,
                isLoadDevices: false,
            });
        }
    }

    @Action(SetDeviceFromWs)
    setDevicesFromWs(ctx: StateContext<DevicesStateModel>, action: SetDeviceFromWs): void {
        const state = ctx.getState();

        const syncSuccessDevice: Device = action.devices.find((device) => device.syncStatus === RegistratorSyncStatus.SYNC_SUCCESS);
        if (syncSuccessDevice) {
            const syncSuccessRegistrator: Device = syncSuccessDevice.type === DeviceTypeEnum.registrator ? syncSuccessDevice : action.devices.find((d) => d.id === syncSuccessDevice.registratorId);
            if (syncSuccessRegistrator) {
                this.translateService
                    .get('devices.deviceSyncSuccess', {
                        deviceName: syncSuccessRegistrator.name ?? syncSuccessRegistrator.defaultName,
                    })
                    .pipe(first())
                    .subscribe((message) => {
                        this.notificationsService.onEmit(TooltipStatusEnum.update, false, message);
                    });
            }
        }

        const syncErrorDevice: Device = action.devices.find((device) => device.syncStatus === RegistratorSyncStatus.SYNC_ERROR);
        if (syncErrorDevice) {
            const syncErrorRegistrator: Device = syncErrorDevice.type === DeviceTypeEnum.registrator ? syncErrorDevice : action.devices.find((d) => syncErrorDevice && d.id === syncErrorDevice.registratorId);

            if (syncErrorRegistrator) {
                this.translateService
                    .get('devices.devuceSyncError', { deviceName: syncErrorDevice.name ?? syncErrorDevice.defaultName })
                    .pipe(first())
                    .subscribe((message) => {
                        this.notificationsService.onEmit(TooltipStatusEnum.error, false, message);
                    });
            }
        }

        const newDevices: Device[] = action.devices.filter((d) => !state.devices.find((device) => device.id === d.id));

        ctx.setState({
            ...state,
            devices: [...newDevices, ...this.deviceService.updatedDevice(state.devices, action.devices)],
            isSetStatusesByActiveEvents: !!action.isSetStatusesByActiveEvents,
            isLoadDevices: !!action.devices.length,
        });
        this.deviceUpdateService.isLoadDevices = !!action.devices.length;

        if (newDevices.length) {
            if (this.router.url.includes('device-dashboard')) {
                ctx.dispatch(new InitUserDevicesArray());
                ctx.dispatch(new GetLogicEventsStatuses());
            }
        }
    }

    @Action(DeleteDeviceFromWs)
    deleteDeviceFromWs(ctx: StateContext<DevicesStateModel>, payload: DeleteDeviceFromWs): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            devices: state.devices.filter((d) => (d.type === DeviceTypeEnum.registrator ? d.id !== payload.deviceId : d.registratorId !== payload.deviceId)),
            userDevicesArray: state.userDevicesArray.filter((r) => r.registrator.id !== payload.deviceId), // .filter(item => !!item)
        });
    }

    @Action(DeleteDevice)
    async deleteDevice(ctx: StateContext<DevicesStateModel>, action: DeleteDevice): Promise<void> {
        const result: ApiResponse = (await this.http
            .delete('/api/devices/test', {
                params: {
                    id: action.device.id,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.EVENTS_ACTIVE_FOR_REGISTRATOR) {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
            ctx.setState({
                ...state,
                errorListEvent: result.data,
            });
            return;
        }

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            action.device.userId === this.store.selectSnapshot(UserState.getUser).id
                ? this.translateService
                      .get('devices.trashDelete', { name: action.device.name ?? action.device.defaultName })
                      .pipe(first())
                      .subscribe((message) => {
                          this.notificationsService.onEmit(TooltipStatusEnum.update, false, message);
                      })
                : this.translateService
                      .get('devices.delete', { name: action.device.name ?? action.device.defaultName })
                      .pipe(first())
                      .subscribe((message) => {
                          this.notificationsService.onEmit(TooltipStatusEnum.update, false, message);
                      });

            ctx.setState({
                ...state,
                devices: state.devices.filter((device) => device.id !== action.device.id),
                userDevicesArray: state.userDevicesArray
                    .filter((item) => {
                        if (item.registrator.id !== action.device.id) {
                            return {
                                ...item,
                                devices: item.devices.filter((d) => d.id !== action.device.id).filter((d) => !!d),
                            };
                        }
                    })
                    .filter((item) => !!item),
                errorListEvent: [],
            });
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(ChangeDeviceActivity)
    async changeActivityDevice(ctx: StateContext<DevicesStateModel>, action: ChangeDeviceActivity): Promise<void> {
        const result = await this.deviceUpdateService.changeActiveDevice(action.deviceId, action.isActive);
        if (result) {
            await ctx.dispatch(new InitUserDevicesArray()).toPromise();
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        }
    }

    @Action(SetCurrentRegistrator)
    async setCurrentRegistrator(ctx: StateContext<DevicesStateModel>, payload: SetCurrentRegistrator): Promise<void> {
        const state = ctx.getState();

        if (state.currentRegistratorId === payload.registratorId) {
            return;
        }

        this.store.dispatch(new SetIsLoadGroup(false));

        ctx.setState({
            ...state,
            currentRegistratorId: payload.registratorId,
        });
    }

    @Action(SetCurrentDevice)
    setCurrentDevice(ctx: StateContext<DevicesStateModel>, payload: SetCurrentDevice): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentDeviceId: payload.deviceId,
        });
    }

    @Action(InitUserDevicesArray)
    async initUserDevicesArray(ctx: StateContext<DevicesStateModel>): Promise<void> {
        const state = ctx.getState();
        const devices: Device[] = state.devices;
        let result: UserRegistratorInterface[] = [];
        const user: User = this.store.selectSnapshot(UserState.getUser);
        const params = this.store.selectSnapshot(UserDeviceParamsState.getParams);
        const activeStatuses = this.store.selectSnapshot(ActiveEventsState.getStatusesEvents);

        devices
            .filter((d) => d.type === DeviceTypeEnum.registrator && d.creationType !== CreationType.MNEMONIC)
            .forEach((device) => {
                result.push({
                    registrator: {
                        ...device,
                        name: this.langService.getCurrentValueFromNameLn(device.name),
                        isVisible: this.deviceService.getIsVisibleDevice(device, params),
                        isKioskMode: device.variables?.find((v) => v.name === VariablesNameEnum.KioskMode)?.currentValue ?? false,
                        isTransportMode: device.variables?.find((v) => v.name === VariablesNameEnum.ExpectExpedition)?.currentValue ?? false,
                    },
                    devices: devices
                        .filter((d) => d.registratorId === device.id)
                        .map((d) => {
                            return {
                                ...d,
                                name: d.name || d.defaultName,
                                isAction: false,
                                listAction: this.deviceService.getListAction(d, user),
                                isVisible: this.deviceService.getIsVisibleDevice(d, params),
                                position: this.deviceService.getDevicesPosition(d, user),
                            };
                        })
                        .sort((a, b) => a.position - b.position),
                    isActionRegistrator: false,
                    position: +this.deviceService.getPosition(device, user),
                    listAction: this.deviceService.getListAction(device, user),
                });
            });

        result = result.sort((a, b) => {
            return a.position - b.position;
        });

        ctx.setState({
            ...state,
            userDevicesArray: [...result],
            isInitedTable: true,
        });
    }

    @Action(UpdateUserDevicesArray)
    async updateUserDevicesArray(ctx: StateContext<DevicesStateModel>, payload: UpdateUserDevicesArray): Promise<void> {
        const state = ctx.getState();
        const devices = payload.devices;
        const user: User = this.store.selectSnapshot(UserState.getUser);
        const params = this.store.selectSnapshot(UserDeviceParamsState.getParams);

        ctx.setState({
            ...state,
            userDevicesArray: state.userDevicesArray
                .map((userDevice) => {
                    const currentUserRegistrator = devices.find((r) => r.id === userDevice.registrator.id);

                    if (!currentUserRegistrator) {
                        return;
                    }

                    return {
                        ...userDevice,
                        registrator: {
                            ...currentUserRegistrator,
                            savedStatusEvent: currentUserRegistrator.savedStatusEvent,
                            errorStatusEvent: currentUserRegistrator.errorStatusEvent,
                            savedStatusDevice: currentUserRegistrator.variables.filter((d) => d.savedStatusValue === ConfigurationVariableSavedEnum.wait)?.length > 0,
                            errorStatusDevice: currentUserRegistrator.variables.filter((d) => d.savedStatusValue === ConfigurationVariableSavedEnum.error)?.length > 0,
                            name: this.langService.getCurrentValueFromNameLn(currentUserRegistrator.name),
                            isVisible: this.deviceService.getIsVisibleDevice(currentUserRegistrator, params),
                            isKioskMode: currentUserRegistrator.variables?.find((v) => v.name === VariablesNameEnum.KioskMode)?.currentValue,
                            isTransportMode: currentUserRegistrator.variables?.find((v) => v.name === VariablesNameEnum.ExpectExpedition)?.currentValue,
                        },
                        devices: userDevice.devices
                            .map((device) => {
                                const currentDevice = devices.find((d) => d.id === device.id);

                                if (!currentDevice) {
                                    return;
                                }

                                return (device = {
                                    ...currentDevice,
                                    savedStatusEvent: device?.savedStatusEvent ?? null,
                                    errorStatusEvent: device?.errorStatusEvent ?? null,
                                    savedStatusDevice: device.variables.filter((d) => d.savedStatusValue === ConfigurationVariableSavedEnum.wait)?.length > 0,
                                    errorStatusDevice: device.variables.filter((d) => d.savedStatusValue === ConfigurationVariableSavedEnum.error)?.length > 0,
                                    variables: device.variables,
                                    name: this.langService.getCurrentValueFromNameLn(currentDevice.name),
                                    isAction: device.isAction,
                                    listAction: this.deviceService.getListAction(currentDevice, user),
                                    isVisible: this.deviceService.getIsVisibleDevice(currentDevice, params),
                                });
                            })
                            .filter((d) => !!d),
                    };
                })
                .filter((r) => !!r),
            isInitedTable: true,
        });
    }

    @Action(UpdateUserDeviceArrayStatus)
    updateUserDeviceArrayStatus(ctx: StateContext<DevicesStateModel>, payload: UpdateUserDeviceArrayStatus): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            userDevicesArray: state.userDevicesArray.map((item) => {
                const currentEventRegistrator = payload.events?.find((event) => item.registrator.variables.find((f) => f.id === event.resultVariableId));
                const currentVariableRegistrator = item.registrator.variables.find((v) => payload.currentEvent?.data?.resultVariableId === v.id);

                if (currentVariableRegistrator && payload.currentEvent && item.registrator.id === currentVariableRegistrator.deviceId) {
                    item.registrator.savedStatusEvent = payload.currentEvent?.data?.savedStatus ?? null;
                    item.registrator.errorStatusEvent = payload.currentEvent?.data?.errorStatus ?? null;
                }

                if (currentEventRegistrator) {
                    item.registrator.savedStatusEvent = currentEventRegistrator?.savedStatus ?? null;
                    item.registrator.errorStatusEvent = currentEventRegistrator?.errorStatus ?? null;
                }

                item.registrator.savedStatusDevice = item.registrator.variables.filter((d) => d.savedStatusValue === ConfigurationVariableSavedEnum.wait)?.length > 0;
                item.registrator.errorStatusDevice = item.registrator.variables.filter((d) => d.savedStatusValue === ConfigurationVariableSavedEnum.error)?.length > 0;

                return {
                    ...item,
                    devices: item.devices.map((device) => {
                        const currentEvent = payload.events?.find((event) => device.variables.find((f) => f.id === event.resultVariableId));
                        const currentVariable = device.variables?.find((v) => payload.currentEvent?.data?.resultVariableId === v.id);
                        if (currentVariable && payload.currentEvent && device.id === currentVariable.deviceId) {
                            return {
                                ...device,
                                savedStatusEvent: payload.currentEvent?.data?.savedStatus ?? null,
                                errorStatusEvent: payload.currentEvent?.data?.errorStatus ?? null,
                            };
                        }

                        if (payload.data?.length) {
                            return {
                                ...device,
                                variables: device.variables.map((v) => {
                                    const currentUpdateVariable = payload.data?.find((f) => f.id === v.id);
                                    if (!currentUpdateVariable) {
                                        return v;
                                    }
                                    return {
                                        ...v,
                                        savedStatusValue: currentUpdateVariable?.savedStatusValue ?? v.savedStatusValue,
                                    };
                                }),
                            };
                        }

                        if (currentEvent) {
                            return {
                                ...device,
                                savedStatusEvent: currentEvent?.savedStatus ?? null,
                                errorStatusEvent: currentEvent?.errorStatus ?? null,
                            };
                        }

                        return {
                            ...device,
                            savedStatusDevice: device.variables.filter((d) => d.savedStatusValue === ConfigurationVariableSavedEnum.wait)?.length > 0,
                            errorStatusDevice: device.variables.filter((d) => d.savedStatusValue === ConfigurationVariableSavedEnum.error)?.length > 0,
                        };
                    }),
                };
            }),
        });
    }

    @Action(GetUserDevicePosition)
    getUserDevicePosition(ctx: StateContext<DevicesStateModel>): void {
        const state = ctx.getState();
        const posData: DeviceDashboardPositionInterface[] = [];

        let registratorIndex = 1;
        state.devices.forEach((device) => {
            if (device.type === DeviceTypeEnum.registrator) {
                posData.push({
                    registratorId: device.id,
                    position: registratorIndex,
                });
                ++registratorIndex;
            }
        });
        this.store.dispatch(new UpdateUserDevicePosition(posData));

        ctx.setState({
            ...state,
        });
    }

    @Action(UpdateUserDevicePosition)
    updateUserDevicePosition(ctx: StateContext<DevicesStateModel>, payload: UpdateUserDevicePosition): void {
        const state = ctx.getState();
        const user = this.store.selectSnapshot(UserState.getUser);

        ctx.setState({
            ...state,
            userDevicesArray: state.userDevicesArray.map((userDevice) => {
                const currentDevice = payload.position.find((p) => p.registratorId === userDevice.registrator.id);
                if (currentDevice) {
                    userDevice.position = currentDevice.position;
                }
                return userDevice;
            }),
        });
        localStorage.setItem(`position${user.id}`, JSON.stringify(payload.position));
    }

    @Action(UpdateDevicesPosition)
    updateDevicesPosition(ctx: StateContext<DevicesStateModel>, payload: UpdateDevicesPosition): void {
        const state = ctx.getState();
        const user = this.store.selectSnapshot(UserState.getUser);

        ctx.setState({
            ...state,
            userDevicesArray: state.userDevicesArray.map((userDevice) => {
                return {
                    ...userDevice,
                    devices: userDevice.devices.map((device) => {
                        const currentDevice = payload.position.find((p) => p.id === device.id);
                        if (currentDevice) {
                            device.position = currentDevice.position;
                        }
                        return device;
                    }),
                };
            }),
        });
        localStorage.setItem(`devicesPosition${user.id}`, JSON.stringify(payload.position));
    }

    @Action(ClearStateDevices)
    clearState(ctx: StateContext<DevicesStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            devices: [...[]],
            userDevicesArray: [],
            registratorOptions: [],
            updateUserDevicesArray: null,
            currentRegistratorId: null,
            currentDeviceId: null,
            isLoadDevices: false,
        });
    }

    @Action(SyncDevice)
    async syncDevice(ctx: StateContext<DevicesStateModel>, payload: SyncDevice): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http.post('/api/devices/sync', { registratorId: payload.registratorId }).toPromise()) as ApiResponse;
        ctx.setState({
            ...state,
        });
    }

    @Action(DeleteVirtualDevice)
    async deleteVirtualDevice(ctx: StateContext<DevicesStateModel>, payload: DeleteVirtualDevice): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .delete(`/api/virtual-devices/`, { params: { id: payload.device.id } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.translateService
                .get(payload.type === CreationType.MNEMONIC ? 'customDevices.deleteMnemonic' : 'customDevices.deleteVDevice', {
                    virtualDevice: payload.device.name || payload.device.defaultName,
                })
                .pipe(first())
                .subscribe((message) => {
                    this.notificationsService.onEmit(TooltipStatusEnum.update, false, message);
                });

            ctx.setState({
                ...state,
                devices: state.devices.filter((device) => device.id !== payload.device.id),
                userDevicesArray: state.userDevicesArray
                    .filter((item) => {
                        if (item.registrator.id !== payload.device.id) {
                            return {
                                ...item,
                                devices: item.devices.filter((d) => d.id !== payload.device.id).filter((d) => !!d),
                            };
                        }
                    })
                    .filter((item) => !!item),
            });
        }
    }

    @Action(UpdateVirtualDevice)
    updateVDevice(ctx: StateContext<DevicesStateModel>, payload: UpdateVirtualDevice): void {
        const state = ctx.getState();
        if (payload.device && payload.device.creationType === CreationType.VIRTUAL) {
            ctx.setState({
                ...state,
                devices: state.devices.map((device) => (device.id === payload.device.id ? payload.device : device)),
            });
        }
    }

    @Action(SetIsAcknowledgementDeviceLog)
    async setIsAcknowledgementDeviceLog(ctx: StateContext<DevicesStateModel>, payload: SetIsAcknowledgementDeviceLog): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post('/api/devices/acknowledgement-device-log', {
                id: payload.registratorId,
                status: payload.value,
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                devices: state.devices.map((d) => {
                    if (d.id === payload.registratorId) {
                        d.isAcknowledgementDeviceLog = payload.value;
                    }

                    return d;
                }),
            });
        }
    }

    @Action(SetOfflineRegistrator)
    async setSetOfflineRegistrator(ctx: StateContext<DevicesStateModel>, payload: SetOfflineRegistrator): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post('/api/devices/offline-registrator', {
                id: payload.registratorId,
                status: payload.value,
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                devices: state.devices.map((d) => {
                    if (d.id === payload.registratorId) {
                        d.isLoggedOffline = payload.value;
                    }

                    return d;
                }),
            });
        }
    }

    @Action(UpdateVariable)
    async updateVariable(ctx: StateContext<DevicesStateModel>, payload: UpdateVariable): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .post('api/devices/update-variable', { ...payload })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        }

        if (result && result.status === HTTP_STATUS.ACCESS_DENIED) {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'devices.popup.notPermission');
        }
    }

    @Action(GetUnitsForUser)
    async getUnitsForUser(ctx: StateContext<DevicesStateModel>): Promise<void> {
        const state = ctx.getState();

        const currentUserId = this.store.selectSnapshot(UserState.getAdminUserId);

        let headers;

        if (currentUserId) {
            headers = new HttpHeaders().set('currentUserId', currentUserId);
        } else {
            headers = new HttpHeaders();
        }

        const result: ApiResponse = (await this.http
            .get('api/devices/units', { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                units: result.data,
            });
        }
    }

    @Action(SetDeviceConfiguration)
    async setDeviceConfiguration(ctx: StateContext<DevicesStateModel>, payload: SetDeviceConfiguration): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .post('api/devices/device-configuration', { ...payload.configuration })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        const currentDevice = devices.find((d) => d.id === payload.configuration.id);
        const currentRegistrator = currentDevice.type === DeviceTypeEnum.registrator ? currentDevice : devices.find((d) => d.id === currentDevice.registratorId);
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        } else if (result && result.status === HTTP_STATUS.REGISTRATOR_IS_OFFLINE) {
            const message = await this.translateService
                .get('detailsDevice.registratorOffline', {
                    registrator: currentRegistrator.name || currentRegistrator.defaultName,
                })
                .toPromise();
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, message);
        }
    }

    @Action(GetAssociatedLogicEvents)
    async getAssociatedLogicEvents(ctx: StateContext<DevicesStateModel>, payload: GetAssociatedLogicEvents): Promise<void> {
        const result: ApiResponse<LogicEvent[]> = (await this.http
            .get('/api/logic-events/associated', {
                params: {
                    deviceId: payload.deviceId,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        ctx.setState({
            ...state,
            errorListEvent: result.data,
        });
    }

    @Action(IsShowDeletePopup)
    isShowDeletePopup(ctx: StateContext<DevicesStateModel>, payload: IsShowDeletePopup): void {
        const state = ctx.getState();

        if (!payload.show) {
            ctx.setState({
                ...state,
                errorListEvent: [],
            });
        }

        ctx.setState({
            ...state,
            showDeletePopup: payload.show,
        });
    }

    @Action(InitRegistratorOptions)
    initRegistratorOptions(ctx: StateContext<DevicesStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            registratorOptions: state.devices
                .filter((d) => d.type === DeviceTypeEnum.registrator && d.creationType !== CreationType.MNEMONIC)
                .map((d) => {
                    return {
                        key: d.id,
                        property: d.id,
                        value: d.name ?? d.defaultName,
                        type: 'text',
                    };
                })
                .sort((a, b) => (a.value.toLowerCase() < b.value.toLowerCase() ? -1 : a.value.toLowerCase() > b.value.toLowerCase() ? 1 : 0)),
        });
    }
}

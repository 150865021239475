import { NotificationGroup } from '../_interfaces/mailing-groups.interfaces';
import { Injectable } from '@angular/core';
import { TypeFilterEnum } from 'src/app/app-shared-elements/filters/enums/type-filter.enum';
import { DropdownFilterOptionInterface } from 'src/app/app-shared-elements/filters/interfaces/filter-option.interface';
import { ColumnsTableInterface, ColumnTypeEnum, ColumnValueAlignEnum } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { StatusNotificationShare } from '../_enums/status-notification-share.enum';
import { RecipientsUserInterface } from '../_interfaces/mailing-recipient-row.interface';

@Injectable({
    providedIn: 'root',
})
export class MailingGroupsService {
    groupsTableColumns: ColumnsTableInterface[] = [
        {
            title: 'mailing.groups.tables.act',
            grow: false,
            small: true,
            maxWidth: '85px',
            minWidth: '85px',
            type: ColumnTypeEnum.icon,
            name: 'isActive',
        },
        {
            title: 'mailing.groups.tables.name',
            grow: true,
            small: false,
            maxWidth: '320px',
            type: ColumnTypeEnum.text,
            name: 'name',
            align: ColumnValueAlignEnum.left,
        },
        {
            title: 'mailing.groups.tables.users',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'users',
            allowHtmlTags: true,
        },
        {
            title: 'devices.actions',
            grow: false,
            small: true,
            maxWidth: '100px',
            minWidth: '85px',
            type: ColumnTypeEnum.action,
            name: 'edit',
            actionBtns: [ColumnsActionTypeEnum.actionBtnsEdit, ColumnsActionTypeEnum.actionBtnsDelete],
        },
    ];

    dropDownFilterOptions: DropdownFilterOptionInterface[] = [
        { key: 'name', value: 'mailing.groups.dropdownFilters.name', type: TypeFilterEnum.text, property: 'name' },
        { key: 'usersId', value: 'mailing.groups.dropdownFilters.users', type: TypeFilterEnum.text, property: 'usersId' },
    ];

    groups: NotificationGroup[];

    constructor() {}

    parseToUserEmails(group: NotificationGroup): string {
        if (!group || !group.recipientUsers || !group.recipientUsers.length) {
            return '';
        }

        return group.recipientUsers.reduce((prev: string, curr: RecipientsUserInterface) => {
            if (!curr) {
                return prev + '';
            }

            return (prev += `<span class="${!curr.isActive ? 'disable' : ''}" style="${this.getCurrentUserStyle(curr, group.isActive)}">${
                group.recipientUsers?.length > 1 ? curr.email + ';' : curr.email
            } </span> `);
        }, '');
    }

    getCurrentUserStyle(user: RecipientsUserInterface, isActive: boolean): any {
        if (!isActive) {
            return 'color: var(--tableTrDisableColor);';
        }

        const result = 'margin: 0 4px 0 0;';
        switch (user.status) {
            case StatusNotificationShare.ACCEPTED:
                return result + 'color: var(--userDeviceCellOk);';
            case StatusNotificationShare.REJECT:
                return result + 'color: var(--userDeviceCellAlarm);';

            case StatusNotificationShare.PROCESS:
                return result + 'color: var(--userDeviceCellAttention);';
        }
    }
}

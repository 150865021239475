import {Device} from '../../../app-shared-elements/_interfaces/Device';
import {SelectOptionInterface} from '../../../app-shared-elements/_interfaces/select-option.interface';

export class GetMnemonic {
    static readonly type = '[Mnemonic] GetMnemonic';

    constructor(public registratorId: string) {}
}

export class UpdateMnemonic {
    static readonly type = '[Mnemonic] UpdateMnemonic';

    constructor(public data: { id: string; data: any; variablesId: string[] }) {}
}

export class GetMnemonicImages {
    static readonly type = '[Mnemonic] GetMnemonicImages';

    constructor(public registratorId: string) {}
}

export class UploadMnemonicImages {
    static readonly type = '[Mnemonic] UploadMnemonicImages';

    constructor(public registratorId: string, public files: File[]) {}
}

export class UpdateMnemonicImageName {
    static readonly type = '[Mnemonic] UpdateMnemonicImageName';

    constructor(public data: { id: string; name: string }) {}
}

export class DeleteMnemonicImage {
    static readonly type = '[Mnemonic] DeleteMnemonicImage';

    constructor(public id: string) {}
}

export class SetCurrentObject {
    static readonly type = '[Mnemonic] SetCurrentObject';

    constructor(public object: any) {}
}

export class UpdateCurrentObject {
    static readonly type = '[Mnemonic] UpdateCurrentObject';

    constructor(public object: any) {}
}

export class InitDeviceOptions {
    static readonly type = '[Mnemonic] InitDeviceOptions';
}

export class InitVariableOptions {
    static readonly type = '[Mnemonic] InitVariableOptions';

    constructor(public currentDevice: SelectOptionInterface<string, string, Device>) {}
}

export class ToggleDevicePopup {
    static readonly type = '[Mnemonic] ToggleDevicePopup';

    constructor(public toggle: boolean) {}
}

export class ToggleImagePopup {
    static readonly type = '[Mnemonic] ToggleImagePopup';

    constructor(public toggle: boolean) {}
}

export class SetIsEditing {
    static readonly type = '[Mnemonic] SetIsEditing';

    constructor(public isEdit: boolean) {}
}

export class UpdateMnemonicData {
    static readonly type = '[Mnemonic] UpdateMnemonicData';

    constructor(public devices: Device[]) {}
}

export class SetMnemonicObjects {
    static readonly type = '[Mnemonic] SetMnemonicObjects';

    constructor(public objects: any) {}
}

export class UpdateObjectValue {
    static readonly type = '[Mnemonic] UpdateObjectValue';

    constructor(public data: {}, public isNotSaveState?: boolean) {}
}

export class SaveCanvasState {
    static readonly type = '[Mnemonic] SaveCanvasState';

    constructor(public state: any) {}
}

export class UndoCanvasState {
    static readonly type = '[Mnemonic] UndoCanvasState';
}

export class RedoCanvasState {
    static readonly type = '[Mnemonic] RedoCanvasState';
}

export class ToggleSidebar {
    static readonly type = '[Mnemonic] ToggleSidebar';

    constructor(public toggle: boolean) {
    }
}

import {Device} from '../../app-shared-elements/_interfaces/Device';
import {IconInterface} from '../../app-shared-elements/_interfaces/ColumnsTable';

export interface DeletedDevicesRowsInterface extends Device {
    preIcons: IconInterface<DeletedDeviceIconAction>[];
    iconType?: string;
    styles: {
        iconType: any;
    };
}

export enum DeletedDeviceIconAction {
    recovery = 'recovery'
}

<div class="roles-info">
    <ng-container *ngFor="let role of roles$ | async">
        <div class="roles-info__item">
            <span class="roles-info__item-title">{{role.name}}</span>
            <div class="roles-info__permission">
                <div *ngFor="let permission of role.permissions; let last = last" class="roles-info__permission-item">
                    <ng-container *ngIf="permission.isRead || permission.isUpdate">
                        <span [class.roles-info__permission-item--active]="permission.isUpdate"
                                  [translate]="'permissions.' + permission.resource"></span><span *ngIf="!last">,</span>
                    </ng-container>

                </div>
            </div>
        </div>
    </ng-container>
</div>

<div class="roles-info__annotation">
    <div class="roles-info__annotation-item">
        <div class="roles-info__square"></div>
        <span [translate]="'detailsDevice.roleView'"></span>
    </div>
    <div class="roles-info__annotation-item">
        <div class="roles-info__square"></div>
        <span [translate]="'detailsDevice.roleEdit'"></span>
    </div>
</div>

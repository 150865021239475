<div class="device-log">
    <app-heading [title]="'breadCrumbs.deviceLogs'"></app-heading>
    <app-filters
        [type]="filterItemTypeEnum.event"
        [journalEditBtn]="true"
        [optionsDropDown]="deviceLogsService.dropDownFilterOptions"
        [optionsFoDropDownSelect]="registratorsOptions"
        [defaultDropdownValue]="defaultDropdownValue"
        [filter]="(params$ | async).filter"
        [currentValueForDropdownInput]="currentValueForDropdownInput"
        (editBtnsEvent)="openEdit()"
        (setFilter)="timeFilterChanged($event)"
        (currentFilterDropDown)="onChangeDropDownLogs($event)"
        (filterEvent)="changeFilter($event)"></app-filters>

    <app-tables
        [unHidden]="true"
        [currentTableColumns]="deviceTablesColumns"
        [rows]="rowsLogs$ | async"
        [isGroupOperations]="(choosenRowIds$ | async) && (choosenRowIds$ | async).length"
        (sortOutput)="onChangeSortLogs($event)"
        (openSmth)="openDetailsDevice($event)"
        (paginationOutput)="onChangePageLogs($event)"
        [configPagination]="(params$ | async).pagination"
        (eventClickBtnFromColumn)="setAcknowledgeEvent($event)"
        (chooseFromGroupOperationEvent)="setGroupOperations($event)">
    </app-tables>
</div>


<app-popup *ngIf="showPopup" [title]="'table.deviceLogs.addToTable'" (closeEmit)="showPopup = false">
    <div class="popup">

        <ng-container *ngFor="let item of deviceLogsService.deviceLogTableColumnsOptional">
            <div class="popup__item">
                <span (click)="dataChanged(item)" class="popup__item-title" [translate]="item.title"></span>
                <app-checkbox [value]="deviceLogColumns.includes(item.name)"
                    (changeEvent)="dataChanged(item)"></app-checkbox>
            </div>
        </ng-container>

    </div>
</app-popup>

<div class="tooltip"
     [class.tooltip--template]="template"
     [class.tooltip--left]="side === tooltipSideEnum.left"
     [class.tooltip--bottom]="side === tooltipSideEnum.bottom"
     [class.tooltip--top]="side === tooltipSideEnum.top"
     [class.tooltip--top-right]="side === tooltipSideEnum.topRight"
     [style.width]="width"
     [style.maxWidth]="maxWidth || '180px'"
>
    <div *ngIf="!template; else templateBlock" class="tooltip__value"
         [class.tooltip__value--white-space]="isWhiteSpace">
        <span>{{value | translate}}</span>
        <!-- <ng-template #errorStatus>
            <span class="tooltip__error-status">{{errorStatusStr}}</span>
        </ng-template> -->
    </div>
    <ng-template #templateBlock>
        <div class="tooltip__value tooltip__value--template">
            <ng-content>

            </ng-content>
        </div>
    </ng-template>
</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {ProfileState} from 'src/app/profile/_store/states/profile.state';
import {Observable} from 'rxjs';
import {User} from 'src/app/app-shared-elements/_interfaces/user.interface';
import {SetUserIdForLogsFromAdmin} from 'src/app/journals/_store/actions/user-logs.actions';
import {filter, first} from 'rxjs/operators';

@Component({
    selector: 'app-admin-profile-logs',
    templateUrl: './admin-profile-logs.component.html',
    styleUrls: ['./admin-profile-logs.component.scss']
})
export class AdminProfileLogsComponent implements OnInit, OnDestroy {

    @Select(ProfileState.getProfileUser) profileUser$: Observable<User>;

    constructor(
        private store: Store
    ) {
        this.profileUser$.pipe(filter(user => !!user), first()).subscribe(user => {
            this.store.dispatch(new SetUserIdForLogsFromAdmin(user.id));
        });

    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
    }

}

import {ParamsFilterTypeEnum, ParamsTimeTypeEnum} from 'src/app/app-shared-elements/_interfaces/params.interface';

export const expeditionFilter = [
    // datetime
    {
        property: 'start',
        value: {
            to: null,
            from: null,
            time: null,
            type: ParamsTimeTypeEnum.ALL_TIME
        },
        type: ParamsFilterTypeEnum.DATETIME
    },

    // drop down
    {
        property: 'registratorId',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true
    }
];

import {EventEmitter, Injectable} from '@angular/core';
import {Group} from '../../../../app-shared-elements/_interfaces/Group';
import {ConfigurationVariableSavedEnum, Variable, VariableType} from 'src/app/app-shared-elements/_interfaces/Variable';
import {Device} from 'src/app/app-shared-elements/_interfaces/Device';
import {Subject} from 'rxjs';
import {NotificationsService} from 'src/app/app-shared-elements/_services/notifications.service';
import {TooltipStatusEnum} from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../../app-shared-elements/_interfaces/user.interface';
import {CheckboxPropertiesDataInterface, CheckboxPropertiesInterface, ColumnModeEnum, ColumnsTableInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import {CreationType} from 'src/app/app-shared-elements/_enums/registrator-sync-status.enu';

@Injectable({
    providedIn: 'root',
})
export class GroupService {


    refreshColorGroup$: Subject<Group> = new Subject<Group>(); // if we add/removed varaible with alarm/attention from/to group

    private timerHandler;
    private timerTime = 1000;
    timerStopEvent: EventEmitter<void> = new EventEmitter<void>();

    private successList: string[] = [];
    private failList: string[] = [];


    constructor(
        private notificationsService: NotificationsService,
        private translateService: TranslateService
    ) {

    }

    getPosition(group: Group, user: User): number {
        if (!user) {
            return;
        }
        const pos = JSON.parse(localStorage.getItem(`groupPosition${user.id}`));

        if (!pos) {
            return;
        }

        const position = pos.find((p) => group.id === p.id);

        if (!position) {
            return null;
        }

        return position.position;
    }

    getColumn(group: Group, user: User): number {
        if (!user) {
            return;
        }
        const col = JSON.parse(localStorage.getItem(`groupPosition${user.id}`));

        if (!col) {
            return;
        }

        const column = col.find((p) => group.id === p.id);

        if (!column) {
            return;
        }

        return column.column;
    }

    getIsFold(group: Group, user: User): boolean {
        if (!user) {
            return;
        }
        const fold = JSON.parse(localStorage.getItem(`groupsFold${user.id}`));
        if (!fold) {
            return;
        }

        const isFold = fold.find((f) => group.id === f.id);
        if (!isFold) {
            return;
        }
        return isFold.isFold;
    }

    initTimer(): void {
        clearTimeout(this.timerHandler);
        this.timerHandler = setTimeout(() => {
            this.stopTimer();
            this.showNotificationTooltip();
        }, this.timerTime);
    }

    private stopTimer(): void {
        this.timerStopEvent.emit();
    }

    addSuccessList(variable: Variable): void {
        this.successList.push(`"${variable.customName ?? variable.name}"`);
    }

    addFailList(variable: Variable): void {
        this.failList.push(variable.customName ?? variable.name);
    }

    private resetList(): void {
        this.successList = [];
        this.failList = [];
    }

    private async showNotificationTooltip(): Promise<void> {
        if (this.successList.length) {
            const key = this.successList.length > 1 ? 'groups.settings.successSavedVariables' : 'groups.settings.successSavedVariable';
            const successMessage = await this.translateService.get(key, {variableNames: Array.from(new Set(this.successList)).join(', ')}).toPromise();
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, successMessage);
        }

        if (this.failList.length) {
            const key = this.failList.length > 1 ? 'groups.settings.failSavedVariables' : 'groups.settings.failSavedVariable';

            const failMessage = await this.translateService.get(key, {variableNames: Array.from(new Set(this.failList)).join(', ')}).toPromise();
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, failMessage);
        }


        this.resetList();
    }

    getCheckboxProperties(variable: Variable, cells: ColumnsTableInterface[], currentDevice: Device, isFreeze?: boolean): CheckboxPropertiesInterface {
        return cells.filter(item => item.mode && item.mode === ColumnModeEnum.checkbox).reduce((pre, cur): CheckboxPropertiesInterface => {
            return {
                ...pre,
                [cur.name as string]: this.getCheckboxPropertiesData(variable, cur, currentDevice, isFreeze)
            };
        }, {});
    }

    private getCheckboxPropertiesData(variable: Variable, cell: ColumnsTableInterface, currentDevice: Device, isFreeze?: boolean): CheckboxPropertiesDataInterface {
        return {
            isFreeze: isFreeze || false,
            isError: (cell.name === 'chart' || cell.name === 'table') && variable.savedStatusConfiguration === ConfigurationVariableSavedEnum.error,
            isWaiting: (cell.name === 'chart' || cell.name === 'table') && variable.savedStatusConfiguration === ConfigurationVariableSavedEnum.wait,
            isDisabled: (cell.name === 'archive' || (currentDevice.creationType === CreationType.VIRTUAL && !variable.archived && cell.isDisable)) || false,
            isCheckbox: this.getIsCheckbox(cell, variable)
        };
    }

    private getIsCheckbox(cell: ColumnsTableInterface, variable: Variable): boolean {
        switch (cell.name) {
            case 'control':
                return !(cell.name === 'control' && !variable.writable);
            case 'chart':
                const types: VariableType[] = [VariableType.INT, VariableType.FLOAT, VariableType.SHORT, VariableType.BYTE];
                return types.includes(variable.type);
            default:
                return true;
        }
    }
}

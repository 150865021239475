import { Group } from '../../../app-shared-elements/_interfaces/Group';
import { Variable, VariableForControlRowInterface } from '../../../app-shared-elements/_interfaces/Variable';

export class SetVariablesForControl {
    static readonly type = '[ControlGroups] SetVariablesForControl';

    constructor(public currentGroup: Group) {}
}

export class UpdateVariablesStatusSavedForControlGroupRows {
    static readonly type = '[ControlGroups] UpdateVariablesForControlGroupRows';

    constructor(public variables: Variable[]) {}
}

export class SetNewValueInVariable {
    static readonly type = '[ControlGroups] SetNewValueInVariable';

    constructor(public event: { row: VariableForControlRowInterface; event: string }) {}
}

export class RevertNewValueInVariable {
    static readonly type = '[ControlGroups] RevertNewValueInVariable';

    constructor(public variableId: string) {}
}

import { SharedUserInterface } from '../../user-devices/_interface/shared-user.interface';

export class SetSharingDevice {
    static readonly type = '[ShareDevices] SetSharingDevice';

    constructor(public registratorId: string) {}
}

export class InitSharedUserList {
    static readonly type = '[ShareDevices] InitSharedUserList';
}

export class AddUserInNewSharingList {
    static readonly type = '[ShareDevices] AddUserInNewSharingList';

    constructor(public user: SharedUserInterface) {}
}

export class ChangeRoleInOldUser {
    static readonly type = '[ShareDevices] ChangeRoleInOldUser';

    constructor(
        public id: number,
        public roleOption: any,
    ) {}
}

export class ChangeRoleInNewUser {
    static readonly type = '[ShareDevices] ChangeRoleInNewUser';

    constructor(
        public id: string,
        public roleOption: any,
    ) {}
}

export class SaveSharingList {
    static readonly type = '[ShareDevices] SaveSharingList';

    constructor(public message: string) {}
}

export class CheckShareUser {
    static readonly type = '[ShareDevices] CheckShareUser';
    constructor(public data: { emails: string[]; registratorId: string }) {}
}

export class ClearNewShareUserList {
    static readonly type = '[ShareDevices] ClearNewShareUserList';
}

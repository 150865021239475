import { Injectable } from '@angular/core';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { ActionListItemInterface } from '../../../app-shared-elements/_interfaces/action-list-item.interface';
import { ActionListItemEnum } from '../../../app-shared-elements/_enums/action-list-item.enum';

@Injectable({
    providedIn: 'root',
})
export class AdminDevicesService {
    constructor() {}

    getListAction(device: Device): ActionListItemInterface[] {
        if (device.isDeleted) {
            return [
                { title: 'btns.detail', action: ActionListItemEnum.detail },
                { title: 'btns.access', action: ActionListItemEnum.access },
                { title: 'btns.delete', action: ActionListItemEnum.delete },
            ];
        } else {
            return [
                { title: 'btns.detail', action: ActionListItemEnum.detail },
                {
                    title: 'btns.access',
                    action: ActionListItemEnum.access,
                },
            ];
        }
    }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { LangEnum } from '../_enums/lang.enum';
import { ChangeLanguage } from '../_store/actions/language.actions';
import { PrimeNGConfig } from 'primeng/api';
import { UpdateAdmin, UpdateUser } from '../_store/actions/user.actions';
import { UserState } from '../_store/states/user.state';
import { AuthState } from '../../auth/_store/states/auth.state';

@Component({
    selector: 'app-lang',
    templateUrl: './lang.component.html',
    styleUrls: ['./lang.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LangComponent implements OnInit {
    lns: LangEnum[] = [LangEnum.ua, LangEnum.en];

    currentLn: LangEnum = LangEnum.en;
    showMobileList = false;
    openLn = false;

    readonly ARROW_ICON_PATH = './assets/design/icons/nav/nav-arrow.svg';

    constructor(private translateService: TranslateService, private store: Store, private config: PrimeNGConfig) {
        this.getCurrentLang();
    }

    ngOnInit(): void {}

    getCurrentLang(): void {
        if (!(localStorage.getItem('ln') as LangEnum)) {
            this.translateService.use(LangEnum.en);
        }

        this.currentLn = this.store.selectSnapshot(UserState.getUser)?.lang ?? (localStorage.getItem('ln') as LangEnum) ?? LangEnum.en;
        this.translateService.use(this.currentLn);
    }

    changeLn(ln: LangEnum): void {
        this.translateService.use(ln);
        this.translateService.get('primeng').subscribe((res) => this.config.setTranslation(res));
        this.store.dispatch(new ChangeLanguage(ln));
        const data = this.store.selectSnapshot(UserState.getUser);

        if (data) {
            const isAdmin = this.store.selectSnapshot(AuthState.getIsAdmin);
            isAdmin
                ? this.store.dispatch(new UpdateAdmin({ ...data, lang: ln }))
                : this.store.dispatch(new UpdateUser({ ...data, lang: ln }));
        }

        this.showMobileList = false;
    }
}

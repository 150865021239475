import { Observable, Subject, Subscription } from 'rxjs';
import { GroupsDataService } from '../../_services/groups-data.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartService } from './_services/chart.service';
import { ChartNavigatorService } from './_services/chart-navigator.service';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { Group } from '../../../app-shared-elements/_interfaces/Group';
import { Variable } from '../../../app-shared-elements/_interfaces/Variable';
import { DeviceService } from '../../../device-dashboard/_services/device.service';
import { ArchiveService } from '../../../app-shared-elements/_services/archive.service';
import { ChartSettingsService } from './_services/chart-settings.service';
import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { ArchiveChartInterface, ArchiveRow, LimitsInterface } from '../../../app-shared-elements/_interfaces/ArchiveChart';
import { PreloaderService } from 'src/app/app-shared-elements/_services/preloader.service';
import { Select, Store } from '@ngxs/store';
import { GroupsState } from '../../_store/states/groups.state';
import { takeUntil } from 'rxjs/operators';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { ChartTypeEnum } from '../../_enums/chart-tpe.enum';
import { MethodPermission, ResourceAction } from 'src/app/app-shared-elements/_enums/permission.enum';
import { LayoutEnum } from 'src/app/app-shared-elements/_enums/layout.enum';
import { ChartState, ErrorMessageChartState } from '../../_store/states/chart.state';
import { ChartModeEnum } from 'src/app/app-shared-elements/_enums/chart-mode.enum';
import { ChangeChartMode, ClearScaleButtons, InitScaleButtons, ReReadArchive, SetArchivePreloaderData, SetDatepickerConfigForReread, SetIsDataLimited, SetIsEmptyArchive } from '../../_store/actions/charts.actions';
import { LayoutState } from 'src/app/app-shared-elements/_store/states/layout.state';
import { VariableWithArchive } from 'src/app/app-shared-elements/_interfaces/VariableWithArchive';
import { SetDisabledTimeOptions, SetFilterConfig, SetFilterType, SetTimeObj } from 'src/app/app-shared-elements/_store/actions/time-filter.actions';
import { TimeFilterState } from 'src/app/app-shared-elements/_store/states/time-filter.state';
import { ArchivePreloaderData } from 'src/app/app-shared-elements/_interfaces/archive-preloader-data.interface';
import { Axis } from 'src/app/app-shared-elements/_interfaces/Axis';
import { DisabledFilterOptionsEnum } from 'src/app/app-shared-elements/_enums/filter-options.enum';
import { VariablesNameEnum } from 'src/app/app-shared-elements/_enums/variables-name.enum';
import { FilterTypeEnum } from 'src/app/app-shared-elements/_enums/filter-type.enum';
import { SocketService } from 'src/app/app-shared-elements/_services/socket.service';
import { SocketEvent } from 'src/app/app-shared-elements/_enums/socket-event.enum';
import { SubscribeOnArchiveOfClientNotify } from '../../_interfaces/subscribe-on-archive-of-socket-data.interface';
import { ConnectEnum } from 'src/app/app-shared-elements/_enums/connect.enum';
import { ThemeService } from 'src/app/theme/theme.service';
import { ThemeEnum } from 'src/app/theme/_enums/theme.enum';
import { ChartErrorTypeInterface } from '../../_interfaces/chart-error-type.interface';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { ChangeAxisScaleOperator } from '../../_enums/change-axis-scale-operator.enum';
import { ChartScaleButtonInterface } from '../../_interfaces/chart-scale-button.interface';
import { ChartNavigatorRangeInterface } from '../../_interfaces/chart-navigarot-range.interface';
import { TooltipSideEnum } from '../../../app-shared-elements/_enums/tooltip-side.enum';
import { ParamsTime, ParamsTimeTypeEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { StateReset } from 'ngxs-reset-plugin';
import { ContainerService } from '../../_services/container.service';
import { ChartEventsEnum } from '../../_enums/chart-events.enum';
import { SetEditMode } from '../../_store/actions/groups.actions';
import { TimeOptionsInterface } from '../../../app-shared-elements/_interfaces/time-options.interface';
import { RereadingEnum } from '../../_enums/rereading.enum';
import { RangeBoxEventInterface } from '../../_interfaces/range-box-event.interface';
import { DatepickerService } from 'src/app/app-shared-elements/_services/datepicker.service';
import { DatePickerConfigInterface } from 'src/app/app-shared-elements/_interfaces/date-picker-config.interface';
import { GetLogicalEvents } from 'src/app/events/_store/actions/logical-events.actions';
import { Router } from '@angular/router';
import { AuthState } from '../../../auth/_store/states/auth.state';
import { RegistratorTypeEnum } from '../../../app-shared-elements/_enums/registrator-type.enum';
import { DataloggerModificationEnum } from '../../../details-device-container/_enums/datalogger-modification.enum';

@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('container') container: ElementRef;
    @ViewChild('chart') chart: ElementRef;

    @Input() chartWidthForReports: number;
    @Input() type: ChartTypeEnum;

    chartTypeEnum = ChartTypeEnum;

    isChart = true;

    private currentRegistrator: Device;
    private currentDevice: Device;
    private currentGroup: Group;

    private groupsSaveSubscription: Subscription;
    private onLangChangeSubscription: Subscription;

    isDataLimited = false;
    tooltipSideEnum = TooltipSideEnum;
    private archTootipSubscription: Subscription;

    private chartWidth;

    isDetailDate = localStorage.getItem('isDetailDate') === 'true' ? true : false;

    private archResult: VariableWithArchive[] = [];
    private archResultActiv = null;

    private monthKeys = [
        'charts.month.jan',
        'charts.month.feb',
        'charts.month.mar',
        'charts.month.apr',
        'charts.month.may',
        'charts.month.jun',
        'charts.month.jul',
        'charts.month.aug',
        'charts.month.sep',
        'charts.month.oct',
        'charts.month.nov',
        'charts.month.dec',
    ];

    private isChartCreating: boolean;

    @Select(GroupsState.getActiveGroup) currentGroup$: Observable<Group>;
    @Select(DevicesState.getCurrentRegistrator) currentRegistrator$: Observable<Device>;
    @Select(DevicesState.getCurrentDevice) currentDevice$: Observable<Device>;
    @Select(DevicesState.getIsLoadDevices) isLoadeDevices$: Observable<boolean>;
    @Select(ChartState.getChartMode) chartMode$: Observable<ChartModeEnum>;
    @Select(ChartState.getDatepickerConfigForReread) datepickerConfigForReread$: Observable<DatePickerConfigInterface>;
    @Select(LayoutState.getGroupLayout) layout$: Observable<LayoutEnum>;
    @Select(ChartState.getIsDataLimited) isDataLimited$: Observable<boolean>;
    @Select(ErrorMessageChartState.getIsEmptyArchive) isEmptyArchive$: Observable<ChartErrorTypeInterface>;
    @Select(TimeFilterState.getTimeObj) timeObj$: Observable<ParamsTime>;
    @Select(ChartState.getChartScaleButtons) scaleButtons$: Observable<ChartScaleButtonInterface[]>;
    @Select(LayoutState.getFullScreen) isFullScreen$: Observable<boolean>;
    @Select(AuthState.getIsAdmin) isAdmin$: Observable<boolean>;

    private destroy: Subject<boolean> = new Subject<boolean>();

    private subscribeOnArchoveHandler;
    private dynamicChartHandler;
    private dynamicChartHandlerInAdmin;
    private currentArchivePeriod: number;

    private errorConnectSubscribe;
    fromToByRangeBox: ParamsTime;
    isReReadPopup = false;

    readonly ATTENTION_ICON_PATH = './assets/design/icons/chart/attention-logic.svg';
    readonly ATTENTION_DARK_ICON_PATH = './assets/design/icons/chart/attention-logic-dark.svg';
    readonly ALARM_ICON_PATH = './assets/design/icons/chart/alarm-logic.svg';
    readonly ALARM_DARK_ICON_PATH = './assets/design/icons/chart/alarm-logic-dark.svg';
    readonly STATUS_ICON_PATH = './assets/design/icons/chart/status-event.svg';
    readonly UPDATE_ICON_PATH = './assets/design/icons/chart/update-chart.svg';

    resourceAction = ResourceAction;
    methodPermission = MethodPermission;
    reportsMode;
    currentModification: Variable;
    private timeObj: ParamsTime;
    ParamsTimeTypeEnum = ParamsTimeTypeEnum;
    chartModeEnum = ChartModeEnum;
    currentTheme: ThemeEnum;
    themeEnum = ThemeEnum;
    changeAxisScaleOperator = ChangeAxisScaleOperator;
    detailHeight: number;
    dataloggerModificationEnum = DataloggerModificationEnum;

    isShowUpdateChart = false;
    currentArchivePeriodInAdmin: number;

    constructor(
        public chartService: ChartService,
        private chartSettingsService: ChartSettingsService,
        private archiveService: ArchiveService,
        private deviceService: DeviceService,
        private chartNavigatorService: ChartNavigatorService,
        private translateService: TranslateService,
        private groupsDataService: GroupsDataService,
        private preloaderService: PreloaderService,
        private ngZone: NgZone,
        private socketService: SocketService,
        public themeService: ThemeService,
        private store: Store,
        private notificationsService: NotificationsService,
        private containerService: ContainerService,
        private datePickerService: DatepickerService,
        public router: Router,
    ) {
        this.store.dispatch(new SetFilterType(FilterTypeEnum.chart));
        this.store.dispatch(
            new SetFilterConfig({
                type: 'date',
                disableFunctions: [DisabledFilterOptionsEnum.allTime, DisabledFilterOptionsEnum.current],
            }),
        );

        this.reportsMode = window.location.search.search('reports=true') !== -1;

        this.currentRegistrator$.pipe(takeUntil(this.destroy)).subscribe(async (registrator) => {
            if (registrator) {
                this.currentRegistrator = registrator;
            }
        });

        this.chartSettingsService.graphEvents.pipe(takeUntil(this.destroy)).subscribe((event: { type: ChartEventsEnum; event?: RangeBoxEventInterface }) => {
            switch (event.type) {
                case ChartEventsEnum.configurationChanged:
                    this.initChart();
                    break;
                case ChartEventsEnum.selection:
                    this.fromToByRangeBox = {
                        ...this.store.selectSnapshot(TimeFilterState.getTimeObj),
                        type: ParamsTimeTypeEnum.RANGE,
                        time: null,
                        from: Math.floor(event.event.xAxis[0].min as number),
                        to: Math.floor(event.event.xAxis[0].max as number),
                    };
                    break;
            }
        });

        this.currentDevice$.pipe(takeUntil(this.destroy)).subscribe((device: Device) => {
            if (!device) {
                return;
            }
            this.currentDevice = device;
            this.currentModification = device.variables.find((f) => f.name === VariablesNameEnum.Modification);
        });

        this.timeObj$.pipe(takeUntil(this.destroy)).subscribe((timeObj) => {
            this.timeObj = { ...timeObj };

            if (timeObj.type === ParamsTimeTypeEnum.RANGE) {
                clearInterval(this.dynamicChartHandler);
                this.chartService.initiUnSubscribeToServerData();
            }
        });

        this.groupsDataService.timeFilter$.pipe(takeUntil(this.destroy)).subscribe(async () => {
            await this.chooseMode(this.store.selectSnapshot(ChartState.getChartMode));
            const variablesIdsForShow = this.store.selectSnapshot(ChartState.getVariablesIdsForShow);
            this.chartService.updateSelectedVariablesOnChart(variablesIdsForShow);
        });

        this.deviceService.redrawChartEvent.pipe(takeUntil(this.destroy)).subscribe(async () => {
            this.currentGroup = this.store.selectSnapshot(GroupsState.getActiveGroup);
            this.chooseMode(this.store.selectSnapshot(ChartState.getChartMode));
        });

        this.chartNavigatorService.graphEvents.pipe(takeUntil(this.destroy)).subscribe(async (range: ChartNavigatorRangeInterface) => {
            if (this.store.selectSnapshot(ChartState.getChartMode) === ChartModeEnum.hole) {
                return;
            }
            await this.updateDetailByRange(range);
        });

        this.onLangChangeSubscription = this.translateService.onLangChange.pipe(takeUntil(this.destroy)).subscribe(() => this.chooseMode(this.store.selectSnapshot(ChartState.getChartMode)));

        this.groupsDataService.resetInterval$.pipe(takeUntil(this.destroy)).subscribe(async () => {
            await this.resetInterval();
        });

        this.themeService.activeTheme$.pipe(takeUntil(this.destroy)).subscribe((currentTheme: ThemeEnum) => {
            this.currentTheme = currentTheme;
            this.chooseMode(this.store.selectSnapshot(ChartState.getChartMode));
        });

        this.subscribeOnArchoveHandler = async (data: SubscribeOnArchiveOfClientNotify) => {
            // console.log(data);
            if (data && data.variablesId) {
                clearInterval(this.dynamicChartHandler);
                await this.dynamicDataUpdate(data.variablesId);
                // if (this.currentArchivePeriod) {
                //     this.dynamicChartHandler = setInterval(async () => {
                //         await this.dynamicDataUpdate(data.variablesId);
                //     }, this.currentArchivePeriod * 1.5);
                // }
            }
        };

        this.socketService.on(SocketEvent.SUBSCRIBE_ON_ARCHIVE, this.subscribeOnArchoveHandler);

        this.containerService.wrapperHeight.pipe(takeUntil(this.destroy)).subscribe((height) => {
            if (!height) {
                return;
            }
            this.detailHeight = height;
        });

        if (this.store.selectSnapshot(AuthState.getIsAdmin)) {
            this.dynamicChartHandlerInAdmin = setInterval(() => {
                this.isShowUpdateChart = true;
                // console.log(this.currentArchivePeriodInAdmin);
            }, this.currentArchivePeriodInAdmin ?? 60000);
        }
    }

    async updateChartInAdmin(): Promise<void> {
        const variables: Variable[] = this.getVariablesForChart(this.currentGroup);
        await this.dynamicDataUpdate(variables.map((v) => v.id));
        clearInterval(this.dynamicChartHandlerInAdmin);
        this.isShowUpdateChart = false;

        this.dynamicChartHandlerInAdmin = setInterval(() => {
            this.isShowUpdateChart = true;
            // console.log(this.currentArchivePeriodInAdmin);
        }, this.currentArchivePeriodInAdmin ?? 90000);
    }

    private async showLoadTootips(list: string[]): Promise<void> {
        if (!this.currentRegistrator) {
            return;
        }

        if (!list || !list.length) {
            return;
        }
        const message = await this.translateService.get('charts.archiveToaster').toPromise();
        list.map((item) => {
            if (item === this.currentRegistrator.id) {
                this.notificationsService.onEmit(TooltipStatusEnum.load, true, message);
            }
        });
    }

    private async updateDetailByRange(range?: ChartNavigatorRangeInterface, mode?: ChartModeEnum): Promise<void> {
        if (!range) {
            await this.chooseMode(this.store.selectSnapshot(ChartState.getChartMode));
            return;
        }

        clearInterval(this.dynamicChartHandler);

        this.chartService.initiUnSubscribeToServerData();

        let min = Math.floor(range.tsMin);
        let max = Math.floor(range.tsMax);

        if (!min || !max) {
            const widthPx = this.chartService.master.plotWidth;
            const startTs = this.chartService.master.xAxis[0].min;
            const endTs = this.chartService.master.xAxis[0].max;
            const startPercentage = range.left / widthPx;
            const endPercentage = range.right / widthPx;

            const milisecondsInRange = endTs - startTs;

            min = startTs + milisecondsInRange * startPercentage;
            max = startTs + milisecondsInRange * endPercentage;
        }

        await this.chartService.updateDataInChart(min, max);
    }

    ngOnInit(): void {
        this.currentGroup$.pipe(takeUntil(this.destroy)).subscribe(async (group) => {
            if (!group) {
                return;
            }
            if (group && this.currentGroup && group.id === this.currentGroup.id) {
                return;
            }
            clearInterval(this.dynamicChartHandler);
            this.currentGroup = group;
            if (this.chartWidthForReports) {
                this.chartSettingsService.setChartWidth(this.chartWidth + 40);
            }

            if (this.timeObj && this.timeObj.type === ParamsTimeTypeEnum.ALL_TIME) {
                // this.initSubsToServerDataAboutNewVariables(this.getVariablesForChart(this.currentGroup));
            }

            // this.store.dispatch(new ChangeChartMode(ChartModeEnum.chart));
            const timeType: ParamsTimeTypeEnum = this.store.selectSnapshot(TimeFilterState.getTimeType);
            if (timeType !== ParamsTimeTypeEnum.RANGE) {
                const timeObj: ParamsTime = this.store.selectSnapshot(TimeFilterState.getTimeObj);

                const currentTimeOption: TimeOptionsInterface = this.store.selectSnapshot(TimeFilterState.getCurrentTimeOption);

                this.store.dispatch(
                    new SetTimeObj({
                        ...timeObj,
                        from: Date.now() - currentTimeOption.property,
                        to: Date.now(),
                    }),
                );
            }
            // if (this.chartWidth || !this.chartWidth) {
            this.ngZone.runOutsideAngular(() => {
                setTimeout(async () => {
                    await this.chooseMode(this.store.selectSnapshot(ChartState.getChartMode));
                }, 300);
            });
            // await this.initChart();
        });
        this.errorConnectSubscribe = this.socketService.errorConnectSubscribe.subscribe(async (status: ConnectEnum) => {
            const variables: Variable[] = [];

            if (this.currentGroup) {
                return variables.push(...this.getVariablesForChart(this.currentGroup));
            }

            switch (status) {
                case ConnectEnum.RECONNECTED:
                    this.chartService.initSubsToServerDataAboutNewVariables(variables);
                    break;
            }
        });

        this.initSettings();
    }

    private async chooseMode(mode): Promise<void> {
        switch (mode) {
            case ChartModeEnum.alarm:
                await this.initChart(ChartModeEnum.alarm);
                break;
            case ChartModeEnum.chart:
                await this.initChart();
                break;
            case ChartModeEnum.chartAndAlarm:
                await this.initChart(ChartModeEnum.chartAndAlarm);
                break;
            case ChartModeEnum.hole:
                await this.initChart(ChartModeEnum.hole);
                break;
            default:
                await this.initChart();
        }
        this.chartNavigatorService.rangeBox = null;
    }

    async ngOnDestroy(): Promise<void> {
        clearInterval(this.dynamicChartHandler);

        this.destroy.next(true);
        this.destroy.complete();
        if (this.groupsSaveSubscription) {
            this.groupsSaveSubscription.unsubscribe();
        }

        if (this.onLangChangeSubscription) {
            this.onLangChangeSubscription.unsubscribe();
        }

        if (this.archTootipSubscription) {
            this.archTootipSubscription.unsubscribe();
        }

        if (this.errorConnectSubscribe) {
            this.errorConnectSubscribe.unsubscribe();
        }

        this.notificationsService.destroyNote();

        this.store.dispatch(new SetIsEmptyArchive({ isError: false, message: '' }));

        this.chartService.initiUnSubscribeToServerData();

        this.socketService.removeListener(SocketEvent.SUBSCRIBE_ON_ARCHIVE);

        this.store.dispatch(new SetDisabledTimeOptions([]));

        await this.preloaderService.destroyArchivePreloader();

        this.store.dispatch(new ClearScaleButtons());
        this.store.dispatch(new StateReset(ChartState));
        // this.store.dispatch(new ClearChartState());

        this.store.dispatch(new ChangeChartMode(ChartModeEnum.chart));
        this.store.dispatch(new SetEditMode(false));
    }

    async ngAfterViewInit(): Promise<void> {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(async () => {
                if (this.container && this.chartWidthForReports) {
                    this.chartSettingsService.setChartWidth(this.chartWidthForReports);
                }
            });
        });

        this.groupsDataService.currentWidthContent$.pipe(takeUntil(this.destroy)).subscribe(async (width) => {
            if (this.chartWidth === width) {
                return;
            }

            if (width) {
                if (this.chartWidth === width) {
                    return;
                }
                this.chartWidth = width;
                if (this.chartWidthForReports) {
                    this.chartSettingsService.setChartWidth(width + 40);
                } else {
                    this.chartSettingsService.setChartWidth(width);
                }

                if (this.currentRegistrator) {
                    await this.chooseMode(this.store.selectSnapshot(ChartState.getChartMode));
                }
            }
        });
    }

    private async initSettings(): Promise<void> {
        if (this.archTootipSubscription) {
            this.archTootipSubscription.unsubscribe();
        }

        this.archTootipSubscription = this.archiveService.archiveRegistratorsList$.subscribe((list) => {
            this.showLoadTootips(list);
        });
    }

    private isShowChart(): boolean {
        try {
            if (!this.currentGroup) {
                return false;
            }
            return !!this.currentGroup.variableGroupSettings.find((setting) => setting.showOnChart);
        } catch (e) {
            // console.log(e);
        }
    }

    private async initChart(mode?: ChartModeEnum): Promise<void> {
        console.log('initChart');
        this.archResultActiv = null;

        if (this.isChartCreating) {
            console.log(1);
            return;
        }

        if (this.store.selectSnapshot(GroupsState.getEditMode)) {
            console.log(2);

            this.chartService.detail = null;
            return;
        }

        if (!this.currentGroup) {
            console.log(3);

            this.chartService.detail = null;
            return;
        }

        this.isChart = this.isShowChart();
        if (!this.isChart) {
            console.log(4);

            this.chartService.detail = null;
            this.store.dispatch(new SetIsEmptyArchive({ isError: true, message: 'charts.noVariable' }));
            return;
        }

        if (!this.chartWidth) {
            console.log(5);

            this.chartService.detail = null;
            return;
        }

        if (!this.currentDevice) {
            console.log(6);
            this.chartService.detail = null;
            return;
        }

        const master = document.getElementById('master');
        const detail = document.getElementById('detail');

        if (!master || !detail) {
            console.log(7);

            this.chartService.detail = null;
            return;
        }

        this.isChartCreating = true;
        const dsVariables: Variable[] =
            this.currentModification?.currentValue === DataloggerModificationEnum.DLT_22
                ? this.currentDevice.variables.filter((f) => f.name === 'Contact1' || f.name === 'Contact2' || f.name === 'Contact3' || f.name === 'Contact4')
                : [];
        const variables: Variable[] = this.getVariablesForChart(this.currentGroup);

        if (this.timeObj && this.timeObj.type !== ParamsTimeTypeEnum.RANGE && variables.length) {
            this.chartService.initSubsToServerDataAboutNewVariables(variables);
        }

        try {
            const variablesWithArchive: VariableWithArchive[] = await this.getVariablesWithArchive(variables, dsVariables, mode);
            let dsVariablesWithArchive: any[] = [];
            if (this.currentModification?.currentValue === DataloggerModificationEnum.DLT_22) {
                dsVariablesWithArchive = await this.getBinaryVariables(dsVariables);

                dsVariablesWithArchive = dsVariablesWithArchive.filter((f) => !!f);
            }

            if (!variablesWithArchive) {
                await this.preloaderService.destroyArchivePreloader();
                this.isChartCreating = false;

                this.chartService.detail = null;

                return;
            }

            this.chartSettingsService.startTime = this.timeObj.from;
            this.archResult = JSON.parse(JSON.stringify(variablesWithArchive));
            if (mode === ChartModeEnum.hole) {
                this.getHoles();
                this.isChartCreating = false;
                return;
            }
            await this.renderChart(variablesWithArchive, this.currentRegistrator.id, dsVariablesWithArchive, dsVariables, null, mode);
            await this.preloaderService.destroyArchivePreloader();
            this.isChartCreating = false;

            this.fromToByRangeBox = null;
        } catch (e) {
            this.chartService.detail = null;

            this.isChartCreating = false;
            this.chartService.initiUnSubscribeToServerData();

            this.fromToByRangeBox = null;

            // this.socketService.removeListener(SocketEvent.SUBSCRIBE_ON_ARCHIVE, this.subscribeOnArchoveHandler);
            await this.preloaderService.destroyArchivePreloader();
            this.store.dispatch(new ClearScaleButtons());
            // console.log(e);
        }
    }

    private getVariablesForChart(group: Group): Variable[] {
        return group.variableGroupSettings
            .map((setting) => {
                const currentVariable = this.currentDevice.variables.find((variable) => variable.id === setting.variableId);
                return setting.showOnChart && currentVariable;
            })
            .filter((i) => i);
    }

    private async getBinaryVariables(variables: Variable[]): Promise<any> {
        const result = [];
        for (const variable of variables) {
            const data = await this.archiveService.getChartBinary({
                variables: [variable.id],
                start: this.timeObj.from,
                end: this.timeObj.type === ParamsTimeTypeEnum.TIME ? Date.now() : this.timeObj.to,
            });

            result.push(data);
        }

        return result;
    }

    private async getVariablesWithArchive(variables: Variable[], dsVariables: Variable[], mode?: ChartModeEnum): Promise<VariableWithArchive[]> {
        try {
            if (dsVariables?.length) {
                this.preloaderService.initArchivePreloader([...variables, ...dsVariables]);
            } else {
                this.preloaderService.initArchivePreloader(mode && mode === ChartModeEnum.chartAndAlarm ? [...variables, ...variables] : variables);
            }

            let variableChangesArchive = [];
            let variablesWithArchive: VariableWithArchive[] = [];
            let archiveLength = 0;

            const archivePreloaderData: ArchivePreloaderData = {
                responsesLength: variables.length,
                currentResponse: 0,
            };

            this.store.dispatch(new SetArchivePreloaderData(archivePreloaderData));
            let isDataLimited = false;

            if (this.currentGroup.variableGroupSettings.find((s) => s.showLimitAxis && variables.find((v) => v.id === s.variableId))) {
                await this.store.dispatch(new GetLogicalEvents([this.currentRegistrator.id])).toPromise();
            }
            const promises = [];
            for (const variable of variables) {
                promises.push(
                    new Promise(async (resolve) => {
                        let archiveObj: ArchiveChartInterface;
                        switch (mode) {
                            case ChartModeEnum.alarm:
                                archiveObj = await this.archiveService.getEventLog({
                                    variables: [variable.id],
                                    start: this.timeObj.from,
                                    end: this.timeObj.type === ParamsTimeTypeEnum.TIME ? Date.now() : this.timeObj.to,
                                });
                                break;
                            case ChartModeEnum.chartAndAlarm:
                                archiveObj = await this.archiveService.getArchive({
                                    variables: [variable.id],
                                    start: this.timeObj.from,
                                    end: this.timeObj.type === ParamsTimeTypeEnum.TIME ? Date.now() : this.timeObj.to,
                                });
                                break;
                            case ChartModeEnum.hole:
                                archiveObj = await this.archiveService.getArchive({
                                    variables: [variable.id],
                                    start: this.timeObj.from,
                                    end: this.timeObj.type === ParamsTimeTypeEnum.TIME ? Date.now() : this.timeObj.to,
                                });
                                break;
                            default:
                                archiveObj = await this.archiveService.getArchive({
                                    variables: [variable.id],
                                    start: this.timeObj.from,
                                    end: this.timeObj.type === ParamsTimeTypeEnum.TIME ? Date.now() : this.timeObj.to,
                                });
                        }

                        if (!archiveObj) {
                            return resolve(false);
                        }
                        this.currentArchivePeriodInAdmin = +archiveObj.periodArchive?.currentValue * 1000 ?? null;

                        archivePreloaderData.currentResponse += 1;
                        this.store.dispatch(new SetArchivePreloaderData(archivePreloaderData));

                        const archive = archiveObj.archive;
                        archiveLength += archive.length;

                        if (!isDataLimited) {
                            isDataLimited = archiveObj.isDataLimited;
                        }
                        if (archiveObj.periodArchive) {
                            variableChangesArchive = archiveObj.periodArchive.variableUpdates.map((item) => {
                                if (!variableChangesArchive.includes(item.id)) {
                                    return item;
                                }
                            });
                        }

                        const variableWithArchive: VariableWithArchive = await this.chartService.getVariableWithArchive(
                            this.currentGroup.variableGroupSettings,
                            variable,
                            archive,
                            this.timeObj.from,
                            this.timeObj.to,
                            archiveObj.periodArchive,
                            mode,
                        );

                        variablesWithArchive.push(variableWithArchive);

                        const limitWMA: VariableWithArchive[] = await this.chartService.getLimits(
                            archiveObj,
                            variable,
                            variablesWithArchive,
                            this.currentGroup.variableGroupSettings.filter((s) => variables.find((v) => v.id === s.variableId) && s.showLimitAxis).filter((s) => !!s),
                            variables,
                        );

                        variablesWithArchive = [...variablesWithArchive, ...limitWMA];

                        return resolve(true);
                    }),
                );
            }
            await Promise.all(promises);

            // const currentVariableGroupSettings = this.currentGroup.variableGroupSettings
            //     .filter((s) => variables.find((v) => v.id === s.variableId) && s.showLimitAxis)
            //     .filter((s) => !!s);
            // if (currentVariableGroupSettings && currentVariableGroupSettings.length) {
            //     const logicalEvents: LogicEvent[] = this.store.selectSnapshot(LogicalEventsState.getLogicalEvents);
            //
            //     const limitWMA: VariableWithArchive[] = await this.chartService.getLimitWMA(
            //         currentVariableGroupSettings,
            //         logicalEvents,
            //         variables,
            //         variablesWithArchive,
            //     );
            //     variablesWithArchive = [...variablesWithArchive, ...limitWMA];
            // }

            if (mode === ChartModeEnum.chartAndAlarm) {
                const promisesAlarm = [];
                for (const variable of variables) {
                    promisesAlarm.push(
                        new Promise(async (resolve) => {
                            const archiveObj: ArchiveChartInterface = await this.archiveService.getEventLog({
                                variables: [variable.id],
                                start: this.timeObj.from,
                                end: this.timeObj.to,
                            });

                            archivePreloaderData.currentResponse += 1;
                            this.store.dispatch(new SetArchivePreloaderData(archivePreloaderData));

                            const archive = archiveObj.archive;
                            archiveLength += archive.length;
                            if (!isDataLimited) {
                                isDataLimited = archiveObj.isDataLimited;
                            }

                            const variableWithArchive: VariableWithArchive = await this.chartService.getVariableWithArchive(
                                this.currentGroup.variableGroupSettings,
                                variable,
                                archive,
                                this.timeObj.from,
                                this.timeObj.to,
                                archiveObj.periodArchive,
                                mode,
                            );

                            variableWithArchive.isErrorMode = true;
                            variablesWithArchive.push(variableWithArchive);

                            variablesWithArchive = this.chartService.separationEventVariablesWithArchive(variablesWithArchive).filter((v) => v.data.length && v.data.find((d) => d.value || d.message));
                            resolve(true);
                        }),
                    );
                }
                await Promise.all(promisesAlarm);
            }

            if (mode === ChartModeEnum.alarm) {
                variablesWithArchive.forEach((v) => {
                    v.isErrorMode = true;
                    return v;
                });

                variablesWithArchive = this.chartService.separationEventVariablesWithArchive(variablesWithArchive).filter((v) => v.data.length && v.data.find((d) => d.message));
            }
            new Promise((resolve) =>
                setTimeout(() => {
                    this.store.dispatch(new SetArchivePreloaderData(null));
                    resolve(true);
                }, 100),
            );

            this.store.dispatch(new SetIsDataLimited(isDataLimited));

            let message = '';
            if (!archiveLength) {
                switch (mode) {
                    case ChartModeEnum.alarm:
                        message = 'charts.noAlarm';
                        break;
                    case ChartModeEnum.chart:
                        message = 'charts.noData';
                        break;
                    default:
                        message = 'charts.noData';
                }
            } else {
                this.store.dispatch(new SetIsEmptyArchive({ isError: false, message: '' }));
            }

            if (!archiveLength) {
                this.store.dispatch(new SetIsEmptyArchive({ isError: !archiveLength ? true : false, message }));
                this.store.dispatch(new SetDisabledTimeOptions(variableChangesArchive));
                return;
            }

            this.store.dispatch(new SetDisabledTimeOptions(variableChangesArchive));

            return variablesWithArchive;
        } catch (e) {
            // console.log(e);
            this.isChartCreating = false;
            this.store.dispatch(new SetArchivePreloaderData({ responsesLength: 0, currentResponse: 0 }));
            await this.preloaderService.destroyArchivePreloader();
            this.store.dispatch(new ClearScaleButtons());
            this.store.dispatch(new SetIsEmptyArchive({ isError: true, message: 'charts.catchInitChart' }));
        }
    }

    private async dynamicDataUpdate(variablesId: string[]): Promise<void> {
        const variables = this.currentDevice.variables.filter((v) => variablesId.includes(v.originVariableId) || variablesId.includes(v.id));
        let currentVariable: Variable;
        if (this.currentRegistrator.registratorType === RegistratorTypeEnum.docker) {
            currentVariable = this.store.selectSnapshot(DevicesState.getCurrentDevice).variables.find((v) => v.name === VariablesNameEnum.ArchivePeriod);
        } else {
            currentVariable = this.currentRegistrator.variables.find((v) => v.name === VariablesNameEnum.ArchivePeriod);
        }
        let archivePeriod;
        if (currentVariable) {
            archivePeriod = 1000 * +currentVariable.currentValue;
        }

        if (!this.chartService.detail) {
            this.initChart(ChartModeEnum.chart);
            return;
        }

        let binaryVariables: Variable[] = [];

        if (this.currentModification?.currentValue === DataloggerModificationEnum.DLT_22) {
            binaryVariables = this.currentDevice.variables.filter((f) => f.name === 'Contact1' || f.name === 'Contact2' || f.name === 'Contact3' || f.name === 'Contact4');
        }

        // const lastPointTime = this.chartService.findLastPointTime(this.chartService.detail, this.timeObj);
        // if (!lastPointTime) {
        //     return;
        // }
        let archiveObj: ArchiveChartInterface;
        let archiveBinaryObj;
        let archiveBinary = [];
        let archive: ArchiveRow[] = [];
        let limits: LimitsInterface[] = [];
        try {
            for (const v of variables) {
                archiveObj = await this.archiveService.getArchive({
                    variables: [v.id],
                    start: this.chartService.findLastPointTime(this.chartService.detail, this.timeObj, v) + 1,
                    end: Date.now(),
                });

                archive = [...archive, ...archiveObj.archive];
                limits = [
                    ...limits,
                    ...archiveObj.limits.map((limit) => {
                        return {
                            ...limit,
                            variableId: archiveObj.archive[0].archiveValues[0].variableId,
                        };
                    }),
                ];
                this.currentArchivePeriod = +archiveObj.periodArchive.currentValue * 1000;
            }

            if (this.currentModification?.currentValue === DataloggerModificationEnum.DLT_22) {
                for (const v of binaryVariables) {
                    archiveBinaryObj = await this.archiveService.getChartBinary({
                        variables: [v.id],
                        start: Date.now() - +this.timeObj.time,
                        end: Date.now(),
                    });

                    archiveBinary = [...archiveBinary, archiveBinaryObj];
                }
            }

            const charts = [this.chartService.master];
            if (!this.chartNavigatorService.rangeBox) {
                charts.push(this.chartService.detail);
            }

            if (this.currentModification?.currentValue === DataloggerModificationEnum.DLT_22) {
                this.chartService.setNewRecordInBinaryChart(this.chartService.master, archiveBinary, this.timeObj);
            }

            this.chartService.setNewRecordInChart(charts, archive, archivePeriod, this.timeObj, limits);
        } catch (e) {
            // console.log(e);
            this.chartService.detail = null;
            return;
        }
    }

    private async resetInterval(mode?: ChartModeEnum): Promise<void> {
        this.chartNavigatorService.deleteRangeBox();
        await this.updateDetailByRange(null, mode);
    }

    async setDetailDate(): Promise<void> {
        this.isDetailDate = !this.isDetailDate;
        localStorage.setItem('isDetailDate', String(this.isDetailDate));
        this.chartSettingsService.setIsDetailDate(this.isDetailDate);
        this.chooseMode(this.store.selectSnapshot(ChartState.getChartMode));
    }

    private async getHoles(): Promise<void> {
        let archResult: VariableWithArchive[] = [];

        if (this.archResultActiv === null) {
            this.archResultActiv = true;
            // console.log(this.archResult);

            archResult = this.chartService.parseHoles(this.archResult, this.timeObj);
        } else {
            this.archResultActiv = !this.archResultActiv;
        }

        await this.renderChart(archResult, this.currentRegistrator.id, null, null, null, ChartModeEnum.hole);
        await this.preloaderService.destroyArchivePreloader();

        if (!archResult || !archResult.length || !this.chartService.checkArchResultData(archResult)) {
            this.store.dispatch(new SetIsEmptyArchive({ isError: true, message: 'charts.noGaps' }));
        }
    }

    private async renderChart(variablesWithArchive: VariableWithArchive[], registratorId: string, dsVariablesWithArchive: any[], dsVariables: Variable[], axis?: Axis[], typeChart?: ChartModeEnum): Promise<void> {
        const chartSettings = await this.chartSettingsService.getGraphSettings(variablesWithArchive, registratorId, axis, dsVariablesWithArchive, dsVariables, typeChart);
        // console.log(chartSettings);
        if (!chartSettings) {
            this.store.dispatch(new SetIsEmptyArchive({ isError: true, message: 'charts.noAxis' }));
            this.chartService.initiUnSubscribeToServerData();
        }

        const months = await this.chartService.getTranslateMonth(this.monthKeys);

        if (months && months.length && !months.find((m) => !m)) {
            Highcharts.setOptions({
                lang: {
                    shortMonths: months,
                },
            });
        }

        this.chartService.master = await Highcharts.chart('master', chartSettings.master);
        this.chartService.detail = await Highcharts.chart('detail', chartSettings.detail);

        const plotBg = document.querySelector('.highcharts-plot-background');
        document
            .querySelector('.charts__master')
            .querySelectorAll('.highcharts-series')
            ?.forEach((item: any, index) => {
                item.style.transform = `translate(${plotBg.getAttribute('x')}px, ${this.getTransformPositionSeries(index)}px)`;
            });
        this.store.dispatch(new InitScaleButtons(this.chartService.detail));
    }

    getTransformPositionSeries(index: number): number {
        switch (index) {
            case 0: {
                return 16;
            }
            case 1: {
                return 13;
            }
            case 2: {
                return 8;
            }
            case 3: {
                return 4;
            }
        }
    }

    changeAxisScale(axisId: string, operator: ChangeAxisScaleOperator): void {
        const axis = this.chartService.detail.yAxis.find((a) => a.userOptions.id === axisId);
        let min = +axis.min !== 0 ? axis.min : -1;
        let max = +axis.max !== 0 ? axis.max : -1;
        const difference = max - min;
        const absoluteOffset = difference * 0.15;

        if (operator === ChangeAxisScaleOperator.plus) {
            min += absoluteOffset;
            max -= absoluteOffset;
        } else if (operator === ChangeAxisScaleOperator.minus) {
            min -= absoluteOffset;
            max += absoluteOffset;
        }

        min = +min.toFixed(2);
        max = +max.toFixed(2);

        axis.options.startOnTick = false;
        axis.options.endOnTick = false;
        axis.setExtremes(min, max);
    }

    reReadingChart(event: RereadingEnum): void {
        switch (event) {
            case RereadingEnum.detail:
                this.store.dispatch(new SetTimeObj(this.fromToByRangeBox));
                const mode = this.store.selectSnapshot(ChartState.getChartMode);
                this.chooseMode(mode);
                return;
            case RereadingEnum.rereading:
                const timeObj = this.store.selectSnapshot(TimeFilterState.getTimeObj);
                const config = this.datePickerService.getDatePickerConfig(timeObj, 1000 * 60 * 60 * 24);
                this.store.dispatch(new SetDatepickerConfigForReread(config));
                this.isReReadPopup = true;

                return;
        }
    }

    async reReadArchive(): Promise<void> {
        await this.store.dispatch(new ReReadArchive()).toPromise();
        this.isReReadPopup = false;
    }

    datepickerEvent(event: DatePickerConfigInterface): void {
        const timeObj = this.store.selectSnapshot(TimeFilterState.getTimeObj);
        const config = this.datePickerService.getDatePickerConfig(
            {
                ...timeObj,
                from: new Date(event.from).getTime(),
            },
            1000 * 60 * 60 * 24,
        );

        this.store.dispatch(new SetDatepickerConfigForReread(config));
    }
}

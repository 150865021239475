<div class="admin-profile">
    <div class="admin-profile__wrapper">
        <app-heading [title]="'admin.userProfile.title'"
                     [breadCrumbs]="(user$ | async)?.login.split('@')[0] + '@'"></app-heading>

        <app-tabs *ngIf="tabs && tabs.length"></app-tabs>

        <router-outlet></router-outlet>
    </div>
</div>

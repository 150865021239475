import {CompletionType} from 'src/app/journals/_enums/completion-type.enum';
import {AcknowledgedDeviceLogType} from "../../journals/_interfaces/device-log.inteface";

export interface AcknPropertyInterface {
    ts: string;
    isAcknowledgeable: boolean;
    acknowledgedByUser: string;
    isDisabledBtn?: boolean;
    btnType?: AcknPropertyBtnTypeEnum;
    acknowledgedType?: AcknowledgedDeviceLogType;
    title: string;
}

export interface TsClearedPropertyInterface {
    time: number;
    completionType: CompletionType;
    finishedByUser: string;
}

export enum AcknPropertyBtnTypeEnum {
    ligth = 'ligth'
}

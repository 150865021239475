<div class="m-events">
    <div class="m-events__wrapper">
        <div class="m-events__header">
            <div class="m-events__filter-icon" (click)="isShowFilter = true">
                <svg-icon [src]="FILTER_ICON_PATH"></svg-icon>
            </div>
        </div>
        <div *ngIf="(eventCards$ | async) && (eventCards$ | async).length; else empty" class="m-events__cards">
            <app-mobile-card *ngFor="let card of eventCards$ | async"
                             [card]="card"
                             [configPagination]="(params$ | async).pagination"></app-mobile-card>
        </div>

        <ng-template #empty>

            <div class="m-events__empty">
                <span [translate]="'table.noData'"></span>
            </div>

        </ng-template>

        <div *ngIf="(eventCards$ | async) && (eventCards$ | async).length" class="m-events__paginate">

            <app-pagination [configPagination]="(params$ | async).pagination"
                            [isMobile]="true"
                            [customCountOption]="customCountOption"
                            (paginationOutput)="changePage($event)"></app-pagination>
        </div>
    </div>

    <div *ngIf="isShowFilter" class="m-events__filters">
        <app-mobile-filter [filters]="(params$ | async).filter"
                           (changeFilterEvent)="isShowFilter = !isShowFilter; changeFilter($event)"></app-mobile-filter>
    </div>
</div>

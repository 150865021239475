import {Component, OnDestroy, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {UserState} from '../_store/states/user.state';
import {Observable} from 'rxjs';
import {ExpiredPasswordState} from '../_store/states/expired-password.state';
import {SetExpiredPopup} from '../_store/actions/expired-password.actions';
import {ChangeUserPassword} from '../_store/actions/user.actions';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ValidatePassword} from '../../auth/_helpers/strong-validator-password';
import {SocketService} from '../_services/socket.service';
import {TypeClient} from '../../admin/users/_enum/type-client.enum';
import {first} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
    selector: 'app-expired-password',
    templateUrl: './expired-password.component.html',
    styleUrls: ['./expired-password.component.scss']
})
export class ExpiredPasswordComponent implements OnInit, OnDestroy {

    @Select(ExpiredPasswordState.getExpiredDays) days$: Observable<number>;

    changePopup = false;
    isVisiblePassword = false;
    repeatPassword = '';
    password = '';
    formPassword: FormGroup;

    readonly CLOSE_ICON_PATH = './assets/design/icons/close.svg';

    constructor(private store: Store,
                private router: Router,
                private socketService: SocketService) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.store.dispatch(new SetExpiredPopup(false));
    }

    close(): void {
        this.store.dispatch(new SetExpiredPopup(false));
    }

    changePassword(): void {
        const data: { id: number | string, password: string } = {
            id: this.store.selectSnapshot(UserState.getUser).id,
            password: this.password
        };

        this.store.dispatch(new ChangeUserPassword(data, this.router.url.includes('control') ? TypeClient.Admin : TypeClient.User)).pipe(first()).subscribe(async () => {
            await this.close();
            await this.socketService.logoutSubscribe.emit();
        });
    }

    moveToChange(): void {
        this.changePopup = true;

        this.formPassword = new FormGroup({
            password: new FormControl('', [
                Validators.required,
                Validators.minLength(8),
                ValidatePassword.bind(this)
            ]),
            repeat: new FormControl('', [
                Validators.required,
                Validators.minLength(8)
            ]),
        });
    }
}

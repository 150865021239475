<div class="invoice">

    <ng-container *ngIf="currentInvoice">

        <div class="invoice__service"
             [style.width]="tableWidth || '700px'"
        >
            <div class="invoice__header invoice__row">
                <div [translate]="'billing.invoice.print.service'"></div>
                <div [translate]="'billing.invoice.print.price'"></div>
            </div>

            <div class="invoice__body">

                <ng-container *ngFor="let item of currentInvoice?.services">

                    <ng-container *ngIf="item.typeService === servicesInvoiceEnum.STANDART && item.paymentAmount > 0">
                        <div class="invoice__row">
                            <div class="invoice__name">{{item.serviceDescription}}</div>
                            <div
                                class="invoice__value">{{item.paymentAmount}} {{currency$ | async}}</div>
                        </div>
                    </ng-container>

                </ng-container>

                <div [hidden]="!currentAdditionalInvoice?.length && !(isAdmin$ | async)" class="invoice__row">
                    <div class="invoice__row-add">
                        <div class="invoice__name" [translate]="'billing.invoice.additional'"></div>

                        <div *ngIf="isAdmin$ | async"
                             (click)="addNewInvoice()"
                             class="invoice__add"
                             [class.invoice__add--disabled]="isEditingMode$ | async"
                        >
                            <svg-icon
                                [src]="(isEditingMode$ | async) ? ADD_DISABLED_ICON_PATH : ADD_ICON_PATH"></svg-icon>
                        </div>
                    </div>
                </div>

                <ng-container *ngFor="let item of currentInvoice?.services">
                    <ng-container
                        *ngIf="item.typeService === servicesInvoiceEnum.ADDITIONAL">

                        <ng-container *ngIf="item.isEditing; else value">

                            <div class="invoice__edit">
                                <div class="invoice__edit-textarea">
                                    <div class="invoice__edit-description"
                                         [translate]="'billing.invoice.table.description'"></div>
                                    <textarea
                                        [(ngModel)]="item.serviceDescription"
                                        (input)="item.serviceDescription = $event.target.value"
                                        maxlength="250"
                                        [placeholder]="'billing.invoice.additionalPlaceholder' | translate"
                                    ></textarea>

                                    <div
                                        class="invoice__textarea-note"> {{'events.messagesCatalog.maxSymbols' | translate}}
                                        250.
                                        <span>{{250 - item?.serviceDescription?.length}} {{'events.messagesCatalog.symbolsLeft' | translate}}</span>
                                    </div>
                                </div>

                                <div class="invoice__edit-input">
                                    <div class="invoice__edit-description"
                                         [translate]="'billing.invoice.table.price'"></div>
                                    <label for="price">
                                        <input [(ngModel)]="item.paymentAmount"
                                               [appInputValidate]="variableValueTypeEnum.numbers"
                                               (input)="item.paymentAmount = $event.target.value"
                                               [placeholder]="'billing.transactions.placeholder.price' | translate"
                                               id="price"
                                               class="input invoice__input" type="number">
                                        <span>{{currency$ | async}}</span>
                                    </label>
                                </div>

                            </div>

                            <div class="invoice__btns">

                                <button (click)="closeEditRow(item)" class="btn btn--light"
                                        [translate]="'btns.cancel'"></button>
                                <button (click)="saveInvoice(item)"
                                        [disabled]="!item.serviceDescription.length || !item.paymentAmount"
                                        class="btn" [translate]="'btns.save'"></button>

                            </div>

                        </ng-container>

                        <ng-template #value>

                            <div class="invoice__additional invoice__row">

                                <div class="invoice__additional-delete">
                                    <div (click)="editRow(item)"
                                         class="invoice__name">{{item.serviceDescription}}</div>
                                    <div *ngIf="isAdmin$ | async"
                                         (click)="deleteInvoice(item)" class="invoice__delete">
                                        <svg-icon [src]="DELETE_ICON_PATH"></svg-icon>
                                    </div>
                                </div>

                                <div
                                    class="invoice__value">{{item.paymentAmount}} {{currency$ | async}}</div>

                            </div>

                        </ng-template>

                    </ng-container>
                </ng-container>

                <div class="invoice__row">
                    <b [translate]="'billing.invoice.debt'"></b>
                    <div>{{currentInvoice?.debt}} {{currency$ | async}}</div>
                </div>

            </div>

            <div class="invoice__price">

                <div class="invoice__item">

                    <div class="invoice__item-text" [translate]="'billing.invoice.print.notVat'"></div>
                    <div>{{currentInvoice.sumWithoutVAT}} {{currency$ | async}}</div>

                </div>

                <div class="invoice__item">

                    <div class="invoice__item-text" [translate]="'billing.invoice.print.vat'"
                         [translateParams]="{vat: currentInvoice?.vat}"></div>
                    <div>{{currentInvoice.vatSum}} {{currency$ | async}}</div>

                </div>

            </div>

            <div class="invoice__item-full invoice__item">
                <div class="invoice__item-full-row">

                    <div class="invoice__item-text invoice__item-full-price"
                         [translate]="'billing.invoice.allPrice'"></div>
                    <div
                        class="invoice__item-full-price">{{currentInvoice.totalPayable}} {{currency$ | async}}</div>
                </div>

            </div>
        </div>
    </ng-container>

</div>

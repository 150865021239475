import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/theme/theme.service';
import { UsersService } from '../../admin/users/_services/users.service';
import { SocketService } from '../../app-shared-elements/_services/socket.service';
import { SignalService } from '../../app-shared-elements/_services/signal.service';
import { Select, Store } from '@ngxs/store';
import { interval, Observable, Subject } from 'rxjs';
import { UserState } from 'src/app/app-shared-elements/_store/states/user.state';
import { TypeClient } from 'src/app/admin/users/_enum/type-client.enum';

import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { first, takeUntil } from 'rxjs/operators';
import { MethodLogin } from '../../auth/_enums/socket-user-status.enum';
import { SidebarStatusEnum } from 'src/app/app-shared-elements/_enums/sidebar-status.enum';
import { LayoutState } from 'src/app/app-shared-elements/_store/states/layout.state';
import { ThemeEnum } from 'src/app/theme/_enums/theme.enum';
import { DevicesState } from '../../device-dashboard/_store/states/user-devices.state';
import { Device } from '../../app-shared-elements/_interfaces/Device';
import { SetCurrentQueryParams } from '../../app-shared-elements/_store/actions/tabs.actions';
import { NavigationItemInterface } from '../_interfaces/navigation-item.interface';
import { PermissionsState } from 'src/app/app-shared-elements/_store/states/permissions.state';
import { RoleInterface } from 'src/app/app-shared-elements/_interfaces/role.interface';
import { RolesService } from 'src/app/admin/roles/_services/roles.service';
import { ChangeUserNavigation, ParseNavigationForStoreTask, ParseNavigationForTransportTask, SetCurrentNavigationAdminList } from 'src/app/app-shared-elements/_store/actions/nav.actions';
import { NavState } from 'src/app/app-shared-elements/_store/states/nav.state';
import { StateReset } from 'ngxs-reset-plugin';
import { DeviceService } from 'src/app/device-dashboard/_services/device.service';
import { AuthState } from '../../auth/_store/states/auth.state';
import { FilterTypePipeEnum } from '../../app-shared-elements/_enums/filter-type-pipe.enum';
import { ResourceAction } from '../../app-shared-elements/_enums/permission.enum';
import { ServerNamesEnum } from '../../app-shared-elements/_enums/server-names.enum';
import { ConfigurationState } from '../../app-shared-elements/_store/states/configuration.state';
import { ConfigurationServerInterface } from '../../app-shared-elements/_interfaces/configuration-server.interface';

/**
 * Компонент, содержащий хедер и навигацию приложения.
 */
@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavComponent implements OnInit, OnDestroy {
    activeTheme: ThemeEnum = localStorage.getItem('theme') as ThemeEnum;
    themeEnum = ThemeEnum;
    isGoogleUser = false;

    userType: TypeClient;
    typeClient = TypeClient;

    openProfile = false;
    private user: User;

    @Select(UserState.getUser) user$: Observable<User>;
    @Select(DevicesState.getDevices) devices$: Observable<Device[]>;
    @Select(DevicesState.getRegistrators) registrators$: Observable<Device[]>;
    @Select(DevicesState.getIsDeviceSyncStatus) isDeviceSyncStatus$: Observable<boolean>;
    @Select(LayoutState.getSidebarStatus) sidebarStatus$: Observable<SidebarStatusEnum>;
    @Select(PermissionsState.getRoles) roles$: Observable<RoleInterface[]>;
    @Select(NavState.getIsExpedition) isExpedition$: Observable<boolean>;
    @Select(NavState.getNavigationList) navigationList$: Observable<NavigationItemInterface[]>;
    @Select(NavState.getAdminNavigationList) navigationAdminList$: Observable<NavigationItemInterface[]>;
    @Select(AuthState.getIsAdmin) isAdmin$: Observable<boolean>;
    @Select(ConfigurationState.getConfigurationServer) configurationServer$: Observable<ConfigurationServerInterface>;

    sidebarStatusEnum = SidebarStatusEnum;
    destroy: Subject<boolean> = new Subject<boolean>();
    currentUrl: string;

    currentNavigationAdminList: NavigationItemInterface[] = [];
    currentNavigationUserList: NavigationItemInterface[] = [];

    filterTypeEnum = FilterTypePipeEnum;

    serverNamesEnum = ServerNamesEnum;

    readonly LOGO_PATH = './assets/design/icons/nav/logo.svg';
    readonly LOGO_CCC_PATH = './assets/design/icons/nav/logo-ccc.svg';
    readonly LOGO_ADMIN_PATH = './assets/design/icons/nav/logo-admin.svg';
    readonly NAV_ARROW_PATH = './assets/design/icons/nav/nav-arrow.svg';
    readonly NAV_SYNC_PATH = './assets/design/icons/nav/nav-sync.svg';
    readonly VOLUME_PATH = './assets/design/icons/nav/volume.svg';
    readonly USER_PATH = './assets/design/icons/nav/user.svg';
    readonly USER_GOOGLE_PATH = './assets/design/icons/nav/user-google.svg';

    constructor(
        private themeService: ThemeService,
        public usersService: UsersService,
        private socketService: SocketService,
        public router: Router,
        private store: Store,
        private signalService: SignalService,
        private deviceService: DeviceService,
    ) {
        this.userType = this.usersService.getTypeClient();

        this.user$.pipe(takeUntil(this.destroy)).subscribe((user) => {
            if (!user) {
                return;
            }

            this.user = user;

            this.isGoogleUser = this.usersService.getUserFromToken().methodLogin === MethodLogin.GOOGLE;
        });

        if (!this.activeTheme) {
            this.themeService.setActiveTheme(ThemeEnum.light);
            this.activeTheme = ThemeEnum.light;
            localStorage.setItem('theme', ThemeEnum.light);
        }

        this.deviceService.updateDeviceLength.pipe(takeUntil(this.destroy)).subscribe(() => {
            this.parseNavigationList();
        });
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
        this.store.dispatch(new StateReset(NavState));
    }

    async ngOnInit(): Promise<void> {
        await this.parseNavigationList();

        this.router.events.pipe(takeUntil(this.destroy)).subscribe((event) => {
            if (event instanceof NavigationStart || event instanceof NavigationEnd) {
                this.currentUrl = event.url;
                this.activeLink(event);
            }
        });

        if (!this.currentUrl) {
            this.activeLink(this.router);
        }
    }

    private async parseNavigationList(): Promise<void> {
        if (this.userType === TypeClient.Admin) {
            this.roles$.pipe(first((roles) => !!roles && !!roles.length)).subscribe((roles) => {
                const currentRole = roles.find((r) => r.id === this.user.roleId);

                if (currentRole.name === RolesService.rootRole) {
                    this.currentNavigationAdminList = this.store.selectSnapshot(NavState.getAdminNavigationList);
                    return;
                }

                this.store.dispatch(new SetCurrentNavigationAdminList(currentRole.permissions));
            });
        } else {
            await this.devices$
                .pipe(
                    first((devices) => !!devices && !!devices.length),
                    takeUntil(interval(5000)),
                )
                .toPromise();
            const isExpedition = this.store.selectSnapshot(NavState.getIsExpedition);
            isExpedition ? this.store.dispatch(new ParseNavigationForTransportTask()) : this.store.dispatch(new ParseNavigationForStoreTask());
        }
    }

    movetoNotifications(): void {
        this.store.selectSnapshot(AuthState.getIsAdmin) && this.router.url.includes('control') ? this.router.navigate(['control/notifications']) : this.router.navigate(['/notifications']);
    }

    activeLink(event: NavigationEnd | NavigationStart | Router): void {
        const currentList = this.userType === TypeClient.User ? this.store.selectSnapshot(NavState.getNavigationList) : this.store.selectSnapshot(NavState.getAdminNavigationList);

        this.store.dispatch(
            new ChangeUserNavigation(
                currentList.map((item) => {
                    return {
                        ...item,
                        action: event.url.includes(item.link) || !!item.children.find((child) => event.url.split('?')[0].includes(child.link)),
                        children: item.children.map((child) => {
                            child.action = false;

                            return {
                                ...child,
                                action: event.url.split('?')[0] === child.link ?? !!event.url.split('?')[0].includes(child.link),
                            };
                        }),
                    };
                }),
                this.userType,
            ),
        );
    }

    async onOffSoundAlarm(): Promise<void> {
        await this.signalService.updateSignal();
    }

    toggleNavigationItem(item: NavigationItemInterface): void {
        const currentList = this.userType === TypeClient.User ? this.store.selectSnapshot(NavState.getNavigationList) : this.store.selectSnapshot(NavState.getAdminNavigationList);

        this.store.dispatch(
            new ChangeUserNavigation(
                currentList.map((n) => {
                    if (item.name === n.name) {
                        return {
                            ...item,
                            toggle: !item.toggle,
                        };
                    }
                    return n;
                }),
                this.userType,
            ),
        );
    }

    trackByName(index: number, item: NavigationItemInterface): string {
        return item.name;
    }

    clearQuery(): void {
        this.store.dispatch(new SetCurrentQueryParams(null));
    }

    moveToLogo(): void {
        const currentRole = this.store.selectSnapshot(PermissionsState.getRoles).find((f) => f.id === this.user.roleId);
        const currentDeviceRole = currentRole?.permissions?.find((f) => f.resource === ResourceAction.DEVICES);
        this.userType === TypeClient.User ? this.router.navigate(['/device-dashboard']) : this.router.navigate([`control/${currentDeviceRole?.isRead ?? currentRole.name === RolesService.rootRole ? 'devices' : 'profile'}`]);
    }
}

export enum ResourceAction {
    NOT_PERMISSION = 'NOT_PERMISSION',
    GROUPS = 'groups_container',
    GROUP_CHART = 'group_chart',
    GROUP_TABLE = 'group_table',
    GROUP_CONTROL = 'group_control',
    GROUP_MAP = 'group_map',
    TRANSPORT = 'transport',
    REPORT = 'report',
    REPORT_LOG = 'report_log',
    REPORT_GENERATE = 'report_generate',
    DEVICES_LOGS = 'devices_logs',
    DEVICES_LIST = 'devices_list', // Пристрої
    LOGIC_EVENT = 'logic_event',
    LOGIC_EVENT_LOG = 'logic_event_log',
    LOGIC_MESSAGE = 'logic_message',
    EXPEDITION = 'expedition',
    DEVICES_STATISTICS = 'devices_statistics',

    BILLING = 'billing',

    dashboard_datalogger = 'dashboard_datalogger',
    dashboard_datalogger_settings = 'dashboard_datalogger_settings',
    dashboard_datalogger_event = 'dashboard_datalogger_event',

    // forAdmin
    ADMINS = 'admins',
    ADMINS_ROLE = 'admins_role',

    USERS = 'users',
    USER_BROWSING = 'user_browsing',

    USERS_ROLE = 'users_role',
    UNITS = 'units',
    EXPORT_IMPORT = 'export_import',
    SERVER_INFO = 'server_info',
    CONSTANT = 'constant',

    STATUS_MESSAGES = 'status_messages',
    LIMIT_MESSAGES = 'limit_messages',

    DEVICES = 'devices',

    EVENTS_LOGS = 'events_logs',

    ADMIN_LOGS = 'admin_logs',

    DATALOGGER_SETTINGS = 'datalogger_settings',

    DEVICES_MESSAGE = 'devices_message',
}

export enum ResourceGroupAction {
    GROUPS = 'groups',
    MNEMONIC = 'mnemonic',
    DEVICE = 'device',
    EVENT = 'event',
    REPORT = 'report',
}

export enum MethodPermission {
    READ = 'read',
    UPDATE = 'update',
}

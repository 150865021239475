import { UsersService } from 'src/app/admin/users/_services/users.service';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { TypeClient } from '../../admin/users/_enum/type-client.enum';
import { combineLatest, Observable, Subject } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { UserState } from 'src/app/app-shared-elements/_store/states/user.state';
import { SessionInterface } from '../_interfaces/Session';
import { SessionState } from '../_store/states/sessions.state';
import { DeleteAdminSessions, DeleteUserSessions, GetUserSessions } from '../_store/actions/sessions.actions';
import { DeleteSessionsDto } from '../_interfaces/DeleteSessionsDto';
import { PreloaderService } from 'src/app/app-shared-elements/_services/preloader.service';
import { first, takeUntil } from 'rxjs/operators';
import { LanguageState } from 'src/app/app-shared-elements/_store/states/language.state';
import { LangEnum } from 'src/app/app-shared-elements/_enums/lang.enum';
import { UserProfileInfo } from 'src/app/app-shared-elements/_interfaces/UserInfo';
import { GetUserNotificationsInfo, SetCurrentProfileUser, SetUserProfileInfo } from '../_store/actions/profile.actions';
import { ProfileState } from '../_store/states/profile.state';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { EventFromTableInterface } from 'src/app/app-shared-elements/_interfaces/event-from-table.interface';
import { TableTypeEnum } from '../../app-shared-elements/_enums/table-type.enum';
import { UserNotificationTransport } from '../_interfaces/user-notification-transport.interface';
import { ChangeUserUpdateValue } from 'src/app/app-shared-elements/_store/actions/user.actions';
import { StateReset } from 'ngxs-reset-plugin';
import { ProfileBlocksState } from '../_store/states/profile-blocks.state';
import { ConfigurationState } from '../../app-shared-elements/_store/states/configuration.state';
import { ConfigurationServerInterface } from '../../app-shared-elements/_interfaces/configuration-server.interface';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent implements OnInit, OnDestroy {
    @Select(UserState.getUser) user$: Observable<User>;

    @Select(SessionState.getUserSessionsRows) sessionsRows$: Observable<SessionInterface[]>;

    @Select(UserState.getUserTimezoneGMT) gmt$: Observable<string>;
    @Select(ProfileState.getUserProfileInfo) userInfo$: Observable<UserProfileInfo[]>;
    @Select(ProfileState.getUserProfileInfoSystem) userInfoSystem$: Observable<UserProfileInfo[]>;
    @Select(ProfileState.getUserProfileInfoMailing) userInfoMailing$: Observable<UserNotificationTransport[]>;
    @Select(LanguageState.getLanguage) ln$: Observable<LangEnum>;
    @Select(ConfigurationState.getConfigurationServer) configurationServer$: Observable<ConfigurationServerInterface>;

    showDeleteSession = false;

    tableTypeEnum = TableTypeEnum;

    dataDeleteSessions: SessionInterface;
    user: User;

    typeClient: TypeClient;
    client = TypeClient;

    isCanMoveTab: boolean;

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        public userService: UsersService,
        private store: Store,
        private preloaderService: PreloaderService,
    ) {
        this.typeClient = this.userService.getTypeClient();

        this.user$.pipe(takeUntil(this.destroy)).subscribe((user) => {
            this.user = user;
        });

        combineLatest([this.user$, this.configurationServer$])
            .pipe(first(([user, configuration]) => !!user && !!configuration))
            .subscribe(async ([user, configuration]) => {
                await this.store.dispatch(new GetUserNotificationsInfo()).toPromise();
                const notification = this.store.selectSnapshot(ProfileState.getUserProfileInfoMailing);
                this.store.dispatch(
                    new ChangeUserUpdateValue({
                        name: user.name,
                        phone: user.phone,
                        timeBlocked: user.timeBlocked,
                        sessionLife: user.sessionLife,
                        dateFormat: user.dateFormat,
                        dateTimeZone: user.dateTimeZone,
                        isAlarmSound: user.isAlarmSound,
                        lang: user.lang,
                        notification,
                    }),
                );
                console.log(user);
                this.store.dispatch(new SetUserProfileInfo(user, false));
                this.store.dispatch(new SetCurrentProfileUser(user));
                this.store.dispatch(new GetUserSessions());
            });
    }

    async ngOnInit(): Promise<void> {}

    ngOnDestroy(): void {
        this.preloaderService.destroyPreloader();
        // this.store.dispatch(new ChangeUserUpdateValue(null));
        this.store.dispatch(new StateReset(ProfileState, ProfileBlocksState));
        this.destroy.next(true);
        this.destroy.complete();
    }

    async checkDeleteSession(event: SessionInterface): Promise<void> {
        this.showDeleteSession = true;
        this.dataDeleteSessions = event;
    }

    async deleteSessions(): Promise<void> {
        const ids = [this.dataDeleteSessions.id];
        const deleteSessionDto: DeleteSessionsDto = {
            ids,
            userId: this.user.id,
        };
        this.showDeleteSession = false;

        this.userService.getTypeClient() === TypeClient.User
            ? this.store.dispatch(new DeleteUserSessions(deleteSessionDto))
            : this.store.dispatch(new DeleteAdminSessions(ids, this.user.id));
    }

    cancelPopup(): void {
        this.showDeleteSession = false;
    }

    eventFromTable(data: EventFromTableInterface): void {
        switch (data.type) {
            case ColumnsActionTypeEnum.actionBtnsDelete:
                this.checkDeleteSession(data.row);
                return;
        }
    }
}

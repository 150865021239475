export enum SocketEvent {
    COUNT_CHANNEL = 'countChannel',
    FREEZE_SESSION = 'freezeSession',
    ACTIVE_EVENTS = 'activeEvents',
    NEW_SHARE = 'newShare',
    DYNAMIC_DEVICE_UPDATE = 'dynamicDeviceUpdate',
    SPLASH_MESSAGE = 'splashMessage',

    DYNAMIC_DEVICE_DELETE = 'dynamicDeviceForceDelete',
    DYNAMIC_DEVICE_INFO = 'dynamicDeviceInfo',
    DO_SESSION_ACTION = 'doSessionAction',
    UN_FREEZE_SESSION = 'unFreezeSession',
    AUTHORIZE_USER = 'authorizeUser',
    INIT_DATA_AFTER_CONNECT = 'initDataAfterConnect',
    USER_DATA = 'user_data',
    PING = 'ping',
    ADMIN_DATA = 'admin_data',
    REPORT_GENERATE_NOTIFICATION = 'reportGenerateNotification',
    REPORT_GENERATE_INFO = 'reportGenerateInfo',
    INVOICE_GENERATE_INFO = 'invoiceGenerateInfo',

    HANDLER_EVENTS_BY_REGISTRATOR = 'handlerEventsByRegistrator',
    ACTIVE_DEVICE_LOGS = 'activeDeviceLogs',
    DYNAMIC_VARIABLE_UPDATE = 'dynamicVariableUpdate',
    DYNAMIC_LOGIC_EVENT_UPDATE = 'dynamicLogicEventUpdate',
    SUBSCRIBE_ON_ARCHIVE = 'subscribeOnArchive',
    SUBSCRIBE_ON_VARIABLES = 'subscribeOnVariables',
    SUBSCRIBE_ON_CONNECT = 'subscribeOnConnect',
    GROUP_SETTINGS_CHANGED = 'groupSettingsChanged',
    REPORT_URL = 'reportUrl',
    FORCE_LOGOUT = 'forceLogout',
    ROLE_UPDATE = 'roleUpdate',
    USER_EXPORT_IMPORT = 'user_export_import',
    DATALOGGER_AUTHORIZE = 'datalogger_authorize',
}

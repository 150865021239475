import {DeleteSessionsDto} from '../../_interfaces/DeleteSessionsDto';

export class GetUserSessions {
    static readonly type = '[Sessions] GetUserSessions';
    constructor(public userId?: string) {
        // console.trace()
    }
}

export class GetAdminSessions {
    static readonly type = '[Sessions] GetAdminSessions';

    constructor(public id?: string) {

    }
}

export class InitUserSessionsRows {
    static readonly type = '[Sessions] InitUserSessionsRows';
}

export class InitAdminSessionsRows {
    static readonly type = '[Sessions] InitAdminSessionsRows';
}

export class DeleteUserSessions {
    static readonly type = '[Sessions] DeleteUserSessions';
    constructor(public payload: DeleteSessionsDto) {

    }
}

export class DeleteAdminSessions {
    static readonly type = '[Sessions] DeleteAdminSessions';
    constructor(public ids: any[], public adminId: string) {

    }
}

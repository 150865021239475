

export enum ActionLogDeviceEnum{
    DEVICE_ERROR = 'device_error',
    INFO = 'info',
    SERVER_ERROR = 'server_error',
    ERROR_CHECK_EXPRESSION = 'error_check_expression',
    ERROR_SAVE_EVENT = 'error_save_event',
    ERROR_SAVE_EVENT_LOG = 'error_save_event_log',
    ERROR_SYNC_EVENT = 'error_sync_event',
    DISCONNECT_TIMEOUT = 'disconnect_timeout'

}
export enum SocketTypeLogDevice {
    REMOVE = 'remove',
    ADD = 'add',
    ALL = 'all'

}

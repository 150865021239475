import { roleStates } from '../admin/roles/_store';
import { configurationState, sharedState } from '../app-shared-elements/_store';
import { virtualState } from '../custom-devices/_store';
import { logicalEventsState } from '../events/_store';
import { groupsStates } from '../groups/_store';
import { mailingStore } from '../mailing/_store';
import { chatStates } from '../notifications/_store';
import { reportsState } from '../reports/_store';
import { journalsStates } from '../journals/_store';
import { profileStates } from '../profile/_store';
import { expeditionsStates } from '../expedition/_store';
import { unitsStates } from '../admin/units/_store';
import { digitalSignatureStates } from '../digital-signature/_store';
import { exportImportStates } from '../admin/export-import/_store';
import { eventMessagesState } from '../admin/event-messages/_store';
import { adminsState } from '../admin/admins/_store';
import { serversDashboardStates } from '../admin/servers-dashboard/_store';
import { usersStates } from '../admin/users/_store';
import { authStates } from '../auth/_store';
import { mobileDevicesStates } from '../mobile/_store';
import { adminProfileState } from '../admin/admin-profile/_store';
import { deviceDashboardStates } from '../device-dashboard/_store';
import { systemSettingsState } from '../admin/system-settings/_store';
import { remoteDevicesState } from '../deleted-devices/_store';
import { adminDevicesState } from '../admin/admin-devices/_store';
import { detailsDeviceState } from '../details-device-container/_store';
import { generationState } from '../generation-key/_store';
import { copyConfigurationState } from '../copy-configuration/_store';
import { companyStates } from '../admin/company/_store';
import { billingState } from '../billing/_store';
import { financeState } from '../admin/admin-finances/_store';
import { mailMessageState } from '../admin/mail-message/_store';
import { adminRegistratorLogs } from '../admin/admin-registrator-logs/_store';
import { adminLogs } from '../admin/admin-journals/_store';
import { mnemonicState } from '../mnemonic-container/_store';
import { devicesControlState } from '../admin/devices-control/_store';
import { createDevicesState } from '../create-devices/_store';
import { transportsStates } from '../transport-container/_store';
import { companyStaffState } from '../company-staff/_store';

export const stateList = [
    ...reportsState,
    ...logicalEventsState,
    ...sharedState,
    ...profileStates,
    ...groupsStates,
    ...mailingStore,
    ...transportsStates,
    ...roleStates,
    ...chatStates,
    ...virtualState,
    ...journalsStates,
    ...expeditionsStates,
    ...unitsStates,
    ...digitalSignatureStates,
    ...exportImportStates,
    ...eventMessagesState,
    ...adminsState,
    ...serversDashboardStates,
    ...usersStates,
    ...authStates,
    ...mobileDevicesStates,
    ...adminProfileState,
    ...deviceDashboardStates,
    ...systemSettingsState,
    ...remoteDevicesState,
    ...adminDevicesState,
    ...detailsDeviceState,
    ...generationState,
    ...copyConfigurationState,
    ...companyStates,
    ...billingState,
    ...financeState,
    ...mailMessageState,
    ...configurationState,
    ...adminRegistratorLogs,
    ...adminLogs,
    ...mnemonicState,
    ...devicesControlState,
    ...createDevicesState,
    ...companyStaffState,
];
//
// export const resetStateList = [
//     ...reportsState,
//     ...logicalEventsState,
//     ...sharedState,
//     ...profileStates,
//     ...groupsStates,
//     ...mailingStore,
//     ...transportsStates,
//     ...roleStates,
//     ...chatStates,
//     ...virtualState,
//     ...journalsStates,
//     ...expeditionsStates,
//     ...unitsStates,
//     ...digitalSignatureStates,
//     ...exportImportStates,
//     ...eventMessagesState,
//     ...adminsState,
//     ...serversDashboardStates,
//     ...usersStates,
//     ...authStates,
//     ...mobileDevicesStates,
//     ...adminProfileState,
//     ...deviceDashboardStates,
//     ...systemSettingsState,
//     ...remoteDevicesState,
//     ...adminDevicesState,
//     ...detailsDeviceState,
//     ...generationState,
//     ...copyConfigurationState,
//     ...companyStates,
//     ...billingState,
//     ...financeState,
//     ...mailMessageState,
//     ...adminRegistratorLogs,
//     ...adminLogs,
// ];

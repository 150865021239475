import {UpdateMailMassageInterface} from "../../_interfaces/update-mail-massage.interface";
import {TextMail} from "../../_interfaces/mail-message.interface";

export class GetMailMessage {
    static readonly type = '[MailMessage] GetMailMessage';
}

export class InitMailMessageRows {
    static readonly type = '[MailMessage] InitMailMessageRows';

}

export class UpdateMailMessage {
    static readonly type = '[MailMessage] UpdateMailMessage';

    constructor(public data: UpdateMailMassageInterface) {
    }

}

export class SetEditMessage {
    static readonly type = '[MailMessage] SetEditMessage';

    constructor(public id: string) {
    }
}

export class CloseEditMessage {
    static readonly type = '[MailMessage] CloseEditMessage';

    constructor(public id: string, public savedRow?: TextMail) {
    }
}

import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetConstant, UpdateConstant } from '../actions/system-settings.actions';
import { ApiResponse } from '../../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { HTTP_STATUS } from '../../../../app-shared-elements/_enums/status.enum';
import { ConstantInterface, ConstantRowsInterface } from '../../_interfaces/system-settings.interface';
import {
    ColumnEditorType,
    ColumnModeEnum,
    ColumnsTableInterface,
    ColumnTypeEnum,
} from '../../../../app-shared-elements/_interfaces/ColumnsTable';
import { NotificationsService } from '../../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../../app-shared-elements/_enums/tooltip-status.enum';
import { SystemSettingsService } from '../../_services/system-settings.service';
import { CONSTANT_KEY } from '../../_enums/system-settings.enum';

export interface SystemSettingsStateModel {
    constants: ConstantInterface[];
    columnsTable: ColumnsTableInterface[];
    constantRows: ConstantRowsInterface[];
}

const SYSTEM_SETTINGS_TOKEN = new StateToken<SystemSettingsStateModel>('systemSettings');

const initColumn: ColumnsTableInterface[] = [
    {
        title: 'systemSettings.name',
        type: ColumnTypeEnum.text,
        name: 'title',
        grow: true,
        postIcons: true,
        isMultilang: true,
    },
    {
        title: 'systemSettings.value',
        editorType: ColumnEditorType.input,
        isCustomType: true,
        name: 'value',
        grow: true,
        optionsName: 'selectOptions',
        currentValueForSelect: 'value',
        mode: ColumnModeEnum.checkbox,
    },
    {
        title: 'systemSettings.updated',
        grow: false,
        small: false,
        maxWidth: '260px',
        minWidth: '200px',
        type: ColumnTypeEnum.date,
        name: 'updated',
    },
];

@State<SystemSettingsStateModel>({
    name: SYSTEM_SETTINGS_TOKEN,
    defaults: {
        constants: [],
        columnsTable: initColumn,
        constantRows: [],
    },
})
@Injectable()
export class SystemSettingsState {
    constructor(
        private http: HttpClient,
        private notificationService: NotificationsService,
        private systemSettingsService: SystemSettingsService,
    ) {}

    @Selector()
    static getConstantColumns(state: SystemSettingsStateModel): ColumnsTableInterface[] {
        return state.columnsTable;
    }

    @Selector()
    static getConstantRows(state: SystemSettingsStateModel): ConstantRowsInterface[] {
        return state.constantRows;
    }

    @Action(GetConstant)
    async getConstant(ctx: StateContext<SystemSettingsStateModel>): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/control/constant')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            result.data = result.data.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());

            ctx.setState({
                ...state,
                constants: result.data,
                constantRows: await Promise.all(
                    result.data.map(async (item) => {
                        return {
                            ...item,
                            title: 'systemSettings.' + item.key,
                            value: await this.systemSettingsService.getCurrentValue(item),
                            columnType: this.systemSettingsService.getCurrentColumnType(item),
                            selectOptions: this.systemSettingsService.getCurrentSelectOptions(item.key),
                            checkboxProperties: this.systemSettingsService.getCheckboxProperties(item),
                            postIcons: this.systemSettingsService.getPostIcons(item),
                        };
                    }),
                ),
            });
        }
    }

    @Action(UpdateConstant)
    async updateConstant(ctx: StateContext<SystemSettingsStateModel>, payload: UpdateConstant): Promise<void> {
        const state = ctx.getState();

        if (payload.data.key === CONSTANT_KEY.REQUIRE_CONFIRM_BY_ADMIN || payload.data.key === CONSTANT_KEY.REQUIRE_CONFIRM_EMAIL) {
            ctx.setState({
                ...state,
                constantRows: state.constantRows.map((r) => {
                    if (r.key === payload.data.key) {
                        return {
                            ...r,
                            checkboxProperties: {
                                value: {
                                    ...r.checkboxProperties.value,
                                    isDisabled: true,
                                },
                            },
                        };
                    }

                    return r;
                }),
            });
        }

        const result: ApiResponse = (await this.http
            .put('/api/control/constant', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
        }

        ctx.dispatch(new GetConstant());
    }
}

import { VariablesNameEnum } from '../_enums/variables-name.enum';
import { AlarmTypeEnum } from '../../events/_enums/alarm.enum';
import { ColumnTypeEnum, IconInterface } from './ColumnsTable';
import { VariableValueTypeEnum } from '../../groups/_enums/variable-value-type.enum';
import { TrendEnum } from '../_enums/trend.enum';

export class VariableChangeArchive {
    id: string;

    variable: Variable;
    variableId: number;

    value: string;

    ts: number;
}

export type CoreValueVariable = Pick<Variable, 'id' | 'internalId' | 'deviceId' | 'originRegistratorId' | 'currentValue' | 'name'>;

export class Variable {
    id: string;
    internalId: string;
    deviceId?: string;
    type: VariableType;
    validationType: string;
    readonly name: VariablesNameEnum | string;
    customName: string;
    currentValue: any;
    savedStatusConfiguration: ConfigurationVariableSavedEnum; // 0 - false, 1 - true, -1 - error
    savedStatusValue: ConfigurationVariableSavedEnum;
    valueToSave: string;
    restriction: string;
    variableUpdates: VariableChangeArchive[];
    unitId: string;
    unitName: string;
    hasEvent: boolean;
    status: AlarmTypeEnum;
    lastActive: string;
    originVariableId: string;
    originDeviceId: string;
    originRegistratorId: string;
    writable: number;
    archived: number; // 1 or 0
    changeable: number;
    isAknowledgeable: boolean;
    isConnect: boolean; // only virtual registrators
    isSocketActive: boolean; // only virtual registrators
    eventInEdit: boolean;
    hidden: number;
    trend: TrendEnum;

    originRegistratorName: string;
    originDeviceName: string;
    sleepStatus: string;
    originVariable: Variable;
}

export interface VariableForControlRowInterface {
    variable: Variable;
    variableName: string;
    currentValue: any;
    valueToSave: any;
    type: VariableType | VariableValueTypeEnum | any;
    id: string;
    savedStatusValue: ConfigurationVariableSavedEnum;
    translateType: string;
    columnType?: ColumnTypeEnum;
    postIcons: IconInterface<VariableFromControlActionIconEnum>[];

    disableClick: boolean;
}

export enum VariableFromControlActionIconEnum {
    revert = 'revert',
}

export enum VariableType {
    FLOAT = 'float',
    BYTE = 'byte',
    INT = 'int',
    SHORT = 'short',
    BOOL = 'bool',
    STRING = 'string',
    DATE = 'date',
}

export enum ConfigurationVariableSavedEnum {
    wait = 0,
    save = 1,
    error = -1,
}

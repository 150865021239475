import { Injectable } from '@angular/core';
import {
    ConfigurationVariableSavedEnum,
    Variable,
    VariableFromControlActionIconEnum,
    VariableType,
} from '../../app-shared-elements/_interfaces/Variable';
import { TranslateService } from '@ngx-translate/core';
import { IconInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';

@Injectable({
    providedIn: 'root',
})
export class GroupControlService {
    constructor(private translateService: TranslateService) {}

    async getTranslateVariableType(type: VariableType): Promise<string> {
        return (await this.translateService.get(`groups.types.${type}`).toPromise()) || '';
    }

    async getTranslateRangeValue(type: VariableType, restriction: string): Promise<string> {
        const [min, max] = restriction?.split(':').map(parseFloat);
        return await this.translateService
            .get('groups.restrictionRange', type === VariableType.BOOL ? { min: `false, 0`, max: `true, 1` } : { min, max })
            .toPromise();
    }

    getValidationType(variable: Variable): { type: string; restriction: string } | string {
        if (variable.validationType.includes('_')) {
            return {
                type: variable.validationType,
                restriction: variable.restriction,
            };
        }

        if (variable.restriction?.length) {
            return {
                type: null,
                restriction: variable.restriction,
            };
        }

        return variable.validationType;
    }

    getPostIcons(variable: Variable, isWait?: boolean): IconInterface<VariableFromControlActionIconEnum>[] {
        if (!variable) {
            return;
        }
        if (isWait) {
            return [
                {
                    path: './assets/design/icons/table-operations/status-saved-wait.svg',
                    action: null,
                    cellNames: ['variableName'],
                },
            ];
        }

        const result: IconInterface<VariableFromControlActionIconEnum>[] = [];

        switch (variable.savedStatusValue) {
            case ConfigurationVariableSavedEnum.error:
                result.push({
                    path: './assets/design/icons/table-operations/status-saved-revert.svg',
                    action: VariableFromControlActionIconEnum.revert,
                    cellNames: ['variableName'],
                });
                break;
            case ConfigurationVariableSavedEnum.wait:
                result.push({
                    path: './assets/design/icons/table-operations/status-saved-wait.svg',
                    action: null,
                    cellNames: ['variableName'],
                });
                break;
        }

        return result;
    }
}

import {NotificationTransportType} from 'src/app/mailing/_interfaces/notification-log.interface';
import {UserNotificationTransportStatusEnum} from '../_enums/user-notification-transport-status.enum';

export interface UserNotificationTransport {
    userId: string;
    transportType: NotificationTransportType;
    transport: string;
    isActive: boolean;
    savedStatus?: UserNotificationTransportStatusEnum;
    readonly inputPlaceholder?: string;
    updated?: Date;
    isValid?: boolean;
    resource: NotificationTransportResourceEnum[];
}

export enum NotificationTransportResourceEnum {
    REPORTS = 'reports',
    EVENTS = 'events'
}

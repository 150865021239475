import {Injectable} from '@angular/core';
import {ColumnEditorType, ColumnsTableInterface, ColumnTypeEnum} from '../../../app-shared-elements/_interfaces/ColumnsTable';
import {MailingCellFieldTypeEnum} from '../../../mailing/_enums/mailing-cell-field-type.enum';
import {TableNamesEnum} from '../../../app-shared-elements/_enums/table-names.enum';
import {AlarmTypeEnum} from '../../../events/_enums/alarm.enum';
import {SelectTypeEnum} from '../../../app-shared-elements/_enums/select-type.enum';
import {SelectOptionInterface} from '../../../app-shared-elements/_interfaces/select-option.interface';
import {ColumnsActionTypeEnum} from 'src/app/app-shared-elements/_enums/columns-action-type.enum';

@Injectable({
    providedIn: 'root'
})
export class EventMessagesService {

    statusMessagesTable: ColumnsTableInterface[] = [
        {
            title: 'statusMessage.table.type',
            grow: false,
            small: false,
            maxWidth: '230px',
            minWidth: '200px',
            type: ColumnTypeEnum.select,
            optionsName: 'typeOptions',
            isSelectEditable: true,
            selectType: SelectTypeEnum.report,
            name: 'type',
            placeholderForSelectColumn: 'statusMessage.table.placeholder',
            currentValueForSelect: 'translateType'
        },
        {
            title: 'statusMessage.table.key',
            grow: true,
            type: ColumnTypeEnum.mailingEditor,
            mailingFieldType: MailingCellFieldTypeEnum.input,
            name: 'key',
        },
        {
            title: 'statusMessage.table.message',
            grow: true,
            small: false,
            type: ColumnTypeEnum.mailingEditor,
            mailingFieldType: MailingCellFieldTypeEnum.textarea,
            name: 'message',
            isInputConstructor: true
        },
        {
            title: 'statusMessage.table.action',
            type: ColumnTypeEnum.mailingEditor,
            name: 'edit',
            minWidth: '160px',
            maxWidth: '160px',
            mailingFieldType: MailingCellFieldTypeEnum.btns,
            tableName: TableNamesEnum.statusMessages,
            actionBtns: [ColumnsActionTypeEnum.actionBtnsEdit, ColumnsActionTypeEnum.actionBtnsDelete]
        }
    ];

    limitMessagesTable: ColumnsTableInterface[] = [
        {
            title: 'limitMessage.type',
            grow: false,
            small: false,
            minWidth: '150px',
            maxWidth: '180px',
            type: ColumnTypeEnum.text,
            name: 'translateType',
            isMultilang: true
        },
        {
            title: 'events.messagesCatalog.table.text',
            grow: true,
            small: false,
            type: ColumnTypeEnum.editor,
            editorType: ColumnEditorType.textarea,
            name: 'message'
        },
    ];

    constructor() {
    }

    getTypeOptions(): SelectOptionInterface<AlarmTypeEnum, string, { cellName: string, data: AlarmTypeEnum }>[] {
        return [
            {
                key: AlarmTypeEnum.attention,
                value: 'statusMessage.attention',
                property: {cellName: 'type', data: AlarmTypeEnum.attention}
            },
            {
                key: AlarmTypeEnum.alarm,
                value: 'statusMessage.alarm',
                property: {cellName: 'type', data: AlarmTypeEnum.alarm}
            }
        ];

    }
}

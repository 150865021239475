import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { filter, first } from 'rxjs/operators';
import { RolesService } from 'src/app/admin/roles/_services/roles.service';
import { TooltipStatusEnum } from '../_enums/tooltip-status.enum';
import { MethodPermission, ResourceAction } from '../_enums/permission.enum';
import { RoleInterface } from '../_interfaces/role.interface';
import { NotificationsService } from '../_services/notifications.service';
import { PermissionService } from '../_services/permission.service';
import { PermissionsState } from '../_store/states/permissions.state';
import { UserState } from '../_store/states/user.state';

@Injectable({
    providedIn: 'root',
})
export class AdminPermissionGuard {
    constructor(
        private store: Store,
        private router: Router,
        private permissionService: PermissionService,
        private notificationsService: NotificationsService,
    ) {}
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        try {
            const roles: RoleInterface[] = (await this.store
                .select(PermissionsState.getRoles)
                .pipe(
                    filter((r) => !!r && !!r.length),
                    first(),
                )
                .toPromise()
                .catch((e) => console.log(e))) as RoleInterface[];

            const currentRole: RoleInterface = roles.find((r) => r.id === this.store.selectSnapshot(UserState.getUser).roleId);
            const data = {
                registrator: null,
                action: route.data.adminResourceAction as ResourceAction,
                permissions: [MethodPermission.READ],
            };

            if (currentRole && currentRole.name === RolesService.rootRole) {
                return true;
            }

            if (!currentRole || this.permissionService.checkPermission(currentRole.permissions, data, true)) {
                return true;
            } else {
                this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'You dont have permission for view this page');
                this.router.navigate(['/403']);

                return false;
            }
            // return true;
        } catch (e) {
            console.log(e);
            this.router.navigate(['/403']);
            return false;
        }
    }
}

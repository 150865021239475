<div *ngIf="breadcrumbs$ | async" class="breadcrumbs">
    <div class="breadcrumbs__wrapper">
        <div class="breadcrumbs__list">
                    <div class="breadcrumbs__arrow breadcrumbs__arrow--sub">
                        <svg-icon [src]="BREADCRUMBS_ICON_PARH"></svg-icon>
                    </div>
                    <div (click)="moveTo(data)" class="breadcrumbs__item">

                        <span class="breadcrumbs__link">{{(breadcrumbs$ | async).title | translate}}</span>
                        <div *ngIf="(breadcrumbs$ | async).children" class="breadcrumbs__list breadcrumbs__list--sub">
                                <div class="breadcrumbs__arrow breadcrumbs__arrow--sub">
                                    <svg-icon [src]="BREADCRUMBS_ICON_PARH"></svg-icon>
                                </div>
                                <div class="breadcrumbs__item">
                                    <span class="breadcrumbs__link breadcrumbs__link--sub">{{(breadcrumbs$ | async).children.title}}</span>
                                </div>
                        </div>
                    </div>
        </div>
    </div>
</div>


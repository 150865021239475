<nav class="nav" appTheme>
    <div class="mainer">
        <div class="nav__wrapper"
             [class.nav__wrapper--empty]="(!(registrators$ | async) || !(registrators$ | async).length) && userType === typeClient.User"
        >

            <div (click)="moveToLogo()" class="nav__logo"
                 [class.nav__logo--ccc]="(configurationServer$ | async)?.serverName === serverNamesEnum.CCC"
            >
                <ng-container *ngIf="userType === typeClient.User && (configurationServer$ | async)?.serverName">
                    <svg-icon
                        [src]="(configurationServer$ | async).serverName === serverNamesEnum.SC ? LOGO_PATH : LOGO_CCC_PATH"></svg-icon>
                </ng-container>

                <ng-container *ngIf="userType === typeClient.Admin">
                    <svg-icon [src]="LOGO_ADMIN_PATH"></svg-icon>
                </ng-container>
            </div>

            <ng-container *ngIf="userType === typeClient.User">
                <ul class="nav__list">
                    <li *ngFor="let item of (navigationList$ | async); trackBy: trackByName"
                        class="nav__list-item"
                        [class.hide]="!(registrators$ | async).length && !item.show"
                        [class.active]="item.action || router.url.includes(item.link)"
                        appClickOutside
                        [isActiveClickOutside]="item.toggle"
                        (click)="toggleNavigationItem(item)"
                        (clickOutside)="toggleNavigationItem(item)">

                        <a *ngIf="item.link" class="nav__list-link" [routerLink]="[item.link]"
                           [class.nav__list-link--sync]="item.isSyncIcon && (isDeviceSyncStatus$ | async)">
                            <span [translate]="item.name"></span>
                            <ng-container *ngIf="item.children && item.children.length && !item.isTabs">
                                <div class="nav__arrow">
                                    <svg-icon [src]="NAV_ARROW_PATH"></svg-icon>
                                </div>
                            </ng-container>
                        </a>
                        <div *ngIf="!item.link" class="nav__list-link">
                            <span [translate]="item.name"></span>
                            <ng-container *ngIf="item.children && item.children.length && !item.isTabs">
                                <div class="nav__arrow">
                                    <svg-icon [src]="NAV_ARROW_PATH"></svg-icon>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="item.children && item.children.length && item.toggle && !item.isTabs"
                             class="nav__setting">
                            <div class="nav__setting-content">
                                <ul>
                                    <ng-container *ngFor="let subItem of item.children">
                                        <li *ngIf="subItem.name && subItem.link"
                                            class="nav__setting-item"
                                            [class.hide]="!(registrators$ | async).length && !subItem.show"
                                            [class.active]="subItem.action"
                                            [routerLink]="[subItem.link]">
                                            <a class="nav__setting-link" [translate]="subItem.name"></a>

                                            <ng-container *ngIf="subItem.isSyncIcon && (isDeviceSyncStatus$ | async)">
                                                <span class="nav__sync">
                                                    <svg-icon [src]="NAV_SYNC_PATH"></svg-icon>
                                                </span>
                                            </ng-container>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </ng-container>

            <ng-container *ngIf="userType === typeClient.Admin">
                <ul class="nav__list">
                    <li *ngFor="let item of (navigationAdminList$ | async); trackBy: trackByName" class="nav__list-item"
                        [class.active]="item.action || router.url.includes(item.link)"
                        appClickOutside
                        [isActiveClickOutside]="item.toggle"
                        (click)="toggleNavigationItem(item)"
                        (clickOutside)="toggleNavigationItem(item)">

                        <a *ngIf="item.link" class="nav__list-link"
                           (click)="clearQuery()"
                           [routerLink]="[item.link]">
                            <span [translate]="item.name"></span>
                            <ng-container *ngIf="item.children && item.children.length && !item.isTabs">
                                <div class="nav__arrow">
                                    <svg-icon [src]="NAV_ARROW_PATH"></svg-icon>

                                </div>
                            </ng-container>
                        </a>
                        <div *ngIf="!item.link" class="nav__list-link">
                            <span [translate]="item.name"></span>
                            <ng-container *ngIf="item.children && item.children.length && !item.isTabs">
                                <div class="nav__arrow">
                                    <svg-icon [src]="NAV_ARROW_PATH"></svg-icon>

                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="item.children && item.children.length && item.toggle && !item.isTabs"
                             class="nav__setting">
                            <div class="nav__setting-content">
                                <ul>
                                    <ng-container *ngFor="let subItem of item.children">
                                        <li *ngIf="subItem.name && subItem.link" class="nav__setting-item"
                                            [class.active]="subItem.action"
                                            [routerLink]="[subItem.link]">
                                            <a class="nav__setting-link" [translate]="subItem.name"></a>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </ng-container>


            <div *ngIf="!(isAdmin$ | async)" class="nav__alert">
                <app-error-alert></app-error-alert>
            </div>
            <div class="nav__settings">

                <div *ngIf="userType === typeClient.User" class="nav__settings-item nav__settings-item--sound"
                     [class.nav__settings-item--sound-off]="!(user$ | async)?.isAlarmSound"
                     (click)="onOffSoundAlarm()">

                    <svg-icon [src]="VOLUME_PATH"></svg-icon>

                </div>

                <div (click)="movetoNotifications()"
                     class="nav__settings-item nav__settings-item--notification">
                    <app-nav-notifications></app-nav-notifications>
                </div>

                <div class="nav__settings-item nav__settings-item--profile"
                     [class.nav__settings-item--active]="router.url.includes('/profile') || router.url.includes('/billing')"
                     (click)="openProfile = !openProfile"
                     appClickOutside
                     [isActiveClickOutside]="openProfile"
                     (clickOutside)="openProfile = false">

                    <svg-icon [class.google]="isGoogleUser"
                              [src]="isGoogleUser ? USER_GOOGLE_PATH : USER_PATH"></svg-icon>

                    <div *ngIf="user$ | async" class="nav__settings-name">
                        {{ (user$ | async).name }}
                    </div>
                    <div class="arrow">
                        <svg-icon [src]="NAV_ARROW_PATH"></svg-icon>
                    </div>
                    <div class="nav__popup" [class.block]="openProfile">
                        <app-profile-menu></app-profile-menu>
                    </div>
                </div>

                <div class="nav__settings-item nav__settings-item--ln">
                    <app-lang></app-lang>
                </div>
            </div>

        </div>
    </div>
</nav>


import { Injectable, NgZone } from '@angular/core';
import { SignalEnum } from '../_enums/signal.enum';
import { Store } from '@ngxs/store';
import { Platform } from '@angular/cdk/platform';
import { UserState } from '../_store/states/user.state';
import { UpdateUser } from '../_store/actions/user.actions';
import { UpdateUserDtoInterface } from '../../profile/_interfaces/update-user-dto.interface';

@Injectable({
    providedIn: 'root',
})
export class SignalService {
    public signal = new Audio();

    constructor(
        private store: Store,
        private platform: Platform,
        private ngZone: NgZone,
    ) {
        this.signal.muted = true;
        this.signal.loop = true;
    }

    public initSignal(type: SignalEnum): void {
        this.signal.src = `assets/sound/${type}-signal.mp3`;
        this.signal.muted = false;
        // console.log(this.store.selectSnapshot(UserState.getUser));
        if (!this.store.selectSnapshot(UserState.getUser).isAlarmSound) {
            // && (this.platform.IOS || this.platform.ANDROID)
            this.signal.muted = true;
        }
        this.signal.load();
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                this.signal.play().catch((e) => console.log(e));
            }, 500);
        });
    }

    async updateSignal(): Promise<void> {
        const user = this.store.selectSnapshot(UserState.getUser);
        const data: UpdateUserDtoInterface = {
            name: user.name,
            phone: user.phone,
            timeBlocked: user.timeBlocked,
            sessionLife: user.sessionLife,
            dateFormat: user.dateFormat,
            dateTimeZone: user.dateTimeZone,
            isAlarmSound: !user.isAlarmSound,
            lang: user.lang,
        };
        await this.store.dispatch(new UpdateUser(data)).toPromise();
        this.signal.muted = !this.signal.muted;
    }

    public destroySignal(): void {
        this.signal.pause();
        this.signal.muted = true;
    }
}

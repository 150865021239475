import {UpdateBillingAccountInfoInterface} from "../../../../billing/_interfaces/billing-personal-account.interface";
import {BillingAccountInfoTypeEnum} from "../../../../billing/_enums/billing-account-info-type.enum";

export class GetProviderAccount {
    static readonly type = '[FinancePayInfo] GetProviderAccount';

}

export class GetProviderAccountInfo {
    static readonly type = '[FinancePayInfo] GetProviderAccountInfo';

}

export class ChangeAccountInfoOptions {
    static readonly type = '[FinancePayInfo] ChangeAccountInfoOptions';

    constructor(public value: string, public type: BillingAccountInfoTypeEnum) {
    }
}

export class UpdateProviderAccount {
    static readonly type = '[FinancePayInfo] UpdateProviderAccount';

    constructor(public data: UpdateBillingAccountInfoInterface) {
    }

}

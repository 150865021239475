import {SharedUserInterface} from '../../user-devices/_interface/shared-user.interface';
import {Action, Selector, State, StateContext, StateToken, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiResponse} from '../../../app-shared-elements/_interfaces/ApiRequest';
import {HTTP_STATUS} from '../../../app-shared-elements/_enums/status.enum';
import {ShareStatusEnum} from '../../../app-shared-elements/_enums/share-status.enum';
import {PermissionsState} from '../../../app-shared-elements/_store/states/permissions.state';
import {TooltipStatusEnum} from '../../../app-shared-elements/_enums/tooltip-status.enum';
import {NotificationsService} from '../../../app-shared-elements/_services/notifications.service';
import {AddUserInNewSharingList, ChangeRoleInNewUser, ChangeRoleInOldUser, CheckShareUser, ClearNewShareUserList, InitSharedUserList, SaveSharingList, SetSharingDevice} from '../actions/share-device.actions';
import {ShareSettingsInterface} from '../../user-devices/_interface/share-settings.interface';

export interface ShareDeviceStateModel {
    sharedRegistratorId: string;
    oldSharingUserInDevice: SharedUserInterface[];
    newSharingUserInDevice: SharedUserInterface[];
    status: ApiResponse;
}

const SHARE_DEVICE_TOKEN = new StateToken<ShareDeviceStateModel>('shareDevice');

@State<ShareDeviceStateModel>({
    name: SHARE_DEVICE_TOKEN,
    defaults: {
        sharedRegistratorId: null,
        oldSharingUserInDevice: [],
        newSharingUserInDevice: [],
        status: null
    }
})

@Injectable()
export class ShareDeviceState {
    constructor(private notificationsService: NotificationsService,
                private store: Store,
                private http: HttpClient) {
    }

    @Selector()
    static getNewSharingUserInDevice(state: ShareDeviceStateModel): SharedUserInterface[] {
        return state.newSharingUserInDevice;
    }

    @Selector()
    static getOldSharingUserInDevice(state: ShareDeviceStateModel): SharedUserInterface[] {
        return state.oldSharingUserInDevice;
    }

    @Selector()
    static getShareDeviceStatus(state: ShareDeviceStateModel): ApiResponse {
        return state.status;
    }

    @Action(SetSharingDevice)
    setSharingDevice(ctx: StateContext<ShareDeviceStateModel>, payload: SetSharingDevice): void {
        const state = ctx.getState();


        ctx.setState({
            ...state,
            sharedRegistratorId: payload.registratorId
        });
    }

    @Action(InitSharedUserList)
    async initSharedUserList(ctx: StateContext<ShareDeviceStateModel>): Promise<void> {
        const state = ctx.getState();

        const headers = new HttpHeaders({registratorId: state.sharedRegistratorId});
        const result: ApiResponse = await this.http.get('api/share-registrators/share', {params: {registratorId: state.sharedRegistratorId}, headers}).toPromise() as ApiResponse;

        if (!result || result.status !== HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state
            });

            return;
        }

        const list: SharedUserInterface[] = result.data.shared.map(user => {
            return {
                name: user.name,
                status: ShareStatusEnum.share,
                id: user.id,
                role: user.role,
                email: user.email,
                updated: false,
                currentRoleOption: this.store.selectSnapshot(PermissionsState.getRolesOptions).find(option => option.key === user.role)
            };
        });

        ctx.setState({
            ...state,
            oldSharingUserInDevice: [...list, ...result.data.waitShared.map(user => {
                return {
                    name: user.name,
                    status: ShareStatusEnum.wait,
                    id: user.id,
                    role: user.role,
                    email: user.email,
                    currentRoleOption: this.store.selectSnapshot(PermissionsState.getRolesOptions).find(option => option.key === user.role)
                };
            })]
        });

    }

    @Action(AddUserInNewSharingList)
    addUserInNewSharingList(ctx: StateContext<ShareDeviceStateModel>, payload: AddUserInNewSharingList): void {
        if (!payload.user) {
            return;
        }

        const state = ctx.getState();

        ctx.setState({
            ...state,
            newSharingUserInDevice: [...state.newSharingUserInDevice, payload.user]
        });
    }

    @Action(ChangeRoleInOldUser)
    changeRoleInOldUser(ctx: StateContext<ShareDeviceStateModel>, payload: ChangeRoleInOldUser): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            oldSharingUserInDevice: state.oldSharingUserInDevice.map(user => {

                if (user.id !== payload.id) {
                    return user;
                }

                if (payload.roleOption === 'delete') {
                    user.role = 'delete';
                    user.roleName = 'delete';
                    user.updated = true;

                    return user;
                }

                user.role = payload.roleOption.key;
                user.roleName = payload.roleOption.value;
                user.updated = true;

                return user;
            })
        });

    }

    @Action(ChangeRoleInNewUser)
    changeRoleInNewUser(ctx: StateContext<ShareDeviceStateModel>, payload: ChangeRoleInNewUser): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            newSharingUserInDevice: state.newSharingUserInDevice.map(user => {
                if (user.email !== payload.id && payload.roleOption !== 'delete') {
                    return user;
                }

                if (payload.roleOption === 'delete') {
                    user.role = 'delete';
                    user.roleName = 'delete';
                    user.updated = true;
                    return user;
                }

                user.role = payload.roleOption.key;
                user.roleName = payload.roleOption.value;
                user.updated = true;

                return user;
            })
        });
    }

    @Action(SaveSharingList)
    async saveSharingList(ctx: StateContext<ShareDeviceStateModel>, payload: SaveSharingList): Promise<void> {
        const state = ctx.getState();
        const removeList: string[] = [];
        const newList: { email: string, role: string }[] = [];
        const updateList: { email: string, role: string }[] = [];
        const updateWaitLst: { email: string, role: string }[] = [];


        state.oldSharingUserInDevice.forEach(user => {
            if (user.role === 'delete') {
                removeList.push(user.email);
            }
        });

        state.newSharingUserInDevice.forEach(user => {
            if (user.role === 'delete') {
                return;
            }

            newList.push({
                email: user.email,
                role: user.role
            });
        });

        state.oldSharingUserInDevice.forEach(user => {
            if (!user.updated || user.role === 'delete') {
                return;
            }

            switch (user.status) {
                case ShareStatusEnum.share:
                    updateList.push({
                        email: user.email,
                        role: user.role
                    });
                    break;
                case ShareStatusEnum.wait:
                    updateWaitLst.push({
                        email: user.email,
                        role: user.role
                    });
                    break;

            }
        });

        const headers = new HttpHeaders({registratorId: state.sharedRegistratorId});

        const shareSettingInterface: ShareSettingsInterface = {
            registratorId: state.sharedRegistratorId,
            message: payload.message,
            newShare: newList,
            updateShare: updateList,
            updateWaitShare: updateWaitLst,
            deleteShare: removeList
        };

        const result: ApiResponse = await this.http.post('api/share-registrators/share', {
            ...shareSettingInterface
        }, {headers}).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }

        ctx.setState({
            ...state,
            sharedRegistratorId: null,
            oldSharingUserInDevice: [],
            newSharingUserInDevice: []
        });
    }

    @Action(CheckShareUser)
    async checkShareUser(ctx: StateContext<ShareDeviceStateModel>, payload: CheckShareUser): Promise<void> {
        const state = ctx.getState();

        const headers = new HttpHeaders({registratorId: payload.data.registratorId});
        const result: ApiResponse = await this.http.post('api/share-registrators/check-shared', {...payload.data}, {headers}).toPromise().catch(e => console.log(e)) as ApiResponse;

        ctx.setState({
            ...state,
            status: result
        });
    }

    @Action(ClearNewShareUserList)
    clearNewShareUserList(ctx: StateContext<ShareDeviceStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            newSharingUserInDevice: []
        });
    }
}

import { ParamsFilterForClient, ParamsFilterTypeEnum } from '../../../app-shared-elements/_interfaces/params.interface';
import { PaymentStatusEnum } from '../../../billing/_interfaces/billing-invoice.interface';

export const financeDebtorsFiltersCheckbox: ParamsFilterForClient[] = [
    {
        title: 'billing.invoice.status.awaitPaid',
        property: 'paymentStatus',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'paymentStatus',
                type: ParamsFilterTypeEnum.TEXT,
                value: PaymentStatusEnum.AWAITING_PAYMENT,
            },
        ],
    },
    {
        title: 'billing.invoice.status.paymentNotPaid',
        property: 'paymentStatus',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'paymentStatus',
                type: ParamsFilterTypeEnum.TEXT,
                value: PaymentStatusEnum.PAYMENT_NOT_PAID,
            },
        ],
    },
    {
        title: 'billing.invoice.status.latePaid',
        property: 'paymentStatus',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'paymentStatus',
                type: ParamsFilterTypeEnum.TEXT,
                value: PaymentStatusEnum.LATE_PAYMENT,
            },
        ],
    },
    {
        title: 'billing.invoice.status.paid',
        property: 'paymentStatus',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'paymentStatus',
                type: ParamsFilterTypeEnum.TEXT,
                value: PaymentStatusEnum.PAID,
            },
        ],
    },
];

export const financeDebtorsDropdown: ParamsFilterForClient[] = [
    {
        property: 'accountNumber',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true,
    },
    {
        property: 'name',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true,
    },
];

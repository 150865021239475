<div class="m-filter">
    <div class="m-filter__wrapper">
        <div class="m-filter__header">
            <div class="m-filter__icon">
                <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="38" height="38" fill="#45495E"/>
                    <path d="M31.7171 4.75H6.30792C5.85977 4.74915 5.44892 4.99951 5.24406 5.39821C5.03637 5.80228 5.07282 6.28886 5.33815 6.65789L14.647 19.7712C14.6501 19.7757 14.6535 19.78 14.6566 19.7845C14.9948 20.2411 15.1777 20.7941 15.1788 21.3626V32.0538C15.1768 32.3705 15.3011 32.6746 15.5244 32.8992C15.7476 33.1239 16.0511 33.2499 16.3675 33.2499C16.5286 33.2499 16.6877 33.2177 16.8363 33.1561L22.0672 31.1614C22.536 31.0185 22.8471 30.5762 22.8471 30.0436V21.3626C22.8479 20.7944 23.031 20.2411 23.369 19.7845C23.3721 19.78 23.3755 19.7757 23.3786 19.7712L32.6871 6.65732C32.9525 6.28857 32.9889 5.80228 32.7812 5.39821C32.5764 4.99951 32.1655 4.74915 31.7171 4.75ZM22.2025 18.9275C21.6826 19.633 21.4015 20.4861 21.3998 21.3626V29.8678L16.6255 31.6881V21.3626C16.6238 20.4861 16.3427 19.633 15.8225 18.9275L6.78517 6.19674H31.2401L22.2025 18.9275Z" fill="#F8F8FD" stroke="#F8F8FD" stroke-width="0.5"/>
                </svg>
            </div>
            <div class="m-filter__close" (click)="close()">
                <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32 19L32.7752 19.9806L34.0155 19L32.7752 18.0194L32 19ZM6 17.75C5.30964 17.75 4.75 18.3096 4.75 19C4.75 19.6904 5.30964 20.25 6 20.25V17.75ZM18.5761 28.0194C18.0346 28.4476 17.9426 29.2337 18.3708 29.7752C18.7989 30.3168 19.585 30.4087 20.1266 29.9806L18.5761 28.0194ZM20.1266 8.01943C19.585 7.59128 18.7989 7.68321 18.3708 8.22477C17.9426 8.76632 18.0346 9.55242 18.5761 9.98057L20.1266 8.01943ZM32 17.75H6V20.25H32V17.75ZM31.2248 18.0194L18.5761 28.0194L20.1266 29.9806L32.7752 19.9806L31.2248 18.0194ZM32.7752 18.0194L20.1266 8.01943L18.5761 9.98057L31.2248 19.9806L32.7752 18.0194Z" fill="#F8F8FD"/>
                    <path d="M32 19L32.9146 20.1889L34.4602 19L32.9146 17.8111L32 19ZM18.0854 27.8111C17.4288 28.3162 17.306 29.2579 17.8111 29.9146C18.3162 30.5712 19.2579 30.694 19.9146 30.1889L18.0854 27.8111ZM19.9146 7.81106C19.2579 7.30596 18.3162 7.4288 17.8111 8.08543C17.306 8.74207 17.4288 9.68384 18.0854 10.1889L19.9146 7.81106ZM31.0854 17.8111L18.0854 27.8111L19.9146 30.1889L32.9146 20.1889L31.0854 17.8111ZM32.9146 17.8111L19.9146 7.81106L18.0854 10.1889L31.0854 20.1889L32.9146 17.8111Z" fill="#F8F8FD"/>
                </svg>
            </div>
        </div>
        <div class="m-filter__body">
            <div class="m-filter__list">
                <ng-container *ngFor="let filter of filters">
                    <div *ngIf="filter.type === paramsFilterTypeEnum.BOOLEAN" class="m-filter__item"
                        [class.m-filter__item--active]="filter.value"
                        (click)="changeFilter(filter)">
                        <div class="m-filter__item-title" [translate]="filter.title"></div>
                    </div>
                </ng-container>

            </div>
        </div>
    </div>
</div>

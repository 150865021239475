<ng-container>
    <app-nav></app-nav>
    <div appTheme class="container">
        <div *ngIf="showSideBar$ | async" class="container__sidebar">
            <app-sidebar></app-sidebar>
        </div>

        <div class="container__main">
            <app-main></app-main>
        </div>
    </div>
</ng-container>





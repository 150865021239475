export class GetCheckPdfSignature {
    static readonly type = '[DigitalSignature] GetCheckPdfSignature';

    constructor(public file: string) {
    }
}

export class ResetDigitalSignature {
    static readonly type = '[DigitalSignature] ResetDigitalSignature';
}

export class ClearDigitalSignatureFile {
    static readonly type = '[DigitalSignature] ClearDigitalSignatureFile';

}

export class SetSelectedFile {
    static readonly type = '[DigitalSignature] SetSelectedFile';

    constructor(public file: { base64File: string, name: string }) {
    }
}

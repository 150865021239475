<div class="sidebar" appTheme>

    <div class="sidebar__wrapper">
        <ul *ngIf="currentRegistrator$ | async" class="sidebar__list">

            <ng-container *ngFor="let item of sidebarList">

                <ng-container *ngIf="!item.isPermission && item.isShowItem">
                    <li class="sidebar__item"
                        [class.active]="router.url.includes(item.link)"
                        (click)="moveTo(item.property)">

                        <div class="sidebar__item-icon">

                            <svg-icon [src]="item.property"></svg-icon>

                        </div>

                        <ng-container *ngIf="item.property === sidebarIconListEnum.detailsDevice; else title">
                            <div class="sidebar__item-title"
                                 [translate]="(currentDevice$ | async)?.type === deviceTypeEnum.datalogger
                                 ? 'detailsDevice.dataloggerTitle' : item.title"></div>
                        </ng-container>

                        <ng-template #title>
                            <div class="sidebar__item-title"
                                 [translate]="item.title"></div>
                        </ng-template>
                    </li>
                </ng-container>

                <ng-container *ngIf="item.isPermission && item.isShowItem">

                    <li *appPermission="{registrator: (currentRegistrator$ | async), action: item.isPermissionProperty, permissions: [methodPermission.READ]}"
                        class="sidebar__item"
                        [class.active]="router.url.includes(item.link)"
                        (click)="moveTo(item.property)">

                        <div class="sidebar__item-icon">

                            <svg-icon [src]="item.property"></svg-icon>

                        </div>

                        <div class="sidebar__item-title" [translate]="item.title"></div>
                    </li>
                </ng-container>


            </ng-container>

        </ul>
    </div>
</div>

<div class="column"
     [class.column--th]="isTh">

    <div class="column-input" [class.column-input--th]="isTh"
         [ngStyle]="type === columnModeEnum.checkbox ? row?.checkboxProperties[cell.name]?.styles : ''">

        <div *ngIf="cell?.preIcons" class="column__wrapper">
            <div *ngIf="cell.preIcons && row?.preIcons && row?.preIcons.length" class="column-input__pre-icons">
                <ng-container *ngFor="let icon of row.preIcons">
                    <div *ngIf="icon.cellNames.includes(cell.name)"
                         class="column-input__pre-icons-item">
                        <svg-icon [src]="icon.path" [svgStyle]="icon.styles"></svg-icon>
                    </div>

                </ng-container>

            </div>
        </div>

        <ng-container *ngIf="type === columnModeEnum.color">
            <app-color-picker [color]="value" (colorChanged)="onChange($event)"></app-color-picker>

            <ng-container *ngIf="cell.isColorCheckbox">

                <div class="column__checkbox">
                    <app-checkbox
                        [value]="row?.checkboxValue"
                        (changeEvent)="changeCheckbox($event)"
                    ></app-checkbox>

                    <div class="column__tooltip">

                        <app-tooltip
                            [value]="row?.checkboxTooltipValue"
                        ></app-tooltip>

                    </div>
                </div>

            </ng-container>
        </ng-container>
        <ng-container *ngIf="type === columnModeEnum.text">
            <input class="column-input__input column-input__input--text input"
                   [class.input--error]="cell?.propertyValidationTypeForInput && isErrorValidation"
                   [style.width]="'80px'"
                   type="text"
                   [disabled]="row?.disabledProperties && row?.disabledProperties[cell.name]"
                   (focusout)="onChange($event.target.value)"
                   [(ngModel)]="value"
                   [appConstantValidate]="cell?.propertyValidationTypeForInput ? {type: row[cell.propertyValidationTypeForInput], value: value} : null"
                   (validateValue)="validateEventChange($event)">
        </ng-container>
        <ng-container *ngIf="type === columnModeEnum.number">
            <input
                class="column-input__input column-input__input--number input"
                [style.width]="'80px'"
                min="1"
                type="number"
                [appMaxNumberValue]="cell?.maxValue"
                (input)="onChange(+$event.target.value)"
                (change)="onChange(+$event.target.value)"
                [(ngModel)]="value"
                appClickOutside
                [isActiveClickOutside]="value !== 1"
                (clickOutside)="checkNumberValue()">
        </ng-container>
        <ng-container *ngIf="type === columnModeEnum.checkbox && row?.checkboxProperties[cell.name].isCheckbox">
            <app-checkbox [value]="value"
                          [isError]="row?.checkboxProperties[cell.name]?.isError"
                          [isWaiting]="row?.checkboxProperties[cell.name]?.isWaiting"
                          [isFreeze]="row?.checkboxProperties[cell.name]?.isFreeze"
                          [isDisabled]="row?.checkboxProperties[cell.name]?.isDisabled"
                          (changeEvent)="onChange($event)"></app-checkbox>

        </ng-container>
    </div>
</div>



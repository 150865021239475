import { TooltipStatusEnum } from '../../app-shared-elements/_enums/tooltip-status.enum';
import { NotificationsService } from '../../app-shared-elements/_services/notifications.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DropdownFilterOptionInterface } from '../../app-shared-elements/filters/interfaces/filter-option.interface';
import { Observable, Subject } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { ActiveEvents } from 'src/app/events/_interfaces/ActiveEvents';
import { ActiveEventsState } from 'src/app/app-shared-elements/_store/states/active-events.state';
import { DevicesState } from '../../device-dashboard/_store/states/user-devices.state';
import { PermissionsState } from 'src/app/app-shared-elements/_store/states/permissions.state';
import { MethodPermission, ResourceAction } from 'src/app/app-shared-elements/_enums/permission.enum';
import { PermissionService } from 'src/app/app-shared-elements/_services/permission.service';
import { SetCurrentRegistrator } from 'src/app/device-dashboard/_store/actions/user-devices.actions';
import { filter, first, skip, takeUntil } from 'rxjs/operators';
import { SetFilterConfig, SetTimeObj, SetTimeType } from 'src/app/app-shared-elements/_store/actions/time-filter.actions';
import { DisabledFilterOptionsEnum } from 'src/app/app-shared-elements/_enums/filter-options.enum';
import { TechnologicLogRowInterface } from '../_interfaces/technologic-log-row.interface';
import { FilterGroupSystemLogsType, FilterTypeSystemLogsEnum } from '../_enums/filter-group-system-logs.enum';
import { TechnologicEventsState } from '../_store/states/technologic-events.state';
import { EventLogsService } from '../_services/event-logs.service';
import { GetTechnologicEventsRows, SetAcknowledge, SetTechnologicFilter } from '../_store/actions/technologic-events.action';
import { FilterTypePipeEnum } from '../../app-shared-elements/_enums/filter-type-pipe.enum';
import {
    Pagination,
    Params,
    ParamsFilter,
    ParamsFilterForClient,
    ParamsFilterTypeEnum,
    ParamsSorted,
    ParamsTime,
    ParamsTimeTypeEnum,
} from 'src/app/app-shared-elements/_interfaces/params.interface';
import { ColumnsTableInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { PreloaderService } from '../../app-shared-elements/_services/preloader.service';
import { PreloaderWrappersEnum } from '../../app-shared-elements/_enums/preloader-wrappers.enum';
import { GetLogicalEvent, SetEventMode } from '../../events/_store/actions/logical-events.actions';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { EventsFiltersNamesEnum } from '../_enums/name-filters.enum';
import { TimeFilterState } from 'src/app/app-shared-elements/_store/states/time-filter.state';
import { StateReset } from 'ngxs-reset-plugin';
import { SetSkeleton } from 'src/app/app-shared-elements/_store/actions/table.actions';
import { UserState } from '../../app-shared-elements/_store/states/user.state';
import { User } from '../../app-shared-elements/_interfaces/user.interface';
import { LogicalEventsState } from 'src/app/events/_store/states/logical-events.state';
import { SelectOptionInterface } from 'src/app/app-shared-elements/_interfaces/select-option.interface';
import { CompletionType } from '../_enums/completion-type.enum';
import { FilterItemTypeEnum } from '../../app-shared-elements/filters/enums/filter-item-type.enum';
import { GetUsers } from '../../admin/users/_store/actions/users.actions';
import { AuthState } from '../../auth/_store/states/auth.state';
import { AdminDevicesState } from '../../admin/admin-devices/_store/states/admin-devices.state';
import { GetAllDevices } from '../../admin/admin-devices/_store/actions/admin-devices.actions';
import { CreationType } from 'src/app/app-shared-elements/_enums/registrator-sync-status.enu';
import { TranslateService } from '@ngx-translate/core';
import { EventModeEnum, LogicEventType } from '../../events/logical-events/_interface/LogicEvent';

@Component({
    selector: 'app-events-log',
    templateUrl: './events-log.component.html',
    styleUrls: ['./events-log.component.scss'],
})
export class EventsLogComponent implements OnInit, OnDestroy {
    devicesOptions: SelectOptionInterface<string, string, Device>[] = [];
    completionTypeOptions: SelectOptionInterface<CompletionType, string, CompletionType>[] = [
        {
            key: CompletionType.CHANGE_ACTIVE,
            value: 'logs.events.complationTypeOptions.changed',
            property: CompletionType.CHANGE_ACTIVE,
        },
        {
            key: CompletionType.DELETE,
            value: 'logs.events.complationTypeOptions.deleted',
            property: CompletionType.DELETE,
        },
        {
            key: CompletionType.COMPLETED,
            value: 'logs.events.complationTypeOptions.completed',
            property: CompletionType.COMPLETED,
        },
    ];

    currentOptionsForDropdown: SelectOptionInterface<any, any, any>[] = [];

    private deviceId: string;
    private currentDeviceOptionKey: string;
    currentOptionKeyForDropdown: string;
    defaultDropdownValue: any;
    isHighlightRegistrators: boolean;

    filterGroupSystemLogsType = FilterGroupSystemLogsType;
    filterTypePipeEnum = FilterTypePipeEnum;

    @Select(TechnologicEventsState.getEvents) rows$: Observable<TechnologicLogRowInterface[]>;
    @Select(TechnologicEventsState.getFilter) params$: Observable<Params>;
    @Select(TechnologicEventsState.getDropDownFilterOptions) dropDownFilterOptions$: Observable<DropdownFilterOptionInterface[]>;
    @Select(ActiveEventsState.getActiveEvents) activeEvents$: Observable<ActiveEvents[]>;
    @Select(TimeFilterState.getTimeObj) timeObj$: Observable<ParamsTime>;
    @Select(UserState.getUser) user$: Observable<User>;
    @Select(DevicesState.getDevices) device$: Observable<Device[]>;
    @Select(DevicesState.getRegistrators) registrators$: Observable<Device[]>;
    @Select(TechnologicEventsState.getUserId) userId$: Observable<string>;

    private user: User;
    isTableOptionsPopup: boolean;

    eventsTableCurrentColumnsName: string[];
    eventsTableCurrentColumns: ColumnsTableInterface[] = [];
    filterItemTypeEnum = FilterItemTypeEnum;

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private activatedRoute: ActivatedRoute,
        public eventLogsService: EventLogsService,
        private store: Store,
        private notificationsService: NotificationsService,
        private router: Router,
        private permissionService: PermissionService,
        private preloaderService: PreloaderService,
        private translateService: TranslateService,
    ) {
        this.store.dispatch(new SetSkeleton(true));

        this.user$.pipe(takeUntil(this.destroy)).subscribe((user) => {
            if (!user) {
                return;
            }

            this.user = user;
        });

        if (this.activatedRoute.snapshot.queryParams.params) {
            const params: Params = JSON.parse(decodeURIComponent(this.activatedRoute.snapshot.queryParams.params));
            const dateFilter = params.filter.find((f) => f.type === ParamsFilterTypeEnum.DATETIME);
            if (dateFilter) {
                this.store.dispatch(new SetTimeObj(dateFilter.value as ParamsTime));
                this.store.dispatch(new SetTimeType((dateFilter.value as ParamsTime).type));
            } else {
                this.store.dispatch(
                    new SetTimeObj({
                        from: null,
                        to: null,
                        time: null,
                        type: ParamsTimeTypeEnum.ALL_TIME,
                    }),
                );
                this.store.dispatch(new SetTimeType(ParamsTimeTypeEnum.ALL_TIME));
            }
        } else {
            this.store.dispatch(new SetTimeObj({ from: null, to: null, time: null, type: ParamsTimeTypeEnum.ALL_TIME }));
            this.store.dispatch(new SetTimeType(ParamsTimeTypeEnum.ALL_TIME));
        }
        this.store.dispatch(
            new SetFilterConfig({
                type: 'date',
                disableFunctions: [
                    DisabledFilterOptionsEnum.current,
                    DisabledFilterOptionsEnum.time,
                    DisabledFilterOptionsEnum.chartType,
                    DisabledFilterOptionsEnum.resetBtn,
                ],
            }),
        );
    }

    async ngOnInit(): Promise<void> {
        // this.store.dispatch(new GetDevices()).toPromise();

        await this.preloaderService.initPreloader(PreloaderWrappersEnum.tableBody);
        this.eventsTableCurrentColumnsName = JSON.parse(localStorage.getItem(`eventsLogColumns${this.user.id}`)) || [];
        this.initTableColumns();

        if (
            !this.store.selectSnapshot(AdminDevicesState.isLoadAdminDevices) &&
            this.store.selectSnapshot(AuthState.getIsAdmin) &&
            this.router.url.includes('control')
        ) {
            await this.store.dispatch(new GetAllDevices()).toPromise();
        }

        if (this.activatedRoute.snapshot.params.id) {
            this.defaultDropdownValue = this.store.selectSnapshot(TechnologicEventsState.getDropDownFilterOptions)[0].value;
            this.deviceId = this.activatedRoute.snapshot.params.id;
            const params: Params = this.store.selectSnapshot(TechnologicEventsState.getFilter);
            const newFilters = (params.filter as ParamsFilter[]).map((f: ParamsFilter) => {
                if (f.isDropdown && f.property === 'deviceId') {
                    f.value = this.activatedRoute.snapshot.params.id;
                }

                return f;
            });
            this.device$
                .pipe(
                    filter((d) => !!d && !!d.length),
                    first(),
                )
                .subscribe((d) => {
                    this.parseDeviceOptions(d);
                    [...this.currentOptionsForDropdown] = this.devicesOptions;
                });
            this.store.dispatch(new SetTechnologicFilter({ ...params, filter: newFilters }));
            this.isHighlightRegistrators = true;
        }

        this.activatedRoute.queryParamMap.pipe(takeUntil(this.destroy)).subscribe((query: ParamMap) => {
            this.store.dispatch(new SetSkeleton(true));

            let params: Params = JSON.parse(decodeURIComponent(query.get('params')));
            const alertParam = JSON.parse(decodeURIComponent(query.get('alertParam')));
            const deviceIdParam = JSON.parse(decodeURIComponent(query.get('deviceIdParam')));

            if (params) {
                const dateTimeFilter = params.filter.find((f) => f.type === ParamsFilterTypeEnum.DATETIME);
                if (dateTimeFilter) {
                    this.store.dispatch(new SetTimeType((dateTimeFilter.value as ParamsTime).type));
                    this.store.dispatch(new SetTimeObj(dateTimeFilter.value as ParamsTime));
                }
            } else {
                params = this.store.selectSnapshot(TechnologicEventsState.getFilter);
                this.store.dispatch(
                    new SetTimeObj({
                        from: null,
                        to: null,
                        time: null,
                        type: ParamsTimeTypeEnum.ALL_TIME,
                    }),
                );
                this.store.dispatch(new SetTimeType(ParamsTimeTypeEnum.ALL_TIME));
            }

            let filterArray: ParamsFilter[] = this.store.selectSnapshot(TechnologicEventsState.getFilter).filter;
            let configFilter;

            if (deviceIdParam) {
                const indexFilter = filterArray.findIndex((f) => f.isDropdown && f.property === 'deviceId');
                if (indexFilter > -1) {
                    filterArray[indexFilter].value = deviceIdParam.id;
                }
                this.deviceId = deviceIdParam.id;
                this.defaultDropdownValue = this.store.selectSnapshot(TechnologicEventsState.getDropDownFilterOptions)[0].value;
                this.device$
                    .pipe(
                        filter((d) => !!d && !!d.length),
                        first(),
                    )
                    .subscribe((d) => {
                        this.parseDeviceOptions(d);
                        [...this.currentOptionsForDropdown] = this.devicesOptions;
                    });
                this.isHighlightRegistrators = true;
            } else if (params) {
                const completionFilter = params.filter.find((f) => f.isDropdown && f.property === 'completionType' && f.value !== null);
                if (completionFilter) {
                    this.defaultDropdownValue = this.store.selectSnapshot(TechnologicEventsState.getDropDownFilterOptions)[1].value;

                    [...this.currentOptionsForDropdown] = this.completionTypeOptions;
                    // this.currentDeviceOptionKey = completionFilter.value as string;
                    this.currentOptionKeyForDropdown = completionFilter.value as string;
                    this.isHighlightRegistrators = false;
                }

                // this.isHighlightRegistrators = false;
            }

            if (alertParam) {
                switch (alertParam.type) {
                    case FilterTypeSystemLogsEnum.alarm:
                        configFilter = [
                            { name: EventsFiltersNamesEnum.alarm, value: true },
                            { name: EventsFiltersNamesEnum.attention, value: false },
                            { name: EventsFiltersNamesEnum.active, value: true },
                            // {name: EventsFiltersNamesEnum.noActive, value: true},
                            { name: EventsFiltersNamesEnum.needToAckn, value: false },
                        ];

                        filterArray = this.getCurrentFilterArray(filterArray, configFilter);

                        break;
                    case FilterTypeSystemLogsEnum.attention:
                        configFilter = [
                            { name: EventsFiltersNamesEnum.attention, value: true },
                            { name: EventsFiltersNamesEnum.alarm, value: false },
                            { name: EventsFiltersNamesEnum.active, value: true },
                            // {name: EventsFiltersNamesEnum.noActive, value: true},
                            { name: EventsFiltersNamesEnum.needToAckn, value: false },
                        ];

                        filterArray = this.getCurrentFilterArray(filterArray, configFilter);

                        break;
                    case FilterTypeSystemLogsEnum.alarmBlink:
                        configFilter = [
                            { name: EventsFiltersNamesEnum.attention, value: false },
                            { name: EventsFiltersNamesEnum.alarm, value: true },
                            { name: EventsFiltersNamesEnum.active, value: true },
                            { name: EventsFiltersNamesEnum.noActive, value: true },
                            { name: EventsFiltersNamesEnum.needToAckn, value: true },
                        ];

                        filterArray = this.getCurrentFilterArray(filterArray, configFilter);

                        break;
                    case FilterTypeSystemLogsEnum.attentionBlink:
                        configFilter = [
                            { name: EventsFiltersNamesEnum.attention, value: true },
                            { name: EventsFiltersNamesEnum.alarm, value: false },
                            { name: EventsFiltersNamesEnum.active, value: true },
                            { name: EventsFiltersNamesEnum.noActive, value: true },
                            { name: EventsFiltersNamesEnum.needToAckn, value: true },
                        ];

                        filterArray = this.getCurrentFilterArray(filterArray, configFilter);
                        break;
                    case FilterTypeSystemLogsEnum.fromDeviceOnlyActiveAlarm:
                        configFilter = [
                            { name: EventsFiltersNamesEnum.attention, value: false },
                            { name: EventsFiltersNamesEnum.alarm, value: true },
                            { name: EventsFiltersNamesEnum.active, value: true },
                            { name: EventsFiltersNamesEnum.noActive, value: false },
                            { name: EventsFiltersNamesEnum.needToAckn, value: false },
                        ];

                        filterArray = this.getCurrentFilterArray(filterArray, configFilter);
                        break;
                    case FilterTypeSystemLogsEnum.fromDeviceOnlyActiveAttention:
                        configFilter = [
                            { name: EventsFiltersNamesEnum.attention, value: true },
                            { name: EventsFiltersNamesEnum.alarm, value: false },
                            { name: EventsFiltersNamesEnum.active, value: true },
                            { name: EventsFiltersNamesEnum.noActive, value: false },
                            { name: EventsFiltersNamesEnum.needToAckn, value: false },
                        ];

                        filterArray = this.getCurrentFilterArray(filterArray, configFilter);
                        break;
                    case FilterTypeSystemLogsEnum.fromDeviceWithWaitAcknAlarm:
                        configFilter = [
                            { name: EventsFiltersNamesEnum.attention, value: false },
                            { name: EventsFiltersNamesEnum.alarm, value: true },
                            { name: EventsFiltersNamesEnum.active, value: true },
                            { name: EventsFiltersNamesEnum.noActive, value: true },
                            { name: EventsFiltersNamesEnum.needToAckn, value: true },
                        ];

                        filterArray = this.getCurrentFilterArray(filterArray, configFilter);
                        break;
                    case FilterTypeSystemLogsEnum.fromDeviceWithWaitAcknAttention:
                        configFilter = [
                            { name: EventsFiltersNamesEnum.attention, value: true },
                            { name: EventsFiltersNamesEnum.alarm, value: false },
                            { name: EventsFiltersNamesEnum.active, value: true },
                            { name: EventsFiltersNamesEnum.noActive, value: true },
                            { name: EventsFiltersNamesEnum.needToAckn, value: true },
                        ];

                        filterArray = this.getCurrentFilterArray(filterArray, configFilter);
                        break;
                }

                if (filterArray) {
                    this.store.dispatch(new SetTechnologicFilter({ ...params, filter: filterArray }));
                }
            } else {
                this.store.dispatch(new SetTechnologicFilter({ ...params }));
            }
        });

        if (this.store.selectSnapshot(AuthState.getIsAdmin) && this.router.url.includes('control')) {
            await this.parseDeviceOptions(this.store.selectSnapshot(AdminDevicesState.getAdminDevices));
        }

        this.rows$.pipe(takeUntil(this.destroy)).subscribe((rows) => {
            if (rows.length) {
                this.preloaderService.destroyPreloader();
            }
        });

        if (this.store.selectSnapshot(TechnologicEventsState.getUserId)) {
            await this.store.dispatch(new GetUsers()).toPromise();
        }

        this.device$
            .pipe(
                filter((d) => !!d && !!d.length),
                first(),
            )
            .subscribe(async (devices) => {
                this.parseDeviceOptions(devices);
            });

        this.initDynamicUpdates();
    }

    private parseDeviceOptions(devices: Device[]): void {
        if (!devices || !devices.length) {
            return;
        }

        this.devicesOptions = [];

        const registrators = this.store.selectSnapshot(DevicesState.getRegistrators).sort((a, b) => (a.name > b.name ? 1 : -1));

        registrators.forEach((r) => {
            const devicesByRegistrator: Device[] = devices
                .filter((d) => d.registratorId === r.id)
                .sort((a, b) => (a.type > b.type ? 1 : -1));

            this.devicesOptions.push({
                key: r.id,
                value: r.name || r.defaultName,
                property: r,
                postIconPath: `./assets/design/icons/custom-select/registrator${
                    r.creationType === CreationType.ORIGIN ? '' : '-virtual'
                }.svg`,
            });

            devicesByRegistrator.forEach((d) => {
                this.devicesOptions.push({
                    key: d.id,
                    value: d.name || d.defaultName,
                    property: d,
                    postIconPath: `./assets/design/icons/custom-select/${d.type}.svg`,
                });
            });
        });

        this.currentDeviceOptionKey = this.deviceId;
        this.currentOptionKeyForDropdown = this.currentDeviceOptionKey;
    }

    ngOnDestroy(): void {
        this.store.dispatch(new SetFilterConfig(null));

        this.store.dispatch(new StateReset(TechnologicEventsState));
        this.preloaderService.destroyPreloader();
        this.destroy.next(true);
        this.destroy.complete();
    }

    private async initDynamicUpdates(): Promise<void> {
        this.activeEvents$.pipe(takeUntil(this.destroy), skip(1)).subscribe(async (data) => {
            if (!data || !data.length) {
                return;
            }
            await this.initRows();
        });
    }

    private async initRows(): Promise<void> {
        this.store.dispatch(new GetTechnologicEventsRows());
        await this.preloaderService.destroyPreloader();
    }

    async onChangePage(data: Pagination): Promise<void> {
        const params: Params = this.store.selectSnapshot(TechnologicEventsState.getFilter);
        localStorage.setItem('itemsPerPageLog', JSON.stringify(data.itemsPerPage));
        this.store.dispatch(new SetTechnologicFilter({ ...params, pagination: data }));
    }

    async onChangeSort(data: ParamsSorted[]): Promise<void> {
        const params: Params = this.store.selectSnapshot(TechnologicEventsState.getFilter);
        this.store.dispatch(new SetTechnologicFilter({ ...params, sorted: [...data] }));
    }

    async clickExpression(data: { row: TechnologicLogRowInterface; cell: ColumnsTableInterface }): Promise<void> {
        if (this.store.selectSnapshot(AuthState.getIsAdmin) && this.router.url.includes('control')) {
            return;
        }
        const check = await this.checkPermission(data.row.registratorId);

        if (!check || this.store.selectSnapshot(TechnologicEventsState.getUserId)?.length) {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'devices.popup.notPermission');
            return;
        }

        await this.store.dispatch(new GetLogicalEvent(data.row.srcId)).toPromise();
        const event = this.store.selectSnapshot(LogicalEventsState.getLogicalEvent);

        if (!event && data.cell.name === 'eventCondition' && check) {
            this.notificationsService.onEmit(TooltipStatusEnum.load, false, 'logs.events.eventDelete');
            return;
        }

        if (data.cell.name === 'eventCondition') {
            // this.store.dispatch(new SetLogicEvent(event));
            // this.store.dispatch(new SetCurrentRegistrator(data.row.registratorId));
            this.store.dispatch(
                new SetEventMode(event.logicEventType === LogicEventType.default ? EventModeEnum.advance : EventModeEnum.simple),
            );
            await this.router.navigate(['logical-events'], { queryParams: { eventId: data.row.srcId } });
        } else {
            this.store.dispatch(new SetCurrentRegistrator(data.row.registratorId));
            await this.router.navigate([`group-container/chart`, data.row.deviceId]);
        }
    }

    async onChangeFilterGroupEvents(data: ParamsFilter[]): Promise<void> {
        const params: Params = this.store.selectSnapshot(TechnologicEventsState.getFilter);

        this.checkGroupFilterLogic(data)
            ? this.store.dispatch(new SetTechnologicFilter({ ...params, filter: data }))
            : this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'filters.incorrectFilters');
    }

    private checkGroupFilterLogic(data): boolean {
        const activity = data.find((item) => item.key === FilterGroupSystemLogsType.activity);
        const notActivity = data.find((item) => item.key === FilterGroupSystemLogsType.notActivity);
        const ackn = data.find((item) => item.key === FilterGroupSystemLogsType.acknowledge);
        const notAckn = data.find((item) => item.key === FilterGroupSystemLogsType.notAcknowledge);

        return !((!activity.checked && !notActivity.checked) || (!ackn.checked && !notAckn.checked));
    }

    async setAcknowledgeEvent(event: { row: TechnologicLogRowInterface }): Promise<void> {
        if (this.store.selectSnapshot(AuthState.getIsAdmin)) {
            return;
        }
        const check = await this.checkPermission(event.row.registratorId);
        if (!check && this.user.id !== event.row.ownerUserId) {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'devices.popup.notPermission');
            return;
        }
        this.store.dispatch(new SetAcknowledge(event.row));
    }

    async onChangeDropDownFilterDevices(data: ParamsFilter[]): Promise<void> {
        const params: Params = this.store.selectSnapshot(TechnologicEventsState.getFilter);
        this.store.dispatch(new SetTechnologicFilter({ ...params, filter: data }));
        this.initRows();
    }

    private async checkPermission(registratorId: string): Promise<boolean> {
        // if (!this.store.selectSnapshot(DevicesState.getIsLoadDevices)) {
        //     await this.store.dispatch(GetDevices).toPromise();
        // }
        const roles = this.store.selectSnapshot(PermissionsState.getRoles);
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getRegistrators).find((r) => r.id === registratorId);

        if (!currentRegistrator || !currentRegistrator.roleId) {
            return true;
        }
        const currentRole = roles.find((role) => role.id === currentRegistrator.roleId);

        const data = {
            registrator: currentRegistrator,
            action: ResourceAction.LOGIC_EVENT_LOG,
            permissions: [MethodPermission.READ, MethodPermission.UPDATE],
        };

        return this.permissionService.checkPermission(currentRole.permissions, data);
    }

    openTableOptions(): void {
        this.isTableOptionsPopup = true;
    }

    changeTableOptions(cell: ColumnsTableInterface): void {
        const index = this.eventsTableCurrentColumnsName.findIndex((e) => e === cell.name);

        if (index === -1) {
            this.eventsTableCurrentColumnsName.push(cell.name);
            this.translateService.get('table.deviceLogs.addColumn').subscribe((message) => {
                this.notificationsService.onEmit(TooltipStatusEnum.update, false, message);
            });
        } else {
            this.eventsTableCurrentColumnsName = this.eventsTableCurrentColumnsName.filter((f) => f !== cell.name);
            this.translateService.get('table.deviceLogs.removeColumn').subscribe((message) => {
                this.notificationsService.onEmit(TooltipStatusEnum.update, false, message);
            });
        }

        this.initTableColumns();
        localStorage.setItem(`eventsLogColumns${this.user.id}`, JSON.stringify(this.eventsTableCurrentColumnsName));
    }

    private initTableColumns(): void {
        this.eventsTableCurrentColumns = this.store.selectSnapshot(TechnologicEventsState.getUserId)?.length
            ? [
                  ...this.eventLogsService.adminEventsTableColumns,
                  ...this.eventLogsService.eventsOptionsTableColumns.filter((f) => this.eventsTableCurrentColumnsName.includes(f.name)),
              ]
            : [
                  ...this.eventLogsService.eventsTableColumns,
                  ...this.eventLogsService.eventsOptionsTableColumns.filter((f) => this.eventsTableCurrentColumnsName.includes(f.name)),
              ];
    }

    changeFilter(event: ParamsFilterForClient[]): void {
        if (!event) {
            return;
        }
        const params: Params = this.store.selectSnapshot(TechnologicEventsState.getFilter);

        this.router.navigate([], {
            queryParams: {
                params: encodeURIComponent(
                    JSON.stringify({
                        ...params,
                        filter: event, // this.paramsService.parseParamsFilterForServer(event)}))
                        pagination: {
                            ...params.pagination,
                            currentPage: 1,
                        },
                    }),
                ),
            },
        });
    }

    setFilter(timeObj: ParamsTime): void {
        if (timeObj) {
            const params: Params = {
                ...this.store.selectSnapshot(TechnologicEventsState.getFilter),
                filter: (this.store.selectSnapshot(TechnologicEventsState.getFilter).filter as ParamsFilterForClient[]).map((f) => {
                    if (f.type === ParamsFilterTypeEnum.DATETIME) {
                        (f.value as ParamsTime) = timeObj;
                    }

                    return f;
                }),
            };
            this.router.navigate([], {
                queryParams: {
                    params: encodeURIComponent(
                        JSON.stringify({
                            ...params,
                            // filter: this.paramsService.parseParamsFilterForServer(params.filter)
                        }),
                    ),
                },
            });
        }
    }

    private getCurrentFilterArray(
        filters: ParamsFilterForClient[],
        data: { name: EventsFiltersNamesEnum; value: boolean }[],
    ): ParamsFilter[] {
        return filters.map((f) => {
            const currentItemData = data.find((item) => (item.name as EventsFiltersNamesEnum) === f.name);
            if (currentItemData) {
                return { ...f, value: currentItemData.value };
            }

            return f;
        });
    }

    chooseFilterEvent(event: { key: any; value: string; property: any }): void {
        const params: Params = this.store.selectSnapshot(TechnologicEventsState.getFilter);
        // this.store.dispatch(new SetSkeleton(true));
        this.currentOptionKeyForDropdown = null;
        this.router.navigate([], {
            queryParams: {
                params: encodeURIComponent(
                    JSON.stringify({
                        ...params,
                        filter: params.filter.map((f) => {
                            if (f.isDropdown) {
                                f.value = null;
                            }
                            return f;
                        }),
                        // filter: this.paramsService.parseParamsFilterForServer(params.filter)
                    }),
                ),
            },
        });
        switch (event.key) {
            case 'completionType':
                [...this.currentOptionsForDropdown] = this.completionTypeOptions;
                this.isHighlightRegistrators = false;
                break;
            case 'deviceId':
                [...this.currentOptionsForDropdown] = this.devicesOptions;
                this.isHighlightRegistrators = true;
                break;
        }
    }
}

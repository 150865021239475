import { ParamsFilterForClient, ParamsFilterTypeEnum } from '../../../app-shared-elements/_interfaces/params.interface';

export const devicesControlDropdown: ParamsFilterForClient[] = [
    {
        property: 'serialNumber',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true,
    },
    {
        property: 'status',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true,
    },
    {
        property: 'createdUserLogin',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true,
    },
];

import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken, Store} from '@ngxs/store';
import {Params} from 'src/app/app-shared-elements/_interfaces/params.interface';
import {CloneDeepService} from 'src/app/app-shared-elements/_services/clone-deep.service';
import {initialFilterCheckboxesData} from 'src/app/device-dashboard/_data/user-device-params';
import {UpdateUserDevicesArray} from 'src/app/device-dashboard/_store/actions/user-devices.actions';
import {DevicesState} from 'src/app/device-dashboard/_store/states/user-devices.state';
import {ChangeAdminProfileDeviceParams} from '../actions/admin-profile-device-params.action';

export interface AdminProfileDeviceParamsModel {
    params: Params;
}

const ADMIN_PROFILE_DEVICE_STATE_PARAMS_TOKEN = new StateToken<AdminProfileDeviceParamsModel>('adminProfileDeviceParams');

const initialParams: Params = {
    pagination: null,
    sorted: null,
    filter: initialFilterCheckboxesData,
};


@State<AdminProfileDeviceParamsModel>({
    name: ADMIN_PROFILE_DEVICE_STATE_PARAMS_TOKEN,
    defaults: {
        params: initialParams,
    }
})
@Injectable()
export class AdminProfileDeviceParamsState {
    constructor(
        private store: Store,
        private cloneDeepService: CloneDeepService) {
    }

    @Selector()
    static getParams(state: AdminProfileDeviceParamsModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }


    @Action(ChangeAdminProfileDeviceParams)
    setDevicesParams(ctx: StateContext<AdminProfileDeviceParamsModel>, payload: ChangeAdminProfileDeviceParams): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            params: this.cloneDeepService.cloneObject(payload.params)
        });

        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        this.store.dispatch(new UpdateUserDevicesArray(devices));
    }
}

import { TypeClient } from 'src/app/admin/users/_enum/type-client.enum';
import { NavigationItemInterface } from 'src/app/app-template/_interfaces/navigation-item.interface';
import { PermissionInterface } from '../../_interfaces/role.interface';

export class ParseNavigationForStoreTask {
    static readonly type = '[Nav] ParseNavigationForStoreTask';
}

export class SetCurrentNavigationUserList {
    static readonly type = '[Nav] SetCurrentNavigationUserList';

    constructor() {}
}

export class SetCurrentNavigationAdminList {
    static readonly type = '[Nav] SetCurrentNavigationAdminList';

    constructor(public permissions: PermissionInterface[]) {}
}

export class ChangeUserNavigation {
    static readonly type = '[Nav] ChangeUserNavigation';

    constructor(public navigation: NavigationItemInterface[], public typeClient: TypeClient) {}
}

export class ParseNavigationForTransportTask {
    static readonly type = '[Nav] ParseNavigationForTransportTask';
}

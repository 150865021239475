import { TypeClient } from './../../users/_enum/type-client.enum';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../../users/_services/users.service';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { SessionInterface } from 'src/app/profile/_interfaces/Session';
import { first } from 'rxjs/operators';
import { GetAdminSession, GetUserSession } from '../_store/actions/admin-profile.action';
import { TableTypeEnum } from 'src/app/app-shared-elements/_enums/table-type.enum';
import { UserState } from 'src/app/app-shared-elements/_store/states/user.state';
import { LanguageState } from 'src/app/app-shared-elements/_store/states/language.state';
import { DataTypeService } from 'src/app/app-shared-elements/_services/data-type.service';
import { LangEnum } from 'src/app/app-shared-elements/_enums/lang.enum';
import { ProfileState } from 'src/app/profile/_store/states/profile.state';
import { UserProfileInfo } from 'src/app/app-shared-elements/_interfaces/UserInfo';
import { SessionState } from 'src/app/profile/_store/states/sessions.state';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { EventFromTableInterface } from 'src/app/app-shared-elements/_interfaces/event-from-table.interface';
import { SetSkeleton } from '../../../app-shared-elements/_store/actions/table.actions';
import { SetUserProfileInfo } from 'src/app/profile/_store/actions/profile.actions';
import { ConfigurationState } from 'src/app/app-shared-elements/_store/states/configuration.state';
import { ConfigurationServerInterface } from 'src/app/app-shared-elements/_interfaces/configuration-server.interface';
import { UserNotificationTransport } from 'src/app/profile/_interfaces/user-notification-transport.interface';
import { ChangeUserUpdateValue } from 'src/app/app-shared-elements/_store/actions/user.actions';

// import { TypeClient } from '../../users/_enum/type-client.enum';

@Component({
    selector: 'app-admin-profile-info',
    templateUrl: './admin-profile-info.component.html',
    styleUrls: ['./admin-profile-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminProfileInfoComponent implements OnInit {
    typeClient = TypeClient;

    @Select(SessionState.getUserSessionsRows) sessionsRows$: Observable<SessionInterface[]>;
    @Select(UserState.getUser) user$: Observable<User>;
    @Select(UserState.getUserTimezoneGMT) gmt$: Observable<string>;
    @Select(LanguageState.getLanguage) ln$: Observable<LangEnum>;
    @Select(ProfileState.getUserProfileInfo) userInfo$: Observable<UserProfileInfo[]>;
    @Select(ProfileState.getUserProfileInfoSystem) userInfoSystem$: Observable<UserProfileInfo[]>;
    @Select(ProfileState.getUserProfileInfoMailing) userInfoMailing$: Observable<UserNotificationTransport[]>;
    @Select(ProfileState.getProfileUser) profileUser$: Observable<User>;
    @Select(ConfigurationState.getConfigurationServer) configuration$: Observable<ConfigurationServerInterface>;

    tableTypeEnum = TableTypeEnum;

    constructor(
        public userService: UsersService,
        private store: Store,
        public dataTypeService: DataTypeService,
        private route: ActivatedRoute,
    ) {
        this.userInfo$.subscribe((userInfo) => console.log(userInfo));
    }

    ngOnInit(): void {
        this.profileUser$.pipe(first((currentUser) => !!currentUser && !!currentUser.id)).subscribe((currentUser) => {
            // console.log(currentUser);
            if (this.route.snapshot.queryParamMap.get('id')) {
                this.store.dispatch(new GetUserSession(currentUser.id));
            } else {
                this.store.dispatch(new GetAdminSession());
            }
            this.store.dispatch(new SetSkeleton(false));
        });

        combineLatest([this.profileUser$, this.configuration$])
            .pipe(first(([profileUser, configuration]) => !!profileUser && !!configuration))
            .subscribe(([profileUser, configuration]) => {
                this.store.dispatch(new SetUserProfileInfo(profileUser));
                const notification = this.store.selectSnapshot(ProfileState.getUserProfileInfoMailing);

                this.store.dispatch(
                    new ChangeUserUpdateValue({
                        name: profileUser.name,
                        phone: profileUser.phone,
                        timeBlocked: profileUser.timeBlocked,
                        sessionLife: profileUser.sessionLife,
                        dateFormat: profileUser.dateFormat,
                        dateTimeZone: profileUser.dateTimeZone,
                        isAlarmSound: profileUser.isAlarmSound,
                        lang: profileUser.lang,
                        notification,
                    }),
                );
            });
    }

    deleteSessions(event: SessionInterface): void {}

    eventFromTable(data: EventFromTableInterface): void {
        switch (data.type) {
            case ColumnsActionTypeEnum.actionBtnsDelete:
                this.deleteSessions(data.row);
                return;
        }
    }
}

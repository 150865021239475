import { BillingInvoiceInterface, BillingInvoiceRowInterface } from '../../_interfaces/billing-invoice.interface';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    AddNewAdditional,
    ClearCurrentInvoice,
    ClearPdf,
    CloseEditingAdditional,
    CreateAdditional,
    DeleteAdditional,
    DownloadInvoice,
    GetBillingInvoice,
    GetCurrentInvoice,
    GetCurrentInvoiceFromServer,
    GetCurrentViewInvoice,
    InitBillingInvoiceRows,
    SetBillingInvoiceParams,
    SetEditingAdditional,
    SetGenerationServerInvoice,
    ToggleInvoicePopup,
    UpdateAdditional,
} from '../actions/billing-invoice.actions';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pagination, Params, ParamsSorted, ParamsSortedOrderEnum } from '../../../app-shared-elements/_interfaces/params.interface';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { BillingInvoiceService } from '../../_services/billing-invoice.service';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { UserState } from '../../../app-shared-elements/_store/states/user.state';
import { ServicesInvoiceEnum, ServicesInvoiceInterface } from '../../_interfaces/billing-service-invoice.interface';
import { DataTypeService } from '../../../app-shared-elements/_services/data-type.service';
import { AuthState } from '../../../auth/_store/states/auth.state';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { CurrencyState } from '../../../app-shared-elements/_store/states/currency.state';
import { ColumnsActionTypeEnum } from '../../../app-shared-elements/_enums/columns-action-type.enum';

export interface BillingInvoiceStateModel {
    invoice: BillingInvoiceInterface[];
    invoiceRows: BillingInvoiceRowInterface[];
    currentInvoice: BillingInvoiceInterface;
    currentViewInvoice: BillingInvoiceInterface;
    params: Params;
    isOpenInvoice: boolean;
    pdf: string;
    isEditingMode: boolean;
}

const BILLING_INVOICE_STATE = new StateToken<BillingInvoiceStateModel>('billingInvoice');

const initialPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

const initialCurrentSort: ParamsSorted[] = [
    {
        property: 'dateInvoice',
        order: ParamsSortedOrderEnum.DESC,
    },
];

export const initialParams: Params = {
    filter: [],
    sorted: initialCurrentSort,
    pagination: initialPagination,
};

@State<BillingInvoiceStateModel>({
    name: BILLING_INVOICE_STATE,
    defaults: {
        invoice: [],
        invoiceRows: [],
        params: initialParams,
        currentInvoice: null,
        currentViewInvoice: null,
        pdf: null,
        isOpenInvoice: false,
        isEditingMode: false,
    },
})
@Injectable()
export class BillingInvoiceState {
    constructor(
        private http: HttpClient,
        private notificationService: NotificationsService,
        private billingInvoiceService: BillingInvoiceService,
        private store: Store,
        private dataTypeService: DataTypeService,
    ) {}

    @Selector()
    static getBillingInvoice(state: BillingInvoiceStateModel): BillingInvoiceInterface[] {
        return state.invoice;
    }

    @Selector()
    static getBillingInvoiceRows(state: BillingInvoiceStateModel): BillingInvoiceRowInterface[] {
        return state.invoiceRows;
    }

    @Selector()
    static getParams(state: BillingInvoiceStateModel): Params {
        return state.params;
    }

    @Selector()
    static getCurrentInvoice(state: BillingInvoiceStateModel): BillingInvoiceInterface {
        return state.currentInvoice;
    }

    @Selector()
    static getCurrentViewInvoice(state: BillingInvoiceStateModel): BillingInvoiceInterface {
        return state.currentViewInvoice;
    }

    @Selector()
    static getInvoicePdf(state: BillingInvoiceStateModel): string {
        return state.pdf;
    }

    @Selector()
    static getIsOpenInvoice(state: BillingInvoiceStateModel): boolean {
        return state.isOpenInvoice;
    }

    @Selector()
    static getIsEditingMode(state: BillingInvoiceStateModel): boolean {
        return state.isEditingMode;
    }

    @Action(GetBillingInvoice)
    async getBillingInvoice(ctx: StateContext<BillingInvoiceStateModel>): Promise<void> {
        const state = ctx.getState();

        const params: Params = {
            ...state.params,
            pagination: {
                itemsPerPage: state.params.pagination.itemsPerPage,
                currentPage: state.params.pagination.currentPage,
            },
            sorted: state.params.sorted && state.params.sorted.length ? state.params.sorted : initialCurrentSort,
        };

        const currentUserId = this.store.selectSnapshot(UserState.getAdminUserId);

        let headers;

        if (currentUserId) {
            headers = new HttpHeaders({
                params: encodeURIComponent(JSON.stringify(params)),
            }).set('currentUserId', currentUserId);
        } else {
            headers = new HttpHeaders({
                params: encodeURIComponent(JSON.stringify(params)),
            });
        }

        const result: ApiResponse = (await this.http
            .get('/api/billing/invoice', { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                invoice: result.data.items as BillingInvoiceInterface[],
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.total,
                    },
                },
            });
        }
    }

    @Action(InitBillingInvoiceRows)
    async initBillingInvoiceRows(ctx: StateContext<BillingInvoiceStateModel>): Promise<void> {
        await ctx.dispatch(new GetBillingInvoice()).toPromise();
        const state = ctx.getState();

        const currency = this.store.selectSnapshot(CurrencyState.getCurrency);

        const invoiceRows: BillingInvoiceRowInterface[] = [];

        (state.invoice as BillingInvoiceInterface[]).map(async (item) => {
            invoiceRows.push({
                ...item,
                totalPayable: item.totalPayable + ' ' + currency,
                date: item.dateInvoice,
                dateInvoice: `${await this.dataTypeService.getCurrentMonthName(+item.dateInvoice.split('-')[0])}  ${
                    item.dateInvoice.split('-')[1]
                }`,
                invoiceDateNumber: item.invoiceNumber + ' - ' + item.dateInvoice,
                status: this.billingInvoiceService.getInvoiceStatus(item),
                rowStyles: this.billingInvoiceService.getInvoiceRowStyle(item),
                styles: {
                    status: this.billingInvoiceService.getInvoiceStatusColor(item),
                },
                disabledActions: +item.totalPayable === 0 ? [ColumnsActionTypeEnum.view, ColumnsActionTypeEnum.download] : [],
            });
        });

        ctx.setState({
            ...state,
            invoiceRows,
        });
    }

    @Action(SetBillingInvoiceParams)
    async setBillingTransactionsFilter(ctx: StateContext<BillingInvoiceStateModel>, payload: SetBillingInvoiceParams): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: { ...payload.params },
        });

        await ctx.dispatch(new InitBillingInvoiceRows()).toPromise();
    }

    @Action(GetCurrentInvoice)
    async getCurrentInvoice(ctx: StateContext<BillingInvoiceStateModel>): Promise<void> {
        const currentUserId = this.store.selectSnapshot(UserState.getAdminUserId);

        let result: ApiResponse;

        if (currentUserId) {
            const headers = new HttpHeaders().set('currentUserId', currentUserId);
            result = (await this.http
                .get('/api/billing/current', { headers })
                .toPromise()
                .catch((e) => console.log(e))) as ApiResponse;
        } else {
            result = (await this.http
                .get('/api/billing/current')
                .toPromise()
                .catch((e) => console.log(e))) as ApiResponse;
        }
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                currentInvoice: {
                    ...(result.data as BillingInvoiceInterface),
                    services: result.data.services.map((row) => ({
                        ...(row as ServicesInvoiceInterface),
                        isEditing: false,
                    })),
                },
            });
        }
    }

    @Action(GetCurrentInvoiceFromServer)
    async getCurrentInvoiceFromServer(ctx: StateContext<BillingInvoiceStateModel>, payload: GetCurrentInvoiceFromServer): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/billing-server/invoice', {
                params: {
                    token: payload.token,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                currentViewInvoice: result.data,
            });
        }
    }

    @Action(GetCurrentViewInvoice)
    getCurrentViewInvoice(ctx: StateContext<BillingInvoiceStateModel>, payload: GetCurrentViewInvoice): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentViewInvoice: payload.invoice,
        });
    }

    @Action(ClearCurrentInvoice)
    clear(ctx: StateContext<BillingInvoiceStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentViewInvoice: null,
            pdf: null,
            isOpenInvoice: false,
        });
    }

    @Action(SetGenerationServerInvoice)
    async setGenerationServerInvoice(ctx: StateContext<BillingInvoiceStateModel>, payload: SetGenerationServerInvoice): Promise<void> {
        const state = ctx.getState();

        const isAdmin = this.store.selectSnapshot(AuthState.getIsAdmin);

        const result: ApiResponse = (await this.http
            .get(`/api/${isAdmin ? 'control/' : ''}billing/download`, {
                params: {
                    id: payload.invoiceId,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                pdf: result.data,
            });
        }
    }

    @Action(DownloadInvoice)
    async downloadInvoice(ctx: StateContext<BillingInvoiceStateModel>, payload: DownloadInvoice): Promise<void> {
        const state = ctx.getState();

        const linkSource = `data:application/pdf;base64,${state.pdf}`;
        const downloadLink = document.createElement('a');
        const fileName = payload.row.invoiceNumber + 'vid' + payload.row.date + '.pdf';

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        ctx.setState({
            ...state,
        });
    }

    @Action(ToggleInvoicePopup)
    toggleInvoicePopup(ctx: StateContext<BillingInvoiceStateModel>, payload: ToggleInvoicePopup): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isOpenInvoice: payload.isPopup,
        });
    }

    @Action(AddNewAdditional)
    addNewAdditional(ctx: StateContext<BillingInvoiceStateModel>): void {
        const state = ctx.getState();

        const data: ServicesInvoiceInterface = {
            serviceDescription: '',
            paymentAmount: null,
            countServices: null,
            isEditing: true,
            typeService: ServicesInvoiceEnum.ADDITIONAL,
            isNew: true,
        };

        ctx.setState({
            ...state,
            currentInvoice: { ...state.currentInvoice, services: [data, ...state.currentInvoice.services] },
            isEditingMode: true,
        });
    }

    @Action(CreateAdditional)
    async createAdditional(ctx: StateContext<BillingInvoiceStateModel>, payload: CreateAdditional): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/control/billing/service', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                currentInvoice: {
                    ...state.currentInvoice,
                    services: [{ ...result.data, isEditing: false }, ...state.currentInvoice.services],
                },
                isEditingMode: false,
            });

            ctx.dispatch(new GetCurrentInvoice());
        }
    }

    @Action(UpdateAdditional)
    async updateAdditional(ctx: StateContext<BillingInvoiceStateModel>, payload: UpdateAdditional): Promise<void> {
        const result: ApiResponse = (await this.http
            .put('/api/control/billing/service', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                currentInvoice: {
                    ...state.currentInvoice,
                    services: [
                        {
                            ...result.data,
                            isEditing: false,
                        },
                        ...state.currentInvoice.services.filter((row) => row.id !== result.data.id),
                    ],
                },
                isEditingMode: false,
            });
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(DeleteAdditional)
    async deleteAdditional(ctx: StateContext<BillingInvoiceStateModel>, payload: DeleteAdditional): Promise<void> {
        const result: ApiResponse = (await this.http
            .delete('/api/control/billing/service', {
                params: {
                    id: payload.id,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                currentInvoice: {
                    ...state.currentInvoice,
                    services: state.currentInvoice.services.filter((f) => f.id !== payload.id),
                },
                isEditingMode: false,
            });
        }
    }

    @Action(SetEditingAdditional)
    setEditingAdditional(ctx: StateContext<BillingInvoiceStateModel>, payload: SetEditingAdditional): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentInvoice: {
                ...state.currentInvoice,
                services: state.currentInvoice.services.map((row) => {
                    return {
                        ...row,
                        isEditing: row.id === payload.id,
                    };
                }),
            },
            isEditingMode: true,
        });
    }

    @Action(CloseEditingAdditional)
    closeEditingAdditional(ctx: StateContext<BillingInvoiceStateModel>, payload: CloseEditingAdditional): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentInvoice: {
                ...state.currentInvoice,
                services: state.currentInvoice.services.map((row) => ({
                    ...(payload.savedRow && payload.savedRow.id === row.id ? payload.savedRow : row),
                    isEditing: false,
                })),
            },
            isEditingMode: false,
        });

        ctx.dispatch(new GetCurrentInvoice());
    }

    @Action(ClearPdf)
    clearPdf(ctx: StateContext<BillingInvoiceStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            pdf: null,
        });
    }
}

<div class="page"
     [class.page--full-height]="isPagination && configPagination && configPagination.totalItems > 5 && configPagination.totalItems < 20 && rows && rows.length
     || isPagination && configPagination && rows && rows.length < 20"
>

    <div *ngIf="isGroupOperations" class="table__operations">
        <app-group-operation></app-group-operation>
    </div>

    <div class="wrapper" [class.wrapper--unhidden]="unHidden">
        <div id="table-print" class="wrapper__scroll">
            <div class="table"
                 [style.maxWidth]="maxWidth || ''"
                 [style.minWidth.px]="minWidth || ''"
                 [style.maxWidth]="maxContent ? 'max-content' : ''"
                 [style.width]="maxContent ? 'max-content' : ''"
                 [class.table--full-width]="fullWidthTable"
                 [class.table--light]="type === tableTypeEnum.light"
                 [class.table--not-full]="type === tableTypeEnum.notFull">

                <div class="table__tr table__tr--head"
                     [class.table__sticky]="isStickyHeader"
                >
                    <div class="table__tr-wrapper">
                        <div class="table__th" *ngFor="let td of currentTableColumns; let i = index;"
                             [style.maxWidth]="td.maxWidth" [style.minWidth]="td.minWidth"
                             [class.table__th--grow]="td.grow"
                             [class.table__th--small]="td.small"
                             [class.table__th--right]="td.align === columnValueAlignEnum.right"
                             [class.table__th--left]="td.align === columnValueAlignEnum.left"
                             [class.table__td--sticky]="td.sticky"
                             [class.table__th--center]="td.align === columnValueAlignEnum.center">

                            <div *ngIf="td?.thIconPath" class="table__th-icon">
                                <div class="table__th-icon-item">
                                    <svg-icon [src]="td.thIconPath"></svg-icon>
                                </div>
                            </div>


                            <span *ngIf="td.title && td.title.length; else groupOperation" class="table__th-value"
                                  [class.table__th-value--checkbox]="td.mode === 'checkbox'">
                                {{td.title | translate}}
                            </span>
                            <ng-template #groupOperation>
                                <app-column-group-operation *ngIf="td.type === columnTypeEnum.groupOperation"
                                                            [isMain]="true"
                                                            [cell]="td"
                                                            (chooseEvent)="chooseFromGroupOperation($event)"></app-column-group-operation>
                            </ng-template>


                            <div *ngIf="td?.icon === columnIconType.remove"
                                 (click)="removeColumn(td)"
                                 class="table__th-remove">
                                <svg-icon [src]="REMOVE_COLUMN_ICON_PATH"></svg-icon>

                            </div>
                            <div class="table__th-arrow" *ngIf="td.sort !== undefined"
                                 [class.table__th-arrow--up]="td.sortField && currentSort[td.sortField] && currentSort[td.sortField].property && currentSort[td.sortField].order === paramsSortedOrderEnum.ASC"
                                 [class.table__th-arrow--down]="td.sortField && currentSort[td.sortField] && currentSort[td.sortField].property && currentSort[td.sortField].order === paramsSortedOrderEnum.DESC"
                                 (click)="sortRows(td.sortField)">
                                <svg-icon [src]="ARROW_COLUMN_ICON_PATH"></svg-icon>
                            </div>

                            <ng-container *ngIf="td.tooltip && td.icon === columnIconType.tooltip">
                                <div class="table__th-icon">
                                    <svg-icon [src]="QUESTION_ICON_PATH"></svg-icon>
                                    <div class="table__th-tooltip">
                                        <ng-container *ngIf="td.customTooltipType; else tooltip">

                                            <app-tooltip
                                                [side]="td.tooltipSide || tooltipSideEnum.left"
                                                [template]="true"
                                                [maxWidth]="td.tooltipMaxWidth"
                                            >

                                                <app-custom-tooltip-message></app-custom-tooltip-message>

                                            </app-tooltip>

                                        </ng-container>

                                        <ng-template #tooltip>

                                            <app-tooltip [side]="td.tooltipSide || tooltipSideEnum.left"
                                                         [template]="true">
                                                {{td.tooltipValue | translate}}
                                            </app-tooltip>

                                        </ng-template>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="(isSkeleton$ | async); else tableContent">
                    <ngx-skeleton-loader
                        [theme]="{ width: '100%', height: '36px', margin: '0 0 -2px 0', padding: 0, backgroundColor: 'var(--backgroundSkeleton)'}"
                        count="12"></ngx-skeleton-loader>
                </ng-container>
                <ng-template #tableContent>

                    <div class="table__body">

                        <div *ngIf="!rows.length && currentTableColumns.length" class="table__body-empty"
                             [translate]="'table.noData'"></div>

                        <div
                            *ngFor="let row of (isPagination ? (rows | paginate: configPagination) : (rows)); let i = index; trackBy: trackByRow;"
                            class="table__tr table__tr--body"
                            [class.table__tr--disabled]="row?.isActive === false || (row?.savedStatus && row?.savedStatus === eventSaveStatus.PROCESS)"
                            [class.table__tr--online]="row?.isOnline || row?.isOnline || row?.id === row?.userId && router.url.includes('control/admins')"
                            [class.table__tr--cleared]="row?.isCleared"
                            [class.table__tr--registrator-error]="row?.savedStatus === statusSaved.ERROR"
                            [class.table__tr--unActive]="row?.isActive === false"
                            [class.table__tr--acordion-child]="row?.isChildAcordion"
                            [class.table__tr--acordion-parrent]="row?.isMainAcordion"
                            [class.table__tr--acordion-parrent-open]="row?.isMainAcordion && row?.toggle"
                            [class.table__tr--acordion-last-child]="row?.lastChildAccordion"
                            [class.table__tr--acordion-hide]="row?.accordionChildHide"
                            [class.table__tr--attention]="row?.typeHover === alarmTypeEnum.attention"
                            [class.table__tr--is-disabled]="isDisabled"
                            [class.table__tr--alarm]="row?.typeHover === alarmTypeEnum.alarm"
                        >


                            <div class="table__tr-wrapper">
                                <div class="table__td" *ngFor="let cell of currentTableColumns"
                                     [style.maxWidth]="cell.maxWidth"
                                     [style.minWidth]="cell.minWidth" [class.table__td--grow]="cell.grow"
                                     [class.table__td--sticky]="cell.sticky"
                                     [ngStyle]="row?.rowStyles"
                                     [class.table__td--empty]="row.isChildAcordion && row.emptyCellNames.includes(cell.name)"
                                     [class.table__td--small]="cell.small">

                                    <app-column-group-operation *ngIf="cell.type === columnTypeEnum.groupOperation"
                                                                [cell]="cell"
                                                                [value]="row[cell.name]"
                                                                [row]="row"
                                                                (chooseEvent)="chooseFromGroupOperation($event)"></app-column-group-operation>

                                    <app-columns-date *ngIf="cell.type === columnTypeEnum.date || (row?.columnType === columnTypeEnum.date && cell.isCustomType)"
                                                      [value]="row[cell.name]"
                                                      [cell]="cell"
                                                      [row]="row">

                                    </app-columns-date>


                                    <app-columns-text
                                        *ngIf="cell.type === columnTypeEnum.text || (row?.columnType === columnTypeEnum.text && cell.isCustomType)"
                                        [value]="row[cell.name]"
                                        [cell]="cell"
                                        [row]="row"
                                        (moveToSmthEvent)="openSmthEvent(row, cell)"
                                        (editSmthEvent)="onEditSmth(row, $event)"
                                        (iconEvent)="iconEvent($event)">
                                    </app-columns-text>

                                    <app-columns-edit *ngIf="cell.type === columnTypeEnum.edit"
                                                      [row]="row"
                                                      [cell]="cell"
                                                      (editEvent)="onDeviceKeySmth(row)"
                                                      (smthEvent)="onSaveSmth(row, $event)"></app-columns-edit>

                                    <app-columns-button *ngIf="cell.type === columnTypeEnum.button || (row?.columnType === columnTypeEnum.button && cell.isCustomType)"
                                                        [value]="row[cell.name]"
                                                        [cell]="cell"
                                                        (clickBtnEvent)="emitClickFromColumnBtn(row, $event, i)">
                                    </app-columns-button>

                                    <app-columns-acknowledge *ngIf="cell.type === columnTypeEnum.acknowledge"
                                                             [value]="row[cell.name]"
                                                             [cell]="cell"
                                                             (clickBtnEvent)="emitClickFromColumnBtn(row, $event, i)">
                                    </app-columns-acknowledge>

                                    <app-columns-ts-clear *ngIf="cell.type === columnTypeEnum.tsClear"
                                                          [value]="row[cell.name]"
                                                          [cell]="cell">
                                    </app-columns-ts-clear>

                                    <app-column-transport *ngIf="cell.type === columnTypeEnum.transport"
                                                          [value]="row[cell.name]"
                                                          [cell]="cell">
                                    </app-column-transport>

                                    <app-column-mailing-editor *ngIf="cell.type === columnTypeEnum.mailingEditor || (row?.columnType === columnTypeEnum.mailingEditor && cell.isCustomType)"
                                                               [value]="row[cell.name]"
                                                               [transportValues]="row?.transports"
                                                               [cell]="cell"
                                                               [row]="row"
                                                               [disableType]="row?.disabledType"
                                                               (moveToSmthEvent)="openSmthEvent(row, cell)"
                                                               (iconEvent)="iconEvent($event)"
                                                               (saveEvent)="onSaveSmth(row, $event)"
                                                               (deleteEvent)="onDeleteSmth(row)"
                                                               (actionBtnEvent)="typeEvent($event, row, cell)"
                                                               (goToEditEvent)="onGoToEditSmth(row)">
                                    </app-column-mailing-editor>

                                    <app-columns-icon *ngIf="cell.type === columnTypeEnum.icon" [value]="row[cell.name]"
                                                      [cell]="cell"
                                                      [row]="row"
                                                      (changeActivityEvent)="emitChangeActivity(row, $event, i)"></app-columns-icon>

                                    <app-column-input *ngIf="(cell.type === columnTypeEnum.input && cell.name !== 'isChoose')
                                        || (row?.columnType === columnTypeEnum.input && cell.isCustomType)"
                                                      [value]="row[cell.name]"
                                                      [row]="row"
                                                      [type]="cell.mode"
                                                      [cell]="cell"
                                                      (editCheckboxEvent)="changeFromCheckbox(row, cell, $event)"
                                                      (editEvent)="changeFromInput(row, cell, $event)"></app-column-input>

                                    <app-column-select
                                        *ngIf="cell.type === columnTypeEnum.select || (row?.columnType === columnTypeEnum.select && cell.isCustomType)"
                                        [options]="row[cell.optionsName]"
                                        [value]="row[cell.currentValueForSelect]"
                                        [row]="row"
                                        [cell]="cell"
                                        (dataChanged)="optionsChanged($event, row, cell)"></app-column-select>


                                    <app-columns-editor
                                        *ngIf="cell.type === columnTypeEnum.editor || (row?.columnType === columnTypeEnum.editor && cell.isCustomType)"
                                        [row]="row"
                                        [value]="row[cell.name]"
                                        [cell]="cell"
                                        (editProcessSmthEvent)="onEditProcessEvent()"
                                        (saveSmthEvent)="onEditSmth(row, $event)"
                                        (deleteSmthEvent)="onDeleteSmth(row)">
                                    </app-columns-editor>

                                    <app-columns-action *ngIf="cell.type === columnTypeEnum.action"
                                                        [row]="row"
                                                        [value]="row[cell.name]"
                                                        [cell]="cell"
                                                        (typeEvent)="typeEvent($event, row, cell)">
                                    </app-columns-action>

                                    <app-columns-date-picker
                                        *ngIf="cell.type === columnTypeEnum.datePicker || (row?.columnType === columnTypeEnum.datePicker && cell.isCustomType)"
                                        (editSmthEvent)="onEditSmth(row, $event)"
                                    ></app-columns-date-picker>
                                </div>
                            </div>

                            <ng-container
                                *ngIf="row?.isMainAcordion && row?.accordionChildRows && row?.accordionChildRows.length">
                                <div *ngFor="let subRow of row.accordionChildRows; trackBy: trackBySubRow;"
                                     class="table__tr table__tr--sub"
                                     [class.table__tr--acordion-child]="subRow?.isChildAcordion"
                                     [class.table__tr--acordion-hide]="subRow?.accordionChildHide"
                                     [class.table__tr--acordion-last-child]="subRow?.lastChildAccordion"
                                     [class.table__tr--disabled]="subRow?.isActive === false"
                                >
                                    <div *ngFor="let cell of currentTableColumns" class="table__td"
                                         [class.table__td--empty]="subRow.isChildAcordion && subRow.emptyCellNames.includes(cell.name)"
                                         [style.maxWidth]="cell.maxWidth"
                                         [style.minWidth]="cell.minWidth" [class.table__td--grow]="cell.grow"
                                         [class.table__td--small]="cell.small">
                                        <app-columns-text *ngIf="cell.type === columnTypeEnum.text"
                                                          [value]="subRow[cell.name]"
                                                          [cell]="cell"
                                                          [row]="subRow"
                                                          (iconEvent)="iconEvent($event)">

                                        </app-columns-text>

                                        <app-columns-icon *ngIf="cell.type === columnTypeEnum.icon"
                                                          [value]="subRow[cell.name]"
                                                          [cell]="cell"
                                                          [row]="subRow"></app-columns-icon>

                                        <app-columns-action *ngIf="cell.type === columnTypeEnum.action"
                                                            [row]="subRow"
                                                            [value]="subRow[cell.name]"
                                                            [cell]="cell"></app-columns-action>
                                    </div>
                                </div>
                            </ng-container>

                        </div>

                    </div>
                </ng-template>

            </div>

        </div>

    </div>

    <ng-container *ngIf="isPagination && configPagination && configPagination.totalItems > 5 && rows && rows.length">

        <div class="pagination">
            <div class="pagination__wrapper">
                <div class="pagination__count-block">
                    <div class="pagination__select">

                        <app-custom-select
                            class="period"
                            [isReset]="false"
                            [isRepeat]="false"
                            [options]="tableService.counts"
                            [value]="customCountOption"
                            [type]="selectTypeEnum.pagination"
                            [smartWrapperPosition]="true"
                            (emmitNewValue)="checkCurrentPage($event)"></app-custom-select>
                    </div>
                    <div class="pagination__info">
                        <span>
                            {{configPagination.currentPage === 1
                            ? configPagination.currentPage
                            : ((configPagination.currentPage - 1) * configPagination.itemsPerPage) + 1}} -
                            {{configPagination.currentPage === 1
                            ? configPagination.totalItems <= configPagination.itemsPerPage
                                ? configPagination.totalItems : configPagination.itemsPerPage
                            : ((configPagination.currentPage + 1) * configPagination.itemsPerPage) - configPagination.itemsPerPage}}
                        </span>
                        <span [translate]="'pagination.of'"></span>
                        <span>
                            {{configPagination.totalItems}}
                        </span>
                    </div>
                </div>

                <div class="pagination__page-block">
                    <pagination-controls
                        *ngIf="configPagination && (configPagination.totalItems > configPagination.itemsPerPage)"
                        class="pagination__list" (pageChange)="pageChanged($event)"
                        maxSize="{{currentWidth < 648 ? '0' : '7'}}" responsive="true">
                    </pagination-controls>
                </div>
            </div>
        </div>

    </ng-container>
</div>

import { TreeRoleItemInterface } from '../../_interfaces/tree-role-item.interface';

export class SetIsAdminRoles {
    static readonly type = '[Roles] SetIsAdminRoles';

    constructor(public isAdmin: boolean) {}
}

export class GetTreeFromServer {
    static readonly type = '[Roles] GetTreeFromServer';
    // constructor(public isAdmin: boolean) {

    // }
}

export class GetRoles {
    static readonly type = '[Roles] GetRoles';
    // constructor(public isAdmin: boolean) {

    // }
}

export class CreateRole {
    static readonly type = '[Roles] CreateRole';

    constructor(public name: string) {}
}

export class InitTree {
    static readonly type = '[Roles] InitTree';
}

export class SetCurrentRole {
    static readonly type = '[Roles] SetCurrentRole';

    constructor(public roleId: string) {}
}

export class SaveRoles {
    static readonly type = '[Roles] SaveRoles';

    constructor(public tree: TreeRoleItemInterface[]) {}
}

export class ChangeTree {
    static readonly type = '[Roles] ChangeTree';

    constructor(public tree: TreeRoleItemInterface[]) {}
}

export class CancelChangeCurrentRole {
    static readonly type = '[Roles] CancelChangeCurrentRole';
}

export class ChangeActiveRole {
    static readonly type = '[Roles] ChangeActiveRole';

    constructor(
        public roleId: string,
        public isActive: boolean,
    ) {}
}

export class DeleteRole {
    static readonly type = '[Roles] DeleteRole';

    constructor(public roleId: string) {}
}

export class RefreshTableRoles {
    static readonly type = '[Roles] RefreshTableRoles';
}

export class CheckCurrentUseRole {
    static readonly type = '[Roles] CheckCurrentUseRole';

    constructor(public roleId: string) {}
}

export class GetCurrentRole {
    static readonly type = '[Roles] GetCurrentRole';

    constructor(public id: string) {}
}

export class ClearCurrentRole {
    static readonly type = '[Roles] ClearCurrentRole';
}

export class CancelRoleOption {
    static readonly type = '[Roles] CancelRoleOption';
}

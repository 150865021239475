export enum HTTP_STATUS {
    SUCCESS = 0,
    OK = 200,
    ERROR = -1,
    NOT_CONFIRMED_EMAIL = -2,
    EMAIL_ALREADY_EXISTS = -3,
    ACCOUNT_ALREADY_REGISTER = -4,
    SESSION_FREEZE = -5,
    INCORRECT_EMAIL = -6,
    INVALID_PARAMETERS = -7,
    WRITE_NOT_FOUND = -8,
    INVALID_PASSWORD = -9,
    IN_PROCESSED_REGISTRATOR = -10,
    REGISTRATOR_IS_OFFLINE = -11,
    EVENTS_ACTIVE_FOR_REGISTRATOR = -12,
    PHONE_ALREADY_USE = -13,
    REPORT_ALREADY_GENERATE = -14,
    REGISTRATOR_IN_SYNC = -15,
    MESSAGE_ALREADY_USE_IN_LOGIC_EVENT = -16,
    INVALID_EVENT_EXPRESSION = -17,
    TOKEN_NOT_VALID = -18,
    ARCHIVE_PERIOD_HISTORY_IS_EMPTY = -19,
    REPORT_PRINT_SERVER_UNAVAILABLE = -20,
    DEFAULT_REPORT_NOT_SET = -21,
    USER_IS_DELETED = -22,
    USER_EMAIL_NOT_CONFIRMED = -23,
    USER_NOT_CONFIRMED_BY_ADMIN = -24,
    USER_NOT_ACTIVE = -25,
    TWO_FACTOR_AUTHENTICATION_ALREADY_TIED = -26,
    TWO_FACTOR_AUTHENTICATION_ALREADY_VERIFY = -27,
    TWO_FACTOR_AUTHENTICATION_IS_DISABLED = -28,
    TWO_FACTOR_AUTHENTICATION_TOKEN_NOT_VALID = -29,
    TWO_FACTOR_AUTHENTICATION_REQUIRED = -30,
    PASSWORD_IS_EXPIRED = -31,
    NEED_CHANGE_PASSWORD = -32,
    MANY_TRY = -40,
    USER_WEB_AUTH_IS_DISABLED = -41,

    DATA_ALREADY_EXISTS = -39,
    UNAUTHORIZED = -401,
    NOT_FOUND = -404,
    ACCESS_DENIED = -403,
    PAYLOAD_TOO_LARGE = -413,
    TOO_MANY_REQUEST = -429,
    INTERNAL_SERVER_ERROR = -500,
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {ColumnsTableInterface, GroupOperationRowPropertyInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import {TableState} from 'src/app/app-shared-elements/_store/states/table.state';
import {GroupOperationActionInterface} from '../../_interfaces/group-operation-action.interface';

@Component({
    selector: 'app-column-group-operation',
    templateUrl: './column-group-operation.component.html',
    styleUrls: ['./column-group-operation.component.scss'],
})
export class ColumnGroupOperationComponent implements OnInit {

    private _row: any;

    @Input() cell: ColumnsTableInterface;
    @Input() isMain: boolean;
    @Input() value: GroupOperationRowPropertyInterface;

    @Input()
    set row(row) {
        this._row = row;
    }

    get row(): any {
        return this._row;
    }

    @Output() chooseEvent: EventEmitter<GroupOperationActionInterface> = new EventEmitter<GroupOperationActionInterface>();

    @Select(TableState.getTableDisableIdsForGroupOperations) disableIds$: Observable<string[]>;
    @Select(TableState.getIsMainGroupOperation) isMainChecked$: Observable<boolean>;

    constructor() {}

    ngOnInit(): void {
    }

    changeEvent(event: boolean): void {
        this.chooseEvent.emit({
            id: this.isMain ? null : this.row.id,
            event, isMain:
            this.isMain
        });
    }
}

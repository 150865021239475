import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken, Store} from '@ngxs/store';
import {ChangeExpeditionArray, GetArchiveMap, GetEventLogMap, GetExpeditionArray, SetExpeditionSelect, SetIsShowMap} from '../actions/device-map.actions';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ApiResponse} from '../../../app-shared-elements/_interfaces/ApiRequest';
import {ArchiveRow} from '../../../app-shared-elements/_interfaces/ArchiveChart';
import {VariableChangesInterface} from '../../_interfaces/variable-changes-interface';
import {HTTP_STATUS} from '../../../app-shared-elements/_enums/status.enum';
import {EventFromServer} from '../../../journals/_interfaces/technologic-log-row.interface';
import {ExpeditionPopupInterface} from '../../container-registrators/device-map/_interface/expedition-popup.interface';
import {GetExpeditions} from '../../../expedition/_store/actions/expeditions.actions';
import {ExpeditionsState} from '../../../expedition/_store/state/expeditions.state';
import {DevicesState} from '../../../device-dashboard/_store/states/user-devices.state';
import {DataTypeService} from '../../../app-shared-elements/_services/data-type.service';

export interface DeviceMapStateModel {
    isShowMap: boolean;
    archiveMap: { items: ArchiveRow[], variableUpdates: VariableChangesInterface[] };
    eventLogsMap: EventFromServer[];
    expeditionSelectTime: { from: number, to: number };
    expeditionPopupArray: ExpeditionPopupInterface[];
}

const DEVICE_MAP_TOKEN = new StateToken<DeviceMapStateModel>('deviceMap');

@State<DeviceMapStateModel>({
    name: DEVICE_MAP_TOKEN,
    defaults: {
        isShowMap: true,
        archiveMap: {items: [], variableUpdates: []},
        eventLogsMap: [],
        expeditionSelectTime: null,
        expeditionPopupArray: []
    }
})
@Injectable()
export class DeviceMapState {
    constructor(private http: HttpClient,
                private dataTypeService: DataTypeService,
                private store: Store) {

    }

    @Selector()
    static getIsShowMap(state: DeviceMapStateModel): boolean {
        return state.isShowMap;
    }

    @Selector()
    static getArchiveMap(state: DeviceMapStateModel): { items: ArchiveRow[], variableUpdates: VariableChangesInterface[] } {
        return state.archiveMap;
    }

    @Selector()
    static getEventLogsMap(state: DeviceMapStateModel): EventFromServer[] {
        return state.eventLogsMap;
    }

    @Selector()
    static getExpeditionSelectTime(state: DeviceMapStateModel): { from: number, to: number } {
        return state.expeditionSelectTime;
    }

    @Selector()
    static getExpeditionPopupArray(state: DeviceMapStateModel): ExpeditionPopupInterface[] {
        return state.expeditionPopupArray;
    }

    @Action(SetIsShowMap)
    setIsShowMap(ctx: StateContext<DeviceMapStateModel>, payload: SetIsShowMap): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isShowMap: payload.value
        });
    }

    @Action(GetArchiveMap)
    async getArchiveMap(ctx: StateContext<DeviceMapStateModel>, payload: GetArchiveMap): Promise<void> {
        const params = new HttpParams()
            .set('variablesId', payload.data.variablesId.join(','))
            .set('start', String(Math.floor(payload.data.start)))
            .set('end', String(Math.floor(payload.data.end)))
            .set('deviceId', String(payload.data.deviceId));

        const result: ApiResponse = await this.http.get('/api/archive/map', {params}).toPromise().catch(e => console.log(e)) as ApiResponse;
        const state = ctx.getState();

        if (result) {
            ctx.setState({
                ...state,
                archiveMap: result.data as { items: ArchiveRow[], variableUpdates: VariableChangesInterface[] }
            });
        }
    }

    @Action(GetEventLogMap)
    async getEventLogMap(ctx: StateContext<DeviceMapStateModel>, payload: GetEventLogMap): Promise<void> {
        const state = ctx.getState();

        const params = new HttpParams()
            .set('registratorId', payload.data.registratorId)
            .set('from', String(Math.floor(payload.data.from)))
            .set('to', String(Math.floor(payload.data.to)));

        const result: ApiResponse = await this.http.get('/api/logic-events-log/map-event-log', {params}).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                eventLogsMap: result.data
            });
        }
    }

    @Action(SetExpeditionSelect)
    setExpeditionSelect(ctx: StateContext<DeviceMapStateModel>, payload: SetExpeditionSelect): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            expeditionSelectTime: {...payload.date}
        });
    }

    @Action(GetExpeditionArray)
    async getExpeditionArray(ctx: StateContext<DeviceMapStateModel>): Promise<void> {
        if (!this.store.selectSnapshot(ExpeditionsState.getExpeditions)?.length) {
            await ctx.dispatch(new GetExpeditions(true)).toPromise();
        }
        const state = ctx.getState();
        ctx.setState({
            ...state,
            expeditionPopupArray: this.store.selectSnapshot(ExpeditionsState.getExpeditions)
                .filter(e => e.registratorId === this.store.selectSnapshot(DevicesState.getCurrentRegistrator).id)
                .map(exp => {
                    let title = this.dataTypeService.reformatDate(exp.start * 1000) + ' - ';
                    title += exp.finish
                        ? this.dataTypeService.reformatDate(exp.finish * 1000) : this.dataTypeService.reformatDate(new Date().getTime());
                    return {
                        title,
                        action: false,
                        from: exp.start * 1000,
                        to: exp.finish ? exp.finish * 1000 : new Date().getTime()
                    };
                })
        });
    }

    @Action(ChangeExpeditionArray)
    changeExpeditionArray(ctx: StateContext<DeviceMapStateModel>, payload: ChangeExpeditionArray): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            expeditionPopupArray: payload.array
        });
    }
}

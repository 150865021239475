import {NgModule} from '@angular/core';
import {MobileDevicesComponent} from './mobile-devices/mobile-devices.component';
import {AppSharedElementsModule} from '../app-shared-elements/app-shared-elements.module';
import {CommonModule} from '@angular/common';
import {MobileRoutingModule} from './mobile-routing.module';
import {MobileEventsComponent} from './mobile-events/mobile-events.component';
import {MobileCardComponent} from './mobile-elements/mobile-card/mobile-card.component';
import {MobileFilterComponent} from './mobile-elements/mobile-filter/mobile-filter.component';


@NgModule({
    declarations: [
        MobileDevicesComponent,
        MobileEventsComponent,
        MobileCardComponent,
        MobileFilterComponent
    ],
    imports: [
        CommonModule,
        AppSharedElementsModule,
        MobileRoutingModule
    ],
    exports: [
        AppSharedElementsModule,
        MobileRoutingModule,
        MobileFilterComponent
    ]
})
export class MobileSharedModule {
}

export enum PreloaderWrappersEnum {
    tableBody = '.table__body',
    content = '.content',
    charts = '.charts__container',
    loadPopup = '.popup__load',
    app = '.app',
    groupsDashboard = '.groups-dashboard__wrapper',
    container = '.container',
    login__page = '.login__page',
    popup = '.popup'
}

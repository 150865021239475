import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SetOperation } from '../../_store/actions/table.actions';
import { TableState } from '../../_store/states/table.state';
import { TableOperationInterface } from '../_interfaces/table-operation.interface';

@Component({
    selector: 'app-group-operation',
    templateUrl: './group-operation.component.html',
    styleUrls: ['./group-operation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupOperationComponent implements OnInit {
    @Select(TableState.getTableGroupOperations) tableOperations$: Observable<TableOperationInterface[]>;

    constructor(private store: Store) {}

    ngOnInit(): void {}

    setOperation(operation: TableOperationInterface): void {
        this.store.dispatch(new SetOperation(operation));
    }
}

import {Subject} from 'rxjs';
import {Injectable, NgZone} from '@angular/core';
import * as workerTimers from 'worker-timers';

export interface ItemQueueInterface<T> {
    queueItemId: string;
    id: string;
    data: T;
}

@Injectable()
export class QueueService<Input, Output> {

    private queue: ItemQueueInterface<Input>[] = [];
    private subscribeOnExecute: Subject<ItemQueueInterface<Output>> = new Subject();
    private handlerTimeout: any;

    constructor(
        private ngZone: NgZone
    ) {
    }
    public destroy(): void {
        this.queue = [];
        this.subscribeOnExecute.complete();
        this.subscribeOnExecute = new Subject<ItemQueueInterface<Output>>();
    }

    getLength(): number {
        return this.queue.length;
    }

    public async start(handler: (item: Input) => Promise<Output>): Promise<void> {
        if (this.handlerTimeout) {
            workerTimers.clearTimeout(this.handlerTimeout);
        }

        const item = this.queue.pop();
        if (!item) {
            this.ngZone.runOutsideAngular(() => {
                this.handlerTimeout = workerTimers.setTimeout(async () => this.start(handler), 400);
            });

            return;
        }
        let outputData = null;

        try {
            outputData = await handler(item.data);
        } catch (e) {
            console.error(e);
        }

        this.subscribeOnExecute.next({
            ...item,
            data: outputData
        });

        this.ngZone.runOutsideAngular(() => {
            this.handlerTimeout = workerTimers.setTimeout(async () => this.start(handler), 0);
        });

        return;
    }

    public has(id: string): boolean {
        return !!this.queue.find(item => item.id === id);
    }

    getRandom(min, max): string {
        return String(Math.random() * (max - min) + min);
    }

    public async execute(uniqId: string, item: Input): Promise<Output> {
        return new Promise((resolve, reject) => {
            const id = String(this.getRandom(1000000, 1000000000));
            const data: ItemQueueInterface<Input> = {
                queueItemId: id,
                id: uniqId,
                data: item
            };

            this.queue.unshift(data);

            const sub = this.subscribeOnExecute.subscribe((output: ItemQueueInterface<Output>) => {
                if (output.queueItemId === id) {
                    sub.unsubscribe();
                    if (output.data) {
                        resolve(output.data);
                    } else {
                        reject();
                    }
                }
            });
        });

    }
}



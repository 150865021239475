<div *ngIf="config" class="calendar">
    <div class="calendar__datepicker datepicker">
        <p-calendar [ngModel]="config.from"
                    [showTime]="true"
                    [dateFormat]="config.dateFormat"
                    [hourFormat]="config.hourFormat === userProfileInfoTypeDateEnum.ddMMyyyy ? '24' : '12'"
                    class="datepicker__wrapper"
                    [class.datepicker__wrapper--choosen]="config.from"
                    placeholder="{{'filters.placeholderFrom' | translate}}"
                    (onSelect)="selectCalendar(fromToTypeCalendar.from, $event)"
                    (onInput)="selectCalendar(fromToTypeCalendar.from, $event)"
                    [showIcon]="true"
                    [hideOnDateTimeSelect]="false"
                    [disabled]="!config"
                    [maxDate]="dateNow">

            <ng-template pTemplate="dateFrom" let-date>

                <span class="datepicker__day"
                      [appDatepickerDisabledDate]="{dateTo: config.to, dateFrom: config.from, currentCalendarDate: date, fromToTypeCalendar: fromToTypeCalendar.from}"
                      [class.datepicker__day--disabled]="+date > dateNow.getDate()">


                        {{date.day}}</span>
            </ng-template>
        </p-calendar>
    </div>

    <div class="calendar__datepicker datepicker">
        <p-calendar [ngModel]="config.to"
                    [showTime]="true"
                    [dateFormat]="config.dateFormat"
                    [hourFormat]="config.hourFormat === userProfileInfoTypeDateEnum.ddMMyyyy ? '24' : '12'"
                    class="datapicker__wrapper"
                    [class.datepicker__wrapper--choosen]="config.to"
                    placeholder="{{'filters.placeholderTo' | translate}}"
                    [showIcon]="true"
                    [defaultDate]="dateNow"
                    [hideOnDateTimeSelect]="false"
                    [disabled]="!config || !config.from"
                    [maxDate]="config.maxDate || dateNow"
                    (onSelect)="selectCalendar(fromToTypeCalendar.to, $event)"
                    (onInput)=" selectCalendar(fromToTypeCalendar.to, $event)">

            <ng-template pTemplate="dateTo" let-date>
                <span class="datepicker__day"
                      [appDatepickerDisabledDate]="{dateTo: config.to, dateFrom: config.from, currentCalendarDate: date, fromToTypeCalendar: fromToTypeCalendar.to}"
                      [class.datepicker__day--disabled]="(date.day < config.from.getDate() && date.month <= config.from.getMonth())
                        || (date.day > dateNow.getDate() && date.month === dateNow.getMonth())
                        || (date.day > (config.from.getDate() + (config.maxRange / DAY_IN_MILLS))
                        || date.month > config.from.getMonth())">{{date.day}}</span>
            </ng-template>

        </p-calendar>
    </div>
</div>



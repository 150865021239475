export class GetConstant {
    static readonly type = '[SystemSettings] GetConstant';
}

export class UpdateConstant {
    static readonly type = '[SystemSettings] UpdateConstant';

    constructor(public data: {key: string, value: string}) {
    }
}

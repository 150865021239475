import {BreadcrumbsService} from '../_services/breadcrumbs.service';
import {Observable, Subject} from 'rxjs';
import {Breadcrumb} from '../_interfaces/Breadcrumbs';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Select} from '@ngxs/store';
import {takeUntil} from 'rxjs/operators';
import {BreadcrumbsState} from '../_store/states/breadcrumbs.state';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {

    data: Breadcrumb;

    readonly BREADCRUMBS_ICON_PARH = './assets/design/icons/breadcrumbs-arrow.svg';

    private destroy: Subject<boolean> = new Subject<boolean>();

    @Select(BreadcrumbsState.getBreadcrumbs) breadcrumbs$: Observable<Breadcrumb>;

    constructor(
        public breadcrumbsService: BreadcrumbsService,
        private router: Router
    ) {
        this.breadcrumbs$.pipe(takeUntil(this.destroy)).subscribe(breadcrumbs => {
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }

    moveTo(item: Breadcrumb): void {
        if (!item.children) {
            return;
        }

        this.router.navigate([item.link]);

    }

}

import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetAdminLogs, GetAdminLogsRows, SetAdminLogsFilter } from '../actions/admin-logs.actions';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../../app-shared-elements/_enums/status.enum';
import { ColumnsTableInterface, ColumnTypeEnum, ColumnValueAlignEnum } from '../../../../app-shared-elements/_interfaces/ColumnsTable';
import { Pagination, Params, ParamsSorted, ParamsSortedOrderEnum } from '../../../../app-shared-elements/_interfaces/params.interface';
import { initialFilterAdminLogs } from '../../_data/admin-logs.data';
import { AdminLogsInterface } from '../../_interfaces/admin-logs.interface';
import { TimeFilterState } from '../../../../app-shared-elements/_store/states/time-filter.state';
import { ParamsService } from '../../../../app-shared-elements/_services/params.service';
import { AdminLogsRowsInterface } from '../../_interfaces/admin-logs-rows.interface';
import { UserLogInterface } from '../../../../journals/user-logs/_interface/user-logs.interface';
import { UsersLogsService } from '../../../../journals/_services/users-logs.service';
import { TranslateService } from '@ngx-translate/core';
import { DropdownFilterOptionInterface } from '../../../../app-shared-elements/filters/interfaces/filter-option.interface';
import { TypeFilterEnum } from '../../../../app-shared-elements/filters/enums/type-filter.enum';

export interface AdminLogsStateModel {
    adminLogs: { items: AdminLogsInterface[]; total: number };
    adminLogsRows: AdminLogsRowsInterface[];
    columns: ColumnsTableInterface[];
    params: Params;
    dropDownFilterOptions: DropdownFilterOptionInterface[];
}

const ADMIN_LOGS_STATE = new StateToken<AdminLogsStateModel>('adminLogs');

const initAdminLogsColumns: ColumnsTableInterface[] = [
    {
        title: 'table.usersLogs.date',
        grow: false,
        small: false,
        sort: 0,
        maxWidth: '120px',
        minWidth: '100px',
        type: ColumnTypeEnum.date,
        name: 'created',
        sortField: 'created',
    },
    {
        title: 'table.usersLogs.userLogin',
        grow: false,
        small: false,
        sort: 0,
        maxWidth: '150px',
        minWidth: '150px',
        type: ColumnTypeEnum.text,
        name: 'userLogin',
        sortField: 'userLogin',
    },
    {
        title: 'table.usersLogs.shortMessages',
        grow: true,
        small: false,
        type: ColumnTypeEnum.text,
        name: 'shortMessage',
        isMultilang: true,
        align: ColumnValueAlignEnum.left,
        allowHtmlTags: true,
    },
    {
        title: 'table.usersLogs.messages',
        grow: true,
        small: false,
        type: ColumnTypeEnum.text,
        name: 'message',
        isMultilang: true,
        align: ColumnValueAlignEnum.left,
        allowHtmlTags: true,
    },
    {
        title: 'table.usersLogs.actionPage',
        grow: false,
        small: false,
        maxWidth: '300px',
        type: ColumnTypeEnum.text,
        name: 'actionPage',
    },
    {
        title: 'table.usersLogs.meta',
        grow: false,
        small: true,
        maxWidth: '200px',
        minWidth: '180px',
        type: ColumnTypeEnum.text,
        name: 'metaData',
    },
    {
        title: 'table.usersLogs.ip',
        grow: false,
        small: true,
        maxWidth: '240px',
        minWidth: '140px',
        type: ColumnTypeEnum.text,
        name: 'userIp',
    },
    {
        title: 'table.usersLogs.action',
        grow: false,
        small: true,
        maxWidth: '85px',
        minWidth: '60px',
        type: ColumnTypeEnum.text,
        name: 'action',
    },
];

const initialCurrentSort: ParamsSorted[] = [
    {
        property: 'created',
        order: ParamsSortedOrderEnum.DESC,
    },
];

const initialPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

export const initialParams: Params = {
    filter: initialFilterAdminLogs,
    sorted: initialCurrentSort,
    pagination: initialPagination,
};

const dropDownFilterOptions: DropdownFilterOptionInterface[] = [
    { key: 'userLogin', value: 'logs.users.filtersDropdown.login', type: TypeFilterEnum.text, property: 'userLogin' },
    { key: 'userIp', value: 'logs.users.filtersDropdown.ip', type: TypeFilterEnum.text, property: 'userIp' },
    { key: 'srcId', value: 'logs.users.filtersDropdown.srcId', type: TypeFilterEnum.text, property: 'srcId' },
];

@State<AdminLogsStateModel>({
    name: ADMIN_LOGS_STATE,
    defaults: {
        adminLogs: { items: [], total: 0 },
        adminLogsRows: [],
        columns: initAdminLogsColumns,
        dropDownFilterOptions,
        params: initialParams,
    },
})
@Injectable()
export class AdminLogsState {
    constructor(
        private http: HttpClient,
        private store: Store,
        private paramsService: ParamsService,
        private usersLogsService: UsersLogsService,
        private translateService: TranslateService,
    ) {}

    @Selector()
    static getAdminLogsRows(state: AdminLogsStateModel): AdminLogsRowsInterface[] {
        return state.adminLogsRows;
    }

    @Selector()
    static getColumns(state: AdminLogsStateModel): ColumnsTableInterface[] {
        return state.columns;
    }

    @Selector()
    static getParams(state: AdminLogsStateModel): Params {
        return state.params;
    }

    @Selector()
    static getDropdownFilterOptions(state: AdminLogsStateModel): DropdownFilterOptionInterface[] {
        return state.dropDownFilterOptions;
    }

    @Action(GetAdminLogs)
    async getAdminLogs(ctx: StateContext<AdminLogsStateModel>): Promise<void> {
        const state = ctx.getState();

        const datetime = this.store.selectSnapshot(TimeFilterState.getTimeObj);
        const params: Params = {
            ...state.params,
            filter: this.paramsService.parseParamsFilterForServer(state.params.filter, datetime),
            sorted: state.params.sorted && state.params.sorted.length ? state.params.sorted : initialCurrentSort,
        };

        // console.log(params);

        const result: ApiResponse = (await this.http
            .get('/api/admins-log', {
                headers: {
                    params: encodeURIComponent(JSON.stringify(params)),
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                adminLogs: result.data,
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.total,
                    },
                    sorted: params.sorted && params.sorted.length ? params.sorted : initialCurrentSort,
                },
            });

            ctx.dispatch(new GetAdminLogsRows());
        }
    }

    @Action(GetAdminLogsRows)
    async getAdminLogsRows(ctx: StateContext<AdminLogsStateModel>): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            adminLogsRows: await Promise.all(
                state.adminLogs.items.map(async (item: UserLogInterface) => {
                    const text = await this.usersLogsService.performDataMessage(item.actionPage, item.jsonDataLog);
                    const shortText = item.shortJsonDataLog
                        ? await this.usersLogsService.performDataMessage(item.actionPage, item.shortJsonDataLog)
                        : null;

                    let srcId: string | number = '';
                    item.jsonDataLog.data.forEach((r) => {
                        if (r.property && r.property.toLowerCase().includes('id') && r.value) {
                            srcId = String(r.value) ?? '';
                        }
                    });

                    return {
                        message: `<span>${text}.</span>`,
                        shortMessage: shortText ? `<span>${shortText}.</span>` : '',
                        action: item.action,
                        userIp: item.userIp,
                        internalId: item.internalId,
                        deviceName: item.deviceName,
                        deviceId: item.deviceId,
                        created: new Date(item.created).getTime(),
                        userLogin: item.userLogin,
                        actionPage: item.actionPage
                            ? await this.translateService.get(`user_logs.actionPage.${item.actionPage}`).toPromise()
                            : '',
                        userId: item.userId,
                        id: item.userId,
                        metaData: item.userAgent,
                        srcId,
                    };
                }),
            ),
        });
    }

    @Action(SetAdminLogsFilter)
    setAdminLogsFilter(ctx: StateContext<AdminLogsStateModel>, payload: SetAdminLogsFilter): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: { ...payload.params },
        });

        ctx.dispatch(new GetAdminLogs());
    }
}

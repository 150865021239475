export enum VariableValueTypeEnum {
    string = 'string',
    float = 'float',
    int = 'int',
    negativeInt = 'negativeInt',
    floatRestriction = 'floatRestriction',
    restriction = 'restriction',
    password = 'password',
    numbers = 'numbers',
    delay = 'delay',
    numbersAndDot = 'numbersAndDot',
    email = 'email',
}

import { ComponentRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngxs/store';
import { PremissionHelperComponent } from '../premission-helper/premission-helper.component';
import { ResourceAction } from '../_enums/permission.enum';
import { PermissionDirectiveDataInterface } from '../_interfaces/permission-directive-data.interface';
import { RoleInterface } from '../_interfaces/role.interface';
import { CloneDeepService } from '../_services/clone-deep.service';
import { ComponentGeneratorService } from '../_services/component-generator.service';
import { PermissionService } from '../_services/permission.service';
import { PermissionsState } from '../_store/states/permissions.state';
import { AuthState } from '../../auth/_store/states/auth.state';

@Directive({
    selector: '[appPermission]',
})
export class PermissionDirective implements OnInit {
    private _data: PermissionDirectiveDataInterface;

    roles: RoleInterface[];

    oldData: PermissionDirectiveDataInterface;

    @Input('appPermission')
    set data(data: PermissionDirectiveDataInterface) {
        this._data = data;

        this.init();
    }

    get data(): PermissionDirectiveDataInterface {
        return this._data;
    }

    isPermissionAllowed = false;
    isFirstCheck = true;

    initedHelpersForActions: ResourceAction[] = [];

    helperComponent: ComponentRef<PremissionHelperComponent>;

    constructor(
        private store: Store,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private permissionService: PermissionService,
        private componentsGeneratorService: ComponentGeneratorService,
        private cloneDeepService: CloneDeepService,
    ) {}

    ngOnInit(): void {}

    async init(): Promise<void> {
        if (
            this.data.isWithoutPermission ||
            (this.data.registrator && !this.data.registrator.roleId) ||
            this.store.selectSnapshot(AuthState.getIsAdmin)
        ) {
            this.oldData = this.cloneDeepService.cloneObject(this.data);

            if (!this.isPermissionAllowed) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.destroyHelpComponent();
                this.isPermissionAllowed = true;
            }

            return;
        }

        if (!this.isDataUpdate() || !this.data.registrator) {
            return;
        }

        this.oldData = this.cloneDeepService.cloneObject(this.data);

        this.roles = this.store.selectSnapshot(PermissionsState.getRoles);

        if (this.data.action && this.data.registrator) {
            await this.updatedView();
        } else if (this.isPermissionAllowed) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.destroyHelpComponent();
            this.isPermissionAllowed = true;
        }
    }

    async updatedView(): Promise<void> {
        // console.log(3);

        try {
            const currentRole: RoleInterface = this.roles.find((role) => role.id === this.data.registrator.roleId);
            // console.log(currentRole);
            if (!currentRole) {
                this.viewContainer.clear();
                await this.initHelpComponent();
                this.isPermissionAllowed = false;
                return;
            }

            // console.log(this.permissionService.checkPermission(currentRole.permissions, this.data));
            if (this.permissionService.checkPermission(currentRole.permissions, this.data)) {
                if (this.isPermissionAllowed) {
                    await this.destroyHelpComponent();
                    return;
                }

                this.viewContainer.createEmbeddedView(this.templateRef);
                this.destroyHelpComponent();
                this.isPermissionAllowed = true;
            } else {
                if (!this.isPermissionAllowed) {
                    await this.initHelpComponent();
                    return;
                }

                this.viewContainer.clear();
                await this.initHelpComponent();
                this.isPermissionAllowed = false;
            }
        } catch (e) {
            console.log(e);
        }
    }

    async initHelpComponent(): Promise<void> {
        // console.log('initHelp comp[onent');
        if (!this.data.isHelper) {
            return;
        }

        let wrapperClass = '';

        if (Array.isArray(this.data.action)) {
            wrapperClass = `.${this.data.action[0]}`;
        } else {
            wrapperClass = `.${this.data.action}`;
        }

        const component = await this.componentsGeneratorService.create(PremissionHelperComponent, wrapperClass);
        this.helperComponent = component as ComponentRef<PremissionHelperComponent>;

        this.helperComponent.instance.currentResource = Array.isArray(this.data.action) ? this.data.action[0] : this.data.action;

        this.initedHelpersForActions.push(Array.isArray(this.data.action) ? this.data.action[0] : this.data.action);
    }

    async destroyHelpComponent(): Promise<void> {
        if (!this.helperComponent) {
            return;
        }
        await this.helperComponent.destroy();
    }

    isDataUpdate(): boolean {
        if (!this.oldData) {
            return true;
        }

        if (this.permissionsUpdate()) {
            return true;
        }

        if (this.registratorUpdate()) {
            return true;
        }

        return false;
    }

    permissionsUpdate(): boolean {
        let result = false;
        this.data.permissions.forEach((p) => {
            if (result) {
                return;
            }

            result = this.oldData.permissions.find((oldPermission) => oldPermission === p) ? false : true;
        });

        return result;
    }

    registratorUpdate(): boolean {
        let result: boolean;
        if (
            !this.oldData.registrator ||
            this.data.registrator?.id !== this.oldData.registrator.id ||
            this.data.registrator.roleId !== this.oldData.registrator.roleId
        ) {
            result = true;
        } else {
            result = false;
        }

        return result;
    }
}

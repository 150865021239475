import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector, Type } from '@angular/core';

@Injectable()
export class ComponentsGeneratorService {
    private static data = {};

    constructor(private appRef: ApplicationRef, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector) {}

    /**
     * Динамическое создание компонена.
     * @param component Component name by string or component factory itself (ex. .create('ModalPopupComponent', ...) or .create(ModalPopupComponent, ...))
     * @param target DOM element or string css selector
     * @param data object with data parameters, will be injected to component
     * @return ViewReference
     */
    public create(component: any, target: HTMLElement | string, data?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            // таймаут необходим чтобы дать angular change detection время все прочекать и избежать ExpressionChangedAfterItHasBeenCheckedError
            setTimeout(() => {
                resolve(this._create(component, target, data));
            }, 0);
        });
    }

    private _create(component: any, target: HTMLElement | string, data?: any): any {
        // 1. Create a component reference from the component
        let componentFactory;
        if (typeof component === 'string') {
            const factories = Array.from(this.componentFactoryResolver['_factories'].keys());

            componentFactory = <Type<any>>factories.find((x: any) => x.name === component);

            if (!componentFactory) {
                throw new Error(`Dynamic creation of component ${component} fail, factory for component is not found`);
            }
        } else {
            componentFactory = component;
        }

        const componentRef = this.componentFactoryResolver.resolveComponentFactory(componentFactory).create(this.injector);

        // 2. Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);

        if (data) {
            Object.keys(data).forEach((key) => {
                componentRef.instance[key] = data[key];
            });
        }

        // 3. Get DOM element from component
        const createdComponent = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        // 4. Append DOM element to the target node
        if (target) {
            let targetDomElement;
            if (target instanceof HTMLElement) {
                targetDomElement = target;
            } else if (typeof target === 'string') {
                targetDomElement = document.querySelector(target);
            }
            if (!targetDomElement) {
                return;
            }
            targetDomElement.appendChild(createdComponent);
            const savedComponents = ComponentsGeneratorService.data;

            if (typeof savedComponents[componentFactory.name] === 'undefined') {
                savedComponents[componentFactory.name] = [{ node: targetDomElement, reference: componentRef }];
            } else {
                savedComponents[componentFactory.name].push({ node: targetDomElement, reference: componentRef });
            }
        }

        // console.log(componentRef);
        return componentRef;
    }
}

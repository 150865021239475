import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {AlarmTypeEnum} from 'src/app/events/_enums/alarm.enum';
import {SignalEnum} from '../../_enums/signal.enum';
import {ErrorAlertInterface} from '../../_interfaces/erorr-alert.interface';
import {SignalService} from '../../_services/signal.service';
import {SwitchFaviconService} from '../../_services/switch-favicon.service';
import {SetErrorAlertDevice, SetErrorAlertEvent} from '../actions/error-alert.actions';

export interface ErrorAlertStateModel {
    data: ErrorAlertInterface;
}

const ERROR_ALERT_TOKEN = new StateToken<ErrorAlertStateModel>('errorAlertState');

const initialData: ErrorAlertInterface = {
    alarm: false,
    alarmBlink: false,
    attention: false,
    attentionBlink: false,
    deviceEvent: false,
    deviceEventBlink: false
};

@State<ErrorAlertStateModel>({
    name: ERROR_ALERT_TOKEN,
    defaults: {
        data: initialData,
    }
})
@Injectable()
export class ErrorAlertState {
    constructor(
        private signalService: SignalService,
        private switchFaviconService: SwitchFaviconService
    ) {

    }

    @Selector()
    static getErrorAlertData(state: ErrorAlertStateModel): ErrorAlertInterface {
        return state.data;
    }

    @Action(SetErrorAlertEvent)
    setErrorAlertEvent(ctx: StateContext<ErrorAlertStateModel>, payload: SetErrorAlertEvent): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            data: {
                ...state.data,
                alarm: false,
                alarmBlink: false,
                attention: false,
                attentionBlink: false,
                deviceEvent: false,
                deviceEventBlink: false
            }
        });

        let start = false;
        let alarm = false;
        let alarmBlink = false;
        let attention = false;
        let attentionBlink = false;

        payload.activeEvents.map(activeEvents => {
            activeEvents.events.map(event => {
                if (!event.tsCleared) {
                    if (event.alarmType === AlarmTypeEnum.alarm) {
                        alarm = true;
                    } else {
                        attention = true;
                    }
                }

                if (event.isAknowledgeable && !event.tsAcknowledget) {
                    if (event.alarmType === AlarmTypeEnum.alarm) {
                        alarmBlink = true;
                    } else {
                        attentionBlink = true;
                    }
                }

                start = true;
            });

        });

        if (start && (alarm || alarmBlink || attention || attentionBlink)) {
            this.switchFaviconService.switchFavicon(true);
        } else if (!alarm && !alarmBlink && !attention && !attentionBlink && !state.data.deviceEvent && !state.data.deviceEventBlink) {
            this.switchFaviconService.switchFavicon(false);
        }

        if (start && alarmBlink) {
            this.signalService.initSignal(SignalEnum.alarm);
        }

        if (start && !alarmBlink && attentionBlink) {
            this.signalService.initSignal(SignalEnum.attention);
        }

        if (start && !alarmBlink && !attentionBlink) {
            this.signalService.destroySignal();
        }

        ctx.setState({
            ...state,
            data: {
                ...state.data,
                alarm,
                alarmBlink,
                attention,
                attentionBlink,
            }
        });
    }

    @Action(SetErrorAlertDevice)
    setErrorAlertDevice(ctx: StateContext<ErrorAlertStateModel>, payload: SetErrorAlertDevice): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            data: {
                ...state.data,
                deviceEvent: false,
                deviceEventBlink: false,
                alarm: false,
                alarmBlink: false,
                attention: false,
                attentionBlink: false,
            }
        });

        let deviceEvent = false;
        let deviceEventBlink = false;

        if (payload.deviceEvents && payload.deviceEvents.length) {
            deviceEvent = true;
            deviceEventBlink = true;
            this.switchFaviconService.switchFavicon(true);
        } else {
            deviceEvent = false;
            deviceEventBlink = false;
        }

        if ((!payload.deviceEvents || !payload.deviceEvents.length) && !state.data.alarm && !state.data.alarmBlink && !state.data.attention && !state.data.attentionBlink) {
            this.switchFaviconService.switchFavicon(false);
        }

        ctx.setState({
            ...state,
            data: {
                ...state.data,
                deviceEvent,
                deviceEventBlink
            }
        });
    }
}

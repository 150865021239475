import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {GetLimitMessages, UpdateLimitMessage} from '../actions/limit-messages.actions';
import {ApiResponse} from '../../../../app-shared-elements/_interfaces/ApiRequest';
import {HTTP_STATUS} from '../../../../app-shared-elements/_enums/status.enum';
import {LimitMessagesInterface} from '../../_interface/limit-messages.interface';
import {NotificationsService} from '../../../../app-shared-elements/_services/notifications.service';
import {TooltipStatusEnum} from '../../../../app-shared-elements/_enums/tooltip-status.enum';
import {EventMessagesService} from '../../_services/event-messages.service';
import {LogicEventType} from '../../../../events/logical-events/_interface/LogicEvent';

export interface LimitMessagesStateModel {
    limitMessages: LimitMessagesInterface[];
    limitMessagesRows: LimitMessagesInterface[];
}

const LIMIT_MESSAGES_TOKEN = new StateToken<LimitMessagesStateModel>('limitMessages');

@State({
    name: LIMIT_MESSAGES_TOKEN,
    defaults: {
        limitMessages: [],
        limitMessagesRows: []
    }
})

@Injectable()
export class LimitMessagesState {
    constructor(private http: HttpClient,
                private notificationsService: NotificationsService,
                private eventMessagesService: EventMessagesService) {
    }

    @Selector()
    static getLimitMessages(state: LimitMessagesStateModel): LimitMessagesInterface[] {
        return state.limitMessages;
    }

    @Selector()
    static getLimitMessagesRows(state: LimitMessagesStateModel): LimitMessagesInterface[] {
        return state.limitMessagesRows;
    }

    @Action(GetLimitMessages)
    async getLimitMessages(ctx: StateContext<LimitMessagesStateModel>): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = await this.http.get('/api/control/messages/limit').toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {

            result.data = result.data.sort((a, b) => a.message > b.message ? 1 : -1);
            ctx.setState({
                ...state,
                limitMessages: result.data,
                limitMessagesRows: result.data.map(d => {
                    d.translateType = d.logicEventType === LogicEventType.attentionDeadlineMin || d.logicEventType === LogicEventType.alarmDeadlineMin
                        ? 'limitMessage.min' : 'limitMessage.max';
                    return d;
                })
            });
        }
    }

    @Action(UpdateLimitMessage)
    async updateLimitMessage(ctx: StateContext<LimitMessagesStateModel>, payload: UpdateLimitMessage): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = await this.http.post('/api/control/messages/limit', {...payload.data}).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                limitMessages: [...state.limitMessages],
                limitMessagesRows: [...state.limitMessagesRows]
            });
            await ctx.dispatch(new GetLimitMessages()).toPromise();
        }
    }
}

<div class="input">
    <div class="input__wrapper">
        <div class="input__label">{{label | translate}}</div>
        <div *ngIf="!isWithoutInput" class="input__input-wrapper">
            <input class="input__input" type="number" min="0" [(ngModel)]="value" (input)="change()"
                   (change)="change()">
        </div>
        <ng-container *ngIf="!isDeleteInput">
            <div class="input__add"
                 [class.input__add--disabled]="value === null || isDisabled && !isWithoutInput || disabled"
                 (click)="applyNewValue()">

                <svg-icon [src]="ADD_ICON_PATH"></svg-icon>
            </div>
        </ng-container>

        <ng-container *ngIf="isDeleteInput">
            <div class="input__delete"
                 (click)="deleteValue()"
            >
                <svg-icon [src]="DELETE_ICON_PATH"></svg-icon>
            </div>
        </ng-container>
    </div>
</div>

import *  as jsonpack from 'jsonpack/main';


export enum CompressionEnum {
    COMPRESSION_PROPERTY = '85f2475ef170'
}

export class CompressionService{

    static compressionJson(data: any): {compression: any} | any{
        // if(this.isCompressionUserRequest()){
        //     return {[CompressionEnum.COMPRESSION_PROPERTY]: jsonpack.pack(data)}
        // }

        return data;
    }

    static decompressionJson(data: any): any{
        if (data[CompressionEnum.COMPRESSION_PROPERTY]){

            const decompressionJsonData = jsonpack.unpack(data[CompressionEnum.COMPRESSION_PROPERTY]);

            return decompressionJsonData;
        }

        return data;


    }
}

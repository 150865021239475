import { Component, OnDestroy, OnInit } from '@angular/core';
import { DropdownFilterOptionInterface } from 'src/app/app-shared-elements/filters/interfaces/filter-option.interface';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { GetUserLogs, SetUserLogsFilter } from '../_store/actions/user-logs.actions';
import { UserLogsState } from '../_store/states/user-logs.state';
import { TableUserLogsInterface } from './_interface/table-user-logs.interface';
import { UsersLogsService } from '../_services/users-logs.service';
import { FilterTypePipeEnum } from '../../app-shared-elements/_enums/filter-type-pipe.enum';
import { SetFilterConfig, SetTimeObj, SetTimeType } from '../../app-shared-elements/_store/actions/time-filter.actions';
import { DisabledFilterOptionsEnum } from '../../app-shared-elements/_enums/filter-options.enum';
import { TimeFilterState } from '../../app-shared-elements/_store/states/time-filter.state';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { FilterDropdownUserLogKeyEnum } from '../_enums/filter-dropdown-key.enum';
import { TooltipStatusEnum } from '../../app-shared-elements/_enums/tooltip-status.enum';
import { NotificationsService } from '../../app-shared-elements/_services/notifications.service';
import {
    Pagination,
    Params,
    ParamsFilter,
    ParamsFilterForClient,
    ParamsSorted,
    ParamsTime,
    ParamsTimeTypeEnum,
} from 'src/app/app-shared-elements/_interfaces/params.interface';
import { ColumnsTableInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { ParamsService } from 'src/app/app-shared-elements/_services/params.service';
import { SetSkeleton } from 'src/app/app-shared-elements/_store/actions/table.actions';
import { User } from '../../app-shared-elements/_interfaces/user.interface';
import { UserState } from '../../app-shared-elements/_store/states/user.state';
import { StateReset } from 'ngxs-reset-plugin';
import { FilterItemTypeEnum } from '../../app-shared-elements/filters/enums/filter-item-type.enum';

@Component({
    selector: 'app-user-logs',
    templateUrl: './user-logs.component.html',
    styleUrls: ['./user-logs.component.scss'],
})
export class UserLogsComponent implements OnInit, OnDestroy {
    // rowsLogs: UserLogInterface[];
    private intervalLogs;
    private user: User;

    showPopup = false;

    searchValue: string;
    private currentRoute;
    filterType: FilterTypePipeEnum;

    @Select(UserLogsState.getUserLogs) rowsLogs$: Observable<TableUserLogsInterface[]>;
    @Select(UserLogsState.getParams) params$: Observable<Params>;
    @Select(UserLogsState.getDropdownFilterOptions) dropDownFilterOptions$: Observable<DropdownFilterOptionInterface[]>;
    @Select(TimeFilterState.getTimeObj) timeObj$: Observable<ParamsTime>;
    @Select(UserState.getUser) user$: Observable<User>;
    @Select(UserLogsState.getUserId) userId$: Observable<string>;

    // @Input() userId: string; // for admin

    private destroy: Subject<boolean> = new Subject<boolean>();

    defaultDropdownValue: DropdownFilterOptionInterface;
    currentValueForDropdownInput: string;

    userLogColumns: string[];
    userTablesColumns: ColumnsTableInterface[] = [];
    filterItemTypeEnum = FilterItemTypeEnum;

    constructor(
        public usersLogsService: UsersLogsService,
        private store: Store,
        private notificationService: NotificationsService,
        private translateService: TranslateService,
        private activatedRoute: ActivatedRoute,
        private paramsService: ParamsService,
    ) {
        this.store.dispatch(new SetSkeleton(true));
        this.store.dispatch(new SetTimeObj({ from: null, to: null, time: null, type: ParamsTimeTypeEnum.ALL_TIME }));
        this.store.dispatch(
            new SetFilterConfig({
                type: 'date',
                disableFunctions: [
                    DisabledFilterOptionsEnum.current,
                    DisabledFilterOptionsEnum.time,
                    DisabledFilterOptionsEnum.chartType,
                    DisabledFilterOptionsEnum.resetBtn,
                ],
            }),
        );
        this.store.dispatch(new SetTimeType(ParamsTimeTypeEnum.ALL_TIME));

        this.user$.pipe(takeUntil(this.destroy)).subscribe((user) => {
            if (!user) {
                return;
            }

            this.user = user;
        });

        this.activatedRoute.params.pipe(takeUntil(this.destroy)).subscribe((param) => {
            if (param && param.id) {
                const currentFilter = this.usersLogsService.dropDownFilterOptions.find(
                    (option) => option.key === FilterDropdownUserLogKeyEnum.srcId,
                );
                this.usersLogsService.setDropDownFilter({ ...currentFilter, filterValue: param.id });
                this.defaultDropdownValue = currentFilter;
                this.currentValueForDropdownInput = param.id;

                const params: Params = this.store.selectSnapshot(UserLogsState.getParams);
                const newFilters = (params.filter as ParamsFilter[]).map((f: ParamsFilter) => {
                    if (f.isDropdown && f.property === 'srcId') {
                        f.value = param.id;
                    }

                    return f;
                });
                this.store.dispatch(new SetUserLogsFilter({ ...params, filter: newFilters }));
            } else {
                this.usersLogsService.setDropDownFilter(null);
            }
        });
    }

    async ngOnInit(): Promise<void> {
        this.userLogColumns = JSON.parse(localStorage.getItem(`userLogColumns${this.user.id}`)) || [];
        this.userLogColumns.push('deviceName');

        this.timeObj$.pipe(takeUntil(this.destroy)).subscribe(async (timeObj) => {
            const params = this.store.selectSnapshot(UserLogsState.getParams);
            await this.store.dispatch(
                new SetUserLogsFilter({
                    ...params,
                    pagination: { ...params.pagination, currentPage: 1 },
                }),
            );
            await this.nextLogs();
        });

        this.translateService.onLangChange.pipe(takeUntil(this.destroy)).subscribe(async () => {
            await this.nextLogs();
        });

        await this.initTableColumns();

        this.currentRoute = this.activatedRoute.snapshot.params.id;

        if (this.currentRoute) {
            this.filterType = FilterTypePipeEnum.filterDeviceLogByRegistratorName;
        }
    }

    ngOnDestroy(): void {
        clearTimeout(this.intervalLogs);
        this.destroy.next(true);
        this.destroy.complete();
        this.store.dispatch(new StateReset(UserLogsState));
    }

    async onChangePageLogs(data: Pagination): Promise<any> {
        const params = this.store.selectSnapshot(UserLogsState.getParams);
        await this.store.dispatch(new SetUserLogsFilter({ ...params, pagination: data }));
    }

    async onChangeSortLogs(data: ParamsSorted[]): Promise<any> {
        const params: Params = this.store.selectSnapshot(UserLogsState.getParams);
        this.store.dispatch(new SetUserLogsFilter({ ...params, sorted: [...data] }));
    }

    async onChangeDropDownLogs(data: DropdownFilterOptionInterface): Promise<any> {
        this.usersLogsService.setDropDownFilter(data);
        await this.nextLogs();
    }

    private async nextLogs(): Promise<any> {
        clearTimeout(this.intervalLogs);
        await this.store.dispatch(new GetUserLogs()).toPromise();
        this.intervalLogs = setTimeout(async () => {
            await this.nextLogs();
        }, 20000);
    }

    openEdit(): void {
        this.showPopup = true;
    }

    dataChanged(event: ColumnsTableInterface): void {
        const index = this.userLogColumns.findIndex((e) => e === event.name);

        if (index === -1) {
            this.userLogColumns.push(event.name);
        } else {
            this.userLogColumns = this.userLogColumns.filter((f) => f !== event.name);
        }

        this.notificationService.onEmit(TooltipStatusEnum.update, false);

        this.initTableColumns();
        localStorage.setItem(`userLogColumns${this.user.id}`, JSON.stringify(this.userLogColumns));
    }

    private initTableColumns(): void {
        this.userTablesColumns = [
            ...this.usersLogsService.usersTableColumns,
            ...this.usersLogsService.userLogTableColumns.filter((f) => this.userLogColumns.includes(f.name)),
        ];
    }

    changeFilter(event: ParamsFilterForClient[]): void {
        if (!event) {
            return;
        }
        const params: Params = this.store.selectSnapshot(UserLogsState.getParams);
        const changedFilter: ParamsFilter[] = this.paramsService.parseParamsFilterForServer(event);

        this.store.dispatch(
            new SetUserLogsFilter({
                ...params,
                filter: event,
                pagination: { ...params.pagination, currentPage: 1 },
            }),
        );
    }
}

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { DisabledFilterOptionsEnum } from '../../_enums/filter-options.enum';
import { FilterTypeEnum } from '../../_enums/filter-type.enum';
import { ConfigForFilterInterface } from '../../_interfaces/config-for-filter.interface';
import { ParamsTime, ParamsTimeTypeEnum } from '../../_interfaces/params.interface';
import { CloneDeepService } from '../../_services/clone-deep.service';
import {
    SetCurrentTimeOption,
    SetDisabledTimeOptions,
    SetFilterConfig,
    SetFilterType,
    SetTimeObj,
    SetTimeType,
} from '../actions/time-filter.actions';
import { TimeOptionsInterface } from '../../_interfaces/time-options.interface';
import { LimitService } from '../../_services/limit.service';

export interface TimeFilterStateModel {
    timeObj: ParamsTime;
    timeOptions: TimeOptionsInterface[];
    currentTimeOption: TimeOptionsInterface;
    timeType: ParamsTimeTypeEnum;
    filterConfig: ConfigForFilterInterface;
    filterType: FilterTypeEnum;
    // calendarConfig: LocaleSettings;
}

const timeOptions: TimeOptionsInterface[] = [
    // {
    //     key: 1,
    //     property: 3600000 / 2,
    //     value: 'filters.periodOne',
    //     type: 'text'
    // },
    {
        key: 2,
        property: 3600000,
        value: 'filters.periodTwo',
        type: 'text',
        disabled: false,
    },
    {
        key: 3,
        property: 3600000 * 2,
        value: 'filters.periodThree',
        type: 'text',
        disabled: false,
    },
    {
        key: 4,
        property: 3600000 * 4,
        value: 'filters.periodFour',
        type: 'text',
        disabled: false,
    },
    {
        key: 5,
        property: 3600000 * 8,
        value: 'filters.periodFive',
        type: 'text',
        disabled: false,
    },
    {
        key: 6,
        property: 3600000 * 16,
        value: 'filters.periodSix',
        type: 'text',
        disabled: false,
    },
    {
        key: 7,
        property: 3600000 * 24,
        value: 'filters.periodSeven',
        type: 'text',
        disabled: false,
    },
    {
        key: 8,
        property: 3600000 * 48,
        value: 'filters.periodEight',
        type: 'text',
        disabled: false,
    },
];

const initialFilterConfig = {
    type: 'date',
    disableFunctions: [DisabledFilterOptionsEnum.allTime, DisabledFilterOptionsEnum.current],
};

const initialTimeObj: ParamsTime = {
    to: null,
    from: null,
    time: 1000 * 60 * 60 * 4,
    type: ParamsTimeTypeEnum.ALL_TIME,
};

const TIME_FILTER_TOKEN = new StateToken<TimeFilterStateModel>('timeFilterState');

// const calendarLocaleUA: LocaleSettings = {
//     firstDayOfWeek: 1,
//     dayNames: [ 'Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четверг', 'П`ятниця', 'Субота'],
//     dayNamesShort: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', ],
//     dayNamesMin: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', ],
//     monthNames: ['Січень ', 'Лютий ', 'Березень ', 'Квітень ', ' Травень ', 'Червень ', 'Липень ', 'Серпень ', 'Вересень ', 'Жовтень ', 'Листопад ', 'Грудень '],
//     monthNamesShort: ['Січ', 'Лют', 'Бер', 'Кві', ' Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру'],
//     today: 'Today',
//     clear: 'Clear'
// };
//
// const calendarLocaleEN: LocaleSettings = {
//     firstDayOfWeek: 0,
//     dayNames: ['Monday ', 'Tuesday ', 'Wednesday ', 'Thursday ', 'Friday ', 'Saturday ', 'Sunday '],
//     dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
//     dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
//     monthNames: [ 'January ', 'February ', 'March ', 'April ', 'May ', 'June ', 'July ', 'August ', 'September ', 'October ', 'November ', 'December ' ],
//     monthNamesShort: [ 'Jan ', 'Feb ', 'Mar ', 'Apr ', 'May ', 'Jun ', 'Jul ', 'Aug ', 'Sep ', 'Oct ', 'Nov ', 'Dec ' ],
//     today: 'Today',
//     clear: 'Clear'
//
// };

@State<TimeFilterStateModel>({
    name: TIME_FILTER_TOKEN,
    defaults: {
        timeObj: initialTimeObj,
        timeOptions,
        currentTimeOption: timeOptions[2],
        timeType: ParamsTimeTypeEnum.RANGE,
        filterConfig: null,
        filterType: null,
        // calendarConfig: localStorage.getItem('ln') && localStorage.getItem('ln').toLowerCase() === LangEnum.ua ? calendarLocaleUA : calendarLocaleEN
    },
})
@Injectable()
export class TimeFilterState {
    constructor(
        private cloneDeepService: CloneDeepService,
        private limitService: LimitService,
    ) {}

    @Selector([TimeFilterState])
    static getTimeObj(state: TimeFilterStateModel): ParamsTime {
        return state.timeObj;
    }

    @Selector()
    static getTimeOptions(state: TimeFilterStateModel): TimeOptionsInterface[] {
        return state.timeOptions;
    }

    @Selector()
    static getCurrentTimeOption(state: TimeFilterStateModel): TimeOptionsInterface {
        return state.currentTimeOption;
    }

    @Selector()
    static getTimeType(state: TimeFilterStateModel): ParamsTimeTypeEnum {
        return state.timeType;
    }

    @Selector()
    static getFilterConfig(state: TimeFilterStateModel): ConfigForFilterInterface {
        return state.filterConfig;
    }

    @Selector()
    static getFilterType(state: TimeFilterStateModel): FilterTypeEnum {
        return state.filterType;
    }

    @Action(SetTimeObj)
    setTimeObj(ctx: StateContext<TimeFilterStateModel>, payload: SetTimeObj): void {
        const state = ctx.getState();
        const timeObj = this.cloneDeepService.cloneObject(payload.timeObj);
        // if (typeof timeObj.from === 'string') {
        timeObj.from = new Date(timeObj.from).getTime();
        // }

        // if (typeof timeObj.to === 'string') {
        timeObj.to = new Date(timeObj.to).getTime();
        // }

        ctx.setState({
            ...state,
            timeObj: { ...payload.timeObj },
        });
    }

    @Action(SetTimeType)
    setTimeType(ctx: StateContext<TimeFilterStateModel>, payload: SetTimeType): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            timeType: payload.type,
        });
    }

    @Action(SetCurrentTimeOption)
    setCurrentTimeOption(ctx: StateContext<TimeFilterStateModel>, payload: SetCurrentTimeOption): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentTimeOption: payload.option,
        });
    }

    @Action(SetDisabledTimeOptions)
    setDisabledTimeOptions(ctx: StateContext<TimeFilterStateModel>, payload: SetDisabledTimeOptions): void {
        const state = ctx.getState();
        // console.log()
        if (!payload.changesPeriodArchive.length) {
            ctx.setState({
                ...state,
                timeOptions: timeOptions,
            });

            return;
        }
        //
        // const now = Date.now();
        // let arrayChangesByTime: VariableChangeArchive[] = payload.changesPeriodArchive.filter(
        //     (item) => item.changeTime <= now - state.timeOptions[state.timeOptions.length - 1].property,
        // );
        // const currentValueArchive: VariableChangeArchive = (payload.changesPeriodArchive as any[]).reduce((pre, item) => {
        //     return pre.changeTime > item.changeTime ? pre : item;
        // });
        //
        // if (arrayChangesByTime.length) {
        //     arrayChangesByTime = arrayChangesByTime.filter((item) => (item.id === currentValueArchive.id ? currentValueArchive : item));
        // } else {
        //     arrayChangesByTime.push(currentValueArchive);
        // }

        ctx.setState({
            ...state,
            // timeOptions: state.timeOptions,
            timeOptions: timeOptions
                .map((option) => {
                    // const arrayChanges = arrayChangesByTime.filter((item) => item.changeTime >= Date.now() - +option.property);
                    // if (!arrayChanges || !arrayChanges.length) {
                    //     arrayChanges.push(currentValueArchive);
                    // }
                    // const middleValue = arrayChanges.reduce((total, item, index, array) => {
                    //     total += +item.newValue;
                    //
                    //     if (index === array.length - 1) {
                    //         return total / array.length;
                    //     } else {
                    //         return total;
                    //     }
                    // }, 0);
                    if (this.limitService.isLimitByHours(payload.changesPeriodArchive, option.property)) {
                        return;
                    }
                    return {
                        ...option,
                    };
                })
                .filter((f) => !!f),
        });
    }

    @Action(SetFilterConfig)
    setFilterConfig(ctx: StateContext<TimeFilterStateModel>, payload: SetFilterConfig): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            filterConfig: payload.config,
        });
    }

    @Action(SetFilterType)
    setFilterType(ctx: StateContext<TimeFilterStateModel>, payload: SetFilterType): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            filterType: payload.type,
        });
    }

    // @Action(SetCalendarConfig)
    // setCalendarConfig(ctx: StateContext<TimeFilterStateModel>, payload: SetCalendarConfig): void {
    //     const state = ctx.getState();
    //     ctx.setState({
    //         ...state,
    //         calendarConfig: payload.ln === LangEnum.ua ? calendarLocaleUA : calendarLocaleEN
    //     });
    // }
}

<div *ngIf="(registrators$ | async) && (registrators$ | async).length" class="dt">
    <div class="dt__wrapper">
        <div class="dt__list">
            <div *ngFor="let registrator of (registrators$ | async); trackBy: trackById"
                 class="dt__item"
                 [class.dt__item--online]="registrator.isConnect"
                 [class.dt__item--current]="registrator.id === (currentRegistrator$ | async)?.id"
                 (click)="moveTo(registrator)">
                <div class="dt__item-icon" [ngSwitch]="registrator.status">
                    <ng-template [ngSwitchCase]="alarmTypeEnum.ok">
                        <svg-icon [src]="OK_PATH"></svg-icon>
                    </ng-template>

                    <ng-template [ngSwitchCase]="alarmTypeEnum.attention">
                        <svg-icon [src]="ATTENTION_PATH"></svg-icon>
                    </ng-template>

                    <ng-template [ngSwitchCase]="alarmTypeEnum.alarm">
                        <svg-icon [src]="ALARM_PATH"></svg-icon>
                    </ng-template>
                </div>
                <span>{{registrator.name ?? registrator.defaultName}}</span>
            </div>
        </div>
    </div>
</div>

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './_services/auth.service';
import {UsersService} from '../admin/users/_services/users.service';
import {TypeClient} from '../admin/users/_enum/type-client.enum';
import {SocketService} from '../app-shared-elements/_services/socket.service';
import {Store} from '@ngxs/store';

/**
 * Сервис, реализующий интерфейс ангуляра CanActivate,
 * используется при определении защищенных роутов.
 */
@Injectable()
export class UserAuthGuardService {
    constructor(
        private authService: AuthService,
        private userService: UsersService,
        private socketService: SocketService,
        private router: Router,
        private store: Store,
    ) {}

    async canActivate(): Promise<boolean> {
        const isAuth = await this.authService.isAuthenticated();
        // const isAdmin = this.store.selectSnapshot(UserState.getUser);

        if (!isAuth) {
            await this.router.navigate(['/login']);
            return false;
        }

        if ((await this.userService.getTypeClient()) === TypeClient.User) {
            return true;
        }

        if ((await this.userService.getTypeClient()) === TypeClient.Admin) {
            this.router.navigate(['/control/profile']);
            return;
        }
        return false;
    }
}

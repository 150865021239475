import {Injectable} from '@angular/core';
import * as cloneDeep from 'clone-deep';

@Injectable({
    providedIn: 'root'
})
export class CloneDeepService {

    constructor() { }

    cloneObject<T>(obj: T): T {
        return {...cloneDeep(obj)};
    }

    cloneArray<T>(arr: T[]): T[] {
        return [...cloneDeep(arr)];
    }
}

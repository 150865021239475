<div cdkDragRootElement=".app-devices"
     class="table__item-devices" cdkDrag
     [cdkDragDisabled]="typeClient === typeClientModel.Admin"
     cdkDragBoundary=".table__item-body"
     [class.table__item-devices--isActive]="!device.isActive"
     [class.table__item--isActive]="!device.isActive"
>
    <div class="table__item-cell table__item-cell-type table__item-cell-type--devices"
         [class.item__devices--off]="!device.isActive"
    >
        <div *ngIf="typeClient !== typeClientModel.Admin" class="table__item-drag" cdkDragHandle>
            <svg-icon *ngIf="!isShowFilter"
                      [src]="userDeviceIconEnum.drag"></svg-icon>

        </div>
        <div class="table__item-tooltip"
             [class.table__item--sleep]="device.sleepStatus && registrator.isConnect"
             [class.table__item--error]="!device.sleepStatus && !device.isConnect || !device.isConnect && !registrator.isConnect"
        >
            <ng-container [ngSwitch]="device.type">

                <ng-template [ngSwitchCase]="deviceTypeEnum.sensor">
                    <svg-icon
                        [src]="userDeviceIconEnum.sensor"></svg-icon>
                </ng-template>

                <ng-template [ngSwitchCase]="deviceTypeEnum.coordinator">
                    <svg-icon
                        [src]="userDeviceIconEnum.coordinator"></svg-icon>
                </ng-template>

                <ng-template [ngSwitchCase]="deviceTypeEnum.datalogger">
                    <svg-icon
                        [src]="userDeviceIconEnum.datalogger"></svg-icon>
                </ng-template>

            </ng-container>
            <div class="table__tooltip">
                <app-tooltip [template]="true">
                    <span [ngSwitch]="device.type">
                        <ng-template
                            [ngSwitchCase]="deviceTypeEnum.sensor">{{ 'devices.tooltipTemperature' | translate }}</ng-template>
                        <ng-template
                            [ngSwitchCase]="deviceTypeEnum.coordinator">{{ 'devices.tooltipCoordinator' | translate }}</ng-template>
                        <ng-template
                            [ngSwitchCase]="deviceTypeEnum.datalogger">{{ 'devices.tooltipDatalogger' | translate }}</ng-template>
                    </span>
                </app-tooltip>
            </div>
        </div>
    </div>

    <div class="table__item-cell table__item-cell-name"
         [class.table__item-cell-name--isActive]="!device.isActive"
    >
        <span (click)="openDevice(device)">{{ device.name }}</span>

        <div (click)="parseAction({title: '', action: actionListItemEnum.detail}, device)"
             class="table__item-icon">
            <ng-container *ngIf="device.savedStatusEvent === eventSaveStatus.PROCESS && +device.errorStatusEvent >= 0 || device.savedStatusDevice">
                <svg-icon [src]="WAIT_ICON_PATH"></svg-icon>
                <div class="table__tooltip">
                    <app-tooltip [template]="true">

                        <ng-container *ngIf="device.savedStatusDevice && !device.savedStatusEvent">
                            <span [translate]="'devices.tooltipWaitStatusDevice'"></span>
                        </ng-container>
                        <ng-container *ngIf="device.savedStatusEvent && !device.savedStatusDevice">
                            <span [translate]="'devices.tooltipWaitStatusEvent'"></span>
                        </ng-container>
                        <ng-container *ngIf="device.savedStatusEvent && device.savedStatusDevice">
                            <span [translate]="'devices.tooltipAllStatusEvent'"></span>
                        </ng-container>
                    </app-tooltip>
                </div>
            </ng-container>

            <ng-container *ngIf="device.savedStatusEvent === eventSaveStatus.ERROR || +device.errorStatusEvent < 0 || device.errorStatusDevice">
                <svg-icon [src]="ERROR_ICON_PATH"></svg-icon>

                <div class="table__tooltip">
                    <app-tooltip [template]="true">

                        <ng-container *ngIf="device.errorStatusDevice && !device.errorStatusEvent">
                            <span [translate]="'devices.tooltipErrorStatusDevice'"></span>
                        </ng-container>
                        <ng-container *ngIf="device.errorStatusEvent && !device.errorStatusDevice || +device.errorStatusEvent < 0 && !device.errorStatusDevice ">
                            <span [translate]="'devices.tooltipErrorStatusEvent'"></span>
                        </ng-container>
                        <ng-container *ngIf="device.errorStatusDevice && device.errorStatusEvent">
                            <span [translate]="'devices.tooltipAllStatusDevice'"></span>
                        </ng-container>
                    </app-tooltip>
                </div>
            </ng-container>
        </div>

        <div *ngIf="device.isDeleted && !registrator.isDeleted && typeClient === typeClientModel.Admin"
             class="table__name-settings">

            <div class="table__name-deleted">

                <div class="table__name-item">
                    <svg-icon [src]="userDeviceIconEnum.deleted"></svg-icon>

                    <span [translate]="'devices.deviceDeleted'"></span>
                </div>

                <div class="table__deleted-tooltip">
                    <app-tooltip [template]="true">

                    <span [translate]="'devices.deviceDeletedTooltip'"
                          [translateParams]="{date: device.deleted | luxonDate: (user$ | async).dateFormat || dataTypeService.dateFormat
        : (gmt$ | async) || dataTypeService.GMT
        : (ln$ | async)}"></span>

                    </app-tooltip>
                </div>
            </div>

        </div>
    </div>

    <div (click)="openCurrentUser(device)"
         class="table__item-cell table__item-cell-owner"
         [class.table__item-cell-owner--pointer]="(isAdmin$ | async) && router.url.includes('control/devices')"
    >
        <div class="table__item-content"
             [class.table__item--isActive]="!device.isActive"
        >
            <span *ngIf="(user$ | async).login === device.login" [translate]="'profile.me'"></span>
            <span *ngIf="(user$ | async).login !== device.login">
                                        {{ device.login.split('@')[0] + '@' }}</span>
            <div class="table__tooltip">
                <app-tooltip [template]="true" [width]="'auto'" [maxWidth]="'initial'">
                    {{ device.login }}
                </app-tooltip>
            </div>
        </div>
    </div>

    <div class="table__item-cell table__item-cell-connect"
         [class.table__item-cell-connect--isActive]="!device.isActive"
         [class.table__item-cell-connect--off]="!device.isConnect"
         [class.table__item-cell-connect--on]="device.isConnect"
         [class.table__item-cell-connect--sleep]="registrator.isConnect && device.sleepStatus"
    >

        <ng-container
            *ngIf="registrator.isConnect && device.sleepStatus; else active">
            sleep
        </ng-container>

        <ng-template #active>
            <ng-container *ngIf="!device.isActive; else connect"
            >Na
            </ng-container>
            <ng-template #connect>
                <span [translate]="device.isConnect ? 'online' : 'offline'"></span>
            </ng-template>
        </ng-template>
    </div>

    <div class="table__item-cell table__item-cell-signal"
         [class.table__item-cell-signal--isActive]="!device.isActive"
         [class.table__item-cell-signal--ok]="device.signal <= 0 && device.signal >= -60"
         [class.table__item-cell-signal--attention]="device.signal < -60 && device.signal >= -75"
         [class.table__item-cell-signal--alarm]="device.signal < -75"
         [class.table__item-cell-signal--off]="device.sleepStatus || !device.isActive || !device.isConnect"
    >

        <ng-container
            *ngIf="(device.sleepStatus && !registrator.isConnect) || !device.sleepStatus && (!device.isActive || !device.isConnect); else signal">
            Na
        </ng-container>
        <ng-template #signal>
            <span [ngSwitch]="device.signal">
                <ng-template [ngSwitchCase]="'-'">{{ device.signal }}</ng-template>
                <ng-template [ngSwitchCase]="'Na'">{{ device.signal }}</ng-template>
                <ng-template ngSwitchDefault>
                    <span>{{ device.signal }}</span>
                    <span [translate]="'devices.signalStatus'"></span>
                </ng-template>
            </span>
        </ng-template>
    </div>

    <div class="table__item-cell table__item-cell-battery"
         [class.table__item-cell-battery--isActive]="!device.isActive"
         [class.table__item-cell-battery--ok]="device.battery > 30"
         [class.table__item-cell-battery--attention]="device.battery <= 30 && device.battery > 15"
         [class.table__item-cell-battery--alarm]="device.battery <= 15 && device.battery === null"
         [class.table__item-cell-battery--off]="device.sleepStatus || !device.isActive || !device.isConnect"
    >
        <ng-container
            *ngIf="(device.sleepStatus && !registrator.isConnect) || !device.sleepStatus && (!device.isActive || !device.isConnect); else battery">
            Na
        </ng-container>
        <ng-template #battery>
                                            <span [ngSwitch]="device.battery">
                                                <ng-template [ngSwitchCase]="'-'">{{ device.battery }}</ng-template>
                                                <ng-template [ngSwitchCase]="'Na'">{{ device.battery }}</ng-template>
                                                <ng-template ngSwitchDefault>{{ device.battery + '%' }}</ng-template>
                                            </span>
        </ng-template>
    </div>

    <div class="table__item-cell table__item-cell-status"
         [class.table__item-cell-status--isActive]="!device.sleepStatus && !device.isActive"
         [class.table__item-cell-status--alarm]="device.status === alarmTypeEnum.alarm"
         [class.table__item-cell-status--attention]="device.status === alarmTypeEnum.attention"
         [class.table__item-cell-status--ok]="device.status === alarmTypeEnum.ok"
         [class.table__item-cell-status--initialization]="device.status === alarmTypeEnum.initialization
                                                || !device.sleepStatus && (!device.isActive || !device.isConnect)"
         [class.table__item-cell-status--na]="(device.sleepStatus && !registrator.isConnect) && !device.isConnect || !device.isActive"
         (click)="moveToSmth(device)"
    >
        <ng-container [ngSwitch]="device.status">
            <ng-template [ngSwitchCase]="alarmTypeEnum.ok">
                {{ 'events.configurator.ok' | translate }}
            </ng-template>
            <ng-template [ngSwitchCase]="alarmTypeEnum.alarm">
                {{ 'events.configurator.alert' | translate }}
            </ng-template>
            <ng-template [ngSwitchCase]="alarmTypeEnum.attention">
                {{ 'events.configurator.attention' | translate }}
            </ng-template>
            <ng-template [ngSwitchCase]="alarmTypeEnum.initialization">
                <div class="table__spinner"></div>
            </ng-template>
        </ng-container>
    </div>

    <div class="table__item-cell table__item-cell-lastAct"
         [class.table__item-cell-lastAct--isActive]="!device.isActive">

        {{
            device.lastActive | luxonDate: (user$ | async).dateFormat || dataTypeService.dateFormat
                : (gmt$ | async) || dataTypeService.GMT
                : (ln$ | async)
        }}
    </div>

    <div *ngIf="device.listAction"
         class="table__item-cell table__item-cell-settings"
         (click)="device.isAction = !device.isAction"
         appClickOutside
         [isActiveClickOutside]="device.isAction"
         (clickOutside)="device.isAction = false">
        <svg-icon [src]="userDeviceIconEnum.setting"></svg-icon>

        <div *ngIf="device.isAction" class="table__settings">
            <app-actions-list [list]="device.listAction"
                              (actionEmitter)="parseAction($event, device)"></app-actions-list>
        </div>
    </div>

</div>

import { ErrorPopupService } from './app-shared-elements/_services/error-popup.service';
import { Observable, Subject } from 'rxjs';
import { BlockUiService } from './app-shared-elements/_services/auto-block-ui.service';
import { UsersService } from './admin/users/_services/users.service';
import { AfterContentInit, AfterViewInit, Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from './auth/_services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { NotificationsService } from './app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from './app-shared-elements/_enums/tooltip-status.enum';
import { ConnectEnum } from './app-shared-elements/_enums/connect.enum';
import { SocketService } from './app-shared-elements/_services/socket.service';
import { Select, Store } from '@ngxs/store';
import { GetRolePermissionsForAdmin, GetRolePermissionsForUser } from './app-shared-elements/_store/actions/permissions.actions';
import { TypeClient } from './admin/users/_enum/type-client.enum';
import { DeviceLogsService } from './journals/_services/device-logs.service';
import { GetActiveEventsFromSocket } from './app-shared-elements/_store/actions/active-events.actions';
import { SocketTypeLogDevice } from './device-dashboard/user-devices/_enum/action-log-device.enum';
import { GetConfigurationServer, GetDescribesConfigurationErrors } from './app-shared-elements/_store/actions/configuration.actions';
import { first, takeUntil } from 'rxjs/operators';
import { NoteNotificationState } from './app-shared-elements/_store/states/note-notification.state';
import { AddNote, RemoveNote } from './app-shared-elements/_store/actions/note-notification.actions';
import { LanguageState } from './app-shared-elements/_store/states/language.state';
import { LangEnum } from './app-shared-elements/_enums/lang.enum';
import { AuthState } from './auth/_store/states/auth.state';
import { ActivatedRoute, Router } from '@angular/router';
import { ExpiredPasswordState } from './app-shared-elements/_store/states/expired-password.state';
import { SetActiveDeviceLogsFromSocket } from './journals/_store/actions/active-device-event.actions';
import { NoteInfoInterface } from './app-shared-elements/_interfaces/note-info.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InitXSRFProtected, SetUserToken } from './auth/_store/actions/auth.actions';
import { ActiveEventsService } from './events/_services/active-events.service';
import { UserState } from './app-shared-elements/_store/states/user.state';
import { User } from './app-shared-elements/_interfaces/user.interface';
import { ProfileService } from './profile/_services/profile.service';
import { GetAllDevices } from './admin/admin-devices/_store/actions/admin-devices.actions';
import { PrimeNGConfig } from 'primeng/api';
import { SocketEvent } from './app-shared-elements/_enums/socket-event.enum';
import { SplashMessageInterface } from './app-shared-elements/_interfaces/splash-message.interface';
import { PermissionsState } from './app-shared-elements/_store/states/permissions.state';
import { ResourceAction } from './app-shared-elements/_enums/permission.enum';
import { RolesService } from './admin/roles/_services/roles.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentInit {
    reportsMode = false;

    showNote = false;

    hideTimeout: any;

    isFreeze = false;
    isHideBlockModal = false;

    @Select(NoteNotificationState.getNotes) notes$: Observable<NoteInfoInterface[]>;
    @Select(LanguageState.getLanguage) ln$: Observable<LangEnum>;
    @Select(ExpiredPasswordState.getExpiredPopup) expiredPopup$: Observable<boolean>;
    @Select(UserState.getUser) user$: Observable<User>;

    @ViewChild('app') app: ElementRef;

    destroy: Subject<boolean> = new Subject<boolean>();

    private currentVersionApp: string;
    private timeoutUpdateVersion: NodeJS.Timeout;
    isNewAppVersion: boolean;

    constructor(
        private authService: AuthService,
        public userService: UsersService,
        public blockService: BlockUiService,
        private profileService: ProfileService,
        private translateService: TranslateService,
        private socketService: SocketService,
        private notificationService: NotificationsService,
        private activeEventsService: ActiveEventsService,
        private errorPopupService: ErrorPopupService,
        private ngZone: NgZone,
        private store: Store,
        private http: HttpClient,
        private router: Router,
        private deviceLogsService: DeviceLogsService,
        private route: ActivatedRoute,
        private config: PrimeNGConfig,
    ) {
        this.user$.pipe(first((u) => !!u)).subscribe((user) => {
            this.profileService.userDataSubs();
        });
    }

    async ngOnInit(): Promise<void> {
        await this.checkUpdate();
        await this.store.dispatch(new InitXSRFProtected()).toPromise();
        this.reportsMode = window.location.search.search('reports=true') !== -1;

        if (this.reportsMode) {
            document.querySelector('.root-preloader').remove();
            document.querySelector('body').classList.remove('remove-scroll-bar');
        } else {
            this.socketService.connect();
            this.socketService.init();
        }

        this.initTranslate();
        this.initNote();
        this.destroyNote();

        if (localStorage.getItem('access_token')) {
            this.store.dispatch(new SetUserToken(localStorage.getItem('access_token')));
        }

        this.activeEventsService.initActiveEvents();
        this.deviceLogsService.initActiveDeviceLogs();
        this.socketService.logoutSubscribe.pipe(takeUntil(this.destroy)).subscribe(() => {
            this.store.dispatch(new GetActiveEventsFromSocket([]));
            this.activeEventsService.activeEvents = [];
            this.store.dispatch(new SetActiveDeviceLogsFromSocket({ type: SocketTypeLogDevice.ALL, logs: [] }));
        });
        await this.store.dispatch(new GetConfigurationServer()).toPromise();

        if (await this.authService.isAuthenticated()) {
            const isAdmin = this.userService.getTypeClient() === TypeClient.Admin;

            if (isAdmin) {
                await this.store.dispatch(new GetRolePermissionsForAdmin()).toPromise();
                const currentRole = this.store.selectSnapshot(PermissionsState.getRoles).find((f) => f.id === this.store.selectSnapshot(UserState.getUser).roleId);
                const currentDeviceRole = currentRole?.permissions?.find((f) => f.resource === ResourceAction.DEVICES);
                if ((currentDeviceRole?.isRead && !this.router.url.includes('invoice-server')) ?? (currentRole.name === RolesService.rootRole && !this.router.url.includes('invoice-server'))) {
                    await this.store.dispatch(new GetAllDevices()).toPromise();
                }
            }

            if (!isAdmin) {
                await this.store.dispatch(new GetRolePermissionsForUser()).toPromise();
            }

            this.profileService.userDataSubs();
        }

        let message;
        this.socketService.errorConnectSubscribe.pipe(takeUntil(this.destroy)).subscribe(async (status: ConnectEnum) => {
            switch (status) {
                case ConnectEnum.ERROR_CONNECT:
                    this.notificationService.onEmit(TooltipStatusEnum.error, false, 'disconnectBlock.serverLost');
                    console.log('create block disc');
                    message = this.socketService.isFirstConnect() ? 'disconnectBlock.infoUnavailableServer' : 'disconnectBlock.info';

                    await this.errorPopupService.initErrorPopup('disconnectBlock.title', message);
                    break;

                case ConnectEnum.RECONNECT_AFTER_ERROR_CONNECT:
                    this.socketService.emit('initDataAfterConnect', this.store.selectSnapshot(AuthState.getUserToken));
                    this.errorPopupService.killErrorPopup();
                    this.socketService.initDataAfterConnect();
                    break;

                case ConnectEnum.RECONNECTED:
                    this.notificationService.onEmit(TooltipStatusEnum.update, false, 'disconnectBlock.serverRestore');
                    this.socketService.initDataAfterConnect();
                    this.errorPopupService.killErrorPopup();
                    break;

                case ConnectEnum.FIRST_CONNECT:
                    this.socketService.initDataAfterConnect();
                    break;
            }
        });
        this.store.dispatch(new GetDescribesConfigurationErrors());
        this.initSplashMessage();
    }

    initNote(): void {
        this.notificationService.noteEmit.pipe(takeUntil(this.destroy)).subscribe((data: NoteInfoInterface) => {
            if (!data) {
                this.showNote = false;
            }
            this.store.dispatch(new AddNote(data));

            clearTimeout(this.hideTimeout);
            if (!data.action) {
                this.ngZone.runOutsideAngular(() => {
                    this.hideTimeout = setTimeout(() => {
                        this.store.dispatch(new RemoveNote());
                    }, 3000);
                });
            }
        });
    }

    destroyNote(event?: NoteInfoInterface): void {
        this.store.dispatch(new RemoveNote(event));
    }

    initSplashMessage(): void {
        this.socketService.fromEvent(SocketEvent.SPLASH_MESSAGE).subscribe((data: SplashMessageInterface) => {
            this.translateService.get(`splashMessage.${data.translateKey}`, data.data).subscribe((str) => {
                this.notificationService.onEmit(TooltipStatusEnum.error, true, str);
            });
        });
    }

    initTranslate(): void {
        const lnStore = this.store.selectSnapshot(UserState.getUser)?.lang || localStorage.getItem('ln') || 'en';
        lnStore ? this.translateService.use(lnStore.toLowerCase()) : this.translateService.use(environment.defaultLocale);
        this.translateService.get('primeng').subscribe((res) => this.config.setTranslation(res));
    }

    async checkUpdate(): Promise<void> {
        const version = await this.getVersionApp();
        // const versionCache = await this.getVersionCacheApp();

        if (!this.currentVersionApp && version) {
            this.currentVersionApp = version;
        }

        if (version && this.currentVersionApp !== version /*|| version !== versionCache*/) {
            // popup
            this.isNewAppVersion = true;
            return;
        }

        this.timeoutUpdateVersion = setTimeout(async () => {
            await this.checkUpdate();
        }, 30000);
    }

    async getVersionApp(): Promise<string> {
        const headers = new HttpHeaders({ 'cache-control': 'no-cache' });

        return (
            await this.http
                .get<any>('assets/version.json', {
                    params: { t: Date.now() },
                    headers,
                })
                .toPromise()
        )?.version;
    }

    ngOnDestroy(): void {
        clearTimeout(this.timeoutUpdateVersion);

        this.profileService.userDataRemoveSubs();

        this.destroy.next(true);
        this.destroy.complete();
    }

    @HostListener('window:mousedown', ['$event'])
    userMouseActivityIntercept($event: any): void {
        this.blockService.doAction();
    }

    @HostListener('window:keypress', ['$event'])
    userKeyboardActivityIntercept($event: any): void {
        this.blockService.doAction();
    }

    @HostListener('click', ['$event'])
    userClickActivityIntercept($event: any): void {
        this.blockService.doAction();
    }

    @HostListener('mousedown', ['$event'])
    clickOutside(e: any): void {
        this.isHideBlockModal = false;
        const freezePopup = document.querySelector('#freeze-block');

        if (!freezePopup && this.blockService.isShowModal) {
            this.socketService.logoutSubscribe.emit();
        }

        if ((freezePopup && !freezePopup.contains(e.target) && this.blockService.isShowModal) || (!freezePopup && this.blockService.isShowModal)) {
            this.app.nativeElement.style.pointerEvents = 'none';
            e.preventDefault();
            e.stopPropagation();
        } else {
            this.app.nativeElement.style.pointerEvents = 'all';
        }
    }

    async ngAfterViewInit(): Promise<void> {}

    ngAfterContentInit(): void {}

    reloadPage(): void {
        location.reload();
    }
}

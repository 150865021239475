import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { EventModeEnum, LogicEvent } from '../../logical-events/_interface/LogicEvent';
import { ActiveEvents } from '../../_interfaces/ActiveEvents';
import { EventMessage } from '../../_interfaces/EventMessage';
import { EventSaveFromSocketInterface } from '../../_interfaces/EventSaveFromSocketDto';
import { ExpressionInterface } from '../../_interfaces/Expression';
import { LogicEventsChangeActiveDto } from '../../_interfaces/LogicEventsChangeActiveDto';
import { Params } from '../../../app-shared-elements/_interfaces/params.interface';
import { LogicalEventRowInterface } from '../../_interfaces/LogicalEventRow';
import { UpdateLogicEventSocketInterface } from '../../_interfaces/update-logic-event-socket.interface';

export class GetLogicalEvents {
    static readonly type = '[LogicalEvents] GetLogicalEvents';

    constructor(public ids: string[]) {}
}

export class GetLogicalEvent {
    static readonly type = '[LogicalEvents] GetLogicalEvent';

    constructor(public eventId: string) {}
}

export class DeleteLogicalEvents {
    static readonly type = '[LogicalEvents] DeleteLogicalEvents';

    constructor(public ids: string[]) {}
}

export class UpdateStatusRows {
    static readonly type = '[LogicalEvents] UpdateStatusRows';

    constructor(public data: ActiveEvents[]) {}
}

export class ChangeActivitiesEvents {
    static readonly type = '[LogicalEvents] ChangeActivitiesEvents';

    constructor(public data: LogicEventsChangeActiveDto) {}
}

export class UpdateEvent {
    static readonly type = '[LogicalEvents] UpdateLogicalEvent';

    constructor(public event: LogicEvent) {}
}

export class UpdateLogicEventFromSocket {
    static readonly type = '[LogicalEvents] UpdateLogicEventFromSocket';

    constructor(public data: UpdateLogicEventSocketInterface) {}
}

export class CopyLogicalEvent {
    static readonly type = '[LogicalEvents] CopyLogicalEvent';

    constructor(public eventId: string) {}
}

export class CreateEvent {
    static readonly type = '[LogicalEvents] CreateLogicalEvent';

    constructor(public event: LogicEvent) {}
}

export class SetCurrentLogicEvent {
    static readonly type = '[LogicalEvents] SetCurrentLogicEvent';

    constructor(public id: string) {}
}

export class SetLogicEvent {
    static readonly type = '[LogicalEvents] SetLogicEvent';

    constructor(public event: LogicEvent) {}
}

export class UpdateCurrentLogicEvent {
    static readonly type = '[LogicalEvents] UpdateCurrentLogicEvent';

    constructor(public logicEvent: LogicEvent) {}
}

export class SetSuccessUpdateEvent {
    static readonly type = '[LogicalEvents] SettSuccessUpdateEvent';

    constructor(public value: boolean) {}
}

export class InitAllVariableOption {
    static readonly type = '[LogicalEvents] InitAllVariableOption';

    constructor(public devices: Device[]) {}
}

export class SetErrorExpressionMessage {
    static readonly type = '[LogicalEvents] SetErrorExpressionMessage';

    constructor(public message: any) {}
}

export class SetFilterVariablesByDevice {
    static readonly type = '[LogicalEvents] SetFilterVariablesByDevice';

    constructor(public deviceId: string) {}
}

export class ValidExpression {
    static readonly type = '[LogicalEvents] ValidExpression';

    constructor(public expression?: ExpressionInterface) {}
}

export class ChangeSavedStatusEvent {
    static readonly type = '[LogicalEvents] ChangeSavedStatusEvent';

    constructor(public data: EventSaveFromSocketInterface) {}
}

export class GetSystemDelay {
    static readonly type = '[LogicalEvents] GetSystemDelay';

    constructor(public registratorId: string) {}
}

export class InitMessageOptions {
    static readonly type = '[LogicalEvents] InitMessageOptions';

    constructor(public messages: EventMessage[]) {}
}

export class SetLogicalEventParams {
    static readonly type = '[LogicalEvents] SetLogicalEventParams';

    constructor(public params: Params) {}
}

export class SetEventMode {
    static readonly type = '[LogicalEvents] SetEventMode';

    constructor(public eventMode: EventModeEnum) {}
}

export class DeleteMultiEvent {
    static readonly type = '[LogicalEvents] DeleteMultiEvent';

    constructor(public row: LogicalEventRowInterface) {}
}

export class InitLogicalEventsRows {
    static readonly type = '[LogicalEvents] InitLogicalEventsRows';
}

export class ChangeActivityMultiEvent {
    static readonly type = '[LogicalEvents] ChangeActivityMultiEvent';

    constructor(
        public row: LogicalEventRowInterface,
        public isActive: boolean,
    ) {}
}

export class UpdateLogicalEventsRows {
    static readonly type = '[LogicalEvents] UpdateLogicalEventsRows';

    constructor() {}
}

export class ToggleLogicalEventsAccordion {
    static readonly type = '[LogicalEvents] ToggleLogicalEventsAccordion';

    constructor(public childIds: string[]) {}
}

export class SetAlarmExpressionString {
    static readonly type = '[LogicalEvents] SetAlarmExpressionString';

    constructor(public alarm: string) {}
}

export class SetAttentionExpressionString {
    static readonly type = '[LogicalEvents] SetAttentionExpressionString';

    constructor(public attention: string) {}
}

// export class ClearMultiEvent {
//     static readonly type = '[LogicalEvents] ClearMultiEvent';
// }

import {EventsFiltersNamesEnum} from 'src/app/journals/_enums/name-filters.enum';

export interface Params {
    readonly pagination: ParamsPagination | Pagination;
    readonly filter: ParamsFilter[] | ParamsFilterForClient[];
    // timeFilter: ParamsTime;
    readonly sorted: ParamsSorted[];
}

export interface ParamsPagination {
    itemsPerPage: number;
    currentPage: number;
}

export interface Pagination extends ParamsPagination {
    totalItems: number;
}

export interface ParamsFilter {
    property: string;
    value: string | number | boolean | ParamsTime;
    type: ParamsFilterTypeEnum;
    relationTrue?: ParamsFilter[];
    relationFalse?: ParamsFilter[];
    isDropdown?: boolean;
    isRadio?: boolean;
    isBorder?: boolean;
}

export interface ParamsFilterForClient extends ParamsFilter {
    title?: string;
    name?: EventsFiltersNamesEnum;
    isArray?: boolean;
}

export interface ParamsSorted {
    order: ParamsSortedOrderEnum;
    property: string;
}

export interface ParamsTime {
    to?: number;
    from?: number;
    time?: number;
    type: ParamsTimeTypeEnum;
}

export enum ParamsTimeTypeEnum {
    ALL_TIME = 'all-time',
    RANGE = 'range',
    // OFFSET = 'offset'
    // fo client
    TIME = 'time'
}


export enum ParamsSortedOrderEnum {
    ASC = 'ASC',
    DESC = 'DESC',
    FALSE = 'false'
}

export enum ParamsFilterTypeEnum {
    TEXT = 'text',
    DATETIME = 'datetime',
    BOOLEAN = 'boolean',
    NULLABLE = 'nullable'
}

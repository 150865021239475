import { Injectable } from '@angular/core';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { ColumnsTableInterface, ColumnTypeEnum } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { ExpeditionInterface } from '../_interfaces/expedition.interface';

@Injectable({
    providedIn: 'root',
})
export class ExpeditionsService {
    public columns: ColumnsTableInterface[] = [
        {
            title: 'expeditions.table.start',
            maxWidth: '200px',
            minWidth: '200px',
            type: ColumnTypeEnum.date,
            name: 'start',
        },
        {
            title: 'expeditions.table.finish',
            maxWidth: '200px',
            minWidth: '200px',
            type: ColumnTypeEnum.date,
            name: 'finish',
        },
        {
            title: 'expeditions.table.registrator',
            grow: true,
            type: ColumnTypeEnum.text,
            name: 'registrator',
        },
        {
            title: 'events.logicalEvents.table.actions',
            grow: false,
            small: true,
            maxWidth: '96px',
            minWidth: '96px',
            type: ColumnTypeEnum.action,
            name: 'edit',
            actionBtns: [ColumnsActionTypeEnum.actionBtnsSend],
        },
    ];

    constructor() {}

    getDisabledAction(expedition: ExpeditionInterface): ColumnsActionTypeEnum[] {
        return expedition && expedition.finish ? [] : [ColumnsActionTypeEnum.actionBtnsSend];
    }
}

import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Device } from '../../_interfaces/Device';

@Component({
    selector: 'app-arch-tooltip',
    templateUrl: './arch-tooltip.component.html',
    styleUrls: ['./arch-tooltip.component.scss'],
})
export class ArchTooltipComponent implements OnDestroy {
    @Input() devices: Device[];

    destroy: Subject<boolean> = new Subject<boolean>();

    constructor() {}

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }
}

export enum ReportGenerateStatus{
    DOWNLOAD_REPORT = 8,
    SIGN_REPORT = 7,
    CREATE_REPORT = 6,
    RENDER_REPORT = 5,
    PROCESS_QUERY = 4,
    CREATING_ENVIRONMENT = 3,
    START_GENERATE = 2,
    DATA_PREPARATION = 1,
    SUCCESS = 0,
    TOO_LONG_CLIENT_ERROR = -1,
    TOO_LONG_GENERATE_PDF_ERROR = -2,
    CANCEL = -5,
    CLIENT_ERROR = -3,
    EMAIL_SEND_ERROR = -4,
    UNKNOWN_ERROR = -500
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomInputItem } from '../_interfaces/custom-input-item.interface';

@Component({
    selector: 'app-custom-input',
    templateUrl: './custom-input.component.html',
    styleUrls: ['./custom-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomInputComponent implements OnInit {
    @Input() items: CustomInputItem[];
    @Input() placeholder: string;
    @Input() isDisabled: boolean;
    @Input() isStartInput: boolean;
    @Input() value: string;

    @Output() valueEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {}

    inputValue(value: string): void {
        this.valueEvent.emit(value);
        this.isStartInput = false;
    }

    selectItem(item: CustomInputItem, event?: boolean): void {
        this.items = this.items.map((select) => {
            if (item.value === select.value) {
                return {
                    ...select,
                    isSelected: event,
                };
            }

            return select;
        });
        this.value = item.value;
        // this.isStartInput = false;
        this.valueEvent.emit({ value: item.value, event });
    }
}

import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken, Store} from '@ngxs/store';
import {HTTP_STATUS} from 'src/app/app-shared-elements/_enums/status.enum';
import {TooltipStatusEnum} from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import {ApiResponse} from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import {NotificationsService} from 'src/app/app-shared-elements/_services/notifications.service';
import {EventSaveStatus} from '../../_enums/event-save-state.enum';
import {EventMessage} from '../../_interfaces/EventMessage';
import {EventSaveFromSocketTypeEnum} from '../../_interfaces/EventSaveFromSocketDto';
import {UpdateMessageDto} from '../../_interfaces/LogicMessagesDto';
import {ChangeSavedStatusMessage, CreateLogicalMessage, DeleteLogicalMessage, GetAllMessages, GetLogicalMessagesByRegistrator, SetEditingMessage, SetMessageParams, UpdateLogicalMessage} from '../actions/messages-catalog.actions';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DevicesState} from '../../../device-dashboard/_store/states/user-devices.state';

import {Pagination, Params, ParamsFilterTypeEnum} from '../../../app-shared-elements/_interfaces/params.interface';
import {MessagesService} from '../../_services/messages.service';
import {Device} from 'src/app/app-shared-elements/_interfaces/Device';
import {TranslateService} from '@ngx-translate/core';

export interface MessageCatalogStateModel {
    logicalMessages: EventMessage[];
    editingMessage: EventMessage;
    params: Params;
}

const LOGICAL_MESSAGES_EVENTS_TOKEN = new StateToken<MessageCatalogStateModel>('logicalMessages');

const initialPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null
};

const initialParams: Params = {
    pagination: initialPagination,
    sorted: null,
    filter: [
        {
            property: 'name',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true
        },
        {
            property: 'registratorId',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true
        }
    ]
};

@State<MessageCatalogStateModel>({
    name: LOGICAL_MESSAGES_EVENTS_TOKEN,
    defaults: {
        logicalMessages: [],
        editingMessage: null,
        params: initialParams
    }
})
@Injectable()
export class LogicalMessagesState {
    constructor(
        private notificationsService: NotificationsService,
        private http: HttpClient,
        private store: Store,
        private messagesService: MessagesService,
        private translateService: TranslateService
    ) {

    }

    @Selector()
    static getLogicalMessages(state: MessageCatalogStateModel): EventMessage[] {
        return state.logicalMessages;
    }

    @Selector()
    static getEditingMessage(state: MessageCatalogStateModel): EventMessage {
        return state.editingMessage;
    }

    @Selector()
    static getParams(state: MessageCatalogStateModel): Params {
        return state.params;
    }

    @Action(GetAllMessages)
    async getAllLogicalMessages(ctx: StateContext<MessageCatalogStateModel>, payload: GetAllMessages): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = await this.http.get(`api/logic-messages`).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                logicalMessages: (result.data as EventMessage[]).map(message => {
                    const currentRegistrator = this.store.selectSnapshot(DevicesState.getRegistrators).find(r => r.id === message.registratorId);
                    return {
                        ...message,
                        registratorName: currentRegistrator ? currentRegistrator.name ?? currentRegistrator.defaultName : '',
                        preIcons: this.messagesService.getPreIcons(message),
                        postIcons: this.messagesService.getPostIcons(message),

                    };
                }).sort((a, b) => new Date(b.created).getTime() > new Date(a.created).getTime() ? 1 : -1),
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.length
                    }
                }
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }

    }

    @Action(GetLogicalMessagesByRegistrator)
    async getLogicalMessagesByRegistrator(ctx: StateContext<MessageCatalogStateModel>, payload: GetLogicalMessagesByRegistrator): Promise<void> {
        const state = ctx.getState();
        const headers = new HttpHeaders({registratorId: payload.registratorId || ''});
        const result: ApiResponse = await this.http.get(`api/logic-messages/${payload.registratorId}`, {headers}).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                logicalMessages: result.data.map(message => {
                    const currentRegistrator = this.store.selectSnapshot(DevicesState.getRegistrators).find(r => r.id === message.registratorId);
                    return {
                        ...message,
                        registratorName: currentRegistrator ? currentRegistrator.name ?? currentRegistrator.defaultName : '',
                        preIcons: this.messagesService.getPreIcons(message),
                        postIcons: this.messagesService.getPostIcons(message),

                    };
                })
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
            ctx.setState({
                ...state,
                logicalMessages: []
            });
        }
    }

    @Action(CreateLogicalMessage)
    async createLogicalMessage(ctx: StateContext<MessageCatalogStateModel>, payload: CreateLogicalMessage): Promise<void> {
        const headers = new HttpHeaders(payload.copyMessage.registratorId);
        const result: ApiResponse = await this.http.post('api/logic-messages', {...payload.copyMessage}, {headers})
            .toPromise().catch(e => console.log(e)) as ApiResponse;
        const state = ctx.getState();
        const registrators = this.store.selectSnapshot(DevicesState.getRegistrators);

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            const currentRegistrator = registrators.find(r => r.id === result.data.registratorId);
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                logicalMessages: [
                    {
                        ...result.data,
                        registratorName: currentRegistrator ? currentRegistrator.name ?? currentRegistrator.defaultName : '',
                        preIcons: this.messagesService.getPreIcons(result.data),
                        postIcons: this.messagesService.getPostIcons(result.data),
                    },
                    ...state.logicalMessages,
                ],
            });
        }
    }

    @Action(DeleteLogicalMessage)
    async deleteLogicalMessage(ctx: StateContext<MessageCatalogStateModel>, payload: DeleteLogicalMessage): Promise<void> {
        const state = ctx.getState();
        const messages: EventMessage[] = this.store.selectSnapshot(LogicalMessagesState.getLogicalMessages);

        const currentMessages: EventMessage[] = messages.filter(message => payload.ids.includes(message.id));

        const registratorIds: string[] = currentMessages.map(message => message.registratorId);

        const headers = new HttpHeaders({registratorId: registratorIds ? registratorIds.join(',') : ''});

        const result: ApiResponse = await this.http.delete(`api/logic-messages`, {params: {messages: JSON.stringify(payload.ids)}, headers})
            .toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                logicalMessages: state.logicalMessages.filter(message => !payload.ids.find(id => id === message.id))
            });
        }

        switch (result.status) {
            case HTTP_STATUS.EVENTS_ACTIVE_FOR_REGISTRATOR:
                this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'devices.deviceUnSyncTooltip');
                return;
            case HTTP_STATUS.MESSAGE_ALREADY_USE_IN_LOGIC_EVENT:
                this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'events.messagesCatalog.messageAlreadyUseInLogicEvent');
                return;
        }
    }

    @Action(UpdateLogicalMessage)
    async updateLogicMessage(ctx: StateContext<MessageCatalogStateModel>, payload: UpdateLogicalMessage): Promise<void> {
        const updateMessageDto: UpdateMessageDto = {
            message: payload.message,
            id: payload.id
        };
        const messages: EventMessage[] = this.store.selectSnapshot(LogicalMessagesState.getLogicalMessages);

        const currentMessage: EventMessage = messages.find(message => message.id === updateMessageDto.id);
        const registratorId: string = currentMessage ? currentMessage.registratorId : '';
        const registrator: Device = this.store.selectSnapshot(DevicesState.getDevices).find(d => d.id === registratorId);
        if (registrator && !registrator.isConnect) {
            const message = await this.translateService.get('events.messagesCatalog.registratorOffline', {registrator: registrator.name}).toPromise();
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, message);
            return;
        }

        const headers = new HttpHeaders({registratorId});
        const result: ApiResponse = await this.http.put('api/logic-messages', updateMessageDto, {headers})
            .toPromise().catch(e => console.log(e)) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                logicalMessages: state.logicalMessages.map(m => {
                    if (m.id === payload.id) {
                        return {
                            ...m,
                            ...result.data
                        };
                    }

                    return m;
                })
            });
        }
    }

    @Action(ChangeSavedStatusMessage)
    changeSavedStatusEvent(ctx: StateContext<MessageCatalogStateModel>, payload: ChangeSavedStatusMessage): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            logicalMessages: state.logicalMessages.map(message => {
                const currentItem = payload.data;

                if (!(currentItem.registratorId === message.registratorId && currentItem.type === EventSaveFromSocketTypeEnum.message && currentItem.items.length)) {
                    message.savedStatus = EventSaveStatus.SAVED;
                    return message;
                }

                const itemIndex = currentItem.items.findIndex(item => item.id === message.id);

                if (itemIndex === -1) {
                    message.savedStatus = EventSaveStatus.SAVED;
                    return message;
                }
                message.savedStatus = currentItem.items[itemIndex].savedStatus;

                return {
                    ...message,
                    preIcons: this.messagesService.getPreIcons(message),
                    postIcons: this.messagesService.getPostIcons(message),
                };
            })
        });

    }

    @Action(SetEditingMessage)
    setEditingMessage(ctx: StateContext<MessageCatalogStateModel>, payload: SetEditingMessage): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            editingMessage: payload.message
        });
    }

    @Action(SetMessageParams)
    setMessageParams(ctx: StateContext<MessageCatalogStateModel>, payload: SetMessageParams): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            params: payload.params
        });
    }
}

import {EventSaveStatus} from '../_enums/event-save-state.enum';

export interface EventSaveFromSocketInterface {
    registratorId: string;
    type: EventSaveFromSocketTypeEnum;
    items: EventSaveFromSocketItemInterface[];
}

export enum EventSaveFromSocketTypeEnum {
    event = 'events',
    message = 'message'
}

export interface EventSaveFromSocketItemInterface {
    id: string;
    savedStatus: EventSaveStatus;
}

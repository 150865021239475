<div class="rereading">
    <div class="rereading__wrapper">
        <div class="rereading__items">
            <div *ngFor="let item of rereadingItems" class="rereading__item"
                 [class.rereading__item--detail]="item.action === rereadingEnum.detail"
                 [class.rereading__item--disable]="item.isDisable"
                 [hidden]="item.action === rereadingEnum.detail && isDisabledDetail"
                 (click)="eventFromIcon(item)">
                <svg-icon [src]="item.path" [svgStyle]="item.styles"></svg-icon>

                <div *ngIf="item.tooltip && item.tooltip.length" class="rereading__tooltip">
                    <app-tooltip [side]="item?.tooltipSide || tooltipSideEnum.left" [value]="item.tooltip"
                                 [width]="'120px'"></app-tooltip>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="ln"
     (click)="openLn = !openLn"
     appClickOutside
     [isActiveClickOutside]="showMobileList"
     (clickOutside)="showMobileList = false"
>

    <div (click)="showMobileList = true" class="ln__value"
         appClickOutside
         [isActiveClickOutside]="openLn"
         (clickOutside)="openLn = false">
        <span>{{currentLn}}</span>
        <svg-icon class="arrow" [src]="ARROW_ICON_PATH"></svg-icon>
    </div>
    <ul class="ln__list" [class.ln__list--active-mobile]="showMobileList" [class.block]="openLn === true">
        <ng-container *ngFor="let item of lns">
            <li *ngIf="item !== currentLn"
                class="ln__item" (click)="currentLn = item; changeLn(item)">{{item}}</li>
        </ng-container>

    </ul>
</div>

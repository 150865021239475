import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-radio-button',
    templateUrl: './radio-button.component.html',
    styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {
    @Input() isDisabled: boolean;
    @Input() value: boolean;
    @Input() title: string;
    @Input() isInfo: boolean;
    @Input() isWait: boolean;
    @Input() isError: boolean;
    @Input() isUserRadio: boolean;
    @Input() isActive: boolean;

    @Output() changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    readonly INFO_ICON_PATH = './assets/design/icons/info.svg';

    constructor() {}

    change(): void {
        this.value = !this.value;
        this.changeEvent.emit(this.value);
    }
}

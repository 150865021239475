import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import invert from 'invert-color';
import { ColorPickerService } from 'ngx-color-picker';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit, OnDestroy {
    private _color = '#000000';

    @Input()
    set color(color) {
        if (!color || !(color.length === 4 || color.length === 7)) {
            this._color = '#000000';
            return;
        }
        this._color = color;
    }

    get color(): string {
        return this._color;
    }

    @Output()
    colorChanged = new EventEmitter();
    newColor;

    constructor(private colorPickerService: ColorPickerService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    setTextColor(color): void {
        this.newColor = color;
    }

    changeColor(): void {
        if (!this.newColor) {
            return;
        }

        this.colorChanged.emit(this.newColor);
    }

    invertColor(hex): any {
        return invert(hex, true);
    }
}

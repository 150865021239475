import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[appFocus]'
})
export class FocusDirective implements AfterViewInit {

    @Input('appFocus') isFocused: boolean;

    constructor(
        private el: ElementRef
    ) {
    }

    ngAfterViewInit(): void {
        if (this.isFocused) {
            this.el.nativeElement.focus();
        }
    }

}

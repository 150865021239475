import {ChartSettingsService} from './chart-settings.service';
import {ComponentsGeneratorService} from '../../../../app-shared-elements/_services/components-generator.service';
import {ComponentRef, EventEmitter, Injectable} from '@angular/core';
import {RangeBoxComponent} from '../range-box/range-box.component';
import {BehaviorSubject} from 'rxjs';
import {Store} from '@ngxs/store';
import {ChartState} from 'src/app/groups/_store/states/chart.state';
import {ChartModeEnum} from 'src/app/app-shared-elements/_enums/chart-mode.enum';
import {TimeFilterState} from 'src/app/app-shared-elements/_store/states/time-filter.state';
import {ChartNavigatorRangeInterface} from 'src/app/groups/_interfaces/chart-navigarot-range.interface';
import {ChartEventsEnum} from 'src/app/groups/_enums/chart-events.enum';
import {ParamsTimeTypeEnum} from 'src/app/app-shared-elements/_interfaces/params.interface';

@Injectable({
    providedIn: 'root'
})
export class ChartNavigatorService {
    public graphInstanceSubject = new BehaviorSubject(null);
    public minMaxSelectedValuesSubject = new BehaviorSubject(null);

    public graphEvents: EventEmitter<ChartNavigatorRangeInterface> = new EventEmitter<ChartNavigatorRangeInterface>();

    public rangeBox: any;
    private xOffset;

    public nafigatorHeight = 65;

    constructor(private componentsGeneratorService: ComponentsGeneratorService,
                private chartSettingsService: ChartSettingsService,
                private store: Store) {

        this.chartSettingsService.graphEvents.subscribe((data: {type: ChartEventsEnum, event?: any}) => {
            if (data.type !== ChartEventsEnum.selection) {
                return;
            }

            const newHeightValue = data.event.target.plotBackground.element.height.baseVal.value;

            if (newHeightValue) {
                this.nafigatorHeight = newHeightValue;
            }

            this.masterChartSelectHandler(data.event);
        });
    }

    private masterChartSelectHandler(event): void {
        if (!event.custom) {
            this.createRangeBox(event);
        }
    }

    public performRangeBoxSettings(event): ChartNavigatorRangeInterface {
        const settings: any = {};

        let x1;
        let x2;

        if (event.left && event.right || event.left === 0) {
            settings.left = event.left;
            settings.right = event.right;

            settings.tsMin = 0;
            settings.tsMax = 0;
        } else {
            this.xOffset = event.target.container.querySelector('.highcharts-plot-background').getAttribute('x');
            if (!event.custom) {
                x1 = event.target.mouseDownX;
                x2 = event.originalEvent.x - event.target.container.getBoundingClientRect().left;
            } else {
                x1 = event.custom.dot1;
                x2 = event.custom.dot2;
            }
            const containerRight = event.target.container.offsetWidth;

            settings.left = x1 < x2 ? x1 : x2 < 0 ? 0 : x2;
            settings.right = x1 >= x2 ? x1 : x2 > containerRight ? containerRight : x2;
            settings.tsMin = event.xAxis[0].min;
            settings.tsMax = event.xAxis[0].max;
        }

        const currentTimeType = this.store.selectSnapshot(TimeFilterState.getTimeType);
        const currentDateMax = this.store.selectSnapshot(TimeFilterState.getTimeObj).to;
        const currentDateMin = this.store.selectSnapshot(TimeFilterState.getTimeObj).from;

        if (currentTimeType === ParamsTimeTypeEnum.RANGE && settings.tsMax > Date.now()) {
            settings.tsMax = Date.now();
        }

        if (currentTimeType === ParamsTimeTypeEnum.RANGE && settings.tsMax > currentDateMax) {
            settings.tsMax = currentDateMax;
        }

        if (settings.tsMax > Date.now()) {
            settings.tsMax = Date.now();
        }

        if (settings.tsMin < currentDateMin) {
            settings.tsMin = currentDateMin;
        }

        settings.offsetX = this.xOffset || 0;


        return settings;
    }

    public updateRangeBox(): void {
        if (!this.rangeBox) {
            return;
        }

        (this.rangeBox as ComponentRef<RangeBoxComponent>).instance.settings = this.getRangeCoordinates();
    }

    private getRangeCoordinates(): any {
        const newRange = {left: 0, right: 0, offsetX: this.xOffset};
        const allPlots = [];

        this.graphInstanceSubject.value.master.series.forEach((seriesObject) => {
            allPlots.push(...seriesObject.data);
        });

        allPlots.forEach((plot) => {
            if (!plot) {
                return;
            }

            if (plot.x === this.minMaxSelectedValuesSubject.value[0]) {
                newRange.left = +plot.clientX + this.xOffset;
            }

            if (plot.x === this.minMaxSelectedValuesSubject.value[1]) {
                newRange.right = +plot.clientX + this.xOffset;
            }
        });

        return newRange;
    }

    public async deleteRangeBox(): Promise<void> {
        if (!this.rangeBox) {
            return;
        }

        await (this.rangeBox as ComponentRef<RangeBoxComponent>).hostView.destroy();
        this.rangeBox = null;
    }

    private createRangeBox(event): void {
        if (this.store.selectSnapshot(ChartState.getChartMode) === ChartModeEnum.hole) {
            return;
        }

        const settings = this.performRangeBoxSettings(event);
        this.graphEvents.emit(this.performRangeBoxSettings(event));

        // return;
        if (this.rangeBox) {
            this.rangeBox.hostView.destroy();
            this.rangeBox = null;
        }

        this.componentsGeneratorService
            .create(RangeBoxComponent, '#master', {settings})
            .then((component) => {
                this.rangeBox = component as ComponentRef<RangeBoxComponent>;

                this.rangeBox.location.nativeElement.style.height =
                    this.nafigatorHeight + 10 + 'px'; // 10 is a constant offset in plots background

                this.rangeBox.instance.events.subscribe(e => {
                    this.graphEvents.emit(this.performRangeBoxSettings(e.data));
                });
            }).catch((err) => {
                console.log(err);
            });
    }
}

import { Component, OnInit } from '@angular/core';
import { BlockUiService } from '../app-shared-elements/_services/auto-block-ui.service';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { ConfigurationState } from '../app-shared-elements/_store/states/configuration.state';
import { Observable } from 'rxjs';
import { ConfigurationServerInterface } from '../app-shared-elements/_interfaces/configuration-server.interface';
import { ServerNamesEnum } from '../app-shared-elements/_enums/server-names.enum';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
    @Select(ConfigurationState.getConfigurationServer) configurationServer$: Observable<ConfigurationServerInterface>;

    readonly LOGO_USER_ICON_PATH = './assets/design/icons/auth-logo-user.svg';
    readonly LOGO_USER_CCC_ICON_PATH = './assets/design/icons/auth-logo-user-ccc.svg';
    readonly LOGO_ADMIN_ICON_PATH = './assets/design/icons/auth-logo-admin.svg';

    serverNamesEnum = ServerNamesEnum;
    constructor(
        private blockUiService: BlockUiService,
        public router: Router,
    ) {
        if (this.router.url.includes('control')) {
            const favicon = document.querySelector('link[rel="icon"]');
            favicon.setAttribute('href', 'assets/design/icons/admin-sc.ico');
        }
    }

    ngOnInit(): void {
        this.blockUiService.clear();
    }
}

<div *ngIf="configPagination && configPagination.totalItems > 5" class="pagination"
    [class.pagination--mobile]="isMobile">
    <div class="pagination__wrapper">
        <div class="pagination__count-block">
            <div class="pagination__select">

                <app-custom-select
                    class="period"
                    [isReset]="false"
                    [isRepeat]="false"
                    [options]="tableService.counts"
                    [value]="customCountOption"
                    [type]="selectTypeEnum.pagination"
                    [smartWrapperPosition]="true"
                    (emmitNewValue)="checkCurrentPage($event)"></app-custom-select>
            </div>
            <div class="pagination__info">
                <span>
                    {{configPagination.currentPage === 1
                    ? configPagination.currentPage
                    : ((configPagination.currentPage - 1) * configPagination.itemsPerPage) + 1}} -
                    {{configPagination.currentPage === 1
                    ? configPagination.totalItems <= configPagination.itemsPerPage
                        ? configPagination.totalItems : configPagination.itemsPerPage
                    : ((configPagination.currentPage + 1) * configPagination.itemsPerPage) - configPagination.itemsPerPage}}
                </span>
                <span [translate]="'pagination.of'"></span>
                <span>
                    {{configPagination.totalItems}}
                </span>
            </div>
        </div>

        <div class="pagination__page-block"
            >
            <pagination-controls *ngIf="configPagination && (configPagination.totalItems > configPagination.itemsPerPage)"
                class="pagination__list" (pageChange)="pageChanged($event)"
                maxSize="{{isMobile ? '0' : '7'}}" responsive="true">
            </pagination-controls>
        </div>
    </div>
</div>

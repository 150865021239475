import { ColumnsTableInterface, ColumnTypeEnum } from '../../../../app-shared-elements/_interfaces/ColumnsTable';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ColumnsActionTypeEnum } from '../../../../app-shared-elements/_enums/columns-action-type.enum';
import {
    ChangeActiveDatalogger,
    CloseEditingDevicesControlRow,
    CreateDatalogger,
    CreateNewDatalogger,
    DeleteDatalogger,
    ExportDatalogger,
    GetDevicesControl,
    GetDevicesControlRegistrators,
    GetDevicesControlRows,
    RegenerateDatalogger,
    SetDevicesControlFilter,
    SetRowsForGroupOperation,
} from '../actions/devices-control.actions';
import { ApiResponse } from '../../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP_STATUS } from '../../../../app-shared-elements/_enums/status.enum';
import { MailingCellFieldTypeEnum } from '../../../../mailing/_enums/mailing-cell-field-type.enum';
import { DevicesControlInterface, DevicesControlRowInterface } from '../../_interfaces/devices-control-row.interface';
import { SelectTypeEnum } from '../../../../app-shared-elements/_enums/select-type.enum';
import { DevicesControlService } from '../../_services/devices-control.service';
import {
    Pagination,
    Params,
    ParamsSorted,
    ParamsSortedOrderEnum,
} from '../../../../app-shared-elements/_interfaces/params.interface';
import { CloneDeepService } from '../../../../app-shared-elements/_services/clone-deep.service';
import {
    DropdownFilterOptionInterface,
} from '../../../../app-shared-elements/filters/interfaces/filter-option.interface';
import { TypeFilterEnum } from '../../../../app-shared-elements/filters/enums/type-filter.enum';
import { DevicesControlStatusEnum } from '../../_enums/devices-control-status.enum';
import { SetMainOperation } from '../../../../app-shared-elements/_store/actions/table.actions';
import { DisabledTypeEnum } from '../../../../app-shared-elements/_enums/disabled-type.enum';
import { NotificationsService } from '../../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../../app-shared-elements/_enums/tooltip-status.enum';
import { TooltipSideEnum } from '../../../../app-shared-elements/_enums/tooltip-side.enum';
import { devicesControlDropdown } from '../../_data/devices-control-data';
import { ParamsService } from '../../../../app-shared-elements/_services/params.service';
import { UserState } from '../../../../app-shared-elements/_store/states/user.state';

export interface DevicesControlStateModel {
    devicesControl: { items: DevicesControlInterface[]; total: number };
    columns: ColumnsTableInterface[];
    rows: DevicesControlRowInterface[];
    params: Params;
    registratorsList: { name: string; id: string }[];
    dropDownFilterOptions: DropdownFilterOptionInterface[];
    choosenRowIds: string[];
    isEditable: boolean;
}

const DEVICES_CONTROL_TOKEN = new StateToken<DevicesControlStateModel>('devicesControl');

const initColumnsTable: ColumnsTableInterface[] = [
    {
        title: '',
        grow: false,
        small: true,
        maxWidth: '92px',
        type: ColumnTypeEnum.groupOperation,
        name: 'isChoose',
    },
    {
        title: 'events.logicalEvents.table.act',
        grow: false,
        small: true,
        maxWidth: '65px',
        minWidth: '65px',
        type: ColumnTypeEnum.icon,
        name: 'isActive',
    },
    {
        title: 'devicesControl.table.serialNumber',
        grow: true,
        postIcons: true,
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.inputAdd,
        name: 'serialNumbers',
        sort: 0,
        sortField: 'serialNumber',
    },
    {
        title: 'devicesControl.table.registrator',
        grow: true,
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.select,
        selectType: SelectTypeEnum.report,
        optionsName: 'registratorOptions',
        placeholderForSelectColumn: 'Назва реєстратора',
        currentValueForSelect: 'registrator',
        name: 'registratorName',
        isMultilang: true,
    },
    {
        title: 'devicesControl.table.email',
        grow: true,
        type: ColumnTypeEnum.text,
        name: 'createdUserLogin',
        tooltip: true,
        isClick: true,
        tooltipSide: TooltipSideEnum.top,
    },
    {
        title: 'devicesControl.table.status',
        grow: true,
        type: ColumnTypeEnum.text,
        isMultilang: true,
        styles: true,
        name: 'status',
    },
    {
        title: 'events.logicalEvents.table.actions',
        grow: false,
        small: true,
        maxWidth: '150px',
        minWidth: '150px',
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.btns,
        name: 'edit',
        actionBtns: [ColumnsActionTypeEnum.actionBtnsDelete, ColumnsActionTypeEnum.export, ColumnsActionTypeEnum.regenerationPassword],
    },
];

const dropDownFilterOptions: DropdownFilterOptionInterface[] = [
    {
        key: 'serialNumber',
        value: 'devicesControl.filter.serialNumber',
        type: TypeFilterEnum.text,
        property: 'serialNumber',
    },
    { key: 'status', value: 'devicesControl.filter.status', type: TypeFilterEnum.select, property: 'status' },
    {
        key: 'createdUserLogin',
        value: 'devicesControl.filter.email',
        type: TypeFilterEnum.text,
        property: 'createdUserLogin',
    },
];

const initialPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

const initialCurrentSort: ParamsSorted[] = [
    {
        property: 'created',
        order: ParamsSortedOrderEnum.DESC,
    },
];

export const initialParams: Params = {
    filter: devicesControlDropdown,
    sorted: initialCurrentSort,
    pagination: initialPagination,
};

@State<DevicesControlStateModel>({
    name: DEVICES_CONTROL_TOKEN,
    defaults: {
        devicesControl: { items: [], total: 0 },
        columns: initColumnsTable,
        rows: [],
        params: initialParams,
        registratorsList: [],
        dropDownFilterOptions,
        choosenRowIds: [],
        isEditable: false,
    },
})
@Injectable()
export class DevicesControlState {
    constructor(
        private store: Store,
        private http: HttpClient,
        private devicesControlService: DevicesControlService,
        private cloneDeepService: CloneDeepService,
        private notificationServices: NotificationsService,
        private paramsService: ParamsService,
    ) {}

    @Selector()
    static getDevicesControlRows(state: DevicesControlStateModel): DevicesControlRowInterface[] {
        return state.rows.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
    }

    @Selector()
    static getParams(state: DevicesControlStateModel): Params {
        return state.params;
    }

    @Selector()
    static getColumns(state: DevicesControlStateModel): ColumnsTableInterface[] {
        return state.columns;
    }

    @Selector()
    static getDropDownFilterOptions(state: DevicesControlStateModel): DropdownFilterOptionInterface[] {
        return state.dropDownFilterOptions;
    }

    @Selector()
    static getChoosenRowIds(state: DevicesControlStateModel): string[] {
        return state.choosenRowIds;
    }

    @Selector()
    static getIsEditable(state: DevicesControlStateModel): boolean {
        return state.isEditable;
    }

    @Action(GetDevicesControl)
    async getDevicesControl(ctx: StateContext<DevicesControlStateModel>): Promise<void> {
        const state = ctx.getState();
        const params: Params = {
            ...state.params,
            filter: this.paramsService.parseParamsFilterForServer(state.params.filter),
            pagination: {
                itemsPerPage: state.params.pagination.itemsPerPage,
                currentPage: state.params.pagination.currentPage,
            },
            sorted: state.params.sorted && state.params.sorted.length ? state.params.sorted : initialCurrentSort,
        };

        const headers = new HttpHeaders({
            params: encodeURIComponent(JSON.stringify(params)),
        });

        const result: ApiResponse = (await this.http
            .get('/api/control/datalogger-settings', { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        // console.log(result);
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                devicesControl: result.data as { items: DevicesControlInterface[]; total: number },
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.total,
                    },
                },
            });
        }
    }

    @Action(GetDevicesControlRegistrators)
    async getDevicesControlRegistrators(ctx: StateContext<DevicesControlStateModel>): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/control/datalogger-settings/registrators')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                registratorsList: result.data,
            });
        }
    }

    @Action(GetDevicesControlRows)
    async getDevicesControlRows(ctx: StateContext<DevicesControlStateModel>): Promise<void> {
        await ctx.dispatch(new GetDevicesControl()).toPromise();
        await ctx.dispatch(new GetDevicesControlRegistrators()).toPromise();
        const state = ctx.getState();
        // console.log(state.devicesControl);
        ctx.setState({
            ...state,
            rows: state.devicesControl.items.map((item) => {
                const currentRegistrator = state.registratorsList.find((f) => f.id === item.registratorId);
                return {
                    ...item,
                    isChoose: {
                        value: false,
                        disabled:
                            item.status !== DevicesControlStatusEnum.WAITING_PERMANENT_PASSWORD &&
                            item.status !== DevicesControlStatusEnum.REQUIRES_PASSWORD_REGENERATION,
                    },
                    isActive: item.isActive,
                    createdUserLogin: item.createdUserLogin.split('@')[0] + '@',
                    registrator: currentRegistrator,
                    registratorName: currentRegistrator?.name ?? 'devicesControl.registratorDelete',
                    serialNumbers: item.serialNumber,
                    postIcons: this.devicesControlService.getPostIcons(item),
                    styles: {
                        status: this.devicesControlService.getStatusStyle(item.status),
                    },
                    tooltipValue: {
                        createdUserLogin: item.createdUserLogin,
                    },
                    status: this.devicesControlService.getStatus(item.status),
                    savedStatus: null,
                    isEditing: false,
                    disabledActions:
                        item.status === DevicesControlStatusEnum.WAITING_PERMANENT_PASSWORD ||
                        item.status === DevicesControlStatusEnum.REQUIRES_PASSWORD_REGENERATION
                            ? []
                            : [
                                  ColumnsActionTypeEnum.actionBtnsDelete,
                                  ColumnsActionTypeEnum.export,
                                  ColumnsActionTypeEnum.regenerationPassword,
                              ],
                    registratorOptions: state.registratorsList.map((registrator) => {
                        return {
                            key: registrator.id,
                            value: registrator.name,
                        };
                    }),
                };
            }),
            choosenRowIds: [],
        });
    }

    @Action(CreateNewDatalogger)
    createNewDatalogger(ctx: StateContext<DevicesControlStateModel>): void {
        const state = ctx.getState();
        const user = this.store.selectSnapshot(UserState.getUser);
        const newDatalogger: DevicesControlRowInterface = {
            id: null,
            isChoose: {
                value: false,
                disabled: false,
            },
            registrator: null,
            serialNumbers: [],
            status: 'Na',
            createdUserLogin: user.login.split('@')[0] + '@' ?? user.name.split('@')[0] + '@',
            isNew: true,
            isActive: false,
            isEditing: true,
            disabledType: DisabledTypeEnum.devicesControl,
            registratorOptions: state.registratorsList.map((registrator) => {
                return {
                    key: registrator.id,
                    value: registrator.name,
                };
            }),
        };

        ctx.setState({
            ...state,
            rows: [newDatalogger, ...state.rows],
            isEditable: true,
        });
    }

    @Action(ChangeActiveDatalogger)
    async changeActiveDatalogger(ctx: StateContext<DevicesControlStateModel>, payload: ChangeActiveDatalogger): Promise<void> {
        const result: ApiResponse = (await this.http
            .put('/api/control/datalogger-settings/active', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();
        // console.log(result);
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
            });

            ctx.dispatch(new GetDevicesControlRows());
            this.notificationServices.onEmit(TooltipStatusEnum.update, false);
        } else {
            this.notificationServices.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(CreateDatalogger)
    async createDatalogger(ctx: StateContext<DevicesControlStateModel>, payload: CreateDatalogger): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/control/datalogger-settings', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        // console.log(result);
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                rows: [result.data, ...state.rows],
            });
            this.notificationServices.onEmit(TooltipStatusEnum.update, false);
        } else if (result && result.status === HTTP_STATUS.DATA_ALREADY_EXISTS) {
            this.notificationServices.onEmit(TooltipStatusEnum.error, false, 'devicesControl.serialNumberExist');
        } else {
            this.notificationServices.onEmit(TooltipStatusEnum.error, false);
        }

        ctx.dispatch(new GetDevicesControlRows());
    }

    @Action(CloseEditingDevicesControlRow)
    devicesControlRowInterface(ctx: StateContext<DevicesControlStateModel>, payload: CloseEditingDevicesControlRow): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            rows: state.rows.map((row) => ({
                ...(payload.savedRow && payload.savedRow.id === row.id ? payload.savedRow : row),
                isEditing: false,
            })),
            isEditable: false,
        });

        ctx.dispatch(new GetDevicesControlRows());
    }

    @Action(SetDevicesControlFilter)
    async setDeviceLogsFilter(ctx: StateContext<DevicesControlStateModel>, payload: SetDevicesControlFilter): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: { ...payload.params },
        });

        await ctx.dispatch(new GetDevicesControlRows()).toPromise();
    }

    @Action(ExportDatalogger)
    async exportDatalogger(ctx: StateContext<DevicesControlStateModel>, payload: ExportDatalogger): Promise<void> {
        const result = await this.http
            .get('/api/control/datalogger-settings/export', {
                responseType: 'blob',
                observe: 'response',
                params: {
                    ids: payload.ids,
                },
            })
            .toPromise()
            .catch((e) => console.log(e));
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.OK) {
            // console.log(result);
            const dataStr = window.URL.createObjectURL(result.body);
            const a = document.createElement('a');
            a.href = dataStr;
            a.download = payload.name + '.csv';
            a.click();
            window.URL.revokeObjectURL(dataStr);
            a.remove();
            this.notificationServices.onEmit(TooltipStatusEnum.update, false, 'devicesControl.exportToaster');
        }
    }

    @Action(RegenerateDatalogger)
    async regenerateDatalogger(ctx: StateContext<DevicesControlStateModel>, payload: RegenerateDatalogger): Promise<void> {
        const result: ApiResponse = (await this.http
            .put('/api/control/datalogger-settings', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
            });
            this.notificationServices.onEmit(TooltipStatusEnum.update, false, 'devicesControl.regenerationToaster');
            ctx.dispatch(new GetDevicesControlRows());
        }
    }

    @Action(DeleteDatalogger)
    async deleteDatalogger(ctx: StateContext<DevicesControlStateModel>, payload: DeleteDatalogger): Promise<void> {
        const result: ApiResponse = (await this.http
            .delete('/api/control/datalogger-settings', {
                params: {
                    id: payload.id,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
            });
            this.notificationServices.onEmit(TooltipStatusEnum.update, false);
            ctx.dispatch(new GetDevicesControlRows());
        }
    }

    @Action(SetRowsForGroupOperation)
    setRowsFroGroupOperation(ctx: StateContext<DevicesControlStateModel>, payload: SetRowsForGroupOperation): void {
        const state = ctx.getState();
        // console.log(payload.event);
        if (payload.event.isMain) {
            this.store.dispatch(new SetMainOperation(payload.event.event));

            ctx.setState({
                ...state,
                rows: state.rows.map((row) => {
                    return {
                        ...row,
                        isChoose: {
                            ...row.isChoose,
                            value: payload.event.event,
                        },
                    };
                }),
                choosenRowIds: payload.event.event ? [...state.rows.map((row) => row.id)] : [],
            });

            return;
        }

        ctx.setState({
            ...state,
            rows: state.rows.map((row) => {
                if (row.id === payload.event.id) {
                    return {
                        ...row,
                        isChoose: {
                            ...row.isChoose,
                            value: payload.event.event,
                        },
                    };
                }
                return row;
            }),
            choosenRowIds: [
                ...state.choosenRowIds,
                payload.event.event && !state.choosenRowIds.find((id) => id === payload.event.id) ? payload.event.id : null,
            ]
                .filter((id) => id !== null)
                .filter((id) => {
                    if (id !== payload.event.id || payload.event.event) {
                        return id;
                    }
                }),
        });
    }
}

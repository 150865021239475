import { Injectable } from '@angular/core';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { LimitLogicEventDto, LimitLogicEventUpdateDto, LogicEvent, LogicEventType } from '../logical-events/_interface/LogicEvent';
import { AlarmTypeEnum } from '../_enums/alarm.enum';
import { ConditionType } from '../_enums/condition-type.enum';
import { ValueConditionsInterface } from '../_interfaces/Expression';

@Injectable({
    providedIn: 'root',
})
export class MultiEventService {
    constructor() {}

    getLimitValuesByVariable(
        logicalEvents: LogicEvent[],
        variable: Variable,
    ): {
        [LogicEventType.alarmDeadlineMax]: number;
        [LogicEventType.alarmDeadlineMin]: number;
        [LogicEventType.attentionDeadlineMax]: number;
        [LogicEventType.attentionDeadlineMin]: number;
    } {
        const currentEvents = logicalEvents.filter((e) => e.resultVariableId === variable.id);

        if (!currentEvents || !currentEvents.length) {
            return;
        }

        const currentMultiEvent: LimitLogicEventUpdateDto = this.parseMultiEvent(currentEvents[0].name, variable.id, currentEvents);

        return {
            [LogicEventType.alarmDeadlineMax]: this.getValueFromMultiEvent(currentMultiEvent, LogicEventType.alarmDeadlineMax),
            [LogicEventType.alarmDeadlineMin]: this.getValueFromMultiEvent(currentMultiEvent, LogicEventType.alarmDeadlineMin),
            [LogicEventType.attentionDeadlineMax]: this.getValueFromMultiEvent(currentMultiEvent, LogicEventType.attentionDeadlineMax),
            [LogicEventType.attentionDeadlineMin]: this.getValueFromMultiEvent(currentMultiEvent, LogicEventType.attentionDeadlineMin),
        };
    }

    parseMultiEvent(name: string, variableId: string, events: LogicEvent[]): LimitLogicEventUpdateDto {
        return {
            name,
            variableId,
            isAcknowledgeableAlarm: !!events.find((e) => e.isAcknowledgeable && e.type === AlarmTypeEnum.alarm),
            isAcknowledgeableAttention: !!events.find((e) => e.isAcknowledgeable && e.type === AlarmTypeEnum.attention),
            limitIsActive: true,
            [LogicEventType.alarmDeadlineMax]: this.getLimitLogicEvent(
                events.find((e) => e.logicEventType === LogicEventType.alarmDeadlineMax),
                LogicEventType.alarmDeadlineMax,
            ),
            [LogicEventType.alarmDeadlineMin]: this.getLimitLogicEvent(
                events.find((e) => e.logicEventType === LogicEventType.alarmDeadlineMin),
                LogicEventType.alarmDeadlineMin,
            ),
            [LogicEventType.attentionDeadlineMax]: this.getLimitLogicEvent(
                events.find((e) => e.logicEventType === LogicEventType.attentionDeadlineMax),
                LogicEventType.attentionDeadlineMax,
            ),
            [LogicEventType.attentionDeadlineMin]: this.getLimitLogicEvent(
                events.find((e) => e.logicEventType === LogicEventType.attentionDeadlineMin),
                LogicEventType.attentionDeadlineMin,
            ),
        };
    }

    private getLimitLogicEvent(event: LogicEvent, logicEventType: LogicEventType): LimitLogicEventDto {
        if (!event) {
            return {
                logicEventType,
                value: '',
                isActive: false,
            };
        }
        const value = event.expression.conditions[0].value.find((v: ValueConditionsInterface) => v.type === ConditionType.Constant).value;
        return {
            logicEventType,
            value,
            isActive: event.isActive,
        };
    }

    private getValueFromMultiEvent(currentMultiEvent: LimitLogicEventUpdateDto, logicEventType: LogicEventType): any {
        if (!currentMultiEvent || !currentMultiEvent[logicEventType]) {
            return null;
        }

        const constantValue = currentMultiEvent[logicEventType].value;

        if (!constantValue) {
            return null;
        }

        return +constantValue;
    }
}

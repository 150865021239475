import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { TransportLimitLogicEvent, TransportLogicEventInterface } from '../../../transport-container/transport-dashboard/_interfaces/transport-logic-event.interface';

export class InitDataloggerDevice {
    static readonly type = '[DetailsDeviceDatalogger] InitDataloggerDevice';
}

export class UpdateDataloggerDevice {
    static readonly type = '[DetailsDeviceDatalogger] UpdateDataloggerDevice';

    constructor(
        public name: string,
        public value: any,
    ) {}
}

export class InitContactControlDevice {
    static readonly type = '[DetailsDeviceDatalogger] InitContactControlDevice';
    constructor(public currentDevice: Device) {}
}

export class UpdateContactControlDevice {
    static readonly type = '[DetailsDeviceDatalogger] InitContactControlDevice';
    constructor(public currentDevice: Device) {}
}

export class SetNewValueInDatalogger {
    static readonly type = '[DetailsDeviceDatalogger] SetNewValueInDatalogger';

    constructor(public data: { id: string; value: any }[]) {}
}

export class RevertValueInDatalogger {
    static readonly type = '[DetailsDeviceDatalogger] RevertValueInDatalogger';

    constructor(public variableId: string) {}
}

export class UpdateDataloggerValue {
    static readonly type = '[DetailsDeviceDatalogger] UpdateDataloggerValue';

    constructor(public device: Device) {}
}

export class AcknowledgeLogicEvent {
    static readonly type = '[DetailsDeviceDatalogger] acknowledgeLogicEvent';

    constructor(public id: string) {}
}

export class InitLimitArray {
    static readonly type = '[DetailsDeviceDatalogger] InitLimitArray';

    constructor(public expeditions: TransportLogicEventInterface<TransportLimitLogicEvent>[]) {}
}

import { ShareStatusEnum } from '../../../app-shared-elements/_enums/share-status.enum';
import { ShareRoleEnum } from '../../../app-shared-elements/_enums/share-roles.enum';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { RoleInterface } from 'src/app/app-shared-elements/_interfaces/role.interface';
import { PermissionsState } from 'src/app/app-shared-elements/_store/states/permissions.state';
import { GetRolePermissionsForUser } from 'src/app/app-shared-elements/_store/actions/permissions.actions';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { SharedUserInterface } from '../_interface/shared-user.interface';
import { takeUntil } from 'rxjs/operators';
import { DisabledTypeEnum } from 'src/app/app-shared-elements/_enums/disabled-type.enum';
import { TooltipSideEnum } from '../../../app-shared-elements/_enums/tooltip-side.enum';
import { ShareDeviceState } from '../../_store/states/share-device.state';
import {
    AddUserInNewSharingList,
    ChangeRoleInNewUser,
    ChangeRoleInOldUser,
    CheckShareUser,
    ClearNewShareUserList,
    InitSharedUserList,
    SaveSharingList,
    SetSharingDevice,
} from '../../_store/actions/share-device.actions';
import { ShareSelectOptionsInterface } from '../_interface/share-select-options.interface';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { CustomInputItem } from 'src/app/app-shared-elements/_interfaces/custom-input-item.interface';
import { GetUsersByCompanies } from 'src/app/mailing/_store/actions/mailing-recipient.actions';
import { MailingRecipientState } from 'src/app/mailing/_store/states/mailing-recipient.state';
import { Router } from '@angular/router';

@Component({
    selector: 'app-share-device',
    templateUrl: './share-device.component.html',
    styleUrls: ['./share-device.component.scss'],
})
export class ShareDeviceComponent implements OnInit, OnDestroy {
    message = '';
    oldUsersList = [];
    newUsersList = [];
    errorEmail = false;
    duplicateEmail = false;
    isRoleInfo = false;

    shareRoleEnum: ShareRoleEnum;
    shareStatusEnum = ShareStatusEnum;
    tooltipSideEnum = TooltipSideEnum;

    autoCompleteItems: CustomInputItem[] = [];

    @Input() registrator: Device;

    @Input() isMnemonic = false;

    @Output() closeEvent = new EventEmitter<void>();
    @Output() updateEvent = new EventEmitter<void>();

    tooltipArray: { title: string; value: string }[] = [
        {
            title: 'share.tooltip.guest',
            value: 'share.tooltip.guestValue',
        },
        {
            title: 'share.tooltip.operator',
            value: 'share.tooltip.operatorValue',
        },
        {
            title: 'share.tooltip.admin',
            value: 'share.tooltip.adminValue',
        },
    ];

    selectOptions: ShareSelectOptionsInterface[] = [
        {
            key: 'name',
            property: 'name',
            type: 'text',
            value: 'share.select.guest',
            enum: ShareRoleEnum.guest,
        },
        {
            key: 'name',
            property: 'name',
            type: 'text',
            value: 'share.select.operator',
            enum: ShareRoleEnum.operator,
        },
        {
            key: 'name',
            property: 'name',
            type: 'text',
            value: 'share.select.admin',
            enum: ShareRoleEnum.admin,
        },
    ];

    isChanged = false;
    searchEmail = '';
    deviceName: string;
    disabledTypeEnum = DisabledTypeEnum;

    isEditInput: boolean;

    newUser = [];

    @Select(PermissionsState.getRolesOptions) roleOptions$: Observable<SelectOptionInterface<string, string, RoleInterface>[]>;

    @Select(ShareDeviceState.getOldSharingUserInDevice) oldUsersList$: Observable<SharedUserInterface[]>;
    @Select(ShareDeviceState.getNewSharingUserInDevice) newUsersList$: Observable<SharedUserInterface[]>;
    @Select(PermissionsState.getRoles) roles$: Observable<RoleInterface[]>;
    @Select(MailingRecipientState.getAutoCompleteUsers) autocompleteValues$: Observable<CustomInputItem[]>;

    isDisabled: boolean;

    scrollTop: number;

    readonly CLOSE_ICON_PATH = './assets/design/icons/close.svg';
    readonly QUESTION_ICON_PATH = './assets/design/icons/question.svg';
    readonly ARROW_BACK_ICON_PATH = './assets/design/icons/arrow-back.svg';
    readonly USER_ICON_PATH = './assets/design/icons/user.svg';

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private store: Store,
        public router: Router,
    ) {
        this.newUsersList$.pipe(takeUntil(this.destroy)).subscribe((list) => {
            this.newUsersList = list;
        });

        this.oldUsersList$.pipe(takeUntil(this.destroy)).subscribe((list) => {
            this.oldUsersList = list;
        });
    }

    async ngOnInit(): Promise<void> {

        this.scrollTop = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.overflowY = 'hidden';

        if (!this.store.selectSnapshot(PermissionsState.getIsLoadRoles)) {
            await this.store.dispatch(new GetRolePermissionsForUser()).toPromise();
        }

        if (!this.store.selectSnapshot(PermissionsState.getRoles).length) {
            await this.store.dispatch(new GetRolePermissionsForUser()).toPromise();
        }

        await this.store.dispatch(new GetUsersByCompanies()).toPromise();

        this.deviceName = this.registrator.name || this.registrator.defaultName;

        this.initUsersList();
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
        document.body.style.position = 'static';
        document.body.style.overflowY = 'auto';
        if (+this.scrollTop > 0) {
            document.body.scrollTo(0, this.scrollTop);
        }
    }

    private async initUsersList(): Promise<void> {
        this.store.dispatch(new SetSharingDevice(this.registrator.id));

        await this.store.dispatch(new InitSharedUserList()).toPromise();
    }

    setNewSelectValueOldUser(event, user: SharedUserInterface): void {
        this.store.dispatch(new ChangeRoleInOldUser(user.id, event));

        this.isChanged = true;
    }

    setNewSelectValueNewUser(event, user): void {
        this.isChanged = event.key ? true : false;
        this.store.dispatch(new ChangeRoleInNewUser(user.email, event));
    }

    addUser(data: { value: string; event: boolean }): void {
        this.isDisabled = false;
        if (typeof data === 'string') {
            this.newUser.push(data);
        }

        this.newUser.push(data.value);

        if (this.newUser?.length && !data.event) {
            this.newUser = this.newUser.filter((user) => user !== data.value);
        }
    }

    async addNewUser(): Promise<void> {
        this.isDisabled = true;

        this.newUser = this.newUser
            .map((user) => {
                if (!this.isEmailValid(user)) {
                    this.errorEmail = true;
                    this.isDisabled = false;
                    return;
                }

                return user;
            })
            .filter((f) => !!f);

        if (this.errorEmail) {
            return;
        }

        this.duplicateEmail = false;

        const data: { emails: string[]; registratorId: string } = {
            emails: this.newUser,
            registratorId: this.registrator.id,
        };
        await this.store.dispatch(new CheckShareUser(data)).toPromise();

        if (this.store.selectSnapshot(ShareDeviceState.getShareDeviceStatus).status !== HTTP_STATUS.SUCCESS) {
            this.errorEmail = true;
            this.isDisabled = false;
            return;
        }

        if (this.store.selectSnapshot(ShareDeviceState.getShareDeviceStatus)?.data.isShared) {
            this.errorEmail = true;
            this.isDisabled = false;
            return;
        }

        // this.newUsersList.forEach((user) => {
        //     if (user.email === this.searchEmail) {
        //         this.duplicateEmail = true;
        //         this.isDisabled = false;
        //         return;
        //     }
        // });

        if (this.duplicateEmail) {
            this.isDisabled = false;
            return;
        }

        this.newUser.forEach((user) => {
            const newUser: { roleName: string; role: string; email: string } = {
                roleName: '',
                role: '',
                email: user,
            };

            this.store.dispatch(new AddUserInNewSharingList(newUser));
        });

        this.newUser = [];
        this.isEditInput = false;
        this.errorEmail = false;
        this.searchEmail = null;
        this.isDisabled = false;
    }

    private isEmailValid(email: string): boolean {
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return reg.test(email);
    }

    async saveChanges(): Promise<void> {
        await this.store.dispatch(new SaveSharingList(this.message)).toPromise();
        this.message = '';
        this.isChanged = false;

        this.close();
    }

    removeOldUser(user: SharedUserInterface): void {
        this.store.dispatch(new ChangeRoleInOldUser(user.id, 'delete'));
        this.isChanged = true;
    }

    removeNewUser(user: SharedUserInterface): void {
        this.store.dispatch(new ChangeRoleInNewUser(String(user.id), 'delete'));
        this.isChanged = true;
    }

    close(): void {
        this.closeEvent.emit();
        this.store.dispatch(new ClearNewShareUserList());
    }
}

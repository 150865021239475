import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { ColumnsTableInterface, ColumnTypeEnum } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { Pagination, Params, ParamsFilterTypeEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { mailingSendersCheckboxes } from '../../_data/mailing-senders-params';
import { SenderUserInterface, SenderUserRowInterface } from '../../_interfaces/sender-user.interface';
import { MailingUserService } from '../../_services/mailing-user.service';
import { AcceptSender, GetMailingSenders, InitMailingSenderRows, SetSendersParams } from '../actions/mailing-sender.actions';
import { StatusNotificationShare } from '../../_enums/status-notification-share.enum';

export interface MailingSerndersStateModel {
    senders: SenderUserInterface[];
    sendersRows: SenderUserRowInterface[];
    columns: ColumnsTableInterface[];
    params: Params;
}

const MAILING_SENDERS_STATE = new StateToken<MailingSerndersStateModel>('mailingSenderState');

const columns: ColumnsTableInterface[] = [
    {
        title: 'mailing.senders.table.sendersEmail',
        grow: true,
        small: false,
        type: ColumnTypeEnum.text,
        name: 'email',
        postIcons: true,
    },
    {
        title: 'mailing.senders.table.sendersName',
        type: ColumnTypeEnum.text,
        name: 'name',
        minWidth: '280px',
        maxWidth: '280px',
    },
    {
        title: 'mailing.senders.table.ackn',
        grow: false,
        small: true,
        maxWidth: '300px',
        minWidth: '300px',
        type: ColumnTypeEnum.button,
        name: 'btns',
    },
];

const configPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

const initialParams = {
    pagination: configPagination,
    sorted: null,
    filter: [
        ...mailingSendersCheckboxes,
        // drop down
        {
            property: 'name',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true,
        },
        {
            property: 'email',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true,
        },
    ],
};

@State<MailingSerndersStateModel>({
    name: MAILING_SENDERS_STATE,
    defaults: {
        senders: [],
        sendersRows: [],
        columns,
        params: initialParams,
    },
})
@Injectable()
export class MailingSendersState {
    constructor(
        private http: HttpClient,
        private mailingUserService: MailingUserService,
        private notificationsService: NotificationsService,
    ) {}

    @Selector()
    static getSenders(state: MailingSerndersStateModel): SenderUserInterface[] {
        return state.senders;
    }

    @Selector()
    static getInviteSenders(state: MailingSerndersStateModel): SenderUserInterface[] {
        return state.senders.filter((f) => f.status === StatusNotificationShare.PROCESS);
    }

    @Selector()
    static getSendersParams(state: MailingSerndersStateModel): Params {
        return state.params;
    }

    @Selector()
    static getSendersColumn(state: MailingSerndersStateModel): ColumnsTableInterface[] {
        return state.columns;
    }

    @Selector()
    static getSendersRows(state: MailingSerndersStateModel): SenderUserRowInterface[] {
        return state.sendersRows;
    }

    @Action(GetMailingSenders)
    async getMailingSenders(ctx: StateContext<MailingSerndersStateModel>): Promise<void> {
        const result: ApiResponse<SenderUserInterface[]> = (await this.http
            .get('/api/notification-user/senders')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<SenderUserInterface[]>;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                senders: result.data,
            });
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
            ctx.setState({
                ...state,
            });
        }

        ctx.dispatch(new InitMailingSenderRows());
    }

    @Action(InitMailingSenderRows)
    initMailingSenderRows(ctx: StateContext<MailingSerndersStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            sendersRows: this.mailingUserService.getSendersRows(state.senders, state.params),
        });
    }

    @Action(AcceptSender)
    async acceptSender(ctx: StateContext<MailingSerndersStateModel>, payload: AcceptSender): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/notification-user/accept-sender', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                senders: state.senders.map((s) => {
                    if (s.id === result.data.id) {
                        return result.data;
                    }
                    return s;
                }),
            });

            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
            });
        }

        ctx.dispatch(new InitMailingSenderRows());
    }

    @Action(SetSendersParams)
    setRecipientParams(ctx: StateContext<MailingSerndersStateModel>, payload: SetSendersParams): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: payload.params,
        });

        ctx.dispatch(new InitMailingSenderRows());
    }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserProfileInfoTypeDateEnum } from 'src/app/profile/_enums/user-profile-info-date-time.enum';
import { ReportSettingsInterface } from 'src/app/reports/_interfaces/ReportSettings';
import { LuxonDatePipe } from '../_pipes/luxon-date.pipe';
import { LanguageState } from '../_store/states/language.state';
import { UserState } from '../_store/states/user.state';
import { LuxonParseDateService } from './luxon-parse-date.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class DataTypeService {
    monthArray: { name: string; num: number }[] = [
        {
            name: 'month.January',
            num: 1,
        },
        {
            name: 'month.February',
            num: 2,
        },
        {
            name: 'month.March',
            num: 3,
        },
        {
            name: 'month.April',
            num: 4,
        },
        {
            name: 'month.May',
            num: 5,
        },
        {
            name: 'month.June',
            num: 6,
        },
        {
            name: 'month.July',
            num: 7,
        },
        {
            name: 'month.August',
            num: 8,
        },
        {
            name: 'month.September',
            num: 9,
        },
        {
            name: 'month.October',
            num: 10,
        },
        {
            name: 'month.November',
            num: 11,
        },
        {
            name: 'month.December',
            num: 12,
        },
    ];

    public readonly GMT = 'GMT';
    public readonly dateFormat = 'dd/MM/yyyy HH:mm:ss';
    public readonly shortDateFormat = 'dd/MM/yyyy';
    constructor(
        private store: Store,
        private luxonDatePipe: LuxonDatePipe,
        private luxonParseDateService: LuxonParseDateService,
        private translateService: TranslateService,
    ) {}

    reformatDate(date: Date | number): string {
        if (!date) {
            return '';
        }

        if (typeof date === 'number') {
            date = new Date(date);
        }
        const user = this.store.selectSnapshot(UserState.getUser);
        const ln = this.store.selectSnapshot(LanguageState.getLanguage);

        if (!user) {
            return this.luxonDatePipe.transform(new Date(date).toISOString(), this.dateFormat, this.GMT, ln);
        }
        return this.luxonDatePipe.transform(
            new Date(date).toISOString(),
            user.dateFormat || this.dateFormat,
            user.dateTimeZone || this.GMT,
            ln,
        );
    }

    reformatDateByLuxon(date: Date | number): string {
        const mills = new Date(date).getTime();
        const user = this.store.selectSnapshot(UserState.getUser);
        // this.luxonParseDateService.getTimeByTimezone(mills, )
        return;
    }

    reformateDateByServerTimezone(date: Date | number, timeZone, locale?): string {
        if (!date) {
            return '';
        }

        if (typeof date === 'number') {
            date = new Date(date);
        }

        if (!locale) {
            locale = 'ua';
        }

        return date.toLocaleString(locale, { timeZone });
    }

    reformatTime(dateMills: number, report?: ReportSettingsInterface): string {
        const user = this.store.selectSnapshot(UserState.getUser);
        let deviation: number;
        let date: string;
        const ln = this.store.selectSnapshot(LanguageState.getLanguage);

        if (!user) {
            if (report && report.id) {
                date = this.luxonDatePipe.transform(dateMills, report.dateFormat || this.dateFormat, report.timezone || this.GMT, ln);
                deviation = this.getDeviation(report.dateFormat);
                return date.slice(date.length - deviation);
            }

            date = this.luxonDatePipe.transform(dateMills, this.dateFormat, this.GMT, ln);
            date = this.luxonDatePipe.transform(dateMills, report.dateFormat || this.dateFormat, report.timezone || this.GMT, ln);
            deviation = this.getDeviation(this.dateFormat);
            return date.slice(date.length - deviation);
        }

        date = this.luxonDatePipe.transform(dateMills, user.dateFormat || this.dateFormat, user.dateTimeZone || this.GMT, ln);
        deviation = this.getDeviation(user.dateFormat);

        return date.slice(date.length - deviation);
    }

    getDeviation(dateFormat): number {
        switch (dateFormat) {
            case UserProfileInfoTypeDateEnum.ddMMyyyy:
                return 8;
            case UserProfileInfoTypeDateEnum.mmDDyyyy:
                return 11;
            default:
                return 8;
        }
    }

    reformatShortDate(dateMills: number): string {
        const user = this.store.selectSnapshot(UserState.getUser);
        let index;
        let date: string;
        const options: any = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
        };

        const ln = this.store.selectSnapshot(LanguageState.getLanguage);

        if (!user) {
            return new Date(dateMills).toLocaleString('ru', options);
        }

        if (user.dateFormat) {
            index = user.dateFormat.indexOf(' ');
        }

        if (!index || index === -1) {
            return this.luxonDatePipe.transform(dateMills, 'dd/MM/yyyy', user.dateTimeZone || this.GMT, ln);
        }

        const format = user.dateFormat.length ? user.dateFormat.slice(0, index) : 'dd/MM/yyyy';
        if (dateMills === 0) {
            return new Date(dateMills).toLocaleString('ru', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
        }
        date = this.luxonDatePipe.transform(dateMills, format, user.dateTimeZone || this.GMT, ln);
        return date;
        const parseDate = new Date(date).getTime();

        const locale = 'ru';

        switch (user.dateFormat) {
            case UserProfileInfoTypeDateEnum.ddMMyyyy:
                options.hour12 = false;
                break;
            case UserProfileInfoTypeDateEnum.mmDDyyyy:
                options.hour12 = true;
                options.month = 'short';
                break;
            default:
                // locale = this.dateFormat;
                break;
        }
        return new Date(dateMills).toLocaleString(locale, options);
    }

    reformateGmt(dateMills: number, customTimeZone?: string): string {
        const user = this.store.selectSnapshot(UserState.getUser);
        const offset = this.getTimezoneOffset(customTimeZone) ? this.getTimezoneOffset(customTimeZone) / 60 : 0;
        if (!user) {
            return '';
        }
        return `${user.dateTimeZone} ${offset > 0 ? '+' : ''}${offset}`;
    }

    getTimezoneOffset(customTimeZone?: string): number {
        const user = this.store.selectSnapshot(UserState.getUser);
        try {
            if (customTimeZone) {
                return this.luxonParseDateService.getCurrentOffset(customTimeZone);
            }

            if (!user) {
                return 0;
            }

            return this.luxonParseDateService.getCurrentOffset(user.dateTimeZone) || 0;
        } catch (e) {
            console.log(e);
            return 0;
        }
    }

    async getCurrentMonthName(num: number): Promise<string> {
        const currentMonth = this.monthArray.find((f) => f.num === num);
        if (currentMonth) {
            return await this.translateService.get(currentMonth.name).toPromise();
        }
    }
}

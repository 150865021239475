import {AppSharedElementsModule} from '../app-shared-elements/app-shared-elements.module';
import {UserProfileInfoComponent} from './user-profile/user-profile-info/user-profile-info.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ProfileRoutingModule} from './profile-routing.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {QRCodeModule} from 'angularx-qrcode';
import {AngularSvgIconModule} from 'angular-svg-icon';


@NgModule({
    declarations: [
        UserProfileComponent,
        UserProfileInfoComponent
    ],
    imports: [
        CommonModule,
        AppSharedElementsModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        ProfileRoutingModule,
        DragDropModule,
        QRCodeModule,
        AngularSvgIconModule.forRoot(),
    ],
    exports: [
        AppSharedElementsModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        UserProfileInfoComponent,
    ]
})
export class ProfileSharedModule { }

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { TypeClient } from 'src/app/admin/users/_enum/type-client.enum';
import { NavigationItemInterface } from 'src/app/app-template/_interfaces/navigation-item.interface';
import { MethodPermission, ResourceAction } from '../../_enums/permission.enum';
import { PermissionService } from '../../_services/permission.service';
import {
    ChangeUserNavigation,
    ParseNavigationForStoreTask,
    ParseNavigationForTransportTask,
    SetCurrentNavigationAdminList,
    SetCurrentNavigationUserList,
} from '../actions/nav.actions';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { Device } from '../../_interfaces/Device';
import { VariablesNameEnum } from '../../_enums/variables-name.enum';

export interface NavStateModel {
    navigationAdminList: NavigationItemInterface[];
    navigationList: NavigationItemInterface[];
}

const NAV_STATE_TOKEN = new StateToken<NavStateModel>('navState');

const navigationList: NavigationItemInterface[] = [
    {
        name: 'navigation.userDevices',
        action: false,
        show: true,
        toggle: false,
        children: [
            {
                name: 'navigation.userDevices',
                link: '/device-dashboard',
                show: true,
                isSyncIcon: true,
            },
            {
                link: '/details/details-dashboard',
                name: 'navigation.detailsDevice',
                show: false,
            },
            {
                link: '/details/details-device',
            },
            {
                link: '/details',
            },
            {
                link: '/details/role-info',
            },
            {
                link: '/create-devices',
                name: 'navigation.createDevices',
                show: true,
            },
            {
                link: '/copy-configuration',
                name: 'copyConfiguration.title',
                show: false,
                isAvailableDevices: true,
            },
            {
                link: '/deleted-devices',
                name: 'navigation.deletedDevices',
                show: true,
                isAvailableDevices: true,
            },
            // {
            //     link: '/generation-key',
            //     name: 'navigation.generationKey',
            //     show: true,
            // },
            {
                link: '/stats',
                name: 'navigation.stats',
                show: false,
            },
        ],
    },
    {
        name: 'navigation.dashboards',
        toggle: false,
        action: false,
        show: false,
        children: [
            {
                link: '/group-container/groups-dashboard',
                name: 'groupsDashboard.title',
            },
            {
                link: '/group-container/map/device-map',
                name: 'navigation.map',
            },
            {
                link: '/transport-container/transport-db',
                name: 'navigation.transport',
            },
            // {
            //     link: '/transport-container/train-db',
            //     name: 'navigation.train',
            // },
            {
                link: '/expeditions',
                name: 'navigation.expeditions',
            },
            {
                link: '/mnemonic/dashboard',
                name: 'navigation.mnemonic',
            },
            {
                link: 'mnemonic/constructor',
            },
        ],
    },
    {
        name: 'navigation.events',
        action: false,
        toggle: false,
        show: false,
        children: [
            {
                link: '/logical-events/event-message-catalog',
                name: 'events.messagesCatalog.heading',
            },
            {
                link: '/logical-events',
                name: 'events.logicalEvents.heading',
            },
        ],
    },
    {
        name: 'navigation.journal',
        toggle: false,
        action: false,
        show: true,
        children: [
            {
                link: '/journals/events-log',
                name: 'heading.eventLog',
                show: true,
            },
            {
                link: '/journals/device-log',
                name: 'navigation.deviceLog',
                show: true,
            },
            {
                link: '/journals/user-log',
                name: 'navigation.userLog',
                show: true,
            },
            {
                link: '/journals/mailing-log',
                name: 'navigation.mailingLogs',
                show: true,
            },
            {
                link: '/journals/report-log',
                name: 'navigation.reportLog',
                show: true,
            },
        ],
    },
    {
        name: 'navigation.reports',
        action: false,
        show: false,
        toggle: false,
        children: [
            {
                link: '/reports',
                name: 'navigation.reports',
            },
            {
                link: '/reports/reports-constructor/',
            },
            {
                link: '/digital-signature',
                name: 'navigation.certificate',
                show: true,
            },
        ],
    },
    {
        link: '/mailing/recipient',
        action: false,
        show: true,
        name: 'navigation.mailing',
        children: [
            {
                link: '/mailing/groups',
            },
            {
                link: '/mailing/recipient',
            },
            {
                link: '/mailing/sender',
            },
        ],
        isTabs: true,
    },
];

const navigationAdminList: NavigationItemInterface[] = [
    {
        name: 'navigation.userDevices',
        action: false,
        toggle: false,
        children: [
            {
                link: '/control/devices',
                name: 'navigation.userDevices',
                permissionResourceAction: ResourceAction.DEVICES,
            },
            {
                link: '/control/details/details-dashboard',
                name: 'navigation.detailsDevice',
                permissionResourceAction: ResourceAction.DEVICES,
            },
            {
                link: '/control/devices-control',
                name: 'devicesControl.title',
                permissionResourceAction: ResourceAction.DATALOGGER_SETTINGS,
            },
            {
                link: '/control/details/details-device',
            },
            {
                link: '/control/details',
            },
            {
                link: '/control/details/role-info',
            },
        ],
    },
    {
        name: 'navigation.admin.admins',
        toggle: false,
        action: false,
        children: [
            {
                link: '/control/admins/list',
                name: 'navigation.admin.admins',
                permissionResourceAction: ResourceAction.ADMINS,
            },
            {
                link: '/control/admins/roles',
                name: 'navigation.admin.role',
                permissionResourceAction: ResourceAction.ADMINS_ROLE,
            },
        ],
    },
    {
        name: 'navigation.admin.user',
        toggle: false,
        action: false,
        children: [
            {
                link: '/control/users',
                name: 'navigation.admin.user',
                permissionResourceAction: ResourceAction.USERS,
            },
            {
                link: '/control/roles',
                name: 'navigation.admin.role',
                permissionResourceAction: ResourceAction.USERS_ROLE,
            },
            {
                link: '/control/company',
                name: 'navigation.admin.company',
                permissionResourceAction: ResourceAction.USERS,
            },
        ],
    },
    {
        name: 'navigation.admin.finances',
        toggle: false,
        action: false,
        show: false,
        children: [
            {
                link: '/control/finances/debtors',
                name: 'navigation.admin.debtors',
                permissionResourceAction: ResourceAction.BILLING,
            },
            {
                link: '/control/finances/invoice',
                name: 'navigation.admin.invoice',
                permissionResourceAction: ResourceAction.BILLING,
            },
            {
                link: '/control/finances/services',
                name: 'navigation.admin.services',
                permissionResourceAction: ResourceAction.BILLING,
            },
            {
                link: '/control/finances/pay-info',
                name: 'navigation.admin.payInfo',
                permissionResourceAction: ResourceAction.BILLING,
            },
        ],
    },
    {
        name: 'navigation.journal',
        toggle: false,
        action: false,
        show: false,
        children: [
            {
                link: '/control/journals/events-log',
                name: 'heading.eventLog',
                permissionResourceAction: ResourceAction.USER_BROWSING,
            },
            {
                link: '/control/journals/device-log',
                name: 'navigation.deviceLog',
                permissionResourceAction: ResourceAction.USER_BROWSING,
            },
            {
                link: '/control/journals/admin-log',
                name: 'navigation.admin.adminLogs',
                permissionResourceAction: ResourceAction.ADMIN_LOGS,
            },
        ],
    },
    {
        name: 'navigation.admin.general',
        toggle: false,
        action: false,
        children: [
            {
                link: '/control/units',
                name: 'navigation.admin.units',
                permissionResourceAction: ResourceAction.UNITS,
            },
            {
                link: '/control/mail-message',
                name: 'navigation.admin.mailMessage',
                permissionResourceAction: ResourceAction.DEVICES_MESSAGE,
            },
            {
                link: '/control/servers',
                name: 'navigation.admin.server',
                permissionResourceAction: ResourceAction.SERVER_INFO,
            },
            {
                link: '/control/export-import',
                name: 'navigation.admin.exportImport',
                permissionResourceAction: ResourceAction.EXPORT_IMPORT,
            },
            {
                link: '/control/system-settings',
                name: 'systemSettings.title',
                permissionResourceAction: ResourceAction.CONSTANT,
            },
        ],
    },
    {
        name: 'navigation.events',
        toggle: false,
        action: false,
        children: [
            {
                link: '/control/event-messages/status-messages',
                name: 'navigation.admin.statusMessage',
                permissionResourceAction: ResourceAction.STATUS_MESSAGES,
            },
            {
                link: '/control/event-messages/limit-messages',
                name: 'navigation.admin.limitMessage',
                permissionResourceAction: ResourceAction.LIMIT_MESSAGES,
            },
        ],
    },
];

@State<NavStateModel>({
    name: NAV_STATE_TOKEN,
    defaults: {
        navigationAdminList,
        navigationList,
    },
})
@Injectable()
export class NavState {
    constructor(private permissionsService: PermissionService) {}

    @Selector()
    static getAdminNavigationList(state: NavStateModel): NavigationItemInterface[] {
        return state.navigationAdminList;
    }

    @Selector()
    static getNavigationList(state: NavStateModel): NavigationItemInterface[] {
        return state.navigationList;
    }

    @Selector([DevicesState.getRegistrators])
    static getIsExpedition(state: NavStateModel, registrators: Device[]): boolean {
        const result = registrators.reduce((prev, curr) => {
            if (prev) {
                return prev;
            }
            const currentVariable = curr.variables.find((v) => v.name === VariablesNameEnum.ExpectExpedition);
            return currentVariable ? currentVariable.currentValue : false;
        }, false);

        return result;
    }

    @Action(ParseNavigationForStoreTask)
    parseNavigationForStoreTask(ctx: StateContext<NavStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            navigationList: state.navigationList.map((item) => {
                return {
                    ...item,
                    children: item.children
                        .filter((child) => child.link !== '/expeditions')
                        .filter((child) => child.link !== '/transport-container/transport-db'),
                };
            }),
        });
    }

    @Action(SetCurrentNavigationUserList)
    setCurrentNavigationUserList(ctx: StateContext<NavStateModel>): void {
        const state = ctx.getState();
    }

    @Action(SetCurrentNavigationAdminList)
    setCurrentNavigationAdminList(ctx: StateContext<NavStateModel>, payload: SetCurrentNavigationAdminList): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            navigationAdminList: state.navigationAdminList
                .map((item) => {
                    return {
                        ...item,
                        children: item.children.filter((child) => {
                            if (!child.permissionResourceAction) {
                                return child;
                            }
                            const data = {
                                registrator: null,
                                action: child.permissionResourceAction,
                                permissions: [MethodPermission.READ],
                            };
                            if (this.permissionsService.checkPermission(payload.permissions, data, true)) {
                                return child;
                            }
                        }),
                    };
                })
                .filter((item) => item.link || (item.children && item.children.length)),
        });
    }

    @Action(ChangeUserNavigation)
    changeNavigation(ctx: StateContext<NavStateModel>, payload: ChangeUserNavigation): void {
        const state = ctx.getState();
        switch (payload.typeClient) {
            case TypeClient.Admin:
                ctx.setState({
                    ...state,
                    navigationAdminList: payload.navigation,
                });
                return;
            case TypeClient.User:
                ctx.setState({
                    ...state,
                    navigationList: payload.navigation,
                });
                return;
            default:
                ctx.setState({
                    ...state,
                });
        }
    }

    @Action(ParseNavigationForTransportTask)
    parseNavigationForTransportTask(ctx: StateContext<NavStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            navigationList: JSON.parse(JSON.stringify(navigationList)),
        });
    }
}

import {
    ConfigurationVariableSavedEnum,
    Variable,
    VariableForControlRowInterface,
    VariableType,
} from '../../../app-shared-elements/_interfaces/Variable';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { CloneDeepService } from '../../../app-shared-elements/_services/clone-deep.service';
import { GroupControlService } from '../../_services/group-control.service';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import {
    RevertNewValueInVariable,
    SetNewValueInVariable,
    SetVariablesForControl,
    UpdateVariablesStatusSavedForControlGroupRows,
} from '../actions/control-groups.action';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ColumnsTableInterface, ColumnTypeEnum, ColumnValueAlignEnum } from '../../../app-shared-elements/_interfaces/ColumnsTable';
import { TableNamesEnum } from '../../../app-shared-elements/_enums/table-names.enum';
import { PermissionService } from '../../../app-shared-elements/_services/permission.service';
import { DeviceTypeEnum } from '../../../app-shared-elements/_enums/device-type.enum';
import { MethodPermission, ResourceAction } from '../../../app-shared-elements/_enums/permission.enum';

export interface ControlGroupsStateModel {
    variableForControl: Variable[];
    variableForControlRows: VariableForControlRowInterface[];
    controlGroupsColumns: ColumnsTableInterface[];
}

const CONTROL_GROUPS_TOKEN = new StateToken<ControlGroupsStateModel>('controlGroups');

const initControlGroupsColumns: ColumnsTableInterface[] = [
    {
        title: 'table.groupControl.variable',
        grow: true,
        small: false,
        name: 'variableName',
        type: ColumnTypeEnum.text,
        postIcons: true,
    },
    {
        title: 'table.groupControl.currentValue',
        grow: false,
        maxWidth: '200px',
        minWidth: '200px',
        small: false,
        name: 'currentValue',
        type: ColumnTypeEnum.text,
        align: ColumnValueAlignEnum.right,
    },
    {
        title: 'table.groupControl.newValue',
        grow: false,
        maxWidth: '250px',
        minWidth: '250px',
        small: false,
        name: 'valueToSave',
        isCustomType: true,
        align: ColumnValueAlignEnum.right,
        isEditable: true,
        isCheckPermission: true,
        tableName: TableNamesEnum.controlGroupTable,
    },
    {
        title: 'table.groupControl.range',
        grow: false,
        maxWidth: '200px',
        minWidth: '200px',
        small: false,
        name: 'range',
        type: ColumnTypeEnum.text,
        align: ColumnValueAlignEnum.right,
    },
    {
        title: 'table.groupControl.type',
        grow: false,
        maxWidth: '170px',
        small: false,
        name: 'translateType',
        type: ColumnTypeEnum.text,
    },
];

@State<ControlGroupsStateModel>({
    name: CONTROL_GROUPS_TOKEN,
    defaults: {
        variableForControl: [],
        variableForControlRows: [],
        controlGroupsColumns: initControlGroupsColumns,
    },
})
@Injectable()
export class ControlGroupsState {
    constructor(
        private cloneDeepService: CloneDeepService,
        private groupControlService: GroupControlService,
        private notificationsService: NotificationsService,
        private store: Store,
        private http: HttpClient,
        private permissionService: PermissionService,
    ) {}

    @Selector()
    static getVariableForControl(state: ControlGroupsStateModel): Variable[] {
        return state.variableForControl;
    }

    @Selector()
    static getVariableForControlRows(state: ControlGroupsStateModel): VariableForControlRowInterface[] {
        return state.variableForControlRows;
    }

    @Selector()
    static getControlGroupsColumns(state: ControlGroupsStateModel): ColumnsTableInterface[] {
        return state.controlGroupsColumns;
    }

    @Action(SetVariablesForControl)
    async setVariablesForControl(ctx: StateContext<ControlGroupsStateModel>, payload: SetVariablesForControl): Promise<void> {
        const state = ctx.getState();
        const currentGroup = payload.currentGroup;
        const currentDevice: Device = this.store.selectSnapshot(DevicesState.getCurrentDevice);
        const variables: Variable[] = currentDevice.variables.filter((variable) =>
            currentGroup.variableGroupSettings.find(
                (setting) => setting.variableId === variable.id && setting.showOnControl && variable.writable === 1,
            ),
        );

        let variableForControlRows: VariableForControlRowInterface[] = await Promise.all(
            variables.map(async (variable) => {
                return {
                    variableName: variable.customName ? variable.customName : variable.name,
                    currentValue: variable.currentValue,
                    valueToSave: variable.valueToSave,
                    type: this.groupControlService.getValidationType(variable),
                    range:
                        variable.type === VariableType.DATE
                            ? null
                            : await this.groupControlService.getTranslateRangeValue(variable.type, variable.restriction),
                    id: variable.id,
                    disableClick: !this.permissionService.checkPermissionComponent({
                        registratorId: currentDevice.type === DeviceTypeEnum.registrator ? currentDevice.id : currentDevice.registratorId,
                        action: ResourceAction.GROUP_CONTROL,
                        method: [MethodPermission.UPDATE],
                    }),
                    columnType: variable.type === VariableType.DATE ? ColumnTypeEnum.datePicker : ColumnTypeEnum.text,
                    savedStatusValue: variable.savedStatusValue,
                    translateType: await this.groupControlService.getTranslateVariableType(variable.type),
                    postIcons: this.groupControlService.getPostIcons(variable),
                    variable,
                };
            }),
        );
        variableForControlRows = variableForControlRows.sort((a, b) => (a.variableName > b.variableName ? 1 : -1));

        ctx.setState({
            ...state,
            variableForControl: variables,
            variableForControlRows,
        });
    }

    @Action(UpdateVariablesStatusSavedForControlGroupRows)
    updateVariablesForControlGroupRows(
        ctx: StateContext<ControlGroupsStateModel>,
        payload: UpdateVariablesStatusSavedForControlGroupRows,
    ): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            variableForControl: state.variableForControl.map((variable) => {
                const currentVariable = payload.variables.find((v) => v.id === variable.id && v.deviceId === variable.deviceId);

                if (currentVariable) {
                    variable = this.cloneDeepService.cloneObject(currentVariable);
                }

                return variable;
            }),
            variableForControlRows: state.variableForControlRows.map((row) => {
                const variable = payload.variables.find((v) => v.id === row.id);
                return {
                    ...row,
                    currentValue: variable.currentValue,
                    valueToSave: variable.valueToSave,
                    savedStatusValue: variable.savedStatusValue,
                    postIcons: this.groupControlService.getPostIcons(variable),
                    variable,
                };
            }),
        });
    }

    @Action(SetNewValueInVariable)
    async setNewValueInVariable(ctx: StateContext<ControlGroupsStateModel>, payload: SetNewValueInVariable): Promise<void> {
        const state = ctx.getState();
        const currentRegistrator: Device = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);
        const headers = new HttpHeaders({ registratorId: currentRegistrator ? currentRegistrator.id : '' });
        if (
            payload.event.row.currentValue === payload.event.event &&
            payload.event.row.savedStatusValue !== ConfigurationVariableSavedEnum.wait
        ) {
            ctx.setState({
                ...state,
                variableForControlRows: state.variableForControlRows.map((row) => {
                    return {
                        ...row,
                        valueToSave: '',
                        // postIcons: this.groupControlService.getPostIcons(payload.event.row.variable, true)
                    };
                }),
            });

            return;
        }

        const result: ApiResponse = (await this.http
            .post('/api/groups/set-variables-values', { id: payload.event.row.id, value: payload.event.event }, { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        if (!result || result.status !== HTTP_STATUS.SUCCESS) {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);

            ctx.setState({
                ...state,
            });

            return;
        }

        ctx.setState({
            ...state,
            variableForControl: state.variableForControl.map((variable) => {
                if (variable.id === result.data.id) {
                    variable = result.data;
                }

                return variable;
            }),
            variableForControlRows: state.variableForControlRows.map((row) => {
                if (row.id === payload.event.row.id) {
                    row.savedStatusValue = ConfigurationVariableSavedEnum.wait;
                    return {
                        ...row,
                        postIcons: this.groupControlService.getPostIcons(result.data),
                        variable: result.data,
                        valueToSave: result.data.valueToSave,
                    };
                }

                return {
                    ...row,
                };
            }),
        });
    }

    @Action(RevertNewValueInVariable)
    async revertNewValueInVariable(ctx: StateContext<ControlGroupsStateModel>, payload: RevertNewValueInVariable): Promise<void> {
        const state = ctx.getState();

        const currentRegistrator: Device = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);

        const headers = new HttpHeaders({ registratorId: currentRegistrator ? currentRegistrator.id : '' });

        const result: ApiResponse = (await this.http
            .post('/api/groups/revert-variable', { variableId: payload.variableId }, { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }
    }
}

export enum FilterDropdownDeviceLogKeyEnum {
    registratorId = 'registratorId',
    internalId = 'internalId',
    deviceName = 'registratorId'
}

export enum FilterDropdownUserLogKeyEnum {
    userLogin = 'userLogin',
    userIp = 'userIp',
    internalId = 'internalId',
    srcId = 'srcId'
}

import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {Breadcrumb} from '../../_interfaces/Breadcrumbs';
import {ClearStateBreadcrumbs, SetBreadcrumbs} from '../actions/breadcrumbs.actions';

export interface BreadcrumbsStateModel {
    breadcrumbs: Breadcrumb;
}

const BREADCRUMBS_TOKEN = new StateToken<BreadcrumbsStateModel>('breadcrumbsState');

@State<BreadcrumbsStateModel>({
    name: BREADCRUMBS_TOKEN,
    defaults: {
        breadcrumbs: null
    }
})
@Injectable()
export class BreadcrumbsState {
    constructor(
    ) {

    }

    @Selector()
    static getBreadcrumbs(state: BreadcrumbsStateModel): Breadcrumb {
        return state.breadcrumbs;
    }

    @Action(SetBreadcrumbs)
    setBreadcrumbs(ctx: StateContext<BreadcrumbsStateModel>, payload: SetBreadcrumbs): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            breadcrumbs: payload.breadcrumbs
        });
    }

    @Action(ClearStateBreadcrumbs)
    clearStateBreadcrumbs(ctx: StateContext<BreadcrumbsStateModel>): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            breadcrumbs: null
        });
    }
}

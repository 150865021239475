import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[appSaveScroll]',
})
export class SaveScrollDirective {
    _scroll: { isScroll: boolean; saveValue: number };

    @Input('appSaveScroll')
    set saveScroll(scroll) {
        this._scroll = scroll;

        if (!scroll || !scroll.isScroll || !scroll.saveValue) {
            return;
        }

        setTimeout(() => {
            this.el.nativeElement.scrollTop = scroll.saveValue;
        }, 100);
    }

    get saveScroll(): { isScroll: boolean; saveValue: number } {
        return this._scroll;
    }

    constructor(private el: ElementRef<HTMLInputElement>) {}
}

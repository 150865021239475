import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { LuxonDatePipe } from 'src/app/app-shared-elements/_pipes/luxon-date.pipe';
import { LanguageState } from 'src/app/app-shared-elements/_store/states/language.state';
import { UserState } from 'src/app/app-shared-elements/_store/states/user.state';
import { MobileCardInterface } from '../../_interfaces/mobile-card.interface';
import { MobileEventsService } from '../../_services/mobile-events.service';
import { InitMobileEventCards } from '../actions/mobile-events.actions';

export interface MobileEventsStateModel {
    eventCards: MobileCardInterface[];
}

const MOBILE_EVENTS_STATE_TOKEN = new StateToken<MobileEventsStateModel>('mobileEventsStateToken');

const propertiesForEventCard: { title: string; prop: string }[] = [
    {
        title: 'logs.events.tables.type',
        prop: 'type',
    },
    {
        title: 'logs.events.tables.ackn',
        prop: 'ackn',
    },
];

@State<MobileEventsStateModel>({
    name: MOBILE_EVENTS_STATE_TOKEN,
    defaults: {
        eventCards: [],
    },
})
@Injectable()
export class MobileEventsState {
    constructor(
        private store: Store,
        private mobileEventsService: MobileEventsService,
        private luxonDatePipe: LuxonDatePipe,
    ) {}

    @Selector()
    static getEventCards(state: MobileEventsStateModel): MobileCardInterface[] {
        return state.eventCards;
    }

    @Action(InitMobileEventCards)
    initMobileEventCards(ctx: StateContext<MobileEventsStateModel>, payload: InitMobileEventCards): void {
        const user = this.store.selectSnapshot(UserState.getUser);
        const ln = this.store.selectSnapshot(LanguageState.getLanguage);

        const state = ctx.getState();

        ctx.setState({
            ...state,
            eventCards: payload.eventRows.map((row) => {
                return {
                    start: this.luxonDatePipe.transform(new Date(row.ts).getTime(), user.dateFormat, user.dateTimeZone, ln),
                    finish: row.tsCleared.time
                        ? this.luxonDatePipe.transform(new Date(row.tsCleared.time).getTime(), user.dateFormat, user.dateTimeZone, ln)
                        : '-',
                    main: row.message,
                    items: this.mobileEventsService.getEventCardItems(row, propertiesForEventCard, user, ln),
                    headerStyles: this.mobileEventsService.getHeaderCardStyle(row),
                };
            }),
        });
    }
}

import { Injectable } from '@angular/core';
import { UserProfileInfoTypeElementEnum } from '../../profile/_enums/user-profile-info-type.enum';
import { SelectOptionInterface } from '../../app-shared-elements/_interfaces/select-option.interface';
import { BillingAccountInfoInterface } from '../_interfaces/billing-account-info.interface';
import { BillingAccountInfoTypeEnum, BillingPayerOptionsTypeEnum } from '../_enums/billing-account-info-type.enum';
import { BillingPersonalAccountInterface } from '../_interfaces/billing-personal-account.interface';
import { Store } from '@ngxs/store';
import { CountryStateCityState } from '../../app-shared-elements/_store/states/country-state-city.state';

@Injectable({
    providedIn: 'root',
})
export class BillingAccountService {
    constructor(private store: Store) {}

    getBillingAccountInfo(info: BillingPersonalAccountInterface): BillingAccountInfoInterface[] {
        const userProfileInfoArray: BillingAccountInfoInterface[] = [
            {
                title: 'billing.info.payer',
                value: +info.payer as BillingPayerOptionsTypeEnum,
                elementType: UserProfileInfoTypeElementEnum.select,
                type: BillingAccountInfoTypeEnum.payer,
                inputPlaceholder: 'billing.info.placeholders.payer',
            },
            {
                title:
                    info.payer === BillingPayerOptionsTypeEnum.INDIVIDUAL
                        ? 'billing.info.payerName'
                        : 'billing.info.company',
                value: info.name,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.name,
                inputPlaceholder: 'billing.info.placeholders.name',
            },
            {
                title: 'billing.info.edrpou',
                value: info.edrpou,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.edrpou,
                inputPlaceholder: 'billing.info.placeholders.edrpou',
            },
            {
                title: 'billing.info.account',
                value: info.paymentAccount,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.paymentAccount,
                inputPlaceholder: 'billing.info.placeholders.account',
            },
            {
                title: 'billing.info.email',
                value: info.email,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.email,
                inputPlaceholder: 'billing.info.placeholders.email',
            },
            {
                title: 'billing.info.phone',
                value: info.phone,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.phone,
                inputPlaceholder: 'billing.info.placeholders.phone',
            },
            {
                title: 'billing.info.country',
                value: info.country,
                elementType: UserProfileInfoTypeElementEnum.select,
                type: BillingAccountInfoTypeEnum.country,
                inputPlaceholder: 'billing.info.placeholders.country',
            },
            {
                title: 'billing.info.index',
                value: info.postcode,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.postcode,
                inputPlaceholder: 'billing.info.placeholders.index',
            },
            {
                title: 'billing.info.region',
                value: info.region,
                elementType: UserProfileInfoTypeElementEnum.select,
                type: BillingAccountInfoTypeEnum.region,
                inputPlaceholder: 'billing.info.placeholders.region',
            },
            {
                title: 'billing.info.city',
                value: info.city,
                elementType: UserProfileInfoTypeElementEnum.select,
                type: BillingAccountInfoTypeEnum.city,
                inputPlaceholder: 'billing.info.placeholders.city',
            },
            {
                title: 'billing.info.address',
                value: info.address,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.address,
                inputPlaceholder: 'billing.info.placeholders.address',
            },
            {
                title: 'billing.info.isVat',
                value: info.isVat,
                elementType: UserProfileInfoTypeElementEnum.checkbox,
                type: BillingAccountInfoTypeEnum.isVat,
                children: [
                    {
                        title: 'billing.info.isVat',
                        value: info.vat,
                        inputPlaceholder: 'billing.info.placeholders.vat',
                        elementType: UserProfileInfoTypeElementEnum.input,
                        type: BillingAccountInfoTypeEnum.vat,
                    },
                ],
            },
            {
                title: 'billing.info.isDiscount',
                value: info.isDiscount,
                isAdmin: true,
                elementType: UserProfileInfoTypeElementEnum.checkbox,
                type: BillingAccountInfoTypeEnum.isDiscount,
                children: [
                    {
                        title: 'billing.info.isDiscount',
                        inputPlaceholder: 'billing.info.placeholders.discount',
                        value: info.discount,
                        elementType: UserProfileInfoTypeElementEnum.input,
                        type: BillingAccountInfoTypeEnum.discount,
                    },
                ],
            },
        ];

        return this.parseInfo(userProfileInfoArray);
    }

    parseInfo(info: BillingAccountInfoInterface[]): BillingAccountInfoInterface[] {
        return info.map((item) => {
            switch (item.type) {
                case BillingAccountInfoTypeEnum.country:
                    const countryOptions = this.initCountryOptions();

                    return {
                        ...item,
                        options: countryOptions,
                        currentOption: item.value ? countryOptions.find((option) => option.value === item.value) : null,
                    };

                case BillingAccountInfoTypeEnum.region:
                    const regionOptions = this.initRegionOptions();

                    return {
                        ...item,
                        options: regionOptions,
                        currentOption: item.value ? regionOptions.find((option) => option.value === item.value) : null,
                    };

                case BillingAccountInfoTypeEnum.city:
                    const cityOptions = this.initCityOptions();

                    return {
                        ...item,
                        options: cityOptions,
                        currentOption: item.value ? cityOptions.find((option) => option.value === item.value) : null,
                    };

                case BillingAccountInfoTypeEnum.payer:
                    const options = this.initPayerOptions();
                    const currentOptions = options.find(
                        (option) =>
                            (+option.property as BillingPayerOptionsTypeEnum) ===
                            (+item.value as BillingPayerOptionsTypeEnum),
                    );

                    return {
                        ...item,
                        options,
                        currentOption: currentOptions ?? null,
                    };
            }
            return item;
        });
    }

    initPayerOptions(): SelectOptionInterface<number, string, BillingPayerOptionsTypeEnum>[] {
        return [
            {
                value: 'billing.info.individual',
                key: BillingPayerOptionsTypeEnum.INDIVIDUAL,
                type: 'payer',
                property: BillingPayerOptionsTypeEnum.INDIVIDUAL,
            },
            {
                value: 'billing.info.legal',
                key: BillingPayerOptionsTypeEnum.LEGAL_ENTITY,
                type: 'payer',
                property: BillingPayerOptionsTypeEnum.LEGAL_ENTITY,
            },
        ];
    }

    initCountryOptions(): SelectOptionInterface[] {
        return this.store.selectSnapshot(CountryStateCityState.getCountry).map((c) => {
            return {
                property: c.isoCode,
                value: c.name,
                key: c.name,
                type: 'country',
            };
        });
    }

    initRegionOptions(): SelectOptionInterface[] {
        return this.store.selectSnapshot(CountryStateCityState.getStates).map((item) => {
            return {
                property: item.isoCode,
                value: item.name,
                key: item.name,
                type: 'region',
            };
        });
    }

    initCityOptions(): SelectOptionInterface[] {
        return this.store.selectSnapshot(CountryStateCityState.getCity).map((item) => {
            return {
                property: item.countryCode,
                value: item.name,
                key: item.name,
                type: 'city',
            };
        });
    }
}

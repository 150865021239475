import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MailingIconSelectOptionInterface} from 'src/app/app-shared-elements/_interfaces/MailingIconSelect';
import {NotificationTransportType} from 'src/app/mailing/_interfaces/notification-log.interface';

@Component({
    selector: 'app-mailing-icon-select',
    templateUrl: './mailing-icon-select.component.html',
    styleUrls: ['./mailing-icon-select.component.scss']
})
export class MailingIconSelectComponent implements OnInit {

    private _options: MailingIconSelectOptionInterface[];
    private _value: any;

    @Input()
    set options(options) {
        this._options = options;
    }

    get options(): MailingIconSelectOptionInterface[] {
        return this._options;
    }

    @Input()
    set value(value) {
        this._value = value;
    }

    get value(): any {
        return this._value;
    }

    @Output() selectedOptionEvent = new EventEmitter<MailingIconSelectOptionInterface>();

    currentOption: MailingIconSelectOptionInterface;

    notificationTransportType = NotificationTransportType;
    isShowPopup = false;

    readonly VIBER_ICON_PATH = './assets/design/icons/table-operations/viber-select.svg';
    readonly TELEGRAM_ICON_PATH = './assets/design/icons/table-operations/telegram-select.svg';
    readonly SMS_ICON_PATH = './assets/design/icons/table-operations/sms-select.svg';
    readonly EMAIL_ICON_PATH = './assets/design/icons/table-operations/email-select.svg';
    readonly ARROW_ICON_PATH = './assets/design/icons/select-arrow.svg';

    constructor() {
    }

    ngOnInit(): void {
        this.currentOption = this.value;
    }

    selectOption(option): void {
        this.currentOption = option;
        this.value = option;

        this.selectedOptionEvent.emit(option);
    }

}

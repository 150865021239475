import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { AppTemplateComponent } from '../app-template/app-template.component';
import { AdminAuthGuardService } from './_guards/admin-auth-guard.service';
import { AdminProfileDevicesComponent } from './admin-profile/admin-profile-devices/admin-profile-devices.component';
import { AdminProfileInfoComponent } from './admin-profile/admin-profile-info/admin-profile-info.component';
import { AdminProfileLogsComponent } from './admin-profile/admin-profile-logs/admin-profile-logs.component';
import { UsersComponent } from './users/users.component';
import { AdminPermissionGuard } from '../app-shared-elements/_guards/admin-permission-guard.guard';
import { ResourceAction } from '../app-shared-elements/_enums/permission.enum';
import { AdminProfileEventLogComponent } from './admin-profile/admin-profile-event-log/admin-profile-event-log.component';
import { RouteGuardService } from '../app-shared-elements/_guards/route-guard-service.service';

const routes: Routes = [
    {
        path: '',
        data: { title: 'Main' },
        component: AppTemplateComponent,
        canActivate: [AdminAuthGuardService],
        children: [
            {
                path: 'users',
                data: { title: 'Users', adminResourceAction: ResourceAction.USERS },
                component: UsersComponent,
                canActivate: [AdminPermissionGuard],
                canDeactivate: [RouteGuardService],
                children: [],
            },
            {
                path: 'company',
                data: { title: 'Users', adminResourceAction: ResourceAction.USERS },
                loadChildren: () => import('../admin/company/company.module').then((m) => m.CompanyModule),
            },
            {
                path: 'users-profile',
                data: { title: 'Admin profile', adminResourceAction: ResourceAction.USER_BROWSING },
                canActivate: [AdminPermissionGuard],
                component: AdminProfileComponent,
                children: [
                    {
                        path: 'user',
                        data: { title: 'User Profile' },
                        component: AdminProfileInfoComponent,
                    },
                    {
                        path: 'logs',
                        data: { title: 'User logs' },
                        component: AdminProfileLogsComponent,
                    },
                    {
                        path: 'devices',
                        data: { title: 'User devices' },
                        component: AdminProfileDevicesComponent,
                    },
                    {
                        path: 'event-log',
                        data: { title: 'Event log' },
                        component: AdminProfileEventLogComponent,
                    },
                ],
            },
            {
                path: 'profile',
                data: { title: 'Admin profile' },
                component: AdminProfileComponent,
                children: [
                    {
                        path: '',
                        data: { title: 'Admin Profile' },
                        component: AdminProfileInfoComponent,
                    },
                ],
            },
            // {
            //     path: 'profile-admin/:id',
            //     data: {title: 'Admin profile'},
            //     component: AdminProfileComponent,
            //     children: [
            //         {
            //             path: '',
            //             data: {title: 'Admin Profile'},
            //             component: AdminProfileInfoComponent,
            //         }
            //     ]
            // },
            {
                path: 'admins',
                data: { title: 'Admins' },
                loadChildren: () => import('../admin/admins/admins.module').then((m) => m.AdminsModule),
            },
            {
                path: '',
                redirectTo: 'admins',
                pathMatch: 'full',
            },
            {
                path: 'roles',
                data: { title: 'roles', typeClient: 'admin', adminResourceAction: ResourceAction.USERS_ROLE },
                canActivate: [AdminPermissionGuard],
                loadChildren: () => import('../admin/roles/roles.module').then((m) => m.RolesModule),
            },
            {
                path: 'units',
                data: { title: 'units', typeClient: 'admin', adminResourceAction: ResourceAction.UNITS },
                canActivate: [AdminPermissionGuard],
                loadChildren: () => import('../admin/units/units.module').then((m) => m.UnitsModule),
            },
            {
                path: 'servers',
                data: { title: 'servers', typeClient: 'admin', adminResourceAction: ResourceAction.SERVER_INFO },
                canActivate: [AdminPermissionGuard],
                loadChildren: () => import('../admin/servers-dashboard/servers-dashboard.module').then((m) => m.ServersDashboardModule),
            },
            {
                path: 'export-import',
                data: { title: 'export-import', typeClient: 'admin', adminResourceAction: ResourceAction.EXPORT_IMPORT },
                canActivate: [AdminPermissionGuard],
                loadChildren: () => import('../admin/export-import/export-import.module').then((m) => m.ExportImportModule),
            },
            {
                path: 'event-messages',
                data: { title: 'event-messages', typeClient: 'admin' },
                loadChildren: () => import('../admin/event-messages/event-messages.module').then((m) => m.EventMessagesModule),
            },
            {
                path: 'system-settings',
                data: { title: 'system-settings', typeClient: 'admin', adminResourceAction: ResourceAction.CONSTANT },
                canActivate: [AdminPermissionGuard],
                loadChildren: () => import('../admin/system-settings/system-settings.module').then((m) => m.SystemSettingsModule),
            },
            {
                path: 'devices',
                data: { title: 'devices', typeClient: 'admin', adminResourceAction: ResourceAction.DEVICES },
                canActivate: [AdminPermissionGuard],
                loadChildren: () => import('../admin/admin-devices/admin-devices.module').then((m) => m.AdminDevicesModule),
            },
            {
                path: 'journals',
                data: { title: 'journals', typeClient: 'admin', isAdmin: true },
                canActivate: [AdminPermissionGuard],
                loadChildren: () => import('../admin/admin-journals/admin-journals.module').then((m) => m.AdminJournalsModule),
            },
            {
                path: 'group-container',
                data: { title: 'group-container', typeClient: 'admin', isAdmin: true },
                canActivate: [AdminPermissionGuard],
                loadChildren: () =>
                    import('../admin/admin-group-container/admin-group-container.module').then((m) => m.AdminGroupContainerModule),
            },
            {
                path: 'notifications',
                data: { title: 'notifications', typeClient: 'admin', isAdmin: true },
                canActivate: [AdminPermissionGuard],
                loadChildren: () =>
                    import('../admin/admin-notifications/admin-notifications.module').then((m) => m.AdminNotificationsModule),
            },
            {
                path: 'finances',
                data: {
                    title: 'finances',
                    typeClient: 'admin',
                    isAdmin: true,
                    adminResourceAction: ResourceAction.BILLING,
                },
                canActivate: [AdminPermissionGuard],
                loadChildren: () => import('../admin/admin-finances/admin-finances.module').then((m) => m.AdminFinancesModule),
            },
            {
                path: 'billing',
                data: { title: 'billing', typeClient: 'admin', isAdmin: true },
                canActivate: [AdminPermissionGuard],
                loadChildren: () => import('../admin/admin-billing/admin-billing.module').then((m) => m.AdminBillingModule),
            },
            {
                path: 'mail-message',
                data: { title: 'mail-message', typeClient: 'admin', isAdmin: true },
                canActivate: [AdminPermissionGuard],
                loadChildren: () => import('../admin/mail-message/mail-message.module').then((m) => m.MailMessageModule),
            },
            {
                path: 'details',
                data: { title: 'details', typeClient: 'admin', isAdmin: true },
                canActivate: [AdminPermissionGuard],
                loadChildren: () =>
                    import('../admin/admin-details-container/admin-details-container.module').then((m) => m.AdminDetailsContainerModule),
            },
            {
                path: 'registrator-logs',
                data: { title: 'registrator-logs', typeClient: 'admin', isAdmin: true },
                canActivate: [AdminPermissionGuard],
                loadChildren: () =>
                    import('../admin/admin-registrator-logs/admin-registrator-logs.module').then((m) => m.AdminRegistratorLogsModule),
            },
            {
                path: 'devices-control',
                data: {
                    title: 'devices-control',
                    typeClient: 'admin',
                    isAdmin: true,
                    adminResourceAction: ResourceAction.DATALOGGER_SETTINGS,
                },
                canActivate: [AdminPermissionGuard],
                loadChildren: () => import('../admin/devices-control/devices-control.module').then((m) => m.DevicesControlModule),
            },
        ],
    },
];

@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule.forChild(routes)],
})
export class AdminRoutingModule {}

import { Axis } from 'src/app/app-shared-elements/_interfaces/Axis';
import { CheckboxPropertiesDataInterface, IconInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { SelectOptionInterface } from 'src/app/app-shared-elements/_interfaces/select-option.interface';

export interface LineRowInterface {
    axisId: string;
    axisName: string;
    axis: Axis;
    color: string;
    name: string;
    type: string;
    id: string; // variableId
    width: number;
    showLimitAxis: boolean;
    checkboxValue: boolean;
    checkboxTooltipValue: string;
    typeOptions: SelectOptionInterface<string, string, { cellName: string; data: string }>[];
    axisOptions: SelectOptionInterface<string, string, { cellName: string; data: Axis }>[];
    checkboxProperties: {
        showLimitAxis: CheckboxPropertiesDataInterface;
    };
}
export interface AxisRowInterface {
    color: string;
    delete: string;
    id: string;
    name: string;
    // unit: string;
    options: SelectOptionInterface[];
    preIcons: IconInterface<AxisRowIconActionEnum>[];
    editConfig: {
        isEditName: boolean;
        maxLength: number;
    };
}

export enum AxisRowIconActionEnum {
    copyBuffer = 'copyBuffer',
}

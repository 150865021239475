import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {SocketTypeLogDevice} from 'src/app/device-dashboard/user-devices/_enum/action-log-device.enum';
import {SetActiveDeviceLogsFromSocket} from '../actions/active-device-event.actions';

export interface ActiveDeviceEventStateModel {
    activeDeviceEvent: string[];
}

const ACTIVE_DEVICE_EVENT_STATE = new StateToken<ActiveDeviceEventStateModel>('activeDeviceEventsState');

@State<ActiveDeviceEventStateModel>({
    name: ACTIVE_DEVICE_EVENT_STATE,
    defaults: {
        activeDeviceEvent: []
    }
})
@Injectable()
export class ActiveDeviceEventState {
    constructor() {

    }

    @Selector()
    static getActiveDeviceEvents(state: ActiveDeviceEventStateModel): string[] {
        return state.activeDeviceEvent;
    }

    @Action(SetActiveDeviceLogsFromSocket)
    async SetActiveDeviceLogsFromSocket(ctx: StateContext<ActiveDeviceEventStateModel>, payload: SetActiveDeviceLogsFromSocket): Promise<void> {
        const state = ctx.getState();
        switch (payload.activeDeviceLogs.type) {
            case SocketTypeLogDevice.ALL:
                // logs = payload.activeDeviceLogs.logs.map(logId => logId);

                ctx.setState({
                    ...state,
                    activeDeviceEvent: payload.activeDeviceLogs.logs.map(id => id)
                });
                break;

            case SocketTypeLogDevice.ADD:
                // logs.push(...payload.activeDeviceLogs.logs);

                ctx.setState({
                    ...state,
                    activeDeviceEvent: [...state.activeDeviceEvent, ...payload.activeDeviceLogs.logs.map(id => id)]
                });
                break;
            case SocketTypeLogDevice.REMOVE:
                // logs = logs.filter(logId => !payload.activeDeviceLogs.logs.find(newLogId => newLogId === logId));

                ctx.setState({
                    ...state,
                    activeDeviceEvent: state.activeDeviceEvent.filter(id => !payload.activeDeviceLogs.logs.find(log => log === id))
                });
                break;
        }

    }
}

<div class="column" (click)="isAction = !isAction"
     #settings
     appClickOutside
     [isActiveClickOutside]="isAction"
     (clickOutside)="isAction = false">
    <div class="column__wrapper">
        <ng-container *ngIf="cell.actionBtns && cell.actionBtns.length > 1; else icon">
            <ng-container *ngIf="cell?.actionBtns?.length !== row?.disabledActions?.length">
                <div class="column__action column__action-icon">
                    <svg-icon [src]="ACTION_ICON_PATH"></svg-icon>

                    <div class="column__setting" [class.block]="isAction">

                        <ng-container *ngFor="let item of tableService.columnActionList">
                            <ng-container *ngIf="cell.actionBtns.includes(item.property)">

                                <div *ngIf="!row?.disabledActions || !row?.disabledActions.includes(item.property)"
                                     class="column__setting-item"
                                     [class.column__setting-item--delete]="item.property === columnsActionTypeEnum.actionBtnsDelete"
                                     [class.column__setting-item--disable]="row?.disabledActions && row?.disabledActions.includes(item.property)"
                                     (click)="moveTo(item)">

                                    <svg-icon [src]="item.property"></svg-icon>

                                    <span class="column__setting-item--text" [translate]="item.title"></span>

                                </div>

                            </ng-container>
                        </ng-container>

                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #icon>

            <ng-container *ngFor="let item of tableService.columnActionList">
                <ng-container *ngIf="cell.actionBtns.includes(item.property)">

                    <svg-icon *ngIf="!row?.disabledActions || !row?.disabledActions.includes(item.property)"
                              (click)="moveTo(item)" [src]="item.property"></svg-icon>

                </ng-container>
            </ng-container>

        </ng-template>
    </div>
</div>

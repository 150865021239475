import {Directive, ElementRef, Input} from '@angular/core';
import {DatepickerDirectiveInterface} from '../_interfaces/datepicker-directive.interface';

@Directive({
    selector: '[appDatepickerDisabledDate]'
})
export class DatepickerDirective {
    private _data: DatepickerDirectiveInterface;

    @Input('appDatepickerDisabledDate')
    set data(data: DatepickerDirectiveInterface) {
        this._data = data;
        this.parse();
    }

    get data(): DatepickerDirectiveInterface {
        return this._data;
    }

    constructor(
        private el: ElementRef
    ) {
    }

    parse(): void {
        if (!this.data.dateFrom) {
            return;
        }

        const currentDayDateMills: number = new Date(this.data.currentCalendarDate.year, this.data.currentCalendarDate.month, this.data.currentCalendarDate.day).getTime();
        let fromDateMills: number;
        let toDateMills: number;
        fromDateMills = new Date(this.data.dateFrom.getFullYear(), this.data.dateFrom.getMonth(), this.data.dateFrom.getDate()).getTime();

        if (this.data.dateTo) {
            toDateMills = new Date(this.data.dateTo.getFullYear(), this.data.dateTo.getMonth(), this.data.dateTo.getDate()).getTime();
        } else {
            this.el.nativeElement.classList.remove('datepicker__day--picked-end');
            this.el.nativeElement.classList.remove('datepicker__day--range');

            if (currentDayDateMills === fromDateMills) {
                this.el.nativeElement.classList.add('datepicker__day--picked-start');
                this.el.nativeElement.classList.add('datepicker__day--picked');
            } else {
                this.el.nativeElement.classList.remove('datepicker__day--picked-start');
                this.el.nativeElement.classList.remove('datepicker__day--picked');
            }

            return;
        }

        if (currentDayDateMills === fromDateMills || currentDayDateMills === toDateMills) {
            this.el.nativeElement.classList.add('datepicker__day--picked');
        } else {
            this.el.nativeElement.classList.remove('datepicker__day--picked');
        }

        if (currentDayDateMills === fromDateMills) {
            this.el.nativeElement.classList.add('datepicker__day--picked-start');
        } else {
            this.el.nativeElement.classList.remove('datepicker__day--picked-start');
        }

        if (currentDayDateMills === toDateMills) {
            this.el.nativeElement.classList.add('datepicker__day--picked-end');
        } else {
            this.el.nativeElement.classList.remove('datepicker__day--picked-end');
        }

        if (currentDayDateMills > fromDateMills && currentDayDateMills < toDateMills) {
            this.el.nativeElement.classList.add('datepicker__day--range');
        } else {
            this.el.nativeElement.classList.remove('datepicker__day--range');
        }

        // switch (this.data.fromToTypeCalendar) {
        //     case FromToTypeCalendar.from:
        //         fromDateMills = new Date(this.data.dateFrom.getFullYear(), this.data.dateFrom.getMonth(), this.data.dateFrom.getDate()).getTime();
        //         toDateMills = new Date(this.data.dateTo.getFullYear(), this.data.dateTo.getMonth(), this.data.dateTo.getDate()).getTime();

        //         if (currentDayDateMills >= fromDateMills && currentDayDateMills <= toDateMills) {
        //             console.log(this.el.nativeElement);
        //             this.el.nativeElement.classList.add('datepicker__day--picked');
        //         }
        //         break;
        //     case FromToTypeCalendar.to:
        //         fromDateMills = new Date(this.data.dateFrom.getFullYear(), this.data.dateFrom.getMonth(), this.data.dateFrom.getDate()).getTime();
        //         toDateMills = new Date(this.data.dateTo.getFullYear(), this.data.dateTo.getMonth(), this.data.dateTo.getDate()).getTime();

        //         if (currentDayDateMills >= fromDateMills && currentDayDateMills <= toDateMills) {
        //             console.log(this.el.nativeElement);
        //             this.el.nativeElement.classList.add('datepicker__day--picked');
        //         }
        //         break;
        // }

    }
}

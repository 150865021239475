import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { Params } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { TransportDashboardInterface } from '../../transport-dashboard/_interfaces/transport-dashboard.interface';
import { TransportDeviceDto, TransportDto } from '../../transport-dashboard/_interfaces/TransportDeviceDto';
import {
    TransportLogicEventInterface,
    UpdateTransportLimitLogicEvent,
} from '../../transport-dashboard/_interfaces/transport-logic-event.interface';
import { EditExpeditionRowInterface } from '../../transport-dashboard/_interfaces/edit-expedition-row.interface';

export class GetTransports {
    static readonly type = '[Transports] GetTransports';
}

export class UpdateTransports {
    static readonly type = '[Transports] UpdateTransports';
    // constructor(public updateTransportDto: UpdateTransportDto) {
    // }
}

// export class ChangeActiveTransport {
//     static readonly type = '[Transports] ChangeActiveTransport';
//     constructor(public changeActive: ChangeActiveTransportDto) {
//     }
// }

export class SetUpdatingTransport {
    static readonly type = '[Transports] SetUpdatingTransport';

    constructor(public transportItem: TransportDashboardInterface) {}
}

export class ChangeUpdatingTransport {
    static readonly type = '[Transports] ChangeUpdatingTransport';

    constructor(public transportItem: TransportDashboardInterface) {}
}

export class InitSelectsTransport {
    static readonly type = '[Transports] InitSelectsTransport';

    constructor(public selects: TransportDeviceDto[]) {}
}

export class ChangeSelectItemTransport {
    static readonly type = '[Transports] ChangeSelectItemTransport';

    constructor(public select: TransportDeviceDto) {}
}

export class InitDevicesOptionsForTransport {
    static readonly type = '[Transports] InitDevicesOptionsForTransport';

    constructor(public transport: TransportDto) {}
}

export class InitVariablesOptionsForTransport {
    static readonly type = '[Transports] InitVariablesOptionsForTransport';

    constructor(public transport: TransportDto) {}
}

export class InitTransportArray {
    static readonly type = '[Transports] InitTransportArray';
}

export class UpdateTransportArray {
    static readonly type = '[Transports] UpdateTransportArray';

    constructor(public devices: Device[]) {}
}

export class UpdateTransportPosition {
    static readonly type = '[Transports] UpdateTransportPosition';

    constructor(public position: { registratorId: string; position: number }[]) {}
}

export class CancelUpdatingTransport {
    static readonly type = '[Transports] CancelUpdatingTransport';
}

export class SetTransportParams {
    static readonly type = '[Transports] SetTransportParams';

    constructor(public params: Params) {}
}

export class GetEditExpedition {
    static readonly type = '[Transports] GetEditExpedition';

    constructor(public variableIds: string[]) {}
}

export class InitTableForEditExpeditions {
    static readonly type = '[Transports] InitTableForEditExpeditions';

    constructor(public ids: { variableId: string; deviceId: string }[]) {}
}

export class SetErrorValidateEditExpedition {
    static readonly type = '[Transports] SetErrorValidateEditExpedition';

    constructor(
        public rowId: string,
        public isError: boolean,
    ) {}
}

export class UpdateTransportLimitLogicEventExpedition {
    static readonly type = '[Transports] UpdateTransportLimitLogicEvent';

    constructor(public data: TransportLogicEventInterface<UpdateTransportLimitLogicEvent>[]) {}
}

export class ChangeRowEditExpedition {
    static readonly type = '[Transports] ChangeRowEditExpedition';

    constructor(public row: EditExpeditionRowInterface) {}
}

export class AddTransport {
    static readonly type = '[Transports] AddTransport';

    constructor(public transport: { registratorId: string; name: string }) {}
}

export class RemoveTransport {
    static readonly type = '[Transports] RemoveTransport';

    constructor(public transport: TransportDashboardInterface) {}
}

import {EventEmitter, Injectable} from '@angular/core';
import {TooltipStatusEnum} from '../_enums/tooltip-status.enum';
import {NoteInfoInterface} from '../_interfaces/note-info.interface';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    noteEmit = new EventEmitter<NoteInfoInterface>();
    destroyNoteEvent = new EventEmitter<void>();

    constructor() { }

    onEmit(status: TooltipStatusEnum, action: boolean, message?: string): void {
        this.noteEmit.emit({status, action, message});
    }

    destroyNote(): void {
        this.destroyNoteEvent.emit();
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { StateReset } from 'ngxs-reset-plugin';
import { DeviceTypeEnum } from 'src/app/app-shared-elements/_enums/device-type.enum';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { LimitLogicEventUpdateDto, LimitMessagesObjectInterface, LogicEventType } from '../../logical-events/_interface/LogicEvent';
import { EventMessage } from '../../_interfaces/EventMessage';
import { ParseMessageService } from '../../_services/parse-message.service';
import { SetErrorExpressionMessage, SetSuccessUpdateEvent } from '../actions/logical-events.actions';
import { ChangeMultiEvent, CreateMultiEvent, GetMessagesForMultiEvent, UpdateMultiEvent } from '../actions/multi-event.action';

export interface MultiEventStateModel {
    currentMultiEvent: LimitLogicEventUpdateDto;
    messagesForMultiEvent: LimitMessagesObjectInterface;
}

const LOGICAL_MULTI_EVENTS_TOKEN = new StateToken<MultiEventStateModel>('multiEvents');

const initialCurrentMultiEvent: LimitLogicEventUpdateDto = {
    name: 'Нова подія',
    variableId: null,
    isAcknowledgeableAlarm: false,
    isAcknowledgeableAttention: false,
    limitIsActive: true,
    [LogicEventType.alarmDeadlineMax]: {
        logicEventType: LogicEventType.alarmDeadlineMax,
        value: '',
        isActive: false,
    },
    [LogicEventType.alarmDeadlineMin]: {
        logicEventType: LogicEventType.alarmDeadlineMin,
        value: '',
        isActive: false,
    },
    [LogicEventType.attentionDeadlineMax]: {
        logicEventType: LogicEventType.attentionDeadlineMax,
        value: '',
        isActive: false,
    },
    [LogicEventType.attentionDeadlineMin]: {
        logicEventType: LogicEventType.attentionDeadlineMin,
        value: '',
        isActive: false,
    },
};

@State<MultiEventStateModel>({
    name: LOGICAL_MULTI_EVENTS_TOKEN,
    defaults: {
        currentMultiEvent: JSON.parse(JSON.stringify(initialCurrentMultiEvent)),
        messagesForMultiEvent: null,
    },
})
@Injectable()
export class MultiEventState {
    constructor(
        private http: HttpClient,
        private store: Store,
        private parseMessageService: ParseMessageService,
        private translateService: TranslateService,
        private notificationService: NotificationsService,
    ) {}

    @Selector()
    static getCurrentMultiEvent(state: MultiEventStateModel): LimitLogicEventUpdateDto {
        return JSON.parse(JSON.stringify(state.currentMultiEvent));
    }

    @Selector()
    static getMessagesForMultiEvent(state: MultiEventStateModel): LimitMessagesObjectInterface {
        return state.messagesForMultiEvent;
    }

    @Action(CreateMultiEvent)
    async createMultiEvent(ctx: StateContext<MultiEventStateModel>, payload: CreateMultiEvent): Promise<void> {
        const result = (await this.http
            .post('/api/logic-events-limit/limit', { ...payload.dto })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        let message: string;
        if (result) {
            switch (result.status as HTTP_STATUS) {
                case HTTP_STATUS.SUCCESS:
                    if (!payload.createOneMore) {
                        this.store.dispatch(new StateReset(MultiEventState));
                    }

                    ctx.dispatch(new SetSuccessUpdateEvent(true));
                    break;
                case HTTP_STATUS.INVALID_EVENT_EXPRESSION:
                    message = await this.translateService
                        .get('events.logicalEvents.invalidExpression')
                        .toPromise()
                        .catch((e) => console.log(e));
                    this.notificationService.onEmit(TooltipStatusEnum.error, false, message);
                    ctx.dispatch(new SetSuccessUpdateEvent(false));
                    ctx.dispatch(new SetErrorExpressionMessage(result && result.data ? result.data : null));
                    break;
                case HTTP_STATUS.EVENTS_ACTIVE_FOR_REGISTRATOR:
                    const devices: Device[] = this.store.selectSnapshot(DevicesState.getDevices);
                    const currentDevice = devices.find((d) => d.variables.find((v) => v.id === payload.dto.variableId));

                    const registrator: Device = currentDevice
                        ? devices.find(
                              (d) =>
                                  d.type === DeviceTypeEnum.registrator &&
                                  (d.id === currentDevice.registratorId || d.id === currentDevice.id),
                          )
                        : null;

                    message = await this.translateService
                        .get('events.logicalEvents.registratorOfflineError', { registrator: registrator ? registrator.name : '' })
                        .toPromise()
                        .catch((e) => console.log(e));
                    this.notificationService.onEmit(TooltipStatusEnum.error, false, message);
                    ctx.dispatch(new SetSuccessUpdateEvent(false));
                    ctx.dispatch(new SetErrorExpressionMessage(result && result.data ? result.data : null));
                    break;
            }
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
            ctx.dispatch(new SetSuccessUpdateEvent(false));
            ctx.dispatch(new SetErrorExpressionMessage(result && result.data ? result.data : null));
        }
    }

    @Action(ChangeMultiEvent)
    async changeMultiEvent(ctx: StateContext<MultiEventStateModel>, payload: ChangeMultiEvent): Promise<void> {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            currentMultiEvent: { ...payload.multiEvent },
        });
    }

    @Action(UpdateMultiEvent)
    async updateMultiEvent(ctx: StateContext<MultiEventStateModel>, payload: UpdateMultiEvent): Promise<void> {
        const state = ctx.getState();
        this.http
            .post('/api/logic-events-limit/limit', { ...payload.multiEvent })
            .toPromise()
            .catch((e) => console.log(e));
    }

    @Action(GetMessagesForMultiEvent)
    async getMessagesForMultiEvent(ctx: StateContext<MultiEventStateModel>, payload: GetMessagesForMultiEvent): Promise<void> {
        const result = (await this.http
            .get('/api/logic-messages/limit')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();
        const devices = this.store.selectSnapshot(DevicesState.getDevices);

        const alarmDeadlineMax = (result.data as EventMessage[]).find((m) => m.logicEventType === LogicEventType.alarmDeadlineMax);
        const alarmDeadlineMin = (result.data as EventMessage[]).find((m) => m.logicEventType === LogicEventType.alarmDeadlineMin);
        const attentionDeadlineMax = (result.data as EventMessage[]).find((m) => m.logicEventType === LogicEventType.attentionDeadlineMax);
        const attentionDeadlineMin = (result.data as EventMessage[]).find((m) => m.logicEventType === LogicEventType.attentionDeadlineMin);
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                messagesForMultiEvent: {
                    [LogicEventType.alarmDeadlineMax]: {
                        ...alarmDeadlineMax,
                        message: this.parseMessageService.performMessage(payload.currentLogicEvent, devices, alarmDeadlineMax.message),
                    },
                    [LogicEventType.alarmDeadlineMin]: {
                        ...alarmDeadlineMin,
                        message: this.parseMessageService.performMessage(payload.currentLogicEvent, devices, alarmDeadlineMin.message),
                    },
                    [LogicEventType.attentionDeadlineMax]: {
                        ...attentionDeadlineMax,
                        message: this.parseMessageService.performMessage(payload.currentLogicEvent, devices, attentionDeadlineMax.message),
                    },
                    [LogicEventType.attentionDeadlineMin]: {
                        ...attentionDeadlineMin,
                        message: this.parseMessageService.performMessage(payload.currentLogicEvent, devices, attentionDeadlineMin.message),
                    },
                },
            });
        } else {
            ctx.setState({
                ...state,
            });
        }
    }

    // @Action(ClearMultiEvent)
    // clearMultiEvent(ctx: StateContext<MultiEventStateModel>): void {
    //     const state = ctx.getState();
    //     console.log('ClearMultiEvent');
    //     ctx.setState({
    //         ...state,
    //         currentMultiEvent: JSON.parse(JSON.stringify(initialCurrentMultiEvent)),
    //         messagesForMultiEvent: null
    //     });

    // }
}

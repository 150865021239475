export enum RegistratorSyncStatus {
    SYNC_ERROR = -1,
    IS_SYNC = 0,
    NOT_SYNC = 1,
    IN_SYNC = 2,
    SYNC_SUCCESS = 3,
}

export enum CreationType {
    ORIGIN = 0,
    VIRTUAL = 1,
    MNEMONIC = 2,
}

import {Params, ParamsSorted} from 'src/app/app-shared-elements/_interfaces/params.interface';
import {TechnologicLogRowInterface} from '../../_interfaces/technologic-log-row.interface';

export class GetTechnologicEventsRows {
    static readonly type = '[TechnologicEvent] GetTechnologicEventsRows';
    constructor() {
        // console.trace()
    }
}

export class UpdateTechnologicEventsRows {
    static readonly type = '[TechnologicEvent] UpdateTechnologicEventsRows';
}


export class SetAcknowledge {
    static readonly type = '[TechnologicEvent] SetAcknowledge';
    constructor(public row: TechnologicLogRowInterface) {
    }
}



export class SetSort {
    static readonly type = '[TechnologicEvent] SetSort';
    constructor(public sort: ParamsSorted) {


    }
}

export class SetUserIdForEventLogsFromAdmin {
    static readonly type = '[TechnologicEvent] SetUserIdForEventLogsFromAdmin';

    constructor(public userId: string) {

    }
}



export class SetTechnologicFilter {
    static readonly type = '[TecnologicEvent] SetTechnologicFilter';
    constructor(public params: Params) {

    }
}

import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken, Store} from '@ngxs/store';
import {HTTP_STATUS} from 'src/app/app-shared-elements/_enums/status.enum';
import {ChartModeEnum} from 'src/app/app-shared-elements/_enums/chart-mode.enum';
import {ArchivePreloaderData} from 'src/app/app-shared-elements/_interfaces/archive-preloader-data.interface';
import {Axis} from 'src/app/app-shared-elements/_interfaces/Axis';
import {DevicesState} from 'src/app/device-dashboard/_store/states/user-devices.state';
import {ChartErrorTypeInterface} from '../../_interfaces/chart-error-type.interface';
import {
    AddVariableToChart,
    ChangeChartMode,
    ClearChartState,
    ClearScaleButtons,
    CreateNewAxis,
    DeleteAxis,
    GetAxis,
    InitScaleButtons,
    RemoveVariableToChart,
    ReReadArchive,
    SetArchivePreloaderData,
    SetDatepickerConfigForReread,
    SetIsDataLimited,
    SetIsEmptyArchive,
    ShawAllVariableToChart,
    UpdateAxis,
} from '../actions/charts.actions';
import {TooltipStatusEnum} from '../../../app-shared-elements/_enums/tooltip-status.enum';
import {NotificationsService} from '../../../app-shared-elements/_services/notifications.service';
import {ChartScaleButtonInterface} from '../../_interfaces/chart-scale-button.interface';
import {InitChartSettingsAxisRows} from '../actions/chart-settings.action';
import {append, patch} from '@ngxs/store/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiResponse} from '../../../app-shared-elements/_interfaces/ApiRequest';
import {DatePickerConfigInterface} from 'src/app/app-shared-elements/_interfaces/date-picker-config.interface';
import {TranslateService} from '@ngx-translate/core';
import {first} from 'rxjs/operators';
import {LuxonDatePipe} from 'src/app/app-shared-elements/_pipes/luxon-date.pipe';
import {UserState} from 'src/app/app-shared-elements/_store/states/user.state';
import {LanguageState} from 'src/app/app-shared-elements/_store/states/language.state';

export interface ChartStateModel {
    chartMode: ChartModeEnum;
    isDataLimited: boolean;
    axis: Axis[];
    archivePreloaderData: ArchivePreloaderData;
    scaleButtons: ChartScaleButtonInterface[];
    variablesIdsForShow: string[];
    datepickerConfigForReread: DatePickerConfigInterface;
}

const CHART_TOKEN = new StateToken<ChartStateModel>('chartState');

const initialArchivePreloaderData: ArchivePreloaderData = {
    responsesLength: 0,
    currentResponse: 0,
};

@State<ChartStateModel>({
    name: CHART_TOKEN,
    defaults: {
        chartMode: ChartModeEnum.chart,
        isDataLimited: false,
        axis: [],
        archivePreloaderData: initialArchivePreloaderData,
        scaleButtons: [],
        variablesIdsForShow: [],
        datepickerConfigForReread: null,
    },
})
@Injectable()
export class ChartState {
    constructor(
        private store: Store,
        private notificationService: NotificationsService,
        private http: HttpClient,
        private translateService: TranslateService,
        private luxonDatePipe: LuxonDatePipe,
    ) {}

    @Selector()
    static getChartMode(state: ChartStateModel): ChartModeEnum {
        return state.chartMode;
    }

    @Selector()
    static getIsDataLimited(state: ChartStateModel): boolean {
        return state.isDataLimited;
    }

    @Selector()
    static getAxis(state: ChartStateModel): Axis[] {
        return state.axis;
    }

    @Selector()
    static getArchivePreloaderData(state: ChartStateModel): ArchivePreloaderData {
        return state.archivePreloaderData;
    }

    @Selector()
    static getChartScaleButtons(state: ChartStateModel): ChartScaleButtonInterface[] {
        return state.scaleButtons;
    }

    @Selector()
    static getVariablesIdsForShow(state: ChartStateModel): string[] {
        return state.variablesIdsForShow;
    }

    @Selector()
    static getDatepickerConfigForReread(state: ChartStateModel): DatePickerConfigInterface {
        return state.datepickerConfigForReread;
    }

    @Action(ChangeChartMode)
    changeChartMode(ctx: StateContext<ChartStateModel>, payload: ChangeChartMode): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            chartMode: payload.mode,
        });
    }

    @Action(SetIsDataLimited)
    setIsDataLimited(ctx: StateContext<ChartStateModel>, payload: SetIsDataLimited): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isDataLimited: payload.value,
        });
    }

    @Action(GetAxis)
    async getAxis(ctx: StateContext<ChartStateModel>, payload: GetAxis): Promise<void> {
        const state = ctx.getState();
        const currentUserId = this.store.selectSnapshot(UserState.getAdminUserId);

        let headers;

        if (currentUserId) {
            headers = new HttpHeaders({ registratorId: payload.registratorId }).set('currentUserId', currentUserId);
        } else {
            headers = new HttpHeaders({ registratorId: payload.registratorId });
        }

        const result = (await this.http.get(`/api/groups/chart-axis/${payload.registratorId}`, { headers }).toPromise()) as Axis[];
        ctx.setState({
            ...state,
            axis: result,
        });
    }

    @Action(CreateNewAxis)
    async createNewAxis(ctx: StateContext<ChartStateModel>): Promise<void> {
        const state = ctx.getState();
        const registratorId = this.store.selectSnapshot(DevicesState.getCurrentRegistratorId);
        const headers = new HttpHeaders({ registratorId });
        const result: Axis = (await this.http
            .post<Axis>('/api/groups/chart-axis', { registrator: registratorId, headers })
            .toPromise()
            .catch((e) => console.log(e))) as Axis;

        ctx.setState({
            ...state,
            axis: [...state.axis, result],
        });
    }

    @Action(SetArchivePreloaderData)
    setArchivePreloaderData(ctx: StateContext<ChartStateModel>, payload: SetArchivePreloaderData): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            archivePreloaderData: { ...payload.archivePreloaderData },
        });
    }

    @Action(UpdateAxis)
    async updateAxis(ctx: StateContext<ChartStateModel>, payload: UpdateAxis): Promise<void> {
        const state = ctx.getState();
        const registratorId = this.store.selectSnapshot(DevicesState.getCurrentRegistrator).id;
        const headers = new HttpHeaders({ registratorId });

        const result = await this.http
            .post<Axis>(`/api/groups/chart-axis`, { changedAxis: payload.axis, registrator: registratorId, headers })
            .toPromise();

        if (result && result.id === payload.axis.id) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                axis: state.axis.map((a) => (a.id === result.id ? result : a)),
            });
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.dispatch(new InitChartSettingsAxisRows());
        }
    }

    @Action(DeleteAxis)
    async deleteAxis(ctx: StateContext<ChartStateModel>, payload: DeleteAxis): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http.delete(`/api/groups/chart-axis/${payload.data.id}`).toPromise()) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                axis: state.axis.filter((a) => a.id !== payload.data.id),
            });
        }
    }

    @Action(InitScaleButtons)
    initScaleButtons(ctx: StateContext<ChartStateModel>, payload: InitScaleButtons): void {
        const state = ctx.getState();
        const isDetailDate = localStorage.getItem('isDetailDate');
        const addTopPxByIsDetailDate = isDetailDate && isDetailDate === 'true' ? 30 : 0;

        let result: ChartScaleButtonInterface[] = [];

        const containerElement = document.querySelector('.charts__container');
        if (!containerElement) {
            return;
        }
        const containerElementX = containerElement.getBoundingClientRect().x;
        const containerElementY = containerElement.getBoundingClientRect().y;

        let top: number;

        payload.detailChart.yAxis.reverse().forEach((y) => {
            const currentYaxisElement = document.getElementsByClassName('highcharts-axis highcharts-yaxis y-axis-' + y.userOptions.id)[0];
            if (!currentYaxisElement) {
                return;
            }
            if (!top) {
                const t = currentYaxisElement.getBoundingClientRect().bottom + 20 - containerElementY + addTopPxByIsDetailDate;
                top = t;
            }

            const left = currentYaxisElement.getBoundingClientRect().x - containerElementX + 8;
            result = [...result, { color: y.userOptions.lineColor, top, left, axisId: y.userOptions.id }];
        });

        ctx.setState({
            ...state,
            scaleButtons: result,
        });
    }

    @Action(ClearScaleButtons)
    clearScaleButtons(ctx: StateContext<ChartStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            scaleButtons: [],
        });
    }

    @Action(AddVariableToChart)
    addVariableToChart(ctx: StateContext<ChartStateModel>, payload: AddVariableToChart): void {
        const state = ctx.getState();
        ctx.setState(
            patch({
                variablesIdsForShow: append([payload.variableId]), // [...state.variablesIdsForShow, payload.variableId]
            }),
        );
    }

    @Action(RemoveVariableToChart)
    removeVariableToChart(ctx: StateContext<ChartStateModel>, payload: RemoveVariableToChart): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            variablesIdsForShow: state.variablesIdsForShow.filter((id) => id !== payload.variableId),
        });
    }

    @Action(ShawAllVariableToChart)
    showAllVariableToChart(ctx: StateContext<ChartStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            variablesIdsForShow: [],
        });
    }

    @Action(ClearChartState)
    clearChartState(ctx: StateContext<ChartStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            variablesIdsForShow: [],
            isDataLimited: false,
            // isEmptyArchive: null
        });
    }

    @Action(ReReadArchive)
    async reReadArchive(ctx: StateContext<ChartStateModel>): Promise<void> {
        const data: { deviceId: string; start: number } = {
            deviceId: this.store.selectSnapshot(DevicesState.getCurrentDevice).id,
            start: this.store.selectSnapshot(ChartState.getDatepickerConfigForReread).from.getTime(),
        };
        const user = this.store.selectSnapshot(UserState.getUser);
        const ln = this.store.selectSnapshot(LanguageState.getLanguage);
        const from = this.luxonDatePipe.transform(data.start, user.dateFormat, user.dateTimeZone, ln);
        const message = await this.translateService.get('charts.rereading.startReread', { from }).pipe(first()).toPromise();
        this.notificationService.onEmit(TooltipStatusEnum.update, false, message);

        const result: ApiResponse = (await this.http
            .get('/api/archive/re-archiving', { params: { ...data } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            const message = await this.translateService.get('charts.rereading.successStartReread', { from }).pipe(first()).toPromise();
            this.notificationService.onEmit(TooltipStatusEnum.update, false, message);

            ctx.setState({
                ...state,
                datepickerConfigForReread: null,
            });
        }
    }

    @Action(SetDatepickerConfigForReread)
    setDatepickerConfigForReread(ctx: StateContext<ChartStateModel>, payload: SetDatepickerConfigForReread): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            datepickerConfigForReread: payload.config,
        });
    }
}

export interface ErrorMessageChartStateModel {
    isEmptyArchive: ChartErrorTypeInterface;
}

const ERROR_MESSAGE_STATE = new StateToken<ErrorMessageChartStateModel>('errorMessageChartStateModel');

const initialIsEmptyArchive: ChartErrorTypeInterface = {
    isError: false,
    message: null,
};

@State<ErrorMessageChartStateModel>({
    name: ERROR_MESSAGE_STATE,
    defaults: {
        isEmptyArchive: initialIsEmptyArchive,
    },
})
@Injectable()
export class ErrorMessageChartState {
    constructor() {}

    @Selector()
    // @SelectorOptions({
    //     injectContainerState: false,
    // })
    static getIsEmptyArchive(state: ErrorMessageChartStateModel): ChartErrorTypeInterface {
        return state.isEmptyArchive;
    }

    @Action(SetIsEmptyArchive)
    setIsEmptyArchive(ctx: StateContext<ErrorMessageChartStateModel>, payload: SetIsEmptyArchive): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isEmptyArchive: payload.data,
        });
    }
}

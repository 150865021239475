<div class="drop-zone">

    <div class="drop-zone__drop" #drop ngx-dropzone
         [class.drop-zone__drop--dotted]="!selectFile || !selectFile.name"
         [disableClick]="true"
         [maxFileSize]="maxFileSize"
         [multiple]="false" (change)="onFileChanged($event)"
         [accept]="accept">

        <div class="drop-zone__item">
            <svg-icon [src]="type"></svg-icon>
            <ng-container *ngIf="!selectFile || !selectFile.name; else file">
                <span class="drop-zone__item-title" [translate]="title"></span>
                <span class="drop-zone__item-text" [translate]="'importExport.or'"></span>
                <button (click)="drop.showFileSelector()" class="btn" [translate]="'importExport.downloadBtn'"></button>
            </ng-container>

            <ng-template #file>
                <span class="drop-zone__item-title" [translate]="subTitle"></span>
                <span class="drop-zone__item-file">{{'"' + selectFile.name + '"'}}</span>
                <div class="drop-zone__btns">
                    <button (click)="close()" class="btn btn--light"
                            [translate]="'btns.cancel'"></button>
                    <button (click)="goToSettings()" class="btn" [translate]="type === dropZoneTypeEnum.import
                    ? 'btns.continue' : 'btns.verify'"></button>
                </div>
            </ng-template>
        </div>

    </div>

</div>

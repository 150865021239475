import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {Store} from '@ngxs/store';
import {RolesService} from 'src/app/admin/roles/_services/roles.service';
import {AdminPermissionDirectiveDataInterface} from '../_interfaces/permission-directive-data.interface';
import {RoleInterface} from '../_interfaces/role.interface';
import {NotificationsService} from '../_services/notifications.service';
import {PermissionService} from '../_services/permission.service';
import {PermissionsState} from '../_store/states/permissions.state';
import {UserState} from '../_store/states/user.state';

@Directive({
    selector: '[appAdminPermission]',
})
export class AdminPermissionDirective {
    private _data: AdminPermissionDirectiveDataInterface;

    roles: RoleInterface[];

    oldData: AdminPermissionDirectiveDataInterface;

    @Input('appAdminPermission')
    set data(data: AdminPermissionDirectiveDataInterface) {
        this._data = data;
        this.init();
    }

    get data(): AdminPermissionDirectiveDataInterface {
        return this._data;
    }

    isPermissionAllowed = false;
    isFirstCheck = true;

    constructor(
        private store: Store,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private permissionService: PermissionService,
        private notificationsService: NotificationsService
    ) {}

    async init(): Promise<void> {

        try {
            const roles = this.store.selectSnapshot(PermissionsState.getRoles);
            const user = this.store.selectSnapshot(UserState.getUser);

            const currentRole = roles.find(r => r.id === user.roleId);

            if (currentRole && currentRole.name === RolesService.rootRole) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                return;
            }

            const data = {
                ...this.data,
                registrator: null,
            };

            if (!currentRole || this.permissionService.checkPermission(currentRole.permissions, data, true)) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        } catch (e) {
            console.log(e);
            this.viewContainer.clear();
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }

    }
}

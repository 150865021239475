import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {LangEnum} from '../../_enums/lang.enum';
import {ChangeLanguage} from '../actions/language.actions';

export interface LanguageStateModel {
    language: LangEnum;
}

const LANGUAGE_TOKEN = new StateToken<LanguageStateModel>('languageState');

@State({
    name: LANGUAGE_TOKEN,
    defaults: {
        language: localStorage.getItem('ln') || LangEnum.en
    }
})
@Injectable()
export class LanguageState {
    constructor() {

    }

    @Selector()
    static getLanguage(state: LanguageStateModel): LangEnum {
        return state.language;
    }

    @Action(ChangeLanguage)
    changeLanguage(ctx: StateContext<LanguageStateModel>, payload: ChangeLanguage): void {
        const state = ctx.getState();

        localStorage.setItem('ln', payload.language.toLowerCase());

        ctx.setState({
            ...state,
            language: payload.language
        });

        // ctx.dispatch(new SetCalendarConfig(payload.language));
    }
}

import { Observable, Subject } from 'rxjs';
import { AfterViewInit, Component, EventEmitter, Inject, Input, LOCALE_ID, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { DropdownFilterOptionInterface } from './interfaces/filter-option.interface';
import { FromToTypeCalendar, TypeFilterEnum } from './enums/type-filter.enum';
import { DisabledFilterOptionsEnum } from '../_enums/filter-options.enum';
import { ChartNavigatorService } from 'src/app/groups/container/chart/_services/chart-navigator.service';
import { SelectTypeEnum } from '../_enums/select-type.enum';
import { CustomSelectSideEnum } from '../_enums/custom-select-side.enum';
import { ChartModeEnum } from '../_enums/chart-mode.enum';
import { Select, Store } from '@ngxs/store';
import { TimeFilterState } from '../_store/states/time-filter.state';
import { SetCurrentTimeOption, SetFilterConfig, SetTimeObj, SetTimeType } from '../_store/actions/time-filter.actions';
import { ChangeChartMode } from 'src/app/groups/_store/actions/charts.actions';
import { ConfigForFilterInterface } from '../_interfaces/config-for-filter.interface';
import { FilterTypeEnum } from '../_enums/filter-type.enum';
import { TooltipSideEnum } from '../_enums/tooltip-side.enum';
import { UserState } from '../_store/states/user.state';
import { LuxonParseDateService } from '../_services/luxon-parse-date.service';
import { ParamsFilterForClient, ParamsFilterTypeEnum, ParamsTime, ParamsTimeTypeEnum } from '../_interfaces/params.interface';
import { UserProfileInfoTypeDateEnum } from '../../profile/_enums/user-profile-info-date-time.enum';
import { filter, first, takeUntil } from 'rxjs/operators';
import { SelectOptionInterface } from '../_interfaces/select-option.interface';
import { TimeOptionsInterface } from '../_interfaces/time-options.interface';
import { FilterItemTypeEnum } from './enums/filter-item-type.enum';
import { User } from '../_interfaces/user.interface';
import { BtnsSideEnum } from '../_enums/btns-side.enum';
import { ConfigurationState } from '../_store/states/configuration.state';
import { CONSTANT_KEY } from '../../admin/system-settings/_enums/system-settings.enum';
import { Router } from '@angular/router';
import { NotificationsService } from '../_services/notifications.service';
import { PopupTypeEnum } from '../_enums/popup-type-.enum';
import { NgxPrintService, PrintOptions } from 'ngx-print';
import { DisabledPermissionDirectiveInterface } from '../_interfaces/permission-directive-data.interface';
import { MethodPermission, ResourceAction } from '../_enums/permission.enum';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, AfterViewInit, OnDestroy {
    _optionsFoDropDownSelect: any[];
    _currentOptionKeyForDropdownSelect: string;
    _currentKeyNameForDropdownSelect: any;
    _defaultDropdownValue: any;
    private _currentIconOption;
    private _currentValueForDropdownInput: string;

    @Input() optionsDropDown: DropdownFilterOptionInterface[];
    public selectedDropDown: DropdownFilterOptionInterface = {
        key: 'default',
        value: 'default',
        type: TypeFilterEnum.default,
        property: 'default',
        filterValue: '',
    };

    @Input()
    set currentKeyNameForDropdownSelect(currentKeyNameForDropdownSelect) {
        this._currentKeyNameForDropdownSelect = currentKeyNameForDropdownSelect;
    }

    get currentKeyNameForDropdownSelect(): any {
        return this._currentKeyNameForDropdownSelect;
    }

    @Input()
    set defaultDropdownValue(defaultDropdownValue) {
        this._defaultDropdownValue = defaultDropdownValue;
    }

    get defaultDropdownValue(): any {
        return this._defaultDropdownValue;
    }

    @Input()
    set currentValueForDropdownInput(currentValueForDropdownInput) {
        this._currentValueForDropdownInput = currentValueForDropdownInput;
        if (currentValueForDropdownInput) {
            this.getCurrentValueFromDropdownSelect();
        }
    }

    get currentValueForDropdownInput(): string {
        return this._currentValueForDropdownInput;
    }

    public selectedKeyFilterDropDown: string;
    public dateFilterDropDown = { from: null, to: null };

    @Input()
    set currentOptionKeyForDropdownSelect(currentOptionKeyForDropdownSelect) {
        this._currentOptionKeyForDropdownSelect = currentOptionKeyForDropdownSelect;
        if (currentOptionKeyForDropdownSelect) {
            // setTimeout(() => {
            //     this.selectedDropDown.type = TypeFilterEnum.select;
            this.currentTypeFromDropdownSelect = TypeFilterEnum.select;
            this.getCurrentOptionFromDownSelect();
            // }, 500)
        }
    }

    get currentOptionKeyForDropdownSelect(): any {
        return this._currentOptionKeyForDropdownSelect;
    }

    @Input()
    set optionsFoDropDownSelect(optionsFoDropDownSelect) {
        this._optionsFoDropDownSelect = optionsFoDropDownSelect;
    }

    get optionsFoDropDownSelect(): any[] {
        return this._optionsFoDropDownSelect;
    }

    @Input() filter: ParamsFilterForClient[];

    paramsFilterTypeEnum = ParamsFilterTypeEnum;

    timeoutInputEmitHendler;
    currentTimezone: string;

    currentValueForDropDownFilter: any;
    typeFilterEnum = TypeFilterEnum;

    dateFrom: Date;
    dateTo: Date;
    // today = new Date();
    isApply = false;
    @Input()
    @Input()
    setDefault: Observable<void>;

    @Input() isBtns: boolean;
    @Input() btnsSide: BtnsSideEnum = BtnsSideEnum.right;
    @Input() isBtnDisabled: boolean;
    @Input() btnDisabledTooltip: string;
    @Input() btnEditTitle: string;
    @Input() isBtnPermission: DisabledPermissionDirectiveInterface;
    @Input() journalEditBtn: boolean;
    @Input() printPdfBtn: {
        id: string;
        value: boolean;
        title: string;
    };
    @Input() csvPrintBtn: boolean;
    @Input() iconOpenBtns: boolean;
    @Input() iconsArray: { path: string; tooltip: string; property: string }[];

    selectTypeEnum = SelectTypeEnum;
    tooltipSideEnum = TooltipSideEnum;
    btnsSideEnum = BtnsSideEnum;

    dateFormat = 'dd/MM/yyyy HH:mm:ss';
    hourFormat: string;

    customSelectSideEnum = CustomSelectSideEnum;

    dateObject: ParamsTime = {
        from: null,
        to: null,
        time: null,
        type: null,
    };

    dateNow: Date; // = new Date();

    maxDateTo;
    minDateTo;

    @Output() private setFilter = new EventEmitter<any>();
    @Output() private resetEmitSmth = new EventEmitter<any>();
    @Output() private changeIconDropdown = new EventEmitter<any>();
    @Output() private editBtnsEvent = new EventEmitter<any>();
    @Output() private iconOpenEvent = new EventEmitter<any>();
    @Output() private currentSelectOption = new EventEmitter<any>();
    @Output() private currentFilterDropDown: EventEmitter<DropdownFilterOptionInterface> =
        new EventEmitter<DropdownFilterOptionInterface>();

    @Output() private filterEvent: EventEmitter<ParamsFilterForClient[]> = new EventEmitter<ParamsFilterForClient[]>();
    @Output() private chooseFilterEvent: EventEmitter<SelectOptionInterface> = new EventEmitter<SelectOptionInterface>();
    @Output() private exportCsvEvent: EventEmitter<any> = new EventEmitter<any>();

    @Input() type: FilterItemTypeEnum;
    @Input() isHighlightRegistrators: boolean;

    isDefault = true;

    @Input() iconOptions;

    @Input()
    set currentIconOption(currentIconOption) {
        this._currentIconOption = currentIconOption;
    }

    get currentIconOption(): any {
        return this._currentIconOption;
    }

    fromToTypeCalendar = FromToTypeCalendar;
    filterItemTypeEnum = FilterItemTypeEnum;

    // currentOption = this.timeOptions[2];

    disabledFilterOptionsEnum = DisabledFilterOptionsEnum;
    userProfileInfoTypeDateEnum = UserProfileInfoTypeDateEnum;

    @Select(TimeFilterState.getTimeOptions) timeOptions$: Observable<TimeOptionsInterface[]>;
    @Select(TimeFilterState.getCurrentTimeOption) currentTimeOption$: Observable<TimeOptionsInterface>;
    @Select(TimeFilterState.getTimeType) timeType$: Observable<ParamsTimeTypeEnum>;
    @Select(TimeFilterState.getFilterConfig) config$: Observable<ConfigForFilterInterface>;
    @Select(TimeFilterState.getFilterType) filterType$: Observable<FilterTypeEnum>;
    @Select(TimeFilterState.getTimeObj) timeObj$: Observable<ParamsTime>;
    @Select(UserState.getUser) user$: Observable<User>;

    paramsTimeTypeEnum = ParamsTimeTypeEnum;

    readonly EDIT_JOURNAL_ICON_PATH = './assets/design/icons/events/edit-journal.svg';
    readonly PRINT_PDF_ICON_PATH = './assets/design/icons/pdf-file.svg';
    readonly EXPORT_CSV_ICON_PATH = './assets/design/icons/csv-table.svg';

    private destroy: Subject<boolean> = new Subject<boolean>();

    currentKeyDropdownSelect: string;
    currentTypeFromDropdownSelect: TypeFilterEnum;

    isShowErrorPopup = false;
    popupTypeEnum = PopupTypeEnum;

    constructor(
        @Inject(LOCALE_ID)
        public locale: string,
        private store: Store,
        public navigatorService: ChartNavigatorService,
        private zone: NgZone,
        private router: Router,
        private luxonParseDateService: LuxonParseDateService,
        private notificationsService: NotificationsService,
        private printService: NgxPrintService,
    ) {
        this.locale = 'lv-LV';

        if (this.locale === 'en-US') {
            this.dateFormat = 'dd/mm/yy';
        } else if (this.locale === 'hu-HU' || 'lv-LV' || 'zh-TW' || 'zh-HK' || 'zh-CN' || 'ko-KR' || 'ja-JP') {
            this.dateFormat = 'dd/mm/yy';
        }
    }

    private getCurrentOptionFromDownSelect(): any {
        const result = this.optionsFoDropDownSelect.find((option) => option.key === this.currentOptionKeyForDropdownSelect);
        if (result && result.value) {
            this.currentValueForDropDownFilter = result.value;

            this.selectedDropDown = {
                // ...result,
                filterValue: result.key,
                type: TypeFilterEnum.select,
                property: '',
                key: this.currentKeyNameForDropdownSelect,
                value: this.currentValueForDropDownFilter,
            };

            // this.onChangeDropDownValue({
            //     key: this.currentOptionKeyForDropdownSelect,
            //     value: this.currentValueForDropDownFilter,
            // });
        }
    }

    private getCurrentValueFromDropdownSelect(): void {
        this.selectedDropDown.type = TypeFilterEnum.text;
        this.currentTypeFromDropdownSelect = TypeFilterEnum.text;
        this.selectedDropDown.filterValue = this.currentValueForDropdownInput;
    }

    async ngOnInit(): Promise<void> {
        this.user$
            .pipe(
                filter((user) => !!user),
                first(),
            )
            .subscribe((user) => {
                this.currentTimezone = user.dateTimeZone;
                this.hourFormat = user.dateFormat;
                this.dateNow = this.luxonParseDateService.getDateByTimezone(new Date(), this.currentTimezone);
            });

        this.timeObj$.pipe(takeUntil(this.destroy)).subscribe((timeObj) => {
            if (timeObj) {
                if (this.router.url.includes('group-container/chart')) {
                    this.dateObject.from = timeObj.from;
                    this.dateObject.to = timeObj.to;
                }
                this.dateFrom = timeObj.from
                    ? this.luxonParseDateService.getDateByTimezone(new Date(timeObj.from), this.currentTimezone)
                    : null;
                this.dateTo = timeObj.to ? this.luxonParseDateService.getDateByTimezone(new Date(timeObj.to), this.currentTimezone) : null;
            }
        });

        // console.log(this.printPdfBtn);
    }

    ngOnDestroy(): void {
        this.store.dispatch(new SetFilterConfig(null));

        this.destroy.next(true);
        this.destroy.complete();
    }

    ngAfterViewInit(): void {}

    setDefaultRange(): void {
        this.store.dispatch(new SetTimeType(ParamsTimeTypeEnum.RANGE));

        this.dateTo = this.luxonParseDateService.getDateByTimezone(new Date(), this.currentTimezone);
        const yesterday = this.luxonParseDateService.getDateByTimezone(new Date(), this.currentTimezone);
        this.dateFrom = new Date(yesterday.setDate(yesterday.getDate() - 1));
        this.fromToFilter();
        this.setMaxToDate();
    }

    private setMaxToDate(): void {
        const date = new Date(this.dateFrom);
        const maxDate = this.store.selectSnapshot(ConfigurationState.getConfigurationServer);
        this.maxDateTo =
            maxDate[CONSTANT_KEY.MAX_DAYS_READ_ARCHIVE] && this.router.url.includes('group-container/chart')
                ? this.luxonParseDateService.getDateByTimezone(
                      new Date(date.setDate(date.getDate() + maxDate[CONSTANT_KEY.MAX_DAYS_READ_ARCHIVE] - 1)),
                      this.currentTimezone,
                  )
                : this.luxonParseDateService.getDateByTimezone(new Date(date.setMonth(date.getMonth() + 1)), this.currentTimezone);
        if (this.dateNow < this.maxDateTo) {
            this.maxDateTo = null;
        }
    }

    private setMinToDate(): void {
        if (this.router.url.includes('group-container/chart')) {
            return;
        }
        const date = new Date(this.dateFrom);
        this.minDateTo = new Date(date.setMinutes(date.getMinutes() + 5));
    }

    selectCalendar(place: FromToTypeCalendar): void {
        this.isApply = true;
        const maxDate = this.store.selectSnapshot(ConfigurationState.getConfigurationServer);
        if (place === FromToTypeCalendar.from) {
            // this.dateTo = null;
            // this.dateObject.dateTo = null;
            this.setMaxToDate();
            this.setMinToDate();
        }

        if (
            !this.router.url.includes('group-container/chart') &&
            this.dateFrom &&
            this.dateTo &&
            this.dateFrom.getTime() >= this.dateTo.getTime()
        ) {
            this.dateTo = null;
            this.dateObject.to = null;
        }

        if (
            this.dateFrom &&
            this.dateTo &&
            (+maxDate[CONSTANT_KEY.MAX_DAYS_READ_ARCHIVE] && this.router.url.includes('group-container/chart')
                ? this.dateTo.getTime() - this.dateFrom.getTime() > 1000 * 60 * 60 * 24 * +maxDate[CONSTANT_KEY.MAX_DAYS_READ_ARCHIVE]
                : this.dateTo.getTime() - this.dateFrom.getTime() > 1000 * 60 * 60 * 24 * 30)
        ) {
            this.dateTo = null;
            this.dateObject.to = null;
        }

        // this.cdr.detectChanges();
    }

    resetSmth(): void {
        this.resetEmitSmth.emit();
    }

    closeCalendar(place: FromToTypeCalendar): void {
        this.isApply = true;
        if (place === FromToTypeCalendar.from) {
            this.dateTo = null;
            this.dateObject.to = null;
            this.setMaxToDate();
            this.setMinToDate();
        }
    }

    // определяем какой из фильтров выбран
    applyFilters(currentOption?: any): void {
        this.isApply = false;
        if (this.dateFrom.getTime() >= this.dateTo.getTime()) {
            this.isShowErrorPopup = true;
            return;
        }

        const timeType = this.store.selectSnapshot(TimeFilterState.getTimeType);
        if (timeType === ParamsTimeTypeEnum.ALL_TIME) {
            this.lastPeriodFilter(currentOption);
            return;
        }

        if (timeType === ParamsTimeTypeEnum.RANGE) {
            this.dateObject.from = this.luxonParseDateService.getMillsBySystemTimezone(
                new Date(this.dateObject.from).getTime(),
                this.currentTimezone,
            );
            this.dateObject.to = this.luxonParseDateService.getMillsBySystemTimezone(
                new Date(this.dateObject.to).getTime(),
                this.currentTimezone,
            );
            this.store.dispatch(new SetTimeObj(this.dateObject));
            this.fromToFilter();
            this.setFilter.emit(this.dateObject);
            return;
        }

        this.store.dispatch(new SetTimeObj(this.dateObject));
        this.setFilter.emit(this.dateObject);
        this.filterEvent.emit(this.filter);
    }

    private lastPeriodFilter(currentOption): void {
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                this.setFilter.emit(this.dateObject);
            });
        });
    }

    private fromToFilter(): void {
        this.dateObject.from = this.dateFrom.getTime();

        this.dateObject.to = this.dateTo.getTime();
        this.dateObject.type = ParamsTimeTypeEnum.RANGE;
    }

    changeLastTimeSelect(currentOption?): void {
        this.store.dispatch(new SetCurrentTimeOption(currentOption));

        this.dateObject.from = new Date(new Date().getTime() - currentOption.property).getTime();
        this.dateObject.to = new Date().getTime();

        this.dateObject.type = ParamsTimeTypeEnum.TIME;
        this.dateObject.time = currentOption.property;

        const timeObj = {
            dateFrom: new Date(new Date().getTime() - currentOption.property).getTime(),
            dateTo: new Date().getTime(),
            type: ParamsTimeTypeEnum.TIME,
            time: currentOption.property,
        };
        // this.store.dispatch(new SetTimeObj(timeObj));

        this.applyFilters(currentOption);
    }

    setLastTime(): void {
        const currentOption = this.store.selectSnapshot(TimeFilterState.getCurrentTimeOption);
        this.store.dispatch(new ChangeChartMode(ChartModeEnum.chart));

        this.store.dispatch(new SetTimeType(ParamsTimeTypeEnum.TIME));

        const timeObj: ParamsTime = {
            from: Date.now() - currentOption.property,
            to: Date.now(),
            time: currentOption.property,
            type: ParamsTimeTypeEnum.TIME,
        };

        this.store.dispatch(new SetTimeObj(timeObj));
        this.changeLastTimeSelect(currentOption);
        // this.setFilter.emit(timeObj)
    }

    emmitFilterGroup(item?: ParamsFilterForClient): void {
        if (item && item.isRadio) {
            this.filter.forEach((f) => {
                if (f.isRadio) {
                    f.value = f.title === item.title;
                }
            });
        }
        this.filterEvent.emit(this.filter);
    }

    selectOptionDropDown(event: DropdownFilterOptionInterface): void {
        this.currentSelectOption.emit(event);

        const isNull = this.currentKeyDropdownSelect;
        this.currentValueForDropDownFilter = null;
        this.currentKeyDropdownSelect = event.key;
        this.currentTypeFromDropdownSelect = null;
        this.selectedDropDown.filterValue = '';
        this.dateFilterDropDown.from = null;
        this.dateFilterDropDown.to = null;
        this.selectedKeyFilterDropDown = event.key;

        if (isNull) {
            this.filterEvent.emit(
                this.filter.map((f) => {
                    if (f.type !== ParamsFilterTypeEnum.BOOLEAN && f.type !== ParamsFilterTypeEnum.DATETIME) {
                        f.value = null;
                    }
                    return f;
                }),
            );
            this.currentTypeFromDropdownSelect = event.type;
        } else {
            this.currentValueForDropDownFilter = null;
            this.filterEvent.emit(null);
            this.currentTypeFromDropdownSelect = event.type;
        }

        this.chooseFilterEvent.emit(event);
    }

    onChangeDropDownValue(value: any, calendarType?: FromToTypeCalendar): void {
        if (value === 'reset') {
            this.currentFilterDropDown.emit(null);
            return;
        }

        let index: number;

        if (this.timeoutInputEmitHendler) {
            clearTimeout(this.timeoutInputEmitHendler);
        }

        switch (this.currentTypeFromDropdownSelect) {
            case TypeFilterEnum.enum:
                this.selectedDropDown.filterValue = value;
                break;

            case TypeFilterEnum.text:
                index = this.filter.findIndex((item) => item.property === this.currentKeyDropdownSelect);
                if (index !== -1) {
                    this.filter[index].value = value && value.length ? value : null;
                }
                break;

            case TypeFilterEnum.datetime:
                this.filter = this.filter.map((item: any) => {
                    if (item.type === ParamsFilterTypeEnum.DATETIME && item.property === 'created') {
                        item.value[calendarType] = new Date(value).getTime();

                        if (item.value.to !== null && item.value.from !== null) {
                            item.value.type = ParamsTimeTypeEnum.RANGE;
                            return item;
                        }
                    }

                    return item;
                });

                this.selectedDropDown.filterValue = this.dateFilterDropDown;
                break;
            case TypeFilterEnum.select:
                index = this.filter.findIndex(
                    (item) => item.property === (this.currentKeyDropdownSelect || this.currentKeyNameForDropdownSelect),
                );
                if (index !== -1) {
                    this.filter[index].value = value.key;
                }
                this.currentValueForDropDownFilter = value;
                // this.selectedDropDown.filterValue = value.key;
                break;
            default:
                break;
        }

        this.zone.runOutsideAngular(() => {
            this.timeoutInputEmitHendler = setTimeout(() => {
                this.filterEvent.emit(this.filter);
            }, 500);
        });
    }

    resetDropDownFilter(): void {
        this.optionsDropDown = this.optionsDropDown.map((item) => {
            item.filterValue = '';
            return item;
        });

        this.selectedDropDown = {
            key: 'default',
            value: 'default',
            type: TypeFilterEnum.default,
            property: 'default',
            filterValue: '',
        };
        this.currentValueForDropDownFilter = null;
        this.currentTypeFromDropdownSelect = null;

        this.dateFilterDropDown.from = null;
        this.dateFilterDropDown.to = null;

        this.filter.forEach((f) => {
            if (f.isDropdown && f.type !== ParamsFilterTypeEnum.DATETIME) {
                f.value = null;
            }

            if (f.isDropdown && f.type === ParamsFilterTypeEnum.DATETIME) {
                f.value = {
                    to: null,
                    from: null,
                    time: null,
                    type: ParamsTimeTypeEnum.ALL_TIME,
                };
            }
        });
        this.filterEvent.emit(this.filter);
    }

    setBeginingFromNow(): void {
        this.store.dispatch(new SetTimeType(ParamsTimeTypeEnum.ALL_TIME));
        // this.dateObject.dateTo = new Date().getTime();
        this.dateObject.to = null;
        this.dateObject.from = null;
        this.dateObject.time = null;
        this.dateObject.type = ParamsTimeTypeEnum.ALL_TIME;
        this.store.dispatch(new SetTimeObj(this.dateObject));

        this.setFilter.emit(this.dateObject);
    }

    setCurrent(): void {
        this.store.dispatch(new SetTimeType(ParamsTimeTypeEnum.ALL_TIME));
        this.dateObject.to = null;
        this.dateObject.from = null;
        this.dateObject.time = null;
        this.dateObject.type = ParamsTimeTypeEnum.ALL_TIME;
        this.store.dispatch(new SetTimeObj(this.dateObject));
    }

    selectChartOption(option): void {
        this.isApply = true;
        this.changeIconDropdown.emit(option);
    }

    onEditBtn(): void {
        this.editBtnsEvent.emit();
    }

    onIconBtn(icon: { path: string; tooltip: string; property: string }): void {
        this.iconOpenEvent.emit(icon.property);
    }

    printPdf(): void {
        const printOptions: PrintOptions = new PrintOptions({
            printSectionId: this.printPdfBtn.id,
            printTitle: this.printPdfBtn.title,
        });

        this.printService.printStyle = {
            '.table__th-remove': { display: 'none' },
            '.table__td': {
                border: '1px solid #CCCCCC',
                height: '44px',
                padding: '0 10px',
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
            },
            '.table__th': {
                'border-right': '1px solid #CCCCCC',
                'border-left': '1px solid #CCCCCC',
                'border-bottom': '1px solid #CCCCCC',
                height: '44px',
                padding: '0 10px',
                display: 'flex',
                'align-items': 'center',
                'justify-content': 'center',
            },
            table: { 'border-collapse': 'collapse' },
            '.table__tr-wrapper': { display: 'flex' },
        };
        this.printService.print(printOptions);
    }

    exportCsv(): void {
        this.exportCsvEvent.emit();
    }

    protected readonly methodPermission = MethodPermission;
    protected readonly resourceAction = ResourceAction;
}

import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { DeviceInfoList } from '../../_interfaces/digitalSignatureInfo';
import {
    ClearDigitalSignatureFile,
    GetCheckPdfSignature,
    ResetDigitalSignature,
    SetSelectedFile,
} from '../actions/digital-signature.action';
import { HttpClient } from '@angular/common/http';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { PreloaderService } from '../../../app-shared-elements/_services/preloader.service';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { CertificateIssuedInterface, SignatureInterface, SignPayloadPdf } from '../../_interfaces/signature.interface';

export interface DigitalSignatureModel {
    selectedFile: { base64File: string; name: string };
    digitalSignatureInfo: SignatureInterface;
    fileInfoList: { title: string; name: string }[];
    deviceInfoList: DeviceInfoList[];
}

const DIGITAL_SIGNATURE_MODEL = new StateToken<DigitalSignatureModel>('digitalSignature');

const initDeviceInfoList: DeviceInfoList[] = [
    {
        title: 'digitalSignature.deviceName',
        name: 'deviceName',
        property: 'deviceName',
    },
    {
        title: 'digitalSignature.creationType',
        name: 'creationType',
        property: 'creationType',
        isEdit: true,
    },
    {
        title: 'digitalSignature.deviceId',
        name: 'deviceId',
        property: 'deviceId',
        isEdit: true,
    },
    {
        title: 'digitalSignature.nameReports',
        name: 'nameReports',
        property: 'nameReports',
    },
    {
        title: 'digitalSignature.reportDateStart',
        name: 'reportDateStart',
        property: 'reportDateStart',
        isDate: true,
    },
    {
        title: 'digitalSignature.reportDateEnd',
        name: 'reportDateEnd',
        property: 'reportDateEnd',
        isDate: true,
    },
    {
        title: 'digitalSignature.timeGenerated',
        name: 'timeGenerated',
        property: 'timeGenerated',
        isDate: true,
    },
    {
        title: 'digitalSignature.timeZone',
        name: 'timeZone',
        property: 'timeZone',
    },
];

const initFileInfoList: { title: string; name: string }[] = [
    {
        title: 'digitalSignature.countryName',
        name: 'countryName',
    },
    {
        title: 'digitalSignature.stateName',
        name: 'stateOrProvinceName',
    },
    {
        title: 'digitalSignature.localityName',
        name: 'localityName',
    },
    {
        title: 'digitalSignature.companyName',
        name: 'companyName',
    },
    {
        title: 'digitalSignature.companyUnitName',
        name: 'companyUnitName',
    },
];

@State<DigitalSignatureModel>({
    name: DIGITAL_SIGNATURE_MODEL,
    defaults: {
        selectedFile: { name: '', base64File: '' },
        digitalSignatureInfo: null,
        fileInfoList: initFileInfoList,
        deviceInfoList: initDeviceInfoList,
    },
})
@Injectable()
export class DigitalSignatureState {
    constructor(
        private http: HttpClient,
        private notificationService: NotificationsService,
        private preloaderService: PreloaderService,
    ) {}

    @Selector()
    static getSelectedFile(state: DigitalSignatureModel): { base64File: string; name: string } {
        return state.selectedFile;
    }

    @Selector()
    static getDeviceInfoKeyObject(state: DigitalSignatureModel): SignPayloadPdf {
        return state.digitalSignatureInfo[1];
    }

    @Selector()
    static getFileInfoKeyObject(state: DigitalSignatureModel): CertificateIssuedInterface {
        return state.digitalSignatureInfo[0].issuedBy;
    }

    @Selector()
    static getDigitalSignatureInfo(state: DigitalSignatureModel): SignatureInterface {
        return state.digitalSignatureInfo;
    }

    @Selector()
    static getFileInfoList(state: DigitalSignatureModel): { title: string; name: string }[] {
        return state.fileInfoList;
    }

    @Selector()
    static getDeviceInfoList(state: DigitalSignatureModel): DeviceInfoList[] {
        return state.deviceInfoList;
    }

    @Action(SetSelectedFile)
    async setSelectedFile(ctx: StateContext<DigitalSignatureModel>, payload: SetSelectedFile): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            selectedFile: payload.file,
        });
    }

    @Action(GetCheckPdfSignature)
    async getCheckPdfSignature(ctx: StateContext<DigitalSignatureModel>, payload: GetCheckPdfSignature): Promise<any> {
        const state = ctx.getState();
        const file = payload.file;

        const result: ApiResponse = (await this.http
            .post('/api/report-sign', { file })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result.status === HTTP_STATUS.PAYLOAD_TOO_LARGE) {
            await this.preloaderService.destroyPreloader();
            this.notificationService.onEmit(TooltipStatusEnum.error, false, 'digitalSignature.largeFile');
            return;
        }

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                digitalSignatureInfo: result.data,
            });
        } else {
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(ClearDigitalSignatureFile)
    clearDigitalSignatureFile(ctx: StateContext<DigitalSignatureModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            selectedFile: { base64File: '', name: '' },
        });
    }

    @Action(ResetDigitalSignature)
    resetDigitalSignature(ctx: StateContext<DigitalSignatureModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            selectedFile: { base64File: '', name: '' },
            digitalSignatureInfo: null,
        });
    }
}

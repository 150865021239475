import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken, Store} from '@ngxs/store';
import {DeviceTypeEnum} from 'src/app/app-shared-elements/_enums/device-type.enum';
import {HTTP_STATUS} from 'src/app/app-shared-elements/_enums/status.enum';
import {TooltipStatusEnum} from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import {CreationType} from 'src/app/app-shared-elements/_enums/registrator-sync-status.enu';
import {VariablesNameEnum} from 'src/app/app-shared-elements/_enums/variables-name.enum';
import {ApiResponse} from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import {Device} from 'src/app/app-shared-elements/_interfaces/Device';
import {NotificationsService} from 'src/app/app-shared-elements/_services/notifications.service';
import {AlarmTypeEnum} from 'src/app/events/_enums/alarm.enum';
import {GetGroupById} from 'src/app/groups/_store/actions/groups.actions';
import {GroupsState} from 'src/app/groups/_store/states/groups.state';
import {UpdateVirtualDevice} from 'src/app/device-dashboard/_store/actions/user-devices.actions';
import {DevicesState, DevicesStateModel} from 'src/app/device-dashboard/_store/states/user-devices.state';
import {VirtualRegistratorsRow} from '../../_interfaces/VirtualRegistratorsRow';
import {ChangeTreeDevices, ChangeVirtualDeviceName, CreateVirtualDevice, InitVirtualDeviceTree, SetCurrentVirtualDevice, SetIsInitConstructor, SetRegistratorsOptions, SetVirtualDeviceForShare, UpdateVariablesVirtualDevice} from '../actions/virtual-device.actions';
import {TreeItemInterface} from '../../../groups/_interfaces/tree-item.interface';
import {SelectOptionInterface} from '../../../app-shared-elements/_interfaces/select-option.interface';

export interface VirtualDeviceStateModel {
    tree: TreeItemInterface[];
    currentVDevice: Device;
    isInitConstructor: boolean;
    vDeviceForShare: Device;
    registratorsOptions: SelectOptionInterface<string, string, Device>[];
}

const VIRTUAL_DEVICE_TOKEN = new StateToken<VirtualDeviceStateModel>('virtualDevice');

@State<VirtualDeviceStateModel>({
    name: VIRTUAL_DEVICE_TOKEN,
    defaults: {
        tree: [],
        currentVDevice: null,
        isInitConstructor: false,
        vDeviceForShare: null,
        registratorsOptions: []
    }
})
@Injectable()
export class VirtualDeviceState {
    constructor(
        private http: HttpClient,
        private notificationsService: NotificationsService,
        private store: Store
    ) {

    }

    @Selector()
    static getVirtualDeviceTree(state: VirtualDeviceStateModel): TreeItemInterface[] {
        return state.tree.sort((a, b) => a.id > b.id ? 1 : -1);
    }

    @Selector()
    static getIsInitConstructor(state: VirtualDeviceStateModel): boolean {
        return state.isInitConstructor;
    }

    @Selector()
    static getCurrentVDevice(state: VirtualDeviceStateModel): Device {
        return state.currentVDevice;
    }

    @Selector([DevicesState])
    static getVDevices(state: VirtualDeviceStateModel, devicesState: DevicesStateModel): Device[] {
        return devicesState.devices.filter(device => device.creationType === CreationType.VIRTUAL);
    }

    @Selector()
    static getVDeviceForShare(state: VirtualDeviceStateModel): Device {
        return state.vDeviceForShare;
    }

    @Selector([VirtualDeviceState.getVDevices])
    static getVRows(state: VirtualDeviceStateModel, devices: Device[]): VirtualRegistratorsRow[] {
        return devices.map(device => {
            let status: string;
            let styles;
            switch (device.status) {
                case AlarmTypeEnum.alarm:
                    status = 'customDevices.status.alarm';
                    styles = {
                        color: '#FF6161',
                        fontWeight: 600,
                        backgroundColor: 'var(--deviceCellAlarmBg)',
                        borderBottom: 'var(--deviceCellAlarmBorder)',
                    };
                    break;
                case AlarmTypeEnum.attention:
                    status = 'customDevices.status.attention';
                    styles = {
                        color: '#F9852C',
                        fontWeight: 600,
                        backgroundColor: 'var(--deviceCellAttentionBg)',
                        borderBottom: 'var(--deviceCellAttentionBorder)',
                    };
                    break;
                case AlarmTypeEnum.ok:
                    status = 'customDevices.status.ok';
                    styles = {
                        fontWeight: 600,
                        backgroundColor: 'var(--deviceCellOkBg)',
                        borderBottom: 'var(--deviceCellOkBorder)',
                        color: 'var(--deviceCellOkColor)',
                    };
                    break;
                default:
                    status = 'customDevices.status.ok';
                    styles = {
                        fontWeight: 600,
                        backgroundColor: 'var(--deviceCellOkBg)',
                        borderBottom: 'var(--deviceCellOkBorder)',
                        color: 'var(--deviceCellOkColor)',
                    };
            }
            return {
                isActive: device.isActive,
                name: (device.name as string),
                login: device.login,
                status,
                device,
                styles
            };
        });
    }

    @Selector()
    static getRegistratorsOptions(state: VirtualDeviceStateModel): SelectOptionInterface<string, string, Device>[] {
        return state.registratorsOptions;
    }

    @Action(InitVirtualDeviceTree)
    initVirtualDeviceTree(ctx: StateContext<VirtualDeviceStateModel>, payload: InitVirtualDeviceTree): void {
        const state = ctx.getState();
        const devices = payload.devices;
        const tree: TreeItemInterface[] = [];

        devices.forEach(device => {
            let mainBranch: TreeItemInterface;
            if (device.type === DeviceTypeEnum.registrator && device.creationType === CreationType.ORIGIN) {
                mainBranch = {
                    id: device.id,
                    name: device.name as string || device.defaultName as string,
                    type: DeviceTypeEnum.registrator,
                    fold: false,
                    active: false,
                    children: []
                };

                devices.forEach(d => {
                    if (d.registratorId === device.id) {
                        mainBranch.children.push(
                            {
                                id: d.id,
                                name: d.name || d.defaultName,
                                type: d.type,
                                fold: false,
                                active: false,
                            }
                        );
                    }
                });


                tree.push(mainBranch);
            }
        });

        ctx.setState({
            ...state,
            tree
        });
    }

    @Action(ChangeTreeDevices)
    changeTree(ctx: StateContext<VirtualDeviceStateModel>, payload: ChangeTreeDevices): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            tree: state.tree.map(node => {
                if (node.id === payload.node.node.id) {
                    return {
                        ...node,
                        active: !node.active
                    };
                }

                return node;
            })
        });

    }

    @Action(CreateVirtualDevice)
    async createVirtualDevice(ctx: StateContext<VirtualDeviceStateModel>, payload: CreateVirtualDevice): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = await this.http.post('/api/virtual-devices', {...payload.data}).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
            });
        }
    }

    @Action(UpdateVariablesVirtualDevice)
    async updateVariablesVirtualDevice(ctx: StateContext<VirtualDeviceStateModel>, payload: UpdateVariablesVirtualDevice): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = await this.http.put('/api/virtual-devices/variables', {...payload.data}).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
                currentVDevice: null
            });
            const groups = this.store.selectSnapshot(GroupsState.getGroups);
            const groupOfUpdateDevice = groups.find(g => g.id === payload.data.id);
            if (groupOfUpdateDevice) {
                await ctx.dispatch(new GetGroupById(groupOfUpdateDevice.id)).toPromise();
            }

            ctx.dispatch(new UpdateVirtualDevice(result.data));

        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);

            ctx.setState({
                ...state,
                currentVDevice: null
            });
        }
    }

    @Action(SetIsInitConstructor)
    setIsInitConstructor(ctx: StateContext<VirtualDeviceStateModel>, payload: SetIsInitConstructor): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isInitConstructor: payload.data
        });
    }

    @Action(SetCurrentVirtualDevice)
    setCurrentVirtualDevice(ctx: StateContext<VirtualDeviceStateModel>, payload: SetCurrentVirtualDevice): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentVDevice: payload.data
        });
    }

    @Action(SetVirtualDeviceForShare)
    setVirtualDeviceForShare(ctx: StateContext<VirtualDeviceStateModel>, payload: SetVirtualDeviceForShare): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            vDeviceForShare: payload.data
        });
    }

    @Action(ChangeVirtualDeviceName)
    async changeVirtualDeviceName(ctx: StateContext<VirtualDeviceStateModel>, payload: ChangeVirtualDeviceName): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = await this.http.put('api/virtual-devices/name', {...payload.data}).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                currentVDevice: {
                    ...state.currentVDevice,
                    name: (result.data as Device).name
                }
            });
        } else {
            ctx.setState({
                ...state
            });
        }


    }

    @Action(SetRegistratorsOptions)
    setRegistratorsOptions(ctx: StateContext<VirtualDeviceStateModel>, payload: SetRegistratorsOptions): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            registratorsOptions: payload.devices
                .filter(d => d.creationType === CreationType.ORIGIN && d.type === DeviceTypeEnum.registrator && d.variables.find(v => v.name === VariablesNameEnum.ExpectExpedition)?.currentValue)
                .map(d => {
                    return {
                        key: d.id,
                        value: d.name ?? d.defaultName,
                        property: d
                    };
                })
        });
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClickEventTypeEnum} from 'src/app/app-shared-elements/_enums/click-event-type.enum';
import {AlignItemsEnum, ColumnsTableInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';

@Component({
    selector: 'app-columns-edit',
    templateUrl: './columns-edit.component.html',
    styleUrls: ['./columns-edit.component.scss']
})
export class ColumnsEditComponent implements OnInit {

    _cell: ColumnsTableInterface;
    _row: any;
    _value: any;

    @Input()
    set value(value) {
        this._value = value;
    }

    get value(): any {
        return this._value;
    }


    @Input()
    set cell(cell: ColumnsTableInterface) {
        this._cell = cell;
    }

    get cell(): ColumnsTableInterface {
        return this._cell;
    }

    @Input()
    set row(row) {
        this._row = row;
    }

    get row(): any {
        return this._row;
    }

    @Output() smthEvent = new EventEmitter<{ row: any, eventType: ClickEventTypeEnum }>();
    @Output() editEvent = new EventEmitter<void>();

    isEditMode = false;

    alignItemsEnum = AlignItemsEnum;
    readonly EDIT_ICON_PATH = './assets/design/icons/actions/edit.svg';

    constructor() {
    }

    ngOnInit(): void {
    }

    edit(): void {
        this.smthEvent.emit({
            row: this.row,
            eventType: ClickEventTypeEnum.edit
        });
    }

    delete(): void {
        this.smthEvent.emit({
            row: this.row,
            eventType: ClickEventTypeEnum.delete
        });
    }

    showEdit(): void {
        this.editEvent.emit();
    }
}

import { AlarmTypeEnum } from '../../events/_enums/alarm.enum';
import { Injectable } from '@angular/core';
import { MailingCellFieldTypeEnum } from '../_enums/mailing-cell-field-type.enum';
import {
    CheckboxPropertiesDataInterface,
    CheckboxPropertiesInterface,
    ColumnModeEnum,
    ColumnsTableInterface,
    ColumnTypeEnum,
    ColumnValueAlignEnum,
    IconInterface,
} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { MailingGroupsEditBlockEnum } from '../_enums/mailing-groups-edit-block.enum';
import { MailingAddEventItemInterface } from '../_interfaces/mailing-groups-edit.interface';
import { LogicEvent, LogicEventType } from '../../events/logical-events/_interface/LogicEvent';
import { SelectOptionInterface } from 'src/app/app-shared-elements/_interfaces/select-option.interface';
import { Variable } from '../../app-shared-elements/_interfaces/Variable';
import { ConditionType } from '../../events/_enums/condition-type.enum';

@Injectable({
    providedIn: 'root',
})
export class MailingGroupEditService {
    tableUsersColumns: ColumnsTableInterface[] = [
        {
            title: 'mailing.groups.tables.usersTable.act',
            grow: false,
            small: true,
            maxWidth: '85px',
            minWidth: '85px',
            type: ColumnTypeEnum.icon,
            name: 'isActive',
        },
        {
            title: 'mailing.groups.tables.usersTable.users',
            grow: true,
            small: false,
            minWidth: '240px',
            type: ColumnTypeEnum.text,
            name: 'users',
            align: ColumnValueAlignEnum.left,
            isClick: true,
        },
        {
            title: 'mailing.groups.tables.usersTable.transports',
            grow: true,
            small: false,
            type: ColumnTypeEnum.mailingEditor,
            name: 'transports',
            minWidth: '240px',
            mailingFieldType: MailingCellFieldTypeEnum.transport,
        },
        {
            title: 'devices.actions',
            grow: false,
            small: false,
            maxWidth: '100px',
            minWidth: '85px',
            type: ColumnTypeEnum.action,
            name: 'edit',
            actionBtns: [ColumnsActionTypeEnum.actionBtnsDelete],
        },
    ];

    tableDevicesColumns: ColumnsTableInterface[] = [
        {
            title: 'mailing.groups.tables.devicesTable.registrator',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'registrator',
            align: ColumnValueAlignEnum.left,
        },
        {
            title: 'mailing.groups.tables.devicesTable.status',
            grow: false,
            small: true,
            maxWidth: '220px',
            minWidth: '200px',
            type: ColumnTypeEnum.input,
            mode: ColumnModeEnum.checkbox,
            name: 'isStatusEvent',
        },
        {
            title: 'devices.actions',
            grow: false,
            small: true,
            maxWidth: '100px',
            minWidth: '85px',
            type: ColumnTypeEnum.action,
            name: 'edit',
            actionBtns: [ColumnsActionTypeEnum.actionBtnsDelete],
        },
    ];

    tableMessagesColumns: ColumnsTableInterface[] = [
        {
            title: 'mailing.groups.tables.messagesTable.act',
            grow: false,
            small: true,
            maxWidth: '85px',
            minWidth: '85px',
            type: ColumnTypeEnum.icon,
            name: 'isActive',
        },
        {
            title: 'mailing.groups.tables.messagesTable.type',
            grow: false,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'type',
            maxWidth: '200px',
            minWidth: '140px',
            align: ColumnValueAlignEnum.left,
            isMultilang: true,
            preIcons: true,
        },
        {
            title: 'mailing.groups.tables.messagesTable.registrator',
            grow: true,
            small: false,
            maxWidth: '320px',
            type: ColumnTypeEnum.text,
            name: 'registrator',
            align: ColumnValueAlignEnum.left,
        },
        {
            title: 'mailing.groups.tables.messagesTable.event',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'event',
            align: ColumnValueAlignEnum.left,
            mode: ColumnModeEnum.link,
            isClick: true,
            underline: true,
            isArrow: true,
        },
        {
            title: 'devices.actions',
            grow: false,
            small: true,
            maxWidth: '100px',
            minWidth: '85px',
            type: ColumnTypeEnum.action,
            name: 'edit',
            actionBtns: [ColumnsActionTypeEnum.actionBtnsDelete],
        },
    ];

    tableReportsColumns: ColumnsTableInterface[] = [
        {
            title: 'mailing.groups.tables.reportsTable.act',
            grow: false,
            small: true,
            maxWidth: '85px',
            minWidth: '85px',
            type: ColumnTypeEnum.icon,
            name: 'isActive',
        },
        {
            title: 'mailing.groups.tables.reportsTable.reportName',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'name',
            maxWidth: '200px',
            minWidth: '140px',
            align: ColumnValueAlignEnum.left,
            isMultilang: true,
            preIcons: true,
        },
        {
            title: 'mailing.groups.tables.reportsTable.device',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'device',
            align: ColumnValueAlignEnum.left,
        },
        {
            title: 'mailing.groups.tables.reportsTable.actions',
            grow: false,
            small: true,
            maxWidth: '100px',
            minWidth: '85px',
            type: ColumnTypeEnum.action,
            name: 'edit',
            actionBtns: [ColumnsActionTypeEnum.actionBtnsDelete],
        },
    ];

    delayFinishOptions: SelectOptionInterface<number, string, void>[] = [
        {
            key: null,
            value: 'mailing.groups.options.now',
        },
        {
            key: -1,
            value: 'mailing.groups.options.never',
        },
        {
            key: 1000 * 60 * 60,
            value: 'mailing.groups.options.oneHour',
        },
        {
            key: 1000 * 60 * 60 * 2,
            value: 'mailing.groups.options.twoHour',
        },
        {
            key: 1000 * 60 * 60 * 4,
            value: 'mailing.groups.options.fourHour',
        },
        {
            key: 1000 * 60 * 60 * 8,
            value: 'mailing.groups.options.eightHour',
        },
        {
            key: 1000 * 60 * 60 * 12,
            value: 'mailing.groups.options.twelveHour',
        },
        {
            key: 1000 * 60 * 60 * 24,
            value: 'mailing.groups.options.day',
        },
    ];

    delayAcknOptions: SelectOptionInterface<number, string, void>[] = [
        {
            key: null,
            value: 'mailing.groups.options.never',
        },
        {
            key: 1000 * 60 * 60,
            value: 'mailing.groups.options.oneHour',
        },
        {
            key: 1000 * 60 * 60 * 2,
            value: 'mailing.groups.options.twoHour',
        },
        {
            key: 1000 * 60 * 60 * 4,
            value: 'mailing.groups.options.fourHour',
        },
        {
            key: 1000 * 60 * 60 * 8,
            value: 'mailing.groups.options.eightHour',
        },
        {
            key: 1000 * 60 * 60 * 12,
            value: 'mailing.groups.options.twelveHour',
        },
        {
            key: 1000 * 60 * 60 * 24,
            value: 'mailing.groups.options.day',
        },
    ];

    constructor() {}

    getCurrentTypeEventString(type: AlarmTypeEnum): string {
        switch (type) {
            case AlarmTypeEnum.alarm:
                return 'mailing.groups.eventTypes.alarm';
            case AlarmTypeEnum.attention:
                return 'mailing.groups.eventTypes.attention';
        }
    }

    getPreIcons(event: LogicEvent): IconInterface<void>[] {
        switch (event.logicEventType) {
            case LogicEventType.alarmDeadlineMin:
                return [
                    {
                        path: './assets/design/icons/mailing-group-edit/alarm-min.svg',
                        cellNames: ['type'],
                    },
                ];
            case LogicEventType.attentionDeadlineMin:
                return [
                    {
                        path: './assets/design/icons/mailing-group-edit/attention-min.svg',
                        cellNames: ['type'],
                    },
                ];
            case LogicEventType.alarmDeadlineMax:
                return [
                    {
                        path: './assets/design/icons/mailing-group-edit/alarm-max.svg',
                        cellNames: ['type'],
                    },
                ];
            case LogicEventType.attentionDeadlineMax:
                return [
                    {
                        path: './assets/design/icons/mailing-group-edit/attention-max.svg',
                        cellNames: ['type'],
                    },
                ];
        }
    }

    getMultiEvent(events, currentVariable: Variable): MailingAddEventItemInterface {
        return events.map((event: LogicEvent) => {
            const currentValue = event.expression.conditions[0]?.value.find((v) => v.type === ConditionType.Constant);
            return {
                name: currentValue.value,
                checked: false,
                id: event.id,
                type: MailingGroupsEditBlockEnum.events,
                logicEventType: event.logicEventType,
            };
        });
    }

    getCheckboxProperties(cells: ColumnsTableInterface[]): CheckboxPropertiesInterface {
        return cells
            .filter((item) => item.mode && item.mode === ColumnModeEnum.checkbox)
            .reduce((pre, cur): CheckboxPropertiesInterface => {
                return {
                    ...pre,
                    [cur.name as string]: this.getCheckboxPropertiesData(),
                };
            }, {});
    }

    private getCheckboxPropertiesData(): CheckboxPropertiesDataInterface {
        return {
            isFreeze: false,
            isError: false,
            isWaiting: false,
            isDisabled: false,
            isCheckbox: true,
        };
    }
}

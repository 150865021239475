import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionListItemEnum } from '../_enums/action-list-item.enum';
import { ActionListItemInterface } from '../_interfaces/action-list-item.interface';

@Component({
    selector: 'app-actions-list',
    templateUrl: './actions-list.component.html',
    styleUrls: ['./actions-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsListComponent implements OnInit {
    @Input() list: ActionListItemInterface[] = [];

    actionListItemEnum = ActionListItemEnum;

    @Output() actionEmitter: EventEmitter<ActionListItemInterface> = new EventEmitter<ActionListItemInterface>();

    constructor() {}

    ngOnInit(): void {}

    action(event: ActionListItemInterface): void {
        this.actionEmitter.emit(event);
    }
}

import { AlarmTypeEnum } from 'src/app/events/_enums/alarm.enum';
import { Injectable } from '@angular/core';
import { EventFromServer, TechnologicLogRowInterface } from '../_interfaces/technologic-log-row.interface';
import {
    ColumnIconType,
    ColumnModeEnum,
    ColumnsTableInterface,
    ColumnTypeEnum,
    IconInterface,
} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { EventTypeEnum } from 'src/app/app-shared-elements/_enums/event-type.enum';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { Store } from '@ngxs/store';
import { UsersState } from '../../admin/users/_store/states/users.state';
import { EventSaveStatus } from 'src/app/events/_enums/event-save-state.enum';
import { Params } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { AuthState } from '../../auth/_store/states/auth.state';

@Injectable({
    providedIn: 'root',
})
export class EventLogsService {
    eventsTableColumns: ColumnsTableInterface[] = [
        {
            title: 'logs.events.tables.from',
            grow: false,
            small: false,
            sort: 0,
            maxWidth: '220px',
            type: ColumnTypeEnum.date,
            name: 'ts',
            sortField: 'ts',
        },
        {
            title: 'logs.events.tables.to',
            grow: false,
            small: false,
            sort: 0,
            maxWidth: '230px',
            type: ColumnTypeEnum.tsClear,
            name: 'tsCleared',
            sortField: 'tsCleared',
        },
        {
            title: 'logs.events.tables.message',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'message',
            postIcons: true,
        },
        {
            title: 'logs.events.tables.expression',
            grow: true,
            small: false,
            underline: true,
            type: ColumnTypeEnum.text,
            name: 'eventCondition',
            maxWidth: '280px',
            mode: ColumnModeEnum.link,
            isClick: true,
        },
        {
            title: 'logs.events.tables.type',
            grow: false,
            small: true,
            sort: 0,
            maxWidth: '180px',
            type: ColumnTypeEnum.text,
            name: 'type',
            styles: true,
            isMultilang: true,
            sortField: 'alarmType',
        },
        {
            title: 'logs.events.tables.ackn',
            grow: false,
            small: false,
            sort: 0,
            maxWidth: '220px',
            minWidth: '160px',
            type: ColumnTypeEnum.acknowledge,
            name: 'ackn',
            sortField: 'tsAcknowledget',
        },
    ];

    adminEventsTableColumns: ColumnsTableInterface[] = [
        {
            title: 'logs.events.tables.from',
            grow: false,
            small: false,
            sort: 0,
            maxWidth: '220px',
            type: ColumnTypeEnum.date,
            name: 'ts',
            sortField: 'ts',
        },
        {
            title: 'logs.events.tables.to',
            grow: false,
            small: false,
            sort: 0,
            maxWidth: '230px',
            type: ColumnTypeEnum.tsClear,
            name: 'tsCleared',
            sortField: 'tsCleared',
        },
        {
            title: 'logs.events.tables.message',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'message',
        },
        {
            title: 'logs.events.tables.user',
            grow: false,
            small: true,
            maxWidth: '180px',
            type: ColumnTypeEnum.text,
            name: 'ownerUser',
        },
        {
            title: 'logs.events.tables.expression',
            grow: true,
            small: false,
            underline: true,
            type: ColumnTypeEnum.text,
            name: 'eventCondition',
            maxWidth: '280px',
            mode: ColumnModeEnum.link,
            isClick: true,
        },
        {
            title: 'logs.events.tables.type',
            grow: false,
            small: true,
            sort: 0,
            maxWidth: '180px',
            type: ColumnTypeEnum.text,
            name: 'type',
            styles: true,
            isMultilang: true,
            sortField: 'alarmType',
        },
        {
            title: 'logs.events.tables.ackn',
            grow: false,
            small: false,
            sort: 0,
            maxWidth: '220px',
            minWidth: '160px',
            type: ColumnTypeEnum.acknowledge,
            name: 'ackn',
            sortField: 'tsAcknowledget',
        },
    ];

    eventsOptionsTableColumns: ColumnsTableInterface[] = [
        {
            title: 'logs.events.tables.registrator',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'registrator',
            mode: ColumnModeEnum.link,
            isClick: true,
            underline: true,
        },
        {
            title: 'logs.events.tables.eventName',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'name',
        },
        {
            title: 'logs.events.tables.eventType',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'eventType',
            isMultilang: true,
            tooltip: true,
            tooltipValue: 'logs.events.eventType.tooltipValue',
            icon: ColumnIconType.tooltip,
        },
    ];

    constructor(
        private store: Store,
        private http: HttpClient,
    ) {}

    async getTechnologicalEventsLog(
        params: Params,
        decrementPage?: boolean,
        currentUserId?: string,
    ): Promise<{ items: EventFromServer[]; total: number }> {
        if (params && params.pagination && decrementPage) {
            params.pagination.currentPage = params.pagination.currentPage - 1;
        }

        if (params.pagination.currentPage < 1) {
            params.pagination.currentPage = 1;
        }

        let result: ApiResponse;

        let headers: HttpHeaders;

        if (currentUserId) {
            headers = new HttpHeaders().set('params', encodeURIComponent(JSON.stringify(params))).set('currentUserId', currentUserId);
        } else {
            headers = new HttpHeaders().set('params', encodeURIComponent(JSON.stringify(params)));
        }

        if (this.store.selectSnapshot(AuthState.getIsAdmin)) {
            currentUserId && currentUserId.length
                ? (result = (await this.http
                      .get('api/logic-events-log', { headers })
                      .toPromise()
                      .catch((e) => console.log(e))) as ApiResponse)
                : (result = (await this.http
                      .get('api/control/logic-events-log', { headers })
                      .toPromise()
                      .catch((e) => console.log(e))) as ApiResponse);
        } else {
            result = (await this.http
                .get('api/logic-events-log', { headers })
                .toPromise()
                .catch((e) => console.log(e))) as ApiResponse;
        }

        return result && result.status === HTTP_STATUS.SUCCESS ? result.data : { items: [], total: 0 };
    }

    async initRows(
        eventsObj: { items: EventFromServer[]; total: number },
        devices: Device[],
    ): Promise<{ logs: TechnologicLogRowInterface[]; totalItems: number }> {
        if (!eventsObj) {
            return;
        }

        const events = eventsObj.items;
        if (!events || !events.length) {
            return { logs: [], totalItems: 0 };
        }

        const users = this.store.selectSnapshot(UsersState.getUsers);
        const rows: TechnologicLogRowInterface[] = await Promise.all(
            (events as any).map(async (item: EventFromServer) => {
                const currentUser = users.find((u) => u.id === item.ownerUserId);
                const currentDevice: Device = devices.find((d) => d.id === item.deviceId);
                return {
                    ...item,
                    id: item.id,
                    ts: +item.ts,
                    ackn: {
                        ts: item.tsAcknowledget,
                        isAcknowledgeable: item.isAknowledgeable,
                        acknowledgedByUser: item.acknowledgedByUser,
                    },
                    tsCleared: {
                        time: item.tsCleared ? +item.tsCleared : null,
                        completionType: item.completionType,
                        finishedByUser: item.finishedByUser,
                    },
                    name: item.logicEventName ? item.logicEventName : '',
                    message: item.message,
                    type: +item.alarmType === AlarmTypeEnum.alarm ? 'events.configurator.alert' : 'events.configurator.attention',
                    acknowledgedByUser: item.acknowledgedByUser ? item.acknowledgedByUser : null,
                    isCleared: this.getClearedParam(item),
                    styles: {
                        type: this.getStylesForAlarmType(item),
                    },
                    registratorId: item.registratorId,
                    srcId: item.eventType === 1 ? item.srcId : null,
                    eventCondition: item.eventCondition, // .split('<').join('&lt').split('>').join('&gt'),
                    disableClick:
                        !currentDevice || item.eventType === EventTypeEnum.status || this.store.selectSnapshot(AuthState.getIsAdmin),
                    registrator: this.getRegistrator(item, devices),
                    eventType: this.getEventType(item),
                    typeHover: item.tsCleared ? AlarmTypeEnum.ok : item.alarmType, // this.getTypeHover(item)
                    ownerUser: currentUser ? currentUser.login.split('@')[0] + '@' : '',
                    postIcons: this.getPostIcons(item),
                };
            }),
        );

        // console.log(rows);

        return { logs: rows, totalItems: eventsObj.total };
    }

    getPostIcons(event: EventFromServer): IconInterface<void>[] {
        if (event.savedStatus === EventSaveStatus.PROCESS) {
            return [
                {
                    cellNames: ['message'],
                    path: './assets/design/icons/table-operations/spinner.svg',
                    tooltip: 'logs.events.waitSync',
                },
            ];
        }

        return [];
    }

    getEventType(item: EventFromServer): string {
        let result = '';
        switch (item.eventType) {
            case EventTypeEnum.logic:
                result = 'logs.events.eventType.logic';
                break;
            case EventTypeEnum.status:
                result = item.variableId ? 'logs.events.eventType.statusVariable' : 'logs.events.eventType.statusDevice';
                break;
        }
        return result;
    }

    getRegistrator(item: EventFromServer, devices: Device[]): string {
        let resultMessage = '';
        const currentRegistrator: Device = devices.find((d) => d.id === item.registratorId);
        const currentDevice: Device = devices.find((d) => d.id === item.deviceId);
        let currentVariable: Variable;
        if (currentRegistrator) {
            resultMessage += currentRegistrator.name || currentRegistrator.defaultName;
        }

        if (currentDevice && currentDevice.id !== currentRegistrator.id) {
            resultMessage += ` -> ${currentDevice.name || currentDevice.defaultName}`;
        }

        if (currentDevice && item.variableId) {
            currentVariable = currentDevice.variables.find((v) => v.id === item.variableId);
        }

        if (currentVariable) {
            resultMessage += ` -> ${currentVariable.customName || currentVariable.name}`;
        }

        return resultMessage;
    }

    getStylesForAlarmType(item): any {
        switch (+item.alarmType) {
            case AlarmTypeEnum.alarm:
                return {
                    color: 'var(--eventLogAlarmColor)',
                    borderBottom: '2px solid var(--eventLogAlarmBorder)',
                    fontWeight: 600,
                    backgroundColor: 'var(--backgroundRowAlarm)',
                };
            case AlarmTypeEnum.attention:
                return {
                    color: 'var(--eventLogAttentionColor)',
                    borderBottom: '2px solid var(--eventLogAttentionBorder)',
                    fontWeight: 600,
                    backgroundColor: 'var(--backgroundRowAttention)',
                };
        }
    }

    getClearedParam(item): boolean {
        if (item.isAknowledgeable && !item.tsAcknowledget) {
            return false;
        }

        if (!item.tsCleared || item.tsCleared === 'undefined') {
            return false;
        }

        return true;
    }
}

import {Device} from 'src/app/app-shared-elements/_interfaces/Device';
import {TreeNodeInterface} from '../../_interfaces/tree-node.interface';
import {CreateVirtualDeviceDto, UpdateNameVirtualDeviceDto, UpdateVariablesVirtualDeviceDto} from '../../_interfaces/VirtualRegistratorsDto';

export class InitVirtualRegistratorsRows {
    static readonly type = '[VirtualDevice] InitVirtualRegistratorsRows';

    constructor(public devices: Device[]) {

    }
}

export class InitVirtualDeviceTree {
    static readonly type = '[VirtualDevice] InitVirtualDeviceTree';

    constructor(public devices: Device[]) {

    }
}

export class CreateVirtualDevice {
    static readonly type = '[VirtualDevice] CreateVirtualDevice';

    constructor(public data: CreateVirtualDeviceDto) {
    }
}

export class UpdateVariablesVirtualDevice {
    static readonly type = '[VirtualDevice] GetVirtualRegistrators';

    constructor(public data: UpdateVariablesVirtualDeviceDto) {
    }
}

export class SetIsInitConstructor {
    static readonly type = '[VirtualDevice] SetIsInitConstructor';

    constructor(public data: boolean) {
    }
}

export class SetCurrentVirtualDevice {
    static readonly type = '[VirtualDevice] SetCurrentVirtualDevice';

    constructor(public data: Device) {
    }
}

export class SetVirtualDeviceForShare {
    static readonly type = '[VirtualDevice] SetVirtualDeviceForShare';

    constructor(public data: Device) {
    }
}

export class ChangeVirtualDeviceName {
    static readonly type = '[VirtualDevice] ChangeVirtualDeviceName';

    constructor(public data: UpdateNameVirtualDeviceDto) {
    }
}

export class SetRegistratorsOptions {
    static readonly type = '[VirtualDevice] SetRegistratorsOptions';

    constructor(public devices: Device[]) {
    }
}

export class ChangeTreeDevices {
    static readonly type = '[VirtualDevice] ChangeTreeDevices';

    constructor(public node: TreeNodeInterface) {
    }
}

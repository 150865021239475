import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DropdownFilterOptionInterface } from '../../app-shared-elements/filters/interfaces/filter-option.interface';
import { Pagination, Params, ParamsFilter, ParamsSorted } from '../../app-shared-elements/_interfaces/params.interface';
import { TypeClient } from './_enum/type-client.enum';
import { ColumnsTableInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { Select, Store } from '@ngxs/store';
import {
    AddNewUser,
    ChangeUserActivity,
    ClearUsers,
    CloseEditingUserRow,
    ConfirmUser,
    CreateUser,
    DeleteUser,
    ExportUser,
    GetUsers,
    ImportUser,
    ImportUsers,
    RecoveryUser,
    SetEditingUserRow,
    SetUsersParams,
    ToggleImportPopup,
    UpdateUser,
    UpdateUserEmail,
} from './_store/actions/users.actions';
import { UsersState } from './_store/states/users.state';
import { Observable, Subject } from 'rxjs';
import { SetSkeleton } from 'src/app/app-shared-elements/_store/actions/table.actions';
import { UsersRowInterface } from './_interfaces/users-row.interface';
import { SetCurrentProfileUser } from 'src/app/profile/_store/actions/profile.actions';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { EventFromTableInterface } from 'src/app/app-shared-elements/_interfaces/event-from-table.interface';
import { SelectOptionInterface } from 'src/app/app-shared-elements/_interfaces/select-option.interface';
import { CompanyInterface } from '../company/_interfaces/company.interface';
import { OperationOnCompany } from '../company/_store/actions/company.actions';
import { OperationOnUserCompany } from '../company/_interfaces/operation-on-user-company.interface';
import { CompanyState } from '../company/_store/states/company.state';
import { first, takeUntil } from 'rxjs/operators';
import { TableService } from '../../app-shared-elements/_services/table.service';
import { CreateUserInterface, UpdateUserInterface } from './_interfaces/new-user.interface';
import { TranslateService } from '@ngx-translate/core';
import { BtnsSideEnum } from '../../app-shared-elements/_enums/btns-side.enum';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone/lib/ngx-dropzone/ngx-dropzone.component';
import { RegistrationTypeEnum } from '../../app-shared-elements/_enums/user.enum';
import { DropZoneTypeEnum } from '../../app-shared-elements/_enums/drop-zone-type.enum';
import { SocketEvent } from '../../app-shared-elements/_enums/socket-event.enum';
import { SocketService } from '../../app-shared-elements/_services/socket.service';
import { ImportExportEventInterface } from './_interfaces/import-export-event.interface';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
    @Select(UsersState.getDropDownFilterOptions) dropDownFilterOptions$: Observable<DropdownFilterOptionInterface[]>;
    @Select(UsersState.getParams) params$: Observable<Params>;
    @Select(UsersState.getUsersRows) rows$: Observable<UsersRowInterface[]>;
    @Select(UsersState.getUserTableColumns) columns$: Observable<ColumnsTableInterface[]>;
    @Select(CompanyState.getCompaniesOptions) companiesOptions$: Observable<SelectOptionInterface<string, string, CompanyInterface>[]>;
    @Select(UsersState.getIsEditingMode) isEditingMode$: Observable<boolean>;
    @Select(UsersState.getIsShowImportPopup) isShowImportPopup$: Observable<boolean>;

    typeCreateOptions: SelectOptionInterface<number, string, number>[] = [
        {
            key: RegistrationTypeEnum.GOOGLE,
            property: RegistrationTypeEnum.GOOGLE,
            value: 'table.users.typeOptions.google',
        },
        {
            key: RegistrationTypeEnum.LOCAL,
            property: RegistrationTypeEnum.LOCAL,
            value: 'table.users.typeOptions.user',
        },
        {
            key: RegistrationTypeEnum.ADMIN,
            property: RegistrationTypeEnum.ADMIN,
            value: 'table.users.typeOptions.admin',
        },
    ];

    filterIconsArray: { path: string; tooltip: string; property: string }[] = [
        {
            path: './assets/design/icons/import-user.svg',
            tooltip: 'table.users.popup.importTooltip',
            property: 'import-user',
        },
        {
            path: './assets/design/icons/users/icon-edit.svg',
            tooltip: 'table.users.popup.tooltip',
            property: 'import-users',
        },
    ];

    currentOptionsForDropDownFilter: SelectOptionInterface<any, string, any>[] = [];

    showDeleteUser = false;
    deleteUserRows: UsersRowInterface;
    currentOptionKeyForDropdown: string;
    currentValueForDropDownFilter: string;
    defaultDropdownValue: any;
    companyId: string;
    btnsSideEnum = BtnsSideEnum;
    isImportModal = false;

    currentRow: UsersRowInterface;
    isCanMoveTab = true;

    isShowExportPopup = false;

    password = '';
    confirmPassword = '';

    requestId: number;

    file: File;

    isDropFile = false;

    userExportImportEvent: ImportExportEventInterface;

    isVisiblePassword = false;
    isVisibleConfirmPassword = false;

    dropZoneTypeEnum = DropZoneTypeEnum;

    isDone = false;

    destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private router: Router,
        private store: Store,
        private route: ActivatedRoute,
        private tableService: TableService,
        private translateService: TranslateService,
        private socketService: SocketService,
    ) {
        this.rows$.subscribe((rows) => console.log(rows));

        this.socketService.fromEvent(SocketEvent.USER_EXPORT_IMPORT).subscribe(async (data: ImportExportEventInterface) => {
            this.userExportImportEvent = data;
        });

        this.tableService.updateUserEvent.pipe(takeUntil(this.destroy)).subscribe(async (row: UsersRowInterface) => {
            if (!this.router.url.includes('users')) {
                return;
            }
            this.store.dispatch(new CloseEditingUserRow(row.id));

            const updateData: UpdateUserInterface = {
                id: row.id,
                name: row.userName,
                phone: String(row.phone).length ? row.phone : null,
                lang: this.translateService.currentLang,
                isDisableWebAuth: row.isDisableWebAuth,
            };

            const data: OperationOnUserCompany = {
                userId: row.id,
                companyId: this.companyId,
            };

            if (!row.isConfirmEmail) {
                this.store.dispatch(new UpdateUserEmail({ id: row.id, login: row.login }));
            }

            this.store.dispatch(new UpdateUser(updateData));
            this.store.dispatch(new OperationOnCompany(data));
            this.isCanMoveTab = true;
        });

        this.tableService.closeEditingEvent.pipe(takeUntil(this.destroy)).subscribe(async (row: UsersRowInterface) => {
            this.isCanMoveTab = true;
            this.store.dispatch(new CloseEditingUserRow(row.id, row));
        });

        this.isShowImportPopup$.pipe(takeUntil(this.destroy)).subscribe((toggle) => {
            if (!toggle) {
                this.closeImportPopup();
            }
        });
    }

    async ngOnInit(): Promise<void> {
        this.isCanMoveTab = true;
        await this.store.dispatch(new SetSkeleton(true)).toPromise();
        this.route.queryParamMap.pipe(first()).subscribe(async (data: ParamMap) => {
            const companyId = data.get('companyId');
            if (companyId) {
                const params: Params = this.store.selectSnapshot(UsersState.getParams);
                this.store.dispatch(
                    new SetUsersParams({
                        ...params,
                        filter: params.filter.map((f: ParamsFilter) => {
                            if (f.property === 'companyId') {
                                this.currentOptionKeyForDropdown = companyId;

                                return {
                                    ...f,
                                    value: companyId,
                                };
                            }
                            return f;
                        }),
                    }),
                );
            }

            await this.store.dispatch(new GetUsers()).toPromise();
            await this.store.dispatch(new SetSkeleton(false)).toPromise();

            const options = this.store.selectSnapshot(UsersState.getDropDownFilterOptions);

            this.defaultDropdownValue = companyId ? options[options.length - 1].value : null;
            this.currentValueForDropDownFilter = companyId;
        });
    }

    async ngOnDestroy(): Promise<void> {
        this.destroy.next(true);
        this.destroy.complete();
        this.store.dispatch(new ClearUsers());
        this.store.dispatch(new SetSkeleton(false));
    }

    onChangePage(pagination: Pagination): void {
        const params: Params = this.store.selectSnapshot(UsersState.getParams);
        this.store.dispatch(new SetUsersParams({ ...params, pagination }));
    }

    onChangeSort(data: ParamsSorted[]): void {
        const params: Params = this.store.selectSnapshot(UsersState.getParams);
        this.store.dispatch(new SetUsersParams({ ...params, sorted: [...data] }));
    }

    changeFilter(filter: ParamsFilter[]): void {
        // console.log(filter);
        if (!filter) {
            this.router.navigate([], {
                queryParams: {
                    companyId: null,
                },
                queryParamsHandling: 'merge',
            });
            return;
        }

        const params: Params = this.store.selectSnapshot(UsersState.getParams);
        this.store.dispatch(
            new SetUsersParams({
                ...params,
                filter,
                pagination: { ...params.pagination, currentPage: 1 },
            }),
        );
    }

    openUser(row: { row: UsersRowInterface; event: any; index: number }): void {
        const user = this.store.selectSnapshot(UsersState.getUsers).find((u) => u.id === row.row.id);
        this.store.dispatch(new SetCurrentProfileUser(user));
        this.router.navigate([`control/users-profile/user`], {
            queryParams: {
                id: row.row.id,
                typeClient: TypeClient.User,
            },
        });
    }

    async changeUserActivity(event: { row: UsersRowInterface; event: boolean; index: number }): Promise<void> {
        const changeActivityDto = {
            id: event.row.id,
            value: event.event,
        };

        await this.store.dispatch(new ChangeUserActivity(changeActivityDto)).toPromise();
    }

    checkDeleteUser(data: UsersRowInterface): void {
        this.showDeleteUser = true;
        this.deleteUserRows = data;
    }

    async deleteUser(): Promise<void> {
        await this.store.dispatch(new DeleteUser(this.deleteUserRows.id)).toPromise();
    }

    confirmUser(event: { row: UsersRowInterface; event: boolean; index: number }): void {
        this.store.dispatch(new ConfirmUser(event.row.id));
    }

    recoveryUser(row: UsersRowInterface): void {
        this.store.dispatch(new RecoveryUser(row.id));
    }

    private goToEditRow(event: UsersRowInterface): void {
        // console.log(event);
        this.isCanMoveTab = false;
        this.store.dispatch(new SetEditingUserRow(event.id));
    }

    eventFromTable(data: EventFromTableInterface): void {
        switch (data.type) {
            case ColumnsActionTypeEnum.recovery:
                this.recoveryUser(data.row);
                return;
            case ColumnsActionTypeEnum.actionBtnsEdit:
                this.goToEditRow(data.row);
                return;
            case ColumnsActionTypeEnum.actionBtnsDelete:
                this.checkDeleteUser(data.row);
                return;
            case ColumnsActionTypeEnum.export:
                this.showExportPopup(data.row);
                return;
        }
    }

    showExportPopup(data: UsersRowInterface): void {
        this.isShowExportPopup = true;
        this.currentRow = data;
        // console.log(data);
    }

    async chooseCompany(event: { cell: ColumnsTableInterface; option: SelectOptionInterface<string, string, { cellName: string; data: CompanyInterface }>; row: UsersRowInterface }): Promise<void> {
        if (event.row.isNew || this.store.selectSnapshot(UsersState.getIsEditingMode)) {
            this.companyId = event.option.key;
            return;
        }

        const data: OperationOnUserCompany = {
            userId: event.row.id,
            companyId: event.option.key,
        };
        await this.store.dispatch(new OperationOnCompany(data)).toPromise();
        await this.store.dispatch(new GetUsers()).toPromise();
    }

    async createNewUser(): Promise<void> {
        this.isCanMoveTab = false;
        await this.store.dispatch(new AddNewUser()).toPromise();
    }

    async saveUser(row: { event: UsersRowInterface; row: UsersRowInterface }): Promise<void> {
        const currentLang = this.translateService.currentLang;

        if (row.event.isNew) {
            const data: CreateUserInterface = {
                login: row.event.login,
                name: row.event.userName,
                phone: String(row.event.phone).length ? row.event.phone : null,
                lang: currentLang,
                companyId: this.companyId,
                isDisableWebAuth: row.event.isDisableWebAuth,
            };

            await this.store.dispatch(new CreateUser(data)).toPromise();
        }
        this.isCanMoveTab = true;
    }

    async onFileChanged(event: NgxDropzoneChangeEvent): Promise<void> {
        await this.store.dispatch(new ImportUsers(event.addedFiles[0])).toPromise();
        this.isImportModal = false;
    }

    chooseFilter(event: SelectOptionInterface, choosedFilter?: string): void {
        if (choosedFilter) {
            return;
        }

        if (!event) {
            return;
        }

        switch (event.key) {
            case 'companyId':
                this.currentOptionsForDropDownFilter = this.store.selectSnapshot(CompanyState.getCompaniesOptions);
                break;
            case 'type':
                this.currentOptionsForDropDownFilter = this.typeCreateOptions;
                break;
        }
    }

    async onImportFileChanged(event: NgxDropzoneChangeEvent): Promise<void> {
        this.file = event.addedFiles[0];
        this.isDropFile = true;
    }

    async importUser(): Promise<void> {
        this.requestId = Math.floor(1000 + Math.random() * 9000);
        this.store.dispatch(new ImportUser(this.password, this.file, this.requestId)).subscribe((ev) => {
            this.isDone = true;
        });
    }

    async exportUser(): Promise<void> {
        this.requestId = Math.floor(1000 + Math.random() * 9000);
        this.store.dispatch(new ExportUser(this.currentRow.id, this.password, this.requestId)).subscribe(() => {
            this.isDone = true;
        });
    }

    closeImportPopup(): void {
        this.password = '';
        this.store.dispatch(new ToggleImportPopup(false));
        this.userExportImportEvent = null;
        this.isVisiblePassword = false;
        this.file = null;
        this.isDropFile = false;
    }

    closeExportPopup(): void {
        this.isShowExportPopup = false;
        this.password = '';
        this.confirmPassword = '';
        this.userExportImportEvent = null;
        this.isDone = false;
    }

    iconOpenEvent(event: string): void {
        switch (event) {
            case 'import-user':
                this.store.dispatch(new ToggleImportPopup(true));
                return;
            case 'import-users':
                this.isImportModal = true;
                return;
        }
    }
}

import {ActiveEvents} from 'src/app/events/_interfaces/ActiveEvents';

export class SetErrorAlertEvent {
    static readonly type = '[ErrorAlert] SetErrorAlert';

    constructor(public activeEvents: ActiveEvents[]) {
    }
}

export class SetErrorAlertDevice {
    static readonly type = '[ErrorAlert] SetErrorAlertDevice';

    constructor(public deviceEvents: string[]) {
    }
}

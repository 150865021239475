export enum CONSTANT_TYPE {
    STRING = 'string',
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    UNIXTIMESTAMP = 'unixtimestamp',
}

export enum CONSTANT_KEY {
    TIME_ZONE = 'time_zone',
    FORMAT_TIME = 'format_time',
    LOCALE = 'locale',
    MAX_CHUNK_ARCHIVE = 'max_chunk_archive',
    USER_CONFIRMATION = 'user_confirmation',
    PASSWORD_EXPIRED = 'password_expired',
    MAX_TIME_GENERATION_REPORT = 'max_time_generation_report',
    REQUIRE_CONFIRM_EMAIL = 'require_confirm_email',
    REQUIRE_CONFIRM_BY_ADMIN = 'require_confirm_by_admin',
    REPORT_CERTIFICATE = 'report_certificate',
    MAX_DAYS_READ_ARCHIVE = 'maximum_number_days_read_archive',

    DELAY_DEVICE_LOG = 'delay_device_log',
}

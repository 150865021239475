import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    AddColumn,
    ExportCsvFile,
    GetTableGroups,
    GetTableGroupsRows,
    InitTableColumns,
    RemoveColumn,
    SetDisabledListColumn,
    SetPagination,
} from '../actions/table-groups.actions';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { VariablesNameEnum } from '../../../app-shared-elements/_enums/variables-name.enum';
import { Pagination, ParamsTimeTypeEnum } from '../../../app-shared-elements/_interfaces/params.interface';
import { VariableGroupSettings } from '../../../app-shared-elements/_interfaces/VariableGroupSettings';
import { SubscribeOnArchiveOfSocketData } from '../../_interfaces/subscribe-on-archive-of-socket-data.interface';
import { SubscribeOnArchiveOfClientType } from '../../_enums/subscribe-on-archive-of-client.enum';
import { SocketEvent } from '../../../app-shared-elements/_enums/socket-event.enum';
import {
    ColumnIconType,
    ColumnsTableInterface,
    ColumnTypeEnum,
    ColumnValueAlignEnum,
} from '../../../app-shared-elements/_interfaces/ColumnsTable';
import { CreationType } from '../../../app-shared-elements/_enums/registrator-sync-status.enu';
import { SocketService } from '../../../app-shared-elements/_services/socket.service';
import { UserState } from '../../../app-shared-elements/_store/states/user.state';
import { GroupsState } from './groups.state';
import { TimeFilterState } from '../../../app-shared-elements/_store/states/time-filter.state';
import { DataTypeService } from '../../../app-shared-elements/_services/data-type.service';
import { TableGroupService } from '../../_services/table-group.service';

export interface TableGroupsStateModel {
    tableGroups: { items: any[]; total: number };
    rows: any[];
    generatedTableColumn: ColumnsTableInterface[];
    pagination: Pagination;
    variableIds: string[];
    disabledListColumn: { variableId: string; title: string }[];
}

const TABLE_GROUPS_TOKEN = new StateToken<TableGroupsStateModel>('tableGroups');

const initConfigPagination: Pagination = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: null,
};

@State<TableGroupsStateModel>({
    name: TABLE_GROUPS_TOKEN,
    defaults: {
        tableGroups: { items: [], total: 0 },
        rows: [],
        generatedTableColumn: [],
        pagination: initConfigPagination,
        variableIds: [],
        disabledListColumn: [],
    },
})
@Injectable()
export class TableGroupsState {
    constructor(
        private http: HttpClient,
        private socketService: SocketService,
        private store: Store,
        private tableGroupService: TableGroupService,
        private dataTypeService: DataTypeService,
    ) {}

    @Selector()
    static getPagination(state: TableGroupsStateModel): Pagination {
        return state.pagination;
    }

    @Selector()
    static getTableGroupsRows(state: TableGroupsStateModel): any[] {
        return state.rows;
    }

    @Selector()
    static getGeneratedTableColumn(state: TableGroupsStateModel): ColumnsTableInterface[] {
        return state.generatedTableColumn.filter((f) => !state.disabledListColumn.find((d) => d.variableId === f.variableId));
    }

    @Selector()
    static getDisabledListColumn(state: TableGroupsStateModel): { variableId: string; title: string }[] {
        return state.disabledListColumn;
    }

    @Selector()
    static getVariableIds(state: TableGroupsStateModel): string[] {
        return state.variableIds;
    }

    @Action(GetTableGroups)
    async getTableGroups(ctx: StateContext<TableGroupsStateModel>, payload: GetTableGroups): Promise<void> {
        const state = ctx.getState();

        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);

        const dateTime =
            this.store.selectSnapshot(TimeFilterState.getTimeObj).type === ParamsTimeTypeEnum.RANGE
                ? { ...this.store.selectSnapshot(TimeFilterState.getTimeObj) }
                : { ...this.store.selectSnapshot(TimeFilterState.getTimeObj), dateTo: Date.now() };

        const filter = {
            datetime: dateTime,
            variableIds: state.variableIds,
            groupId: payload.groupId,
            pagination: state.pagination,
        };

        const currentUserId = this.store.selectSnapshot(UserState.getAdminUserId);

        let headers;
        if (currentUserId) {
            headers = new HttpHeaders({
                params: JSON.stringify(filter),
                registratorId: currentRegistrator ? currentRegistrator.id : '',
            }).set('currentUserId', currentUserId);
        } else {
            headers = new HttpHeaders({
                params: JSON.stringify(filter),
                registratorId: currentRegistrator ? currentRegistrator.id : '',
            });
        }

        const result: ApiResponse = (await this.http
            .get('/api/archive/table', { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                tableGroups: result.data as { items: any[]; total: number },
                pagination: {
                    ...state.pagination,
                    totalItems: result.data.total,
                },
            });

            ctx.dispatch(new GetTableGroupsRows());
        }
    }

    @Action(GetTableGroupsRows)
    getTableGroupsRows(ctx: StateContext<TableGroupsStateModel>): void {
        const state = ctx.getState();

        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);
        const batteryLevelVariable = currentRegistrator.variables.find((variable) => variable.name === VariablesNameEnum.BatteryLevel);

        const currentTableColumns = state.generatedTableColumn;

        ctx.setState({
            ...state,
            rows: state.tableGroups.items.map((item) => {
                const row = {
                    ts: +item.ts,
                };
                item.archiveValues.map((value) => {
                    const currentDevice = this.store
                        .selectSnapshot(DevicesState.getCurrentDevice)
                        .variables.find((v) => (v.originVariableId ?? v.id) === value.variableId);
                    if (!currentDevice) {
                        return;
                    }

                    currentTableColumns.map((column: any) => {
                        if (column?.variableId === value.variableId) {
                            if (batteryLevelVariable && batteryLevelVariable.id === value.variableId && value.value === '101') {
                                row[column.name] = '100';
                                return;
                            }
                            row[column.name] = value.value + currentDevice?.unitName;
                        }
                    });
                });
                return row;
            }),
        });
    }

    @Action(InitTableColumns)
    initTableColumns(ctx: StateContext<TableGroupsStateModel>, payload: InitTableColumns): void {
        const state = ctx.getState();
        const currentGroup = payload.currentGroup;
        const user = this.store.selectSnapshot(UserState.getUser);

        const variableIds = [];
        const showSettingsOnTableVariables: VariableGroupSettings[] = currentGroup.variableGroupSettings.filter(
            (variable) => variable.showOnTable,
        );
        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        const currentDevice = devices.find((device) => device.id === currentGroup.deviceId);

        const generatedTableColumn = [];
        currentDevice.variables.forEach((variable) => {
            if (showSettingsOnTableVariables.find((setting) => setting.variableId === variable.id)) {
                variableIds.push(variable.id);
            }
        });

        const data: SubscribeOnArchiveOfSocketData = {
            type: SubscribeOnArchiveOfClientType.SUBSCRIBE,
            variablesId: variableIds,
        };

        this.socketService.emit(SocketEvent.SUBSCRIBE_ON_ARCHIVE, data);

        if (showSettingsOnTableVariables && showSettingsOnTableVariables.length) {
            generatedTableColumn.push({
                title: 'table.tableGroup.date',
                grow: false,
                small: false,
                maxWidth: '260px',
                minWidth: '220px',
                type: ColumnTypeEnum.date,
                name: 'ts',
                isSettings: false,
                sticky: true,
            });

            showSettingsOnTableVariables.map((setting) => {
                const currentVariable = currentDevice.variables.find((variable) => variable.id === setting.variableId);
                generatedTableColumn.push({
                    title: currentVariable.customName ?? currentVariable.name, // this.getCurrentTitle(item.nameLn) || item.currentName,
                    grow: true,
                    small: false,
                    type: ColumnTypeEnum.text,
                    name: currentVariable.id,
                    maxWidth: '200px',
                    minWidth: '200px',
                    icon: ColumnIconType.remove,
                    variableId: currentDevice.creationType === CreationType.ORIGIN ? currentVariable.id : currentVariable.originVariableId,
                    align: ColumnValueAlignEnum.right,
                });
            });

            ctx.setState({
                ...state,
                generatedTableColumn,
                variableIds,
                disabledListColumn: JSON.parse(localStorage.getItem(`tableColumn${user.id}${currentGroup.id}`)) || [],
            });
        } else {
            ctx.setState({
                ...state,
                rows: [],
                generatedTableColumn: [],
                variableIds: [],
            });
        }
    }

    @Action(SetDisabledListColumn)
    setDisabledListColumn(ctx: StateContext<TableGroupsStateModel>, payload: SetDisabledListColumn): void {
        const state = ctx.getState();
        const user = this.store.selectSnapshot(UserState.getUser);
        const currentGroup = this.store.selectSnapshot(GroupsState.getActiveGroup);

        localStorage.setItem(`tableColumn${user.id}${currentGroup.id}`, JSON.stringify(state.disabledListColumn));

        ctx.setState({
            ...state,
            disabledListColumn: payload.columns,
        });
    }

    @Action(SetPagination)
    setPagination(ctx: StateContext<TableGroupsStateModel>, payload: SetPagination): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            pagination: {
                ...state.pagination,
                currentPage: payload.data.currentPage,
            },
        });

        ctx.dispatch(new GetTableGroups(payload.groupId));
    }

    @Action(AddColumn)
    addColumn(ctx: StateContext<TableGroupsStateModel>, payload: AddColumn): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            disabledListColumn: state.disabledListColumn.filter((item) => item.variableId !== payload.column.variableId),
        });

        ctx.dispatch(new SetDisabledListColumn(state.disabledListColumn.filter((item) => item.variableId !== payload.column.variableId)));
    }

    @Action(RemoveColumn)
    removeColumn(ctx: StateContext<TableGroupsStateModel>, payload: RemoveColumn): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            disabledListColumn: [
                {
                    variableId: payload.column.variableId,
                    title: payload.column.title,
                },
                ...state.disabledListColumn,
            ],
        });

        ctx.dispatch(
            new SetDisabledListColumn([
                { variableId: payload.column.variableId, title: payload.column.title },
                ...state.disabledListColumn,
            ]),
        );
    }

    @Action(ExportCsvFile)
    async exportCsvFile(ctx: StateContext<TableGroupsStateModel>): Promise<void> {
        const state = ctx.getState();
        const propertyNames = Object.values(state.generatedTableColumn.map((item) => item.name));
        const rowsTitle = Promise.all(
            Object.values(state.generatedTableColumn.map(async (item) => await this.tableGroupService.getTitle(item.title))),
        );

        const rows = [];

        state.rows
            .map((item) => {
                return {
                    ...item,
                    ts: this.dataTypeService.reformatDate(item.ts),
                };
            })
            .forEach((item) => {
                const values = [];

                propertyNames.forEach((key) => {
                    let value = item[key];

                    value = value !== undefined && value !== null ? value.toString() : '';

                    values.push(value);
                });

                rows.push(values);
            });

        rows.unshift(await rowsTitle);

        const currentDevice = this.store.selectSnapshot(DevicesState.getCurrentDevice);

        const jsonObject = JSON.stringify(rows);
        const csv = this.tableGroupService.convertToCSV(jsonObject);

        const exportedFilename = (currentDevice.name ?? currentDevice.name) + '.csv' || 'export.csv';

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', exportedFilename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

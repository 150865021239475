import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { ActiveEvents } from 'src/app/events/_interfaces/ActiveEvents';
import { TechnologicEvent } from 'src/app/events/_interfaces/TechnologicEvent';
import { EventTypeEnum } from '../../_enums/event-type.enum';
import { GetActiveEventsFromSocket, GetLogicEventsStatuses, SetActiveEventsFromAdmin } from '../actions/active-events.actions';
import { ApiResponse } from '../../_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { HTTP_STATUS } from '../../_enums/status.enum';
import { StatusesEvents } from '../../../events/_interfaces/statuses-events.interface';
import { UpdateUserDeviceArrayStatus } from '../../../device-dashboard/_store/actions/user-devices.actions';

export interface ActiveEventsStateModel {
    activeEvents: ActiveEvents[];
    activeStatuses: StatusesEvents[];
    lastActiveEvent: TechnologicEvent;
}

const ACTIVE_EVENTS_TOKEN = new StateToken<ActiveEventsStateModel>('activeEvents');

@State<ActiveEventsStateModel>({
    name: ACTIVE_EVENTS_TOKEN,
    defaults: {
        activeEvents: [],
        activeStatuses: [],
        lastActiveEvent: null,
    },
})
@Injectable()
export class ActiveEventsState {
    constructor(
        private http: HttpClient,
        private store: Store,
    ) {}

    @Selector()
    static getActiveEvents(state: ActiveEventsStateModel): ActiveEvents[] {
        return state.activeEvents;
    }

    @Selector()
    static getLastActiveEvents(state: ActiveEventsStateModel): TechnologicEvent {
        return state.lastActiveEvent;
    }

    @Selector()
    static getStatusesEvents(state: ActiveEventsStateModel): StatusesEvents[] {
        return state.activeStatuses;
    }

    @Action(GetActiveEventsFromSocket)
    getActiveEventsFromSocket(ctx: StateContext<ActiveEventsStateModel>, payload: GetActiveEventsFromSocket): void {
        const state = ctx.getState();

        let lastActiveEvent: TechnologicEvent;

        if (payload && payload.activeEvents && payload.activeEvents.length) {
            payload.activeEvents.forEach((activeEvent) => {
                if (!activeEvent.events || !activeEvent.events.length) {
                    return;
                }

                const activeStatusEvents = activeEvent.events.filter((e) => e.eventType === EventTypeEnum.status && !e.tsAcknowledget && e.isAknowledgeable);
                // console.log(activeStatusEvents);
                if (!activeStatusEvents.length) {
                    return;
                }

                const latelyActiveEvent = activeStatusEvents.reduce((max: TechnologicEvent, cur: TechnologicEvent) => {
                    return cur && +cur.ts > +max.ts ? cur : max;
                });

                if (!lastActiveEvent) {
                    lastActiveEvent = latelyActiveEvent;
                    return;
                }

                lastActiveEvent = latelyActiveEvent.ts > lastActiveEvent.ts ? { ...latelyActiveEvent } : { ...lastActiveEvent };
            });
        }

        ctx.setState({
            ...state,
            activeEvents: [...payload.activeEvents],
            lastActiveEvent,
        });
    }

    @Action(SetActiveEventsFromAdmin)
    setActiveEventsFromAdmin(ctx: StateContext<ActiveEventsStateModel>, payload: SetActiveEventsFromAdmin): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            activeEvents: payload.activeEvents,
        });
    }

    @Action(GetLogicEventsStatuses)
    async getLogicEventsStatuses(ctx: StateContext<ActiveEventsStateModel>, payload: GetLogicEventsStatuses): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/logic-events/statuses')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                activeStatuses: result.data,
            });

            await this.store.dispatch(new UpdateUserDeviceArrayStatus(result.data, payload.currentEvent)).toPromise();
        }
    }
}

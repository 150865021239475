import { Injectable } from '@angular/core';
import { DropdownFilterOptionInterface } from '../../app-shared-elements/filters/interfaces/filter-option.interface';
import { TypeFilterEnum } from 'src/app/app-shared-elements/filters/enums/type-filter.enum';
import { ActiveDeviceLogs } from '../../device-dashboard/user-devices/_interface/table-device-logs.interface';

import {
    ColumnIconType,
    ColumnModeEnum,
    ColumnsTableInterface,
    ColumnTypeEnum,
    ColumnValueAlignEnum,
} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { Store } from '@ngxs/store';
import { SocketEvent } from '../../app-shared-elements/_enums/socket-event.enum';
import { SocketService } from '../../app-shared-elements/_services/socket.service';
import { FilterDropdownDeviceLogKeyEnum } from '../_enums/filter-dropdown-key.enum';
import { SetActiveDeviceLogsFromSocket } from '../_store/actions/active-device-event.actions';
import { TooltipSideEnum } from '../../app-shared-elements/_enums/tooltip-side.enum';

@Injectable({
    providedIn: 'root',
})
export class DeviceLogsService {
    deviceLogTableColumnsOptional: ColumnsTableInterface[] = [
        {
            title: 'table.deviceLogs.logId',
            grow: false,
            small: true,
            maxWidth: '240px',
            minWidth: '180px',
            type: ColumnTypeEnum.text,
            name: 'id',
        },
        {
            title: 'table.deviceLogs.internalId',
            grow: false,
            small: true,
            maxWidth: '240px',
            minWidth: '180px',
            type: ColumnTypeEnum.text,
            name: 'internalId',
        },
        {
            title: 'table.deviceLogs.id',
            grow: false,
            small: true,
            maxWidth: '240px',
            minWidth: '180px',
            type: ColumnTypeEnum.text,
            name: 'registratorId',
        },
        {
            title: 'table.deviceLogs.additionalInfo',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'additionalInfo',
            align: ColumnValueAlignEnum.left,
            allowHtmlTags: true,
            wrap: true,
        },
        {
            title: 'table.deviceLogs.requestName',
            grow: false,
            small: true,
            maxWidth: '200px',
            minWidth: '160px',
            type: ColumnTypeEnum.text,
            name: 'requestName',
        },
    ];

    deviceTableColumnsDefault: ColumnsTableInterface[] = [
        {
            title: '',
            grow: false,
            small: true,
            maxWidth: '92px',
            type: ColumnTypeEnum.groupOperation,
            name: 'isChoose',
            icon: ColumnIconType.tooltip,
            tooltip: true,
            tooltipSide: TooltipSideEnum.right,
            tooltipValue: 'table.deviceLogs.chooseTooltip',
        },
        {
            title: 'table.deviceLogs.data',
            grow: false,
            small: true,
            maxWidth: '200px',
            minWidth: '200px',
            type: ColumnTypeEnum.date,
            name: 'tsDate',
            sort: 0,
            sortField: 'updated',
        },
        {
            title: 'table.deviceLogs.deviceName',
            grow: true,
            small: false,
            maxWidth: '220px',
            minWidth: '140px',
            type: ColumnTypeEnum.text,
            name: 'deviceName',
            align: ColumnValueAlignEnum.left,
            mode: ColumnModeEnum.link,
            isClick: true,
            underline: true,
        },
        {
            title: 'table.deviceLogs.message',
            grow: true,
            small: true,
            type: ColumnTypeEnum.text,
            name: 'message',
        },
        {
            title: 'logs.events.tables.ackn',
            grow: false,
            small: false,
            maxWidth: '200px',
            minWidth: '140px',
            type: ColumnTypeEnum.acknowledge,
            name: 'ackn',
        },
    ];

    dropDownFilterOptions: DropdownFilterOptionInterface[] = [
        {
            key: FilterDropdownDeviceLogKeyEnum.registratorId,
            value: 'logs.devices.filtersDropdown.registratorId',
            type: TypeFilterEnum.text,
            property: 'registratorId',
        },
        {
            key: FilterDropdownDeviceLogKeyEnum.internalId,
            value: 'logs.devices.filtersDropdown.internalId',
            type: TypeFilterEnum.text,
            property: 'internalId',
        },
        {
            key: FilterDropdownDeviceLogKeyEnum.deviceName,
            value: 'logs.events.dropdownFilters.registrator',
            type: TypeFilterEnum.select,
            property: 'deviceId',
        },
    ];

    private activeDeviceLogsHandler;

    constructor(
        private store: Store,
        private socketService: SocketService,
    ) {}

    public initActiveDeviceLogs(): void {
        if (this.activeDeviceLogsHandler) {
            this.socketService.removeListener(SocketEvent.ACTIVE_DEVICE_LOGS, this.activeDeviceLogsHandler);
        }

        this.activeDeviceLogsHandler = (data) => {
            this.deviceActiveLogsReceived(data);
        };

        this.socketService.on(SocketEvent.ACTIVE_DEVICE_LOGS, this.activeDeviceLogsHandler);
        // this.socket.on('close', this.activeEventsHandler);
    }

    private deviceActiveLogsReceived(data: ActiveDeviceLogs): void {
        this.store.dispatch(new SetActiveDeviceLogsFromSocket(data));
        // this.store.dispatch(new GetDeviceLogsRows());
    }

    setDropDownFilter(data): void {}
}

import {Subject} from 'rxjs';
import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit, OnDestroy {

    destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
    ) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }


}

import { UpdateSocketRoleInterface } from '../../../admin/roles/_interfaces/update-socket-role.interface';
import { CheckPermissionInterface } from '../../_interfaces/check-permission.interface';

export class GetRolePermissionsForUser {
    static readonly type = '[Permissions] GetRolesForUser';

    constructor() {}
}

export class GetRolePermissionsForAdmin {
    static readonly type = '[Permissions] GetRolesForAdmin';

    constructor() {}
}

export class UpdateRoleForUser {
    static readonly type = '[Permissions] UpdateRoleForUser';

    constructor(public roles: UpdateSocketRoleInterface) {}
}

export class InitRoleOptions {
    static readonly type = '[Permissions] InitRoleOptions';
}

export class CheckPermission {
    static readonly type = '[Permissions] checkPermission';

    constructor(public data: CheckPermissionInterface) {}
}

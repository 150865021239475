import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { GetMailingLogs, SetMailingLogsFilter } from '../_store/actions/mailing-messages-log.actions';
import {
    Pagination,
    Params,
    ParamsFilterForClient,
    ParamsSorted,
    ParamsTime,
    ParamsTimeTypeEnum,
} from 'src/app/app-shared-elements/_interfaces/params.interface';
import { skip, takeUntil } from 'rxjs/operators';

import { DisabledFilterOptionsEnum } from 'src/app/app-shared-elements/_enums/filter-options.enum';
import { SetFilterConfig, SetTimeObj, SetTimeType } from 'src/app/app-shared-elements/_store/actions/time-filter.actions';
import { MailingRowLogInterface } from '../../mailing/_interfaces/notification-log.interface';
import { MailingService } from '../_services/mailing.service';
import { MailingMessageLogsState } from '../_store/states/mailing-messages-log.state';
import { TimeFilterState } from 'src/app/app-shared-elements/_store/states/time-filter.state';
import { StateReset } from 'ngxs-reset-plugin';
import { SetSkeleton } from 'src/app/app-shared-elements/_store/actions/table.actions';
import { FilterItemTypeEnum } from '../../app-shared-elements/filters/enums/filter-item-type.enum';

@Component({
    selector: 'app-mailing-log',
    templateUrl: './mailing-log.component.html',
    styleUrls: ['./mailing-log.component.scss'],
})
export class MailingLogComponent implements OnInit, OnDestroy {
    @Select(MailingMessageLogsState.getMailingMessagesLogs) logRows$: Observable<MailingRowLogInterface[]>;
    @Select(MailingMessageLogsState.getMailingParams) params$: Observable<Params>;
    @Select(TimeFilterState.getTimeObj) timeObj$: Observable<ParamsTime>;

    filterItemTypeEnum = FilterItemTypeEnum;
    private timeoutLogs;
    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(public mailingService: MailingService, private store: Store) {
        this.store.dispatch(new SetSkeleton(true));
        this.store.dispatch(new SetTimeObj({ from: null, to: null, time: null, type: ParamsTimeTypeEnum.ALL_TIME }));

        this.store.dispatch(new SetTimeType(ParamsTimeTypeEnum.ALL_TIME));
        this.store.dispatch(
            new SetFilterConfig({
                type: 'date',

                disableFunctions: [
                    DisabledFilterOptionsEnum.current,
                    DisabledFilterOptionsEnum.chartType,
                    DisabledFilterOptionsEnum.time,
                    DisabledFilterOptionsEnum.resetBtn,
                ],
            }),
        );
    }

    async ngOnInit(): Promise<void> {
        await this.getLogRows();
        this.logRows$.subscribe((rows) => console.log(rows));

        this.timeObj$.pipe(takeUntil(this.destroy), skip(1)).subscribe((timeObj) => {
            if (timeObj) {
                const params = this.store.selectSnapshot(MailingMessageLogsState.getMailingParams);
                this.store.dispatch(
                    new SetMailingLogsFilter({
                        ...params,
                        pagination: { ...params.pagination, currentPage: 1 },
                    }),
                );
                this.getLogRows();
            }
        });
    }

    ngOnDestroy(): void {
        clearTimeout(this.timeoutLogs);
        this.store.dispatch(new StateReset(MailingMessageLogsState));
        this.destroy.next(true);
        this.destroy.complete();
    }

    private async getLogRows(): Promise<void> {
        clearTimeout(this.timeoutLogs);
        await this.store.dispatch(new GetMailingLogs()).toPromise();

        this.timeoutLogs = setTimeout(async () => {
            await this.getLogRows();
        }, 60000);
    }

    async onChangePage(data: Pagination): Promise<void> {
        const params = this.store.selectSnapshot(MailingMessageLogsState.getMailingParams);
        await this.store.dispatch(new SetMailingLogsFilter({ ...params, pagination: data })).toPromise();
    }

    changeSort(data: ParamsSorted[]): void {
        const params = this.store.selectSnapshot(MailingMessageLogsState.getMailingParams);
        this.store.dispatch(new SetMailingLogsFilter({ ...params, sorted: [...data] }));
    }

    async changeFilter(filter: ParamsFilterForClient[]): Promise<void> {
        if (!filter) {
            return;
        }
        const params = this.store.selectSnapshot(MailingMessageLogsState.getMailingParams);
        await this.store.dispatch(new SetMailingLogsFilter({ ...params, filter })).toPromise();
    }
}

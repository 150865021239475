import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { MethodPermission, ResourceAction } from '../_enums/permission.enum';
import { PermissionDirectiveDataInterface } from '../_interfaces/permission-directive-data.interface';
import { PermissionInterface } from '../_interfaces/role.interface';
import { PermissionsState } from '../_store/states/permissions.state';
import { SocketService } from './socket.service';
import { SocketEvent } from '../_enums/socket-event.enum';
import { UpdateSocketRoleInterface } from '../../admin/roles/_interfaces/update-socket-role.interface';
import { UpdateRoleForUser } from '../_store/actions/permissions.actions';
import { UsersService } from '../../admin/users/_services/users.service';
import { TypeClient } from '../../admin/users/_enum/type-client.enum';
import { GetDevices } from '../../device-dashboard/_store/actions/user-devices.actions';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    constructor(
        private store: Store,
        private socketService: SocketService,
        private userService: UsersService,
    ) {
        if (this.userService.getTypeClient() === TypeClient.User) {
            this.socketService.fromEvent(SocketEvent.ROLE_UPDATE).subscribe((roles: UpdateSocketRoleInterface) => {
                // console.log(roles);
                this.store.dispatch(new UpdateRoleForUser(roles));
            });
        }
    }

    checkPermission(permissions: PermissionInterface[], data: PermissionDirectiveDataInterface, fromAdminGruard?: boolean): boolean {
        if (!fromAdminGruard) {
            if (!data.registrator.roleId) {
                return true;
            }

            if (data.registrator.roleId === '-1') {
                return false;
            }
        }

        if (!permissions) {
            return false;
        }

        let check = false;

        permissions.forEach((permission) => {
            if (check) {
                return;
            }

            if (permission.resource === data.action && this.checkCurrentCapabilities(permission, data)) {
                check = true;
                return;
            }

            if (permission.childrenPermissions && permission.childrenPermissions.length) {
                check = this.checkPermission(permission.childrenPermissions, data);
            }
        });
        return check;
    }

    checkCurrentCapabilities(permission: PermissionInterface, data: PermissionDirectiveDataInterface): boolean {
        // console.log(permission);
        // console.log(data);
        // let check = true;
        const resultPermissions = [];
        data.permissions.forEach((item) => {
            // if (check) {
            //     return;
            // }

            if (item === MethodPermission.READ && permission.isRead) {
                resultPermissions.push(MethodPermission.READ);
            }

            if (item === MethodPermission.UPDATE && permission.isUpdate) {
                resultPermissions.push(MethodPermission.UPDATE);
            }

            // switch (item) {
            //     case MethodPermission.READ:
            //         if (permission.isRead) {
            //             resultPermissions.push(permission.isRead);
            //         }
            //         break;
            //     case MethodPermission.UPDATE:
            //         if (permission.isUpdate) {
            //             resultPermissions.push(permission.isUpdate);

            //         }
            //         check = permission.isUpdate ? true : false;
            //         break;
            // }
        });
        // console.log(resultPermissions);
        return resultPermissions.length === data.permissions.length;
    }

    getAllowedRegistratorIds(ids: string[], resourceAction: ResourceAction, methodPermissions: MethodPermission[]): string[] {
        const registrators = this.store.selectSnapshot(DevicesState.getRegistrators);
        const roles = this.store.selectSnapshot(PermissionsState.getRoles);

        return ids
            .filter((id) => {
                const currentRegistrator = registrators.find((r) => r.id === id);

                if (currentRegistrator && currentRegistrator.roleId === '-1') {
                    return;
                }
                if (!currentRegistrator || !currentRegistrator.roleId) {
                    return id;
                }

                const currentRole = roles.find((role) => role.id === currentRegistrator.roleId);
                const data: PermissionDirectiveDataInterface = {
                    registrator: currentRegistrator,
                    action: resourceAction,
                    permissions: methodPermissions,
                };

                if (!currentRole || this.checkPermission(currentRole.permissions, data)) {
                    return id;
                }
            })
            .filter((id) => !!id);
    }

    checkPermissionComponent(permissions: { action: ResourceAction; method: MethodPermission[]; registratorId: string }): boolean {
        if (!this.store.selectSnapshot(DevicesState.getIsLoadDevices)) {
            this.store.dispatch(GetDevices).toPromise();
        }
        const roles = this.store.selectSnapshot(PermissionsState.getRoles);
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getRegistrators).find((r) => r.id === permissions.registratorId);
        if (!currentRegistrator.roleId) {
            return true;
        }
        const currentRole = roles.find((role) => role.id === currentRegistrator.roleId);

        const data = {
            registrator: currentRegistrator,
            action: permissions.action,
            permissions: permissions.method,
        };

        return this.checkPermission(currentRole.permissions, data);
    }
}

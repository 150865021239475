import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { CreationType } from 'src/app/app-shared-elements/_enums/registrator-sync-status.enu';
import { TableNamesEnum } from 'src/app/app-shared-elements/_enums/table-names.enum';
import { ColumnIconType, ColumnModeEnum, ColumnsTableInterface, ColumnTypeEnum } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { GroupService } from '../../container/chart/_services/group.service';
import { GroupEditGeneralSettingsRowInterface } from '../../_interfaces/group-edit-general-settings-row.interface';
import { ChangeGroupEditColumns, SetGeneralSettingsRows, UpdateGeneralSettingsRow, UpdateVariableName } from '../actions/general-settings.actions';
import { GroupsState } from './groups.state';
import { SetSkeleton } from '../../../app-shared-elements/_store/actions/table.actions';

export interface GeneralSettingsGroupModel {
    generalSettingsRows: GroupEditGeneralSettingsRowInterface[];
    groupEditColumns: ColumnsTableInterface[];
    virtualGroupEditColumns: ColumnsTableInterface[];
}

const GENERAL_SETTINGS_GROUP_TOKEN = new StateToken<GeneralSettingsGroupModel>('generalSettingsGroupState');

const initialGroupEditTableColumns: ColumnsTableInterface[] = [
    // {
    //     title: 'table.groupEdit.id',
    //     grow: false,
    //     small: false,
    //     maxWidth: '80px',
    //     type: 'text',
    //     name: 'id',
    // },
    {
        title: 'table.groupEdit.name',
        grow: true,
        small: false,
        type: ColumnTypeEnum.text,
        name: 'name',
        isEditable: true,
        isClick: true,
        tooltip: true,
        tableName: TableNamesEnum.projectSettingsTable,
        // widthAuto: true
    },
    {
        title: 'table.groupEdit.group',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'group',
        maxWidth: '90px',
        minWidth: '80px',
    },
    {
        title: 'table.groupEdit.chart',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'chart',
        maxWidth: '90px',
        minWidth: '80px',
    },
    {
        title: 'table.groupEdit.table',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'table',
        maxWidth: '90px',
        minWidth: '80px',
    },
    {
        title: 'table.groupEdit.control',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'control',
        maxWidth: '95px',
        minWidth: '80px',
    },
    {
        title: 'table.groupEdit.archive',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'archive',
        maxWidth: '95px',
        minWidth: '80px',
        icon: ColumnIconType.tooltip,
        tooltip: true,
        tooltipValue: 'table.groupEdit.archiveTooltip',
        isDisable: true,
    },
];

const initialVirtualGroupEditTableColumns: ColumnsTableInterface[] = [
    {
        title: 'table.groupEdit.name',
        grow: true,
        small: false,
        type: ColumnTypeEnum.text,
        name: 'name',
        isClick: true,
        tooltip: true,
        tableName: TableNamesEnum.projectSettingsTable,
        // widthAuto: true
    },
    {
        title: 'table.groupEdit.group',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'group',
        maxWidth: '90px',
        minWidth: '80px',
    },
    {
        title: 'table.groupEdit.chart',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'chart',
        maxWidth: '90px',
        minWidth: '80px',
        isDisable: true,
    },
    {
        title: 'table.groupEdit.table',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'table',
        maxWidth: '90px',
        minWidth: '80px',
        isDisable: true,
    },
    {
        title: 'table.groupEdit.archive',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'archive',
        maxWidth: '95px',
        minWidth: '80px',
        icon: ColumnIconType.tooltip,
        tooltip: true,
        tooltipValue: 'table.groupEdit.archiveTooltip',
        isDisable: true,
    },
];

@State<GeneralSettingsGroupModel>({
    name: GENERAL_SETTINGS_GROUP_TOKEN,
    defaults: {
        generalSettingsRows: [],
        groupEditColumns: initialGroupEditTableColumns,
        virtualGroupEditColumns: initialVirtualGroupEditTableColumns,
    },
})
@Injectable()
export class GeneralSettingsGroupState {
    constructor(
        private store: Store,
        private groupService: GroupService,
    ) {}

    @Selector()
    static getGeneralSettingsRows(state: GeneralSettingsGroupModel): GroupEditGeneralSettingsRowInterface[] {
        return state.generalSettingsRows.sort((a, b) => (a.variable.name > b.variable.name ? 1 : -1));
    }

    @Selector()
    static getGroupEditColumns(state: GeneralSettingsGroupModel): ColumnsTableInterface[] {
        return state.groupEditColumns;
    }

    @Selector()
    static getVirtualGroupEditColumns(state: GeneralSettingsGroupModel): ColumnsTableInterface[] {
        return state.virtualGroupEditColumns;
    }

    @Action(SetGeneralSettingsRows)
    setGeneralSettingsRows(ctx: StateContext<GeneralSettingsGroupModel>): void {
        const state = ctx.getState();
        const rows: GroupEditGeneralSettingsRowInterface[] = [];
        const activeGroup = this.store.selectSnapshot(GroupsState.getActiveGroup);
        const currentDevice = this.store.selectSnapshot(DevicesState.getDevices).find((device) => device.id === activeGroup.deviceId);
        currentDevice.variables.forEach((variable) => {
            const variableGroupSettings = activeGroup.variableGroupSettings.find((i) => i.variableId === variable.id);
            if (!variableGroupSettings) {
                return;
            }

            rows.push({
                id: variable.id,
                name: variable.customName || variable.name,
                variable,
                group: variableGroupSettings.showOnGroup,
                chart: variableGroupSettings.showOnChart, // currentDevice.creationType === CreationType.VIRTUAL && !variable.archived ? false : variableGroupSettings.showOnChart,
                table: variableGroupSettings.showOnTable, // currentDevice.creationType === CreationType.VIRTUAL && !variable.archived ? false : variableGroupSettings.showOnTable,
                control: variableGroupSettings.showOnControl,
                writable: variable.writable,
                archive: variable.archived,
                savedStatusConfiguration: variable.savedStatusConfiguration,
                tooltipValue: { name: variable.name },
                checkboxProperties: this.groupService.getCheckboxProperties(variable, currentDevice.creationType === CreationType.ORIGIN ? initialGroupEditTableColumns : initialVirtualGroupEditTableColumns, currentDevice),
                editConfig: {
                    isEditName: true,
                    maxLength: 128,
                },
            });
        });
        ctx.setState({
            ...state,
            generalSettingsRows: rows,
        });

        ctx.dispatch(new SetSkeleton(false));
    }

    @Action(UpdateVariableName)
    updateVariableName(ctx: StateContext<GeneralSettingsGroupModel>, payload: UpdateVariableName): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            generalSettingsRows: state.generalSettingsRows.map((item) => {
                if (payload.data.id === item.variable.id) {
                    return {
                        ...item,
                        name: payload.data.newName ?? item.name,
                    };
                }

                return item;
            }),
        });
    }

    @Action(UpdateGeneralSettingsRow)
    async updateGeneralSettingsRow(ctx: StateContext<GeneralSettingsGroupModel>, payload: UpdateGeneralSettingsRow): Promise<void> {
        const state = ctx.getState();
        const activeGroup = this.store.selectSnapshot(GroupsState.getActiveGroup);
        const currentDevice = this.store.selectSnapshot(DevicesState.getDevices).find((device) => device.id === activeGroup.deviceId);
        this.groupService.initTimer();
        ctx.setState({
            ...state,
            generalSettingsRows: state.generalSettingsRows.map((row) => {
                if (row.id === payload.row.id) {
                    return {
                        ...payload.row,
                        checkboxProperties: this.groupService.getCheckboxProperties(
                            payload.row.variable,
                            currentDevice.creationType === CreationType.ORIGIN ? initialGroupEditTableColumns : initialVirtualGroupEditTableColumns,
                            currentDevice,
                            true,
                        ),
                    };
                }

                return row;
            }),
        });
    }

    @Action(ChangeGroupEditColumns)
    changeGroupEditColumns(ctx: StateContext<GeneralSettingsGroupModel>, payload: ChangeGroupEditColumns): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            groupEditColumns: [...payload.columns],
        });
    }
}

export enum ServicesInvoiceEnum {
    STANDART = 0,
    ADDITIONAL = 1,
}

export class ServicesInvoiceInterface {
    id?: string;
    invoiceId?: string;
    serviceDescription: string;
    srcId?: string;
    paymentAmount: number;
    countServices: number;
    typeService?: ServicesInvoiceEnum;
    created?: Date;
    isEditing?: boolean;
    isNew?: boolean;
}

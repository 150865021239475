import { ErrorPopupService } from './error-popup.service';
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { ConnectEnum } from '../_enums/connect.enum';
import { SocketUserStatusEnum } from '../../auth/_enums/socket-user-status.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { LangService } from './lang.service';
import { SocketRequestInterface, SocketResponseInterface } from '../_interfaces/SocketInterface';
import { User } from '../../app-shared-elements/_interfaces/user.interface';
import { Store } from '@ngxs/store';
import { SetUser } from '../_store/actions/user.actions';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../_interfaces/ApiRequest';
import { HTTP_STATUS } from '../_enums/status.enum';
import * as workerTimers from 'worker-timers';
import { SocketEvent } from '../_enums/socket-event.enum';
import { map } from 'rxjs/operators';
import { CompressionService } from './compression.service';
import { TokenPayloadInterface } from '../../auth/_interfaces/token-payload.interface';
import { TypeClient } from '../../admin/users/_enum/type-client.enum';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from './notifications.service';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    public errorConnectSubscribe: BehaviorSubject<ConnectEnum> = new BehaviorSubject<ConnectEnum>(null);
    public firstConnect = true;
    public isConnect = false;
    public successfulConnect$: EventEmitter<any> = new EventEmitter<any>();
    public logoutSubscribe: EventEmitter<void> = new EventEmitter<void>();
    public freezeSessionSubscribe: Observable<any>;
    public initLoadedSubscribe: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isLoaded = false;
    public isForceLogout = false;
    private isErrorConnect = false;
    private initDataTimeout;

    activePromiseUser: Promise<User> = null;

    public ping$ = new BehaviorSubject(null);
    private timePing = 0;
    private storeUser: User;

    tiemoutUpdateUser;

    constructor(
        private readonly socket: Socket,
        private langService: LangService,
        private errorPopupService: ErrorPopupService,
        private store: Store,
        private http: HttpClient,
        private translateService: TranslateService,
        private zone: NgZone,
        private notificationService: NotificationsService,
    ) {
        this.logoutSubscribe.subscribe(() => {
            this.storeUser = null;
            this.activePromiseUser = null;
            clearTimeout(this.tiemoutUpdateUser);
        });

        this.nextPing();

        this.socket.on('pong', () => {
            this.ping$.next(Date.now() - this.timePing);
            workerTimers.setTimeout(() => this.nextPing(), 4000);
        });
    }

    getSocketId(): string {
        return this.socket.ioSocket.id || '';
    }

    async updateUser(): Promise<void> {
        clearTimeout(this.tiemoutUpdateUser);
        await this.getUserRequest();

        this.zone.runOutsideAngular(() => {
            this.tiemoutUpdateUser = setTimeout(async () => {
                await this.updateUser();
            }, 60000);
        });
    }

    nextPing(): void {
        this.timePing = Date.now();
        this.socket.emit('ping');
    }

    async getUserRequest(): Promise<User> {
        const data: string = localStorage.getItem('access_token');
        if (!data) {
            return null;
        }

        const dataUser: ApiResponse = (await this.http.get('/api/auth/user', { params: { token: data } }).toPromise()) as ApiResponse;

        if (dataUser.status === HTTP_STATUS.INVALID_PASSWORD) {
            this.logoutSubscribe.emit();

            return null;
        }

        if (dataUser.status === HTTP_STATUS.UNAUTHORIZED) {
            this.logoutSubscribe.emit();

            return null;
        }

        if (dataUser?.status !== HTTP_STATUS.SUCCESS) {
            return null;
        }

        const user: User = dataUser.data;
        this.storeUser = user;

        this.store.dispatch(new SetUser(user));
        return this.storeUser;
    }

    public async getUser(): Promise<User | null> {
        if (this.storeUser) {
            return this.storeUser;
        }

        return await this.getUserRequest();
    }

    async disconnect(): Promise<void> {
        this.socket.disconnect();
    }

    fromEvent(eventName: SocketEvent): Observable<any> {
        return this.socket.fromEvent(eventName).pipe(
            map((data: any) => {
                return CompressionService.decompressionJson(data);
            }),
        );
    }

    on(eventName: SocketEvent, callback: any): void {
        return this.socket.on(eventName, (data: any) => {
            return callback(CompressionService.decompressionJson(data));
        });
    }

    removeListener(eventName: SocketEvent, callback?: any): any {
        this.socket.removeListener(eventName, callback);
    }

    emit(eventName: string, data: any = null): void {
        const request: SocketRequestInterface = {
            token: this.getUserToken(),
            data,
        };
        this.socket.emit(eventName, data);
    }

    connect(): void {
        this.socket.connect();
    }

    async emitWait(eventName: string, data: any = null): Promise<SocketResponseInterface> {
        const request: SocketRequestInterface = {
            token: this.getUserToken(),
            data,
        };

        return new Promise((resolve: any) => {
            this.socket.emit(eventName, data, (response: any) => {
                resolve(response);
            });
        });
    }

    async closeSocket(): Promise<void> {
        await this.disconnect();
        this.firstConnect = true;
        this.isConnect = false;
    }

    reconnect(): void {
        this.socket.disconnect();
        this.socket.connect();
    }

    incrementGlobalPreloaderLoaded(increment: 'loadScript' | 'socketConnect' | 'loadDevice' | 'configuration' | 'loadRoles' | 'notAuthorized' | 'admin'): void {
        (window as any).incrementGlobalPreloader(increment);
    }

    removePreloaderAfterLimitConnection(translate): void {
        (window as any).setErrorInGlobalPreloader(translate);
    }

    async socketAuthentication(): Promise<any> {
        return new Promise((resolve) => {
            this.socket.emit(SocketEvent.AUTHORIZE_USER, this.getUserToken(), async (data: any) => {
                const status: SocketUserStatusEnum = data.status;
                if (!this.isLoaded) {
                    this.isLoaded = true;
                    this.initLoadedSubscribe.next(true);
                }

                switch (status) {
                    case SocketUserStatusEnum.limitConnectionError:
                        const translateDate = { count: data.data.limitConnection };
                        const translate = await this.langService.translateString('disconnectBlock.infoManyTabs', translateDate);
                        this.removePreloaderAfterLimitConnection(translate);

                        document.querySelector('app-root').remove();
                        document.querySelectorAll('script').forEach((script) => script.remove());
                        // await this.errorPopupService.initErrorPopup('disconnectBlock.title', translate);

                        this.socket.disconnect();
                        console.log('%c Socket ', 'color: white; background-color: #2274A5', 'Limit Connection Error');

                        resolve(data);
                        return;

                    case SocketUserStatusEnum.tokenNotAllowed:
                    case SocketUserStatusEnum.credentialsError:
                    case SocketUserStatusEnum.user_is_deleted:
                    case SocketUserStatusEnum.user_not_active:
                    case SocketUserStatusEnum.user_not_confirmed_by_admin:
                    case SocketUserStatusEnum.user_email_not_confirmed:
                        this.logoutSubscribe.emit();
                        console.log('%c Socket ', 'color: white; background-color: #2274A5', 'Authorize Credentials Error');
                        if (!this.isLoaded) {
                            this.initLoadedSubscribe.next(true);
                            this.isLoaded = true;
                        }
                        this.incrementGlobalPreloaderLoaded('notAuthorized');
                        resolve(data);
                        return;

                    case SocketUserStatusEnum.OK:
                        this.timePing = 0;
                        await this.updateUser();

                        this.successfulConnect$.emit(data);
                        if (this.isFirstConnect()) {
                            this.errorConnectSubscribe.next(ConnectEnum.FIRST_CONNECT);
                        } else {
                            this.errorConnectSubscribe.next(ConnectEnum.RECONNECTED);
                        }
                        this.firstConnect = false;
                        this.isConnect = true;
                        if (!this.isLoaded) {
                            this.initLoadedSubscribe.next(true);
                            this.isLoaded = true;
                        }
                        const user = await this.getUser();
                        if (this.getAuthTokenPayload()?.typeClient === TypeClient.Admin) {
                            this.incrementGlobalPreloaderLoaded('admin');
                        } else {
                            this.incrementGlobalPreloaderLoaded('socketConnect');
                        }

                        console.log('%c Socket ', 'color: white; background-color: #2274A5', 'Successfully connected!');
                        resolve(data);
                        return;

                    case SocketUserStatusEnum.logoutUser:
                        if (!this.firstConnect) {
                            this.errorConnectSubscribe.next(ConnectEnum.RECONNECTED);
                        } else {
                            this.incrementGlobalPreloaderLoaded('notAuthorized');
                        }
                        if (!this.isLoaded) {
                            this.initLoadedSubscribe.next(true);
                            this.isLoaded = true;
                        }
                        console.log('%c Socket ', 'color: white; background-color: yellow', 'Logout');
                        this.logoutSubscribe.emit();
                        resolve(data);
                        return;
                    case SocketUserStatusEnum.error:
                        this.socket.disconnect();
                        this.incrementGlobalPreloaderLoaded('notAuthorized');

                        this.logoutSubscribe.emit();
                }
            });
        }).catch((e) => {
            console.log(e);
        });
    }

    public getUserToken(): string {
        return localStorage.getItem('access_token');
    }

    getAuthTokenPayload(): TokenPayloadInterface {
        const token = this.getUserToken();
        if (!token) {
            return null;
        }

        return JSON.parse(atob(token.split('.')[1]));
    }

    public init(): void {
        this.socket.fromEvent('connect').subscribe(async () => {
            if (this.isErrorConnect) {
                this.isErrorConnect = false;
                this.errorConnectSubscribe.next(ConnectEnum.RECONNECT_AFTER_ERROR_CONNECT);
            }

            await this.socketAuthentication();
        });

        this.socket.fromEvent('disconnect').subscribe(async () => {
            if (!(await this.getUserToken())) {
                console.log('%c Socket ', 'color: white; background-color: #D33F49', 'Disconnect');
                this.isConnect = false;
                this.errorConnectSubscribe.next(ConnectEnum.DISCONNECT);
                this.incrementGlobalPreloaderLoaded('notAuthorized');
            }
        });

        this.socket.fromEvent('connect_error').subscribe(async (data) => {
            this.isErrorConnect = true;

            if (this.firstConnect) {
                await this.disconnect();
                this.removePreloaderAfterLimitConnection(await this.translateService.get('disconnectBlock.infoUnavailableServer').toPromise());
                return;
            }

            this.errorConnectSubscribe.next(ConnectEnum.ERROR_CONNECT);

            this.isConnect = false;
            console.log('connect_error');
        });

        this.freezeSessionSubscribe = this.fromEvent(SocketEvent.FREEZE_SESSION);
        this.socket.fromEvent(SocketEvent.FORCE_LOGOUT).subscribe(async () => {
            this.isForceLogout = true;
            this.logoutSubscribe.emit();
        });
    }

    public isFirstConnect(): boolean {
        return this.firstConnect;
    }

    public isConnected(): boolean {
        return this.isConnect;
    }

    public unFreezeSession(token, password: string): Promise<boolean> {
        return new Promise((resolve) => {
            this.socket.emit(SocketEvent.UN_FREEZE_SESSION, { password }, (data) => {
                resolve(data.status === SocketUserStatusEnum.OK);
            });
        });
    }

    public freezeSession(): void {
        this.socket.emit(SocketEvent.FREEZE_SESSION);
    }

    public doAction(): void {
        this.socket.emit('doSessionAction');
    }

    public initDataAfterConnect(): void {
        clearTimeout(this.initDataTimeout);
        this.initDataTimeout = setTimeout(() => {
            this.emit('initDataAfterConnect', this.getUserToken());
        }, 1500);
    }
}

import { Injectable } from '@angular/core';
import { UserProfileInfoTypeDateEnum } from '../../../profile/_enums/user-profile-info-date-time.enum';
import { ConfigurationState } from '../../../app-shared-elements/_store/states/configuration.state';
import { Store } from '@ngxs/store';
import { LuxonParseDateService } from '../../../app-shared-elements/_services/luxon-parse-date.service';
import { CONSTANT_KEY } from '../_enums/system-settings.enum';
import { LangEnum } from '../../../app-shared-elements/_enums/lang.enum';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { ConstantInterface, ConstantRowsInterface, SystemSettingsIconActionEnum } from '../_interfaces/system-settings.interface';
import { CheckboxPropertiesDataInterface, ColumnTypeEnum, IconInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { TranslateService } from '@ngx-translate/core';
import { DataTypeService } from 'src/app/app-shared-elements/_services/data-type.service';
import { TooltipSideEnum } from '../../../app-shared-elements/_enums/tooltip-side.enum';

@Injectable({
    providedIn: 'root',
})
export class SystemSettingsService {
    constructor(
        private store: Store,
        private luxonParseDateService: LuxonParseDateService,
        private translateService: TranslateService,
        private dataTypeService: DataTypeService,
    ) {}

    async getCurrentValue(item: ConstantInterface): Promise<string | boolean> {
        const currentOffset = this.luxonParseDateService.getCurrentOffset(item.value) / 60;
        const timeZoneValue = `${item.value} ${+currentOffset > 0 ? '+' + currentOffset : currentOffset}`;
        switch (item.key) {
            case CONSTANT_KEY.TIME_ZONE:
                return timeZoneValue;
            case CONSTANT_KEY.FORMAT_TIME:
                return item.value === UserProfileInfoTypeDateEnum.ddMMyyyy ? 'profile.dateFormat.ddMMyyyy' : 'profile.dateFormat.mmDDyyyy';
            case CONSTANT_KEY.REQUIRE_CONFIRM_BY_ADMIN:
            case CONSTANT_KEY.REQUIRE_CONFIRM_EMAIL:
                return !!+item.value;
            case CONSTANT_KEY.REPORT_CERTIFICATE:
                return await this.getValueCertificate(item);

            default:
                return item.value;
        }
    }

    private async getValueCertificate(item: ConstantInterface): Promise<string> {
        if (!item) {
            return await this.translateService.get('systemSettings.report_certificate_disable').toPromise();
        }

        return await this.translateService
            .get('systemSettings.report_certificate_valid', {
                from: this.dataTypeService.reformatDate(item.value.validFrom),
                to: this.dataTypeService.reformatDate(item.value.validTo),
            })
            .toPromise();
    }

    getCheckboxProperties(item: ConstantInterface): { value: CheckboxPropertiesDataInterface } {
        if (item.key === CONSTANT_KEY.REQUIRE_CONFIRM_BY_ADMIN || item.key === CONSTANT_KEY.REQUIRE_CONFIRM_EMAIL) {
            return {
                value: {
                    isFreeze: false,
                    isError: false,
                    isWaiting: false,
                    isDisabled: false,
                    isCheckbox: true,
                    styles: {
                        'justify-content': 'flex-start',
                    },
                },
            };
        }
    }

    getCurrentSelectOptions(
        key: CONSTANT_KEY,
    ):
        | SelectOptionInterface<LangEnum, LangEnum>[]
        | SelectOptionInterface<UserProfileInfoTypeDateEnum, string, UserProfileInfoTypeDateEnum>[]
        | SelectOptionInterface[] {
        switch (key) {
            case CONSTANT_KEY.TIME_ZONE:
                return this.initTimeZoneOptions();
            case CONSTANT_KEY.FORMAT_TIME:
                return this.initDateFormatOptions();
            case CONSTANT_KEY.LOCALE:
                return this.initLocaleOptions();
        }
    }

    private initLocaleOptions(): SelectOptionInterface<LangEnum, LangEnum>[] {
        return [
            {
                key: LangEnum.ua,
                value: LangEnum.ua,
            },
            {
                key: LangEnum.en,
                value: LangEnum.en,
            },
        ];
    }

    private initDateFormatOptions(): SelectOptionInterface<UserProfileInfoTypeDateEnum, string, UserProfileInfoTypeDateEnum>[] {
        return [
            {
                type: 'dateFormat',
                property: UserProfileInfoTypeDateEnum.ddMMyyyy,
                key: UserProfileInfoTypeDateEnum.ddMMyyyy,
                value: 'profile.dateFormat.ddMMyyyy',
            },
            {
                type: 'dateFormat',
                property: UserProfileInfoTypeDateEnum.mmDDyyyy,
                key: UserProfileInfoTypeDateEnum.mmDDyyyy,
                value: 'profile.dateFormat.mmDDyyyy',
            },
        ];
    }

    private initTimeZoneOptions(): SelectOptionInterface[] {
        const timezones = this.store.selectSnapshot(ConfigurationState.getConfigurationServer).timezones.map((zone) => {
            const offsetHour = this.luxonParseDateService.getCurrentOffset(zone) / 60;
            const value = `${zone} ${+offsetHour > 0 ? '+' + offsetHour : offsetHour}`;
            return {
                property: `${offsetHour}`,
                value,
                key: `${zone}`,
                type: 'timezone',
            };
        });

        return timezones.sort((a, b) => +a.property - +b.property);
    }

    getCurrentColumnType(row: ConstantRowsInterface): ColumnTypeEnum {
        switch (row.key) {
            case CONSTANT_KEY.TIME_ZONE:
            case CONSTANT_KEY.FORMAT_TIME:
            case CONSTANT_KEY.LOCALE:
                return ColumnTypeEnum.select;
            case CONSTANT_KEY.REQUIRE_CONFIRM_BY_ADMIN:
            case CONSTANT_KEY.REQUIRE_CONFIRM_EMAIL:
                return ColumnTypeEnum.input;
            case CONSTANT_KEY.REPORT_CERTIFICATE:
                return ColumnTypeEnum.text;
            default:
                return ColumnTypeEnum.editor;
        }
    }

    getPostIcons(item: ConstantRowsInterface): IconInterface<SystemSettingsIconActionEnum>[] {
        if (!item) {
            return;
        }

        switch (item.key) {
            case CONSTANT_KEY.MAX_TIME_GENERATION_REPORT:
            case CONSTANT_KEY.PASSWORD_EXPIRED:
            case CONSTANT_KEY.MAX_DAYS_READ_ARCHIVE:
            case CONSTANT_KEY.DELAY_DEVICE_LOG:
                return [
                    {
                        path: './assets/design/icons/table-operations/info.svg',
                        action: SystemSettingsIconActionEnum.info,
                        styles: {
                            fill: 'var(--columnMailingIconColor)',
                            stroke: 'var(--columnMailingIconColor)',
                        },
                        tooltip: this.getTooltipValue(item),
                        tooltipSide: TooltipSideEnum.left,
                        cellNames: ['title'],
                    },
                ];
            default:
                return [];
        }
    }

    private getTooltipValue(row: ConstantRowsInterface): string {
        switch (row.key) {
            case CONSTANT_KEY.PASSWORD_EXPIRED:
            case CONSTANT_KEY.MAX_DAYS_READ_ARCHIVE:
                return 'systemSettings.tooltip.days';
            case CONSTANT_KEY.DELAY_DEVICE_LOG:
            case CONSTANT_KEY.MAX_TIME_GENERATION_REPORT:
                return 'systemSettings.tooltip.sec';
            default:
                return '';
        }
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-toggle-button',
    templateUrl: './toggle-button.component.html',
    styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent {
    @Input() title: string;
    @Input() isToggle: boolean;
    @Input() isWait: boolean;
    @Input() isDisabled: boolean;
    @Input() isEdit: boolean;
    @Output() changeEvent = new EventEmitter();

    constructor() {}

    change(): void {
        this.isToggle = !this.isToggle;
        this.changeEvent.emit(this.isToggle);
    }
}

import { Injectable } from '@angular/core';
import {
    ColumnIconType,
    ColumnsTableInterface,
    ColumnTypeEnum,
    ColumnValueAlignEnum,
} from '../../app-shared-elements/_interfaces/ColumnsTable';
import { ColumnsActionTypeEnum } from '../../app-shared-elements/_enums/columns-action-type.enum';
import { BillingInvoiceInterface, PaymentStatusEnum } from '../_interfaces/billing-invoice.interface';
import { CustomTooltipTypeEnum } from '../../app-shared-elements/_enums/custom-tooltip-type.enum';
import { TooltipSideEnum } from '../../app-shared-elements/_enums/tooltip-side.enum';
import { DataTypeService } from '../../app-shared-elements/_services/data-type.service';

@Injectable({
    providedIn: 'root',
})
export class BillingInvoiceService {
    invoiceTableColumns: ColumnsTableInterface[] = [
        {
            title: 'billing.invoice.table.nNumber',
            grow: true,
            small: false,
            maxWidth: '320px',
            minWidth: '300px',
            type: ColumnTypeEnum.text,
            align: ColumnValueAlignEnum.center,
            sort: true,
            name: 'invoiceDateNumber',
            sortField: 'invoiceNumber',
        },
        {
            title: 'billing.invoice.table.period',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            align: ColumnValueAlignEnum.center,
            sort: true,
            name: 'dateInvoice',
            sortField: 'dateInvoice',
        },
        {
            title: 'billing.invoice.table.debt',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            align: ColumnValueAlignEnum.center,
            name: 'totalPayable',
        },
        {
            title: 'billing.invoice.table.status',
            grow: false,
            small: false,
            styles: true,
            type: ColumnTypeEnum.text,
            align: ColumnValueAlignEnum.center,
            name: 'status',
            isMultilang: true,
            tooltip: true,
            icon: ColumnIconType.tooltip,
            customTooltipType: CustomTooltipTypeEnum.status,
            tooltipMaxWidth: '250px',
            tooltipSide: TooltipSideEnum.bottom,
        },
        {
            title: 'events.logicalEvents.table.actions',
            grow: false,
            small: true,
            maxWidth: '96px',
            minWidth: '96px',
            type: ColumnTypeEnum.action,
            name: 'edit',
            actionBtns: [ColumnsActionTypeEnum.view, ColumnsActionTypeEnum.download],
        },
    ];

    constructor(private dataTypeService: DataTypeService) {}

    getInvoiceStatus(invoice: BillingInvoiceInterface): string | Date {
        switch (invoice.paymentStatus) {
            case PaymentStatusEnum.NOT_COMPLETE:
                return '-';
            case PaymentStatusEnum.AWAITING_PAYMENT:
                return 'billing.invoice.status.awaitPaid';
            case PaymentStatusEnum.PAYMENT_NOT_PAID:
                return 'billing.invoice.status.paymentNotPaid';
            case PaymentStatusEnum.LATE_PAYMENT:
                return 'billing.invoice.status.latePaid';
            case PaymentStatusEnum.PAID:
                return this.dataTypeService.reformatDate(invoice.datePaid);
        }
    }

    getInvoiceRowStyle(invoice: BillingInvoiceInterface): { backgroundColor: string } {
        switch (invoice.paymentStatus) {
            case PaymentStatusEnum.NOT_COMPLETE:
                return {
                    backgroundColor: 'rgba(204, 204, 204, 0.38)',
                };
            case PaymentStatusEnum.LATE_PAYMENT:
                return {
                    backgroundColor: '#F8E6E6',
                };
        }
    }

    getInvoiceStatusColor(invoice: BillingInvoiceInterface): { color: string } {
        switch (invoice.paymentStatus) {
            case PaymentStatusEnum.PAID:
                return {
                    color: '#0FA579',
                };
            case PaymentStatusEnum.PAYMENT_NOT_PAID:
                return {
                    color: '#F9852C',
                };
            case PaymentStatusEnum.LATE_PAYMENT:
                return {
                    color: '#FF6161',
                };
        }
    }
}

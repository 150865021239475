import { ActionLogDeviceEnum } from 'src/app/device-dashboard/user-devices/_enum/action-log-device.enum';
import { TsDatePropertyInterface } from '../../app-shared-elements/_interfaces/ts-date-property.interface';

export interface DeviceLogInterface {
    acknowledgedByUser: string | null;
    acknowledgedType: AcknowledgedDeviceLogType;
    action: ActionLogDeviceEnum;
    created: string;
    updated: string;
    count: number;
    deviceName: string;
    deviceId: string;
    id: string;
    internalId: string;
    isAknowledgeable: boolean;
    keyMessage: string;
    messageForAdmin: string;
    messageInfo: string;
    messageRequest: string;
    registratorId: string;
    requestName: string;
    tsAcknowledget: string;
    userId: string;
    userLogin: string;
    tsDate: TsDatePropertyInterface;
}

export enum AcknowledgedDeviceLogType {
    NATIVE = 0,
    DELETE = 1,
}

import { Params } from '../../../../app-shared-elements/_interfaces/params.interface';
import { CreateUserInterface, UpdateUserInterface } from '../../_interfaces/new-user.interface';
import { UsersRowInterface } from '../../_interfaces/users-row.interface';
import { DisabledAuthUserInterface } from '../../_interfaces/disabled-auth-user.interface';

export class GetUsers {
    static readonly type = '[Users] GetUsers';

    constructor(public total?: boolean) {}
}

export class GetUser {
    static readonly type = '[Users] GetUser';

    constructor(public userId: string) {}
}

export class InitUsersRows {
    static readonly type = '[Users] InitUsersRows';
}

export class DeleteUser {
    static readonly type = '[Users] DeleteUser';

    constructor(public id: string) {}
}

export class ChangeUserActivity {
    static readonly type = '[Users] ChangeUserActivity';

    constructor(public data: { id: string; value: boolean }) {}
}

export class ConfirmUser {
    static readonly type = '[Users] ConfirmUser';

    constructor(public id: string) {}
}

export class SetUsersParams {
    static readonly type = '[Users] SetUsersParams';

    constructor(public params: Params) {}
}

export class ClearUsers {
    static readonly type = '[Users] ClearUsers';
}

export class RecoveryUser {
    static readonly type = '[Users] RecoveryUser';

    constructor(public id: string) {}
}

export class CreateUser {
    static readonly type = '[Users] CreateUser';

    constructor(public data: CreateUserInterface) {}
}

export class UpdateUser {
    static readonly type = '[Users] UpdateUser';

    constructor(public data: UpdateUserInterface) {}
}

export class UpdateUserEmail {
    static readonly type = '[Users] UpdateUserEmail';

    constructor(public data: { id: string; login: string }) {}
}

export class AddNewUser {
    static readonly type = '[Users] AddNewUser';
}

export class SetEditingUserRow {
    static readonly type = '[Users] SetEditingUserRow';

    constructor(public id: string) {}
}

export class CloseEditingUserRow {
    static readonly type = '[Users] CloseEditingUserRow';

    constructor(
        public id: string,
        public savedRow?: UsersRowInterface,
    ) {}
}

export class ImportUsers {
    static readonly type = '[Users] ImportUsers';

    constructor(public file: File) {}
}

export class ImportUser {
    static readonly type = '[Users] ImportUser';

    constructor(
        public password: string,
        public file: File,
        public requestId: number,
    ) {}
}

export class ToggleImportPopup {
    static readonly type = '[Users] ToggleImportPopup';

    constructor(public toggle: boolean) {}
}

export class ExportUser {
    static readonly type = '[Users] ExportUser';

    constructor(
        public userId: string,
        public password: string,
        public requestId: number,
    ) {}
}

export class ChangeDisabledAuthUser {
    static readonly type = '[Users] ChangeDisabledAuthUser';

    constructor(public data: DisabledAuthUserInterface) {}
}

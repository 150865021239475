import { AddUserInterface, CreateUserInterface, UpdateUserInterface } from '../../../admin/users/_interfaces/new-user.interface';
import { Params } from '../../../app-shared-elements/_interfaces/params.interface';

export class GetUsers {
    static readonly type = '[CompanyStaff] GetUsers';
}

export class InitCompanyStaffRow {
    static readonly type = '[CompanyStaff] InitCompanyStaffRow';
}

export class CreateUser {
    static readonly type = '[CompanyStaff] CreateUser';

    constructor(public data: CreateUserInterface) {}
}

export class ChangeUserActive {
    static readonly type = '[CompanyStaff] ChangeUserActive';

    constructor(public data: { id: string; value: boolean }) {}
}

export class UpdateUser {
    static readonly type = '[CompanyStaff] UpdateUser';

    constructor(public data: UpdateUserInterface) {}
}

export class UpdateUserEmail {
    static readonly type = '[CompanyStaff] UpdateUserEmail';

    constructor(public data: { id: string; login: string }) {}
}

export class AddNewUser {
    static readonly type = '[CompanyStaff] AddNewUser';
}

export class RecoveryUser {
    static readonly type = '[CompanyStaff] RecoveryUser';

    constructor(public id: string) {}
}

export class ChangeUserPassword {
    static readonly type = '[CompanyStaff] ChangeUserPassword';

    constructor(
        public id: string,
        public password: string,
    ) {}
}

export class DeleteUser {
    static readonly type = '[CompanyStaff] DeleteUser';

    constructor(public id: string) {}
}

export class SetEditingUserRow {
    static readonly type = '[CompanyStaff] SetEditingUserRow';

    constructor(public id: string) {}
}

export class CloseEditingUserRow {
    static readonly type = '[CompanyStaff] CloseEditingUserRow';

    constructor(
        public id: string,
        public savedRow?: any,
    ) {}
}

export class SetUsersParams {
    static readonly type = '[CompanyStaff] SetUsersParams';

    constructor(public params: Params) {}
}

export class UpdateAddUserArray {
    static readonly type = '[CompanyStaff] UpdateAddUserArray';

    constructor(public data: AddUserInterface) {}
}

export class ToggleImportPopup {
    static readonly type = '[CompanyStaff] ToggleImportPopup';

    constructor(public toggle: boolean) {}
}

export class ImportUsers {
    static readonly type = '[CompanyStaff] ImportUsers';

    constructor(public file: File) {}
}

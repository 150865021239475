import { DataTypeService } from '../../../../app-shared-elements/_services/data-type.service';
import { Axis } from '../../../../app-shared-elements/_interfaces/Axis';
import { EventEmitter, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetAxis } from 'src/app/groups/_store/actions/charts.actions';
import { ChartState } from 'src/app/groups/_store/states/chart.state';
import { ChartModeEnum } from 'src/app/app-shared-elements/_enums/chart-mode.enum';
import { VariableWithArchive } from 'src/app/app-shared-elements/_interfaces/VariableWithArchive';
import { AlarmTypeEnum } from 'src/app/events/_enums/alarm.enum';
import { EventTypeEnum } from 'src/app/app-shared-elements/_enums/event-type.enum';
import { ThemeEnum } from 'src/app/theme/_enums/theme.enum';
import { ChartAlarmIcon } from 'src/app/groups/_enums/chart-alarm-icon.enum';
import { DataSeries } from 'src/app/groups/_interfaces/data-series.interface';
import { ChartEventsEnum } from 'src/app/groups/_enums/chart-events.enum';
import { ReportSettingsInterface } from 'src/app/reports/_interfaces/ReportSettings';
import { IconInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { AxisRowIconActionEnum } from 'src/app/groups/_interfaces/chart-settings-row.interface';
import { RangeBoxEventInterface } from 'src/app/groups/_interfaces/range-box-event.interface';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { LogicEvent, LogicEventType } from 'src/app/events/logical-events/_interface/LogicEvent';
import { GroupsState } from 'src/app/groups/_store/states/groups.state';
import { Group } from 'src/app/app-shared-elements/_interfaces/Group';
import { ReportService } from 'src/app/reports/_services/report.service';
import * as Highcharts from 'highcharts';
import Xrange from 'highcharts/modules/xrange';
import { DevicesState } from '../../../../device-dashboard/_store/states/user-devices.state';
import { VariablesNameEnum } from '../../../../app-shared-elements/_enums/variables-name.enum';
import { DataloggerModificationEnum } from '../../../../details-device-container/_enums/datalogger-modification.enum';
import { TimeFilterState } from '../../../../app-shared-elements/_store/states/time-filter.state';

Xrange(Highcharts);

@Injectable({
    providedIn: 'root',
})
export class ChartSettingsService {
    public graphEvents: EventEmitter<{ type: ChartEventsEnum; event?: any }> = new EventEmitter<{
        type: ChartEventsEnum;
        event?: RangeBoxEventInterface;
    }>();
    private selectionEventBlock = false;
    public chartWidth: number;
    public chartHeight: number;

    currentTheme: ThemeEnum;
    startTime: number;

    private currentReport: ReportSettingsInterface;

    public isDetailDate: boolean = localStorage.getItem('isDetailDate') === 'true' ? true : false;

    constructor(
        private dataTypeService: DataTypeService,
        private store: Store,
        private reportService: ReportService,
    ) {}

    public setIsDetailDate(value: boolean): void {
        this.isDetailDate = value;
    }

    private getStartTime(): number {
        return this.startTime;
    }

    public async getGraphSettings(variables: VariableWithArchive[], registratorId: string, axis: Axis[] = null, dsVariablesWithArchive: any[], dsVariables: Variable[], typeChart?: ChartModeEnum, timeZone?: string): Promise<any> {
        if (!variables || !variables.length) {
            return;
        }
        if (!axis) {
            await this.store.dispatch(new GetAxis(registratorId)).toPromise();
            axis = this.store.selectSnapshot(ChartState.getAxis);
        }
        axis = axis.filter((item) => variables.find((variable) => variable.axis === item.id));
        this.currentTheme = localStorage.getItem('theme') as ThemeEnum;
        const activeGroup = this.store.selectSnapshot(GroupsState.getActiveGroup);
        const iconAlarmInBase64 = this.currentTheme === ThemeEnum.light ? ChartAlarmIcon.logicAlarmLight : ChartAlarmIcon.logicAlarmDark;
        const iconAttentionInBase64 = this.currentTheme === ThemeEnum.light ? ChartAlarmIcon.logicAttentionLight : ChartAlarmIcon.logicAttentionDark;
        const iconStatusAlarmInBase64 = ChartAlarmIcon.statusIcon;
        const report = this.getCurrentReportForChart();
        const timezoneOffset = this.dataTypeService.getTimezoneOffset(timeZone);
        if (!axis || !axis.length) {
            return;
        }

        const modification = this.store.selectSnapshot(DevicesState.getCurrentDevice)?.variables?.find((f) => f.name === VariablesNameEnum.Modification);
        let master;
        let width = this.getChartWidth();
        let height = this.getHeight();
        if (dsVariables?.length || modification?.currentValue === DataloggerModificationEnum.DLT_22) {
            const this$ = this;
            width = this.getChartWidth() - 10;
            height = this.getHeight() - 30;
            master = {
                master: {
                    chart: {
                        type: 'xrange',
                        height: 160,
                        reflow: true,
                        width: width,
                        backgroundColor: '#fff',
                        spacingLeft: 10,
                    },
                    title: {
                        text: '',
                    },
                    credits: {
                        enabled: false,
                    },
                    xAxis: {
                        type: 'datetime',
                        title: {
                            text: null,
                        },
                        lineColor: 'var(--chartLineColor)',
                        lineWidth: 1,
                        labels: {
                            style: {
                                color: 'var(--chartLineColor)',
                            },
                        },
                        minPadding: 0.01,
                        maxPadding: 0.01,
                    },
                    legend: {
                        enabled: false,
                    },
                    time: {
                        timezoneOffset: -timezoneOffset || 0,
                    },
                    plotOptions: {
                        series: {
                            centerInCategory: true,
                        },
                    },
                    yAxis: {
                        title: {
                            text: '',
                        },
                        type: 'category',
                        categories: dsVariables.sort((a, b) => +a.name.slice(-1) - +b.name.slice(-1)).map((variable) => variable.customName?.slice(0, 8) ?? variable.name?.slice(0, 8)),
                        reversed: true,
                    },
                    tooltip: {
                        useHTML: true,
                        distance: 20,
                        formatter(): string {
                            const dateStrX = dataTypeService.reformatDate(this.x);
                            const dateStrX2 = dataTypeService.reformatDate(this.x2);
                            return `${this.series.userOptions.name} = ${this$.getValueNames(this.point.options.value)} <br> ${dateStrX} - ${dateStrX2}`;
                        },
                    },
                    series: this.performDsSeries(dsVariablesWithArchive, dsVariables),
                },
            };
        } else {
            master = {
                master: {
                    chart: {
                        reflow: true,
                        height: '100px',
                        width: this.getChartWidth(),
                        backgroundColor: '#F8F8F8',
                        zoomType: 'x',
                        events: {
                            selection: (event: RangeBoxEventInterface) => {
                                this.emitSelectionEvent(event);
                                return false;
                            },

                            load: () => {
                                // this.graphEvents.emit({type: ChartEventsEnum.load});
                            },
                            redraw: (isRemoveFirstPoint: boolean) => {},
                            style: {},
                        },
                    },
                    time: {
                        timezoneOffset: -timezoneOffset || 0,
                    },
                    title: {
                        text: '',
                    },
                    xAxis: {
                        type: 'datetime',
                        title: {
                            text: null,
                        },
                        lineColor: 'var(--chartLineColor)',
                        lineWidth: 1,
                        labels: {
                            style: {
                                color: 'var(--chartLineColor)',
                            },
                        },
                        minPadding: 0,
                    },
                    yAxis: (() => {
                        return this.performYAxis(axis, true, typeChart, variables, activeGroup);
                    })(),
                    tooltip: {
                        formatter(): boolean {
                            return false;
                        },
                    },
                    legend: {
                        enabled: false,
                    },
                    credits: {
                        enabled: false,
                    },
                    plotOptions: {
                        series: {
                            lineWidth: 1,
                            marker: {
                                enabled: false,
                            },
                            shadow: false,
                            states: {
                                hover: {
                                    lineWidth: 1,
                                },
                            },
                            enableMouseTracking: false,
                        },
                    },
                    series: (() => {
                        return this.performSeries(variables, 'master', axis, activeGroup);
                    })(),

                    exporting: {
                        enabled: false,
                    },
                },
            };
        }

        const dataTypeService = this.dataTypeService;
        const isDetailDate = this.isDetailDate;
        return new Promise((resolve, reject) => {
            const settings: any = {
                ...master,
                detail: {
                    lang: {
                        // month:
                    },
                    chart: {
                        // renderTo: document.getElementById('detail'),
                        selectionMarkerFill: 'none',
                        reflow: true,
                        spacingLeft: 0,
                        zoomType: 'x',
                        width: width ? width : null,
                        height: height || null,
                        resetZoomButton: {
                            theme: {
                                display: 'none',
                            },
                        },

                        events: {
                            selection: (event) => {
                                // event.stopPropagation();
                                return false;
                            },
                            render: () => {
                                this.graphEvents.emit({ type: ChartEventsEnum.detailChartRender });
                            },
                        },
                    },
                    time: {
                        // useUTC: false,
                        // timezoneOffset: -180 // gmt +3
                        timezoneOffset: -timezoneOffset || 0,
                    },
                    tooltip: {
                        useHTML: true,
                        formatter(): string {
                            const name = this.series.name ? this.series.name : '';
                            const dateStr = dataTypeService.reformatDate(this.x);
                            let unit;
                            let valueF;
                            let currentData;
                            let currentItem;

                            switch (typeChart) {
                                case ChartModeEnum.alarm:
                                    currentData = this.series.userOptions.data.find((item) => item[0] === this.x)[2];
                                    if (!currentData) {
                                        return `<span>${name}<br/> ${dateStr}</span>`;
                                    }
                                    if (currentData.message) {
                                        unit = currentData.message || '';
                                        return `<span>${name} ${unit}, <br/> ${dateStr}</span>`;
                                    }
                                    currentItem = currentData.userOptions?.data.find((item) => item[0] === this.x)[2];

                                    if (!currentItem) {
                                        return `<span>${name}<br/> ${dateStr}</span>`;
                                    }
                                    unit = currentItem.message || '';
                                    return `<span>${name} ${unit}, <br/> ${dateStr}</span>`;

                                case ChartModeEnum.chartAndAlarm:
                                    if (this.series.userOptions.marker.enabled) {
                                        currentData = this.series.userOptions.data.find((item) => item[0] === this.x)[2];
                                        if (!currentData) {
                                            return `<span>${name}<br/> ${dateStr}</span>`;
                                        }
                                        if (currentData.message) {
                                            unit = currentData.message || '';
                                            return `<span>${name} ${unit}, <br/> ${dateStr}</span>`;
                                        }
                                        currentItem = currentData.userOptions?.data.find((item) => item[0] === this.x)[2];
                                        if (!currentItem) {
                                            return `<span>${name}<br/> ${dateStr}</span>`;
                                        }
                                        unit = currentItem.message || '';

                                        switch (this.series.userOptions.marker.symbol) {
                                            case iconAlarmInBase64:
                                                // unit = this.series.userOptions.data.find(item => item[0] === this.x)[2].message;
                                                return `<span>${name} (${unit}, <br/> ${dateStr} <span style="color: #FF6161; font-weight: 600">[Alarm]</span>)</span>`;
                                            case iconAttentionInBase64:
                                                // unit = this.series.userOptions.data.find(item => item[0] === this.x)[2].message;
                                                return `<span>${name} (${unit}, <br/> ${dateStr} <span style="color: #F5A623; font-weight: 600">[Attention]</span>)</span>`;
                                            case iconStatusAlarmInBase64:
                                                // unit = this.series.userOptions.data.find(item => item[0] === this.x)[2].message;
                                                return `<span>${unit}, <br/> ${dateStr} <span style="color: #F5A623; font-weight: 600">(StatusEvent)</span></span>`;
                                        }
                                    } else {
                                        valueF = this.y;
                                        if (this.series.userOptions.units) {
                                            unit = this.series.userOptions.units;
                                        }
                                        if (this.series.userOptions.data.find((item) => item[0] === this.x) && this.series.userOptions.data.find((item) => item[0] === this.x)[2].values) {
                                            unit = this.series.userOptions.data.find((item) => item[0] === this.x)[2].values;
                                        }
                                        if (unit) {
                                            return `<span>${name} = ${valueF} ${unit},  <br/> ${dateStr}</span>`;
                                        }
                                        return `<span>${name} = ${valueF}, ${dateStr}</span>`;
                                    }
                                    break;
                                case ChartModeEnum.hole:
                                    return `<span>${name}, ${dateStr}</span>`;
                                default:
                                    valueF = this.y;
                                    if (this.series.userOptions.units) {
                                        unit = this.series.userOptions.units;
                                    }
                                    if (this.series.userOptions.data.find((item) => item[0] === this.x) && this.series.userOptions.data.find((item) => item[0] === this.x)[2].values) {
                                        // unit = this.series.userOptions.data.find(item => item[0] === this.x)[2].values;
                                        unit = this.series.yAxis.userOptions.units;
                                    }

                                    if (unit) {
                                        return `<span style="display: flex; flex-direction: column; row-gap: 7px">
  <span>${name} = ${valueF} ${unit}</span>  <span> ${dateStr} </span></span>`;
                                    }
                                    return `<span>${name} = ${valueF}, ${dateStr}</span>`;
                            }
                            // return `<span>${name} = ${value}, ${moment(this.x).format('HH:mm, MMM DD, YY')}</span>`;
                        },
                    },
                    credits: {
                        enabled: false,
                    },
                    title: {
                        text: '',
                    },

                    xAxis: isDetailDate
                        ? {
                              type: 'datetime',
                              lineColor: 'var(--chartLineColor)',
                              labels: {
                                  style: {
                                      color: 'var(--chartLineColor)',
                                  },
                                  formatter: function () {
                                      return `<span>${dataTypeService.reformatTime(this.pos, report)}</span> <br>
                                        <span>${dataTypeService.reformatShortDate(this.pos)}</span> <br>
                                        <span>${dataTypeService.reformateGmt(this.pos, timeZone)}</span>`;
                                  },
                              },
                              minPadding: 0,
                          }
                        : {
                              type: 'datetime',
                              lineColor: 'var(--chartLineColor)',
                              labels: {
                                  style: {
                                      color: 'var(--chartLineColor)',
                                  },
                              },
                              minPadding: 0,
                              // left: 105
                          },
                    yAxis: (() => {
                        return this.performYAxis(axis, false, typeChart, variables, activeGroup);
                    })(),
                    legend: {
                        width: variables.length > 5 ? this.getChartWidth() * 0.7 : 0, // this.getChartWidth() - 100 ?? 0,
                        // x: 10,
                        // itemWidth: 150,
                        align: 'center',
                        itemStyle: {
                            color: 'var(--baseFontColor)',
                        },
                    },
                    series: (() => {
                        return this.performSeries(variables, 'detail', axis, activeGroup, typeChart);
                    })(),
                },
            };

            resolve(settings);
        });
    }

    // private getStartOnTick(): boolean {
    //     if (!this.store.selectSnapshot(ArchiveState.getArchive)?.archive?.length) {
    //         return false;
    //     }
    //
    //     const firstTick = new Date(+this.store.selectSnapshot(ArchiveState.getArchive)?.archive[0]?.ts).getMinutes();
    //
    //     return firstTick !== 0;
    // }
    //
    // private getEndOnTick(): boolean {
    //     if (!this.store.selectSnapshot(ArchiveState.getArchive)?.archive?.length) {
    //         return false;
    //     }
    //
    //     const lastTick = new Date(
    //         +this.store.selectSnapshot(ArchiveState.getArchive)?.archive[
    //             this.store.selectSnapshot(ArchiveState.getArchive)?.archive.length - 1
    //         ]?.ts,
    //     ).getMinutes();
    //
    //     return lastTick < 59 && lastTick !== 0;
    // }

    private getValueNames(value: string): string {
        switch (value) {
            case 'e14':
            case 'e1':
            case '0': {
                return 'Off';
            }
            case 'e13':
            case '1': {
                return 'On';
            }
            case '-1': {
                return 'Na';
            }
        }
    }

    private performYAxis(graphAxis, master: boolean, mode: ChartModeEnum, variables: VariableWithArchive[], group: Group): any {
        const axis = [];
        graphAxis.forEach((item: Axis) => {
            const newItem: any = {};

            let vgsByAxisId;

            if (group) {
                vgsByAxisId = group.variableGroupSettings.filter((s) => s.axisId === item.id);
            } else {
                vgsByAxisId = this.reportService.getVariableGroupSettingsFroChart().filter((s) => s.axisId === item.id);
            }

            const uniqUnits: string[] = [];
            vgsByAxisId.forEach((vgs) => {
                const currentVariable = variables.find((v) => (v.originalVariableId ?? v.id) === vgs.variableId);
                if (currentVariable && currentVariable.unit && currentVariable.unit.length) {
                    uniqUnits.push(currentVariable.unit);
                }
            });

            const units: string = Array.from(new Set(uniqUnits)).join(',');

            const title = item.name + ` ${units}`;

            newItem.title = {
                text: title,
                // text: '',
                style: {
                    color: item.color,
                },
            };

            newItem.units = units;

            if (mode === ChartModeEnum.hole) {
                newItem.min = 0;
                newItem.max = variables.length * 7;
                newItem.tickAmount = variables.length;
            }

            if (item.color) {
                newItem.lineColor = item.color;
                newItem.lineWidth = 1;

                const color = { color: item.color };
                newItem.labels ? (newItem.labels.style = color) : (newItem.labels = { style: color });
            }

            newItem.id = item.id;
            newItem.className = 'y-axis-' + item.id + '  y-axis-current';

            if (master) {
                newItem.visible = false;
            }

            axis.push(newItem);
        });
        // axis[0].title.style = {transform: 'translateX(100px)'};
        // axis[0].labels = {style: {transform: 'translateX(100px)'}};
        return axis;
    }

    private emitSelectionEvent(event: RangeBoxEventInterface): void {
        console.log(event);
        if (this.selectionEventBlock) {
            return;
        }

        this.selectionEventBlock = true;
        this.graphEvents.emit({ type: ChartEventsEnum.selection, event });

        setTimeout(() => {
            this.selectionEventBlock = false;
        }, 100);
    }

    private performDsSeries(variables: any[], dsVariable: Variable[]): any {
        const seriesData = [];
        const series = [];
        const time = this.store.selectSnapshot(TimeFilterState.getTimeObj);

        variables.forEach((item) => {
            item.forEach((v) => {
                seriesData.push(v);
            });
        });

        dsVariable.forEach((variable) => {
            const currentData = seriesData.filter((f) => f.variableId === variable.id);
            const currentItem = [];
            if (!currentData.length) {
                currentItem.push({
                    x: Date.now() - time.time,
                    x2: Date.now(),
                    visible: false,
                    isEmpty: true,
                    y: +variable.name.slice(-1) - 1,
                });
            }

            currentData.forEach((item, index) => {
                currentItem.push({
                    x: +item.x,
                    x2: +item.x2,
                    y: +variable.name.slice(-1) - 1,
                    index,
                    value: item.value,
                    color: this.getDataColor(item),
                });
            });

            series.push({
                name: variable.customName ?? variable.name,
                borderColor: '',
                pointWidth: 15,
                borderRadius: 0,
                variableId: variable.id,
                dataLabels: {
                    enabled: false,
                },
                data: currentItem,
            });
        });

        console.log(series);
        return series;
    }

    getDataColor(item): string {
        switch (item.value) {
            case '0':
            case 'e1':
            case 'e14': {
                return 'transparent';
            }
            case '1':
            case 'e13': {
                return '#3DB557';
            }
            case '-1': {
                return '#000';
            }
        }
    }

    private performSeries(variables: VariableWithArchive[], chartType: 'master' | 'detail', axis: Axis[], activeGroup: Group, typeChart?): any {
        let series = [];
        variables.forEach((variable, index) => {
            if (!variable) {
                return;
            }

            const currentVariableGroupSetting = activeGroup?.variableGroupSettings?.find((f) => f.variableId === variable.id);
            const variableAxis = axis.find((x) => x.id === variable.axis);

            const currentData = [];
            variable.data.forEach((item) => {
                const obj: DataSeries = {
                    message: item.message,
                    values: item.values,
                    alarmType: item.alarmType,
                    eventType: item.eventType,
                };

                const arr: [number, number, DataSeries] = [item.ts, item.value, obj];

                if (variable.alarmType === AlarmTypeEnum.alarm || variable.alarmType === AlarmTypeEnum.attention) {
                    arr[1] = item.message ? item.value || 0 : null;
                }

                currentData.push(arr);
            });

            // console.log(variable);

            currentData.sort((a, b) => +a.ts - +b.ts);
            if (variable.axis && variableAxis) {
                const newItem: any = {};

                newItem.yAxis = variable.axis;
                newItem.marker = {};
                newItem.name = variable.name;
                newItem.customId = index;

                if (+variable.width) {
                    newItem.lineWidth = +variable.width;
                    newItem.marker.radius = +variable.width + 2;
                }

                if (variable.line) {
                    newItem.dashStyle = variable.line;
                }

                if (variable.color) {
                    newItem.color = variable.color || '#000000';
                }

                if (variable?.isLimitVariable) {
                    newItem.isLimitVariable = variable?.isLimitVariable ?? false;
                }

                if (chartType === 'master' && variable.limitValue !== undefined) {
                    newItem.color = 'transparent';
                }

                switch (typeChart) {
                    case ChartModeEnum.alarm:
                        newItem.marker.enabled = true;
                        if (variable.isErrorMode && variable.eventType === EventTypeEnum.status) {
                            newItem.marker.symbol = ChartAlarmIcon.statusIcon;
                        }

                        if (variable.isErrorMode && variable.eventType === EventTypeEnum.logic) {
                            newItem.marker.symbol = variable.isErrorMode
                                ? variable.alarmType === AlarmTypeEnum.attention
                                    ? this.currentTheme === ThemeEnum.light
                                        ? ChartAlarmIcon.logicAttentionLight
                                        : ChartAlarmIcon.logicAttentionDark
                                    : this.currentTheme === ThemeEnum.light
                                    ? ChartAlarmIcon.logicAlarmLight
                                    : ChartAlarmIcon.logicAlarmDark
                                : null;
                        }
                        break;
                    case ChartModeEnum.chartAndAlarm:
                        newItem.marker.enabled = variable.data.length && variable.isErrorMode ? variable.isErrorMode : false;

                        if (variable.isErrorMode && variable.eventType === EventTypeEnum.status) {
                            newItem.marker.symbol = ChartAlarmIcon.statusIcon;
                        }

                        if (variable.isErrorMode && variable.eventType === EventTypeEnum.logic) {
                            newItem.marker.symbol = variable.isErrorMode
                                ? variable.alarmType === AlarmTypeEnum.attention
                                    ? this.currentTheme === ThemeEnum.light
                                        ? ChartAlarmIcon.logicAttentionLight
                                        : ChartAlarmIcon.logicAttentionDark
                                    : this.currentTheme === ThemeEnum.light
                                    ? ChartAlarmIcon.logicAlarmLight
                                    : ChartAlarmIcon.logicAlarmDark
                                : null;
                        }

                        break;
                    default:
                        newItem.marker.symbol = 'square';
                        newItem.marker.enabled = false;

                        break;
                }

                if (currentVariableGroupSetting?.lineType === 'Circle') {
                    newItem.marker.symbol = 'circle';
                    newItem.marker.enabled = true;
                    newItem.marker.radius = 1;
                }

                if (variable.limitValue !== undefined) {
                    newItem.limitValue = variable.limitValue; // for top and bottom limit series
                    newItem.limitType = variable.limitType;
                }

                newItem.showInNavigator = true;
                newItem.variableId = variable.id;
                newItem.units = variable.unit;

                if (currentData) {
                    newItem.data = currentData;
                }

                // newItem.data = [{x: currentData.}]

                series.push(newItem);
            }
        });

        series = series.sort((a, b) => (a.name > b.name ? 1 : -1));
        console.log(series);
        return series;
    }

    setChartWidth(width): any {
        this.chartWidth = width;
    }

    public getChartWidth(): number {
        return this.chartWidth;
    }

    setHeight(height: number): void {
        this.chartHeight = height;
    }

    private getHeight(): number {
        return this.chartHeight;
    }

    setReportForChart(report: ReportSettingsInterface): void {
        this.currentReport = report;
    }

    private getCurrentReportForChart(): ReportSettingsInterface {
        return this.currentReport;
    }

    getPreIcons(axis: Axis): IconInterface<AxisRowIconActionEnum>[] {
        if (!axis) {
            return [];
        }

        return [
            {
                path: './assets/design/icons/table-operations/copy.svg',
                action: AxisRowIconActionEnum.copyBuffer,
                cellNames: ['id'],
            },
        ];
    }

    getIsDisabledCheckboxForLimitAxis(variable: Variable, logicEvents: LogicEvent[]): boolean {
        return !!!logicEvents.filter((e) => e.resultVariableId === variable.id).find((event) => event.logicEventType !== LogicEventType.default);
    }
}

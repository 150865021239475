import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CheckboxPropertiesInterface, ColumnModeEnum, ColumnsTableInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { ConfigurationVariableSavedEnum } from 'src/app/app-shared-elements/_interfaces/Variable';

@Component({
    selector: 'app-column-input',
    templateUrl: './column-input.component.html',
    styleUrls: ['./column-input.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnInputComponent implements OnInit, OnDestroy {
    private _selectedRows: any;
    // private _value: any;

    emitTimeout = null;

    isErrorValidation: boolean;

    @Input() type: ColumnModeEnum;
    @Input() value: any;
    @Input() cell: ColumnsTableInterface;
    @Input() row: { [property: string]: any; checkboxProperties: CheckboxPropertiesInterface[] };
    @Input() isTh: any = false;
    @Input() checkboxProperties: CheckboxPropertiesInterface;

    // @Input()
    // set value(value) {
    //     if (this.row && this.row.id === "df414460-59f5-4d12-854a-3e6bcd031c43") {
    //         console.log(value);
    //     }
    //     this._value = value;
    // }

    // get value(): any {
    //     return this._value;
    // }

    @Input()
    set selectedRows(selectedRows: any) {
        if (!this.isTh) {
            return;
        }
        this._selectedRows = selectedRows;
        this.checkEmptySelectedRows(selectedRows);
    }

    get selectedRows(): any {
        return this._selectedRows;
    }

    configurationVariableSavedEnum = ConfigurationVariableSavedEnum;
    columnModeEnum = ColumnModeEnum;

    @Output() editEvent: EventEmitter<boolean | string | number> = new EventEmitter<boolean | string | number>();
    @Output() editCheckboxEvent = new EventEmitter<boolean>();
    // @Output() validateEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit(): void {}

    checkEmptySelectedRows(selectedRows): void {
        let check = true;
        Object.keys(selectedRows).map((id) => {
            if (!selectedRows[id].checked) {
                check = false;
            }
        });

        if (!Object.keys(selectedRows).length) {
            check = false;
        }

        this.value = check;
    }

    ngOnDestroy(): void {
        clearTimeout(this.emitTimeout);
    }

    onChange(data: boolean | string | number): void {
        if (this.cell.mode === ColumnModeEnum.checkbox) {
            this.value = !this.value;
            this.editEvent.emit(this.value);
        } else if (this.cell.mode === ColumnModeEnum.number) {
            if (+data < 1 || typeof data !== ColumnModeEnum.number) {
                data = 1;
            }
            clearTimeout(this.emitTimeout);
            this.emitTimeout = setTimeout(() => {
                this.editEvent.emit(data);
            }, 500);
        } else {
            this.editEvent.emit(data);
        }
    }

    checkNumberValue(): void {
        if (this.value < 1) {
            this.value = 1;
            return;
        }
    }

    validateEventChange(isError: boolean): void {
        if (!this.cell.propertyValidationTypeForInput) {
            return;
        }

        this.isErrorValidation = isError;
        // this.validateEvent.emit(isError);
    }

    changeCheckbox(event: boolean): void {
        this.editCheckboxEvent.emit(event);
    }
}

import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';
import {LangEnum} from '../_enums/lang.enum';


@Pipe({
    name: 'luxonDate',
})
@Injectable({
    providedIn: 'root'
  })
export class LuxonDatePipe implements PipeTransform {


    constructor(
    ) {
    }

    transform(value: string | number | Date, format: string, timezone: string, ln: LangEnum): string {
        if (!value) {
            return '';
        }

        const currentLang: LangEnum | string = ln && ln.toLowerCase() === LangEnum.en ? LangEnum.en : 'uk';
        let date;
        if (isNaN(value as number)) {
            date = DateTime.fromISO(value as string).setZone(timezone).setLocale(currentLang);
        } else if (value instanceof Date && !isNaN(date.valueOf())) {
            date = DateTime.fromMillis(new Date(value).getTime()).setZone(timezone).setLocale(currentLang);

        } else {
            date = DateTime.fromMillis(+value as number).setZone(timezone).setLocale(currentLang);
        }
        return date.toFormat(format);
    }

}

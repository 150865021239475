import {CreateDeviceUnitDto, UnitRowInterface, UpdateDeviceUnitDto} from '../../_interfaces/units.intefaces';

export class GetUnits {
    static readonly type = '[Units] GetUnits';

}

export class UpdateUnit {
    static readonly type = '[Units] UpdateUnit';
    constructor(public data: UpdateDeviceUnitDto) {}

}

export class CreateUnit {
    static readonly type = '[Units] CreateUnit';
    constructor(public data: CreateDeviceUnitDto) {}
}

export class AddNewUnit {
    static readonly type = '[Units] AddNewUnit';
}

export class CloseEditingUnitRow {
    static readonly type = '[Units] CloseEditingUnitRow';
    constructor(public id: string, public savedRow?: UnitRowInterface) {}
}

export class SetEditingUnitRow {
    static readonly type = '[Units] SetEditingUnitRow';
    constructor(public id: string) {}
}

export class DeleteUnit {
    static readonly type = '[Units] DeleteUnit';
    constructor(public id: string) {}
}



export enum TypeClient{
    User = 'user',
    Admin = 'admin'
}

export enum TypeClientServerEnum {
    client = 'client',
    server = 'server'
}

import {ColumnButtonItem} from 'src/app/app-shared-elements/tables/_interfaces/column-button-item.interface';
import {IconInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import {StatusNotificationShare} from '../_enums/status-notification-share.enum';

export interface SenderUserInterface {
    id: string;
    email: string;
    senderUserId: string;
    name: string;
    status: StatusNotificationShare;
    isActive: boolean;
    created: Date;
}

export interface SenderUserRowInterface extends SenderUserInterface {
    preIcons: IconInterface<void>[];
    postIcons: IconInterface<void>[];
    btns: ColumnButtonItem<SenderColumnButtonActionEnum>[];
}

export enum SenderColumnButtonActionEnum {
    reject = 'reject',
    accept = 'accept'
}

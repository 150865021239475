import { VariableValueTypeEnum } from '../../groups/_enums/variable-value-type.enum';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { RestrictionValidateService } from '../_services/restriction-validate.service';

@Directive({
    selector: '[appInputValidate]',
})
export class InputValidateDirective {
    @Input('appInputValidate') type: VariableValueTypeEnum | any;

    oldValue = '';

    constructor(
        private _el: ElementRef,
        private router: Router,
        private restrictionValidateService: RestrictionValidateService,
    ) {
        this.oldValue = this._el.nativeElement.value;
    }

    @HostListener('input', ['$event']) onInputChange(event): any {
        if (!this.type) {
            return;
        }

        const initialValue = this._el.nativeElement.value;

        if (this.type?.type?.includes('_')) {
            this.floatRestriction(this.type, initialValue);
            return;
        }

        if (this.type.restriction) {
            this.restrictionValidate(this.type.restriction, initialValue);
            return;
        }

        switch (this.type) {
            case VariableValueTypeEnum.int:
                this.intValidate(initialValue);
                break;

            case VariableValueTypeEnum.negativeInt:
                this.negativeInt(initialValue);
                break;
            case VariableValueTypeEnum.float:
                this.floatValidate(initialValue);
                break;
            case VariableValueTypeEnum.password:
                this.passwordValidate(initialValue);
                break;
            case VariableValueTypeEnum.numbers:
                this.numbersOnlyValidate(initialValue);
                break;
            case VariableValueTypeEnum.delay:
                this.delayValidate(initialValue);
                break;
            case VariableValueTypeEnum.numbersAndDot:
                this.numbersAndDotValidate(initialValue);
                break;
            case VariableValueTypeEnum.email:
                this.emailValidate(initialValue);
                break;
        }

        if (initialValue !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
    }

    numbersOnlyValidate(initialValue: string): void {
        this._el.nativeElement.value = initialValue.replace(/[^0-9]/g, '');
    }

    delayValidate(initialValue: string): void {
        this._el.nativeElement.value = initialValue.replace(/[^0-9]/g, '');
    }

    numbersAndDotValidate(initialValue: string): void {
        // const reg = RegExp(/^-?\d+(\.\d+)?$/g);
        const reg = RegExp(/^-?\d*[.,]?\d?$/);
        initialValue = initialValue.replace(',', '.');
        if (!reg.test(initialValue)) {
            this._el.nativeElement.value = this.oldValue;
            return;
        }
        this.oldValue = initialValue;
        this._el.nativeElement.value = initialValue;
    }

    intValidate(initialValue: string): void {
        this._el.nativeElement.value = initialValue.replace(/[^\d]/g, '');
    }

    negativeInt(initialValue: string): void {
        this._el.nativeElement.value = +initialValue >= 0 ? '' : initialValue.replace(/[^-?\d]/g, '');
    }

    floatValidate(initialValue: string): void {
        this._el.nativeElement.value = initialValue.replace(/[^.\d]/g, '');
    }

    passwordValidate(initialValue: string): void {
        this._el.nativeElement.value = initialValue.replace(/[Яа-яЁё\s]/, '');
    }

    emailValidate(initialValue: string): void {
        if (this.router.url.includes('control')) {
            return;
        }
        if (!initialValue || !initialValue.length) {
            this._el.nativeElement.style.borderBottom = '1px solid red';
        }
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        this._el.nativeElement.style.borderBottom = !!initialValue.match(reg) ? '' : '1px solid red';
    }

    restrictionValidate(restriction: string, initialValue: string): void {
        const [min, max] = restriction.split(':').map(parseFloat);
        const maxLength = min.toString().length > max.toString().length ? min.toString().length : max.toString().length;

        if (!min.toString().includes('-') && !max.toString().includes('-')) {
            initialValue = initialValue.replace('-', '');
        }

        if (!this.restrictionValidateService.validateValue(initialValue, restriction) && initialValue.length >= maxLength) {
            this._el.nativeElement.value = this.oldValue;
            return;
        }
        this.oldValue = initialValue;
        this._el.nativeElement.value = initialValue;
    }

    floatRestriction(data: { type: string; restriction: string }, initialValue: string): void {
        initialValue = initialValue.replace(',', '.');
        if (!this.restrictionValidateService.floatSignValidation(data.type, initialValue) && initialValue.length > 1) {
            this._el.nativeElement.value = this.oldValue;
            return;
        }

        const [min, max] = data.restriction.split(':').map(parseFloat);
        if (!this.restrictionValidateService.validateValue(initialValue, data.restriction) && (initialValue.length >= min.toString().length || initialValue.length >= max.toString().length)) {
            this._el.nativeElement.value = this.oldValue;
            return;
        }

        this.oldValue = initialValue;
        this._el.nativeElement.value = initialValue;
    }
}

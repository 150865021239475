export enum SidebarIconListEnum {
    chart = './assets/design/icons/sidebar/chart.svg',
    tableGroups = './assets/design/icons/sidebar/table-groups.svg',
    controlGroups = './assets/design/icons/sidebar/control-groups.svg',
    detailsDevice = './assets/design/icons/sidebar/details-device.svg',
    deviceMap = './assets/design/icons/sidebar/map.svg',
    expeditions = './assets/design/icons/sidebar/expeditions.svg',
    train = './assets/design/icons/sidebar/train.svg',
    companyStaff = './assets/design/icons/sidebar/company-staff.svg',
}

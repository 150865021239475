import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { Group } from 'src/app/app-shared-elements/_interfaces/Group';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { CloneDeepService } from 'src/app/app-shared-elements/_services/clone-deep.service';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import {
    ChangeMapSettings,
    ClearGroupState,
    GetAllGroups,
    GetGroupById,
    SetActiveGroup,
    SetEditMode,
    SetIsLoadGroup,
    UpdateGroupDashboardFold,
    UpdateGroupDashboardPosition,
    UpdateGroupFold,
    UpdateGroupName,
    UpdateGroupPosition,
    UpdatePositionGroup,
    UpdateVariableGroupSettingFromSocket,
    UpdateVariableGroupSettings,
} from '../actions/groups.actions';
import { GroupService } from '../../container/chart/_services/group.service';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import {
    ColumnIconType,
    ColumnModeEnum,
    ColumnsTableInterface,
    ColumnTypeEnum,
} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { GroupsDashboardService } from '../../_services/groups-dashboard.service';
import { TableNamesEnum } from 'src/app/app-shared-elements/_enums/table-names.enum';
import { VariableGroupSettings } from 'src/app/app-shared-elements/_interfaces/VariableGroupSettings';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserState } from '../../../app-shared-elements/_store/states/user.state';
import { TranslateService } from '@ngx-translate/core';
import { MethodPermission, ResourceAction } from '../../../app-shared-elements/_enums/permission.enum';
import { PermissionService } from '../../../app-shared-elements/_services/permission.service';
import { Router } from '@angular/router';
import { SetIsSidebar } from 'src/app/app-shared-elements/_store/actions/layout.actions';
import { DeviceTypeEnum } from 'src/app/app-shared-elements/_enums/device-type.enum';
import { AuthState } from '../../../auth/_store/states/auth.state';

export interface GroupsStateModel {
    groups: Group[];
    activeGroup: Group;
    // generalSettingsRows: GroupEditGeneralSettingsRowInterface[];
    isGroupsLoaded: boolean;
    // groupEditColumns: ColumnsTableInterface[];
    // virtualGroupEditColumns: ColumnsTableInterface[];
    editMode: boolean;
}

const GROUPS_TOKEN = new StateToken<GroupsStateModel>('groupsState');

const initialGroupEditTableColumns: ColumnsTableInterface[] = [
    // {
    //     title: 'table.groupEdit.id',
    //     grow: false,
    //     small: false,
    //     maxWidth: '80px',
    //     type: 'text',
    //     name: 'id',
    // },
    {
        title: 'table.groupEdit.name',
        grow: true,
        small: false,
        type: ColumnTypeEnum.text,
        name: 'name',
        isEditable: true,
        isClick: true,
        tooltip: true,
        tableName: TableNamesEnum.projectSettingsTable,
        // widthAuto: true
    },
    {
        title: 'table.groupEdit.group',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'group',
        maxWidth: '90px',
        minWidth: '80px',
    },
    {
        title: 'table.groupEdit.chart',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'chart',
        maxWidth: '90px',
        minWidth: '80px',
    },
    {
        title: 'table.groupEdit.table',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'table',
        maxWidth: '90px',
        minWidth: '80px',
    },
    {
        title: 'table.groupEdit.control',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'control',
        maxWidth: '95px',
        minWidth: '80px',
    },
    {
        title: 'table.groupEdit.archive',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'archive',
        maxWidth: '95px',
        minWidth: '80px',
        icon: ColumnIconType.tooltip,
        tooltip: true,
        tooltipValue: 'table.groupEdit.archiveTooltip',
        isDisable: true,
    },
];

const initialVirtualGroupEditTableColumns: ColumnsTableInterface[] = [
    {
        title: 'table.groupEdit.name',
        grow: true,
        small: false,
        type: ColumnTypeEnum.text,
        name: 'name',
        isClick: true,
        tooltip: true,
        tableName: TableNamesEnum.projectSettingsTable,
        // widthAuto: true
    },
    {
        title: 'table.groupEdit.group',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'group',
        maxWidth: '90px',
        minWidth: '80px',
    },
    {
        title: 'table.groupEdit.chart',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'chart',
        maxWidth: '90px',
        minWidth: '80px',
        isDisable: true,
    },
    {
        title: 'table.groupEdit.table',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'table',
        maxWidth: '90px',
        minWidth: '80px',
        isDisable: true,
    },
    {
        title: 'table.groupEdit.archive',
        grow: false,
        small: false,
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'archive',
        maxWidth: '95px',
        minWidth: '80px',
        icon: ColumnIconType.tooltip,
        tooltip: true,
        tooltipValue: 'table.groupEdit.archiveTooltip',
        isDisable: true,
    },
];

@State<GroupsStateModel>({
    name: GROUPS_TOKEN,
    defaults: {
        groups: [],
        activeGroup: null,
        // generalSettingsRows: [],
        isGroupsLoaded: false,
        // groupEditColumns: initialGroupEditTableColumns,
        editMode: false,
        // virtualGroupEditColumns: initialVirtualGroupEditTableColumns
    },
})
@Injectable()
export class GroupsState {
    constructor(
        private cloneDeep: CloneDeepService,
        private notificationsService: NotificationsService,
        private translateService: TranslateService,
        public store: Store,
        private groupService: GroupService,
        private groupDashboardService: GroupsDashboardService,
        private http: HttpClient,
        private permissionService: PermissionService,
        private router: Router,
    ) {}

    @Selector()
    static getGroups(state: GroupsStateModel): Group[] {
        return state.groups.sort((a, b) => a.position - b.position);
    }

    @Selector()
    static getActiveGroup(state: GroupsStateModel): Group {
        return state.activeGroup;
    }

    @Selector([DevicesState.getRegistrators])
    static getAllRegistratorsGroups(state: GroupsStateModel, registrators: Device[]): Group[] {
        return state.groups.filter((group) => registrators.find((r) => r.id === group.deviceId));
    }

    @Selector()
    static getIsLoadedGroup(state: GroupsStateModel): boolean {
        return state.isGroupsLoaded;
    }

    // @Selector()
    // static getGroupEditColumns(state: GroupsStateModel): ColumnsTableInterface[] {
    //     return state.groupEditColumns;
    // }

    // @Selector()
    // static getVirtualGroupEditColumns(state: GroupsStateModel): ColumnsTableInterface[] {
    //     return state.virtualGroupEditColumns;
    // }

    @Selector()
    static getEditMode(state: GroupsStateModel): boolean {
        return state.editMode;
    }

    @Action(GetAllGroups)
    async getAllGroups(ctx: StateContext<GroupsStateModel>, payload: GetAllGroups): Promise<void> {
        const currentParams = this.router.parseUrl(this.router.url).root.children.primary.segments;
        const currentParamsUrl = currentParams.length > 1 && currentParams[1].path ? currentParams[1].path : null;

        const params = new HttpParams().set('registrator', JSON.stringify(payload.registratorIds));

        const currentUserId = this.store.selectSnapshot(UserState.getAdminUserId);

        let headers;

        if (currentUserId) {
            headers = new HttpHeaders({
                registratorId: this.permissionService
                    .getAllowedRegistratorIds(payload.registratorIds, ResourceAction.GROUPS, [MethodPermission.READ])
                    .join(','),
            }).set('currentUserId', currentUserId);
        } else {
            headers = new HttpHeaders({
                registratorId: this.permissionService
                    .getAllowedRegistratorIds(payload.registratorIds, ResourceAction.GROUPS, [MethodPermission.READ])
                    .join(','),
            });
        }

        const result: ApiResponse = (await this.http.get('/api/groups', { params, headers }).toPromise()) as ApiResponse;
        const user = this.store.selectSnapshot(UserState.getUser);
        const devices = this.store.selectSnapshot(DevicesState.getDevices);

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            if (!this.store.selectSnapshot(AuthState.getIsAdmin) && this.router.url.includes('groups-dashboard')) {
                let indexGroup = 1;
                result.data.forEach((item: Group) => {
                    const currentDevice = devices.find((d) => d.id === item.deviceId);
                    item.position = this.groupDashboardService.getPosition(item, user, currentDevice) || indexGroup;
                    indexGroup++;
                });

                result.data.forEach((item) => {
                    item.isFold = this.groupDashboardService.getIsFold(item, user) || false;
                });
            } else {
                result.data.forEach((item) => {
                    item.position = this.groupService.getPosition(item, user) || null;
                    item.column = this.groupService.getColumn(item, user) || 1;
                });

                result.data.forEach((item) => {
                    item.isFold = this.groupService.getIsFold(item, user) || false;
                });
            }

            ctx.setState({
                ...ctx.getState(),
                groups: result.data,
                isGroupsLoaded: true,
            });
        } else if (result && result.status === HTTP_STATUS.ACCESS_DENIED) {
            ctx.dispatch(new SetIsSidebar(false));
            const message = await this.translateService.get('groups.accessDenied').toPromise();
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, message);
        } else {
            ctx.setState({
                ...ctx.getState(),
            });
        }
    }

    @Action(UpdateGroupPosition)
    updateGroupPosition(ctx: StateContext<GroupsStateModel>, payload: UpdateGroupPosition): void {
        const state = ctx.getState();
        const user = this.store.selectSnapshot(UserState.getUser);

        ctx.setState({
            ...state,
            groups: state.groups.map((group) => {
                const currentGroup = payload.position.find((f) => f.id === group.id);
                if (currentGroup) {
                    group.position = currentGroup.position;
                    group.column = currentGroup.column ? currentGroup.column : null;
                }
                return group;
            }),
        });

        localStorage.setItem(`groupPosition${user.id}`, JSON.stringify(payload.position));
    }

    @Action(UpdateGroupDashboardPosition)
    updateGroupDashboardPosition(ctx: StateContext<GroupsStateModel>, payload: UpdateGroupDashboardPosition): void {
        const state = ctx.getState();
        const user = this.store.selectSnapshot(UserState.getUser);

        ctx.setState({
            ...state,
            groups: state.groups.map((group) => {
                const currentGroup = payload.position.find((f) => f.id === group.id);
                if (currentGroup) {
                    group.position = currentGroup.position;
                    group.column = currentGroup.column ? currentGroup.column : null;
                }
                return group;
            }),
        });

        switch (payload.currentFilterType) {
            case DeviceTypeEnum.registrator:
                localStorage.setItem(`groupsRegistratorPosition${user.id}`, JSON.stringify(payload.position));
                return;
            case DeviceTypeEnum.sensor:
                localStorage.setItem(`groupsDevicePosition${user.id}`, JSON.stringify(payload.position));
                return;
            case DeviceTypeEnum.coordinator:
                localStorage.setItem(`groupsCoordinatorPosition${user.id}`, JSON.stringify(payload.position));
                return;
            case DeviceTypeEnum.datalogger:
                localStorage.setItem(`groupsDataloggerPosition${user.id}`, JSON.stringify(payload.position));
                return;
        }
    }

    @Action(UpdateGroupFold)
    updateGroupFold(ctx: StateContext<GroupsStateModel>, payload: UpdateGroupFold): void {
        const state = ctx.getState();
        const user = this.store.selectSnapshot(UserState.getUser);

        ctx.setState({
            ...state,
            groups: state.groups.map((group) => {
                const currentGroup = payload.fold.find((f) => f.id === group.id);
                if (currentGroup) {
                    group.isFold = currentGroup.isFold;
                }
                return group;
            }),
        });

        localStorage.setItem(`groupsFold${user.id}`, JSON.stringify(payload.fold));
    }

    @Action(UpdateGroupDashboardFold)
    updateGroupDashboardFold(ctx: StateContext<GroupsStateModel>, payload: UpdateGroupDashboardFold): void {
        const state = ctx.getState();
        const user = this.store.selectSnapshot(UserState.getUser);

        ctx.setState({
            ...state,
            groups: state.groups.map((group) => {
                const currentGroup = payload.fold.find((f) => f.id === group.id);
                if (currentGroup) {
                    group.isFold = currentGroup.isFold;
                }
                return group;
            }),
        });

        localStorage.setItem(`groupsDashboardFold${user.id}`, JSON.stringify(payload.fold));
    }

    @Action(SetActiveGroup)
    setActiveGroup(ctx: StateContext<GroupsStateModel>, payload: SetActiveGroup): void {
        const state = ctx.getState();
        const user = this.store.selectSnapshot(UserState.getUser);

        if (this.router.url.includes('group-container')) {
            state.groups.forEach((item) => {
                item.isFold = this.groupService.getIsFold(item, user) || false;
            });
        }

        ctx.setState({
            ...state,
            activeGroup: state.groups.find((group) => group.id === payload.groupId) || state.activeGroup,
        });
    }

    @Action(GetGroupById)
    async getGroupById(ctx: StateContext<GroupsStateModel>, payload: GetGroupById): Promise<void> {
        const state = ctx.getState();
        const registratorsIds: string[] = this.store.selectSnapshot(DevicesState.getRegistrators).map((r) => r.id);
        const user = this.store.selectSnapshot(UserState.getUser);
        const currentUserId = this.store.selectSnapshot(UserState.getAdminUserId);
        const headers = currentUserId
            ? new HttpHeaders({ registratorId: registratorsIds.join(',') }).set('currentUserId', currentUserId)
            : new HttpHeaders({ registratorId: registratorsIds.join(',') });

        const result: ApiResponse = (await this.http
            .get('/api/groups/group-one', {
                params: { id: payload.id },
                headers,
            })
            .toPromise()) as ApiResponse;

        if (!result && result.status !== HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
            });
        }

        if (!this.store.selectSnapshot(AuthState.getIsAdmin)) {
            result.data.position = this.groupService.getPosition(result.data, user) || null;
            result.data.column = this.groupService.getColumn(result.data, user);
        }
        ctx.setState({
            ...state,
            groups: state.groups.map((group) => {
                if (group.id === result.data.id) {
                    group = this.cloneDeep.cloneObject(result.data);
                }

                return group;
            }),
        });
    }

    @Action(UpdatePositionGroup)
    updatePositionGroup(ctx: StateContext<GroupsStateModel>, payload: UpdatePositionGroup): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            groups: state.groups.map((group) => {
                const currentPositionIndex = payload.groupsPosition.findIndex((item) => item.id === group.id);
                if (currentPositionIndex < 0) {
                    return group;
                }
                group.position = payload.groupsPosition[currentPositionIndex].position;
                return group;
            }),
        });
    }

    @Action(ClearGroupState)
    clearGroupState(ctx: StateContext<GroupsStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            groups: [],
            activeGroup: null,
            isGroupsLoaded: false,
        });
    }

    @Action(SetIsLoadGroup)
    setIsLoadGroup(ctx: StateContext<GroupsStateModel>, payload: SetIsLoadGroup): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isGroupsLoaded: payload.isLoad,
        });
    }

    @Action(UpdateGroupName)
    async updateGroupName(ctx: StateContext<GroupsStateModel>, payload: UpdateGroupName): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .post('/api/groups/update-name', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,

                groups: state.groups.map((group) => {
                    if (group.id === result.data.id) {
                        group.name = result.data.name;
                    }

                    return group;
                }),
                activeGroup: {
                    ...state.activeGroup,
                    name: result.data.name,
                },
            });
        }
    }

    @Action(UpdateVariableGroupSettings)
    async updateVariableGroupSettings(ctx: StateContext<GroupsStateModel>, payload: UpdateVariableGroupSettings): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/groups/update-settings', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const devices: Device[] = this.store.selectSnapshot(DevicesState.getDevices);
        const state = ctx.getState();

        const currentDevice: Device = devices.find((device) => device.id === state.activeGroup.deviceId);
        const currentVariable: Variable = currentDevice.variables.find((v) => v.id === payload.data.variableId);
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            if (payload.isChankResponeses) {
                this.groupService.initTimer();
                this.groupService.addSuccessList({
                    ...currentVariable,
                    customName: payload.data.name || currentVariable.name,
                });
            } else {
                this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            }

            ctx.setState({
                ...state,

                groups: state.groups.map((group) => {
                    if (group.id === (result.data as VariableGroupSettings).groupId) {
                        group.variableGroupSettings = group.variableGroupSettings.map((setting) =>
                            setting.id === result.data.id ? result.data : setting,
                        );
                    }
                    return group;
                }),
                activeGroup: {
                    ...state.activeGroup,
                    variableGroupSettings: state.activeGroup.variableGroupSettings.map((setting) =>
                        setting.id === result.data.id ? result.data : setting,
                    ),
                },
            });
        } else {
            this.groupService.addFailList(currentVariable);
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(UpdateVariableGroupSettingFromSocket)
    updateVariableGroupSettingFromSocket(ctx: StateContext<GroupsStateModel>, payload: UpdateVariableGroupSettingFromSocket): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            groups: state.groups.map((group) => {
                if (group.id === payload.variableGroupSetting.groupId) {
                    group.variableGroupSettings = group.variableGroupSettings.map((setting) =>
                        setting.id === payload.variableGroupSetting.id ? { ...payload.variableGroupSetting } : setting,
                    );
                }
                return group;
            }),
            activeGroup: {
                ...state.activeGroup,
                variableGroupSettings: state.activeGroup.variableGroupSettings.map((setting) =>
                    setting.id === payload.variableGroupSetting.id ? { ...payload.variableGroupSetting } : setting,
                ),
            },
        });
    }

    @Action(SetEditMode)
    setEditMode(ctx: StateContext<GroupsStateModel>, payload: SetEditMode): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            editMode: payload.value,
        });
    }

    @Action(ChangeMapSettings)
    async changeMapSettings(ctx: StateContext<GroupsStateModel>, payload: ChangeMapSettings): Promise<void> {
        const state = ctx.getState();
        const currentRegistrator: Device = this.store
            .selectSnapshot(DevicesState.getRegistrators)
            .find((r) => state.activeGroup.deviceId === r.id);
        const headers = new HttpHeaders({ registratorId: currentRegistrator.id });
        const result: ApiResponse = (await this.http
            .post(`api/groups/update-map-settings/${state.activeGroup.id}`, { ...payload.mapSettings }, { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                activeGroup: {
                    ...state.activeGroup,
                    mapSettings: result.data,
                },
                groups: state.groups.map((g) =>
                    g.id === state.activeGroup.id
                        ? {
                              ...state.activeGroup,
                              mapSettings: result.data,
                          }
                        : g,
                ),
            });
        }
    }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {NavProfileItemActionEnum} from 'src/app/app-shared-elements/_enums/nav-profile-item-action.enum';
import {NavProfileItemInterface} from 'src/app/app-shared-elements/_interfaces/nav-profile-item.interface';
import {SocketService} from 'src/app/app-shared-elements/_services/socket.service';
import {ThemeService} from 'src/app/theme/theme.service';
import {ThemeEnum} from 'src/app/theme/_enums/theme.enum';
import {Store} from "@ngxs/store";
import {AuthState} from "../../../auth/_store/states/auth.state";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
    selector: 'app-profile-menu',
    templateUrl: './profile-menu.component.html',
    styleUrls: ['./profile-menu.component.scss'],
})
export class ProfileMenuComponent implements OnInit, OnDestroy {

    navProfileList: NavProfileItemInterface[] = [
        {
            title: 'nav.userProfile',
            icon: './assets/design/icons/nav/nav-profile.svg',
            action: NavProfileItemActionEnum.moveProfile,
            isActive: false
        },
        {
            title: 'nav.billing',
            isAdmin: true,
            icon: './assets/design/icons/nav/nav-billing.svg',
            action: NavProfileItemActionEnum.moveBilling,
            isActive: false
        },
        {
            title: 'nav.theme',
            icon: './assets/design/icons/nav/nav-theme.svg',
            action: NavProfileItemActionEnum.changeTheme,
            isActive: false
        },
        {
            title: 'nav.exit',
            icon: './assets/design/icons/nav/nav-logout.svg',
            action: NavProfileItemActionEnum.logout,
            isActive: false
        },
        {
            title: 'nav.blockSession',
            icon: './assets/design/icons/nav/nav-block-session.svg',
            action: NavProfileItemActionEnum.blockSession,
            isActive: false
        }
    ];

    activeTheme: ThemeEnum = localStorage.getItem('theme') as ThemeEnum;


    navProfileItemActionEnum = NavProfileItemActionEnum;
    themeEnum = ThemeEnum;

    destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        public router: Router,
        private socketService: SocketService,
        private themeService: ThemeService,
        private store: Store
    ) {
    }

    ngOnInit(): void {
        if (this.store.selectSnapshot(AuthState.getIsAdmin)) {
            this.navProfileList = this.navProfileList.filter(f => !f.isAdmin);
        }
        this.activeItem();

        this.router.events.pipe(takeUntil(this.destroy)).subscribe(event => {
            if (event instanceof NavigationStart || event instanceof NavigationEnd) {
                this.activeItem();
            }
        });
    }

    parseAction(action: NavProfileItemActionEnum): void {
        // this.actionEvent.emit(action);
        switch (action) {
            case NavProfileItemActionEnum.moveProfile:
                this.moveToProfile();
                break;
            case NavProfileItemActionEnum.moveBilling:
                this.moveToBilling();
                break;
            case NavProfileItemActionEnum.changeTheme:
                this.toggleTheme();
                break;
            case NavProfileItemActionEnum.logout:
                this.logout();
                break;
            case NavProfileItemActionEnum.blockSession:
                this.blockSession();
        }
    }

    private logout(): void {
        this.socketService.logoutSubscribe.emit();
    }

    private blockSession(): void {
        this.socketService.freezeSession();
    }

    private moveToBilling(): void {
        this.router.navigate(['/billing']);
    }

    private moveToProfile(): void {
        this.router.navigate(['/profile']);
    }

    private toggleTheme(): void {
        if (this.activeTheme !== ThemeEnum.light) {
            this.themeService.setActiveTheme(ThemeEnum.light);
            this.activeTheme = ThemeEnum.light;
            localStorage.setItem('theme', ThemeEnum.light);
        } else {
            this.themeService.setActiveTheme(ThemeEnum.dark);
            this.activeTheme = ThemeEnum.dark;
            localStorage.setItem('theme', ThemeEnum.dark);
        }
    }

    private activeItem(): void {
        this.navProfileList = this.navProfileList.map(item => {
            item.isActive = false;

            switch (item.action) {
                case NavProfileItemActionEnum.moveBilling:
                    if (item.action === NavProfileItemActionEnum.moveBilling && this.router.url.includes('/billing')) {
                        item.isActive = true;
                    }
                    break;
                case NavProfileItemActionEnum.moveProfile:
                    if (item.action === NavProfileItemActionEnum.moveProfile && this.router.url.includes('/profile')) {
                        item.isActive = true;
                    }
                    break;
                default:
                    item.isActive = false;
            }

            return item;
        });
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }
}

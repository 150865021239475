import { LangEnum } from 'src/app/app-shared-elements/_enums/lang.enum';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { UserProfileInfoTypeDateEnum } from 'src/app/profile/_enums/user-profile-info-date-time.enum';
import { FormatPrintEnum } from '../_enums/format-print.type.enum';
import { ScheduleTypeEnum } from '../_enums/schedule-type.enum';

export interface ReportSettingsInterface {
    nameReport: string;
    nameCompany?: string;
    logo?: string | null;
    logoName?: string | null;
    logoSize?: number | null;
    deviceId: string;
    isSendTelegram: boolean;
    isSendEmail: boolean;
    telegram: string | null;
    email: string | null;
    date: ReportSettingsDateInterface;
    format: FormatPrintEnum;
    isShowNameReport?: boolean;
    isShowNameCompany?: boolean;
    isShowChart?: boolean;
    isShowSummaryTable?: boolean;
    isShowTable?: boolean;
    isLimits?: boolean;
    isShowUserLogs?: boolean;
    isShowEventLogs?: boolean;
    isShowOnlyAlarm?: boolean;
    isSplitTable?: boolean;
    isUpdateLogo: boolean;
    repeat: ReportRepeatEnum;
    isActive: boolean;
    readonly id?: string;
    userId?: string;
    userName?: string;
    readonly owner?: string;
    language: LangEnum;
    timezone: string;
    dateFormat: UserProfileInfoTypeDateEnum;
    // chartMode: ChartModeEnum;
    // reportVariables: Variable[];
    variablesId: string[];

    created?: string;

    isDefaultReport: boolean; // only for server
}

export interface ReportSettingsDateInterface {
    dateFrom: string | any;
    dateTo: string | any;
    schedule: ScheduleTypeEnum;
}

export interface ReportSettingsResponse {
    start: number;
    end: number;
    groupId: number;
    userId: number;
    token?: string;
    isGroupTable?: boolean;
    isChart?: boolean;
    isUserLogTable?: boolean;
}

export enum ReportRepeatEnum {
    once = 'once',
    regular = 'regular',
    none = 'none',
}

export interface VariableItemCheckboxInterface {
    variable: Variable;
    checked: boolean;
    device: Device;
}

import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {ChangeExportSettings, ClearImportState, GetExportSettings, GetImportSettings, GetSettingsList, SetImportFile} from '../actions/export-import.actions';
import {ApiResponse} from '../../../../app-shared-elements/_interfaces/ApiRequest';
import {HttpClient} from '@angular/common/http';
import {HTTP_STATUS} from '../../../../app-shared-elements/_enums/status.enum';
import {NotificationsService} from '../../../../app-shared-elements/_services/notifications.service';
import {TooltipStatusEnum} from '../../../../app-shared-elements/_enums/tooltip-status.enum';

export interface ExportImportStateModel {
    settingsList: { name: string, checked: boolean }[];
    file: any;
}

const EXPORT_IMPORT_TOKEN = new StateToken<ExportImportStateModel>('exportImportState');

@State({
    name: EXPORT_IMPORT_TOKEN,
    defaults: {
        settingsList: [],
        file: null
    }
})

@Injectable()
export class ExportImportState {
    constructor(private http: HttpClient,
                private notificationService: NotificationsService) {
    }

    @Selector()
    static getImportFile(state: ExportImportStateModel): any {
        return state.file;
    }

    @Selector()
    static getObjectKeysFromFile(state: ExportImportStateModel): string[] {
        return Object.keys(state.file);
    }

    @Selector()
    static getSettingsList(state: ExportImportStateModel): { name: string, checked: boolean }[] {
        return state.settingsList;
    }

    @Action(SetImportFile)
    async setImportFile(ctx: StateContext<ExportImportStateModel>, payload: SetImportFile): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            file: payload.file
        });
    }

    @Action(GetSettingsList)
    async getSettingsList(ctx: StateContext<ExportImportStateModel>): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = await this.http.get('/api/control/export-import/available-export-list').toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                settingsList: result.data.map(r => {
                    return {
                        name: r,
                        checked: false
                    };
                })
            });
        }
    }

    @Action(GetImportSettings)
    async getImport(ctx: StateContext<ExportImportStateModel>, payload: GetImportSettings): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = await this.http.post('/api/control/export-import/import', {...payload.object}).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state
            });
        }
    }

    @Action(GetExportSettings)
    async getExportSettings(ctx: StateContext<ExportImportStateModel>, payload: GetExportSettings): Promise<void> {
        const state = ctx.getState();
        const listExport = payload.list.join(',');

        const result: ApiResponse = await this.http.get('/api/control/export-import/export', {
            params: {
                listExport
            }
        }).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);

            const dataStr = 'data:text/json;charset=utf-8,' + JSON.stringify(result.data);
            const a = document.createElement('a');
            a.href = dataStr;
            a.download = 'settings.json';
            a.click();

            ctx.setState({
                ...state
            });
        }
    }

    @Action(ClearImportState)
    clearImportState(ctx: StateContext<ExportImportStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            file: null
        });
    }

    @Action(ChangeExportSettings)
    changeExportSettings(ctx: StateContext<ExportImportStateModel>, payload: ChangeExportSettings): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            settingsList: state.settingsList.map(item => {

                item.checked = payload.list.includes(item.name);
                return item;
            })
        });
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersComponent} from './users.component';
import {FormsModule} from '@angular/forms';
import {AppSharedElementsModule} from '../../app-shared-elements/app-shared-elements.module';
import {NgxDropzoneModule} from 'ngx-dropzone';


@NgModule({
    declarations: [UsersComponent],
    imports: [
        CommonModule,
        FormsModule,
        AppSharedElementsModule,
        NgxDropzoneModule
    ],
    providers: [
        // UsersService
    ]
})
export class UsersModule {
}

import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Pagination, Params, ParamsSorted, ParamsSortedOrderEnum } from '../../../../app-shared-elements/_interfaces/params.interface';
import { DropdownFilterOptionInterface } from '../../../../app-shared-elements/filters/interfaces/filter-option.interface';
import { TypeFilterEnum } from '../../../../app-shared-elements/filters/enums/type-filter.enum';
import { financeInvoiceDropdown, financeInvoiceFiltersCheckbox } from '../../_data/finance-invoice-data';
import { GetFinanceInvoice, SetFinanceInvoiceParams } from '../actions/finance-invoice.actions';
import { ParamsService } from '../../../../app-shared-elements/_services/params.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../../app-shared-elements/_enums/status.enum';
import { BillingInvoiceInterface, InvoiceAdminInterface } from '../../../../billing/_interfaces/billing-invoice.interface';
import { BillingInvoiceService } from '../../../../billing/_services/billing-invoice.service';
import { ColumnsActionTypeEnum } from '../../../../app-shared-elements/_enums/columns-action-type.enum';
import { CurrencyState } from '../../../../app-shared-elements/_store/states/currency.state';

export interface FinanceInvoiceStateModel {
    invoice: InvoiceAdminInterface[];
    params: Params;
    dropDownFilterOptions: DropdownFilterOptionInterface[];
}

const FINANCE_INVOICE_STATE = new StateToken<FinanceInvoiceStateModel>('financeInvoice');

const initialPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

const initialCurrentSort: ParamsSorted[] = [
    {
        property: 'invoiceNumber',
        order: ParamsSortedOrderEnum.DESC,
    },
];

export const initialParams: Params = {
    filter: [...financeInvoiceFiltersCheckbox, ...financeInvoiceDropdown],
    pagination: initialPagination,
    sorted: initialCurrentSort,
};

const dropDownFilterOptions: DropdownFilterOptionInterface[] = [
    {
        key: 'invoiceNumber',
        value: 'adminFinances.invoice.dropDown.number',
        type: TypeFilterEnum.text,
        property: 'invoiceNumber',
    },
    {
        key: 'debitorName',
        value: 'adminFinances.invoice.dropDown.debtors',
        type: TypeFilterEnum.text,
        property: 'debitorName',
    },
    {
        key: 'dateInvoice',
        value: 'adminFinances.invoice.dropDown.period',
        type: TypeFilterEnum.text,
        property: 'dateInvoice',
    },
];

@State<FinanceInvoiceStateModel>({
    name: FINANCE_INVOICE_STATE,
    defaults: {
        invoice: [],
        params: initialParams,
        dropDownFilterOptions,
    },
})
@Injectable()
export class FinanceInvoiceState {
    constructor(
        private paramsService: ParamsService,
        private http: HttpClient,
        private billingInvoiceService: BillingInvoiceService,
        private store: Store,
    ) {}

    @Selector()
    static getFinanceInvoice(state: FinanceInvoiceStateModel): InvoiceAdminInterface[] {
        return state.invoice;
    }

    @Selector()
    static getDropDownFilterOptions(state: FinanceInvoiceStateModel): DropdownFilterOptionInterface[] {
        return state.dropDownFilterOptions;
    }

    @Selector()
    static getParams(state: FinanceInvoiceStateModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }

    @Action(GetFinanceInvoice)
    async getFinanceInvoice(ctx: StateContext<FinanceInvoiceStateModel>): Promise<void> {
        const state = ctx.getState();

        const params: Params = {
            ...state.params,
            filter: this.paramsService.parseParamsFilterForServer(state.params.filter),
            pagination: {
                itemsPerPage: state.params.pagination.itemsPerPage,
                currentPage: state.params.pagination.currentPage,
            },
            sorted: state.params.sorted && state.params.sorted.length ? state.params.sorted : initialCurrentSort,
        };

        const headers = new HttpHeaders({
            params: encodeURIComponent(JSON.stringify(params)),
        });

        const result: ApiResponse = (await this.http
            .get('/api/control/billing/invoice', { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        // console.log(result);
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            const currency = this.store.selectSnapshot(CurrencyState.getCurrency);

            ctx.setState({
                ...state,
                invoice: result.data.items.map((item: BillingInvoiceInterface) => {
                    return {
                        ...item,
                        totalPayable: item.totalPayable + currency,
                        invoiceNumber: item.invoiceNumber + ' - ' + item.dateInvoice,
                        status: this.billingInvoiceService.getInvoiceStatus(item),
                        rowStyles: this.billingInvoiceService.getInvoiceRowStyle(item),
                        styles: {
                            status: this.billingInvoiceService.getInvoiceStatusColor(item),
                        },
                        disabledActions: +item.totalPayable > 0 ? [] : [ColumnsActionTypeEnum.view, ColumnsActionTypeEnum.download],
                    };
                }),
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.total,
                    },
                },
            });
        }
    }

    @Action(SetFinanceInvoiceParams)
    setFinanceInvoiceParams(ctx: StateContext<FinanceInvoiceStateModel>, payload: SetFinanceInvoiceParams): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: payload.params,
        });

        ctx.dispatch(new GetFinanceInvoice());
    }
}

<div class="arch">
    <div class="arch__wrapper">
        <ul class="arch__list">
            <li *ngFor="let device of devices"
                class="arth__item">{{device.name}} {{device.loadArchiveDevicesName?.length ? '(' + device.loadArchiveDevicesName.join(", ") + ')' : ''}}
                <br><b [translate]="'charts.archiveMissing'"
                       [translateParams]="{archive: device.daysArchivesLeft || 1}"></b></li>
        </ul>
    </div>
</div>

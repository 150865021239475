import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { IconInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { LogicEventType } from '../logical-events/_interface/LogicEvent';
import { EventSaveStatus } from '../_enums/event-save-state.enum';
import { ExpressionInterface } from './Expression';
import { Device } from '../../app-shared-elements/_interfaces/Device';

export interface LogicalEventRowInterface {
    eventAlertMessageId: string;
    expression: ExpressionInterface;
    expressionString: string;
    readonly id: string;
    readonly copyId: string;
    isAcknowledgeable: boolean;
    isActive?: boolean;
    name: string;
    registrator: string;
    registratorId: string;
    resultVariableId: string;
    resultVariableName: string;
    device?: Device;
    savedStatus: EventSaveStatus;
    state: string;
    styles: {
        state: any;
    };
    // type: EventSaveStatus;
    tooltipValue: TooltipValueLogicalEventRowInterface;
    logicEventType: LogicEventType;
    // isMultiEvent?: boolean;
    errorStatus: number;
    created: string; // iso string
    disabledActions: ColumnsActionTypeEnum[];

    // for multi events
    isMainAcordion?: boolean;
    isChildAcordion?: boolean;
    accordionChildIds?: string[];
    limitIsActive?: boolean;
    emptyCellNames?: string[];
    toggle?: boolean;
    accordionChildHide?: boolean;
    lastChildAccordion?: boolean;
    accordionChildRows: LogicalEventRowInterface[];
    preIcons: IconInterface<LogicalEventIconActions>[];
    postIcons: IconInterface<LogicalEventIconActions>[];
}

export interface TooltipValueLogicalEventRowInterface {
    resultVariableName: string;
    expressionString: string;
}

export enum LogicalEventIconActions {
    copyBuffer = 'copyBuffer',
    accordionToggle = 'accordionToggle',
}

// export class SetCurrentProfileUser {
//     static readonly type = '[AdminProfile] SetCurrentProfileUser';
//     constructor(public userId: string) {}
// }

export class InitDescribeList {
    static readonly type = '[AdminProfile] InitDescribeList';
}

export class GetAdminDevicesByUser {
    static readonly type = '[AdminProfile] GetAdminDevicesByUser';

    constructor(public userId: string) {}
}

export class GetUserSession {
    static readonly type = '[AdminProfile] GetUserSession';

    constructor(public userId: string) {}
}

export class GetAdminSession {
    static readonly type = '[AdminProfile] GetUserSession';
    // constructor(public userId: string) {}
}

export class InitAdminProfileDeviceRows {
    static readonly type = '[AdminProfile] InitAdminProfileDeviceRows';

    constructor(public userId: string) {}
}

export class UpdateAdminProfileDeviceRows {
    static readonly type = '[AdminProfile] UpdateAdminProfileDeviceRows';
}

export class InitRegistratorsOptions {
    static readonly type = '[AdminProfile] InitRegistratorsOptions';
}

export class InitRoleInfo {
    static readonly type = '[AdminProfile] InitRoleInfo';

    constructor(public roleId: string) {}
}

export class ToggleRoleInfoPopup {
    static readonly type = '[AdminProfile] ToggleRoleInfoPopup';

    constructor(public toggle: boolean) {}
}

import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appMaxNumberValue]'
})
export class MaxNumberValueDirective {

    @Input('appMaxNumberValue') maxValue: number;

    constructor(private _el: ElementRef) { }



    @HostListener('input', ['$event']) onInputChange(event) {
        if (!this.maxValue) {
            return;
        }

        const initialValue = this._el.nativeElement.value;

        if (+initialValue > 25) {
            this._el.nativeElement.value = 25;
        }

        // if (initialValue !== this._el.nativeElement.value) {
        //     event.stopPropagation();
        // }
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserAuthGuardService} from '../auth/user-auth-guard.service';
import {RouterModule, Routes} from '@angular/router';
import {DeviceLogsComponent} from './device-logs/device-logs.component';
import {UserLogsComponent} from './user-logs/user-logs.component';
import {EventsLogComponent} from './event-logs/events-log.component';
import {MailingLogComponent} from './mailing-logs/mailing-log.component';
import {ReportsLogsComponent} from './report-logs/reports-logs.component';

const routes: Routes = [
    {
        path: 'device-log',
        data: {title: 'Device Log'},
        component: DeviceLogsComponent,
        canActivate: [UserAuthGuardService],
    },
    {
        path: 'user-log',
        data: {title: 'User Log'},
        component: UserLogsComponent,
        canActivate: [UserAuthGuardService],
    },
    {
        path: 'events-log',
        data: {title: 'Events Log'},
        component: EventsLogComponent,
        canActivate: [UserAuthGuardService],
    },
    {
        path: 'mailing-log',
        data: {title: 'Mailing Log'},
        component: MailingLogComponent,
        canActivate: [UserAuthGuardService],
    },
    {
        path: 'report-log',
        data: {title: 'Report Log'},
        component: ReportsLogsComponent,
        canActivate: [UserAuthGuardService],
    },
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forChild(routes)
    ]
})
export class JournalsRoutingModule {
}

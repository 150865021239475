import { Injectable } from '@angular/core';
import { ColumnsTableInterface, ColumnTypeEnum, ColumnValueAlignEnum } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import {
    MailingTransportRowLogInterface,
    NotificationLogInterface,
    NotificationLogType,
    NotificationStatus,
    NotificationTransportType,
} from '../../mailing/_interfaces/notification-log.interface';

@Injectable({
    providedIn: 'root',
})
export class MailingService {
    public messagesTableColumns: ColumnsTableInterface[] = [
        {
            title: 'mailing.messages.table.date',
            grow: false,
            small: false,
            sort: 0,
            maxWidth: '260px',
            minWidth: '160px',
            type: ColumnTypeEnum.date,
            name: 'date',
            sortField: 'created',
        },
        {
            title: 'mailing.messages.table.type',
            grow: false,
            small: true,
            sort: 0,
            maxWidth: '180px',
            minWidth: '90px',
            type: ColumnTypeEnum.text,
            name: 'type',
            isMultilang: true,
            sortField: 'logType',
        },
        {
            title: 'mailing.messages.table.message',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'message',
            align: ColumnValueAlignEnum.left,
        },
        {
            title: 'mailing.messages.table.status',
            grow: false,
            small: true,
            maxWidth: '180px',
            minWidth: '90px',
            type: ColumnTypeEnum.text,
            name: 'statusValue',
            isMultilang: true,
            styles: true,
        },
        {
            title: 'mailing.messages.table.group',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'groupName',
            align: ColumnValueAlignEnum.left,
        },
        {
            title: 'mailing.messages.table.recipient',
            grow: false,
            small: false,
            maxWidth: '220px',
            minWidth: '185px',
            type: ColumnTypeEnum.text,
            name: 'recipientLogin',
            tooltip: true,
        },
        {
            title: 'mailing.messages.table.transport',
            grow: false,
            small: false,
            maxWidth: '300px',
            minWidth: '120px',
            type: ColumnTypeEnum.transport,
            name: 'transportValue',
            tooltip: true,
        },
    ];

    constructor() {}

    getTransportForLogRow(log: NotificationLogInterface): MailingTransportRowLogInterface {
        switch (log.transportType) {
            case NotificationTransportType.EMAIL:
                return {
                    icon: NotificationTransportType.EMAIL,
                    data: log.transportType,
                };
            case NotificationTransportType.TELEGRAM:
                return {
                    icon: NotificationTransportType.TELEGRAM,
                    data: log.transportType,
                };
            case NotificationTransportType.VIBER:
                return {
                    icon: NotificationTransportType.VIBER,
                    data: log.transportType,
                };
            default:
                return null;
        }
    }

    getLogType(log: NotificationLogInterface): string {
        switch (log.logType) {
            case NotificationLogType.EVENT_LOG:
                return 'mailing.messages.types.eventLog';
            case NotificationLogType.REPORT:
                return 'mailing.messages.types.report';
            case NotificationLogType.VERIFY:
                return 'mailing.messages.types.verify';
            case NotificationLogType.ANY:
                return 'mailing.messages.types.any';
            default:
                return 'mailing.messages.types.any';
        }
    }

    getStyleForLogRow(log: NotificationLogInterface): any {
        switch (log.status) {
            case NotificationStatus.CHAT_NOT_FOUND:
            case NotificationStatus.ERROR:
                return {
                    color: '#FF6161',
                    'font-weight': '600',
                };
            case NotificationStatus.IN_PROCESS:
                return {
                    color: '#F9852C',
                    'font-weight': '600',
                };
            case NotificationStatus.SEND:
                return {
                    color: '#0FA579',
                    'font-weight': '600',
                };
        }
    }

    getStatusForLogRow(log: NotificationLogInterface): string {
        switch (log.status) {
            case NotificationStatus.ERROR:
                return 'mailing.messages.statuses.dontSent';
            case NotificationStatus.IN_PROCESS:
                return 'mailing.messages.statuses.progress';
            case NotificationStatus.SEND:
                return 'mailing.messages.statuses.sent';
            case NotificationStatus.CHAT_NOT_FOUND:
                return 'mailing.messages.statuses.notFound';
        }
    }
}

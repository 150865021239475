<div #chart class="charts" [style.width.px]="chartWidthForReports ? chartWidthForReports : ''">
    <div *appPermission="{registrator: currentRegistrator$ | async,
        action: resourceAction.GROUP_CHART,
        permissions: [methodPermission.READ],
        isHelper: true,
        isWithoutPermission: reportsMode}" class="charts__wrapper" #container>

        <div class="charts__info-wrapper">
            <div *ngIf="(isDataLimited$ | async) && !(isEmptyArchive$ | async).isError" class="charts__info">
                <div class="charts__limited" [translate]="'charts.dataIsLimited'"></div>
                <div class="charts__rereading">
                    <app-rereading (reReadingEvent)="reReadingChart($event)"
                                   [isDisabledDetail]="!!!fromToByRangeBox || (chartMode$ | async) === chartModeEnum.hole"></app-rereading>
                </div>
            </div>

            <div *ngIf="(isAdmin$ | async) && isShowUpdateChart" class="charts__update"
                 [style.width]="(isDataLimited$ | async) && !(isEmptyArchive$ | async).isError ? '2%' : '100%'"
            >
                <svg-icon (click)="updateChartInAdmin()" [src]="UPDATE_ICON_PATH"></svg-icon>
            </div>

            <div
                *ngIf="(chartMode$ | async) === chartModeEnum.alarm || (chartMode$ | async) === chartModeEnum.chartAndAlarm"
                class="charts__info-icons"
                [class.charts__info-icons--margin]="(isDataLimited$ | async)">
                <div class="charts__icon charts__icon--alarm">

                    <svg-icon
                        [src]="currentTheme === themeEnum.light ? ALARM_ICON_PATH : ALARM_DARK_ICON_PATH"></svg-icon>

                    <div class="charts__icon-tooltip">
                        <app-tooltip [value]="'charts.icons.alarm'" [side]="tooltipSideEnum.bottom"
                                     [isWhiteSpace]="true"></app-tooltip>
                    </div>
                </div>

                <div class="charts__icon charts__icon--attention">
                    <svg-icon
                        [src]="currentTheme === themeEnum.light ? ATTENTION_ICON_PATH : ATTENTION_DARK_ICON_PATH"></svg-icon>

                    <div class="charts__icon-tooltip">
                        <app-tooltip [value]="'charts.icons.attention'" [side]="tooltipSideEnum.bottom"
                                     [isWhiteSpace]="true"></app-tooltip>
                    </div>
                </div>
                <div class="charts__icon charts__icon--status">
                    <svg-icon [src]="STATUS_ICON_PATH"></svg-icon>

                    <div class="charts__icon-tooltip">
                        <app-tooltip [value]="'charts.icons.status'" [side]="tooltipSideEnum.bottom"
                                     [isWhiteSpace]="true"></app-tooltip>
                    </div>
                </div>
            </div>
        </div>

        <div class="charts__container"
             [class.charts__container--ds]="currentModification?.currentValue === dataloggerModificationEnum.DLT_22"
             [class.charts__container--hide]="((isEmptyArchive$ | async).isError || !isChart)">
            <!-- [style.height]="(isFullScreen$ | async) ? '100%' : 'calc(100% - 130px)'" -->

            <div class="charts__master" id="master" style="width:100%;" [style.min-height]="currentModification?.currentValue === dataloggerModificationEnum.DLT_22 ? '180px' : '100px'"></div>
            <div class="charts__detail" id="detail" style="width:100%;"
                 [style.height]="detailHeight ? (detailHeight + 'px') : '100%'"></div>

            <div *ngIf="chartService.detail" class="charts__resize-y">
                <div *ngFor="let btn of (scaleButtons$ | async); let i = index;"
                     class="charts__resize-item"
                     [style.top.px]="btn.top"
                     [style.left.px]="btn.left">
                    <button class="charts__resize-plus"
                            [style.border]="'1px solid ' + btn.color"
                            [style.color]="btn.color"
                            (click)="changeAxisScale(btn.axisId, changeAxisScaleOperator.plus)">+
                    </button>
                    <button class="charts__resize-minus"
                            [style.border]="'1px solid ' + btn.color"
                            [style.color]="btn.color"
                            (click)="changeAxisScale(btn.axisId, changeAxisScaleOperator.minus)">-
                    </button>
                </div>
            </div>

            <div *ngIf="type !== chartTypeEnum.report" class="charts__date-format">
                <app-checkbox [value]="isDetailDate"
                              [title]="'charts.detailDateTitle'"
                              (changeEvent)="setDetailDate()"></app-checkbox>
            </div>

        </div>
        <ng-container *ngIf="(isEmptyArchive$ | async).isError">
            <div class="charts__no-data" [translate]="(isEmptyArchive$ | async).message"></div>
        </ng-container>
    </div>
    <div class="{{resourceAction.GROUP_CHART}}"></div>
</div>

<app-popup *ngIf="isReReadPopup" [title]="'charts.rereading.popup.title'" (closeEmit)="isReReadPopup = false">
        <span class="popup__text" [translate]="'charts.rereading.popup.message'"></span>
        <div class="popup__calendar">
            <app-date-picker [config]="datepickerConfigForReread$ | async"
                (datepickerEvent)="datepickerEvent($event)"></app-date-picker>
        </div>
        <div class="popup__btns">
            <button class="popup__btn btn btn--ligth" (click)="isReReadPopup = false"
                    [translate]="'btns.cancel'"></button>
            <button class="popup__btn btn btn--red" (click)="reReadArchive()"
                    [translate]="'btns.continue'"></button>
        </div>
    <!-- </div> -->
</app-popup>

<!-- </ng-template> -->


import {Injectable} from '@angular/core';
import {ReportLogDataInterface} from '../_interfaces/ReportLog';
import {ReportGenerateStatus} from '../../reports/_enums/report-generate-status.enum';

@Injectable({
    providedIn: 'root'
})
export class ReportsLogsService {

    constructor() {
    }

    initSendValue(log: ReportLogDataInterface): string {
        let result = '';
        if (log.isSendEmail) {
            result += 'E-mail';
        }

        if (log.isSendTelegram) {
            result += ' Telegram';
        }

        return result;
    }

    initStatusValue(log: ReportLogDataInterface): string {
        switch (log.status) {
            case ReportGenerateStatus.SUCCESS:
                return 'reports.logs.status.ok';
            case ReportGenerateStatus.CANCEL:
                return 'reports.logs.status.cancel';
            case ReportGenerateStatus.EMAIL_SEND_ERROR:
                return 'reports.logs.status.emailError';
            case ReportGenerateStatus.CLIENT_ERROR:
                return 'reports.logs.status.client';
            default : return 'reports.logs.status.error';
        }
    }
    //
    // initColorStatus(log: ReportLogDataInterface): string {
    //     return log.status === ReportGenerateStatus.SUCCESS ? '#0ABB87' : '#FF6161';
    // }

}

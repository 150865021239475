import { CompanyInterface, CompanyRowInterface } from '../../_interfaces/company.interface';
import { OperationOnUserCompany } from '../../_interfaces/operation-on-user-company.interface';

export class GetCompanies {
    static readonly type = '[Company] GetCompanies';
}

export class CreateCompany {
    static readonly type = '[Company] CreateCompany';

    constructor(public company: CompanyInterface) {}
}

export class GetCurrentCompany {
    static readonly type = '[Company] GetCurrentCompany';
}

export class UpdateCompany {
    static readonly type = '[Company] UpdateCompany';

    constructor(public company: CompanyInterface) {}
}

export class DeleteCompany {
    static readonly type = '[Company] DeleteCompany';

    constructor(public id: string) {}
}

export class OperationOnCompany {
    static readonly type = '[Company] OperationOnCompany';

    constructor(public data: OperationOnUserCompany) {}
}

export class AddCompany {
    static readonly type = '[Company] AddCompany';
}

export class SetEditingCompanyRow {
    static readonly type = '[Company] SetEditingCompanyRow';

    constructor(public id: string) {}
}

export class CloseEditingCompanyRow {
    static readonly type = '[Company] CloseEditingCompanyRow';

    constructor(
        public id: string,
        public savedRow?: CompanyRowInterface,
    ) {}
}

export class InitCompanyRows {
    static readonly type = '[Company] InitCompanyRows';
}

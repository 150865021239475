import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { NotificationTransportType } from 'src/app/mailing/_interfaces/notification-log.interface';
import { UpdateCurrentAdminDtoInterface, UpdateUserDtoInterface } from 'src/app/profile/_interfaces/update-user-dto.interface';
import { TypeClient } from '../../../admin/users/_enum/type-client.enum';

export class GetCurrentUser {
    static readonly type = '[User] GetCurrentUser';

    constructor(public userId: string) {}
}

export class UpdateUser {
    static readonly type = '[User] UpdateUser';

    constructor(public data: UpdateUserDtoInterface) {}
}

export class UpdateAdmin {
    static readonly type = '[User] UpdateAdmin';

    constructor(public data: UpdateCurrentAdminDtoInterface) {}
}

export class SetUser {
    static readonly type = '[User] SetUser';

    constructor(public user: User) {}
}

export class ChangeUserPassword {
    static readonly type = '[User] ChangeUserPassword';

    constructor(public data: { id: number | string; password: string }, public client: TypeClient) {}
}

export class ReConfirmTransportNotification {
    static readonly type = '[User] ReConfirmTransportNotification';

    constructor(public transportType: NotificationTransportType) {}
}

export class ConfirmTransportNotification {
    static readonly type = '[User] ConfirmTransportNotification';

    constructor(public token: string) {}
}

export class ChangeUserUpdateValue {
    static readonly type = '[User] ChangeUserUpdateValue';

    constructor(public data: UpdateUserDtoInterface) {}
}

export class SetUserIdFromAdmin {
    static readonly type = '[User] SetUserIdFromAdmin';

    constructor(public userId: string) {}
}

export class SetTypeClient {
    static readonly type = '[User] SetTypeClient';

    constructor(public typeClient: TypeClient) {}
}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserState} from "../../../_store/states/user.state";
import {Observable} from "rxjs";
import {User} from "../../../_interfaces/user.interface";
import {Select} from "@ngxs/store";
import {UserProfileInfoTypeDateEnum} from "../../../../profile/_enums/user-profile-info-date-time.enum";
import {filter, first} from "rxjs/operators";
import {LuxonParseDateService} from "../../../_services/luxon-parse-date.service";

@Component({
    selector: 'app-columns-date-picker',
    templateUrl: './columns-date-picker.component.html',
    styleUrls: ['./columns-date-picker.component.scss']
})
export class ColumnsDatePickerComponent implements OnInit {
    @Select(UserState.getUser) user$: Observable<User>;

    @Output() editSmthEvent = new EventEmitter<any>();

    dateNow: Date;
    dateFormat = 'dd/MM/yy';

    userProfileInfoTypeDateEnum = UserProfileInfoTypeDateEnum;

    constructor(
        private luxonParseDateService: LuxonParseDateService
    ) {
    }

    ngOnInit(): void {
        this.user$.pipe(filter(user => !!user), first()).subscribe((user: User) => {
            this.dateNow = this.luxonParseDateService.getDateByTimezone(new Date(), user.dateTimeZone);
        });
    }

    datepickerEvent(event: Date): void {
        this.editSmthEvent.emit(event.getTime());
    }
}

<div class="action-list">
    <div class="action-list__wrapper">
        <ul class="action-list__list">
            <li *ngFor="let item of list" (click)="action(item)" class="action-list__item"
                [class.action-list__item--delete]="item.action === actionListItemEnum.delete">

                <svg-icon [src]="item.action"></svg-icon>

                <div class="action-list__item-title"
                     [class.action-list__item-title--delete]="item.action === actionListItemEnum.delete">{{item.title | translate}}</div>
            </li>
        </ul>
    </div>
</div>

import {InviteInterface} from '../../_interfaces/InviteInterfaces';

export class GetInvites {
    static readonly type = '[Invites] GetInvites';
}

export class ApplyInvite {
    static readonly type = '[Invites] ApplyInvite';
    constructor(public invite: InviteInterface) {}
}

export class CancelInvite {
    static readonly type = '[Invites] CancelInvite';
    constructor(public invite: InviteInterface) {}
}

<div class="user-profile">
    <div class="user-profile__wrapper">
        <div class="user-profile__tab">
            <app-user-profile-info [type]="client.User"
                                   [gmt]="gmt$ | async"
                                   [userInfo]="userInfo$ | async"
                                   [userInfoMailing]="userInfoMailing$ | async"
                                   [userInfoSystem]="userInfoSystem$ | async"
                                   [isEditable]="true"
                                   (isCanMoveTabEvent)="isCanMoveTab = $event"
                                   [ln]="ln$ | async"></app-user-profile-info>

            <div class="user-profile__table-heading" [translate]="'admin.userProfile.tableTitle'"></div>
            <app-tables *ngIf="(sessionsRows$ | async)"
                [isPagination]="false"
                [currentTableColumns]="userService.sessionTableColumns"
                [rows]="(sessionsRows$ | async)"
                [type]="tableTypeEnum.light"
                (actionEvent)="eventFromTable($event)"></app-tables>
        </div>

    </div>
</div>

<app-popup *ngIf="showDeleteSession"
           [title]="'profile.deleteTitle' | translate"
           (closeEmit)="cancelPopup()">
    <span class="popup__text popup__text--delete" [translate]="'profile.deleteQuestion'"></span>

    <div class="btns">
        <button class="btn btn--light" (click)="cancelPopup()" [translate]="'btns.cancel'"></button>
        <button class="btn btn--red" (click)="deleteSessions()" [translate]="'btns.delete'"></button>

    </div>
</app-popup>


export enum DisabledTypeEnum {
    sharedDeviceDisabled = 'sharedDeviceDisabled',
    addOrDeleteBtnInVirtualDeviceConstructor = 'addOrDeleteBtnInVirtualDeviceConstructor',
    saveVirtualDeviceWithoutArchivePeriod = 'saveVirtualDeviceWithoutArchivePeriod',
    checkForAlreadyAddArchivePeriodToVirtualDevice = 'checkForAlreadyAddArchivePeriodToVirtualDevice',
    checkForAlreadyAddVariableToVirtualDevice = 'checkForAlreadyAddVariableToVirtualDevice',
    saveTransport = 'saveTrasport',
    unitsTable = 'unitsTable',
    companyTable = 'companyTable',
    mailingUserTable = 'mailingUserTable',
    statusMessageTable = 'statusMessageTable',
    checkSaveMultiEvent = 'checkSaveMultiEvent',
    checkSaveLogicEvent = 'checkSaveLogicEvent',
    adminsListTable = 'adminsListTable',
    saveReports = 'saveReports',
    usersTable = 'usersTable',
    billingInfo = 'billingInfo',
    mailMessage = 'mailMessage',
    financeServices = 'financeServices',
    devicesControl = 'devicesControl',
    email = 'email',
    restriction = 'restriction',
    companyStaff = 'companyStaff',
}

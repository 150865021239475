import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {User} from 'src/app/app-shared-elements/_interfaces/user.interface';
import {LangEnum} from 'src/app/app-shared-elements/_enums/lang.enum';
import {AcknPropertyInterface} from 'src/app/app-shared-elements/_interfaces/acknowldge-property.inteface';
import {ColumnsTableInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import {DataTypeService} from 'src/app/app-shared-elements/_services/data-type.service';
import {LanguageState} from 'src/app/app-shared-elements/_store/states/language.state';
import {UserState} from 'src/app/app-shared-elements/_store/states/user.state';
import {TooltipSideEnum} from "../../../_enums/tooltip-side.enum";
import {AcknowledgedDeviceLogType} from "../../../../journals/_interfaces/device-log.inteface";

@Component({
    selector: 'app-columns-acknowledge',
    templateUrl: './columns-acknowledge.component.html',
    styleUrls: ['./columns-acknowledge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsAcknowledgeComponent implements OnInit {

    private _value: AcknPropertyInterface;

    @Input()
    set value(value) {
        this._value = value;
    }

    get value(): any {
        return this._value;
    }

    @Input() cell: ColumnsTableInterface;

    @Output() clickBtnEvent = new EventEmitter<void>();

    @Select(UserState.getUser) user$: Observable<User>;
    @Select(UserState.getUserTimezoneGMT) gmt$: Observable<string>;
    @Select(LanguageState.getLanguage) ln$: Observable<LangEnum>;


    isDisabledAfterClick: boolean;

    acknowledgedDeviceLogType = AcknowledgedDeviceLogType;

    tooltipSideEnum = TooltipSideEnum;

    constructor(public dataTypeService: DataTypeService) {
    }

    ngOnInit(): void {
    }


    emitClickBtn(): void {
        this.isDisabledAfterClick = true;
        this.clickBtnEvent.emit();
    }

}

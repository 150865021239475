<div id="freeze-block"
     class="block"
>
    <div class="block__wrapper">
        <div class="login__wrapper">

            <div class="block__close" (click)="closeModal()">
                <svg-icon [src]="CLOSE_PATH"></svg-icon>

            </div>
            <div class="block__header">
                <h1 class="login__title block__title" [translate]="'auth.block.title'"></h1>
            </div>
            <form class="block__form login__form" [formGroup]="form" novalidate>
                <!--                <div class="login__form-item">-->
                <!--                    <label class="login__form-label block__label" [translate]="'auth.block.login'"></label>-->
                <!--                    <input class="login__form-input" type="text" [value]="email" disabled name="email"-->
                <!--                           formControlName="email">-->
                <!--                    <p class="login__form-error"-->
                <!--                       *ngIf="form.controls['email'].invalid && form.controls['email'].touched">Please enter valid-->
                <!--                        data</p>-->

                <!--                </div>-->
                <div class="login__form-item">
                    <label class="login__form-label block__label" [translate]="'auth.block.password'"></label>
                    <input class="login__form-input"
                           appDisabledAutoFill
                           [placeholder]="'auth.block.password' | translate"
                           [type]="'password'"
                           name="login" formControlName="password">
                    <!--                    <div class="login__icon-eyes" (click)="isVisiblePassword = !isVisiblePassword">-->
                    <!--                        <svg-icon-->
                    <!--                                  [src]="!isVisiblePassword ? './assets/design/icons/eyes.svg' : './assets/design/icons/eyes-close.svg'"></svg-icon>-->
                    <!--                    </div>-->
                    <p class="login__form-error"
                       *ngIf="form.controls['password'].invalid && form.controls['password'].touched && !errorMessage"
                       [translate]="'auth.login.errorPassword'"></p>
                    <p class="login__form-error" *ngIf="errorMessage" [translate]="'auth.login.errorPassword'"></p>
                </div>
                <div class="login__form-activity">
                    <div class="block__btns">
                        <div class="login__form-activity-column block__btns-wrapper">
                            <button type="button" (click)="logout()" class="block__btn btn btn--light"
                                    [translate]="'btns.logout'"></button>
                            <button (click)="login()"
                                    type="button"
                                    [disabled]="!form.value.password.length"
                                    class="block__btn block__btn--login btn"
                                    [translate]="'btns.signIn'"></button>
                            <div *ngIf="(configurationServer$ | async)?.isGoogleAuthorization"
                                 class="login__form-activity-column block__google">
                                <p class="login__form-sign-with-title" [translate]="'auth.login.with'"></p>
                                <div class="login__form-sign-with-link" (click)="googleLogin()">
                                    <svg-icon [src]="GOOGLE_ICONS_PATH"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>

<div class="column">
    <div class="column-ackn">
        <div class="column-ackn__wrapper column-ackn__wrapper--tsValue">

            <div class="column-ts-time">
                {{displayValue | luxonDate:(user$ | async).dateFormat || dataTypeService.dateFormat : (gmt$ | async) || dataTypeService.GMT : (ln$ | async)}}
            </div>
            <div *ngIf="value && value.completionType" class="column-type-completion">
                <ng-container *ngIf="value.completionType === completionType.CHANGE_ACTIVE">
                    <span [translate]="'logs.events.changed'"></span>
                    <div class="column-type-completion__tooltip">
                        <app-tooltip [template]="true">
                            <div class="column-type-completion__tooltip-text"
                                 [translate]="'events.logicalEvents.table.completionByActive'"
                                 [translateParams]="{email: value.finishedByUser}"></div>
                        </app-tooltip>
                    </div>
                </ng-container>

                <ng-container
                    *ngIf="value.completionType === completionType.DELETE">
                    <span [translate]="'logs.events.deleted'"></span>
                    <div class="column-type-completion__tooltip">
                        <app-tooltip [template]="true">
                            <div class="column-type-completion__tooltip-text"
                                 [translate]="'events.logicalEvents.table.completionByDelete'"
                                 [translateParams]="{email: value.finishedByUser}"></div>
                        </app-tooltip>
                    </div>
                </ng-container>

                <ng-container *ngIf="value.completionType === completionType.COMPLETED">
                    <span [translate]="'logs.events.completed'"></span>
                    <div class="column-type-completion__tooltip">
                        <app-tooltip [template]="true">
                            <div class="column-type-completion__tooltip-text"
                                 [translate]="'events.logicalEvents.table.completionByCompleted'"
                                 [translateParams]="{email: value.finishedByUser}"></div>
                        </app-tooltip>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

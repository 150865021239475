import { HTTP_STATUS } from './../_enums/status.enum';
import { catchError, tap } from 'rxjs/operators';
import { NotificationsService } from './../_services/notifications.service';
import { Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { TooltipStatusEnum } from '../_enums/tooltip-status.enum';
import { TranslateService } from '@ngx-translate/core';
import { BlockUiService } from '../_services/auto-block-ui.service';
import { Store } from '@ngxs/store';
import { UserState } from '../_store/states/user.state';

@Injectable()
export class StatusResponseInterceptor implements HttpInterceptor {
    errorStatuses = [
        HTTP_STATUS.ERROR,
        HTTP_STATUS.IN_PROCESSED_REGISTRATOR,
        HTTP_STATUS.EVENTS_ACTIVE_FOR_REGISTRATOR,
        HTTP_STATUS.REGISTRATOR_IS_OFFLINE,
        HTTP_STATUS.UNAUTHORIZED,
    ];
    statusesForEmptyBody = [HTTP_STATUS.TOO_MANY_REQUEST, HTTP_STATUS.INTERNAL_SERVER_ERROR, HTTP_STATUS.PAYLOAD_TOO_LARGE, HTTP_STATUS.OK];

    constructor(
        private notificationsService: NotificationsService,
        private translateService: TranslateService,
        private zone: NgZone,
        private blockUiService: BlockUiService,
        private store: Store,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(async (evt: HttpResponse<any>) => {
                if (evt.body?.status === HTTP_STATUS.SESSION_FREEZE) {
                    this.blockUiService.blockUi(true);

                    const translate = await this.translateService.get('disconnectBlock.sessionBlocked').toPromise();

                    this.zone.runOutsideAngular(() => {
                        setTimeout(() => {
                            this.notificationsService.onEmit(TooltipStatusEnum.load, true, translate);
                            this.blockUiService.openModal();
                        }, 100);
                    });

                    return;
                }

                if (this.errorStatuses.includes(evt.body?.status)) {
                    const translate = await this.translateService.get(evt.body?.message).toPromise();
                    const message = `${evt.url}<br>${translate || evt.body?.message}`;
                    this.zone.runOutsideAngular(() => {
                        setTimeout(() => {
                            this.notificationsService.onEmit(TooltipStatusEnum.error, false, message);
                        }, 100);
                    });
                }

                switch (evt?.body?.status as HTTP_STATUS) {
                    case HTTP_STATUS.ACCESS_DENIED:
                        const accessDenied = await this.translateService.get('permissions.accessDenied').toPromise();
                        this.zone.runOutsideAngular(() => {
                            setTimeout(() => {
                                this.notificationsService.onEmit(TooltipStatusEnum.error, false, accessDenied);
                            }, 100);
                        });
                        break;
                    case HTTP_STATUS.REGISTRATOR_IN_SYNC:
                        const messageSync = await this.translateService.get('devices.registratorIsSync').toPromise();
                        this.zone.runOutsideAngular(() => {
                            setTimeout(() => {
                                this.notificationsService.onEmit(TooltipStatusEnum.error, false, messageSync);
                            }, 100);
                        });
                        break;
                }
            }),
            catchError((event: HttpErrorResponse) => {
                const body = this.modifyBody(event.status);
                const d = new HttpResponse({ body });
                if (!this.store.selectSnapshot(UserState.getUser) && event.status === 401) {
                    return of(d);
                }

                if (event.url?.indexOf('assets/version.json') !== -1) {
                    return of(d);
                }

                if (event.status === 429) {
                    this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'registratorLogs.errorToaster');
                    return of(d);
                }

                // console.log(d);
                this.notificationsService.onEmit(TooltipStatusEnum.error, false, `server error: ${event.status}`);

                return of(d);
            }),
        );
    }

    private modifyBody(statusErr: number): any {
        const currentStatus = this.statusesForEmptyBody.find((status) => Math.abs(status) === statusErr);
        return { status: currentStatus } || { status: HTTP_STATUS.ERROR };
    }
}

<div class="footer" appTheme>
    <div class="footer__wrapper">

        <div class="footer__message">
            <span *ngIf="isAdmin$ | async; else user" [translate]="'footer.adminMessage'"
                  [translateParams]="{
                    version: (configurationServer$ | async)?.buildDate | date: 'YYYY-MM-dd HH:mm:ss',
                    time: dateNow | date: 'HH:mm:ss z',
                    timezone: (((user$ | async) && (user$ | async).dateTimeZone) ? (user$ | async).dateTimeZone.replace('Kiev', 'Kyiv') : dataTypeService.GMT),
                    ping: ping,
                    performance: performance}"
            ></span>

            <ng-template #user>

                <span [translate]="'footer.message'"
                      [translateParams]="{
                    version: (configurationServer$ | async)?.buildDate | date: 'YYYY-MM-dd HH:mm:ss',
                    time: dateNow | date: 'HH:mm:ss z',
                    timezone: (((user$ | async) && (user$ | async).dateTimeZone) ? (user$ | async).dateTimeZone.replace('Kiev', 'Kyiv') : dataTypeService.GMT),
                    ping: ping}"
                ></span>

            </ng-template>
        </div>

        <div *ngIf="lastActiveEvent$ | async" class="footer__last-event">

            <div class="footer__last-event-icon">

                <svg-icon [src]="(lastActiveEvent$ | async).alarmType === alarmTypeEnum.attention
                ? ATTENTION_ICON_PATH : ALARM_ICON_PATH"></svg-icon>

            </div>

            <div class="footer__last-event-time"
                 [class.footer__last-event-time--attention]="(lastActiveEvent$ | async).alarmType === alarmTypeEnum.attention"
                 [class.footer__last-event-time--alarm]="(lastActiveEvent$ | async).alarmType === alarmTypeEnum.alarm"
            >

                {{(lastActiveEvent$ | async)?.ts | luxonDate
                : (user$ | async) && (user$ | async).dateFormat ? (user$ | async).dateFormat
                    : dataTypeService.dateFormat : (gmt$ | async)
                    : (ln$ | async)}}

            </div>

            <div class="footer__last-event-message"
                 [class.footer__last-event-message--attention]="(lastActiveEvent$ | async).alarmType === alarmTypeEnum.attention"
                 [class.footer__last-event-message--alarm]="(lastActiveEvent$ | async).alarmType === alarmTypeEnum.alarm"
            >{{(lastActiveEvent$ | async).message}}</div>
        </div>
        <div class="footer__status-arch" [class.footer__status-arch--load]="devices && devices.length">
            <svg-icon [src]="STATUS_ARCH_PATH"></svg-icon>

            <div *ngIf="devices && devices.length" class="footer__arch-info">
                <app-arch-tooltip [devices]="devices"></app-arch-tooltip>
            </div>
        </div>
        <div class="footer__devices">
            <svg-icon [src]="DEVICES_TOOLTIP_PATH"></svg-icon>

            <div class="footer__devices-tooltip">
                <app-devices-tooltip></app-devices-tooltip>
            </div>

        </div>
    </div>
</div>

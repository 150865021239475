import {Directive, ElementRef, EventEmitter, HostListener, Input, NgZone, OnInit, Output} from '@angular/core';
import {OperandConstantType} from '../../events/_enums/condition-type.enum';
import {ConstantValidateConfigInterface} from '../../events/_interfaces/ConstantValidateConfig';

@Directive({
    selector: '[appConstantValidate]'
})
export class ConstantValidateDirective implements OnInit {
    private _config: ConstantValidateConfigInterface;

    @Input('appConstantValidate')
    set config(config) {
        if (!config) {
            return;
        }
        this._config = config;
        // console.log(config);
        // console.log(this.value);
        if (!this.value) {
            this.value = config.value;

            this.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.selectCurrentValidate();
                }, 300);
            });
        }
    }

    get config(): ConstantValidateConfigInterface {
        return this._config;
    }

    private currentBooleanValueArray = [0, 1, '0', '1', 'true', 'false'];

    value: any;

    @Output() validateValue: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private el: ElementRef,
        private ngZone: NgZone
    ) { }

    ngOnInit(): void {
    }

    @HostListener('input', ['$event'])
    onKeyDown(): void {
        this.value = this.el.nativeElement.value;

        this.selectCurrentValidate();
    }

    selectCurrentValidate(): void {
        if (!this.config) {
            return;
        }

        switch (this.config.type) {
            case OperandConstantType.BOOL:
                this.validateBool();
                break;
            case OperandConstantType.FLOAT:
                this.validateFloat();
                break;
            case OperandConstantType.INT:
                this.validateInt();
                break;
            case OperandConstantType.STRING:
                this.validateString();
                break;
        }

        // this.validateValue.emit(this.value)
    }

    validateBool(): void {
        this.currentBooleanValueArray.includes(this.value) ? this.validateValue.emit(false) : this.validateValue.emit(true);
    }

    validateFloat(): void {
        if (isNaN(+this.value)) {
            this.validateValue.emit(true);
            return;
        }

        (Number(+this.value) === +this.value && +this.value % 1 !== 0) || (Number(+this.value) === +this.value && +this.value % 1 === 0)
            ? this.validateValue.emit(false)
            : this.validateValue.emit(true);
    }

    validateInt(): void {
        if (isNaN(+this.value)) {
            this.validateValue.emit(true);
            return;
        }

        if (this.value && this.value.includes('.')) {
            this.validateValue.emit(true);
            return;
        }

        Number(+this.value) === +this.value && +this.value % 1 === 0 ? this.validateValue.emit(false) : this.validateValue.emit(true);
    }

    validateString(): void {
        this.validateValue.emit(false);
    }

}

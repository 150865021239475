import {BillingAccountInfoTypeEnum} from '../../_enums/billing-account-info-type.enum';
import {UpdateBillingAccountInfoInterface} from "../../_interfaces/billing-personal-account.interface";
import {UpdateAdminDtoInterface} from "../../../admin/admin-billing/_interfaces/update-admin-dto.interface";

export class GetBillingAccount {
    static readonly type = '[BillingInfo] GetBillingAccount';

}

export class GetBillingAccountInfo {
    static readonly type = '[BillingInfo] GetBillingAccountInfo';
}

export class ChangeAccountInfoUpdateValue {
    static readonly type = '[BillingInfo] ChangeAccountInfoUpdateValue';

    constructor(public data: UpdateBillingAccountInfoInterface) {

    }

}

export class ChangeAccountInfoOptions {
    static readonly type = '[BillingInfo] ChangeAccountInfoOptions';

    constructor(public value: string, public type: BillingAccountInfoTypeEnum) {
    }
}

export class UpdateBillingAccountInfo {
    static readonly type = '[BillingInfo] UpdateBillingAccountInfo';

    constructor(public data: UpdateBillingAccountInfoInterface) {
    }

}

export class UpdateAdminBillingInfo {
    static readonly type = '[BillingInfo] UpdateAdminBillingInfo';

    constructor(public data: UpdateAdminDtoInterface) {
    }
}

export class UpdateBillingAccountBalance {
    static readonly type = '[BillingInfo] UpdateBillingAccountBalance';

    constructor(public balance: number) {
    }
}

import { UsersService } from '../../admin/users/_services/users.service';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TableService } from '../_services/table.service';
import { EventSaveStatus } from 'src/app/events/_enums/event-save-state.enum';
import { ColumnIconType, ColumnsTableInterface, ColumnTypeEnum, ColumnValueAlignEnum } from '../_interfaces/ColumnsTable';
import { TableTypeEnum } from '../_enums/table-type.enum';
import { SelectTypeEnum } from '../_enums/select-type.enum';
import { Pagination, ParamsSorted, ParamsSortedOrderEnum } from '../_interfaces/params.interface';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { TableState } from '../_store/states/table.state';
import { AlarmTypeEnum } from 'src/app/events/_enums/alarm.enum';
import { Router } from '@angular/router';
import { ColumnsActionTypeEnum } from '../_enums/columns-action-type.enum';
import { EventFromTableInterface } from '../_interfaces/event-from-table.interface';
import { GroupOperationActionInterface } from './_interfaces/group-operation-action.interface';
import { IconEventInterface } from './_interfaces/icon-event.interface';
import { UpdatedStatusMessageRows } from '../../admin/event-messages/_store/actions/status-messages.actions';
import { SelectOptionInterface } from '../_interfaces/select-option.interface';
import { DeviceTypeEnum } from '../_enums/device-type.enum';
import { TooltipSideEnum } from '../_enums/tooltip-side.enum';

@Component({
    selector: 'app-tables',
    templateUrl: './tables.component.html',
    styleUrls: ['./tables.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TablesComponent implements OnInit, OnDestroy, AfterViewInit {
    private _currentTableColumns: ColumnsTableInterface[];
    private _rows: any[] = [];

    statusSaved = EventSaveStatus;
    currentSort = {}; // ParamsSorted[] = [];

    @Input() fullWidthTable = false;
    @Input() isPagination = true;
    @Input() type: TableTypeEnum;
    @Input() unHidden = false;
    @Input() maxWidth: number;
    @Input() minWidth: number;
    @Input() maxContent: boolean;
    @Input() isGroupOperations: boolean;
    @Input() isDisabled: boolean;
    @Input() isStickyHeader = false;

    @Output() private sortOutput: EventEmitter<ParamsSorted[]> = new EventEmitter<ParamsSorted[]>();
    @Output() private paginationOutput: EventEmitter<Pagination> = new EventEmitter<Pagination>();
    @Output() private deleteSmthEvent = new EventEmitter<any>();
    @Output() private deviceKeyEvent = new EventEmitter<any>();
    // @Output() revertSmthEvent = new EventEmitter<any>();
    @Output() private editSmthEvent = new EventEmitter<{ row: any; event: any }>();
    @Output() private goToEditSmthEvent = new EventEmitter<any>();
    @Output() private editProcessEvent = new EventEmitter<any>();
    @Output() private openSmth = new EventEmitter<any>();
    @Output() private activityEvent: EventEmitter<{ row: any; event: boolean; index: number }> = new EventEmitter<{
        row: any;
        event: boolean;
        index: number;
    }>();
    @Output() private eventClickBtnFromColumn: EventEmitter<{
        row: any;
        event: any;
        index: number;
    }> = new EventEmitter<{
        row: any;
        event: any;
        index: number;
    }>();
    @Output() private removeColumnEvent = new EventEmitter<any>();
    @Output() private eventFromIcon: EventEmitter<IconEventInterface<any>> = new EventEmitter<IconEventInterface<any>>();

    @Output() actionEvent: EventEmitter<EventFromTableInterface> = new EventEmitter<EventFromTableInterface>();
    @Output() chooseFromGroupOperationEvent: EventEmitter<GroupOperationActionInterface> = new EventEmitter<GroupOperationActionInterface>();
    @Output() changeSelectOptionEvent: EventEmitter<{
        row: any;
        option: SelectOptionInterface;
        cell: ColumnsTableInterface;
    }> = new EventEmitter<{ row: any; option: SelectOptionInterface; cell: ColumnsTableInterface }>();
    @Output() eventFromInputColumn: EventEmitter<any> = new EventEmitter<any>();
    @Output() eventFromCheckbox = new EventEmitter<any>();
    // @Output() optionsChangedEvent: EventEmitter<any> = new EventEmitter<any>();

    @Input() configPagination: Pagination = {
        itemsPerPage: 1,
        currentPage: 1,
        totalItems: 1,
    };

    tableTypeEnum = TableTypeEnum;
    paramsSortedOrderEnum = ParamsSortedOrderEnum;

    isShowChoose = false;

    @Input()
    set currentTableColumns(array: ColumnsTableInterface[]) {
        this._currentTableColumns = array;
    }

    get currentTableColumns(): ColumnsTableInterface[] {
        return this._currentTableColumns;
    }

    @Input()
    set rows(rows: any[]) {
        this._rows = rows; // || [];
    }

    get rows(): any[] {
        this.customCountOption = this.tableService.counts.find((option) => this.configPagination && option.key === this.configPagination.itemsPerPage) || this.tableService.counts[2];

        return this._rows;
    }

    deviceTypeEnum = DeviceTypeEnum;
    currentWidth: number;

    eventSaveStatus = EventSaveStatus;
    columnTypeEnum = ColumnTypeEnum;
    columnIconType = ColumnIconType;
    columnValueAlignEnum = ColumnValueAlignEnum;
    selectTypeEnum = SelectTypeEnum;
    tooltipSideEnum = TooltipSideEnum;
    alarmTypeEnum = AlarmTypeEnum;

    customCountOption: SelectOptionInterface<number, string, number>;

    readonly QUESTION_ICON_PATH = './assets/design/icons/question.svg';
    readonly ARROW_COLUMN_ICON_PATH = './assets/design/icons/table-operations/arrow-columns.svg';
    readonly REMOVE_COLUMN_ICON_PATH = './assets/design/icons/table-operations/remove-column.svg';

    @Select(TableState.getSkeleton) isSkeleton$: Observable<boolean>;

    // @Select(TableState.getTableGroupOperations) tableOperations$: Observable<boolean>;

    constructor(
        public tableService: TableService,
        public usersService: UsersService,
        public router: Router,
        private store: Store,
    ) {
        this.currentWidth = window.innerWidth;
    }

    ngOnInit(): void {
        this.customCountOption = this.tableService.counts.find((option) => this.configPagination && option.key === this.configPagination.itemsPerPage) || this.tableService.counts[2];
    }

    ngAfterViewInit(): void {}

    ngOnDestroy(): void {}

    @HostListener('window:resize', ['$event'])
    onResize(event): void {
        // event.target.innerWidth;

        this.currentWidth = event.target.innerWidth;
    }

    openSmthEvent(row, cell?): void {
        this.openSmth.emit({ row, cell });
    }

    pageChanged(event: number): void {
        this.configPagination.currentPage = event;
        this.paginationOutput.emit(this.configPagination);
    }

    checkCurrentPage(event: SelectOptionInterface<number, string, number>): void {
        const maxPage = Math.ceil(this.configPagination.totalItems / event.key);

        if (this.configPagination.currentPage > maxPage) {
            this.configPagination.currentPage = 1;
        }
        this.configPagination.itemsPerPage = event.key;
        this.paginationOutput.emit(this.configPagination);
    }

    optionsChanged(option: SelectOptionInterface<any, any, any>, row, cell): void {
        const event = option.key;

        if (this.router.url.includes('system-settings')) {
            this.editSmthEvent.emit({ row, event });
        }

        if (this.router.url.includes('control/company')) {
            row[cell.name] = option.key;
        }

        if (this.router.url.includes('event-messages/status-messages')) {
            row[cell.name] = option.property.data;
            this.store.dispatch(new UpdatedStatusMessageRows(option.key !== null));
        }

        this.changeSelectOptionEvent.emit({ row, option, cell });
    }

    changeFromInput(row: any, cell: ColumnsTableInterface, event: any): void {
        this.eventFromInputColumn.emit({
            ...row,
            [cell.name]: event,
        });
    }

    changeFromCheckbox(row, cell: ColumnsTableInterface, event): void {
        this.eventFromCheckbox.emit({
            row,
            event,
        });
    }

    sortRows(sortField: string): void {
        if (!this.currentSort[sortField]) {
            (this.currentSort[sortField] as ParamsSorted) = {
                order: ParamsSortedOrderEnum.ASC,
                property: sortField,
            };
            this.sortOutput.emit(this.getCurrentSortArray());
            return;
        } else {
            if ((this.currentSort[sortField] as ParamsSorted).order === ParamsSortedOrderEnum.FALSE) {
                (this.currentSort[sortField] as ParamsSorted).order = ParamsSortedOrderEnum.ASC;
                (this.currentSort[sortField] as ParamsSorted).property = sortField;

                this.sortOutput.emit(this.getCurrentSortArray());
                return;
            }

            if ((this.currentSort[sortField] as ParamsSorted).order === ParamsSortedOrderEnum.ASC) {
                (this.currentSort[sortField] as ParamsSorted).order = ParamsSortedOrderEnum.DESC;
                this.sortOutput.emit(this.getCurrentSortArray());
                return;
            }

            if ((this.currentSort[sortField] as ParamsSorted).order === ParamsSortedOrderEnum.DESC) {
                (this.currentSort[sortField] as ParamsSorted).order = ParamsSortedOrderEnum.FALSE;
                (this.currentSort[sortField] as ParamsSorted).property = null;
                this.sortOutput.emit(this.getCurrentSortArray());
                return;
            }
        }
    }

    private getCurrentSortArray(): ParamsSorted[] {
        const arraySorted: ParamsSorted[] = [];
        Object.keys(this.currentSort).forEach((key) => {
            if (!(this.currentSort[key] as ParamsSorted).property) {
                return;
            }

            arraySorted.push({ property: this.currentSort[key].property, order: this.currentSort[key].order });
        });

        return arraySorted.filter((sort) => sort.order !== ParamsSortedOrderEnum.FALSE);
    }

    onDeleteSmth(row): void {
        this.deleteSmthEvent.emit(row);
    }

    onDeviceKeySmth(row): void {
        this.deviceKeyEvent.emit(row);
    }

    // onRevertSmth(row): void {
    //     this.revertSmthEvent.emit(row);
    // }

    onEditSmth(row, event?): void {
        if (event) {
            row.event = event;
        }
        this.editSmthEvent.emit({ row, event });
    }

    onEditProcessEvent(): void {
        this.editProcessEvent.emit();
    }

    emitChangeActivity(row, event, index): void {
        this.activityEvent.emit({ row, event, index });
    }

    emitClickFromColumnBtn(row, event, index): void {
        this.eventClickBtnFromColumn.emit({ row, event, index });
    }

    removeColumn(cell): void {
        this.removeColumnEvent.emit(cell);
    }

    onSaveSmth(row, event): void {
        this.editSmthEvent.emit({ row, event });
    }

    onGoToEditSmth(event): void {
        this.goToEditSmthEvent.emit(event);
    }

    trackByRow(index, item): any {
        return item.id;
    }

    trackBySubRow(index, item): any {
        return item.id;
    }

    typeEvent(type: ColumnsActionTypeEnum, row: any, cell: ColumnsTableInterface): void {
        this.actionEvent.emit({ type, row, cell });
    }

    chooseFromGroupOperation(event: GroupOperationActionInterface): void {
        this.chooseFromGroupOperationEvent.emit(event);
    }

    iconEvent(event: IconEventInterface<any>): void {
        this.eventFromIcon.emit(event);
    }
}

export enum UserDeviceIconEnum {
    drag = './assets/design/icons/devices/drag.svg',
    registrator = './assets/design/icons/devices/registrator.svg',
    sensor = './assets/design/icons/devices/sensor.svg',
    coordinator = './assets/design/icons/devices/coordinator.svg',
    datalogger = './assets/design/icons/devices/datalogger.svg',
    setting = './assets/design/icons/devices/setting.svg',
    sync = './assets/design/icons/devices/sync.svg',

    // admin

    deleted = './assets/design/icons/devices/deleted.svg',
}

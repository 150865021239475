import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../auth/_services/auth.service';
import {UsersService} from '../users/_services/users.service';
import {TypeClient} from '../users/_enum/type-client.enum';

/**
 * Сервис, реализующий интерфейс ангуляра CanActivate,
 * используется при определении защищенных роутов.
 */
@Injectable()
export class AdminAuthGuardService {
    constructor(
        private authService: AuthService,
        private userService: UsersService,
        private route: Router,
    ) {}

    async canActivate(): Promise<boolean> {
        if (!(await this.authService.isAuthenticated())) {
            await this.route.navigate(['/login']);
            return false;
        }

        if (this.userService.getTypeClient() === TypeClient.Admin) {
            return true;
        }
        await this.route.navigate(['/']);

        return false;
    }
}

import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {GenerationAuthenticationKey, UnBindAuthenticationKey, VerificationAuthenticationKey} from '../actions/authentication.actions';
import {ApiResponse} from '../../../app-shared-elements/_interfaces/ApiRequest';
import {HttpClient} from '@angular/common/http';
import {HTTP_STATUS} from '../../../app-shared-elements/_enums/status.enum';
import {NotificationsService} from '../../../app-shared-elements/_services/notifications.service';
import {TooltipStatusEnum} from '../../../app-shared-elements/_enums/tooltip-status.enum';
import {UsersService} from 'src/app/admin/users/_services/users.service';
import {TypeClient} from 'src/app/admin/users/_enum/type-client.enum';

export interface AuthenticationStateModel {
    code: { qrSecret: string, secret: string };
}

const AUTHENTICATION_TOKEN = new StateToken<AuthenticationStateModel>('authentication');

@State<AuthenticationStateModel>({
    name: AUTHENTICATION_TOKEN,
    defaults: {
        code: null
    }
})

@Injectable()
export class AuthenticationState {
    constructor(
        private http: HttpClient,
        private notificationService: NotificationsService,
        private usersService: UsersService
    ) {
    }

    @Selector()
    static getQrSecret(state: AuthenticationStateModel): string {
        return state.code.qrSecret;
    }

    @Selector()
    static getSecret(state: AuthenticationStateModel): string {
        return state.code.secret;
    }

    @Action(GenerationAuthenticationKey)
    async generationAuthenticationKey(ctx: StateContext<AuthenticationStateModel>): Promise<void> {
        const state = ctx.getState();
        const currentTypeClient = this.usersService.getTypeClient();
        const result: ApiResponse = await this.http.post(`/api${currentTypeClient === TypeClient.Admin ? '/control' : ''}/two-factor-authentication/generate`, {}).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {

            ctx.setState({
                ...state,
                code: result.data
            });
        }
    }

    @Action(VerificationAuthenticationKey)
    async verificationAuthenticationKey(ctx: StateContext<AuthenticationStateModel>, payload: VerificationAuthenticationKey): Promise<void> {
        const state = ctx.getState();
        const currentTypeClient = this.usersService.getTypeClient();
        const result: ApiResponse = await this.http.post(`/api${currentTypeClient === TypeClient.Admin ? '/control' : ''}/two-factor-authentication/verify`,
            {
                token: payload.code
            }).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (!result) {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
            return;
        }

        switch (result.status) {
            case HTTP_STATUS.TWO_FACTOR_AUTHENTICATION_TOKEN_NOT_VALID:
                this.notificationService.onEmit(TooltipStatusEnum.error, false, 'Code doesn`t valid');
                return;
            case HTTP_STATUS.SUCCESS:
                this.notificationService.onEmit(TooltipStatusEnum.update, false);
                ctx.setState({
                    ...state,
                });
                return;
        }
    }

    @Action(UnBindAuthenticationKey)
    async unBindAuthenticationKey(ctx: StateContext<AuthenticationStateModel>, payload: UnBindAuthenticationKey): Promise<void> {
        const state = ctx.getState();
        const currentTypeClient = this.usersService.getTypeClient();
        const result: ApiResponse = await this.http.post(`/api${currentTypeClient === TypeClient.Admin ? '/control' : ''}/two-factor-authentication/unbind`,
            {
                token: payload.code
            }).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state
            });
        }
    }
}


import { ReportLogInterface } from '../_interfaces/ReportLog';
import { ReportsLogsService } from '../_services/reports-logs.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { ReportsState } from '../../reports/_store/states/reports.state';
import { GetReportsLogs, SetReportsLogsParams } from '../../reports/_store/actions/reports.actions';
import { SetFilterConfig, SetTimeObj, SetTimeType } from '../../app-shared-elements/_store/actions/time-filter.actions';
import { DisabledFilterOptionsEnum } from '../../app-shared-elements/_enums/filter-options.enum';
import { Pagination, Params, ParamsSorted, ParamsTime, ParamsTimeTypeEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { PreloaderService } from 'src/app/app-shared-elements/_services/preloader.service';
import { PreloaderWrappersEnum } from 'src/app/app-shared-elements/_enums/preloader-wrappers.enum';
import { ColumnsTableInterface } from '../../app-shared-elements/_interfaces/ColumnsTable';
import { TimeFilterState } from 'src/app/app-shared-elements/_store/states/time-filter.state';
import { skip, takeUntil } from 'rxjs/operators';
import { SetSkeleton } from 'src/app/app-shared-elements/_store/actions/table.actions';
import { FilterItemTypeEnum } from '../../app-shared-elements/filters/enums/filter-item-type.enum';

@Component({
    selector: 'app-reports-logs',
    templateUrl: './reports-logs.component.html',
    styleUrls: ['./reports-logs.component.scss'],
})
export class ReportsLogsComponent implements OnInit, OnDestroy {
    @Select(ReportsState.getReportsLogsColumn) reportsLogsColumn$: Observable<ColumnsTableInterface[]>;
    @Select(ReportsState.getReportsLogsRows) reportLogsRows$: Observable<ReportLogInterface[]>;
    @Select(ReportsState.getParamsForLogs) params$: Observable<Params>;
    @Select(TimeFilterState.getTimeObj) timeObj$: Observable<ParamsTime>;

    filterItemTypeEnum = FilterItemTypeEnum;
    private timeOutLogsHandler;
    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(public reportsLogsService: ReportsLogsService, private store: Store, private preloaderService: PreloaderService) {
        this.store.dispatch(new SetSkeleton(true));
        this.store.dispatch(new SetTimeObj({ from: null, to: null, time: null, type: ParamsTimeTypeEnum.ALL_TIME }));
        this.store.dispatch(new SetTimeType(ParamsTimeTypeEnum.ALL_TIME));
        this.store.dispatch(
            new SetFilterConfig({
                type: 'date',
                disableFunctions: [
                    DisabledFilterOptionsEnum.current,
                    DisabledFilterOptionsEnum.time,
                    DisabledFilterOptionsEnum.chartType,
                    DisabledFilterOptionsEnum.resetBtn,
                ],
            }),
        );
    }

    async ngOnInit(): Promise<void> {
        await this.preloaderService.initPreloader(PreloaderWrappersEnum.tableBody);
        // await this.store.dispatch(new GetReportsLogs()).toPromise();
        await this.nextLogs();
        // await this.getLogs();

        this.timeObj$.pipe(takeUntil(this.destroy), skip(1)).subscribe((timeObj) => {
            const params = this.store.selectSnapshot(ReportsState.getParamsForLogs);
            this.store.dispatch(
                new SetReportsLogsParams({
                    ...params,
                    pagination: { ...params.pagination, currentPage: 1 },
                }),
            );
            this.store.dispatch(new GetReportsLogs());
        });
    }

    ngOnDestroy(): void {
        clearTimeout(this.timeOutLogsHandler);
        this.preloaderService.destroyPreloader();
        this.destroy.next(true);
        this.destroy.complete();
    }

    private async nextLogs(): Promise<void> {
        clearTimeout(this.timeOutLogsHandler);
        await this.store.dispatch(new GetReportsLogs()).toPromise();
        await this.preloaderService.destroyPreloader();

        this.timeOutLogsHandler = setTimeout(async () => {
            await this.nextLogs();
        }, 60000);
    }

    async onChangePageLogs(pagination: Pagination): Promise<void> {
        const params = this.store.selectSnapshot(ReportsState.getParamsForLogs);

        this.store.dispatch(new SetReportsLogsParams({ ...params, pagination })).toPromise();
    }

    async changeSort(data: ParamsSorted[]): Promise<void> {
        const params = this.store.selectSnapshot(ReportsState.getParamsForLogs);

        this.store.dispatch(new SetReportsLogsParams({ ...params, sorted: [...data] })).toPromise();
    }
}

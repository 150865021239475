import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { ConfigurationState } from '../_store/states/configuration.state';
import { Observable } from 'rxjs';
import { ConfigurationServerInterface } from '../_interfaces/configuration-server.interface';
import { ServerNamesEnum } from '../_enums/server-names.enum';

@Component({
    selector: 'app-preloader',
    templateUrl: './preloader.component.html',
    styleUrls: ['./preloader.component.scss'],
})
export class PreloaderComponent implements OnInit, OnDestroy {
    @Select(ConfigurationState.getConfigurationServer) configurationServer$: Observable<ConfigurationServerInterface>;

    @Input() fullSize: boolean;
    @Input() backgroundColor = 'transparent';
    @Input() isBlur: boolean;

    isMain: boolean;

    serverNamesEnum = ServerNamesEnum;

    constructor() {}

    ngOnInit(): void {
        if (this.fullSize) {
            const body = document.getElementsByTagName('body');
            body[0].setAttribute('style', 'overflow-y: hidden;');
        }
    }

    ngOnDestroy(): void {
        if (this.fullSize) {
            const body = document.getElementsByTagName('body');
            body[0].setAttribute('style', 'overflow-y: auto;');
        }
    }
}

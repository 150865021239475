import { CheckboxPropertiesDataInterface, ColumnTypeEnum, IconInterface } from '../../../app-shared-elements/_interfaces/ColumnsTable';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { LangEnum } from '../../../app-shared-elements/_enums/lang.enum';
import { UserProfileInfoTypeDateEnum } from '../../../profile/_enums/user-profile-info-date-time.enum';

export interface ConstantInterface {
    key: string;
    value: string | any;
    valueType: string;
    description: string;
    created: number;
    updated: number;
}

export interface ConstantRowsInterface extends Omit<ConstantInterface, 'value'> {
    title: string;
    columnType: ColumnTypeEnum;
    selectOptions:
        | SelectOptionInterface<LangEnum, LangEnum>[]
        | SelectOptionInterface<UserProfileInfoTypeDateEnum, string, UserProfileInfoTypeDateEnum>[]
        | SelectOptionInterface[];
    value: string | boolean;
    checkboxProperties?: {
        value: CheckboxPropertiesDataInterface;
    };
    postIcons: IconInterface<SystemSettingsIconActionEnum>[];
}

export enum SystemSettingsIconActionEnum {
    info = 'info',
}

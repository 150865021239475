import {TooltipStatusEnum} from './../_enums/tooltip-status.enum';
import {EventEmitter, Injectable} from '@angular/core';
import {NotificationsService} from './notifications.service';
import {Subscription} from 'rxjs';
import {SocketService} from './socket.service';
import {SocketEvent} from '../_enums/socket-event.enum';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class InviteService {

    public newInviteSubscription: EventEmitter<any> = new EventEmitter<any>();
    updateInviteSubscription: Subscription;
    constructor(
        private socketService: SocketService,
        private notificationService: NotificationsService,
        private translateService: TranslateService
    ) {
        this.updateInviteSubscription = socketService.fromEvent(SocketEvent.NEW_SHARE).subscribe((data: any) => {
            this.nextInviteSocket(data);
            this.newInviteSubscription.emit(data);
        });

    }

    async nextInviteSocket(data: { inviter: string, message: string, name: string }): Promise<void> {
        const message: string = await this.translateService.get('share.invite.inviteToaster', {name: data.name, inviter: data.inviter}).toPromise();
        this.notificationService.onEmit(TooltipStatusEnum.update, false, message);
    }

}

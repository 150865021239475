import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';
import {DropdownFilterOptionInterface} from '../../app-shared-elements/filters/interfaces/filter-option.interface';
import {TypeFilterEnum} from 'src/app/app-shared-elements/filters/enums/type-filter.enum';
import {LangService} from '../../app-shared-elements/_services/lang.service';
import {
    ColumnsTableInterface,
    ColumnTypeEnum,
    ColumnValueAlignEnum
} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import {
    ActionPage,
    JsonDataLogInterface,
    JsonTranslateData,
    TypeJsonPropertyLog
} from '../user-logs/_interface/user-logs.interface';
import {FilterDropdownUserLogKeyEnum} from '../_enums/filter-dropdown-key.enum';
import {DataTypeService} from "../../app-shared-elements/_services/data-type.service";


@Injectable({
    providedIn: 'root'
})
export class UsersLogsService {

    userLogTableColumns: ColumnsTableInterface[] = [
        {
            title: 'table.usersLogs.messages',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'message',
            isMultilang: true,
            align: ColumnValueAlignEnum.left,
            allowHtmlTags: true
        },
        {
            title: 'table.deviceLogs.deviceName',
            grow: true,
            small: false,
            maxWidth: '260px',
            minWidth: '160px',
            type: ColumnTypeEnum.text,
            name: 'deviceName',
            align: ColumnValueAlignEnum.left
        },
        {
            title: 'table.deviceLogs.internalId',
            grow: false,
            small: true,
            maxWidth: '240px',
            minWidth: '180px',
            type: ColumnTypeEnum.text,
            name: 'internalId'
        },
        {
            title: 'table.deviceLogs.id',
            grow: false,
            small: true,
            maxWidth: '240px',
            minWidth: '180px',
            type: ColumnTypeEnum.text,
            name: 'deviceId'
        },
        {
            title: 'table.usersLogs.actionPage',
            grow: false,
            small: false,
            maxWidth: '300px',
            type: ColumnTypeEnum.text,
            name: 'actionPage'
        },
        {
            title: 'table.usersLogs.meta',
            grow: false,
            small: true,
            maxWidth: '200px',
            minWidth: '180px',
            type: ColumnTypeEnum.text,
            name: 'metaData'
        },
        {
            title: 'table.usersLogs.ip',
            grow: false,
            small: true,
            maxWidth: '240px',
            minWidth: '140px',
            type: ColumnTypeEnum.text,
            name: 'userIp'
        },
        {
            title: 'table.usersLogs.action',
            grow: false,
            small: true,
            maxWidth: '85px',
            minWidth: '60px',
            type: ColumnTypeEnum.text,
            name: 'action'
        }
    ];

    usersTableColumns: ColumnsTableInterface[] = [
        {
            title: 'table.usersLogs.date',
            grow: false,
            small: false,
            sort: 0,
            maxWidth: '120px',
            minWidth: '100px',
            type: ColumnTypeEnum.date,
            name: 'created',
            sortField: 'created'
        },
        {
            title: 'table.usersLogs.userLogin',
            grow: false,
            small: false,
            sort: 0,
            maxWidth: '150px',
            minWidth: '150px',
            type: ColumnTypeEnum.text,
            name: 'userLogin',
            sortField: 'userLogin'
        },
        {
            title: 'table.usersLogs.shortMessages',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'shortMessage',
            isMultilang: true,
            align: ColumnValueAlignEnum.left,
            allowHtmlTags: true
        }
    ];

    dropDownFilterOptions: DropdownFilterOptionInterface[] = [
        {
            key: FilterDropdownUserLogKeyEnum.userLogin,
            value: 'logs.users.filtersDropdown.login',
            type: TypeFilterEnum.text,
            property: 'userLogin'
        },
        {
            key: FilterDropdownUserLogKeyEnum.userIp,
            value: 'logs.users.filtersDropdown.ip',
            type: TypeFilterEnum.text,
            property: 'userIp'
        },
        {
            key: FilterDropdownUserLogKeyEnum.internalId,
            value: 'logs.users.filtersDropdown.internalId',
            type: TypeFilterEnum.text,
            property: 'internalId'
        },
        {
            key: FilterDropdownUserLogKeyEnum.srcId,
            value: 'logs.users.filtersDropdown.srcId',
            type: TypeFilterEnum.text,
            property: 'srcId'
        },
    ];

    keyAction = 'user_logs.action';
    keyProperty = 'user_logs.property';
    keyEnum = 'user_logs.enum';
    keyEntity = 'user_logs.entity';

    constructor(
        private translateService: TranslateService,
        private langService: LangService,
        private dataTypeService: DataTypeService,
    ) {
    }

    async performDataMessage(actionPage: ActionPage, jsonProperty: JsonTranslateData): Promise<string> {
        const messages = await Promise.all((jsonProperty.property ?? []).map(async (item) => {
            return await this.translateService.get(`${this.keyProperty}.${item.keyTranslate}`, await this.translateProperty(actionPage, item.data)).toPromise();
        }));

        const key = `${this.keyAction}.${jsonProperty.keyTranslate}`;
        return await this.translateService.get(key, {
            ...await this.translateProperty(actionPage, jsonProperty.data),
            entityProperty: messages.join(', ')
        }).toPromise();
    }

    async translateProperty(actionPage: string, data: JsonDataLogInterface[]): Promise<any> {
        const d: any = {};
        if (!data) {
            return {};
        }
        data = Array.isArray(data) ? data : [data];
        for (const item of data) {
            const key = item.rootKey || `${this.keyEntity}.${actionPage}`;
            switch (item.type) {
                case TypeJsonPropertyLog.BOOL:
                    d[item.property] = await this.translateService.get(`${this.keyEnum}.${item.type}.${item.value}`).toPromise();
                    break;

                case TypeJsonPropertyLog.LANG:
                    try {
                        d[item.property] = this.langService.getCurrentValueFromNameLn(item.value);

                    } catch (e) {
                        d[item.property] = '';

                    }
                    break;

                case TypeJsonPropertyLog.STRING:

                    d[item.property] = item.isTranslate ? await this.translateService.get(`${key}.${item.value}`).toPromise() : String(item.value);

                    if (item.property === 'dateFrom' || item.property === 'dateTo') {
                        d[item.property] = this.dataTypeService.reformatDate(new Date(item.value).getTime() + 3600000 * 3);
                    }
                    break;

                case TypeJsonPropertyLog.TRANSLATE_VALUE:
                    d[item.property] = await this.translateService.get(`${key}._value.${item.key_Value}.${item.value}`).toPromise();
                    break;
                default:
                    d[item.property] = item.value;
            }
        }

        return d;
    }

    setDropDownFilter(data): void {

    }

}

import { DataTypeService } from 'src/app/app-shared-elements/_services/data-type.service';
import { ReportSettingsInterface } from '../_interfaces/ReportSettings';
import { ReportService } from '../_services/report.service';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormatPrintEnum } from '../_enums/format-print.type.enum';
import { Group } from 'src/app/app-shared-elements/_interfaces/Group';
import { ChartTypeEnum } from 'src/app/groups/_enums/chart-tpe.enum';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { EventLogsService } from '../../journals/_services/event-logs.service';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ConfigurationState } from 'src/app/app-shared-elements/_store/states/configuration.state';
import { LuxonParseDateService } from 'src/app/app-shared-elements/_services/luxon-parse-date.service';
import { DataSettingsForGenerateReport, TableDataForGenerateReport } from '../_interfaces/data-report.interface';
import { ConfigurationServerInterface } from 'src/app/app-shared-elements/_interfaces/configuration-server.interface';
import { ColumnsTableInterface, ColumnTypeEnum } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';

@Component({
    selector: 'app-generate-print',
    templateUrl: './generate-print.component.html',
    styleUrls: ['./generate-print.component.scss'],
})
export class GeneratePrintComponent implements OnInit {
    private _dateObj: any;
    private _reportSettings: ReportSettingsInterface;

    chartTypeEnum = ChartTypeEnum;

    usersTableColumns: ColumnsTableInterface[] = [
        {
            title: 'table.usersLogs.ip',
            grow: false,
            small: true,
            maxWidth: '80px',
            minWidth: '80px',
            type: ColumnTypeEnum.text,
            name: 'ip',
        },
        {
            title: 'table.usersLogs.typeClient',
            grow: false,
            small: true,
            maxWidth: '80px',
            minWidth: '80px',
            type: ColumnTypeEnum.text,
            name: 'typeClient',
        },
        {
            title: 'table.usersLogs.date',
            grow: false,
            small: false,
            maxWidth: '140px',
            minWidth: '120px',
            type: ColumnTypeEnum.date,
            name: 'created',
        },
        {
            title: 'table.usersLogs.userLogin',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'userLogin',
            maxWidth: '200px',
        },
        {
            title: 'table.usersLogs.messages',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'key',
        },
        {
            title: 'table.usersLogs.action',
            grow: false,
            small: false,
            sort: 0,
            maxWidth: '120px',
            type: ColumnTypeEnum.text,
            name: 'action',
        },
    ];

    gmt: string;

    chartWidth;
    pdfWidth;

    @Input()
    set reportSettings(reportSettings: ReportSettingsInterface) {
        this._reportSettings = reportSettings;
        if (reportSettings.isSplitTable) {
            this.uniqIds = Array.from(new Set(reportSettings.variablesId));
        }
        if (this.reportSettings) {
            this.getGtm();
            this.getChartWidth();
        }
    }

    get reportSettings(): ReportSettingsInterface {
        return this._reportSettings;
    }

    @Input() tableGroup: TableDataForGenerateReport;
    @Input() currentGroup: Group;

    @Input() data: DataSettingsForGenerateReport;

    @Input() isOnlyDsChart: boolean;

    @Input()
    set dateObj(dateObj) {
        this._dateObj = dateObj;
    }

    get dateObj(): any {
        return this._dateObj;
    }

    @Output() finishPrintEvent = new EventEmitter<void>();

    @Select(ConfigurationState.getConfigurationServer) configurationServer$: Observable<ConfigurationServerInterface>;

    start: number;
    end: number;
    groupId: number;
    isDataLimited;
    token: string;
    variables: any[];
    @Input() currentGroupName: string;

    isHide: boolean;

    @ViewChild('content') content: TemplateRef<any>;

    timeFilter = {
        dateFrom: Date.now() - 1000 * 60 * 60 * 0.5,
        dateTo: Date.now(),
        time: 3600000 / 2,
        type: 'time',
    };

    formatPrintEnum = FormatPrintEnum;
    @Input() isEmptyArchive: boolean;

    now = Date.now();
    // user = SocketService.user
    user: User;

    uniqIds: string[];

    constructor(
        public reportService: ReportService,
        public dataTypeService: DataTypeService,
        public eventLogsService: EventLogsService,
        private luxonParseDateService: LuxonParseDateService,
    ) {}

    getTdValue(row, td): string | number {
        // Object.keys(row)
        const kyes = Object.getOwnPropertyNames(row);

        const currentKey = kyes.find((key) => key === td.title);

        return row[currentKey];
    }

    getDate(ts): string {
        return this.dataTypeService.reformatDate(+ts);
    }

    async ngOnInit(): Promise<void> {
        console.log('ngOnInit generatePrint', this.reportSettings);
    }

    getGtm(): void {
        const offset = this.luxonParseDateService.getCurrentOffset(this.reportSettings.timezone) / 60;
        this.gmt = `GMT ${offset < 0 ? '-' : '+'}${offset}`;
    }

    getChartWidth(): void {
        switch (this.reportSettings.format) {
            case FormatPrintEnum.A4:
                this.chartWidth = 724;
                break;
            case FormatPrintEnum.A4Landscape:
                this.chartWidth = 1053;
                break;
        }
    }
}

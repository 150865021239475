<div class="column"
     [class.column--full-width]="isFullWidth"
>
    <div
        class="column-editor__svg">
        <svg-icon [src]="EDITOR_ICON_PATH"></svg-icon>
    </div>

    <div class="column-editor">
        <div *ngIf="!isEdit" class="column-editor__wrapper" (click)="setEdit()">
            <div class="column-editor__value">
                <span>{{value}}</span>

                <div *ngIf="cell?.postIcons && row?.postIcons && row?.postIcons.length"
                     class="column-editor__pre-icons column-editor__pre-icons--post">
                    <ng-container *ngFor="let icon of row.postIcons">
                        <div class="column-editor__pre-icons-item column-editor__pre-icons-item--post"
                             [class.column-editor__pre-icons-item--action]="icon.action">
                            <svg-icon [src]="icon.path" [svgStyle]="icon.styles"></svg-icon>

                            <div *ngIf="icon.tooltip && icon.tooltip.length"
                                 class="column-editor__pre-icons-tooltip column-editor__pre-icons-tooltip--post">
                                <app-tooltip [value]="icon.tooltip" [width]="'120px'"></app-tooltip>
                            </div>
                        </div>

                    </ng-container>

                </div>
            </div>
        </div>

        <div *ngIf="isEdit" class="column-editor__wrapper">
            <div class="column-editor__editor">
                <ng-container *ngIf="cell.editorType === columnEditorType.textarea">
                    <div class="column-editor__tag">

                        <ng-container *ngFor="let item of inputList">

                            <div class="column-editor__tag-item">
                                <app-input-constructor [label]="item.title"
                                                       [isWithoutInput]="item.isWithoutInput"
                                                       [disabled]="message.length > item.disableLength"
                                                       [type]="item.type"
                                                       (newValueEvent)="insertData($event)"></app-input-constructor>
                            </div>

                        </ng-container>

                    </div>
                    <div class="column-editor__textarea-wrapper">
                    <textarea #textarea class="column-editor__textarea"
                              [class.column-editor__textarea--error]="!message.length"
                              [(ngModel)]="message"
                              (input)="setCursorPosition(); isEdited = true"
                              [placeholder]="'events.messagesCatalog.placeholderName' | translate"
                              maxlength="250"
                              (click)="setCursorPosition()"></textarea>
                        <div class="column-editor__textarea-note"> {{'events.messagesCatalog.maxSymbols' | translate}}
                            250.
                            <span
                                [class.column-editor__textarea-note-error]="message.length > 250">{{250 - message.length}} {{'events.messagesCatalog.symbolsLeft' | translate}}</span>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="cell.editorType === columnEditorType.input">

                    <div class="column-editor__input-wrapper">
                        <input [(ngModel)]="message"
                               (input)="isEdited = true"
                               [placeholder]="'events.messagesCatalog.placeholderName' | translate"
                               maxlength="250"
                               type="text" class="input column-editor__input">
                    </div>

                </ng-container>
                <div class="column-editor__btns">
                    <button class="column-editor__btn btn btn--light" (click)="closeEdit()"
                            [translate]="'btns.cancel'"></button>
                    <button class="column-editor__btn btn btn--red"
                            [disabled]="!isEdited || !message.length || message.length > 250"
                            (click)="save()" [translate]="'btns.save'"></button>
                </div>
            </div>
        </div>
    </div>
</div>

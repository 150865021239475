import { ParamsFilterForClient, ParamsFilterTypeEnum, ParamsTimeTypeEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';

export const initialFilterMailingLogs: ParamsFilterForClient[] = [
    // date time
    {
        property: 'created',
        value: {
            to: null,
            from: null,
            time: null,
            type: ParamsTimeTypeEnum.ALL_TIME,
        },
        type: ParamsFilterTypeEnum.DATETIME,
    },
];

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthSharedModule } from './auth-shared.module';
import { AuthComponent } from './auth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { ForgotComponent } from './forgot/forgot.component';
import { ResetComponent } from './reset/reset.component';
import { AppSharedElementsModule } from '../app-shared-elements/app-shared-elements.module';
import { LoginGuardService } from './_guards/login-guard.service';
import { TranslateModule } from '@ngx-translate/core';
import { AdminAuthGuardService } from '../admin/_guards/admin-auth-guard.service';
import { UserAuthGuardService } from './user-auth-guard.service';
import { ConfirmComponent } from './confirm/confirm.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
    declarations: [
        AuthComponent,
        LoginComponent,
        RegistrationComponent,
        ForgotComponent,
        ResetComponent,
        ConfirmComponent,
        ChangePasswordComponent,
        AuthenticationComponent,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        RouterModule,
        FormsModule,
        AuthSharedModule,
        ReactiveFormsModule,
        AppSharedElementsModule,
        TranslateModule,
        AngularSvgIconModule.forRoot(),
    ],

    providers: [UserAuthGuardService, AdminAuthGuardService, LoginGuardService],
    exports: [TranslateModule],
})
export class AuthModule {}

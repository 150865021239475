import { Params } from '../../../../app-shared-elements/_interfaces/params.interface';

export class GetFinanceDebtors {
    static readonly type = '[FinanceDebtors] GetFinanceDebtors';
}

export class SetFinanceDebtorsParams {
    static readonly type = '[FinanceDebtors] SetFinanceDebtorsParams';

    constructor(public params: Params) {}
}

export class ClearParams {
    static readonly type = '[FinanceDebtors] ClearParams';
}

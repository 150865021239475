import { BehaviorSubject, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SocketService } from './socket.service';
import { ConnectEnum } from '../_enums/connect.enum';
import { Store } from '@ngxs/store';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { ApiResponse } from '../_interfaces/ApiRequest';
import { ArchiveChartInterface } from '../_interfaces/ArchiveChart';
import { HTTP_STATUS } from '../_enums/status.enum';
import { NotificationsService } from './notifications.service';
import { SetIsEmptyArchive } from 'src/app/groups/_store/actions/charts.actions';
import { UserState } from '../_store/states/user.state';
import { SetArchive } from '../_store/actions/archive.actions';

@Injectable({
    providedIn: 'root',
})
export class ArchiveService {
    // array of id's of registrators, which is loading archive right now
    private registratorsInArchiveLoadingState$ = new BehaviorSubject([]);
    archiveRegistratorsList$ = this.registratorsInArchiveLoadingState$.asObservable();

    private isVaraiblesDataSubscribed = false;
    private connectSubscribe: Subscription;

    constructor(
        private httpClient: HttpClient,
        private socketService: SocketService,
        private notificationsService: NotificationsService,
        private store: Store,
    ) {
        this.connectSubscribe = this.socketService.errorConnectSubscribe.subscribe((status) => {
            switch (status) {
                case ConnectEnum.FIRST_CONNECT:
                case ConnectEnum.RECONNECTED:
            }
        });
    }

    async getArchive(options: { variables: string[]; start: number; end: number; isDisabledLimited?: boolean; isAdmin?: boolean }): Promise<ArchiveChartInterface> {
        const currentUserId = this.store.selectSnapshot(UserState.getAdminUserId);

        const params = new HttpParams()
            .set('variables', options.variables.join(','))
            .set('start', String(Math.floor(options.start)))
            .set('end', String(Math.floor(options.end)))
            .set('isDisabledLimited', String(Number(!!options.isDisabledLimited)));

        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);

        let headers;

        if (currentUserId) {
            headers = new HttpHeaders({ registratorId: currentRegistrator ? currentRegistrator.id : '' }).set('currentUserId', currentUserId);
        } else {
            headers = new HttpHeaders({ registratorId: currentRegistrator ? currentRegistrator.id : '' });
        }
        const result: ApiResponse = (await this.httpClient
            .get('/api/archive/chart', {
                params,
                headers,
            })
            .toPromise()) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.store.dispatch(new SetArchive(result.data));
            return result.data;
        } else if (result.status === HTTP_STATUS.ARCHIVE_PERIOD_HISTORY_IS_EMPTY) {
            this.store.dispatch(new SetIsEmptyArchive({ isError: true, message: 'charts.noArchiveStory' }));
        } else {
            this.store.dispatch(new SetIsEmptyArchive({ isError: true, message: 'charts.serverError' }));
        }
    }

    async getEventLog(options: { variables: string[]; start: number; end: number; isDisabledLimited?: boolean }): Promise<ArchiveChartInterface> {
        const params = new HttpParams()
            .set('variables', options.variables.join(','))
            .set('start', String(options.start))
            .set('end', String(options.end))
            .set('isDisabledLimited', String(Number(!!options.isDisabledLimited)));

        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);

        let headers;
        const currentUserId = this.store.selectSnapshot(UserState.getAdminUserId);

        if (currentUserId) {
            headers = new HttpHeaders({ registratorId: currentRegistrator ? currentRegistrator.id : '' }).set('currentUserId', currentUserId);
        } else {
            headers = new HttpHeaders({ registratorId: currentRegistrator ? currentRegistrator.id : '' });
        }

        const result: ApiResponse = (await this.httpClient
            .get('/api/archive/chart-event-log', {
                params,
                headers,
            })
            .toPromise()) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            return result.data as ArchiveChartInterface;
        } else {
            this.store.dispatch(new SetIsEmptyArchive({ isError: true, message: 'charts.serverError' }));
        }
    }

    async getChartBinary(options: { variables: string[]; start: number; end: number }) {
        const params = new HttpParams().set('variables', options.variables.join(',')).set('start', String(options.start)).set('end', String(options.end));

        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);

        let headers;
        const currentUserId = this.store.selectSnapshot(UserState.getAdminUserId);

        if (currentUserId) {
            headers = new HttpHeaders({ registratorId: currentRegistrator ? currentRegistrator.id : '' }).set('currentUserId', currentUserId);
        } else {
            headers = new HttpHeaders({ registratorId: currentRegistrator ? currentRegistrator.id : '' });
        }

        const result: ApiResponse = (await this.httpClient
            .get('/api/archive/chart-binary', {
                params,
                headers,
            })
            .toPromise()) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            return result.data;
        }
    }
}

<div id="content" class="content"
     [class.content--fullscreen]="fullscreen$ | async"
     [class.content--display-height]="displayHeight$ | async"
     [class.content--ischart]="isChart$ | async"
>
    <div class="content__wrapper"
         [class.content__wrapper--fullscreen]="fullscreen$ | async"
    >
        <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
</div>

export class ChannelInterface {
    name: string;
    channelId: string;
    type: ChannelTypeEnum;
    message: string;
    date: Date;
    numberOfUnconfirmed: number;
    isRead: boolean;
}

export enum ChannelTypeEnum {
    SYSTEM = 'SYSTEM',
    DEVICE_SHARING = 'DEVICE_SHARING',
    DEVICE_MESSAGE = 'DEVICE_MESSAGE',
    MALLING_SHARING = 'MALLING_SHARING',
    MNEMONIC_SHARING = 'MNEMONIC_SHARING',
}

<div class="login login--reset">
    <div class="login__wrapper">
        <ng-container *ngIf="!success">
            <h2 class="login__title" [translate]="'auth.forgot.title'"></h2>
            <form class="login__form" [formGroup]="form" novalidate>
                <div class="login__form-item">
                    <label class="login__form-label" [translate]="'auth.forgot.login'"></label>
                    <input class="login__form-input"
                           placeholder="{{'auth.forgot.email' | translate}}"
                           type="text"
                           name="login"
                           formControlName="email">
                    <p class="login__form-error"
                       *ngIf="form.controls['email'].invalid && form.controls['email'].touched || noSuchEmail"
                       [translate]="'auth.forgot.error'"></p>

                </div>

                <div class="login__form-activity">
                    <div class="login__form-activity-item">
                        <div class="login__form-activity-column">
                            <button (click)="resetPassword()"
                                    [disabled]="!form.valid || !form.controls['email'].valid"
                                    type="button"
                                    class="login__form-sign-btn"
                                    [translate]="'auth.forgot.reset'"></button>
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>

        <ng-container *ngIf="success">
            <h2 class="login__title" [translate]="'auth.forgot.successFullTitle'"></h2>
            <div class="login__message" [translate]="'auth.forgot.successFullMessage'"></div>
            <button (click)="moveToLoginPage()" class="login__form-sign-btn" [translate]="'btns.ok'"></button>
        </ng-container>
        <div *ngIf="!success" class="login__move">
            <span [translate]="'auth.forgot.question'"></span>
            <span (click)="moveToLoginPage()" [translate]="'auth.forgot.signIn'"></span>
        </div>
    </div>
</div>

import {TranslateService} from '@ngx-translate/core';
import {EventEmitter, Injectable} from '@angular/core';
import {Language} from '../_interfaces/Language';
import {LanguageValue} from '../_interfaces/LanguageValue';
import {first} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LangService {
    private langs = ['en', 'ua'];
    private currentLanguage: string;
    public langChangeEvent = new EventEmitter<any>();

    constructor(private translateService: TranslateService) {
        this.currentLanguage = this.translateService.currentLang;

        this.translateService.onLangChange.subscribe(lang => {
            this.currentLanguage = lang.lang || this.translateService.currentLang;
            this.langChangeEvent.emit();
        });
    }

    public get(language: Language | string, lang?): LanguageValue|any {
        if (typeof language === 'string') {
            return language;
        }

        const currentLang = lang ? lang : this.currentLanguage;
        const languageValue = language.values.find(item => item.lang === currentLang);

        if (languageValue) {
            return languageValue;
        } else {
            const engNameItem = language.values.find(item => item.lang === 'en');
            return engNameItem ? engNameItem : {};
        }
    }

    public getLangs(): string[] {
        return this.langs;
    }

    public getCurrentLang(): string {
        return this.currentLanguage;
    }

    async translateString(str, interpolateParams?): Promise<string> {
        let result: string;
        this.translateService.get(str, interpolateParams).pipe(first()).subscribe(data => {
            result = data;
        });

        return result;
    }

    getCurrentValueFromNameLn(nameLn): string {
        if (typeof nameLn === 'string') {
            return nameLn;
        }

        let result: string;
        if (!nameLn) { return ''; }

        nameLn.values.map(item => {
            if (item.lang === this.currentLanguage) {
                result = item.value;
            }
        });

        return result;
    }

    getValueFromNameLn(nameLn, lang): string {
        let result: string;
        nameLn.values.map(item => {
            if (item.lang === lang) {
                result = item.value;
            }
        });

        return result;
    }

    setValueFromNameLn(nameLn, value, lang): string {
        nameLn.values.map(item => {
            if (item.lang === lang) {
                item.value = value;
            }
        });

        return nameLn;
    }
}

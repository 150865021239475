import {AddMailingReportInterfaceDto, NotificationReportUpdateDto} from '../../_interfaces/mailing-reports.interfaces';

export class GetMailingReport {
    static readonly type = '[MailingReport] GetMailingReport';

    constructor(public groupId: string) {
    }
}

export class AddMailingReport {
    static readonly type = '[MailingReport] AddMailingReport';

    constructor(public data: AddMailingReportInterfaceDto) {
    }
}


export class InitMailingReportRows {
    static readonly type = '[MailingReport] InitMailingReportRows';
}

export class InitGroupEditReportsPopupRows {
    static readonly type = '[MailingReport] InitGroupEditReportsPopupRows';

}

export class DeleteReportInGroupEdit {
    static readonly type = '[MailingReport] DeleteReportInGroupEdit';

    constructor(public id: string) {

    }

}

export class UpdateActivityReportInGroupEdit {
    static readonly type = '[MailingReport] UpdateActivityReportInGroupEdit';

    constructor(public data: NotificationReportUpdateDto) {

    }

}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements OnInit {
    @Input() isDisabled: boolean;
    @Input() value: boolean;
    @Input() title: string;
    @Input() isFreeze: boolean;
    @Input() isWaiting: boolean;
    @Input() isError: boolean;

    readonly CHECKBOX_PATH = './assets/design/icons/checkbox.svg';

    @Output() changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {}

    ngOnInit(): void {}

    change(): void {
        this.value = !this.value;
        this.changeEvent.emit(this.value);
    }
}

<div class="user-device"
     [class.user-device--pagination]="isPagination && configPagination && configPagination.totalItems > 5"
>
    <app-filters
        [type]="filterItemTypeEnum.event"
        [filter]="filter ? filter : (params$ | async)?.filter"
        [optionsFoDropDownSelect]="currentOptionsForDropDownFilter"
        [currentOptionKeyForDropdownSelect]="companiesOptions?.length ? currentOptionKeyForDropdown : null"
        [defaultDropdownValue]="defaultDropdownValue"
        [optionsDropDown]="optionsDropDown"
        [isBtns]="typeClient === typeClientModel.User"
        [isBtnDisabled]="!ownerDevices.length"
        [btnEditTitle]="'btns.create'"
        [btnDisabledTooltip]="'customDevices.tooltipMessage'"
        (editBtnsEvent)="createNewVirtualDevice()"
        (filterEvent)="changeFilter($event)"
        (chooseFilterEvent)="chooseFilter($event)"></app-filters>

    <div *ngIf="invites.length" class="user-profile__invite">
        <ng-container *ngFor="let invite of invites">
            <app-invite [invite]="invite" (closeInviteEvent)="refresh();"></app-invite>
        </ng-container>
    </div>

    <div>
        <app-malling-invite></app-malling-invite>
    </div>

    <div class="table"
         [class.table--pagination]="isPagination && configPagination && configPagination.totalItems > 5"
         [class.table--empty]="!userDevicesArray.length && invites.length">
        <div class="table__header">
            <ng-container *ngFor="let item of deviceTableColumns">
                <div class="table__header-item"
                     [style.maxWidth]="item.maxWidth"
                     [class.table__header-item--small]="item.small"
                     [style.minWidth]="item.minWidth"
                     [class.table__header-item--grow]="item.grow">
                    <div class="table__header-item--title">
                        {{ item.title | translate }}
                    </div>
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="(isSkeleton$ | async); else tableContent">
            <ngx-skeleton-loader
                [theme]="{ width: '100%', height: '36px', margin: '1px 0 -1px 0', padding: 0, backgroundColor: 'var(--backgroundSkeleton)' }"
                count="20"></ngx-skeleton-loader>
        </ng-container>
        <ng-template #tableContent>

            <div class="table__body" cdkDropList
                 (cdkDropListDropped)="drop($event)"
                 [cdkDropListData]="userDevicesArray">
                <ng-container
                    *ngFor="let item of (!isPagination ? userDevicesArray : userDevicesArray | paginate: configPagination) | filterBySmth: {type: filterType, comparedValue: searchFilter}; trackBy: trackById; let i = index">
                    <div class="table__item"
                         cdkDrag
                         [cdkDragDisabled]="typeClient === typeClientModel.Admin">

                        <ng-container>
                            <div *ngIf="item.registrator.isVisible" class="table__item-registrator"
                                 [class.table__item--isActive]="!item.registrator.isActive"
                                 [class.table__item--isActivekiosk]="!item.registrator.isKioskMode && !item.registrator.isActive && item.registrator.registratorType !== registratorTypeEnum.docker"
                                 [class.table__item-registrator--isActive]="!item.registrator.isActive">


                                <div class="table__item-cell table__item-cell-type"
                                     [class.table__item-cell-type--off]="!item.registrator.isActive">

                                    <div *ngIf="typeClient !== typeClientModel.Admin"
                                         class="table__item-drag"
                                         cdkDragHandle>

                                        <svg-icon [src]="userDeviceIconEnum.drag"></svg-icon>

                                    </div>

                                    <div class="table__item-tooltip"
                                         [class.table__item-tooltip--kiosk]="item.registrator.isKioskMode"
                                         [class.table__item-tooltip--error]="!item.registrator.isConnect && !item.registrator.isSleepStatus"
                                         [class.table__item-tooltip--kioskError]="item.registrator.isTransportMode && item.registrator.creationType === creationType.ORIGIN && !item.registrator.isKioskMode
                                          && !item.registrator.isConnect && !item.registrator.isSleepStatus"
                                    >

                                        <ng-container *ngIf="item.registrator.isWorkExpedition; else transportMode">
                                            <svg-icon [src]="EXPEDITION_ON_ICON_PATH"></svg-icon>
                                        </ng-container>

                                        <ng-template #transportMode>
                                            <ng-container
                                                *ngIf="item.registrator.isTransportMode && item.registrator.creationType === creationType.ORIGIN;
                                             else registratorIcon">
                                                <svg-icon
                                                    [src]="item.registrator.isKioskMode ? TRANSPORT_KIOSK_ICON_PATH : TRANSPORT_ICON_PATH"></svg-icon>
                                            </ng-container>

                                            <ng-template #registratorIcon>

                                                <ng-container *ngIf="!item.registrator.isKioskMode; else kioskMode">

                                                    <ng-container [ngSwitch]="item.registrator.creationType">

                                                        <ng-template [ngSwitchCase]="creationType.ORIGIN">

                                                            <ng-container [ngSwitch]="item.registrator.registratorType">
                                                                <ng-template
                                                                    [ngSwitchCase]="registratorTypeEnum.android">
                                                                    <svg-icon
                                                                        [src]="REGISTRATOR_ICON_PATH"></svg-icon>
                                                                </ng-template>

                                                                <ng-template
                                                                    [ngSwitchCase]="registratorTypeEnum.docker">
                                                                    <svg-icon
                                                                        [src]="DOCKER_ICON_PATH"></svg-icon>
                                                                </ng-template>

                                                                <ng-template
                                                                    [ngSwitchCase]="registratorTypeEnum.datalogger">
                                                                    <svg-icon
                                                                        [src]="userDeviceIconEnum.datalogger"></svg-icon>
                                                                </ng-template>

                                                                <ng-template ngSwitchDefault>
                                                                    <svg-icon
                                                                        [src]="DOCKER_ICON_PATH"></svg-icon>
                                                                </ng-template>
                                                            </ng-container>

                                                        </ng-template>

                                                        <ng-template [ngSwitchCase]="creationType.VIRTUAL">

                                                            <svg-icon [src]="VIRTUAL_REGISTRATOR_ICON_PATH"></svg-icon>

                                                        </ng-template>

                                                    </ng-container>

                                                </ng-container>

                                                <ng-template #kioskMode>
                                                    <svg-icon [src]="REGISTRATOR_KIOSK_ICON_PATH"></svg-icon>
                                                </ng-template>

                                            </ng-template>
                                        </ng-template>

                                        <span class="item__devices"
                                              [translate]="item.registrator.isActive ? 'devices.on' : 'devices.off'"
                                              [class.item__devices--off]="!item.registrator.isActive"></span>
                                        <div class="table__tooltip">

                                            <ng-container
                                                *ngIf="item.registrator.isWorkExpedition; else transportModeTooltip">
                                                <app-tooltip
                                                    [template]="true">
                                                    <span [translate]="'devices.tooltipExpedition'"></span>
                                                </app-tooltip>
                                            </ng-container>

                                            <ng-template #transportModeTooltip>
                                                <ng-container
                                                    *ngIf="!item.registrator.isKioskMode; else kioskModeTooltip">
                                                    <app-tooltip
                                                        *ngIf="item.registrator.creationType === creationType.ORIGIN"
                                                        [template]="true">

                                                <span [ngSwitch]="item.registrator.type">
                                                    <ng-template
                                                        [ngSwitchCase]="deviceTypeEnum.registrator">

                                                        <ng-container [ngSwitch]="item.registrator.registratorType">

                                                            <ng-template [ngSwitchCase]="registratorTypeEnum.android">
                                                                    <span [translate]="'devices.tooltipAndroid'"></span>
                                                                </ng-template>

                                                                <ng-template
                                                                    [ngSwitchCase]="registratorTypeEnum.docker">
                                                                    <span [translate]="'devices.tooltipDocker'"></span>
                                                                </ng-template>

                                                                <ng-template
                                                                    [ngSwitchCase]="registratorTypeEnum.datalogger">
                                                                    <span
                                                                        [translate]="'devices.tooltipDatalogger'"></span>
                                                                </ng-template>

                                                            <ng-template ngSwitchDefault>
                                                                <span [translate]="'devices.tooltipDocker'"></span>
                                                            </ng-template>

                                                        </ng-container>
                                                    </ng-template>
                                                </span>
                                                    </app-tooltip>
                                                    <app-tooltip
                                                        *ngIf="item.registrator.creationType === creationType.VIRTUAL"
                                                        [value]="'devices.tooltipVDevice'">
                                                    </app-tooltip>
                                                </ng-container>

                                                <ng-template #kioskModeTooltip>
                                                    <app-tooltip
                                                        [template]="true">
                                                        <span [translate]="'devices.tooltipKioskMode'"></span>
                                                    </app-tooltip>
                                                </ng-template>
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>

                                <div class="table__item-cell table__item-cell-name"
                                     [class.table__item-cell-name--virtual]="item.registrator.creationType === creationType.VIRTUAL && (isAdmin$ | async)"
                                     [class.table__item-cell-name--isActive]="!item.registrator.isActive">
                                    <span (click)="openDevice(item.registrator)">{{ item.registrator.name }}</span>

                                    <div
                                         class="table__item-icon">
                                        <ng-container *ngIf="item.registrator.savedStatusEvent === eventSaveStatus.PROCESS && +item.registrator.errorStatusEvent >= 0 || item.registrator.savedStatusDevice">
                                            <svg-icon [src]="WAIT_ICON_PATH"></svg-icon>
                                            <div class="table__tooltip">
                                                <app-tooltip [template]="true">

                                                    <ng-container *ngIf="item.registrator.savedStatusDevice && !item.registrator.savedStatusEvent">
                                                        <span [translate]="'devices.tooltipWaitStatusDevice'"></span>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.registrator.savedStatusEvent && !item.registrator.savedStatusDevice">
                                                        <span [translate]="'devices.tooltipWaitStatusEvent'"></span>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.registrator.savedStatusEvent && item.registrator.savedStatusDevice">
                                                        <span [translate]="'devices.tooltipAllStatusEvent'"></span>
                                                    </ng-container>
                                                </app-tooltip>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="item.registrator.savedStatusEvent === eventSaveStatus.ERROR || +item.registrator.errorStatusEvent < 0 || item.registrator.errorStatusDevice">
                                            <svg-icon [src]="ERROR_ICON_PATH"></svg-icon>

                                            <div class="table__tooltip">
                                                <app-tooltip [template]="true">

                                                    <ng-container *ngIf="item.registrator.errorStatusDevice && !item.registrator.errorStatusEvent">
                                                        <span [translate]="'devices.tooltipErrorStatusDevice'"></span>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.registrator.errorStatusEvent && !item.registrator.errorStatusDevice || +item.registrator.errorStatusEvent < 0 && !item.registrator.errorStatusDevice ">
                                                        <span [translate]="'devices.tooltipErrorStatusEvent'"></span>
                                                    </ng-container>
                                                    <ng-container *ngIf="item.registrator.errorStatusDevice && item.registrator.errorStatusEvent">
                                                        <span [translate]="'devices.tooltipAllStatusDevice'"></span>
                                                    </ng-container>
                                                </app-tooltip>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div
                                        *ngIf="item.registrator.syncStatus !== null && item.registrator.syncStatus !== registratorSyncStatus.IS_SYNC"
                                        class="table__name-settings">

                                        <div [ngSwitch]="item.registrator.syncStatus">
                                            <ng-template [ngSwitchCase]="registratorSyncStatus.NOT_SYNC">
                                                <div class="table__name-unsync">
                                                    <span (click)="openSyncPopup(item.registrator)">

                                                        <svg-icon [src]="userDeviceIconEnum.sync"></svg-icon>
                                                    </span>

                                                    <div class="table__unsync-tooltip">
                                                        <app-tooltip
                                                            [value]="'devices.deviceUnSyncTooltip'"></app-tooltip>
                                                    </div>
                                                </div>
                                            </ng-template>

                                            <ng-template [ngSwitchCase]="registratorSyncStatus.IN_SYNC">
                                                <div class="table__spinner"></div>
                                            </ng-template>
                                        </div>


                                    </div>

                                    <div *ngIf="item.registrator.isDeleted && typeClient === typeClientModel.Admin"
                                         class="table__name-settings">

                                        <div class="table__name-deleted">

                                            <div class="table__name-item">
                                                <svg-icon [src]="userDeviceIconEnum.deleted"></svg-icon>

                                                <span [translate]="'devices.deviceDeleted'"></span>
                                            </div>

                                            <div class="table__deleted-tooltip">
                                                <app-tooltip [template]="true">

                                                    <span [translate]="'devices.deviceDeletedTooltip'"
                                                          [translateParams]="{date: item.registrator.deleted | luxonDate :user?.dateFormat || dataTypeService.dateFormat
                                    : user?.dateTimeZone || dataTypeService.GMT
                                    : (ln$ | async)}"></span>

                                                </app-tooltip>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div (click)="openCurrentUser(item.registrator)"
                                     class="table__item-cell table__item-cell-owner"
                                     [class.table__item-cell-owner--pointer]="(isAdmin$ | async) && router.url.includes('control/devices')"
                                >
                                    <div class="table__item-content"
                                         [class.table__item--isActive]="!item.registrator.isActive">

                                        <span *ngIf="user?.login === item.registrator.login"
                                              [translate]="'profile.me'"></span>
                                        <span
                                            *ngIf="user?.login !== item.registrator.login">{{ item.registrator.login.split('@')[0] + '@' }}</span>

                                        <div class="table__tooltip">
                                            <app-tooltip [template]="true" [width]="'auto'" [maxWidth]="'initial'">
                                                {{ item.registrator.login }}
                                            </app-tooltip>
                                        </div>
                                    </div>
                                </div>

                                <div class="table__item-cell table__item-cell-connect"
                                     [class.table__item-cell-connect--isActive]="!item.registrator.isActive"
                                     [class.table__item-cell-connect--off]="!item.registrator.isConnect"
                                     [class.table__item-cell-connect--on]="item.registrator.isConnect"
                                     [class.table__item-cell-connect--sleep]="item.registrator.sleepStatus"
                                >

                                    <ng-container *ngIf="item.registrator.sleepStatus; else active">
                                        sleep
                                    </ng-container>

                                    <ng-template #active>
                                        <ng-container *ngIf="!item.registrator.isActive; else connect"
                                        >Na
                                        </ng-container>

                                        <ng-template #connect>
                                        <span *ngIf="item.registrator.creationType === creationType.ORIGIN"
                                              [translate]="item.registrator.isConnect ? 'online' : 'offline'"></span>
                                            <span
                                                *ngIf="item.registrator.creationType === creationType.VIRTUAL">-</span>
                                        </ng-template>
                                    </ng-template>
                                </div>

                                <div class="table__item-cell table__item-cell-signal"
                                     [class.table__item-cell-signal--isActive]="!item.registrator.isActive"
                                     [class.table__item-cell-signal--ok]="item.registrator.signal <= 0 && item.registrator.signal >= -60"
                                     [class.table__item-cell-signal--attention]="item.registrator.signal < -60 && item.registrator.signal >= -75"
                                     [class.table__item-cell-signal--alarm]="item.registrator.signal < -75"
                                     [class.table__item-cell-signal--off]="!item.registrator.isActive || !item.registrator.isConnect">
                                    <ng-container
                                        *ngIf="!item.registrator.isConnect || !item.registrator.isActive; else signal">
                                        Na
                                    </ng-container>

                                    <ng-template #signal>
                                        <span [ngSwitch]="item.registrator.signal">
                                            <ng-template [ngSwitchCase]="'-'">-</ng-template>
                                            <ng-template
                                                [ngSwitchCase]="'PS'">{{ item.registrator.signal }}</ng-template>
                                            <ng-template [ngSwitchCase]="'Na'">Na</ng-template>
                                            <ng-template
                                                [ngSwitchCase]="'NA'">{{ item.registrator.signal }}</ng-template>
                                            <ng-template
                                                [ngSwitchCase]="'eth'">{{ item.registrator.signal }}</ng-template>
                                            <ng-template
                                                [ngSwitchCase]="'Incorrect'">{{ item.registrator.signal }}</ng-template>
                                            <ng-template ngSwitchDefault>
                                                <span>{{ item.registrator.signal }}</span>
                                                <span [translate]="'devices.signalStatus'"></span>
                                            </ng-template>
                                        </span>

                                    </ng-template>
                                </div>

                                <div class="table__item-cell table__item-cell-battery"
                                     [class.table__item-cell-battery--isActive]="!item.registrator.isActive"
                                     [class.table__item-cell-battery--ok]="item.registrator.battery > 30"
                                     [class.table__item-cell-battery--attention]="item.registrator.battery <= 30 && item.registrator.battery > 15"
                                     [class.table__item-cell-battery--alarm]="item.registrator.battery <= 15"
                                     [class.table__item-cell-battery--off]="!item.registrator.isActive || !item.registrator.isConnect">

                                    <ng-container
                                        *ngIf="!item.registrator.isActive || !item.registrator.isConnect; else battery">
                                        Na
                                    </ng-container>
                                    <ng-template #battery>
                                        <span [ngSwitch]="item.registrator.battery">
                                            <ng-template [ngSwitchCase]="'-'">-</ng-template>
                                            <ng-template
                                                [ngSwitchCase]="'PS'">{{ 'devices.batteryStatus' | translate }}</ng-template>
                                            <ng-template
                                                [ngSwitchCase]="'Na'">{{ 'devices.batteryStatusNa' | translate }}</ng-template>
                                            <ng-template
                                                ngSwitchDefault>{{ item.registrator.battery + '%' }}</ng-template>
                                        </span>

                                    </ng-template>
                                </div>

                                <div class="table__item-cell table__item-cell-status"
                                     [class.table__item-cell-status--isActive]="!item.registrator.isActive"
                                     [class.table__item-cell-status--alarm]="item.registrator.status === alarmTypeEnum.alarm"
                                     [class.table__item-cell-status--attention]="item.registrator.status === alarmTypeEnum.attention"
                                     [class.table__item-cell-status--ok]="item.registrator.status === alarmTypeEnum.ok"
                                     [class.table__item-cell-status--initialization]="item.registrator.status === alarmTypeEnum.initialization
                                        || !item.registrator.isConnect || !item.registrator.isActive"
                                     [class.table__item-cell-status--na]="(!item.registrator.isConnect || !item.registrator.isActive) && item.registrator.creationType === creationType.ORIGIN"
                                     (click)="moveToSmth(item.registrator)">

                                    <ng-container [ngSwitch]="item.registrator.status">
                                        <ng-template [ngSwitchCase]="alarmTypeEnum.ok">
                                            {{ 'events.configurator.ok' | translate }}
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="alarmTypeEnum.alarm">
                                            {{ 'events.configurator.alert' | translate }}
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="alarmTypeEnum.attention">
                                            {{ 'events.configurator.attention' | translate }}
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="alarmTypeEnum.initialization">
                                            <div class="table__spinner"></div>
                                        </ng-template>

                                    </ng-container>


                                </div>

                                <div class="table__item-cell table__item-cell-lastAct"
                                     [class.table__item-cell-lastAct--isActive]="!item.registrator.isActive">
                                    <span
                                        *ngIf="!item.registrator.lastActive || item.registrator.creationType === creationType.VIRTUAL; else lastActive"
                                        class="table__item--isActive">Na</span>

                                    <ng-template #lastActive>
                                        {{
                                            item.registrator.lastActive | luxonDate :user?.dateFormat || dataTypeService.dateFormat
                                                : user?.dateTimeZone || dataTypeService.GMT
                                                : (ln$ | async)
                                        }}
                                    </ng-template>
                                </div>

                                <div *ngIf="item.listAction"
                                     class="table__item-cell table__item-cell-settings"
                                     appClickOutside
                                     [isActiveClickOutside]="item.isActionRegistrator"
                                     (clickOutside)="item.isActionRegistrator = false"
                                     (click)="item.isActionRegistrator = !item.isActionRegistrator">

                                    <svg-icon [src]="userDeviceIconEnum.setting"></svg-icon>

                                    <div *ngIf="item.isActionRegistrator" class="table__settings">
                                        <app-actions-list [list]="item.listAction"
                                                          (actionEmitter)="parseAction($event, item)"></app-actions-list>
                                    </div>
                                </div>

                            </div>
                        </ng-container>

                        <div class="table__item-body"
                             cdkDropList
                             (cdkDropListDropped)="dropDevices($event, item.devices)"
                             [cdkDropListData]="item.devices">

                            <ng-container *ngFor="let device of item.devices; trackBy: trackByDeviceId">

                                <ng-container *ngIf="device && device.isVisible">

                                    <app-devices [device]="device"
                                                 [registrator]="item.registrator"
                                                 [typeClient]="typeClient"
                                                 [isShowFilter]="isShowFilter"
                                                 (openCurrentUserEvent)="openCurrentUser($event)"
                                                 (openDeviceEvent)="openDevice($event)"
                                                 (moveToSmthEvent)="moveToSmth($event)"
                                                 (parseActionEvent)="parseActionDevices($event)"
                                    ></app-devices>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>

            </div>

            <ng-container *ngIf="tableService.emptyTableAfterFilter$ | async">
                <h1 class="table__empty table__empty--filter" [translate]="'devices.filterEmpty'"></h1>
            </ng-container>
            <ng-container *ngIf="!(tableService.emptyTableAfterFilter$ | async) && !userDevicesArray.length">
                <h1 class="table__empty table__empty--devices" [translate]="'devices.empty'"></h1>
            </ng-container>

        </ng-template>

    </div>

    <ng-container
        *ngIf="isPagination && configPagination && configPagination.totalItems > 5 && userDevicesArray && userDevicesArray.length">

        <app-pagination [configPagination]="configPagination"
                        [customCountOption]="customCountOption"
                        (paginationOutput)="changePage($event)"></app-pagination>

    </ng-container>

</div>


export enum SocketUserStatusEnum {
    OK = 0,
    error = -1,
    tokenNotAllowed = -2,
    limitConnectionError = -3,
    unlockActiveSession= -4,
    credentialsError = -5,
    lockActiveSession = -6,
    logoutUser = -7,
    notLogin = -8,
    clientAlreadyConnected = -9,

    user_is_deleted = -10,
    user_email_not_confirmed = -11,
    user_not_confirmed_by_admin = -12,
    user_not_active = -13,

}

export enum MethodLogin{
    LOCAL = 'local',
    GOOGLE = 'google'
}

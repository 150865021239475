import { Injectable } from '@angular/core';
import {
    CheckboxPropertiesDataInterface,
    CheckboxPropertiesInterface,
    ColumnModeEnum,
    ColumnsTableInterface,
    IconInterface,
} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { UnitInterface } from 'src/app/app-shared-elements/_interfaces/unit.interface';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { AlarmTypeEnum } from '../../events/_enums/alarm.enum';
import { DetailsDeviceIconActionEnum } from '../_interface/details-device.interface';
import { VirtualDeviceInfoInterface } from '../_interface/virtual-device-info.interface';
import { SelectOptionInterface } from '../../app-shared-elements/_interfaces/select-option.interface';
import { VariableGroupSettings } from 'src/app/app-shared-elements/_interfaces/VariableGroupSettings';
import { PermissionsState } from '../../app-shared-elements/_store/states/permissions.state';
import { Store } from '@ngxs/store';

@Injectable({
    providedIn: 'root',
})
export class DetailsDeviceService {
    virtualDeviceInfo: VirtualDeviceInfoInterface[] = [
        {
            title: 'detailsDevice.originRegistrator',
            name: 'originRegistratorId',
        },
        {
            title: 'detailsDevice.originVariable',
            name: 'originVariableId',
        },
        {
            title: 'detailsDevice.serverId',
            name: 'id',
        },
        {
            title: 'detailsDevice.internalId',
            name: 'internalId',
        },
        {
            title: 'detailsDevice.deviceId',
            name: 'deviceId',
        },
        {
            title: 'detailsDevice.comments',
            name: 'comment',
        },
    ];

    originalDeviceInfo: VirtualDeviceInfoInterface[] = [
        {
            title: 'detailsDevice.serverId',
            name: 'id',
        },
        {
            title: 'detailsDevice.internalId',
            name: 'internalId',
        },
        {
            title: 'detailsDevice.deviceId',
            name: 'deviceId',
        },
        {
            title: 'detailsDevice.comments',
            name: 'comment',
        },
    ];

    constructor(private store: Store) {}

    getUnitOptions(units: UnitInterface[]): SelectOptionInterface<string, string, { cellName: string; data: UnitInterface }>[] {
        const options: SelectOptionInterface<string, string, { cellName: string; data: UnitInterface }>[] = [];
        units.map((u) => {
            options.push({
                key: u.id,
                value: u.unit,
                type: 'text',
                property: { cellName: 'unit', data: u },
            });
        });

        const placeholder = {
            key: null,
            value: 'detailsDevice.placeholderForChooseUnits',
            type: 'text',
            property: { cellName: 'unit', data: null },
        };
        return [placeholder, ...options];
    }

    getStatusStyle(status: AlarmTypeEnum): any {
        switch (status) {
            case AlarmTypeEnum.alarm:
                return {
                    color: '#FF6161',
                };
            case AlarmTypeEnum.attention:
                return {
                    color: '#F9852C',
                };
        }
    }

    getPostIcons(variable: Variable): IconInterface<DetailsDeviceIconActionEnum>[] {
        if (!variable) {
            return;
        }

        return [
            {
                path: './assets/design/icons/table-operations/info.svg',
                action: DetailsDeviceIconActionEnum.info,
                styles: {
                    fill: 'var(--columnMailingIconColor)',
                    stroke: 'var(--columnMailingIconColor)',
                },
                cellNames: ['variableName'],
            },
        ];
    }

    getCheckboxProperties(cells: ColumnsTableInterface[], currentGroupSetting: VariableGroupSettings): CheckboxPropertiesInterface {
        return cells
            .filter((item) => item.mode && item.mode === ColumnModeEnum.checkbox)
            .reduce((pre, cur): CheckboxPropertiesInterface => {
                return {
                    ...pre,
                    [cur.name as string]: this.getCheckboxPropertiesData(cur, currentGroupSetting),
                };
            }, {});
    }

    private getCheckboxPropertiesData(
        cell: ColumnsTableInterface,
        currentGroupSetting: VariableGroupSettings,
    ): CheckboxPropertiesDataInterface {
        return {
            isFreeze: false,
            isError: false,
            isWaiting: false,
            isDisabled:
                (cell.name === 'statusAcknowledgeable' && !currentGroupSetting.statusAlarm) ||
                cell.isDisable ||
                !this.store.selectSnapshot(PermissionsState.getIsCheckPermission),
            isCheckbox: true,
        };
    }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TooltipSideEnum} from 'src/app/app-shared-elements/_enums/tooltip-side.enum';
import {IconInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import {RereadingEnum} from 'src/app/groups/_enums/rereading.enum';
import {ChartService} from '../_services/chart.service';
import {Store} from '@ngxs/store';
import {AuthState} from '../../../../auth/_store/states/auth.state';

@Component({
    selector: 'app-rereading',
    templateUrl: './rereading.component.html',
    styleUrls: ['./rereading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RereadingComponent implements OnInit {

    private _isDisabledDetail: boolean;

    rereadingItems: IconInterface<RereadingEnum>[] = [];
    tooltipSideEnum = TooltipSideEnum;

    @Input()
    set isDisabledDetail(isDisabledDetail: boolean) {
        this._isDisabledDetail = isDisabledDetail;

        this.changeRereadingItems(isDisabledDetail);
    }

    get isDisabledDetail(): boolean {
        return this._isDisabledDetail;
    }

    @Output() reReadingEvent: EventEmitter<RereadingEnum> = new EventEmitter<RereadingEnum>();

    rereadingEnum = RereadingEnum;

    constructor(
        private chartService: ChartService,
        private store: Store
    ) {
        this.rereadingItems = this.chartService.getRereadingItems();
    }

    ngOnInit(): void {
    }

    eventFromIcon(item: IconInterface<RereadingEnum>): void {
        this.reReadingEvent.emit(item.action);
    }

    private changeRereadingItems(isDisabledDetail: boolean): void {
        this.rereadingItems = this.rereadingItems.map(item => {
            if (item.action === RereadingEnum.detail) {
                return {
                    ...item,
                    isDisable: isDisabledDetail
                };
            }

            if (this.store.selectSnapshot(AuthState.getIsAdmin) && item.action === RereadingEnum.rereading) {
                return {
                    ...item,
                    isDisable: true
                };
            }

            return item;
        });
    }
}

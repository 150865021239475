import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
    errorMessage: string;
    errorStatus: string;

    constructor(private router: Router) {
        if (router.url.includes('404')) {
            this.errorStatus = '404';
            this.errorMessage = 'notFound.message';
        } else {
            this.errorMessage = 'notPermission.message';
            this.errorStatus = '403';
        }

        this.router.events.pipe(first((events) => events instanceof NavigationEnd)).subscribe(async (event: NavigationEnd) => {
            if (event.urlAfterRedirects.includes('404')) {
                this.errorStatus = '404';
                this.errorMessage = 'notFound.message';
            } else {
                this.errorMessage = 'notPermission.message';
                this.errorStatus = '403';
            }
        });
    }

    ngOnInit(): void {}
}

<div class="user-log">
    <app-heading *ngIf="!(userId$ | async)" [title]="'navigation.userLog'"></app-heading>
    <app-filters
        [type]="filterItemTypeEnum.event"
        [journalEditBtn]="true"
        (editBtnsEvent)="openEdit()"
        [optionsDropDown]="dropDownFilterOptions$ | async"
        [currentValueForDropdownInput]="currentValueForDropdownInput"
        [defaultDropdownValue]="defaultDropdownValue"
        [filter]="(params$ | async).filter"
        (currentFilterDropDown)="onChangeDropDownLogs($event)"
        (filterEvent)="changeFilter($event)"></app-filters>

    <app-tables
        [currentTableColumns]="userTablesColumns"
        [rows]="rowsLogs$ | async"
        (sortOutput)="onChangeSortLogs($event)"
        (paginationOutput)="onChangePageLogs($event)"
        [configPagination]="(params$ | async).pagination"></app-tables>
</div>

<app-popup *ngIf="showPopup" [title]="'table.deviceLogs.addToTable'" (closeEmit)="showPopup = false">

    <div class="popup">

        <ng-container *ngFor="let item of usersLogsService.userLogTableColumns">
            <div class="popup__item">
                <span (click)="dataChanged(item)" class="popup__item-title" [translate]="item.title"></span>
                <app-checkbox [value]="userLogColumns.includes(item.name)"
                    (changeEvent)="dataChanged(item)"></app-checkbox>
            </div>
        </ng-container>

    </div>
</app-popup>

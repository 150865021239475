import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { Pagination, Params, ParamsFilterTypeEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { MailingRecipientRowInterface, RecipientsUserInterface } from '../../_interfaces/mailing-recipient-row.interface';
import { MailingUserService } from '../../_services/mailing-user.service';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import {
    AddNewRecipient,
    ChangeActivityRecipient,
    CloseEditingRecipientRow,
    CreateNewRecipient,
    DeleteRecipient,
    GetRecipients,
    GetUsersByCompanies,
    InitRecipientsRows,
    SetEditingRecipientRow,
    SetIsDeleteRecipientPopup,
    SetRecipientParams,
} from '../actions/mailing-recipient.actions';
import { mailingRecipientCheckboxes } from '../../_data/mailing-recipient-params';
import { StatusNotificationShare } from '../../_enums/status-notification-share.enum';
import { ColumnsTableInterface, ColumnTypeEnum, ColumnValueAlignEnum } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { MailingCellFieldTypeEnum } from '../../_enums/mailing-cell-field-type.enum';
import { TableNamesEnum } from 'src/app/app-shared-elements/_enums/table-names.enum';
import { TranslateService } from '@ngx-translate/core';
import { CustomInputItem } from 'src/app/app-shared-elements/_interfaces/custom-input-item.interface';
import { DropdownFilterOptionInterface } from 'src/app/app-shared-elements/filters/interfaces/filter-option.interface';
import { TypeFilterEnum } from 'src/app/app-shared-elements/filters/enums/type-filter.enum';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { DisabledTypeEnum } from 'src/app/app-shared-elements/_enums/disabled-type.enum';

export interface MailingRecipientStateModel {
    // updatedRecipient: MailingRecipientRowInterface;
    updatingRecipient: MailingRecipientRowInterface;
    recipients: RecipientsUserInterface[];
    recipientsRows: MailingRecipientRowInterface[];
    isEditingMode: boolean;
    params: Params;
    columns: ColumnsTableInterface[];
    autocompleteValues: CustomInputItem[];
    dropDownFilter: DropdownFilterOptionInterface[];
    isDeletePopup: boolean;
}

const columns: ColumnsTableInterface[] = [
    {
        title: 'mailing.recipient.table.act',
        grow: false,
        small: true,
        maxWidth: '85px',
        minWidth: '85px',
        type: ColumnTypeEnum.icon,
        name: 'isActive',
    },
    {
        title: 'mailing.recipient.table.email',
        grow: true,
        small: false,
        minWidth: '240px',
        type: ColumnTypeEnum.mailingEditor,
        name: 'email',
        align: ColumnValueAlignEnum.left,
        mailingFieldType: MailingCellFieldTypeEnum.inputSelect,
        inputPlaceholderValue: 'mailing.recipient.table.emailPlaceholder',
    },
    {
        title: 'mailing.recipient.table.name',
        type: ColumnTypeEnum.mailingEditor,
        name: 'name',
        minWidth: '240px',
        maxWidth: '260px',
        align: ColumnValueAlignEnum.left,
    },
    {
        title: 'mailing.recipient.table.transports',
        grow: true,
        small: false,
        type: ColumnTypeEnum.mailingEditor,
        minWidth: '280px',
        name: 'transports',
        mailingFieldType: MailingCellFieldTypeEnum.transport,
    },
    {
        title: 'mailing.recipient.table.status',
        // type: ColumnTypeEnum.mailingEditor,
        type: ColumnTypeEnum.text,
        name: 'statusValue',
        minWidth: '240px',
        maxWidth: '260px',
        align: ColumnValueAlignEnum.center,
        styles: true,
        isMultilang: true,
    },
    {
        title: 'mailing.recipient.table.actions',
        grow: false,
        small: true,
        maxWidth: '140px',
        minWidth: '140px',
        type: ColumnTypeEnum.mailingEditor,
        name: 'edit',
        mailingFieldType: MailingCellFieldTypeEnum.btns,
        tableName: TableNamesEnum.mailingRecipientTable,
        actionBtns: [ColumnsActionTypeEnum.actionBtnsDelete],
    },
];

const MAILING_USER_TOKEN = new StateToken<MailingRecipientStateModel>('mailingUsers');

const initDropDownFilterRecipients: DropdownFilterOptionInterface[] = [
    { key: 'name', value: 'mailing.recipient.table.name', type: TypeFilterEnum.text, property: 'name' },
    { key: 'email', value: 'mailing.recipient.table.email', type: TypeFilterEnum.text, property: 'email' },
];

const configPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

const initialParams = {
    pagination: configPagination,
    sorted: null,
    filter: [
        ...mailingRecipientCheckboxes,
        // drop down
        {
            property: 'name',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true,
        },
        {
            property: 'email',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true,
        },
    ],
};

@State<MailingRecipientStateModel>({
    name: MAILING_USER_TOKEN,
    defaults: {
        // updatedRecipient: null,
        updatingRecipient: null,
        recipients: [],
        recipientsRows: [],
        isEditingMode: false,
        params: JSON.parse(JSON.stringify(initialParams)),
        columns,
        autocompleteValues: [],
        dropDownFilter: initDropDownFilterRecipients,
        isDeletePopup: false,
    },
})
@Injectable()
export class MailingRecipientState {
    constructor(
        private mailingUserService: MailingUserService,
        private notificationsService: NotificationsService,
        private translateService: TranslateService,
        private http: HttpClient,
    ) {}

    @Selector()
    static getRecipientParams(state: MailingRecipientStateModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }

    @Selector()
    static getRecipientColumns(state: MailingRecipientStateModel): ColumnsTableInterface[] {
        return state.columns;
    }

    @Selector()
    static getRecipientRows(state: MailingRecipientStateModel): MailingRecipientRowInterface[] {
        return state.recipientsRows;
    }

    @Selector()
    static getRecipientDropdownFilter(state: MailingRecipientStateModel): DropdownFilterOptionInterface[] {
        return state.dropDownFilter;
    }

    @Selector()
    static getIsEditingMode(state: MailingRecipientStateModel): boolean {
        return state.isEditingMode;
    }

    @Selector()
    static getAutoCompleteUsers(state: MailingRecipientStateModel): CustomInputItem[] {
        return state.autocompleteValues;
    }

    @Selector()
    static getIsDeletePopup(state: MailingRecipientStateModel): boolean {
        return state.isDeletePopup;
    }

    @Action(GetRecipients)
    async GetRecipients(ctx: StateContext<MailingRecipientStateModel>): Promise<void> {
        const result: ApiResponse<RecipientsUserInterface[]> = (await this.http
            .get('/api/notification-user/recipients')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<RecipientsUserInterface[]>;
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                recipients: result.data,
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.length,
                    },
                },
            });

            await ctx.dispatch(new GetUsersByCompanies()).toPromise();
            ctx.dispatch(new InitRecipientsRows());
        }
    }

    @Action(InitRecipientsRows)
    async initRecipientsRows(ctx: StateContext<MailingRecipientStateModel>): Promise<void> {
        const state = ctx.getState();
        const params = state.params;

        const rows: MailingRecipientRowInterface[] = await this.mailingUserService.getRecipientRows(state.recipients, state.autocompleteValues, params);
        ctx.setState({
            ...state,
            recipientsRows: rows.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()),
        });
    }

    @Action(SetRecipientParams)
    setRecipientParams(ctx: StateContext<MailingRecipientStateModel>, payload: SetRecipientParams): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: payload.params,
        });

        ctx.dispatch(new InitRecipientsRows());
    }

    @Action(AddNewRecipient)
    addNewRecipient(ctx: StateContext<MailingRecipientStateModel>): void {
        const state = ctx.getState();

        const newRecipientRow: MailingRecipientRowInterface = {
            email: '',
            senderUserId: '',
            name: '',
            status: StatusNotificationShare.PROCESS,
            isActive: true,
            transports: [],
            statusValue: '',
            isEditing: true,
            isNew: true,
            require: {
                email: true,
            },
            created: new Date(),
            autocompleteValues: state.autocompleteValues,
            disabledType: DisabledTypeEnum.mailingUserTable,
        };

        ctx.setState({
            ...state,
            recipientsRows: [newRecipientRow, ...state.recipientsRows],
            isEditingMode: true,
        });
    }

    @Action(CreateNewRecipient)
    async createNewRecipient(ctx: StateContext<MailingRecipientStateModel>, payload: CreateNewRecipient): Promise<void> {
        const result: ApiResponse<RecipientsUserInterface> = (await this.http
            .post('/api/notification-user', { email: payload.recipientRow.email, isActive: true })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<RecipientsUserInterface>;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                isEditingMode: false,
                recipients: [result.data, ...state.recipients],
            });

            const message: string = await this.translateService
                .get('mailing.recipient.successCreateRecipient', { email: result.data.email })
                .toPromise()
                .catch((e) => console.log(e));
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, message);
        } else {
            const message: string = await this.translateService
                .get('mailing.recipient.errorCreateRecipient', { email: payload.recipientRow.email })
                .toPromise()
                .catch((e) => console.log(e));
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, message);

            ctx.setState({
                ...state,
                isEditingMode: false,
            });
        }

        ctx.dispatch(new InitRecipientsRows());
    }

    @Action(DeleteRecipient)
    async deleteRecipient(ctx: StateContext<MailingRecipientStateModel>, payload: DeleteRecipient): Promise<void> {
        const result: ApiResponse<RecipientsUserInterface> = (await this.http
            .delete('/api/notification-user', { params: { id: payload.id } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<RecipientsUserInterface>;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                recipients: state.recipients.filter((r) => r.id !== payload.id),
            });
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        } else {
            ctx.setState({
                ...state,
            });
        }

        ctx.dispatch(new InitRecipientsRows());
    }

    @Action(SetEditingRecipientRow)
    cetEditingRecipientRow(ctx: StateContext<MailingRecipientStateModel>, payload: SetEditingRecipientRow): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            recipientsRows: state.recipientsRows.map((r) => {
                if (r.id === payload.id) {
                    return {
                        ...r,
                        isEditing: true,
                    };
                }
                return r;
            }),
        });
    }

    @Action(CloseEditingRecipientRow)
    closeEditingRow(ctx: StateContext<MailingRecipientStateModel>, payload: CloseEditingRecipientRow): void {
        const state = ctx.getState();

        if (state.recipientsRows.length === 1 && state.recipientsRows[0].isNew) {
            ctx.setState({
                ...state,
                recipientsRows: [],
                isEditingMode: false,
            });

            return;
        }

        ctx.setState({
            ...state,
            recipientsRows: state.recipientsRows.map((row) => ({ ...(payload.row && payload.row.id === row.id ? payload.row : row), isEditing: false })).filter((r) => r.id),
            isEditingMode: false,
        });

        // ctx.dispatch(GetUnits);
    }

    @Action(ChangeActivityRecipient)
    async ChangeActivityRecipient(ctx: StateContext<MailingRecipientStateModel>, payload: ChangeActivityRecipient): Promise<void> {
        const result: ApiResponse<RecipientsUserInterface> = (await this.http
            .put('/api/notification-user/active', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<RecipientsUserInterface>;
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                recipients: state.recipients.map((r) => ({
                    ...r,
                    isActive: r.id === result.data.id ? result.data.isActive : r.isActive,
                })),
            });
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }

        ctx.dispatch(new InitRecipientsRows());
    }

    @Action(GetUsersByCompanies)
    async GetUsersByCompanies(ctx: StateContext<MailingRecipientStateModel>): Promise<void> {
        const result: ApiResponse<string[]> = (await this.http
            .get('/api/users/users-company')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<string[]>;
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                autocompleteValues: [...result.data.map((item) => ({ value: item, isSelected: false }))],
            });
        }
    }

    @Action(SetIsDeleteRecipientPopup)
    setIsDeleteRecipientPopup(ctx: StateContext<MailingRecipientStateModel>, payload: SetIsDeleteRecipientPopup): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isDeletePopup: payload.value,
        });
    }
}

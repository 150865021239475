import { Injectable } from '@angular/core';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { Store } from '@ngxs/store';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { Device } from '../../../app-shared-elements/_interfaces/Device';

@Injectable({
    providedIn: 'root',
})
export class TrainDashboardService {
    constructor(private store: Store) {}

    public getDataloggerOptions(usedIds: string[]): SelectOptionInterface<string, string, Device | string>[] {
        const dataloggers = this.store.selectSnapshot(DevicesState.getDataloggers);
        const dataloggerOptions: SelectOptionInterface<string, string, Device | string>[] = dataloggers
            .filter((f) => f.isTransport)
            .map((device) => {
                const isDisabled = !!usedIds.find((f) => f === device.id);
                return {
                    key: device.id,
                    value: device.name ?? device.defaultName,
                    disabled: isDisabled,
                    property: device,
                };
            });

        dataloggerOptions.unshift({
            key: null,
            value: 'train.select.deviceSelect',
            property: '-1',
        });

        return dataloggerOptions;
    }
}

import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TabsService {

    isCanChangeTab = new EventEmitter<boolean>();
    currentTabIdEvent = new EventEmitter<number>();

    currentTabId: any;

    constructor() { }

    setIsCanChangeTagEvent(value: boolean): void {
        this.isCanChangeTab.emit(value);
    }


    setCurrentTabId(id): void {
        this.currentTabId = id;
    }

    getCurrentTabId<T>(): T {
        return this.currentTabId;
    }
}

export enum FilterTypePipeEnum {
    variablesForTransportSelect = 'variablesForTransportSelect',
    groupByRegistratorId = 'groupByRegistratorId',
    variablesForExpressionSelect = 'variablesForExpressionSelect',
    variablesForExpressionSelectDevice = 'variablesForExpressionSelectDevice',
    messagesByRegistratorId = 'messagesByRegistratorId',
    logicalEventsRowByRegistratorId = 'logicalEventsRowByRegistratorId',
    groupsByGPSMode = 'groupsByGPSMode',
    logicalMessagesByRegistratorId = 'logicalMessagesByRegistratorId',
    filterHideCoordinatorForGroups = 'filterHideCoordinatorForGroups',
    filterHideRegistratorForGroups = 'filterHideRegistratorForGroups',
    logicalEventFilterByMultiEventActive = 'logicalEventFilterByMultiEventActive',

    // devices filter
    filterDeviceByRegistratorId = 'filterDeviceByRegistratorId',
    filterDeviceByOwner = 'filterDeviceByOwner',
    filterDeviceByConnect = 'filterDeviceByConnect',
    filterDeviceByStatus = 'filterDeviceByStatus',
    filterDeviceByCompany = 'filterDeviceByCompany',

    // reports filter
    filterReportsByName = 'filterReportsByName',
    filterReportsByOwner = 'filterReportsByOwner',
    filterReportsByGroup = 'filterReportsByGroup',

    // custom select filter
    filterOptionsByValue = 'filterOptionsByValue',

    // mailing group filter
    filterMailingGroupByValue = 'filterMailingGroupByValue',
    filterMailingGroupByNameOfGroup = 'filterMailingGroupByNameOfGroup',
    filterMailingGroupByName = 'filterMailingGroupByName',

    // device log
    filterDeviceLogByRegistratorName = 'filterDeviceLogByRegistratorName',

    // logical event
    filterLogicalEventByName = 'filterLogicalEventByName',
    filterLogicalEventByStatus = 'filterLogicalEventByStatus',
    filterLogicalEventByRegistratorId = 'filterLogicalEventByRegistratorId',

    // message catalog
    filterMessageCatalogByName = 'filterMessageCatalogByName',
    filterMessageCatalogByRegistratorId = 'filterMessageCatalogByRegistratorId',

    // remote device
    filterDeletedDeviceById = 'filterDeletedDeviceById',

    // copy configurator
    filterCopyConfigurator = 'filterCopyConfigurator',

    // configurator

    filterConstructorType = 'filterConstructorType',

    // nav

    filterNavigation = 'filterNavigation',
}

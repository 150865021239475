import {CopyMessagesDto} from '../../_interfaces/CopyMessagesDto';
import {EventMessage} from '../../_interfaces/EventMessage';
import {EventSaveFromSocketInterface} from '../../_interfaces/EventSaveFromSocketDto';
import {Params} from '../../../app-shared-elements/_interfaces/params.interface';

export class GetLogicalMessagesByRegistrator {
    static readonly type = '[MessagesCatalog] GetLogicalMessagesByRegistrator';
    constructor(public registratorId: string) {}
}

export class GetAllMessages {
    static readonly type = '[MessagesCatalog] GetAllMessages';
}

export class CreateLogicalMessage {
    static readonly type = '[MessagesCatalog] CreateLogicalMessage';
    constructor(public copyMessage: CopyMessagesDto) {}
}

export class DeleteLogicalMessage {
    static readonly type = '[MessageCatalog] DeleteLogicalMessage';
    constructor(public ids: string[]) {}
}

export class UpdateLogicalMessage {
    static readonly type = '[MessageCatalog] UpdateLogicalMessage';
    constructor(public message, public id: string) {}
}

export class ChangeSavedStatusMessage {
    static readonly type = '[MessageCatalog] ChangeSavedStatusMessage';
    constructor(public data: EventSaveFromSocketInterface) {

    }
}

export class SetEditingMessage {
    static readonly type = '[MessageCatalog] SetEditingMessage';
    constructor(public message: EventMessage) {}
}

export class SetMessageParams {
    static readonly type = '[MessageCatalog] SetMessageParams';
    constructor(public params: Params) {}
}

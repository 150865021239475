import { AbstractControl } from '@angular/forms';

export function ValidatePassword(control: AbstractControl): { [key: string]: boolean } | null {
    if (!control.value) {
        return null;
    }
    if (control.value.length > 0) {
        this.strength = 0;
    }
    if (control.value !== undefined && control.value.length < 8) {
        return { password: true };
    }

    const password = control.value;
    // initial strength
    let strength = 0;

    // if length is 8 characters or more, increase strength value
    // if (password.length > 7) strength += 1

    // if it has numbers and characters, increase strength value
    if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/) && !password.match(/([а-я])/)) {
        strength += 1;
    }

    // if password contains both lower and uppercase characters, increase strength value
    // if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/))  strength += 1

    // if it has one special character, increase strength value
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~, +, =, |, /])/)) {
        strength += 1;
    }

    if (password.includes(' ')) {
        strength = 0;
    }

    // if it has two special character, increase strength value
    // if (password.match(/(.*[!,%,&,@,#,$,^,*,?,_,~].*[!,",%,&,@,#,$,^,*,?,_,~])/)) this.strength += 1

    this.strength = strength;
    return null;
}

<div class="cc">
    <div class="cc__wrapper">
        <div class="cc__title" [translate]="isError ? 'checkingConfirm.titleError' : 'checkingConfirm.title'"></div>
        <div class="cc__message" [translate]="isError ? 'checkingConfirm.errorMessage' : 'checkingConfirm.message'"
             [translateParams]="{transport: t}">
        </div>
        <div class="cc__btns">
            <button class="cc__btn btn" [translate]="'checkingConfirm.moveToProfile'"
                    (click)="moveToProfile()"></button>
        </div>
    </div>
</div>

import { Directive, ElementRef, Input } from '@angular/core';
import { UnitRowInterface } from 'src/app/admin/units/_interfaces/units.intefaces';
import { SharedUserInterface } from 'src/app/device-dashboard/user-devices/_interface/shared-user.interface';
import { DisabledTypeEnum } from '../_enums/disabled-type.enum';
import { VariablesNameEnum } from '../_enums/variables-name.enum';
import { DisabledDirectiveDataInterface } from '../_interfaces/disabled-directive-data.interface';
import { StatusMessagesRowInterface } from '../../admin/event-messages/_interface/status-messages.interface';
import { LimitLogicEventUpdateDto, LogicEvent } from 'src/app/events/logical-events/_interface/LogicEvent';
import { LogicalEventsService } from 'src/app/events/_services/logical-events.service';
import { VirtualDeviceVariableForConstructor } from 'src/app/custom-devices/_interfaces/VirtualDeviceVariableForConstructor';
import { AdminsListRowsInterface } from '../../admin/admins/_interface/admins-list-rows.interface';
import { ReportSettingsInterface } from '../../reports/_interfaces/ReportSettings';
import { CompanyRowInterface } from 'src/app/admin/company/_interfaces/company.interface';
import { Device } from '../_interfaces/Device';
import { MailingRecipientRowInterface } from 'src/app/mailing/_interfaces/mailing-recipient-row.interface';
import { UsersRowInterface } from '../../admin/users/_interfaces/users-row.interface';
import { BillingPersonalAccountInterface, UpdateBillingAccountInfoInterface } from '../../billing/_interfaces/billing-personal-account.interface';
import { TextMail } from '../../admin/mail-message/_interfaces/mail-message.interface';
import { NotificationTransportType } from '../../mailing/_interfaces/notification-log.interface';
import { FinanceServicesRowInterface } from '../../admin/admin-finances/_interfaces/finance-services.interface';
import { DevicesControlRowInterface } from '../../admin/devices-control/_interfaces/devices-control-row.interface';
import { Router } from '@angular/router';
import { RestrictionValidateService } from '../_services/restriction-validate.service';
import { TransportDeviceDto } from '../../transport-container/transport-dashboard/_interfaces/TransportDeviceDto';
import { CompanyStaffRow } from '../../company-staff/_interfaces/company-staff-row.interface';

@Directive({
    selector: '[appDisabled]',
})
export class DisabledDirective {
    private _data: DisabledDirectiveDataInterface;

    @Input('appDisabled')
    set data(data: DisabledDirectiveDataInterface) {
        this._data = data;
        if (!data) {
            return;
        }
        this.findCurrentMethod();
    }

    get data(): DisabledDirectiveDataInterface {
        return this._data;
    }

    constructor(
        private el: ElementRef,
        private logicalEventsService: LogicalEventsService,
        private router: Router,
        private restrictionValidateService: RestrictionValidateService,
    ) {}

    findCurrentMethod(): void {
        switch (this.data.type) {
            case DisabledTypeEnum.sharedDeviceDisabled:
                this.isSharedDeviceDisabled(this.data.data);
                return;
            case DisabledTypeEnum.addOrDeleteBtnInVirtualDeviceConstructor:
                this.addDeleteVirtualDeviceConstructorDisabled(this.data.data);
                return;
            case DisabledTypeEnum.saveVirtualDeviceWithoutArchivePeriod:
                this.saveVirtualDeviceWithoutArchivePeriod(this.data.data);
                return;
            case DisabledTypeEnum.checkForAlreadyAddArchivePeriodToVirtualDevice:
                this.checkForAlreadyAddArchivePeriodToVirtualDevice(this.data.data);
                return;
            case DisabledTypeEnum.checkForAlreadyAddVariableToVirtualDevice:
                this.checkForAlreadyAddVariableToVirtualDevice(this.data.data);
                return;
            case DisabledTypeEnum.saveTransport:
                this.saveTransport(this.data.data);
                return;
            case DisabledTypeEnum.unitsTable:
                this.unitsTable(this.data.data);
                return;
            case DisabledTypeEnum.companyTable:
                this.companyTable(this.data.data);
                return;
            case DisabledTypeEnum.mailingUserTable:
                this.mailingUserTable(this.data.data);
                return;
            case DisabledTypeEnum.statusMessageTable:
                this.statusMessageTable(this.data.data);
                return;
            case DisabledTypeEnum.checkSaveMultiEvent:
                this.checkSaveMultiEvent(this.data.data);
                return;
            case DisabledTypeEnum.checkSaveLogicEvent:
                this.checkSaveLogicEvent(this.data.data);
                return;
            case DisabledTypeEnum.adminsListTable:
                this.saveAdminListTable(this.data.data);
                return;
            case DisabledTypeEnum.saveReports:
                this.saveReportsList(this.data.data);
                return;
            case DisabledTypeEnum.usersTable:
                this.saveUsersTable(this.data.data);
                return;
            case DisabledTypeEnum.billingInfo:
                this.saveBillingInfo(this.data.data);
                return;
            case DisabledTypeEnum.mailMessage:
                this.saveMailMessage(this.data.data);
                return;
            case DisabledTypeEnum.financeServices:
                this.saveFinanceServices(this.data.data);
                return;
            case DisabledTypeEnum.devicesControl:
                this.saveDevicesControl(this.data.data);
                return;
            case DisabledTypeEnum.email:
                this.validateEmail(this.data.data);
                return;
            case DisabledTypeEnum.restriction:
                this.restriction(this.data.data);
                return;
            case DisabledTypeEnum.companyStaff:
                this.saveCompanyStaffTable(this.data.data);
                return;
        }
    }

    private isSharedDeviceDisabled(data: { oldUsersList: SharedUserInterface[]; newUsersList: SharedUserInterface[]; isChanged: boolean; isMnemonic: boolean }): void {
        if (data.isMnemonic && data.newUsersList && data.newUsersList.length) {
            this.el.nativeElement.disabled = false;
            return;
        }

        if (((!data.oldUsersList || !data.oldUsersList.length) && (!data.newUsersList || !data.newUsersList.length)) || !data.isChanged) {
            this.el.nativeElement.disabled = true;
            return;
        }

        let isSaveDisabled = false;

        data.oldUsersList.forEach((user) => {
            if (isSaveDisabled) {
                return;
            }

            if (!user.role || !user.role.length) {
                isSaveDisabled = true;
            }
        });

        data.newUsersList.forEach((user) => {
            if (isSaveDisabled) {
                return;
            }

            if (!user.role || !user.role.length) {
                isSaveDisabled = true;
            }
        });

        this.el.nativeElement.disabled = isSaveDisabled;
    }

    private addDeleteVirtualDeviceConstructorDisabled(data: VirtualDeviceVariableForConstructor[]): void {
        if (!data && !data.length) {
            this.el.nativeElement.disabled = true;
            return;
        }

        if (!data.find((item) => item.check === true)) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private saveVirtualDeviceWithoutArchivePeriod(data: { selectedVariablesForVirtualDevice: VirtualDeviceVariableForConstructor[]; isEdited: boolean }): void {
        if (!data || !data.selectedVariablesForVirtualDevice || !data.selectedVariablesForVirtualDevice.length) {
            this.el.nativeElement.disabled = true;
            return;
        }

        if (!data.isEdited) {
            this.el.nativeElement.disabled = true;
            return;
        }

        if (!data.selectedVariablesForVirtualDevice.find((v) => v.variable.name === VariablesNameEnum.ArchivePeriod)) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private checkForAlreadyAddArchivePeriodToVirtualDevice(data: VirtualDeviceVariableForConstructor[]): void {
        if (data && data.length && data.find((v) => v.variable.name === VariablesNameEnum.ArchivePeriod)) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private checkForAlreadyAddVariableToVirtualDevice(data: { selectedVariablesForVirtualDevice: VirtualDeviceVariableForConstructor[]; variable: VirtualDeviceVariableForConstructor }): void {
        if (!data || !data.selectedVariablesForVirtualDevice || !data.selectedVariablesForVirtualDevice.length) {
            this.el.nativeElement.disabled = false;
            return;
        }

        if (!data.variable) {
            this.el.nativeElement.disabled = false;
            return;
        }

        if (data.selectedVariablesForVirtualDevice.find((v) => v.variable.originVariableId === data.variable.id)) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private saveTransport(data: { selectsTransport: TransportDeviceDto[]; isEdit: boolean }): void {
        if (!data.isEdit) {
            this.el.nativeElement.disabled = true;
            return;
        }

        data.selectsTransport.forEach((item) => {
            if (item.deviceId && !item.variableId) {
                this.el.nativeElement.disabled = true;
                return;
            }
        });

        if (data.selectsTransport.find((item) => item.deviceId && !item.variableId)) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private unitsTable(data: UnitRowInterface): void {
        if (!data || !data.name || !data.name.length || !data.unit || !data.unit.length) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private companyTable(data: CompanyRowInterface): void {
        if (!data || !data.name || !data.name.length || !data.managerId || !data.managerId.length) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private mailingUserTable(row: MailingRecipientRowInterface): void {
        if (!row || !row.email.length || !row.email.includes('@')) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private statusMessageTable(data: { editingRow: StatusMessagesRowInterface; isEdit: boolean }): void {
        if (!data.editingRow || !data.editingRow.message.length || !data.editingRow.key.length || !data.isEdit) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private saveAdminListTable(row: AdminsListRowsInterface): void {
        if (!row || !row.email.length || !row.email.includes('@') || !row.name.length || !row.password.length || row.password.length < 8) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private checkSaveMultiEvent(data: { event: LimitLogicEventUpdateDto; isNameInEditMode: boolean; isEdited: boolean; errorExpressionMessage: string; registrator: Device }): void {
        if (!data || !data.event || !data.event.variableId || data.errorExpressionMessage || !data.isEdited || (data.registrator && data.registrator.isWorkExpedition)) {
            this.el.nativeElement.disabled = true;
            return;
        }
        let isDisable: boolean;

        const currentKeys: string[] = Object.keys(data.event).filter((key) => this.logicalEventsService.instanceOfLimitLogicEvent(data.event[key]));

        if (!currentKeys.find((key) => data.event[key].value)) {
            this.el.nativeElement.disabled = true;
            return;
        }

        currentKeys.forEach((key) => {
            if (isDisable) {
                return;
            }

            if (data.event[key].isActive && !data.event[key].value) {
                isDisable = true;
            }
        });

        if (isDisable) {
            this.el.nativeElement.disabled = true;
            return;
        }

        if (!data.event.variableId || !data.isNameInEditMode) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private checkSaveLogicEvent(data: { event: LogicEvent; errorExpressionMessage: string; isNameInEditMode: boolean; isEdited: boolean; registrator: Device }): void {
        if (
            !data.isEdited ||
            (data.event.isActive && data.event.id) ||
            (data.registrator && data.registrator.isWorkExpedition) ||
            (data.errorExpressionMessage && data.errorExpressionMessage) ||
            !data.event.eventAlertMessageId ||
            !data.event.expression.conditions.length ||
            !data.event.resultVariableId ||
            !data.event.name ||
            !data.event.name.length ||
            !data.isNameInEditMode
        ) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private saveReportsList(data: { report: ReportSettingsInterface; isEdited }): void {
        // console.log(data);
        try {
            if (
                !data.report ||
                !data.report.nameReport ||
                !data.report.deviceId ||
                !data.report.dateFormat ||
                !data.report.timezone ||
                (!data.report.isDefaultReport && !data.report.date.schedule === null) ||
                !data.report.variablesId.length ||
                !data.isEdited
            ) {
                this.el.nativeElement.disabled = true;
                return;
            }

            this.el.nativeElement.disabled = false;
        } catch (e) {
            // console.log(data);
            // console.trace();
            // console.log(e);
            return;
        }
    }

    private saveUsersTable(row: UsersRowInterface): void {
        const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!row || !row?.login || !row?.login?.match(emailRegEx) || !row?.login?.length || !row?.userName?.length) {
            this.el.nativeElement.disabled = true;
            return;
        }
        this.el.nativeElement.disabled = false;
    }

    private saveBillingInfo(data: { info: BillingPersonalAccountInterface; update: UpdateBillingAccountInfoInterface; isDisabled: boolean; isAdmin: boolean }): void {
        if (data.isDisabled) {
            this.el.nativeElement.disabled = true;
            return;
        }

        if (!data.isAdmin) {
            if (
                (!data?.update?.name?.length && !data?.info?.name?.length) ||
                (!data?.info?.email?.length && !data?.update?.email?.length) ||
                (!data?.info?.phone && !data?.update?.phone) ||
                (!data?.info?.address?.length && !data?.update?.address?.length)
            ) {
                this.el.nativeElement.disabled = true;
                return;
            }
        }
        this.el.nativeElement.disabled = false;
    }

    private saveMailMessage(row: TextMail): void {
        if (!row || !row?.text || !row?.text?.length || !row?.text.trim() || (row.transportType === NotificationTransportType.EMAIL && (!row?.subject || !row?.subject.trim() || !row?.subject?.length))) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private saveFinanceServices(row: FinanceServicesRowInterface): void {
        if (!row || !row?.serviceDescription?.length || !row?.paymentAmount) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private saveDevicesControl(row: DevicesControlRowInterface): void {
        // console.log(row);
        if (!row || !row?.serialNumbers?.length || !row.registrator) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }

    private validateEmail(value: string): void {
        if (this.router.url.includes('control')) {
            return;
        }
        if (!value || !value.length) {
            this.el.nativeElement.disabled = false;
        }
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        this.el.nativeElement.disabled = !!value.match(reg) ? '' : '1px solid red';
    }

    private restriction(data: { value: string; restriction: { restriction: string } }): void {
        if (!this.restrictionValidateService.validateValue(data.value, data.restriction.restriction)) {
            this.el.nativeElement.style.display = 'none';
            return;
        }

        this.el.nativeElement.style.display = 'block';
    }

    private saveCompanyStaffTable(row: CompanyStaffRow): void {
        if ((row?.isNew && !row?.password.length) || row?.password?.length < 8) {
            this.el.nativeElement.disabled = true;
            return;
        }
        const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!row || !row?.userName || !row?.userName.length || !row?.login?.length || !row?.login?.match(emailRegEx)) {
            this.el.nativeElement.disabled = true;
            return;
        }

        this.el.nativeElement.disabled = false;
    }
}

import { ActiveEvents } from '../_interfaces/ActiveEvents';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { SocketService } from '../../app-shared-elements/_services/socket.service';
import { Store } from '@ngxs/store';
import { ConditionType, OperandConstantType } from '../_enums/condition-type.enum';
import { ColumnsTableInterface, ColumnTypeEnum, IconInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { DropdownFilterOptionInterface } from 'src/app/app-shared-elements/filters/interfaces/filter-option.interface';
import { TypeFilterEnum } from '../../app-shared-elements/filters/enums/type-filter.enum';
import { LimitLogicEventDto, LogicEvent, LogicEventType } from '../logical-events/_interface/LogicEvent';
import { EventTypeEnum } from 'src/app/app-shared-elements/_enums/event-type.enum';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { AlarmTypeEnum } from '../_enums/alarm.enum';
import { ExpressionService } from './expression.service';
import { Variable, VariableType } from 'src/app/app-shared-elements/_interfaces/Variable';
import { DataTypeService } from 'src/app/app-shared-elements/_services/data-type.service';
import { LogicalEventIconActions, LogicalEventRowInterface } from '../_interfaces/LogicalEventRow';
import { TranslateService } from '@ngx-translate/core';
import { EventSaveStatus } from '../_enums/event-save-state.enum';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { ConfigurationService } from 'src/app/app-shared-elements/_services/configuration.service';
import { DeviceTypeEnum } from '../../app-shared-elements/_enums/device-type.enum';

@Injectable({
    providedIn: 'root',
})
export class LogicalEventsService implements OnDestroy {
    eventsListsHappensColumns: ColumnsTableInterface[] = [
        // {
        //     title: '',
        //     grow: false,
        //     small: true,
        //     maxWidth: '80px',
        //     type: ColumnTypeEnum.input,
        //     name: 'isChoose',
        //     mode: ColumnModeEnum.checkbox
        // },
        {
            title: 'events.logicalEvents.table.act',
            grow: false,
            small: true,
            maxWidth: '85px',
            minWidth: '85px',
            type: ColumnTypeEnum.icon,
            name: 'isActive',
        },
        {
            title: 'events.logicalEvents.table.name',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'name',
            postIcons: true,
            preIcons: true,
            isArrow: true,
        },
        {
            title: 'events.logicalEvents.table.registrator',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'registrator',
            maxWidth: '220px',
        },
        {
            title: 'events.logicalEvents.table.tag',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            maxWidth: '220px',
            name: 'resultVariableName',
            tooltip: true,
            isClick: true,
            // isMultilang: true
        },
        {
            title: 'events.logicalEvents.table.expression',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'expressionString',
            tooltip: true,
            isClick: true,
        },
        {
            title: 'events.logicalEvents.table.status',
            grow: false,
            small: false,
            type: ColumnTypeEnum.text,
            isMultilang: true,
            name: 'state',
            styles: true,
            maxWidth: '140px',
        },
        {
            title: 'events.logicalEvents.table.eventId',
            maxWidth: '170px',
            minWidth: '160px',
            grow: false,
            type: ColumnTypeEnum.text,
            name: 'copyId',
            isClick: true,
            substr: 12,
            preIcons: true,
        },
        {
            title: 'events.logicalEvents.table.actions',
            grow: false,
            small: false,
            maxWidth: '100px',
            minWidth: '85px',
            type: ColumnTypeEnum.action,
            name: 'edit',
            actionBtns: [
                ColumnsActionTypeEnum.view,
                ColumnsActionTypeEnum.actionBtnsEdit,
                ColumnsActionTypeEnum.actionBtnsDelete,
                ColumnsActionTypeEnum.actionBtnsDuplicate,
            ],
        },
    ];

    private mainOperators = {
        [ConditionType.Equal]: {
            operands: 2,
        },
        [ConditionType.Not]: {
            operands: 2,
        },
        [ConditionType.MoreThan]: {
            operands: 2,
        },
        [ConditionType.LessThan]: {
            operands: 2,
        },
        [ConditionType.MoreThanOrEqual]: {
            operands: 2,
        },
        [ConditionType.LessThanOrEqual]: {
            operands: 2,
        },
        [ConditionType.Negation]: {
            operands: 1,
        },
        [ConditionType.Or]: {
            operands: 2,
        },
        [ConditionType.And]: {
            operands: 2,
        },
    };

    private operators = {
        [ConditionType.Constant]: {
            operands: 1,
            isValue: true,
            disabledInParentTypes: ['root', [ConditionType.Or], [ConditionType.And]],
        },
        [ConditionType.Tag]: {
            operands: 1,
            isValue: true,
            disabledInParentTypes: ['root', [ConditionType.Or], [ConditionType.And]],
        },
        [ConditionType.Equal]: {
            operands: 2,
        },
        [ConditionType.Not]: {
            operands: 2,
        },
        [ConditionType.MoreThan]: {
            operands: 2,
        },
        [ConditionType.LessThan]: {
            operands: 2,
        },
        [ConditionType.MoreThanOrEqual]: {
            operands: 2,
        },
        [ConditionType.LessThanOrEqual]: {
            operands: 2,
        },
        [ConditionType.Negation]: {
            operands: 1,
        },
        [ConditionType.Or]: {
            operands: 2,
        },
        [ConditionType.And]: {
            operands: 2,
        },
        [ConditionType.Plus]: {
            operands: 2,
            disabledInParentTypes: ['root'],
        },
        [ConditionType.Minus]: {
            operands: 2,
            disabledInParentTypes: ['root'],
        },
        [ConditionType.Multiplication]: {
            operands: 2,
            disabledInParentTypes: ['root'],
        },
        [ConditionType.Division]: {
            operands: 2,
            disabledInParentTypes: ['root'],
        },
    };

    dropDownFilterOptions: DropdownFilterOptionInterface[] = [
        { key: 'name', value: 'events.logicalEvents.dropdownFilters.name', type: TypeFilterEnum.text, property: 'name' },
        {
            key: 'status',
            value: 'events.logicalEvents.dropdownFilters.status',
            type: TypeFilterEnum.select,
            property: 'status',
        },
        {
            key: 'registratorId',
            value: 'events.logicalEvents.dropdownFilters.registratorId',
            type: TypeFilterEnum.select,
            property: 'registratorId',
        },
    ];

    public updateEventEmitter = new EventEmitter<any>();

    constructor(
        private http: HttpClient,
        private socketService: SocketService,
        private store: Store,
        private expressionService: ExpressionService,
        private dataTypeService: DataTypeService,
        private translateService: TranslateService,
        private configurationService: ConfigurationService,
    ) {}

    ngOnDestroy(): void {}

    getOperators(): any {
        return this.operators;
    }

    getMainOperators(): any {
        return this.mainOperators;
    }

    instanceOfLimitLogicEvent(data): data is LimitLogicEventDto {
        return typeof data === 'object' && 'logicEventType' in data;
    }

    getResultVariableName(resultVariableId: string, device: Device): string {
        if (!device) {
            return '';
        }

        const variable: Variable = device.variables.find((v) => v.id === resultVariableId);

        return variable ? variable.customName || variable.name : '';
    }

    getMultiEventMainSavedStatus(events: LogicEvent[]): EventSaveStatus {
        if (events.find((e) => e.savedStatus === EventSaveStatus.ERROR)) {
            return EventSaveStatus.ERROR;
        }

        if (events.find((e) => e.savedStatus === EventSaveStatus.PROCESS)) {
            return EventSaveStatus.PROCESS;
        }

        return EventSaveStatus.SAVED;
    }

    getExpressionString(event: LogicEvent): string {
        return this.expressionService.parseExpressionToString(event.expression, event.registratorId);
    }

    getStateForMultiEvent(strings: string[]): string {
        if (strings.find((s) => s === 'events.configurator.alert')) {
            return 'events.configurator.alert';
        }

        if (strings.find((s) => s === 'events.configurator.attention')) {
            return 'events.configurator.attention';
        }

        return 'events.configurator.ok';
    }

    getEventState(currentEvents: ActiveEvents, event: LogicEvent): string {
        if (currentEvents) {
            const currentEvent = currentEvents.events.find(
                (item) => item.eventType === EventTypeEnum.logic && item.srcId === event.id && !item.tsCleared,
            );
            if (currentEvent) {
                if (currentEvent.alarmType === AlarmTypeEnum.attention) {
                    return 'events.configurator.attention';
                } else if (currentEvent.alarmType === AlarmTypeEnum.alarm) {
                    return 'events.configurator.alert';
                } else {
                    return 'events.configurator.ok';
                }
            } else {
                return 'events.configurator.ok';
            }
        } else {
            return 'events.configurator.ok';
        }
    }

    getStatusStyles(state): any {
        switch (state) {
            case 'events.configurator.attention':
                return {
                    color: '#F9852C',
                    fontWeight: 600,
                    backgroundColor: 'var(--deviceCellAttentionBg)',
                    borderBottom: 'var(--deviceCellAttentionBorder)',
                };
            case 'events.configurator.alert':
                return {
                    color: '#FF6161',
                    fontWeight: 600,
                    backgroundColor: 'var(--deviceCellAlarmBg)',
                    borderBottom: 'var(--deviceCellAlarmBorder)',
                };
            case 'events.configurator.ok':
                return {
                    fontWeight: 600,
                    backgroundColor: 'var(--deviceCellOkBg)',
                    borderBottom: 'var(--deviceCellOkBorder)',
                    color: 'var(--deviceCellOkColor)',
                };
        }
    }

    async initRows(events: LogicEvent[], eventsStatus: ActiveEvents[], devices: Device[]): Promise<LogicalEventRowInterface[]> {
        if (!events) {
            return [];
        }

        const updateTitle = await this.translateService.get('events.logicalEvents.update').toPromise();
        const rows: LogicalEventRowInterface[] = events
            .filter((e) => e.logicEventType === LogicEventType.default)
            .map((event: LogicEvent) => {
                const currentRegistrator: Device = devices.find((registrator) => registrator.id === event.registratorId);
                const currentDevice = devices.find((d) => d.variables.find((v) => v.id === event.resultVariableId));
                let currentEvents: ActiveEvents;

                if (eventsStatus && eventsStatus.length) {
                    currentEvents = eventsStatus.find((item) => item.registratorId === currentRegistrator.id);
                }

                return {
                    eventAlertMessageId: event.eventAlertMessageId,
                    expression: event.expression,
                    expressionString: this.getExpressionString(event),
                    id: event.id,
                    copyId: event.id,
                    isAcknowledgeable: event.isAcknowledgeable,
                    isActive: event.isActive,
                    name: event.name || '',
                    notifications: event.notifications,
                    registrator: currentRegistrator.name || currentRegistrator.defaultName,
                    registratorId: event.registratorId,
                    resultVariableId: event.resultVariableId,
                    resultVariableName: this.getResultVariableName(event.resultVariableId, currentDevice),
                    savedStatus: event.savedStatus,
                    state:
                        event.savedStatus === EventSaveStatus.ERROR
                            ? ''
                            : !event.isActive
                            ? 'NA'
                            : this.getEventState(currentEvents, event),
                    styles: {
                        state: event.isActive ? this.getStatusStyles(this.getEventState(currentEvents, event)) : '',
                    },
                    type: event.type,
                    tooltipValue: {
                        resultVariableName: currentDevice ? currentDevice.name ?? currentDevice.defaultName : '',
                        expressionString: `${updateTitle} ${this.dataTypeService.reformatDate(new Date(event.updated))}`,
                    },
                    logicEventType: event.logicEventType,
                    errorStatus: event.errorStatus,
                    created: event.created,
                    accordionChildRows: [],
                    disabledActions: event.isActive ? [ColumnsActionTypeEnum.actionBtnsEdit] : [ColumnsActionTypeEnum.view],
                    preIcons: this.getPreIcons(event),
                    postIcons: this.getPostIcons(event, false, null, currentDevice),
                };
            });

        const resultVariableIdsForByMultiEvents = Array.from(
            new Set(events.filter((e) => e.logicEventType !== LogicEventType.default).map((event) => event.resultVariableId)),
        );

        const multiRows: LogicalEventRowInterface[] = await Promise.all(
            resultVariableIdsForByMultiEvents.map(async (id) => {
                const eventsByVariableId = events.filter((e) => e.resultVariableId === id && e.logicEventType !== LogicEventType.default);

                const currentDevice = devices.find((d) => d.variables.find((v) => v.id === id));
                const currentRegistrator: Device = devices.find((registrator) => registrator.id === eventsByVariableId[0].registratorId);
                const currentVariable = currentDevice.variables.find((f) => f.id === eventsByVariableId[0].resultVariableId);
                let currentEvents: ActiveEvents;

                if (eventsStatus && eventsStatus.length) {
                    currentEvents = eventsStatus.find((item) => item.registratorId === currentRegistrator.id);
                }

                const childStates: string[] = eventsByVariableId.map((e) => {
                    return this.getEventState(currentEvents, e);
                });

                return {
                    eventAlertMessageId: null,
                    expression: null,
                    expressionString: '',
                    id: eventsByVariableId[0].id,
                    copyId: '',
                    isAcknowledgeable: null,
                    device: currentDevice,
                    iconTooltipValue: currentDevice.type === DeviceTypeEnum.datalogger ? 'events.logicalEvents.activeDatalogger' : null,
                    isActive: eventsByVariableId[0].limitIsActive,
                    name: currentVariable?.customName ?? currentVariable?.name ?? eventsByVariableId[0].name ?? '',
                    registrator: currentRegistrator.name || currentRegistrator.defaultName,
                    registratorId: currentRegistrator.id,
                    resultVariableId: id,
                    resultVariableName: this.getResultVariableName(id, currentDevice),
                    savedStatus: this.getMultiEventMainSavedStatus(eventsByVariableId),
                    state: eventsByVariableId[0].limitIsActive ? this.getStateForMultiEvent(childStates) : 'NA',
                    styles: {
                        state: eventsByVariableId[0].limitIsActive ? this.getStatusStyles(this.getStateForMultiEvent(childStates)) : '',
                    },
                    tooltipValue: {
                        resultVariableName: currentDevice ? currentDevice.name ?? currentDevice.defaultName : '',
                        expressionString: `${updateTitle} ${this.dataTypeService.reformatDate(new Date(events[0].updated))}`,
                    },
                    isMainAcordion: true,
                    accordionChildIds: [...eventsByVariableId.map((e) => e.id)],
                    toggle: false,
                    logicEventType: null,
                    errorStatus: eventsByVariableId[0].errorStatus,
                    created: new Date(new Date(eventsByVariableId[0].created).getTime() + 1000).toISOString(),
                    accordionChildRows: await this.getMiltiSubRows(
                        eventsByVariableId,
                        currentRegistrator,
                        currentDevice,
                        currentEvents,
                        updateTitle,
                    ),
                    disabledActions: this.getDisabledActions(eventsByVariableId[0], currentDevice),
                    preIcons: this.getPreIcons(null),
                    postIcons: this.getPostIcons(null, true, null, currentDevice),
                };
            }),
        );

        return [...rows, ...multiRows];
    }

    private async getMiltiSubRows(
        events: LogicEvent[],
        currentRegistrator: Device,
        currentDevice: Device,
        currentEvents: ActiveEvents,
        updateTitle: string,
    ): Promise<LogicalEventRowInterface[]> {
        return (
            await Promise.all(
                events.map(async (event, index) => {
                    const currentVariable = currentDevice.variables.find((f) => f.id === event.resultVariableId);
                    return {
                        eventAlertMessageId: event.eventAlertMessageId,
                        expression: event.expression,
                        expressionString: this.getExpressionString(event),
                        id: event.id,
                        copyId: event.id,
                        isActive: event.isActive && event.limitIsActive,
                        limitIsActive: event.isActive,
                        isAcknowledgeable: event.isAcknowledgeable,
                        name: await this.getLimitEventName(
                            currentVariable?.customName ?? currentVariable?.name ?? event.name,
                            event.logicEventType,
                        ),
                        registrator: currentRegistrator.name || currentRegistrator.defaultName,
                        registratorId: event.registratorId,
                        resultVariableId: event.resultVariableId,
                        resultVariableName: this.getResultVariableName(event.resultVariableId, currentDevice),
                        savedStatus: event.savedStatus,
                        state:
                            event.savedStatus === EventSaveStatus.ERROR
                                ? ''
                                : event.isActive && event.limitIsActive
                                ? this.getEventState(currentEvents, event)
                                : 'NA',
                        styles: {
                            state:
                                event.isActive && event.limitIsActive ? this.getStatusStyles(this.getEventState(currentEvents, event)) : '',
                        },
                        tooltipValue: {
                            resultVariableName: currentDevice ? currentDevice.name ?? currentDevice.defaultName : '',
                            expressionString: `${updateTitle} ${this.dataTypeService.reformatDate(new Date(event.updated))}`,
                        },
                        isChildAcordion: true,
                        emptyCellNames: ['isActive', 'edit'],
                        accordionChildHide: true,
                        logicEventType: event.logicEventType,
                        errorStatus: event.errorStatus,
                        created: new Date(new Date(events[0].created).getTime() + index).toISOString(),
                        lastChildAccordion: index === 0,
                        accordionChildRows: [],
                        disabledActions: [],
                        preIcons: this.getPreIcons(event),
                        postIcons: this.getPostIcons(event, false, null, currentDevice),
                    };
                }),
            )
        )
            .filter((e) => e.limitIsActive)
            .sort((a, b) => (new Date(b.created).getTime() > new Date(a.created).getTime() ? 1 : -1));
    }

    getDisabledActions(events: LogicEvent, currentDevice: Device): ColumnsActionTypeEnum[] {
        if (currentDevice?.type === DeviceTypeEnum.datalogger) {
            return events.limitIsActive
                ? [ColumnsActionTypeEnum.actionBtnsEdit, ColumnsActionTypeEnum.actionBtnsDuplicate, ColumnsActionTypeEnum.actionBtnsDelete]
                : [ColumnsActionTypeEnum.view, ColumnsActionTypeEnum.actionBtnsDuplicate, ColumnsActionTypeEnum.actionBtnsDelete];
        }

        return events.limitIsActive
            ? [ColumnsActionTypeEnum.actionBtnsEdit, ColumnsActionTypeEnum.actionBtnsDuplicate]
            : [ColumnsActionTypeEnum.view, ColumnsActionTypeEnum.actionBtnsDuplicate];
    }

    getPreIcons(e: LogicEvent): IconInterface<LogicalEventIconActions>[] {
        if (!e) {
            return [];
        }

        let result: IconInterface<LogicalEventIconActions>[] = [
            {
                path: './assets/design/icons/table-operations/copy.svg',
                action: LogicalEventIconActions.copyBuffer,
                cellNames: ['copyId'],
            },
        ];

        switch (e.logicEventType) {
            case LogicEventType.alarmDeadlineMax:
                result = [
                    ...result,
                    {
                        path: './assets/design/icons/table-operations/alarm-arrow.svg',
                        action: null,
                        cellNames: ['name'],
                    },
                ];
                break;
            case LogicEventType.alarmDeadlineMin:
                result = [
                    ...result,
                    {
                        path: './assets/design/icons/table-operations/alarm-arrow.svg',
                        action: null,
                        styles: {
                            transform: 'rotate(180deg)',
                        },
                        cellNames: ['name'],
                    },
                ];
                break;
            case LogicEventType.attentionDeadlineMax:
                result = [
                    ...result,
                    {
                        path: './assets/design/icons/table-operations/attention-arrow.svg',
                        action: null,
                        cellNames: ['name'],
                    },
                ];
                break;
            case LogicEventType.attentionDeadlineMin:
                result = [
                    ...result,
                    {
                        path: './assets/design/icons/table-operations/attention-arrow.svg',
                        action: null,
                        styles: {
                            transform: 'rotate(180deg)',
                        },
                        cellNames: ['name'],
                    },
                ];
                break;
        }

        return result;
    }

    getPostIcons(
        e: LogicEvent,
        isAccordion?: boolean,
        row?: LogicalEventRowInterface,
        currentDevice?: Device,
    ): IconInterface<LogicalEventIconActions>[] {
        if (!e) {
            if (isAccordion || (isAccordion && row && row.accordionChildRows.length)) {
                if (currentDevice?.isWorkExpedition) {
                    return [
                        {
                            path: './assets/design/icons/transport/expedition-on.svg',
                            action: null,
                            tooltip: 'devices.tooltipExpedition',
                            cellNames: ['name'],
                        },
                        {
                            path:
                                row && row.toggle
                                    ? './assets/design/icons/table-operations/accordion-down.svg'
                                    : './assets/design/icons/table-operations/accordion-up.svg',
                            action: LogicalEventIconActions.accordionToggle,
                            styles: {
                                width: '13px',
                            },
                            cellNames: ['name'],
                        },
                    ];
                } else {
                    return [
                        {
                            path:
                                row && row.toggle
                                    ? './assets/design/icons/table-operations/accordion-down.svg'
                                    : './assets/design/icons/table-operations/accordion-up.svg',
                            action: LogicalEventIconActions.accordionToggle,
                            cellNames: ['name'],
                        },
                    ];
                }
            }

            return [];
        }

        const result: IconInterface<LogicalEventIconActions>[] = [];

        if (e && currentDevice?.isWorkExpedition) {
            result.push({
                path: './assets/design/icons/transport/expedition-on.svg',
                action: null,
                tooltip: 'devices.tooltipExpedition',
                styles: {
                    marginRight: '21px',
                },
                cellNames: ['name'],
            });
        }

        switch (e.savedStatus) {
            case EventSaveStatus.ERROR:
                result.push({
                    path: './assets/design/icons/table-operations/status-saved-error.svg',
                    action: null,
                    tooltip: this.configurationService.getCurrentMessage(e.errorStatus),
                    cellNames: ['name'],
                });
                break;
            case EventSaveStatus.PROCESS:
                result.push({
                    path: './assets/design/icons/table-operations/spinner.svg',
                    action: null,
                    cellNames: ['name'],
                });
                break;
        }

        return result;
    }

    private async getLimitEventName(name: string, logicalEventType: LogicEventType): Promise<string> {
        switch (logicalEventType) {
            case LogicEventType.alarmDeadlineMin:
                return await this.translateService
                    .get('events.logicalEvents.multiEvent.bottomLimitNameAlarm', { eventName: name })
                    .toPromise();
            case LogicEventType.alarmDeadlineMax:
                return await this.translateService
                    .get('events.logicalEvents.multiEvent.topLimitNameAlarm', { eventName: name })
                    .toPromise();
            case LogicEventType.attentionDeadlineMin:
                return await this.translateService
                    .get('events.logicalEvents.multiEvent.bottomLimitNameAttention', { eventName: name })
                    .toPromise();
            case LogicEventType.attentionDeadlineMax:
                return await this.translateService
                    .get('events.logicalEvents.multiEvent.topLimitNameAttention', { eventName: name })
                    .toPromise();
            default:
                return '';
        }
    }

    findCurrentOperandType(variable: Variable): OperandConstantType {
        if (!variable) {
            return;
        }

        switch (variable.type) {
            case VariableType.BOOL:
                return OperandConstantType.BOOL;
            case VariableType.INT:
                return OperandConstantType.INT;
            case VariableType.BYTE:
                return OperandConstantType.INT;
            case VariableType.SHORT:
                return OperandConstantType.INT;
            case VariableType.STRING:
                return OperandConstantType.STRING;
            case VariableType.FLOAT:
                return OperandConstantType.FLOAT;
        }
    }
}

import { FinanceUpdateServicesInterface } from '../../_interfaces/finance-update-services.interface';
import { CreateServicesInterface, FinanceServicesRowInterface } from '../../_interfaces/finance-services.interface';

export class GetFinanceServices {
    static readonly type = '[FinanceServices] GetFinanceServices';
}

export class UpdateFinanceService {
    static readonly type = '[FinanceServices] UpdateFinanceService';

    constructor(public data: FinanceUpdateServicesInterface) {}
}

export class NewFinanceService {
    static readonly type = '[FinanceServices] NewFinanceService';
}

export class CreateFinanceService {
    static readonly type = '[FinanceServices] CreateFinanceService';

    constructor(public data: CreateServicesInterface) {}
}

export class DeleteFinanceService {
    static readonly type = '[FinanceServices] DeleteFinanceService';

    constructor(public id: string) {}
}

export class CloseEditingServiceRow {
    static readonly type = '[FinanceServices] CloseEditingServiceRow';

    constructor(public id: string, public savedRow?: FinanceServicesRowInterface) {}
}

export class SetEditingServiceRow {
    static readonly type = '[FinanceServices] SetEditingServiceRow';

    constructor(public id: string) {}
}

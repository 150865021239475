import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {SetFullScreen} from '../_store/actions/layout.actions';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    constructor(
        private store: Store) {
    }

    async enterFullscreen(): Promise<void> {
        const elem = document.getElementById('content');
        await elem.requestFullscreen();
        this.store.dispatch(new SetFullScreen(true));

    }

    async exitFullscreen(): Promise<void> {
        await document.exitFullscreen();
        this.store.dispatch(new SetFullScreen(false));

    }
}

import { Axis } from 'src/app/app-shared-elements/_interfaces/Axis';
import { ColumnsTableInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { GroupEditGeneralSettingsRowInterface } from '../../_interfaces/group-edit-general-settings-row.interface';

export class SetGeneralSettingsRows {
    static readonly type = '[GeneralSettingsGroup] SetGeneralSettingsRows';

    constructor() {}
}

export class UpdateGeneralSettingsRow {
    static readonly type = '[GeneralSettingsGroup] UpdateGeneralSettingsRow';

    constructor(public row: GroupEditGeneralSettingsRowInterface, public axis?: Axis[]) {}
}

export class ChangeGroupEditColumns {
    static readonly type = '[GeneralSettingsGroup] ChangeGroupEditColumns';

    constructor(public columns: ColumnsTableInterface[]) {}
}

export class UpdateVariableName {
    static readonly type = '[GeneralSettingsGroup] UpdateVariableName';

    constructor(public data: { id: string; newName: string }) {}
}

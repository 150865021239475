import { StatusResponseInterceptor } from './app-shared-elements/_interceptors/status-response.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AppTemplateModule } from './app-template/app-template.module';
import { AuthInterceptor } from './auth/auth.interceptor';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { UsersModule } from './admin/users/users.module';
import { ThemeModule } from './theme/theme.module';
import { FormsModule } from '@angular/forms';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { AppSharedElementsModule } from './app-shared-elements/app-shared-elements.module';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import { stateList } from './store';
import { AppRoutingModule } from './app-routing.module';
import { AdminModule } from './admin/admin.module';
import { PostResponseInterceptor } from './app-shared-elements/_interceptors/post-response.interceptor';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { environment } from 'src/environments/environment';
import { DecryptionInterceptorInterceptor } from './app-shared-elements/_interceptors/decryption-interceptor.interceptor';
import { MobileModule } from './mobile/mobile.module';
import { CheckConfirmModule } from './check-confirm/check-confirm.module';

const config: SocketIoConfig = { url: `${window.location.origin}/client`, options: { autoConnect: false, timeout: 5000, path: '/socket.io-user'} };

export function HttpLoaderFactory(http: HttpClient): any {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        HttpClientXsrfModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN',
        }),
        BrowserModule,
        HttpClientModule,
        AuthModule,
        AppTemplateModule,
        UsersModule,
        ThemeModule,
        CommonModule,
        FormsModule,
        SocketIoModule.forRoot(config),
        FormsModule,
        DragDropModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        AppSharedElementsModule,
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
        NgxsResetPluginModule.forRoot(),
        NgxsModule.forRoot([...stateList], {
            selectorOptions: {
                // injectContainerState: false,
                suppressErrors: true,
            },
        }),
        AppRoutingModule,
        AdminModule,
        MobileModule,
        CheckConfirmModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DecryptionInterceptorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: StatusResponseInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PostResponseInterceptor,
            multi: true,
        },
        // StatusResponseInterceptor,
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule {}

import {
    ParamsFilterForClient,
    ParamsFilterTypeEnum,
    ParamsTimeTypeEnum
} from "../../../app-shared-elements/_interfaces/params.interface";

export const initialFilterAdminLogs: ParamsFilterForClient[] = [

    // date time
    {
        property: 'created',
        value: {
            to: null,
            from: null,
            time: null,
            type: ParamsTimeTypeEnum.ALL_TIME
        },
        type: ParamsFilterTypeEnum.DATETIME
    },


    // drop down
    {
        property: 'userLogin',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true
    },
    {
        property: 'userIp',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true
    },
    {
        property: 'srcId',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true
    },
];

import {CreateAdminInterface, UpdateAdminInterface} from '../../_interface/create-admin.interface';

export class GetAdmins {
    static readonly type = '[AdminsList] GetAdmins';
}

export class GetAdminsRows {
    static readonly type = '[AdminsList] GetAdminsRows';
}

export class AddNewAdmin {
    static readonly type = '[AdminsList] AddNewAdmin';
}

export class CreateAdmin {
    static readonly type = '[AdminsList] CreateAdmin';
    constructor(public data: CreateAdminInterface) {
    }
}

export class UpdateAdmin {
    static readonly type = '[AdminsList] UpdateAdmin';
    constructor(public data: UpdateAdminInterface) {
    }
}

export class DeleteAdmin {
    static readonly type = '[AdminsList] DeleteAdmin';
    constructor(public id: string) {
    }
}

export class ChangeAdminActivity {
    static readonly type = '[AdminsList] ChangeAdminActivity';
    constructor(public data: { id: string, value: boolean }) {
    }
}

export class CloseEditingAdminsRows {
    static readonly type = '[AdminsList] CloseEditingAdminsRows';
}

export class SetEditingAdminsRows {
    static readonly type = '[AdminsList] SetEditingAdminsRows';
    constructor(public id: string) {
    }
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {HTTP_STATUS} from 'src/app/app-shared-elements/_enums/status.enum';
import {TooltipStatusEnum} from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import {ApiResponse} from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import {UnitInterface} from 'src/app/app-shared-elements/_interfaces/unit.interface';
import {NotificationsService} from 'src/app/app-shared-elements/_services/notifications.service';
import {UnitRowInterface} from '../../_interfaces/units.intefaces';
import {AddNewUnit, CloseEditingUnitRow, CreateUnit, DeleteUnit, GetUnits, SetEditingUnitRow, UpdateUnit} from '../actions/units.actions';
import {append, patch} from '@ngxs/store/operators';
import {DisabledTypeEnum} from 'src/app/app-shared-elements/_enums/disabled-type.enum';

export interface UnitsStateModel {
    units: UnitInterface[];
    unitsRows: UnitRowInterface[];
    isEditingMode: boolean;
}

const UNITS_TOKEN = new StateToken<UnitsStateModel>('unitsState');

@State<UnitsStateModel>({
    name: UNITS_TOKEN,
    defaults: {
        units: [],
        unitsRows: [],
        isEditingMode: false
    }
})
@Injectable()
export class UnitsState {
    constructor(
        private http: HttpClient,
        private notificationsService: NotificationsService
    ) {

    }

    @Selector()
    static getUnits(state: UnitsStateModel): UnitInterface[] {
        return state.units;
    }

    @Selector()
    static getUnitsRows(state: UnitsStateModel): UnitRowInterface[] {
        return state.unitsRows.sort((a, b) => a.id > b.id ? 1 : -1);
    }

    @Selector()
    static getIsEditingMode(state: UnitsStateModel): boolean {
        return state.isEditingMode;
    }


    @Action(GetUnits)
    async getUnits(ctx: StateContext<UnitsStateModel>): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = await this.http.get('api/control/device-unit').toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {

            ctx.setState({
                ...state,
                units: result && result.status === HTTP_STATUS.SUCCESS ? result.data : [],
                unitsRows: result && result.status === HTTP_STATUS.SUCCESS
                    ? result.data.map(item => ({...(item as UnitInterface), isEditing: false}))
                    : []
            });
        } else {
            ctx.setState({
                ...state,
                units: [],
                unitsRows: []
            });
        }
    }

    @Action(CreateUnit)
    async createUnits(ctx: StateContext<UnitsStateModel>, payload: CreateUnit): Promise<void> {
        const state = ctx.getState();

        const duplicateUnit = state.units?.length && state.units.find(u => u.unit === payload.data.unit);
        if (duplicateUnit) {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'admin.units.duplicate');
            return;
        }

        const result: ApiResponse<UnitInterface> = await this.http.post('api/control/device-unit', {...payload.data}).toPromise().catch(e => console.log(e)) as ApiResponse<UnitInterface>;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                units: [result.data, ...state.units],
                unitsRows: [{
                    ...result.data,
                    isEditing: false,
                    require: {
                        unit: true,
                        name: true
                    },
                    disabledType: DisabledTypeEnum.unitsTable
                },
                    ...state.unitsRows.filter(row => !row.isNew)],
                isEditingMode: false
            });
            ctx.dispatch(new GetUnits());
            return;
        }

        ctx.setState({
            ...state,
            unitsRows: state.unitsRows.filter(row => !row.isNew)
        });
    }

    @Action(UpdateUnit)
    async updateUnits(ctx: StateContext<UnitsStateModel>, payload: UpdateUnit): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse<UnitInterface> = await this.http.put('api/control/device-unit', {...payload.data}).toPromise().catch(e => console.log(e)) as ApiResponse<UnitInterface>;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                units: [result.data, ...state.units],
                unitsRows: [
                    {
                        ...result.data,
                        isEditing: false,
                        require: {
                            unit: true,
                            name: true
                        },
                        disabledType: DisabledTypeEnum.unitsTable
                    },
                    ...state.unitsRows.filter(row => row.id !== result.data.id)
                ],
                isEditingMode: false,
            });

            return;
        }

        ctx.setState({
            ...state,
            unitsRows: state.unitsRows.filter(row => !row.isNew)
        });
    }

    @Action(AddNewUnit)
    addNewUnit(ctx: StateContext<UnitsStateModel>): void {
        const state = ctx.getState();

        const emptyUnit: UnitRowInterface = {
            name: '',
            unit: '',
            isEditing: true,
            isNew: true,
            require: {
                unit: true,
                name: true
            },
            disabledType: DisabledTypeEnum.unitsTable
        };

        ctx.setState(
            patch({
                unitsRows: append([emptyUnit])
            }));
    }

    @Action(CloseEditingUnitRow)
    closeEditingRow(ctx: StateContext<UnitsStateModel>, payload: CloseEditingUnitRow): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            unitsRows: state.unitsRows.map(row => ({...(payload.savedRow && payload.savedRow.id === row.id ? payload.savedRow : row), isEditing: false})),
            isEditingMode: false
        });

        ctx.dispatch(GetUnits);
    }

    @Action(SetEditingUnitRow)
    setEditingRow(ctx: StateContext<UnitsStateModel>, payload: SetEditingUnitRow): void {
        const state = ctx.getState();

        if (state.unitsRows.find(r => r.isEditing)) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, 'admin.units.alreadyEditing');
            return;
        }

        ctx.setState({
            ...state,
            unitsRows: state.unitsRows.map(row => ({...row, isEditing: row.id === payload.id})),
            isEditingMode: true
        });
    }

    @Action(DeleteUnit)
    async deleteUnit(ctx: StateContext<UnitsStateModel>, payload: DeleteUnit): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = await this.http.delete(`api/control/device-unit/`, {params: {id: payload.id}}).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                units: state.units.filter(u => u.id !== payload.id),
                unitsRows: state.unitsRows.filter(r => r.id !== payload.id),
            });

            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        }
    }
}

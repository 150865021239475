import { Injectable } from '@angular/core';
import { Device } from '../../_interfaces/Device';
import { CreationType } from '../../_enums/registrator-sync-status.enu';
import { VariablesNameEnum } from '../../_enums/variables-name.enum';
import { DeviceTypeEnum } from '../../_enums/device-type.enum';
import { CoreValueVariable, Variable, VariableType } from '../../_interfaces/Variable';
import { AlarmTypeEnum } from '../../../events/_enums/alarm.enum';
import { TechnologicEvent } from '../../../events/_interfaces/TechnologicEvent';
import { ActiveEvents } from '../../../events/_interfaces/ActiveEvents';
import { ActiveEventsService } from '../../../events/_services/active-events.service';
import { DataTypeService } from '../data-type.service';
import { RegistratorTypeEnum } from '../../_enums/registrator-type.enum';

@Injectable({
    providedIn: 'root',
})
export class PerformDeviceService {
    private readonly allowRegex = /^[e][0-9]{1,5}$/;
    private readonly allowValue = ['NA', "немає зв'язку", 'немає даних'];

    public isSetStatusesByEvents: boolean;
    private readonly checkPropertyVariable = ['savedStatusConfiguration', 'savedStatusValue', 'unit', 'customName'];
    private readonly checkPropertyDevice = ['syncStatus', 'name', 'isConnect', 'isArchiveLoading', 'isLoggedOffline', 'daysArchivesLeft'];

    constructor(
        private activeEventsService: ActiveEventsService,
        private dataTypeService: DataTypeService,
    ) {}

    public async setValue(devices: Device[]): Promise<Device[]> {
        return await Promise.all(
            devices.map(async (device) => {
                if (device.creationType === CreationType.VIRTUAL) {
                    device.signal = '-';
                    device.battery = '-';
                    device.variables = this.performVariableValue(device.variables);

                    return device;
                }

                const signal = device.variables.find((variable) => variable.name === VariablesNameEnum.RSSI)?.currentValue || 'Na';
                const battery = device.variables.find((variable) => variable.name === VariablesNameEnum.BatteryLevel)?.currentValue || 'Na';
                const isTransport = device.variables.find((variable) => variable.name === VariablesNameEnum.IsTransport)?.currentValue || false;
                const sleepStatus = device.variables.find((variable) => variable.name === VariablesNameEnum.SleepStatus)?.currentValue || false;

                switch (device.type) {
                    case DeviceTypeEnum.registrator:
                        device.periodArchive = +device.variables.find((variable1) => variable1.name === VariablesNameEnum.ArchivePeriod)?.currentValue || 60;
                        device.signal = +signal === 1 ? 'eth' : signal;
                        device.battery = +battery === 101 ? 'PS' : battery;
                        device.sleepStatus = this.parseToBool(sleepStatus);
                        break;
                    case DeviceTypeEnum.coordinator:
                        device.signal = '-';
                        device.battery = '-';
                        break;

                    default:
                        device.sleepStatus = this.parseToBool(sleepStatus);
                        device.isTransport = this.parseToBool(isTransport);
                        device.signal = signal;
                        device.battery = this.parseBatteryValue(battery);
                        break;
                }

                device.variables = this.performVariableValue(device.variables);

                return device;
            }),
        );
    }

    public async setStatus(devices: Device[], activeEvents: ActiveEvents[], isInit?: boolean): Promise<{ all: Device[]; updated: Device[] }> {
        const updatedDevice: Device[] = [];
        if (!this.activeEventsService.isInit && !isInit) {
            this.isSetStatusesByEvents = false;
            return {
                all: devices.map((device) => {
                    device.status = AlarmTypeEnum.initialization;
                    device.variables = this.setStatusVariable(device.variables, AlarmTypeEnum.initialization);

                    return device;
                }),
                updated: [],
            };
        }

        devices = devices.map((device) => {
            device.status = AlarmTypeEnum.ok;
            device.variables = this.setStatusVariable(device.variables, AlarmTypeEnum.ok);
            return device;
        });

        const deviceEvents: TechnologicEvent[] = [];

        await Promise.all(
            activeEvents.map(async (activeEvent) => {
                let resultDeviceId: string;

                await Promise.all(
                    activeEvent.events.map(async (event) => {
                        // const isLastEventOfVariable = activeEvent.events.length > 1 ? !!activeEvent.events.find(e => e.ts <= event.ts && e.variableId === event.variableId) : true;

                        if ((!event.isAknowledgeable && event.tsCleared) || (event.tsCleared && event.isAknowledgeable && event.tsAcknowledget)) {
                            return;
                        }

                        if (!event.variableId) {
                            const currentDeviceIndex = devices.findIndex((device) => device.id === event.deviceId);
                            if (currentDeviceIndex > -1) {
                                deviceEvents.push(event);
                                const currentStatus = deviceEvents.find((f) => f.alarmType === AlarmTypeEnum.alarm);
                                devices[currentDeviceIndex].eStatus = true;
                                devices[currentDeviceIndex].status = this.getStatusPriority(deviceEvents, event);
                                return;
                            }
                        }

                        const devicesByVariable: Device[] = this.getDeviceByVariableId(devices, event.variableId);
                        if (!devicesByVariable.length) {
                            return;
                        }

                        devicesByVariable.forEach((deviceByVariable) => {
                            const indexDevice = devices.findIndex((device) => device.id === deviceByVariable.id);

                            if (indexDevice === -1) {
                                return;
                            }

                            // if (isLastEventOfVariable) {
                            devices[indexDevice].variables = this.setStatusVariable(devices[indexDevice].variables, event);

                            // }

                            if (this.getOldPriorityStatus(event.alarmType, devices[indexDevice].status)) {
                                devices[indexDevice].status = event.alarmType;
                                resultDeviceId = devices[indexDevice].id;
                                updatedDevice.push(devices[indexDevice]);
                            }
                        });
                    }),
                );
            }),
        );

        this.isSetStatusesByEvents = !!activeEvents && !!activeEvents.length;
        return {
            all: devices,
            updated: updatedDevice,
        };
    }

    getStatusPriority(events: TechnologicEvent[], event: TechnologicEvent): AlarmTypeEnum {
        const currentAlarmAckn = events.find((f) => f.alarmType === AlarmTypeEnum.alarm && f.isAknowledgeable && !f.tsAcknowledget);
        const currentAttentionAckn = events.find((f) => f.alarmType === AlarmTypeEnum.attention && f.isAknowledgeable && !f.tsAcknowledget);
        const currentAlarm = events.find((f) => f.alarmType === AlarmTypeEnum.alarm);

        if (currentAlarmAckn) {
            return AlarmTypeEnum.alarm;
        }

        if (!currentAlarmAckn && currentAttentionAckn) {
            return AlarmTypeEnum.attention;
        }

        if (!currentAlarmAckn && !currentAttentionAckn && currentAlarm) {
            return AlarmTypeEnum.alarm;
        }

        return event.alarmType;
    }

    public setConnectVirtualVariable(variables: Variable[], coreVariables: CoreValueVariable[]): { all: Variable[]; isUpdate: boolean; lastActive: string } {
        let isUpdate = false;
        let lastActive = 0;
        variables = variables.map((variable) => {
            const variableWithArchivePeriod: CoreValueVariable = coreVariables.find(
                (v) => (v.deviceId === variable.originDeviceId || v.originRegistratorId === variable.originRegistratorId) && v.name === VariablesNameEnum.ArchivePeriod,
            );

            const variableWithSeepStatus: CoreValueVariable = coreVariables.find((v) => v.deviceId === variable.originDeviceId && v.name === VariablesNameEnum.SleepStatus);

            variable.sleepStatus = variableWithSeepStatus?.currentValue;
            // console.log(variable.sleepStatus, coreVariables, variable.originDeviceId)
            const oldValue = variable.isConnect;

            const variableLastActive = new Date(variable.lastActive).getTime();

            if (variableLastActive > lastActive) {
                lastActive = variableLastActive;
            }

            if (!variableWithArchivePeriod) {
                variable.isConnect = false;
            } else {
                variable.isConnect = variable.isSocketActive && this.isConnect(variable, variableWithArchivePeriod.currentValue);
            }

            if (oldValue !== variable.isConnect) {
                isUpdate = true;
            }

            return variable;
        });

        return {
            all: variables,
            isUpdate,
            lastActive: lastActive ? new Date(lastActive).toISOString() : null,
        };
    }

    public async setConnect(devices: Device[]): Promise<{ all: Device[]; isUpdate: boolean }> {
        let tempRegistrator: Device;
        let deviceTemp: Device;
        let isUpdate = false;
        let coordinatorStatus: boolean;

        // console.error('start', devices)
        try {
            devices = await Promise.all(
                devices.map(async (device: Device) => {
                    deviceTemp = { ...device };

                    if (device.creationType === CreationType.VIRTUAL) {
                        device.isConnect = true;
                        const variablesData = this.setConnectVirtualVariable(device.variables, device.coreVariables);

                        device.variables = variablesData.all;
                        if (variablesData.isUpdate) {
                            isUpdate = true;
                        }

                        if (variablesData.lastActive && variablesData.lastActive > device.lastActive) {
                            device.lastActive = variablesData.lastActive;
                        }

                        return device;
                    }
                    tempRegistrator = devices.find((dev) => dev.id === device.registratorId);

                    if (tempRegistrator) {
                        coordinatorStatus = !!+tempRegistrator.variables.find((variable) => variable.name === VariablesNameEnum.CoordinatorStatus)?.currentValue;
                    }

                    device.variables = device.variables.map((variable) => {
                        variable.isConnect = true;
                        return variable;
                    });
                    switch (device.type) {
                        case DeviceTypeEnum.registrator:
                            if (device.registratorType === RegistratorTypeEnum.datalogger) {
                                device.isConnect = device ? this.isConnect(device, device.periodArchive) && device.isSocketActive : false;
                            } else {
                                device.isConnect = device.isSocketActive;
                            }
                            break;
                        case DeviceTypeEnum.coordinator:
                            device.isActive = tempRegistrator ? tempRegistrator.isActive : false;
                            device.isConnect = tempRegistrator.ids.includes(device.id) && !!coordinatorStatus && device.isSocketActive;
                            break;
                        case DeviceTypeEnum.datalogger:
                            const currentArchivePeriod = device?.variables?.find(v => v.name === VariablesNameEnum.ArchivePeriod);
                            device.isActive = tempRegistrator ? tempRegistrator.isActive : false;
                            device.isConnect = tempRegistrator ? this.isConnect(device, +currentArchivePeriod?.currentValue) && device.isSocketActive : false;
                            break;

                        default:
                            try {
                                device.isActive = tempRegistrator ? tempRegistrator.isActive : false;
                                device.isConnect = tempRegistrator ? this.isConnect(device, tempRegistrator.periodArchive) && device.isSocketActive && !!coordinatorStatus : false;
                            } catch (e) {
                                device.isConnect = false;
                                console.error(e);
                            }
                    }

                    if (device.isConnect !== deviceTemp.isConnect) {
                        isUpdate = true;
                    }
                    tempRegistrator = null;
                    coordinatorStatus = null;
                    return device;
                }),
            );
            return {
                all: devices,
                isUpdate,
            };
        } catch (e) {
            console.log(e);
        }
    }

    public setStatusVariable(variables: Variable[], event: TechnologicEvent | AlarmTypeEnum): Variable[] {
        return variables.map((variable) => {
            if (typeof event === 'number') {
                variable.status = event as AlarmTypeEnum;
                return variable;
            }

            if (variable.originVariableId === event.variableId || variable.id === event.variableId) {
                if (this.getOldPriorityStatus(event.alarmType, variable.status) && (!event.tsCleared || (!event.tsAcknowledget && event.isAknowledgeable))) {
                    variable.status = event.alarmType;
                }

                if (event.isAknowledgeable && !event.tsAcknowledget) {
                    variable.isAknowledgeable = true;
                }
            }
            return variable;
        });
    }

    public parseToBool(n: boolean | string | number): boolean {
        if (n === 'true') {
            return true;
        }

        if (n === 'false') {
            return false;
        }

        return Boolean(+n);
    }

    public isValidErrorByReg(value: string): boolean {
        if (!value || typeof value !== 'string') {
            return false;
        }
        const reg = value.match(this.allowRegex);

        return reg && !!reg.length;
    }

    public isErrorVariable(variable: Variable): boolean {
        return this.allowValue.includes(variable.currentValue) || this.isValidErrorByReg(variable.currentValue);
    }

    public performByTypeVariableValue(variable: Variable): any {
        if (this.isErrorVariable(variable)) {
            return variable.currentValue;
        }
        switch (variable.type) {
            case VariableType.BOOL:
                return this.parseToBool(variable.currentValue);
            case VariableType.DATE:
                if (isNaN(variable.currentValue) || variable.name === VariablesNameEnum.ArchiveStart || variable.name === VariablesNameEnum.ArchiveStop) {
                    return variable.currentValue;
                }

                return this.dataTypeService.reformatShortDate(+variable.currentValue * 1000);
            default:
                return variable.currentValue;
        }
    }

    public performVariableValue(variables: Variable[]): Variable[] {
        return variables
            .filter((f) => f.hidden !== 1)
            .map((variable) => {
                variable.validationType = variable.type;
                variable.type = variable.type.includes('_') ? (variable.type.split('_')[0] as VariableType) : variable.type;

                switch (variable.name) {
                    case VariablesNameEnum.BatteryLevel:
                        if (+variable.currentValue === 101) {
                            variable.currentValue = 'PS';
                        }
                        break;
                    case VariablesNameEnum.RSSI:
                        if (+variable.currentValue === 1) {
                            variable.currentValue = 'eth';
                        }
                        break;
                    default:
                        variable.currentValue = this.performByTypeVariableValue(variable);
                }
                return variable;
            });
    }

    public getOldPriorityStatus(newStatus: AlarmTypeEnum, oldStatus: AlarmTypeEnum): boolean {
        if (!oldStatus) {
            return true;
        }

        if (oldStatus === AlarmTypeEnum.alarm) {
            return false;
        }

        return newStatus === AlarmTypeEnum.alarm || newStatus === AlarmTypeEnum.attention;
    }

    public getDeviceByVariableId(devices: Device[], variableId: string): Device[] {
        if (variableId === null) {
            return [];
        }

        return devices.filter((device) => {
            return device.variables.find((variable) => variable.id === variableId || variable.originVariableId === variableId);
        });
    }

    public setTimeReceipt(devices: Device[]): Device[] {
        if (!devices) {
            return [];
        }

        return devices.map((device) => {
            device.timeReceipt = Date.now();
            return device;
        });
    }

    public checkOnCache(devices: Device[], cacheTime: number): boolean {
        if (!devices.length) {
            return false;
        }

        for (const device of devices) {
            if (Date.now() > device.timeReceipt + cacheTime) {
                return false;
            }
        }

        return true;
    }

    public parseBatteryValue(value: any): any {
        // if (!isNaN(value)) {
        //     value = Math.floor((+value / 65500) * 100);
        // }

        if (!value && value !== 0) {
            return 'Na';
        }

        return value;
    }

    parseDateTime(time: string | number): number {
        if (typeof time === 'string') {
            return new Date(time).getTime();
        }

        return +time;
    }

    public isConnect(item: Device | Variable, periodArchive): boolean {
        const withPeriod = new Date(item.lastActive).getTime() + periodArchive * 1000 + 1000 * 15;
        return withPeriod > Date.now();
    }
}

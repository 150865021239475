import { DetailsDeviceInterface, DetailsDeviceRows } from '../../_interface/details-device.interface';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    ClearDetailsDevice,
    GetRoleInfoRows,
    InitDetailsDeviceCoordinatorRow,
    InitDetailsDeviceDataloggerRow,
    InitDetailsDeviceObject,
    InitDetailsDeviceOptions,
    InitDetailsDeviceRegistratorRow,
    InitDetailsDeviceSensorRow,
    SetCurrentAction,
    SetIsChangeModeDetailDevice,
    SetRegistratorStats,
    UpdateDetailsDevice,
    UpdateDetailsDeviceUnit,
    UpdateDeviceAlarmSettings,
} from '../actions/details-device.actions';
import { DeviceTypeEnum } from '../../../app-shared-elements/_enums/device-type.enum';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { GroupsState } from '../../../groups/_store/states/groups.state';
import { ColumnModeEnum, ColumnsTableInterface, ColumnTypeEnum, ColumnValueAlignEnum } from '../../../app-shared-elements/_interfaces/ColumnsTable';
import { HttpClient } from '@angular/common/http';
import { DetailsDeviceService } from '../../_services/details-device.service';
import { UnitInterface } from 'src/app/app-shared-elements/_interfaces/unit.interface';
import { DetailDeviceInfoItemInterface } from '../../_interface/details-device-info-item.interface';
import { CreationType } from '../../../app-shared-elements/_enums/registrator-sync-status.enu';
import { DetailsDeviceRoleInfoInterface } from '../../_interface/details-device-role-info.interface';
import { PermissionsState } from '../../../app-shared-elements/_store/states/permissions.state';
import { RolesService } from '../../../admin/roles/_services/roles.service';
import { DetailsDeviceTabsEnum } from '../../_enums/details-device-tabs.enum';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { DeviceStatInterface } from 'src/app/device-dashboard/_interfaces/device-stat.interface';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { VariablesNameEnum } from 'src/app/app-shared-elements/_enums/variables-name.enum';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { PermissionService } from '../../../app-shared-elements/_services/permission.service';
import { MethodPermission, ResourceAction } from '../../../app-shared-elements/_enums/permission.enum';

export interface DetailsDeviceStateModel {
    detailsDeviceObject: DetailsDeviceInterface;
    updateDetailsDevice: DetailsDeviceInterface;
    detailsDeviceOptions: SelectOptionInterface<string, string, Device>[];
    detailsRegistratorRows: DetailsDeviceRows[];
    detailsSensorRows: DetailsDeviceRows[];
    detailsCoordinatorRows: DetailsDeviceRows[];
    detailsDataloggerRows: DetailsDeviceRows[];
    detailsDeviceColumns: ColumnsTableInterface[];
    detailsVDeviceColumns: ColumnsTableInterface[];
    isChangeMode: boolean;
    skeletonInfo: DetailDeviceInfoItemInterface[];
    roleInfoRows: DetailsDeviceRoleInfoInterface[];
    registratorStats: DeviceStatInterface;
}

const DETAILS_STATE_TOKEN = new StateToken<DetailsDeviceStateModel>('detailsDeviceObject');

const initDetailsDeviceColumns: ColumnsTableInterface[] = [
    {
        title: 'detailsDevice.tableColumn.variable',
        grow: true,
        small: false,
        type: ColumnTypeEnum.text,
        tooltip: true,
        isClick: true,
        isEditable: true,
        name: 'variableName',
        postIcons: true,
        styles: true,
    },
    {
        title: 'detailsDevice.tableColumn.value',
        grow: false,
        small: false,
        maxWidth: '160px',
        minWidth: '120px',
        align: ColumnValueAlignEnum.left,
        type: ColumnTypeEnum.text,
        name: 'value',
    },
    {
        title: 'detailsDevice.tableColumn.type',
        grow: false,
        small: false,
        maxWidth: '280px',
        minWidth: '240px',
        align: ColumnValueAlignEnum.left,
        type: ColumnTypeEnum.text,
        name: 'type',
        isMultilang: true,
    },
    {
        title: 'detailsDevice.tableColumn.unit',
        grow: false,
        small: false,
        maxWidth: '180px',
        minWidth: '180px',
        align: ColumnValueAlignEnum.left,
        type: ColumnTypeEnum.select,
        name: 'unit',
        isEditable: true,
        optionsName: 'unitOptions',
        placeholderForSelectColumn: 'detailsDevice.placeholderForChooseUnits',
        currentValueForSelect: 'unit',
    },
    {
        title: 'detailsDevice.tableColumn.alarm',
        grow: false,
        small: false,
        minWidth: '140px',
        maxWidth: '140px',
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'statusAlarm',
    },
    {
        title: 'detailsDevice.tableColumn.acknowledge',
        grow: false,
        small: false,
        minWidth: '160px',
        maxWidth: '160px',
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'statusAcknowledgeable',
    },
    // {
    //     title: 'detailsDevice.tableColumn.comments',
    //     grow: true,
    //     small: false,
    //     type: ColumnTypeEnum.text,
    //     name: 'comment'
    // }
];

const initDetailsVDeviceColumns: ColumnsTableInterface[] = [
    {
        title: 'detailsDevice.tableColumn.variable',
        grow: true,
        small: false,
        type: ColumnTypeEnum.text,
        name: 'variableName',
        tooltip: true,
        isClick: true,
        postIcons: true,
        styles: true,
    },
    {
        title: 'detailsDevice.tableColumn.value',
        grow: false,
        small: false,
        maxWidth: '160px',
        minWidth: '120px',
        align: ColumnValueAlignEnum.left,
        type: ColumnTypeEnum.text,
        name: 'value',
    },
    {
        title: 'detailsDevice.tableColumn.type',
        grow: false,
        small: false,
        maxWidth: '280px',
        minWidth: '240px',
        align: ColumnValueAlignEnum.left,
        type: ColumnTypeEnum.text,
        name: 'type',
        isMultilang: true,
    },
    {
        title: 'detailsDevice.tableColumn.unit',
        grow: false,
        small: false,
        maxWidth: '180px',
        minWidth: '180px',
        type: ColumnTypeEnum.text,
        name: 'unit',
    },
    {
        title: 'detailsDevice.tableColumn.alarm',
        grow: false,
        small: false,
        minWidth: '140px',
        maxWidth: '140px',
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'statusAlarm',
        isDisable: true,
    },
    {
        title: 'detailsDevice.tableColumn.acknowledge',
        grow: false,
        small: false,
        minWidth: '160px',
        maxWidth: '160px',
        type: ColumnTypeEnum.input,
        mode: ColumnModeEnum.checkbox,
        name: 'statusAcknowledgeable',
        isDisable: true,
    },
    // {
    //     title: 'detailsDevice.tableColumn.comments',
    //     grow: true,
    //     small: false,
    //     type: ColumnTypeEnum.text,
    //     name: 'comment'
    // }
];

const initialSkeletonInfo: DetailDeviceInfoItemInterface[] = [
    {
        title: 'detailsDevice.act',
        value: '',
        deviceProperty: 'isActive',
        isActive: true,
    },
    {
        title: 'detailsDevice.status',
        value: '',
        deviceProperty: 'status',
        status: true,
    },
    {
        title: 'detailsDevice.signal',
        value: '',
        deviceProperty: 'signal',
        signal: true,
        isHide: true,
    },
    {
        title: 'detailsDevice.connect',
        value: '',
        deviceProperty: 'isConnect',
        isConnect: true,
        isHide: true,
    },
    {
        title: 'detailsDevice.battery',
        value: '',
        deviceProperty: 'battery',
        battery: true,
        isHide: true,
    },
    {
        title: 'detailsDevice.name',
        value: '',
        deviceProperty: 'defaultName',
    },
    {
        title: 'detailsDevice.internalId',
        value: '',
        deviceProperty: 'internalId',
    },
    {
        title: 'detailsDevice.serverId',
        value: '',
        deviceProperty: 'id',
    },
    {
        title: 'detailsDevice.ip',
        value: '',
        deviceProperty: 'ip',
        onlyRegistrator: true,
        isHide: true,
    },
    {
        title: 'detailsDevice.mac',
        value: '',
        deviceProperty: 'mac',
        onlyRegistrator: true,
        isHide: true,
    },
    {
        title: 'detailsDevice.registrationDate',
        value: '',
        deviceProperty: 'created',
        isDate: true,
    },
    {
        title: 'detailsDevice.lastAct',
        value: '',
        deviceProperty: 'lastActive',
        isDate: true,
        isHide: true,
    },
];

@State<DetailsDeviceStateModel>({
    name: DETAILS_STATE_TOKEN,
    defaults: {
        detailsDeviceObject: null,
        updateDetailsDevice: null,
        detailsDeviceOptions: [],
        detailsRegistratorRows: [],
        detailsSensorRows: [],
        detailsCoordinatorRows: [],
        detailsDataloggerRows: [],
        detailsDeviceColumns: initDetailsDeviceColumns,
        detailsVDeviceColumns: initDetailsVDeviceColumns,
        isChangeMode: false,
        skeletonInfo: initialSkeletonInfo,
        roleInfoRows: [],
        registratorStats: null,
    },
})
@Injectable()
export class DetailsDeviceState {
    constructor(
        private store: Store,
        private http: HttpClient,
        private detailDeviceService: DetailsDeviceService,
        private rolesService: RolesService,
        private notificationServices: NotificationsService,
        private permissionService: PermissionService,
    ) {}

    @Selector([DevicesState])
    static getDetailsDeviceObject(state: DetailsDeviceStateModel): DetailsDeviceInterface {
        return state.detailsDeviceObject;
    }

    @Selector()
    static getDetailsDeviceOptions(state: DetailsDeviceStateModel): SelectOptionInterface<string, string, Device>[] {
        return state.detailsDeviceOptions;
    }

    @Selector()
    static getDetailsRegistratorRows(state: DetailsDeviceStateModel): DetailsDeviceRows[] {
        return state.detailsRegistratorRows;
    }

    @Selector()
    static getDetailsSensorRows(state: DetailsDeviceStateModel): DetailsDeviceRows[] {
        return state.detailsSensorRows;
    }

    @Selector()
    static getDetailsCoordinatorRows(state: DetailsDeviceStateModel): DetailsDeviceRows[] {
        return state.detailsCoordinatorRows;
    }

    @Selector()
    static getDetailsDataloggerRows(state: DetailsDeviceStateModel): DetailsDeviceRows[] {
        return state.detailsDataloggerRows;
    }

    @Selector()
    static getDetailsDeviceColumn(state: DetailsDeviceStateModel): ColumnsTableInterface[] {
        return state.detailsDeviceColumns;
    }

    @Selector()
    static getDetailsVDeviceColumn(state: DetailsDeviceStateModel): ColumnsTableInterface[] {
        return state.detailsVDeviceColumns;
    }

    @Selector()
    static getSkeletonInfo(state: DetailsDeviceStateModel): DetailDeviceInfoItemInterface[] {
        return state.skeletonInfo;
    }

    @Selector()
    static getRoleInfoRows(state: DetailsDeviceStateModel): DetailsDeviceRoleInfoInterface[] {
        return state.roleInfoRows;
    }

    @Selector()
    static getCurrentRegistratorStat(state: DetailsDeviceStateModel): DeviceStatInterface {
        return state.registratorStats;
    }

    @Action(SetRegistratorStats)
    setRegistratorStats(ctx: StateContext<DetailsDeviceStateModel>): void {
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);
        const state = ctx.getState();

        if (!currentRegistrator) {
            ctx.setState({
                ...state,
                registratorStats: null,
            });
            return;
        }

        const totalMemoryVariable: Variable = currentRegistrator.variables.find((v) => v.name === VariablesNameEnum.TotalMemory);
        const freeMemoryVariable: Variable = currentRegistrator.variables.find((v) => v.name === VariablesNameEnum.FreeMemory);

        if (!totalMemoryVariable || !freeMemoryVariable) {
            ctx.setState({
                ...state,
                registratorStats: null,
            });
            return;
        }

        const busy = +(+totalMemoryVariable.currentValue - +freeMemoryVariable.currentValue).toFixed(2);
        const pieValue = ((100 * busy) / +totalMemoryVariable.currentValue).toFixed();

        ctx.setState({
            ...state,
            registratorStats: {
                pieValue: `${pieValue}`,
                busy: `${(busy / 1000).toFixed(2)} `,
                free: `${(+freeMemoryVariable.currentValue / 1000).toFixed(2)} `,
            },
        });
    }

    @Action(InitDetailsDeviceOptions)
    async InitDetailsDeviceOptions(ctx: StateContext<DetailsDeviceStateModel>): Promise<void> {
        const state = ctx.getState();
        const registrators: Device[] = this.store.selectSnapshot(DevicesState.getRegistrators);

        const allRegistrators = {
            key: null,
            value: 'detailsDevice.allRegistrators',
            type: 'text',
            property: null,
        };

        ctx.setState({
            ...state,
            detailsDeviceOptions: [
                allRegistrators,
                ...registrators
                    .map((r) => {
                        return {
                            key: r.id,
                            value: r.name ?? r.defaultName,
                            type: 'text',
                            property: r,
                            postIconPath: `./assets/design/icons/custom-select/registrator${r.creationType === CreationType.ORIGIN ? '' : '-virtual'}.svg`,
                        };
                    })
                    .sort((a, b) => (a.value > b.value ? 1 : -1)),
            ],
        });
    }

    @Action(InitDetailsDeviceObject)
    async InitDetailsDeviceObject(ctx: StateContext<DetailsDeviceStateModel>, payload: InitDetailsDeviceObject): Promise<void> {
        const state = ctx.getState();
        const devices: Device[] = this.store.selectSnapshot(DevicesState.getDevices);
        state.detailsDeviceObject = null;
        let result: DetailsDeviceInterface;
        const currentRegistrator = devices.find((d) => d.type === DeviceTypeEnum.registrator && d.id === payload.registratorId);
        result = {
            registrator: {
                ...currentRegistrator,
                isAction: false,
                isKioskMode: currentRegistrator.variables?.find((v) => v.name === VariablesNameEnum.KioskMode)?.currentValue ?? false,
            },
            sensors: devices
                .filter((d) => d.type === DeviceTypeEnum.sensor && d.registratorId === payload.registratorId)
                .map((device) => {
                    return {
                        ...device,
                        isAction: false,
                        isFocusInputChangeTimeOfSensor: false,
                    };
                })
                .sort((a, b) => (a.id > b.id ? 1 : -1)),
            coordinators: devices
                .filter((d) => d.type === DeviceTypeEnum.coordinator && d.registratorId === payload.registratorId)
                .map((device) => {
                    return {
                        ...device,
                        isAction: false,
                        isFocusInputChangeTimeOfSensor: false,
                    };
                })
                .sort((a, b) => (a.id > b.id ? 1 : -1)),
            dataloggers: devices
                .filter((d) => d.type === DeviceTypeEnum.datalogger && d.registratorId === payload.registratorId)
                .map((device) => {
                    return {
                        ...device,
                        isAction: false,
                        isFocusInputChangeTimeOfSensor: false,
                    };
                })
                .sort((a, b) => (a.id > b.id ? 1 : -1)),
        };

        ctx.setState({
            ...state,
            detailsDeviceObject: result,
        });
    }

    @Action(UpdateDetailsDevice)
    async UpdateDetailsDevice(ctx: StateContext<DetailsDeviceStateModel>, payload: UpdateDetailsDevice): Promise<void> {
        const state = ctx.getState();

        const devices: Device[] = payload.devices;

        const currentRegistrator: Device = devices.find((f) => f.id === state.detailsDeviceObject.registrator.id);

        if (state.detailsDeviceObject) {
            ctx.setState({
                ...state,
                detailsDeviceObject: {
                    ...state.detailsDeviceObject,
                    sensors: state.detailsDeviceObject.sensors.map((s) => {
                        const currentSensor: Device = devices.find((d) => d.id === s.id);

                        if (!currentSensor) {
                            return s;
                        }

                        return {
                            ...currentSensor,
                            isAction: s.isAction,
                            name: currentSensor.name ?? currentSensor.defaultName,
                            isFocusInputChangeTimeOfSensor: false,
                        };
                    }),
                    registrator: (state.detailsDeviceObject.registrator = {
                        ...currentRegistrator,
                        name: currentRegistrator.name ?? currentRegistrator.defaultName,
                        isAction: state.detailsDeviceObject.registrator.isAction,
                        isKioskMode: currentRegistrator.variables?.find((v) => v.name === VariablesNameEnum.KioskMode)?.currentValue,
                    }),
                    coordinators: state.detailsDeviceObject.coordinators.map((coordinator) => {
                        const currentCoordinator = devices.find((f) => f.id === coordinator.id);
                        if (!currentCoordinator) {
                            return coordinator;
                        }

                        return {
                            ...currentCoordinator,
                            isAction: coordinator.isAction,
                            name: coordinator.name ?? coordinator.defaultName,
                            isFocusInputChangeTimeOfSensor: false,
                        };
                    }),
                    dataloggers: state.detailsDeviceObject.dataloggers.map((datalogger) => {
                        const currentDatalogger = devices.find((f) => f.id === datalogger.id);
                        if (!currentDatalogger) {
                            return datalogger;
                        }

                        return {
                            ...currentDatalogger,
                            isAction: datalogger.isAction,
                            name: datalogger.name ?? datalogger.defaultName,
                            isFocusInputChangeTimeOfSensor: false,
                        };
                    }),
                },
                detailsRegistratorRows: state.detailsRegistratorRows.map((row) => {
                    if (!currentRegistrator) {
                        return row;
                    }

                    const currentVariable = currentRegistrator.variables.find((variable) => variable.id === row.id);

                    return {
                        ...row,
                        value: currentVariable.currentValue,
                        variableName: currentVariable.customName ? currentVariable.customName : currentVariable.name,
                        unit: currentVariable.unitName,
                        status: currentVariable.status,
                        styles: {
                            variableName: this.detailDeviceService.getStatusStyle(currentVariable.status),
                        },
                    };
                }),
                detailsSensorRows: state.detailsSensorRows.map((row) => {
                    const currentSensor = devices.find((f) => f.id === row.deviceId);

                    if (!currentSensor) {
                        return row;
                    }

                    const currentVariable = currentSensor.variables.find((variable) => variable.id === row.id);
                    return {
                        ...row,
                        value: currentVariable.currentValue,
                        unit: currentVariable.unitName,
                        variableName: currentVariable.customName ? currentVariable.customName : currentVariable.name,
                        status: currentVariable.status,
                        styles: {
                            variableName: this.detailDeviceService.getStatusStyle(currentVariable.status),
                        },
                    };
                }),
                detailsCoordinatorRows: state.detailsCoordinatorRows.map((row) => {
                    const currentCoordinator = devices.find((f) => f.id === row.id);

                    if (!currentCoordinator) {
                        return row;
                    }

                    const currentVariable = currentCoordinator.variables.find((variable) => variable.id === row.id);
                    return {
                        ...row,
                        value: currentVariable.currentValue,
                        variableName: currentVariable.customName ? currentVariable.customName : currentVariable.name,
                        unit: currentVariable.unitName,
                        status: currentVariable.status,
                        styles: {
                            variableName: this.detailDeviceService.getStatusStyle(currentVariable.status),
                        },
                    };
                }),
                detailsDataloggerRows: state.detailsDataloggerRows.map((row) => {
                    const currentDatalogger = devices.find((f) => f.id === row.deviceId);

                    if (!currentDatalogger) {
                        return row;
                    }

                    const currentVariable = currentDatalogger.variables.find((variable) => variable.id === row.id);
                    return {
                        ...row,
                        value: currentVariable.currentValue,
                        variableName: currentVariable.customName ? currentVariable.customName : currentVariable.name,
                        unit: currentVariable.unitName,
                        status: currentVariable.status,
                        styles: {
                            variableName: this.detailDeviceService.getStatusStyle(currentVariable.status),
                        },
                    };
                }),
            });
        }
    }

    @Action(InitDetailsDeviceRegistratorRow)
    async InitDetailsDeviceRegistratorRow(ctx: StateContext<DetailsDeviceStateModel>): Promise<void> {
        const state = ctx.getState();

        let detailsRegistratorRows: DetailsDeviceRows[] = [];

        const groups = this.store.selectSnapshot(GroupsState.getGroups);
        if (!groups) {
            return;
        }

        const units: UnitInterface[] = this.store.selectSnapshot(DevicesState.getUnitsForUser);

        state.detailsDeviceObject.registrator.variables.forEach((variable) => {
            const currentGroup = groups.find((f) => f.deviceId === state.detailsDeviceObject.registrator.id);
            if (!currentGroup) {
                return;
            }
            const currentGroupSetting = currentGroup.variableGroupSettings.find((f) => f.variableId === variable.id);

            detailsRegistratorRows.push({
                id: variable.id,
                deviceId: state.detailsDeviceObject.registrator.id,
                variableName: variable.customName || variable.name,
                name: variable.customName || variable.name,
                value: variable.currentValue,
                type: `detailsDevice.types.${variable.type}`,
                comment: null,
                statusAlarm: currentGroupSetting.statusAlarm,
                statusAcknowledgeable: currentGroupSetting.statusAknowledgeable,
                tooltipValue: { variableName: variable.name },
                deviceType: state.detailsDeviceObject.registrator.creationType,
                unit: variable.unitName,
                unitId: variable.unitId,
                variableId: variable.id,
                unitOptions: this.detailDeviceService.getUnitOptions(units),
                status: variable.status,
                rowTabs: DetailsDeviceTabsEnum.registratorRow,
                internalId: variable.internalId,
                disableClick: !this.permissionService.checkPermissionComponent({
                    registratorId: state.detailsDeviceObject.registrator.id,
                    action: ResourceAction.GROUPS,
                    method: [MethodPermission.UPDATE],
                }),
                styles: {
                    variableName: this.detailDeviceService.getStatusStyle(variable.status),
                },
                isSelectDisabled: !this.store.selectSnapshot(PermissionsState.getIsCheckPermission),
                isDisable: state.detailsDeviceObject.registrator.creationType === CreationType.VIRTUAL,
                postIcons: this.detailDeviceService.getPostIcons(variable),
                checkboxProperties: this.detailDeviceService.getCheckboxProperties(
                    state.detailsDeviceObject.registrator.creationType === CreationType.VIRTUAL ? initDetailsVDeviceColumns : initDetailsDeviceColumns,
                    currentGroupSetting,
                ),
            });
        });

        detailsRegistratorRows = detailsRegistratorRows.sort((a, b) => (a.internalId > b.internalId ? 1 : -1));

        ctx.setState({
            ...state,
            detailsRegistratorRows,
        });
    }

    @Action(InitDetailsDeviceSensorRow)
    async InitDetailsDeviceSensorRow(ctx: StateContext<DetailsDeviceStateModel>, payload: InitDetailsDeviceSensorRow): Promise<void> {
        const state = ctx.getState();

        let detailsSensorRows: DetailsDeviceRows[] = [];
        const groups = this.store.selectSnapshot(GroupsState.getGroups);

        if (!groups) {
            return;
        }

        const units: UnitInterface[] = this.store.selectSnapshot(DevicesState.getUnitsForUser);

        const currentSensor = state.detailsDeviceObject.sensors.find((f) => f.id === payload.currentSensorId);
        if (!currentSensor) {
            return;
        }
        const currentGroup = groups.find((f) => f.deviceId === currentSensor.id);
        if (!currentGroup) {
            return;
        }

        currentSensor.variables.forEach((variable) => {
            const currentGroupVariable = currentGroup.variableGroupSettings.find((f) => f.variableId === variable.id);

            detailsSensorRows.push({
                id: variable.id,
                deviceId: currentSensor.id,
                variableName: variable.customName ?? variable.name,
                name: variable.customName ?? variable.name,
                value: variable.currentValue,
                type: `detailsDevice.types.${variable.type}`,
                comment: null,
                statusAlarm: currentGroupVariable.statusAlarm,
                statusAcknowledgeable: currentGroupVariable.statusAknowledgeable,
                tooltipValue: { variableName: variable.name },
                unit: variable.unitName,
                unitId: variable.unitId,
                variableId: variable.id,
                unitOptions: this.detailDeviceService.getUnitOptions(units),
                status: variable.status,
                rowTabs: DetailsDeviceTabsEnum.sensorRow,
                internalId: variable.internalId,
                disableClick: !this.permissionService.checkPermissionComponent({
                    registratorId: state.detailsDeviceObject.registrator.id,
                    action: ResourceAction.GROUPS,
                    method: [MethodPermission.UPDATE],
                }),
                isSelectDisabled: !this.store.selectSnapshot(PermissionsState.getIsCheckPermission),
                styles: {
                    variableName: this.detailDeviceService.getStatusStyle(variable.status),
                },
                postIcons: this.detailDeviceService.getPostIcons(variable),
                checkboxProperties: this.detailDeviceService.getCheckboxProperties(initDetailsDeviceColumns, currentGroupVariable),
            });
        });

        detailsSensorRows = detailsSensorRows.sort((a, b) => (a.internalId > b.internalId ? 1 : -1));

        ctx.setState({
            ...state,
            detailsSensorRows,
        });
    }

    @Action(InitDetailsDeviceCoordinatorRow)
    async InitDetailsDeviceCoordinatorRow(ctx: StateContext<DetailsDeviceStateModel>, payload: InitDetailsDeviceCoordinatorRow): Promise<void> {
        const state = ctx.getState();

        let detailsCoordinatorRows: DetailsDeviceRows[] = [];
        const groups = this.store.selectSnapshot(GroupsState.getGroups);
        const units: UnitInterface[] = this.store.selectSnapshot(DevicesState.getUnitsForUser);

        if (state.detailsDeviceObject.coordinators && state.detailsDeviceObject.coordinators.length) {
            const currentCoordinator = state.detailsDeviceObject.coordinators.find((f) => f.id === payload.currentCoordinatorId);

            currentCoordinator.variables.forEach((variable) => {
                const currentGroup = groups.find((f) => f.deviceId === variable.deviceId);
                if (!currentGroup) {
                    return;
                }
                const currentGroupVariable = currentGroup.variableGroupSettings.find((f) => f.variableId === variable.id);
                detailsCoordinatorRows.push({
                    id: variable.id,
                    deviceId: currentCoordinator.id,
                    variableName: variable.customName || variable.name,
                    name: variable.customName || variable.name,
                    value: variable.currentValue,
                    type: `detailsDevice.types.${variable.type}`,
                    comment: null,
                    statusAlarm: currentGroupVariable.statusAlarm,
                    statusAcknowledgeable: currentGroupVariable.statusAknowledgeable,
                    tooltipValue: { variableName: variable.name },
                    unit: variable.unitName,
                    unitId: variable.unitId,
                    variableId: variable.id,
                    unitOptions: this.detailDeviceService.getUnitOptions(units),
                    status: variable.status,
                    rowTabs: DetailsDeviceTabsEnum.coordinatorRow,
                    internalId: variable.internalId,
                    disableClick: !this.permissionService.checkPermissionComponent({
                        registratorId: state.detailsDeviceObject.registrator.id,
                        action: ResourceAction.GROUPS,
                        method: [MethodPermission.UPDATE],
                    }),
                    isSelectDisabled: !this.store.selectSnapshot(PermissionsState.getIsCheckPermission),
                    styles: {
                        variableName: this.detailDeviceService.getStatusStyle(variable.status),
                    },
                    postIcons: this.detailDeviceService.getPostIcons(variable),
                    checkboxProperties: this.detailDeviceService.getCheckboxProperties(initDetailsDeviceColumns, currentGroupVariable),
                });
            });
        }

        detailsCoordinatorRows = detailsCoordinatorRows.sort((a, b) => (a.internalId > b.internalId ? 1 : -1));

        ctx.setState({
            ...state,
            detailsCoordinatorRows,
        });
    }

    @Action(InitDetailsDeviceDataloggerRow)
    async InitDetailsDeviceDataloggerRow(ctx: StateContext<DetailsDeviceStateModel>, payload: InitDetailsDeviceDataloggerRow): Promise<void> {
        const state = ctx.getState();

        let detailsDataloggerRows: DetailsDeviceRows[] = [];
        const groups = this.store.selectSnapshot(GroupsState.getGroups);
        const units: UnitInterface[] = this.store.selectSnapshot(DevicesState.getUnitsForUser);

        if (state.detailsDeviceObject.dataloggers && state.detailsDeviceObject.dataloggers.length) {
            const currentDatalogger = state.detailsDeviceObject.dataloggers.find((f) => f.id === payload.currentDataloggerId);

            currentDatalogger.variables.forEach((variable) => {
                const currentGroup = groups.find((f) => f.deviceId === variable.deviceId);
                if (!currentGroup) {
                    return;
                }
                const currentGroupVariable = currentGroup.variableGroupSettings.find((f) => f.variableId === variable.id);
                detailsDataloggerRows.push({
                    id: variable.id,
                    deviceId: currentDatalogger.id,
                    variableName: variable.customName || variable.name,
                    name: variable.customName || variable.name,
                    value: variable.currentValue,
                    type: `detailsDevice.types.${variable.type}`,
                    comment: null,
                    statusAlarm: currentGroupVariable.statusAlarm,
                    statusAcknowledgeable: currentGroupVariable.statusAknowledgeable,
                    tooltipValue: { variableName: variable.name },
                    unit: variable.unitName,
                    unitId: variable.unitId,
                    variableId: variable.id,
                    unitOptions: this.detailDeviceService.getUnitOptions(units),
                    status: variable.status,
                    rowTabs: DetailsDeviceTabsEnum.coordinatorRow,
                    internalId: variable.internalId,
                    disableClick: !this.permissionService.checkPermissionComponent({
                        registratorId: state.detailsDeviceObject.registrator.id,
                        action: ResourceAction.GROUPS,
                        method: [MethodPermission.UPDATE],
                    }),
                    isSelectDisabled: !this.store.selectSnapshot(PermissionsState.getIsCheckPermission),
                    styles: {
                        variableName: this.detailDeviceService.getStatusStyle(variable.status),
                    },
                    postIcons: this.detailDeviceService.getPostIcons(variable),
                    checkboxProperties: this.detailDeviceService.getCheckboxProperties(initDetailsDeviceColumns, currentGroupVariable),
                });
            });
        }

        detailsDataloggerRows = detailsDataloggerRows.sort((a, b) => (a.internalId > b.internalId ? 1 : -1));

        ctx.setState({
            ...state,
            detailsDataloggerRows,
        });
    }

    @Action(ClearDetailsDevice)
    clearState(ctx: StateContext<DetailsDeviceStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            detailsDeviceObject: null,
            updateDetailsDevice: null,
            // detailsDeviceOptions: [],
            detailsRegistratorRows: [],
            detailsSensorRows: [],
            detailsCoordinatorRows: [],
            detailsDataloggerRows: [],
        });
    }

    @Action(SetIsChangeModeDetailDevice)
    setIsChangeModeDetailDevice(ctx: StateContext<DetailsDeviceStateModel>, payload: SetIsChangeModeDetailDevice): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            isChangeMode: payload.value,
        });
    }

    @Action(SetCurrentAction)
    setCurrentAction(ctx: StateContext<DetailsDeviceStateModel>, payload: SetCurrentAction): void {
        const state = ctx.getState();

        switch (payload.device.type) {
            case DeviceTypeEnum.registrator:
                ctx.setState({
                    ...state,
                    detailsDeviceObject: {
                        ...state.detailsDeviceObject,
                        registrator: {
                            ...state.detailsDeviceObject.registrator,
                            isAction: !state.detailsDeviceObject.registrator.isAction,
                        },
                        sensors: state.detailsDeviceObject.sensors.map((s) => ({ ...s, isAction: false })),
                        coordinators: state.detailsDeviceObject.coordinators.map((c) => ({ ...c, isAction: false })),
                        dataloggers: state.detailsDeviceObject.dataloggers.map((c) => ({ ...c, isAction: false })),
                    },
                });
                break;
            case DeviceTypeEnum.sensor:
                ctx.setState({
                    ...state,
                    detailsDeviceObject: {
                        ...state.detailsDeviceObject,
                        sensors: state.detailsDeviceObject.sensors.map((s) => {
                            if (s.id === payload.device.id) {
                                return {
                                    ...s,
                                    isAction: !s.isAction,
                                };
                            }
                            return {
                                ...s,
                                isAction: false,
                            };
                        }),
                        coordinators: state.detailsDeviceObject.coordinators.map((c) => ({ ...c, isAction: false })),
                        dataloggers: state.detailsDeviceObject.dataloggers.map((c) => ({ ...c, isAction: false })),
                        registrator: {
                            ...state.detailsDeviceObject.registrator,
                            isAction: false,
                        },
                    },
                });
                break;
            case DeviceTypeEnum.coordinator:
                ctx.setState({
                    ...state,
                    detailsDeviceObject: {
                        ...state.detailsDeviceObject,
                        coordinators: state.detailsDeviceObject.coordinators.map((c) => {
                            if (c.id === payload.device.id) {
                                return {
                                    ...c,
                                    isAction: !c.isAction,
                                };
                            }
                            return {
                                ...c,
                                isAction: false,
                            };
                        }),
                        sensors: state.detailsDeviceObject.sensors.map((s) => ({ ...s, isAction: false })),
                        dataloggers: state.detailsDeviceObject.dataloggers.map((s) => ({ ...s, isAction: false })),
                        registrator: {
                            ...state.detailsDeviceObject.registrator,
                            isAction: false,
                        },
                    },
                });
                break;
            case DeviceTypeEnum.datalogger:
                ctx.setState({
                    ...state,
                    detailsDeviceObject: {
                        ...state.detailsDeviceObject,
                        dataloggers: state.detailsDeviceObject.dataloggers.map((c) => {
                            if (c.id === payload.device.id) {
                                return {
                                    ...c,
                                    isAction: !c.isAction,
                                };
                            }
                            return {
                                ...c,
                                isAction: false,
                            };
                        }),
                        sensors: state.detailsDeviceObject.sensors.map((s) => ({ ...s, isAction: false })),
                        coordinators: state.detailsDeviceObject.coordinators.map((s) => ({ ...s, isAction: false })),
                        registrator: {
                            ...state.detailsDeviceObject.registrator,
                            isAction: false,
                        },
                    },
                });
                break;
        }
    }

    @Action(GetRoleInfoRows)
    getRoleInfoRows(ctx: StateContext<DetailsDeviceStateModel>): void {
        const state = ctx.getState();
        const roles = this.store.selectSnapshot(PermissionsState.getRoles);
        const roleInfoRows: DetailsDeviceRoleInfoInterface[] = [];
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);
        roles
            .filter((f) => f.id === currentRegistrator.roleId)
            .map(async (role) => {
                roleInfoRows.push({
                    role: role.name,
                    description: `<span class="permissions">${await this.rolesService.getDescriptionForCurrentRole(role.permissions)}</span>`,
                });
            });

        ctx.setState({
            ...state,
            roleInfoRows,
        });
    }

    @Action(UpdateDetailsDeviceUnit)
    async updateDetailsDeviceUnit(ctx: StateContext<DetailsDeviceStateModel>, payload: UpdateDetailsDeviceUnit): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .post('/api/devices/update-unit-variable', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationServices.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
            });
        } else {
            this.notificationServices.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(UpdateDeviceAlarmSettings)
    async updateDeviceAlarmSettings(ctx: StateContext<DetailsDeviceStateModel>, payload: UpdateDeviceAlarmSettings): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post('/api/groups/update-alarm-settings', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationServices.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
                detailsRegistratorRows: state.detailsRegistratorRows.map((row) => {
                    if (row.variableId === result.data.variableId) {
                        return {
                            ...row,
                            checkboxProperties: this.detailDeviceService.getCheckboxProperties(
                                state.detailsDeviceObject.registrator.creationType === CreationType.VIRTUAL ? initDetailsVDeviceColumns : initDetailsDeviceColumns,
                                result.data,
                            ),
                        };
                    }

                    return row;
                }),
                detailsSensorRows: state.detailsSensorRows.map((row) => {
                    if (row.variableId === result.data.variableId) {
                        return {
                            ...row,
                            checkboxProperties: this.detailDeviceService.getCheckboxProperties(initDetailsDeviceColumns, result.data),
                        };
                    }
                    return row;
                }),
                detailsCoordinatorRows: state.detailsCoordinatorRows.map((row) => {
                    if (row.variableId === result.data.variableId) {
                        return {
                            ...row,
                            checkboxProperties: this.detailDeviceService.getCheckboxProperties(initDetailsDeviceColumns, result.data),
                        };
                    }
                    return row;
                }),
                detailsDataloggerRows: state.detailsDataloggerRows.map((row) => {
                    if (row.variableId === result.data.variableId) {
                        return {
                            ...row,
                            checkboxProperties: this.detailDeviceService.getCheckboxProperties(initDetailsDeviceColumns, result.data),
                        };
                    }
                    return row;
                }),
            });
        } else {
            this.notificationServices.onEmit(TooltipStatusEnum.error, false);
        }
    }
}

import { ConstructorEditTypeEnum } from '../_enums/constructor-edit-type.enum';
import { ObjectTypeEnum } from '../_enums/object-type.enum';

export interface ConstructorEditInterface {
    name: string;
    type: ConstructorEditTypeEnum;
    editType: EditTypeInterface;
    isColorSelect?: boolean;
    isColumn?: boolean;
    placeholder?: string;
    objectType: ObjectTypeEnum | ObjectTypeEnum[];
    isFixedNumber?: boolean;
}

export enum EditTypeInterface {
    input = 'input',
    file = 'file',
    btn = 'btn',
    textArea = 'text-area',
    checkbox = 'checkbox',
}

import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetCurrency } from '../actions/currency.actions';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../../_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../_enums/status.enum';
import { AuthState } from '../../../auth/_store/states/auth.state';
import { TranslateService } from '@ngx-translate/core';

export interface CurrencyStateModel {
    currency: string;
}

const CURRENCY_TOKEN = new StateToken<CurrencyStateModel>('currencyState');

@State<CurrencyStateModel>({
    name: CURRENCY_TOKEN,
    defaults: {
        currency: null,
    },
})
@Injectable()
export class CurrencyState {
    constructor(private http: HttpClient, private store: Store, private translateService: TranslateService) {}

    @Selector()
    static getCurrency(state: CurrencyStateModel): string {
        return state.currency;
    }

    @Action(GetCurrency)
    async getCurrency(ctx: StateContext<CurrencyStateModel>): Promise<void> {
        const isAdmin = this.store.selectSnapshot(AuthState.getIsAdmin);
        const result: ApiResponse = (await this.http
            .get(isAdmin ? '/api/control/billing-currency/default' : '/api/billing/currency/default')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        const UAH = await this.translateService.get('billing.transactions.UAH').toPromise();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                currency: result.data.currency ?? UAH,
            });
        }
    }
}

import {ParamsFilterForClient, ParamsFilterTypeEnum, ParamsTimeTypeEnum} from 'src/app/app-shared-elements/_interfaces/params.interface';

export const initialFilterDeviceLog: ParamsFilterForClient[] = [
    {
        title: 'logs.events.filters.waitAckn',
        property: 'isAknowledgeable',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'tsAcknowledget',
                value: true,
                type: ParamsFilterTypeEnum.NULLABLE,
            },
            {
                property: 'isAknowledgeable',
                value: true,
                type: ParamsFilterTypeEnum.BOOLEAN,
            }
        ],
    },



    // datetime
    {
        property: 'created',
        value: {
            to: null,
            from: null,
            time: null,
            type: ParamsTimeTypeEnum.ALL_TIME
        },
        type: ParamsFilterTypeEnum.DATETIME
    },

    // drop down
    {
        property: 'registratorId',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true
    },
    {
        property: 'internalId',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true
    },
    {
        property: 'registratorId',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true
    }
];

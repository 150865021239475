<div class="select"
     #select
     [class.select--report]="type === selectTypeEnum.report"
     [class.select--pagination]="type === selectTypeEnum.pagination"
     [class.select--table]="type === selectTypeEnum.table"
     [class.select--require]="isRequire && !currentOption"
     [class.select--disable]="isDisable"
     [class.select--unActive]="unActive"
     [style.height.px]="height"
     appClickOutside
     [isActiveClickOutside]="isShowPopup"
     (clickOutside)="clickOutside()">

    <div
        (click)="computingWrapperPosition(); isShowPopup = !isShowPopup; emmitEditMode.emit(isShowPopup)"
        class="select__wrapper"
        [class.select__wrapper--icon]="isIconList">
        <div class="select__value"
             [class.select__value--disabled]="isDisable && isIconList"
             [class.select__value--hole]="currentOption && currentOption?.property === chartModeEnum.hole"
             [class.select__value--default]="isDefault">
            <ng-container
                *ngIf="isObject(value) && !placeholder && type !== 'table' && type !== selectTypeEnum.chartGroup">
                <span class="select__value-text select__value-text--object">{{value.value | translate}}</span>
            </ng-container>
            <ng-container *ngIf="!isObject(value) && !placeholder && type !== selectTypeEnum.chartGroup">
                <span class="select__value-text select__value-text--text">{{value | translate}}</span>
            </ng-container>
            <ng-container *ngIf="placeholder && value === 'filters.customFilter.defaultValue'">
                <span class="select__value-text select__value-text--placeholder">{{placeholder | translate}}</span>
            </ng-container>

            <ng-container *ngIf="isIconList">
                <svg-icon [src]="currentOption.preIconPath"></svg-icon>
            </ng-container>

        </div>
        <div class="select__arrow"
             [class.select__icon--disabled]="isDisable"
             [class.select__arrow--default]="value === 'filters.customFilter.defaultValue'">
            <svg-icon [src]="ARROW_ICON_PATH"></svg-icon>
        </div>
        <div *ngIf="isTooltipFullValue && currentOption && currentOptionLength && value" class="select__full-value">

            <app-tooltip [value]="value" [template]="true" [isWhiteSpace]="true">
                <div class="select__tootip">{{currentOption.value | translate}}</div>
            </app-tooltip>
        </div>
    </div>
    <ul *ngIf="isShowPopup" class="select__list"
        [appSaveScroll]="{isScroll: true, saveValue: saveScrollPosition}"
        (scroll)="saveScroll($event.target)"
        [class.select__list--show]="isShowPopup"
        [class.select__list--above]="listAbove && !isOpenBottom"
        [class.select__list--left]="side === customSelectSideEnum.left"
        [style.max-height]="maxHeight ? maxHeight : '280px'"
        [style.width]="width ? width : ''">

        <li *ngIf="options && options.length > 5 && isSearch" class="select__option select__option--search">
            <input type="text" class="select__input input"
                   [placeholder]="'filters.search' | translate"
                   [appFocus]="true"
                   [(ngModel)]="searchStr">
        </li>

        <li *ngIf="!placeholder && isReset"
            class="select__option select__option--default"
            [translate]="resetMessage || 'filters.customFilter.defaultValue'"
            (click)="reset(); isShowPopup = false; emmitEditMode.emit(isShowPopup)"></li>

        <ng-container
            *ngFor="let option of options | filterBySmth: {type: filterTypePipeEnum.filterOptionsByValue, comparedValue: searchStr}; trackBy: trackByKey">
            <ng-container *ngIf="isRepeat && type !== selectTypeEnum.chartGroup">
                <li class="select__option"
                    (click)="setOption(option); isShowPopup = false; emmitEditMode.emit(isShowPopup)"
                    [class.select__option--disabled]="option.disabled"
                    [class.select__option--choose]="option.property === '-1'"
                    [class.select__option--registrator]="isHighlightRegistrators && option.property.type === deviceTypeEnum.registrator"
                    [class.select__option--devices]="isHighlightRegistrators && option.property.type !== deviceTypeEnum.registrator"
                    [class.select__option--hr]="option?.property?.type === deviceTypeEnum.registrator && !selectTypeEnum.report">
                    <span>{{option.value | translate}}</span>

                    <!-- <app-select-icon [option]="option"
                                     [isHighlightRegistrators]="isHighlightRegistrators"></app-select-icon> -->
                    <svg-icon [src]="option.postIconPath"></svg-icon>

                </li>
            </ng-container>

            <!-- select for only icons -->
            <ng-container *ngIf="selectTypeEnum.chartGroup && isIconList">
                <li *ngIf="!currentOption || option.key !== currentOption.key"
                    class="select__option select__option--only-icons"
                    [class.select__option--hole]="option && option?.property === chartModeEnum.hole"
                    (click)="setOption(option); isShowPopup = false; emmitEditMode.emit(isShowPopup)"
                    [class.select__option--icon]="isIconList"
                    [class.select__option--disabled]="option.disabled">

                    <svg-icon [src]="option.preIconPath"></svg-icon>
                    <!-- <app-select-icon [option]="option" [disabled]="isDisable"></app-select-icon> -->

                    <span>{{option.value | translate}}</span>
                </li>
            </ng-container>


            <ng-container *ngIf="!isRepeat && type !== selectTypeEnum.chartGroup">
                <li *ngIf="option?.key !== currentOption?.key"
                    class="select__option select__option--simple"
                    [class.select__option--disabled]="option.disabled"
                    [class.select__option--registrator]="isHighlightRegistrators && option.property.type === deviceTypeEnum.registrator"
                    [class.select__option--devices]="isHighlightRegistrators && option.property.type !== deviceTypeEnum.registrator"
                    (click)="setOption(option); isShowPopup = false; emmitEditMode.emit(isShowPopup)">

                    <span>{{option.value | translate}}</span>

                    <svg-icon [src]="option.postIconPath"></svg-icon>

                </li>
            </ng-container>

        </ng-container>

        <li *ngIf="delete.length > 0"
            (click)="removeEmit(); isShowPopup = false; emmitEditMode.emit(isShowPopup)"
            class="select__option select__option--delete select__option--default">{{delete | translate}}</li>
        <!-- </cdk-virtual-scroll-viewport> -->

    </ul>
</div>

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SocketUserStatusEnum } from '../../auth/_enums/socket-user-status.enum';
import { UsersService } from 'src/app/admin/users/_services/users.service';
import { ApiResponse } from '../_interfaces/ApiRequest';
import { NotificationsService } from '../_services/notifications.service';
import { Observable, Subscription } from 'rxjs';
import { BlockUiService } from '../_services/auto-block-ui.service';
import { SocketService } from '../_services/socket.service';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '../../auth/_store/states/auth.state';
import { ConfigurationState } from '../_store/states/configuration.state';
import { ConfigurationServerInterface } from '../_interfaces/configuration-server.interface';
import { TooltipStatusEnum } from '../_enums/tooltip-status.enum';
import { HTTP_STATUS } from '../_enums/status.enum';

@Component({
    selector: 'app-block-ui',
    templateUrl: './block-ui.component.html',
    styleUrls: ['./block-ui.component.scss'],
})
export class BlockUiComponent implements OnInit, OnDestroy {
    @Select(ConfigurationState.getConfigurationServer) configurationServer$: Observable<ConfigurationServerInterface>;

    form: FormGroup = new FormGroup({
        // email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', Validators.required),
    });

    isVisiblePassword = false;
    errorMessage = false;

    @Output() closeEvent = new EventEmitter<void>();

    password = '';

    onLoginFunction;
    freezeSessionSubscribe$: Subscription;
    loginWindow: any;
    public email: string;

    readonly GOOGLE_ICONS_PATH = './assets/design/icons/auth/google.svg';
    readonly CLOSE_PATH = './assets/design/icons/close.svg';

    constructor(
        private socketService: SocketService,
        private blockService: BlockUiService,
        private userService: UsersService,
        private notificationService: NotificationsService,
        private store: Store,
    ) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.email = this.userService.getUserFromToken().login;
        }, 200);
    }

    ngOnDestroy(): void {
        if (this.freezeSessionSubscribe$) {
            this.freezeSessionSubscribe$.unsubscribe();
        }
    }

    closeModal(): void {
        this.closeEvent.emit();
        // this.blockService.closeModal();
    }

    async logout(): Promise<void> {
        this.blockService.blockUi(false);
        this.socketService.logoutSubscribe.emit();
    }

    async login(): Promise<void> {
        const token = this.store.selectSnapshot(AuthState.getUserToken);

        const status = await this.socketService.emitWait('unFreezeSession', {
            token,
            password: this.form.value.password,
        });

        if (status.status === 0) {
            this.blockService.blockUi(false);
        }

        if (status.status === SocketUserStatusEnum.credentialsError) {
            this.errorMessage = true;
        }
    }

    googleLogin(): void {
        const token = this.store.selectSnapshot(AuthState.getUserToken);
        this.loginWindow = window.open(
            `${window.location.origin}/api/auth/google?token=${token}`,
            '',
            'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no',
        );
        this.onLoginFunction = async (event) => {
            try {
                const data: ApiResponse = JSON.parse(event.data);
                if (data.status === HTTP_STATUS.INCORRECT_EMAIL) {
                    this.notificationService.onEmit(TooltipStatusEnum.error, false, 'Incorrect email');
                }
            } catch (e) {
                this.notificationService.onEmit(TooltipStatusEnum.error, false, 'Error');
            }

            setTimeout(() => {
                this.loginWindow.close();
            }, 500);

            window.removeEventListener('message', this.onLoginFunction);
        };

        this.freezeSessionSubscribe$ = this.socketService.freezeSessionSubscribe.subscribe(this.onFreeze.bind(this));
        window.addEventListener('message', this.onLoginFunction);
    }

    onFreeze(data): void {
        if (data.status === SocketUserStatusEnum.unlockActiveSession) {
            setTimeout(() => {
                window.addEventListener('message', this.onLoginFunction);
                this.onLoginFunction = () => {};
                this.loginWindow.close();
                if (this.freezeSessionSubscribe$) {
                    this.freezeSessionSubscribe$.unsubscribe();
                }
            }, 500);
        }
    }
}

export class GenerationAuthenticationKey {
    static readonly type = '[Authentication] GenerationAuthenticationKey';

}

export class VerificationAuthenticationKey {
    static readonly type = '[Authentication] VerificationAuthenticationKey';

    constructor(public code: string) {
    }
}

export class UnBindAuthenticationKey {
    static readonly type = '[Authentication] UnBindAuthenticationKey';
    constructor(public code: string) {
    }
}

<div class="column"
    [class.column--top]="cell?.alignItems === alignItemsEnum.top">
    <div class="column__wrapper">
        <ng-container *ngIf="!isEditMode && !cell.isBtnShow">
            <div (click)="edit()" class="column__icon column__icon--edit">
                <svg-icon [src]="EDIT_ICON_PATH"></svg-icon>
            </div>
        </ng-container>

        <ng-container *ngIf="isEditMode && cell.isEditable">
            <div class="column__btns">
                <button class="column__btn btn btn--red">Save</button>
                <button class="column__btn btn btn--light">Reset</button>
            </div>
        </ng-container>

        <ng-container *ngIf="cell.isBtnShow">
            <button class="column__btn-show btn btn--light" (click)="showEdit()" [translate]="'btns.view'"></button>
        </ng-container>
    </div>
</div>

import {Params} from "../../../../app-shared-elements/_interfaces/params.interface";

export class GetAdminLogs {
    static readonly type = '[AdminLogs] GetAdminLogs';

}

export class GetAdminLogsRows {
    static readonly type = '[AdminLogs] GetAdminLogsRows';

}

export class SetAdminLogsFilter {
    static readonly type = '[AdminLogs] SetAdminLogsFilter';

    constructor(public params: Params) {
    }
}

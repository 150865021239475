import { ParamsFilterForClient, ParamsFilterTypeEnum, ParamsTimeTypeEnum } from '../../app-shared-elements/_interfaces/params.interface';

export const companyStaffFiltersCheckbox: ParamsFilterForClient[] = [
    {
        title: 'users.filtersDropdown.act',
        property: 'isActive',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'isActive',
                type: ParamsFilterTypeEnum.BOOLEAN,
                value: true,
            },
        ],
    },
    {
        title: 'users.filtersDropdown.online',
        property: 'isOnline',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'isOnline',
                type: ParamsFilterTypeEnum.BOOLEAN,
                value: true,
            },
        ],
    },
    {
        title: 'users.filtersDropdown.offline',
        property: 'isOnline',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'isOnline',
                type: ParamsFilterTypeEnum.BOOLEAN,
                value: false,
            },
        ],
    },
    {
        title: 'users.filtersDropdown.deleted',
        property: 'isDeleted',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'isDeleted',
                type: ParamsFilterTypeEnum.BOOLEAN,
                value: true,
            },
        ],
    },
];

export const companyStaffFiltersDropdown: ParamsFilterForClient[] = [
    {
        property: 'login',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true,
    },
    {
        property: 'name',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true,
    },
    {
        property: 'created',
        value: {
            to: null,
            from: null,
            time: null,
            type: ParamsTimeTypeEnum.ALL_TIME,
        },
        type: ParamsFilterTypeEnum.DATETIME,
        isDropdown: true,
    },
];

import { Variable, VariableType } from 'src/app/app-shared-elements/_interfaces/Variable';
import { ExpressionInterface, ValueConditionsInterface } from '../_interfaces/Expression';
import { Injectable } from '@angular/core';
import { ConditionType, OperandConstantType } from '../_enums/condition-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { LogicEventType } from '../logical-events/_interface/LogicEvent';

@Injectable({
    providedIn: 'root',
})
export class ExpressionService {
    constructor(private translateService: TranslateService, private store: Store) {}

    parseExpressionToString(expression: ExpressionInterface, registratorId: string): string {
        if (!Object.keys(expression).length) {
            return '';
        }

        let result = '';
        expression.conditions.map((conditions, index) => {
            if (index > 0) {
                result += ' && ';
            }
            result += this.parseConditionToString(conditions, registratorId);
        });

        return result;
    }

    private parseConditionToString(condition: ValueConditionsInterface, registratorId: string): string {
        const masterOprators = condition.type;

        const left = this.moveOnObject(condition.value[0], registratorId);
        const right = this.moveOnObject(condition.value[1], registratorId);

        return masterOprators === ConditionType.Negation ? `(${masterOprators} (${left})` : `(${left}) ${masterOprators} (${right})`;
    }

    private moveOnObject(object, registratorId: string): string {
        if (!object || Object.keys(object).length === 0) {
            return '';
        }

        const devices = this.store
            .selectSnapshot(DevicesState.getDevices)
            .filter((d) => d.registratorId === registratorId || d.id === registratorId);
        if (object.type === ConditionType.Constant) {
            return object.value + `<${object.operandType}>`;
        }

        if (object.type === ConditionType.Tag) {
            let variable: Variable;
            devices.forEach((d) => {
                if (variable) {
                    return;
                }

                const currentVariable = d.variables.find(
                    (v) => v.internalId === object.value.variableInternalId && d.internalId === object.value.deviceInternalId,
                );
                if (currentVariable) {
                    variable = currentVariable;
                }
            });

            if (!variable) {
                return 'Na';
            }
            return variable.customName ?? variable.name;
        }

        let left;
        let right;
        left = object && object.value[0] ? this.moveOnObject(object.value[0], registratorId) : '';
        right = object && object.value[1] ? this.moveOnObject(object.value[1], registratorId) : '';

        return object.type === ConditionType.Negation ? `${object.type} ${left}` : `${left} ${object.type} ${right}`;
    }

    async getErrorValidateExpressionMessage(message): Promise<string> {
        return await this.translateService
            .get(`events.expressions.expressionException.${message.exceptionKey}`, { ...message.exceptionData })
            .toPromise();
    }

    private validateTypeBeetwenVariableAndConstant(values: ValueConditionsInterface[]): boolean {
        if (!values.length || !Object.keys(values[0]).length || !Object.keys(values[1]).length) {
            return true;
        }

        if (!values.find((value) => value.type === ConditionType.Constant) || !values.find((value) => value.type === ConditionType.Tag)) {
            return true;
        }

        const constantType = values.find((value) => value.type === ConditionType.Constant).operandType;

        const variableConditionValue = values.find((value) => value.type === ConditionType.Tag);

        if (!variableConditionValue) {
            return true;
        }

        const currentDevice = this.store
            .selectSnapshot(DevicesState.getDevices)
            .find((device) => device.internalId === variableConditionValue.value.deviceInternalId);

        if (!currentDevice) {
            return true;
        }

        const currentVariable: Variable = currentDevice.variables.find(
            (variable) => variable.internalId === variableConditionValue.value.variableInternalId,
        );
        if (
            (currentVariable.type === VariableType.INT || currentVariable.type === VariableType.FLOAT) &&
            (constantType === OperandConstantType.FLOAT || constantType === OperandConstantType.INT)
        ) {
            return true;
        }
        return (currentVariable.type as string) === (constantType as string);
    }

    validateExpression(expression: ExpressionInterface): boolean {
        let result = true;

        expression.conditions.forEach((condition) => {
            if (!this.validateCondition(condition)) {
                result = false;
            }
        });

        return result;
    }

    private validateCondition(condition: ValueConditionsInterface): boolean {
        return this.validateConditionItem(condition.value);
    }

    private validateConditionItem(values: ValueConditionsInterface[]): boolean {
        let result = true;
        if (!this.validateTypeBeetwenVariableAndConstant(values)) {
            result = false;
        }
        // this.validateTypeBeetwenVariableAndConstant(values)
        return result;
    }

    async createLimitExpression(
        variable: Variable,
        type: LogicEventType,
        value: string,
        operandType: OperandConstantType,
    ): Promise<ExpressionInterface> {
        // CREATE EXPRESSION

        const currentDevice = this.store.selectSnapshot(DevicesState.getDevices).find((d) => d.id === variable.deviceId);
        const translateType = await this.translateService.get(`events.expressions.${operandType}`).toPromise();

        return {
            conditions: [
                {
                    type: this.getTypeForLimitExpression(type),
                    value: [
                        {
                            type: ConditionType.Tag,
                            value: {
                                variableInternalId: variable.internalId,
                                deviceInternalId: currentDevice.internalId,
                                variableId: variable.id,
                            },
                        },
                        {
                            type: ConditionType.Constant,
                            value,
                            operandType: translateType,
                        },
                    ],
                },
            ],
        };
    }

    private getTypeForLimitExpression(type: LogicEventType): ConditionType {
        switch (type) {
            case LogicEventType.alarmDeadlineMax:
                return ConditionType.MoreThan;
            case LogicEventType.attentionDeadlineMax:
                return ConditionType.MoreThan;
            case LogicEventType.alarmDeadlineMin:
                return ConditionType.LessThan;
            case LogicEventType.attentionDeadlineMin:
                return ConditionType.LessThan;
        }
    }
}

import { LineRowInterface } from '../../_interfaces/chart-settings-row.interface';

export class InitChartSettingsLineRows {
    static readonly type = '[ChartSettings] InitChartSettingsLineRows';
}

export class InitChartSettingsAxisRows {
    static readonly type = '[ChartSettings] InitChartSettingsAxisRows';
}

export class UpdateChartSettingLine {
    static readonly type = '[ChartSettings] UpdateChartSettingLine';

    constructor(public row: LineRowInterface) {}
}

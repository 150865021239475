import { FilterTypeEnum } from '../../_enums/filter-type.enum';
import { ConfigForFilterInterface } from '../../_interfaces/config-for-filter.interface';
import { ParamsTime, ParamsTimeTypeEnum } from '../../_interfaces/params.interface';
import { VariableChangeArchive } from '../../_interfaces/Variable';

export class SetTimeObj {
    static readonly type = '[TimeFilter] SetTimeObj';

    constructor(public timeObj: ParamsTime) {}
}

export class SetTimeType {
    static readonly type = '[TimeFilter] SetTimeType';

    constructor(public type: ParamsTimeTypeEnum) {}
}

export class SetCurrentTimeOption {
    static readonly type = '[TimeFilter] SetCurrentTimeOption';

    constructor(public option: any) {}
}

export class SetDisabledTimeOptions {
    static readonly type = '[TimeFilter] SetDisabledTimeOptions';

    constructor(public changesPeriodArchive: VariableChangeArchive[]) {}
}

export class SetFilterConfig {
    static readonly type = '[TimeFilter] SetFilterConfig';

    constructor(public config: ConfigForFilterInterface) {}
}

export class SetFilterType {
    static readonly type = '[TimeFilter] SetFilterType';

    constructor(public type: FilterTypeEnum) {}
}

// export class SetCalendarConfig {
//     static readonly type = '[TimeFilter] SetCalendarConfig';
//     constructor(public ln: LangEnum) {}
// }

export enum VariablesNameEnum {
    Temperature = 'Temperature',
    Temperature1Value = 'Temperature1Value',
    Temperature1 = 'Temperature1',
    Temperature2Value = 'Temperature2Value',
    Temperature2 = 'Temperature2',
    Humidity = 'Humidity',
    HumidityValue = 'HumidityValue',
    Unknown = 'Unknown',
    BatteryLife = 'BatteryLife',
    BatteryCapacity = 'BatteryCapacity',
    RepeaterAddress = 'RepeaterAddress',
    RSSI_S = 'RSSI_S',
    RSSI = 'RSSI',
    BatteryLevel = 'BatteryLevel',
    CommunicationQuality_S = 'CommunicationQuality_S',
    SensorModes = 'SensorModes',
    TemperatureOffset = 'TemperatureOffset',
    TemperatureIncline = 'TemperatureIncline',
    HumidityOffset = 'HumidityOffset',
    HumidityIncline = 'HumidityIncline',
    BroadcastFrequencyChannel = 'BroadcastFrequencyChannel',
    NetworkAddress = 'NetworkAddress',
    SensorNetworkAddress = 'SensorNetworkAddress',
    TransmitterPower = 'TransmitterPower',
    FilteringTemperature = 'FilteringTemperature',
    RSSI_C = 'RSSI_C',
    CommunicationQuality_C = 'CommunicationQuality_C',
    APIVersion = 'APIVersion',
    CoordinatorStatus = 'CoordinatorStatus',
    ArchivePeriod = 'ArchivePeriod',
    Latitude = 'Latitude',
    Longitude = 'Longitude',
    GPSMode = 'GPSMode',
    EventHandler = 'EventHandler',
    MacAddress = 'MacAddress',
    IpAddress = 'IpAddress',
    LogicEventDelay = 'LogicEventDelay',
    ExpectExpedition = 'ExpectExpedition',
    VerificationDate = 'VariaficationDate',
    FreeMemory = 'FreeMemory',
    TotalMemory = 'TotalMemory',
    KioskMode = 'KioskMode',
    PowerSupplyStatus = 'PowerSupplyStatus',
    BoundaryLevelRssi = 'BoundaryLevelRssi',
    SensorsBoundaryLevelRssi = 'SensorsBoundaryLevelRssi',
    SleepStatus = 'SleepStatus',
    ArchiveMode = 'ArchiveMode',
    ArchiveLaunchMode = 'ArchiveLaunchMode',
    ArchiveRequested = 'ArchiveRequested',
    ArchiveStatus = 'ArchiveStatus',
    EventStatus = 'EventStatus',
    EventRequested = 'EventRequested',
    ButtonMode = 'ButtonMode',
    EventMode = 'EventMode',
    ArchiveStart = 'ArchiveStart',
    ArchiveStop = 'ArchiveStop',
    ArchiveDelay = 'ArchiveDelay',
    EventDelay = 'EventDelay',
    Modification = 'Modification',
    IsTransport = 'IsTransport',
    Contact1Antibounce = 'Contact1Antibounce',
    Contact1EventDelay = 'Contact1EventDelay',
    Contact1EventEnabled = 'Contact1EventEnabled',
    Contact1Polarity = 'Contact1Polarity',
    Contact1TriggerStatus = 'Contact1TriggerStatus',
    Contact2Antibounce = 'Contact1Antibounce',
    Contact2EventDelay = 'Contact1EventDelay',
    Contact2EventEnabled = 'Contact1EventEnabled',
    Contact2Polarity = 'Contact1Polarity',
    Contact2TriggerStatus = 'Contact1TriggerStatus',
    Contact3Antibounce = 'Contact1Antibounce',
    Contact3EventDelay = 'Contact1EventDelay',
    Contact3EventEnabled = 'Contact1EventEnabled',
    Contact3Polarity = 'Contact1Polarity',
    Contact3TriggerStatus = 'Contact1TriggerStatus',
    Contact4Antibounce = 'Contact1Antibounce',
    Contact4EventDelay = 'Contact1EventDelay',
    Contact4EventEnabled = 'Contact1EventEnabled',
    Contact4Polarity = 'Contact1Polarity',
    Contact4TriggerStatus = 'Contact1TriggerStatus',
    Contact1 = 'Contact1',
    Contact2 = 'Contact2',
    Contact3 = 'Contact3',
    Contact4 = 'Contact4',
    ContactPeriod = 'ContactPeriod',
    LogicDelay = 'LogicDelay',
}

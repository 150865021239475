export enum FilterGroupSystemLogsType {
    alarm = 'alarmType',
    attention = 'alarmType',
    activity = 'tsCleared',
    notActivity = 'tsCleared',
    acknowledge = 'tsAcknowledget',
    notAcknowledge = 'isAknowledgeable',
    isWwaitingAcknowledge = 'tsAcknowledget'
}

export enum FilterTypeSystemLogsEnum {
    alarm = 'alarm',
    attention = 'attention',
    alarmBlink = 'alarmBlink',
    attentionBlink = 'attentionBlink',
    deviceBlink = 'deviceBlink',
    fromDeviceOnlyActiveAttention = 'fromDeviceOnlyActiveAttention',
    fromDeviceWithWaitAcknAttention = 'fromDeviceWithWaitAcknAttention',
    fromDeviceOnlyActiveAlarm = 'fromDeviceOnlyActiveAlarm',
    fromDeviceWithWaitAcknAlarm = 'fromDeviceWithWaitAcknAlarm'

}

import {GroupOperationActionInterface} from 'src/app/app-shared-elements/tables/_interfaces/group-operation-action.interface';
import {Params} from 'src/app/app-shared-elements/_interfaces/params.interface';

export class GetDeviceLogs {
    static readonly type = '[DeviceLog] GetDeviceLogs';
    constructor(public isFirstPage?: boolean) {
    }

}

export class GetDeviceLogsRows {
    static readonly type = '[DeviceLog] GetDeviceLogsRows';
    constructor() {
    }
}

// export class SetActiveDeviceLogsFromSocket {
//     static readonly type = '[DeviceLog] SetActiveDeviceLogsFromSocket';

//     constructor(public activeDeviceLogs: ActiveDeviceLogs) {
//     }
// }

export class SetAcknowledgeDeviceLog {
    static readonly type = '[DeviceLog] SetAcknowledgeDeviceLog';

    constructor(public logIds: string[]) {
    }
}

export class SetDeviceLogsFilter {
    static readonly type = '[DeviceLog] SetDeviceLogsFilter';
    constructor(public params: Params) {

    }
}


export class SetRowsForGroupOperation {
    static readonly type = '[DeviceLog] SetRowsFroGroupOperation';
    constructor(public event: GroupOperationActionInterface) {}
}

import { fabric } from 'fabric';
import { FabricShapeService } from './convas-tools.service';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
    CustomFabricEllipse,
    CustomFabricImage,
    CustomFabricLine,
    CustomFabricObject,
    CustomFabricPath,
    CustomFabricRect,
    CustomFabricTriangle,
    DrawingTools,
    FabricObjectType,
} from '../_data/data';
import { DrawingToolsIconsEnum } from '../_enums/drawing-tools-icons.enum';
import { SaveCanvasState } from '../_store/actions/mnemonic.actions';
import { ThemeEnum } from '../../theme/_enums/theme.enum';

@Injectable({
    providedIn: 'root',
})
export class CanvasEventHandlerService {
    public canvas: fabric.Canvas;
    private _selectedTool: DrawingTools = DrawingTools.SELECT;
    private previousTop: number;
    private previousLeft: number;
    private previousScaleX: number;
    private previousScaleY: number;

    set selectedTool(t: DrawingTools) {
        if (this.canvas?.getObjects()?.length) {
            this.canvas.discardActiveObject();
            this.canvas.renderAll();
        }
        this._selectedTool = t;
    }

    get selectedTool(): DrawingTools {
        return this._selectedTool;
    }

    public _isMouseDown = false;
    private _elementUnderDrawing:
        | CustomFabricEllipse
        | CustomFabricRect
        | CustomFabricPath
        | CustomFabricLine
        | CustomFabricTriangle
        | CustomFabricImage;

    constructor(
        private store: Store,
        private fabricShapeService: FabricShapeService,
    ) {}

    mouseDown(e: Event): void {
        this._isMouseDown = true;
        const pointer = this.canvas.getPointer(e);

        if (this._selectedTool && this._selectedTool !== DrawingTools.SELECT) {
            this.store.dispatch(new SaveCanvasState(this.canvas.toJSON(['id', 'name'])));
        }

        const theme = localStorage.getItem('theme') as ThemeEnum;

        switch (this._selectedTool) {
            case DrawingTools.ELLIPSE:
                this._elementUnderDrawing = this.fabricShapeService.createEllipse(this.canvas, pointer, theme);
                this.selectedTool = DrawingTools.SELECT;
                break;
            case DrawingTools.RECTANGLE:
                this._elementUnderDrawing = this.fabricShapeService.createRectangle(this.canvas, pointer, theme);
                this.selectedTool = DrawingTools.SELECT;
                break;
            case DrawingTools.TRIANGLE:
                this._elementUnderDrawing = this.fabricShapeService.createTriangle(this.canvas, pointer, theme);
                this.selectedTool = DrawingTools.SELECT;
                break;
            case DrawingTools.DOUBLE_TRIANGLE:
                this._elementUnderDrawing = this.fabricShapeService.createDoubleTriangle(this.canvas, pointer, theme);
                this.selectedTool = DrawingTools.SELECT;
                break;
            case DrawingTools.IMAGE:
                this._elementUnderDrawing = this.fabricShapeService.createImage(
                    this.canvas,
                    pointer,
                    theme,
                    DrawingToolsIconsEnum.defaultImage,
                );
                this.selectedTool = DrawingTools.SELECT;
                break;
            case DrawingTools.CHART:
                this._elementUnderDrawing = this.fabricShapeService.createChart(this.canvas, pointer, theme);
                this.selectedTool = DrawingTools.SELECT;
                break;
            case DrawingTools.I_TEXT:
                this.fabricShapeService.createIText(
                    this.canvas,
                    {
                        pointer,
                    },
                    theme,
                );
                this.selectedTool = DrawingTools.SELECT;
                break;
            case DrawingTools.TEXT:
                this.fabricShapeService.createText(
                    this.canvas,
                    {
                        pointer,
                    },
                    theme,
                );
                this.selectedTool = DrawingTools.SELECT;
                break;
            case DrawingTools.TEMPERATURE:
                this._elementUnderDrawing = this.fabricShapeService.createImage(
                    this.canvas,
                    pointer,
                    theme,
                    DrawingToolsIconsEnum.temperature,
                );
                this.selectedTool = DrawingTools.SELECT;
                break;
            case DrawingTools.HUMIDITY:
                this._elementUnderDrawing = this.fabricShapeService.createImage(
                    this.canvas,
                    pointer,
                    theme,
                    DrawingToolsIconsEnum.humidity,
                );
                this.selectedTool = DrawingTools.SELECT;
                break;
            case DrawingTools.DBM:
                this._elementUnderDrawing = this.fabricShapeService.createImage(this.canvas, pointer, theme, DrawingToolsIconsEnum.dbm);
                this.selectedTool = DrawingTools.SELECT;
                break;
            case DrawingTools.LINE:
                this._elementUnderDrawing = this.fabricShapeService.createLine(this.canvas, pointer, theme);
                this.selectedTool = DrawingTools.SELECT;
                break;
        }
    }

    mouseMove(e: Event): void {
        if (!this._isMouseDown) {
            return;
        }

        this.canvas.renderAll();
    }

    mouseUp(): void {
        this._isMouseDown = false;
        this.canvas.renderAll();
    }

    extendToObjectWithId(): void {
        fabric.Object.prototype.toObject = ((toObject) => {
            return function (this: CustomFabricObject): any {
                return fabric.util.object.extend(toObject.call(this), {
                    id: this.id,
                });
            };
        })(fabric.Object.prototype.toObject);
    }

    objectSelected(object: CustomFabricObject | any): void {
        this.previousLeft = object.left;
        this.previousTop = object.top;
        this.previousScaleX = object.scaleX;
        this.previousScaleY = object.scaleY;
    }

    objectMoving(id: string, type: FabricObjectType, newLeft: number, newTop: number): void {
        this.previousLeft = newLeft;
        this.previousTop = newTop;
    }
}

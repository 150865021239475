import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { TypeFilterEnum } from 'src/app/app-shared-elements/filters/enums/type-filter.enum';
import { DropdownFilterOptionInterface } from 'src/app/app-shared-elements/filters/interfaces/filter-option.interface';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { Pagination, Params, ParamsSorted, ParamsSortedOrderEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { CloneDeepService } from 'src/app/app-shared-elements/_services/clone-deep.service';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { ParamsService } from 'src/app/app-shared-elements/_services/params.service';
import { TimeFilterState } from 'src/app/app-shared-elements/_store/states/time-filter.state';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { expeditionFilter } from '../../_data/expedition-data';
import { ExpeditionInterface, ExpeditionRowInterface } from '../../_interfaces/expedition.interface';
import { ExpeditionsService } from '../../_services/expeditions.service';
import {
    ClearExpeditions,
    GetExpeditions,
    InitExpeditionsRows,
    PrintExpedition,
    SetExpeditionParams,
} from '../actions/expeditions.actions';

export interface ExpeditionsStateModel {
    expeditions: ExpeditionInterface[];
    expeditionsRows: ExpeditionRowInterface[];
    dropDownFilterOptions: DropdownFilterOptionInterface[];
    params: Params;
}

const EXPEDITIONS_TOKEN = new StateToken<ExpeditionsStateModel>('expeditionsState');

const initialConfigPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

const dropDownFilterOptions: DropdownFilterOptionInterface[] = [
    {
        key: 'registratorId',
        value: 'expeditions.filters.dropdownFilters.registrator',
        type: TypeFilterEnum.select,
        property: 'registratorId',
    },
];

const initialCurrentSort: ParamsSorted[] = [
    {
        property: 'start',
        order: ParamsSortedOrderEnum.DESC,
    },
];

export const initialParams: Params = {
    pagination: initialConfigPagination,
    filter: expeditionFilter,
    sorted: initialCurrentSort,
};

@State<ExpeditionsStateModel>({
    name: EXPEDITIONS_TOKEN,
    defaults: {
        expeditions: [],
        expeditionsRows: [],
        params: initialParams,
        dropDownFilterOptions,
    },
})
@Injectable()
export class ExpeditionsState {
    constructor(
        private http: HttpClient,
        private store: Store,
        private cloneDeepService: CloneDeepService,
        private paramsService: ParamsService,
        private expeditionsService: ExpeditionsService,
        private notificationsService: NotificationsService,
        private translateService: TranslateService,
    ) {}

    @Selector()
    static getExpeditions(state: ExpeditionsStateModel): ExpeditionInterface[] {
        return state.expeditions;
    }

    @Selector()
    static getExpeditionsRows(state: ExpeditionsStateModel): ExpeditionRowInterface[] {
        return state.expeditionsRows;
    }

    @Selector()
    static getDropDownFilterOptions(state: ExpeditionsStateModel): DropdownFilterOptionInterface[] {
        return state.dropDownFilterOptions;
    }

    @Selector()
    static getParams(state: ExpeditionsStateModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }

    @Action(GetExpeditions)
    async getExpeditions(ctx: StateContext<ExpeditionsStateModel>, payload: GetExpeditions): Promise<void> {
        const state = ctx.getState();
        const datetime = this.store.selectSnapshot(TimeFilterState.getTimeObj);

        const params: Params = {
            ...state.params,
            filter: this.paramsService.parseParamsFilterForServer(state.params.filter, datetime),
            pagination: {
                currentPage: state.params.pagination.currentPage,
                itemsPerPage: payload.isAllExpedition ? 99999999 : state.params.pagination.itemsPerPage,
            },
        };

        const result: ApiResponse = (await this.http
            .get('api/expedition', { headers: { params: encodeURIComponent(JSON.stringify(params)) } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                expeditions: result.data.items as ExpeditionInterface[],
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.total,
                    },
                },
            });
        }
    }

    @Action(InitExpeditionsRows)
    async initExpeditionsRows(ctx: StateContext<ExpeditionsStateModel>): Promise<void> {
        await ctx.dispatch(new GetExpeditions(false)).toPromise();
        const state = ctx.getState();
        const devices: Device[] = this.store.selectSnapshot(DevicesState.getRegistrators);

        ctx.setState({
            ...state,
            expeditionsRows: state.expeditions.map((e) => {
                const currentDevice: Device = devices.find((r) => r.id === e.registratorId);
                return {
                    start: e.start * 1000,
                    finish: e.finish ? e.finish * 1000 : null,
                    registrator: currentDevice ? currentDevice.name ?? currentDevice.defaultName : '',
                    id: e.id,
                    device: currentDevice,
                    disabledActions: this.expeditionsService.getDisabledAction(e),
                };
            }),
        });
    }

    @Action(ClearExpeditions)
    clearSetTechnologicFilterState(ctx: StateContext<ExpeditionsStateModel>): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            params: initialParams,
            expeditionsRows: [],
            expeditions: [],
        });
    }

    @Action(SetExpeditionParams)
    async setExpeditionParams(ctx: StateContext<ExpeditionsStateModel>, payload: SetExpeditionParams): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: this.cloneDeepService.cloneObject(payload.params),
        });

        await ctx.dispatch(new InitExpeditionsRows()).toPromise();
    }

    @Action(PrintExpedition)
    async printExpedition(ctx: StateContext<ExpeditionsStateModel>, payload: PrintExpedition): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/reports/generate-expedition', { expeditionId: payload.expeditionId })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result) {
            let message: string;
            switch (result.status) {
                case HTTP_STATUS.SUCCESS:
                    this.notificationsService.onEmit(TooltipStatusEnum.update, false, 'expeditions.successPrint');
                    break;
                case HTTP_STATUS.REPORT_PRINT_SERVER_UNAVAILABLE:
                    message = await this.translateService
                        .get('expeditions.printServerUnavailable')
                        .toPromise()
                        .catch((e) => console.log(e));
                    this.notificationsService.onEmit(TooltipStatusEnum.error, false, message);

                    break;
                case HTTP_STATUS.DEFAULT_REPORT_NOT_SET:
                    message = await this.translateService
                        .get('expeditions.emptyDefaultReport')
                        .toPromise()
                        .catch((e) => console.log(e));
                    this.notificationsService.onEmit(TooltipStatusEnum.error, false, message);
                    break;
            }
        }
    }
}

import {Breadcrumb} from '../../_interfaces/Breadcrumbs';

export class SetBreadcrumbs {
    static readonly type = '[Breadcrumbs] SetBreadcrumbs';

    constructor(public breadcrumbs: Breadcrumb) {

    }
}

export class ClearStateBreadcrumbs {
    static readonly type = '[Breadcrumbs] ClearStateBreadcrumbs';
}

import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { DevicesControlStatusEnum } from '../_enums/devices-control-status.enum';
import { GroupOperationRowPropertyInterface, IconInterface } from '../../../app-shared-elements/_interfaces/ColumnsTable';
import { ColumnsActionTypeEnum } from '../../../app-shared-elements/_enums/columns-action-type.enum';
import { DisabledTypeEnum } from '../../../app-shared-elements/_enums/disabled-type.enum';

export interface DevicesControlRowInterface {
    id: string;
    registratorName?: string;
    registrator?: any;
    status: DevicesControlStatusEnum | string;
    createdUserLogin: string;
    created?: Date;
    isActive: boolean;
    tooltipValue?: {
        createdUserLogin: string;
    };
    styles?: {
        status: any;
    };
    isChoose: GroupOperationRowPropertyInterface;
    serialNumbers: string[];
    isNew?: boolean;
    isEditing: boolean;
    postIcons?: IconInterface<DevicesControlIconActionEnum>[];
    registratorOptions: SelectOptionInterface[];
    disabledActions?: ColumnsActionTypeEnum[];
    disabledType?: DisabledTypeEnum;
}

export interface DevicesControlInterface {
    id: string;

    isActive: boolean;

    ownerKey: string;

    internalId: string;

    serialNumber: string[];

    createdUserLogin: string;

    createdUserId: string;

    ownerUserId: string;

    registratorId: string;

    status: DevicesControlStatusEnum;

    savedStatus: any;

    ownerUserLogin: string;

    expiresAt: Date;

    created: Date;

    updated: Date;
}

export enum DevicesControlIconActionEnum {
    info = 'info',
}

import {Params} from 'src/app/app-shared-elements/_interfaces/params.interface';

export class GetMailingLogs {
    static readonly type = '[MailingLogs] GetMailingLogs';

}

export class GetMailingMessagesLogsRows {
    static readonly type = '[MailingLogs] GetMailingMessagesLogsRows';
    constructor() {
    }
}

export class SetMailingLogsFilter {
    static readonly type = '[MailingLogs] SetMailingLogsFilter';
    constructor(public params: Params) {

    }
}

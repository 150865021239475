<div class="preloader">

    <div *ngIf="+data.requestId === +requestId && data.requestId && requestId; else message"
         class="preloader__wrapper">
        <div class="preloader__progress">

            <div class="preloader__message"
                 [translate]="'importExport.user.archivePreloader'"
                 [translateParams]="{startIndex: data.progress1.startIndex,
                             endIndex: data.progress1.endIndex}"
            ></div>
            <div
                class="preloader__part"
                [style.width]="data.progress1.percent + '%'">
            </div>
        </div>

        <div class="preloader__progress">

            <div class="preloader__message"
                 [translate]="'importExport.user.archiveStringPreloader'"
                 [translateParams]="{startIndex: data.progress2.startIndex,
                             endIndex: data.progress2.endIndex}"
            ></div>
            <div
                class="preloader__part"
                [style.width]="data.progress2.percent + '%'">
            </div>
        </div>

        <div class="preloader__text">

            <ng-container *ngIf="!isDone; else done">

            <span *ngIf="data.entity !== 'zip' && data.entity !== 'download' && data.entity !== 'upload'"
                  [translate]="'importExport.user.preloaderDownload'"></span>
                <b><span
                    [translate]="'importExport.downloadEntity.' + data.entity"></span></b>

            </ng-container>

            <ng-template #done>

                <span [translate]="'importExport.user.downloadDone'"></span>
            </ng-template>

        </div>
    </div>

    <ng-template #message>

        <div [translate]="'importExport.user.responseText'"></div>

    </ng-template>

</div>

import {Group, MapGroupSettings} from 'src/app/app-shared-elements/_interfaces/Group';
import {VariableGroupSettings} from 'src/app/app-shared-elements/_interfaces/VariableGroupSettings';
import {UpdateGroupNameDto} from 'src/app/profile/_interfaces/UpdateGroupNameDto';
import {VariableGroupSettingsDto} from 'src/app/device-dashboard/_interfaces/VariableGroupSettingsDto';
import {GroupPositionInterface} from '../../_interfaces/group-position.interface';
import {GroupFoldInterface} from '../../_interfaces/group-fold.interface';
import {DeviceTypeEnum} from 'src/app/app-shared-elements/_enums/device-type.enum';

export class GetAllGroups {
    static readonly type = '[Groups] GetAllGroups';
    constructor(public registratorIds: string[]) {
    }

}

export class UpdateGroupPosition {
    static readonly type = '[Groups] UpdateGroupPosition';
    constructor(public position: GroupPositionInterface[]) {
    }
}

export class UpdateGroupDashboardPosition {
    static readonly type = '[Groups] UpdateGroupDashboardPosition';

    constructor(public position: GroupPositionInterface[], public currentFilterType: DeviceTypeEnum) {
    }
}

export class UpdateGroupFold {
    static readonly type = '[Groups] UpdateGroupFold';
    constructor(public fold: GroupFoldInterface[]) {
    }
}

export class UpdateGroupDashboardFold {
    static readonly type = '[Groups] UpdateGroupDashboardFold';
    constructor(public fold: GroupFoldInterface[]) {
    }
}

export class UpdateGroup {
    static readonly type = '[Groups] UpdateGroup';

    constructor(public group: Group) {
    }
}

export class SetActiveGroup {
    static readonly type = '[Groups] SetActiveGroup';

    constructor(public groupId: string) {
    }
}

export class GetGroupById {
    static readonly type = '[Groups] GetGroupById';

    constructor(public id: string) {
    }
}

export class UpdatePositionGroup {
    static readonly type = '[Groups] UpdatePositionGroup';

    constructor(public groupsPosition: any) {
    }
}


export class ClearGroupState {
    static readonly type = '[Groups] ClearGroupState';
}

export class SetIsLoadGroup {
    static readonly type = '[Groups] SetIsLoadGroup';

    constructor(public isLoad: boolean) {
    }
}

export class UpdateGroupName {
    static readonly type = '[Groups] UpdateGroupName';

    constructor(public data: UpdateGroupNameDto) {

    }
}

export class UpdateVariableGroupSettings {
    static readonly type = '[Groups] UpdateVariableGroupSettings';

    constructor(public data: VariableGroupSettingsDto, public isChankResponeses?: boolean) {
        // console.trace()
    }
}

export class SetEditMode {
    static readonly type = '[Groups] SetEditMode';

    constructor(public value: boolean) {
    }
}

export class UpdateVariableGroupSettingFromSocket {
    static readonly type = '[Groups] UpdateVariableGroupSettingFromSocket';
    constructor(public variableGroupSetting: VariableGroupSettings) {

    }
}

export class ChangeMapSettings {
    static readonly type = '[Groups] ChangeMapSettings';
    constructor(public mapSettings: MapGroupSettings) {}
}

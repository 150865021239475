import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Axis } from 'src/app/app-shared-elements/_interfaces/Axis';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { GetLogicalEvents } from 'src/app/events/_store/actions/logical-events.actions';
import { LogicalEventsState } from 'src/app/events/_store/states/logical-events.state';
import { ChartSettingsService } from '../../container/chart/_services/chart-settings.service';
import { AxisRowInterface, LineRowInterface } from '../../_interfaces/chart-settings-row.interface';
import { InitChartSettingsAxisRows, InitChartSettingsLineRows, UpdateChartSettingLine } from '../actions/chart-settings.action';
import { GetAxis } from '../actions/charts.actions';
import { ChartState } from './chart.state';
import { GroupsState } from './groups.state';
import { Router } from '@angular/router';
import { VariablesNameEnum } from '../../../app-shared-elements/_enums/variables-name.enum';

export class ChartSettingsStateModel {
    lineRows: LineRowInterface[];
    axisRows: AxisRowInterface[];
}

const CHART_SETTINGS_TOKEN = new StateToken<ChartSettingsStateModel>('chartSettings');

const chartLineTypes = ['Solid', 'ShortDash', 'ShortDot', 'ShortDashDot', 'Dot', 'Dash', 'LongDash', 'DashDot', 'Circle'];

@State<ChartSettingsStateModel>({
    name: CHART_SETTINGS_TOKEN,
    defaults: {
        lineRows: [],
        axisRows: [],
    },
})
@Injectable()
export class ChartSettingsState {
    constructor(
        private store: Store,
        private chartSettingsService: ChartSettingsService,
        private router: Router,
    ) {}

    @Selector([ChartSettingsState, GroupsState.getGroups])
    static getChartSettingsLineRows(state: ChartSettingsStateModel): LineRowInterface[] {
        return state.lineRows;
    }

    @Selector([ChartSettingsState, GroupsState])
    static getChartSettingsAxisRows(state: ChartSettingsStateModel): AxisRowInterface[] {
        return state.axisRows;
    }

    @Action(InitChartSettingsLineRows)
    async initChartSettingsLineRows(ctx: StateContext<ChartSettingsStateModel>): Promise<void> {
        let axis: Axis[] = [];
        if (!this.store.selectSnapshot(ChartState.getAxis).length) {
            await this.store.dispatch(new GetAxis(this.store.selectSnapshot(DevicesState.getCurrentRegistratorId))).toPromise();
        }

        const state = ctx.getState();

        const activeGroup = this.store.selectSnapshot(GroupsState.getActiveGroup);
        const currentDevice = this.store.selectSnapshot(DevicesState.getDevices).find((device) => device.id === activeGroup.deviceId);

        await this.store.dispatch(new GetLogicalEvents([currentDevice.registratorId || currentDevice.id])).toPromise();
        const logicEvents = this.store.selectSnapshot(LogicalEventsState.getLogicalEvents);
        axis = this.store.selectSnapshot(ChartState.getAxis);

        ctx.setState({
            ...state,
            lineRows: activeGroup.variableGroupSettings
                .filter((setting) => setting.showOnChart)
                .map((setting) => {
                    const currentVariable = currentDevice.variables.find((i) => i.id === setting.variableId);

                    if (!currentVariable) {
                        return;
                    }

                    const currentAxis = axis.find((a) => a.id === setting.axisId);

                    return {
                        id: currentVariable.id,
                        name: currentVariable.customName || currentVariable.name,
                        color: setting.color,
                        width: setting.lineWidth,
                        type: setting.lineType,
                        checkboxValue: currentAxis?.color === setting?.color,
                        checkboxTooltipValue: 'charts.chartsSettings.checkboxTooltip',
                        axisName: currentAxis ? currentAxis.name : '',
                        axisId: currentAxis ? currentAxis.id : null,
                        axis: currentAxis,
                        showLimitAxis: !!setting.showLimitAxis,
                        axisOptions: axis
                            .map((a) => {
                                return {
                                    key: a.id,
                                    value: a.name,
                                    property: { cellName: 'axisId', data: a },
                                };
                            })
                            .sort((a, b) => (a.value > b.value ? 1 : -1)),
                        typeOptions: chartLineTypes
                            .filter((f) => (currentVariable.name === VariablesNameEnum.RSSI ? f : f !== 'Circle'))
                            .map((type) => {
                                return {
                                    value: type,
                                    // cell: 'type',
                                    key: type,
                                    property: { cellName: 'lineType', data: type },
                                };
                            }),
                        checkboxProperties: {
                            showLimitAxis: {
                                isCheckbox: true,
                                isDisabled: this.chartSettingsService.getIsDisabledCheckboxForLimitAxis(currentVariable, logicEvents),
                            },
                        },
                    };
                })
                .filter((s) => !!s)
                .sort((a, b) => (a.id > b.id ? 1 : -1)),
        });
    }

    @Action(UpdateChartSettingLine)
    updateChartSettingLine(ctx: StateContext<ChartSettingsStateModel>, payload: UpdateChartSettingLine): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            lineRows: state.lineRows.map((row) =>
                row.id === payload.row.id
                    ? {
                          ...payload.row,
                          checkboxValue: payload.row.color === payload.row.axis.color,
                      }
                    : row,
            ),
        });
    }

    @Action(InitChartSettingsAxisRows)
    async initChartSettingsAxisROws(ctx: StateContext<ChartSettingsStateModel>): Promise<void> {
        const state = ctx.getState();

        let axis: Axis[];
        await this.store.dispatch(new GetAxis(this.store.selectSnapshot(DevicesState.getCurrentRegistratorId))).toPromise();
        axis = this.store.selectSnapshot(ChartState.getAxis);

        ctx.setState({
            ...state,
            axisRows: axis
                .map((item) => {
                    return {
                        id: item.id,
                        name: item.name,
                        // unit: item.units,
                        color: item.color,
                        delete: 'delete',
                        options: [],
                        preIcons: this.chartSettingsService.getPreIcons(item),
                        editConfig: {
                            isEditName: true,
                            maxLength: 128,
                        },
                    };
                })
                .sort((a, b) => {
                    return a.id > b.id ? 1 : -1;
                }),
        });
    }
}

import { MailingCellFieldTypeEnum } from 'src/app/mailing/_enums/mailing-cell-field-type.enum';
import { ColumnsActionTypeEnum } from '../_enums/columns-action-type.enum';
import { TableNamesEnum } from '../_enums/table-names.enum';
import { SelectTypeEnum } from '../_enums/select-type.enum';
import { AlarmTypeEnum } from 'src/app/events/_enums/alarm.enum';
import { TooltipSideEnum } from '../_enums/tooltip-side.enum';
import { CustomTooltipTypeEnum } from '../_enums/custom-tooltip-type.enum';

export interface ColumnsTableInterface {
    title: string;
    type?: ColumnTypeEnum; // type of column component
    isCustomType?: boolean; // custom type column
    name: string; // name field in row
    grow?: boolean; // wide width
    small?: boolean; // narrow width
    underline?: boolean; // underline link
    wrap?: boolean; // flex-wrap text
    mode?: ColumnModeEnum;
    maxWidth?: string; // max width column
    minWidth?: string; // min width column
    styles?: boolean; // is have style object in row
    align?: ColumnValueAlignEnum; // align for value column
    isEditable?: boolean; // is edit mode in column now
    icon?: ColumnIconType;
    isBtnShow?: boolean;
    variableId?: string; // variable id
    isMultilang?: boolean; // multinang value
    tooltip?: boolean; // is have tooltip
    customTooltipType?: CustomTooltipTypeEnum; // custom tooltip message
    tooltipSide?: TooltipSideEnum; // is tooltip side
    tooltipMaxWidth?: string;
    isClick?: boolean; // is click column
    maxValue?: any; // maxValue in input
    sort?: any;
    allowHtmlTags?: boolean; //  use inner html directive
    mailingFieldType?: MailingCellFieldTypeEnum; // some field for mailing table users
    editorType?: ColumnEditorType; // column editor type
    alignItems?: AlignItemsEnum; // show data on Y axis
    isCheckPermission?: boolean; // check permission wne smth change or not
    isCustomInputConstructorList?: boolean; // custom input constructor in mailing editor
    actionBtns?: ColumnsActionTypeEnum[];
    isShortDate?: boolean; // shortDate for date column
    isDisable?: boolean; // cell can be disable
    tableName?: TableNamesEnum; // table name for applyEditingConfig
    optionsName?: string; // name for options field in row
    substr?: number; // the number of digits to shorten the text
    placeholderForSelectColumn?: string; // ln key for placeholder, for column(select)
    sortField?: string;
    tooltipValue?: string; // tooltip message in column
    sticky?: boolean; // add sticky block
    selectType?: SelectTypeEnum; // is select type style
    isSelectEditable?: boolean;
    isInputConstructor?: boolean;
    isInputCheckbox?: boolean;
    checkboxProperty?: string;
    currentValueForSelect?: string;
    eventsIcon?: AlarmTypeEnum; // current icon for edit expedition table (alarm/attention)
    propertyValidationTypeForInput?: string; // property in row, which describes type for appDisabled in columns-input
    isArrow?: boolean; // arrow in logical event
    preIcons?: boolean; // is icon before text value?
    postIcons?: boolean;
    postText?: boolean; // is text after text value
    isReadOnly?: boolean; // read only text column
    thIconPath?: string; // path to svg for th ICON
    // isSearch?: boolean; // search for type: ColumnTypeEnum.select
    inputPlaceholderValue?: string; // input placeholder for mailing editor
    isFullWrapperBtn?: boolean; // is full wrapper mailing editor button

    isColorCheckbox?: boolean;
}

export enum ColumnTypeEnum {
    date = 'date',
    text = 'text',
    edit = 'edit',
    button = 'button',
    acknowledge = 'acknowledge',
    transport = 'transport',
    mailingEditor = 'mailingEditor',
    icon = 'icon', // row property type only ColumnsIconDataEnum
    input = 'input',
    select = 'select',
    editor = 'editor',
    tsClear = 'tsClear',
    action = 'action',
    groupOperation = 'groupOperation',
    datePicker = 'datePicker',
}

export enum ColumnEditorType {
    textarea = 'textarea',
    input = 'input',
}

export enum ColumnModeEnum {
    number = 'number',
    checkbox = 'checkbox',
    color = 'color',
    text = 'text',
    link = 'link',
}

export enum ColumnValueAlignEnum {
    right = 'rigth',
    left = 'left',
    center = 'center',
}

export enum ColumnIconType {
    remove = 'remove',
    tooltip = 'tooltip',
    events = 'events',
}

export enum AlignItemsEnum {
    top = 'top',
    center = 'center',
    bottom = 'bottom',
}

export interface IconInterface<T> {
    path: string;
    styles?: any;
    tooltip?: string;
    tooltipSide?: TooltipSideEnum;
    action?: T;
    cellNames: string[];
    isDisable?: boolean;
}

export interface GroupOperationRowPropertyInterface {
    disabled: boolean;
    value: boolean;
}

export interface CheckboxPropertiesInterface {
    [cellName: string]: CheckboxPropertiesDataInterface;
}

export interface CheckboxPropertiesDataInterface {
    isFreeze?: boolean;
    isError?: boolean;
    isWaiting?: boolean;
    isDisabled?: boolean;
    isCheckbox: boolean;
    styles?: any;
}

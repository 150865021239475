import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LayoutEnum } from '../_enums/layout.enum';
import { Select, Store } from '@ngxs/store';
import { SetGroupLayout } from '../_store/actions/layout.actions';
import { LayoutState } from '../_store/states/layout.state';
import { Observable, Subject } from 'rxjs';
import { GroupsState } from 'src/app/groups/_store/states/groups.state';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { Device } from '../_interfaces/Device';
import { Group } from '../_interfaces/Group';
import { DocumentService } from '../_services/document.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { UserState } from '../_store/states/user.state';
import { User } from '../_interfaces/user.interface';

@Component({
    selector: 'app-heading',
    templateUrl: './heading.component.html',
    styleUrls: ['./heading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingComponent implements OnInit {
    switchLayoutTable: { title: string; name: LayoutEnum; iconPath: string }[] = [];

    private _breadCrumbs: string;
    private _breadCrumbsChild: string;
    private _title: string;

    @Select(LayoutState.getGroupLayout) layout$: Observable<LayoutEnum>;
    @Select(GroupsState.getActiveGroup) currentGroup$: Observable<Group>;
    @Select(DevicesState.getCurrentRegistrator) currentRegistrator$: Observable<Device>;
    @Select(UserState.getCurrentUser) currentUser$: Observable<User>;

    @Input() showChangeLayout = false;

    @Input() isShortCut = false;
    showEditLayoutPopup = false;
    showFullscreenLayout = false;
    layoutEnum = LayoutEnum;

    @Input() nameOfUser: string;
    @Input() breadCrumbsTranslate = true;
    @Input() isClickTitle = false;

    @Output() clickTitleEvent = new EventEmitter<void>();

    @Output() showShortCutEvent = new EventEmitter<void>();

    // @Input() titleTranslate = true;

    @Input()
    set breadCrumbs(breadCrumbs: string) {
        this._breadCrumbs = breadCrumbs;
    }

    get breadCrumbs(): string {
        return this._breadCrumbs;
    }

    @Input()
    set breadCrumbsChild(breadCrumbsChild: string) {
        this._breadCrumbsChild = breadCrumbsChild;
    }

    get breadCrumbsChild(): string {
        return this._breadCrumbsChild;
    }

    @Input()
    set title(title) {
        this._title = title;
    }

    get title(): string {
        return this._title;
    }

    @Input() translateParams: any;

    layoutZeroTitle: string;
    layoutOneTitle: string;
    layoutTwoTitle: string;

    readonly BC_ICON_PATH = './assets/design/icons/heading/breadcrumbs-icon.svg';
    readonly FULL_SCREEN_ICON_PATH = './assets/design/icons/heading/full-screen.svg';
    readonly LAYOUT_ICON_PATH = './assets/design/icons/heading/layout.svg';
    readonly LAYOUT_1_ICON_PATH = './assets/design/icons/heading/layout-1.svg';
    readonly LAYOUT_2_ICON_PATH = './assets/design/icons/heading/layout-2.svg';
    readonly LAYOUT_3_ICON_PATH = './assets/design/icons/heading/layout-3.svg';

    readonly SHORTCUT_ICON_PATH = './assets/design/icons/table-operations/info.svg';

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private store: Store,
        private documentService: DocumentService,
        public router: Router,
    ) {
        this.router.events
            .pipe(
                takeUntil(this.destroy),
                filter((event) => event instanceof NavigationEnd),
            )
            .subscribe((event: NavigationEnd) => {
                this.initLayoutTitle();
            });
    }

    ngOnInit(): void {}

    switchLayout(id: LayoutEnum): void {
        this.store.dispatch(new SetGroupLayout(id));
        this.showEditLayoutPopup = false;
    }

    async setFullScreenContent(): Promise<void> {
        this.showFullscreenLayout = !this.showFullscreenLayout;

        this.showFullscreenLayout ? await this.documentService.enterFullscreen() : await this.documentService.exitFullscreen();
    }

    showEditLayout(): void {
        this.initLayoutTitle();
        this.initSwitchLayoutTable();
        this.showEditLayoutPopup = true;
    }

    showShortCut(): void {
        this.showShortCutEvent.emit();
    }

    initLayoutTitle(): void {
        switch (true) {
            case this.router.url.includes('chart'):
                this.layoutZeroTitle = 'heading.layout.chart';
                this.layoutOneTitle = 'heading.layout.groupsChart';
                this.layoutTwoTitle = 'heading.layout.twoColumns';
                break;
            case this.router.url.includes('device-map'):
                this.layoutZeroTitle = 'heading.layoutMap.chart';
                this.layoutOneTitle = 'heading.layoutMap.groupsChart';
                this.layoutTwoTitle = 'heading.layoutMap.twoColumns';
                break;
            case this.router.url.includes('table-groups'):
                this.layoutZeroTitle = 'heading.layoutTable.table';
                this.layoutOneTitle = 'heading.layoutTable.groupsChart';
                this.layoutTwoTitle = 'heading.layoutTable.twoColumns';
                break;
            case this.router.url.includes('control-groups'):
                this.layoutZeroTitle = 'heading.layoutControl.control';
                this.layoutOneTitle = 'heading.layoutControl.groupsChart';
                this.layoutTwoTitle = 'heading.layoutControl.twoColumns';
                break;
        }
    }

    initSwitchLayoutTable(): void {
        this.switchLayoutTable = [
            {
                title: this.layoutZeroTitle,
                name: LayoutEnum.zero,
                iconPath: this.LAYOUT_1_ICON_PATH,
            },
            {
                title: this.layoutOneTitle,
                name: LayoutEnum.one,
                iconPath: this.LAYOUT_2_ICON_PATH,
            },
            {
                title: this.layoutTwoTitle,
                name: LayoutEnum.two,
                iconPath: this.LAYOUT_3_ICON_PATH,
            },
        ];
    }

    moveTo(): void {
        if (!this.isClickTitle) {
            return;
        }

        this.clickTitleEvent.emit();
    }
}

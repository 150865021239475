// export interface NotificationLogInterface {
//     readonly id: number;
//     isStatusEvent: boolean;
//     alarmType: AlarmTypeEnum;
//     typeTransport: NotificationTransportType;
//     transport: string;
//     status: NotificationStatus;
//     groupName: string;
//     userName: string;
//     message: string;
//     created: Date;
// }

export interface NotificationLogInterface {
    transportType: NotificationTransportType;
    transport?: string;
    recipientLogin: string;
    senderLogin: string;
    srcId: string;
    status: NotificationStatus;
    message: string;
    logType: NotificationLogType;
    groupName: string;
    created: Date;
    readonly id: string;
}

export interface MailingTransportRowLogInterface {
    icon: NotificationTransportType;
    data: string;
}

export interface MailingRowLogInterface extends NotificationLogInterface {
    date: Date;
    // message: string;
    // status: string;
    // groupName: string;
    userName: string;
    type: string;
    transportValue: MailingTransportRowLogInterface;
    styles: {
        statusValue: any;
    };
    statusValue: string;
}

export enum NotificationTransportType {
    TELEGRAM = 'telegram',
    VIBER = 'viber',
    EMAIL = 'email',
}

export enum NotificationLogType {
    EVENT_LOG = 0,
    REPORT = 1,
    VERIFY = 2,
    ANY = 3,
}

export enum NotificationStatus {
    IN_PROCESS = 0,
    SEND = 1,
    ERROR = 2,
    CHAT_NOT_FOUND = 3,
}

import { Observable, Subject } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GroupsState } from 'src/app/groups/_store/states/groups.state';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { LayoutEnum } from '../../app-shared-elements/_enums/layout.enum';
import { MethodPermission, ResourceAction } from 'src/app/app-shared-elements/_enums/permission.enum';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { SetSidebarStatus } from 'src/app/app-shared-elements/_store/actions/layout.actions';
import { CreationType } from 'src/app/app-shared-elements/_enums/registrator-sync-status.enu';
import { SidebarStatusEnum } from 'src/app/app-shared-elements/_enums/sidebar-status.enum';
import { LayoutState } from 'src/app/app-shared-elements/_store/states/layout.state';
import { takeUntil } from 'rxjs/operators';
import { VariablesNameEnum } from 'src/app/app-shared-elements/_enums/variables-name.enum';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { GPS } from 'src/app/groups/container-registrators/device-map/_enum/gps.enum';
import { SidebarListInterface } from '../_interfaces/sidebar-list.interface';
import { SidebarIconListEnum } from './_enums/sidebar-icon-list.enum';
import { NavState } from '../../app-shared-elements/_store/states/nav.state';
import { AuthState } from '../../auth/_store/states/auth.state';
import { DeviceTypeEnum } from '../../app-shared-elements/_enums/device-type.enum';
import { RegistratorTypeEnum } from '../../app-shared-elements/_enums/registrator-type.enum';
import { DataloggerModificationEnum } from '../../details-device-container/_enums/datalogger-modification.enum';
import { UserState } from '../../app-shared-elements/_store/states/user.state';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
    sidebarList: SidebarListInterface[] = [
        {
            title: 'table.groupEdit.chart',
            isPermission: true,
            isShowItem: true,
            link: 'group-container/chart',
            isPermissionProperty: ResourceAction.GROUP_CHART,
            property: SidebarIconListEnum.chart,
        },
        {
            title: 'table.groupEdit.table',
            isPermission: true,
            isShowItem: true,
            link: 'group-container/table-groups',
            isPermissionProperty: ResourceAction.GROUP_TABLE,
            property: SidebarIconListEnum.tableGroups,
        },
        {
            title: 'table.groupEdit.control',
            isPermission: true,
            isShowItem: true,
            link: 'group-container/control-groups',
            isPermissionProperty: ResourceAction.GROUP_CONTROL,
            property: SidebarIconListEnum.controlGroups,
        },
        {
            title: 'detailsDevice.title',
            isPermission: false,
            isShowItem: true,
            link: 'details',
            property: SidebarIconListEnum.detailsDevice,
        },
        {
            title: 'table.groupEdit.map',
            isPermission: true,
            isShowItem: true,
            link: 'map/device-map',
            isPermissionProperty: ResourceAction.GROUP_MAP,
            property: SidebarIconListEnum.deviceMap,
        },
        {
            title: 'expeditions.title',
            isPermission: true,
            isShowItem: true,
            link: 'expeditions',
            isPermissionProperty: ResourceAction.EXPEDITION,
            property: SidebarIconListEnum.expeditions,
        },
        {
            title: 'train.title',
            isPermission: true,
            isShowItem: true,
            link: 'transport-container/train-db',
            isPermissionProperty: ResourceAction.EXPEDITION,
            property: SidebarIconListEnum.train,
        },
        {
            title: 'companyStaff.title',
            isPermission: true,
            isShowItem: true,
            link: '/company-staff',
            property: SidebarIconListEnum.companyStaff,
        },
    ];

    layoutEnum = LayoutEnum;

    methodPermission = MethodPermission;
    resourceAction = ResourceAction;
    sidebarIconListEnum = SidebarIconListEnum;

    @Select(DevicesState.getCurrentRegistrator) currentRegistrator$: Observable<Device>;
    @Select(DevicesState.getCurrentDevice) currentDevice$: Observable<Device>;
    @Select(LayoutState.getSidebarStatus) sidebarStatus$: Observable<SidebarStatusEnum>;

    creationType = CreationType;

    destroy: Subject<boolean> = new Subject<boolean>();

    sidebarStatusEnum = SidebarStatusEnum;
    deviceTypeEnum = DeviceTypeEnum;
    gpsVariable: Variable;

    constructor(
        public router: Router,
        private store: Store,
    ) {
        this.store.dispatch(new SetSidebarStatus(SidebarStatusEnum.default));

        this.router.events.pipe(takeUntil(this.destroy)).subscribe((event) => {
            if (event instanceof NavigationStart || event instanceof NavigationEnd) {
                this.showSidebarItem();
            }
        });
    }

    ngOnInit(): void {
        this.currentRegistrator$.pipe(takeUntil(this.destroy)).subscribe((currentRegistrator) => {
            if (!currentRegistrator) {
                return;
            }

            this.gpsVariable = currentRegistrator.variables.find((v) => v.name === VariablesNameEnum.GPSMode);
            if (currentRegistrator) {
                this.showSidebarItem();
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }

    moveTo(property: SidebarIconListEnum): void {
        const currentGroup = this.store.selectSnapshot(GroupsState.getActiveGroup);
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);
        const currentDevice = this.store.selectSnapshot(DevicesState.getCurrentDevice);
        const isAdmin = this.store.selectSnapshot(AuthState.getIsAdmin);
        const id = currentGroup ? currentGroup.deviceId : currentRegistrator.id;

        switch (property) {
            case SidebarIconListEnum.chart:
                this.router.navigate([`${isAdmin ? 'control' : ''}/group-container/chart`, currentDevice ? currentDevice.id : id]);
                break;
            case SidebarIconListEnum.tableGroups:
                this.router.navigate([`${isAdmin ? 'control' : ''}/group-container/table-groups`, currentDevice ? currentDevice.id : id]);
                break;
            case SidebarIconListEnum.controlGroups:
                this.router.navigate([`${isAdmin ? 'control' : ''}/group-container/control-groups`, currentDevice ? currentDevice.id : id]);
                break;
            case SidebarIconListEnum.detailsDevice:
                if ((currentDevice.type === DeviceTypeEnum.datalogger || currentDevice.registratorType === RegistratorTypeEnum.datalogger) && !isAdmin) {
                    this.router.navigate([`/details/datalogger-dashboard`], {
                        queryParams: { id: currentDevice.id },
                    });
                } else {
                    this.router.navigate([`${isAdmin ? 'control' : ''}/details/details-device`], {
                        queryParams: { id: this.store.selectSnapshot(DevicesState.getCurrentRegistrator).id },
                    });
                }
                break;
            case SidebarIconListEnum.deviceMap:
                this.router.navigate([`group-container/map/device-map`], { queryParams: { id } });
                break;
            case SidebarIconListEnum.expeditions:
                currentRegistrator.creationType === CreationType.ORIGIN ? this.router.navigate([`/expeditions/${currentRegistrator.id}`]) : this.router.navigate(['/expeditions']);
                break;
            case SidebarIconListEnum.train:
                this.router.navigate(['transport-container/train-db']);
                break;
            case SidebarIconListEnum.companyStaff:
                this.router.navigate(['company-staff']);
                break;
        }
    }

    showSidebarItem(): void {
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);
        const isExpedition = this.store.selectSnapshot(NavState.getIsExpedition);
        const isAdmin = this.store.selectSnapshot(AuthState.getIsAdmin);
        const currentDevice = this.store.selectSnapshot(DevicesState.getCurrentDevice);
        const isTransport = currentDevice.variables?.find((f) => f.name === VariablesNameEnum.IsTransport)?.currentValue;
        const modification = currentDevice.variables?.find((v) => v.name === VariablesNameEnum.Modification);
        const user = this.store.selectSnapshot(UserState.getUser);

        this.sidebarList.map((item) => {
            item.isShowItem = true;

            switch (item.property) {
                case SidebarIconListEnum.controlGroups:
                    if (currentRegistrator && currentRegistrator.creationType === CreationType.VIRTUAL) {
                        item.isShowItem = false;
                    }
                    break;
                case SidebarIconListEnum.expeditions:
                    if ((currentRegistrator && currentRegistrator.creationType === CreationType.VIRTUAL) || !isExpedition || isAdmin || modification?.currentValue === DataloggerModificationEnum.DLT_22) {
                        item.isShowItem = false;
                    }
                    break;

                case SidebarIconListEnum.deviceMap:
                    if ((this.gpsVariable && +this.gpsVariable.currentValue === GPS.NEVER) || !this.gpsVariable || isAdmin) {
                        item.isShowItem = false;
                    }
                    break;
                case SidebarIconListEnum.train:
                    if (!isTransport) {
                        item.isShowItem = false;
                    }
                    break;

                case SidebarIconListEnum.companyStaff:
                    if (!user?.companyManagerId) {
                        item.isShowItem = false;
                    }
                    break;
                default:
                    item.isShowItem = true;
            }

            return item;
        });
    }
}

import {LimitLogicEventUpdateDto, LogicEvent} from '../../logical-events/_interface/LogicEvent';

// export class ClearMultiEvent {
//     static readonly type = '[MultiEvent] ClearMultiEvent';
// }

export class UpdateMultiEvent {
    static readonly type = '[MultiEvent] UpdateMultiEvent';
constructor(public multiEvent: LimitLogicEventUpdateDto) {}
}

export class CreateMultiEvent {
    static readonly type = '[MultiEvent] CreateMultiEvent';
    constructor(public dto: LimitLogicEventUpdateDto, public createOneMore: boolean) {}
}


export class ChangeMultiEvent {
    static readonly type = '[MultiEvent] ChangeMultiEvent';
    constructor(public multiEvent: LimitLogicEventUpdateDto) {
    }
}


export class GetMessagesForMultiEvent {
    static readonly type = '[MultiEvent] GetMessagesForMultiEvent';
    constructor(public currentLogicEvent: LogicEvent) {}
}

<div class="auth">
    <div class="auth__header">
        <div class="auth__logo"
             [class.auth__logo--ccc]="(configurationServer$ | async)?.serverName === serverNamesEnum.CCC"
        >
            <ng-container *ngIf="!router.url.includes('control') && (configurationServer$ | async)?.serverName; else admin">
                <svg-icon [src]="(configurationServer$ | async).serverName === serverNamesEnum.SC ? LOGO_USER_ICON_PATH : LOGO_USER_CCC_ICON_PATH"></svg-icon>
            </ng-container>

            <ng-template #admin>
                <svg-icon [src]="LOGO_ADMIN_ICON_PATH "></svg-icon>
            </ng-template>
        </div>
        <div class="auth__ln">
            <app-lang></app-lang>
        </div>
    </div>

    <div class="auth__wrapper">

        <router-outlet></router-outlet>

    </div>
</div>


import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActiveEvents} from '../_interfaces/ActiveEvents';
import {SocketService} from '../../app-shared-elements/_services/socket.service';
import {ConnectEnum} from '../../app-shared-elements/_enums/connect.enum';
import {GetActiveEventsFromSocket} from '../../app-shared-elements/_store/actions/active-events.actions';
import {Store} from '@ngxs/store';
import {SocketEvent} from '../../app-shared-elements/_enums/socket-event.enum';

@Injectable({
    providedIn: 'root'
})
export class ActiveEventsService implements OnDestroy {


    private errorConnectSubscribe: Subscription;
    private activeEventsHandler;

    public activeEvents: ActiveEvents[] = [];
    public isInit = false;

    constructor(
        private socketService: SocketService,
        private store: Store
    ) {
        this.errorConnectSubscribe = this.socketService.logoutSubscribe.subscribe((status: ConnectEnum) => {
            this.store.dispatch(new GetActiveEventsFromSocket([]));
        });

        this.activeEventsReceived([]);
    }

    ngOnDestroy(): void {
        this.socketService.removeListener(SocketEvent.ACTIVE_EVENTS, this.activeEventsHandler);

        if (this.errorConnectSubscribe) {
            this.errorConnectSubscribe.unsubscribe();
        }
    }

    public initActiveEvents(): void {
        if (this.activeEventsHandler) {
            this.socketService.removeListener(SocketEvent.ACTIVE_EVENTS, this.activeEventsHandler);
        }

        this.activeEventsHandler = (data: ActiveEvents[]) => {
            this.activeEventsReceived(data);
        };

        this.socketService.on(SocketEvent.ACTIVE_EVENTS, this.activeEventsHandler);
    }

    private activeEventsReceived(data: ActiveEvents[]): void {
        data.map(receivedEvents => {
            const existsItem = this.activeEvents.find(events => events.registratorId === receivedEvents.registratorId);

            existsItem
                ? this.activeEvents.splice(this.activeEvents.indexOf(existsItem), 1, receivedEvents)
                : this.activeEvents.push(receivedEvents);

        });
        this.isInit = true;
        this.store.dispatch(new GetActiveEventsFromSocket(this.activeEvents));

    }
}

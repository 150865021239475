import {Component, OnInit} from '@angular/core';
import {ThemeEnum} from '../../theme/_enums/theme.enum';
import {ThemeService} from '../../theme/theme.service';

@Component({
    selector: 'app-mobile',
    templateUrl: './mobile.component.html',
    styleUrls: ['./mobile.component.scss']
})
export class MobileComponent implements OnInit {

    constructor(private themeService: ThemeService) {
    }

    ngOnInit(): void {
        this.themeService.setActiveTheme(ThemeEnum.dark);
        localStorage.setItem('theme', ThemeEnum.dark);
    }

}

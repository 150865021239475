import {User} from 'src/app/app-shared-elements/_interfaces/user.interface';
import {UserProfileInfo} from 'src/app/app-shared-elements/_interfaces/UserInfo';
import {UserNotificationTransport} from '../../_interfaces/user-notification-transport.interface';

export class SetCurrentProfileUser {
    static readonly type = '[Profile] SetCurrentProfileUser';
    constructor(public user: User) {

    }
}

export class SetDescribeSkelet {
    static readonly type = '[Profile] SetDescribeSkelet';
    constructor(public user: User) {}
}

export class SetUserProfileInfo {
    static readonly type = '[Profile] SetUserProfileInfo';

    constructor(public user: User, public isEditable?: boolean) {
    }
}

export class ChangeUserProfileInfo {
    static readonly type = '[Profile] ChangeUserProfileInfo';

    constructor(public profileInfo: UserProfileInfo[]) {

    }
}

export class ChangeUserProfileSystem {
    static readonly type = '[Profile] ChangeUserProfileSystem';

    constructor(public profileInfo: UserProfileInfo[]) {

    }
}

export class GetUserNotificationsInfo {
    static readonly type = '[Profile] GetUserNotificationsInfo';
}

// export class ChangeNotificationsMailingInfo {
//     static readonly type = '[Profile] ChangeNotificationsMailingInfo';
//     constructor(public userProfileInfoMailing: UserNotificationTransport[]) {}
// }

export class ChangeUserNotificationsInfo {
    static readonly type = '[Profile] ChangeUserNotificationsInfo';

    constructor(public notification: UserNotificationTransport[]) {
    }
}




import { MailingRecipientRowInterface, RecipientsUserInterface } from '../_interfaces/mailing-recipient-row.interface';
import { EventEmitter, Injectable } from '@angular/core';
import { TypeFilterEnum } from 'src/app/app-shared-elements/filters/enums/type-filter.enum';
import { DropdownFilterOptionInterface } from 'src/app/app-shared-elements/filters/interfaces/filter-option.interface';
import { IconInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { StatusNotificationShare } from '../_enums/status-notification-share.enum';
import { CustomInputItem } from 'src/app/app-shared-elements/_interfaces/custom-input-item.interface';
import { SenderColumnButtonActionEnum, SenderUserInterface, SenderUserRowInterface } from '../_interfaces/sender-user.interface';
import { ColumnButtonItem } from 'src/app/app-shared-elements/tables/_interfaces/column-button-item.interface';
import { TooltipSideEnum } from 'src/app/app-shared-elements/_enums/tooltip-side.enum';
import { Params, ParamsFilter, ParamsFilterTypeEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { DisabledTypeEnum } from 'src/app/app-shared-elements/_enums/disabled-type.enum';
import { UserNotificationTransportStatusEnum } from 'src/app/profile/_enums/user-notification-transport-status.enum';

@Injectable({
    providedIn: 'root',
})
export class MailingUserService {
    changeActivityTransportEvent = new EventEmitter<{ row: MailingRecipientRowInterface; index: number }>();

    dropDownFilterOptions: DropdownFilterOptionInterface[] = [
        { key: 'name', value: 'mailing.users.dropdownFilters.name', type: TypeFilterEnum.text, property: 'name' },
        { key: 'email', value: 'mailing.users.dropdownFilters.id', type: TypeFilterEnum.text, property: 'email' },
    ];

    constructor() {}

    async getRecipientRows(
        recipients: RecipientsUserInterface[],
        autocompleteValues: CustomInputItem[],
        params: Params,
    ): Promise<MailingRecipientRowInterface[]> {
        return recipients
            .filter((rec) => this.filterRecipient(rec, params))
            .map((r) => {
                return {
                    ...r,
                    statusValue: this.getStatusValue(r.status),
                    isEditing: false,
                    autocompleteValues,
                    require: {
                        email: true,
                    },
                    styles: {
                        statusValue: this.getStatusStyles(r.status),
                    },
                    name: r.name ?? '',
                    disabledType: DisabledTypeEnum.mailingUserTable,
                    transports: r.transports ? r.transports.filter((t) => t.savedStatus === UserNotificationTransportStatusEnum.SAVED) : [],
                };
            })
            .filter((row) => this.filterByDropdown(row, params));
    }

    getStatusStyles(status: StatusNotificationShare): any {
        const result = {
            'text-align': 'center',
        };
        switch (status) {
            case StatusNotificationShare.ACCEPTED:
                return {
                    ...result,
                    color: 'var(--userDeviceCellOk)',
                };
            case StatusNotificationShare.PROCESS:
            case StatusNotificationShare.NOT_REGISTERED:
                return {
                    ...result,
                    color: 'var(--eventLogAttentionColor)',
                };
            case StatusNotificationShare.REJECT:
                return {
                    ...result,
                    color: 'var(--userDeviceCellAlarm)',
                };
            default:
                return result;
        }
    }

    filterRecipient(recipient: RecipientsUserInterface, params: Params): boolean {
        const checkboxFilter: ParamsFilter[] = params.filter.filter(
            (f) => f.type === ParamsFilterTypeEnum.BOOLEAN && !f.isDropdown && f.value,
        );
        if (!checkboxFilter || !checkboxFilter.length) {
            return true;
        }
        const filterProperies = Array.from(new Set(checkboxFilter.map((c) => c.property)));
        let count = 0;
        Object.keys(recipient)
            .filter((key) => filterProperies.find((p) => p === key))
            .forEach((key) => {
                if (checkboxFilter.find((f) => f.relationTrue[0].value === recipient[key])) {
                    count++;
                }
            });

        return count === filterProperies.length;
    }

    getStatusValue(status: StatusNotificationShare): string {
        switch (status) {
            case StatusNotificationShare.ACCEPTED:
                return 'mailing.recipient.ackn';
            case StatusNotificationShare.REJECT:
                return 'mailing.recipient.reject';
            case StatusNotificationShare.PROCESS:
                return 'mailing.recipient.wait';
            case StatusNotificationShare.NOT_REGISTERED:
                return 'mailing.recipient.notRegistered';
            default:
                return '';
        }
    }

    getSendersRows(senders: SenderUserInterface[], params: Params): SenderUserRowInterface[] {
        return senders
            .filter((sen) => this.filterSenders(sen, params))
            .map((s) => {
                return {
                    ...s,
                    preIcons: [],
                    postIcons: this.getPostIconsSender(s),
                    btns: this.getBtnsForSender(s),
                };
            })
            .filter((row) => this.filterByDropdown(row, params))
            .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
    }

    filterSenders(sender: SenderUserInterface, params: Params): boolean {
        const checkboxFilter: ParamsFilter[] = params.filter.filter(
            (f) => f.type === ParamsFilterTypeEnum.BOOLEAN && !f.isDropdown && f.value,
        );
        if (!checkboxFilter || !checkboxFilter.length) {
            return true;
        }
        const filterProperties = Array.from(new Set(checkboxFilter.map((c) => c.property)));
        let count = 0;
        Object.keys(sender)
            .filter((key) => filterProperties.find((p) => p === key))
            .forEach((key) => {
                if (checkboxFilter.find((f) => f.relationTrue[0].value === sender[key])) {
                    count++;
                }
            });

        return count === filterProperties.length;
    }

    getPostIconsSender(s: SenderUserInterface): IconInterface<void>[] {
        if (s.isActive) {
            return [];
        }

        return [
            {
                path: './assets/design/icons/question.svg',
                cellNames: ['email'],
                tooltip: 'mailing.senders.table.quetionTooltip',
                tooltipSide: TooltipSideEnum.left,
            },
        ];
    }

    private getBtnsForSender(sender: SenderUserInterface): ColumnButtonItem<SenderColumnButtonActionEnum>[] {
        return [
            {
                title: 'btns.reject',
                modificator: 'light',
                isDisabled: this.getIsDisableForSenderBtn(sender.status, SenderColumnButtonActionEnum.reject),
                action: SenderColumnButtonActionEnum.reject,
            },
            {
                title: 'btns.confirm',
                modificator: '',
                isDisabled: this.getIsDisableForSenderBtn(sender.status, SenderColumnButtonActionEnum.accept),
                action: SenderColumnButtonActionEnum.accept,
            },
        ];
    }

    private getIsDisableForSenderBtn(status: StatusNotificationShare, btnEvent?: SenderColumnButtonActionEnum): boolean {
        if (status === StatusNotificationShare.PROCESS) {
            return false;
        }

        switch (btnEvent) {
            case SenderColumnButtonActionEnum.accept:
                return status === StatusNotificationShare.ACCEPTED;
            case SenderColumnButtonActionEnum.reject:
                return status === StatusNotificationShare.REJECT;
        }
    }

    private filterByDropdown<T extends RecipientsUserInterface | SenderUserInterface>(row: T, params: Params): boolean {
        const currentFilter = params.filter.find((f) => f.isDropdown && f.value);
        if (!currentFilter) {
            return true;
        }

        if (!row[currentFilter.property]) {
            return false;
        }

        return row[currentFilter.property].includes(currentFilter.value);
    }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { ConfigurationVariableSavedEnum } from 'src/app/app-shared-elements/_interfaces/Variable';
import { EventSaveStatus } from 'src/app/events/_enums/event-save-state.enum';
import {
    AlignItemsEnum,
    ColumnModeEnum,
    ColumnsTableInterface,
    ColumnValueAlignEnum,
    IconInterface,
} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { MethodPermission, ResourceAction } from 'src/app/app-shared-elements/_enums/permission.enum';
import { PermissionsState } from 'src/app/app-shared-elements/_store/states/permissions.state';
import { Store } from '@ngxs/store';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { PermissionService } from 'src/app/app-shared-elements/_services/permission.service';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import {
    AddApplyTableEditionConfig,
    RemoveApplyTableEditionConfig,
    SetIsEditMode,
} from 'src/app/app-shared-elements/_store/actions/table.actions';
import { TableState } from 'src/app/app-shared-elements/_store/states/table.state';
import { CreationType } from '../../../_enums/registrator-sync-status.enu';
import { LogicEventType } from '../../../../events/logical-events/_interface/LogicEvent';
import { StatusTwoFactorAuthenticationEnum } from '../../../_enums/status-two-factor-authentication.enum';
import { IconEventInterface } from '../../_interfaces/icon-event.interface';
import { DeviceTypeEnum } from 'src/app/app-shared-elements/_enums/device-type.enum';
import { TooltipSideEnum } from 'src/app/app-shared-elements/_enums/tooltip-side.enum';
import { DisabledTypeEnum } from '../../../_enums/disabled-type.enum';

@Component({
    selector: 'app-columns-text',
    templateUrl: './columns-text.component.html',
    styleUrls: ['./columns-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnsTextComponent implements OnInit, OnDestroy {
    private _row;
    private _cell: ColumnsTableInterface;
    private _value;

    public displayValue: string;

    @Input()
    set value(value) {
        this._value = value;
    }

    get value(): any {
        return this._value;
    }

    @Input()
    set cell(cell: ColumnsTableInterface) {
        this._cell = cell;
    }

    get cell(): ColumnsTableInterface {
        return this._cell;
    }

    @Input()
    set row(row) {
        this._row = row;
    }

    get row(): any {
        return this._row;
    }

    @Output() moveToSmthEvent = new EventEmitter<any>();
    @Output() editSmthEvent = new EventEmitter<any>();
    // @Output() deleteSmthEvent = new EventEmitter<any>();
    @Output() iconEvent: EventEmitter<IconEventInterface<any>> = new EventEmitter<IconEventInterface<any>>();

    isEdit = false;
    originalValue;

    deviceTypeEnum = DeviceTypeEnum;
    configurationVariableSavedEnum = ConfigurationVariableSavedEnum;

    eventSaveStatus = EventSaveStatus;
    columnValueAlignEnum = ColumnValueAlignEnum;
    columnModeEnum = ColumnModeEnum;
    logicEventType = LogicEventType;

    alignItemsEnum = AlignItemsEnum;
    creationType = CreationType;

    methodPermission = MethodPermission;
    resourceAction = ResourceAction;
    tooltipSideEnum = TooltipSideEnum;

    disabledTypeEnum = DisabledTypeEnum;

    statusTwoFactorAuthenticationEnum = StatusTwoFactorAuthenticationEnum;

    readonly OK_ICON_PATH = './assets/design/icons/ok.svg';
    readonly CANCEL_ICON_PATH = './assets/design/icons/cancel.svg';
    readonly EDIT_ICON_PATH = './assets/design/icons/table-operations/editor-cell.svg';

    changedValue: any;

    constructor(
        private store: Store,
        private permissionService: PermissionService,
        private notificationsService: NotificationsService,
        private zone: NgZone,
    ) {}

    valueChanged(value): void {
        this.changedValue = value;
    }

    ngOnInit(): void {
        this.originalValue = this.value;
    }

    ngOnDestroy(): void {
        if (this.store.selectSnapshot(TableState.getApplyEditionConfig).includes(this.cell.tableName)) {
            this.store.dispatch(new RemoveApplyTableEditionConfig(this.cell.tableName));
        }
    }

    setEdit(): void {
        if (!this.cell.isEditable || this.row?.disableClick) {
            return;
        }

        if (this.store.selectSnapshot(TableState.getApplyEditionConfig).find((item) => item === this.cell.tableName)) {
            return;
        }

        this.store.dispatch(new SetIsEditMode(true));

        if (this.cell.tableName) {
            this.store.dispatch(new AddApplyTableEditionConfig(this.cell.tableName));
        }
        this.isEdit = true;

        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                const editField = document.getElementById('edit');
                if (editField) {
                    editField.focus();
                }
            });
        });
    }

    moveToSmth(): void {
        if (!this.cell.isClick) {
            return;
        }

        this.moveToSmthEvent.emit(this.row);
    }

    saveSmth(): any {
        this.isEdit = false;
        this.store.dispatch(new RemoveApplyTableEditionConfig(this.cell.tableName));
        this.store.dispatch(new SetIsEditMode(false));

        if (this.cell.isCheckPermission && !this.checkPermission()) {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'devices.popup.notPermission');
            return;
        }
        this.editSmthEvent.emit(this.changedValue);
    }

    cancelSmth(): void {
        this.isEdit = false;
        this.value = this.originalValue;
        this.store.dispatch(new RemoveApplyTableEditionConfig(this.cell.tableName));
        this.store.dispatch(new SetIsEditMode(false));
    }

    edit(): void {
        this.editSmthEvent.emit();
    }

    checkPermission(): boolean {
        const roles = this.store.selectSnapshot(PermissionsState.getRoles);
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);
        if (!currentRegistrator.roleId) {
            return true;
        }

        const data = {
            registrator: currentRegistrator,
            action: ResourceAction.GROUP_CONTROL,
            permissions: [MethodPermission.READ, MethodPermission.UPDATE],
        };

        const currentRole = roles.find((role) => role.id === currentRegistrator.roleId);

        return this.permissionService.checkPermission(currentRole.permissions, data);
    }

    iconClick(icon: IconInterface<any>, row: any, cell: ColumnsTableInterface): void {
        this.iconEvent.emit({ icon, row, cell });
    }
}

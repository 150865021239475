import {Params} from 'src/app/app-shared-elements/_interfaces/params.interface';
import {NotificationGroup, NotificationGroupUpdateDto} from '../../_interfaces/mailing-groups.interfaces';

export class GetMailingGroups {
    static readonly type = '[MailingGroup] GetMailingGroups';
}

export class GetMailingGroupById {
    static readonly type = '[MailingGroup] GetMailingGroupById';

    constructor(public id: string) {
    }
}

export class InitRowsMailingGroup {
    static readonly type = '[MailingGroup] InitRowsMailingGroup';
}

export class CreateMailingGroup {
    static readonly type = '[MailingGroup] CreateMailingGroup';
}

export class UpdateMailingGroup {
    static readonly type = '[MailingGroup] UpdateMailingGroup';

    constructor(public groupsDto: NotificationGroupUpdateDto, public isEditGroup?: boolean) {
    }
}

export class DeleteMailingGroup {
    static readonly type = '[MailingGroup] DeleteMailingGroup';

    constructor(public id: string) {
    }
}

export class SetUpdatingGroup {
    static readonly type = '[MailingGroup] SetUpdatingGroup';

    constructor(public nGroup: NotificationGroup) {
    }
}

export class SetMailingGroupParams {
    static readonly type = '[MailingGroup] SetMailingGroupParams';

    constructor(public params: Params) {

    }
}

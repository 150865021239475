import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { LangEnum } from 'src/app/app-shared-elements/_enums/lang.enum';
import { SelectOptionInterface } from 'src/app/app-shared-elements/_interfaces/select-option.interface';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { UserProfileInfo } from 'src/app/app-shared-elements/_interfaces/UserInfo';
import { LuxonParseDateService } from 'src/app/app-shared-elements/_services/luxon-parse-date.service';
import { ValidationService } from 'src/app/app-shared-elements/_services/validation.service';
import { ConfigurationState } from 'src/app/app-shared-elements/_store/states/configuration.state';
import { LanguageState } from 'src/app/app-shared-elements/_store/states/language.state';
import { NotificationTransportType } from 'src/app/mailing/_interfaces/notification-log.interface';
import { UserProfileInfoTypeDateEnum } from '../_enums/user-profile-info-date-time.enum';
import { UserProfileInfoTypeElementEnum, UserProfileinfoTypeEnum } from '../_enums/user-profile-info-type.enum';
import { UpdateUserDtoInterface } from '../_interfaces/update-user-dto.interface';
import { UserNotificationTransport } from '../_interfaces/user-notification-transport.interface';
import { ChangeUserNotificationsInfo, ChangeUserProfileInfo, SetUserProfileInfo } from '../_store/actions/profile.actions';
import { UpdateUserSocketInterface } from '../../app-shared-elements/_interfaces/update-user-socket.interface';
import { SocketEvent } from '../../app-shared-elements/_enums/socket-event.enum';
import { SocketService } from '../../app-shared-elements/_services/socket.service';

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    private optionsForDateFormat: SelectOptionInterface<UserProfileInfoTypeDateEnum, string, UserProfileInfoTypeDateEnum>[] = [
        {
            type: 'dateFormat',
            property: UserProfileInfoTypeDateEnum.ddMMyyyy,
            key: UserProfileInfoTypeDateEnum.ddMMyyyy,
            value: 'profile.dateFormat.ddMMyyyy',
        },
        {
            type: 'dateFormat',
            property: UserProfileInfoTypeDateEnum.mmDDyyyy,
            key: UserProfileInfoTypeDateEnum.mmDDyyyy,
            value: 'profile.dateFormat.mmDDyyyy',
        },
    ];

    private optionsForLn: SelectOptionInterface<LangEnum>[] = [
        {
            key: LangEnum.ua,
            value: LangEnum.ua.toUpperCase(),
        },
        {
            key: LangEnum.en,
            value: LangEnum.en.toUpperCase(),
        },
    ];

    private userDataHandler;

    constructor(
        private store: Store,
        private luxonParseDateService: LuxonParseDateService,
        private validationService: ValidationService,
        private socketService: SocketService,
    ) {}

    getUserProfileInfo(user: User, isEditable: boolean): UserProfileInfo[] {
        const info: UserProfileInfo[] = [
            {
                title: 'admin.userProfile.login',
                value: user.login,
                isEdit: false,
                isEditable: false,
                type: UserProfileinfoTypeEnum.login,
                elementType: null,
                isValid: true,
            },
            {
                title: 'admin.userProfile.name',
                value: user.name,
                isEdit: false,
                isEditable,
                type: UserProfileinfoTypeEnum.name,
                newValue: user.name,
                elementType: UserProfileInfoTypeElementEnum.input,
                isValid: true,
                inputPlaceholder: 'profile.namePlaceholder',
            },
            {
                title: 'admin.userProfile.phone',
                value: user.phone,
                isEdit: false,
                isEditable,
                type: UserProfileinfoTypeEnum.phone,
                newValue: user.phone,
                elementType: UserProfileInfoTypeElementEnum.input,
                isValid: true,
                inputPlaceholder: 'profile.phonePlaceholder',
            },
            {
                title: 'admin.userProfile.lastAct',
                value: new Date(user.updated).getTime(),
                isEdit: false,
                isEditable: false,
                type: UserProfileinfoTypeEnum.updated,
                isDate: true,
                elementType: null,
                isValid: true,
            },
            {
                title: 'admin.userProfile.registrationDate',
                value: new Date(user.created).getTime(),
                isEdit: false,
                isEditable: false,
                type: UserProfileinfoTypeEnum.created,
                isDate: true,
                elementType: null,
                isValid: true,
            },
        ];

        return this.parseInfo(info);
    }

    getUserProfileInfoSystem(user: User, isEditable: boolean): UserProfileInfo[] {
        const info: UserProfileInfo[] = [
            {
                title: 'admin.userProfile.dateFormat',
                value: user.dateFormat,
                isEdit: false,
                isEditable: false,
                type: UserProfileinfoTypeEnum.dateFormat,
                elementType: UserProfileInfoTypeElementEnum.select,
                isValid: true,
            },
            {
                title: 'admin.userProfile.timezone',
                value: user.dateTimeZone,
                isEdit: false,
                isEditable: false,
                type: UserProfileinfoTypeEnum.dateTimeZone,
                elementType: UserProfileInfoTypeElementEnum.select,
                isValid: true,
            },
            {
                title: 'admin.userProfile.sessionLife',
                value: user.sessionLife.toString(),
                isEdit: false,
                isEditable,
                type: UserProfileinfoTypeEnum.sessionLife,
                newValue: user.sessionLife.toString(),
                elementType: UserProfileInfoTypeElementEnum.input,
                isValid: true,
                maxLength: 2,
                inputPlaceholder: 'profile.sessionLifePlaceholder',
            },
            {
                title: 'admin.userProfile.timeBlocked',
                value: user.timeBlocked.toString(),
                isEdit: false,
                isEditable,
                type: UserProfileinfoTypeEnum.timeBlocked,
                newValue: user.timeBlocked.toString(),
                elementType: UserProfileInfoTypeElementEnum.input,
                isValid: true,
                maxLength: 2,
                inputPlaceholder: 'profile.timeBlockPlaceholder',
            },
        ];

        return this.parseInfo(info);
    }

    private parseInfo(info: UserProfileInfo[]): UserProfileInfo[] {
        return info.map((item) => {
            switch (item.type) {
                case UserProfileinfoTypeEnum.dateTimeZone:
                    const optionsTimezone = this.initTimezoneOptions(item);
                    return {
                        ...item,
                        options: optionsTimezone,
                        currentOption: item.value ? optionsTimezone.find((option) => option.key === item.value) : null,
                    };
                case UserProfileinfoTypeEnum.dateFormat:
                    const optionsFormat = this.initDateTimeOptions(item);

                    return {
                        ...item,
                        options: optionsFormat,
                        currentOption: item.value ? optionsFormat.find((option) => option.key === item.value) : null,
                    };
                case UserProfileinfoTypeEnum.lang:
                    const currentLn = this.store.selectSnapshot(LanguageState.getLanguage);
                    return {
                        ...item,
                        options: this.optionsForLn,
                        currentOption: this.optionsForLn.find((option) => option.key === currentLn) ?? LangEnum.en,
                    };
            }

            return item;
        });
    }

    private initTimezoneOptions(item: UserProfileInfo): SelectOptionInterface[] {
        return this.store
            .selectSnapshot(ConfigurationState.getConfigurationServer)
            .timezones?.map((zone) => {
                const offsetHour = this.luxonParseDateService.getCurrentOffset(zone) / 60;
                const value = `${zone.replace('Kiev', 'Kyiv')} ${offsetHour > 0 ? '+' + offsetHour : offsetHour}`;
                return {
                    property: `${offsetHour}`,
                    value,
                    key: `${zone}`,
                    type: 'timezone',
                };
            })
            .sort((a, b) => +a.property - +b.property);
    }

    private initDateTimeOptions(item: UserProfileInfo): SelectOptionInterface[] {
        return this.optionsForDateFormat;
    }

    isNoValid(data: UpdateUserDtoInterface, userProfileInfo: UserProfileInfo[], userMailingInfo: UserNotificationTransport[]): boolean {
        const validatedUserProfileInfo = userProfileInfo.map((info) => {
            if (this.validationData(info.type, data[info.type])) {
                return {
                    ...info,
                    isValid: true,
                };
            }

            return { ...info, isValid: false };
        });
        this.store.dispatch(new ChangeUserProfileInfo(validatedUserProfileInfo));

        const validatedUserMailingInfo = userMailingInfo.map((info) => {
            const currentTransport = data.notification.find((d) => d.transportType === info.transportType);
            if (!currentTransport) {
                return {
                    ...info,
                    isValid: true,
                };
            }

            if (this.validateTransport(info.transportType, currentTransport.transport)) {
                return {
                    ...info,
                    isValid: true,
                };
            }

            return { ...info, isValid: false };
        });
        this.store.dispatch(new ChangeUserNotificationsInfo(validatedUserMailingInfo));

        return !!validatedUserProfileInfo.find((i) => !i.isValid) || !!validatedUserMailingInfo.find((i) => !i.isValid);
    }

    private validateTransport(type: NotificationTransportType, value: string): boolean {
        const phoneReg = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

        if (!value || !value.length) {
            return true;
        }

        switch (type) {
            case NotificationTransportType.EMAIL:
                return this.validationService.emailValidation(value);
            case NotificationTransportType.VIBER:
                return !!value.match(phoneReg);

            case NotificationTransportType.TELEGRAM:
                return value.length >= 9;
            default:
                return true;
        }
    }

    private validationData(type: UserProfileinfoTypeEnum, value: string): boolean {
        const phoneReg = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;

        if (!value || !value.length) {
            return true;
        }

        switch (type) {
            case UserProfileinfoTypeEnum.phone:
                return !!value.match(phoneReg);
            case UserProfileinfoTypeEnum.name:
                return value.length < 50;
        }

        return true;
    }

    userDataSubs(): void {
        this.userDataRemoveSubs();

        this.userDataHandler = (data: UpdateUserSocketInterface) => {
            if (data.user) {
                this.store.dispatch(new SetUserProfileInfo(data.user));
            }
            this.store.dispatch(new ChangeUserNotificationsInfo(data.transports));
        };

        this.socketService.on(SocketEvent.USER_DATA, this.userDataHandler);
    }

    userDataRemoveSubs(): void {
        this.socketService.removeListener(SocketEvent.USER_DATA);
    }
}

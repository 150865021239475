import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    AddNewMessage,
    CloseEditingStatusMessageRow,
    CreateStatusMessage,
    DeleteStatusMessage,
    GetStatusMessages,
    GetStatusMessagesRows,
    SetEditingStatusMessageRow,
    UpdatedStatusMessageRows,
    UpdateStatusMessage,
} from '../actions/status-messages.actions';
import { StatusMessagesRowInterface } from '../../_interface/status-messages.interface';
import { StatusMessageInterface } from '../../_interface/status-message.interface';
import { ApiResponse } from '../../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../../app-shared-elements/_enums/status.enum';
import { NotificationsService } from '../../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../../app-shared-elements/_enums/tooltip-status.enum';
import { EventMessagesService } from '../../_services/event-messages.service';
import { AlarmTypeEnum } from '../../../../events/_enums/alarm.enum';
import { DisabledTypeEnum } from 'src/app/app-shared-elements/_enums/disabled-type.enum';

export interface StatusMessagesStateModel {
    statusMessages: StatusMessageInterface[];
    statusMessagesRows: StatusMessagesRowInterface[];
    isEditingMode: boolean;
    isEdit: boolean;
}

const STATUS_MESSAGES_TOKEN = new StateToken<StatusMessagesStateModel>('statusMessages');

@State({
    name: STATUS_MESSAGES_TOKEN,
    defaults: {
        statusMessages: [],
        statusMessagesRows: [],
        isEditingMode: false,
        isEdit: true,
    },
})
@Injectable()
export class StatusMessagesState {
    constructor(
        private http: HttpClient,
        private notificationsService: NotificationsService,
        private eventMessagesService: EventMessagesService,
    ) {}

    @Selector()
    static getStatusMessagesRows(state: StatusMessagesStateModel): StatusMessagesRowInterface[] {
        return state.statusMessagesRows;
    }

    @Selector()
    static getIsEditingMode(state: StatusMessagesStateModel): boolean {
        return state.isEditingMode;
    }

    @Selector()
    static getIsEditStatusMessage(state: StatusMessagesStateModel): boolean {
        return state.isEdit;
    }

    @Action(GetStatusMessages)
    async getStatusMessages(ctx: StateContext<StatusMessagesStateModel>): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .get('/api/control/messages/status')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                statusMessages: result.data,
            });

            await ctx.dispatch(new GetStatusMessagesRows()).toPromise();
        }
    }

    @Action(GetStatusMessagesRows)
    async getStatusMessagesRows(ctx: StateContext<StatusMessagesStateModel>): Promise<void> {
        const state = ctx.getState();

        let statusMessagesRows: StatusMessagesRowInterface[] = [];

        state.statusMessages.forEach((message) => {
            const translateType = message.type === AlarmTypeEnum.attention ? 'statusMessage.attention' : 'statusMessage.alarm';
            statusMessagesRows.push({
                id: message.id,
                key: message.key,
                message: message.message,
                type: message.type,
                translateType,
                created: message.created,
                updated: message.updated,
                typeOptions: this.eventMessagesService.getTypeOptions(),
                require: {
                    type: true,
                    message: true,
                    key: true,
                },
                disabledType: DisabledTypeEnum.statusMessageTable,
            });
        });

        statusMessagesRows = statusMessagesRows.sort((a, b) => (a.id > b.id ? 1 : -1));

        ctx.setState({
            ...state,
            statusMessagesRows,
        });
    }

    @Action(AddNewMessage)
    addNewMessage(ctx: StateContext<StatusMessagesStateModel>): void {
        const state = ctx.getState();

        const emptyMessage: StatusMessagesRowInterface = {
            key: '',
            type: null,
            message: '',
            isEditing: true,
            isNew: true,
            typeOptions: this.eventMessagesService.getTypeOptions(),
            require: {
                type: true,
                message: true,
                key: true,
            },
            disabledType: DisabledTypeEnum.statusMessageTable,
        };

        ctx.setState({
            ...state,
            statusMessagesRows: [emptyMessage, ...state.statusMessagesRows],
            isEditingMode: true,
        });
    }

    @Action(CreateStatusMessage)
    async createStatusMessage(ctx: StateContext<StatusMessagesStateModel>, payload: CreateStatusMessage): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post('/api/control/messages/status', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                statusMessages: result.data,
                statusMessagesRows: [
                    {
                        ...result.data,
                        isEditing: false,
                    },
                    ...state.statusMessagesRows.filter((r) => !r.isNew),
                ],
                isEditingMode: false,
            });

            await ctx.dispatch(new GetStatusMessages()).toPromise();
        }
    }

    @Action(UpdateStatusMessage)
    async updateStatusMessage(ctx: StateContext<StatusMessagesStateModel>, payload: UpdateStatusMessage): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .put('/api/control/messages/status', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                statusMessages: result.data,
                statusMessagesRows: [
                    { ...result.data, isEditing: false },
                    ...state.statusMessagesRows.filter((r) => r.id !== result.data.id),
                ],
                isEditingMode: false,
            });
            await ctx.dispatch(new GetStatusMessages()).toPromise();
        }
    }

    @Action(DeleteStatusMessage)
    async deleteStatusMessage(ctx: StateContext<StatusMessagesStateModel>, payload: DeleteStatusMessage): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .delete('/api/control/messages/status', {
                params: { id: payload.id },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                statusMessages: state.statusMessages.filter((r) => r.id !== payload.id),
                statusMessagesRows: state.statusMessagesRows.filter((r) => r.id !== payload.id),
            });
        }
    }

    @Action(CloseEditingStatusMessageRow)
    closeEditingRow(ctx: StateContext<StatusMessagesStateModel>, payload: CloseEditingStatusMessageRow): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            statusMessagesRows: state.statusMessagesRows.map((row) => ({ ...row, isEditing: false })),
            isEditingMode: false,
        });
        ctx.dispatch(GetStatusMessagesRows);
    }

    @Action(SetEditingStatusMessageRow)
    setEditingRow(ctx: StateContext<StatusMessagesStateModel>, payload: SetEditingStatusMessageRow): void {
        const state = ctx.getState();

        if (state.statusMessagesRows.find((r) => r.isEditing)) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, 'admin.units.alreadyEditing');
            return;
        }

        ctx.setState({
            ...state,
            statusMessagesRows: state.statusMessagesRows.map((row) => ({ ...row, isEditing: row.id === payload.id })),
            isEditingMode: true,
        });
    }

    @Action(UpdatedStatusMessageRows)
    updatedStatusMessageRows(ctx: StateContext<StatusMessagesStateModel>, payload: UpdatedStatusMessageRows): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isEdit: payload.isEdit,
        });
    }
}

import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken, Store} from '@ngxs/store';
import {LayoutEnum} from '../../_enums/layout.enum';
import {SidebarStatusEnum} from '../../_enums/sidebar-status.enum';
import {SetFullScreen, SetGroupLayout, SetIsChart, SetIsDisplayHeight, SetIsSidebar, SetSidebarStatus} from '../actions/layout.actions';
import {Router} from '@angular/router';
import {UserState} from './user.state';

export interface LayoutStateModel {
    isSidebar: boolean;
    groupLayout: LayoutEnum;
    isDisplayHeight: boolean;
    isChart: boolean;
    fullScreen: boolean;
    sidebarStatus: SidebarStatusEnum;
}

const LAYOUT_TOKEN = new StateToken<LayoutStateModel>('layoutState');

@State<LayoutStateModel>({
    name: LAYOUT_TOKEN,
    defaults: {
        isSidebar: false,
        groupLayout: LayoutEnum.one,
        isDisplayHeight: false,
        isChart: false,
        fullScreen: false,
        sidebarStatus: SidebarStatusEnum.default
    }
})
@Injectable()
export class LayoutState {
    constructor(private router: Router,
                private store: Store) {
    }

    @Selector()
    static getIsSidebar(state: LayoutStateModel): boolean {
        return state.isSidebar;
    }

    @Selector()
    static getGroupLayout(state: LayoutStateModel): LayoutEnum {
        return state.groupLayout;
    }

    @Selector()
    static getIsDisplayHeight(state: LayoutStateModel): boolean {
        return state.isDisplayHeight;
    }

    @Selector()
    static getFullScreen(state: LayoutStateModel): boolean {
        return state.fullScreen;
    }

    @Selector()
    static getSidebarStatus(state: LayoutStateModel): SidebarStatusEnum {
        return state.sidebarStatus;
    }

    @Selector()
    static getIsChart(state: LayoutStateModel): boolean {
        return state.isChart;
    }


    @Action(SetGroupLayout)
    setGroupLayout(ctx: StateContext<LayoutStateModel>, payload: SetGroupLayout): void {
        const state = ctx.getState();
        const currentParams = this.router.parseUrl(this.router.url).root.children.primary.segments;
        const currentParamsUrl = currentParams.length > 1 || currentParams[1].path ? currentParams[1].path : null;
        const user = this.store.selectSnapshot(UserState.getUser);
        localStorage.setItem(`layout${currentParamsUrl}${user.id}`, `${payload.layout}`);
        ctx.setState({
            ...state,
            groupLayout: payload.layout
        });
    }

    @Action(SetIsSidebar)
    setIsSidebar(ctx: StateContext<LayoutStateModel>, payload: SetIsSidebar): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isSidebar: payload.value
        });
    }

    @Action(SetIsDisplayHeight)
    setIsDisplayHeight(ctx: StateContext<LayoutStateModel>, payload: SetIsDisplayHeight): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isDisplayHeight: payload.value
        });
    }

    @Action(SetFullScreen)
    setFullScreen(ctx: StateContext<LayoutStateModel>, payload: SetFullScreen): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            fullScreen: payload.value
        });
    }

    @Action(SetSidebarStatus)
    setSidebarStatus(ctx: StateContext<LayoutStateModel>, payload: SetSidebarStatus): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            sidebarStatus: payload.status
        });
    }

    @Action(SetIsChart)
    setIsChart(ctx: StateContext<LayoutStateModel>, payload: SetIsChart): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isChart: payload.value
        });
    }
}

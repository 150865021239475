import {MailMessageInterface} from "../../_interfaces/mail-message.interface";
import {Action, Selector, State, StateContext, StateToken} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {
    CloseEditMessage,
    GetMailMessage,
    InitMailMessageRows,
    SetEditMessage,
    UpdateMailMessage
} from "../actions/mail-message.actions";
import {ApiResponse} from "../../../../app-shared-elements/_interfaces/ApiRequest";
import {HttpClient} from "@angular/common/http";
import {HTTP_STATUS} from "../../../../app-shared-elements/_enums/status.enum";
import {MailMessageRowsInterface} from "../../_interfaces/mail-message-rows.interface";
import {
    ColumnEditorType,
    ColumnsTableInterface,
    ColumnTypeEnum,
    ColumnValueAlignEnum
} from "../../../../app-shared-elements/_interfaces/ColumnsTable";
import {NotificationsService} from "../../../../app-shared-elements/_services/notifications.service";
import {TooltipStatusEnum} from "../../../../app-shared-elements/_enums/tooltip-status.enum";
import {MailingCellFieldTypeEnum} from "../../../../mailing/_enums/mailing-cell-field-type.enum";
import {DisabledTypeEnum} from "../../../../app-shared-elements/_enums/disabled-type.enum";
import {MailMessageService} from "../../_services/mail-message.service";

export interface MailMessageStateModel {
    mailMessage: MailMessageInterface[];
    mailMessageRows: MailMessageRowsInterface[];
    mailMessageColumns: ColumnsTableInterface[];
}

const MAIL_MESSAGE_STATE = new StateToken<MailMessageStateModel>('mailMessage');

const mailMessageColumns: ColumnsTableInterface[] = [
    {
        title: 'mailMessage.type',
        maxWidth: '321px',
        minWidth: '321px',
        type: ColumnTypeEnum.text,
        align: ColumnValueAlignEnum.left,
        name: 'type',
    },
    {
        title: 'mailMessage.transportType',
        maxWidth: '101px',
        minWidth: '101px',
        type: ColumnTypeEnum.text,
        align: ColumnValueAlignEnum.left,
        name: 'transportType',
    },
    {
        title: 'mailMessage.subject',
        maxWidth: '381px',
        minWidth: '381px',
        type: ColumnTypeEnum.text,
        align: ColumnValueAlignEnum.left,
        name: 'subject',
    },
    {
        title: 'mailMessage.text',
        grow: true,
        small: false,
        type: ColumnTypeEnum.editor,
        editorType: ColumnEditorType.textarea,
        name: 'text',
        postIcons: true
    },
    {
        title: 'mailing.recipient.table.actions',
        grow: false,
        small: true,
        maxWidth: '140px',
        minWidth: '140px',
        type: ColumnTypeEnum.mailingEditor,
        name: 'edit',
        mailingFieldType: MailingCellFieldTypeEnum.btns
    }
];

@State<MailMessageStateModel>({
    name: MAIL_MESSAGE_STATE,
    defaults: {
        mailMessage: [],
        mailMessageRows: [],
        mailMessageColumns
    }
})

@Injectable()
export class MailMessageState {
    constructor(
        private http: HttpClient,
        private notificationService: NotificationsService,
        private mailMessageService: MailMessageService
    ) {
    }

    @Selector()
    static getMailMessageRows(state: MailMessageStateModel): MailMessageRowsInterface[] {
        return state.mailMessageRows;
    }

    @Selector()
    static getMailMessageColumns(state: MailMessageStateModel): ColumnsTableInterface[] {
        return state.mailMessageColumns;
    }

    @Action(GetMailMessage)
    async getMailMessage(ctx: StateContext<MailMessageStateModel>): Promise<void> {
        const result: ApiResponse = await this.http.get('/api/control/mail').toPromise().catch(e => console.log(e)) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                mailMessage: result.data
            });
        }
    }

    @Action(InitMailMessageRows)
    async initMailMessageRows(ctx: StateContext<MailMessageStateModel>): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            mailMessageRows: state.mailMessage.map(row => {
                return {
                    ...row,
                    isChildRows: row.texts.map(child => {
                        return {
                            ...child,
                            subjectInputList: this.mailMessageService.getSubjectInputList(child.variablesSubject),
                            inputConstructorList: this.mailMessageService.getInputList(child.variables),
                            disabledType: DisabledTypeEnum.mailMessage
                        };
                    })
                };
            }).sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())
        });
    }

    @Action(UpdateMailMessage)
    async updateMailMessage(ctx: StateContext<MailMessageStateModel>, payload: UpdateMailMessage): Promise<void> {
        const result: ApiResponse = await this.http.put('/api/control/mail', {...payload.data}).toPromise().catch(e => console.log(e)) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                mailMessageRows: state.mailMessageRows.map(row => {
                    return {
                        ...row,
                        isChildRows: row.isChildRows.map(item => {
                            if (item.id === result.data.id) {
                                return {
                                    ...result.data
                                };
                            }

                            return item;
                        })
                    };
                })
            });
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(SetEditMessage)
    setEdit(ctx: StateContext<MailMessageStateModel>, payload: SetEditMessage): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            mailMessageRows: state.mailMessageRows.map(row => {
                return {
                    ...row,
                    isChildRows: row.isChildRows.map(child => {
                        return {
                            ...child,
                            isEditing: child.id === payload.id
                        };
                    })
                };
            })
        });
    }

    @Action(CloseEditMessage)
    closeEdit(ctx: StateContext<MailMessageStateModel>, payload: CloseEditMessage): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            mailMessageRows: state.mailMessageRows.map(row => {
                return {
                    ...row,
                    isChildRows: row.isChildRows.map(child => ({
                        ...(payload.savedRow && payload.savedRow.id === child.id ? payload.savedRow : child),
                        isEditing: false
                    }))
                };
            })
        });
    }
}

import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { DeviceConfigurationDto } from '../../_interfaces/device-configuration-dto.interface';
import { DeviceDashboardPositionInterface } from '../../_interfaces/device-dashboard-position.interface';
import { CreationType } from '../../../app-shared-elements/_enums/registrator-sync-status.enu';
import { StatusesEvents } from '../../../events/_interfaces/statuses-events.interface';
import { UpdateLogicEventSocketInterface } from '../../../events/_interfaces/update-logic-event-socket.interface';
import { Variable } from '../../../app-shared-elements/_interfaces/Variable';

export class GetDevices {
    static readonly type = '[Devices] GetDevices';

    constructor(public fromCache?: boolean) {}
}

export class UpdateDevicesState {
    static readonly type = '[Devices] UpdateDevicesState';

    constructor(public devices: Device[]) {}
}

export class SetDeviceFromWs {
    static readonly type = '[Devices] SetDeviceFromWs';

    constructor(
        public devices: Device[],
        public isSetStatusesByActiveEvents?: boolean,
    ) {}
}

export class DeleteDeviceFromWs {
    static readonly type = '[Devices] DeleteDeviceFromWs';

    constructor(public deviceId: string) {}
}

export class DeleteDevice {
    static readonly type = '[Devices] DeleteDevice';

    constructor(public device: Device) {}
}

export class DeleteVirtualDevice {
    static readonly type = '[Devices] DeleteVirtualDevice';

    constructor(
        public device: Device,
        public type?: CreationType,
    ) {}
}

export class ChangeDeviceActivity {
    static readonly type = '[Devices] ChangeDeviceActivity';

    constructor(
        public deviceId: string,
        public isActive: boolean,
    ) {}
}

export class SetCurrentRegistrator {
    static readonly type = '[Devices] SetCurrentRegistrator';

    constructor(public registratorId: string) {
        // console.trace();
    }
}

export class SetCurrentDevice {
    static readonly type = '[Devices] SetCurrentDevice';

    constructor(public deviceId: string) {
        // console.trace();
    }
}

export class InitUserDevicesArray {
    static readonly type = '[Devices] InitUserDevicesArray';
}

export class UpdateUserDevicesArray {
    static readonly type = '[Devices] UpdateUserDevicesArray';

    constructor(public devices: Device[]) {}
}

export class ClearStateDevices {
    static readonly type = '[Devices] ClearStateDevices';
}

export class SyncDevice {
    static readonly type = '[Devices] SyncDevice';

    constructor(public registratorId: string) {}
}

export class UpdateVirtualDevice {
    static readonly type = '[Devices] UpdateVDevice';

    constructor(public device: Device) {}
}

export class SetIsAcknowledgementDeviceLog {
    static readonly type = '[Devices] SetIsAcknowledgementDeviceLog';

    constructor(
        public value: boolean,
        public registratorId: string,
    ) {}
}

export class SetOfflineRegistrator {
    static readonly type = '[Devices] SetOfflineRegistrator';

    constructor(
        public value: boolean,
        public registratorId: string,
    ) {}
}

export class UpdateVariable {
    static readonly type = '[Devices] UpdateVariable';

    constructor(
        public variableId: string,
        public unitId: string,
        public name: string,
    ) {}
}

export class GetUnitsForUser {
    static readonly type = '[Devices] GetUnitsForUser';
}

export class GetUserDevicePosition {
    static readonly type = '[Device] GetUserDevicePosition';
}

export class UpdateUserDeviceArrayStatus {
    static readonly type = '[Devices] UpdateUserDeviceArrayStatus';
    constructor(
        public events: StatusesEvents[],
        public currentEvent?: UpdateLogicEventSocketInterface,
        public data?: Variable[],
    ) {}
}

export class UpdateUserDevicePosition {
    static readonly type = '[Device] UpdateUserDevicePosition';

    constructor(public position: DeviceDashboardPositionInterface[]) {}
}

export class UpdateDevicesPosition {
    static readonly type = '[Device] UpdateDevicesPosition';

    constructor(public position: { id: string; position: number }[]) {}
}

export class SetDeviceConfiguration {
    static readonly type = '[Device] SetDeviceConfiguration';

    constructor(public configuration: DeviceConfigurationDto) {}
}

export class GetAssociatedLogicEvents {
    static readonly type = '[Device] GetAssociatedLogicEvents';

    constructor(public deviceId: string) {}
}

export class IsShowDeletePopup {
    static readonly type = '[Device] IsShowDeletePopup';

    constructor(public show: boolean) {}
}

export class InitRegistratorOptions {
    static readonly type = '[Device] InitRegistratorOptions';
}

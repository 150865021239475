<div class="column">
    <div class="column-date"
         [class.column-date--updated]="value?.updated"
    >

        <div *ngIf="!value?.ts">
            {{value | luxonDate:
            cell.isShortDate ? dataTypeService.shortDateFormat : (user$ | async).dateFormat || dataTypeService.dateFormat : (gmt$ | async) || dataTypeService.GMT : (ln$ | async)
            }}
        </div>

        <ng-container *ngIf="value?.ts">

            <div>
                {{value.ts | luxonDate:
            (user$ | async).dateFormat || dataTypeService.dateFormat : (gmt$ | async) || dataTypeService.GMT : (ln$ | async)}}
            </div>

            <div *ngIf="value?.updated && +value?.count > 1">
                {{value.updated | luxonDate:
            (user$ | async).dateFormat || dataTypeService.dateFormat : (gmt$ | async) || dataTypeService.GMT : (ln$ | async)}}
            </div>

        </ng-container>
    </div>

    <div *ngIf="+value?.count > 1 ?? value?.updated" class="column-date__count">
        {{value.count}}
    </div>
</div>

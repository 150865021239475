import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ActiveEventsState } from '../_store/states/active-events.state';
import { ActiveEvents } from 'src/app/events/_interfaces/ActiveEvents';
import { SetCurrentQueryParams } from '../_store/actions/tabs.actions';
import { FilterTypeSystemLogsEnum } from '../../journals/_enums/filter-group-system-logs.enum';
import { UserState } from '../_store/states/user.state';
import { User } from '../../app-shared-elements/_interfaces/user.interface';
import { PlatformEnum } from '../_enums/platform.enum';
import { takeUntil } from 'rxjs/operators';
import { ActiveDeviceEventState } from 'src/app/journals/_store/states/active-device-event.state';
import { Params } from '../_interfaces/params.interface';
import { SetTechnologicFilter } from 'src/app/journals/_store/actions/technologic-events.action';
import { TechnologicEventsState } from 'src/app/journals/_store/states/technologic-events.state';
import { initialFilter } from 'src/app/journals/_data/events-data';
import { SetErrorAlertDevice, SetErrorAlertEvent } from '../_store/actions/error-alert.actions';
import { ErrorAlertState } from '../_store/states/error-alert.state';
import { ErrorAlertInterface } from '../_interfaces/erorr-alert.interface';

@Component({
    selector: 'app-error-alert',
    templateUrl: './error-alert.component.html',
    styleUrls: ['./error-alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorAlertComponent implements OnInit, OnDestroy {
    @Select(ActiveEventsState.getActiveEvents) activeEvents$: Observable<ActiveEvents[]>;
    @Select(UserState.getUser) user$: Observable<User>;
    @Select(ActiveDeviceEventState.getActiveDeviceEvents) activeDeviceLogs$: Observable<string[]>;
    @Select(ErrorAlertState.getErrorAlertData) errorAlertData$: Observable<ErrorAlertInterface>;

    @Input() platform: PlatformEnum;
    platformEnum = PlatformEnum;

    readonly M_ATTENTION_PATH = './assets/design/icons/events/m-alert-attention.svg';
    readonly M_ALARM_PATH = './assets/design/icons/events/m-alert-alarm.svg';
    readonly ATTENTION_PATH = './assets/design/icons/events/alert-attention.svg';
    readonly ALARM_PATH = './assets/design/icons/events/alert-alarm.svg';
    readonly DEVICE_EVENT_PATH = './assets/design/icons/events/device-event.svg';

    destroy: Subject<boolean> = new Subject<boolean>();

    constructor(private router: Router, private store: Store) {}

    ngOnInit(): void {
        this.activeDeviceLogs$.pipe(takeUntil(this.destroy)).subscribe((activeDeviceEventsArr) => {
            this.store.dispatch(new SetErrorAlertDevice(activeDeviceEventsArr));
        });

        this.activeEvents$.pipe(takeUntil(this.destroy)).subscribe((activeEventsArr) => {
            this.store.dispatch(new SetErrorAlertEvent(activeEventsArr));
        });
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }

    alertClick(): void {
        if (this.platform === PlatformEnum.mobile) {
            const params: Params = this.store.selectSnapshot(TechnologicEventsState.getFilter);
            this.store.dispatch(
                new SetTechnologicFilter({
                    ...params,
                    filter: initialFilter.map((f) => {
                        if (
                            this.store.selectSnapshot(ErrorAlertState.getErrorAlertData).alarmBlink &&
                            (f.title === 'logs.events.filters.act' ||
                                f.title === 'logs.events.filters.alarm' ||
                                f.title === 'logs.events.filters.needAckn')
                        ) {
                            return {
                                ...f,
                                value: true,
                            };
                        }
                        if (f.title === 'logs.events.filters.act' || f.title === 'logs.events.filters.alarm') {
                            return {
                                ...f,
                                value: true,
                            };
                        }
                        return f;
                    }),
                }),
            );

            this.router.navigate(['/m/events']);
            return;
        }

        this.router.navigate(['/journals/events-log'], {
            queryParams: {
                alertParam: encodeURIComponent(
                    JSON.stringify(
                        this.store.selectSnapshot(ErrorAlertState.getErrorAlertData).alarmBlink
                            ? { type: FilterTypeSystemLogsEnum.alarmBlink }
                            : { type: FilterTypeSystemLogsEnum.alarm },
                    ),
                ),
            },
        });
    }

    attentionClick(): void {
        if (this.platform === PlatformEnum.mobile) {
            const params: Params = this.store.selectSnapshot(TechnologicEventsState.getFilter);
            this.store.dispatch(
                new SetTechnologicFilter({
                    ...params,
                    filter: initialFilter.map((f) => {
                        if (
                            this.store.selectSnapshot(ErrorAlertState.getErrorAlertData).attentionBlink &&
                            (f.title === 'logs.events.filters.act' ||
                                f.title === 'logs.events.filters.attention' ||
                                f.title === 'logs.events.filters.needAckn')
                        ) {
                            return {
                                ...f,
                                value: true,
                            };
                        }

                        if (f.title === 'logs.events.filters.act' || f.title === 'logs.events.filters.attention') {
                            return {
                                ...f,
                                value: true,
                            };
                        }
                        return f;
                    }),
                }),
            );
            this.router.navigate(['/m/events']);

            return;
        }

        this.router.navigate(['/journals/events-log'], {
            queryParams: {
                alertParam: encodeURIComponent(
                    JSON.stringify(
                        this.store.selectSnapshot(ErrorAlertState.getErrorAlertData).attentionBlink
                            ? { type: FilterTypeSystemLogsEnum.attentionBlink }
                            : { type: FilterTypeSystemLogsEnum.attention },
                    ),
                ),
            },
        });
    }

    deviceClick(): void {
        this.store.dispatch(new SetCurrentQueryParams({ type: FilterTypeSystemLogsEnum.deviceBlink }));
        this.router.navigate(['/journals/device-log'], { queryParams: { type: FilterTypeSystemLogsEnum.deviceBlink } });
    }
}

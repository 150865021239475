import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {User} from 'src/app/app-shared-elements/_interfaces/user.interface';
import {LangEnum} from 'src/app/app-shared-elements/_enums/lang.enum';
import {ColumnsTableInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import {DataTypeService} from 'src/app/app-shared-elements/_services/data-type.service';
import {LanguageState} from 'src/app/app-shared-elements/_store/states/language.state';
import {UserState} from 'src/app/app-shared-elements/_store/states/user.state';
import {TsClearedPropertyInterface} from 'src/app/app-shared-elements/_interfaces/acknowldge-property.inteface';
import {CompletionType} from 'src/app/journals/_enums/completion-type.enum';

@Component({
    selector: 'app-columns-ts-clear',
    templateUrl: './columns-ts-clear.component.html',
    styleUrls: ['./columns-ts-clear.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsTsClearComponent implements OnInit {

    private _value: TsClearedPropertyInterface;

    buttonValue: string;
    isShowButton: boolean;

    displayValue: string | number;

    @Input()
    set value(value: TsClearedPropertyInterface) {
        this._value = value;
        if (value) {
            this.parseValue();
        }
    }

    get value(): TsClearedPropertyInterface {
        return this._value;
    }

    completionType = CompletionType;

    @Input() cell: ColumnsTableInterface;

    @Select(UserState.getUser) user$: Observable<User>;
    @Select(UserState.getUserTimezoneGMT) gmt$: Observable<string>;
    @Select(LanguageState.getLanguage) ln$: Observable<LangEnum>;


    constructor(
        public dataTypeService: DataTypeService
    ) {
    }

    ngOnInit(): void {
    }

    parseValue(): void {

        if (!this.value.time) {
            this.displayValue = '';
            return;
        }

        if (this.value.time) {
            this.displayValue = +this.value.time;
            return;
        }

    }
}

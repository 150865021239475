<div class="c-input">
    <div class="c-input__wrapper">
        <div class="c-input__input">
            <input class="input" type="text"
                   [(ngModel)]="value"
                   [placeholder]="(placeholder || '') | translate"
                   [disabled]="isDisabled"
                   (click)="isStartInput = true"
                   (input)="isStartInput = false"
                   (change)="inputValue($event.target.value)">
        </div>
        <div *ngIf="items && items.length && isStartInput" class="c-input__items">
            <ng-container *ngFor="let item of items">
                <div (click)="selectItem(item, !item.isSelected)"
                     class="c-input__item">
                    <div
                         class="c-input__value">
                        {{item.value}}</div>

                    <app-checkbox
                        [value]="item.isSelected"
                    ></app-checkbox>
                </div>
            </ng-container>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SocketService } from '../../app-shared-elements/_services/socket.service';
import { Store } from '@ngxs/store';
import { StateResetAll } from 'ngxs-reset-plugin';
import { Logout } from '../_store/actions/auth.actions';
import { SignalService } from '../../app-shared-elements/_services/signal.service';
import { SwitchFaviconService } from '../../app-shared-elements/_services/switch-favicon.service';
import { UsersService } from 'src/app/admin/users/_services/users.service';
import { TypeClient } from 'src/app/admin/users/_enum/type-client.enum';
import { ProfileService } from '../../profile/_services/profile.service';
import { ConfigurationState } from '../../app-shared-elements/_store/states/configuration.state';
import { TimeFilterState } from '../../app-shared-elements/_store/states/time-filter.state';
import { UserState } from '../../app-shared-elements/_store/states/user.state';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private router: Router,
        private socketService: SocketService,
        private store: Store,
        private signalService: SignalService,
        private switchFaviconService: SwitchFaviconService,
        private usersService: UsersService,
        private profileService: ProfileService,
    ) {
        window.addEventListener('storage', async (e) => {
            if (e.key === 'access_token') {
                if (!e.newValue) {
                    console.log('logout from 2');
                    await this.socketService.logoutSubscribe.emit();
                } else {
                    // TODO replace on await this.ngZone.run(() => this.router.navigateByUrl('/profile/user-device'));
                    window.location.href = '/device-dashboard';
                }
            }
        });

        socketService.logoutSubscribe.subscribe(async () => {
            await this.logout();
        });
    }

    public async isAuthenticated(): Promise<boolean> {
        return !!(await this.socketService.getUser());
    }

    async logout(): Promise<void> {
        const currentTypeClient: TypeClient =
            this.usersService.getTypeClient() ?? this.store.selectSnapshot(UserState.getTypeClient) ?? TypeClient.User;

        await this.store.dispatch(new Logout()).toPromise();
        await this.socketService.closeSocket();
        this.profileService.userDataRemoveSubs();
        this.signalService.destroySignal();
        this.switchFaviconService.switchFavicon(false);
        this.socketService.incrementGlobalPreloaderLoaded('notAuthorized');
        this.router.url.includes('control') && currentTypeClient === TypeClient.Admin
            ? this.router.navigate(['/login/control'])
            : this.router.navigate(['/login']);

        // this.store.dispatch(new StateReset(...resetStateList));
        this.store.dispatch(new StateResetAll(ConfigurationState, TimeFilterState));
    }
}

import {Tabs} from '../../_interfaces/Tabs';

export class SetTabs {
    static readonly type = '[Tabs] GetTabs';
    constructor(public tabs: Tabs[]) {
    }
}

export class SetCurrentTab {
    static readonly type = '[Tabs] SetCurrentTab';
    constructor(public tabId: number) {

    }
}

export class SetCurrentTabByPath {
    static readonly type = '[Tabs] SetCurrentTabByPath';
    constructor(public path: string) {

    }
}

export class ClearTabs {
    static readonly type = '[Tabs] ClearTabs';
}

export class SetCurrentQueryParams {
    static readonly type = '[Tabs] SetCurrentQueryParams';
    constructor(public currentQueryParams: any) {}
}

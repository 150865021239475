<div class="heading">
    <div class="heading__wrapper">
        <h1 class="heading__title">
            <span (click)="moveTo()"
                  class="heading__title-main"
                  [class.heading__title-main--click]="isClickTitle"
                  [translate]="title"
                  [translateParams]="translateParams"
            ></span>
            <span *ngIf="nameOfUser">{{nameOfUser}}</span>
            <ng-container *ngIf="breadCrumbs && breadCrumbs.length">
                <span class="heading__breadcrumbs-icon">
                    <svg-icon [src]="BC_ICON_PATH"></svg-icon>
                </span>
                <span
                    class="heading__breadcrumbs">{{breadCrumbsTranslate ? (breadCrumbs | translate) : breadCrumbs}}</span>
            </ng-container>

            <ng-container *ngIf="breadCrumbsChild && breadCrumbsChild.length">
                <span class="heading__breadcrumbs-icon">
                    <svg-icon [src]="BC_ICON_PATH"></svg-icon>
                </span>
                <span
                    class="heading__breadcrumbs">{{breadCrumbsTranslate ? (breadCrumbsChild | translate) : breadCrumbsChild}}</span>
            </ng-container>
        </h1>
        <div class="heading__layout">

            <div *ngIf="isShortCut" (click)="showShortCut()" class="heading__layout-item">

                <div class="heading__layout-icon">
                    <svg-icon [src]="SHORTCUT_ICON_PATH"></svg-icon>
                </div>

                <span [translate]="'mnemonic.shortCut'"></span>

            </div>

            <div *ngIf="showChangeLayout" (click)="showEditLayout()" class="heading__layout-item">
                <div class="heading__layout-icon">
                    <svg-icon [src]="LAYOUT_ICON_PATH"></svg-icon>

                </div>
                <span [translate]="'heading.editLayout'"></span>
            </div>
            <div (click)="setFullScreenContent()" class="heading__layout-item">
                <div class="heading__layout-icon">
                    <svg-icon [src]="FULL_SCREEN_ICON_PATH"></svg-icon>
                </div>
                <span [translate]="'heading.fullScreen'"></span>
            </div>
        </div>
    </div>

    <app-popup *ngIf="showEditLayoutPopup"
               (closeEmit)="showEditLayoutPopup = false"
               [title]="'heading.editLayout'">
        <div class="switch-layout">
            <ul class="switch-layout__list">
                <ng-container *ngFor="let item of switchLayoutTable">
                    <li class="switch-layout__item">

                        <div class="switch-layout__icon" (click)="switchLayout(item.name)">
                            <svg-icon [src]="item.iconPath"></svg-icon>
                        </div>

                        <span class="switch-layout__title" [translate]="item.title"></span>
                    </li>
                </ng-container>

            </ul>
        </div>

    </app-popup>
</div>


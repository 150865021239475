import {Component, Input, OnInit} from '@angular/core';
import {TooltipSideEnum} from '../../_enums/tooltip-side.enum';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent implements OnInit {

    @Input() value: string;
    @Input() side: TooltipSideEnum = TooltipSideEnum.right;
    @Input() template = false;
    @Input() isWhiteSpace: boolean;
    @Input() width = '';
    @Input() maxWidth = '';

    tooltipSideEnum = TooltipSideEnum;

    constructor(
    ) { }

    ngOnInit(): void {
    }

}

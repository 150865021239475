import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropZoneTypeEnum } from '../_enums/drop-zone-type.enum';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone/lib/ngx-dropzone/ngx-dropzone.component';

@Component({
    selector: 'app-drop-zone',
    templateUrl: './drop-zone.component.html',
    styleUrls: ['./drop-zone.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropZoneComponent implements OnInit {
    @Output() closeEvent = new EventEmitter<void>();
    @Output() fileChangedEvent = new EventEmitter<NgxDropzoneChangeEvent>();
    @Output() saveEvent = new EventEmitter<void>();

    @Input() selectFile: File | { base64File: string; name: string };
    @Input() type: DropZoneTypeEnum;
    @Input() title: string;
    @Input() subTitle: string;
    @Input() accept: string;
    @Input() maxFileSize: number;

    dropZoneTypeEnum = DropZoneTypeEnum;

    constructor() {}

    ngOnInit(): void {}

    onFileChanged(event: NgxDropzoneChangeEvent): void {
        this.fileChangedEvent.emit(event);
    }

    goToSettings(): void {
        this.saveEvent.emit();
    }

    close(): void {
        this.closeEvent.emit();
    }
}

import { ServicesInvoiceInterface } from './billing-service-invoice.interface';
import { LangEnum } from '../../app-shared-elements/_enums/lang.enum';
import { ColumnsActionTypeEnum } from '../../app-shared-elements/_enums/columns-action-type.enum';

export interface BillingInvoiceInterface {
    id: string;
    invoiceNumber: number;
    dateInvoice: string;
    totalPayable: number | string;
    datePaid: Date;
    userId: string;
    providerDescription: any;
    payerDescription: any;
    currencyId: string;
    paymentStatus: PaymentStatusEnum;
    services: ServicesInvoiceInterface[];
    debt: number;
    created: Date;
    lang?: LangEnum;
    vatSum: number;
    sumWithoutVAT: number;
    vat: number;
}

export interface BillingInvoiceRowInterface extends BillingInvoiceInterface {
    invoiceDateNumber?: string;
    date?: string;
    status?: string | Date;
    rowStyles?: any;
    styles?: {
        status: any;
    };
    disabledActions?: ColumnsActionTypeEnum[];
}

export enum PaymentStatusEnum {
    NOT_COMPLETE = 0,
    AWAITING_PAYMENT = 1,
    PAYMENT_NOT_PAID = 2,
    LATE_PAYMENT = 3,
    PAID = 4,
}

export interface InvoiceAdminInterface extends BillingInvoiceInterface {
    debitorName: string;
}

<div class="user-profile__tab">

    <app-user-devices
        [deviceTableColumns]="adminProfileService.deviceColumns"
        [userDevicesArray]="devicesArray$ | async"
        [typeClient]="typeClient.Admin"
        [filterType]="filterType"
        [user]="user$ | async"
        [searchFilter]="searchFilter"
        [optionsRegistrators]="registratorsOptions$ | async"
        (openDeviceEvent)="openDevice($event)"
        (changeFilterEvent)="changeFilter($event)"
        (activeDeviceEvent)="parseActionDevices($event)"
        (actionEvent)="parseAction($event)"
    ></app-user-devices>

</div>

<app-popup *ngIf="isOpenRoleInfoPopup$ | async"
           [title]="'detailsDevice.roleInfoTitle'"
           (closeEmit)="closeRoleInfoPopup()"
>

    <app-tables
        [currentTableColumns]="tableColumn"
        [rows]="roleInfo$ | async"
        [type]="tableTypeEnum.notFull"
    ></app-tables>


    <button (click)="closeRoleInfoPopup()"
            class="btn btn--light"
            [translate]="'btns.close'"></button>
</app-popup>

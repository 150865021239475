import {Observable} from 'rxjs';
import {Component, OnDestroy} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {LayoutState} from '../app-shared-elements/_store/states/layout.state';
import {first} from 'rxjs/operators';
import {ActivatedRoute, Data} from '@angular/router';
import {SetIsAdmin} from '../auth/_store/actions/auth.actions';

/**
 * Корень основной части приложения. Содержит router-outlet для
 * роутинга на MainComponent (или на любой другой шаблон в будущем).
 */
@Component({
    selector: 'app-template',
    templateUrl: './app-template.component.html',
    styleUrls: ['./app-template.component.scss']
})
export class AppTemplateComponent implements OnDestroy {


    @Select(LayoutState.getIsSidebar) showSideBar$: Observable<boolean>;

    constructor(
        private store: Store,
        private route: ActivatedRoute
    ) {
        this.route.data.pipe(first()).subscribe((data: Data) => {
            this.store.dispatch(new SetIsAdmin(data.isAdmin as boolean));
        });

    }

    ngOnDestroy(): void {

    }
}

import {Component, Input, OnInit} from '@angular/core';
import {ColumnsTableInterface} from "../_interfaces/ColumnsTable";
import {CustomTooltipTypeEnum} from "../_enums/custom-tooltip-type.enum";

@Component({
    selector: 'app-custom-tooltip-message',
    templateUrl: './custom-tooltip-message.component.html',
    styleUrls: ['./custom-tooltip-message.component.scss']
})
export class CustomTooltipMessageComponent implements OnInit {

    statusTooltip: { title: string, color: string }[] = [
        {
            title: '- Послуга оплачена',
            color: '#0FA579'
        },
        {
            title: '- Несплачено своєчасно',
            color: '#F9852C'
        },
        {
            title: '- Протерміновано',
            color: '#FF6161'
        }
    ];


    @Input() currentColumn: ColumnsTableInterface;

    customTooltipTypeEnum = CustomTooltipTypeEnum;

    constructor() {
    }

    ngOnInit(): void {
    }

}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PasswordValidateService {
    constructor() {}

    validatePassword(value: string | any): any {
        let strength = 0;

        if (!value?.length) {
            return -1;
        }

        if (value.length > 0) {
            strength = 0;
        }

        if (value.match(/([a-zA-Z])/) && value.match(/([0-9])/) && !value.match(/([а-я])/)) {
            strength += 1;
        }

        if (value.match(/([!,%,&,@,#,$,^,*,?,_,~, +, =, |, /])/)) {
            strength += 1;
        }

        if (value.includes(' ')) {
            strength = 0;
        }

        if (value.length > 0 && value.length < 8) {
            strength = 0;
        }

        return strength;
    }
}

import { Component, Input } from '@angular/core';
import { ImportExportEventInterface } from '../../admin/users/_interfaces/import-export-event.interface';

@Component({
    selector: 'app-import-export-preloader',
    templateUrl: './import-export-preloader.component.html',
    styleUrls: ['./import-export-preloader.component.scss'],
})
export class ImportExportPreloaderComponent {
    @Input() data: ImportExportEventInterface;
    @Input() requestId: number;
    @Input() isDone: boolean;

    constructor() {}
}

import { Injectable } from '@angular/core';
import { TransportDashboardInterfaceVariable } from '../_interfaces/transport-dashboard.interface';
import { Store } from '@ngxs/store';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { ActiveEventsState } from 'src/app/app-shared-elements/_store/states/active-events.state';
import { ColumnIconType, ColumnModeEnum, ColumnsTableInterface, ColumnTypeEnum, IconInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { LogicEventType } from 'src/app/events/logical-events/_interface/LogicEvent';
import { EditExpeditionRowInterface } from '../_interfaces/edit-expedition-row.interface';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { ConditionType } from 'src/app/events/_enums/condition-type.enum';
import { LogicalEventsService } from 'src/app/events/_services/logical-events.service';
import { TransportLimitLogicEvent, TransportLogicEventInterface } from '../_interfaces/transport-logic-event.interface';
import { TransportDto } from '../_interfaces/TransportDeviceDto';
import { TransportPositionInterface } from '../_interfaces/transport-position.interface';
import { AlarmTypeEnum } from '../../../events/_enums/alarm.enum';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { Params } from '@angular/router';
import { ParamsFilterForClient } from '../../../app-shared-elements/_interfaces/params.interface';
import { DeviceTypeEnum } from '../../../app-shared-elements/_enums/device-type.enum';
import { VariablesNameEnum } from '../../../app-shared-elements/_enums/variables-name.enum';
import { User } from '../../../app-shared-elements/_interfaces/user.interface';
import { CreationType } from '../../../app-shared-elements/_enums/registrator-sync-status.enu';
import { TechnologicEvent } from '../../../events/_interfaces/TechnologicEvent';

@Injectable({
    providedIn: 'root',
})
export class TransportDashboardService {
    public columnsEditExpedition: ColumnsTableInterface[] = [
        {
            title: 'transportDashboard.edit.table.sensor',
            type: ColumnTypeEnum.text,
            name: 'name',
            grow: true,
        },
        {
            title: 'transportDashboard.edit.table.bottomLimit',
            type: ColumnTypeEnum.input,
            name: LogicEventType.alarmDeadlineMin,
            eventsIcon: AlarmTypeEnum.alarm,
            icon: ColumnIconType.events,
            minWidth: '170px',
            mode: ColumnModeEnum.text,
            propertyValidationTypeForInput: 'validationType',
            thIconPath: './assets/design/icons/events/bottom-limit-alarm.svg',
            preIcons: true,
        },
        {
            title: 'transportDashboard.edit.table.topLimit',
            type: ColumnTypeEnum.input,
            name: LogicEventType.alarmDeadlineMax,
            eventsIcon: AlarmTypeEnum.alarm,
            icon: ColumnIconType.events,
            minWidth: '170px',
            mode: ColumnModeEnum.text,
            propertyValidationTypeForInput: 'validationType',
            thIconPath: './assets/design/icons/events/top-limit-alarm.svg',
            preIcons: true,
        },
        {
            title: 'transportDashboard.edit.table.bottomLimit',
            type: ColumnTypeEnum.input,
            name: LogicEventType.attentionDeadlineMin,
            eventsIcon: AlarmTypeEnum.attention,
            icon: ColumnIconType.events,
            minWidth: '170px',
            mode: ColumnModeEnum.text,
            propertyValidationTypeForInput: 'validationType',
            thIconPath: './assets/design/icons/events/bottom-limit-attention.svg',
            preIcons: true,
        },
        {
            title: 'transportDashboard.edit.table.topLimit',
            type: ColumnTypeEnum.input,
            name: LogicEventType.attentionDeadlineMax,
            eventsIcon: AlarmTypeEnum.attention,
            icon: ColumnIconType.events,
            minWidth: '170px',
            mode: ColumnModeEnum.text,
            propertyValidationTypeForInput: 'validationType',
            thIconPath: './assets/design/icons/events/top-limit-attention.svg',
            preIcons: true,
        },
    ];

    constructor(
        private store: Store,
        private logicalEventsService: LogicalEventsService,
    ) {}

    getCurrentTransportVariable(deviceId: string, variableId: string, registratorId: string, expeditionLimit: TransportLogicEventInterface<TransportLimitLogicEvent>[]): TransportDashboardInterfaceVariable {
        const currentDevice = this.store.selectSnapshot(DevicesState.getDevices).find((device) => device.id === deviceId);
        const currentLimit = expeditionLimit.find((e) => e.variableId === variableId);
        if (!currentDevice) {
            return;
        }

        const currentVariable = currentDevice.variables.find((variable) => variable.id === variableId);
        if (!currentVariable) {
            return;
        }

        return {
            deviceId: currentVariable.deviceId,
            name: currentVariable.customName ?? currentVariable.name,
            id: currentVariable.id,
            value: currentVariable.unitName ? `${currentVariable.currentValue} ${currentVariable.unitName}` : currentVariable.currentValue,
            status: currentVariable.status,
            acknowledgeableStatus: this.getAknStatus(registratorId, currentVariable.id),
            unit: currentVariable.unitName,
            isConnect: currentVariable.isConnect,
            device: currentDevice,
            alarmMinLimit: this.getValueFoDatalogger(currentLimit, LogicEventType.alarmDeadlineMin),
            alarmMaxLimit: this.getValueFoDatalogger(currentLimit, LogicEventType.alarmDeadlineMax),
        };
    }

    getAknStatus(transportRegistratorId?: string, variableId?: string): boolean {
        const events = this.store.selectSnapshot(ActiveEventsState.getActiveEvents);
        if (!events) {
            return false;
        }
        const currentEvents = events.find((event) => event.registratorId === transportRegistratorId);

        if (!currentEvents) {
            return false;
        }
        const currentEvent = currentEvents.events.find((event) => event.variableId === variableId);

        return currentEvent && currentEvent.isAknowledgeable && !currentEvent.tsAcknowledget && !currentEvent.tsCleared;
    }

    isTransportVisible(device: Device, params: Params): boolean {
        if (!params || !params.filter?.length) {
            return true;
        }

        const choosedFilters = params.filter.filter((f) => f.value);
        let flatFilters: ParamsFilterForClient[] = [];

        choosedFilters.forEach((f) => {
            if (f.relationTrue) {
                flatFilters = [...flatFilters, ...f.relationTrue];
                return;
            }

            flatFilters = [...flatFilters, f];
        });

        let count = 0;
        const currentLength = new Set(flatFilters.map((f) => f.property)).size;
        flatFilters.forEach((f) => {
            if (f.value === device[f.property]) {
                count++;
            }
        });
        return count === currentLength;
    }

    getCoordinatorStatus(registratorId: string): boolean {
        const currentCoordinators = this.store.selectSnapshot(DevicesState.getDevices).filter((d) => d.registratorId === registratorId && d.type === DeviceTypeEnum.coordinator);
        if (!currentCoordinators || !currentCoordinators.length) {
            return;
        }
        const currentVariableStatus = currentCoordinators.find((c) => c.variables.find((v) => v.name === VariablesNameEnum.PowerSupplyStatus && String(v.currentValue).includes('e11')));
        return !!(currentCoordinators && currentCoordinators.length && currentVariableStatus);
    }

    getPosition(transport: TransportDto, user: User): number {
        const pos: TransportPositionInterface[] = JSON.parse(localStorage.getItem(`transportPosition${user.id}`));
        if (!pos) {
            return;
        }
        const position = pos.find((p) => transport.registratorId === p.registratorId);
        if (!position) {
            return null;
        }
        return position.position;
    }

    initEditExpeditionsRows(events: TransportLogicEventInterface<TransportLimitLogicEvent>[], variables: Variable[]): EditExpeditionRowInterface[] {
        return variables.map((v) => {
            const currentEvent = events.find((e) => e.variableId === v.id);
            return {
                name: v.customName ?? v.name,
                id: v.id,
                [LogicEventType.alarmDeadlineMin]: this.getValueFromEvent(currentEvent, LogicEventType.alarmDeadlineMin),
                [LogicEventType.alarmDeadlineMax]: this.getValueFromEvent(currentEvent, LogicEventType.alarmDeadlineMax),
                [LogicEventType.attentionDeadlineMin]: this.getValueFromEvent(currentEvent, LogicEventType.attentionDeadlineMin),
                [LogicEventType.attentionDeadlineMax]: this.getValueFromEvent(currentEvent, LogicEventType.attentionDeadlineMax),
                validationType: this.logicalEventsService.findCurrentOperandType(v),
                disabledProperties: {
                    [LogicEventType.alarmDeadlineMin]: this.getActivityFromEvent(currentEvent, LogicEventType.alarmDeadlineMin),
                    [LogicEventType.alarmDeadlineMax]: this.getActivityFromEvent(currentEvent, LogicEventType.alarmDeadlineMax),
                    [LogicEventType.attentionDeadlineMin]: this.getActivityFromEvent(currentEvent, LogicEventType.attentionDeadlineMin),
                    [LogicEventType.attentionDeadlineMax]: this.getActivityFromEvent(currentEvent, LogicEventType.attentionDeadlineMax),
                },
                preIcons: this.getPreIcons(currentEvent),
            };
        });

        // return result;
    }

    getValueFoDatalogger(currentEvent: TransportLogicEventInterface<TransportLimitLogicEvent>, logicEventType: LogicEventType): { value: string } {
        if (!currentEvent || !currentEvent[logicEventType] || !this.getActivityFromEvent(currentEvent, currentEvent[logicEventType])) {
            return {
                value: '',
            };
        }
        const constantValue = currentEvent[logicEventType].expression.conditions[0]?.value.find((v) => v.type === ConditionType.Constant);

        if (!constantValue) {
            return {
                value: '',
            };
        }

        return {
            ...currentEvent[logicEventType],
            oldValue: constantValue.value as string,
            value: constantValue.value as string,
        };
    }
    getValueFromEvent(currentEvent: TransportLogicEventInterface<TransportLimitLogicEvent>, logicEventType: LogicEventType): string {
        if (!currentEvent || !currentEvent[logicEventType] || !this.getActivityFromEvent(currentEvent, currentEvent[logicEventType])) {
            return '';
        }
        const constantValue = currentEvent[logicEventType].expression.conditions[0]?.value.find((v) => v.type === ConditionType.Constant);

        if (!constantValue) {
            return '';
        }

        return constantValue.value as string;
    }

    private getActivityFromEvent(currentEvent: TransportLogicEventInterface<TransportLimitLogicEvent>, logicEventType: LogicEventType): boolean {
        if (this.store.selectSnapshot(DevicesState.getCurrentRegistrator)?.creationType === CreationType.VIRTUAL) {
            return true;
        }
        return currentEvent && currentEvent[logicEventType] ? !currentEvent[logicEventType].isActive : true;
    }

    private getPreIcons(currentEvent: TransportLogicEventInterface<TransportLimitLogicEvent>): IconInterface<void>[] {
        const currentActiveEvent = this.getActiveEventByVariable(currentEvent?.variableId);
        if (!currentActiveEvent) {
            return [];
        }

        const currentTransportEvent = Object.values(currentEvent).find((e) => e.id === currentActiveEvent.srcId);

        switch (currentTransportEvent?.logicEventType) {
            case LogicEventType.attentionDeadlineMin:
            case LogicEventType.attentionDeadlineMax:
                return [
                    {
                        path: './assets/design/icons/footer/device-attention.svg',
                        action: null,
                        cellNames: [currentTransportEvent.logicEventType],
                    },
                ];
            case LogicEventType.alarmDeadlineMin:
            case LogicEventType.alarmDeadlineMax:
                return [
                    {
                        path: './assets/design/icons/footer/device-alarm.svg',
                        action: null,
                        cellNames: [currentTransportEvent.logicEventType],
                    },
                ];
        }
        return;
    }

    getActiveEventByVariable(variableId: string): TechnologicEvent {
        const activeEvents = this.store.selectSnapshot(ActiveEventsState.getActiveEvents);
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);
        const activeEventByRegistrator = activeEvents.find((e) => e.registratorId === currentRegistrator?.id);
        return activeEventByRegistrator?.events?.find((event) => !event.tsCleared && event.variableId === variableId);
    }
}

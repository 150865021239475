import {Params} from "../../../app-shared-elements/_interfaces/params.interface";
import {BillingInvoiceInterface, BillingInvoiceRowInterface} from "../../_interfaces/billing-invoice.interface";
import {ServicesInvoiceInterface} from "../../_interfaces/billing-service-invoice.interface";

export class GetBillingInvoice {
    static readonly type = '[BillingInvoice] GetBillingInvoice';
}

export class InitBillingInvoiceRows {
    static readonly type = '[BillingInvoice] InitBillingInvoiceRows';

}

export class SetBillingInvoiceParams {
    static readonly type = '[BillingInvoice] SetBillingInvoiceParams';

    constructor(public params: Params) {

    }
}

export class GetCurrentInvoice {
    static readonly type = '[BillingInvoice] GetCurrentInvoice';

}

export class ToggleInvoicePopup {
    static readonly type = '[BillingInvoice] ToggleInvoicePopup';

    constructor(public isPopup: boolean) {
    }
}

export class GetCurrentViewInvoice {
    static readonly type = '[BillingInvoice] GetCurrentViewInvoice';

    constructor(public invoice: BillingInvoiceInterface) {
    }
}

export class SetGenerationServerInvoice {
    static readonly type = '[BillingInvoice] SetGenerationServerInvoice';

    constructor(public invoiceId: string) {
    }

}

export class DownloadInvoice {
    static readonly type = '[BillingInvoice] DownloadInvoice';

    constructor(public row: BillingInvoiceRowInterface) {
    }
}

export class GetCurrentInvoiceFromServer {
    static readonly type = '[BillingInvoice] GetCurrentInvoiceFromServer';

    constructor(public token: string) {
    }
}

export class ClearCurrentInvoice {
    static readonly type = '[BillingInvoice] ClearCurrentInvoice';

}

export class AddNewAdditional {
    static readonly type = '[BillingInvoice] AddNewAdditional';

}

export class CreateAdditional {
    static readonly type = '[BillingInvoice] CreateAdditional';

    constructor(public data: { invoiceId: string, serviceDescription: string, paymentAmount: number, countServices: number }) {
    }

}

export class UpdateAdditional {
    static readonly type = '[BillingInvoice] UpdateAdditional';

    constructor(public data: { serviceDescription: string, paymentAmount: number, countServices: number }) {
    }

}

export class DeleteAdditional {
    static readonly type = '[BillingInvoice] DeleteAdditional';

    constructor(public id: string) {
    }

}

export class SetEditingAdditional {
    static readonly type = '[BillingInvoice] SetEditingAdditional';

    constructor(public id: string) {
    }
}

export class CloseEditingAdditional {
    static readonly type = '[BillingInvoice] CloseEditingAdditional';

    constructor(public id: string, public savedRow: ServicesInvoiceInterface) {
    }
}

export class ClearPdf {
    static readonly type = '[BillingInvoice] ClearPdf';

}


import { Injectable } from '@angular/core';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { User } from '../../../app-shared-elements/_interfaces/user.interface';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    constructor() {}

    getUserOptions(users: User[]): SelectOptionInterface<string, string, User>[] {
        console.log(users);

        const options: SelectOptionInterface<string, string, User>[] = [];

        users.map((user) => {
            options.push({
                key: user.id,
                value: user.name,
                property: user,
            });
        });

        return options;
    }
}

import {DataTypeService} from './../../../_services/data-type.service';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColumnsTableInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import {Store} from '@ngxs/store';
import {LuxonDatePipe} from 'src/app/app-shared-elements/_pipes/luxon-date.pipe';
import {ColumnButtonItem} from '../../_interfaces/column-button-item.interface';

@Component({
    selector: 'app-columns-button',
    templateUrl: './columns-button.component.html',
    styleUrls: ['./columns-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsButtonComponent implements OnInit {

    @Input() cell: ColumnsTableInterface;
    @Input() value: ColumnButtonItem<any>[];

    @Output() clickBtnEvent: EventEmitter<ColumnButtonItem<any>> = new EventEmitter<ColumnButtonItem<any>>();


    constructor(private dataTypeService: DataTypeService, private luxonDatePipe: LuxonDatePipe, private store: Store) {
    }

    ngOnInit(): void {
    }


    emitClickBtn(item: ColumnButtonItem<any>): void {
        this.clickBtnEvent.emit(item);
    }

}

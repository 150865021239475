import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    CloseEditingServiceRow,
    CreateFinanceService,
    DeleteFinanceService,
    GetFinanceServices,
    NewFinanceService,
    SetEditingServiceRow,
    UpdateFinanceService,
} from '../actions/finance-services.actions';
import { ApiResponse } from '../../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../../app-shared-elements/_enums/status.enum';
import { FinanceServicesInterface, FinanceServicesRowInterface } from '../../_interfaces/finance-services.interface';
import { NotificationsService } from '../../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../../app-shared-elements/_enums/tooltip-status.enum';
import { CurrencyInterface } from '../../_interfaces/currency.interface';
import { DisabledTypeEnum } from '../../../../app-shared-elements/_enums/disabled-type.enum';
import { CurrencyState } from '../../../../app-shared-elements/_store/states/currency.state';

export interface FinanceServicesStateModel {
    services: FinanceServicesInterface[];
    servicesRows: FinanceServicesRowInterface[];
    isEditingMode: boolean;
    currency: CurrencyInterface[];
}

const FINANCE_SERVICES_STATE = new StateToken<FinanceServicesStateModel>('financeServices');

@State<FinanceServicesStateModel>({
    name: FINANCE_SERVICES_STATE,
    defaults: {
        services: [],
        servicesRows: [],
        isEditingMode: false,
        currency: null,
    },
})
@Injectable()
export class FinanceServicesState {
    constructor(private http: HttpClient, private store: Store, private notificationService: NotificationsService) {}

    @Selector()
    static getFinanceServices(state: FinanceServicesStateModel): FinanceServicesInterface[] {
        return state.services;
    }

    @Selector()
    static getFinanceRowServices(state: FinanceServicesStateModel): FinanceServicesRowInterface[] {
        return state.servicesRows.sort((a, b) =>
            a.serviceDescription.toLocaleLowerCase().toLowerCase() <
            b.serviceDescription.toLocaleLowerCase().toLowerCase()
                ? -1
                : 1,
        );
    }

    @Selector()
    static getIsEditingMode(state: FinanceServicesStateModel): boolean {
        return state.isEditingMode;
    }

    @Selector()
    static getCurrency(state: FinanceServicesStateModel): CurrencyInterface[] {
        return state.currency;
    }

    @Action(GetFinanceServices)
    async getFinanceServices(ctx: StateContext<FinanceServicesStateModel>): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/control/billing/constant-services')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                services: result.data,
                servicesRows: result.data.map((item) => ({
                    ...(item as FinanceServicesInterface),
                    currency: this.store.selectSnapshot(CurrencyState.getCurrency),
                    isEditing: false,
                })),
            });
        }
    }

    @Action(UpdateFinanceService)
    async updateFinanceService(
        ctx: StateContext<FinanceServicesStateModel>,
        payload: UpdateFinanceService,
    ): Promise<void> {
        const result: ApiResponse = (await this.http
            .put('/api/control/billing/constant-services', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                services: [result.data, ...state.services],
                servicesRows: [
                    {
                        ...result.data,
                        isEditing: false,
                        require: {
                            serviceDescription: true,
                            paymentAmount: true,
                        },
                        disabledType: DisabledTypeEnum.financeServices,
                    },
                    ...state.servicesRows.filter((row) => row.id !== result.data.id),
                ],
                isEditingMode: false,
            });

            ctx.dispatch(new GetFinanceServices());
        }
    }

    @Action(NewFinanceService)
    newFinanceService(ctx: StateContext<FinanceServicesStateModel>): void {
        const state = ctx.getState();

        const newService: FinanceServicesRowInterface = {
            isEditing: true,
            isNew: true,
            serviceDescription: '',
            require: {
                serviceDescription: true,
                paymentAmount: true,
            },
            currency: this.store.selectSnapshot(CurrencyState.getCurrency),
            paymentAmount: null,
            disabledType: DisabledTypeEnum.financeServices,
        };

        ctx.setState({
            ...state,
            servicesRows: [newService, ...state.servicesRows],
        });
    }

    @Action(CreateFinanceService)
    async createFinanceService(
        ctx: StateContext<FinanceServicesStateModel>,
        payload: CreateFinanceService,
    ): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/control/billing/constant-services', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                services: [result.data, ...state.services],
                servicesRows: [
                    {
                        ...result.data,
                        isEditing: false,
                        require: {
                            serviceDescription: true,
                            paymentAmount: true,
                        },
                        disabledType: DisabledTypeEnum.financeServices,
                    },
                    ...state.servicesRows.filter((row) => !row.isNew),
                ],
                isEditingMode: false,
            });

            ctx.dispatch(new GetFinanceServices());
        }
    }

    @Action(DeleteFinanceService)
    async deleteFinanceService(
        ctx: StateContext<FinanceServicesStateModel>,
        payload: DeleteFinanceService,
    ): Promise<void> {
        const result: ApiResponse = (await this.http
            .delete('/api/control/billing/constant-services', {
                params: {
                    id: payload.id,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                services: state.services.filter((s) => s.id !== payload.id),
                servicesRows: state.servicesRows.filter((f) => f.id !== payload.id),
            });

            ctx.dispatch(new GetFinanceServices());
        }
    }

    @Action(CloseEditingServiceRow)
    closeEditingRow(ctx: StateContext<FinanceServicesStateModel>, payload: CloseEditingServiceRow): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            servicesRows: state.servicesRows.map((row) => ({
                ...(payload.savedRow && payload.savedRow.id === row.id ? payload.savedRow : row),
                isEditing: false,
            })),
            isEditingMode: false,
        });

        ctx.dispatch(GetFinanceServices);
    }

    @Action(SetEditingServiceRow)
    setEditingRow(ctx: StateContext<FinanceServicesStateModel>, payload: SetEditingServiceRow): void {
        const state = ctx.getState();

        if (state.servicesRows.find((r) => r.isEditing)) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false, 'admin.units.alreadyEditing');
            return;
        }

        ctx.setState({
            ...state,
            servicesRows: state.servicesRows.map((row) => ({ ...row, isEditing: row.id === payload.id })),
            isEditingMode: true,
        });
    }
}

import {Action, Selector, State, StateContext, StateToken} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {GetCity, GetCountry, GetStates, SetCurrentCountry} from "../actions/country-state-city.actions";
import {ApiResponse} from "../../_interfaces/ApiRequest";
import {HttpClient} from "@angular/common/http";
import {HTTP_STATUS} from "../../_enums/status.enum";
import {CountryInterface} from "../../_interfaces/country.interface";
import {CityInterface} from "../../_interfaces/city.interface";
import {StatesInterface} from "../../_interfaces/states.interface";

export interface CountryStateCityStateModel {
    country: CountryInterface[];
    states: StatesInterface[];
    city: CityInterface[];
    currentCountry: CountryInterface;
}

const COUNTRY_STATE_CITY_STATE = new StateToken<CountryStateCityStateModel>('countryStateCity');

@State<CountryStateCityStateModel>({
    name: COUNTRY_STATE_CITY_STATE,
    defaults: {
        country: [],
        states: [],
        city: [],
        currentCountry: null
    }
})

@Injectable()
export class CountryStateCityState {
    constructor(
        private http: HttpClient
    ) {
    }

    @Selector()
    static getCountry(state: CountryStateCityStateModel): CountryInterface[] {
        return state.country;
    }

    @Selector()
    static getStates(state: CountryStateCityStateModel): StatesInterface[] {
        return state.states;
    }

    @Selector()
    static getCity(state: CountryStateCityStateModel): CityInterface[] {
        return state.city;
    }

    @Selector()
    static getCurrentCountry(state: CountryStateCityStateModel): CountryInterface {
        return state.currentCountry;
    }

    @Action(GetCountry)
    async getCountry(ctx: StateContext<CountryStateCityStateModel>): Promise<void> {
        const result: ApiResponse = await this.http.get('api/country').toPromise().catch(e => console.log(e)) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {

            ctx.setState({
                ...state,
                country: (result.data as CountryInterface[]).map(country => {
                    return {
                        ...country,
                        name: country.isoCode === 'RU' ? country.name + ' [terrorist state]' : country.name
                    };
                })
            });

        }
    }

    @Action(GetStates)
    async getStates(ctx: StateContext<CountryStateCityStateModel>, payload: GetStates): Promise<void> {
        const result: ApiResponse = await this.http.get('api/country/state', {
            params: {
                countryCode: payload.countryCode
            }
        }).toPromise().catch(e => console.log(e)) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {

            ctx.setState({
                ...state,
                states: result.data
            });

        }
    }

    @Action(GetCity)
    async getCity(ctx: StateContext<CountryStateCityStateModel>, payload: GetCity): Promise<void> {
        const result: ApiResponse = await this.http.get('api/country/city', {
            params: {
                countryCode: payload.countryCode,
                stateCode: payload.stateCode
            }
        }).toPromise().catch(e => console.log(e)) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {

            ctx.setState({
                ...state,
                city: result.data
            });

        }
    }

    @Action(SetCurrentCountry)
    setCurrentCountry(ctx: StateContext<CountryStateCityStateModel>, payload: SetCurrentCountry): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentCountry: state.country.find(f => f.isoCode === payload.isoCode)
        });
    }
}

export enum ConditionTypeEnum {
    logic = 0,
    action = 1,
    constant = 2,
    device = 3
}


export enum OperandConstantType {
    FLOAT = 'float',
    INT = 'int',
    STRING = 'string',
    BOOL = 'bool'
}
export enum ConditionType {
    Not = '!=',
    LessThan = '<',
    LessThanOrEqual = '<=',
    MoreThan = '>',
    MoreThanOrEqual = '>=',
    Equal = '==',
    Plus = '+',
    Minus = '-',
    Division = '/',
    Multiplication = '*',
    And = '&&',
    Or = '||',
    Constant = 'constant',
    Tag = 'tag',
    Negation = '!'
}

import { ChartModeEnum } from 'src/app/app-shared-elements/_enums/chart-mode.enum';
import { ArchivePreloaderData } from 'src/app/app-shared-elements/_interfaces/archive-preloader-data.interface';
import { Axis } from 'src/app/app-shared-elements/_interfaces/Axis';
import { DatePickerConfigInterface } from 'src/app/app-shared-elements/_interfaces/date-picker-config.interface';
import { ChartErrorTypeInterface } from '../../_interfaces/chart-error-type.interface';
import { DeleteAxisDto } from '../../_interfaces/DeleteAxisDto';

export class ChangeChartMode {
    static readonly type = '[Chart] ChangeChartMode';
    constructor(public mode: ChartModeEnum) {}
}

export class SetIsDataLimited {
    static readonly type = '[Chart] SetIsDataLimited';
    constructor(public value: boolean) {}
}
export class SetIsEmptyArchive {
    static readonly type = '[Chart] SetIsEmptyArchive';
    constructor(public data: ChartErrorTypeInterface) {}
}

export class GetAxis {
    static readonly type = '[Chart] GetAxis';
    constructor(public registratorId: string) {}
}

export class CreateNewAxis {
    static readonly type = '[Chart] CreateNewAxis';
}

export class SetArchivePreloaderData {
    static readonly type = '[Chart] SetArchivePreloaderData';
    constructor(public archivePreloaderData: ArchivePreloaderData) {}
}

export class UpdateAxis {
    static readonly type = '[Chart] UpdateAxis';
    constructor(public axis: Axis) {}
}

export class DeleteAxis {
    static readonly type = '[Chart] DeleteAxis';
    constructor(public data: DeleteAxisDto) {}
}

export class InitScaleButtons {
    static readonly type = '[Chart] InitScaleButtons';
    constructor(public detailChart: any) {}
}

export class ClearScaleButtons {
    static readonly type = '[Chart] ClearScaleButtons';
}

export class AddVariableToChart {
    static readonly type = '[Chart] AddVariableToChart';
    constructor(public variableId: string) {}
}

export class RemoveVariableToChart {
    static readonly type = '[Chart] RemoveVariableToChart';
    constructor(public variableId: string) {}
}

export class ShawAllVariableToChart {
    static readonly type = '[Chart] ShawAllVariableToChart';
}

export class ClearChartState {
    static readonly type = '[Chart] ClearChartState';
}

export class ReReadArchive {
    static readonly type = '[Chart] ReReadArchive';
}

export class SetDatepickerConfigForReread {
    static readonly type = '[Chart] SetDatepickerConfigForReread';
    constructor(public config: DatePickerConfigInterface) {}
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { ConnectEnum } from '../../app-shared-elements/_enums/connect.enum';
import { ConfigurationServerInterface } from '../../app-shared-elements/_interfaces/configuration-server.interface';
import { SocketService } from '../../app-shared-elements/_services/socket.service';
import { DataTypeService } from 'src/app/app-shared-elements/_services/data-type.service';
import { UserState } from 'src/app/app-shared-elements/_store/states/user.state';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { Select } from '@ngxs/store';
import { ConfigurationState } from 'src/app/app-shared-elements/_store/states/configuration.state';
import { ActiveEventsState } from 'src/app/app-shared-elements/_store/states/active-events.state';
import { TechnologicEvent } from 'src/app/events/_interfaces/TechnologicEvent';
import { EventTypeEnum } from 'src/app/app-shared-elements/_enums/event-type.enum';
import { DevicesState } from '../../device-dashboard/_store/states/user-devices.state';
import { Device } from '../../app-shared-elements/_interfaces/Device';
import { LangEnum } from 'src/app/app-shared-elements/_enums/lang.enum';
import { LanguageState } from 'src/app/app-shared-elements/_store/states/language.state';
import { DeviceDynamicUpdateService } from '../../app-shared-elements/_services/dynamic/device-dynamic-update.service';
import { takeUntil } from 'rxjs/operators';
import { AlarmTypeEnum } from '../../events/_enums/alarm.enum';
import { Clipboard } from '@angular/cdk/clipboard';
import { AuthState } from '../../auth/_store/states/auth.state';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
    registrators: Device[];
    devices: Device[];
    tryConnection = false;
    errorConnectSubscribe: Subscription;
    token = localStorage.getItem('access_token');

    ATTENTION_ICON_PATH = './assets/design/icons/footer/attention-icon.svg';
    ALARM_ICON_PATH = './assets/design/icons/footer/alarm-icon.svg';
    STATUS_ARCH_PATH = './assets/design/icons/footer/status-arch.svg';
    DEVICES_TOOLTIP_PATH = './assets/design/icons/footer/devices-tooltip.svg';

    @Select(UserState.getUser) user$: Observable<User>;
    @Select(UserState.getUserTimezoneGMT) gmt$: Observable<string>;
    @Select(ConfigurationState.getConfigurationServer) configurationServer$: Observable<ConfigurationServerInterface>;
    @Select(ActiveEventsState.getLastActiveEvents) lastActiveEvent$: Observable<TechnologicEvent>;
    @Select(LanguageState.getLanguage) ln$: Observable<LangEnum>;
    @Select(DevicesState.getRegistrators) registrators$: Observable<Device[]>;
    @Select(AuthState.getIsAdmin) isAdmin$: Observable<boolean>;
    @Select(DevicesState.getDevices) devices$: Observable<Device[]>;

    eventTypeEnum = EventTypeEnum;
    alarmTypeEnum = AlarmTypeEnum;

    ping = '-';
    performance = null;

    dateNow: Date;

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private socketService: SocketService,
        public dataTypeService: DataTypeService,
        private deviceDynamicUpdateService: DeviceDynamicUpdateService,
        private clipBoard: Clipboard,
    ) {
        setInterval(() => {
            this.dateNow = new Date();
        }, 1);

        this.devices$.pipe(takeUntil(this.destroy)).subscribe((devices) => {
            if (!devices) {
                return;
            }

            this.devices = devices.filter((device) => device.isArchiveLoading);
        });

        this.errorConnectSubscribe = this.socketService.errorConnectSubscribe.subscribe((status: ConnectEnum) => {
            switch (status) {
                case ConnectEnum.DISCONNECT:
                    this.tryConnection = true;
                    break;

                case ConnectEnum.RECONNECTED:
                    this.tryConnection = false;
                    break;
            }
        });

        this.socketService.ping$.pipe(takeUntil(this.destroy)).subscribe((ping) => {
            this.ping = ping ? `${ping}ms` : '-';
        });

        this.deviceDynamicUpdateService.performance$.subscribe((performance) => {
            if (!performance) {
                this.performance = '-';
                return;
            }
            this.performance = `CPU ${+((performance.time / 5000) * 100).toFixed(0)}% ${performance.count} operation in ${
                performance.time
            }ms`;
        });
    }

    async ngOnInit(): Promise<void> {}

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }

    copyTokenToBuffer(): void {
        this.clipBoard.copy(this.token);
        alert('Token copy to buffer: ' + this.token);
    }
}

import {Params} from 'src/app/app-shared-elements/_interfaces/params.interface';

export class GetUserLogs {
    static readonly type = '[UserLogs] GetUserLogs';
}

export class GetUserLogsRows {
    static readonly type = '[UserLogs] GetUserLogsRows';

}

export class SetUserLogsFilter {
    static readonly type = '[UserLogs] SetUserLogsFilter';

    constructor(public params: Params) {
    }
}

export class ResetUserLogsState {
    static readonly type = '[UserLogs] ResetUserLogsState';
}

export class SetUserIdForLogsFromAdmin {
    static readonly type = '[UserLogs] SetUserIdForLogsFromAdmin';

    constructor(public userId: string) {

    }
}

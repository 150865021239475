import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken, Store} from '@ngxs/store';
import {Params, ParamsFilterForClient} from 'src/app/app-shared-elements/_interfaces/params.interface';
import {compareFilters} from 'src/app/app-shared-elements/_services/table.service';
import {initialFilterCheckboxesData} from '../../_data/user-device-params';
import {SetDevicesParams} from '../actions/user-device-params.actions';

export interface UserDeviceParamsModel {
    params: Params;

}

const DEVICE_STATE_PARAMS_TOKEN = new StateToken<UserDeviceParamsModel>('devicesParams');



const initialFilterCheckboxes: ParamsFilterForClient[] =
    JSON.parse(localStorage.getItem('paramsFilterDevices')) && JSON.parse(localStorage.getItem('paramsFilterDevices')).length && compareFilters(initialFilterCheckboxesData.filter(f => !f.isDropdown), JSON.parse(localStorage.getItem('paramsFilterDevices')))
        ? initialFilterCheckboxesData.map(f => {
            const savedParam = JSON.parse(localStorage.getItem('paramsFilterDevices')).find(p => p.title === f.title);
            if (savedParam) {
                return savedParam;
            }
            return f;
        })
        : initialFilterCheckboxesData;

const initialParams: Params = {
    pagination: null,
    sorted: null,
    filter: initialFilterCheckboxes,
    // timeFilter: null
};

@State<UserDeviceParamsModel>({
    name: DEVICE_STATE_PARAMS_TOKEN,
    defaults: {
        params: initialParams,
    }
})
@Injectable()
export class UserDeviceParamsState {
    constructor(
        private store: Store) {
    }

    @Selector()
    static getParams(state: UserDeviceParamsModel): Params {
        return state.params;
    }


    @Action(SetDevicesParams)
    setDevicesParams(ctx: StateContext<UserDeviceParamsModel>, payload: SetDevicesParams): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            params: {...payload.params}
        });
    }
}


import { ReportService } from '../_services/report.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartService } from '../../groups/container/chart/_services/chart.service';
import { ChartSettingsService } from '../../groups/container/chart/_services/chart-settings.service';
import * as Highcharts from 'highcharts';
import { FromToTypeCalendar } from '../../app-shared-elements/filters/enums/type-filter.enum';
import { ReportRepeatEnum, ReportSettingsInterface } from '../_interfaces/ReportSettings';
import { FormatPrintEnum } from '../_enums/format-print.type.enum';
import { ArchiveChartInterface } from '../../app-shared-elements/_interfaces/ArchiveChart';
import { TranslateService } from '@ngx-translate/core';
import { VariableWithArchive } from 'src/app/app-shared-elements/_interfaces/VariableWithArchive';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { UserProfileInfoTypeDateEnum } from 'src/app/profile/_enums/user-profile-info-date-time.enum';
import { Store } from '@ngxs/store';
import { SetCurrentReport } from '../_store/actions/reports.actions';
import { VariableGroupSettings } from 'src/app/app-shared-elements/_interfaces/VariableGroupSettings';
import { DataSettingsForGenerateReport, EventsLogDataForGenerateReport, SummaryTableDataForGenerateReport, TableDataForGenerateReport } from '../_interfaces/data-report.interface';
import { ChartEventsEnum } from 'src/app/groups/_enums/chart-events.enum';
import { ParamsTime } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { LangEnum } from 'src/app/app-shared-elements/_enums/lang.enum';
import { ChangeLanguage } from 'src/app/app-shared-elements/_store/actions/language.actions';
import { ArchiveService } from '../../app-shared-elements/_services/archive.service';

@Component({
    selector: 'app-report-generation-server',
    templateUrl: './report-generation-server.component.html',
    styleUrls: ['./report-generation-server.component.scss'],
})
export class ReportGenerationServerComponent implements AfterViewInit, OnInit {
    groupId: number;
    reportId: number;
    userId: number;

    isDataLimited;
    token: string;
    variables: any[];

    dateObject: ParamsTime = {
        from: null,
        to: null,
        time: null,
        type: null,
    };

    reportSettings: ReportSettingsInterface = {
        nameReport: '',
        nameCompany: '',
        logo: '',
        logoName: '',
        logoSize: null,
        deviceId: null,
        isSendTelegram: false,
        isSendEmail: false,
        telegram: '',
        email: '',
        date: {
            dateFrom: '',
            dateTo: '',
            schedule: null,
        },
        format: FormatPrintEnum.A4,
        isShowNameReport: false,
        isShowNameCompany: false,
        isShowChart: false,
        isShowTable: false,
        isShowUserLogs: false,
        isUpdateLogo: false,
        repeat: ReportRepeatEnum.none,
        isActive: true,
        language: LangEnum.en,
        timezone: null,
        dateFormat: UserProfileInfoTypeDateEnum.ddMMyyyy,
        // reportVariables: [],
        variablesId: [],
        // chartMode: ChartModeEnum.chart
        isDefaultReport: false,
    };

    fromToTypeCalendar = FromToTypeCalendar;
    pdfWidth;
    currentGroupName: string;
    dateObjForPrint: any = {};
    currentTableGroupColumns: any[] = [];
    isStartGenerate: boolean;
    goPrint: boolean;
    report: any;

    isEmptyArchive: boolean;
    // chart: ArchiveChartInterface;
    tableGroup: TableDataForGenerateReport;
    summaryTable: SummaryTableDataForGenerateReport;
    eventLogTable: EventsLogDataForGenerateReport;

    isOnlyDsChart = false;

    settings: DataSettingsForGenerateReport;

    constructor(
        private activateRoute: ActivatedRoute,
        private chartService: ChartService,
        private reportService: ReportService,
        private chartSettingsService: ChartSettingsService,
        private translateService: TranslateService,
        private store: Store,
        private archiveService: ArchiveService,
    ) {
        const params: any = this.activateRoute.snapshot.queryParams;
        this.token = params.token;
        const data = JSON.parse(atob(this.token.split('.')[1]));
        this.groupId = data.groupId;
        this.reportId = data.reportId;
        this.userId = data.userId;
        this.dateObjForPrint.start = data.start;
        this.dateObjForPrint.end = data.end;
    }

    async ngAfterViewInit(): Promise<void> {
        // this.store.dispatch(new SetCurrentReport(this.reportSettings));
        //
        // setTimeout(async () => {
        //     await this.initData();
        // }, 400);
    }

    async ngOnInit(): Promise<void> {
        this.store.dispatch(new SetCurrentReport(this.reportSettings));

        await this.initData();
    }

    //
    // async ngOn(): Promise<void> {
    //
    // }

    async initData(): Promise<void> {
        let totaleResponses: number;
        let currentResponse = 0;
        this.translateService.use(this.reportSettings.language);

        const settings: DataSettingsForGenerateReport = await this.reportService.getDataSettings(this.token);

        this.settings = settings;

        if (!settings || !settings.report) {
            return;
        }

        this.store.dispatch(new ChangeLanguage(settings.report.language));
        await this.translateService.use(settings.report.language).toPromise();

        this.reportSettings = settings.report;

        this.store.dispatch(new SetCurrentReport(this.reportSettings));

        this.isStartGenerate = true;

        const monthKeys = [
            'charts.month.jan',
            'charts.month.feb',
            'charts.month.mar',
            'charts.month.apr',
            'charts.month.may',
            'charts.month.jun',
            'charts.month.jul',
            'charts.month.aug',
            'charts.month.sep',
            'charts.month.oct',
            'charts.month.nov',
            'charts.month.dec',
        ];

        const months = await this.chartService.getTranslateMonth(monthKeys);

        if (months && months.length && !months.find((m) => !m)) {
            Highcharts.setOptions({
                lang: {
                    shortMonths: months,
                },
            });
        }

        const variablesSettings: {
            variable: Variable;
            settings: VariableGroupSettings;
        }[] = await this.reportService.getVariablesSettings(this.token);
        let variables: Variable[] = variablesSettings.map((vS) => vS.variable);
        const varialeGroupSettings: VariableGroupSettings[] = variablesSettings.map((vS) => vS.settings);

        // const variableSettings: VariableGroupSettings[] = settings.group.variableGroupSettings.filter(setting => setting.showOnChart);

        // const variables: Variable[] = settings.device.variables.filter(v => variableSettings.find(setting => setting.variableId === v.id));

        totaleResponses = variablesSettings.length + Number(this.reportSettings.isShowTable) + Number(this.reportSettings.isShowEventLogs) + Number(this.reportSettings.isShowSummaryTable);
        console.log(`process-query:${currentResponse}/${totaleResponses}`);

        if (this.reportSettings && this.reportSettings.isShowTable) {
            currentResponse++;
            console.log(`process-query:${currentResponse}/${totaleResponses}`);
            this.tableGroup = await this.reportService.getDataGroupTable(this.token);

            if (this.reportSettings.isSplitTable) {
                const resultObject = {};

                variables.forEach((item) => {
                    const deviceId = item.deviceId;

                    if (!resultObject[deviceId]) {
                        resultObject[deviceId] = [];
                    }

                    resultObject[deviceId].push(item);
                });

                const resultArray: any[] = Object.values(resultObject);

                resultArray.map(async (item: Variable[], index) => {
                    const currentDevice = settings.devices.find((f) => item.find((v) => v.deviceId === f.id));
                    const tableGroupsArray: TableDataForGenerateReport = {
                        ...this.tableGroup,
                        head: this.tableGroup.head.filter((f) => item.find((v) => v.id === f.id)),
                        body: this.tableGroup.body.filter((f) => f.archiveValues.find((v) => item.find((a) => a.id === v.variableId))).filter((f) => !!f),
                    };

                    if (currentDevice) {
                        document.getElementsByClassName(`report-print__name${index}`)[0].innerHTML = await this.translateService
                            .get('reports.splitTableTitle', { name: currentDevice.name ?? currentDevice.defaultName })
                            .toPromise();
                    }

                    document.getElementsByClassName(`container-table-report${index}`)[0].innerHTML = await this.reportService.initTableGroup(tableGroupsArray, this.reportSettings.dateFormat, this.reportSettings.timezone, item);

                    if (!tableGroupsArray.body.length) {
                        document.getElementsByClassName(`container-table-report${index}`)[0].classList.add('report-print__empty');
                        document.getElementsByClassName(`container-table-report${index}`)[0].innerHTML = await this.translateService.get('reports.empty.table').toPromise();
                    }
                });
            } else {
                document.getElementsByClassName('container-table-report')[0].innerHTML = await this.reportService.initTableGroup(this.tableGroup, this.reportSettings.dateFormat, this.reportSettings.timezone, variables);
            }

            console.log('container-table-report', document.getElementsByClassName('container-table-report'));
        }

        if (this.reportSettings && this.reportSettings.isShowEventLogs) {
            currentResponse++;
            console.log(`process-query:${currentResponse}/${totaleResponses}`);
            this.eventLogTable = await this.reportService.getDataEventLog(this.token);
            await this.reportService.initEventTable(this.eventLogTable, this.reportSettings.dateFormat, this.reportSettings.timezone);
        }

        if (this.reportSettings && this.reportSettings.isShowSummaryTable) {
            currentResponse++;
            console.log(`process-query:${currentResponse}/${totaleResponses}`);
            this.summaryTable = await this.reportService.getDataSummaryTable(this.token);
            await this.reportService.initSummaryTable(this.summaryTable, variables);
        }

        this.reportSettings.isShowUserLogs = false;
        this.currentGroupName = settings.device.name ?? settings.device.defaultName;

        const axis = (await this.reportService.getDataAxis(this.token)) || [];
        const uniqAxis = [];
        axis.forEach((a) => {
            if (uniqAxis.find((x) => x.id === a.id)) {
                return;
            }

            uniqAxis.push(a);
        });

        if (this.reportSettings.isShowChart && variables && variables.length) {
            this.chartSettingsService.graphEvents.subscribe((data: { type: ChartEventsEnum; event?: any }) => {
                if (data.type !== ChartEventsEnum.detailChartRender) {
                    setTimeout(() => {
                        console.log(this.token);
                    }, 2000);
                    return;
                }
                this.chartSettingsService.setChartWidth(null);
                setTimeout(() => {
                    console.log(this.token);
                }, 2000);
            });
        } else {
            setTimeout(() => {
                console.log(this.token);
            }, 2000);

            return;
        }

        let variablesWithArchive: VariableWithArchive[] = [];
        let index = 0;
        const dsVariables = variables.filter((f) => f.name === 'Contact1' || f.name === 'Contact2' || f.name === 'Contact3' || f.name === 'Contact4');

        let dsVariablesWithArchive: any[] = [];

        if (dsVariables && dsVariables.length) {
            dsVariablesWithArchive = await this.getBinaryVariables(dsVariables);

            dsVariablesWithArchive = dsVariablesWithArchive.filter((f) => !!f);
        }

        this.isOnlyDsChart = dsVariables.length === variables.length;

        // variables = variables.filter((f) => f.name !== 'Contact1' && f.name !== 'Contact2' && f.name !== 'Contact3' && f.name !== 'Contact4');

        for (const variable of variables) {
            currentResponse++;
            console.log(`process-query:${currentResponse}/${totaleResponses}`);
            const chart: ArchiveChartInterface = await this.reportService.getDataChart(this.token, variable.id);
            // this.chart = chart;
            const varibleArchive: VariableWithArchive = await this.chartService.getVariableWithArchive(varialeGroupSettings, variable, chart.archive, this.dateObjForPrint.start, this.dateObjForPrint.end, null);

            variablesWithArchive.push(varibleArchive);

            if (this.reportSettings.isLimits) {
                const limitWMA = await this.chartService.getLimits(chart, variable, variablesWithArchive, varialeGroupSettings, variables);

                variablesWithArchive = [...variablesWithArchive, ...limitWMA];
            }
            console.log(variablesWithArchive);
            index++;
        }

        const registratorId = settings.device.registratorId ? settings.device.registratorId : settings.device.id;
        this.chartSettingsService.setReportForChart(settings.report);
        const chartSettings = await this.chartSettingsService.getGraphSettings(variablesWithArchive, registratorId, uniqAxis, dsVariablesWithArchive, dsVariables, null, this.reportSettings.timezone);

        if (this.isEmptyChart(variablesWithArchive)) {
            console.log('isEmptyArchive = true');
            this.isEmptyArchive = true;
            console.log(this.token);
            return;
        }

        setTimeout(() => {
            this.chartService.detail = Highcharts.chart('detail', chartSettings.detail);
            if (this.isOnlyDsChart) {
                this.chartService.detail = Highcharts.chart('master', chartSettings.master);
            }
        }, 1000);
    }

    private isEmptyChart(variableWithArchive: VariableWithArchive[]): boolean {
        if (!variableWithArchive || !variableWithArchive.length) {
            return true;
        }

        if (this.isOnlyDsChart) {
            return false;
        }
        return !!!variableWithArchive.find((v) => v.data?.length);
    }

    public async getBinaryVariables(variables: Variable[]): Promise<any> {
        const result = [];
        for (const variable of variables) {
            const data = await this.archiveService.getChartBinary({
                variables: [variable.id],
                start: this.dateObjForPrint.start,
                end: this.dateObjForPrint.end,
            });

            result.push(data);
        }

        return result;
    }
}

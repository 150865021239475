import {IconInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';

export interface ReportRowInterface {
    isActive: boolean;
    name: string;
    device: string;
    deviceId: string;
    period: string;
    date: number;
    id: string;
    owner: string;
    created: number;
    preIcons: IconInterface<ReportRowIconActionEnum>[];
    postText: {
        name: string
    };
    postTextStyles: {
        name: any
    };
    tooltipValue: {
        owner: string;
    };
}


export enum ReportRowIconActionEnum {
    copyBuffer = 'copyBuffer'
}

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ColumnsActionTypeEnum} from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import {MethodPermission} from 'src/app/app-shared-elements/_enums/permission.enum';
import {
    AlignItemsEnum,
    ColumnsTableInterface,
    ColumnTypeEnum
} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import {Tabs} from 'src/app/app-shared-elements/_interfaces/Tabs';
import {PermissionInterface, RoleInterface} from '../../../app-shared-elements/_interfaces/role.interface';
import {TreeRoleItemInterface} from '../_interfaces/tree-role-item.interface';

@Injectable({
    providedIn: 'root'
})
export class RolesService {

    static readonly rootRole = 'Root';
    static readonly defaultRole = 'Default';

    initialTabsUserRoles: Tabs[] = [
        {
            title: 'admin.roles.tabs.settings',
            active: false,
            id: 0,
            path: '/control/roles'
        },
        {
            title: 'admin.roles.tabs.permission',
            active: false,
            id: 1,
            path: '/control/roles/constructor'
        }
    ];

    initialTabsAdminRoles: Tabs[] = [
        {
            title: 'admin.roles.tabs.settings',
            active: false,
            id: 0,
            path: '/control/admins/roles'
        },
        {
            title: 'admin.roles.tabs.permission',
            active: false,
            id: 1,
            path: '/control/admins/roles/constructor'
        }
    ];

    tableColumns: ColumnsTableInterface[] = [
        // {
        //     title: 'admin.roles.table.act',
        //     small: true,
        //     type: ColumnTypeEnum.icon,
        //     name: 'isActive',
        //     alignItems: AlignItemsEnum.top
        // },
        {
            title: 'admin.roles.table.role',
            maxWidth: '220px',
            type: ColumnTypeEnum.text,
            name: 'role',
            alignItems: AlignItemsEnum.top
        },
        {
            title: 'admin.roles.table.info',
            grow: true,
            type: ColumnTypeEnum.text,
            name: 'description',
            allowHtmlTags: true
        },
        {
            title: 'devices.actions',
            grow: false,
            small: true,
            maxWidth: '85px',
            minWidth: '85px',
            type: ColumnTypeEnum.action,
            name: 'edit',
            actionBtns: [ColumnsActionTypeEnum.actionBtnsEdit, ColumnsActionTypeEnum.actionBtnsDelete]
        }
    ];

    constructor(
        private http: HttpClient,
        private translateService: TranslateService,
    ) {
    }

    initTree(originTree, currentRole: RoleInterface): TreeRoleItemInterface[] {
        if (!currentRole) {
            return;
        }
        const treeView: TreeRoleItemInterface[] = [];
        if (!currentRole.permissions) {
            return [];
        }
        currentRole.permissions.forEach(permission => {
            const result = {
                name: `permissions.${permission.resource}`,
                active: true,
                isActive: true,
                isExpanded: true,
                main: true,
                isCheck: this.getIsCheck(permission),
                resource: permission.resource,
                children: [
                    {
                        name: `permissions.${permission.resource}`,
                        active: true,
                        isActive: true,
                        isExpanded: true,
                        main: false,
                        isEdit: permission.isUpdate,
                        isView: permission.isRead,
                        resource: permission.resource,
                        children: permission.childrenPermissions ? this.initChildBranch(permission, originTree) : [],
                        parentPermissionId: permission.parentPermissionId,
                        isShowIsEdit: originTree[permission.resource].permissions.includes(MethodPermission.UPDATE),
                        tooltip: `permissions.tooltip.${permission.resource}`,
                    }
                ]
            };


            // if (this.treeFromServer[permission.resource].permissions.includes(MethodPermission.UPDATE)) {
            //     result.children = [{...result.children[0], isEdit: permission.isUpdate}]
            // }

            treeView.push(result);
        });
        return treeView;
    }

    getIsCheck(permissionMain: PermissionInterface): boolean {
        let result = true;

        if (!permissionMain.isRead || !permissionMain.isUpdate) {
            return false;
        }

        if ((!permissionMain.childrenPermissions || !permissionMain.childrenPermissions.length) && permissionMain.isRead && permissionMain.isUpdate) {
            return true;
        }

        permissionMain.childrenPermissions.forEach(permission => {
            result = this.getIsCheck(permission);
        });

        return result;
    }


    initChildBranch(permission: PermissionInterface, originTree): TreeRoleItemInterface[] {
        // if (permission.resource === 'logic_message') {
        //     console.log(permission);
        // }
        return permission.childrenPermissions.map(childrenPermission => {
            const result: TreeRoleItemInterface = {
                name: `permissions.${childrenPermission.resource}`,
                active: true,
                isActive: true,
                isExpanded: true,
                main: false,
                isDisabled: permission.isRead ? false : true,
                isEdit: childrenPermission.isUpdate,
                isView: childrenPermission.isRead,
                resource: childrenPermission.resource,
                children: childrenPermission.childrenPermissions ? this.initChildBranch(childrenPermission, originTree) : [],
                parentPermissionId: childrenPermission.parentPermissionId,
                isShowIsEdit: originTree[childrenPermission.resource].permissions.includes(MethodPermission.UPDATE),
                tooltip: `permissions.tooltip.${childrenPermission.resource}`,
            };

            return result;
        });

    }

    changeCurrentRole(currentRole: RoleInterface, currentTree: TreeRoleItemInterface[]): RoleInterface {
        currentRole = {
            ...currentRole,
            description: 'description',
            permissions: currentRole.permissions.map(permission => {
                const currentPermission = currentTree.find(branch => branch.resource === permission.resource);

                if (!currentPermission) {
                    return;
                }

                permission.isRead = currentPermission.children[0].isView;
                permission.isUpdate = !!currentPermission.children[0].isEdit;
                permission.description = `${permission.resource} description`;
                permission.parentPermissionId = currentPermission.children[0].parentPermissionId;

                if (permission.childrenPermissions.length) {
                    permission.childrenPermissions = permission.childrenPermissions.map(childPermission => {
                        const currentChildrenPermission = currentPermission.children[0].children.find(branch => branch.resource === childPermission.resource);

                        if (!currentChildrenPermission) {
                            return;
                        }

                        childPermission.description = 'childPermission';
                        childPermission.isRead = currentChildrenPermission.isView;
                        childPermission.isUpdate = !!currentChildrenPermission.isEdit;
                        childPermission.parentPermissionId = currentChildrenPermission.parentPermissionId;

                        return childPermission;
                    });
                }

                return permission;
            })
        };


        return currentRole;
    }

    setCurrentValueInsideNode(childBranches: TreeRoleItemInterface[], value: boolean, floor = 0): TreeRoleItemInterface[] {
        return childBranches.map(branch => {
            branch.isEdit = value;
            branch.isView = value;

            branch.isDisabled = !value && floor > 0 ? true : false;

            if (branch.children && branch.children.length) {
                branch.children = this.setCurrentValueInsideNode(branch.children, value, ++floor);
            }

            return branch;
        });
    }

    // async updateRole(currentRole: RoleInterface): Promise<ApiResponse> {
    //     return await this.http.put('api/control/device-role', {...currentRole}).toPromise().catch(e => console.log(e)) as ApiResponse;
    // }

    async getDescriptionForCurrentRole(permissions: PermissionInterface[]): Promise<string> {
        if (!permissions || !permissions.length) {
            return '';
        }
        let result = '';
        await Promise.all(
            permissions.map(async permission => {
                if (permission.isRead || permission.isUpdate) {
                    const resourse = await this.translateService.get(`permissions.${permission.resource}`).toPromise();
                    result += `<span class="${permission.isUpdate ? 'edit' : ''}">${resourse}</span> | `;
                }

                if (permission.childrenPermissions && permission.childrenPermissions.length) {
                    const childResource = await this.getDescriptionForCurrentRole(permission.childrenPermissions);
                    result += childResource;
                }
            })
        );


        return result;
    }

    getCurrentCheckBoxAll(tree: TreeRoleItemInterface[]): TreeRoleItemInterface[] {
        return tree.map(branch => {

            branch.isCheck = this.findInvesionValueInChildBranch(branch.children, branch.isCheck);

            return branch;
        });
    }

    findInvesionValueInChildBranch(children: TreeRoleItemInterface[], currentMainValue: boolean): boolean {
        let result = currentMainValue;
        children.forEach(item => {

            if (!item.isEdit) {
                item.children = this.setChildIsEditFalse(item.children);
            }

            if (!item.isView) {
                item.children = this.setChildIsViewFalse(item.children);
            }

            item.children = this.setChildIsDisabledFalse(item.children, !item.isView || item.isDisabled ? true : false);

            if (result && (item.isEdit !== currentMainValue || item.isView !== currentMainValue)) {
                result = !currentMainValue;
                return;
            }

            if (item.children && item.children.length) {
                result = this.findInvesionValueInChildBranch(item.children, result ? currentMainValue : !currentMainValue);
            }
        });

        return result;
    }

    setChildIsEditFalse(branchChildren: TreeRoleItemInterface[]): TreeRoleItemInterface[] {
        return branchChildren.map(item => {
            if (item.children && item.children.length) {
                item.children = this.setChildIsEditFalse(item.children);
            }

            item.isEdit = false;

            return item;
        });
    }

    setChildIsViewFalse(branchChildren: TreeRoleItemInterface[]): TreeRoleItemInterface[] {
        return branchChildren.map(item => {
            if (item.children && item.children.length) {
                item.children = this.setChildIsViewFalse(item.children);
            }

            item.isView = false;
            item.isEdit = false;

            return item;
        });
    }

    setChildIsDisabledFalse(branchChildren: TreeRoleItemInterface[], value: boolean): TreeRoleItemInterface[] {
        return branchChildren.map(item => {
            if (item.children && item.children.length) {
                item.children = this.setChildIsDisabledFalse(item.children, value);
            }

            item.isDisabled = value;

            return item;
        });
    }
}

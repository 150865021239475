export class GetCountry {
    static readonly type = '[CountryStateCity] GetCountry';
}

export class GetStates {
    static readonly type = '[CountryStateCity] GetStates';

    constructor(public countryCode: string) {
    }
}

export class GetCity {
    static readonly type = '[CountryStateCity] GetCity';

    constructor(public countryCode: string, public stateCode: string) {
    }
}

export class SetCurrentCountry {
    static readonly type = '[CountryStateCity] SetCurrentCountry';

    constructor(public isoCode: string) {
    }
}

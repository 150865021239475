import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Tabs } from '../../_interfaces/Tabs';
import { SetCurrentQueryParams, SetCurrentTab, SetCurrentTabByPath, SetTabs } from '../actions/tabs.actions';

export interface TabsStateModel {
    tabs: Tabs[];
    currentQueryParams: any;
    // currentTab: Tabs;
}

const TABS_TOKEN = new StateToken<TabsStateModel>('tabsState');

@State({
    name: TABS_TOKEN,
    defaults: {
        tabs: [],
        currentQueryParams: null,
        // currentTab: null
    },
})
@Injectable()
export class TabsState {
    constructor(private router: Router) {}

    @Selector()
    static getTabs(state: TabsStateModel): Tabs[] {
        return state.tabs;
    }

    @Selector()
    static getCurrentQueryParams(state: TabsStateModel): any {
        return state.currentQueryParams;
    }

    // @Selector()
    // static getCurrentTab(state: TabsStateModel) {
    //     return state.currentTab;
    // }

    @Action(SetTabs)
    setTabs(ctx: StateContext<TabsStateModel>, payload: SetTabs): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            tabs: payload.tabs,
        });
    }

    @Action(SetCurrentTab)
    setCurrentTab(ctx: StateContext<TabsStateModel>, payload: SetCurrentTab): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            tabs: state.tabs.map((tab) => {
                tab.id === payload.tabId ? (tab.active = true) : (tab.active = false);
                return tab;
            }),
        });
    }

    @Action(SetCurrentTabByPath)
    setCurrentTabByPath(ctx: StateContext<TabsStateModel>, payload: SetCurrentTabByPath): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            tabs: [
                ...state.tabs.map((tab) => {
                    tab.path === payload.path ? (tab.active = true) : (tab.active = false);
                    return tab;
                }),
            ],
        });
    }

    @Action(SetCurrentQueryParams)
    setCurrentQueryParams(ctx: StateContext<TabsStateModel>, payload: SetCurrentQueryParams): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentQueryParams: payload.currentQueryParams,
        });
    }
}

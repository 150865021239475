<div class="expired-password">
    <div class="expired-password__wrapper">
        <div class="expired-password__header">
            <div class="expired-password__title" [translate]="'profile.changePasswordTitle'"></div>
            <div (click)="close()" class="expired-password__close">
                <svg-icon [src]="CLOSE_ICON_PATH"></svg-icon>
            </div>
        </div>

        <div class="expired-password__body">
            <ng-container *ngIf="!changePopup;else change">
                <div *ngIf="(days$ | async) !== 0" class="expired-password__message" [translate]="'expiredPopup.message'"
                     [translateParams]="{days: days$ | async}"></div>
                <div *ngIf="(days$ | async) === 0" class="expired-password__message" [translate]="'expiredPopup.todayMessage'"></div>

                <div class="expired-password__btns">
                    <button (click)="close()" class="btn btn--light" [translate]="'btns.cancel'"></button>
                    <button (click)="moveToChange()" class="btn" [translate]="'btns.changePassword'"></button>
                </div>
            </ng-container>

            <ng-template #change>
                <form class="expired-password__change" [formGroup]="formPassword" novalidate>
                    <div class="expired-password__item">
                    <label class="expired-password__label" [translate]="'auth.registration.password'"></label>
                        <input [type]="isVisiblePassword ? 'text' : 'password'"
                               [(ngModel)]="password"
                               class="input expired-password__input"
                               placeholder="{{'auth.registration.passwordPlaceholder' | translate}}" type="text"
                               formControlName="password"
                        >
                        <div class="expired-password__icon-eyes" (click)="isVisiblePassword = !isVisiblePassword"
                             [class.expired-password__icon-eyes--open-eyes]="isVisiblePassword">
                             <svg-icon *ngIf="!isVisiblePassword; else eyes" [src]="'./assets/design/icons/eyes.svg'"></svg-icon>
                             <ng-template #eyes>
                                 <svg-icon [src]="'./assets/design/icons/eyes-close.svg'"></svg-icon>
                             </ng-template>
                        </div>
                        <span class="expired-password__error expired-password__error--default" *ngIf="!password.length" [translate]="'auth.registration.passwordNote'"></span>

                    </div>

                    <div class="expired-password__item">
                    <label class="expired-password__label" [translate]="'auth.registration.repeat'"></label>
                    <input class="input expired-password__input" placeholder="{{'auth.registration.repeatPlaceholder' | translate}}"
                           type="text" [type]="isVisiblePassword ? 'text' : 'password'"
                           [(ngModel)]="repeatPassword" formControlName="repeat">
                        <div class="expired-password__icon-eyes" (click)="isVisiblePassword = !isVisiblePassword">
                             <svg-icon *ngIf="!isVisiblePassword; else eyes" [src]="'./assets/design/icons/eyes.svg'"></svg-icon>
                             <ng-template #eyes>
                                 <svg-icon [src]="'./assets/design/icons/eyes-close.svg'"></svg-icon>
                             </ng-template>
                        </div>
                        <span class="expired-password__error" *ngIf="formPassword.controls['repeat'].invalid && formPassword.controls['repeat'].touched && password !== repeatPassword && password.length" [translate]="'auth.registration.errorRepeatPassword'"></span>

                    </div>

                </form>

                <div class="expired-password__btns">
                    <button type="button" (click)="close()"
                            class="btn btn--light"
                            [translate]="'btns.close'"></button>
                    <button type="button"
                            [disabled]="password !== repeatPassword || formPassword.invalid"
                            (click)="changePassword();" class="btn btn--red"
                            [translate]="'btns.changePassword'"></button>
                </div>
            </ng-template>
        </div>
    </div>
</div>

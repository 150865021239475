export enum MailingCellFieldTypeEnum {
    input = 'input',
    textarea = 'textarea',
    transport = 'transport',
    select = 'select',
    btns = 'btns',
    text = 'text',
    inputPassword = 'inputPassword',
    inputSelect = 'inputSelect',
    inputAdd = 'inputAdd',
}

export enum MailingCellFieldStateTypeEnum {
    default = 'default',
    edit = 'edit',
}

import {PopupTypeEnum} from './../_enums/popup-type-.enum';
import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-block-disconnect',
    templateUrl: './block-disconnect.component.html',
    styleUrls: ['./block-disconnect.component.scss']
})
export class BlockDisconnectComponent implements OnInit {

    private _title: string;
    private _info: string;


    @Input()
    set title(title: string) {
        this._title = title;
    } // = 'disconnectBlock.title';
    get title(): string {
        return this._title;
    }

    @Input()
    set info(info: string) {
        this._info = info;
    }

    get info(): string {
        return this._info;
    }

    type = PopupTypeEnum;

    DISCONNECT_ICON_PATH = './assets/design/icons/shared/disconnect.svg';

    constructor() {
    }

    ngOnInit(): void {
    }

}

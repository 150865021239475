<div class="nav__popup">

    <div *ngFor="let item of navProfileList"
         class="nav__popup-item"
         [class.nav__popup-item--active]="item.isActive"
         (click)="parseAction(item.action)">

        <div class="nav__popup-icon"
             [class.nav__popup-icon--profile]="item.action === navProfileItemActionEnum.moveProfile"
             [class.nav__popup-icon--billing]="item.action === navProfileItemActionEnum.moveBilling"
        >
            <svg-icon [src]="item.icon"></svg-icon>
        </div>
        <div class="nav__popup-value" [translate]="item.title"></div>

        <div *ngIf="item.action === navProfileItemActionEnum.changeTheme" class="nav__theme-toggle"
             [class.nav__theme-toggle--dark]="activeTheme === themeEnum.dark"
             [class.nav__theme-toggle--light]="activeTheme === themeEnum.light"
        >
            <div class="nav__theme-toggle-point"></div>
        </div>

    </div>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { PopupTypeEnum } from '../_enums/popup-type-.enum';

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupComponent implements OnInit, OnDestroy {
    @Input() title: string;
    @Input() isClose = true;
    @Input() type: PopupTypeEnum;
    @Input() minWidth: number;
    @Output() closeEmit = new EventEmitter<void>();

    scrollTop: number;

    popupTypeEnum = PopupTypeEnum;

    readonly CLOSE_ICON_PATH = './assets/design/icons/close.svg';

    constructor() {
    }

    ngOnDestroy(): void {
        document.body.style.position = 'static';
        document.body.style.overflowY = 'auto';
        if (+this.scrollTop > 0) {
            document.body.scrollTo(0, this.scrollTop);
        }
    }

    ngOnInit(): void {
        this.scrollTop = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.overflowY = 'hidden';
    }

    close(): void {
        this.closeEmit.emit();
    }
}

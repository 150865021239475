import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Breadcrumb} from '../_interfaces/Breadcrumbs';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbsService {

    private breadcrumbData = new BehaviorSubject(null);

    breadcrumbDataObs: Observable<Breadcrumb> = this.breadcrumbData.asObservable();

    constructor() { }

    initNewBreadcrumbsData(data: Breadcrumb): void {

        this.breadcrumbData.next(data);
    }

    changeCurrentBreadcrumbData(data: Breadcrumb): void {
        this.breadcrumbData.next(data);
    }

    addChildren(children): void {

    }
}

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import {
    ChangeUserPassword,
    ChangeUserUpdateValue,
    ConfirmTransportNotification,
    GetCurrentUser,
    ReConfirmTransportNotification,
    SetTypeClient,
    SetUser,
    SetUserIdFromAdmin,
    UpdateAdmin,
    UpdateUser,
} from '../actions/user.actions';
import { ApiResponse } from '../../_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { HTTP_STATUS } from '../../_enums/status.enum';
import { TooltipStatusEnum } from '../../_enums/tooltip-status.enum';
import { NotificationsService } from '../../_services/notifications.service';
import { TypeClient } from '../../../admin/users/_enum/type-client.enum';
import { UpdateUserDtoInterface } from 'src/app/profile/_interfaces/update-user-dto.interface';
import { ChangeUserNotificationsInfo, SetCurrentProfileUser, SetUserProfileInfo } from 'src/app/profile/_store/actions/profile.actions';
import { TranslateService } from '@ngx-translate/core';

export interface UserStateModel {
    user: User;
    currentUser: User;
    isLoadUser: boolean;
    userUpdateValues: UpdateUserDtoInterface;
    adminUserId: string;
    typeClient: TypeClient;
}

const USER_TOKEN = new StateToken<UserStateModel>('user');

@State<UserStateModel>({
    name: USER_TOKEN,
    defaults: {
        user: null,
        currentUser: null,
        isLoadUser: false,
        userUpdateValues: null,
        adminUserId: null,
        typeClient: null,
    },
})
@Injectable()
export class UserState {
    constructor(
        private http: HttpClient,
        private notificationsService: NotificationsService,
        private translateService: TranslateService,
        private store: Store,
    ) {}

    @Selector()
    static getUser(state: UserStateModel): User {
        return state.user;
    }

    @Selector()
    static getCurrentUser(state: UserStateModel): User {
        return state.currentUser;
    }

    @Selector()
    static getUserName(state: UserStateModel): string {
        return state.user.name;
    }

    @Selector()
    static getIsLoadUser(state: UserStateModel): boolean {
        return state.isLoadUser;
    }

    @Selector()
    static getUserTimezoneGMT(state: UserStateModel): string {
        return state.user.dateTimeZone;
    }

    @Selector()
    static getUserUpdateValues(state: UserStateModel): UpdateUserDtoInterface {
        return state.userUpdateValues;
    }

    @Selector()
    static getAdminUserId(state: UserStateModel): string {
        return state.adminUserId;
    }

    @Selector()
    static getTypeClient(state: UserStateModel): TypeClient {
        return state.typeClient;
    }

    @Action(UpdateUser)
    async updateUser(ctx: StateContext<UserStateModel>, payload: UpdateUser): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse<User> = (await this.http
            .patch('/api/users', {
                ...payload.data,
                notification: payload.data.notification?.map((n) => {
                    return {
                        userId: n.userId,
                        transportType: n.transportType,
                        transport: n.transport,
                        isActive: n.isActive,
                        resource: n.resource,
                    };
                }),
            })
            .toPromise()) as ApiResponse<User>;
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                user: result.data,
            });

            ctx.dispatch(new SetCurrentProfileUser(result.data));
            ctx.dispatch(new SetUserProfileInfo(result.data));
            ctx.dispatch(
                new ChangeUserUpdateValue({
                    ...state.userUpdateValues,
                    name: result.data.name,
                    phone: result.data.phone,
                    timeBlocked: result.data.timeBlocked,
                    sessionLife: result.data.sessionLife,
                    dateFormat: result.data.dateFormat,
                    dateTimeZone: result.data.dateTimeZone,
                    isAlarmSound: result.data.isAlarmSound,
                }),
            );

            if (payload.data.notification && payload.data.notification.length) {
                ctx.dispatch(new ChangeUserNotificationsInfo(payload.data.notification));
            }
        }
    }

    @Action(UpdateAdmin)
    async updateAdmin(ctx: StateContext<UserStateModel>, payload: UpdateAdmin): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http.put('/api/control/admins/current', { ...payload.data }).toPromise()) as ApiResponse;
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                user: result.data,
            });

            ctx.dispatch(new SetCurrentProfileUser(result.data));
            ctx.dispatch(new SetUserProfileInfo(result.data));
            ctx.dispatch(
                new ChangeUserUpdateValue({
                    ...state.userUpdateValues,
                    name: result.data.name,
                    phone: result.data.phone,
                    timeBlocked: result.data.timeBlocked,
                    dateFormat: result.data.dateFormat,
                    dateTimeZone: result.data.dateTimeZone,
                    isAlarmSound: result.data.isAlarmSound,
                }),
            );
        }
    }

    @Action(SetUser)
    setUser(ctx: StateContext<UserStateModel>, payload: SetUser): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            user: payload.user,
            isLoadUser: true,
        });
    }

    @Action(ReConfirmTransportNotification)
    async reConfirmTransportNotification(ctx: StateContext<UserStateModel>, payload: ReConfirmTransportNotification): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/users/repeat-confirm-notification-transport', { transportType: payload.transportType })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            const message = await this.translateService
                .get('profile.checkTransport', { transport: payload.transportType })
                .toPromise()
                .catch((e) => console.log(e));
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, message);
        }
    }

    @Action(ConfirmTransportNotification)
    async confirmTransportNotification(ctx: StateContext<UserStateModel>, payload: ConfirmTransportNotification): Promise<void> {
        const result: ApiResponse = (await this.http
            .patch('/api/auth/confirm-transport', { token: payload.token })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
    }

    @Action(ChangeUserPassword)
    async changeUserPassword(ctx: StateContext<UserStateModel>, payload: ChangeUserPassword): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse =
            payload.client === TypeClient.User
                ? ((await this.http
                      .patch('/api/users/update-password', { ...payload.data })
                      .toPromise()
                      .catch((e) => console.log(e))) as ApiResponse)
                : ((await this.http
                      .put('/api/control/admins/update-password', { ...payload.data })
                      .toPromise()
                      .catch((e) => console.log(e))) as ApiResponse);

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, 'tooltipMessages.successPasswordUpdate');
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(ChangeUserUpdateValue)
    changeUserUpdateValue(ctx: StateContext<UserStateModel>, payload: ChangeUserUpdateValue): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            userUpdateValues: payload.data, // JSON.parse(JSON.stringify(payload.data))
        });

        // ctx.dispatch(new ChangeUserNotificationsInfo(payload.data.notification));
    }

    @Action(GetCurrentUser)
    async getCurrentUser(ctx: StateContext<UserStateModel>, payload: GetCurrentUser): Promise<void> {
        const result: ApiResponse = (await this.http
            .get(`/api/control/users/${payload.userId}`)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                currentUser: result.data,
            });
        }
    }

    @Action(SetUserIdFromAdmin)
    getUserIdFromAdmin(ctx: StateContext<UserStateModel>, payload: SetUserIdFromAdmin): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            adminUserId: payload.userId,
        });
    }

    @Action(SetTypeClient)
    setTypeClient(ctx: StateContext<UserStateModel>, payload: SetTypeClient): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            typeClient: payload.typeClient,
        });
    }
}

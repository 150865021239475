import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class ResetPasswordGuard {
    constructor(
        private r: ActivatedRoute,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (state.root.queryParams.token) {
            return true;
        } else {
            this.router.navigate(['login']);
            return false;
        }
    }
}

import {Observable} from 'rxjs';
import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {SocketService} from '../../app-shared-elements/_services/socket.service';
import {Select} from '@ngxs/store';
import {LayoutState} from 'src/app/app-shared-elements/_store/states/layout.state';
import {Router} from "@angular/router";

/**
 * Основной компонент, определяющий подключение частей шаблона,
 * таких как хедер, футер и центральная часть.
 */
@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnDestroy, AfterViewInit {

    @Select(LayoutState.getIsDisplayHeight) displayHeight$: Observable<boolean>;
    @Select(LayoutState.getFullScreen) fullscreen$: Observable<boolean>;
    @Select(LayoutState.getIsChart) isChart$: Observable<boolean>;


    constructor(
        private socketService: SocketService,
        private router: Router
    ) {

        if (this.router.url.includes('control')) {
            const favicon = document.querySelector('link[rel="icon"]');
            favicon.setAttribute('href', 'assets/design/icons/admin-sc.ico');
        }

    }

    logout(): any {
        this.socketService.logoutSubscribe.emit();
    }

    ngOnDestroy(): void {

    }

    ngAfterViewInit(): void {
    }
}


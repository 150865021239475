import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlignItemsEnum, ColumnsTableInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { ColumnsIconDataEnum } from '../../_interfaces/columns-icon-data.enum';

@Component({
    selector: 'app-columns-icon',
    templateUrl: './columns-icon.component.html',
    styleUrls: ['./columns-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnsIconComponent implements OnInit {
    private _value: ColumnsIconDataEnum | boolean;
    private _row: any;

    @Input() cell: ColumnsTableInterface;

    @Input()
    set value(value: ColumnsIconDataEnum | boolean) {
        this._value = value;
        this.parseValue(value);
    }

    get value(): ColumnsIconDataEnum | boolean {
        return this._value;
    }

    @Input()
    set row(row) {
        this._row = row;
    }

    get row(): any {
        return this._row;
    }

    @Output() changeActivityEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    alignItemsEnum = AlignItemsEnum;
    columnsIconDataEnum = ColumnsIconDataEnum;

    displayValue: ColumnsIconDataEnum;

    readonly ACTIVE_ICON_PATH = './assets/design/icons/table-operations/activity.svg';
    readonly INACTIVE_ICON_PATH = './assets/design/icons/table-operations/in-activity.svg';
    readonly DELETE_ICON_PATH = './assets/design/icons/table-operations/delete.svg';

    constructor() {}

    ngOnInit(): void {}

    private parseValue(value: ColumnsIconDataEnum | boolean): void {
        if (typeof value === 'boolean') {
            this.displayValue = value ? ColumnsIconDataEnum.active : ColumnsIconDataEnum.inActive;
            return;
        }

        if (!value) {
            this.displayValue = ColumnsIconDataEnum.inActive;
            return;
        }

        this.displayValue = value as ColumnsIconDataEnum;
        return;
    }

    changeCurrentActive(value: boolean): void {
        if (this.row.isEditing) {
            return;
        }
        this.changeActivityEvent.emit(value);
    }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {Observable, Subject} from 'rxjs';
import {first} from 'rxjs/operators';
import {ConfirmToken} from '../_store/actions/auth.actions';
import {ConfigurationState} from '../../app-shared-elements/_store/states/configuration.state';
import {ConfigurationServerInterface} from '../../app-shared-elements/_interfaces/configuration-server.interface';
import {CONSTANT_KEY} from '../../admin/system-settings/_enums/system-settings.enum';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit, OnDestroy {
    @Select(ConfigurationState.getConfigurationServer) configurationServer$: Observable<ConfigurationServerInterface>;

    CONSTANT_KEY = CONSTANT_KEY;

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private router: Router,
        private store: Store,
        private route: ActivatedRoute
    ) {
        this.route.queryParamMap.pipe(first()).subscribe((query: ParamMap) => {
            const token = query.get('token');
            if (token) {
                this.store.dispatch(new ConfirmToken(token, this.router.url.includes('control')));
            } else {
                this.router.url.includes('control') ? this.router.navigate(['/login/control']) : this.router.navigate(['/login']);
            }
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }

    moveLogin(): void {
        this.router.url.includes('control') ? this.router.navigate(['/login/control']) : this.router.navigate(['/login']);
    }
}

import { CountChannelInterface } from '../../../notifications/_interfaces/CountChannel';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SetNotificationsCount } from '../actions/notifications-count.actions';

export interface NotificationsCountStateModel {
    notifications: CountChannelInterface;
}

const NOTIFICATIONS_COUNT_STATE_TOKEN = new StateToken<NotificationsCountStateModel>('notificationsCount');

@State<NotificationsCountStateModel>({
    name: NOTIFICATIONS_COUNT_STATE_TOKEN,
    defaults: {
        notifications: null,
    },
})
@Injectable()
export class NotificationsCountState {
    constructor() {}

    @Selector()
    static getNotificationsCount(state: NotificationsCountStateModel): CountChannelInterface {
        return state.notifications;
    }

    @Action(SetNotificationsCount)
    setNotificationsCount(ctx: StateContext<NotificationsCountStateModel>, payload: SetNotificationsCount): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            notifications: payload.count,
        });
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectTypeEnum} from '../_enums/select-type.enum';
import {Pagination} from '../_interfaces/params.interface';
import {SelectOptionInterface} from '../_interfaces/select-option.interface';
import {TableService} from '../_services/table.service';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {

    @Input() configPagination: Pagination = {
        itemsPerPage: 1,
        currentPage: 1,
        totalItems: 1
    };

    @Input() customCountOption: SelectOptionInterface;
    @Input() currentWidth: number;
    @Input() isMobile: boolean;


    @Output() paginationOutput: EventEmitter<Pagination> = new EventEmitter<Pagination>();


    selectTypeEnum = SelectTypeEnum;

    constructor(
        public tableService: TableService
    ) {}

    ngOnInit(): void {}

    checkCurrentPage(event: SelectOptionInterface<number, string, number>): void {
        const maxPage = Math.ceil(this.configPagination.totalItems / event.key);

        if (this.configPagination.currentPage > maxPage) {
            this.configPagination.currentPage = 1;
        }
        this.configPagination.itemsPerPage = event.key;
        this.paginationOutput.emit(this.configPagination);

    }

    pageChanged(event: number): void {
        this.configPagination.currentPage = event;
        this.paginationOutput.emit(this.configPagination);
    }

}

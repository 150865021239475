import {Action, Selector, State, StateContext, StateToken, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SetExpiredPopup} from '../actions/expired-password.actions';
import {UserState} from './user.state';
import {Router} from '@angular/router';

export interface ExpiredPasswordStateModel {
    expiredPopup: boolean;
    expiredDays: number;
}

const EXPIRED_PASSWORD_TOKEN = new StateToken<ExpiredPasswordStateModel>('expiredPassword');

@State<ExpiredPasswordStateModel>({
    name: EXPIRED_PASSWORD_TOKEN,
    defaults: {
        expiredPopup: false,
        expiredDays: 0
    }
})

@Injectable()
export class ExpiredPasswordState {
    constructor(private store: Store, private router: Router) {
    }

    @Selector()
    static getExpiredPopup(state: ExpiredPasswordStateModel): boolean {
        return state.expiredPopup;
    }

    @Selector()
    static getExpiredDays(state: ExpiredPasswordStateModel): number {
        return state.expiredDays;
    }

    @Action(SetExpiredPopup)
    setExpiredPopup(ctx: StateContext<ExpiredPasswordStateModel>, payload: SetExpiredPopup): void {
        const state = ctx.getState();
        const user = payload.user ? payload.user : this.store.selectSnapshot(UserState.getUser);

        if (!user) {
            this.router.navigate(['/login']);
            return;
        }

        const currentDate = Date.parse(new Date().toString());
        const daysLeft = Math.round((currentDate - Date.parse(user.passwordUpdate.toString())) / 86400000);
        const days = user.lifePassword - daysLeft;

        if (days <= 5 && daysLeft <= user.lifePassword) {
            ctx.setState({
                ...state,
                expiredPopup: payload.action,
                expiredDays: days
            });
        } else {
            ctx.setState({
                ...state,
                expiredPopup: false
            });
        }
    }
}

import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector, NgZone, Type } from '@angular/core';
import { ComponentsGeneratorService } from './components-generator.service';

@Injectable({
    providedIn: 'root'
})
export class ComponentGeneratorService {

    public data: any = {};

    constructor(
        private appRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
    ) { }

     /**
     * Динамическое создание компонена.
     * @param component Component name by string or component factory itself (ex. .create('ModalPopupComponent', ...) or .create(ModalPopupComponent, ...))
     * @param target DOM element or string css selector
     * @param data object with data parameters, will be injected to component
     * @return ViewReference
     */
    public create(component: any, target: HTMLElement | string, data?: any) {
        return new Promise((resolve, reject) => {
            // таймаут необходим чтобы дать angular change detection время все прочекать и избежать ExpressionChangedAfterItHasBeenCheckedError
            setTimeout(() => {
                resolve(this._create(component, target, data));
            }, 0);
        });
    }

     /** Удаление динамически созданых компонентов.*/
     public destroyComponent(component, node?) {
        let data = this.data;

        if (typeof data[component] === 'undefined' || data[component].length === 0) {
            return false;
        }

        let i = data[component].length;
        while (i--) {
            if (data[component].hasOwnProperty(i)) {
                if ((node && data[component][i] === node) || !node) {
                    data[component][i].reference.destroy();
                    data[component].splice(i, 1);
                }
            }
        }

    }

    private _create(component: any, target: HTMLElement | string, data?: any) {
        // 1. Create a component reference from the component
        let componentFactory;
        if (typeof component === 'string') {

            let factories = Array.from(this.componentFactoryResolver['_factories'].keys());

            componentFactory = <Type<any>>factories.find((x: any) => x.name === component);

            if (!componentFactory) {
                throw new Error(`Dynamic creation of component ${component} fail, factory for component is not found`);
            }
        } else {
            componentFactory = component;
        }

        const componentRef = this.componentFactoryResolver
            .resolveComponentFactory(componentFactory)
            .create(this.injector);

        // 2. Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);

        if (data) {
            Object.keys(data).forEach((key) => {
                componentRef.instance[key] = data[key];
            });
        }

        // 3. Get DOM element from component
        const createdComponent = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        // 4. Append DOM element to the target node
        if (target) {
            let targetDomElement;
            if (target instanceof HTMLElement) {
                targetDomElement = target;
            } else if (typeof target === 'string') {
                targetDomElement = document.querySelector(target);

            }

            targetDomElement.appendChild(createdComponent);
            let savedComponents = this.data;

            if (typeof savedComponents[componentFactory.name] === 'undefined') {
                savedComponents[componentFactory.name] = [{node: targetDomElement, reference: componentRef}];
            } else {
                savedComponents[componentFactory.name].push({node: targetDomElement, reference: componentRef});
            }
        }



        return componentRef;
    }
}

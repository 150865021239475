import { DeviceTypeEnum } from '../../app-shared-elements/_enums/device-type.enum';
import { EventEmitter, Injectable } from '@angular/core';
import { Pagination, Params, ParamsFilterForClient, ParamsFilterTypeEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { DropdownFilterOptionInterface } from 'src/app/app-shared-elements/filters/interfaces/filter-option.interface';
import { Device } from '../../app-shared-elements/_interfaces/Device';
import { CreationType } from '../../app-shared-elements/_enums/registrator-sync-status.enu';
import { ActionListItemInterface } from 'src/app/app-shared-elements/_interfaces/action-list-item.interface';
import { ActionListItemEnum } from 'src/app/app-shared-elements/_enums/action-list-item.enum';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { TypeFilterEnum } from 'src/app/app-shared-elements/filters/enums/type-filter.enum';
import { CloneDeepService } from 'src/app/app-shared-elements/_services/clone-deep.service';
import { FilterTypeSystemLogsEnum } from 'src/app/journals/_enums/filter-group-system-logs.enum';
import { AlarmTypeEnum } from 'src/app/events/_enums/alarm.enum';
import { TechnologicEvent } from 'src/app/events/_interfaces/TechnologicEvent';
import { DeviceDashboardPositionInterface } from '../_interfaces/device-dashboard-position.interface';

@Injectable({
    providedIn: 'root',
})
export class DeviceService {
    private readonly strangerRegistrator: ActionListItemInterface[] = [
        { title: 'btns.detail', action: ActionListItemEnum.detail },
        { title: 'btns.delete', action: ActionListItemEnum.delete },
    ];

    private readonly selfRegistrator: ActionListItemInterface[] = [
        { title: 'btns.activity', action: ActionListItemEnum.active },
        { title: 'btns.detail', action: ActionListItemEnum.detail },
        { title: 'devices.share', action: ActionListItemEnum.share },
        { title: 'btns.trashDeleted', action: ActionListItemEnum.delete },
    ];

    private readonly selfVirtualRegistrator: ActionListItemInterface[] = [
        { title: 'btns.detail', action: ActionListItemEnum.detail },
        { title: 'btns.edit', action: ActionListItemEnum.edit },
        { title: 'devices.share', action: ActionListItemEnum.share },
        { title: 'btns.delete', action: ActionListItemEnum.delete },
    ];

    private readonly strangerDevice: ActionListItemInterface[] = [{ title: 'btns.detail', action: ActionListItemEnum.detail }];

    private readonly selfDevice: ActionListItemInterface[] = [
        { title: 'btns.detail', action: ActionListItemEnum.detail },
        { title: 'btns.trashDeleted', action: ActionListItemEnum.delete },
    ];

    dropDownFilterOptions: DropdownFilterOptionInterface[] = [
        { key: 'registratorId', value: 'devices.dropdownFilters.name', type: TypeFilterEnum.select, property: 'registratorId' },
        { key: 'owner', value: 'devices.dropdownFilters.login', type: TypeFilterEnum.text, property: 'owner' },
        { key: 'isConnect', value: 'devices.dropdownFilters.connect', type: TypeFilterEnum.select, property: 'isConnect' },
        { key: 'status', value: 'devices.dropdownFilters.status', type: TypeFilterEnum.select, property: 'status' },
    ];

    dropDownFilterOptionsForAdmin: DropdownFilterOptionInterface[] = [
        { key: 'registratorId', value: 'devices.dropdownFilters.name', type: TypeFilterEnum.select, property: 'registratorId' },
        { key: 'owner', value: 'devices.dropdownFilters.login', type: TypeFilterEnum.text, property: 'owner' },
        { key: 'isConnect', value: 'devices.dropdownFilters.connect', type: TypeFilterEnum.select, property: 'isConnect' },
        { key: 'status', value: 'devices.dropdownFilters.status', type: TypeFilterEnum.select, property: 'status' },
        { key: 'companyId', value: 'devices.dropdownFilters.company', type: TypeFilterEnum.select, property: 'companyId' },
    ];

    public groupsSaveEvent: EventEmitter<string> = new EventEmitter<string>();
    public groupCloseEvent: EventEmitter<void> = new EventEmitter<void>();
    public redrawChartEvent: EventEmitter<void> = new EventEmitter<void>();
    public updateDeviceLength: EventEmitter<void> = new EventEmitter<void>();

    constructor(private cloneDeepService: CloneDeepService) {}

    onChangePage(data: Pagination): void {
        localStorage.setItem('itemsPerPage', JSON.stringify(data.itemsPerPage));
    }

    setGroupDataFilter(data: ParamsFilterForClient[]): void {
        localStorage.setItem('paramsFilterDevices', JSON.stringify(data));
    }

    getPosition(registrator: Device, user: User): number {
        if (!user) {
            return;
        }

        const pos: DeviceDashboardPositionInterface[] = JSON.parse(localStorage.getItem(`position${user.id}`));

        if (!pos) {
            return;
        }
        const position = pos.find((p) => registrator.id === p.registratorId);
        if (!position) {
            return null;
        }
        return position.position;
    }

    getDevicesPosition(device: Device, user: User): number {
        if (!user) {
            return;
        }
        const pos: { id: string; position: number }[] = JSON.parse(localStorage.getItem(`devicesPosition${user.id}`));
        if (!pos) {
            return;
        }

        const position = pos.find((f) => f.id === device.id);
        if (!position) {
            return null;
        }
        return position.position;
    }

    getListAction(device: Device, user: User): ActionListItemInterface[] {
        if (!user) {
            return [];
        }

        let list: ActionListItemInterface[] = [];

        if (device.type === DeviceTypeEnum.registrator) {
            list = device.userId === user.id ? (device.creationType === CreationType.VIRTUAL ? this.selfVirtualRegistrator : this.selfRegistrator) : this.strangerRegistrator;
        } else {
            list = device.userId === user.id ? this.selfDevice : this.strangerDevice;
        }
        return list;
    }

    getIsVisibleDevice(device: Device, params: Params): boolean {
        if (!params || !params.filter || !params.filter.length) {
            return true;
        }
        const currentFilters: ParamsFilterForClient[] = params.filter.filter((f) => f.type === ParamsFilterTypeEnum.BOOLEAN && f.value && !f.isDropdown);
        let currentFlatFilters: ParamsFilterForClient[] = [];
        let flatRelationsFilters: ParamsFilterForClient[] = [];
        currentFilters.forEach((filter) => {
            if (!filter.relationTrue) {
                return;
            }

            currentFlatFilters = [...currentFlatFilters, ...filter.relationTrue];
            if (filter.relationFalse) {
                flatRelationsFilters = [...flatRelationsFilters, ...filter.relationFalse];
            }
        });

        if (!currentFilters || !currentFilters.length) {
            return true;
        }

        let count = 0;
        const currentLength = new Set(currentFlatFilters.map((f) => f.property)).size;
        let isCreationType = false;
        currentFlatFilters.forEach((filter) => {
            if (filter.property === 'creationType' && device.type !== DeviceTypeEnum.registrator) {
                isCreationType = true;
                return;
            }

            if (filter.value === device[filter.property]) {
                count++;
            }
        });

        if (isCreationType && device.type !== DeviceTypeEnum.registrator && currentLength > 1) {
            return count >= currentLength - 1;
        }

        return count >= currentLength;
    }

    updatedDevice(oldDevices: Device[], newDevices: Device[]): Device[] {
        return oldDevices.map((device) => {
            const updatedDevice = newDevices.find((d) => device.id === d.id);
            if (updatedDevice) {
                device = this.cloneDeepService.cloneObject(updatedDevice);
            }

            return device;
        });
    }

    getCurrentSystemLogsEnumForFilter(
        status: AlarmTypeEnum,
        techEvents: TechnologicEvent[],
    ): FilterTypeSystemLogsEnum.fromDeviceOnlyActiveAlarm | FilterTypeSystemLogsEnum.fromDeviceOnlyActiveAttention | FilterTypeSystemLogsEnum.fromDeviceWithWaitAcknAttention | FilterTypeSystemLogsEnum.fromDeviceWithWaitAcknAlarm {
        if (!techEvents || !techEvents.length) {
            return status === AlarmTypeEnum.attention ? FilterTypeSystemLogsEnum.fromDeviceWithWaitAcknAttention : FilterTypeSystemLogsEnum.fromDeviceWithWaitAcknAlarm;
        }

        const isFindActiveEvent = !!techEvents.find((e) => e.alarmType === status && !e.tsCleared);

        if (isFindActiveEvent) {
            return status === AlarmTypeEnum.attention ? FilterTypeSystemLogsEnum.fromDeviceOnlyActiveAttention : FilterTypeSystemLogsEnum.fromDeviceOnlyActiveAlarm;
        }

        return status === AlarmTypeEnum.attention ? FilterTypeSystemLogsEnum.fromDeviceWithWaitAcknAttention : FilterTypeSystemLogsEnum.fromDeviceWithWaitAcknAlarm;
    }
}

<div class="column"
     [class.column--edit]="row.isEditing && !cell.isFullWrapperBtn"
>
    <ng-container *ngIf="!row.isEditing && cell.isSelectEditable; else select">
        <div class="column__value">{{value | translate}}</div>
    </ng-container>
    <ng-template #select>
        <div class="column-select"
             [class.column-select--top]="cell.alignItems === alignItemsEnum.top"
        >
            <app-custom-select [isReset]="false"
                               [style.width]="'100%'"
                               [isRequire]="!value"
                               [options]="options"
                               [isDisable]="row?.isSelectDisabled"
                               [isSearch]="true"
                               [isDefault]="!!!value"
                               [value]="value || cell.placeholderForSelectColumn || 'filters.customFilter.defaultValue'"
                               [currentOption]="currentOption"
                               (emmitNewValue)="setNewOption($event)"
                               (emmitEditMode)="setEditmode($event)"
                               [type]="cell.selectType ? cell.selectType : selectTypeEnum.table"></app-custom-select>
        </div>
    </ng-template>
</div>

import { HTTP_STATUS } from './../../app-shared-elements/_enums/status.enum';
import { ApiResponse } from '../../app-shared-elements/_interfaces/ApiRequest';
import { ReportRepeatEnum, ReportSettingsInterface } from './../_interfaces/ReportSettings';
import { ScheduleTypeEnum } from './../_enums/schedule-type.enum';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormatPrintEnum } from '../_enums/format-print.type.enum';
import { DataTypeService } from '../../app-shared-elements/_services/data-type.service';
import { Store } from '@ngxs/store';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { DeviceTypeEnum } from 'src/app/app-shared-elements/_enums/device-type.enum';
import { Breadcrumb } from 'src/app/app-shared-elements/_interfaces/Breadcrumbs';
import { UserProfileInfoTypeDateEnum } from 'src/app/profile/_enums/user-profile-info-date-time.enum';
import { Axis } from 'src/app/app-shared-elements/_interfaces/Axis';
import { DataSettingsForGenerateReport, EventsLogDataForGenerateReport, SummaryTableDataForGenerateReport, TableDataForGenerateReport } from '../_interfaces/data-report.interface';
import { ArchiveChartInterface } from 'src/app/app-shared-elements/_interfaces/ArchiveChart';
import { TranslateService } from '@ngx-translate/core';
import { LuxonDatePipe } from '../../app-shared-elements/_pipes/luxon-date.pipe';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { PerformArchiveInterface } from 'src/app/groups/_interfaces/GenerateDataInterface';
import { LanguageState } from 'src/app/app-shared-elements/_store/states/language.state';
import { LangEnum } from 'src/app/app-shared-elements/_enums/lang.enum';
import { VariableGroupSettings } from 'src/app/app-shared-elements/_interfaces/VariableGroupSettings';
import { CompletionType } from 'src/app/journals/_enums/completion-type.enum';
import { EventFromServer } from 'src/app/journals/_interfaces/technologic-log-row.interface';
import { CheckboxReportListInterface } from '../_interfaces/checkbox-report-list.interface';
import { SelectOptionInterface } from '../../app-shared-elements/_interfaces/select-option.interface';
import { SetArchive } from '../../app-shared-elements/_store/actions/archive.actions';

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    style = `<style>
                table {
                    width: 100% !important;
                    border-collapse: collapse !important;
                    page-break-inside: auto !important;
                    background-color: #fff !important;
                    display: table !important;
                }
                thead {
                    display: table-header-group !important;
                    break-inside: avoid !important;
                }
                tr {
                    page-break-inside: avoid !important;
                    page-break-after: auto !important;
                    width: 100% !important;
                    border-left: 1px solid #000 !important;
                    border-top: 1px solid #000 !important;
                    box-sizing: border-box !important;
                }
                tr:last-child td {
                    border-bottom: 1px solid #000 !important;
                }
                th {
                    padding: 2px 4px !important;
                    border-right: 1px solid #000 !important;
                    font-size: 11px !important;
                    box-sizing: border-box !important;
                }
                td {
                    font-size: 10px !important;
                    padding: 2px 4px !important;;
                    box-sizing: border-box !important;
                    border-right: 1px solid #000 !important;
                }
                td.report-print__no-data {
                    padding: 10px 4px !important;
                    font-size: 18px !important;
                    text-align: center !important;
                }
            </style>`;

    eventsTableColumns = [
        {
            title: 'logs.events.tables.from',
            grow: false,
            small: false,
            maxWidth: '260px',
            type: 'date',
            name: 'appearedFrom',
            isSettings: false,
            isDate: true,
            isTranslate: false,
        },
        {
            title: 'logs.events.tables.to',
            grow: false,
            small: false,
            maxWidth: '260px',
            type: 'date',
            name: 'appearedTo',
            isSettings: false,
            isDate: true,
            isTranslate: false,
        },
        {
            title: 'logs.events.tables.name',
            grow: true,
            small: false,
            type: 'text',
            name: 'name',
            isDate: false,
            isTranslate: false,
        },
        {
            title: 'logs.events.tables.type',
            grow: false,
            small: true,
            maxWidth: '180px',
            type: 'text',
            name: 'type',
            isDate: false,
            isTranslate: false,
        },
        {
            title: 'logs.events.tables.ackn',
            grow: false,
            small: false,
            maxWidth: '220px',
            minWidth: '160px',
            type: 'acknowledge',
            name: 'ackn',
            isDate: false,
            isTranslate: true,
        },
        {
            title: 'logs.events.tables.user',
            grow: false,
            small: false,
            maxWidth: '220px',
            minWidth: '160px',
            type: 'text',
            name: 'user',
        },
    ];

    summaryTableColumns = [
        {
            title: 'reports.summaryTableColumns.variable',
            maxWidth: '260px',
            type: 'text',
            name: 'variableName',
        },
        {
            title: 'reports.summaryTableColumns.min',
            maxWidth: '260px',
            type: 'text',
            name: 'minValue',
        },
        {
            title: 'reports.summaryTableColumns.max',
            type: 'text',
            name: 'maxValue',
        },
        {
            title: 'reports.summaryTableColumns.middle',
            maxWidth: '180px',
            type: 'text',
            name: 'middleValue',
        },
    ];

    scheduleOptions: SelectOptionInterface<ScheduleTypeEnum>[] = [
        {
            value: 'reports.periodSelect.currentDay',
            key: ScheduleTypeEnum.currentDay,
        },
        {
            value: 'reports.periodSelect.lastDay',
            key: ScheduleTypeEnum.lastDay,
        },
        {
            value: 'reports.periodSelect.lastWeek',
            key: ScheduleTypeEnum.lastWeek,
        },
        {
            value: 'reports.periodSelect.lastMonth',
            key: ScheduleTypeEnum.lastMonth,
        },
        {
            value: 'reports.periodSelect.lastQuarter',
            key: ScheduleTypeEnum.lastQuarter,
        },
        {
            value: 'reports.periodSelect.lastYear',
            key: ScheduleTypeEnum.lastYear,
        },
        {
            value: 'reports.periodSelect.customShedule',
            key: ScheduleTypeEnum.customSchedule,
        },
    ];

    formatOptions: SelectOptionInterface<FormatPrintEnum>[] = [
        {
            value: 'reports.printFormatSelect.a4',
            key: FormatPrintEnum.A4,
        },
        {
            value: 'reports.printFormatSelect.a4Landscape',
            key: FormatPrintEnum.A4Landscape,
        },
    ];

    periodOptions: SelectOptionInterface<ReportRepeatEnum>[] = [
        {
            value: 'reports.regularSelect.once',
            key: ReportRepeatEnum.once,
            type: 'text',
            disabled: false,
        },
        {
            value: 'reports.regularSelect.regular',
            key: ReportRepeatEnum.regular,
            type: 'text',
            disabled: false,
        },
        // {
        //     value: 'reports.regularSelect.none',
        //     key: ReportRepeatEnum.none,
        //     type: 'text',
        //     disabled: false
        // }
    ];

    lnOptions: SelectOptionInterface<LangEnum>[] = [
        {
            value: 'reports.lnOptions.en',
            key: LangEnum.en,
            type: 'text',
        },
        {
            value: 'reports.lnOptions.ua',
            key: LangEnum.ua,
            type: 'text',
        },
    ];

    showReportsTables: CheckboxReportListInterface[] = [
        {
            title: 'reports.nameCheck',
            value: 'isShowNameReport',
            isToggle: false,
        },
        {
            title: 'reports.nameOfCompany',
            value: 'isShowNameCompany',
            isToggle: false,
        },
        {
            title: 'reports.chart',
            value: 'isShowChart',
            isToggle: false,
            children: [
                {
                    title: 'reports.isLimits',
                    value: 'isLimits',
                    isToggle: false,
                    property: 'isLimits',
                },
            ],
        },
        {
            title: 'reports.table',
            value: 'isShowTable',
            isToggle: false,
            children: [
                {
                    title: 'reports.isSplitTable',
                    value: 'isSplitTable',
                    isToggle: false,
                    property: 'splitTable',
                },
            ],
        },
        {
            title: 'reports.summaryTable',
            value: 'isShowSummaryTable',
            isToggle: false,
        },
        {
            title: 'reports.eventLog',
            value: 'isShowEventLogs',
            isToggle: false,
            children: [
                {
                    title: 'reports.isAlarm',
                    value: 'isShowOnlyAlarm',
                    isToggle: false,
                    property: 'showOnlyAlarm',
                },
            ],
        },
        // {
        //     title: 'reports.userLogs',
        //     value: 'isShowUserLogs',
        // },
    ];

    reportsBreadcrumbs: Breadcrumb = {
        title: 'reports.breadCrumbs.list',
        link: '/reports',
        id: 0,
        active: false,
    };

    optionsForDateFormat: SelectOptionInterface<UserProfileInfoTypeDateEnum, string, UserProfileInfoTypeDateEnum>[] = [
        {
            type: 'dateFormat',
            property: UserProfileInfoTypeDateEnum.ddMMyyyy,
            key: UserProfileInfoTypeDateEnum.ddMMyyyy,
            value: 'profile.dateFormat.ddMMyyyy',
        },
        {
            type: 'dateFormat',
            property: UserProfileInfoTypeDateEnum.mmDDyyyy,
            key: UserProfileInfoTypeDateEnum.mmDDyyyy,
            value: 'profile.dateFormat.mmDDyyyy',
        },
    ];

    currentReport: ReportSettingsInterface;
    userLogsRows: any[];
    currentTableGroupColumns: any[] = [];
    groupRows: any[];
    eventRows: any[];
    summaryTableRows: { variableName: string; minValue: string; maxValue: string; middleValue: string }[];
    private variableGroupSettingsFroChart: VariableGroupSettings[] = [];

    constructor(
        private httpClient: HttpClient,
        private dataTypeService: DataTypeService,
        private store: Store,
        private translateService: TranslateService,
        private luxonDatePipe: LuxonDatePipe,
    ) {}

    async getDataForServerPrint(options: { groupId: number; start: number; end: number; token: string; isGroupTable: number; isChart: number; isUserLogTable: number; isShowEventLogs: number }): Promise<any> {
        const params = new HttpParams()
            .set('token', options.token)
            .set('isGroupTable', String(options.isGroupTable))
            .set('isChart', String(options.isChart))
            .set('isUserLogTable', String(options.isUserLogTable))
            .set('isShowEventLogs', String(options.isShowEventLogs));

        const result = await this.httpClient.get<ApiResponse>('api/reports-server/generate', { params }).toPromise();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            return result.data;
        }
    }

    async getDataSettings(token: string): Promise<DataSettingsForGenerateReport> {
        const params = new HttpParams().set('token', token);
        const result = await this.httpClient.get<ApiResponse>('api/reports-server/settings', { params }).toPromise();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            return result.data;
        }
    }

    async getVariablesSettings(token: string): Promise<{ variable: Variable; settings: VariableGroupSettings }[]> {
        const params = new HttpParams().set('token', token);
        const result: ApiResponse<{ variable: Variable; settings: VariableGroupSettings }[]> = (await this.httpClient
            .get<ApiResponse<{ variable: Variable; settings: VariableGroupSettings }[]>>('api/reports-server/generate-variable-settings', {
                params,
            })
            .toPromise()) as ApiResponse<{ variable: Variable; settings: VariableGroupSettings }[]>;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.setVariableGroupSettingsFroChart(result.data.map((item) => item.settings));
            return result.data;
        }
    }

    async getDataChart(token: string, variableId: string): Promise<ArchiveChartInterface> {
        const params = new HttpParams().set('token', token).set('variableId', `${variableId}`);
        const result = await this.httpClient.get<ApiResponse>('api/reports-server/generate-chart', { params }).toPromise();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.store.dispatch(new SetArchive(result.data));
            return result.data;
        }
    }

    async getDataAxis(token: string): Promise<Axis[]> {
        const params = new HttpParams().set('token', token);
        const result = await this.httpClient.get<ApiResponse>('api/reports-server/generate-axis', { params }).toPromise();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            return result.data;
        }
    }

    async getDataGroupTable(token: string): Promise<TableDataForGenerateReport> {
        const params = new HttpParams().set('token', token);
        const result = await this.httpClient.get<ApiResponse>('api/reports-server/generate-group-table', { params }).toPromise();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            return result.data;
        }
    }

    async getDataEventLog(token: string): Promise<EventsLogDataForGenerateReport> {
        const params = new HttpParams().set('token', token);
        const result = await this.httpClient.get<ApiResponse>('api/reports-server/generate-event-log', { params }).toPromise();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            return result.data;
        }
    }

    async getDataSummaryTable(token: string): Promise<SummaryTableDataForGenerateReport> {
        const params = new HttpParams().set('token', token);
        const result = await this.httpClient.get<ApiResponse>('api/reports-server/generate-summary-table', { params }).toPromise();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            return result.data;
        }
    }

    async getSettingsForServer(token): Promise<ReportSettingsInterface> {
        const params = new HttpParams().set('token', String(token));

        const result = await this.httpClient.get<ApiResponse>('api/reports-server/settings', { params }).toPromise();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            return result.data;
        }
    }

    async generateReport(reportSettings: ReportSettingsInterface): Promise<ApiResponse> {
        const devices: Device[] = this.store.selectSnapshot(DevicesState.getDevices);
        const currentDevice = devices.find((device) => device.id === reportSettings.deviceId);

        const registratorId = currentDevice.type === DeviceTypeEnum.registrator ? currentDevice.id : currentDevice.registratorId;

        const headers = new HttpHeaders({ registratorId: registratorId || '' });

        return (await this.httpClient
            .post(`/api/reports/generate`, reportSettings, { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
    }

    async initUserLogsTable(userLogs): Promise<any> {
        this.userLogsRows = userLogs.items;

        //         action: "login"
        // created: "2020-12-14T12:37:47.000Z"
        // data: {executorUser: "soft25@sidstudio.com.ua"}
        // id: 73
        // ip: "192.168.0.16"
        // key: "logs.users.messages.login_user"
        // typeClient: "user"
        // userLogin: "soft25@sidstudio.com.ua"
    }

    async initTableGroup(data: TableDataForGenerateReport, format: string, timezone: string, variables: Variable[]): Promise<string> {
        if (!data || !data.head || !data.body || !data.head.length || !data.body.length) {
            return '';
        }

        const ln = this.store.selectSnapshot(LanguageState.getLanguage);

        let head = `<thead><tr>`;

        head += `<th>${await this.translateService.get('table.tableGroup.date').toPromise()}</th>`;
        data.head.map((variable: { name: string; id: string }) => {
            const currentVariable: Variable = variables.find((v) => variable.id === v.id || variable.id === v.originVariableId);
            const unit: string = currentVariable?.unitName;
            const name: string = currentVariable?.customName || variable.name;
            head += `<th>${name}, ${unit || ''}</th>`;
        });
        head += `<tr>`;

        let body = `<tbody>`;
        data.body.map((item) => {
            body += `<tr>`;
            body += `<td>${this.luxonDatePipe.transform(+item.ts, format, timezone, ln)}</td>`;

            data.head.forEach((variable) => {
                let isFind = false;
                item.archiveValues.forEach((value) => {
                    if (variable.id === value.variableId) {
                        body += `<td style="text-align: right;">${value.value}</td>`;
                        isFind = true;
                    }
                });
                if (!isFind) {
                    body += `<td></td>`;
                }
            });
        });

        return `${this.style}<table>${head}${body}</table>`;
    }

    async initEventTable(data: EventsLogDataForGenerateReport, format: string, timezone: string): Promise<any[]> {
        if (!data || !data.items || !data.items.length) {
            return [];
        }
        const alarm = await this.translateService.get('logs.events.filters.alarm').toPromise();
        const attention = await this.translateService.get('logs.events.filters.attention').toPromise();
        const completionTypeChangeStr = await this.translateService.get('logs.events.changed').toPromise();
        const completionTypeDeletedStr = await this.translateService.get('logs.events.deleted').toPromise();
        const completionTypeCompletedStr = await this.translateService.get('logs.events.completed').toPromise();
        const ln = this.store.selectSnapshot(LanguageState.getLanguage);
        // let completionType: CompletionType;

        const rows = (data.items as EventFromServer[]).map((item) => {
            let completionTypeStr = '';
            if (item.completionType !== CompletionType.NATIVE) {
                switch (item.completionType) {
                    case CompletionType.DELETE:
                        completionTypeStr = `[${completionTypeDeletedStr}]`;
                        break;
                    case CompletionType.CHANGE_ACTIVE:
                        completionTypeStr = `[${completionTypeChangeStr}]`;
                        break;
                    case CompletionType.COMPLETED:
                        completionTypeStr = `[${completionTypeCompletedStr}]`;
                        break;
                }
            }
            return {
                id: item.id,
                appearedFrom: this.luxonDatePipe.transform(+item.ts, format, timezone, ln),
                ackn: this.parseValueAckn({ ts: item.tsAcknowledget, isAknowledgeable: item.isAknowledgeable }),
                appearedTo: item.tsCleared ? `${completionTypeStr} ${this.luxonDatePipe.transform(+item.tsCleared, format, timezone, ln)}` : null,
                name: item.message,
                type: +item.alarmType === 1 ? alarm : attention,
                user: item.acknowledgedByUser,
                isCleared: this.getClearedParam(item),
                // isTranslate:
                // isCleared: ((!item.tsCleared || item.tsCleared === 'undefined') && (!item.isAknowledgeable)) ? false : true
            };
        });
        this.eventRows = [...rows];
    }

    async initSummaryTable(data: SummaryTableDataForGenerateReport, variables: Variable[]): Promise<void> {
        try {
            this.summaryTableRows = data.data.map((item) => {
                const currentVariable: Variable = variables.find((v) => item.variableId === (v.originVariableId || v.id));
                const unit = currentVariable ? currentVariable.unitName || '' : '';

                return {
                    variableName: currentVariable.customName || currentVariable.name,
                    minValue: `${item.minValue} ${unit}`,
                    maxValue: `${item.maxValue} ${unit}`,
                    middleValue: `${item.middleValue} ${unit}`,
                };
            });
        } catch (e) {
            console.log(e);
        }
    }

    private getMinValue(data: PerformArchiveInterface[], variableId: string): string {
        if (!data || !data.length) {
            return '';
        }
        const values = data
            .map((i) => {
                const currentItem = i.archiveValues.find((item) => item.variableId === variableId);
                return currentItem ? currentItem.value : null;
            })
            .filter((value) => !isNaN(+value) && value !== null)
            .map((v) => +v);

        if (!values || !values.length) {
            return '-';
        }

        const result = values.reduce((prev, curr) => {
            return prev < curr ? prev : curr;
        });
        return `${result ?? ''}`;
    }

    private getMaxValue(data: PerformArchiveInterface[], variableId: string): string {
        const values = data
            .map((i) => {
                const currentIntem = i.archiveValues.find((item) => item.variableId === variableId);
                return currentIntem ? currentIntem.value : null;
            })
            .filter((value) => !isNaN(+value) && value !== null)
            .map((v) => +v);

        if (!values || !values.length) {
            return '-';
        }

        const result = values.reduce((prev, curr) => {
            return prev > curr ? prev : curr;
        });
        return `${result ?? ''}`;
    }

    private getMiddleValue(data: PerformArchiveInterface[], variableId: string): string {
        const values = data
            .map((i) => {
                const currentIntem = i.archiveValues.find((item) => item.variableId === variableId);
                return currentIntem ? currentIntem.value : null;
            })
            .filter((value) => !isNaN(+value) && value !== null)
            .map((v) => +v);

        if (!values || !values.length) {
            return '-';
        }

        const result = values.reduce((prev, curr) => {
            return prev + curr;
        });

        return `${(result / values.length).toFixed(2) ?? ''}`;
    }

    parseValueAckn(value): string {
        if (!value.ts && !value.isAknowledgeable) {
            return 'reports.notConfirm';
        }

        if (value.ts) {
            return this.dataTypeService.reformatDate(+value.ts);
        }

        if (!value.ts && value.isAknowledgeable) {
            return 'events.logicalEvents.noAckn';
        }
    }

    getClearedParam(item: EventFromServer): boolean {
        if (item.isAknowledgeable && !item.tsAcknowledget) {
            return false;
        }

        if (!item.tsCleared || item.tsCleared === 'undefined') {
            return false;
        }

        return true;
    }

    setVariableGroupSettingsFroChart(settings: VariableGroupSettings[]): void {
        this.variableGroupSettingsFroChart = settings;
    }

    getVariableGroupSettingsFroChart(): VariableGroupSettings[] {
        return this.variableGroupSettingsFroChart || [];
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { HTTP_STATUS } from '../../_enums/status.enum';
import { ApiResponse } from '../../_interfaces/ApiRequest';
import { GetConfigurationServer, GetDescribesConfigurationErrors } from '../actions/configuration.actions';
import { SocketService } from '../../_services/socket.service';
import { ConfigurationDescribeErrorInterface } from '../../_interfaces/configuration-describe-error.interface';
import { ConfigurationServerInterface } from '../../_interfaces/configuration-server.interface';

export interface ConfigurationStateModel {
    configurationServer: ConfigurationServerInterface;
    describeConfigurationErrors: ConfigurationDescribeErrorInterface;
}

const CONFIGURATION_TOKEN = new StateToken<ConfigurationStateModel>('configurationState');

@State<ConfigurationStateModel>({
    name: CONFIGURATION_TOKEN,
    defaults: {
        configurationServer: null,
        describeConfigurationErrors: null,
    },
})
@Injectable()
export class ConfigurationState {
    constructor(
        private http: HttpClient,
        private socketService: SocketService,
    ) {}

    @Selector()
    static getConfigurationServer(state: ConfigurationStateModel): ConfigurationServerInterface {
        return state.configurationServer;
    }

    @Selector()
    static getDescribeConfigurationErrors(state: ConfigurationStateModel): ConfigurationDescribeErrorInterface {
        return state.describeConfigurationErrors;
    }

    @Action(GetConfigurationServer)
    async getConfigurationServer(ctx: StateContext<ConfigurationStateModel>): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .get<ApiResponse>('api/configuration-server')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        this.socketService.incrementGlobalPreloaderLoaded('configuration');

        if (result?.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                configurationServer: result.data as ConfigurationServerInterface,
            });
        }
    }

    @Action(GetDescribesConfigurationErrors)
    async getDescribesConfigurationErrors(ctx: StateContext<ConfigurationStateModel>): Promise<void> {
        const result = (await this.http
            .get('/api/configuration-server/device-error-status')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                describeConfigurationErrors: result.data,
            });
        }
    }
}

import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-range-box',
    templateUrl: './range-box.component.html',
    styleUrls: ['./range-box.component.scss']
})
export class RangeBoxComponent implements OnInit, AfterViewInit, OnDestroy {
    private _settings: any;
    private isComponentInited = false;

    private isMoveActive = false;
    private isResizeActive = false;
    private resizeDirection: string;

    private dynamicSettings: any = {};

    private hostElement: any;
    private wrapperElement: any;
    private masterContainerElement: any;
    private centerElement: any;

    private eventListeners: any = [];

    @Input()
    set settings(settings) {
        this._settings = settings;
        this.positionChanges();
    }

    get settings(): any {
        return this._settings;
    }

    @Output()
    events = new EventEmitter();

    constructor() {}

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        if (!this.isComponentInited) {
            this.wrapperElement = document.querySelector('.range-box-wrapper');
            this.hostElement = this.wrapperElement.parentElement;
            this.masterContainerElement = document.querySelector('#master');
            this.centerElement = this.wrapperElement.querySelector('.center');

            this.eventListeners = [
                {
                    type: 'mouseup',
                    target: this.masterContainerElement,
                    cb: (e) => {
                        console.log(e);
                        if (this.isMoveActive) {
                            this.endMove();
                        }

                        if (this.isResizeActive) {
                            this.endResize();
                        }
                    }
                },
                {
                    type: 'mousemove',
                    target: this.masterContainerElement,
                    cb: (e) => {
                        if (this.isResizeActive || this.isMoveActive) {
                            this.onMouseMove(e.x || e.clientX);
                        }
                    }
                }
            ];

            this.eventListeners.map(item => {
                item.target.addEventListener(item.type, item.cb);
            });

            this.isComponentInited = true;
        }
    }

    ngOnDestroy(): void {
        this.eventListeners.map(item => {
            item.target.removeEventListener(item.type, item.cb);
        });
    }

    public positionChanges(): void {
        if (!this.isComponentInited) {
            setTimeout(() => {
                this.updatePosition();
            });
        } else {
            this.updatePosition();
        }
    }

    private updatePosition(): void {
        this.wrapperElement = document.querySelector('.range-box-wrapper');
        this.hostElement = this.wrapperElement.parentElement;
        this.hostElement.style.left = `${this.settings.left}px`;
        this.hostElement.style.width = `${this.settings.right - this.settings.left}px`;
    }

    public onMouseMove(x): void {
        if (!this.isMoveActive && !this.isResizeActive) {
            return;
        }


        if (this.dynamicSettings.lastX === x) {
            return;
        }

        this.dynamicSettings.lastX = x;

        if (this.isMoveActive) {
            this.moveRangeBox(x);
        }

        if (this.isResizeActive) {
            this.resizeRangeBox(x);
        }
    }

    public startResize(type, x): void {
        this.updateCorrectionOffsets(x);
        this.isResizeActive = true;
        this.resizeDirection = type;
    }

    public endResize(): void {
        this.isResizeActive = false;
        this.events.emit({type: 'resize', data: this.settings});
    }

    public startMove(event): void {
        if (event.target !== this.centerElement) {
            return;
        }

        this.updateCorrectionOffsets(event.x);
        this.isMoveActive = true;
    }

    public endMove(): void {
        this.isMoveActive = false;

        this.events.emit({type: 'move', data: this.settings});
    }

    private updateCorrectionOffsets(x): void {
        this.dynamicSettings.width = Number.parseInt(this.hostElement.style.width);
        this.dynamicSettings.left = Number.parseInt(this.hostElement.style.left, 10);
        this.dynamicSettings.right = this.dynamicSettings.left + Number.parseInt(this.dynamicSettings.width);
        this.dynamicSettings.outerOffset = this.masterContainerElement.offsetLeft;
        this.dynamicSettings.innerOffset = x - this.dynamicSettings.outerOffset - this.dynamicSettings.left;
        this.dynamicSettings.masterWidth = this.masterContainerElement.offsetWidth;
    }

    private moveRangeBox(x): void {
        const left = x - this.dynamicSettings.outerOffset - this.dynamicSettings.innerOffset;
        const right = this.dynamicSettings.width + left;
        const offset = this.masterContainerElement.querySelector('.highcharts-plot-background').getAttribute('x');

        if (left < 0 || right > this.dynamicSettings.masterWidth) {
            return;
        }

        this.settings = {
            left,
            right,
            offsetX: offset
        };
    }

    private resizeRangeBox(x): void {
        let left;
        let right;

        if (this.resizeDirection === 'left') {
            left = x - this.dynamicSettings.outerOffset - this.dynamicSettings.innerOffset;
            right = this.dynamicSettings.right;
        }

        if (this.resizeDirection === 'right') {
            left = this.dynamicSettings.left;
            right = x + (this.dynamicSettings.width - this.dynamicSettings.innerOffset) - this.dynamicSettings.outerOffset;
        }

        if (left < 0 || right > this.dynamicSettings.masterWidth) {
            return;
        }

        const offset = this.masterContainerElement.querySelector('.highcharts-plot-background').getAttribute('x');

        this.settings = {
            left,
            right,
            offsetX: offset
        };
    }
}

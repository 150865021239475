export enum TrailerPositionEnum {
    FIRST = '0',
    SECOND = '1',
}

export enum PartTrailerPositionEnum {
    FIRST = '0',
    SECOND = '1',
    THIRD = '2',
}

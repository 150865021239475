import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DeviceDashboardComponent} from './device-dashboard.component';
import {AppSharedElementsModule} from '../app-shared-elements/app-shared-elements.module';
import {DeviceDashboardRoutingModule} from './device-dashboard-routing.module';
import {DevicesComponent} from './user-devices/devices/devices.component';
import {ShareDeviceComponent} from './user-devices/share-device/share-device.component';
import {ShareRoleInfoComponent} from './user-devices/share-device/share-role-info/share-role-info.component';
import {UserDevicesComponent} from './user-devices/user-devices.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {QRCodeModule} from 'angularx-qrcode';
import {ClipboardModule} from '@angular/cdk/clipboard';

@NgModule({
    declarations: [
        DeviceDashboardComponent,
        UserDevicesComponent,
        ShareDeviceComponent,
        ShareRoleInfoComponent,
        DevicesComponent,
    ],
    imports: [
        CommonModule,
        AppSharedElementsModule,
        DeviceDashboardRoutingModule,
        DragDropModule,
        QRCodeModule,
        ClipboardModule
    ],
    exports: [
        DeviceDashboardComponent,
        AppSharedElementsModule,
        DeviceDashboardRoutingModule,
        UserDevicesComponent,
        ShareDeviceComponent,
        ShareRoleInfoComponent,
        DevicesComponent,
    ]
})
export class DeviceDashboardSharedModule {}

<div class="invite">
    <div class="invite__wrapper">

        <div class="invite__message">
            <span [translate]="'share.invite.customer'" [translateParams]="{created: invite.created
            | luxonDate:(user$ | async).dateFormat
            || dataTypeService.dateFormat : (gmt$ | async) || dataTypeService.GMT : (ln$ | async)}"></span>

            <span class="invite__strong">{{invite.inviter}} </span>
            <span
                [translate]="invite.registratorType === creationType.MNEMONIC ? 'share.invite.getMnemonic' : 'share.invite.get'"></span>
            <span class="invite__strong">
                {{invite.name ? invite.name + '.' : invite.defaultName + '.'}}
            </span>
            <span *ngIf="invite.message.length" class="invite__strong" [translate]="'share.invite.comment'"></span>
            <span>{{invite.message}}</span>
        </div>

        <div class="invite__btns">
            <button (click)="cancelInvite()" class="btn btn--light btn--invite"
                    [translate]="'btns.reject'"></button>
            <button (click)="applyInvite()" class="btn btn--invite" [translate]="'btns.accept'"></button>
        </div>
    </div>
</div>

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { CustomSelectSideEnum } from '../_enums/custom-select-side.enum';
import { DeviceTypeEnum } from '../_enums/device-type.enum';
import { FilterTypePipeEnum } from '../_enums/filter-type-pipe.enum';
import { SelectTypeEnum } from '../_enums/select-type.enum';
import { SelectOptionInterface } from '../_interfaces/select-option.interface';
import { ChartModeEnum } from '../_enums/chart-mode.enum';

@Component({
    selector: 'app-custom-select',
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss'],
})
export class CustomSelectComponent implements OnInit, AfterViewInit {
    private _options: SelectOptionInterface[] = [];
    private _value: any = 'filters.customFilter.defaultValue';
    _currentOption: SelectOptionInterface;

    @Input()
    set options(options) {
        this._options = options;
        if (options && options.length) {
        }
    }

    get options(): any[] {
        return this._options;
    }

    @Input() set currentOption(currentOption: SelectOptionInterface<any, any, any>) {
        this._currentOption = currentOption;
    }

    get currentOption(): SelectOptionInterface {
        return this._currentOption;
    }

    @Input() set value(value) {
        this._value = value;
    }

    get value(): any {
        return this._value;
    }

    @Input() isTooltipFullValue: boolean;
    @Input() delete = '';
    @Input() placeholder: string;
    @Input() isReset = true;
    @Input() isDisable: boolean;
    @Input() unActive: boolean;
    @Input() isRepeat = false;
    @Input() type: SelectTypeEnum;
    @Input() isRequire: boolean;
    @Input() smartWrapperPosition: boolean;
    @Input() isIconList: boolean;
    @Input() isSearch: boolean;
    @Input() width: string;
    @Input() height: string;
    @Input() maxHeight: string;
    @Input() isHighlightRegistrators: boolean;
    @Input() isDefault: boolean;
    @Input() resetMessage: string;
    @Input() side: CustomSelectSideEnum = CustomSelectSideEnum.right;
    @Input() isOpenBottom: boolean;

    @Output() emmitNewValue = new EventEmitter<any>();
    @Output() emmitReset = new EventEmitter<any>();
    @Output() emmitRemove = new EventEmitter<any>();
    @Output() emmitEditMode: EventEmitter<boolean> = new EventEmitter<boolean>();

    isShowPopup = false;

    customSelectSideEnum = CustomSelectSideEnum;
    selectTypeEnum = SelectTypeEnum;

    saveScrollPosition: number;
    scrollPosition: number;

    currentOptionLength = 0;

    searchStr = '';

    deviceTypeEnum = DeviceTypeEnum;

    @ViewChild('select') select: ElementRef;

    listAbove: boolean;

    filterTypePipeEnum = FilterTypePipeEnum;

    readonly ARROW_ICON_PATH = './assets/design/icons/select-arrow.svg';

    chartModeEnum = ChartModeEnum;

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor() {}

    ngOnInit(): void {
        this.currentOptionLength = this.options && this.options.length ? this.options.length : 0;
    }

    ngAfterViewInit(): void {
        if (this.smartWrapperPosition) {
            this.computingWrapperPosition();
        }
    }

    setOption(option): void {
        this.value = option.value;
        this.emmitNewValue.emit(option);
        this.placeholder = null;
        this.currentOption = option;
        this.searchStr = '';
        this.saveScrollPosition = this.scrollPosition;
    }

    reset(): void {
        this.value = this.resetMessage || 'filters.customFilter.defaultValue';
        this.currentOption = null;
        this.emmitReset.emit();
        this.searchStr = '';
    }

    isObject(value): boolean {
        return value instanceof Object ? true : false;
    }

    removeEmit(): void {
        this.value = this.delete;

        this.currentOption = null;
        this.emmitRemove.emit();
    }

    computingWrapperPosition(): void {
        const topPositionRelatedDocument = this.select.nativeElement.getBoundingClientRect().y;
        const windowHeight = window.innerHeight;
        this.listAbove = windowHeight - topPositionRelatedDocument < 310 ? true : false;
    }

    clickOutside(): void {
        if (this.isShowPopup) {
            this.emmitEditMode.emit(false);
        }

        this.searchStr = '';
        this.isShowPopup = false;
    }

    trackByKey(index, item: { value: string; key: string; property: string }): any {
        return item.key;
    }

    saveScroll(item): void {
        if (this.options?.length <= 5) {
            return;
        }
        this.scrollPosition = item.scrollTop;
    }
}

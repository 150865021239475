import {Action, Selector, State, StateContext, StateToken} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServerStatusInterface} from '../../_interface/server-status.interface';
import {GetServers, GetServersRows} from '../actions/servers-dashboard.actions';
import {ApiResponse} from '../../../../app-shared-elements/_interfaces/ApiRequest';
import {HTTP_STATUS} from '../../../../app-shared-elements/_enums/status.enum';

export interface ServersDashboardStateModel {
    servers: ServerStatusInterface[];
    serversRows: ServerStatusInterface[];
}

const SERVERS_DASHBOARD_TOKEN = new StateToken<ServersDashboardStateModel>('serversDashboardState');

@State({
    name: SERVERS_DASHBOARD_TOKEN,
    defaults: {
        servers: [],
        serversRows: []
    }
})

@Injectable()
export class ServersDashboardState {
    constructor(private http: HttpClient) {
    }

    @Selector()
    static getServers(state: ServersDashboardStateModel): ServerStatusInterface[] {
        return state.servers;
    }

    @Selector()
    static getServersRows(state: ServersDashboardStateModel): ServerStatusInterface[] {
        return state.serversRows;
    }

    @Action(GetServers)
    async getServers(ctx: StateContext<ServersDashboardStateModel>): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = await this.http.get('api/control/status-servers').toPromise() as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                servers: result.data
            });
        } else {
            ctx.setState({
                ...state,
                servers: []
            });
        }

        await ctx.dispatch(GetServersRows).toPromise();
    }

    @Action(GetServersRows)
    async getServersRows(ctx: StateContext<ServersDashboardStateModel>): Promise<void> {
        const state = ctx.getState();

        const result: ServerStatusInterface[] = state.servers;


        result.forEach((value, index) => {
                const findServer = result.find(s => s.id === value.id);
                if (!findServer) {
                    result.splice(index, 1);
                }

                for (const key of Object.keys(new ServerStatusInterface())) {
                    result[index][key] = findServer[key];
                }

                for (const g in result[index]) {
                    if (result[index][g] === undefined) {
                        delete result[index][g];
                    }
                }
            }
        );

        ctx.setState({
            ...state,
            serversRows: result
        });
    }
}

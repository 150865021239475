import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-can-deactivate-popap',
    templateUrl: './can-deactivate-popap.component.html',
    styleUrls: ['./can-deactivate-popap.component.scss']
})
export class CanDeactivatePopapComponent implements OnInit {

    @Input() title: string;

    @Output() eventEmit = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit(): void {
    }

}

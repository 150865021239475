import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { AuthService } from 'src/app/auth/_services/auth.service';
import { SocketUserStatusEnum } from '../../auth/_enums/socket-user-status.enum';
import { NotificationsService } from './notifications.service';
import { TooltipStatusEnum } from '../_enums/tooltip-status.enum';
import { SocketService } from './socket.service';
import { first } from 'rxjs/operators';

// import {BlockUiService} from './block-ui.service';

@Injectable({
    providedIn: 'root',
})
export class BlockUiService {
    public modalEvent = new EventEmitter<boolean>();
    public isBlockUi = false;
    public isInit = false;
    public timeoutAction;
    public isShowModal = false;

    constructor(
        private authService: AuthService,
        private socketService: SocketService,
        private notificationService: NotificationsService,
        private ngZone: NgZone,
    ) {
        this.socketService.successfulConnect$.pipe(first()).subscribe((data) => {
            this.initBlockSession();
            if (data.freeze) {
                this.blockUi(true);
            }
        });

        this.socketService.logoutSubscribe.subscribe(() => {
            this.isShowModal = false;
            this.isBlockUi = false;
        });
    }

    public doAction(): void {
        if (this.isBlockUi) {
            this.openModal();
            return;
        }

        if (this.timeoutAction) {
            return;
        }

        this.ngZone.runOutsideAngular(() => {
            this.timeoutAction = setTimeout(() => {
                this.socketService.doAction();
                this.timeoutAction = null;
            }, 1500);
        });
    }

    removePreloader(): void {
        const preloader = document.querySelector('.root-preloader');
        if (preloader) {
            document.querySelector('.root-preloader').remove();
        }
        document.querySelector('body').classList.remove('remove-scroll-bar');
    }

    private initBlockSession(): void {
        if (this.isInit) {
            return;
        }
        this.modalEvent.subscribe((data) => {
            this.isShowModal = data;
        });

        this.socketService.freezeSessionSubscribe.subscribe((data) => {
            if (data.status === SocketUserStatusEnum.unlockActiveSession) {
                this.notificationService.onEmit(TooltipStatusEnum.update, false, 'disconnectBlock.sessionUnlocked');
                this.blockUi(false);
            }

            if (data.status === SocketUserStatusEnum.lockActiveSession) {
                this.notificationService.onEmit(TooltipStatusEnum.load, true, 'disconnectBlock.sessionBlocked');
                this.blockUi(true);
            }
        });

        this.isInit = true;
    }

    public openModal(): void {
        this.modalEvent.emit(true);
    }

    public closeModal(): void {
        this.modalEvent.emit(false);
    }

    public clear(): void {
        this.isShowModal = false;
        this.isBlockUi = false;
    }

    blockUi(block: boolean): void {
        this.isBlockUi = block;

        if (block === false) {
            this.isShowModal = false;
        }
    }
}

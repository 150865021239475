import {CountChannelInterface} from '../../_interfaces/CountChannel';
import {ChannelInterface, ChannelTypeEnum} from "../../_interfaces/Channel";

export class GetChatNotifications {
    static readonly type = '[Chat] GetChatNotifications';
}

export class SetChatNotifications {
    static readonly type = '[Chat] SetChatNotifications';

    constructor(public data: CountChannelInterface) {

    }
}

export class GetReadChannel {
    static readonly type = '[Chat] GetReadChannel';

    constructor(public channelId?: string, public type?: string) {
    }
}

export class GetChannels {
    static readonly type = '[Chat] GetChannels';
}

export class SetActiveChannel {
    static readonly type = '[Chat] SetActiveChannel';

    constructor(public channel: ChannelInterface) {
    }
}

export class GetDialog {
    static readonly type = '[Chat] GetDialog';

    constructor(public channelId?: string, public type?: ChannelTypeEnum) {
    }
}

export class SetIsAdminChat {
    static readonly type = '[Chat] SetIsAdminChat';

    constructor(public isAdmin: boolean) {
    }
}

export class ClearChatState {
    static readonly type = '[Chat] ClearChatState';

}

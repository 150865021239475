import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { LangEnum } from 'src/app/app-shared-elements/_enums/lang.enum';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { LuxonDatePipe } from 'src/app/app-shared-elements/_pipes/luxon-date.pipe';
import { InvitesState } from 'src/app/app-shared-elements/_store/states/invite.state';
import { LanguageState } from 'src/app/app-shared-elements/_store/states/language.state';
import { PermissionsState } from 'src/app/app-shared-elements/_store/states/permissions.state';
import { UserState } from 'src/app/app-shared-elements/_store/states/user.state';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { ChatMessageInterface } from '../_interfaces/Message';
import { ShareMessageInterface } from '../_interfaces/share-message.interface';
import { UserMessageInterface } from '../_interfaces/user-message.interface';
import { SystemMessageInterface } from '../_interfaces/system-message.interface';
import { ChannelTypeEnum } from '../_interfaces/Channel';
import { RolesService } from '../../admin/roles/_services/roles.service';
import { AdminsListState } from '../../admin/admins/_store/states/admins-list.state';
import { AdminInterface } from '../../admin/admins/_interface/admins-list.interface';
import { SenderUserInterface } from '../../mailing/_interfaces/sender-user.interface';
import { ShortDateFormatEnum } from '../../app-shared-elements/_enums/short-date-format.enum';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    constructor(
        private store: Store,
        private translateService: TranslateService,
        private luxonDatePipe: LuxonDatePipe,
    ) {}

    async parseMessages(message: ShareMessageInterface | UserMessageInterface | SystemMessageInterface, type: ChannelTypeEnum): Promise<ChatMessageInterface> {
        const currentText = await this.getCurrentText(message, type);
        const currentAdmin: AdminInterface = this.store.selectSnapshot(AdminsListState.getAdmins)?.find((item) => item.id === message.readAdminId);
        return {
            created: message.created,
            id: message.id,
            updated: this.messageInstanceOfShare(message) ? (message as ShareMessageInterface).updated : message.created,
            isMine: false,
            sender: this.messageInstanceOfShare(message) ? (message as ShareMessageInterface).inviter : this.getSenderForUserMessage(message as UserMessageInterface),
            message: currentText,
            roleId: this.messageInstanceOfShare(message) ? (message as ShareMessageInterface).role : null,
            registratorId: message.registratorId,
            isRead: this.messageInstanceOfShare(message) ? (message as ShareMessageInterface).isRead : !!(message as UserMessageInterface).read,
            statusInvite: this.messageInstanceOfShare(message) ? (message as ShareMessageInterface).statusInvite : null,
            channelType: type,
            readAdmin: message.readAdmin,
            readAdminName: currentAdmin?.name ?? currentAdmin?.login,
        };
    }

    private messageInstanceOfShare(m: any): m is ShareMessageInterface {
        return 'inviter' in m;
    }

    private async getCurrentText(message: ShareMessageInterface | UserMessageInterface | SystemMessageInterface | SenderUserInterface, type: ChannelTypeEnum): Promise<string> {
        switch (type) {
            case ChannelTypeEnum.DEVICE_MESSAGE:
                return await this.parseTextForUserMessage(message as UserMessageInterface);
            case ChannelTypeEnum.DEVICE_SHARING:
                return await this.parseTextForShare(message as ShareMessageInterface);
            case ChannelTypeEnum.SYSTEM:
                return await this.parseTextForSystemMessage(message as SystemMessageInterface);
            case ChannelTypeEnum.MALLING_SHARING:
                return await this.parseTextForMallingMessage(message as SenderUserInterface);
            case ChannelTypeEnum.MNEMONIC_SHARING:
                return await this.parseTextForMnemonic(message as ShareMessageInterface);
            default:
                return await this.parseTextForShare(message as ShareMessageInterface);
        }
    }

    private getSenderForUserMessage(m: UserMessageInterface): string {
        const registrators = this.store.selectSnapshot(DevicesState.getRegistrators);
        const currentRegistrator = registrators.find((r) => r.id === m.registratorId);
        return currentRegistrator ? currentRegistrator.name ?? currentRegistrator.defaultName : '';
    }

    private async parseTextForMallingMessage(m: SenderUserInterface): Promise<string> {
        return await this.translateService
            .get('notifications.mallingMessage', {
                user: m.name,
            })
            .toPromise()
            .catch((e) => console.log(e));
    }

    private async parseTextForSystemMessage(m: SystemMessageInterface): Promise<string> {
        const defaultRole = await this.translateService.get('permissions.default').toPromise();

        return await this.translateService
            .get('notifications.systemMessage', {
                role: m.payloadData.roleNameOld,
                default: m.payloadData.newRoleName === RolesService.defaultRole ? defaultRole : m.payloadData.newRoleName,
            })
            .toPromise()
            .catch((e) => console.log(e));
    }

    private async parseTextForUserMessage(m: UserMessageInterface): Promise<string> {
        return await this.translateService
            .get('notifications.deviceMessage', {
                title: m.title,
                message: m.text,
            })
            .toPromise()
            .catch((e) => console.log(e));
    }

    private async parseTextForMnemonic(m: ShareMessageInterface): Promise<string> {
        const user: User = this.store.selectSnapshot(UserState.getUser);
        const ln: LangEnum = this.store.selectSnapshot(LanguageState.getLanguage);
        const currentInvite = this.store.selectSnapshot(InvitesState.getInvites).find((f) => f.registratorId === m.registratorId);
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getRegistrators).find((r) => r.id === m.registratorId);
        const messageCreateDate = this.luxonDatePipe.transform(m.created, user.dateFormat, user.dateTimeZone, ln);
        return await this.translateService
            .get('notifications.mnemonicMessage', {
                created: messageCreateDate,
                user: m.inviter,
                registrator: currentInvite?.name ?? currentInvite?.defaultName ?? currentRegistrator?.name ?? currentRegistrator?.defaultName ?? '',
            })
            .toPromise()
            .catch((e) => console.log(e));
    }

    private async parseTextForShare(m: ShareMessageInterface): Promise<string> {
        const user: User = this.store.selectSnapshot(UserState.getUser);
        const ln: LangEnum = this.store.selectSnapshot(LanguageState.getLanguage);
        const currentInvite = this.store.selectSnapshot(InvitesState.getInvites).find((f) => f.registratorId === m.registratorId);
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getRegistrators).find((r) => r.id === m.registratorId);
        const currentRole = this.store.selectSnapshot(PermissionsState.getRoles).find((r) => r.id === m.role);
        const messageCreateDate = this.luxonDatePipe.transform(m?.created, user?.dateFormat ?? ShortDateFormatEnum.ddMMyyyy, user?.dateTimeZone, ln);
        const comment = await this.translateService.get('notifications.comment').toPromise();

        return await this.translateService
            .get('notifications.shareMessage', {
                created: messageCreateDate,
                user: m.inviter,
                message: m.message ? comment + m.message : m.message,
                registrator: currentInvite?.name ?? currentInvite?.defaultName ?? currentRegistrator?.name ?? currentRegistrator?.defaultName ?? '',
                role: currentRole ? currentRole.name : '',
            })
            .toPromise()
            .catch((e) => console.log(e));
    }
}

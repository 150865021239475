import { UpdateTrainInterface } from '../../train-dashboard/_interfaces/update-train.interface';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import {
    TransportLimitLogicEvent,
    TransportLogicEventInterface,
} from '../../transport-dashboard/_interfaces/transport-logic-event.interface';
import { TrailerTransportDtoInterface } from '../../train-dashboard/_interfaces/trailer-transport-dto.interface';
import { TrainArrayInterface } from '../../train-dashboard/_interfaces/train-array.interface';

export class GetTrain {
    static readonly type = '[Train] GetTrain';
}

export class DeleteTrain {
    static readonly type = '[Train] DeleteTrain';

    constructor(public id: string) {}
}

export class CreateTrain {
    static readonly type = '[Train] CreateTrain';

    constructor() {}
}

export class UpdateTrainArray {
    static readonly type = '[Train] UpdateTrainArray';

    constructor(
        public devices: Device[],
        public expeditions: TransportLogicEventInterface<TransportLimitLogicEvent>[],
    ) {}
}

export class UpdateTrain {
    static readonly type = '[Train] UpdateTrain';

    constructor() {}
}

export class InitTrainArray {
    static readonly type = '[Train] InitTrainArray';
}

export class InitTrailerOptions {
    static readonly type = '[Train] InitTrailerOptions';

    constructor(public currentTrain: TrainArrayInterface) {}
}

export class InitPartOptions {
    static readonly type = '[Train] InitPartOptions';

    constructor(
        public device: Device,
        public trailer: TrailerTransportDtoInterface,
    ) {}
}

export class UpdateTrainData {
    static readonly type = '[Train] UpdateTrainData';

    constructor(public data: UpdateTrainInterface) {}
}

export class GetUsedTrainId {
    static readonly type = '[Train] GetUsedTrainId';
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { ChannelInterface } from '../../_interfaces/Channel';
import {
    ClearChatState,
    GetChannels,
    GetChatNotifications,
    GetDialog,
    GetReadChannel,
    SetActiveChannel,
    SetChatNotifications,
    SetIsAdminChat,
} from '../actions/chat.actions';
import { CountChannelInterface } from '../../_interfaces/CountChannel';
import { UserMessageInterface } from '../../_interfaces/user-message.interface';
import { ShareMessageInterface } from '../../_interfaces/share-message.interface';
import { ChatMessageInterface } from '../../_interfaces/Message';
import { ChatService } from '../../_services/chat.service';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { GetDevices } from '../../../device-dashboard/_store/actions/user-devices.actions';
import { SystemMessageInterface } from '../../_interfaces/system-message.interface';

export interface ChatStateModel {
    channels: ChannelInterface[];
    activeChannel: ChannelInterface;
    dialog: ChatMessageInterface[];
    chatNotification: CountChannelInterface;
    isAdminChat: boolean;
}

const CHAT_TOKEN = new StateToken<ChatStateModel>('chatState');

@State<ChatStateModel>({
    name: CHAT_TOKEN,
    defaults: {
        channels: [],
        activeChannel: null,
        dialog: [],
        chatNotification: null,
        isAdminChat: false,
    },
})
@Injectable()
export class ChatState {
    constructor(private http: HttpClient, private chatService: ChatService, private store: Store) {}

    @Selector()
    static getChannels(state: ChatStateModel): ChannelInterface[] {
        return state.channels;
    }

    @Selector()
    static getActiveChannel(state: ChatStateModel): ChannelInterface {
        return state.activeChannel;
    }

    @Selector()
    static getDialog(state: ChatStateModel): ChatMessageInterface[] {
        return state.dialog;
    }

    @Selector()
    static getChatNotification(state: ChatStateModel): CountChannelInterface {
        return state.chatNotification;
    }

    @Selector()
    static getIsAdminChat(state: ChatStateModel): boolean {
        return state.isAdminChat;
    }

    @Action(GetChatNotifications)
    async getChatNotifications(ctx: StateContext<ChatStateModel>): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse<CountChannelInterface> = (await this.http
            .get(`api/${state.isAdminChat ? 'control/' : ''}chat`)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<CountChannelInterface>;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                chatNotification: result.data,
            });
        }
    }

    @Action(SetChatNotifications)
    async setChatNotifications(ctx: StateContext<ChatStateModel>, payload: SetChatNotifications): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            chatNotification: payload.data,
        });
    }

    @Action(GetReadChannel)
    async getReadChannel(ctx: StateContext<ChatStateModel>, payload: GetReadChannel): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post(`api/${state.isAdminChat ? 'control/' : ''}chat/set-read`, {
                channelId: payload.channelId,
                type: payload.type,
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                channels: state.channels.map((channel) => {
                    if (channel.channelId === payload.channelId) {
                        channel.isRead = true;
                    }
                    return channel;
                }),
            });
        }
    }

    @Action(GetChannels)
    async getChannels(ctx: StateContext<ChatStateModel>): Promise<void> {
        if (!this.store.selectSnapshot(DevicesState.getIsLoadDevices)) {
            ctx.dispatch(new GetDevices());
        }
        const state = ctx.getState();

        const result: ApiResponse<ChannelInterface[]> = (await this.http
            .get(`api/${state.isAdminChat ? 'control/' : ''}chat/channels`)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<ChannelInterface[]>;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                channels: result.data.sort((a, b) => +a.date - +b.date),
            });
        }
    }

    @Action(SetActiveChannel)
    setActiveChannel(ctx: StateContext<ChatStateModel>, payload: SetActiveChannel): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            activeChannel: payload.channel,
        });
    }

    @Action(GetDialog)
    async getDialog(ctx: StateContext<ChatStateModel>, payload: GetDialog): Promise<void> {
        const state = ctx.getState();

        if (!payload.channelId && !payload.type) {
            ctx.setState({
                ...state,
            });
            return;
        }

        const result: ApiResponse<ShareMessageInterface[] | UserMessageInterface[] | SystemMessageInterface[]> =
            (await this.http
                .get(`api/${state.isAdminChat ? 'control/' : ''}chat/messages`, {
                    params: {
                        channelId: payload.channelId || state.activeChannel.channelId,
                        type: payload.type || state.activeChannel.type,
                    },
                })
                .toPromise()
                .catch((e) => console.log(e))) as ApiResponse<
                ShareMessageInterface[] | UserMessageInterface[] | SystemMessageInterface[]
            >;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            let dialog = [];
            for (const m of result.data) {
                const newMessage = await this.chatService.parseMessages(m, payload.type);
                dialog = [...dialog, newMessage];
            }
            ctx.setState({
                ...state,
                dialog: dialog.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()),
            });
        } else {
            ctx.setState({
                ...state,
                dialog: [],
            });
        }
    }

    @Action(SetIsAdminChat)
    setIsAdminChat(ctx: StateContext<ChatStateModel>, payload: SetIsAdminChat): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isAdminChat: payload.isAdmin,
        });
    }

    @Action(ClearChatState)
    clear(ctx: StateContext<ChatStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            channels: [],
            activeChannel: null,
            dialog: [],
            isAdminChat: false,
        });
    }
}

import {MailingTransportRowLogInterface, NotificationTransportType} from '../../../../mailing/_interfaces/notification-log.interface';
import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ColumnsTableInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';

@Component({
    selector: 'app-column-transport',
    templateUrl: './column-transport.component.html',
    styleUrls: ['./column-transport.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnTransportComponent implements OnInit {

    private _cell: ColumnsTableInterface;
    private _row: any;
    // private _value: MailingTransportRowLogInterface;

    @Input() value: MailingTransportRowLogInterface;
    // set value(value) {
    //     this._value = value;
    // }

    // get value(): MailingTransportRowLogInterface {
    //     return this._value;
    // }


    @Input()
    set cell(cell: ColumnsTableInterface) {
        this._cell = cell;
    }

    get cell(): ColumnsTableInterface {
        return this._cell;
    }

    @Input()
    set row(row) {
        this._row = row;
    }

    get row(): any {
        return this._row;
    }

    notificationTransportType = NotificationTransportType;

    readonly VIBER_ICON_PATH = './assets/design/icons/table-operations/viber.svg';
    readonly TELEGRAM_ICON_PATH = './assets/design/icons/table-operations/telegram.svg';
    readonly SMS_ICON_PATH = './assets/design/icons/table-operations/sms.svg';
    readonly EMAIL_ICON_PATH = './assets/design/icons/table-operations/email.svg';

    constructor() {
    }

    ngOnInit(): void {
    }

}

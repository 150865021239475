export enum BillingAccountInfoTypeEnum {
    payer = 'payer',
    name = 'name',
    email = 'email',
    phone = 'phone',
    address = 'address',
    country = 'country',
    region = 'region',
    city = 'city',
    postcode = 'postcode',
    edrpou = 'edrpou',
    paymentAccount = 'paymentAccount',
    isVat = 'isVat',
    vat = 'vat',
    isDiscount = 'isDiscount',
    discount = 'discount',
    director = 'director',
    signature = 'signature',
    currency = 'currency',
}

export enum BillingPayerOptionsTypeEnum {
    INDIVIDUAL = 0,
    LEGAL_ENTITY = 1,
}

export enum BillingPaymentMethodEnum {
    cash = 'cash',
}

import { NotificationEventResponseInterface } from '../../_interfaces/mailing-groups.interfaces';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { MailingGroupEditService } from '../../_services/mailing-group-edit.service';
import {
    ChangeActiveUsersInGroupEdit,
    ChangeCurrentBlockInEditGroup,
    ClearPopupList,
    DeleteDeviceInGroupEdit,
    DeleteEventsInGroupEdit,
    DeleteUserInGroupEdit,
    GetMailingGroupEditDevices,
    GetMailingGroupEditEvents,
    GetMailingGroupEditUsers,
    InitDevicesPopupRows,
    InitEventsPopupRows,
    InitMailingGroupEditDevicesRows,
    InitMailingGroupEditingEventsRows,
    InitMailingGroupEditingUsersRows,
    InitPopupListForAddItem,
    InitUsersPopupRows,
    MailingGroupEditingChangeDevicesTable,
    MailingGroupEditingChangeEventsTable,
    MailingGroupEditingChangeUsersTable,
    MailingGroupEditingUpdateDevice,
    MailingGroupEditingUpdateEvent,
    SetIsMailingGroupEdit,
} from '../actions/mailing-group-edit.actions';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { MailingGroupsEditBlockEnum } from '../../_enums/mailing-groups-edit-block.enum';
import {
    MailingAddDeviceItemInterface,
    MailingAddEventItemInterface,
    MailingAddUserItemInterface,
    MailingGroupsDeviceRowInterface,
    MailingGroupsEventRowInterface,
    MailingGroupsUserRowInterface,
    NotificationDevice,
    NotificationEvent,
} from '../../_interfaces/mailing-groups-edit.interface';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { LogicEvent, LogicEventType } from '../../../events/logical-events/_interface/LogicEvent';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { NotificationUserResponseInterface } from '../../_interfaces/mailing-recipient-row.interface';
import { MailingGroupReportState } from './mailing-group-report.state';
import { GetLogicalEvents } from 'src/app/events/_store/actions/logical-events.actions';
import { LogicalEventsState } from 'src/app/events/_store/states/logical-events.state';

export interface MailingGroupEditStateModel {
    groupEditUsers: NotificationUserResponseInterface;
    groupEditUsersRows: MailingGroupsUserRowInterface[];
    groupEditDevices: NotificationDevice[];
    groupEditDevicesRows: MailingGroupsDeviceRowInterface[];
    groupEditEvents: NotificationEventResponseInterface;
    groupEditEventsRows: MailingGroupsEventRowInterface[];
    groupEditPopupList: MailingAddUserItemInterface[] | MailingAddDeviceItemInterface[] | MailingAddEventItemInterface[];
    currentBlockInEditingGroup: MailingGroupsEditBlockEnum;
    isMailingGroupEdit: boolean;
    groupEditUsersPopupRows: MailingAddUserItemInterface[];
    groupEditDevicesPopupRows: MailingAddDeviceItemInterface[];
    groupEditEventsPopupRows: MailingAddEventItemInterface[];
}

const MAILING_GROUP_EDIT_TOKEN = new StateToken<MailingGroupEditStateModel>('mailingGroupEdit');

@State({
    name: MAILING_GROUP_EDIT_TOKEN,
    defaults: {
        groupEditUsers: null,
        groupEditUsersRows: [],
        groupEditDevices: [],
        groupEditDevicesRows: [],
        groupEditEvents: null,
        groupEditEventsRows: [],
        groupEditPopupList: [],
        currentBlockInEditingGroup: null,
        isMailingGroupEdit: false,
        groupEditUsersPopupRows: [],
        groupEditDevicesPopupRows: [],
        groupEditEventsPopupRows: [],
    },
})
@Injectable()
export class MailingGroupEditState {
    constructor(
        private http: HttpClient,
        private mailingGroupEditService: MailingGroupEditService,
        private notificationsService: NotificationsService,
        private store: Store,
    ) {}

    @Selector()
    static getGroupEditUsersRows(state: MailingGroupEditStateModel): MailingGroupsUserRowInterface[] {
        return state.groupEditUsersRows.sort((a, b) => (a.id > b.id ? 1 : -1));
    }

    @Selector()
    static getGroupEditDevicesRows(state: MailingGroupEditStateModel): MailingGroupsDeviceRowInterface[] {
        return state.groupEditDevicesRows.sort((a, b) => (a.id > b.id ? 1 : -1));
    }

    @Selector()
    static getGroupEditMessagesRows(state: MailingGroupEditStateModel): MailingGroupsEventRowInterface[] {
        return state.groupEditEventsRows.sort((a, b) => (a.registrator > b.registrator ? 1 : -1));
    }

    @Selector()
    static getGroupEditPopupList(state: MailingGroupEditStateModel): MailingAddUserItemInterface[] | MailingAddDeviceItemInterface[] | MailingAddEventItemInterface[] {
        return state.groupEditPopupList;
    }

    @Selector()
    static getCurrentBlockInEditingGroup(state: MailingGroupEditStateModel): MailingGroupsEditBlockEnum {
        return state.currentBlockInEditingGroup;
    }

    @Selector()
    static getIsMailingGroupEdit(state: MailingGroupEditStateModel): boolean {
        return state.isMailingGroupEdit;
    }

    @Selector()
    static getGroupEditEvents(state: MailingGroupEditStateModel): any {
        return state.groupEditEvents;
    }

    @Action(GetMailingGroupEditUsers)
    async getMailingGroupEditingUsers(ctx: StateContext<MailingGroupEditStateModel>, payload: GetMailingGroupEditUsers): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse<NotificationUserResponseInterface> = (await this.http
            .get(`api/notification-user/group?groupId=${payload.groupId}`)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<NotificationUserResponseInterface>;
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                groupEditUsers: result.data,
            });

            ctx.dispatch(new InitMailingGroupEditingUsersRows());
        }
    }

    @Action(GetMailingGroupEditDevices)
    async getMailingGroupEditDevices(ctx: StateContext<MailingGroupEditStateModel>, payload: GetMailingGroupEditDevices): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .get(`api/notification-device?groupId=${payload.groupId}`)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                groupEditDevices: result.data,
            });

            ctx.dispatch(new InitMailingGroupEditDevicesRows());
        } else {
            ctx.setState({
                ...state,
                groupEditDevices: [],
            });
        }
    }

    @Action(GetMailingGroupEditEvents)
    async getMailingGroupEditEvents(ctx: StateContext<MailingGroupEditStateModel>, payload: GetMailingGroupEditEvents): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse<NotificationEventResponseInterface> = (await this.http
            .get(`api/notification-event?groupId=${payload.groupId}`)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<NotificationEventResponseInterface>;
        const registrators = this.store.selectSnapshot(DevicesState.getRegistrators);
        await ctx.dispatch(new GetLogicalEvents(registrators.map((r) => r.id))).toPromise();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                groupEditEvents: result.data,
            });

            ctx.dispatch(new InitMailingGroupEditingEventsRows());
        } else {
            ctx.setState({
                ...state,
                groupEditEvents: null,
            });
        }
    }

    @Action(InitMailingGroupEditingUsersRows)
    async initMailingGroupEditingUsersRows(ctx: StateContext<MailingGroupEditStateModel>): Promise<void> {
        const state = ctx.getState();
        const users: NotificationUserResponseInterface = state.groupEditUsers;

        ctx.setState({
            ...state,
            groupEditUsersRows: users.usersIsInGroup.map((user) => {
                return {
                    ...user,
                    id: user.id,
                    isActive: user.isActive,
                    users: user.notificationUser.email,
                    transports: user.notificationUser.transports, // this.mailingGroupEditService.transportsToString(user.notificationUser.transports),
                    tooltipValue: {
                        users: user.notificationUser.email,
                    },
                };
            }),
        });
    }

    @Action(InitMailingGroupEditDevicesRows)
    async initMailingGroupEditingDevicesRows(ctx: StateContext<MailingGroupEditStateModel>): Promise<void> {
        const state = ctx.getState();

        const registrators: Device[] = this.store.selectSnapshot(DevicesState.getOwnerRegistrators);
        ctx.setState({
            ...state,
            groupEditDevicesRows: state.groupEditDevices.map((device) => {
                const currentDevice = registrators.find((f) => f.id === device.deviceId);
                return {
                    isStatusEvent: device.isStatusEvent,
                    id: device.id,
                    registrator: currentDevice.name ?? currentDevice.defaultName,
                    checkboxProperties: this.mailingGroupEditService.getCheckboxProperties(this.mailingGroupEditService.tableDevicesColumns),
                };
            }),
        });
    }

    @Action(InitMailingGroupEditingEventsRows)
    async initMailingGroupEditingMessagesRows(ctx: StateContext<MailingGroupEditStateModel>): Promise<void> {
        const state = ctx.getState();

        const events: NotificationEventResponseInterface = state.groupEditEvents;
        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        const registrators: Device[] = this.store.selectSnapshot(DevicesState.getOwnerRegistrators);

        const logicalEvents = this.store.selectSnapshot(LogicalEventsState.getLogicalEvents);
        ctx.setState({
            ...state,
            groupEditEventsRows: events.eventsIsInGroup.map((event) => {
                const currentLogicEvent: LogicEvent = logicalEvents.find((e) => e.id === event.logicEventId);
                const currentDevice = devices.find((d) => d.variables.find((v) => v.id === currentLogicEvent.resultVariableId));
                const currentVariable = currentDevice.variables.find((v) => v.id === currentLogicEvent.resultVariableId);
                const currentRegistrator = registrators.find((f) => f.id === currentLogicEvent.registratorId);

                return {
                    isActive: event.isActive,
                    id: event.id,
                    registrator: currentRegistrator.name ?? currentRegistrator.defaultName,
                    create: currentLogicEvent.created,
                    event: currentVariable?.customName ?? currentVariable?.name ?? currentLogicEvent?.name ?? '',
                    type: this.mailingGroupEditService.getCurrentTypeEventString(currentLogicEvent.type),
                    eventId: currentLogicEvent.id,
                    logicEventType: currentLogicEvent.logicEventType,
                    preIcons: this.mailingGroupEditService.getPreIcons(currentLogicEvent),
                };
            }),
        });
    }

    @Action(MailingGroupEditingUpdateDevice)
    async mailingGroupEditingUpdateDevice(ctx: StateContext<MailingGroupEditStateModel>, payload: MailingGroupEditingUpdateDevice): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .put('api/notification-device', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                groupEditDevicesRows: state.groupEditDevicesRows.map((row) => {
                    if (row.id === result.data.id) {
                        row.isStatusEvent = result.data.isStatusEvent;
                    }

                    return row;
                }),
            });
        } else {
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(MailingGroupEditingUpdateEvent)
    async mailingGroupEditingUpdateEvent(ctx: StateContext<MailingGroupEditStateModel>, payload: MailingGroupEditingUpdateEvent): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse<NotificationEvent> = (await this.http
            .put('api/notification-event', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<NotificationEvent>;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
                groupEditEvents: {
                    ...state.groupEditEvents,
                    eventsIsInGroup: state.groupEditEvents.eventsIsInGroup.map((e) => (e.id === result.data.id ? result.data : e)),
                },
            });
        } else {
            ctx.setState({
                ...state,
            });
        }

        ctx.dispatch(new InitMailingGroupEditingEventsRows());
    }

    @Action(DeleteDeviceInGroupEdit)
    async mailingGroupEditingDeleteDevice(ctx: StateContext<MailingGroupEditStateModel>, payload: DeleteDeviceInGroupEdit): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .delete('api/notification-device', { params: { id: payload.id } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            await ctx.dispatch(new ChangeCurrentBlockInEditGroup(null)).toPromise();
            ctx.setState({
                ...state,
                groupEditDevicesRows: state.groupEditDevicesRows.filter((row) => payload.id !== row.id),
            });

            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        }
    }

    @Action(DeleteUserInGroupEdit)
    async deleteUserInGroupEdit(ctx: StateContext<MailingGroupEditStateModel>, payload: DeleteUserInGroupEdit): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .delete('api/notification-user/group', { params: { id: payload.id } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            await ctx.dispatch(new ChangeCurrentBlockInEditGroup(null)).toPromise();
            ctx.setState({
                ...state,
                groupEditUsersRows: state.groupEditUsersRows.filter((row) => payload.id !== row.id),
            });
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        }
    }

    @Action(ChangeActiveUsersInGroupEdit)
    async changeActiveUsersInGroupEdit(ctx: StateContext<MailingGroupEditStateModel>, payload: ChangeActiveUsersInGroupEdit): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .put('api/notification-user/group', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
                groupEditUsersRows: state.groupEditUsersRows.map((userRow) => {
                    const currentItem = result.data.find((item) => item.id === userRow.id);
                    if (currentItem) {
                        userRow.isActive = currentItem.isActive;
                    }

                    return userRow;
                }),
            });
        }
    }

    @Action(DeleteEventsInGroupEdit)
    async deleteEventsInGroupEdit(ctx: StateContext<MailingGroupEditStateModel>, payload: DeleteEventsInGroupEdit): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .delete('api/notification-event', { params: { id: payload.id } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            await ctx.dispatch(new ChangeCurrentBlockInEditGroup(null)).toPromise();
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                groupEditEventsRows: state.groupEditEventsRows.filter((eventRow) => eventRow.id !== payload.id),
            });

            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        }
    }

    @Action(InitPopupListForAddItem)
    async getUpdatingGroupUsers(ctx: StateContext<MailingGroupEditStateModel>, payload: InitPopupListForAddItem): Promise<void> {
        const state = ctx.getState();

        let groupEditPopupList: MailingAddUserItemInterface[] | MailingAddDeviceItemInterface[] | MailingAddEventItemInterface[];

        switch (payload.type) {
            case MailingGroupsEditBlockEnum.users:
                groupEditPopupList = state.groupEditUsersPopupRows;
                break;
            case MailingGroupsEditBlockEnum.devices:
                groupEditPopupList = state.groupEditDevicesPopupRows;
                break;
            case MailingGroupsEditBlockEnum.events:
                groupEditPopupList = state.groupEditEventsPopupRows.sort((a, b) => (a.deviceId > b.deviceId ? 1 : -1));
                break;
            case MailingGroupsEditBlockEnum.reports:
                const groupEditReportsPopupRows = this.store.selectSnapshot(MailingGroupReportState.getGroupEditReportsPopupRows);
                groupEditPopupList = groupEditReportsPopupRows;
                break;
        }

        groupEditPopupList = groupEditPopupList.sort((a, b) => (a.id > b.id ? 1 : -1));

        ctx.setState({
            ...state,
            groupEditPopupList,
        });
    }

    @Action(ClearPopupList)
    clearPopupList(ctx: StateContext<MailingGroupEditStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            groupEditPopupList: [],
        });
    }

    @Action(MailingGroupEditingChangeUsersTable)
    async mailingGroupEditingChangeUsersTable(ctx: StateContext<MailingGroupEditStateModel>, payload: MailingGroupEditingChangeUsersTable): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post('api/notification-user/group', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        }

        ctx.setState({
            ...state,
        });
    }

    @Action(MailingGroupEditingChangeDevicesTable)
    async mailingGroupEditingChangeDevicesTable(ctx: StateContext<MailingGroupEditStateModel>, payload: MailingGroupEditingChangeDevicesTable): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .post('api/notification-device', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(MailingGroupEditingChangeEventsTable)
    async mailingGroupEditingChangeMessagesTable(ctx: StateContext<MailingGroupEditStateModel>, payload: MailingGroupEditingChangeEventsTable): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post('api/notification-event', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(ChangeCurrentBlockInEditGroup)
    getCurrentBlockInEditingGroup(ctx: StateContext<MailingGroupEditStateModel>, payload: ChangeCurrentBlockInEditGroup): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentBlockInEditingGroup: payload.currentBlock,
        });
    }

    @Action(SetIsMailingGroupEdit)
    setIsMailingGroupEdit(ctx: StateContext<MailingGroupEditStateModel>, payload: SetIsMailingGroupEdit): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isMailingGroupEdit: payload.value,
        });
    }

    @Action(InitUsersPopupRows)
    initUsersPopupRows(ctx: StateContext<MailingGroupEditStateModel>): void {
        const state = ctx.getState();
        const users: NotificationUserResponseInterface = state.groupEditUsers;

        ctx.setState({
            ...state,
            groupEditUsersPopupRows: users.usersInNotGroup
                .map((user) => {
                    return {
                        name: user.name,
                        email: user.email,
                        checked: false,
                        id: user.id,
                        type: MailingGroupsEditBlockEnum.users,
                    };
                })
                .filter((r) => !!r),
        });
    }

    @Action(InitDevicesPopupRows)
    async initDevicesPopupRows(ctx: StateContext<MailingGroupEditStateModel>): Promise<void> {
        const state = ctx.getState();
        const registrators: Device[] = this.store.selectSnapshot(DevicesState.getOwnerRegistrators);

        ctx.setState({
            ...state,
            groupEditDevicesPopupRows: registrators
                .map((registrator) => {
                    const currentGroupDevice = state.groupEditDevices.find((d) => registrator.id === d.deviceId);
                    if (!currentGroupDevice) {
                        return {
                            name: registrator.name ?? registrator.defaultName,
                            checked: false,
                            id: registrator.id,
                            creationType: registrator.creationType,
                            type: MailingGroupsEditBlockEnum.devices,
                        };
                    }
                })
                .filter((r) => !!r),
        });
    }

    @Action(InitEventsPopupRows)
    async initEventsPopupRows(ctx: StateContext<MailingGroupEditStateModel>): Promise<void> {
        const state = ctx.getState();
        const events: any = state.groupEditEvents;
        const eventsIsInGroup = [...events.eventsIsInGroup];
        const devices = this.store.selectSnapshot(DevicesState.getDevices);

        const rows: MailingAddEventItemInterface[] = events.eventsIsNotGroup
            .filter((e) => e.logicEventType === LogicEventType.default)
            .map((event: LogicEvent) => {
                const currentGroupEvent = eventsIsInGroup.find((e) => e.logicEvent.id === event.id);
                if (!currentGroupEvent) {
                    return {
                        name: event.name ?? '',
                        checked: false,
                        id: event.id,
                        type: MailingGroupsEditBlockEnum.events,
                        logicEventType: event.logicEventType,
                    };
                }
            });
        const resultVariableIdsForByMultiEvents = Array.from(new Set(events.eventsIsNotGroup.filter((e) => e.logicEventType !== LogicEventType.default).map((event) => event.resultVariableId)));
        const multi: MailingAddEventItemInterface[] = resultVariableIdsForByMultiEvents.map((id) => {
            const currentMultiEvent = events.eventsIsNotGroup.filter((e) => e.resultVariableId === id && e.logicEventType !== LogicEventType.default);
            const currentDevice = devices.find((d) => d.variables.find((v) => v.id === id));
            const currentVariable = currentDevice.variables.find((v) => v.id === currentMultiEvent[0].resultVariableId);
            const currentGroupEvent = eventsIsInGroup.find((e) => e.logicEvent.id === id);
            if (!currentGroupEvent) {
                return {
                    name: (currentVariable?.customName ?? currentVariable?.name ?? currentMultiEvent[0].name ?? '') + ' - ' + (currentDevice?.name ?? currentDevice?.defaultName),
                    checked: false,
                    deviceId: currentDevice.id,
                    id: '',
                    type: MailingGroupsEditBlockEnum.events,
                    logicEventType: currentMultiEvent[0].logicEventType,
                    multiEventRows: this.mailingGroupEditService.getMultiEvent(currentMultiEvent, currentVariable),
                };
            }
        });

        ctx.setState({
            ...state,
            groupEditEventsPopupRows: [...rows, ...multi],
        });
    }
}

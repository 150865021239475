<div class="mailing-log">
    <app-heading [title]="'navigation.mailingLogs'"></app-heading>
    <div class="mailing-log__wrapper">
        <div class="mailing-log__filters">
            <app-filters [type]="filterItemTypeEnum.event"
                [filter]="(params$ | async).filter"
                (filterEvent)="changeFilter($event)"></app-filters>
        </div>
        <div class="mailing-log__tables">
            <app-tables [currentTableColumns]="mailingService.messagesTableColumns"
                [rows]="logRows$ | async"
                [configPagination]="(params$ | async).pagination"
                (paginationOutput)="onChangePage($event)"
                (sortOutput)="changeSort($event)">
            </app-tables>
        </div>
    </div>
</div>

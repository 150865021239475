import {Params} from 'src/app/app-shared-elements/_interfaces/params.interface';

export class GetExpeditions {
    static readonly type = '[Expeditions] GetExpeditions';

    constructor(public isAllExpedition?: boolean) {
    }
}

export class InitExpeditionsRows {
    static readonly type = '[Expeditions] InitExpeditionsRows';
}


export class ClearExpeditions {
    static readonly type = '[Expeditions] ClearExpeditions';
}

export class SetExpeditionParams {
    static readonly type = '[Expeditions] SetExpeditionParams';
    constructor(public params: Params) {

    }
}

export class PrintExpedition {
    static readonly type = '[Expeditions] PrintExpedition';
    constructor(public expeditionId: string) {

    }
}

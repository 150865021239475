import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { DevicesState } from '../../device-dashboard/_store/states/user-devices.state';
import { UserState } from '../_store/states/user.state';

@Injectable({
    providedIn: 'root',
})
export class EmptyProfileGuard {
    constructor(
        private store: Store,
        private router: Router,
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        const user = this.store.selectSnapshot(UserState.getUser);

        if (
            this.store.selectSnapshot(DevicesState.getIsLoadDevices) &&
            !devices.length &&
            user &&
            !user.available_devices &&
            !user.available_registrators
        ) {
            await this.router.navigate(['/404']);
            return false;
        } else {
            return true;
        }
    }
}

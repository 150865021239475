import { ComponentRef, Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { ArchivePerloaderComponent } from '../archive-perloader/archive-perloader.component';
import { PreloaderComponent } from '../preloader/preloader.component';
import { PreloaderWrappersEnum } from '../_enums/preloader-wrappers.enum';
import { Variable } from '../_interfaces/Variable';
import { ComponentsGeneratorService } from './components-generator.service';

@Injectable({
    providedIn: 'root',
})
export class PreloaderService {
    isPreloader: boolean;

    private preloader: ComponentRef<PreloaderComponent>;
    private archivePreloader: ComponentRef<ArchivePerloaderComponent>;

    constructor(private componentsGeneratorService: ComponentsGeneratorService) {}

    async initPreloader(wrapperClass: PreloaderWrappersEnum): Promise<void> {
        if (this.isPreloader) {
            return;
        }

        this.isPreloader = true;

        try {
            const component = await this.componentsGeneratorService.create(PreloaderComponent, wrapperClass || PreloaderWrappersEnum.content);
            this.preloader = component as ComponentRef<PreloaderComponent>;

            if (!this.preloader) {
                return;
            }

            this.preloader.instance.isMain = true;

            switch (wrapperClass) {
                case PreloaderWrappersEnum.tableBody:
                case PreloaderWrappersEnum.charts:
                    this.preloader.instance.isBlur = true;
                    break;
                case PreloaderWrappersEnum.content:
                case PreloaderWrappersEnum.popup:
                    this.preloader.instance.fullSize = true;
                    break;
            }

            return;
        } catch (e) {
            console.log(e);
            this.isPreloader = false;
        }
    }

    async initArchivePreloader(variables: Variable[]): Promise<void> {
        if (this.isPreloader || variables.length === 0) {
            return;
        }

        this.isPreloader = true;

        try {
            const component = await this.componentsGeneratorService.create(ArchivePerloaderComponent, PreloaderWrappersEnum.app);
            this.archivePreloader = component as ComponentRef<ArchivePerloaderComponent>;
            this.archivePreloader.instance.count = variables.length;

            // this.preloader.instance.isMain = true;
            this.archivePreloader.instance.destroyEvent.pipe(first()).subscribe(() => {
                if (this.archivePreloader) {
                    this.archivePreloader.destroy();
                }
            });

            return;
        } catch (e) {
            this.isPreloader = false;
            console.log(e);
        }
    }

    async destroyArchivePreloader(): Promise<void> {
        if (this.archivePreloader) {
            await this.archivePreloader.destroy();
            this.archivePreloader = null;

            this.isPreloader = false;
        }

        this.isPreloader = false;
    }

    async destroyPreloader(): Promise<void> {
        if (this.isPreloader && this.preloader) {
            await this.preloader.destroy();
            this.preloader = null;

            this.isPreloader = false;
        }

        this.isPreloader = false;
    }
}

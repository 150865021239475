import { ActiveEvents } from 'src/app/events/_interfaces/ActiveEvents';
import { UpdateLogicEventSocketInterface } from '../../../events/_interfaces/update-logic-event-socket.interface';

export class GetActiveEventsFromSocket {
    static readonly type = '[ActiveEvents] GetActiveEventsFromSocket';

    constructor(public activeEvents: ActiveEvents[]) {}
}

export class SetActiveEventsFromAdmin {
    static readonly type = '[ActiveEvents] SetActiveEventsFromAdmin';

    constructor(public activeEvents: ActiveEvents[]) {}
}

export class GetLogicEventsStatuses {
    static readonly type = '[ActiveEvents] GetLogicEventsStatuses';
    constructor(public currentEvent?: UpdateLogicEventSocketInterface) {}
}

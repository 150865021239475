import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { RoleInterface, RoleOptionsInterface } from 'src/app/app-shared-elements/_interfaces/role.interface';
import { ShareDeviceService } from 'src/app/device-dashboard/_services/share-device.service';
import { HTTP_STATUS } from '../../_enums/status.enum';
import {
    CheckPermission,
    GetRolePermissionsForAdmin,
    GetRolePermissionsForUser,
    InitRoleOptions,
    UpdateRoleForUser,
} from '../actions/permissions.actions';
import { ApiResponse } from '../../_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { SocketService } from '../../_services/socket.service';
import { TranslateService } from '@ngx-translate/core';
import { RolesService } from '../../../admin/roles/_services/roles.service';
import { ActionRoleUpdate } from '../../../admin/roles/_interfaces/update-socket-role.interface';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { PermissionService } from '../../_services/permission.service';

export interface PermissionsStateModel {
    roles: RoleInterface[];
    isLoadRoles: boolean;
    roleOptions: RoleOptionsInterface[];
    isCheckPermission: boolean;
    isSocketUpdateRoles: boolean;
}

const PERMISSIONS_TOKEN = new StateToken<PermissionsStateModel>('permissionsState');

@State<PermissionsStateModel>({
    name: PERMISSIONS_TOKEN,
    defaults: {
        roles: [],
        isLoadRoles: false,
        roleOptions: [],
        isCheckPermission: true,
        isSocketUpdateRoles: false,
    },
})
@Injectable()
export class PermissionsState {
    constructor(
        private sharedDeviceService: ShareDeviceService,
        private socketService: SocketService,
        private http: HttpClient,
        private translateService: TranslateService,
        private store: Store,
        private permissionService: PermissionService,
    ) {}

    @Selector()
    static getRoles(state: PermissionsStateModel): RoleInterface[] {
        return state.roles;
    }

    @Selector()
    static getIsSocketUpdateRoles(state: PermissionsStateModel): boolean {
        return state.isSocketUpdateRoles;
    }

    @Selector()
    static getIsLoadRoles(state: PermissionsStateModel): boolean {
        return state.isLoadRoles;
    }

    @Selector()
    static getRolesOptions(state: PermissionsStateModel): RoleOptionsInterface[] {
        return state.roleOptions;
    }

    @Selector()
    static getIsCheckPermission(state: PermissionsStateModel): boolean {
        return state.isCheckPermission;
    }

    @Action(GetRolePermissionsForUser)
    async getRoles(ctx: StateContext<PermissionsStateModel>): Promise<void> {
        const state = ctx.getState();
        const result = (await this.http
            .get('/api/device-role')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        this.socketService.incrementGlobalPreloaderLoaded('loadRoles');
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            const defaultRole = await this.translateService.get('permissions.default').toPromise();

            ctx.setState({
                ...state,
                roles: result.data.map((role) => {
                    return {
                        ...role,
                        name: role.name === RolesService.defaultRole ? defaultRole : role.name,
                    };
                }),
                isLoadRoles: true,
            });

            ctx.dispatch(new InitRoleOptions());
        } else {
            ctx.setState({
                ...state,
                isLoadRoles: false,
            });
        }
    }

    @Action(GetRolePermissionsForAdmin)
    async getRolesForAdmin(ctx: StateContext<PermissionsStateModel>): Promise<void> {
        const state = ctx.getState();
        const result = (await this.http
            .get('/api/control/admin-role')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        this.socketService.incrementGlobalPreloaderLoaded('loadRoles');
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                roles: result.data,
                isLoadRoles: true,
            });

            ctx.dispatch(new InitRoleOptions());
        } else {
            ctx.setState({
                ...state,
                isLoadRoles: false,
            });
        }
    }

    @Action(UpdateRoleForUser)
    updateRoleForUser(ctx: StateContext<PermissionsStateModel>, action: UpdateRoleForUser): void {
        const state = ctx.getState();
        const currentRoles = action.roles.roles.find((role) => state.roles.find((r) => r.id === role.id));

        if (action.roles.action === ActionRoleUpdate.DELETE) {
            ctx.setState({
                ...state,
                roles: state.roles.filter((r) => r.id !== currentRoles.id),
                isSocketUpdateRoles: true,
            });

            return;
        }

        if (action.roles.action === ActionRoleUpdate.ALL) {
            ctx.setState({
                ...state,
                roles: [...action.roles.roles],
                isSocketUpdateRoles: true,
            });

            return;
        }

        ctx.setState({
            ...state,
            roles: state.roles.map((role) => {
                if (role.id === currentRoles.id) {
                    role = currentRoles;
                }

                return role;
            }),
            isSocketUpdateRoles: true,
        });
    }

    @Action(InitRoleOptions)
    async initRoleOptions(ctx: StateContext<PermissionsStateModel>): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            roleOptions: state.roles
                .map((role) => {
                    return {
                        key: role.id,
                        property: role,
                        type: 'text',
                        value: role.name,
                    };
                })
                .filter((r) => !!r),
        });
    }

    @Action(CheckPermission)
    checkPermission(ctx: StateContext<PermissionsStateModel>, payload: CheckPermission): void {
        const state = ctx.getState();

        const roles = state.roles;
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);
        if (!currentRegistrator.roleId) {
            ctx.setState({
                ...state,
                isCheckPermission: true,
            });
            return;
        }

        const currentRole = roles.find((role) => role.id === currentRegistrator.roleId);

        ctx.setState({
            ...state,
            isCheckPermission: this.permissionService.checkPermission(currentRole?.permissions, payload.data),
        });
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TooltipStatusEnum } from '../../_enums/tooltip-status.enum';
import { NoteInfoInterface } from '../../_interfaces/note-info.interface';
import { Router } from '@angular/router';

@Component({
    selector: 'app-note-notifications',
    templateUrl: './note-notifications.component.html',
    styleUrls: ['./note-notifications.component.scss'],
})
export class NoteNotificationsComponent implements OnInit {
    @Input() noteInfo: NoteInfoInterface;

    @Output() closeEvent = new EventEmitter<void>();

    tooltipStatusEnum = TooltipStatusEnum;

    readonly CLOSE_ICON_PATH = './assets/design/icons/close.svg';
    readonly NOTE_ICON_PATH = './assets/design/icons/note.svg';

    constructor(public router: Router) {}

    ngOnInit(): void {}

    close(): void {
        this.closeEvent.emit();
    }
}

import { Injectable } from '@angular/core';
import { ColumnsTableInterface, ColumnTypeEnum } from '../../app-shared-elements/_interfaces/ColumnsTable';
import { BillingTransactionsInterface, TypeTransactionEnum } from '../_interfaces/billing-transactions.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class BillingTransactionsService {
    transactionsTableColumns: ColumnsTableInterface[] = [
        {
            title: 'billing.transactions.table.created',
            grow: false,
            small: false,
            maxWidth: '260px',
            minWidth: '200px',
            type: ColumnTypeEnum.date,
            sort: true,
            name: 'created',
            sortField: 'created',
        },
        {
            title: 'billing.transactions.table.amount',
            grow: false,
            small: false,
            type: ColumnTypeEnum.text,
            sort: 0,
            styles: true,
            name: 'amountMoney',
            sortField: 'amountMoney',
        },
        {
            title: 'billing.transactions.table.balance',
            grow: false,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'totalMoney',
        },
        {
            title: 'billing.transactions.table.details',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'details',
        },
        {
            title: 'billing.transactions.table.description',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'description',
        },
    ];

    constructor(private translateService: TranslateService) {}

    getAmountMoneyColor(transactions: BillingTransactionsInterface): { fontWeight: number; color: string } {
        if (+transactions.amountMoney > 0) {
            return {
                fontWeight: 600,
                color: 'var(--deviceGreen)',
            };
        } else {
            return {
                fontWeight: 600,
                color: 'var(--deviceRed)',
            };
        }
    }

    async getDetailsMessage(transactions: BillingTransactionsInterface): Promise<string> {
        switch (transactions.typeTransaction) {
            case TypeTransactionEnum.CASHLESS:
                return await this.translateService.get('billing.transactions.table.cashless').toPromise();
            case TypeTransactionEnum.CORRECTION:
                return await this.translateService.get('billing.transactions.table.correct').toPromise();
            case TypeTransactionEnum.INVOICE_PAYMENT:
                return await this.translateService
                    .get('billing.transactions.table.payment', {
                        nNumber: transactions.payload.invoiceNumber,
                        date: transactions.payload.dateInvoice,
                    })
                    .toPromise();
        }
    }
}

export enum TypeFilterEnum {
    text = 'text',
    datetime = 'datetime',
    boolean = 'boolean',
    enum = 'enum',
    default = '',
    select = 'select'
}

// export enum ParamsTimeTypeEnum {
//     time = 'time',
//     allTime = 'all-time',
//     range = 'range',
//     current = 'current'
// }

export enum FromToTypeCalendar {
    from = 'from',
    to = 'to'
}


export enum SERVER_TYPE{
    CLIENT = 'client',
    DEVICE = 'device',
    REPORT = 'report',
    NOTIFICATION = 'notification'
}

export class ServerStatusInterface {
    id: string = null;

    type: SERVER_TYPE[] = null;

    name: string = null;

    host: string = null;

    port: number = null;

    isOnline: boolean = null;

    ramUsage?: string = null;

    cpuUsage?: string = null;

    queueArchive?: number = null;

    queueAuthorize?: number = null;

    queueDeviceRequest?: number = null;

    queueNotification?: number = null;

    queueReport?: number = null;

    connectedDevice?: number = null;

    connectedUser?: number = null;

}

<div class="users">

    <app-heading [title]="'heading.users'"></app-heading>

    <app-filters [optionsDropDown]="dropDownFilterOptions$ | async"
                 [filter]="(params$ | async).filter"
                 [optionsFoDropDownSelect]="currentOptionsForDropDownFilter"
                 [defaultDropdownValue]="defaultDropdownValue"
                 [isBtns]="true"
                 [btnsSide]="btnsSideEnum.left"
                 [isBtnDisabled]="isEditingMode$ | async"
                 [btnEditTitle]="'btns.create'"
                 [iconOpenBtns]="true"
                 [iconsArray]="filterIconsArray"
                 (iconOpenEvent)="iconOpenEvent($event)"
                 (editBtnsEvent)="createNewUser()"
                 [currentOptionKeyForDropdownSelect]="(companiesOptions$ | async).length ? currentOptionKeyForDropdown : null"
                 (filterEvent)="changeFilter($event)"
                 (chooseFilterEvent)="chooseFilter($event)"
    ></app-filters>

    <app-tables
        [unHidden]="true"
        [currentTableColumns]="columns$ | async"
        [rows]="rows$ | async"
        [configPagination]="(params$ | async).pagination"
        (actionEvent)="eventFromTable($event)"
        (openSmth)="openUser($event)"
        (activityEvent)="changeUserActivity($event)"
        (deleteSmthEvent)="checkDeleteUser($event)"
        (eventClickBtnFromColumn)="confirmUser($event)"
        (changeSelectOptionEvent)="chooseCompany($event)"
        (editSmthEvent)="saveUser($event)"
        (sortOutput)="onChangeSort($event)"
        (paginationOutput)="onChangePage($event)"
    ></app-tables>
</div>

<app-popup *ngIf="isImportModal"
           [title]="'table.users.popup.importTitle'"
           (closeEmit)="isImportModal = false"
>

    <div class="popup__drop-zone" #drop ngx-dropzone
         [disableClick]="true"
         [multiple]="false"
         (change)="onFileChanged($event)"
         [accept]="'text/csv'"
         [maxFileSize]="1.5e+7"
    >

        <div class="popup__drop-zone-items">

            <div class="popup__drop-zone-item">

                <div class="popup__drop-zone-text" [translate]="'table.users.popup.format'"></div>
                <div class="popup__drop-zone-text" [translate]="'table.users.popup.size'"></div>
                <div class="popup__drop-zone-text" [translate]="'table.users.popup.info'"></div>

            </div>

            <div class="popup__drop-zone-item">
                <svg-icon [src]="'./assets/design/icons/dropzone/import.svg'"></svg-icon>
            </div>

        </div>


    </div>

    <div class="popup__btns">

        <button class="btn btn--light" (click)="isImportModal = false" [translate]="'btns.cancel'"></button>
        <button class="btn" (click)="drop.showFileSelector()" [translate]="'importExport.downloadBtn'"></button>
        <span class="popup__drop-zone-text" [translate]="'table.users.popup.or'"></span>

    </div>

</app-popup>

<app-popup *ngIf="showDeleteUser"
           [title]="'table.users.popup.title' | translate"
           (closeEmit)="showDeleteUser = false">
    <span class="popup__text popup__text--delete" [translate]="deleteUserRows.isConfirmEmail && deleteUserRows.lastActive ?
     'table.users.popup.trashDeleteQuestion' : 'table.users.popup.deleteQuestion'"
          [translateParams]="{name: deleteUserRows.login}"
    ></span>

    <div class="btns">
        <button class="btn btn--light" (click)="showDeleteUser = false" [translate]="'btns.cancel'"></button>
        <button class="btn btn--red" (click)="deleteUser(); showDeleteUser = false"
                [translate]="deleteUserRows.isConfirmEmail && deleteUserRows.lastActive
                ? 'btns.trashDeleted' : 'btns.delete'"></button>

    </div>
</app-popup>

<app-popup *ngIf="isShowImportPopup$ | async"
           [title]="'importExport.user.importTitle'"
           (closeEmit)="closeImportPopup()">

    <div class="import-popup"
         [style.height]="isDropFile ? '100%' : '400px'"
    >


        <ng-container *ngIf="!userExportImportEvent; else preloader">
            <ng-container *ngIf="!isDropFile; else file">
                <div class="import-popup__drop-zone">

                    <app-drop-zone
                        [title]="'importExport.user.dropZoneTitle'"
                        [subTitle]="'mnemonic.popup.dropZoneSubTitle'"
                        [accept]="'.zip, .sensorscloud, .sc, .rar'"
                        [type]="dropZoneTypeEnum.import"
                        (fileChangedEvent)="onImportFileChanged($event)"
                    ></app-drop-zone>

                </div>
            </ng-container>

            <ng-template #file>

                <div class="import-popup__message" [translate]="'importExport.user.importMessage'"></div>
                <div class="export-popup__item">

                    <label class="login__form-label" [translate]="'importExport.user.importLabel'"></label>
                    <input class="login__form-input"
                           [(ngModel)]="password"
                           [type]="isVisiblePassword ? 'text' : 'password'"
                           [placeholder]="'importExport.user.passwordPlaceholder' | translate">
                    <div class="login__icon-eyes" (click)="isVisiblePassword = !isVisiblePassword">
                        <svg-icon
                            [src]="!isVisiblePassword ? './assets/design/icons/eyes.svg' : './assets/design/icons/eyes-close.svg'"></svg-icon>
                    </div>

                </div>

            </ng-template>
        </ng-container>

        <ng-template #preloader>

            <app-import-export-preloader [data]="userExportImportEvent"
                                         [isDone]="isDone"
                                         [requestId]="requestId"></app-import-export-preloader>

        </ng-template>

        <div class="import-popup__btns">

            <button (click)="closeImportPopup()"
                    class="btn btn--light" [translate]="'btns.cancel'"></button>
            <button *ngIf="isDropFile && file && !userExportImportEvent"
                    [disabled]="!password || !password.length || !file"
                    (click)="importUser()" class="btn"
                    [translate]="'btns.import'"></button>

        </div>

    </div>

</app-popup>

<app-popup *ngIf="isShowExportPopup"
           [title]="'importExport.user.exportTitle'"
           (closeEmit)="isShowExportPopup = false">

    <div class="export-popup">

        <ng-container *ngIf="!userExportImportEvent; else preloader">
            <div class="export-popup__items">

                <div class="export-popup__item">
                    <span [translate]="'importExport.user.exportMessage'"></span>
                </div>

                <div class="export-popup__item">

                    <label class="login__form-label" [translate]="'importExport.user.password'"></label>
                    <input class="login__form-input"
                           [(ngModel)]="password"
                           [type]="isVisiblePassword ? 'text' : 'password'"
                           [placeholder]="'importExport.user.passwordPlaceholder' | translate">
                    <div class="login__icon-eyes" (click)="isVisiblePassword = !isVisiblePassword">
                        <svg-icon
                            [src]="!isVisiblePassword ? './assets/design/icons/eyes.svg' : './assets/design/icons/eyes-close.svg'"></svg-icon>
                    </div>

                </div>

                <div class="export-popup__item">

                    <label class="login__form-label" [translate]="'importExport.user.confirmPassword'"></label>
                    <input class="login__form-input"
                           [(ngModel)]="confirmPassword"
                           [type]="isVisibleConfirmPassword ? 'text' : 'password'"
                           [placeholder]="'importExport.user.confirmPasswordPlaceholder' | translate">
                    <div class="login__icon-eyes" (click)="isVisibleConfirmPassword = !isVisibleConfirmPassword">
                        <svg-icon
                            [src]="!isVisibleConfirmPassword ? './assets/design/icons/eyes.svg' : './assets/design/icons/eyes-close.svg'"></svg-icon>
                    </div>

                </div>

            </div>
        </ng-container>

        <ng-template #preloader>

            <app-import-export-preloader [data]="userExportImportEvent"
                                         [isDone]="isDone"
                                         [requestId]="requestId"></app-import-export-preloader>

        </ng-template>

        <div class="export-popup__btns">

            <button (click)="closeExportPopup()"
                    [translate]="'btns.cancel'" class="btn btn--light"></button>

            <button *ngIf="!userExportImportEvent"
                    (click)="exportUser()"
                    class="btn"
                    [disabled]="!password?.length || !confirmPassword?.length || password !== confirmPassword"
                    [translate]="'btns.export'"></button>

        </div>

    </div>

</app-popup>


export enum TypeJsonPropertyLog{
    STRING = 'string',
    LANG = 'lang',
    BOOL = 'bool',
    TRANSLATE_VALUE = 'translateValue'
}

export enum ActionPage{
    SYSTEM = 'system',
    DEVICE_LIST = 'device_list',
    USER_PROFILE = 'user_profile',
    GROUP = 'group',
    REPORT = 'report_list',
    MAILING = 'mailing',
    LOGIC_EVENT = 'logic_event'
}


export enum ActionLogUserEnum {
    UPDATE = 'update',
    DELETE = 'delete',
    CREATE = 'create',
    LOGIN = 'login',
    UNLOCKED = 'unlocked'

}

export class JsonDataLogInterface {
    property?: string;
    value?: string | number;
    isTranslate: boolean;
    type?: TypeJsonPropertyLog;
    // tslint:disable-next-line:variable-name
    key_Value?: string; // for _value
    rootKey?: string;
}

export class JsonPropertyLogInterface {
    data?: JsonDataLogInterface[];
    keyTranslate: string;
}

export class JsonTranslateData {
    data?: JsonDataLogInterface[];
    keyTranslate: string;
    property?: JsonPropertyLogInterface[];

}

export class UserLogInterface{
    id: string;
    userId: string;
    userIp: string;
    userLogin: string;
    jsonDataLog: JsonTranslateData;
    shortJsonDataLog: JsonTranslateData;
    actionPage: ActionPage;
    deviceId?: string;
    deviceName?: string;
    groupId?: number;
    action: ActionLogUserEnum;
    internalId: string;
    userAgent: string;

    created: Date;
}

export class SetDataloggerSerialNumber {
    static readonly type = '[CreateDevices] SetDataloggerSerialNumber';

    // constructor(public internalId: string) {}
}

export class SetDataloggerCode {
    static readonly type = '[CreateDevices] SetDataloggerCode';

    constructor(
        // public internalId: string,
        public code: string,
    ) {}
}

export class ToggleErrorPopup {
    static readonly type = '[CreateDevices] ToggleErrorPopup';

    constructor(public toggle: boolean) {}
}

export class ToggleIsAuthCode {
    static readonly type = '[CreateDevices] ToggleIsAuthCode';

    constructor(public toggle: boolean) {}
}

export class ToggleIsPreloader {
    static readonly type = '[CreateDevices] ToggleIsPreloader';

    constructor(public toggle: boolean) {}
}

export class SetErrorPopup {
    static readonly type = '[CreateDevices] SetErrorPopup';

    constructor(
        public isToggle: boolean,
        public message: string,
    ) {}
}

export class ResetSerialNumber {
    static readonly type = '[CreateDevices] ResetSerialNumber';
}

export class SetSerialNumber {
    static readonly type = '[CreateDevices] SetSerialNumber';

    constructor(public serialNumber: string) {}
}

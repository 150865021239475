import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CreateTrain, DeleteTrain, GetTrain, GetUsedTrainId, InitPartOptions, InitTrailerOptions, InitTrainArray, UpdateTrain, UpdateTrainArray, UpdateTrainData } from '../actions/train-device.actions';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { CarTransportDtoInterface } from '../../train-dashboard/_interfaces/car-transport-dto.interface';
import { TrainArrayInterface } from '../../train-dashboard/_interfaces/train-array.interface';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { TransportDashboardService } from '../../transport-dashboard/_services/transport-dashboard.service';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { VariablesNameEnum } from '../../../app-shared-elements/_enums/variables-name.enum';
import { GetEditExpedition } from '../actions/transport-device.actions';
import { TransportState } from './transport-device.state';
import { LogicEventType } from '../../../events/logical-events/_interface/LogicEvent';
import { PartTrailerPositionEnum, TrailerPositionEnum } from '../../train-dashboard/_enums/train.enum';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { UpdateTrainInterface } from '../../train-dashboard/_interfaces/update-train.interface';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { CustomSelectTrainInterface } from '../../train-dashboard/_interfaces/custom-select-train.interface';
import { TrainDashboardService } from '../../train-dashboard/_services/train-dashboard.service';
import { PermissionService } from '../../../app-shared-elements/_services/permission.service';
import { MethodPermission, ResourceAction } from '../../../app-shared-elements/_enums/permission.enum';
import { PermissionsState } from '../../../app-shared-elements/_store/states/permissions.state';

export interface TrainStateModel {
    train: CarTransportDtoInterface[];
    trainArray: TrainArrayInterface[];
    updateData: UpdateTrainInterface;
    selectOptionsArray: CustomSelectTrainInterface[];
    usedIds: { devicesId: string[]; registratorsId: string[] };
}

const TRAIN_STATE_TOKEN = new StateToken<TrainStateModel>('train');

@State<TrainStateModel>({
    name: TRAIN_STATE_TOKEN,
    defaults: {
        train: [],
        trainArray: [],
        updateData: null,
        selectOptionsArray: [],
        usedIds: null,
    },
})
@Injectable()
export class TrainState {
    constructor(
        private http: HttpClient,
        private store: Store,
        private transportDashboardService: TransportDashboardService,
        private notificationsService: NotificationsService,
        private trainDashboardService: TrainDashboardService,
        private permissionService: PermissionService,
    ) {}

    @Selector()
    static getTrain(state: TrainStateModel): CarTransportDtoInterface[] {
        return state.train;
    }

    @Selector()
    static getTrainArray(state: TrainStateModel): TrainArrayInterface[] {
        console.log(state.trainArray);
        return state.trainArray.sort((a, b) => +a.position - +b.position);
    }

    @Selector()
    static getUpdateTrainData(state: TrainStateModel): UpdateTrainInterface {
        return state.updateData;
    }

    @Selector()
    static getSelectOptionsArray(state: TrainStateModel): CustomSelectTrainInterface[] {
        return state.selectOptionsArray;
    }

    @Action(GetTrain)
    async getTrain(ctx: StateContext<TrainStateModel>): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/multi-transport')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();
        console.log(result);
        if (result && result.data) {
            ctx.setState({
                ...state,
                train: result.data,
            });
            const variableIds: string[] = [];
            (result.data as CarTransportDtoInterface[]).forEach((item) => {
                item.trailers.forEach((trailer) => {
                    const currentDevice = this.store.selectSnapshot(DevicesState.getVirtualDevices).find((f) => f.id === trailer.virtualRegistratorId);
                    if (!currentDevice) {
                        return;
                    }
                    currentDevice.variables
                        .filter((f) => f.name === VariablesNameEnum.Temperature1 || f.name === VariablesNameEnum.Temperature2)
                        .forEach((variable) => {
                            variableIds.push(variable.originVariableId);
                        });
                });
            });
            await ctx.dispatch(new InitTrainArray()).toPromise();
            await this.store.dispatch(new GetEditExpedition(variableIds)).toPromise();
        }
    }

    @Action(InitTrainArray)
    async initTrain(ctx: StateContext<TrainStateModel>): Promise<void> {
        const state = ctx.getState();
        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        const expeditions = this.store.selectSnapshot(TransportState.getEditExpedition);
        ctx.setState({
            ...state,
            trainArray: state.train.map((item) => {
                return {
                    ...item,
                    trailers: item.trailers
                        .map((trailer) => {
                            const currentVirtualDevice = devices.find((f) => f.id === trailer.virtualRegistratorId);

                            return {
                                ...trailer,
                                name: currentVirtualDevice?.name ?? currentVirtualDevice?.defaultName ?? null,
                                selectId: currentVirtualDevice?.id,
                                parts: trailer.parts
                                    .map((part) => {
                                        const currentDevice = devices.find((f) => f.id === part.deviceId);
                                        const currentRegistrator = devices.find((f) => f.id === currentDevice?.registratorId);

                                        return {
                                            ...part,
                                            name: currentDevice?.name ?? currentDevice?.defaultName ?? null,
                                            battery: currentDevice?.battery ?? null,
                                            rssi: currentDevice?.signal ?? null,
                                            isConnect: (!currentDevice?.sleepStatus && currentDevice?.isConnect) || (currentRegistrator?.isConnect && currentDevice?.sleepStatus),
                                            sleepStatus: currentRegistrator?.isConnect && currentDevice?.sleepStatus,
                                            limits: currentDevice?.variables
                                                .filter((f) => f.name === VariablesNameEnum.Temperature1 || f.name === VariablesNameEnum.Temperature2)
                                                .map((variable) => {
                                                    const currentExpedition = expeditions.find((f) => f.variableId === variable.id);
                                                    return {
                                                        trend: variable.trend,
                                                        unit: variable.unitName,
                                                        deviceId: part.deviceId,
                                                        name: variable.customName ?? variable.name,
                                                        value: variable.currentValue,
                                                        status: variable.status,
                                                        alarmMinLimit: this.transportDashboardService.getValueFoDatalogger(currentExpedition, LogicEventType.alarmDeadlineMin),
                                                        alarmMaxLimit: this.transportDashboardService.getValueFoDatalogger(currentExpedition, LogicEventType.alarmDeadlineMax),
                                                    };
                                                }),
                                        };
                                    })
                                    .sort((a, b) => +a.position - +b.position),
                            };
                        })
                        .sort((a, b) => +a.position - +b.position),
                };
            }),
        });
    }

    @Action(UpdateTrainArray)
    updateTrainArray(ctx: StateContext<TrainStateModel>, payload: UpdateTrainArray): void {
        const state = ctx.getState();
        const devices = payload.devices;
        const expeditions = payload.expeditions.length ? payload.expeditions : this.store.selectSnapshot(TransportState.getEditExpedition);

        ctx.setState({
            ...state,
            trainArray: state.trainArray.map((item) => {
                return {
                    ...item,
                    trailers: item.trailers.map((trailer) => {
                        const currentVirtualDevice = devices.find((f) => f.id === trailer.virtualRegistratorId);
                        return {
                            ...trailer,
                            name: currentVirtualDevice?.name ?? currentVirtualDevice?.defaultName ?? null,
                            parts: trailer.parts
                                .map((part) => {
                                    const currentDevice = devices.find((f) => f.id === part?.deviceId);
                                    const currentRegistrator = devices.find((f) => f.id === currentDevice?.registratorId);

                                    const currentBattery = currentDevice?.variables?.find((f) => f.name === VariablesNameEnum.BatteryLevel);
                                    const currentRssi = currentDevice?.variables?.find((f) => f.name === VariablesNameEnum.RSSI);
                                    return {
                                        ...part,
                                        name: currentDevice?.name ?? currentDevice?.defaultName ?? null,
                                        battery: {
                                            value: currentBattery?.currentValue ?? null,
                                            unit: currentBattery?.unitName ?? null,
                                        },
                                        rssi: {
                                            value: currentRssi?.currentValue ?? null,
                                            unit: currentRssi?.unitName ?? null,
                                        },
                                        isConnect: (!currentDevice?.sleepStatus && currentDevice?.isConnect) || (currentRegistrator?.isConnect && currentDevice?.sleepStatus),
                                        sleepStatus: currentRegistrator?.isConnect && currentDevice?.sleepStatus,
                                        limits: currentDevice?.variables
                                            .filter((f) => f.name === VariablesNameEnum.Temperature1 || f.name === VariablesNameEnum.Temperature2)
                                            .map((variable) => {
                                                const currentExpedition = expeditions.find((f) => f.variableId === variable.id);
                                                return {
                                                    unit: variable.unitName,
                                                    trend: variable.trend,
                                                    name: variable.customName ?? variable.name,
                                                    value: variable.currentValue,
                                                    deviceId: part.deviceId,
                                                    status: variable.status,
                                                    lastActive: variable.lastActive,
                                                    alarmMinLimit: this.transportDashboardService.getValueFoDatalogger(currentExpedition, LogicEventType.alarmDeadlineMin),
                                                    alarmMaxLimit: this.transportDashboardService.getValueFoDatalogger(currentExpedition, LogicEventType.alarmDeadlineMax),
                                                };
                                            }),
                                    };
                                })
                                .sort((a, b) => +a.position - +b.position),
                        };
                    }),
                };
            }),
        });
    }

    @Action(DeleteTrain)
    async deleteTrain(ctx: StateContext<TrainStateModel>, payload: DeleteTrain): Promise<void> {
        const result: ApiResponse = (await this.http
            .delete('/api/multi-transport', {
                params: {
                    id: payload.id,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
            });

            await ctx.dispatch(new GetTrain()).toPromise();
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(CreateTrain)
    async createTrain(ctx: StateContext<TrainStateModel>): Promise<void> {
        const state = ctx.getState();
        const emptyTrain: CarTransportDtoInterface = {
            position: state.train.length > 0 ? (+state.train.sort((a, b) => +a.position - +b.position)[state.train.length - 1].position + 1).toString() : '1',
            id: null,
            number: '',
            trailers: [
                {
                    virtualRegistratorId: null,
                    position: TrailerPositionEnum.FIRST,
                    number: '',
                    parts: [
                        {
                            position: PartTrailerPositionEnum.FIRST,
                            deviceId: null,
                        },
                        {
                            position: PartTrailerPositionEnum.SECOND,
                            deviceId: null,
                        },
                        {
                            position: PartTrailerPositionEnum.THIRD,
                            deviceId: null,
                        },
                    ],
                },
                {
                    virtualRegistratorId: null,
                    position: TrailerPositionEnum.SECOND,
                    number: '',
                    parts: [
                        {
                            position: PartTrailerPositionEnum.FIRST,
                            deviceId: null,
                        },
                        {
                            position: PartTrailerPositionEnum.SECOND,
                            deviceId: null,
                        },
                        {
                            position: PartTrailerPositionEnum.THIRD,
                            deviceId: null,
                        },
                    ],
                },
            ],
        };

        const result: ApiResponse = (await this.http
            .post('/api/multi-transport', { ...emptyTrain })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        console.log(result);
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
            });

            await ctx.dispatch(new GetTrain()).toPromise();
        }
    }

    @Action(UpdateTrain)
    async updateTrain(ctx: StateContext<TrainStateModel>): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .put('/api/multi-transport', {
                ...state.updateData,
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
            });

            await ctx.dispatch(new GetTrain()).toPromise();
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(InitTrailerOptions)
    initTrailerOptions(ctx: StateContext<TrainStateModel>, payload: InitTrailerOptions): void {
        const state = ctx.getState();
        const virtualDevices = this.store.selectSnapshot(DevicesState.getVirtualDevices);
        const currentTrain = state.trainArray.find((f) => f.id === payload.currentTrain.id);
        const devicesOptions: SelectOptionInterface<string, string, Device | string>[] = virtualDevices.map((device) => {
            const data = {
                registrator: device,
                action: ResourceAction.TRANSPORT,
                permissions: [MethodPermission.READ],
            };
            const roles = this.store.selectSnapshot(PermissionsState.getRoles);
            const currentRole = roles.find((role) => role.id === device.roleId);
            if (!currentRole || this.permissionService.checkPermission(currentRole?.permissions, data)) {
                return {
                    key: device.id,
                    value: device.name ?? device.defaultName,
                    disabled: !!state.usedIds.registratorsId.find((f) => f === device.id),
                    property: device,
                };
            }
        });

        devicesOptions.unshift({
            key: null,
            value: null,
            property: '-1',
        });

        const dataloggerOptions: SelectOptionInterface<string, string, Device | string>[] = this.trainDashboardService.getDataloggerOptions(state.usedIds.devicesId);

        ctx.setState({
            ...state,
            selectOptionsArray: currentTrain.trailers.map((trailer) => {
                return {
                    ...trailer,
                    options: devicesOptions.map((option) => {
                        if (option.property === '-1' && !option.value) {
                            return {
                                ...option,
                                value: trailer.position === TrailerPositionEnum.FIRST ? 'train.select.trainSelect' : 'train.select.trailerSelect',
                            };
                        }

                        return option;
                    }),
                    partOptions: trailer.parts.map((part) => {
                        console.log(part);
                        const currentDevice = virtualDevices.find((f) => f.id === trailer.selectId);
                        console.log(currentDevice);
                        if (!currentDevice) {
                            return {
                                name: part.name,
                                position: part.position,
                                options: [],
                            };
                        }

                        return {
                            name: part.name,
                            position: part.position,
                            options: dataloggerOptions,
                        };
                    }),
                };
            }),
        });
    }

    @Action(InitPartOptions)
    initPartOptions(ctx: StateContext<TrainStateModel>, payload: InitPartOptions): void {
        const state = ctx.getState();

        const dataloggerOptions: SelectOptionInterface<string, string, Device | string>[] = this.trainDashboardService.getDataloggerOptions(state.usedIds.devicesId);

        ctx.setState({
            ...state,
            selectOptionsArray: state.selectOptionsArray.map((select) => {
                if (select.position === payload.trailer.position) {
                    return {
                        ...select,
                        name: payload.device?.name ?? payload.device?.defaultName ?? null,
                        partOptions: select.partOptions.map((option) => {
                            if (!payload.device) {
                                return {
                                    ...option,
                                    name: null,
                                    options: [],
                                };
                            }

                            if (payload.device.id !== select.virtualRegistratorId) {
                                return {
                                    ...option,
                                    name: null,
                                    options: dataloggerOptions,
                                };
                            }
                            return {
                                ...option,
                                options: dataloggerOptions,
                            };
                        }),
                    };
                }
                return select;
            }),
        });
    }

    @Action(UpdateTrainData)
    updateTrainData(ctx: StateContext<TrainStateModel>, payload: UpdateTrainData): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            updateData: payload.data,
        });
    }

    @Action(GetUsedTrainId)
    async getUsedTrainId(ctx: StateContext<TrainStateModel>): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/multi-transport/used-id')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                usedIds: result.data,
            });
        }
    }
}

import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';

@Injectable({
    providedIn: 'root'
})
export class LuxonParseDateService {

    constructor() { }

    getDateByTimezone(date: Date, timezone: string): Date {
        const luxonDate = DateTime.fromJSDate(date);
        let newDate = luxonDate.setZone(timezone);
        if (!newDate.isValid) {
            newDate = luxonDate.setZone('Europe/Kiev');
        }
        const resultDate = new Date(newDate.year, newDate.month - 1, newDate.day, newDate.hour, newDate.minute);
        return resultDate;
    }

    getMillsBySystemTimezone(mills: number, timezone: string): number {
        const luxonDateInSystemTimezone = DateTime.fromMillis(mills);
        const luxonDateInProjectTimezone = DateTime.fromMillis(mills).setZone(timezone);
        const offset = luxonDateInSystemTimezone.offset - luxonDateInProjectTimezone.offset;
        return luxonDateInSystemTimezone.toMillis() + (1000 * 60 * (offset));
    }

    getCurrentOffset(timezone: string): number {
        const date = DateTime.local().setZone(timezone);

        return date.isValid ? date.offset : 0;
    }

    getTimeByTimezone(mills: number, timezone: string): DateTime {
        return DateTime.fromMillis(mills).setZone(timezone);
    }
}

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import {
    AddTransport,
    CancelUpdatingTransport,
    ChangeRowEditExpedition,
    ChangeSelectItemTransport,
    ChangeUpdatingTransport,
    GetEditExpedition,
    GetTransports,
    InitDevicesOptionsForTransport,
    InitSelectsTransport,
    InitTableForEditExpeditions,
    InitTransportArray,
    InitVariablesOptionsForTransport,
    RemoveTransport,
    SetErrorValidateEditExpedition,
    SetTransportParams,
    SetUpdatingTransport,
    UpdateTransportArray,
    UpdateTransportLimitLogicEventExpedition,
    UpdateTransportPosition,
    UpdateTransports,
} from '../actions/transport-device.actions';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { CreationType } from '../../../app-shared-elements/_enums/registrator-sync-status.enu';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { Params, ParamsFilterForClient } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { CloneDeepService } from 'src/app/app-shared-elements/_services/clone-deep.service';
import { UserState } from '../../../app-shared-elements/_store/states/user.state';
import { LogicEvent, LogicEventType } from 'src/app/events/logical-events/_interface/LogicEvent';
import { VariablesNameEnum } from 'src/app/app-shared-elements/_enums/variables-name.enum';
import { compareFilters } from 'src/app/app-shared-elements/_services/table.service';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { LogicalEventsState } from '../../../events/_store/states/logical-events.state';
import { Router } from '@angular/router';
import { TransportDeviceDto, TransportDto } from '../../transport-dashboard/_interfaces/TransportDeviceDto';
import { TransportDashboardInterface } from '../../transport-dashboard/_interfaces/transport-dashboard.interface';
import { EditExpeditionRowInterface } from '../../transport-dashboard/_interfaces/edit-expedition-row.interface';
import {
    TransportLimitLogicEvent,
    TransportLogicEventInterface,
} from '../../transport-dashboard/_interfaces/transport-logic-event.interface';
import { initialOriginFilterTransports } from '../../transport-dashboard/_data/transport-state-initial';
import { TransportDashboardService } from '../../transport-dashboard/_services/transport-dashboard.service';
import { UpdateTransportDto } from '../../transport-dashboard/_interfaces/UpdateTransportDto';

export interface TransportsStateModel {
    transports: TransportDto[];
    updatingTransportItem: TransportDashboardInterface;
    transportsArray: TransportDashboardInterface[];
    savedTransportsItem: TransportDashboardInterface;
    selectsTransport: TransportDeviceDto[];
    devicesOptions: SelectOptionInterface[];
    variablesOptions: SelectOptionInterface<string, string, Variable>[];
    params: Params;
    rowsForEditExpedition: EditExpeditionRowInterface[];
    editExpedition: TransportLogicEventInterface<TransportLimitLogicEvent>[];
    isExpeditionLoad: boolean;
    arrayErrorValidateRowsId: string[];
    multiEventsSkelet: LogicEvent[];
    transportChange: { registratorId: string; name: string }[];
}

const TRANSPORT_STATE_TOKEN = new StateToken<TransportsStateModel>('transports');

const initialFilterTransports: ParamsFilterForClient[] =
    JSON.parse(localStorage.getItem('checkboxFilterTransport')) &&
    compareFilters(JSON.parse(localStorage.getItem('checkboxFilterTransport')), initialOriginFilterTransports)
        ? JSON.parse(localStorage.getItem('checkboxFilterTransport')).filter((item) =>
              initialOriginFilterTransports.find((filter) => filter.title === item.title),
          )
        : initialOriginFilterTransports;

const intialParams: Params = {
    filter: initialFilterTransports,
    sorted: null,
    pagination: null,
};

@State<TransportsStateModel>({
    name: TRANSPORT_STATE_TOKEN,
    defaults: {
        transports: [],
        updatingTransportItem: null,
        transportsArray: [],
        savedTransportsItem: null,
        selectsTransport: [],
        devicesOptions: [],
        variablesOptions: [],
        params: intialParams,
        rowsForEditExpedition: [],
        editExpedition: [],
        isExpeditionLoad: false,
        arrayErrorValidateRowsId: [],
        multiEventsSkelet: [],
        transportChange: [],
    },
})
@Injectable()
export class TransportState {
    constructor(
        public transportDashboardService: TransportDashboardService,
        private store: Store,
        private notificationService: NotificationsService,
        private http: HttpClient,
        private cloneDeepService: CloneDeepService,
        private router: Router,
    ) {}

    @Selector()
    static getTransports(state: TransportsStateModel): TransportDto[] {
        return state.transports;
    }

    @Selector()
    static getUpdatingTransportItem(state: TransportsStateModel): TransportDashboardInterface {
        return state.updatingTransportItem;
    }

    @Selector()
    static getSelectsTransport(state: TransportsStateModel): TransportDeviceDto[] {
        return state.selectsTransport.sort((a, b) => (a.id > b.id ? 1 : -1));
    }

    @Selector()
    static getDevicesOptions(state: TransportsStateModel): SelectOptionInterface[] {
        return state.devicesOptions;
    }

    @Selector()
    static getVariablesOptions(state: TransportsStateModel): SelectOptionInterface<string, string, Variable>[] {
        return state.variablesOptions;
    }

    @Selector()
    static getTransportsArray(state: TransportsStateModel): TransportDashboardInterface[] {
        return state.transportsArray
            .filter((t) => t.isVisible && !state.transportChange.find((d) => d.registratorId === t.registratorId))
            .sort((a, b) => a.position - b.position);
    }

    @Selector()
    static getParams(state: TransportsStateModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }

    @Selector()
    static getRowsForEditExpedition(state: TransportsStateModel): EditExpeditionRowInterface[] {
        return state.rowsForEditExpedition;
    }

    @Selector()
    static getErrorValidateEditExpeditions(state: TransportsStateModel): boolean {
        return !!state.arrayErrorValidateRowsId.length;
    }

    @Selector()
    static getTransportChange(state: TransportsStateModel): { registratorId: string; name: string }[] {
        return state.transportChange;
    }

    @Selector()
    static getEditExpedition(state: TransportsStateModel): TransportLogicEventInterface<TransportLimitLogicEvent>[] {
        return state.editExpedition;
    }

    @Action(GetTransports)
    async getTransports(ctx: StateContext<TransportsStateModel>): Promise<void> {
        const state = ctx.getState();
        const registrators = this.store.selectSnapshot(DevicesState.getRegistrators);
        const registratorIds = registrators.map((r) => r.id);

        const headers = new HttpHeaders({ registratorId: registratorIds.join(',') || '' });

        const result: ApiResponse = (await this.http
            .get('/api/transport', { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            const transports = (result.data as TransportDto[]).filter((t) => {
                const currentRegistrator = registrators
                    .filter((r) => r.creationType === CreationType.ORIGIN)
                    .find((r) => r.id === t.registratorId);
                const expeditionVariable: Variable = currentRegistrator?.variables?.find(
                    (v) => v.name === VariablesNameEnum.ExpectExpedition,
                );
                if (expeditionVariable && expeditionVariable.currentValue && currentRegistrator && currentRegistrator.isActive) {
                    return t;
                }
            });
            ctx.setState({
                ...state,
                transports,
            });

            const variableIds: string[] = [];
            transports.forEach((transport) => {
                transport.transportDevices.forEach((t) => {
                    if (t.variableId) {
                        variableIds.push(t.variableId);
                    }
                });
            });

            await this.store.dispatch(new GetEditExpedition(variableIds)).toPromise();
        }
    }

    @Action(UpdateTransports)
    async updateTransports(ctx: StateContext<TransportsStateModel>): Promise<void> {
        const state = ctx.getState();
        const updatingTransport = state.transports.find((transport) => transport.id === state.updatingTransportItem.id);
        const updateTransportDto: UpdateTransportDto = {
            transportId: state.updatingTransportItem.id,
            transportDevices: state.selectsTransport.map((select) => {
                return {
                    id: select.id,
                    deviceId: select.deviceId,
                    variableId: select.variableId,
                    unit: select.unit,
                };
            }),
        };

        const registrators = this.store.selectSnapshot(DevicesState.getRegistrators);

        const currentTransport: TransportDto = state.transports.find((t) => t.id === updateTransportDto.transportId);
        const currentRegistrator: Device = registrators.find((r) => r.id === currentTransport.registratorId);

        const registratorId = currentRegistrator ? currentRegistrator.id : '';

        const headers = new HttpHeaders(registratorId);

        const result: ApiResponse = (await this.http
            .put('/api/transport/device', { ...updateTransportDto }, { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            updatingTransport.transportDevices = [...result.data];

            ctx.setState({
                ...state,
                transports: state.transports.map((transport) => {
                    if (transport.id === updatingTransport.id) {
                        transport.transportDevices = [...result.data];
                    }

                    return transport;
                }),
            });

            await ctx.dispatch(new UpdateTransportArray(this.store.selectSnapshot(DevicesState.getDevices))).toPromise();
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(SetUpdatingTransport)
    setUpdatingTransport(ctx: StateContext<TransportsStateModel>, payload: SetUpdatingTransport): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            updatingTransportItem: payload.transportItem,
            savedTransportsItem: payload.transportItem,
        });
    }

    @Action(ChangeUpdatingTransport)
    changeUpdatingTransport(ctx: StateContext<TransportsStateModel>, payload: ChangeUpdatingTransport): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            updatingTransportItem: payload.transportItem,
        });
    }

    @Action(InitSelectsTransport)
    initSelectsTransport(ctx: StateContext<TransportsStateModel>, payload: InitSelectsTransport): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            selectsTransport: payload.selects,
        });
    }

    @Action(ChangeSelectItemTransport)
    changeSelectItemTransport(ctx: StateContext<TransportsStateModel>, payload: ChangeSelectItemTransport): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            selectsTransport: state.selectsTransport.map((select) => (select.id === payload.select.id ? payload.select : select)),
        });
    }

    @Action(InitDevicesOptionsForTransport)
    initDevicesOptionsForTransport(ctx: StateContext<TransportsStateModel>, payload: InitDevicesOptionsForTransport): void {
        const state = ctx.getState();
        const devicesOptions: SelectOptionInterface<string, string>[] = [];

        if (!payload.transport) {
            ctx.setState({
                ...state,
                devicesOptions,
            });

            return;
        }

        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        devices.forEach((device) => {
            if (
                device.registratorId === payload.transport.registratorId ||
                (device.id === payload.transport.registratorId && device.creationType === CreationType.VIRTUAL)
            ) {
                devicesOptions.push({
                    key: device.id,
                    value: device.name ?? device.defaultName,
                    type: 'text',
                });
            }
        });
        if (devicesOptions) {
            devicesOptions.unshift({
                key: null,
                value: 'transportDashboard.filters.chooseRegistrator',
                type: 'text',
                property: '-1',
            });
        }

        ctx.setState({
            ...state,
            devicesOptions,
        });
    }

    @Action(InitVariablesOptionsForTransport)
    initVariablesOptionsForTransport(ctx: StateContext<TransportsStateModel>, payload: InitVariablesOptionsForTransport): void {
        const state = ctx.getState();

        let variablesOptions: SelectOptionInterface<string, string, Variable>[] = [];

        if (!payload.transport) {
            ctx.setState({
                ...state,
                variablesOptions,
            });

            return;
        }

        const devices = this.store
            .selectSnapshot(DevicesState.getDevices)
            .filter(
                (device) =>
                    device.registratorId === payload.transport.registratorId ||
                    (device.id === payload.transport.registratorId && device.creationType === CreationType.VIRTUAL),
            );

        devices.forEach((device) => {
            device.variables.forEach((variable) => {
                variablesOptions.push({
                    key: variable.id,
                    value: variable.customName ?? variable.name,
                    type: 'text',
                    property: variable,
                });
                // }
            });
        });

        variablesOptions = variablesOptions.sort((a, b) => {
            return a.property.internalId > b.property.internalId ? 1 : -1;
        });

        ctx.setState({
            ...state,
            variablesOptions,
        });
    }

    @Action(InitTransportArray)
    async initTransportArray(ctx: StateContext<TransportsStateModel>): Promise<void> {
        const state = ctx.getState();
        const registrators = this.store.selectSnapshot(DevicesState.getAllRegistrators);
        const user = this.store.selectSnapshot(UserState.getUser);

        ctx.setState({
            ...state,
            transportsArray: state.transports
                .map((transport, index) => {
                    const currentRegistrator = registrators.find((registrator) => registrator.id === transport.registratorId);
                    if (!currentRegistrator) {
                        return;
                    }

                    return {
                        registratorName: currentRegistrator.name ?? currentRegistrator.defaultName,
                        isConnect: currentRegistrator.isConnect,
                        counterDevice: transport.transportDevices.filter((t) => t.deviceId != null),
                        registratorId: transport.registratorId,
                        registratorStatus: currentRegistrator.status,
                        id: transport.id,
                        isActive: transport.isActive,
                        position: +this.transportDashboardService.getPosition(transport, user) || index,
                        registrator: currentRegistrator,
                        creationType: currentRegistrator.creationType,
                        isVisible: this.transportDashboardService.isTransportVisible(currentRegistrator, state.params),
                        isSignalStatus: this.transportDashboardService.getCoordinatorStatus(transport.registratorId),
                        transportDevices: transport.transportDevices
                            .map((tDevice) => {
                                return {
                                    id: tDevice.id,
                                    deviceId: tDevice.deviceId || null,
                                    transportVariable: tDevice.variableId
                                        ? this.transportDashboardService.getCurrentTransportVariable(
                                              tDevice.deviceId,
                                              tDevice.variableId,
                                              transport.registratorId,
                                              state.editExpedition,
                                          )
                                        : null,
                                };
                            })
                            .sort((a, b) => (a.id > b.id ? 1 : -1)),
                    };
                })
                .filter((t) => !!t)
                .sort((a, b) => (a.position > b.position ? 1 : -1)),
            transportChange: JSON.parse(localStorage.getItem(`transportChanges${user.id}`)) || [],
        });
    }

    @Action(UpdateTransportPosition)
    updateTransportPosition(ctx: StateContext<TransportsStateModel>, payload: UpdateTransportPosition): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            transportsArray: state.transportsArray.map((transport) => {
                const currentTransport = payload.position.find((p) => p.registratorId === transport.registratorId);
                if (currentTransport) {
                    transport.position = currentTransport.position;
                }
                return transport;
            }),
        });
    }

    @Action(UpdateTransportArray)
    updateTransportArray(ctx: StateContext<TransportsStateModel>, payload: UpdateTransportArray): void {
        const state = ctx.getState();
        const devices = payload.devices;
        const events = this.store.selectSnapshot(LogicalEventsState.getLogicalEvents);
        // console.log(events);

        ctx.setState({
            ...state,
            transportsArray: state.transportsArray
                .map((transport) => {
                    const currentDevice = devices.find((device) => device.id === transport.registratorId);
                    if (!currentDevice) {
                        return;
                    }

                    return {
                        ...transport,
                        registrator: currentDevice,
                        registratorStatus: currentDevice.status,
                        registratorName: currentDevice.name ?? currentDevice.defaultName,
                        isConnect: currentDevice.isConnect,
                        isActive: state.transports.find((t) => t.registratorId === transport.registratorId).isActive,
                        isSignalStatus: this.transportDashboardService.getCoordinatorStatus(transport.registratorId),
                        transportDevices: transport.transportDevices
                            .map((tDevice) => {
                                const updatedDevice: Device = devices.find((d) => d.id === tDevice.deviceId);
                                if (!updatedDevice || !tDevice.transportVariable) {
                                    return;
                                }

                                const currentLimit = state.editExpedition.find((e) => e.variableId === tDevice.transportVariable.id);
                                const updatedVariable: Variable = updatedDevice.variables.find(
                                    (variable) => variable.id === tDevice.transportVariable.id,
                                );
                                if (!updatedVariable) {
                                    return tDevice;
                                }

                                return {
                                    ...tDevice,
                                    transportVariable: {
                                        deviceId: updatedVariable.deviceId,
                                        name: updatedVariable.customName ?? updatedVariable.name,
                                        id: updatedVariable.id,
                                        value: updatedVariable.unitName
                                            ? `${updatedVariable.currentValue} ${updatedVariable.unitName}`
                                            : updatedVariable.currentValue,
                                        status: updatedVariable.status,
                                        acknowledgeableStatus: this.transportDashboardService.getAknStatus(
                                            transport.registratorId,
                                            updatedVariable.id,
                                        ),
                                        unit: updatedVariable.unitName,
                                        isConnect: updatedVariable.isConnect,
                                        device: updatedDevice,
                                        alarmMinLimit: this.transportDashboardService.getValueFoDatalogger(
                                            currentLimit,
                                            LogicEventType.alarmDeadlineMin,
                                        ),
                                        alarmMaxLimit: this.transportDashboardService.getValueFoDatalogger(
                                            currentLimit,
                                            LogicEventType.alarmDeadlineMax,
                                        ),
                                    },
                                };
                            })
                            .filter((tDevice) => !!tDevice),
                    };
                })
                .filter((transport) => !!transport),
        });
    }

    @Action(CancelUpdatingTransport)
    cancelUpdatingTransport(ctx: StateContext<TransportsStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            transportsArray: state.transportsArray.map((transportItem) =>
                transportItem.id === state.updatingTransportItem.id ? state.savedTransportsItem : transportItem,
            ),
            updatingTransportItem: null,
            savedTransportsItem: null,
        });
    }

    @Action(SetTransportParams)
    setTransportParams(ctx: StateContext<TransportsStateModel>, payload: SetTransportParams): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: this.cloneDeepService.cloneObject(payload.params),
        });
    }

    @Action(GetEditExpedition)
    async getEditExpedition(ctx: StateContext<TransportsStateModel>, payload: GetEditExpedition): Promise<void> {
        const state = ctx.getState();

        const variablesId = payload.variableIds.join(',');

        const result: ApiResponse = (await this.http
            .get('api/logic-events-limit/variables-limit', {
                params: {
                    variablesId,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                editExpedition: result.data,
                isExpeditionLoad: true,
            });
        } else {
            ctx.setState({
                ...state,
                editExpedition: [],
                isExpeditionLoad: false,
            });
        }
    }

    @Action(InitTableForEditExpeditions)
    async initTableForEditExpeditions(ctx: StateContext<TransportsStateModel>, payload: InitTableForEditExpeditions): Promise<void> {
        const state = ctx.getState();
        if (!state.isExpeditionLoad) {
            await ctx.dispatch(new GetEditExpedition(payload.ids.map((i) => i.variableId))).toPromise();
        }
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);
        const devices = this.store
            .selectSnapshot(DevicesState.getDevices)
            .filter((d) => d.id === currentRegistrator.id || d.registratorId === currentRegistrator?.id);

        const variables: Variable[] = payload.ids
            .map((i) => {
                const currentDevice = devices.find((d) => d.id === i.deviceId);
                if (!currentDevice) {
                    return;
                }
                return currentDevice.variables.find((v) => v.id === i.variableId);
            })
            .filter((v) => !!v);

        ctx.setState({
            ...state,
            rowsForEditExpedition: this.transportDashboardService.initEditExpeditionsRows(state.editExpedition, variables),
        });
    }

    @Action(SetErrorValidateEditExpedition)
    setErrorValidateEditExpedition(ctx: StateContext<TransportsStateModel>, payload: SetErrorValidateEditExpedition): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            arrayErrorValidateRowsId: Array.from(
                new Set([
                    ...state.arrayErrorValidateRowsId.filter((id) => id !== payload.rowId && !payload.isError),
                    payload.isError ? payload.rowId : null,
                ]),
            ).filter((id) => !!id),
        });
    }

    @Action(UpdateTransportLimitLogicEventExpedition)
    async updateTransportLimitLogicEventExpedition(
        ctx: StateContext<TransportsStateModel>,
        payload: UpdateTransportLimitLogicEventExpedition,
    ): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('api/logic-events-limit/variables-limit', payload.data)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
            });
            if (!this.router.url.includes('datalogger-dashboard')) {
                const ids: string[] = state.updatingTransportItem?.counterDevice?.map((i) => i.variableId);

                ctx.dispatch(new GetEditExpedition(ids));
            }
        } else {
            if (result && result.status === HTTP_STATUS.REGISTRATOR_IS_OFFLINE) {
                this.notificationService.onEmit(TooltipStatusEnum.error, false, 'events.logicalEvents.offlineRegistrator');
            }
            if (!this.router.url.includes('datalogger-dashboard')) {
                const ids: {
                    variableId: string;
                    deviceId: string;
                }[] = state.updatingTransportItem.counterDevice.map((i) => ({
                    variableId: i.variableId,
                    deviceId: i.deviceId,
                }));

                await ctx.dispatch(new InitTableForEditExpeditions(ids));
            }
        }
    }

    @Action(ChangeRowEditExpedition)
    changeRowEditExpedition(ctx: StateContext<TransportsStateModel>, payload: ChangeRowEditExpedition): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            rowsForEditExpedition: state.rowsForEditExpedition.map((r) => {
                if (r.id === payload.row.id) {
                    return {
                        ...payload.row,
                    };
                }
                return { ...r };
            }),
        });
    }

    @Action(AddTransport)
    addTransport(ctx: StateContext<TransportsStateModel>, payload: AddTransport): void {
        const state = ctx.getState();
        const user = this.store.selectSnapshot(UserState.getUser);

        localStorage.setItem(
            `transportChanges${user.id}`,
            JSON.stringify(state.transportChange.filter((t) => t.registratorId !== payload.transport.registratorId)),
        );

        ctx.setState({
            ...state,
            transportChange: state.transportChange.filter((t) => t.registratorId !== payload.transport.registratorId),
        });
    }

    @Action(RemoveTransport)
    removeTransport(ctx: StateContext<TransportsStateModel>, payload: RemoveTransport): void {
        const state = ctx.getState();
        const user = this.store.selectSnapshot(UserState.getUser);

        const data: {
            registratorId: string;
            name: string;
        } = {
            registratorId: payload.transport.registratorId,
            name: payload.transport.registratorName,
        };

        localStorage.setItem(`transportChanges${user.id}`, JSON.stringify([data, ...state.transportChange]));

        ctx.setState({
            ...state,
            transportChange: [data, ...state.transportChange],
        });
    }
}

<div class="column"
    [class.column--main]="isMain">
    <div class="column-go">
        <div class="column-go__wrapper">
            <app-checkbox [value]="isMain ? (isMainChecked$ | async) : value?.value"
                [isDisabled]="!isMain && value?.disabled"
                (changeEvent)="changeEvent($event)"></app-checkbox>
        </div>
    </div>
</div>

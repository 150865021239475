import {ParamsFilterForClient, ParamsFilterTypeEnum, ParamsTimeTypeEnum} from 'src/app/app-shared-elements/_interfaces/params.interface';
import {AlarmTypeEnum} from 'src/app/events/_enums/alarm.enum';
import {EventsFiltersNamesEnum} from '../_enums/name-filters.enum';

export const initialFilter: ParamsFilterForClient[] = [
    {
        title: 'logs.events.filters.act',
        property: 'tsCleared',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'tsCleared',
                value: true,
                type: ParamsFilterTypeEnum.NULLABLE,
            }
        ],
        relationFalse: [
            {
                property: 'tsCleared',
                value: false,
                type: ParamsFilterTypeEnum.NULLABLE,
            }
        ],
        name: EventsFiltersNamesEnum.active
    },
    {
        title: 'logs.events.filters.noAct',
        property: 'tsCleared',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'tsCleared',
                value: false,
                type: ParamsFilterTypeEnum.NULLABLE,
            }
        ],
        relationFalse: [
            {
                property: 'tsCleared',
                value: true,
                type: ParamsFilterTypeEnum.NULLABLE,
            }
        ],
        name: EventsFiltersNamesEnum.noActive,
        isBorder: true
    },
    {
        title: 'logs.events.filters.alarm',
        property: 'alarmType',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'alarmType',
                value: AlarmTypeEnum.alarm,
                type: ParamsFilterTypeEnum.TEXT,
            }
        ],
        name: EventsFiltersNamesEnum.alarm
    },
    {
        title: 'logs.events.filters.attention',
        property: 'alarmType',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'alarmType',
                value: AlarmTypeEnum.attention,
                type: ParamsFilterTypeEnum.TEXT,
            }
        ],
        name: EventsFiltersNamesEnum.attention,
        isBorder: true
    },
    // {
    //     title: 'logs.events.filters.ackn',
    //     property: 'tsAcknowledget',
    //     value: true,
    //     type: ParamsFilterTypeEnum.BOOLEAN,
    //     relationTrue: [
    //         {
    //             property: 'tsAcknowledget',
    //             value: false,
    //             type: ParamsFilterTypeEnum.NULLABLE,
    //         }
    //     ],
    //     name: EventsFiltersNamesEnum.ackn
    // },
    // {
    //     title: 'logs.events.filters.notAckn',
    //     property: 'isAknowledgeable',
    //     value: true,
    //     type: ParamsFilterTypeEnum.BOOLEAN,
    //     name: EventsFiltersNamesEnum.dontNeedToAckn,
    //     relationTrue: [
    //         {
    //             property: 'tsAcknowledget',
    //             value: false,
    //             type: ParamsFilterTypeEnum.NULLABLE,
    //         },
    //         {
    //             property: 'isAknowledgeable',
    //             value: false,
    //             type: ParamsFilterTypeEnum.BOOLEAN,
    //         }
    //     ],
    // },
    {
        title: 'logs.events.filters.needAckn',
        property: 'isAknowledgeable',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'tsAcknowledget',
                value: true,
                type: ParamsFilterTypeEnum.NULLABLE,
            },
            {
                property: 'isAknowledgeable',
                value: true,
                type: ParamsFilterTypeEnum.BOOLEAN,
            }
        ],
        name: EventsFiltersNamesEnum.needToAckn
    },

    // drop down
    {
        property: 'deviceId',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true
    },
    {
        property: 'completionType',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true
    },

    // time filter
    {
        property: 'ts',
        value: {
            to: null,
            from: null,
            time: null,
            type: ParamsTimeTypeEnum.ALL_TIME
        },
        type: ParamsFilterTypeEnum.DATETIME
    },
];

import { Component, OnDestroy, OnInit } from '@angular/core';
import { CountChannelInterface } from 'src/app/notifications/_interfaces/CountChannel';
import { SocketEvent } from '../_enums/socket-event.enum';
import { SocketService } from '../_services/socket.service';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationsCountState } from '../_store/states/notifications-count.state';
import { SetNotificationsCount } from '../_store/actions/notifications-count.actions';

@Component({
    selector: 'app-nav-notifications',
    templateUrl: './nav-notifications.component.html',
    styleUrls: ['./nav-notifications.component.scss'],
})
export class NavNotificationsComponent implements OnInit, OnDestroy {
    @Select(NotificationsCountState.getNotificationsCount) notifications$: Observable<CountChannelInterface>;

    readonly NOTIFICATIONS_ICON_PATH = './assets/design/icons/nav/notifications.svg';
    private destroy: Subject<boolean> = new Subject<boolean>();

    notifications: CountChannelInterface;

    constructor(private socketService: SocketService, public router: Router, private store: Store) {
        this.socketService.fromEvent(SocketEvent.COUNT_CHANNEL).subscribe((count: CountChannelInterface) => {
            if (count) {
                this.store.dispatch(new SetNotificationsCount(count));
            }
        });
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {}
}

<div class="archive-preloader">
    <div class="archive-preloader__wrapper">
        <div class="archive-preloader__text">
            <span [translate]="'charts.prelodaerArchive.getData'"></span>
            <span [translate]="'charts.prelodaerArchive.complete'"
                [translateParams]="{
                    completed: array.length,
                    allReposonse: count}"></span>
        </div>

        <div class="archive-preloader__progress">
            <ng-container *ngFor="let part of array; let i = index;">
                <div
                    class="archive-preloader__part"
                    [style.width]="100 / count + '%'">

                    <div class="archive-preloader__animation"></div>
                </div>
            </ng-container>
            <!-- [class.archive-preloader__part--hide]="(data$ | async).currentResponse > i" -->

        </div>

        <button class="archive-preloader__cancel btn btn--light" [translate]="'btns.cancel'"
                (click)="cancelPreloader()"></button>
    </div>
</div>

import { ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { StateReset } from 'ngxs-reset-plugin';
import { Observable, Subject, Subscription } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { TypeClient } from '../admin/users/_enum/type-client.enum';
import { ConnectEnum } from '../app-shared-elements/_enums/connect.enum';
import { DeviceTypeEnum } from '../app-shared-elements/_enums/device-type.enum';
import { TooltipStatusEnum } from '../app-shared-elements/_enums/tooltip-status.enum';
import { DropdownFilterOptionInterface } from '../app-shared-elements/filters/interfaces/filter-option.interface';
import { ActionListItemEnum } from '../app-shared-elements/_enums/action-list-item.enum';
import { FilterTypePipeEnum } from '../app-shared-elements/_enums/filter-type-pipe.enum';
import { LangEnum } from '../app-shared-elements/_enums/lang.enum';
import { MethodPermission, ResourceAction } from '../app-shared-elements/_enums/permission.enum';
import { ActionListItemInterface } from '../app-shared-elements/_interfaces/action-list-item.interface';
import { ColumnsTableInterface } from '../app-shared-elements/_interfaces/ColumnsTable';
import { Device } from '../app-shared-elements/_interfaces/Device';
import { InviteInterface } from '../app-shared-elements/_interfaces/InviteInterfaces';
import { Params, ParamsFilter, ParamsFilterForClient, ParamsFilterTypeEnum } from '../app-shared-elements/_interfaces/params.interface';
import { User } from '../app-shared-elements/_interfaces/user.interface';
import { CloneDeepService } from '../app-shared-elements/_services/clone-deep.service';
import { InviteService } from '../app-shared-elements/_services/invite-service.service';
import { PermissionService } from '../app-shared-elements/_services/permission.service';
import { SocketService } from '../app-shared-elements/_services/socket.service';
import { GetInvites } from '../app-shared-elements/_store/actions/invite.actions';
import { SetSkeleton } from '../app-shared-elements/_store/actions/table.actions';
import { InvitesState } from '../app-shared-elements/_store/states/invite.state';
import { LanguageState } from '../app-shared-elements/_store/states/language.state';
import { PermissionsState } from '../app-shared-elements/_store/states/permissions.state';
import { TableState } from '../app-shared-elements/_store/states/table.state';
import { UserState } from '../app-shared-elements/_store/states/user.state';
import { AlarmTypeEnum } from '../events/_enums/alarm.enum';
import { UserRegistratorInterface, UserSensorInterface } from './user-devices/_interface/user-devices.interface';
import { VariableByRegistratorInterface } from './_interfaces/VariableByRegistratorInterface';
import { DeviceService } from './_services/device.service';
import { SetDevicesParams } from './_store/actions/user-device-params.actions';
import {
    ChangeDeviceActivity,
    DeleteDevice,
    DeleteVirtualDevice,
    GetAssociatedLogicEvents,
    GetDevices,
    GetUserDevicePosition,
    InitRegistratorOptions,
    InitUserDevicesArray,
    IsShowDeletePopup,
    SetCurrentDevice,
    SyncDevice,
    UpdateDevicesPosition,
    UpdateUserDeviceArrayStatus,
    UpdateUserDevicePosition,
    UpdateUserDevicesArray,
} from './_store/actions/user-devices.actions';
import { UserDeviceParamsState } from './_store/states/user-device-params.state';
import { DevicesState } from './_store/states/user-devices.state';
import { SelectOptionInterface } from '../app-shared-elements/_interfaces/select-option.interface';
import { ActiveEvents } from '../events/_interfaces/ActiveEvents';
import { ActiveEventsState } from '../app-shared-elements/_store/states/active-events.state';
import { TechnologicEvent } from '../events/_interfaces/TechnologicEvent';
import { DeviceDashboardPositionInterface } from './_interfaces/device-dashboard-position.interface';
import { CreationType } from '../app-shared-elements/_enums/registrator-sync-status.enu';
import { LogicEvent, LogicEventType } from '../events/logical-events/_interface/LogicEvent';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationsService } from '../app-shared-elements/_services/notifications.service';
import { PreloaderService } from '../app-shared-elements/_services/preloader.service';
import { GetMailingSenders } from '../mailing/_store/actions/mailing-sender.actions';
import { FilterTypeSystemLogsEnum } from '../journals/_enums/filter-group-system-logs.enum';
import { RegistratorTypeEnum } from '../app-shared-elements/_enums/registrator-type.enum';
import { SocketEvent } from '../app-shared-elements/_enums/socket-event.enum';
import { GetLogicEventsStatuses } from '../app-shared-elements/_store/actions/active-events.actions';
import { UpdateLogicEventSocketInterface } from '../events/_interfaces/update-logic-event-socket.interface';
import { Variable } from '../app-shared-elements/_interfaces/Variable';

@Component({
    selector: 'app-device-dashboard',
    templateUrl: './device-dashboard.component.html',
    styleUrls: ['./device-dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceDashboardComponent implements OnInit, OnDestroy {
    @Select(DevicesState.getDevices) devices$: Observable<Device[]>;
    @Select(UserDeviceParamsState.getParams) params$: Observable<Params>;
    @Select(InvitesState.getInvites) invites$: Observable<InviteInterface[]>;
    @Select(DevicesState.getUserDeviceArray) userDevicesArray$: Observable<UserRegistratorInterface[]>;
    @Select(DevicesState.getOwnerDevices) ownerDevices$: Observable<Device[]>;
    @Select(DevicesState.getRegistratorOptions) registratorOptions$: Observable<SelectOptionInterface[]>;
    @Select(DevicesState.getUserDeviceTableColumns) deviceTableColumns$: Observable<ColumnsTableInterface[]>;
    @Select(DevicesState.getDropDownFilterDevices) dropDownFilterDevices$: Observable<DropdownFilterOptionInterface[]>;
    @Select(UserState.getUser) user$: Observable<User>;
    @Select(UserState.getUserTimezoneGMT) gmt$: Observable<string>;
    @Select(LanguageState.getLanguage) ln$: Observable<LangEnum>;
    @Select(TableState.getSkeleton) isSkeleton$: Observable<boolean>;
    @Select(DevicesState.getErrorListEvent) errorListEvent$: Observable<LogicEvent[]>;
    @Select(DevicesState.getIsShowDeletePopup) showDeleteDevice$: Observable<boolean>;

    devices: Device[];
    deviceTypeEnum = DeviceTypeEnum;
    alarmTypeEnum = AlarmTypeEnum;
    private userDevicesArray: UserRegistratorInterface[] = [];
    userDevicesArraySave: UserRegistratorInterface[] = [];
    tooltip = TooltipStatusEnum;
    creationType = CreationType;

    user: User;

    connectOptions: SelectOptionInterface<boolean>[] = [
        { key: true, value: 'devices.dropdownFilters.online', property: 'isConnect' },
        { key: false, value: 'devices.dropdownFilters.offline', property: 'isConnect' },
    ];

    statusOptions: SelectOptionInterface<AlarmTypeEnum>[] = [
        { key: AlarmTypeEnum.ok, value: 'devices.dropdownFilters.ok', property: 'status' },
        { key: AlarmTypeEnum.attention, value: 'devices.dropdownFilters.attention', property: 'status' },
        { key: AlarmTypeEnum.alarm, value: 'devices.dropdownFilters.alarm', property: 'status' },
    ];

    searchFilter: any;
    positionData: DeviceDashboardPositionInterface[] = [];
    devicesPositionData: { id: string; position: number }[];
    posData: DeviceDashboardPositionInterface[];

    currentDeviceId: string;
    currentDevice: Device;
    currentName: string;
    showShareDevice = false;

    subscribes: Subscription[] = [];

    variablesByRegistrator: VariableByRegistratorInterface[] = [];

    isShowModalActivity: boolean;
    changedDevice: UserSensorInterface;

    destroy: Subject<boolean> = new Subject<boolean>();

    filterType: FilterTypePipeEnum;

    isShowSyncPopup: boolean;
    randomCodeForSync: string;

    data;

    randomCodeInput: string;
    registratorForSync: Device;
    typeClient = TypeClient;
    registratorSyncName: string;
    logicEventType = LogicEventType;
    readonly EVENT__LIMIT_ICON_PATH = './assets/design/icons/mailing-group-edit/multi-events.svg';
    readonly ACTIVE_DEFAULT_EVENT = './assets/design/icons/deleted-devices/active.svg';
    readonly UN_ACTIVE_DEFAULT_EVENT = './assets/design/icons/deleted-devices/un-active.svg';

    isTrashDeleted = false;

    constructor(
        public deviceService: DeviceService,
        private inviteService: InviteService,
        private router: Router,
        private socketService: SocketService,
        private cloneDeepService: CloneDeepService,
        private store: Store,
        private ngZone: NgZone,
        private permissionService: PermissionService,
        private clipBoard: Clipboard,
        private notificationService: NotificationsService,
        private preloaderService: PreloaderService,
    ) {
        this.devices$.pipe(takeUntil(this.destroy)).subscribe(async (devices: Device[]) => {
            this.devices = devices;

            if (this.store.selectSnapshot(TableState.getSkeleton)) {
                this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                        this.store.dispatch(new SetSkeleton(false));
                    }, 1000);
                });
            }

            this.updateDataDevice();

            this.initRegistratorOptions();
        });

        this.user$.pipe(takeUntil(this.destroy)).subscribe((user) => {
            if (!user) {
                return;
            }
            this.user = this.cloneDeepService.cloneObject(user);
        });

        this.socketService
            .fromEvent(SocketEvent.DYNAMIC_LOGIC_EVENT_UPDATE)
            .pipe(takeUntil(this.destroy))
            .subscribe((data: UpdateLogicEventSocketInterface) => {
                console.log(data);
                this.updateEventStatus(data);
                // this.store.dispatch(new GetLogicEventsStatuses(data));
            });

        this.socketService
            .fromEvent(SocketEvent.DYNAMIC_VARIABLE_UPDATE)
            .pipe(takeUntil(this.destroy))
            .subscribe((data: Variable[]) => {
                this.store.dispatch(new UpdateUserDeviceArrayStatus(null, null, data));
            });
    }

    async ngOnInit(): Promise<void> {
        await this.store.dispatch(new GetLogicEventsStatuses()).toPromise();
        this.userDevicesArray$.pipe(takeUntil(this.destroy)).subscribe((userDeviceArray) => {
            if (!userDeviceArray || !userDeviceArray.length) {
                return;
            }

            if (this.store.selectSnapshot(TableState.getSkeleton)) {
                this.ngZone.runOutsideAngular(() => {
                    setTimeout(() => {
                        this.store.dispatch(new SetSkeleton(false));
                    }, 1000);
                });
            }

            this.userDevicesArray = userDeviceArray;
        });

        this.subscribes.push(
            this.socketService.errorConnectSubscribe.subscribe(async (status: ConnectEnum) => {
                if (status === ConnectEnum.RECONNECTED) {
                    await this.initInvite();
                }
            }),
        );

        this.ngZone.runOutsideAngular(() => {
            setTimeout(async () => {
                await this.initInvite();
            }, 400);
        });

        this.devicesPositionData = JSON.parse(localStorage.getItem(`devicesPosition${this.user.id}`)) || [];
        this.posData = JSON.parse(localStorage.getItem(`position${this.user.id}`)) || [];
        this.positionData = JSON.parse(localStorage.getItem(`position${this.user.id}`)) || [];

        this.devices$.pipe(first((devices) => !!devices && !!devices.length)).subscribe(() => {
            this.initRows();
        });

        this.params$.pipe(takeUntil(this.destroy)).subscribe((params) => {
            if (!params) {
                return;
            }

            const checkboxes = params.filter.filter((f) => f.type === ParamsFilterTypeEnum.BOOLEAN && !f.isDropdown);
            this.deviceService.setGroupDataFilter(checkboxes);
            const currentFilter = params.filter.find((f) => f.value !== null && f.isDropdown);
            if (!currentFilter) {
                this.searchFilter = null;
                return;
            }

            let count = 0;
            checkboxes.forEach((c) => {
                if (!c.value) {
                    count++;
                }
            });

            if (count === checkboxes.length && !currentFilter) {
                return;
            }

            this.currentFilterEvent(currentFilter);
        });

        this.subscribes.push(
            this.inviteService.newInviteSubscription.subscribe((data) => {
                this.initInvite();
                this.initRows();
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscribes.forEach((sub) => {
            if (sub) {
                sub.unsubscribe();
            }
        });

        this.userDevicesArray = [];
        this.destroy.next(true);
        this.destroy.complete();
        this.store.dispatch(new StateReset(UserDeviceParamsState));
    }

    private updateEventStatus(data: UpdateLogicEventSocketInterface): void {
        clearTimeout(this.data);
        this.data = setTimeout(() => {
            this.store.dispatch(new GetLogicEventsStatuses(data));
        }, 2000);
    }

    private updateDataDevice(): void {
        if (!this.devices || !this.devices.length || !this.store.selectSnapshot(DevicesState.getIsInitedTable) || !this.posData) {
            return;
        }

        const newDevices: Device[] = this.devices.filter((d) => d.type === DeviceTypeEnum.registrator && d.creationType !== CreationType.MNEMONIC && !this.userDevicesArray.find((user) => user.registrator.id === d.id));
        if (newDevices.length) {
            newDevices.forEach((device) => {
                this.posData.push({
                    registratorId: device.id,
                    position: this.posData.length + 1,
                });
            });
            this.store.dispatch(new UpdateUserDevicePosition(this.posData));
        }

        const updateDevicePosition: Device[] = this.devices.filter((d) => d.type === DeviceTypeEnum.registrator && d.creationType !== CreationType.MNEMONIC && this.posData && !this.posData.find((f) => f.registratorId === d.id));
        if (updateDevicePosition && updateDevicePosition.length) {
            updateDevicePosition.forEach((data) => {
                this.posData.push({
                    registratorId: data.id,
                    position: this.posData.length + 1,
                });
            });
            this.store.dispatch(new UpdateUserDevicePosition(this.posData));
        }

        if (this.userDevicesArray && this.userDevicesArray.length !== this.posData.length) {
            this.posData = this.posData.filter((pos) => {
                const currentRegistrator = this.store.selectSnapshot(DevicesState.getAllRegistrators).find((f) => f.id === pos.registratorId);

                if (currentRegistrator) {
                    return pos;
                }
            });
            this.store.dispatch(new UpdateUserDevicePosition(this.posData));
        }

        this.store.dispatch(new UpdateUserDevicesArray(this.devices));
    }

    private async initRows(): Promise<void> {
        if (!this.devices || !this.devices.length || !this.userDevicesArray) {
            return;
        }
        await this.store.dispatch(new InitUserDevicesArray()).toPromise();
        await this.store.dispatch(new UpdateUserDevicesArray(this.devices)).toPromise();
        await this.store.dispatch(new UpdateUserDeviceArrayStatus(this.store.selectSnapshot(ActiveEventsState.getStatusesEvents))).toPromise();

        this.userDevicesArraySave = [...this.userDevicesArray];
        if (this.userDevicesArray.length && !this.posData.length) {
            this.store.dispatch(new GetUserDevicePosition());
        }
    }

    private editVRegistrator(registrator: UserSensorInterface): void {
        this.router.navigate(['/custom-devices/edit-custom-devices', registrator.id]);
    }

    private openShareDevice(registrator: UserSensorInterface): void {
        this.currentDeviceId = registrator.id;
        this.currentDevice = this.devices.find((d) => d.id === registrator.id);
        this.showShareDevice = true;
    }

    private openDetailRegistrator(device: Device): void {
        this.store.dispatch(new SetCurrentDevice(device.id));
        if (device.type === DeviceTypeEnum.datalogger || device.registratorType === RegistratorTypeEnum.datalogger) {
            this.router.navigate([`details/datalogger-dashboard`], {
                queryParams: { id: device.id },
            });
        } else {
            this.router.navigate([`details/details-device`], {
                queryParams: { id: device.type === DeviceTypeEnum.registrator ? device.id : device.registratorId },
            });
        }
    }

    private async openDeleteDevice(device: Device): Promise<void> {
        if (device.creationType === CreationType.ORIGIN && device.userId === this.user.id) {
            await this.store.dispatch(new GetAssociatedLogicEvents(device.id)).toPromise();
        }

        this.currentDeviceId = device.id;
        this.currentDevice = device;
        this.initRandomCode();
        await this.store.dispatch(new IsShowDeletePopup(true)).toPromise();
    }

    moveToEventsCatalog(): void {
        this.router.navigate(['logical-events'], {
            queryParams: {
                registratorId: this.currentDevice.type === DeviceTypeEnum.registrator ? this.currentDevice.id : this.currentDevice.registratorId,
            },
        });
        this.clearDeleteRegistrator();
    }

    clearDeleteRegistrator(): void {
        this.randomCodeInput = '';
        this.currentDeviceId = null;
        this.currentDevice = null;
        this.isTrashDeleted = false;
        this.store.dispatch(new IsShowDeletePopup(false));
    }

    async deleteDevice(currentDevice: Device): Promise<void> {
        this.randomCodeInput = '';

        currentDevice.creationType === CreationType.ORIGIN ? await this.store.dispatch(new DeleteDevice(currentDevice)).toPromise() : await this.store.dispatch(new DeleteVirtualDevice(currentDevice)).toPromise();

        if (!this.store.selectSnapshot(DevicesState.getErrorListEvent)?.length) {
            await this.clearDeleteRegistrator();
        }
        if (!this.router.url.includes('control') && (this.store.selectSnapshot(DevicesState.getUserDeviceArray) || !this.store.selectSnapshot(DevicesState.getUserDeviceArray).length)) {
            await this.store.dispatch(new GetDevices()).toPromise();
        }

        this.updateDrop(currentDevice);

        this.deviceService.updateDeviceLength.emit();
    }

    updateDrop(currentDevice: Device): void {
        if (currentDevice && currentDevice.type === DeviceTypeEnum.registrator) {
            this.posData = this.posData
                .filter((d) => d.registratorId !== currentDevice.id)
                .map((data, index) => {
                    data.position = index + 1;
                    return data;
                });
            this.store.dispatch(new UpdateUserDevicePosition(this.posData));
        } else if (currentDevice && currentDevice.type !== DeviceTypeEnum.registrator) {
            this.devicesPositionData = this.devicesPositionData
                .filter((d) => d.id !== currentDevice.id)
                .map((data, index) => {
                    data.position = index + 1;
                    return data;
                });
            this.store.dispatch(new UpdateDevicesPosition(this.devicesPositionData));
        }
    }

    private async initInvite(): Promise<any> {
        this.store.dispatch(new GetInvites());
        this.store.dispatch(new GetMailingSenders());
    }

    async refresh(): Promise<any> {
        await this.initRows();
    }

    async changeDeviceAct(data: UserSensorInterface): Promise<void> {
        await this.store.dispatch(new ChangeDeviceActivity(data.id, !data.isActive)).toPromise();
        this.changedDevice = null;
        this.isShowModalActivity = false;
    }

    checkChangeActivityDevice(registrator: UserSensorInterface): void {
        this.isShowModalActivity = true;
        this.currentName = registrator.name ?? registrator.defaultName;
        this.changedDevice = registrator;
    }

    moveToEvents(row: UserSensorInterface): void {
        if (row.creationType === CreationType.VIRTUAL) {
            this.router.navigate(['/journals/events-log'], {
                queryParams: {
                    deviceIdParam: encodeURIComponent(JSON.stringify({ id: null })),
                    alertParam: encodeURIComponent(JSON.stringify({ type: row.status === AlarmTypeEnum.attention ? FilterTypeSystemLogsEnum.fromDeviceOnlyActiveAttention : FilterTypeSystemLogsEnum.fromDeviceOnlyActiveAlarm })),
                },
            });

            return;
        }
        if (row.status !== AlarmTypeEnum.ok) {
            const registratorId = row.type === DeviceTypeEnum.registrator ? row.id : row.registratorId;
            const activeEvent: ActiveEvents = this.store.selectSnapshot(ActiveEventsState.getActiveEvents).find((a) => a.registratorId === registratorId);
            const techEvents: TechnologicEvent[] = activeEvent.events.filter((e) => e.deviceId === row.id);

            const type = this.deviceService.getCurrentSystemLogsEnumForFilter(row.status, techEvents);
            // AlarmTypeEnum.attention ? FilterTypeSystemLogsEnum.attention : FilterTypeSystemLogsEnum.alarm;

            this.router.navigate(['/journals/events-log'], {
                queryParams: {
                    deviceIdParam: encodeURIComponent(JSON.stringify({ id: row.id })),
                    alertParam: encodeURIComponent(JSON.stringify({ type })),
                },
            });
        }
    }

    openSyncPopup(registrator: UserSensorInterface): void {
        if (!this.checkPermission(registrator.id)) {
            this.registratorSyncName = registrator.name ?? registrator.defaultName;
            this.notificationService.onEmit(TooltipStatusEnum.error, false, 'devices.popup.notPermission');
            return;
        }

        this.isShowSyncPopup = true;
        this.registratorForSync = registrator;
        this.initRandomCode();
    }

    private initRandomCode(): void {
        this.randomCodeForSync = Math.floor(1000 + Math.random() * 9000).toString();
        this.randomCodeInput = '';
    }

    clearDataForSync(): void {
        if (this.randomCodeInput === this.randomCodeForSync) {
            this.store.dispatch(new SyncDevice(this.registratorForSync.id));
            this.isShowSyncPopup = false;
            this.randomCodeInput = '';
        }
    }

    private checkPermission(registratorId: string): boolean {
        const roles = this.store.selectSnapshot(PermissionsState.getRoles);
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getRegistrators).find((r) => r.id === registratorId);

        if (!currentRegistrator.roleId) {
            return true;
        }
        const currentRole = roles.find((role) => role.id === currentRegistrator.roleId);

        const data = {
            registrator: currentRegistrator,
            action: ResourceAction.DEVICES_LIST,
            permissions: [MethodPermission.READ, MethodPermission.UPDATE],
        };

        return this.permissionService.checkPermission(currentRole.permissions, data);
    }

    parseAction(data: { event: ActionListItemInterface; item: UserRegistratorInterface }): void {
        const device: Device = this.devices.find((d) => d.id === data.item.registrator.id);
        switch (data.event.action) {
            case ActionListItemEnum.delete:
                this.openDeleteDevice(device);
                break;
            case ActionListItemEnum.detail:
                this.openDetailRegistrator(device);
                break;
            case ActionListItemEnum.share:
                this.openShareDevice(data.item.registrator);
                break;
            case ActionListItemEnum.edit:
                this.editVRegistrator(data.item.registrator);
                break;
            case ActionListItemEnum.active:
                this.checkChangeActivityDevice(data.item.registrator);
                break;
        }
    }

    parseActionDevices(event: { event: ActionListItemInterface; device: UserSensorInterface }): void {
        const device: Device = this.devices.find((d) => d.id === event.device.id);
        switch (event.event.action) {
            case ActionListItemEnum.delete:
                this.openDeleteDevice(device);
                break;
            case ActionListItemEnum.detail:
                this.openDetailRegistrator(device);
                break;
        }
    }

    changeFilter(f: ParamsFilterForClient[]): void {
        if (!f) {
            return;
        }
        const params: Params = this.store.selectSnapshot(UserDeviceParamsState.getParams);

        this.store.dispatch(
            new SetDevicesParams({
                ...params,
                filter: f,
                pagination: { ...params.pagination, currentPage: 1 },
            }),
        );
        this.store.dispatch(new UpdateUserDevicesArray(this.devices));
    }

    private async initRegistratorOptions(): Promise<void> {
        await this.store.dispatch(new InitRegistratorOptions()).toPromise();
    }

    private currentFilterEvent(currentFilter: ParamsFilter): void {
        switch (currentFilter.property) {
            case 'registratorId':
                if (currentFilter.value !== null) {
                    this.filterType = FilterTypePipeEnum.filterDeviceByRegistratorId;
                    this.searchFilter = currentFilter.value;
                }
                break;
            case 'owner':
                if (currentFilter.value && (currentFilter.value as string).length) {
                    this.filterType = FilterTypePipeEnum.filterDeviceByOwner;
                    this.searchFilter = currentFilter.value;
                }

                break;
            case 'isConnect':
                if (currentFilter.value !== null) {
                    this.filterType = FilterTypePipeEnum.filterDeviceByConnect;
                    this.searchFilter = currentFilter.value;
                }

                break;
            case 'status':
                if (currentFilter.value !== null) {
                    this.filterType = FilterTypePipeEnum.filterDeviceByStatus;
                    this.searchFilter = currentFilter.value;
                }

                break;
        }
    }

    copyEventId(event: LogicEvent): void {
        this.clipBoard.copy(event.id);
        this.notificationService.onEmit(TooltipStatusEnum.update, false, 'table.copyToBuffer');
    }
}

import {Params} from 'src/app/app-shared-elements/_interfaces/params.interface';

export class GetMailingSenders {
    static readonly type = '[MailingSenders] GetMailingSenders';
}

export class InitMailingSenderRows {
    static readonly type = '[MailingSenders] InitMailingSenderRows';
}

export class AcceptSender {
    static readonly type = '[MailingSenders] AcceptSender';

    constructor(public data: { id: string, isAccept: boolean }) {
    }
}

export class SetSendersParams {
    static readonly type = '[MailingSenders] SetSendersParams';

    constructor(public params: Params) {

    }
}

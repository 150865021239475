<div class="popup"
     [class.popup--success]="type === popupTypeEnum.success"
     [class.popup--attention]="type === popupTypeEnum.attention"
     [class.popup--error]="type === popupTypeEnum.error">
    <div class="popup__wrapper"
         [style.min-width.px]="minWidth"
    >
        <div class="popup__header">
            <div class="popup__title">{{title | translate}}</div>
            <div *ngIf="isClose" class="popup__close" (click)="close()">
                <svg-icon [src]="CLOSE_ICON_PATH"></svg-icon>
            </div>
        </div>
        <div class="popup__body">
            <ng-content></ng-content>
        </div>
    </div>
</div>

<div class="reports-logs">
    <app-heading [title]="'navigation.reportLog'"></app-heading>

    <app-filters
        [type]="filterItemTypeEnum.event"></app-filters>

    <div class="reports-logs__wrapper">
        <app-tables [currentTableColumns]="reportsLogsColumn$ | async"
            [rows]="reportLogsRows$ | async"
            [configPagination]="(params$ | async).pagination"
            (paginationOutput)="onChangePageLogs($event)"
            (sortOutput)="changeSort($event)"></app-tables>
    </div>
</div>

<div class="limit">
    <div class="limit__title" [translate]="title"></div>
    <div class="limit__limits">
        <div class="limit__item">
            <div class="limit__item-checkbox">
                <app-checkbox
                    [value]="bottomActive"
                    [isDisabled]="isDisabled"
                    [title]="'events.logicalEvents.multiEvent.bottomLimit'"
                    (changeEvent)="setActiveBottomLimit($event)"></app-checkbox>
            </div>
            <!-- <div class="limit__item-title" [translate]="'events.logicalEvents.multiEvent.bottomLimit'"></div> -->
            <div class="limit__item-value">
                <input type="text" class="limit__item-input input"
                    [class.input--require]="(bottomActive && !bottomValue)"
                    [class.input--error]="bottomActive && errorBottomLimit"
                    [disabled]="isDisabled || !bottomActive"
                    [ngModel]="bottomValue"
                    [appConstantValidate]="{type: currentOperanType,
                        value: bottomValue}"
                    (input)="changeBottomLimit($event.target.value)"
                    (validateValue)="setValidateValue($event, 'min')">

            </div>
            <div *ngIf="errorBottomLimit && bottomActive" class="limit__validate-error" [translate]="'events.logicalEvents.multiEvent.errorValidateOperandType'"></div>

        </div>
        <div class="limit__item">
            <div class="limit__item-checkbox">

                <app-checkbox
                    [value]="topActive"
                    [isDisabled]="isDisabled"
                    [title]="'events.logicalEvents.multiEvent.topLimit'"
                    (changeEvent)="setActiveTopLimit($event)"></app-checkbox>
            </div>
            <!-- <div class="limit__item-title" [translate]="'events.logicalEvents.multiEvent.topLimit'"></div> -->
            <div class="limit__item-value">
                <input type="text" class="limit__item-input input"
                    [class.input--require]="(topActive && !topValue)"
                    [class.input--error]="(topActive && errorTopLimit)"
                    [disabled]="isDisabled || !topActive"
                    [ngModel]="topValue"
                    [appConstantValidate]="{type: currentOperanType,
                        value: topValue}"
                    (input)="changeTopLimit($event.target.value)"
                    (validateValue)="setValidateValue($event, 'max')">
            </div>
            <div *ngIf="errorTopLimit" class="limit__validate-error" [translate]="'events.logicalEvents.multiEvent.errorValidateOperandType'"></div>

        </div>
        <div class="limit__item limit__item--ackn">
            <app-checkbox
                [value]="ackn"
                [isDisabled]="isDisabled"
                [title]="'events.logicalEvents.multiEvent.ackn'"
                (changeEvent)="changeAckn($event)"></app-checkbox>
        </div>
    </div>
</div>

<div class="column"
     [class.column--waiting]="row?.savedStatusValue === configurationVariableSavedEnum.wait"
     [class.column--errorSave]="row?.savedStatusValue === configurationVariableSavedEnum.error"
     [class.column--top]="cell?.alignItems === alignItemsEnum.top"
     [class.column--custom-styles]="cell?.styles">

    <div class="column-text" [class.column-text--left]="cell.grow"
         [class.column-text--editable]="cell?.isEditable">

        <div class="column-text__wrapper" (click)="setEdit();"
             [class.column-text__wrapper--right]="cell?.align === columnValueAlignEnum.right"
             [class.column-text__wrapper--center]="cell?.align === columnValueAlignEnum.center"
             [class.column-text__wrapper--pre-icons]="cell?.preIcons"
             [class.column-text__wrapper--link]="cell.mode === columnModeEnum.link && row.isClick">

            <div *ngIf="cell.preIcons && row?.preIcons && row?.preIcons.length" class="column-text__pre-icons">
                <ng-container *ngFor="let icon of row.preIcons">
                    <div *ngIf="icon.cellNames.includes(cell.name)"
                         class="column-text__pre-icons-item"
                         [class.column-text__pre-icons-item--margin]="icon.cellNames.includes('type')"
                         [class.column-text__pre-icons-item--action]="icon.action"
                         (click)="iconClick(icon, row, cell)">
                        <svg-icon [src]="icon.path" [svgStyle]="icon.styles"></svg-icon>

                        <div *ngIf="icon.tooltip && icon.tooltip.length" class="column-text__pre-icons-tooltip">
                            <app-tooltip [side]="icon?.tooltipSide || tooltipSideEnum.right" [value]="icon.tooltip"
                                         [width]="'120px'"></app-tooltip>
                        </div>
                    </div>

                </ng-container>

            </div>


            <div *ngIf="cell.allowHtmlTags && !this.cell?.isClick" class="column-text__value column-text__value--html"
                 [class.column-text__value--wrap]="cell?.wrap"
                 [class.column-text__value--right]="cell?.align === columnValueAlignEnum.right"
                 [class.column-text__value--center]="cell?.align === columnValueAlignEnum.center"
                 [innerHTML]="value | safeHtml" [ngStyle]="cell?.styles ? row.styles : ''">
            </div>


            <div *ngIf="(!cell?.isClick && !cell.allowHtmlTags) || row.disableClick" class="column-text__value"
                 [class.column-text__value--left]="cell?.align === columnValueAlignEnum.left"
                 [class.column-text__value--center]="cell?.align === columnValueAlignEnum.center"
                 [class.column-text__value--hide]="isEdit"
                 [ngStyle]="cell?.styles && row.styles ? row.styles[cell.name] : ''">

                <span>{{cell.isMultilang ? (value | translate) : value}}</span>


            </div>

            <div *ngIf="cell?.isClick && !row.disableClick" class="column-text__value column-text__clickable-value"
                 [class.column-text__value--left]="cell?.align === columnValueAlignEnum.left"
                 [class.column-text__value--center]="cell?.align === columnValueAlignEnum.center"
                 [class.column-text__value--underline]="cell.underline"
                 [class.column-text__value--hide]="isEdit"
                 [ngStyle]="cell?.styles === true ? row.styles[cell.name] : ''"
                 (click)="moveToSmth()">


                <span>{{value && cell.substr ? value.substr(0, cell.substr || value.length) : value}}

                    <div *ngIf="cell?.tooltip && value && row.tooltipValue && !isEdit" class="column-text__tooltip">
                        <div class="column-text__tooltip-value">
                                {{row.tooltipValue[cell.name]}}
                        </div>
                    </div>
                </span>
                <span *ngIf="cell.substr && row[cell.name]">...</span>
            </div>
        </div>

        <div *ngIf="cell.postIcons && row?.postIcons && row?.postIcons.length"
             class="column-text__pre-icons column-text__pre-icons--post">
            <ng-container *ngFor="let icon of row.postIcons">
                <div *ngIf="icon.cellNames.includes(cell.name)"
                     class="column-text__pre-icons-item column-text__pre-icons-item--post"
                     [class.column-text__pre-icons-item--action]="icon.action"
                     (click)="iconClick(icon, row, cell)">
                    <svg-icon [src]="icon.path" [svgStyle]="icon.styles"></svg-icon>

                    <!-- <div *ngIf="icon.tooltip && icon.tooltip.length"
                         class="column-text__pre-icons-tooltip column-text__pre-icons-tooltip--post"> -->
                    <app-tooltip *ngIf="icon.tooltip && icon.tooltip.length" [value]="icon.tooltip"
                                 [side]="icon.tooltipSide" [width]="'120px'"></app-tooltip>
                    <!-- </div> -->
                </div>

            </ng-container>

        </div>

        <div *ngIf="cell?.postText && row?.postText && row?.postText[cell.name].length" class="column-text__post-text"
             [ngStyle]="cell?.postText && row?.postTextStyles && row?.postTextStyles[cell.name]"
        >
            <span>{{row.postText[cell.name] | translate}}</span>
        </div>

        <div *ngIf="cell.isEditable && isEdit" class="column-text__edit">
            <input class="column-text__edit-input"
                   [appInputValidate]="row.type"
                   #valueInput
                   type="text"
                   [value]="cell.isMultilang ? displayValue : value" [maxLength]="row?.editConfig?.maxLength || 200"
                   (change)="valueChanged($event.target.value)">


            <div class="column-text__edit-settings">
                <div class="column-text__edit-settings-item"
                     [appDisabled]="row.editConfig?.isEditName ? null : {type: row.type.restriction ? disabledTypeEnum.restriction : null,
                      data: {value: valueInput.value, restriction: row.type}}"
                     (click)="saveSmth()">
                    <svg-icon [src]="OK_ICON_PATH"></svg-icon>
                </div>
                <div class="column-text__edit-settings-item"
                     (click)="cancelSmth()">
                    <svg-icon [src]="CANCEL_ICON_PATH"></svg-icon>
                </div>

            </div>


        </div>
    </div>

    <div *ngIf="cell.isEditable && !row?.disableClick" class="column-text__editable">
        <svg-icon [src]="EDIT_ICON_PATH"></svg-icon>
    </div>
</div>

import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken, Store} from '@ngxs/store';
import {
    GetTechnologicEventsRows,
    SetAcknowledge,
    SetTechnologicFilter,
    SetUserIdForEventLogsFromAdmin
} from '../actions/technologic-events.action';
import {EventLogsService} from '../../_services/event-logs.service';
import {EventFromServer, TechnologicLogRowInterface} from '../../_interfaces/technologic-log-row.interface';
import {TimeFilterState} from 'src/app/app-shared-elements/_store/states/time-filter.state';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import {HTTP_STATUS} from 'src/app/app-shared-elements/_enums/status.enum';
import {DropdownFilterOptionInterface} from 'src/app/app-shared-elements/filters/interfaces/filter-option.interface';
import {
    Pagination,
    Params,
    ParamsSorted,
    ParamsSortedOrderEnum
} from 'src/app/app-shared-elements/_interfaces/params.interface';
import {TypeFilterEnum} from 'src/app/app-shared-elements/filters/enums/type-filter.enum';
import {CloneDeepService} from 'src/app/app-shared-elements/_services/clone-deep.service';
import {DevicesState} from 'src/app/device-dashboard/_store/states/user-devices.state';
import {Device} from 'src/app/app-shared-elements/_interfaces/Device';
import {initialFilter} from '../../_data/events-data';
import {ParamsService} from 'src/app/app-shared-elements/_services/params.service';
import {SetSkeleton} from 'src/app/app-shared-elements/_store/actions/table.actions';
import {NotificationsService} from 'src/app/app-shared-elements/_services/notifications.service';
import {TooltipStatusEnum} from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import {AlarmTypeEnum} from 'src/app/events/_enums/alarm.enum';
import {AuthState} from '../../../auth/_store/states/auth.state';
import {AdminDevicesState} from '../../../admin/admin-devices/_store/states/admin-devices.state';

export interface TechnologicEventsStateModel {
    eventsRows: TechnologicLogRowInterface[];
    params: Params;
    dropDownFilterOptions: DropdownFilterOptionInterface[];
    userId: string;
}

const TECHNOLOGIC_EVENTS_TOKEN = new StateToken<TechnologicEventsStateModel>('technologicEvents');


const initialCurrentSort: ParamsSorted[] = [{
    property: 'ts',
    order: ParamsSortedOrderEnum.DESC
}];

const initialPagination: Pagination = {
    itemsPerPage: JSON.parse(localStorage.getItem('itemsPerPageLog')) || 20,
    currentPage: 1,
    totalItems: null
};

const dropDownFilterOptions: DropdownFilterOptionInterface[] = [
    { key: 'deviceId', value: 'logs.events.dropdownFilters.registrator', type: TypeFilterEnum.select, property: 'deviceId' },
    { key: 'completionType', value: 'logs.events.dropdownFilters.completionType', type: TypeFilterEnum.select, property: 'completionType' },
];


export const initialParams: Params = {
    filter: initialFilter,
    sorted: initialCurrentSort,
    pagination: initialPagination
};

@State<TechnologicEventsStateModel>({
    name: TECHNOLOGIC_EVENTS_TOKEN,
    defaults: {
        eventsRows: [],
        params: initialParams,
        dropDownFilterOptions,
        userId: null
    }
})
@Injectable()
export class TechnologicEventsState {
    constructor(
        private eventsLogsService: EventLogsService,
        private store: Store,
        private http: HttpClient,
        private cloneDeepService: CloneDeepService,
        private paramsService: ParamsService,
        private notificationsService: NotificationsService
    ) {
    }

    @Selector()
    static getEvents(state: TechnologicEventsStateModel): TechnologicLogRowInterface[] {
        return state.eventsRows;
    }

    @Selector()
    static getFilter(state: TechnologicEventsStateModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }

    @Selector()
    static getDropDownFilterOptions(state: TechnologicEventsStateModel): DropdownFilterOptionInterface[] {
        return state.dropDownFilterOptions;
    }

    @Selector()
    static getUserId(state: TechnologicEventsStateModel): string {
        return state.userId;
    }


    @Action(GetTechnologicEventsRows)
    async getTechnologicalEventRows(ctx: StateContext<TechnologicEventsStateModel>): Promise<void> {
        const state = ctx.getState();
        const decrementPage = state.eventsRows.length < 2;
        const datetime = this.store.selectSnapshot(TimeFilterState.getTimeObj);
        const params: Params = {
            ...state.params,
            filter: this.paramsService.parseParamsFilterForServer(state.params.filter, datetime),
            sorted: state.params.sorted && state.params.sorted.length ? state.params.sorted : initialCurrentSort
        };

        const eventsObj = await this.eventsLogsService.getTechnologicalEventsLog({...params}, decrementPage, state.userId);
        ctx.dispatch(new SetSkeleton(false));

        const devices: Device[] = this.store.selectSnapshot(AuthState.getIsAdmin)
            ? this.store.selectSnapshot(AdminDevicesState.getAdminDevices)
            : this.store.selectSnapshot(DevicesState.getDevices);

        const result: {logs: TechnologicLogRowInterface[], totalItems: number} = await this.eventsLogsService.initRows(eventsObj, devices);

        ctx.setState({
            ...state,
            eventsRows: result.logs,
            params: {
                ...state.params,
                pagination: {
                    ...state.params.pagination,
                    totalItems: result.totalItems
                    // totalItems: eventsObj[1]
                }
            }
        });

        this.store.dispatch(new SetSkeleton(false));

    }

    @Action(SetAcknowledge)
    async setAcknowledge(ctx: StateContext<TechnologicEventsStateModel>, payload: SetAcknowledge): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            eventsRows: state.eventsRows.map(row => row.id === payload.row.id ? {...row, ackn: {...row.ackn, isDisabledBtn: true}} : row)
        });

        const result: ApiResponse = await this.http.post(`api/logic-events-log/acknowledge`, {eventId: payload.row.id})
            .toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                eventsRows: state.eventsRows.map((row: TechnologicLogRowInterface) => {
                    if (row.id !== result.data.id) {
                        return row;
                    }

                    return {
                        ...row,
                        typeHover: AlarmTypeEnum.ok,
                        ackn: {
                            ts: (result.data as EventFromServer).tsAcknowledget,
                            isAcknowledgeable: (result.data as EventFromServer).isAknowledgeable,
                            acknowledgedByUser: (result.data as EventFromServer).acknowledgedByUser,
                            title: 'events.logicalEvents.ackn'
                        },
                        tsCleared: {
                            time: (result.data as EventFromServer).tsCleared ? +(result.data as EventFromServer).tsCleared : null,
                            completionType: (result.data as EventFromServer).completionType,
                            finishedByUser: (result.data as EventFromServer).finishedByUser,
                        },
                    };
                })
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);

            ctx.setState({...state});
        }
    }

    @Action(SetTechnologicFilter)
    async setFilter(ctx: StateContext<TechnologicEventsStateModel>, payload: SetTechnologicFilter): Promise<void> {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            params: this.cloneDeepService.cloneObject(payload.params)
        });

        await ctx.dispatch(new GetTechnologicEventsRows()).toPromise();
    }

    @Action(SetUserIdForEventLogsFromAdmin)
    setUserIdForLogsFromAdmin(ctx: StateContext<TechnologicEventsStateModel>, payload: SetUserIdForEventLogsFromAdmin): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            userId: payload.userId
        });
    }
}

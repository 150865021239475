<div class="column"
     [class.column--top]="cell?.alignItems === alignItemsEnum.top">
    <div class="column-icon"
         [hidden]="row?.isHideActiveIcon">
        <div class="column-icon__wrapper">
            <div class="column-icon__icon"
                 [ngSwitch]="displayValue">

                <ng-template [ngSwitchCase]="columnsIconDataEnum.active">
                    <span (click)="changeCurrentActive(false);">
                        <svg-icon [src]="ACTIVE_ICON_PATH"></svg-icon>
                    </span>
                </ng-template>

                <ng-template [ngSwitchCase]="columnsIconDataEnum.inActive">
                    <span (click)="changeCurrentActive(true);">
                        <svg-icon [src]="INACTIVE_ICON_PATH"></svg-icon>
                    </span>
                </ng-template>

                <ng-template [ngSwitchCase]="columnsIconDataEnum.deleted">
                    <svg-icon [src]="DELETE_ICON_PATH"></svg-icon>
                </ng-template>
            </div>

            <div *ngIf="row?.iconTooltipValue"
                 class="column-icon__tooltip">
                <app-tooltip [template]="true">
                    <span [translate]="row?.iconTooltipValue"></span>
                </app-tooltip>
            </div>
        </div>
    </div>
</div>



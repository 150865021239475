import {EventEmitter, Injectable} from '@angular/core';
import {ColumnEditorType, ColumnsTableInterface, ColumnTypeEnum, ColumnValueAlignEnum, IconInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import {DropdownFilterOptionInterface} from '../../app-shared-elements/filters/interfaces/filter-option.interface';
import {TypeFilterEnum} from '../../app-shared-elements/filters/enums/type-filter.enum';
import {ColumnsActionTypeEnum} from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import {EventMessage, EventMessageIconActionEnum} from '../_interfaces/EventMessage';
import {EventSaveStatus} from '../_enums/event-save-state.enum';

@Injectable({
    providedIn: 'root'
})
export class MessagesService {

    editMessageEvent = new EventEmitter<any>();

    messagesTableColumns: ColumnsTableInterface[] = [

        {
            title: 'events.messagesCatalog.table.text',
            grow: true,
            small: false,
            type: ColumnTypeEnum.editor,
            editorType: ColumnEditorType.textarea,
            name: 'message',
            postIcons: true
        },
        {
            title: 'events.messagesCatalog.table.registrator',
            maxWidth: '300px',
            grow: true,
            type: ColumnTypeEnum.text,
            align: ColumnValueAlignEnum.left,
            name: 'registratorName',
        },
        {
            title: 'events.messagesCatalog.table.messageId',
            maxWidth: '170px',
            minWidth: '160px',
            grow: false,
            type: ColumnTypeEnum.text,
            name: 'id',
            isClick: true,
            preIcons: true,
            substr: 12
        },
        {
            title: 'devices.actions',
            grow: false,
            small: false,
            maxWidth: '100px',
            minWidth: '85px',
            type: ColumnTypeEnum.action,
            name: 'edit',
            actionBtns: [ColumnsActionTypeEnum.actionBtnsCopy, ColumnsActionTypeEnum.actionBtnsDelete]
        }
    ];

    dropDownFilterOptions: DropdownFilterOptionInterface[] = [
        {key: 'name', value: 'events.messagesCatalog.dropdownFilters.name', type: TypeFilterEnum.text, property: 'name'},
        {key: 'registratorId', value: 'events.messagesCatalog.dropdownFilters.registrator', type: TypeFilterEnum.select, property: 'registratorId'}
    ];

    constructor(
        ) {
    }

    setEditMessageEvent(): void {
        this.editMessageEvent.emit();
    }

    getPreIcons(message: EventMessage): IconInterface<EventMessageIconActionEnum>[] {
        if (!message) {
            return [];
        }

        return [
            {
                path: './assets/design/icons/table-operations/copy.svg',
                action: EventMessageIconActionEnum.copyBuffer,
                cellNames: ['id']
            }
        ];
    }

    getPostIcons(message: EventMessage): IconInterface<EventMessageIconActionEnum>[] {
        if (!message) {
            return [];
        }

        if (message.savedStatus === EventSaveStatus.PROCESS) {
            return [
                {
                    path: './assets/design/icons/table-operations/spinner.svg',
                    action: null,
                    cellNames: ['message']
                }
            ];
        }

        return;

    }

}

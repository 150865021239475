export class GetAdminRegistratorLogs {
    static readonly type = '[RegistratorLogs] GetAdminRegistratorLogs';

    constructor(public registratorId: string) {
    }
}

export class DownloadRegistratorLog {
    static readonly type = '[RegistratorLogs] DownloadRegistratorLog';

    constructor(public registratorId: string, public filename: string) {
    }
}

import { ExpressionInterface } from './../../_interfaces/Expression';
import { AlarmTypeEnum } from '../../_enums/alarm.enum';
import { EventSaveStatus } from '../../_enums/event-save-state.enum';
import { EventMessage } from '../../_interfaces/EventMessage';
import { UpdateLogicEventSocketInterface } from '../../_interfaces/update-logic-event-socket.interface';
import { EventSaveFromSocketInterface } from '../../_interfaces/EventSaveFromSocketDto';
import { LogicalEventRowInterface } from '../../_interfaces/LogicalEventRow';
import { Device } from '../../../app-shared-elements/_interfaces/Device';

export interface LogicEvent {
    isAcknowledgeable: boolean; // 0/1
    type: AlarmTypeEnum.attention | AlarmTypeEnum.alarm;
    notifications: boolean;
    expression?: ExpressionInterface;
    eventAlertMessageId: string;
    readonly id?: string;
    name?: string;
    device?: Device;
    resultVariableId: string;
    registratorId: string;
    isActive?: boolean;
    savedStatus: EventSaveStatus;
    created?: string; // iso string
    updated?: string; // iso string
    userCreated?: string; // user email
    userUpdated?: string; // user email
    logicEventType: LogicEventType;
    limitIsActive?: boolean;
    errorMessage?: string;
    readonly errorStatus?: number;
}

export enum LogicEventType {
    default = 'l0',
    attentionDeadlineMin = 'l1',
    alarmDeadlineMin = 'l2',
    attentionDeadlineMax = 'l3',
    alarmDeadlineMax = 'l4',
}

export enum EventModeEnum {
    simple = -1,
    advance = -2,
}
export interface LimitLogicEventUpdateDto {
    name: string;
    variableId: string;
    isAcknowledgeableAlarm: boolean;
    isAcknowledgeableAttention: boolean;
    limitIsActive: boolean;
    [LogicEventType.alarmDeadlineMax]: LimitLogicEventDto;
    [LogicEventType.alarmDeadlineMin]: LimitLogicEventDto;
    [LogicEventType.attentionDeadlineMax]: LimitLogicEventDto;
    [LogicEventType.attentionDeadlineMin]: LimitLogicEventDto;
}

export interface LimitLogicEventDto {
    logicEventType: LogicEventType;
    value: string;
    isActive: boolean;
}

export interface LimitMessagesObjectInterface {
    [LogicEventType.alarmDeadlineMax]: EventMessage;
    [LogicEventType.alarmDeadlineMin]: EventMessage;
    [LogicEventType.attentionDeadlineMax]: EventMessage;
    [LogicEventType.attentionDeadlineMin]: EventMessage;
}

export enum TypeProcessLogicEvents {
    updateLogicEventFromSocket = 'updateLogicEventFromSocket',
    eventSaveFromSocket = 'eventSaveFromSocket',
    changeMultiEventActivity = 'changeMultiEventActivity',
    fromNewActiveEvents = 'fromNewActiveEvents',
}

export interface QueueProcessLogicEvent {
    type: TypeProcessLogicEvents;
    updateLogicEventFromSocket?: UpdateLogicEventSocketInterface;
    eventSaveFromSocket?: EventSaveFromSocketInterface;
    changeMultiEventActivity?: { row: LogicalEventRowInterface; isActive: boolean };
}

import { LangService } from './lang.service';
import { EventEmitter, Injectable } from '@angular/core';
import { DropdownFilterOptionInterface } from '../filters/interfaces/filter-option.interface';
import { TypeFilterEnum } from '../filters/enums/type-filter.enum';
import { UnitRowInterface } from 'src/app/admin/units/_interfaces/units.intefaces';
import { BehaviorSubject } from 'rxjs';
import { Pagination, ParamsFilterForClient, ParamsSorted, ParamsSortedOrderEnum } from '../_interfaces/params.interface';
import { StatusMessagesRowInterface } from '../../admin/event-messages/_interface/status-messages.interface';
import { AdminsListRowsInterface } from '../../admin/admins/_interface/admins-list-rows.interface';
import { TableOperationInterface } from '../tables/_interfaces/table-operation.interface';
import { SelectOptionInterface } from '../_interfaces/select-option.interface';
import { CompanyRowInterface } from 'src/app/admin/company/_interfaces/company.interface';
import { ColumnsActionTypeEnum } from '../_enums/columns-action-type.enum';
import { MailingRecipientRowInterface } from 'src/app/mailing/_interfaces/mailing-recipient-row.interface';
import { UsersRowInterface } from '../../admin/users/_interfaces/users-row.interface';
import { FinanceServicesInterface } from '../../admin/admin-finances/_interfaces/finance-services.interface';
import { TextMail } from '../../admin/mail-message/_interfaces/mail-message.interface';
import { MnemonicRowInterface } from '../../mnemonic-container/_interfaces/mnemonic-row.interface';
import { DevicesControlRowInterface } from '../../admin/devices-control/_interfaces/devices-control-row.interface';
import { CompanyStaffRow } from '../../company-staff/_interfaces/company-staff-row.interface';

@Injectable({
    providedIn: 'root',
})
export class TableService {
    columnActionList: { title: string; property: ColumnsActionTypeEnum; styles?: any }[] = [
        {
            title: 'events.configurator.revision',
            property: ColumnsActionTypeEnum.view,
        },
        {
            title: 'btns.regenerate',
            property: ColumnsActionTypeEnum.isGenerationKey,
        },
        {
            title: 'btns.regenerationPassword',
            property: ColumnsActionTypeEnum.regenerationPassword,
        },
        {
            title: 'btns.edit',
            property: ColumnsActionTypeEnum.actionBtnsEdit,
        },
        {
            title: 'actions.copy',
            property: ColumnsActionTypeEnum.actionBtnsCopy,
        },
        {
            title: 'actions.duplicate',
            property: ColumnsActionTypeEnum.actionBtnsDuplicate,
        },
        {
            title: 'actions.settings',
            property: ColumnsActionTypeEnum.settings,
        },
        {
            title: 'customDevices.table.share',
            property: ColumnsActionTypeEnum.isShare,
        },
        {
            title: 'expeditions.report',
            property: ColumnsActionTypeEnum.actionBtnsReport,
        },
        {
            title: 'expeditions.send',
            property: ColumnsActionTypeEnum.actionBtnsSend,
        },
        {
            title: 'btns.changePassword',
            property: ColumnsActionTypeEnum.passwordRecovery,
        },
        {
            title: 'btns.recovery',
            property: ColumnsActionTypeEnum.recovery,
        },
        {
            title: 'btns.export',
            property: ColumnsActionTypeEnum.export,
        },
        {
            title: 'btns.delete',
            property: ColumnsActionTypeEnum.actionBtnsDelete,
            styles: {
                color: 'var(--deviceDeleteSvg)',
            },
        },
        {
            title: 'company.table.devices',
            property: ColumnsActionTypeEnum.devices,
        },
        {
            title: 'company.table.users',
            property: ColumnsActionTypeEnum.users,
        },
        {
            title: 'btns.download',
            property: ColumnsActionTypeEnum.download,
        },
    ];

    readonly counts: SelectOptionInterface<number, string, number>[] = [
        {
            type: 'text',
            property: 5,
            value: '5',
            key: 5,
        },
        {
            type: 'text',
            property: 10,
            value: '10',
            key: 10,
        },
        {
            type: 'text',
            property: 20,
            value: '20',
            key: 20,
        },
        {
            type: 'text',
            property: 50,
            value: '50',
            key: 50,
        },
    ];

    public tableEvents = new EventEmitter();

    closeEditingEvent = new EventEmitter<
        | MailingRecipientRowInterface
        | UnitRowInterface
        | StatusMessagesRowInterface
        | UsersRowInterface
        | CompanyRowInterface
        | FinanceServicesInterface
        | TextMail
        | MnemonicRowInterface
        | DevicesControlRowInterface
        | CompanyStaffRow
    >();
    updateUserEvent = new EventEmitter<
        | MailingRecipientRowInterface
        | UnitRowInterface
        | AdminsListRowsInterface
        | UsersRowInterface
        | CompanyRowInterface
        | FinanceServicesInterface
        | TextMail
        | MnemonicRowInterface
        | DevicesControlRowInterface
        | CompanyStaffRow
    >();
    rowsLengthAfterFilter: EventEmitter<number> = new EventEmitter<number>();

    tableGroupOperationEvent: EventEmitter<TableOperationInterface> = new EventEmitter<TableOperationInterface>();

    private emptyTableAfterFilter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    emptyTableAfterFilter$ = this.emptyTableAfterFilter.asObservable();

    private editingRow: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    editingRow$ = this.editingRow.asObservable();

    constructor(private langService: LangService) {}

    paginate<T>(rows: T[], configPagination: Pagination): T[] {
        const start = (configPagination.currentPage - 1) * configPagination.itemsPerPage;
        const end = start + configPagination.itemsPerPage;
        return rows.slice(start, end);
    }

    setEmptyTableAfterFilter(value: boolean): void {
        this.emptyTableAfterFilter.next(value);
    }

    dropDownFilter<T>(items: T[], dropDownFilter: DropdownFilterOptionInterface): T[] {
        if (!dropDownFilter?.filterValue) {
            return items;
        }

        return items.filter((item, f, g) => {
            if (item[dropDownFilter.property]) {
                if (dropDownFilter.type === TypeFilterEnum.text) {
                    let value;

                    if (typeof item[dropDownFilter.property] === 'object' && item[dropDownFilter.property].values) {
                        const currentLang = this.langService.get(item[dropDownFilter.property]).value;
                        value = currentLang;
                    } else {
                        value = String(item[dropDownFilter.property] || '');
                    }

                    return value.toLowerCase().indexOf(dropDownFilter.filterValue.trim().toLowerCase()) !== -1;
                }

                if (dropDownFilter.type === TypeFilterEnum.enum) {
                    const value = String(item[dropDownFilter.property] || '');
                    if (+dropDownFilter.filterValue === -1) {
                        return true;
                    }
                    return value.trim() === dropDownFilter.filterValue.trim();
                }

                if (dropDownFilter.type === TypeFilterEnum.datetime) {
                    const value = new Date(item[dropDownFilter.property]);
                    const from = dropDownFilter.filterValue.from;
                    const to = dropDownFilter.filterValue.to;

                    if (dropDownFilter.filterValue.from) {
                        const performFrom = new Date(from);

                        if (value < performFrom) {
                            return false;
                        }
                    }

                    if (dropDownFilter.filterValue.to) {
                        const performTo = new Date(to);

                        if (value > performTo) {
                            return false;
                        }
                    }

                    return true;
                }
            }
            return false;
        });
    }

    sortRows<array>(rows: array[], sort: ParamsSorted): array[] {
        if (!sort || sort?.order === ParamsSortedOrderEnum.DESC) {
            return rows;
        }

        return rows.sort(this.sortByFiled(sort.property, sort.order));
    }

    sortByFiled(field, order: ParamsSortedOrderEnum): any {
        if (order === ParamsSortedOrderEnum.DESC) {
            return (a, b) => (a[field] > b[field] ? 1 : -1);
        }

        return (a, b) => (a[field] < b[field] ? 1 : -1);
    }

    updateEditingRow(row: any): void {
        this.editingRow.next(row);
    }

    compareFilters(filters: ParamsFilterForClient[], savedFilters: ParamsFilterForClient[]): boolean {
        try {
            if (!savedFilters || !filters) {
                return false;
            }

            if (filters.length !== savedFilters.length) {
                return false;
            }

            let isCompare = true;

            filters.forEach((f) => {
                if (!isCompare) {
                    return;
                }
                const currentOldFilter = savedFilters.find((sFilter) => sFilter.title === f.title);
                if (!currentOldFilter) {
                    isCompare = false;
                }

                if ((f.relationTrue && f.relationTrue.length && !currentOldFilter.relationTrue) || f.relationTrue.length !== currentOldFilter.relationTrue.length) {
                    isCompare = false;
                }
            });

            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }
}

export const compareFilters = (filters: ParamsFilterForClient[], savedFilters: ParamsFilterForClient[]): boolean => {
    try {
        const parseFitlers = filters.map((f) => {
            return {
                property: f.property,
                type: f.type,
                relationTrue: f.relationTrue
                    ? f.relationTrue.map((r) => {
                          return {
                              property: r.property,
                              type: r.type,
                              value: 0,
                          };
                      })
                    : [],
                relationFalse: f.relationFalse
                    ? f.relationFalse.map((r) => {
                          return {
                              property: r.property,
                              type: r.type,
                              value: 0,
                          };
                      })
                    : [],
            };
        });

        const parseSavedFitlers = savedFilters.map((f) => {
            return {
                property: f.property,
                type: f.type,
                relationTrue: f.relationTrue
                    ? f.relationTrue.map((r) => {
                          return {
                              property: r.property,
                              type: r.type,
                              value: 0,
                          };
                      })
                    : [],
                relationFalse: f.relationFalse
                    ? f.relationFalse.map((r) => {
                          return {
                              property: r.property,
                              type: r.type,
                              value: 0,
                          };
                      })
                    : [],
            };
        });

        const result = JSON.stringify(parseFitlers) === JSON.stringify(parseSavedFitlers);
        return result;
    } catch (e) {
        console.log(e);
        return false;
    }
};

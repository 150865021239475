import {LayoutEnum} from '../../_enums/layout.enum';
import {SidebarStatusEnum} from '../../_enums/sidebar-status.enum';

export class SetGroupLayout {
    static readonly type = '[Layout] SetGroupLayout';

    constructor(public layout: LayoutEnum) {
    }
}

export class SetIsSidebar {
    static readonly type = '[Layout] SetIsSidebar';

    constructor(public value: boolean) {
    }
}

export class SetIsDisplayHeight {
    static readonly type = '[Layout] SetIsDisplayHeight';

    constructor(public value: boolean) {

    }
}

export class SetFullScreen {
    static readonly type = '[Layout] SetFullScreen';

    constructor(public value: boolean) {

    }
}

export class SetSidebarStatus {
    static readonly type = '[Layout] SetSidebarStatus';

    constructor(public status: SidebarStatusEnum) {
    }
}

export class SetIsChart {
    static readonly type = '[Layout] SetIsChart';

    constructor(public value: boolean) {
    }
}

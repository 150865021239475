import { BehaviorSubject } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { Variable } from '../../../../app-shared-elements/_interfaces/Variable';
import { VariableWithArchive } from 'src/app/app-shared-elements/_interfaces/VariableWithArchive';
import { VariableGroupSettings } from 'src/app/app-shared-elements/_interfaces/VariableGroupSettings';
import { DataChartVariable } from 'src/app/groups/_interfaces/chart-variable-data.interface';
import { AlarmTypeEnum } from 'src/app/events/_enums/alarm.enum';
import { EventTypeEnum } from 'src/app/app-shared-elements/_enums/event-type.enum';
import {
    ArchiveChartInterface,
    ArchiveRow,
    LimitsInterface,
} from 'src/app/app-shared-elements/_interfaces/ArchiveChart';
import { TranslateService } from '@ngx-translate/core';
import { IconInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { RereadingEnum } from 'src/app/groups/_enums/rereading.enum';
import { LogicEventType } from 'src/app/events/logical-events/_interface/LogicEvent';
import { Store } from '@ngxs/store';
import { TimeFilterState } from 'src/app/app-shared-elements/_store/states/time-filter.state';
import { MultiEventService } from 'src/app/events/_services/multi-event.service';
import { CloneDeepService } from 'src/app/app-shared-elements/_services/clone-deep.service';
import { ParamsTime, ParamsTimeTypeEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { SocketEvent } from 'src/app/app-shared-elements/_enums/socket-event.enum';
import { SubscribeOnArchiveOfClientType } from 'src/app/groups/_enums/subscribe-on-archive-of-client.enum';
import {
    SubscribeOnArchiveOfSocketData,
} from 'src/app/groups/_interfaces/subscribe-on-archive-of-socket-data.interface';
import { SocketService } from 'src/app/app-shared-elements/_services/socket.service';
import { ChartModeEnum } from '../../../../app-shared-elements/_enums/chart-mode.enum';

@Injectable({
    providedIn: 'root',
})
export class ChartService {
    public initChartEvent = new EventEmitter();

    public master;
    public detail;
    public minMaxSelectedValuesSubject = new BehaviorSubject(null);

    // public selectedVariables: number[] = [];

    public periodArchive: Variable;

    readonly MIN_GAPS_DATA_LENGTH = 2;

    constructor(
        private translateService: TranslateService,
        private store: Store,
        private multiEventService: MultiEventService,
        private cloneDeepService: CloneDeepService,
        private socketService: SocketService,
    ) {}

    public updateSelectedVariablesOnChart(selectedVariables: string[]): void {
        if (!this.detail) {
            return;
        }

        const series = this.detail.series;

        series.forEach((currentSeries) => {
            // if empty arr - show all series by default
            const varActive = !selectedVariables || selectedVariables.length === 0 ? true : !!selectedVariables.find((x) => x === currentSeries.userOptions.variableId);

            varActive ? currentSeries.show() : currentSeries.hide();
            currentSeries.lwSeleced = varActive;
        });
    }

    public getMinMaxPointsOfSeries(range): any[] {
        const series = this.master.series;
        let min = 0;
        let max = 0;
        const allPlots = [];
        let left = range.left;
        let right = range.right;

        series.forEach((seriesObject) => {
            allPlots.push(...seriesObject.data);
        });

        if (range.offsetX) {
            left = left - range.offsetX;
            right = right - range.offsetX;
        }

        allPlots.forEach((plot) => {
            if (!plot) {
                return;
            }

            if (left <= plot.clientX && (plot.x < min || min === 0)) {
                min = plot.x;
            }

            if (right >= plot.clientX && plot.x > max) {
                max = plot.x;
            }
        });

        return [min, max];
    }

    public async addBreakpointsToArchive(data): Promise<any[]> {
        const [...records1]: any[] = data;
        const [...records]: any[] = records1;
        let previousRecord = null;
        const result = [];

        records.map((record, index, array) => {
            if (!previousRecord) {
                previousRecord = record;
                return;
            }
            if (record.value === null && previousRecord.value === null) {
                return;
            }

            if (index === 1) {
                // было 1
                result.push(array[0]);
            }
            if (record.drawType === true) {
                let nullRecordPrevious2 = { ts: record.ts - 1000, value: null };
                result.push(nullRecordPrevious2);
                result.push(record);
                nullRecordPrevious2 = { ts: record.ts + 1000, value: null };
                result.push(nullRecordPrevious2);
            } else if (record.drawType === '-1') {
                const nullRecordPrevious2 = { ts: record.ts - 1000, value: null };
                result.push(nullRecordPrevious2);
                result.push(record);
            } else if (record.drawType === '1') {
                const nullRecordPrevious2 = { ts: record.ts + 1000, value: null };
                result.push(record);
                result.push(nullRecordPrevious2);
            } else {
                result.push(record);
            }
            previousRecord = record;
            return record;
        });
        return result;
        // .sort((a, b) => {
        //     return a[0] > b[0];
        // });
    }

    public async getVariableWithArchive(settings: VariableGroupSettings[], variable: Variable, archive: ArchiveRow[], start, end, archivePeriodVariable: Variable, mode?: ChartModeEnum): Promise<VariableWithArchive> {
        if (archivePeriodVariable) {
            archivePeriodVariable.variableUpdates = archivePeriodVariable.variableUpdates.map((item) => {
                item.ts = +item.ts * 1000;
                return item;
            });

            archivePeriodVariable.variableUpdates = archivePeriodVariable.variableUpdates.sort((a, b) => (a.ts > b.ts ? 1 : b.ts > a.ts ? -1 : 0));
        }

        const variableChartSetting: VariableGroupSettings = settings.find((item) => item.variableId === variable.id);
        if (!variableChartSetting) {
            return null;
        }

        const variableName = variable.customName ? variable.customName : variable.name;
        const unit = variable.unitName ? variable.unitName : '';

        const chartVariable: VariableWithArchive = {
            axis: variableChartSetting.axisId,
            id: variable.originVariableId || variable.id,
            name: variableName,
            line: variableChartSetting.lineType,
            width: variableChartSetting.lineWidth,
            color: variableChartSetting.color,
            originalVariableId: variable.id,
            unit,

            data: [
                {
                    ts: start,
                    value: null,
                    isHole: null,
                },
                ...archive
                    .map((archiveRow) => {
                        let currentArchiveValue;
                        currentArchiveValue = archiveRow.archiveValues.find((archiveValue) => archiveValue.variableId === variable.originVariableId || archiveValue.variableId === variable.id);

                        const obj: DataChartVariable = {
                            ts: +archiveRow.ts,
                            value: +currentArchiveValue.value,
                            isHole: currentArchiveValue.isHole,
                            isPing: currentArchiveValue.isPing,
                            drawType: currentArchiveValue.drawType,
                            message: currentArchiveValue.message,
                            values: currentArchiveValue.values,
                            maxValue: currentArchiveValue.maxValue,
                            minValue: currentArchiveValue.minValue,
                            alarmType: currentArchiveValue.eventType === EventTypeEnum.logic ? currentArchiveValue.alarmType : undefined,
                            eventType: currentArchiveValue.eventType,
                        };
                        return obj;
                    })
                    .sort((a, b) => a.ts - b.ts),
            ],
        };

        if (chartVariable.data[chartVariable.data.length - 1].isPing && mode !== ChartModeEnum.hole) {
            chartVariable.data.push({
                ts: end,
                value: null,
                isHole: null,
            });
        }
        chartVariable.data = await this.addBreakpointsToArchive(chartVariable.data);
        chartVariable.eventType = this.getEventType(archive);
        chartVariable.alarmType = this.getAlarmType(archive);

        return chartVariable;
    }

    async getLimits(archiveObj: ArchiveChartInterface, variable: Variable, variablesWithArchive: VariableWithArchive[], settings: VariableGroupSettings[], variables: Variable[]): Promise<VariableWithArchive[]> {
        if (!archiveObj.archive.length) {
            return [];
        }
        const limits: { type: LogicEventType; data: any[] }[] = this.parseLimitValues(archiveObj.limits);

        return await this.getLimitWMA(
            settings.filter((s) => variables.find((v) => v.id === s.variableId) && s.showLimitAxis).filter((s) => !!s),
            limits,
            variable,
            variablesWithArchive,
        );
    }

    parseLimitValues(limits: LimitsInterface[]): { type: LogicEventType; data: any[] }[] {
        return [
            {
                type: LogicEventType.alarmDeadlineMin,
                data: limits
                    .filter((f) => !!f)
                    .map((item) => {
                        return {
                            ts: item.ts * 1000,
                            variableId: item.variableId,
                            value: item.value.split(',')[1],
                        };
                    }),
            },
            {
                type: LogicEventType.alarmDeadlineMax,
                data: limits
                    .filter((f) => !!f)
                    .map((item) => {
                        return {
                            ts: item.ts * 1000,
                            variableId: item.variableId,
                            value: item.value.split(',')[3],
                        };
                    }),
            },
        ];
    }

    private getEventType(archive: ArchiveRow[]): EventTypeEnum {
        let eventType: EventTypeEnum;
        archive.find((item) =>
            item.archiveValues.find((value) => {
                if (value.eventType !== undefined) {
                    eventType = value.eventType;
                }
            }),
        );
        switch (eventType) {
            case EventTypeEnum.logic:
                return EventTypeEnum.logic;
            case EventTypeEnum.status:
                return EventTypeEnum.status;
            default:
                return;
        }
    }

    private getAlarmType(archive: ArchiveRow[]): AlarmTypeEnum {
        let alarmType: AlarmTypeEnum;

        archive.find((item) =>
            item.archiveValues.find((value) => {
                if (value.alarmType !== undefined) {
                    alarmType = value.alarmType;
                }
            }),
        );
        switch (alarmType) {
            case AlarmTypeEnum.alarm:
                return AlarmTypeEnum.alarm;
            case AlarmTypeEnum.attention:
                return AlarmTypeEnum.attention;
            default:
                return;
        }
    }

    async getTranslateMonth(lnKeys: string[]): Promise<string[]> {
        return await Promise.all(
            lnKeys.map(async (key) => {
                return await this.translateService
                    .get(key)
                    .toPromise()
                    .catch((e) => console.log(e));
            }),
        );
    }

    getRereadingItems(): IconInterface<RereadingEnum>[] {
        return [
            {
                path: './assets/design/icons/chart/rereading.svg',
                cellNames: [],
                action: RereadingEnum.rereading,
                tooltip: 'charts.rereading.reread',
            },
            {
                path: './assets/design/icons/chart/detail.svg',
                cellNames: [],
                action: RereadingEnum.detail,
                tooltip: 'charts.rereading.detail',
            },
        ];
    }

    async getLimitWMA(
        settings: VariableGroupSettings[],
        limits: {
            type: LogicEventType;
            data: { ts: number; value: string }[];
        }[],
        variable: Variable,
        variablesWithArchive: VariableWithArchive[],
    ): Promise<VariableWithArchive[]> {
        try {
            const result: VariableWithArchive[] = [];
            const variableChartSetting: VariableGroupSettings = settings.find((item) => item.variableId === variable.id);
            if (!variableChartSetting || !limits?.length) {
                return [];
            }

            const currentVWA = variablesWithArchive.find((vwa) => vwa.id === variable.id);
            if (limits) {
                for (const item of limits) {
                    const limitWMA = await this.getLimitVariableWithArchive(currentVWA, item.data, item.type as LogicEventType);
                    if (limitWMA) {
                        result.push(limitWMA);
                    }
                }
            }
            return result;
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    async getLimitVariableWithArchive(
        variableWithArchive: VariableWithArchive,
        limitValue: {
            ts: number;
            value: string;
        }[],
        limitType?: LogicEventType,
    ): Promise<VariableWithArchive> {
        if (!limitValue?.length || !variableWithArchive) {
            return;
        }

        try {
            const subName = await this.translateService.get(`charts.${limitType}`).toPromise();

            let newData: ArchiveRow[] = [];
            const timeObj = this.store.selectSnapshot(TimeFilterState.getTimeObj);
            if (!variableWithArchive.data || !variableWithArchive.data.length) {
                newData = [
                    {
                        ts: +timeObj.from,
                        value: +limitValue[0].value,
                        eventType: undefined,
                        alarmType: undefined,
                        isHole: undefined,
                    },
                    {
                        ts: +timeObj.to,
                        value: +limitValue[limitValue.length - 1].value,
                        eventType: undefined,
                        alarmType: undefined,
                        isHole: undefined,
                    },
                ];
            }

            return {
                ...variableWithArchive,
                alarmType: null,
                eventType: null,
                width: 1,
                line: 'LongDash',
                color: variableWithArchive.color,
                isLimitVariable: true,
                data: newData.length
                    ? newData
                    : variableWithArchive.data
                          .filter((f) => limitValue.filter((l) => l.ts > f.ts))
                          .map((item) => ({
                              ts: item.ts,
                              value: this.getLimitValue(item, limitValue),
                              eventType: undefined,
                              alarmType: undefined,
                              isHole: undefined,
                          })),
                limitValue: +limitValue[limitValue.length - 1].value,
                limitType: limitType,
                name: variableWithArchive.name + ` ${subName}`,
            };
        } catch (e) {
            console.log(e);
            return;
        }
    }

    getLimitValue(item: ArchiveRow, limitValue: { ts: number; value: string }[]): number {
        let value = null;
        limitValue.forEach((limit) => {
            if (item.ts >= limit.ts) {
                value = +limit.value;
            }

            if (limit.ts && item.ts >= limit.ts && !limit.value.length) {
                value = null;
            }
        });

        return value;
    }

    separationEventVariablesWithArchive(variablesWithArchive: VariableWithArchive[]): VariableWithArchive[] {
        const result: VariableWithArchive[] = [];
        variablesWithArchive.forEach((v) => {
            if (!v.isErrorMode) {
                result.push(v);
                return;
            }

            result.push(this.getLogicalEventVariableWithArcive(this.cloneDeepService.cloneObject(v), AlarmTypeEnum.alarm));
            result.push(this.getLogicalEventVariableWithArcive(this.cloneDeepService.cloneObject(v), AlarmTypeEnum.attention));

            result.push(this.getStatusEventVariableWithArcive(this.cloneDeepService.cloneObject(v)));
        });

        return result;
    }

    getLogicalEventVariableWithArcive(variablesWithArchive: VariableWithArchive, alarmType: AlarmTypeEnum): VariableWithArchive {
        variablesWithArchive.data = variablesWithArchive.data.filter((item) => (item.alarmType !== alarmType && item.eventType === EventTypeEnum.logic) || item.value === null);
        variablesWithArchive.alarmType = alarmType === AlarmTypeEnum.attention ? AlarmTypeEnum.alarm : AlarmTypeEnum.attention;
        variablesWithArchive.eventType = EventTypeEnum.logic;

        return variablesWithArchive;
    }

    getStatusEventVariableWithArcive(variablesWithArchive: VariableWithArchive): VariableWithArchive {
        // variablesWithArchive.data = variablesWithArchive.data.filter(item => (item.alarmType !== EventTypeEnum.logic) && (item.alarmType !== undefined));
        variablesWithArchive.data = variablesWithArchive.data.filter((item) => item.eventType === EventTypeEnum.status || item.value === null);
        variablesWithArchive.eventType = EventTypeEnum.status;
        return variablesWithArchive;
    }

    setNewRecordInBinaryChart(chart, archive, timeObj: ParamsTime): void {
        if (!archive[0].length && !archive[1].length && !archive[2].length && !archive[3].length) {
            return;
        }

        chart.xAxis[0].setExtremes(Date.now() - timeObj.time - 90000, Date.now() + 90000);

        chart.series.map((series) => {
            archive.forEach((archive) => {
                archive
                    .filter((f) => f.variableId === series.userOptions.variableId)
                    .map((data, index) => {
                        series.data.forEach((item) => {
                            if (item.x && item.x2 && +item.index === index) {
                                item.x = +data.x;
                                item.x2 = +data.x2;
                                item.value = data.value;
                                item.color = this.getDataColor(data);
                            }
                        });
                    });
            });
        });

        chart.series.forEach((series) => {
            series.data.forEach((item) => {
                if (item.isEmpty) {
                    item.x = timeObj.type === ParamsTimeTypeEnum.ALL_TIME ? Date.now() - timeObj.time : timeObj.from;
                    item.x2 = timeObj.type === ParamsTimeTypeEnum.ALL_TIME ? Date.now() + 1000 : timeObj.to;
                }
            });
        });

        console.log(chart);

        chart.redraw();
    }

    getDataColor(item): string {
        switch (item.value) {
            case '0':
            case 'e1':
            case 'e14': {
                return 'transparent';
            }
            case '1':
            case 'e13': {
                return '#3DB557';
            }
            case '-1': {
                return '#000';
            }
        }
    }

    setNewRecordInChart(charts, archive: ArchiveRow[], archivePeriod: number, timeObj: ParamsTime, limits: LimitsInterface[]): void {
        charts.map((chart) => {
            chart.series.map((series, i) => {
                const isDeleteOldPointNeeded = Date.now() - this.findFirstPointTime([series]) > timeObj.time;
                let records = [];

                series.data.forEach((item) => {
                    if (item) {
                        records.push([item.x, item.y]);
                    }
                });

                records = records.sort((a, b) => a[0] - b[0]);
                if (series.data.length < 2) {
                    records.push([Date.now() - archivePeriod, null]);
                    records.push([Date.now(), null]);
                }

                archive
                    .filter((archiveRow: ArchiveRow) => archiveRow.archiveValues.find((archiveValue) => archiveValue.variableId === series.userOptions.variableId))
                    .map((archiveRow: ArchiveRow, index) => {
                        if (series.options.limitValue !== undefined) {
                            const limitValues = this.parseLimitValues(limits);
                            const currentLimit = limitValues.find((f) => f.data.find((f) => f.variableId === series.userOptions.variableId) && f.type === series.options.limitType);
                            const currentLimitData = currentLimit.data.find((f) => f.variableId === series.userOptions.variableId);
                            series.addPoint(
                                [
                                    +archiveRow.ts,
                                    // currentLimit && currentLimit.data[currentLimit.data.length - 1].value.length ? +currentLimit.data[currentLimit.data.length - 1].value : null,
                                    currentLimitData ? +currentLimitData.value : null,
                                    {
                                        message: null,
                                        values: null,
                                        alarmType: null,
                                        eventType: null,
                                    },
                                ],
                                false, // no redraw chart series
                                isDeleteOldPointNeeded,
                            ); // do delete oldest point on series

                            return;
                        }

                        const currentArchiveValue = archiveRow.archiveValues.find((archiveValue) => archiveValue.variableId === series.userOptions.variableId);

                        if (!archiveRow.archiveValues[0].drawType && !series.data[series.data.length - 1]?.y && !series.data[series.data.length - 1]?.isHole) {
                            series.data[series.data.length - 1]?.remove(false, false);
                        }

                        if (+archiveRow.ts - records[records.length - 1][0] > archivePeriod && index === 0) {
                            // (1000 * 60 * +currentValue * 1.5)
                            series.addPoint(
                                [
                                    +records[records.length - 1][0] + 1000,
                                    null,
                                    {
                                        message: null,
                                        values: null,
                                        alarmType: null,
                                        eventType: null,
                                    },
                                ],
                                false, // no redraw chart series
                                isDeleteOldPointNeeded, // do delete oldest point on series
                            );
                        }

                        if (currentArchiveValue.drawType === true) {
                            series.addPoint(
                                [
                                    +archiveRow - 1000,
                                    null,
                                    {
                                        message: null,
                                        values: null,
                                        alarmType: null,
                                        eventType: null,
                                    },
                                ],
                                false, // no redraw chart series
                            );

                            series.addPoint(
                                [
                                    +archiveRow.ts,
                                    +currentArchiveValue.value,
                                    {
                                        message: null,
                                        values: null,
                                        alarmType: null,
                                        eventType: null,
                                    },
                                ],
                                false, // no redraw chart series
                                isDeleteOldPointNeeded, // do delete oldest point on series
                            );

                            series.addPoint(
                                [
                                    +archiveRow + 1000,
                                    null,
                                    {
                                        message: null,
                                        values: null,
                                        alarmType: null,
                                        eventType: null,
                                    },
                                ],
                                false, // no redraw chart series
                            );
                        } else if (currentArchiveValue.drawType === '-1') {
                            series.addPoint(
                                [
                                    +archiveRow - 1000,
                                    null,
                                    {
                                        message: null,
                                        values: null,
                                        alarmType: null,
                                        eventType: null,
                                    },
                                ],
                                false, // no redraw chart series
                            );

                            series.addPoint(
                                [
                                    +archiveRow.ts,
                                    +currentArchiveValue.value,
                                    {
                                        message: null,
                                        values: null,
                                        alarmType: null,
                                        eventType: null,
                                    },
                                ],
                                false, // no redraw chart series
                                isDeleteOldPointNeeded, // do delete oldest point on series
                            );
                        } else if (currentArchiveValue.drawType === '1') {
                            series.addPoint(
                                [
                                    +archiveRow.ts,
                                    +currentArchiveValue.value,
                                    {
                                        message: null,
                                        values: null,
                                        alarmType: null,
                                        eventType: null,
                                    },
                                ],
                                false, // no redraw chart series
                                isDeleteOldPointNeeded, // do delete oldest point on series
                            );

                            series.addPoint(
                                [
                                    +archiveRow + 1000,
                                    null,
                                    {
                                        message: null,
                                        values: null,
                                        alarmType: null,
                                        eventType: null,
                                    },
                                ],
                                false, // no redraw chart series
                            );
                        } else {
                            series.addPoint(
                                [
                                    +archiveRow.ts,
                                    +currentArchiveValue.value,
                                    {
                                        message: null,
                                        values: null,
                                        alarmType: null,
                                        eventType: null,
                                    },
                                ],
                                false, // no redraw chart series
                                isDeleteOldPointNeeded, // do delete oldest point on series
                            );
                        }

                        //
                        // series.addPoint(
                        //     [
                        //         +archiveRow.ts,
                        //         +currentArchiveValue.value,
                        //         {
                        //             message: null,
                        //             values: null,
                        //             alarmType: null,
                        //             eventType: null,
                        //         },
                        //     ],
                        //     false, // no redraw chart series
                        //     isDeleteOldPointNeeded, // do delete oldest point on series
                        // );
                    });
            });
            chart.redraw(); // redraw chart after adding all the points
        });
    }

    private findFirstPointTime(chartOrSeries): number {
        let firstPointTime = Date.now();

        const arr = Array.isArray(chartOrSeries) ? chartOrSeries : chartOrSeries.series;
        arr.map((item) => {
            item.data.map((point, index) => {
                if (!point && index) {
                    if (+item.data[index - 1] && +item.data[index - 1].x < firstPointTime && String(item.data[index - 1].y) !== 'null') {
                        firstPointTime = +item.data[index - 1].point.x;
                    }
                    return;
                }

                if (+point.x < firstPointTime && String(point.y) !== 'null') {
                    firstPointTime = +point.x;
                }
            });
        });

        return firstPointTime;
    }

    findLastPointTimeByBinary(chart, timeObj: ParamsTime, variable: Variable): number {
        let lastPointTime = 0;

        // chart.series.map((item) => {
        //     if (item.userOptions.variableId === variable.id) {
        //         item.data.map((point) => {
        //             if (point && point.x && point.x2 && point.x2 > lastPointTime) {
        //                 lastPointTime = +point.x2;
        //             }
        //         });
        //     }
        // });

        chart.series.map((item) => {
            if (item.userOptions.variableId === variable.id) {
                console.log(item.data);
                console.log(item.data.sort((a, b) => +a.x - +b.x));
                lastPointTime = item.data.sort((a, b) => +a.x - +b.x)[0].x;
            }
        });

        if (!lastPointTime) {
            lastPointTime = timeObj.from;
        }

        console.log(lastPointTime);
        return lastPointTime;
    }

    findLastPointTime(chartOrSeries, timeObj: ParamsTime, variable?: Variable): number {
        let lastPointTime = 0;

        if (!chartOrSeries) {
            return lastPointTime;
        }

        const arr = Array.isArray(chartOrSeries) ? chartOrSeries : chartOrSeries.series;
        arr.map((item) => {
            if (item.userOptions.variableId === variable.id && !item.userOptions.isLimitVariable) {
                item.data.map((point) => {
                    if (point && point.y && +point.x > lastPointTime) {
                        lastPointTime = +point.x;
                    }
                });
            }
        });

        if (!lastPointTime) {
            lastPointTime = timeObj.from;
        }

        return lastPointTime;
    }

    parseHoles(archive: VariableWithArchive[], timeObj: ParamsTime): VariableWithArchive[] {
        let countValue = 5;
        const end = timeObj.to;

        // archive = archive.map((arch) => {
        //     console.log(arch.data);
        //     console.log(arch.data[arch.data.length - 1]);
        //     return {
        //         ...arch,
        //         data: arch.data
        //             .map((item) => {
        //                 if (
        //                     item.ts === arch.data[arch.data.length - 1].ts &&
        //                     !arch.data[arch.data.length - 1].value &&
        //                     !arch.data[arch.data.length - 1].isHole
        //                 ) {
        //                     return;
        //                 }
        //
        //                 return item;
        //             })
        //             .filter((f) => !!f),
        //     };
        // });
        // console.log(archive);
        return archive
            .map((point) => {
                const resArch: ArchiveRow[] = [
                    {
                        ts: timeObj.from,
                        value: null,
                    },
                ];

                point.data
                    .filter((item) => item.value !== null && !item.isPing)
                    .sort((a, b) => +a.ts - +b.ts)
                    .forEach((item, index, array) => {
                        const value = countValue;
                        if (index === 0) {
                            if (!item.isPing && !item.drawType) {
                                return;
                            } else {
                                resArch[0].value = value;
                                if (!item.isHole) {
                                    resArch.push({ ts: item.ts, value });
                                    resArch.push({ ts: item.ts + 1000, value: null });
                                } else if (array[index + 1]?.drawType === false) {
                                    resArch.push({ ts: array[index + 1].ts, value });
                                    resArch.push({ ts: array[index + 1].ts + 1000, value: null });
                                } else {
                                    return;
                                }
                            }
                        }
                        if (index > 0 && index < array.length - 1) {
                            if (resArch[resArch.length - 1].value === null) {
                                // Мы ищем начало нового разрыва
                                if (!item.drawType) {
                                    // если текущая точка не дырка
                                    return;
                                } else {
                                    // Если текущая точка дырка
                                    if (item.isHole) {
                                        resArch.push({ ts: array[index - 1].ts, value });
                                    } else {
                                        resArch.push({ ts: item.ts, value });
                                    }
                                }
                            } else {
                                // Мы ищем конец текущего разрыва
                                if (!item.drawType) {
                                    // если текущая точка не дырка
                                    resArch.push({ ts: item.ts, value });
                                    resArch.push({ ts: item.ts + 1000, value: null });
                                } else {
                                    // Если текущая точка дырка
                                    if (!item.isHole) {
                                        resArch.push({ ts: item.ts, value });
                                        resArch.push({ ts: item.ts + 1000, value: null });
                                    } else {
                                        return;
                                    }
                                }
                            }
                        }
                        if (index === array.length - 1) {
                            if (resArch[resArch.length - 1].value === null) {
                                // Мы ищем начало нового разрыва
                                if (!item.isPing && !item.drawType) {
                                    // если текущая точка не дырка
                                    resArch.push({ ts: end, value: null });
                                } else {
                                    // Если текущая точка дырка
                                    resArch.push({ ts: item.ts, value });
                                    resArch.push({ ts: end, value });
                                }
                            } else {
                                // Мы ищем конец текущего разрыва
                                resArch.push({ ts: end, value });
                            }
                        }
                    });
                countValue += 5;

                return {
                    ...point,
                    data: resArch,
                };
            })
            .filter((point) => !!point);
    }

    checkArchResultData(archResult): boolean {
        let result: boolean;

        archResult.forEach((item) => {
            if (result) {
                return;
            }

            if (!item.data || !item.data.length || item.data.length < this.MIN_GAPS_DATA_LENGTH) {
                return;
            }

            if (item.data.length === 2 && item.data[0].value === null && item.data[1].value === null) {
                return;
            }

            if (item.data.find((point) => point[1] !== null)) {
                result = true;
            }
        });

        return result;
    }

    updateDataInChart(from = null, to = null): Promise<any> {
        return new Promise((resolve, reject) => {
            const detailSeries = this.detail.series;
            const masterSeries = this.master.series;

            masterSeries.forEach((series) => {
                const filteredMasterPoints = series.data.filter((point) => point && point.x >= from && point.x <= to);

                const currentDetailSerie = detailSeries.find((detailSerie) => detailSerie.userOptions.variableId === series.userOptions.variableId && detailSerie.userOptions.customId === series.userOptions.customId);

                currentDetailSerie.setData(
                    [
                        [from, null],
                        ...filteredMasterPoints.map((point) => {
                            return [point.x, point.y, point.series];
                        }),
                        [to, null],
                    ],
                    false,
                );
            });

            this.detail.redraw();
        });
    }

    initSubsToServerDataAboutNewVariables(variables: Variable[]): void {
        let data: SubscribeOnArchiveOfSocketData;

        this.initiUnSubscribeToServerData();

        data = {
            type: SubscribeOnArchiveOfClientType.SUBSCRIBE,
            variablesId: variables.map((v) => v.originVariableId || v.id),
        };

        this.socketService.emit(SocketEvent.SUBSCRIBE_ON_ARCHIVE, data);
    }

    initiUnSubscribeToServerData(): void {
        let data: SubscribeOnArchiveOfSocketData;

        data = {
            type: SubscribeOnArchiveOfClientType.UNSUBSCRIBE,
        };

        this.socketService.emit(SocketEvent.SUBSCRIBE_ON_ARCHIVE, data);
    }
}

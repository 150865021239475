import { DeviceTypeEnum } from '../../app-shared-elements/_enums/device-type.enum';
import { Observable, Subject } from 'rxjs';
import { DeviceService } from '../_services/device.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TypeClient } from 'src/app/admin/users/_enum/type-client.enum';
import { DropdownFilterOptionInterface } from 'src/app/app-shared-elements/filters/interfaces/filter-option.interface';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { Router } from '@angular/router';
import { Device } from '../../app-shared-elements/_interfaces/Device';
import { UserRegistratorInterface, UserSensorInterface } from './_interface/user-devices.interface';
import { AlarmTypeEnum } from '../../events/_enums/alarm.enum';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DataTypeService } from '../../app-shared-elements/_services/data-type.service';
import { Select, Store } from '@ngxs/store';
import { SetCurrentRegistrator, UpdateDevicesPosition, UpdateUserDevicePosition } from '../_store/actions/user-devices.actions';
import { DevicesState } from '../_store/states/user-devices.state';
import { InviteInterface } from 'src/app/app-shared-elements/_interfaces/InviteInterfaces';
import { FilterTypePipeEnum } from 'src/app/app-shared-elements/_enums/filter-type-pipe.enum';
import { CreationType, RegistratorSyncStatus } from 'src/app/app-shared-elements/_enums/registrator-sync-status.enu';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { TooltipSideEnum } from '../../app-shared-elements/_enums/tooltip-side.enum';
import { ActionListItemInterface } from 'src/app/app-shared-elements/_interfaces/action-list-item.interface';
import { Pagination, Params, ParamsFilter, ParamsFilterForClient } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { ColumnsTableInterface } from '../../app-shared-elements/_interfaces/ColumnsTable';
import { LangEnum } from 'src/app/app-shared-elements/_enums/lang.enum';
import { LanguageState } from 'src/app/app-shared-elements/_store/states/language.state';
import { TableService } from 'src/app/app-shared-elements/_services/table.service';
import { UserDeviceParamsState } from '../_store/states/user-device-params.state';
import { UserDeviceIconEnum } from './_enum/user-device-icon.enum';
import { TableState } from 'src/app/app-shared-elements/_store/states/table.state';
import { FilterItemTypeEnum } from '../../app-shared-elements/filters/enums/filter-item-type.enum';
import { DeviceDashboardPositionInterface } from '../_interfaces/device-dashboard-position.interface';
import { SelectOptionInterface } from '../../app-shared-elements/_interfaces/select-option.interface';
import { SelectTypeEnum } from '../../app-shared-elements/_enums/select-type.enum';
import { CompanyInterface } from 'src/app/admin/company/_interfaces/company.interface';
import { AuthState } from '../../auth/_store/states/auth.state';
import { BtnsSideEnum } from '../../app-shared-elements/_enums/btns-side.enum';
import { RegistratorTypeEnum } from '../../app-shared-elements/_enums/registrator-type.enum';
import { takeUntil } from 'rxjs/operators';
import { EventSaveStatus } from '../../events/_enums/event-save-state.enum';
import { ActionListItemEnum } from '../../app-shared-elements/_enums/action-list-item.enum';

@Component({
    selector: 'app-user-devices',
    templateUrl: './user-devices.component.html',
    styleUrls: ['./user-devices.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDevicesComponent implements OnInit, OnDestroy {
    private _choosedFilter: string;

    @Input()
    set choosedFilter(choosedFilter: string) {
        this._choosedFilter = choosedFilter;
        this.chooseFilter(null, choosedFilter);
    }

    get choosedFilter(): string {
        return this._choosedFilter;
    }

    @Select(UserDeviceParamsState.getParams) params$: Observable<Params>;
    @Select(DevicesState.getDropDownFilterDevices) dropDownFilterDevices$: Observable<DropdownFilterOptionInterface[]>;
    @Select(LanguageState.getLanguage) ln$: Observable<LangEnum>;
    @Select(TableState.getSkeleton) isSkeleton$: Observable<boolean>;
    @Select(AuthState.getIsAdmin) isAdmin$: Observable<boolean>;

    deviceTypeEnum = DeviceTypeEnum;
    alarmTypeEnum = AlarmTypeEnum;

    @Input() userDevicesArray: UserRegistratorInterface[] = [];
    @Input() devices: Device[] = [];
    @Input() invites: InviteInterface[] = [];
    @Input() user: User;
    @Input() optionsRegistrators: SelectOptionInterface[] = [];
    @Input() ownerDevices: Device[] = [];
    @Input() deviceTableColumns: ColumnsTableInterface[] = [];
    @Input() filterType: FilterTypePipeEnum;
    @Input() searchFilter: any;
    @Input() typeClient: TypeClient;
    @Input() isPagination = false;
    @Input() filter: ParamsFilter[] | ParamsFilterForClient[];
    @Input() configPagination: Pagination;
    @Input() optionsDropDown: DropdownFilterOptionInterface[];
    @Input() companiesOptions: SelectOptionInterface<CompanyInterface>[] = [];

    // for filter
    @Input() currentOptionKeyForDropdown: string;
    @Input() defaultDropdownValue: string;
    @Input() currentValueForDropDownFilter: string;

    typeClientModel = TypeClient;

    @Output() changeFilterEvent: EventEmitter<ParamsFilterForClient[]> = new EventEmitter<ParamsFilterForClient[]>();
    @Output() changePaginationEvent: EventEmitter<Pagination> = new EventEmitter<Pagination>();
    @Output() refreshEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() openDeviceEvent: EventEmitter<UserSensorInterface> = new EventEmitter<UserSensorInterface>();
    @Output() openCurrentUserEvent: EventEmitter<Device> = new EventEmitter<Device>();
    @Output() syncEvent: EventEmitter<UserSensorInterface> = new EventEmitter<UserSensorInterface>();
    @Output() activityEvent: EventEmitter<UserSensorInterface> = new EventEmitter<UserSensorInterface>();
    @Output() moveToSmthEvent: EventEmitter<UserSensorInterface> = new EventEmitter<UserSensorInterface>();
    @Output() activeDeviceEvent: EventEmitter<{
        event: ActionListItemInterface;
        device: UserSensorInterface;
    }> = new EventEmitter<{
        event: ActionListItemInterface;
        device: UserSensorInterface;
    }>();
    @Output() actionEvent: EventEmitter<{
        event: ActionListItemInterface;
        item: UserRegistratorInterface;
    }> = new EventEmitter<{
        event: ActionListItemInterface;
        item: UserRegistratorInterface;
    }>();

    tooltip = TooltipStatusEnum;
    filterItemTypeEnum = FilterItemTypeEnum;
    selectTypeEnum = SelectTypeEnum;
    btnsSideEnum = BtnsSideEnum;

    connectOptions: SelectOptionInterface<boolean>[] = [
        { key: true, value: 'devices.dropdownFilters.online', property: 'isConnect' },
        { key: false, value: 'devices.dropdownFilters.offline', property: 'isConnect' },
    ];

    statusOptions: SelectOptionInterface<AlarmTypeEnum>[] = [
        { key: AlarmTypeEnum.ok, value: 'devices.dropdownFilters.ok', property: 'status' },
        { key: AlarmTypeEnum.attention, value: 'devices.dropdownFilters.attention', property: 'status' },
        { key: AlarmTypeEnum.alarm, value: 'devices.dropdownFilters.alarm', property: 'status' },
    ];

    @Input() currentOptionsForDropDownFilter: SelectOptionInterface<boolean | AlarmTypeEnum | string | CompanyInterface>[] = [];

    private positionData: DeviceDashboardPositionInterface[] = [];
    private devicesPositionData: { id: string; position: number }[];

    private destroy: Subject<boolean> = new Subject<boolean>();

    filterTypePipeEnum = FilterTypePipeEnum;

    creationType = CreationType;
    tooltipSideEnum = TooltipSideEnum;
    userDeviceIconEnum = UserDeviceIconEnum;
    registratorTypeEnum = RegistratorTypeEnum;

    registratorSyncStatus = RegistratorSyncStatus;
    customCountOption: SelectOptionInterface<number, string, number>;

    isShowFilter = false;

    readonly REGISTRATOR_ICON_PATH = './assets/design/icons/devices/registrator.svg';
    readonly REGISTRATOR_KIOSK_ICON_PATH = './assets/design/icons/devices/registrator-kiosk.svg';
    readonly TRANSPORT_ICON_PATH = './assets/design/icons/devices/transport.svg';
    readonly TRANSPORT_KIOSK_ICON_PATH = './assets/design/icons/devices/transport-kiosk.svg';
    readonly DOCKER_ICON_PATH = './assets/design/icons/devices/docker.svg';
    readonly VIRTUAL_REGISTRATOR_ICON_PATH = './assets/design/icons/devices/virtual.svg';
    readonly EXPEDITION_ON_ICON_PATH = './assets/design/icons/transport/expedition-on.svg';
    readonly EXPEDITION_OFF_ICON_PATH = './assets/design/icons/transport/expedition-off.svg';
    readonly WAIT_ICON_PATH = './assets/design/icons/devices/wait.svg';
    readonly ERROR_ICON_PATH = './assets/design/icons/devices/error.svg';

    constructor(
        public deviceService: DeviceService,
        public router: Router,
        public dataTypeService: DataTypeService,
        private store: Store,
        public tableService: TableService,
    ) {
        this.params$.pipe(takeUntil(this.destroy)).subscribe((params) => {
            this.isShowFilter = !!params.filter.filter((f) => f.value).length;
        });
    }

    async ngOnInit(): Promise<void> {
        this.customCountOption = this.tableService.counts.find((option) => this.configPagination && option.key === this.configPagination.itemsPerPage) || this.tableService.counts[2];

        if (this.user) {
            this.devicesPositionData = JSON.parse(localStorage.getItem(`devicesPosition${this.user.id}`)) || [];
            this.positionData = JSON.parse(localStorage.getItem(`position${this.user.id}`)) || [];
        }
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }

    async createNewVirtualDevice(): Promise<void> {
        await this.router.navigate(['/custom-devices/edit-custom-devices']);
    }

    async refresh(): Promise<void> {
        this.refreshEvent.emit();
    }

    async openDevice(row: UserSensorInterface): Promise<void> {
        if (this.typeClient === TypeClient.Admin) {
            this.openDeviceEvent.emit(row);
            return;
        }
        this.store.dispatch(new SetCurrentRegistrator(row.type === DeviceTypeEnum.registrator ? row.id : row.registratorId));
        this.router.navigate([`/group-container/chart`, row.id]);
    }

    async openCurrentUser(row: Device): Promise<void> {
        if (!this.store.selectSnapshot(AuthState.getIsAdmin)) {
            return;
        }

        this.openCurrentUserEvent.emit(row);
    }

    checkChangeActivityDevice(registrator: UserSensorInterface): void {
        this.activityEvent.emit(registrator);
    }

    moveToSmth(row: UserSensorInterface): void {
        this.moveToSmthEvent.emit(row);
    }

    drop(event: CdkDragDrop<UserRegistratorInterface[]>): void {
        moveItemInArray(this.userDevicesArray, event.previousIndex, event.currentIndex);

        this.positionData = [];
        event.item.dropContainer.data.forEach((data, index) => {
            const currentRegistrator = this.userDevicesArray.find((device) => device.registrator.id === data.registrator.id);
            if (!currentRegistrator) {
                return;
            }

            const currentLocalDevice = this.positionData.find((d) => d.registratorId === data.registrator.id);

            !currentLocalDevice
                ? this.positionData.push({
                      registratorId: currentRegistrator.registrator.id,
                      position: index + 1,
                  })
                : this.positionData.map((pos) => {
                      if (pos.registratorId === currentLocalDevice.registratorId) {
                          pos.position = index + 1;
                      }
                      return pos;
                  });
        });

        this.positionData = this.positionData.sort((a, b) => a.position - b.position);
        this.store.dispatch(new UpdateUserDevicePosition(this.positionData));
    }

    dropDevices(event: CdkDragDrop<UserSensorInterface[]>, devices: UserSensorInterface[]): void {
        moveItemInArray(devices, event.previousIndex, event.currentIndex);
        event.item.dropContainer.data.forEach((data, index) => {
            const currentDevice = devices.find((device) => device.id === data.id);
            if (!currentDevice) {
                return;
            }
            const currentLocalDevice = this.devicesPositionData.find((f) => f.id === data.id);

            if (!currentLocalDevice) {
                this.devicesPositionData.push({
                    id: currentDevice.id,
                    position: index + 1,
                });
                currentDevice.position = index + 1;
            } else {
                this.devicesPositionData.map((pos) => {
                    if (pos.id === currentLocalDevice.id) {
                        pos.position = index + 1;
                        return pos;
                    }
                });
            }
        });

        this.devicesPositionData = this.devicesPositionData.sort((a, b) => a.position - b.position);
        this.store.dispatch(new UpdateDevicesPosition(this.devicesPositionData));
    }

    openSyncPopup(registrator: UserSensorInterface): void {
        this.syncEvent.emit(registrator);
    }

    parseAction(event: ActionListItemInterface, item: UserRegistratorInterface): void {
        this.actionEvent.emit({ event, item });
    }

    parseActionDevices(event: { event: ActionListItemInterface; device: UserSensorInterface }): void {
        this.activeDeviceEvent.emit(event);
    }

    trackById(index, device: UserRegistratorInterface): string {
        return device ? device.registrator.id : '';
    }

    trackByDeviceId(index, device: UserSensorInterface): string {
        return device ? device.id : '';
    }

    changeFilter(f: ParamsFilterForClient[]): void {
        this.changeFilterEvent.emit(f);
    }

    chooseFilter(event: SelectOptionInterface<boolean | string | AlarmTypeEnum | CompanyInterface>, choosedFilter?: string): void {
        if (choosedFilter) {
            this.currentOptionsForDropDownFilter = [...this.companiesOptions];
            return;
        }

        if (!event) {
            return;
        }

        switch (event.key) {
            case 'registratorId':
                this.currentOptionsForDropDownFilter = [...this.optionsRegistrators];
                break;
            case 'isConnect':
                this.currentOptionsForDropDownFilter = [...this.connectOptions];
                break;
            case 'status':
                this.currentOptionsForDropDownFilter = [...this.statusOptions];
                break;
            case 'companyId':
                this.currentOptionsForDropDownFilter = [...this.companiesOptions];
                break;
        }
    }

    async changePage(event: Pagination): Promise<void> {
        this.changePaginationEvent.emit(event);
    }

    protected readonly eventSaveStatus = EventSaveStatus;
    protected readonly actionListItemEnum = ActionListItemEnum;
}

import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    AuthSignUp,
    AuthSingIn,
    AuthSingInControl,
    CheckToken,
    ConfirmEmail,
    ConfirmToken,
    ForgotAdminPassword,
    ForgotPassword,
    GetConstantForClient,
    InitXSRFProtected,
    Logout,
    ResetPassword,
    SetIsAdmin,
    SetUserToken,
} from '../actions/auth.actions';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { Router } from '@angular/router';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../../profile/_services/profile.service';

export interface AuthStateModel {
    userToken: string;
    status: ApiResponse;
    errorMessage: string;
    isClickHere: boolean;
    isAdmin: boolean;
}

const AUTH_TOKEN = new StateToken<AuthStateModel>('auth');

@State({
    name: AUTH_TOKEN,
    defaults: {
        userToken: localStorage.getItem('access_token') ?? null,
        status: null,
        errorMessage: null,
        isClickHere: false,
        isAdmin: false,
    },
})
@Injectable()
export class AuthState {
    constructor(
        private http: HttpClient,
        private router: Router,
        private store: Store,
        private notificationsService: NotificationsService,
        private translateService: TranslateService,
        private profileService: ProfileService,
    ) {}

    @Selector()
    static getUserToken(state: AuthStateModel): string {
        return state.userToken;
    }

    @Selector()
    static getAuthStatus(state: AuthStateModel): ApiResponse {
        return state.status;
    }

    @Selector()
    static getErrorMessage(state: AuthStateModel): string {
        return state.errorMessage;
    }

    @Selector()
    static getIsClickHere(state: AuthStateModel): boolean {
        return state.isClickHere;
    }

    @Selector()
    static getIsAdmin(state: AuthStateModel): boolean {
        return state.isAdmin;
    }

    @Action(Logout)
    async logout(ctx: StateContext<AuthStateModel>): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = await this.http
            .delete(`/api/${state.isAdmin ? 'auth-control' : 'auth'}/logout`, { params: { token: state.userToken } })
            .toPromise()
            .catch((e) => e);
        // console.log(result);

        localStorage.removeItem('access_token');
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                userToken: null,
            });
        }
    }

    @Action(ResetPassword)
    async resetPassword(ctx: StateContext<AuthStateModel>, payload: ResetPassword): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .put(`/api/${payload.isAdmin ? 'auth-control' : 'auth'}/forgot-password`, { ...payload.data })
            .toPromise()
            .catch((e) => e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            payload.isAdmin ? await this.router.navigate(['/login/control']) : await this.router.navigate(['/login']);
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(ForgotPassword)
    async forgotPassword(ctx: StateContext<AuthStateModel>, payload: ForgotPassword): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post('/api/auth/forgot-password', { email: payload.email })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        ctx.setState({
            ...state,
            status: result,
        });
    }

    @Action(ForgotAdminPassword)
    async forgotAdminPassword(ctx: StateContext<AuthStateModel>, payload: ForgotAdminPassword): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post('/api/auth-control/forgot-password', { email: payload.email })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        ctx.setState({
            ...state,
            status: result,
        });
    }

    @Action(CheckToken)
    async checkToken(ctx: StateContext<AuthStateModel>, payload: CheckToken): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .get(`/api/${payload.isAdmin ? 'auth-control' : 'auth'}/forgot-password-check`, {
                params: {
                    token: payload.token,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        ctx.setState({
            ...state,
            status: result,
        });
    }

    @Action(AuthSignUp)
    async authSignUp(ctx: StateContext<AuthStateModel>, payload: AuthSignUp): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/auth/sign-up', payload.user)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        ctx.setState({
            ...state,
            status: result,
            errorMessage: null,
        });
    }

    @Action(AuthSingIn)
    async authSignIn(ctx: StateContext<AuthStateModel>, payload: AuthSingIn): Promise<void> {
        const result: ApiResponse = (await this.http
            .post(`/api/${payload.isAdmin ? 'auth-control' : 'auth'}/sign-in`, { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (!result) {
            ctx.setState({
                ...state,
                errorMessage: 'auth.login.errorConnect',
            });

            return;
        }

        if (result.status !== HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                status: null,
            });
        }

        switch (result.status) {
            case HTTP_STATUS.INVALID_PASSWORD:
            case HTTP_STATUS.ERROR:
                ctx.setState({
                    ...state,
                    status: result,
                    errorMessage: 'auth.login.error',
                });
                this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'auth.login.error');

                return;
            case HTTP_STATUS.USER_IS_DELETED:
            case HTTP_STATUS.USER_NOT_CONFIRMED_BY_ADMIN:
            case HTTP_STATUS.USER_NOT_ACTIVE:
                ctx.setState({
                    ...state,
                    errorMessage: 'auth.login.erroNotActiveUser',
                });

                return;
            case HTTP_STATUS.USER_EMAIL_NOT_CONFIRMED:
                ctx.setState({
                    ...state,
                    errorMessage: 'auth.login.errorEmailNotCorfirmed',
                    isClickHere: true,
                });

                return;
            case HTTP_STATUS.TWO_FACTOR_AUTHENTICATION_REQUIRED:
            case HTTP_STATUS.PASSWORD_IS_EXPIRED:
                ctx.setState({
                    ...state,
                    status: result,
                });
                return;
            case HTTP_STATUS.NEED_CHANGE_PASSWORD:
                ctx.setState({
                    ...state,
                    status: result,
                    errorMessage: 'auth.login.error',
                });
                return;
            case HTTP_STATUS.TWO_FACTOR_AUTHENTICATION_TOKEN_NOT_VALID:
                ctx.setState({
                    ...state,
                    errorMessage: 'profile.authenticationPopup.errorCode',
                });
                return;
            case HTTP_STATUS.USER_WEB_AUTH_IS_DISABLED:
                ctx.setState({
                    ...state,
                    errorMessage: 'auth.login.disabledWebAuth',
                });
        }
        // console.log(result);
        ctx.setState({
            ...state,
            status: result,
            errorMessage: null,
            isClickHere: false,
            userToken: (result.data as User).access_token,
        });

        this.profileService.userDataSubs();

        this.store.dispatch(new SetUserToken(result.data.access_token));
        localStorage.setItem('access_token', (result.data as User).access_token);
    }

    @Action(AuthSingInControl)
    async authSignInControl(ctx: StateContext<AuthStateModel>, payload: AuthSingInControl): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post('/api/auth-control/sign-in', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        ctx.setState({
            ...state,
            status: result,
        });
    }

    @Action(SetUserToken)
    setUserToken(ctx: StateContext<AuthStateModel>, payload: SetUserToken): void {
        const state = ctx.getState();

        localStorage.setItem('access_token', payload.token);

        ctx.setState({
            ...state,
            userToken: payload.token,
        });
    }

    @Action(ConfirmEmail)
    async confirmEmail(ctx: StateContext<AuthStateModel>, payload: ConfirmEmail): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post(`/api/${payload.isAdmin ? 'auth-control' : 'auth'}/confirm`, { email: payload.email })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        // console.log(result);

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            const message = await this.translateService.get('auth.login.repeatConfirmSuccessMessage').toPromise();
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, message);

            ctx.setState({
                ...state,
                isClickHere: false,
                errorMessage: null,
            });
        }
    }

    @Action(ConfirmToken)
    async confirmToken(ctx: StateContext<AuthStateModel>, payload: ConfirmToken): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .patch(`/api/${payload.isAdmin ? 'auth-control' : 'auth'}/confirm`, { token: payload.token })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.NEED_CHANGE_PASSWORD) {
        }

        if (!result || (result.status !== HTTP_STATUS.SUCCESS && result.status !== HTTP_STATUS.NEED_CHANGE_PASSWORD)) {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'Token doesn`t valid');
            payload.isAdmin ? this.router.navigate(['/login/control']) : this.router.navigate(['/login']);
        }

        ctx.setState({
            ...state,
            isClickHere: false,
            errorMessage: null,
        });
    }

    @Action(SetIsAdmin)
    sSetIsAdmin(ctx: StateContext<AuthStateModel>, payload: SetIsAdmin): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isAdmin: payload.value,
        });
    }

    @Action(GetConstantForClient)
    async getConstantForClient(ctx: StateContext<AuthStateModel>): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/constant')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
    }

    @Action(InitXSRFProtected)
    async initXSRFProtected(ctx: StateContext<AuthStateModel>): Promise<void> {
        const result = await this.http
            .get('/api/csurf')
            .toPromise()
            .catch((e) => console.log(e));
    }
}

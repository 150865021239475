import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CheckConfirmComponent} from './check-confirm.component';
import {AppSharedElementsModule} from '../app-shared-elements/app-shared-elements.module';

@NgModule({
    declarations: [CheckConfirmComponent],
    imports: [
        CommonModule,
        AppSharedElementsModule,
    ],
})
export class CheckConfirmModule {
}

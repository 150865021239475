import { ComponentsGeneratorService } from './_services/components-generator.service';
import { RouteGuardService } from './_guards/route-guard-service.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadingComponent } from './heading/heading.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { FiltersComponent } from './filters/filters.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { TablesComponent } from './tables/tables.component';
import { ErrorAlertComponent } from './error-alert/error-alert.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { LangComponent } from './lang/lang.component';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { CustomSelectComponent } from './custom-select/custom-select.component';
import { ColorPickerModule } from 'ngx-color-picker';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ColumnsTextComponent } from './tables/columns/columns-text/columns-text.component';
import { ColumnsDateComponent } from './tables/columns/columns-date/columns-date.component';
import { ColumnsButtonComponent } from './tables/columns/columns-button/columns-button.component';
import { ColumnsIconComponent } from './tables/columns/columns-icon/columns-icon.component';
import { ColumnInputComponent } from './tables/columns/column-input/column-input.component';
import { ColumnSelectComponent } from './tables/columns/column-select/column-select.component';
import { PopupComponent } from './popup/popup.component';
import { NavNotificationsComponent } from './nav-notifications/nav-notifications.component';
import { TabsComponent } from './tabs/tabs.component';
import { TooltipComponent } from './tooltips/tooltip/tooltip.component';
import { InviteComponent } from './invite/invite.component';
import { NoteNotificationsComponent } from './tooltips/note-notifications/note-notifications.component';
import { SettingsHeaderComponent } from './settings-header/settings-header.component';
import { EditMultiNameComponent } from './edit-multi-name/edit-multi-name.component';
import { ColumnsEditorComponent } from './tables/columns/columns-editor/columns-editor.component';
import { ArchTooltipComponent } from './tooltips/arch-tooltip/arch-tooltip.component';
import { InputConstructorComponent } from './input-constructor/input-constructor.component';
import { ColumnsAcknowledgeComponent } from './tables/columns/columns-acknowledge/columns-acknowledge.component';
import { ColumnsEditComponent } from './tables/columns/columns-edit/columns-edit.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { PhoneNumberDirective } from './_directives/phone-number.directive';
import { BlockDisconnectComponent } from './block-disconnect/block-disconnect.component';
import { InputValidateDirective } from './_directives/input-validate.directive';
import { MaxNumberValueDirective } from './_directives/max-number-value.directive';
import { ColumnTransportComponent } from './tables/columns/column-transport/column-transport.component';
import { ColumnMailingEditorComponent } from './tables/columns/column-mailing-editor/column-mailing-editor.component';
import { MailingIconSelectComponent } from './tables/columns/column-mailing-editor/mailing-icon-select/mailing-icon-select.component';
import { RouterModule } from '@angular/router';
import { FilterPipe } from './_pipes/filter.pipe';
import { CanDeactivatePopapComponent } from './can-deactivate-popap/can-deactivate-popap.component';
import { PermissionDirective } from './_directives/permission.directive';

import { ColumnsTsClearComponent } from './tables/columns/columns-ts-clear/columns-ts-clear.component';
import { PremissionHelperComponent } from './premission-helper/premission-helper.component';
import { FilterByPermissionsPipe } from './_pipes/filter-by-permissions.pipe';

import { BlockCopyPastCutDirective } from './_directives/block-copy-past-cut.directive';
import { DisabledDirective } from './_directives/disabled.directive';
import { ColumnsActionComponent } from './tables/columns/columns-action/columns-action.component';
import { ArchivePerloaderComponent } from './archive-perloader/archive-perloader.component';
import { DatepickerDirective } from './_directives/datepicker.directive';
import { LuxonDatePipe } from './_pipes/luxon-date.pipe';
import { ActionsListComponent } from './actions-list/actions-list.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { QueueService } from './_services/queue.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ClickOutsideDirective } from './_directives/click-outside.directive';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { LimitComponent } from './limit/limit.component';
import { ConstantValidateDirective } from './_directives/constant-validate.directive';
import { BlockUiComponent } from './block-ui/block-ui.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AdminPermissionDirective } from './_directives/admin-permission.directive';
import { GroupOperationComponent } from './tables/group-operation/group-operation.component';
import { ColumnGroupOperationComponent } from './tables/columns/column-group-operation/column-group-operation.component';
import { ExpiredPasswordComponent } from './expired-password/expired-password.component';
import { DropZoneComponent } from './drop-zone/drop-zone.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DevicesTooltipComponent } from './tooltips/devices-tooltip/devices-tooltip.component';
import { CustomInputComponent } from './custom-input/custom-input.component';
import { SafeHtmlPipe } from './_pipes/safe-html.pipe';
import { InvoiceTableComponent } from './invoice-table/invoice-table.component';
import { CustomTooltipMessageComponent } from './custom-tooltip-message/custom-tooltip-message.component';
import { FocusDirective } from './_directives/focus.directive';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ColumnsDatePickerComponent } from './tables/columns/columns-date-picker/columns-date-picker.component';
import { SaveScrollDirective } from './_directives/save-scroll.directive';
import { MallingInviteComponent } from './malling-invite/malling-invite.component';
import { ImportExportPreloaderComponent } from './import-export-preloader/import-export-preloader.component';
import { NgxPrintModule } from 'ngx-print';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { ToggleButtonComponent } from './toggle-button/toggle-button.component';
import { DisabledAutoFillDirective } from './_directives/disabled-auto-fill.directive';
import { DisabledPermissionDirective } from './_directives/disabled-permission.directive';

@NgModule({
    declarations: [
        HeadingComponent,
        BreadcrumbsComponent,
        FiltersComponent,
        TablesComponent,
        LangComponent,
        ErrorAlertComponent,
        BlockUiComponent,
        ColumnsEditComponent,
        ColorPickerComponent,
        CustomSelectComponent,
        ColumnsTextComponent,
        ColumnsDateComponent,
        ColumnsButtonComponent,
        ColumnsIconComponent,
        ColumnInputComponent,
        ColumnSelectComponent,
        PopupComponent,
        NavNotificationsComponent,
        TabsComponent,
        TooltipComponent,
        InviteComponent,
        NoteNotificationsComponent,
        SettingsHeaderComponent,
        EditMultiNameComponent,
        ColumnsEditorComponent,
        ArchTooltipComponent,
        InputConstructorComponent,
        ColumnsAcknowledgeComponent,
        ColumnsEditComponent,
        PreloaderComponent,
        PhoneNumberDirective,
        BlockDisconnectComponent,
        InputValidateDirective,
        MaxNumberValueDirective,
        ColumnTransportComponent,
        ColumnMailingEditorComponent,
        MailingIconSelectComponent,
        ColumnsTsClearComponent,
        FilterPipe,
        CanDeactivatePopapComponent,
        PermissionDirective,
        DisabledPermissionDirective,
        PremissionHelperComponent,
        FilterByPermissionsPipe,
        BlockCopyPastCutDirective,
        DisabledDirective,
        ColumnsActionComponent,
        ArchivePerloaderComponent,
        DatepickerDirective,
        LuxonDatePipe,
        ActionsListComponent,
        ClickOutsideDirective,
        CheckboxComponent,
        LimitComponent,
        ConstantValidateDirective,
        ConstantValidateDirective,
        GroupOperationComponent,
        ColumnGroupOperationComponent,
        AdminPermissionDirective,
        ExpiredPasswordComponent,
        DropZoneComponent,
        PaginationComponent,
        DatePickerComponent,
        DevicesTooltipComponent,
        CustomInputComponent,
        SafeHtmlPipe,
        InvoiceTableComponent,
        CustomTooltipMessageComponent,
        FocusDirective,
        ColumnsDatePickerComponent,
        SaveScrollDirective,
        MallingInviteComponent,
        ImportExportPreloaderComponent,
        RadioButtonComponent,
        ToggleButtonComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        CalendarModule,
        NgxPaginationModule,
        ReactiveFormsModule,
        TranslateModule,
        ColorPickerModule,
        DragDropModule,
        CommonModule,
        RouterModule,
        ClipboardModule,
        ScrollingModule,
        NgxSkeletonLoaderModule,
        NgxDropzoneModule,
        AngularSvgIconModule.forRoot(),
        NgxPrintModule,
        DisabledAutoFillDirective,
    ],
    exports: [
        HeadingComponent,
        BreadcrumbsComponent,
        FiltersComponent,
        ErrorAlertComponent,
        LangComponent,
        BlockUiComponent,
        TablesComponent,
        NavNotificationsComponent,
        TabsComponent,
        PopupComponent,
        CustomSelectComponent,
        TooltipComponent,
        InviteComponent,
        NoteNotificationsComponent,
        FormsModule,
        TranslateModule,
        ReactiveFormsModule,
        SettingsHeaderComponent,
        EditMultiNameComponent,
        ArchTooltipComponent,
        InputConstructorComponent,
        CalendarModule,
        ColumnsAcknowledgeComponent,
        ColumnsTsClearComponent,
        ColumnsEditComponent,
        PreloaderComponent,
        PhoneNumberDirective,
        InputValidateDirective,
        MaxNumberValueDirective,
        ColorPickerComponent,
        RouterModule,
        FilterPipe,
        PermissionDirective,
        DisabledPermissionDirective,
        PremissionHelperComponent,
        FilterByPermissionsPipe,
        BlockCopyPastCutDirective,
        DisabledDirective,
        ColumnsActionComponent,
        ArchivePerloaderComponent,
        DatepickerDirective,
        LuxonDatePipe,
        ActionsListComponent,
        NgxSkeletonLoaderModule,
        ClickOutsideDirective,
        CheckboxComponent,
        ColumnsEditorComponent,
        ConstantValidateDirective,
        LimitComponent,
        ConstantValidateDirective,
        ColumnsEditorComponent,
        AdminPermissionDirective,
        ExpiredPasswordComponent,
        DropZoneComponent,
        AngularSvgIconModule,
        PaginationComponent,
        NgxPaginationModule,
        DatePickerComponent,
        DevicesTooltipComponent,
        CustomInputComponent,
        SafeHtmlPipe,
        InvoiceTableComponent,
        CustomTooltipMessageComponent,
        ColumnMailingEditorComponent,
        MallingInviteComponent,
        FocusDirective,
        ImportExportPreloaderComponent,
        RadioButtonComponent,
        ToggleButtonComponent,
    ],
    providers: [TranslateModule, RouteGuardService, ComponentsGeneratorService, QueueService],
})
export class AppSharedElementsModule {}

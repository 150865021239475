import {ExpeditionPopupInterface} from '../../container-registrators/device-map/_interface/expedition-popup.interface';

export class SetIsShowMap {
    static readonly type = '[DeviceMap] SetIsShowMap';

    constructor(public value: boolean) {
    }
}

export class GetArchiveMap {
    static readonly type = '[DeviceMap] GetArchiveMap';

    constructor(public data: { deviceId: string, variablesId: string[], start: number, end: number }) {
    }
}

export class GetEventLogMap {
    static readonly type = '[DeviceMap] GetEventLogMap';

    constructor(public data: { registratorId: string, from: number, to: number }) {
    }
}

export class GetExpeditionArray {
    static readonly type = '[DeviceMap] GetExpeditionArray';

}

export class ChangeExpeditionArray {
    static readonly type = '[DeviceMap] ChangeExpeditionArray';

    constructor(public array: ExpeditionPopupInterface[]) {
    }
}

export class SetExpeditionSelect {
    static readonly type = '[DeviceMap] SetExpeditionSelect';

    constructor(public date: { from: number, to: number }) {
    }
}

import {Params} from "../../../app-shared-elements/_interfaces/params.interface";
import {MoneyTransactionsInterface} from "../../_interfaces/money-transactions.interface";

export class GetBillingTransactions {
    static readonly type = '[BillingTransactions] GetBillingTransactions';
}

export class InitBillingTransactionsRows {
    static readonly type = '[BillingTransactions] InitBillingTransactionsRows';

}

export class SetBillingTransactionsParams {
    static readonly type = '[BillingTransactions] SetBillingTransactionsFilter';

    constructor(public params: Params) {

    }
}

export class MoneyTransactions {
    static readonly type = '[BillingTransactions] MoneyTransactions';

    constructor(public data: MoneyTransactionsInterface) {
    }
}

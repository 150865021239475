<div class="custom-tooltip">

    <ng-container [ngSwitch]="currentColumn?.customTooltipType === customTooltipTypeEnum.status">

        <div class="custom-tooltip__status">

            <ng-container *ngFor="let item of statusTooltip">

                <div class="custom-tooltip__status-item">
                    <div class="custom-tooltip__status-block"
                         [style.background-color]="item.color"
                    ></div>

                    <div [translate]="item.title"></div>
                </div>

            </ng-container>

        </div>

    </ng-container>

</div>

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {LimitLogicEventUpdateDto, LogicEventType} from 'src/app/events/logical-events/_interface/LogicEvent';
import {AlarmTypeEnum} from 'src/app/events/_enums/alarm.enum';
import {OperandConstantType} from 'src/app/events/_enums/condition-type.enum';
import {Variable} from '../_interfaces/Variable';

@Component({
    selector: 'app-limit',
    templateUrl: './limit.component.html',
    styleUrls: ['./limit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LimitComponent implements OnInit, OnDestroy {

    @Input() bottomValue: string;
    @Input() topValue: string;
    @Input() bottomActive: boolean;
    @Input() topActive: boolean;
    @Input() currentOperanType: OperandConstantType = OperandConstantType.FLOAT;
    @Input() title: string;
    @Input() ackn: boolean;
    @Input() isDisabled: boolean;
    @Input() alarmType: AlarmTypeEnum.attention | AlarmTypeEnum.alarm;

    @Output() updateMultiEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() updateBottomActive: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() updateTopActive: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() updateBottomValue: EventEmitter<string> = new EventEmitter<string>();
    @Output() updateTopValue: EventEmitter<string> = new EventEmitter<string>();
    @Output() updateAckn: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() validateErrorEvent: EventEmitter<boolean> = new EventEmitter<boolean>();




    currnetVariable: Variable;

    logicEventType = LogicEventType;
    multiEvent: LimitLogicEventUpdateDto;
    alarmTypeEnum = AlarmTypeEnum;

    errorBottomLimit: boolean;
    errorTopLimit: boolean;


    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor() {}

    ngOnInit(): void {

    }


    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }

    setActiveBottomLimit(event: boolean): void {
        this.updateBottomActive.emit(event);

        // this.updateMultiEvent.emit();
    }

    changeBottomLimit(event: string): void {
        this.updateBottomValue.emit(event);

    }

    setActiveTopLimit(event: boolean): void {
        this.updateTopActive.emit(event);

    }

    changeTopLimit(event: string): void {
        this.updateTopValue.emit(event);
    }

    changeAckn(event: boolean): void {
        this.updateAckn.emit(event);
    }

    setValidateValue(isInvalide: boolean, limit: 'min' | 'max'): void {
        if (isInvalide) {
            switch (limit) {
                case 'min':
                    this.errorBottomLimit = true;
                    break;
                case 'max':
                    this.errorTopLimit = true;
                    break;
            }

            this.validateErrorEvent.emit(true);

            return;
        }
        this.validateErrorEvent.emit(false);

        this.errorTopLimit = false;
        this.errorBottomLimit = false;
    }

}

<div class="authentication">
    <h1 class="authentication__title" [translate]="'profile.authenticationPopup.title'"></h1>
    <label class="authentication__label" for="auth"
           [translate]="'profile.authenticationPopup.titleCode'"></label>
    <input [(ngModel)]="authToken"
           [placeholder]="'profile.authenticationPopup.inputPlaceholder' | translate" type="text"
           id="auth"
           class="input authentication__input">
    <span *ngIf="(errorMessage$ | async) && (errorMessage$ | async).length && authToken"
          class="authentication__error"
          [translate]="errorMessage$ | async"></span>
    <div class="authentication__btns">
        <button (click)="close()"
                [translate]="'btns.back'" class="authentication__btn btn btn--light"></button>
        <button [disabled]="!authToken" (click)="login()" [accessKey]="'enter'"
                [translate]="'btns.signIn'" class="authentication__btn btn btn--red"></button>
    </div>

</div>

import {Params} from '../../../app-shared-elements/_interfaces/params.interface';

export class GetDeletedDevices {
    static readonly type = '[DeletedDevices] GetDeletedDevices';
}

export class InitDeletedDevices {
    static readonly type = '[DeletedDevices] InitDeletedDevices';

}

export class RecoveryDeletedDevice {
    static readonly type = '[DeletedDevices] RecoveryDeletedDevice';

    constructor(public id: string) {
    }
}

export class SetDeletedDevicesFilter {
    static readonly type = '[DeletedDevices] SetDeletedDevicesFilter';

    constructor(public params: Params) {

    }
}

export class InitDeviceOptions {
    static readonly type = '[DeletedDevices] InitDeviceOptions';

}

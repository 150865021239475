<div class="column">
    <div class="column__wrapper">
        <div *ngIf="value" class="column__icon">
            <ng-container [ngSwitch]="value.icon">
                <ng-template [ngSwitchCase]="notificationTransportType.EMAIL">
                    <svg-icon [src]="EMAIL_ICON_PATH"></svg-icon>
                </ng-template>

                <ng-template [ngSwitchCase]="notificationTransportType.TELEGRAM">
                    <svg-icon [src]="TELEGRAM_ICON_PATH"></svg-icon>

                </ng-template>

                <ng-template [ngSwitchCase]="notificationTransportType.VIBER">
                    <svg-icon [src]="VIBER_ICON_PATH"></svg-icon>

                </ng-template>

            </ng-container>
        </div>
        <div class="column__data">
            {{value?.data}}
        </div>
    </div>
</div>

import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, StateToken, Store} from '@ngxs/store';
import {TableUserLogsInterface} from '../../user-logs/_interface/table-user-logs.interface';
import {GetUserLogs, GetUserLogsRows, ResetUserLogsState, SetUserIdForLogsFromAdmin, SetUserLogsFilter} from '../actions/user-logs.actions';
import {UsersLogsService} from '../../_services/users-logs.service';
import {Pagination, Params, ParamsSorted, ParamsSortedOrderEnum} from 'src/app/app-shared-elements/_interfaces/params.interface';
import {initialFilterUserLog} from '../../_data/users-logs.data';
import {DropdownFilterOptionInterface} from 'src/app/app-shared-elements/filters/interfaces/filter-option.interface';
import {TypeFilterEnum} from 'src/app/app-shared-elements/filters/enums/type-filter.enum';
import {CloneDeepService} from 'src/app/app-shared-elements/_services/clone-deep.service';
import {TimeFilterState} from 'src/app/app-shared-elements/_store/states/time-filter.state';
import {ParamsService} from 'src/app/app-shared-elements/_services/params.service';
import {SetSkeleton} from 'src/app/app-shared-elements/_store/actions/table.actions';
import {UserLogInterface} from '../../user-logs/_interface/user-logs.interface';
import {TranslateService} from '@ngx-translate/core';
import {ApiResponse} from '../../../app-shared-elements/_interfaces/ApiRequest';
import {HttpClient} from '@angular/common/http';
import {HTTP_STATUS} from '../../../app-shared-elements/_enums/status.enum';

export interface UserLogsStateModel {
    userLogs: { items: UserLogInterface[], total: number };
    userLogsRows: TableUserLogsInterface[];
    params: Params;
    dropDownFilterOptions: DropdownFilterOptionInterface[];
    userId: string; // for admin
}

const USER_LOGS_TOKEN = new StateToken<UserLogsStateModel>('userLogs');

const initialCurrentSort: ParamsSorted[] = [{
    property: 'created',
    order: ParamsSortedOrderEnum.DESC
}];

const initialPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null
};

const dropDownFilterOptions: DropdownFilterOptionInterface[] = [
    {key: 'userLogin', value: 'logs.users.filtersDropdown.login', type: TypeFilterEnum.text, property: 'userLogin'},
    {key: 'userIp', value: 'logs.users.filtersDropdown.ip', type: TypeFilterEnum.text, property: 'userIp'},
    {key: 'internalId', value: 'logs.users.filtersDropdown.internalId', type: TypeFilterEnum.text, property: 'internalId'},
    {key: 'srcId', value: 'logs.users.filtersDropdown.srcId', type: TypeFilterEnum.text, property: 'srcId'},
];


export const initialParams: Params = {
    filter: initialFilterUserLog,
    sorted: initialCurrentSort,
    pagination: initialPagination
};

@State<UserLogsStateModel>({
    name: USER_LOGS_TOKEN,
    defaults: {
        userLogs: {items: [], total: 0},
        userLogsRows: [],
        params: initialParams,
        dropDownFilterOptions,
        userId: null
    }
})
@Injectable()
export class UserLogsState {
    constructor(
        private usersLogsService: UsersLogsService,
        private cloneDeepService: CloneDeepService,
        private store: Store,
        private paramsService: ParamsService,
        private translateService: TranslateService,
        private http: HttpClient
    ) {
    }

    @Selector()
    static getUserLogs(state: UserLogsStateModel): TableUserLogsInterface[] {
        return state.userLogsRows;
    }

    @Selector()
    static getParams(state: UserLogsStateModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }


    @Selector()
    static getDropdownFilterOptions(state: UserLogsStateModel): DropdownFilterOptionInterface[] {
        return state.dropDownFilterOptions;
    }

    @Selector()
    static getUserId(state: UserLogsStateModel): string {
        return state.userId;
    }

    @Action(GetUserLogs)
    async getUserLogs(ctx: StateContext<UserLogsStateModel>, payload: GetUserLogs): Promise<void> {
        const state = ctx.getState();

        const datetime = this.store.selectSnapshot(TimeFilterState.getTimeObj);
        const params: Params = {
            ...state.params,
            filter: this.paramsService.parseParamsFilterForServer(state.params.filter, datetime),
            sorted: state.params.sorted && state.params.sorted.length ? state.params.sorted : initialCurrentSort

        };

        let result: ApiResponse;
        if (state.userId) {
            result = await this.http.get('api/users-log', {
                headers: {
                    params: encodeURIComponent(JSON.stringify(params)),
                    currentUserId: state.userId
                }
            }).toPromise() as ApiResponse;
        } else {
            result = await this.http.get('api/users-log', {
                headers: {
                    params: encodeURIComponent(JSON.stringify(params)),
                }
            }).toPromise() as ApiResponse;
        }

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                userLogs: result.data,
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.total
                    },
                    sorted: params.sorted && params.sorted.length ? params.sorted : initialCurrentSort

                }
            });

            ctx.dispatch(new GetUserLogsRows());
        }
    }

    @Action(GetUserLogsRows)
    async getUserLogsRows(ctx: StateContext<UserLogsStateModel>): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            userLogsRows: await Promise.all(state.userLogs.items.map(async (item: UserLogInterface) => {
                const text = await this.usersLogsService.performDataMessage(item.actionPage, item.jsonDataLog);
                const shortText = item.shortJsonDataLog ? await this.usersLogsService.performDataMessage(item.actionPage, item.shortJsonDataLog) : null;

                let srcId: string | number = '';
                item.jsonDataLog.data.forEach(r => {
                    if (r.property && r.property.toLowerCase().includes('id') && r.value) {
                        srcId = String(r.value) ?? '';
                    }

                });

                return {
                    message: `<span>${text}.</span>`,
                    shortMessage: shortText ? `<span>${shortText}.</span>` : '',
                    action: item.action,
                    userIp: item.userIp,
                    internalId: item.internalId,
                    deviceName: item.deviceName,
                    deviceId: item.deviceId,
                    created: new Date(item.created).getTime(),
                    userLogin: item.userLogin,
                    actionPage: item.actionPage ? await this.translateService.get(`user_logs.actionPage.${item.actionPage}`).toPromise() : '',
                    userId: item.userId,
                    id: item.userId,
                    metaData: item.userAgent,
                    srcId
                };
            }))
        });
        ctx.dispatch(new SetSkeleton(false));
    }

    @Action(SetUserLogsFilter)
    async setUserLogsFilter(ctx: StateContext<UserLogsStateModel>, payload: SetUserLogsFilter): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: this.cloneDeepService.cloneObject(payload.params)
        });

        await ctx.dispatch(new GetUserLogs());
    }

    @Action(ResetUserLogsState)
    resetUserLogsState(ctx: StateContext<UserLogsStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            userLogs: {items: [], total: 0},
            userLogsRows: [],
            params: initialParams
        });
    }

    @Action(SetUserIdForLogsFromAdmin)
    setUserIdForLogsFromAdmin(ctx: StateContext<UserLogsStateModel>, payload: SetUserIdForLogsFromAdmin): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            userId: payload.userId
        });
    }
}

<div class="nav-note"
     [class.nav-note--active]="router.url.includes('notifications')"
>
    <div class="nav-note__wrapper">
        <div class="nav-note__icon">
            <svg-icon [src]="NOTIFICATIONS_ICON_PATH"></svg-icon>
        </div>

        <div *ngIf="(notifications$ | async) && +(notifications$ | async).count > 0" class="nav-note__notification"
             [class.nav-note__notification--read]="(notifications$ | async).isRead">
            <div *ngIf="(notifications$ | async) && (notifications$ | async).count > 0"
                 class="nav-note__notification-value">
                {{(notifications$ | async).count}}
            </div>
        </div>
    </div>
</div>

import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { TabsService } from './../_services/tabs.service';
import { Tabs } from '../_interfaces/Tabs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TabsState } from '../_store/states/tabs.state';
import { Select, Store } from '@ngxs/store';
import { filter, takeUntil } from 'rxjs/operators';
import { SetCurrentTab, SetTabs } from '../_store/actions/tabs.actions';

@Component({
    selector: 'app-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, OnDestroy {
    tabs: Tabs[];

    // @Input() newTabId;
    @Input() notPath: boolean;
    @Input() isLastPath: boolean;
    @Input() isDisabled: boolean;

    @Output() currentTabEmit = new EventEmitter<number>();

    isCanChangeTabs = true;
    showListenerPopup = false;
    newId: number;

    @Select(TabsState.getTabs) tabs$: Observable<Tabs[]>;
    @Select(TabsState.getCurrentQueryParams) currentQueryParams$: Observable<Tabs[]>;

    destroy: Subject<boolean> = new Subject<boolean>();

    constructor(private tabsService: TabsService, private router: Router, private store: Store) {
        this.tabsService.isCanChangeTab.pipe(takeUntil(this.destroy)).subscribe((value) => {
            this.isCanChangeTabs = value;
        });
    }

    setCurrentStartTab(): void {
        const id = this.tabsService.getCurrentTabId<number>();
        if (id) {
            this.tabs.map((tab) => (tab.id === id ? (tab.active = true) : (tab.active = false)));
        }
    }

    ngOnInit(): void {
        this.tabs$.pipe(takeUntil(this.destroy)).subscribe((tabs) => {
            this.tabs = tabs;
            if (tabs && tabs.length && !this.notPath) {
                const currentTab = this.tabs.find((tab) => tab.active === true);

                if (!currentTab) {
                    return;
                }

                const query = this.store.selectSnapshot(TabsState.getCurrentQueryParams);
                query
                    ? this.router.navigate([currentTab.path], { queryParams: query })
                    : this.router.navigate([currentTab.path]);
            }
        });

        this.router.events
            .pipe(
                takeUntil(this.destroy),
                filter((event) => event instanceof (NavigationEnd || NavigationStart)),
            )
            .subscribe((event: NavigationEnd | NavigationStart) => {
                if (this.notPath) {
                    return;
                }

                const urlTree = this.router.parseUrl(this.router.url);
                const urlWithoutParams = urlTree.root.children.primary.segments.map((it) => it.path).join('/');

                const currentTab = this.tabs.find((tab) => tab.active);

                if (!currentTab) {
                    return;
                }

                if (!currentTab.path.includes(urlWithoutParams)) {
                    const newCurrentTab = this.tabs.find((tab) => tab.path.includes(urlWithoutParams));
                    this.store.dispatch(new SetCurrentTab(newCurrentTab.id));
                }
            });
    }

    ngOnDestroy(): void {
        this.store.dispatch(new SetTabs([]));
        this.destroy.next(true);
        this.destroy.complete();
    }

    setActiveTab(id: number): void {
        if (this.notPath) {
            this.currentTabEmit.emit(id);
        }
        this.store.dispatch(new SetCurrentTab(id));
    }

    moveToTabWithoutSave(): void {
        this.tabsService.setIsCanChangeTagEvent(true);
        this.setActiveTab(this.newId);
        this.showListenerPopup = false;
    }
}

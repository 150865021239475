<div class="info">
    <div class="info__wrapper">

        <div class="info__block">
            <div class="info__title" (click)="setCurrentBlock('userProfileInfo')">
                <svg-icon [src]="(blocks$ | async)?.userProfileInfo ? ARROW_ICON_DOWN : ARROW_ICON_UP"></svg-icon>
                <span [translate]="'profile.privateData'"></span>
            </div>
            <ng-container *ngIf="(blocks$ | async)?.userProfileInfo">
                <div class="info__list info__list--profile">
                    <div *ngFor="let item of userInfo" class="info__item">
                        <div class="info__item-title" [translate]="item.title"></div>
                        <div class="info__item-value">
                            <ng-container [ngSwitch]="item.elementType">
                                <ng-template [ngSwitchCase]="userProfileInfoTypeElementEnum.input">
                                    <div class="info__value-input">
                                        <input class="info__input input" type="text"
                                               [class.input--error]="!item.isValid"
                                               [placeholder]="item.inputPlaceholder | translate"
                                               [ngModel]="item.value"
                                               [disabled]="(currentUser$ | async)?.id !== (profileUser$ | async)?.id"
                                               [appInputValidate]="item.type === userProfileInfoTypeEnum.phone ? variableValueTypeEnum.numbers : null"
                                               (input)="changeData(item.type, $event.target.value); isDisabledSave = false; isCanMoveTabEvent.emit(false)">

                                        <div *ngIf="!item.isValid" class="info__value-error"
                                             [translate]="'profile.errorDataFormat'"></div>
                                    </div>

                                </ng-template>
                                <ng-template [ngSwitchCase]="userProfileInfoTypeElementEnum.select">
                                    <div class="info__value-select">
                                        <app-custom-select></app-custom-select>
                                    </div>

                                </ng-template>

                                <ng-template ngSwitchDefault>
                                    <div
                                        class="info__value-simple">{{item.isDate
                                        ? (item.value | luxonDate:(profileUser$ | async).dateFormat
                                        || dataTypeService.dateFormat : (gmt$ | async) || dataTypeService.GMT : (ln$ | async))
                                        : item.value}}</div>
                                </ng-template>
                            </ng-container>

                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="info__title" (click)="setCurrentBlock('userProfileInfoSystem')">
                <svg-icon [src]="(blocks$ | async)?.userProfileInfoSystem ? ARROW_ICON_DOWN : ARROW_ICON_UP"></svg-icon>
                <span [translate]="'profile.systemData'"></span>
            </div>
            <ng-container *ngIf="(blocks$ | async)?.userProfileInfoSystem">
                <div class="info__list info__list--system">
                    <div *ngFor="let item of userInfoSystem" class="info__item">
                        <div class="info__item-title" [translate]="item.title"></div>
                        <div class="info__item-value">
                            <ng-container [ngSwitch]="item.elementType">
                                <ng-template [ngSwitchCase]="userProfileInfoTypeElementEnum.input">
                                    <div class="info__value-input">
                                        <input class="info__input info__input--small input" type="text"
                                               [ngModel]="item.value"
                                               [maxlength]="item?.maxLength"
                                               [disabled]="(currentUser$ | async)?.id !== (profileUser$ | async)?.id"
                                               [placeholder]="item.inputPlaceholder | translate"
                                               [class.input--error]="!item.isValid"
                                               (input)="changeSystemData($event.target.value, item.type); isDisabledSave = false; isCanMoveTabEvent.emit(false)">
                                    </div>

                                </ng-template>
                                <ng-template [ngSwitchCase]="userProfileInfoTypeElementEnum.select">
                                    <div class="info__value-select">
                                        <app-custom-select
                                            [isReset]="false"
                                            [isSearch]="true"
                                            [isDisable]="(currentUser$ | async)?.id !== (profileUser$ | async)?.id"
                                            [options]="item.options"
                                            [currentOption]="item.currentOption"
                                            [value]="item.currentOption"
                                            (emmitNewValue)="changeSystemData($event.key, item.type); isDisabledSave = false; isCanMoveTabEvent.emit(false)"></app-custom-select>
                                    </div>

                                </ng-template>
                            </ng-container>
                        </div>
                    </div>
                    <ng-container *ngIf="(currentUser$ | async)?.id === (profileUser$ | async)?.id">
                        <div class="info__item">
                            <div class="info__item-title" [translate]="'admin.userProfile.changePassword'"></div>
                            <div class="info__item-value">
                                <div class="info__value-icon" (click)="showPasswordPopup()">
                                    <svg-icon [src]="CHANGE_PASSWORD_ICON_PATH"></svg-icon>
                                </div>
                            </div>
                        </div>
                        <div class="info__item">
                            <div class="info__item-title" [translate]="'admin.userProfile.authentication'"></div>
                            <div class="info__item-value">
                                <div class="info__value-icon" (click)="openAuthentication()">
                                    <svg-icon [src]="TWO_FACTOR_ICON_PATH"></svg-icon>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                </div>
            </ng-container>
        </div>


        <div class="info__block">
            <ng-container *ngIf="userInfoMailing && userInfoMailing.length">
                <div class="info__title info__title--inline" (click)="setCurrentBlock('userProfileInfoMailing')">
                    <svg-icon
                        [src]="(blocks$ | async)?.userProfileInfoMailing ? ARROW_ICON_DOWN : ARROW_ICON_UP"></svg-icon>
                    <span [translate]="'profile.mailingData'"></span>
                </div>
                <div *ngIf="(blocks$ | async)?.userProfileInfoMailing" class="info__subtitle"
                     [translate]="'profile.mailingDataSubtitle'"></div>
                <ng-container *ngIf="(blocks$ | async)?.userProfileInfoMailing">
                    <div class="info__list info__list--mailing">
                        <div *ngFor="let item of userInfoMailing" class="info__item"
                             [class]="'info__item--' + item.transportType">
                            <div class="info__item-title"
                                 [translate]="'admin.userProfile.' + item?.transportType"></div>
                            <div class="info__item-value">
                                <div class="info__value-input">
                                    <div class="info__value-input-main">
                                        <input class="info__input input"
                                               type="text"
                                               [ngModel]="item?.transport"
                                               [disabled]="(currentUser$ | async)?.id !== (profileUser$ | async)?.id"
                                               [placeholder]="item.inputPlaceholder | translate"
                                               [class.input--error]="!item.isValid"
                                               [appInputValidate]="item?.transportType === notificationTransportType.EMAIL ? null : variableValueTypeEnum.numbers"
                                               (input)="changeTransport(item.transportType, $event.target.value); item.transport = $event.target.value;
                                               isDisabledSave = false; isCanMoveTabEvent.emit(false)">

                                        <div class="info__value-act"
                                             [class.info__value-act--unActive]="!item?.isActive"
                                             [class.info__value-act--disabled]="!item?.transport"
                                             (click)="changeTransportActive(item, !item.isActive)">
                                            <svg-icon [src]="ACT_ICON_PATH"></svg-icon>
                                        </div>

                                        <div *ngIf="item.savedStatus === userNotificationTransportStatusEnum.PROCESS"
                                             class="info__saved-status">
                                <span [translate]="'profile.dontConfirm'"
                                      [translateParams]="{item: item.transportType}"></span>
                                            <span class="link" [translate]="'profile.confirmNow'"
                                                  (click)="repeatConfirm(item)"></span>
                                        </div>

                                        <div *ngIf="item.savedStatus === userNotificationTransportStatusEnum.ERROR"
                                             class="info__saved-status info__saved-status--error">
                                            <span [translate]="'profile.errorTransport'"></span>
                                        </div>
                                    </div>

                                    <div class="info__value-resources">
                                        <div class="info__resource">
                                            <app-checkbox [title]="'profile.report'"
                                                          [isDisabled]="!item.transport"
                                                          [value]="item.resource.includes(notificationTransportResourceEnum.REPORTS)"
                                                          (changeEvent)="changeResource(item.transportType, notificationTransportResourceEnum.REPORTS, $event); isDisabledSave = false; isCanMoveTabEvent.emit(false)"></app-checkbox>
                                            <div class="info__resource-describe" [translate]="'profile.reportDescribe'"
                                                 [translateParams]="{transport: item?.transportType}"></div>
                                        </div>
                                        <div class="info__resource">
                                            <app-checkbox [title]="'profile.event'"
                                                          [isDisabled]="!item.transport"
                                                          [value]="item.resource.includes(notificationTransportResourceEnum.EVENTS)"
                                                          (changeEvent)="changeResource(item.transportType, notificationTransportResourceEnum.EVENTS, $event); isDisabledSave = false; isCanMoveTabEvent.emit(false)"></app-checkbox>
                                            <div class="info__resource-describe" [translate]="'profile.eventDescribe'"
                                                 [translateParams]="{transport: item?.transportType}"></div>
                                        </div>
                                    </div>
                                    <div *ngIf="!item.isValid; else valid" class="info__value-error"
                                         [translate]="'profile.errorDataFormat'"></div>
                                    <ng-template #valid>
                                        <div *ngIf="item.transportType !== notificationTransportType.EMAIL"
                                             class="info__value-transport-note">
                                            <span [translate]="'profile.moveLink'"
                                                  (checkNodes)="checkNodes($event)"></span>
                                            <a class="info__value-transport-link"
                                               [href]="'https://t.me/' + (configuration$ | async)?.telegramBotName"
                                               target="_blank">{{(configuration$ | async)?.telegramBotName}}</a>
                                            <span [translate]="'profile.orSkan'"></span>
                                            <span class="info__value-transport-link"
                                                  (click)="generateQrCode(item.transportType)">QR-code</span>
                                            <span [translate]="'profile.moveBot'"
                                                  [translateParams]="{bot: item.transportType}"></span>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </ng-container>

        </div>

    </div>

    <div *ngIf="(currentUser$ | async)?.id === (profileUser$ | async)?.id" class="info__save"
         [class.info__save--admin]="router.url.includes('control')"
    >
        <div class="info__item-title"></div>
        <div class="info__item-value">
            <button class="info__save-btn btn" [disabled]="isDisabledSave" [translate]="'btns.save'"
                    (click)="updateUser(); isDisabledSave = true"></button>
        </div>
    </div>
</div>

<app-popup *ngIf="popupAuthentication" [title]="'profile.authenticationPopup.title'"
           (closeEmit)="closeAuthenticationPopup()">
    <div class="authentication">
        <ng-container *ngIf="!openVerification; else verification">
            <div class="authentication__body">
                <div class="authentication__message">
                    <p class="authentication__message-text"
                       [innerHTML]="'profile.authenticationPopup.messages.text' | translate"
                    ></p>
                    <p class="authentication__message-text"
                       [translate]="'profile.authenticationPopup.messages.warning'"></p>
                </div>

                <div class="authentication__btns">
                    <button (click)="closeAuthenticationPopup()" class="btn btn--light"
                            [translate]="'btns.cancel'"></button>
                    <button (click)="generation()" class="btn"
                            [accessKey]="'enter'"
                            [translate]="'btns.continue'"></button>
                </div>
            </div>
        </ng-container>

        <ng-template #verification>
            <div class="verification">
                <div class="verification__wrapper">
                    <div *ngIf="qrSecret$ | async" class="verification__qr-code">
                        <qrcode [qrdata]="qrSecret$ | async" [margin]="2" [width]="180"
                                allowEmptyString="true"
                                [errorCorrectionLevel]="'H'" [elementType]="'svg'"></qrcode>
                    </div>

                    <div class="verification__message">
                        <div class="verification__message-text">
                            <span class="verification__message-text"
                                  [translate]="'profile.authenticationPopup.messages.verification'"
                                  [translateParams]="{code: secret$ | async}"></span>
                        </div>

                        <label class="verification__label" for="code"
                               [translate]="'profile.authenticationPopup.verificationCode'"></label>
                        <input [(ngModel)]="verificationCode"
                               [placeholder]="'profile.authenticationPopup.inputPlaceholder' | translate"
                               type="text" id="code" class="verification__input input">
                    </div>
                </div>

                <div class="verification__btns">
                    <button (click)="closeAuthenticationPopup()" class="btn btn--light"
                            [translate]="'btns.cancel'"></button>
                    <button [disabled]="!verificationCode.length" (click)="verificationKey()" class="btn"
                            [accessKey]="'enter'"
                            [translate]="'profile.authenticationPopup.generationBtn'"></button>
                </div>
            </div>
        </ng-template>
    </div>

</app-popup>

<app-popup *ngIf="popupDeleteVerification" [title]="'profile.authenticationPopup.title'"
           (closeEmit)="closeDeleteVerificationPopup()">
    <div class="popup">
        <div class="popup__delete">
            <span class="popup__delete-message" [translate]="'profile.authenticationPopup.messages.delete'"></span>
            <input [(ngModel)]="deleteCode"
                   [placeholder]="'profile.authenticationPopup.inputPlaceholder' | translate" class="input"
                   type="text">
        </div>

        <div class="popup__delete-btns">
            <button (click)="popupDeleteVerification = false; deleteCode = ''" class="btn btn--light"
                    [translate]="'btns.cancel'"></button>
            <button (click)="unBindToken()" class="btn btn--red"
                    [accessKey]="'enter'"
                    [translate]="'btns.delete'"></button>
        </div>
    </div>
</app-popup>

<app-popup *ngIf="popupChangePassword" [title]="'profile.changePasswordTitle'"
           (closeEmit)="popupChangePassword = false; strength = -1; errorRepeat = false;">
    <div class="block">
        <form class="login__form" [formGroup]="formPassword" novalidate>
            <div class="login__form-item">
                <label class="login__form-label" [translate]="'auth.registration.password'"></label>
                <input [type]="isVisiblePassword ? 'text' : 'password'"
                       [class.login__form-input--middle]="strength === 1"
                       [class.login__form-input--strong]="strength > 1"
                       (keydown.backspace)="lookChangePasswordInput()" [(ngModel)]="modelPassword"
                       class="login__form-input"
                       placeholder="{{'auth.reset.passwordPlaceholder' | translate}}" type="text"
                       (input)="isEmptyForm = false" formControlName="password">
                <div class="login__icon-eyes" (click)="isVisiblePassword = !isVisiblePassword">
                    <svg-icon *ngIf="!isVisiblePassword; else eyes" [src]="'./assets/design/icons/eyes.svg'"></svg-icon>
                    <ng-template #eyes>
                        <svg-icon [src]="'./assets/design/icons/eyes-close.svg'"></svg-icon>
                    </ng-template>
                </div>
                <p class="login__form-error" [class.login__form-error--middle]="strength === 1"
                   [class.login__form-error--default]="strength === -1"
                   [class.login__form-error--error]="strength === 0 || (strength === -1 && formPassword.controls['password'].invalid && formPassword.controls['password'].touched)"
                   [class.login__form-error--success]="strength > 1">
                    <span *ngIf="strength < 1" [translate]="'auth.registration.passwordNote'"></span>
                    <span *ngIf="strength === 1" [translate]="'auth.registration.passwordBetter'"></span>
                    <span *ngIf="strength === 2" [translate]="'auth.registration.passwordBest'"></span>
                </p>

            </div>
            <div class="login__form-item">
                <label class="login__form-label" [translate]="'auth.registration.repeat'"></label>
                <input class="login__form-input" placeholder="{{'auth.registration.repeatPlaceholder' | translate}}"
                       type="text" formControlName="repeat" [type]="isVisiblePassword ? 'text' : 'password'"
                       [(ngModel)]="repeatPassword"
                       (input)="isEmptyForm = false">
                <div class="login__icon-eyes" (click)="isVisiblePassword = !isVisiblePassword">
                    <svg-icon *ngIf="!isVisiblePassword; else eyes" [src]="'./assets/design/icons/eyes.svg'"></svg-icon>
                    <ng-template #eyes>
                        <svg-icon [src]="'./assets/design/icons/eyes-close.svg'"></svg-icon>
                    </ng-template>
                </div>
                <p class="login__form-error"
                   *ngIf="(formPassword.controls['repeat'].invalid && formPassword.controls['repeat'].touched && !isEmptyForm) || errorRepeat"
                   [translate]="'auth.registration.errorRepeatPassword'"></p>
                <p class="login__form-error" *ngIf="isEmptyForm" [translate]="'auth.registration.requireError'"></p>

            </div>
            <div class="login__form-activity">
                <div class="login__form-activity-item">
                    <div class="login__form-activity-column">
                        <button type="button" (click)="popupChangePassword = false; errorRepeat = false; strength = -1"
                                class="block__btn btn btn--light"
                                [translate]="'btns.close'"></button>
                        <button type="button"
                                [disabled]="strength < 1"
                                (click)="changePassword();" class="block__btn btn btn--red"
                                [translate]="'profile.changePassword'"></button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</app-popup>

<app-popup *ngIf="qrCodePopup" [title]="'profile.popup.title'" (closeEmit)="qrCodePopup = false; linkForQrCode = null">
    <div class="popup__message">

    </div>
    <div class="popup__qr">
        <qrcode [qrdata]="linkForQrCode" [margin]="0" [width]="128"
                allowEmptyString="true"
                [errorCorrectionLevel]="'H'" [elementType]="'svg'"></qrcode>
    </div>

</app-popup>

import {AdminSharedModule} from './admin-shared.module';
import {AdminComponent} from './admin.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';


@NgModule({
    declarations: [AdminComponent],
    imports: [
        CommonModule,
        FormsModule,
        AdminSharedModule,
    ],
    exports: []
})
export class AdminModule { }

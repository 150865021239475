import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class DesktopGuard {
    constructor(
        public platform: Platform,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (!this.platform.ANDROID && !this.platform.IOS) {
            return true;
        }

        this.router.navigate(['/m/devices']);
    }
}

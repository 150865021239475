<ng-container>
    <div class="alert">
        <div
            *ngIf="((errorAlertData$ | async)?.deviceEvent || (errorAlertData$ | async)?.deviceEventBlink) && platform !== platformEnum.mobile"
            class="alert__wrapper alert__wrapper--device"
            [class.blink]="(errorAlertData$ | async).deviceEventBlink"
            (click)="deviceClick()">
            <div class="alert__icon">
                <svg-icon [src]="DEVICE_EVENT_PATH"></svg-icon>
            </div>
            <div class="alert__message" [translate]="'logs.events.system'"></div>
        </div>

        <div *ngIf="(errorAlertData$ | async)?.alarm || (errorAlertData$ | async)?.alarmBlink"
             class="alert__wrapper alert__wrapper--alert"
             [class.blink]="(errorAlertData$ | async).alarmBlink"
             [class.alert__wrapper--mobile]="platform === platformEnum.mobile"
             (click)="alertClick()">
            <div class="alert__icon">
                <svg-icon [src]="platform === platformEnum.mobile ? M_ALARM_PATH : ALARM_PATH"></svg-icon>

            </div>
            <div class="alert__message" [translate]="'logs.events.alert'"></div>
        </div>

        <div *ngIf="(errorAlertData$ | async)?.attention || (errorAlertData$ | async)?.attentionBlink"
             class="alert__wrapper alert__wrapper--attention"
             [class.blink]="(errorAlertData$ | async).attentionBlink"
             [class.alert__wrapper--mobile]="platform === platformEnum.mobile"
             (click)="attentionClick()">

            <div class="alert__icon">
                <svg-icon [src]="platform === platformEnum.mobile ? M_ATTENTION_PATH : ATTENTION_PATH"></svg-icon>
            </div>
            <div class="alert__message" [translate]="'logs.events.attention'"></div>
        </div>


    </div>
</ng-container>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-edit-multi-name',
    templateUrl: './edit-multi-name.component.html',
    styleUrls: ['./edit-multi-name.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMultiNameComponent implements OnInit {
    newName;
    saveName: any;
    isEdited: boolean;

    @Input() width: string;
    @Input() name: string;
    @Input() isDisabled: boolean;
    @Input() isError: boolean;
    @Input() isShowBtn = true;
    @Output() nameEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() updateNameEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() cancelEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() editModeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    readonly OK_ICON_PATH = './assets/design/icons/ok.svg';
    readonly CANCEL_ICON_PATH = './assets/design/icons/cancel.svg';

    constructor() {}

    ngOnInit(): void {
        this.saveName = this.name;
    }

    setNewName(event): any {
        this.newName = event;
    }

    setUpdateName(event): any {
        this.updateNameEvent.emit(event);
    }

    setOk(): void {
        if (!this.name || !this.name.length) {
            return;
        }
        this.editModeEvent.emit(false);
        this.isEdited = false;
        this.saveName = this.name;
        this.nameEvent.emit(this.name);
    }

    cancel(): void {
        this.editModeEvent.emit(false);
        this.isEdited = false;
        this.name = this.saveName;
        this.cancelEvent.emit();
    }
}

import {DeviceLogsState} from './states/device-logs.state';
import {UserLogsState} from './states/user-logs.state';
import {TechnologicEventsState} from './states/technologic-events.state';
import {MailingMessageLogsState} from './states/mailing-messages-log.state';
import {ActiveDeviceEventState} from './states/active-device-event.state';

export const journalsStates = [
    UserLogsState,
    DeviceLogsState,
    TechnologicEventsState,
    MailingMessageLogsState,
    ActiveDeviceEventState
];

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ThemeEnum} from './_enums/theme.enum';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    public value = (localStorage.getItem('theme') as ThemeEnum) || ThemeEnum.light;
    private activeTheme: BehaviorSubject<ThemeEnum> = new BehaviorSubject<ThemeEnum>(this.value);
    activeTheme$ = this.activeTheme.asObservable();


    public getActiveTheme(): Observable<ThemeEnum> {
        return this.activeTheme.asObservable();
    }

    setActiveTheme(theme: ThemeEnum): void {
        this.activeTheme.next(theme);
    }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BillingInvoiceInterface } from '../../billing/_interfaces/billing-invoice.interface';
import { ServicesInvoiceEnum, ServicesInvoiceInterface } from '../../billing/_interfaces/billing-service-invoice.interface';
import { Select, Store } from '@ngxs/store';
import { AuthState } from '../../auth/_store/states/auth.state';
import { Observable, Subject } from 'rxjs';
import { CloseEditingAdditional, SetEditingAdditional } from '../../billing/_store/actions/billing-invoice.actions';
import { BillingInvoiceState } from '../../billing/_store/states/billing-invoice.state';
import { VariableValueTypeEnum } from '../../groups/_enums/variable-value-type.enum';
import { CurrencyState } from '../_store/states/currency.state';
import { GetCurrency } from '../_store/actions/currency.actions';

@Component({
    selector: 'app-invoice-table',
    templateUrl: './invoice-table.component.html',
    styleUrls: ['./invoice-table.component.scss'],
})
export class InvoiceTableComponent implements OnInit {
    @Select(BillingInvoiceState.getIsEditingMode) isEditingMode$: Observable<boolean>;
    @Select(AuthState.getIsAdmin) isAdmin$: Observable<boolean>;
    @Select(CurrencyState.getCurrency) currency$: Observable<string>;

    @Input() tableWidth: string;
    @Input() currentInvoice: BillingInvoiceInterface;

    @Output() addServiceEvent = new EventEmitter<void>();
    @Output() deleteServiceEvent = new EventEmitter<ServicesInvoiceInterface>();
    @Output() updateServiceEvent = new EventEmitter<{ row: BillingInvoiceInterface; data: ServicesInvoiceInterface }>();

    @Output() isCanMoveTabEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    ADD_ICON_PATH = './assets/design/icons/invoice/plus.svg';
    ADD_DISABLED_ICON_PATH = './assets/design/icons/invoice/plus-disabled.svg';
    DELETE_ICON_PATH = './assets/design/icons/invoice/delete.svg';

    servicesInvoiceEnum = ServicesInvoiceEnum;
    variableValueTypeEnum = VariableValueTypeEnum;

    currentAdditionalInvoice: ServicesInvoiceInterface[];

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(new GetCurrency());
        this.isCanMoveTabEvent.emit(true);
        this.currentAdditionalInvoice = this.currentInvoice?.services?.filter((f) => f.typeService === ServicesInvoiceEnum.ADDITIONAL);
    }

    addNewInvoice(): void {
        this.isCanMoveTabEvent.emit(false);
        this.addServiceEvent.emit();
    }

    deleteInvoice(item: ServicesInvoiceInterface): void {
        this.deleteServiceEvent.emit(item);
        this.isCanMoveTabEvent.emit(true);
    }

    saveInvoice(data: ServicesInvoiceInterface): void {
        this.isCanMoveTabEvent.emit(true);
        const row = this.store.selectSnapshot(BillingInvoiceState.getCurrentInvoice);
        this.updateServiceEvent.emit({ row, data });
    }

    editRow(row: ServicesInvoiceInterface): void {
        if (!this.store.selectSnapshot(AuthState.getIsAdmin)) {
            return;
        }
        this.isCanMoveTabEvent.emit(false);
        this.store.dispatch(new SetEditingAdditional(row.id));
    }

    closeEditRow(data: ServicesInvoiceInterface): void {
        this.isCanMoveTabEvent.emit(true);
        this.store.dispatch(new CloseEditingAdditional(data.id, data));
    }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserProfileInfoTypeDateEnum } from 'src/app/profile/_enums/user-profile-info-date-time.enum';
import { FromToTypeCalendar } from '../filters/enums/type-filter.enum';
import { DatePickerConfigInterface } from '../_interfaces/date-picker-config.interface';

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnInit {
    @Input() config: DatePickerConfigInterface;

    @Output() datepickerEvent: EventEmitter<DatePickerConfigInterface> = new EventEmitter<DatePickerConfigInterface>();

    userProfileInfoTypeDateEnum = UserProfileInfoTypeDateEnum;
    fromToTypeCalendar = FromToTypeCalendar;

    readonly DAY_IN_MILLS = 86400000;

    dateNow = new Date();

    constructor() {}

    ngOnInit(): void {}

    selectCalendar(from: FromToTypeCalendar, event: Date): void {
        switch (from) {
            case FromToTypeCalendar.from:
                this.datepickerEvent.emit({
                    ...this.config,
                    from: event,
                    to: null,
                });
                break;
            case FromToTypeCalendar.to:
                this.datepickerEvent.emit({
                    ...this.config,
                    to: event,
                });
                break;
        }
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { UserProfileInfo } from 'src/app/app-shared-elements/_interfaces/UserInfo';
import { NotificationTransportType } from 'src/app/mailing/_interfaces/notification-log.interface';
import { UserNotificationTransport } from '../../_interfaces/user-notification-transport.interface';
import { ProfileService } from '../../_services/profile.service';
import { ChangeUserNotificationsInfo, ChangeUserProfileInfo, ChangeUserProfileSystem, GetUserNotificationsInfo, SetCurrentProfileUser, SetUserProfileInfo } from '../actions/profile.actions';

export interface ProfileStateModel {
    profileUser: User;
    userProfileInfo: UserProfileInfo[];
    userProfileInfoMailing: UserNotificationTransport[];
    userProfileInfoSystem: UserProfileInfo[];
}

const PROFILE_STATE = new StateToken<ProfileStateModel>('profileState');

@State<ProfileStateModel>({
    name: PROFILE_STATE,
    defaults: {
        profileUser: null,
        userProfileInfo: [],
        userProfileInfoMailing: [],
        userProfileInfoSystem: [],
    },
})
@Injectable()
export class ProfileState {
    constructor(
        private profileService: ProfileService,
        private http: HttpClient,
        private store: Store,
    ) {}

    @Selector()
    static getProfileUser(state: ProfileStateModel): User {
        return state.profileUser;
    }

    @Selector()
    static getUserProfileInfo(state: ProfileStateModel): UserProfileInfo[] {
        return state.userProfileInfo;
    }

    @Selector()
    static getUserProfileInfoMailing(state: ProfileStateModel): UserNotificationTransport[] {
        return state.userProfileInfoMailing.sort((a, b) => {
            if (a.transportType === NotificationTransportType.TELEGRAM) {
                return -1;
            }

            if (a.transportType === NotificationTransportType.VIBER && b.transportType !== NotificationTransportType.TELEGRAM) {
                return -1;
            }

            return 1;
        });
    }

    @Selector()
    static getUserProfileInfoSystem(state: ProfileStateModel): UserProfileInfo[] {
        return state.userProfileInfoSystem;
    }

    @Action(SetCurrentProfileUser)
    setCurrentProfileUser(ctx: StateContext<ProfileStateModel>, payload: SetCurrentProfileUser): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            profileUser: payload.user,
        });
    }

    @Action(SetUserProfileInfo)
    setUserProfileInfo(ctx: StateContext<ProfileStateModel>, payload: SetUserProfileInfo): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            userProfileInfo: this.profileService.getUserProfileInfo(payload.user, payload.isEditable),
            userProfileInfoSystem: this.profileService.getUserProfileInfoSystem(payload.user, payload.isEditable),
        });
    }

    @Action(ChangeUserProfileInfo)
    changeUserProfileInfo(ctx: StateContext<ProfileStateModel>, payload: ChangeUserProfileInfo): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            userProfileInfo: payload.profileInfo, // state.userProfileInfo.map(item => item.type === payload.profileInfoItem.type ? payload.profileInfoItem : item)
        });
    }

    @Action(ChangeUserProfileSystem)
    changeUserProfileSystem(ctx: StateContext<ProfileStateModel>, payload: ChangeUserProfileSystem): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            userProfileInfoSystem: payload.profileInfo, // state.userProfileInfo.map(item => item.type === payload.profileInfoItem.type ? payload.profileInfoItem : item)
        });
    }

    @Action(GetUserNotificationsInfo)
    async getUserNotificationsInfo(ctx: StateContext<ProfileStateModel>): Promise<void> {
        const result: ApiResponse<UserNotificationTransport[]> = (await this.http
            .get('/api/users/notification')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<UserNotificationTransport[]>;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                userProfileInfoMailing: result.data
                    .filter((f) => f.transportType !== NotificationTransportType.VIBER)
                    .map((item) => {
                        return {
                            ...item,
                            isValid: true,
                            inputPlaceholder: `profile.${item.transportType}Placeholder`,
                        };
                    }),
            });
        }
    }

    @Action(ChangeUserNotificationsInfo)
    changeUserNotificationsInfo(ctx: StateContext<ProfileStateModel>, payload: ChangeUserNotificationsInfo): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            userProfileInfoMailing: state.userProfileInfoMailing.map((item) => {
                const currentTransport = payload.notification.find((n) => n.transportType === item.transportType);
                if (!currentTransport) {
                    return item;
                }
                return currentTransport;
            }),
        });
    }
}

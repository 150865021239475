import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Select} from '@ngxs/store';
import {PermissionsState} from '../../../../app-shared-elements/_store/states/permissions.state';
import {Observable} from 'rxjs';
import {RoleInterface} from '../../../../app-shared-elements/_interfaces/role.interface';

@Component({
    selector: 'app-share-role-info',
    templateUrl: './share-role-info.component.html',
    styleUrls: ['./share-role-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShareRoleInfoComponent implements OnInit {

    @Select(PermissionsState.getRoles) roles$: Observable<RoleInterface[]>;

    constructor() {
    }

    ngOnInit(): void {
    }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { GetUsers } from 'src/app/admin/users/_store/actions/users.actions';
import { UsersState } from 'src/app/admin/users/_store/states/users.state';
import { DeviceTypeEnum } from 'src/app/app-shared-elements/_enums/device-type.enum';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { PerformDeviceService } from 'src/app/app-shared-elements/_services/dynamic/perform-device.service';
import { UserRegistratorInterface } from 'src/app/device-dashboard/user-devices/_interface/user-devices.interface';
import { DeviceService } from 'src/app/device-dashboard/_services/device.service';
import { ActiveEvents } from 'src/app/events/_interfaces/ActiveEvents';
import {
    InitAdminProfileDeviceRows,
    InitRegistratorsOptions,
    InitRoleInfo,
    ToggleRoleInfoPopup,
    UpdateAdminProfileDeviceRows,
} from '../actions/admin-profile.action';
import { AdminProfileDeviceParamsState } from './admin-profile-device-params.state';
import { SetSkeleton } from '../../../../app-shared-elements/_store/actions/table.actions';
import { SelectOptionInterface } from '../../../../app-shared-elements/_interfaces/select-option.interface';
import { ActionListItemEnum } from '../../../../app-shared-elements/_enums/action-list-item.enum';
import { SharedUserInterface } from '../../../../device-dashboard/user-devices/_interface/shared-user.interface';
import { DetailsDeviceRoleInfoInterface } from '../../../../details-device-container/_interface/details-device-role-info.interface';
import { RolesService } from '../../../roles/_services/roles.service';
import { GetCurrentRole } from '../../../roles/_store/actions/roles.actions';
import { RolesState } from '../../../roles/_store/states/roles.state';

export interface AdminProfileStateModel {
    devicesArray: UserRegistratorInterface[];
    registratorsOptions: SelectOptionInterface<string, string, Device>[];
    sharedDevices: SharedUserInterface[];
    roleInfo: DetailsDeviceRoleInfoInterface[];
    isOpenRoleInfoPopup: boolean;
}

const ADMIN_PROFILE_STATE_TOKEN = new StateToken<AdminProfileStateModel>('adminProfileState');

@State<AdminProfileStateModel>({
    name: ADMIN_PROFILE_STATE_TOKEN,
    defaults: {
        devicesArray: [],
        registratorsOptions: [],
        sharedDevices: [],
        roleInfo: [],
        isOpenRoleInfoPopup: false,
    },
})
@Injectable()
export class AdminProfileState {
    constructor(
        private store: Store,
        private http: HttpClient,
        private performDeviceService: PerformDeviceService,
        private deviceService: DeviceService,
        private rolesService: RolesService,
    ) {}

    @Selector()
    static getDevicesArray(state: AdminProfileStateModel): UserRegistratorInterface[] {
        return state.devicesArray;
    }

    @Selector()
    static getRegistratorsOptions(state: AdminProfileStateModel): SelectOptionInterface<string, string, Device>[] {
        return state.registratorsOptions;
    }

    @Selector()
    static getSharedDevices(state: AdminProfileStateModel): SharedUserInterface[] {
        return state.sharedDevices;
    }

    @Selector()
    static getIsOpenRoleInfoPopup(state: AdminProfileStateModel): boolean {
        return state.isOpenRoleInfoPopup;
    }

    @Selector()
    static getRoleInfo(state: AdminProfileStateModel): DetailsDeviceRoleInfoInterface[] {
        // console.log(state.roleInfo);
        return state.roleInfo;
    }

    @Action(InitAdminProfileDeviceRows)
    async initUserDevicesArray(ctx: StateContext<AdminProfileStateModel>, payload: InitAdminProfileDeviceRows): Promise<void> {
        const headers = new HttpHeaders({
            currentUserId: payload.userId,
        });
        const result = (await this.http
            .get('/api/devices', { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (!result || !result.data.length) {
            ctx.setState({
                ...state,
                devicesArray: [],
            });

            await ctx.dispatch(new SetSkeleton(false)).toPromise();
            return;
        }

        const resultActiveEvents = (await this.http
            .get('/api/logic-events-log/active-log', { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        let devices: Device[] = await this.performDeviceService.setValue(result.data);
        devices = await (await this.performDeviceService.setStatus(devices, (resultActiveEvents.data as ActiveEvents[]) ?? [], true)).all;
        devices = await (await this.performDeviceService.setConnect(devices)).all;

        if (!this.store.selectSnapshot(UsersState.getUsers).length) {
            await this.store.dispatch(new GetUsers()).toPromise();
        }

        await ctx.dispatch(new SetSkeleton(false)).toPromise();

        ctx.setState({
            ...state,
            devicesArray: devices
                .filter((device) => device.type === DeviceTypeEnum.registrator)
                .map((registrator, index) => {
                    return {
                        registrator: {
                            ...registrator,
                            name: registrator.name,
                            isVisible: true,
                        },
                        devices: devices
                            .filter((d) => d.registratorId === registrator.id)
                            .map((d, i) => {
                                return {
                                    ...d,
                                    name: d.name || d.defaultName,
                                    isAction: false,
                                    listAction: registrator.roleId
                                        ? [
                                              {
                                                  title: 'btns.access',
                                                  action: ActionListItemEnum.access,
                                              },
                                          ]
                                        : [],
                                    isVisible: true,
                                    position: i,
                                };
                            })
                            .sort((a, b) => a.position - b.position),
                        isActionRegistrator: false,
                        position: index,
                        listAction: registrator.roleId
                            ? [
                                  {
                                      title: 'btns.access',
                                      action: ActionListItemEnum.access,
                                  },
                              ]
                            : [],
                    };
                }),
        });
    }

    @Action(UpdateAdminProfileDeviceRows)
    updateAdminProfileDeviceRows(ctx: StateContext<AdminProfileStateModel>): void {
        const params = this.store.selectSnapshot(AdminProfileDeviceParamsState.getParams);
        const state = ctx.getState();

        ctx.setState({
            ...state,
            devicesArray: state.devicesArray.map((item, index) => {
                return {
                    ...item,
                    registrator: {
                        ...item.registrator,
                        isVisible: this.deviceService.getIsVisibleDevice(item.registrator, params),
                    },
                    devices: item.devices
                        .map((d, i) => {
                            return {
                                ...d,
                                isVisible: this.deviceService.getIsVisibleDevice(d, params),
                            };
                        })
                        .sort((a, b) => a.position - b.position),
                };
            }),
        });
    }

    @Action(InitRegistratorsOptions)
    initRegistratorsOptions(ctx: StateContext<AdminProfileStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            registratorsOptions: state.devicesArray.map((item) => {
                return {
                    key: item.registrator.id,
                    property: item.registrator,
                    value: item.registrator.name ?? item.registrator.defaultName,
                };
            }),
        });
    }

    @Action(InitRoleInfo)
    async initRoleInfo(ctx: StateContext<AdminProfileStateModel>, payload: InitRoleInfo): Promise<void> {
        const state = ctx.getState();
        await ctx.dispatch(new GetCurrentRole(payload.roleId)).toPromise();

        const currentRole = this.store.selectSnapshot(RolesState.getCurrentUserRole);
        // console.log(currentRole);
        ctx.setState({
            ...state,
            roleInfo: [
                {
                    role: currentRole.name,
                    description: `<span class="permissions">${await this.rolesService.getDescriptionForCurrentRole(
                        currentRole.permissions,
                    )}</span>`,
                },
            ],
            isOpenRoleInfoPopup: true,
        });
    }

    @Action(ToggleRoleInfoPopup)
    toggleRoleInfoPopup(ctx: StateContext<AdminProfileStateModel>, payload: ToggleRoleInfoPopup): void {
        const state = ctx.getState();

        if (!payload.toggle) {
            ctx.setState({
                ...state,
                roleInfo: [],
            });
        }

        ctx.setState({
            ...state,
            isOpenRoleInfoPopup: payload.toggle,
        });
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DropdownFilterOptionInterface } from 'src/app/app-shared-elements/filters/interfaces/filter-option.interface';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { DeviceLogsState } from '../_store/states/device-logs.state';
import {
    GetDeviceLogs,
    SetAcknowledgeDeviceLog,
    SetDeviceLogsFilter,
    SetRowsForGroupOperation,
} from '../_store/actions/device-logs.actions';
import { FilterByPermissionsEnum } from 'src/app/app-shared-elements/_enums/filter-by-permissions-type.enum';
import { MethodPermission, ResourceAction } from 'src/app/app-shared-elements/_enums/permission.enum';
import { filter, first, takeUntil } from 'rxjs/operators';
import { TableDeviceLogsInterface } from '../../device-dashboard/user-devices/_interface/table-device-logs.interface';
import { DevicesState } from '../../device-dashboard/_store/states/user-devices.state';
import { GetDevices } from '../../device-dashboard/_store/actions/user-devices.actions';
import { DeviceLogsService } from '../_services/device-logs.service';
import { FilterTypeSystemLogsEnum } from '../_enums/filter-group-system-logs.enum';
import { FilterTypePipeEnum } from '../../app-shared-elements/_enums/filter-type-pipe.enum';
import { SetFilterConfig, SetTimeObj, SetTimeType } from '../../app-shared-elements/_store/actions/time-filter.actions';
import { DisabledFilterOptionsEnum } from '../../app-shared-elements/_enums/filter-options.enum';
import { TimeFilterState } from '../../app-shared-elements/_store/states/time-filter.state';
import { FilterDropdownDeviceLogKeyEnum } from '../_enums/filter-dropdown-key.enum';
import { NotificationsService } from '../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../app-shared-elements/_enums/tooltip-status.enum';
import { PermissionsState } from '../../app-shared-elements/_store/states/permissions.state';
import { PermissionService } from '../../app-shared-elements/_services/permission.service';
import { ColumnsTableInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { CreationType } from '../../app-shared-elements/_enums/registrator-sync-status.enu';
import { Device } from '../../app-shared-elements/_interfaces/Device';
import { InitTableGroupOperations, SetSkeleton } from 'src/app/app-shared-elements/_store/actions/table.actions';
import {
    Pagination,
    Params,
    ParamsFilter,
    ParamsFilterForClient,
    ParamsSorted,
    ParamsTime,
    ParamsTimeTypeEnum,
} from '../../app-shared-elements/_interfaces/params.interface';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { User } from '../../app-shared-elements/_interfaces/user.interface';
import { UserState } from '../../app-shared-elements/_store/states/user.state';
import { GroupOperationActionInterface } from 'src/app/app-shared-elements/tables/_interfaces/group-operation-action.interface';
import { TableOperationInterface, TableOperationTypeEnum } from 'src/app/app-shared-elements/tables/_interfaces/table-operation.interface';
import { TableService } from 'src/app/app-shared-elements/_services/table.service';
import { StateReset } from 'ngxs-reset-plugin';
import { SelectOptionInterface } from '../../app-shared-elements/_interfaces/select-option.interface';
import { FilterItemTypeEnum } from '../../app-shared-elements/filters/enums/filter-item-type.enum';
import { AdminDevicesState } from '../../admin/admin-devices/_store/states/admin-devices.state';
import { AuthState } from '../../auth/_store/states/auth.state';
import { GetAllDevices } from '../../admin/admin-devices/_store/actions/admin-devices.actions';

@Component({
    selector: 'app-device-logs',
    templateUrl: './device-logs.component.html',
    styleUrls: ['./device-logs.component.scss'],
})
export class DeviceLogsComponent implements OnInit, OnDestroy {
    private intervalLogs;
    private user: User;
    showPopup = false;
    registratorsOptions: SelectOptionInterface<string, string, Device>[] = [];

    private searchValue: string;
    filterType: FilterTypePipeEnum;
    filterTypePipeEnum = FilterTypePipeEnum;
    filterItemTypeEnum = FilterItemTypeEnum;

    filterByPermissionsEnum = FilterByPermissionsEnum;
    resourceAction = ResourceAction;
    methodPermission = MethodPermission;
    defaultDropdownValue;
    currentValueForDropdownInput;

    private currentRoute: string;

    deviceLogColumns: string[];

    deviceTablesColumns: ColumnsTableInterface[] = [];

    @Select(DeviceLogsState.getDeviceLogsRows) rowsLogs$: Observable<TableDeviceLogsInterface[]>;
    @Select(DeviceLogsState.getParams) params$: Observable<Params>;
    @Select(TimeFilterState.getTimeObj) timeObj$: Observable<ParamsTime>;
    @Select(DevicesState.getDevices) devices$: Observable<Device[]>;
    @Select(UserState.getUser) user$: Observable<User>;
    @Select(DeviceLogsState.getChoosenRowIds) choosenRowIds$: Observable<string>;

    destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        public deviceLogsService: DeviceLogsService,
        private translateService: TranslateService,
        private notificationService: NotificationsService,
        private store: Store,
        private route: ActivatedRoute,
        private permissionService: PermissionService,
        private router: Router,
        private tableService: TableService,
    ) {
        this.store.dispatch(new SetSkeleton(true));

        this.store.dispatch(new SetTimeObj({ from: null, to: null, time: null, type: ParamsTimeTypeEnum.ALL_TIME }));
        this.store.dispatch(
            new SetFilterConfig({
                type: 'date',
                disableFunctions: [
                    DisabledFilterOptionsEnum.current,
                    DisabledFilterOptionsEnum.time,
                    DisabledFilterOptionsEnum.chartType,
                    DisabledFilterOptionsEnum.resetBtn,
                ],
            }),
        );
        this.store.dispatch(new SetTimeType(ParamsTimeTypeEnum.ALL_TIME));

        this.user$.pipe(takeUntil(this.destroy)).subscribe((user) => {
            if (!user) {
                return;
            }

            this.user = user;
        });

        this.route.queryParamMap.pipe(takeUntil(this.destroy)).subscribe((query: ParamMap) => {
            const id = query.get('id');
            if (query && id) {
                const currentFilter = this.deviceLogsService.dropDownFilterOptions.find(
                    (option) => option.key === FilterDropdownDeviceLogKeyEnum.registratorId,
                );
                this.deviceLogsService.setDropDownFilter({ ...currentFilter, filterValue: id });
                this.defaultDropdownValue = currentFilter;
                this.currentValueForDropdownInput = id;

                const params: Params = this.store.selectSnapshot(DeviceLogsState.getParams);
                const newFilters = (params.filter as ParamsFilter[]).map((f: ParamsFilter) => {
                    if (f.isDropdown && f.property === 'registratorId') {
                        f.value = id;
                    }

                    return f;
                });
                this.store.dispatch(new SetDeviceLogsFilter({ ...params, filter: newFilters }));
            }

            const type: FilterTypeSystemLogsEnum = query.get('type') as FilterTypeSystemLogsEnum;

            if (type) {
                const params: Params = this.store.selectSnapshot(DeviceLogsState.getParams);

                switch (type) {
                    case FilterTypeSystemLogsEnum.deviceBlink:
                        const newFilters: ParamsFilter[] = (params.filter as ParamsFilter[]).map((element) =>
                            element.property === 'isAknowledgeable'
                                ? {
                                      ...element,
                                      value: true,
                                  }
                                : element,
                        );
                        this.store.dispatch(new SetDeviceLogsFilter({ ...params, filter: newFilters }));
                        break;
                }
            }
        });

        this.timeObj$.pipe(takeUntil(this.destroy)).subscribe(async (timeObj) => {
            const params: Params = this.store.selectSnapshot(DeviceLogsState.getParams);
            this.store.dispatch(
                new SetDeviceLogsFilter({
                    ...params,
                    pagination: { ...params.pagination, currentPage: 1 },
                }),
            );
            await this.nextLogs();
        });

        this.translateService.onLangChange.pipe(takeUntil(this.destroy)).subscribe(async () => {
            await this.nextLogs();
        });
    }

    async ngOnInit(): Promise<void> {
        if (!this.store.selectSnapshot(DevicesState.getIsLoadDevices)) {
            await this.store.dispatch(new GetDevices());
        }

        this.deviceLogColumns = JSON.parse(localStorage.getItem(`deviceLogColumns${this.user.id}`)) || [];
        this.initTableColumns();

        if (
            !this.store.selectSnapshot(AdminDevicesState.isLoadAdminDevices) &&
            this.router.url.includes('control') &&
            this.store.selectSnapshot(AuthState.getIsAdmin)
        ) {
            await this.store.dispatch(new GetAllDevices()).toPromise();
        }

        this.devices$
            .pipe(
                filter((devices) => !!devices && !!devices.length),
                first(),
            )
            .subscribe((devices) => {
                this.currentRoute = this.route.snapshot.queryParams.id || null;
                this.searchValue = this.route.snapshot.queryParams.id;
                if (this.currentRoute) {
                    this.filterType = FilterTypePipeEnum.filterDeviceLogByRegistratorName;
                }
                this.parseDeviceOptions();
            });

        if (this.router.url.includes('control') && this.store.selectSnapshot(AuthState.getIsAdmin)) {
            await this.parseDeviceOptions();
        }

        this.tableService.tableGroupOperationEvent.subscribe(async (data: TableOperationInterface) => {
            const ids = this.store.selectSnapshot(DeviceLogsState.getChoosenRowIds);
            await this.store.dispatch(new SetAcknowledgeDeviceLog(ids)).toPromise();
            this.store.dispatch(new SetSkeleton(true));
            await this.nextLogs();
            this.store.dispatch(new SetSkeleton(false));
        });

        this.setTableOperations();
    }

    ngOnDestroy(): void {
        clearTimeout(this.intervalLogs);
        this.destroy.next(true);
        this.destroy.complete();
        this.store.dispatch(new StateReset(DeviceLogsState));
    }

    async onChangePageLogs(data: Pagination): Promise<void> {
        // await this.deviceLogsService.onChangePage(data);
        const params = this.store.selectSnapshot(DeviceLogsState.getParams);
        await this.store.dispatch(new SetDeviceLogsFilter({ ...params, pagination: data }));
    }

    async onChangeSortLogs(data: ParamsSorted[]): Promise<void> {
        const params: Params = this.store.selectSnapshot(DeviceLogsState.getParams);
        this.store.dispatch(new SetDeviceLogsFilter({ ...params, sorted: [...data] }));
    }

    async onChangeDropDownLogs(data: DropdownFilterOptionInterface): Promise<void> {
        this.deviceLogsService.setDropDownFilter(data);
        await this.nextLogs();
    }

    async setAcknowledgeEvent(row: { row: TableDeviceLogsInterface; event: any; index: number }): Promise<void> {
        if (this.store.selectSnapshot(AuthState.getIsAdmin)) {
            return;
        }
        const check = await this.checkPermission(row.row.registratorId);
        if (!check) {
            this.notificationService.onEmit(TooltipStatusEnum.error, false, 'devices.popup.notPermission');
            return;
        }
        // await this.deviceLogsService.acknowledgeDeviceLog(row.row.logId);
        await this.store.dispatch(new SetAcknowledgeDeviceLog([row.row.id])).toPromise();
        await this.nextLogs();
    }

    async openDetailsDevice(event: { row: TableDeviceLogsInterface; cell: ColumnsTableInterface }): Promise<void> {
        const currentRegistrator = this.store
            .selectSnapshot(DevicesState.getRegistrators)
            .find((device) => device.id === event.row.registratorId);
        if (!currentRegistrator) {
            return;
        }
        await this.router.navigate(
            [
                `${
                    this.store.selectSnapshot(AuthState.getIsAdmin) && this.router.url.includes('control') ? 'control' : ''
                }/details/details-device`,
            ],
            { queryParams: { id: currentRegistrator.id } },
        );
    }

    async nextLogs(): Promise<void> {
        clearTimeout(this.intervalLogs);
        await this.store.dispatch(new GetDeviceLogs()).toPromise();

        this.intervalLogs = setTimeout(async () => {
            await this.nextLogs();
        }, 60000);
    }

    timeFilterChanged(data): void {}

    openEdit(): void {
        this.showPopup = true;
    }

    dataChanged(event: ColumnsTableInterface): void {
        const index = this.deviceLogColumns.findIndex((e) => e === event.name);

        if (index === -1) {
            this.deviceLogColumns.push(event.name);
            this.translateService.get('table.deviceLogs.addColumn').subscribe((message) => {
                this.notificationService.onEmit(TooltipStatusEnum.update, false, message);
            });
        } else {
            this.deviceLogColumns = this.deviceLogColumns.filter((f) => f !== event.name);
            this.translateService.get('table.deviceLogs.removeColumn').subscribe((message) => {
                this.notificationService.onEmit(TooltipStatusEnum.update, false, message);
            });
        }

        this.initTableColumns();
        localStorage.setItem(`deviceLogColumns${this.user.id}`, JSON.stringify(this.deviceLogColumns));
    }

    initTableColumns(): void {
        this.deviceTablesColumns = [
            ...this.deviceLogsService.deviceTableColumnsDefault,
            ...this.deviceLogsService.deviceLogTableColumnsOptional.filter((f) => this.deviceLogColumns.includes(f.name)),
        ];
    }

    async checkPermission(registratorId: string): Promise<boolean> {
        if (!this.store.selectSnapshot(DevicesState.getIsLoadDevices)) {
            await this.store.dispatch(GetDevices).toPromise();
        }
        const roles = this.store.selectSnapshot(PermissionsState.getRoles);
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getRegistrators).find((r) => r.id === registratorId);

        if (!currentRegistrator.roleId) {
            return true;
        }
        const currentRole = roles.find((role) => role.id === currentRegistrator.roleId);

        const data = {
            registrator: currentRegistrator,
            action: ResourceAction.DEVICES_LOGS,
            permissions: [MethodPermission.READ, MethodPermission.UPDATE],
        };

        return this.permissionService.checkPermission(currentRole.permissions, data);
    }

    parseDeviceOptions(): void {
        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        if (!devices || !devices.length) {
            return;
        }

        this.registratorsOptions = devices
            .filter((r) => r.creationType === CreationType.ORIGIN)
            .map((r) => {
                return {
                    key: r.id,
                    value: `${r.name || r.defaultName}`,
                    property: r,
                    postIconPath: `./assets/design/icons/custom-select/${r.type}.svg`,
                };
            })
            .sort((a, b) => (a.value > b.value ? 1 : -1));
    }

    changeFilter(event: ParamsFilterForClient[]): void {
        if (!event) {
            return;
        }

        const params: Params = this.store.selectSnapshot(DeviceLogsState.getParams);
        this.store.dispatch(
            new SetDeviceLogsFilter({
                ...params,
                filter: event,
                pagination: { ...params.pagination, currentPage: 1 },
            }),
        );
    }

    private setTableOperations(): void {
        const operations: TableOperationInterface[] = [
            {
                svgPath: './assets/design/icons/table-operations/ackn.svg',
                type: TableOperationTypeEnum.acknowledge,
            },
        ];
        this.store.dispatch(new InitTableGroupOperations(operations));
    }

    setGroupOperations(event: GroupOperationActionInterface): void {
        this.store.dispatch(new SetRowsForGroupOperation(event));
    }
}

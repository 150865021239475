export interface TableOperationInterface {
    svgPath: string;
    type: TableOperationTypeEnum;
}

export enum TableOperationTypeEnum {
    acknowledge = 'acknowledge',
    export = 'export',
    regeneration = 'regeneration',
}

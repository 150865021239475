import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { HTTP_STATUS } from '../../_enums/status.enum';
import { TooltipStatusEnum } from '../../_enums/tooltip-status.enum';
import { InviteInterface } from '../../_interfaces/InviteInterfaces';
import { NotificationsService } from '../../_services/notifications.service';
import { ApplyInvite, CancelInvite, GetInvites } from '../actions/invite.actions';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../../_interfaces/ApiRequest';
import { GetChatNotifications } from '../../../notifications/_store/actions/chat.actions';
import { TranslateService } from '@ngx-translate/core';

export interface InvitesStateModel {
    invites: InviteInterface[];
}

const INVITE_STATE_TOKEN = new StateToken<InvitesStateModel>('inviteState');

@State<InvitesStateModel>({
    name: INVITE_STATE_TOKEN,
    defaults: {
        invites: [],
    },
})
@Injectable()
export class InvitesState {
    constructor(private notificationsService: NotificationsService, private http: HttpClient, private translateService: TranslateService) {}

    @Selector()
    static getInvites(state: InvitesStateModel): InviteInterface[] {
        return state.invites;
    }

    @Action(GetInvites)
    async getInvites(ctx: StateContext<InvitesStateModel>): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .get('api/share-registrators/invite-access')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                invites: result.data.map((invite) => {
                    invite.created = new Date(invite.created).getTime();
                    return invite;
                }),
            });
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(ApplyInvite)
    async applyInvite(ctx: StateContext<InvitesStateModel>, payload: ApplyInvite): Promise<void> {
        const inviteId = payload.invite.id;
        const answer = true;
        const result: ApiResponse = (await this.http
            .post('api/share-registrators/invite-access', { inviteId, answer })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            await ctx.dispatch(new GetChatNotifications()).toPromise();
            const message = await this.translateService
                .get('tooltipMessages.successAddDevice', { device: payload.invite.name ?? payload.invite.defaultName })
                .toPromise();

            ctx.setState({
                ...state,
                invites: state.invites.filter((invite) => invite.id !== payload.invite.id),
            });
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, message);
        }
    }

    @Action(CancelInvite)
    async cancelInvite(ctx: StateContext<InvitesStateModel>, payload: CancelInvite): Promise<void> {
        const state = ctx.getState();
        const inviteId = payload.invite.id;
        const answer = false;
        const result: ApiResponse = (await this.http
            .post('api/share-registrators/invite-access', { inviteId, answer })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            await ctx.dispatch(new GetChatNotifications()).toPromise();
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                invites: state.invites.filter((invite) => invite.id !== payload.invite.id),
            });
        }
    }
}

<div class="tabs">
    <div class="tabs__wrapper">
        <ul *ngIf="tabs.length" class="tabs__list">
            <ng-container *ngFor="let tab of tabs$ | async; let i = index;">
                <li class="tabs__item"
                    [class.tabs__item--disabled]="tab.isDisabled"
                    [class.tabs__item--active]="tab.active"
                    (click)="setActiveTab(tab.id)">
                    {{tab.title | translate}}
                </li>
            </ng-container>
            <li *ngIf="!isLastPath" class="tabs__item tabs__item--grow"></li>
        </ul>
    </div>
</div>


<app-popup *ngIf="showListenerPopup" [title]="'profile.confirmAction'" (closeEmit)="showListenerPopup = false;">
    <div class="block">
        <div class="block__message" [translate]="'profile.haveChange'"></div>
        <div class="login__form-activity">
            <div class="login__form-activity-item">
                <div class="login__form-activity-column">
                    <button type="button"
                            (click)="moveToTabWithoutSave()"
                            class="block__btn btn btn--light"
                            [translate]="'admin.userProfile.popupBtnMoveToTab'"></button>
                    <button type="button"
                            (click)="showListenerPopup = false"
                            class="block__btn btn"
                            [translate]="'admin.userProfile.popupBtnCancel'"></button>
                </div>
            </div>
        </div>
    </div>
</app-popup>

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import {
    GetDeviceLogs,
    GetDeviceLogsRows,
    SetAcknowledgeDeviceLog,
    SetDeviceLogsFilter,
    SetRowsForGroupOperation,
} from '../actions/device-logs.actions';
import { TableDeviceLogsInterface } from '../../../device-dashboard/user-devices/_interface/table-device-logs.interface';
import { initialFilterDeviceLog } from '../../_data/device-logs-data';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { Pagination, Params, ParamsSorted, ParamsSortedOrderEnum } from '../../../app-shared-elements/_interfaces/params.interface';
import { CloneDeepService } from '../../../app-shared-elements/_services/clone-deep.service';
import { ParamsService } from '../../../app-shared-elements/_services/params.service';
import { TimeFilterState } from 'src/app/app-shared-elements/_store/states/time-filter.state';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { DeviceLogInterface } from '../../_interfaces/device-log.inteface';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import { TranslateService } from '@ngx-translate/core';
import { SetMainOperation, SetSkeleton } from '../../../app-shared-elements/_store/actions/table.actions';
import { Router } from '@angular/router';
import { AuthState } from '../../../auth/_store/states/auth.state';

export interface DeviceLogsModel {
    deviceLogs: { items: DeviceLogInterface[]; total: number };
    logsRows: TableDeviceLogsInterface[];
    // activeDeviceLogs: string[];
    params: Params;
    choosenRowIds: string[];
}

const DEVICE_LOGS_TOKEN = new StateToken<DeviceLogsModel>('deviceLogs');

const initialPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

const initialSort: ParamsSorted[] = [
    {
        order: ParamsSortedOrderEnum.DESC,
        property: 'created',
    },
];

export const initialParams: Params = {
    filter: initialFilterDeviceLog,
    sorted: initialSort,
    pagination: initialPagination,
};

@State<DeviceLogsModel>({
    name: DEVICE_LOGS_TOKEN,
    defaults: {
        deviceLogs: { items: [], total: 0 },
        logsRows: [],
        // activeDeviceLogs: [],
        params: initialParams,
        choosenRowIds: [],
    },
})
@Injectable()
export class DeviceLogsState {
    constructor(
        private http: HttpClient,
        private paramsService: ParamsService,
        private store: Store,
        private cloneDeepService: CloneDeepService,
        private notificationsService: NotificationsService,
        private translateService: TranslateService,
        private router: Router,
    ) {}

    @Selector()
    static getDeviceLogsRows(state: DeviceLogsModel): TableDeviceLogsInterface[] {
        return state.logsRows;
    }

    @Selector()
    static getParams(state: DeviceLogsModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }

    @Selector()
    static getChoosenRowIds(state: DeviceLogsModel): string[] {
        return state.choosenRowIds;
    }

    @Action(GetDeviceLogs)
    async getDeviceLogs(ctx: StateContext<DeviceLogsModel>): Promise<void> {
        const state = ctx.getState();

        const registrators: Device[] = this.store.selectSnapshot(DevicesState.getRegistrators);

        const registratorIds = registrators.map((r) => r.id);
        const datetime = this.store.selectSnapshot(TimeFilterState.getTimeObj);
        const params: Params = {
            ...state.params,
            filter: this.paramsService.parseParamsFilterForServer(state.params.filter, datetime),
            pagination: {
                itemsPerPage: state.params.pagination.itemsPerPage,
                currentPage: state.params.pagination.currentPage,
            },
            sorted: state.params.sorted && state.params.sorted.length ? state.params.sorted : initialSort,
        };

        const headers = new HttpHeaders({
            registratorId: registratorIds ? registratorIds.join(',') : '',
            params: encodeURIComponent(JSON.stringify(params)),
        });

        let result: ApiResponse;

        switch (true) {
            case this.store.selectSnapshot(AuthState.getIsAdmin) && this.router.url.includes('/control/journals/device-log'):
                result = (await this.http
                    .get('api/control/device-log', {
                        headers: {
                            params: encodeURIComponent(JSON.stringify(params)),
                        },
                    })
                    .toPromise()
                    .catch((e) => console.log(e))) as ApiResponse;
                break;
            case this.router.url.includes('/journals/device-log'):
                result = (await this.http
                    .get('api/device-log', { headers })
                    .toPromise()
                    .catch((e) => console.log(e))) as ApiResponse;
                break;
        }

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                deviceLogs: result.data as { items: DeviceLogInterface[]; total: number },
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.total,
                    },
                },
                choosenRowIds: [],
            });

            this.store.dispatch(new SetMainOperation(false));

            ctx.dispatch(new GetDeviceLogsRows());
        }
    }

    @Action(GetDeviceLogsRows)
    async getDeviceLogsRows(ctx: StateContext<DeviceLogsModel>): Promise<any> {
        const state = ctx.getState();
        const keyAction = 'device_logs.action.';
        const keyMessage = 'device_logs.message.';
        const devices = this.store.selectSnapshot(DevicesState.getDevices);

        ctx.setState({
            ...state,
            logsRows: await Promise.all(
                state.deviceLogs.items.map(async (item: DeviceLogInterface) => {
                    let additionalInfo = '';

                    if (item.messageRequest) {
                        additionalInfo = `<p><b>Request</b>: ${item.messageRequest}</p>`;
                    }

                    if (item.messageInfo) {
                        additionalInfo += `<p><b>Info</b>: ${item.messageInfo}</p>`;
                    }

                    if (item.messageForAdmin) {
                        additionalInfo += `<p><b>For admin</b>: ${item.messageForAdmin}</p>`;
                    }

                    const currentDevice = devices.find((d) => d.id === item.deviceId);

                    return {
                        isChoose: {
                            value: !!state.choosenRowIds.find((id) => id === item.id),
                            disabled: !(!item.tsAcknowledget && item.isAknowledgeable),
                        },
                        action: await this.translateService.get(`${keyAction}${item.action}`).toPromise(),
                        deviceName: currentDevice ? currentDevice.name ?? currentDevice.defaultName : item.deviceName,
                        message: await this.translateService.get(`${keyMessage}${item.keyMessage}`).toPromise(),
                        created: item.created,
                        updated: item.updated,
                        tsDate: {
                            ts: item.created,
                            updated: item.updated,
                            count: item.count,
                        },
                        internalId: item.internalId,
                        id: item.id,
                        ackn: {
                            ts: item.tsAcknowledget,
                            isAcknowledgeable: item.isAknowledgeable,
                            acknowledgedByUser: item.acknowledgedByUser,
                            acknowledgedType: item.acknowledgedType,
                            isDisabledBtn: false,
                            title: 'events.logicalEvents.ackn',
                        },
                        requestName: item.requestName,
                        registratorId: item.registratorId,
                        additionalInfo,
                    };
                }),
            ),
        });
        ctx.dispatch(new SetSkeleton(false));
    }

    @Action(SetAcknowledgeDeviceLog)
    async SetAcknowledgeDeviceLog(ctx: StateContext<DeviceLogsModel>, payload: SetAcknowledgeDeviceLog): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            logsRows: state.logsRows.map((row) =>
                payload.logIds.find((id) => id === row.id)
                    ? {
                          ...row,
                          ackn: { ...row.ackn, isDisabledBtn: true },
                      }
                    : { ...row },
            ),
        });

        const result: ApiResponse = (await this.http
            .post(`api/device-log/acknowledge`, { ids: payload.logIds })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (payload.logIds.length > 1) {
            this.store.dispatch(new SetMainOperation(false));
        }

        if (state.logsRows.length === 1) {
            this.store.dispatch(new SetSkeleton(true));
        }

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                deviceLogs: {
                    ...state.deviceLogs,
                    items: state.deviceLogs.items.filter((row) => !payload.logIds.find((id) => id === row.id)),
                },
                logsRows: state.logsRows.filter((row) => !payload.logIds.find((id) => id === row.id)),
                choosenRowIds: payload.logIds.length > 1 ? [] : state.choosenRowIds.filter((id) => id !== payload.logIds[0]),
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        currentPage:
                            state.logsRows.length === 1
                                ? state.params.pagination.currentPage - 1 || 1
                                : state.params.pagination.currentPage,
                    },
                },
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);

            ctx.setState({
                ...state,
            });
        }
    }

    @Action(SetDeviceLogsFilter)
    async setDeviceLogsFilter(ctx: StateContext<DeviceLogsModel>, payload: SetDeviceLogsFilter): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: this.cloneDeepService.cloneObject(payload.params),
        });

        await ctx.dispatch(new GetDeviceLogs()).toPromise();
    }

    @Action(SetRowsForGroupOperation)
    setRowsFroGroupOperation(ctx: StateContext<DeviceLogsModel>, payload: SetRowsForGroupOperation): void {
        const state = ctx.getState();

        if (payload.event.isMain) {
            this.store.dispatch(new SetMainOperation(payload.event.event));

            ctx.setState({
                ...state,
                logsRows: state.logsRows.map((row) => {
                    return {
                        ...row,
                        isChoose: {
                            ...row.isChoose,
                            value: !row.ackn.isAcknowledgeable || row.ackn.ts ? false : payload.event.event,
                        },
                    };
                }),
                choosenRowIds: payload.event.event
                    ? [...state.logsRows.filter((row) => row.ackn.isAcknowledgeable && !row.ackn.ts).map((row) => row.id)]
                    : [],
            });

            return;
        }

        ctx.setState({
            ...state,
            logsRows: state.logsRows.map((row) => {
                if (row.id === payload.event.id) {
                    return {
                        ...row,
                        isChoose: {
                            ...row.isChoose,
                            value: payload.event.event,
                        },
                    };
                }
                return row;
            }),
            choosenRowIds: [
                ...state.choosenRowIds,
                payload.event.event && !state.choosenRowIds.find((id) => id === payload.event.id) ? payload.event.id : null,
            ]
                .filter((id) => id !== null)
                .filter((id) => {
                    if (id !== payload.event.id || payload.event.event) {
                        return id;
                    }
                }),
        });
    }
}

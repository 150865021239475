<div class="checkbox"
    [class.checkbox--disable]="isDisabled"
    [class.checkbox--freeze]="isFreeze"
    [class.checkbox--waiting]="isWaiting"
    [class.checkbox--error]="isError">
    <div class="checkbox__label">
        <input type="checkbox" [ngModel]="value" (change)="change()">
        <svg-icon [src]="CHECKBOX_PATH"></svg-icon>
    </div>
    <div *ngIf="title" class="checkbox__title" [translate]="title" (click)="change()"></div>
</div>

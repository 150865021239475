import { ActiveEventsState } from './states/active-events.state';
import { BreadcrumbsState } from './states/breadcrumbs.state';
import { ConfigurationState } from './states/configuration.state';
import { InvitesState } from './states/invite.state';
import { LanguageState } from './states/language.state';
import { LayoutState } from './states/layout.state';
import { NoteNotificationState } from './states/note-notification.state';
import { PermissionsState } from './states/permissions.state';
import { TableState } from './states/table.state';
import { TabsState } from './states/tabs.state';
import { TimeFilterState } from './states/time-filter.state';
import { UserState } from './states/user.state';
import { ExpiredPasswordState } from './states/expired-password.state';
import { NavState } from './states/nav.state';
import { ErrorAlertState } from './states/error-alert.state';
import { CountryStateCityState } from './states/country-state-city.state';
import { CurrencyState } from './states/currency.state';
import { ArchiveState } from './states/archive.state';
import { NotificationsCountState } from './states/notifications-count.state';

export const sharedState = [
    UserState,
    InvitesState,
    ActiveEventsState,
    TabsState,
    PermissionsState,
    BreadcrumbsState,
    LayoutState,
    TableState,
    LanguageState,
    NoteNotificationState,
    ExpiredPasswordState,
    NavState,
    ErrorAlertState,
    CountryStateCityState,
    CurrencyState,
    ArchiveState,
    NotificationsCountState,
];

export const configurationState = [TimeFilterState, ConfigurationState];

<div class="events">
    <div class="events__wrapper">
        <app-heading *ngIf="!(userId$ | async)" [title]="'heading.eventLog'"></app-heading>
        <app-filters [type]="filterItemTypeEnum.event"
            [journalEditBtn]="true"
            [optionsDropDown]="(dropDownFilterOptions$ | async)"
            [optionsFoDropDownSelect]="currentOptionsForDropdown"
            [currentKeyNameForDropdownSelect]="(dropDownFilterOptions$ | async)[0].key"
            [currentOptionKeyForDropdownSelect]="currentOptionKeyForDropdown"
            [defaultDropdownValue]="defaultDropdownValue"
            [isHighlightRegistrators]="isHighlightRegistrators"
            [filter]="(params$ | async).filter"
            (currentFilterDropDown)="onChangeDropDownFilterDevices($event)"
            (editBtnsEvent)="openTableOptions()"
            (filterEvent)="changeFilter($event)"
            (setFilter)="setFilter($event)"
            (chooseFilterEvent)="chooseFilterEvent($event)"></app-filters>

        <app-tables
            [currentTableColumns]="eventsTableCurrentColumns"
            [rows]="rows$ | async"
            [isPagination]="true"
            [unHidden]="true"
            [configPagination]="(params$ | async).pagination"
            (sortOutput)="onChangeSort($event)"
            (paginationOutput)="onChangePage($event)"
            (openSmth)="clickExpression($event)"
            (eventClickBtnFromColumn)="setAcknowledgeEvent($event)"></app-tables>
    </div>
</div>

<app-popup *ngIf="isTableOptionsPopup" [title]="'table.deviceLogs.addToTable'" (closeEmit)="isTableOptionsPopup = false">
    <div class="popup popup--options">

        <ng-container *ngFor="let item of eventLogsService.eventsOptionsTableColumns">
            <div class="popup__item">
                <span (click)="changeTableOptions(item)" class="popup__item-title" [translate]="item.title"></span>
                <app-checkbox [value]="eventsTableCurrentColumnsName.includes(item.name)"
                    (changeEvent)="changeTableOptions(item)"></app-checkbox>
            </div>
        </ng-container>

    </div>
</app-popup>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Select} from '@ngxs/store';
import {LogicalEventsState} from '../../events/_store/states/logical-events.state';
import {Observable} from 'rxjs';
import {LogicEvent} from '../../events/logical-events/_interface/LogicEvent';

@Component({
    selector: 'app-settings-header',
    templateUrl: './settings-header.component.html',
    styleUrls: ['./settings-header.component.scss']
})
export class SettingsHeaderComponent implements OnInit {

    @Select(LogicalEventsState.getCurrentLogicalEvent) event$: Observable<LogicEvent>;
    @Output() closeEvent = new EventEmitter<void>();

    // private _title = '';

    // @Input()
    // set title(title) {
    //     this._title = title;
    // }

    // get title(): string {
    //     return this._title;
    // }
    @Input() title: string;

    readonly CLOSE_ICON_PATH = './assets/design/icons/close.svg';
    readonly EDIT_CONFOIGURATOR_ICON_PATH = './assets/design/icons/logical-events/edit-configurator.svg';

    constructor() {
    }

    ngOnInit(): void {
    }

    closeEmmit(): void {
        this.closeEvent.emit();
    }
}

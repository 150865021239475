import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';
import { FilterTypePipeEnum } from 'src/app/app-shared-elements/_enums/filter-type-pipe.enum';
import {
    Params,
    ParamsFilter,
    ParamsFilterForClient,
    ParamsFilterTypeEnum,
} from 'src/app/app-shared-elements/_interfaces/params.interface';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { UserRegistratorInterface, UserSensorInterface } from 'src/app/device-dashboard/user-devices/_interface/user-devices.interface';
import { DeviceService } from 'src/app/device-dashboard/_services/device.service';
import { ProfileState } from 'src/app/profile/_store/states/profile.state';
import { TypeClient } from '../../users/_enum/type-client.enum';
import { AdminProfileService } from '../_services/admin-profile.service';
import { ChangeAdminProfileDeviceParams } from '../_store/actions/admin-profile-device-params.action';
import {
    InitAdminProfileDeviceRows,
    InitRegistratorsOptions,
    InitRoleInfo,
    ToggleRoleInfoPopup,
    UpdateAdminProfileDeviceRows,
} from '../_store/actions/admin-profile.action';
import { AdminProfileDeviceParamsState } from '../_store/states/admin-profile-device-params.state';
import { AdminProfileState } from '../_store/states/admin-profile.state';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { SetUserIdFromAdmin } from '../../../app-shared-elements/_store/actions/user.actions';
import { DeviceTypeEnum } from '../../../app-shared-elements/_enums/device-type.enum';
import { SetCurrentRegistrator } from '../../../device-dashboard/_store/actions/user-devices.actions';
import { StateReset } from 'ngxs-reset-plugin';
import { AdminDevicesState } from '../../admin-devices/_store/states/admin-devices.state';
import { CreationType } from '../../../app-shared-elements/_enums/registrator-sync-status.enu';
import { SetSkeleton } from '../../../app-shared-elements/_store/actions/table.actions';
import { UserState } from '../../../app-shared-elements/_store/states/user.state';
import { DataTypeService } from '../../../app-shared-elements/_services/data-type.service';
import { ActionListItemEnum } from '../../../app-shared-elements/_enums/action-list-item.enum';
import { ActionListItemInterface } from '../../../app-shared-elements/_interfaces/action-list-item.interface';
import { TableTypeEnum } from '../../../app-shared-elements/_enums/table-type.enum';
import { ClearCurrentRole } from '../../roles/_store/actions/roles.actions';
import { DetailsDeviceRoleInfoInterface } from '../../../details-device-container/_interface/details-device-role-info.interface';
import { AlignItemsEnum, ColumnsTableInterface, ColumnTypeEnum } from '../../../app-shared-elements/_interfaces/ColumnsTable';

@Component({
    selector: 'app-admin-profile-devices',
    templateUrl: './admin-profile-devices.component.html',
    styleUrls: ['./admin-profile-devices.component.scss'],
})
export class AdminProfileDevicesComponent implements OnInit, OnDestroy {
    @Select(AdminProfileState.getDevicesArray) devicesArray$: Observable<UserRegistratorInterface[]>;
    @Select(ProfileState.getProfileUser) profileUser$: Observable<User>;
    @Select(AdminProfileState.getRegistratorsOptions) registratorsOptions$: Observable<SelectOptionInterface[]>;
    @Select(UserState.getUser) user$: Observable<User>;
    @Select(AdminProfileDeviceParamsState.getParams) params$: Observable<Params>;
    @Select(AdminProfileState.getIsOpenRoleInfoPopup) isOpenRoleInfoPopup$: Observable<boolean>;
    @Select(AdminProfileState.getRoleInfo) roleInfo$: Observable<DetailsDeviceRoleInfoInterface[]>;

    filterType: FilterTypePipeEnum;
    searchFilter: any;

    typeClient = TypeClient;

    tableTypeEnum = TableTypeEnum;

    tableColumn: ColumnsTableInterface[] = [
        {
            title: 'detailsDevice.tableColumn.role',
            maxWidth: '220px',
            type: ColumnTypeEnum.text,
            name: 'role',
            alignItems: AlignItemsEnum.top,
        },
        {
            title: 'detailsDevice.tableColumn.description',
            grow: true,
            type: ColumnTypeEnum.text,
            name: 'description',
            allowHtmlTags: true,
        },
    ];

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        public adminProfileService: AdminProfileService,
        private deviceService: DeviceService,
        private router: Router,
        public dataTypeService: DataTypeService,
    ) {
        this.route.queryParamMap.pipe(takeUntil(this.destroy)).subscribe((params: ParamMap) => {
            const id = params.get('id');
            this.profileUser$
                .pipe(
                    filter((user) => !!user && !!user.id),
                    first(),
                )
                .subscribe((currentUser) => {
                    this.store.dispatch(new InitAdminProfileDeviceRows(currentUser.id));
                });
        });
    }

    async ngOnInit(): Promise<void> {
        await this.store.dispatch(new SetSkeleton(true)).toPromise();
        this.devicesArray$
            .pipe(
                filter((array) => !!array && !!array.length),
                first(),
            )
            .subscribe(async (array) => {
                await this.store.dispatch(new InitRegistratorsOptions()).toPromise();
            });

        this.params$.pipe(takeUntil(this.destroy)).subscribe((params) => {
            if (!params || !params.filter) {
                return;
            }

            const checkboxes = params.filter.filter((f) => f.type === ParamsFilterTypeEnum.BOOLEAN && !f.isDropdown);
            this.deviceService.setGroupDataFilter(checkboxes);
            const currentFilter = params.filter.find((f) => f.value !== null && f.isDropdown);
            if (!currentFilter) {
                this.searchFilter = null;
                return;
            }

            let count = 0;
            checkboxes.forEach((c) => {
                if (!c.value) {
                    count++;
                }
            });

            if (count === checkboxes.length && !currentFilter) {
                return;
            }

            this.currentFilterEvent(currentFilter);
        });
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
        this.store.dispatch(new StateReset(AdminDevicesState));
        this.store.dispatch(new SetSkeleton(false));
    }

    changeFilter(f: ParamsFilterForClient[]): void {
        const params = this.store.selectSnapshot(AdminProfileDeviceParamsState.getParams);

        this.store.dispatch(
            new ChangeAdminProfileDeviceParams({
                ...params,
                filter: f,
                pagination: { ...params.pagination, currentPage: 1 },
            }),
        );
        this.store.dispatch(new UpdateAdminProfileDeviceRows());
    }

    private currentFilterEvent(currentFilter: ParamsFilter): void {
        switch (currentFilter.property) {
            case 'registratorId':
                if (currentFilter.value !== null) {
                    this.filterType = FilterTypePipeEnum.filterDeviceByRegistratorId;
                    this.searchFilter = currentFilter.value;
                }
                break;
            case 'owner':
                if (currentFilter.value && (currentFilter.value as string).length) {
                    this.filterType = FilterTypePipeEnum.filterDeviceByOwner;
                    this.searchFilter = currentFilter.value;
                }

                break;
            case 'isConnect':
                if (currentFilter.value !== null) {
                    this.filterType = FilterTypePipeEnum.filterDeviceByConnect;
                    this.searchFilter = currentFilter.value;
                }

                break;
            case 'status':
                if (currentFilter.value !== null) {
                    this.filterType = FilterTypePipeEnum.filterDeviceByStatus;
                    this.searchFilter = currentFilter.value;
                }

                break;
        }
    }

    openDevice(row: UserSensorInterface): void {
        if (row.creationType === CreationType.VIRTUAL) {
            return;
        }
        this.store.dispatch(new SetUserIdFromAdmin(this.store.selectSnapshot(ProfileState.getProfileUser).id));
        this.store.dispatch(new SetCurrentRegistrator(row.type === DeviceTypeEnum.registrator ? row.id : row.registratorId));
        this.router.navigate([`/control/group-container/chart`, row.id]);
    }

    async parseAction(event: { event: ActionListItemInterface; item: UserRegistratorInterface }): Promise<void> {
        switch (event.event.action) {
            case ActionListItemEnum.access:
                await this.store.dispatch(new InitRoleInfo(event.item.registrator.roleId)).toPromise();
                this.store.dispatch(new ToggleRoleInfoPopup(true));
                break;
        }
    }

    async parseActionDevices(event: { event: ActionListItemInterface; device: UserSensorInterface }): Promise<void> {
        // console.log(event);
        const currentRegistrator = this.store
            .selectSnapshot(AdminProfileState.getDevicesArray)
            .find((r) => r.registrator.id === event.device.registratorId);
        // console.log(currentRegistrator);
        switch (event.event.action) {
            case ActionListItemEnum.access:
                await this.store.dispatch(new InitRoleInfo(currentRegistrator.registrator.roleId)).toPromise();
                this.store.dispatch(new ToggleRoleInfoPopup(true));
                break;
        }
    }

    closeRoleInfoPopup(): void {
        this.store.dispatch(new ClearCurrentRole());
        this.store.dispatch(new ToggleRoleInfoPopup(false));
    }
}

import {MessagesService} from '../../../../events/_services/messages.service';
import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {InputConstructorTypeEnum} from 'src/app/app-shared-elements/_enums/input-constructor-type.enum';
import {EventSaveStatus} from 'src/app/events/_enums/event-save-state.enum';
import {ColumnEditorType, ColumnsTableInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import {PermissionService} from 'src/app/app-shared-elements/_services/permission.service';
import {Store} from '@ngxs/store';
import {DevicesState} from 'src/app/device-dashboard/_store/states/user-devices.state';
import {PermissionsState} from 'src/app/app-shared-elements/_store/states/permissions.state';
import {MethodPermission, ResourceAction} from 'src/app/app-shared-elements/_enums/permission.enum';
import {NotificationsService} from 'src/app/app-shared-elements/_services/notifications.service';
import {SetEditingMessage} from 'src/app/events/_store/actions/messages-catalog.actions';
import {EventMessage} from 'src/app/events/_interfaces/EventMessage';
import {TooltipStatusEnum} from '../../../_enums/tooltip-status.enum';
import {Router} from '@angular/router';
import {InputListInterface} from '../../../_interfaces/input-list.interface';


@Component({
    selector: 'app-columns-editor',
    templateUrl: './columns-editor.component.html',
    styleUrls: ['./columns-editor.component.scss'],
})
export class ColumnsEditorComponent implements OnInit, OnDestroy {

    inputList: InputListInterface[] = [
        {
            title: 'events.messagesCatalog.tagName',
            type: InputConstructorTypeEnum.tagIndex,
            disableLength: 235,
            isWithoutInput: false
        },
        {
            title: 'events.messagesCatalog.tagValue',
            type: InputConstructorTypeEnum.tagValue,
            disableLength: 235,
            isWithoutInput: false
        },
        {
            title: 'events.messagesCatalog.registrator',
            type: InputConstructorTypeEnum.registrator,
            disableLength: 232,
            isWithoutInput: true
        },
        {
            title: 'events.messagesCatalog.constant',
            type: InputConstructorTypeEnum.constantValue,
            disableLength: 230,
            isWithoutInput: false
        },
    ];

    private _row: any;
    private _cell: ColumnsTableInterface;
    private _value: string;

    @ViewChild('textarea') textarea: ElementRef;

    @Input() isFullWidth: boolean;

    @Input()
    set row(row) {
        this._row = row;
    }

    get row(): any {
        return this._row;
    }

    @Input()
    set cell(cell: ColumnsTableInterface) {
        this._cell = cell;
    }

    get cell(): ColumnsTableInterface {
        return this._cell;
    }

    @Input() // value: any;
    set value(value: string) {
        this._value = value;
        this.savedMessage = value;
    }

    get value(): string {
        return this._value;
    }

    @Output() deleteSmthEvent = new EventEmitter<any>();
    @Output() saveSmthEvent = new EventEmitter<any>();
    @Output() editProcessSmthEvent = new EventEmitter<any>();

    message: any;
    savedMessage: any;

    isEdit = false;
    isEdited = false;
    isEditable = true;

    editEventSubscription: Subscription;

    inputConstructorTypeEnum = InputConstructorTypeEnum;

    eventSaveStatus = EventSaveStatus;

    currentCursorPosition: number;

    columnEditorType = ColumnEditorType;
    readonly EDITOR_ICON_PATH = './assets/design/icons/table-operations/editor-cell.svg';


    constructor(
        private messagesService: MessagesService,
        private permissionService: PermissionService,
        private notificationsService: NotificationsService,
        private router: Router,
        private store: Store) {
    }

    ngOnInit(): void {
        this.message = this.value;
        this.savedMessage = this.value;

        if (this.cell && (this.cell.title === 'events.messagesCatalog.table.text' || this.cell.title === 'text'
            || this.cell.title === 'systemSettings.value' || this.cell.title === 'adminFinances.services.price')) {
            this.editEventSubscription = this.messagesService.editMessageEvent.subscribe(() => {
                this.isEditable = !this.isEditable;
                if (this.isEdit) {
                    this.isEdit = false;
                }
            });
        }

        if (this.cell && this.cell.title === 'events.messagesCatalog.table.text' && this.value === '') {
            this.isEdit = true;
        }

        if (this.isEdit) {
            this.editProcessSmthEvent.emit();
        }
    }

    ngOnDestroy(): void {
        this.editEventSubscription.unsubscribe();
    }

    setEdit(): void {
        if (!this.isEditable) {
            this.isEdit = false;
            return;
        }

        if (!this.checkPermissions() && this.router.url.includes('logical-events/event-message-catalog')) {
            this.isEdit = false;
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'events.messagesCatalog.permissionTooltipMessage');
            return;
        }

        this.isEdit = true;

        this.store.dispatch(new SetEditingMessage(this.row as EventMessage));
        this.editEventSubscription.unsubscribe();
        this.editProcessSmthEvent.emit();
        this.messagesService.setEditMessageEvent();


    }

    delete(): void {
        this.deleteSmthEvent.emit();
        this.messagesService.setEditMessageEvent();
        this.editProcessSmthEvent.emit();

    }

    save(): void {
        const newValue = this.message;
        this.saveSmthEvent.emit(newValue);
        this.isEdit = false;
        this.editProcessSmthEvent.emit();
        this.messagesService.setEditMessageEvent();

        this.store.dispatch(new SetEditingMessage(null));
    }

    closeEdit(): void {
        this.message = this.savedMessage;
        this.isEdit = false;
        this.messagesService.setEditMessageEvent();
        this.editProcessSmthEvent.emit();

        this.editEventSubscription = this.messagesService.editMessageEvent.subscribe(value => {
            this.isEditable = !this.isEditable;
            if (this.isEdit) {
                this.isEdit = false;
            }
        });

        this.store.dispatch(new SetEditingMessage(null));

    }


    insertData(event: { type: InputConstructorTypeEnum, value: number | string }): void {

        const data = `{{${event.type}${event.value ? `${event.value}` : ''}}}`;
        if (this.currentCursorPosition) {
            const firstStr = this.message.slice(0, this.currentCursorPosition);
            const secondStr = this.message.slice(this.currentCursorPosition);
            this.message = firstStr + ' ' + data + ' ' + secondStr;
        } else {
            this.message = this.message + ' ' + data;
        }
        this.isEdited = true;

    }

    setCursorPosition(): void {
        this.currentCursorPosition = this.textarea.nativeElement.selectionStart;
    }


    checkPermissions(): boolean {
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getDevices).find(device => device.id === this.row.registratorId);

        if (!currentRegistrator) {
            return false;
        }

        if (!currentRegistrator.roleId) {
            return true;
        }

        const roles = this.store.selectSnapshot(PermissionsState.getRoles);
        const currentRole = roles.find(role => role.id === currentRegistrator.roleId);

        const data = {
            registrator: currentRegistrator,
            action: ResourceAction.LOGIC_MESSAGE,
            permissions: [MethodPermission.READ, MethodPermission.UPDATE]
        };

        return this.permissionService.checkPermission(currentRole.permissions, data);
    }
}

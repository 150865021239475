<div class="device-dashboard"
     [class.device-dashboard--empty]="!(userDevicesArray$ | async) || !(userDevicesArray$ | async).length">
    <div class="device-dashboard__wrapper"
         [class.device-dashboard__wrapper--empty]="!(userDevicesArray$ | async) || !(userDevicesArray$ | async).length"
    >
        <app-heading [title]="'breadCrumbs.devices'"></app-heading>

        <app-user-devices *ngIf="(user$ | async) && (user$ | async).id"
                          [deviceTableColumns]="deviceTableColumns$ | async"
                          [userDevicesArray]="userDevicesArray$ | async"
                          [invites]="invites$ | async"
                          [user]="user$ | async"
                          [optionsRegistrators]="registratorOptions$ | async"
                          [filterType]="filterType"
                          [searchFilter]="searchFilter"
                          [typeClient]="typeClient.User"
                          [ownerDevices]="ownerDevices$ | async"
                          [optionsDropDown]="deviceService.dropDownFilterOptions"
                          (changeFilterEvent)="changeFilter($event)"
                          (activeDeviceEvent)="parseActionDevices($event)"
                          (actionEvent)="parseAction($event)"
                          (syncEvent)="openSyncPopup($event)"
                          (moveToSmthEvent)="moveToEvents($event)"
                          (refreshEvent)="refresh()"
                          (activityEvent)="checkChangeActivityDevice($event)"></app-user-devices>
    </div>
</div>


<app-popup *ngIf="showDeleteDevice$ | async"
           [title]="'devices.popup.confirmAction'"
           (closeEmit)="clearDeleteRegistrator(); randomCodeInput = ''">
    <div class="popup__body">

        <ng-container
            *ngIf="(errorListEvent$ | async) && (errorListEvent$ | async).length
            && currentDevice.userId === user.id && currentDevice.creationType === creationType.ORIGIN && !isTrashDeleted; else defaultDelete">
            <div class="popup__message">
                <div [translate]="'devices.popup.deleteMessage'"
                     [translateParams]="{name: currentDevice.name ?? currentDevice.defaultName}"
                     class="popup__message-confirm popup__message-confirm--success"></div>

                <ng-container *ngFor="let event of errorListEvent$ | async">

                    <div class="popup__event-item">

                        <div *ngIf="event.logicEventType !== logicEventType.default"
                             class="popup__event-arrow"
                             [class.popup__event-arrow--attention]="event?.logicEventType === logicEventType.attentionDeadlineMax
                             || event?.logicEventType === logicEventType.attentionDeadlineMin"
                             [class.popup__event-arrow--down]="event?.logicEventType === logicEventType.alarmDeadlineMin
                             || event?.logicEventType === logicEventType.attentionDeadlineMin"
                             [class.popup__event-arrow--unActive]="!event.isActive || event.isActive && !event.limitIsActive"
                        >
                            <svg-icon [src]="EVENT__LIMIT_ICON_PATH"></svg-icon>

                        </div>

                        <ng-container *ngIf="event.logicEventType === logicEventType.default">

                            <div class="popup__event-icon">
                                <svg-icon
                                    [src]="event.isActive ? ACTIVE_DEFAULT_EVENT : UN_ACTIVE_DEFAULT_EVENT"></svg-icon>
                            </div>
                        </ng-container>

                        <span [translate]="'devices.popup.name'"
                              [translateParams]="{name: event.name}"></span>

                        <span (click)="copyEventId(event)">
                            <svg-icon [src]="'./assets/design/icons/table-operations/copy.svg'"></svg-icon>
                        </span>
                        <span [cdkCopyToClipboard]="event.id"
                              [translate]="'devices.popup.id'"
                              [translateParams]="{id: event.id.substr(0, 5)}"></span>
                    </div>
                </ng-container>

                <div (click)="moveToEventsCatalog()" class="popup__link"
                     [translate]="'events.configurator.eventsLink'"></div>
            </div>
        </ng-container>

        <ng-template #defaultDelete>
            <div class="popup__message">

                <ng-container
                    *ngIf="currentDevice.type === deviceTypeEnum.registrator && currentDevice.userId === user.id
                    && currentDevice.creationType === creationType.ORIGIN">
                    <div class="popup__message-coordinator"
                         [translate]="'devices.popup.acknDeleteConfirm'">
                    </div>

                </ng-container>

                <ng-container *ngIf="currentDevice.type === deviceTypeEnum.coordinator">
                    <div class="popup__message-coordinator"
                         [translate]="'deletedDevices.popup.coordinatorMessage'"></div>
                </ng-container>

                <span class="popup__message-confirm"
                      [translate]="currentDevice.creationType === creationType.ORIGIN && currentDevice.userId === user.id
                  ? 'devices.popup.trashConfirm' : 'devices.popup.deleteConfirm'"
                      [translateParams]="{deviceName: currentDevice.name || currentDevice.defaultName}"></span>
                <span class="popup__message-code" appBlockCopyPastCut>{{randomCodeForSync}}</span>
            </div>
            <div class="popup__input">
                <input class="input" type="text"
                       max="20"
                       [placeholder]="'devices.popup.placeholerCode' | translate"
                       [(ngModel)]="randomCodeInput">
            </div>
        </ng-template>
    </div>

    <div class="popup__btns">
        <button class="btn btn--light" (click)="clearDeleteRegistrator(); randomCodeInput = ''"
                [translate]="'btns.cancel'"></button>

        <button *ngIf="(errorListEvent$ | async)?.length && currentDevice.userId === user.id
        && currentDevice.creationType === creationType.ORIGIN && !isTrashDeleted; else defaultBtn"
                class="btn btn--red"
                autofocus
                [accessKey]="'enter'"
                (click)="isTrashDeleted = true"
                [translate]="'btns.confirm'"
        ></button>

        <ng-template #defaultBtn>
            <button
                class="btn btn--red"
                autofocus
                [accessKey]="'enter'"
                [disabled]="!randomCodeInput || randomCodeInput !== randomCodeForSync"
                (click)="deleteDevice(currentDevice)" [translate]="'btns.confirm'"></button>
        </ng-template>

    </div>
</app-popup>


<app-share-device *ngIf="showShareDevice"
                  (closeEvent)="showShareDevice = false"
                  [registrator]="currentDevice"></app-share-device>


<app-popup
    *ngIf="isShowModalActivity"
    (closeEmit)="isShowModalActivity = false"
    [title]="'devices.popup.confirmAction'">
    <div class="popup__body">

        <ng-container>
            <div class="popup__message"
                 [translate]="changedDevice.isActive ? 'devices.popup.message' : 'devices.popup.unActiveMessage'"
                 [translateParams]="{name: currentName}"
            ></div>
        </ng-container>

        <div class="popup__btns">
            <button class="btn btn--light" (click)="isShowModalActivity = false"
                    [translate]="'btns.cancel'"></button>

            <button class="btn btn--red" (click)="changeDeviceAct(changedDevice)"
                    [translate]="changedDevice.isActive ? 'btns.off' : 'btns.on'"></button>

        </div>
    </div>
</app-popup>

<app-popup *ngIf="isShowSyncPopup"
           [title]="'devices.popup.confirmAction'"
           (closeEmit)="isShowSyncPopup = false; randomCodeInput = ''">

    <div class="popup__body">
        <div class="popup__message">
            <span [translate]="'devices.popup.syncMessage'"></span>
            <br>
            <span class="popup__message-confirm" [translate]="'devices.popup.syncConfirm'"></span>
            <span class="popup__message-code" appBlockCopyPastCut>{{randomCodeForSync}}</span>
        </div>
        <div class="popup__input">
            <input class="input" type="text"
                   max="20"
                   [placeholder]="'devices.popup.placeholerCode' | translate"
                   [(ngModel)]="randomCodeInput">
        </div>

        <div class="popup__btns">
            <button class="btn btn--light" (click)="isShowSyncPopup = false; randomCodeInput = ''"
                    [translate]="'btns.cancel'"></button>

            <button class="btn btn--red" (click)="clearDataForSync()"
                    [disabled]="!randomCodeInput || randomCodeInput !== randomCodeForSync"
                    [translate]="'btns.confirm'"></button>
        </div>
    </div>

</app-popup>

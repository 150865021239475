<div *ngIf="(senders$ | async) && (senders$ | async).length" class="malling-invite">


    <div class="malling-invite__wrapper">

        <ng-container *ngFor="let item of senders$ | async">

            <div class="malling-invite__message">

                <span [translate]="'share.invite.customer'" [translateParams]="{created: item.created
            | luxonDate:(user$ | async).dateFormat
            || dataTypeService.dateFormat : (gmt$ | async) || dataTypeService.GMT : (ln$ | async)}"></span>

                <span class="malling-invite__strong">
                {{item.name}}
                </span>

                <span [translate]="'share.invite.mallingShared'"></span>

            </div>

            <div class="malling-invite__btns">
                <button (click)="cancelInvite(item)" class="btn btn--light btn--invite"
                        [translate]="'btns.reject'"></button>
                <button (click)="applyInvite(item)" class="btn btn--invite" [translate]="'btns.accept'"></button>
            </div>
        </ng-container>

    </div>

</div>

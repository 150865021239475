import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {DatePickerConfigInterface} from '../_interfaces/date-picker-config.interface';
import {ParamsTime} from '../_interfaces/params.interface';
import {UserState} from '../_store/states/user.state';

@Injectable({
    providedIn: 'root',
})
export class DatepickerService {

    constructor(
        private store: Store
    ) {}

    getDatePickerConfig(timeObj: ParamsTime, maxRange?: number): DatePickerConfigInterface {
        const dateFormat = 'dd/mm/yy';

        return {
            from: new Date(timeObj.from),
            to: maxRange ? this.getDateTo(timeObj, maxRange) : new Date(timeObj.to),
            maxRange,
            dateFormat,
            hourFormat: this.store.selectSnapshot(UserState.getUser).dateFormat,
            maxDate: maxRange ? this.getMaxDate(new Date(timeObj.from), maxRange) : new Date()
        };
    }


    private getMaxDate(dateFrom: Date, maxRange: number): Date {
        return new Date(dateFrom.setTime(dateFrom.getTime() + maxRange));
    }

    private getDateTo(timeObj: ParamsTime, maxRange: number): Date {
        const to = new Date(timeObj.from);
        to.setTime(to.getTime() + maxRange);
        return to;
    }
}

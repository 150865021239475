import { Params } from '../../../../app-shared-elements/_interfaces/params.interface';
import { Device } from '../../../../app-shared-elements/_interfaces/Device';

export class GetAdminDevices {
    static readonly type = '[AdminDevices] GetAdminDevices';

    constructor() {}
}

export class GetAllDevices {
    static readonly type = '[AdminDevices] GetAllDevices';
}

export class UpdateAdminDevicesRows {
    static readonly type = '[AdminDevices] UpdateAdminDevicesRows';
}

export class SetAdminDevicesFilter {
    static readonly type = '[AdminDevices] SetAdminDevicesFilter';

    constructor(public params: Params) {}
}

export class GetRegistratorsOptions {
    static readonly type = '[AdminDevices] SetRegistratorIds';
}

export class ClearAdminDevicesState {
    static readonly type = '[AdminDevices] ClearAdminDevicesState';
}

export class DeleteAdminDevice {
    static readonly type = '[AdminDevices] DeleteAdminDevice';

    constructor(public device: Device) {}
}

export class ToggleDeletePopup {
    static readonly type = '[AdminDevices] ToggleDeletePopup';

    constructor(public toggle: boolean) {}
}

export class ToggleAccessPopup {
    static readonly type = '[AdminDevices] ToggleAccessPopup';

    constructor(public toggle: boolean) {}
}

export class InitSharedDevices {
    static readonly type = '[AdminDevices] InitSharedDevices';

    constructor(
        public registratorId: string,
        public userId: string,
    ) {}
}

import {LogicEventType} from '../../../../events/logical-events/_interface/LogicEvent';

export class GetLimitMessages {
    static readonly type = '[LimitMessages] GetLimitMessages';
}

export class UpdateLimitMessage {
    static readonly type = '[LimitMessages] UpdateLimitMessage';
    constructor(public data: { message: string, logicEventType: LogicEventType }) {
    }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SetCurrentDevice, SetCurrentRegistrator } from 'src/app/device-dashboard/_store/actions/user-devices.actions';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { SetActiveGroup, SetIsLoadGroup } from 'src/app/groups/_store/actions/groups.actions';
import { Device } from '../../_interfaces/Device';
import { AlarmTypeEnum } from '../../../events/_enums/alarm.enum';
import { VariablesNameEnum } from '../../_enums/variables-name.enum';
import { GPS } from '../../../groups/container-registrators/device-map/_enum/gps.enum';
import { GroupsState } from '../../../groups/_store/states/groups.state';
import { AuthState } from '../../../auth/_store/states/auth.state';
import { SetUserIdFromAdmin } from '../../_store/actions/user.actions';

@Component({
    selector: 'app-devices-tooltip',
    templateUrl: './devices-tooltip.component.html',
    styleUrls: ['./devices-tooltip.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesTooltipComponent implements OnInit {
    @Select(DevicesState.getRegistrators) registrators$: Observable<Device[]>;
    @Select(DevicesState.getCurrentRegistrator) currentRegistrator$: Observable<Device>;

    alarmTypeEnum = AlarmTypeEnum;

    readonly ALARM_PATH = './assets/design/icons/footer/device-alarm.svg';
    readonly ATTENTION_PATH = './assets/design/icons/footer/device-attention.svg';
    readonly OK_PATH = './assets/design/icons/footer/device-ok.svg';

    constructor(private router: Router, private store: Store) {}

    ngOnInit(): void {}

    moveTo(registrator: Device): void {
        if (this.store.selectSnapshot(AuthState.getIsAdmin)) {
            this.moveToAdmin(registrator);
            return;
        }
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);
        const groups = this.store.selectSnapshot(GroupsState.getAllRegistratorsGroups);

        if (!currentRegistrator || currentRegistrator.id !== registrator.id) {
            this.store.dispatch(new SetIsLoadGroup(false));
            this.store.dispatch(new SetCurrentRegistrator(registrator.id));
            this.store.dispatch(new SetCurrentDevice(registrator.id));
        }

        if (this.router.url.includes('details/details-device')) {
            this.router.navigate([], { queryParams: { id: registrator.id } });
            return;
        }

        if (this.router.url.includes('map/device-map')) {
            const currentGroup = groups?.find((g) => g.deviceId === registrator.id);
            +registrator.variables?.find((v) => v.name === VariablesNameEnum.GPSMode)?.currentValue !== GPS.NEVER
                ? this.store.dispatch(new SetActiveGroup(currentGroup.id))
                : this.router.navigate([`/group-container/chart`, registrator.id]);
        }

        if (!this.router.url.includes('group-container')) {
            this.router.navigate([`/group-container/chart`, registrator.id]);
        }
    }

    moveToAdmin(registrator: Device): void {
        const currentRegistrator = this.store.selectSnapshot(DevicesState.getCurrentRegistrator);

        if (!currentRegistrator || currentRegistrator.id !== registrator.id) {
            this.store.dispatch(new SetIsLoadGroup(false));
            this.store.dispatch(new SetUserIdFromAdmin(registrator.userId));
            this.store.dispatch(new SetCurrentRegistrator(registrator.id));
            this.store.dispatch(new SetCurrentDevice(registrator.id));
        }

        if (this.router.url.includes('control/details/details-device')) {
            this.router.navigate([], { queryParams: { id: registrator.id } });
            return;
        }

        if (!this.router.url.includes('control/group-container')) {
            this.router.navigate([`control/group-container/chart`, registrator.id]);
        }
    }

    trackById(index, item: Device): string {
        return item.id;
    }
}

import { Params } from '../../../../app-shared-elements/_interfaces/params.interface';

export class GetFinanceInvoice {
    static readonly type = '[FinanceInvoice] GetFinanceInvoice';
}

export class SetFinanceInvoiceParams {
    static readonly type = '[FinanceInvoice] SetFinanceInvoiceParams';

    constructor(public params: Params) {}
}

<div class="share">
    <div class="share__wrapper">
        <div class="share__header">
            <div class="share__title" *ngIf="!isRoleInfo; else roleTitle">
                <span [translate]="'share.title'"></span>
                <span>{{deviceName}}</span>
            </div>
            <ng-template #roleTitle>
                <div class="share__title-role">
                    <svg-icon (click)="isRoleInfo = false" [src]="ARROW_BACK_ICON_PATH"></svg-icon>
                    <span class="share__title" [translate]="'share.titleRole'"></span>
                </div>
            </ng-template>
            <div class="share__header-icon">

                <div *ngIf="(roles$ | async)?.length && !isRoleInfo && !router.url.includes('mnemonic/dashboard')"
                     class="share__question"
                     (click)="isRoleInfo = true">
                    <svg-icon [src]="QUESTION_ICON_PATH"></svg-icon>

                    <div class="share__tooltip">
                        <app-tooltip [template]="true" [side]="tooltipSideEnum.bottom">
                            <span [translate]="'share.tooltip.roleInfo'"></span>
                        </app-tooltip>
                    </div>
                </div>
                <div (click)="close()" class="share__close">
                    <svg-icon [src]="CLOSE_ICON_PATH"></svg-icon>
                </div>
            </div>
        </div>
        <div class="share__body">
            <ng-container *ngIf="!isRoleInfo; else roleInfo">
                <div class="share__add-title" [translate]="'share.title'"></div>
                <div class="share__add-block">
                    <div class="share__input">
                        <app-custom-input
                            [items]="autocompleteValues$ | async"
                            [placeholder]="'share.email'"
                            [isStartInput]="isEditInput"
                            [value]="searchEmail"
                            (valueEvent)="addUser($event)"></app-custom-input>
                    </div>

                    <!-- <input class="share__input" type="text" [placeholder]="'share.email' | translate"
                           [(ngModel)]="searchEmail"> -->
                    <button (click)="addNewUser()" class="share__btn btn"
                            [disabled]="!newUser.length || isDisabled" [translate]="'btns.add'"></button>
                    <p *ngIf="errorEmail" class="share__error" [translate]="'share.error'"></p>
                    <p *ngIf="duplicateEmail" class="share__error" [translate]="'share.duplicate'"></p>
                </div>
                <div class="share__textarea-wrapper">
                <textarea [disabled]="(newUsersList$ | async).length < 1" class="share__textarea"
                          [placeholder]="'share.writeMessage' | translate" maxlength="256"
                          [(ngModel)]="message"></textarea>
                    <span class="share__textarea-counter">{{message.length}}/256 <span
                        [translate]="'share.symbol'"></span></span>
                </div>
                <div class="share__users">
                    <ul *ngIf="newUsersList$ | async" class="share__list share__list--new">
                        <li *ngFor="let user of newUsersList$ | async" class="share__item share__item--new">
                            <div class="share__item-info">
                                <div class="share__ava-wait">
                                    <svg-icon [src]="USER_ICON_PATH"></svg-icon>
                                </div>
                                <div class="share__item-title">
                                    <!-- <div class="share__item-name">Name name</div> -->
                                    <div class="share__item-email">{{user.email}}</div>
                                </div>
                            </div>
                            <div *ngIf="!isMnemonic"
                                 class="share__item-select">

                                <app-custom-select [options]="roleOptions$ | async"
                                                   [isReset]="false"
                                                   [isRequire]="!user.role"
                                                   [value]="user.roleName === 'delete' ? 'btns.delete' : (user.roleName || 'share.chooseRole')"
                                                   [delete]="'btns.delete'"
                                                   [isOpenBottom]="true"
                                                   (emmitRemove)="removeNewUser(user)"
                                                   (emmitNewValue)="setNewSelectValueNewUser($event, user)"></app-custom-select>
                            </div>
                        </li>
                    </ul>
                    <ul class="share__list share__list--old">
                        <li *ngFor="let user of oldUsersList$ | async" class="share__item"
                            [class.share__item--wait]="user.status === shareStatusEnum.wait">
                            <div class="share__item-info">
                                <div class="share__ava">
                                    <svg-icon *ngIf="user.status !== shareStatusEnum.wait" class="share__ava-profile"
                                              [src]="USER_ICON_PATH"></svg-icon>
                                    <svg-icon *ngIf="user.status === shareStatusEnum.wait" class="share__ava-wait"
                                              [src]="USER_ICON_PATH"></svg-icon>
                                </div>
                                <div class="share__item-title">
                                    <div class="share__item-name">{{user.name}}</div>
                                    <div class="share__item-email">{{user.email}}</div>
                                </div>
                            </div>
                            <div *ngIf="!isMnemonic"
                                 class="share__item-select">
                                <app-custom-select [isReset]="false"
                                                   [value]="user.roleName === 'delete' ? 'btns.delete' : (user.roleName || 'share.choose')"
                                                   [isRequire]="true"
                                                   [options]="roleOptions$ | async"
                                                   [delete]="'btns.delete'"
                                                   [isOpenBottom]="true"
                                                   [currentOption]="user.currentRoleOption"
                                                   [value]="user.currentRoleOption ? user.currentRoleOption.value : 'share.chooseRole'"
                                                   (emmitRemove)="removeOldUser(user)"
                                                   (emmitNewValue)="setNewSelectValueOldUser($event, user)"></app-custom-select>
                            </div>

                        </li>
                    </ul>
                </div>
                <div class="share__footer">
                    <button (click)="close()" class="btn btn--light"
                            [translate]="'btns.close'"></button>
                    <button (click)="saveChanges()" class="share__btn btn btn--red"
                            [appDisabled]="{type: disabledTypeEnum.sharedDeviceDisabled,
                        data: {oldUsersList: oldUsersList, newUsersList: newUsersList, isChanged: isChanged, isMnemonic: isMnemonic}}"
                            [translate]="'btns.save'"></button>
                </div>
            </ng-container>

            <ng-template #roleInfo>

               <app-share-role-info></app-share-role-info>

            </ng-template>
        </div>

    </div>
</div>

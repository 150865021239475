import {BehaviorSubject} from 'rxjs';
import {EventEmitter, Injectable} from '@angular/core';
import {Group} from 'src/app/app-shared-elements/_interfaces/Group';
import {Store} from '@ngxs/store';

@Injectable({
    providedIn: 'root'
})
export class GroupsDataService {


    currentGroup: Group;
    groups$ = new BehaviorSubject<Group[]>(null);
    currentWidthContent$ = new BehaviorSubject<number>(null);
    timeFilter$ = new EventEmitter<void>();
    resetInterval$ = new EventEmitter<void>();

    constructor(private store: Store) {
    }

    setGroups(groups: Group[]): void {
        this.groups$.next(groups);
    }


    setcurrentWidthContent(width: number): void {

        this.currentWidthContent$.next(width);
    }

    setTimeFilter(): void {
        this.timeFilter$.next();
    }

    setResetInterval(): void {
        this.resetInterval$.emit();
    }

}

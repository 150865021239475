import {AdminProfileDevicesComponent} from './admin-profile/admin-profile-devices/admin-profile-devices.component';
import {AdminProfileLogsComponent} from './admin-profile/admin-profile-logs/admin-profile-logs.component';
import {AdminProfileInfoComponent} from './admin-profile/admin-profile-info/admin-profile-info.component';
import {AdminProfileComponent} from './admin-profile/admin-profile.component';
import {AppSharedElementsModule} from '../app-shared-elements/app-shared-elements.module';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ProfileSharedModule} from '../profile/profile-shared.module';
import {AdminRoutingModule} from './admin-routing.module';
import {DeviceDashboardSharedModule} from '../device-dashboard/device-dashboard-shared.module';
import {JournalsSharedModule} from '../journals/journals-shared.module';
import {AdminProfileEventLogComponent} from './admin-profile/admin-profile-event-log/admin-profile-event-log.component';


@NgModule({
    declarations: [
        AdminProfileComponent,
        AdminProfileInfoComponent,
        AdminProfileDevicesComponent,
        AdminProfileLogsComponent,
        AdminProfileEventLogComponent

    ],
    imports: [
        CommonModule,
        FormsModule,
        AppSharedElementsModule,
        TranslateModule,
        ProfileSharedModule,
        AdminRoutingModule,
        DeviceDashboardSharedModule,
        JournalsSharedModule
    ],
    exports: [
        AppSharedElementsModule,
        AdminRoutingModule
    ]
})
export class AdminSharedModule {
}

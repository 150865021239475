import {Injectable} from '@angular/core';
import {Tabs} from 'src/app/app-shared-elements/_interfaces/Tabs';
import {UserProfileInfo} from 'src/app/app-shared-elements/_interfaces/UserInfo';
import {UserProfileinfoTypeEnum} from 'src/app/profile/_enums/user-profile-info-type.enum';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    tabsAdmin: Tabs[] = [
        {
            title: 'profile.tabs.myProfile',
            active: false,
            id: 0,
            path: '/control/profile'
        },
        {
            title: 'profile.tabs.logs',
            active: false,
            id: 1,
            path: '/control/profile/admin-logs'
        },
    ];

    tabsUser: Tabs[] = [
        {
            title: 'profile.tabs.myProfile',
            active: false,
            id: 0,
            path: '/control/users-profile/user'
        },
        {
            title: 'profile.tabs.devices',
            active: false,
            id: 1,
            path: '/control/users-profile/devices'
        },
        {
            title: 'profile.tabs.logs',
            active: false,
            id: 2,
            path: '/control/users-profile/logs'
        },
        {
            title: 'profile.tabs.eventLog',
            active: false,
            id: 3,
            path: '/control/users-profile/event-log'
        },
    ];

    constructor() { }

    getUserProfileInfo(user): UserProfileInfo[] {
        const info: UserProfileInfo[] = [
            {
                title: 'admin.userProfile.name',
                value: user.name,
                isEdit: false,
                isEditable: true,
                type: UserProfileinfoTypeEnum.name,
                elementType: null,
                isValid: true
            },
            {
                title: 'admin.userProfile.act',
                value: user.isActive,
                isEdit: false,
                isEditable: false,
                isIcon: true,
                type: UserProfileinfoTypeEnum.isActivity,
                elementType: null,
                isValid: true
            },
            {
                title: 'admin.userProfile.login',
                value: user.login,
                isEdit: false,
                isEditable: false,
                type: UserProfileinfoTypeEnum.login,
                elementType: null,
                isValid: true
            },
            {
                title: 'admin.userProfile.phone',
                value: user.phone,
                isEdit: false,
                isEditable: true,
                type: UserProfileinfoTypeEnum.phone,
                elementType: null,
                isValid: true
            },
            {
                title: 'admin.userProfile.lastAct',
                value: this.reformatDate(user.updated),
                isEdit: false,
                isEditable: false,
                type: UserProfileinfoTypeEnum.updated,
                elementType: null,
                isValid: true
            },
            {
                title: 'admin.userProfile.registrationDate',
                value: this.reformatDate(user.created),
                isEdit: false,
                isEditable: false,
                type: UserProfileinfoTypeEnum.created,
                elementType: null,
                isValid: true

            }
        ];

        return info;
    }

    getTabsAdmin(): Tabs[] {
        return this.tabsAdmin;
    }

    getTabsUser(): Tabs[] {
        return this.tabsUser;
    }


    reformatDate(data): any {
        // console.log(data);
        if (!isNaN(data)) {
            data = Number(data);
        }
        const date = new Date(data);
        // console.log( (new Date(date)).toLocaleDateString() + ' ' + (new Date(date)).toLocaleTimeString())
        return (new Date(date)).toLocaleDateString() + ' ' + (new Date(date)).toLocaleTimeString();
    }
}

import { Injectable } from '@angular/core';
import { User } from '../../app-shared-elements/_interfaces/user.interface';
import { DisabledTypeEnum } from '../../app-shared-elements/_enums/disabled-type.enum';
import { CompanyStaffRow } from '../_interfaces/company-staff-row.interface';
import { UsersService } from '../../admin/users/_services/users.service';
import { ColumnTypeEnum } from '../../app-shared-elements/_interfaces/ColumnsTable';

@Injectable({
    providedIn: 'root',
})
export class CompanyStaffService {
    constructor(private usersService: UsersService) {}

    public initCompanyStaffTable(users: User[]): CompanyStaffRow[] {
        if (!users?.length) {
            return [];
        }
        const result: CompanyStaffRow[] = [];

        users.forEach((u) => {
            result.push({
                ...u,
                userName: u.name ?? u.login,
                lastActive: u.lastActive,
                activeStatus: this.usersService.getActiveStatusForAdminUsersTable(u),
                disabledActions: this.usersService.getDisabledBtns(u),
                isDisableWebAuth: u.isDisableWebAuth,
                isActive: u.isActive,
                status: u.isOnline ? 'online' : 'offline',
                isReadOnly: u.login?.length > 0,
                phone: u.phone,
                password: u.passwordUpdate ? this.getBtnsForCompany() : '',
                columnType: u.passwordUpdate ? ColumnTypeEnum.button : ColumnTypeEnum.mailingEditor,
                isNew: false,
                styles: {
                    status: this.usersService.getStatusStyle(u),
                    login: this.usersService.getEmailStyle(u),
                },
                require: {
                    login: false,
                    userName: true,
                    password: false,
                },
                preIcons: null,
                postIcons: this.usersService.getPostIcons(u),
                disabledType: DisabledTypeEnum.companyStaff,
            });
        });

        return result;
    }

    getBtnsForCompany(): any {
        return [
            {
                title: 'btns.changePassword',
                action: 'change',
            },
        ];
    }
}

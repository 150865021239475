import {Interface} from 'readline';
import {IconInterface} from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import {LogicEventType} from '../logical-events/_interface/LogicEvent';
import {EventSaveStatus} from '../_enums/event-save-state.enum';

export interface EventMessage {
    id: string;
    message: string;
    // level: LevelMessageEnum;
    ack: boolean;
    savedStatus: EventSaveStatus;
    registratorId: string;
    registratorName?: string;
    created: string;
    userUpdated: string;
    userCreated: string;
    uodated: string;
    logicEventType: LogicEventType;
    readonly errorStatus: number;

    // for table
    preIcons: IconInterface<EventMessageIconActionEnum>[];
}

export enum EventMessageIconActionEnum {
    copyBuffer = 'copyBuffer'
}

import { MailingGroupsEditBlockEnum } from '../../_enums/mailing-groups-edit-block.enum';
import {
    NotificationDeviceCreateDto,
    NotificationDeviceUpdateDto,
    NotificationEventCreateDto,
    NotificationEventUpdateDto,
    NotificationUserGroupCreateDto,
    NotificationUserGroupUpdateDto,
} from '../../_interfaces/mailing-groups-edit.interface';

export class GetMailingGroupEditUsers {
    static readonly type = '[MailingGroupEdit] GetMailingGroupEditingUsers';

    constructor(public groupId: string) {}
}

export class GetMailingGroupEditDevices {
    static readonly type = '[MailingGroupEdit] GetMailingGroupEditDevices';

    constructor(public groupId: string) {}
}

export class GetMailingGroupEditEvents {
    static readonly type = '[MailingGroupEdit] GetMailingGroupEditEvents';

    constructor(public groupId: string) {}
}

export class InitMailingGroupEditingUsersRows {
    static readonly type = '[MailingGroupEdit] InitMailingGroupEditingUsersRows';
}

export class InitMailingGroupEditDevicesRows {
    static readonly type = '[MailingGroupEdit] InitMailingGroupEditingDevicesRows';
}

export class InitMailingGroupEditingEventsRows {
    static readonly type = '[MailingGroupEdit] InitMailingGroupEditingEventsRows';
}

export class MailingGroupEditingChangeUsersTable {
    static readonly type = '[MailingGroupEdit] MailingGroupEditingChangeUsersTable';

    constructor(public data: NotificationUserGroupCreateDto) {}
}

export class MailingGroupEditingChangeDevicesTable {
    static readonly type = '[MailingGroupEdit] MailingGroupEditingChangeDevicesTable';

    constructor(public data: NotificationDeviceCreateDto) {}
}

export class MailingGroupEditingChangeEventsTable {
    static readonly type = '[MailingGroupEdit] MailingGroupEditingChangeMessagesTable';

    constructor(public data: NotificationEventCreateDto) {}
}

export class ChangeActiveUsersInGroupEdit {
    static readonly type = '[MailingGroupEdit] ChangeActiveUsersInGroupedit';

    constructor(public data: NotificationUserGroupUpdateDto) {}
}

export class ChangeCurrentBlockInEditGroup {
    static readonly type = '[MailingGroupEdit] ChangeCurrentBlockInEditGroup';

    constructor(public currentBlock: MailingGroupsEditBlockEnum) {}
}

export class SetIsMailingGroupEdit {
    static readonly type = '[MailingGroupEdit] SetIsMailingGroupEdit';

    constructor(public value: boolean) {}
}

export class InitUsersPopupRows {
    static readonly type = '[MailingGroupEdit] InitUsersPopupRows';
}

export class InitDevicesPopupRows {
    static readonly type = '[MailingGroupEdit] InitDevicesPopupRows';
}

export class InitEventsPopupRows {
    static readonly type = '[MailingGroupEdit] InitEventsPopupRows';
}

export class InitPopupListForAddItem {
    static readonly type = '[MailingGroupEdit] InitPopupListForAddItem';

    constructor(public type: MailingGroupsEditBlockEnum) {}
}

export class ClearPopupList {
    static readonly type = '[MailingGroupEdit] ClearPopupList';
}

export class DeleteUserInGroupEdit {
    static readonly type = '[MailingGroupEdit] DeleteUserInGroupEdit';

    constructor(public id: string) {}
}

export class DeleteDeviceInGroupEdit {
    static readonly type = '[MailingGroupEdit] DeleteDeviceInGroupEdit';

    constructor(public id: string) {}
}

export class DeleteEventsInGroupEdit {
    static readonly type = '[MailingGroupEdit] DeleteEventsInGroupEdit';

    constructor(public id: string) {}
}

export class MailingGroupEditingUpdateDevice {
    static readonly type = '[MailingGroupEdit] MailingGroupEditingUpdateDevice';

    constructor(public data: NotificationDeviceUpdateDto) {}
}

export class MailingGroupEditingUpdateEvent {
    static readonly type = '[MailingGroupEdit] MailingGroupEditingUpdateEvent';

    constructor(public data: NotificationEventUpdateDto) {}
}

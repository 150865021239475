import { Injectable } from '@angular/core';
import { DevicesControlStatusEnum } from '../_enums/devices-control-status.enum';
import { IconInterface } from '../../../app-shared-elements/_interfaces/ColumnsTable';
import { DevicesControlIconActionEnum, DevicesControlInterface } from '../_interfaces/devices-control-row.interface';

@Injectable({
    providedIn: 'root',
})
export class DevicesControlService {
    constructor() {}

    getPostIcons(item: DevicesControlInterface): IconInterface<DevicesControlIconActionEnum>[] {
        if (!item) {
            return;
        }
        return [
            {
                path: './assets/design/icons/table-operations/info.svg',
                action: DevicesControlIconActionEnum.info,
                styles: {
                    fill: 'var(--columnMailingIconColor)',
                    stroke: 'var(--columnMailingIconColor)',
                },
                cellNames: ['serialNumbers'],
            },
        ];
    }

    getStatus(status: any): string {
        switch (status) {
            case DevicesControlStatusEnum.WAITING_PERMANENT_PASSWORD:
                return 'devicesControl.status.permanentPassword';
            case DevicesControlStatusEnum.WAITING_CONFIGURATION:
                return 'devicesControl.status.configuration';
            case DevicesControlStatusEnum.WAITING_FOR_OWNER_CONNECT:
                return 'devicesControl.status.ownerConnect';
            case DevicesControlStatusEnum.CONNECTED:
                return 'devicesControl.status.connected';
            case DevicesControlStatusEnum.REQUIRES_PASSWORD_REGENERATION:
                return 'devicesControl.status.regeneration';
            case DevicesControlStatusEnum.WAITING_FOR_DELETE:
                return 'devicesControl.status.delete';
        }
    }

    getStatusStyle(status: any): { color: string } {
        switch (status) {
            case DevicesControlStatusEnum.WAITING_PERMANENT_PASSWORD:
                return {
                    color: '#F9852C',
                };
            case DevicesControlStatusEnum.WAITING_CONFIGURATION:
                return {
                    color: '#F9852C',
                };
            case DevicesControlStatusEnum.WAITING_FOR_OWNER_CONNECT:
                return {
                    color: '#F9852C',
                };
            case DevicesControlStatusEnum.CONNECTED:
                return {
                    color: '#0FA579',
                };
            case DevicesControlStatusEnum.REQUIRES_PASSWORD_REGENERATION:
                return {
                    color: '#FF6161',
                };
            case DevicesControlStatusEnum.WAITING_FOR_DELETE:
                return {
                    color: '#FF6161',
                };
        }
    }
}

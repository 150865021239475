import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColumnsTableInterface } from '../../../_interfaces/ColumnsTable';
import { ColumnsActionTypeEnum } from '../../../_enums/columns-action-type.enum';
import { Router } from '@angular/router';
import { TableService } from 'src/app/app-shared-elements/_services/table.service';

@Component({
    selector: 'app-columns-action',
    templateUrl: './columns-action.component.html',
    styleUrls: ['./columns-action.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnsActionComponent implements OnInit {
    @Input() row: any;

    @Input() cell: ColumnsTableInterface;

    @Input() value: any;

    @Output() typeEvent: EventEmitter<ColumnsActionTypeEnum> = new EventEmitter<ColumnsActionTypeEnum>();

    readonly ACTION_ICON_PATH = './assets/design/icons/table-operations/action.svg';

    isAction = false;

    columnsActionTypeEnum = ColumnsActionTypeEnum;

    @ViewChild('settings') settings: ElementRef;

    constructor(public router: Router, public tableService: TableService) {}

    ngOnInit(): void {}

    moveTo(data: { title: string; property: ColumnsActionTypeEnum }): void {
        this.typeEvent.emit(data.property);
    }
}

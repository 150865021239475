import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SwitchFaviconService {

    private favicon = document.querySelector('link[rel="icon"]');

    constructor() {
    }

    switchFavicon(action: boolean): void {
        if (action && this.favicon.outerHTML.includes('assets/design/icons/favicon-ind.ico')
            || !action && this.favicon.outerHTML.includes('favicon.ico')) {
            return;
        }
        action
            ? this.favicon.setAttribute('href', 'assets/design/icons/favicon-ind.ico')
            : this.favicon.setAttribute('href', 'favicon.ico');
    }
}

import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ColumnsTableInterface, ColumnTypeEnum } from '../../../app-shared-elements/_interfaces/ColumnsTable';
import { MailingCellFieldTypeEnum } from '../../../mailing/_enums/mailing-cell-field-type.enum';
import { TableNamesEnum } from '../../../app-shared-elements/_enums/table-names.enum';
import { ColumnsActionTypeEnum } from '../../../app-shared-elements/_enums/columns-action-type.enum';
import {
    AddNewUser,
    ChangeUserActive,
    ChangeUserPassword,
    CloseEditingUserRow,
    CreateUser,
    DeleteUser,
    GetUsers,
    ImportUsers,
    InitCompanyStaffRow,
    RecoveryUser,
    SetEditingUserRow,
    SetUsersParams,
    UpdateAddUserArray,
    UpdateUser,
    UpdateUserEmail,
} from '../_actions/company-staff.actions';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { CompanyStaffRow } from '../../_interfaces/company-staff-row.interface';
import { Pagination, Params, ParamsSorted, ParamsSortedOrderEnum } from '../../../app-shared-elements/_interfaces/params.interface';
import { ParamsService } from '../../../app-shared-elements/_services/params.service';
import { CloneDeepService } from '../../../app-shared-elements/_services/clone-deep.service';
import { CompanyStaffService } from '../../_services/company-staff.service';
import { DropdownFilterOptionInterface } from '../../../app-shared-elements/filters/interfaces/filter-option.interface';
import { TypeFilterEnum } from '../../../app-shared-elements/filters/enums/type-filter.enum';
import { companyStaffFiltersCheckbox, companyStaffFiltersDropdown } from '../../_data/company-staff.data';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { AddUserInterface } from '../../../admin/users/_interfaces/new-user.interface';

export interface CompanyStaffStateModel {
    users: any[];
    usersRow: CompanyStaffRow[];
    addUserArray: AddUserInterface;
    isEditingMode: boolean;
    companyStaffTableColumns: ColumnsTableInterface[];
    dropDownFilterOptions: DropdownFilterOptionInterface[];
    params: Params;
}

const COMPANY_STAFF_STATE_TOKEN = new StateToken<CompanyStaffStateModel>('companyStaff');

const initCompanyStaffTableColumns: ColumnsTableInterface[] = [
    {
        title: 'table.users.act',
        grow: false,
        small: true,
        maxWidth: '80px',
        type: ColumnTypeEnum.icon,
        name: 'activeStatus',
    },
    {
        title: 'table.users.connect',
        maxWidth: '90px',
        minWidth: '90px',
        type: ColumnTypeEnum.text,
        name: 'status',
        styles: true,
    },
    {
        title: 'table.users.email',
        grow: true,
        small: false,
        maxWidth: '300px',
        minWidth: '300px',
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.input,
        isInputCheckbox: true,
        checkboxProperty: 'isDisableWebAuth',
        name: 'login',
        preIcons: true,
        postIcons: true,
        styles: true,
        isReadOnly: true,
    },
    {
        title: 'table.users.name',
        grow: true,
        small: false,
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.input,
        name: 'userName',
    },
    {
        title: 'table.users.phone',
        grow: true,
        small: false,
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.input,
        name: 'phone',
    },
    {
        title: 'admin.adminList.table.password',
        grow: true,
        small: false,
        isCustomType: true,
        type: null,
        mailingFieldType: MailingCellFieldTypeEnum.inputPassword,
        name: 'password',
    },
    {
        title: 'table.users.lastActiveDate',
        grow: false,
        small: false,
        maxWidth: '220px',
        minWidth: '220px',
        type: ColumnTypeEnum.date,
        name: 'lastActive',
        sort: true,
        sortField: 'updated',
    },
    {
        title: 'events.logicalEvents.table.actions',
        grow: false,
        small: true,
        maxWidth: '108px',
        minWidth: '108px',
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.btns,
        tableName: TableNamesEnum.usersListTable,
        name: 'edit',
        isFullWrapperBtn: true,
        actionBtns: [ColumnsActionTypeEnum.recovery, ColumnsActionTypeEnum.passwordRecovery, ColumnsActionTypeEnum.actionBtnsEdit, ColumnsActionTypeEnum.actionBtnsDelete],
    },
];

const initialConfigPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

const initialCurrentSort: ParamsSorted[] = [
    {
        property: 'created',
        order: ParamsSortedOrderEnum.DESC,
    },
];

const dropDownFilterOptions: DropdownFilterOptionInterface[] = [
    { key: 'login', value: 'users.filtersDropdown.login', type: TypeFilterEnum.text, property: 'login' },
    { key: 'name', value: 'users.filtersDropdown.name', type: TypeFilterEnum.text, property: 'name' },
    { key: 'created', value: 'users.filtersDropdown.date', type: TypeFilterEnum.datetime, property: 'created' },
];

export const initialParams: Params = {
    pagination: initialConfigPagination,
    filter: [...companyStaffFiltersCheckbox, ...companyStaffFiltersDropdown],
    sorted: initialCurrentSort,
};

@State<CompanyStaffStateModel>({
    name: COMPANY_STAFF_STATE_TOKEN,
    defaults: {
        users: [],
        usersRow: [],
        addUserArray: null,
        isEditingMode: false,
        params: initialParams,
        dropDownFilterOptions,
        companyStaffTableColumns: initCompanyStaffTableColumns,
    },
})
@Injectable()
export class CompanyStaffState {
    constructor(
        private http: HttpClient,
        private store: Store,
        private paramsService: ParamsService,
        private cloneDeepService: CloneDeepService,
        private companyStaffService: CompanyStaffService,
        private notificationsService: NotificationsService,
    ) {}

    @Selector()
    static getCompanyStaffTableColumns(state: CompanyStaffStateModel): ColumnsTableInterface[] {
        return state.companyStaffTableColumns;
    }

    @Selector()
    static getUserRows(state: CompanyStaffStateModel): CompanyStaffRow[] {
        return state.usersRow;
    }

    @Selector()
    static getParams(state: CompanyStaffStateModel): Params {
        return state.params;
    }

    @Selector()
    static getDropDownFilterOptions(state: CompanyStaffStateModel): DropdownFilterOptionInterface[] {
        return state.dropDownFilterOptions;
    }

    @Selector()
    static getIsEditingMode(state: CompanyStaffStateModel): boolean {
        return state.isEditingMode;
    }

    @Selector()
    static getAddUserArray(state: CompanyStaffStateModel): AddUserInterface {
        return state.addUserArray;
    }

    @Action(GetUsers)
    async getUsers(ctx: StateContext<CompanyStaffStateModel>): Promise<void> {
        const state = ctx.getState();
        const params: Params = {
            ...state.params,
            filter: this.paramsService.parseParamsFilterForServer(state.params.filter),
            sorted: state.params.sorted && state.params.sorted.length ? state.params.sorted : initialCurrentSort,
        };

        const result: ApiResponse = (await this.http
            .get('/api/company/users', { headers: { params: encodeURIComponent(JSON.stringify(params)) } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                users: result.data.items,
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.total,
                    },
                },
            });

            ctx.dispatch(new InitCompanyStaffRow());
        }
    }

    @Action(SetUsersParams)
    setUsersParams(ctx: StateContext<CompanyStaffStateModel>, payload: SetUsersParams): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            params: this.cloneDeepService.cloneObject(payload.params),
        });

        ctx.dispatch(new GetUsers()).toPromise();
    }

    @Action(InitCompanyStaffRow)
    initCompanyStaffRow(ctx: StateContext<CompanyStaffStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            usersRow: this.companyStaffService.initCompanyStaffTable(state.users),
            isEditingMode: false,
        });
    }

    @Action(ChangeUserActive)
    async changeUserActive(ctx: StateContext<CompanyStaffStateModel>, payload: ChangeUserActive): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/company/change-user-active', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
            });

            ctx.dispatch(new GetUsers());
        }
    }

    @Action(CreateUser)
    async createUser(ctx: StateContext<CompanyStaffStateModel>, payload: CreateUser): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/company/create-user-manually', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.EMAIL_ALREADY_EXISTS) {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false, 'importExport.user.userExist');
        }

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                users: [result.data, ...state.users],
                usersRow: [
                    {
                        ...result.data,
                        isEditing: false,
                        require: {
                            email: true,
                        },
                    },
                    ...state.usersRow.filter((row) => !row.isNew),
                ],
                isEditingMode: false,
            });
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }

        ctx.dispatch(new GetUsers());
    }

    @Action(UpdateUser)
    async updateUser(ctx: StateContext<CompanyStaffStateModel>, payload: UpdateUser): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/company/update-user-manually', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
            });

            ctx.dispatch(new GetUsers());
        }
    }

    @Action(UpdateUserEmail)
    async updateUserEmail(ctx: StateContext<CompanyStaffStateModel>, payload: UpdateUserEmail): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('/api/company/update-user-login', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(DeleteUser)
    async deleteUser(ctx: StateContext<CompanyStaffStateModel>, payload: DeleteUser): Promise<void> {
        const result: ApiResponse = (await this.http
            .delete(' /api/company/delete-user', {
                params: {
                    id: payload.id,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                users: state.users.filter((u) => u.id !== payload.id),
                usersRow: state.usersRow.filter((u) => u.id !== payload.id),
            });

            ctx.dispatch(new GetUsers());
        }
    }

    @Action(ChangeUserPassword)
    async changeUserPassword(ctx: StateContext<CompanyStaffStateModel>, payload: ChangeUserPassword): Promise<void> {
        const result = (await this.http
            .post(' /api/company/change-user-password', { id: payload.id, password: payload.password })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(RecoveryUser)
    async recoveryUser(ctx: StateContext<CompanyStaffStateModel>, payload: RecoveryUser): Promise<void> {
        const result: ApiResponse = (await this.http
            .post(' /api/company/recovery-user', { id: payload.id })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                users: state.users.map((u) => (u.id === result.data.id ? result.data : u)),
            });

            ctx.dispatch(new GetUsers());
        }
    }

    @Action(AddNewUser)
    addNewUser(ctx: StateContext<CompanyStaffStateModel>): void {
        const state = ctx.getState();
        //
        // const emptyUser: CompanyStaffRow = {
        //     disabledActions: null,
        //     lastActive: null,
        //     userName: '',
        //     preIcons: null,
        //     postIcons: null,
        //     login: '',
        //     isActive: true,
        //     status: 'Na',
        //     id: '',
        //     password: '',
        //     isEditing: true,
        //     columnType: ColumnTypeEnum.mailingEditor,
        //     isNew: true,
        //     isDisableWebAuth: false,
        //     isSendUser: false,
        //     require: {
        //         login: true,
        //         userName: true,
        //         password: true,
        //     },
        //     disabledType: DisabledTypeEnum.companyStaff,
        // };
        //
        // ctx.setState({
        //     ...state,
        //     usersRow: [emptyUser, ...state.usersRow],
        //     isEditingMode: true,
        // });
    }

    @Action(SetEditingUserRow)
    setEditingUserRow(ctx: StateContext<CompanyStaffStateModel>, payload: SetEditingUserRow): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            usersRow: state.usersRow.map((row) => ({ ...row, isEditing: row.id === payload.id })),
            isEditingMode: true,
        });
    }

    @Action(CloseEditingUserRow)
    closeEditingUserRow(ctx: StateContext<CompanyStaffStateModel>, payload: CloseEditingUserRow): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            usersRow: state.usersRow.map((row) => ({
                ...(payload.savedRow && payload.savedRow.id === row.id ? payload.savedRow : row),
                isEditing: false,
            })),
            isEditingMode: false,
        });

        ctx.dispatch(new GetUsers());
    }

    @Action(UpdateAddUserArray)
    updateAddUserArray(ctx: StateContext<CompanyStaffStateModel>, payload: UpdateAddUserArray): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            addUserArray: payload.data,
        });
    }

    @Action(ImportUsers)
    async importUsers(ctx: StateContext<CompanyStaffStateModel>, payload: ImportUsers): Promise<void> {
        const fd = new FormData();
        fd.append('file', payload.file);

        const result: ApiResponse = (await this.http
            .post('/api/company/create-user-csv', fd)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                users: [result.data, ...state.users],
            });
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
        }

        ctx.dispatch(new GetUsers());
    }
}

import { Injectable } from '@angular/core';
import { Group } from '../../app-shared-elements/_interfaces/Group';
import { User } from '../../app-shared-elements/_interfaces/user.interface';
import { Device } from '../../app-shared-elements/_interfaces/Device';
import { DeviceTypeEnum } from '../../app-shared-elements/_enums/device-type.enum';
import { GroupFoldInterface } from '../_interfaces/group-fold.interface';

@Injectable({
    providedIn: 'root',
})
export class GroupsDashboardService {
    constructor() {}

    getPosition(group: Group, user: User, currentDevice: Device): number {
        let pos: { id: string; position: number }[];
        const filter = JSON.parse(localStorage.getItem('checkboxDashboardFilterGroups'));

        if (!filter) {
            return;
        }

        switch (currentDevice.type) {
            case DeviceTypeEnum.registrator:
                pos = JSON.parse(localStorage.getItem(`groupsRegistratorPosition${user.id}`));
                break;
            case DeviceTypeEnum.sensor:
                pos = JSON.parse(localStorage.getItem(`groupsDevicePosition${user.id}`));
                break;
            case DeviceTypeEnum.coordinator:
                pos = JSON.parse(localStorage.getItem(`groupsCoordinatorPosition${user.id}`));
                break;
            case DeviceTypeEnum.datalogger:
                pos = JSON.parse(localStorage.getItem(`groupsDataloggerPosition${user.id}`));
                break;
        }

        if (!pos) {
            return;
        }
        const position = pos.find((p) => group.id === p.id);
        if (!position) {
            return null;
        }
        return position.position;
    }

    getIsFold(group: Group, user: User): boolean {
        const fold: GroupFoldInterface[] = JSON.parse(localStorage.getItem(`groupsDashboardFold${user.id}`));
        if (!fold) {
            return;
        }

        const isFold = fold.find((f) => group.id === f.id);
        if (!isFold) {
            return;
        }
        return isFold.isFold;
    }
}

export enum UserProfileinfoTypeEnum {
    login = 'login',
    name = 'name',
    phone = 'phone',
    // telegram = 'telegram',
    dateFormat = 'dateFormat',
    dateOne = 'dateOne',
    dateTwo = 'dateTwo',
    timeBlocked = 'timeBlocked',
    sessionLife = 'sessionLife',
    updated = 'updated',
    created = 'created',
    isActivity = 'isActivity',
    dateTimeZone = 'dateTimeZone',
    lang = 'lang',
}

export enum UserProfileInfoTypeElementEnum {
    input = 'input',
    select = 'select',
    checkbox = 'checkbox',
    download = 'download',
}

import { NotificationsService } from './../app-shared-elements/_services/notifications.service';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './_services/auth.service';
import { catchError, tap } from 'rxjs/operators';
import { HTTP_STATUS } from '../app-shared-elements/_enums/status.enum';
import { BlockUiService } from '../app-shared-elements/_services/auto-block-ui.service';
import { TooltipStatusEnum } from '../app-shared-elements/_enums/tooltip-status.enum';
import { SocketService } from '../app-shared-elements/_services/socket.service';
import { Store } from '@ngxs/store';
import { AuthState } from './_store/states/auth.state';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private blockService: BlockUiService,
        private notificationsService: NotificationsService,
        private socketService: SocketService,
        private store: Store,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = this.store.selectSnapshot(AuthState.getUserToken);

        if (token) {
            req = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + token).set('socketid', this.socketService.getSocketId()),
            });
        }

        return next.handle(req).pipe(
            tap((evt: HttpResponse<any>) => {
                if (req.method !== 'GET' && evt.body?.status === HTTP_STATUS.SESSION_FREEZE) {
                    this.blockService.blockUi(true);
                }
            }),
            catchError((err) => {
                console.log(err);
                if (err instanceof HttpErrorResponse && err.status === 401) {
                    this.notificationsService.onEmit(TooltipStatusEnum.load, false, 'tooltipMessages.sessionCompleted');
                    this.socketService.logoutSubscribe.emit();
                    this.blockService.removePreloader();
                }

                return throwError(err);
            }),
        );
    }
}

import { Injectable } from '@angular/core';
import { ColumnsTableInterface, ColumnTypeEnum } from '../../../app-shared-elements/_interfaces/ColumnsTable';
import { MailingCellFieldTypeEnum } from '../../../mailing/_enums/mailing-cell-field-type.enum';
import { TableNamesEnum } from '../../../app-shared-elements/_enums/table-names.enum';
import { SelectTypeEnum } from '../../../app-shared-elements/_enums/select-type.enum';
import { RoleInterface } from 'src/app/app-shared-elements/_interfaces/role.interface';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';

@Injectable({
    providedIn: 'root',
})
export class AdminsService {
    adminsListTable: ColumnsTableInterface[] = [
        {
            title: 'admin.adminList.table.act',
            maxWidth: '90px',
            minWidth: '80px',
            type: ColumnTypeEnum.icon,
            name: 'isActive',
        },
        {
            title: 'admin.adminList.table.email',
            grow: true,
            type: ColumnTypeEnum.mailingEditor,
            mailingFieldType: MailingCellFieldTypeEnum.input,
            name: 'email',
            isReadOnly: true,
        },
        {
            title: 'admin.adminList.table.name',
            grow: true,
            type: ColumnTypeEnum.mailingEditor,
            mailingFieldType: MailingCellFieldTypeEnum.input,
            name: 'name',
        },
        {
            title: 'admin.adminList.table.role',
            grow: false,
            small: false,
            maxWidth: '230px',
            minWidth: '200px',
            type: ColumnTypeEnum.select,
            optionsName: 'selectOptions',
            isFullWrapperBtn: true,
            isSelectEditable: true,
            selectType: SelectTypeEnum.report,
            name: 'role',
            currentValueForSelect: 'role',
        },
        {
            title: 'admin.adminList.table.password',
            grow: true,
            type: ColumnTypeEnum.mailingEditor,
            mailingFieldType: MailingCellFieldTypeEnum.inputPassword,
            name: 'password',
        },
        {
            title: 'admin.adminList.table.actions',
            type: ColumnTypeEnum.mailingEditor,
            name: 'edit',
            minWidth: '160px',
            maxWidth: '160px',
            mailingFieldType: MailingCellFieldTypeEnum.btns,
            tableName: TableNamesEnum.adminsListTable,
            actionBtns: [ColumnsActionTypeEnum.actionBtnsEdit, ColumnsActionTypeEnum.view, ColumnsActionTypeEnum.actionBtnsDelete],
        },
    ];

    constructor() {}

    getAdminRolesOptions(roles: RoleInterface[], rootRole: string): SelectOptionInterface<string, string, { cellName: string; data: RoleInterface }>[] {
        return roles
            .filter((r) => r.name !== rootRole)
            .map((r) => {
                return {
                    key: r.id,
                    value: r.name,
                    property: { cellName: 'roleId', data: r },
                };
            });
    }
}

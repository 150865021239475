import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppTemplateComponent } from './app-template.component';
import { UserAuthGuardService } from '../auth/user-auth-guard.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { DesktopGuard } from '../app-shared-elements/_guards/desktop.guard';
import { EmptyProfileGuard } from '../app-shared-elements/_guards/empty-profile.guard';

export const adminRoutes: Routes = [
    {
        path: 'control',
        title: 'SCloud Admin',
        data: { title: 'Main', isAdmin: true },
        loadChildren: () => import('../admin/admin.module').then((m) => m.AdminModule),
    },
];

export const userDesktopRoutes: Routes | any = [
    {
        path: '',
        data: { title: 'Main' },
        component: AppTemplateComponent,
        canActivate: [UserAuthGuardService, DesktopGuard],
        children: [
            {
                path: 'profile',
                data: { title: 'profile' },
                loadChildren: () => import('../profile/profile.module').then((m) => m.ProfileModule),
            },
            {
                path: 'notifications',
                data: { title: 'notifications' },
                canActivate: [UserAuthGuardService],
                loadChildren: () => import('../notifications/notifications.module').then((m) => m.NotificationsModule),
            },
            {
                path: '',
                redirectTo: 'device-dashboard',
                pathMatch: 'full',
            },
            {
                path: 'logical-events',
                data: { title: 'events list' },
                loadChildren: () => import('../events/events.module').then((m) => m.EventsModule),
                canActivate: [UserAuthGuardService, EmptyProfileGuard],
            },
            {
                path: 'device-dashboard',
                data: { title: 'Deice dashboard' },
                loadChildren: () => import('../device-dashboard/device-dashboard.module').then((m) => m.DeviceDashboardModule),
                canActivate: [UserAuthGuardService],
            },
            {
                path: 'group-container',
                data: { title: 'Container' },
                loadChildren: () => import('../groups/groups.module').then((m) => m.GroupsModule),
                canActivate: [UserAuthGuardService, EmptyProfileGuard],
            },
            {
                path: 'reports',
                data: { title: 'reports' },
                loadChildren: () => import('../reports/reports.module').then((m) => m.ReportsModule),
                canActivate: [EmptyProfileGuard],
            },
            {
                path: 'custom-devices',
                data: { title: 'Custom devices' },
                loadChildren: () => import('../custom-devices/custom-devices.module').then((m) => m.CustomDevicesModule),
                canActivate: [UserAuthGuardService, EmptyProfileGuard],
            },
            {
                path: 'mailing',
                data: { title: 'mailing' },
                canActivate: [UserAuthGuardService],
                loadChildren: () => import('../mailing/mailing.module').then((m) => m.MailingModule),
            },
            {
                path: 'transport-container',
                data: { title: 'transport container' },
                canActivate: [UserAuthGuardService, EmptyProfileGuard],
                loadChildren: () => import('../transport-container/transport-container.module').then((m) => m.TransportContainerModule),
            },
            {
                path: 'journals',
                data: { title: 'journals' },
                canActivate: [UserAuthGuardService],
                loadChildren: () => import('../journals/journals.module').then((m) => m.JournalsModule),
            },
            {
                path: 'expeditions',
                data: { title: 'expeditions' },
                canActivate: [UserAuthGuardService, EmptyProfileGuard],
                loadChildren: () => import('../expedition/expedition.module').then((m) => m.ExpeditionModule),
            },
            {
                path: 'expeditions/:id',
                data: { title: 'expeditions' },
                canActivate: [UserAuthGuardService, EmptyProfileGuard],
                loadChildren: () => import('../expedition/expedition.module').then((m) => m.ExpeditionModule),
            },
            {
                path: 'digital-signature',
                data: { title: 'digital-signature' },
                canActivate: [UserAuthGuardService, EmptyProfileGuard],
                loadChildren: () => import('../digital-signature/digital-signature.module').then((m) => m.DigitalSignatureModule),
            },
            {
                path: '404',
                data: { title: 'Page not found' },
                component: NotFoundComponent,
            },
            {
                path: '403',
                data: { title: 'Page not found' },
                component: NotFoundComponent,
            },
            {
                path: 'details',
                data: { title: 'Details' },
                canActivate: [UserAuthGuardService, EmptyProfileGuard],
                loadChildren: () => import('../details-device-container/details-device-container.module').then((m) => m.DetailsDeviceContainerModule),
            },
            {
                path: 'generation-key',
                data: { title: 'Generation' },
                canActivate: [UserAuthGuardService],
                loadChildren: () => import('../generation-key/generation-key.module').then((m) => m.GenerationKeyModule),
            },
            {
                path: 'copy-configuration',
                data: { title: 'Copy configuration' },
                canActivate: [UserAuthGuardService, EmptyProfileGuard],
                loadChildren: () => import('../copy-configuration/copy-configuration.module').then((m) => m.CopyConfigurationModule),
            },
            {
                path: 'stats',
                data: { title: 'Stats' },
                canActivate: [UserAuthGuardService, EmptyProfileGuard],
                loadChildren: () => import('../stats/stats.module').then((m) => m.StatsModule),
            },
            {
                path: 'deleted-devices',
                data: { title: 'deleted-devices' },
                canActivate: [UserAuthGuardService],
                loadChildren: () => import('../deleted-devices/deleted-devices.module').then((m) => m.DeletedDevicesModule),
            },
            {
                path: 'billing',
                data: { title: 'Billing' },
                canActivate: [UserAuthGuardService],
                loadChildren: () => import('../billing/billing.module').then((m) => m.BillingModule),
            },
            {
                path: 'mnemonic',
                data: { title: 'Mnemonic' },
                canActivate: [UserAuthGuardService],
                loadChildren: () => import('../mnemonic-container/mnemonic-container.module').then((m) => m.MnemonicContainerModule),
            },
            {
                path: 'create-devices',
                data: { title: 'Create devices' },
                canActivate: [UserAuthGuardService],
                loadChildren: () => import('../create-devices/create-devices.module').then((m) => m.CreateDevicesModule),
            },
            {
                path: 'company-staff',
                data: { title: 'Company Staff' },
                canActivate: [UserAuthGuardService],
                loadChildren: () => import('../company-staff/company-staff.module').then((m) => m.CompanyStaffModule),
            },
        ],
    },

    // cc
];

export const mobileRoutes: Routes = [
    {
        path: 'm',
        loadChildren: () => import('../mobile/mobile.module').then((m) => m.MobileModule),
    },
];

@NgModule({
    imports: [RouterModule.forChild([...userDesktopRoutes, ...adminRoutes, ...mobileRoutes])],
    exports: [RouterModule],
})
export class AppRoutingModule {}

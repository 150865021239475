import { ReportRepeatEnum, ReportSettingsInterface, VariableItemCheckboxInterface } from '../../_interfaces/ReportSettings';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import {
    CancelReports,
    ChangeActivityReport,
    ChangeCurrentReportSettings,
    ClearStateReports,
    CreateReport,
    DeleteReport,
    DownloadReport,
    GetReportById,
    GetReports,
    GetReportsLogs,
    InitReportsLogsRows,
    InitRowsReports,
    InitVariablesList,
    SetCurrentReport,
    SetMessageInLoadPopup,
    SetReportModeConstructor,
    SetReportPdf,
    SetReportsListParams,
    SetReportsLogsParams,
    TogglePopupLoad,
    UpdateReport,
} from '../actions/reports.actions';
import { ReportsListService } from '../../_services/reports-list.service';
import { FormatPrintEnum } from '../../_enums/format-print.type.enum';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { ReportRowInterface } from '../../_interfaces/ReportRow';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { DeviceTypeEnum } from 'src/app/app-shared-elements/_enums/device-type.enum';
import { ScheduleTypeEnum } from '../../_enums/schedule-type.enum';
import { Router } from '@angular/router';
import { ReportLogDataInterface, ReportLogInterface } from '../../../journals/_interfaces/ReportLog';
import { ReportsLogsService } from '../../../journals/_services/reports-logs.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { CloneDeepService } from '../../../app-shared-elements/_services/clone-deep.service';
import { Pagination, Params, ParamsFilterTypeEnum, ParamsSorted, ParamsSortedOrderEnum } from '../../../app-shared-elements/_interfaces/params.interface';
import { TranslateService } from '@ngx-translate/core';
import { ColumnsTableInterface, ColumnTypeEnum, ColumnValueAlignEnum } from '../../../app-shared-elements/_interfaces/ColumnsTable';
import { PreloaderService } from '../../../app-shared-elements/_services/preloader.service';
import { ReportGenerateStatus } from '../../_enums/report-generate-status.enum';
import { LangEnum } from 'src/app/app-shared-elements/_enums/lang.enum';
import { initialFilterReportsLogs } from 'src/app/journals/_data/repors-logs-data';
import { TimeFilterState } from 'src/app/app-shared-elements/_store/states/time-filter.state';
import { ParamsService } from 'src/app/app-shared-elements/_services/params.service';
import { SetSkeleton } from 'src/app/app-shared-elements/_store/actions/table.actions';
import { RegistratorTaskTypeEnum } from 'src/app/app-shared-elements/_enums/registrator-task-type.enum';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { UserProfileInfoTypeDateEnum } from 'src/app/profile/_enums/user-profile-info-date-time.enum';

export interface ReportsStateModel {
    reports: ReportSettingsInterface[];
    currentReport: ReportSettingsInterface;
    reportsLogs: ReportLogDataInterface[];
    reportsLogsRows: ReportLogInterface[];
    reportsLogsColumn: ColumnsTableInterface[];
    reportsRows: ReportRowInterface[];
    isLoadPopup: boolean;
    loadPopupMessage: string;
    pdf: string;
    paramsForLogs: Params;
    paramsForList: Params;
    variablesList: VariableItemCheckboxInterface[];
    reportModeConstructor: RegistratorTaskTypeEnum;
}

export const emptyReport: ReportSettingsInterface = {
    nameReport: '',
    nameCompany: '',
    logo: '',
    logoName: '',
    logoSize: null,
    deviceId: null,
    isSendTelegram: false,
    isSendEmail: false,
    telegram: '',
    email: '',
    date: {
        dateFrom: '',
        dateTo: '',
        schedule: ScheduleTypeEnum.lastDay,
    },
    format: FormatPrintEnum.A4,
    isShowNameReport: false,
    isShowNameCompany: false,
    isShowChart: false,
    isShowTable: false,
    isShowSummaryTable: false,
    isShowOnlyAlarm: false,
    isSplitTable: false,
    isLimits: false,
    isShowUserLogs: false,
    isUpdateLogo: false,
    repeat: ReportRepeatEnum.none,
    isActive: true,
    id: null,
    language: LangEnum.en,
    timezone: null, // 'Europe/Kyiv GMT+03:00',
    dateFormat: UserProfileInfoTypeDateEnum.ddMMyyyy, // UserProfileInfoTypeDateEnum.ddMMyyyy
    // chartMode: ChartModeEnum.chart
    // reportVariables: [],
    variablesId: [],
    isDefaultReport: false,
};

const REPORTS_STATE_TOKEN = new StateToken<ReportsStateModel>('reports');

const initReportsLogsColumns: ColumnsTableInterface[] = [
    {
        title: 'reports.logs.table.created',
        small: false,
        maxWidth: '180px',
        type: ColumnTypeEnum.date,
        name: 'created',
        sort: 0,
        sortField: 'end',
    },
    {
        title: 'reports.logs.table.name',
        grow: true,
        small: false,
        type: ColumnTypeEnum.text,
        name: 'name',
        align: ColumnValueAlignEnum.left,
        sort: 0,
        sortField: 'reportName',
    },
    {
        title: 'reports.logs.table.device',
        grow: true,
        small: false,
        maxWidth: '240px',
        type: ColumnTypeEnum.text,
        name: 'group',
        align: ColumnValueAlignEnum.left,
    },
    {
        title: 'reports.logs.table.status',
        small: false,
        maxWidth: '180px',
        type: ColumnTypeEnum.text,
        name: 'status',
        isMultilang: true,
        styles: true,
    },
    {
        title: 'reports.logs.table.timeCreation',
        small: true,
        maxWidth: '180px',
        type: ColumnTypeEnum.text,
        name: 'timeCreation',
    },
];

const initialConfigPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

const initialCurrentSort: ParamsSorted[] = [
    {
        property: 'end',
        order: ParamsSortedOrderEnum.DESC,
    },
];

const initialParamsForList: Params = {
    sorted: null,
    pagination: initialConfigPagination,
    filter: [
        // drop down
        {
            property: 'name',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true,
        },
        {
            property: 'owner',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true,
        },
        {
            property: 'device',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true,
        },
    ],
};

const initialParamsForLogs: Params = {
    pagination: initialConfigPagination,
    filter: initialFilterReportsLogs,
    sorted: initialCurrentSort,
};

@State<ReportsStateModel>({
    name: REPORTS_STATE_TOKEN,
    defaults: {
        reports: [],
        currentReport: JSON.parse(JSON.stringify(emptyReport)),
        reportsLogs: [],
        reportsLogsRows: [],
        reportsLogsColumn: initReportsLogsColumns,
        reportsRows: [],
        isLoadPopup: false,
        loadPopupMessage: null,
        pdf: null,
        paramsForLogs: initialParamsForLogs,
        paramsForList: initialParamsForList,
        variablesList: [],
        reportModeConstructor: null,
    },
})
@Injectable()
export class ReportsState {
    constructor(
        private reportsListService: ReportsListService,
        private reportLogsService: ReportsLogsService,
        private notificationsService: NotificationsService,
        private store: Store,
        private http: HttpClient,
        private router: Router,
        private cloneDeepService: CloneDeepService,
        private translateService: TranslateService,
        private preloaderService: PreloaderService,
        private paramsService: ParamsService,
    ) {}

    @Selector()
    static getReportsList(state: ReportsStateModel): ReportSettingsInterface[] {
        return state.reports;
    }

    @Selector()
    static getReport(state: ReportsStateModel): ReportSettingsInterface {
        return state.currentReport; // JSON.parse(JSON.stringify(state.currentReport));
    }

    @Selector()
    static getReportsLogs(state: ReportsStateModel): ReportLogDataInterface[] {
        return state.reportsLogs;
    }

    @Selector()
    static getReportsLogsRows(state: ReportsStateModel): ReportLogInterface[] {
        return state.reportsLogsRows;
    }

    @Selector()
    static getReportsRows(state: ReportsStateModel): ReportRowInterface[] {
        return state.reportsRows;
    }

    @Selector()
    static getReportsLogsColumn(state: ReportsStateModel): ColumnsTableInterface[] {
        return state.reportsLogsColumn;
    }

    @Selector()
    static getIsLoadPopup(state: ReportsStateModel): boolean {
        return state.isLoadPopup;
    }

    @Selector()
    static getLoadPopupMessage(state: ReportsStateModel): string {
        return state.loadPopupMessage;
    }

    @Selector()
    static getReportPdf(state: ReportsStateModel): string {
        return state.pdf;
    }

    @Selector()
    static getParamsForLogs(state: ReportsStateModel): Params {
        return state.paramsForLogs;
    }

    @Selector()
    static getParamsForList(state: ReportsStateModel): Params {
        return state.paramsForList;
    }

    @Selector()
    static getVariablesCheckboxList(state: ReportsStateModel): VariableItemCheckboxInterface[] {
        return state.variablesList;
    }

    @Selector()
    static getReportConstructorMode(state: ReportsStateModel): RegistratorTaskTypeEnum {
        return state.reportModeConstructor;
    }

    @Action(GetReportsLogs)
    async getReportsLogs(ctx: StateContext<ReportsStateModel>): Promise<void> {
        const state = ctx.getState();

        const datetime = this.store.selectSnapshot(TimeFilterState.getTimeObj);
        const params: Params = {
            ...state.paramsForLogs,
            filter: this.paramsService.parseParamsFilterForServer(state.paramsForLogs.filter, datetime),
            pagination: {
                currentPage: state.paramsForLogs.pagination.currentPage,
                itemsPerPage: state.paramsForLogs.pagination.itemsPerPage,
            },
            sorted: state.paramsForLogs.sorted && state.paramsForLogs.sorted.length ? state.paramsForLogs.sorted : initialCurrentSort,
        };

        const result = (await this.http
            .get<ApiResponse>('api/reports-log', {
                headers: {
                    params: encodeURIComponent(JSON.stringify(params)),
                },
                responseType: 'json',
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        ctx.dispatch(new SetSkeleton(false));

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                reportsLogs: result.data.items as ReportLogDataInterface[],
                paramsForLogs: {
                    ...state.paramsForLogs,
                    pagination: {
                        ...state.paramsForLogs.pagination,
                        totalItems: result.data.total,
                    },
                    sorted: params.sorted && params.sorted.length ? params.sorted : initialCurrentSort,
                },
            });
        }

        await ctx.dispatch(new InitReportsLogsRows()).toPromise();
    }

    @Action(InitReportsLogsRows)
    async InitReportsLogsRows(ctx: StateContext<ReportsStateModel>): Promise<void> {
        const state = ctx.getState();
        const reportsLogsRows: ReportLogInterface[] = [];
        const seconds = await this.translateService
            .get('events.logicalEvents.seconds')
            .toPromise()
            .catch((e) => console.log(e));

        state.reportsLogs.map(async (log) => {
            let creation: string | number = 'Generate...';

            if (log.end) {
                creation = `${Math.ceil((+log.end - +log.start) / 1000)} ${seconds}`;
            }
            reportsLogsRows.push({
                name: log.reportName,
                group: log.groupName,
                status: this.reportLogsService.initStatusValue(log),
                created: +log.end,
                timeCreation: creation,
                id: log.id,
                styles: {
                    status: {
                        fontWeight: 600,
                        color: log.status === ReportGenerateStatus.SUCCESS ? '#0ABB87' : '#FF6161',
                    },
                },
            });
        });

        await this.preloaderService.destroyPreloader();

        ctx.setState({
            ...state,
            reportsLogsRows,
        });
    }

    @Action(GetReportById)
    async getReportById(ctx: StateContext<ReportsStateModel>, payload: GetReportById): Promise<void> {
        if (payload.id) {
            const devices: Device[] = this.store.selectSnapshot(DevicesState.getDevices);

            const currentReport = this.store.selectSnapshot(ReportsState.getReportsList).find((report) => report.id === payload.id);

            const currentDevice = devices.find((device) => device.id === currentReport.deviceId);

            const registratorId = currentDevice.type === DeviceTypeEnum.registrator ? currentDevice.id : currentDevice.registratorId;

            const headers = new HttpHeaders({ registratorId: registratorId || '' });

            const result: ApiResponse = (await this.http
                .get<ApiResponse>(`api/reports/${payload.id}`, { headers })
                .toPromise()
                .catch((e) => console.log(e))) as ApiResponse;

            const state = ctx.getState();

            if (result && result.status === HTTP_STATUS.SUCCESS) {
                ctx.setState({
                    ...state,
                    currentReport:
                        {
                            ...result.data,
                            isDefaultReport: currentDevice?.defaultReportId === currentReport?.id,
                        } || emptyReport,
                });
            }
        }
    }

    @Action(SetCurrentReport)
    setCurrentReport(ctx: StateContext<ReportsStateModel>, action: SetCurrentReport): void {
        const state = ctx.getState();
        const devices: Device[] = this.store.selectSnapshot(DevicesState.getDevices);
        let currentReport: ReportSettingsInterface;
        let currentDevice: Device;
        if (action.report) {
            currentReport = this.store.selectSnapshot(ReportsState.getReportsList).find((report) => report.id === action.report.id);

            currentDevice = devices?.find((device) => device.id === currentReport?.deviceId);
        }
        ctx.setState({
            ...state,
            currentReport:
                {
                    ...action.report,
                    isDefaultReport: currentDevice && currentReport && currentReport.id && currentDevice.defaultReportId === currentReport.id,
                } || emptyReport,
        });
    }

    @Action(UpdateReport)
    async updateReport(ctx: StateContext<ReportsStateModel>, payload: UpdateReport): Promise<void> {
        const devices: Device[] = this.store.selectSnapshot(DevicesState.getDevices);
        const currentDevice = devices.find((device) => device.id === payload.report.deviceId);

        const registratorId = currentDevice.type === DeviceTypeEnum.registrator ? currentDevice.id : currentDevice.registratorId;

        const headers = new HttpHeaders({ registratorId: registratorId || '' });

        const parsedReport: ReportSettingsInterface = {
            ...payload.report,
            date: {
                ...payload.report.date,
                schedule: payload.report.isDefaultReport ? ScheduleTypeEnum.customSchedule : payload.report.date.schedule,
            },
            repeat: payload.report.isDefaultReport ? ReportRepeatEnum.once : payload.report.repeat,
        };

        const result: ApiResponse = (await this.http
            .post(`/api/reports/${payload.report.id}`, { ...parsedReport }, { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, 'tooltipMessages.reportSaved');
        }

        const state = ctx.getState();
        ctx.setState({
            ...state,
            ...(result.data || emptyReport),
        });

        // await ctx.dispatch(new GetReports());

        return;
    }

    @Action(CreateReport)
    async createReport(ctx: StateContext<ReportsStateModel>, payload: CreateReport): Promise<void> {
        const state = ctx.getState();
        const devices: Device[] = this.store.selectSnapshot(DevicesState.getDevices);
        const currentDevice = devices.find((device) => device.id === payload.report.deviceId);

        const registratorId = currentDevice.type === DeviceTypeEnum.registrator ? currentDevice.id : currentDevice.registratorId;

        const headers = new HttpHeaders({ registratorId: registratorId || '' });

        const parsedReport: ReportSettingsInterface = {
            ...payload.report,
            date: {
                ...payload.report.date,
                schedule: payload.report.isDefaultReport ? ScheduleTypeEnum.customSchedule : payload.report.date.schedule,
            },
            repeat: payload.report.isDefaultReport ? ReportRepeatEnum.once : payload.report.repeat,
        };

        const result: ApiResponse = (await this.http
            .post('/api/reports', { ...parsedReport }, { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, 'tooltipMessages.reportSaved');

            ctx.setState({
                ...state,
                currentReport: result.data,
            });

            this.router.navigate([`reports/reports-constructor/${result.data.id}`]);
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
            this.router.navigate([`reports`]);
        }
    }

    @Action(GetReports)
    async getReports(ctx: StateContext<ReportsStateModel>): Promise<void> {
        const state = ctx.getState();
        const registrators: Device[] = this.store.selectSnapshot(DevicesState.getRegistrators);

        const registratorIds = registrators.map((r) => r.id);

        const params: Params = {
            ...state.paramsForList,
            filter: this.paramsService.parseParamsFilterForServer(state.paramsForList.filter),
            pagination: {
                currentPage: state.paramsForList.pagination.currentPage,
                itemsPerPage: state.paramsForList.pagination.itemsPerPage,
            },
            sorted: null,
        };

        const headers = new HttpHeaders({
            registratorId: registratorIds ? registratorIds.join(',') : '',
            params: encodeURIComponent(JSON.stringify(params)),
        });

        const result: ApiResponse = (await this.http
            .get<ApiResponse>('api/reports', { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                reports: result.data || [],
                paramsForList: {
                    ...state.paramsForList,
                    pagination: {
                        ...state.paramsForList.pagination,
                        totalItems: result.data.length,
                    },
                },
            });
        }

        // await ctx.dispatch(new InitRowsReports(result.data)).toPromise();
    }

    @Action(InitRowsReports)
    initRowsReports(ctx: StateContext<ReportsStateModel>): void {
        const state = ctx.getState();
        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        ctx.setState({
            ...state,
            reportsRows: state.reports
                .map((report) => {
                    const currentDevice = devices.find((device) => device.id === report.deviceId);
                    const defaultReport = devices.find((device) => device.defaultReportId === report.id);

                    return {
                        isActive: report.isActive,
                        name: report.nameReport,
                        device: currentDevice ? currentDevice.name ?? currentDevice.defaultName : '',
                        deviceId: report.deviceId,
                        period: this.reportsListService.parseReportPeriod(report, defaultReport),
                        date: report.repeat === ReportRepeatEnum.regular ? this.reportsListService.parseNextDateNextPrint(report.date.schedule) : null,
                        id: report.id,
                        owner: report.owner ? report.owner.split('@')[0] + '@' : '',
                        created: new Date(report.created).getTime(),
                        preIcons: this.reportsListService.getPreIcons(report),
                        tooltipValue: {
                            owner: report.owner,
                        },
                        postText: {
                            name: defaultReport ? 'reports.list.table.defaultText' : '',
                        },
                        postTextStyles: {
                            name: {
                                color: 'var(--userDeviceIsActiveColor)',
                                fontStyle: 'italic',
                            },
                        },
                    };
                })
                .sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()),
        });
    }

    @Action(ChangeActivityReport)
    async changeActivityReport(ctx: StateContext<ReportsStateModel>, payload: ChangeActivityReport): Promise<void> {
        const state = ctx.getState();
        const devices: Device[] = this.store.selectSnapshot(DevicesState.getDevices);
        const reports: ReportSettingsInterface[] = this.store.selectSnapshot(ReportsState.getReportsList);
        const currentReport = reports.find((report) => report.id === payload.reportId);

        const currentDevice = devices.find((device) => device.id === currentReport.deviceId);

        const registratorId = currentDevice.type === DeviceTypeEnum.registrator ? currentDevice.id : currentDevice.registratorId;

        const headers = new HttpHeaders({ registratorId: registratorId || '' });

        const result: ApiResponse = (await this.http
            .put('api/reports', { reportId: payload.reportId, isActive: payload.isActive }, { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
                reportsRows: state.reportsRows.map((row) => {
                    if (row.id === payload.reportId) {
                        row.isActive = payload.isActive;
                    }

                    return row;
                }),
                reports: state.reports.map((report) => {
                    if (report.id === payload.reportId) {
                        report.isActive = payload.isActive;
                    }

                    return report;
                }),
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);

            ctx.setState({
                ...state,
            });
        }
    }

    @Action(DeleteReport)
    async deleteReport(ctx: StateContext<ReportsStateModel>, payload: DeleteReport): Promise<void> {
        const state = ctx.getState();
        const devices: Device[] = this.store.selectSnapshot(DevicesState.getDevices);
        const reports: ReportSettingsInterface[] = this.store.selectSnapshot(ReportsState.getReportsList);
        const currentReport = reports.find((report) => report.id === payload.reportId);

        const currentDevice = devices.find((device) => device.id === currentReport.deviceId);

        const registratorId = currentDevice.type === DeviceTypeEnum.registrator ? currentDevice.id : currentDevice.registratorId;

        const headers = new HttpHeaders({ registratorId: registratorId || '' });

        const result: ApiResponse = (await this.http
            .delete(`api/reports/${payload.reportId}`, { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
                reports: state.reports.filter((report) => report.id !== payload.reportId),
                reportsRows: state.reportsRows.filter((row) => row.id !== payload.reportId),
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);

            ctx.setState({
                ...state,
            });
        }
    }

    @Action(TogglePopupLoad)
    togglePopupLoad(ctx: StateContext<ReportsStateModel>, payload: TogglePopupLoad): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isLoadPopup: payload.value,
        });
    }

    @Action(SetMessageInLoadPopup)
    setMessageInLoadPopup(ctx: StateContext<ReportsStateModel>, payload: SetMessageInLoadPopup): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            loadPopupMessage: payload.message,
        });
    }

    @Action(SetReportPdf)
    setReportPdf(ctx: StateContext<ReportsStateModel>, payload: SetReportPdf): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            pdf: payload.base64,
        });
    }

    @Action(ClearStateReports)
    clearState(ctx: StateContext<ReportsStateModel>): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            reports: [],
            currentReport: emptyReport,
            variablesList: [],
            reportsLogs: [],
            reportsLogsRows: [],
            reportsRows: [],
            isLoadPopup: false,
            loadPopupMessage: null,
            pdf: null,
        });
    }

    @Action(SetReportsLogsParams)
    async setReportsLogsParams(ctx: StateContext<ReportsStateModel>, payload: SetReportsLogsParams): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            paramsForLogs: this.cloneDeepService.cloneObject(payload.params),
        });

        await ctx.dispatch(new GetReportsLogs()).toPromise();
    }

    @Action(SetReportsListParams)
    async setReportslistParams(ctx: StateContext<ReportsStateModel>, payload: SetReportsListParams): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            paramsForList: this.cloneDeepService.cloneObject(payload.params),
        });
    }

    @Action(ChangeCurrentReportSettings)
    changeCurrentReportSettings(ctx: StateContext<ReportsStateModel>, payload: ChangeCurrentReportSettings): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentReport: payload.reportSettings,
        });
    }

    @Action(InitVariablesList)
    initVariablesList(ctx: StateContext<ReportsStateModel>, payload: InitVariablesList): void {
        const state = ctx.getState();
        const devicesByRegistrator: Device[] = this.store.selectSnapshot(DevicesState.getDevices).filter((d) => d.id === payload.device.id || d.registratorId === payload.device.id);
        const variables: Variable[] = devicesByRegistrator.map((d) => d.variables).flat();

        ctx.setState({
            ...state,
            variablesList: variables
                .filter((v) => v.archived)
                .map((variable) => {
                    const currentDevice = devicesByRegistrator.find((d) => d.id === variable.deviceId);
                    return {
                        variable,
                        checked: !!state.currentReport.variablesId.find((id) => id === variable.id),
                        device: currentDevice,
                    };
                })
                .sort((a, b) => (a.variable.name > b.variable.name ? 1 : -1)),
        });
    }

    @Action(SetReportModeConstructor)
    setReportModeConstructor(ctx: StateContext<ReportsStateModel>, payload: SetReportModeConstructor): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            reportModeConstructor: payload.mode,
        });
    }

    @Action(CancelReports)
    async cancelReports(ctx: StateContext<ReportsStateModel>): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post(`api/reports/cancel/${state.currentReport.id}`, { reportId: state.currentReport.id })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(DownloadReport)
    downloadReport(ctx: StateContext<ReportsStateModel>): void {
        const state = ctx.getState();

        const linkSource = `data:application/pdf;base64,${state.pdf}`;
        const downloadLink = document.createElement('a');
        const fileName = state.currentReport.nameReport + '.pdf';

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        ctx.dispatch(new SetReportPdf(null));
        ctx.dispatch(new TogglePopupLoad(false));
        ctx.dispatch(new SetMessageInLoadPopup(null));

        ctx.setState({
            ...state,
        });
    }
}

import { Group } from '../../../app-shared-elements/_interfaces/Group';
import { Pagination } from '../../../app-shared-elements/_interfaces/params.interface';
import { ColumnsTableInterface } from '../../../app-shared-elements/_interfaces/ColumnsTable';

export class GetTableGroups {
    static readonly type = '[TableGroups] GetGroupsTable';

    constructor(public groupId: string) {}
}

export class GetTableGroupsRows {
    static readonly type = '[TableGroups] GetTableGroupsRows';
}

export class InitTableColumns {
    static readonly type = '[TableGroups] InitTableColumns';

    constructor(public currentGroup: Group) {}
}

export class SetDisabledListColumn {
    static readonly type = '[TableGroups] SetDisabledListColumn';

    constructor(public columns: { variableId: string; title: string }[]) {}
}

export class SetPagination {
    static readonly type = '[TableGroups] SetPagination';

    constructor(
        public data: Pagination,
        public groupId: string,
    ) {}
}

export class AddColumn {
    static readonly type = '[TableGroups] AddColumn';

    constructor(public column: { variableId: string; title: string }) {}
}

export class RemoveColumn {
    static readonly type = '[TableGroups] RemoveColumn';

    constructor(public column: ColumnsTableInterface) {}
}

export class ExportCsvFile {
    static readonly type = '[TableGroups] ExportCsvFile';
}

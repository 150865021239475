<div *ngIf="reportSettings" id="report-print report-print--a4-land" class="report-print" #content
     [style.width]="pdfWidth"
     [class.report-print--a4]="reportSettings.format === formatPrintEnum.A4"
     [class.report-print--hide]="isHide"
     [class.report-print--detail]="isOnlyDsChart"
     [class.report-print--a4-land]="reportSettings.format === formatPrintEnum.A4Landscape">
    <div class="report-print__head">
        <div *ngIf="reportSettings.isShowNameReport" class="report-print__title">{{reportSettings.nameReport}}</div>
    </div>

    <div class="report-print__desc">
        <div class="report-print__info">
            <div *ngIf="reportSettings.isShowNameCompany" class="report-print__item">
                <div class="report-print__item-title" [translate]="'reports.company'"></div>
                <div class="report-print__item-value">{{reportSettings.nameCompany}}</div>
            </div>

            <div class="report-print__item">
                <div class="report-print__item-title" [translate]="'reports.deviceTitle'"></div>
                <div class="report-print__item-value">
                    <span>{{data.device.name || data.device.defaultName}}</span>
                    <!-- <span class="report-print__item-registrator">({{data.device.name || data.device.defaultName}})</span> -->
                </div>
            </div>

            <div class="report-print__item">
                <div class="report-print__item-title" [translate]="'reports.period'"></div>
                <div class="report-print__item-value">

                    <span>{{+reportSettings.date.dateFrom | luxonDate
                        : reportSettings.dateFormat || dataTypeService.dateFormat
                            : reportSettings.timezone || dataTypeService.GMT
                            : reportSettings.language}}</span> -
                    <span>{{+reportSettings.date.dateTo | luxonDate
                        :reportSettings.dateFormat || dataTypeService.dateFormat
                            : reportSettings.timezone || dataTypeService.GMT
                            : reportSettings.language}}</span>
                </div>
            </div>

            <div *ngIf="reportSettings?.timezone" class="report-print__item">
                <div class="report-print__item-title" [translate]="'reports.timezoneTitle'"></div>
                <div class="report-print__item-value">{{reportSettings?.timezone?.replace('Kiev', 'Kyiv')}}{{gmt}}</div>
            </div>
        </div>
        <div *ngIf="reportSettings.logo" class="report-print__logo">
            <img src="{{reportSettings.logo}}">
        </div>

    </div>

    <div class="report-print__body">
        <div *ngIf="reportSettings.isShowChart" class="report-print__chart"
             [style.padding-right]="isEmptyArchive ? '10px' : 0"
        >

            <div class="report-print__title">
                <span [translate]="'reports.chartTtitle'"></span>
            </div>
            <app-chart *ngIf="!isEmptyArchive; else emptyChart"
                       class="report-print"
                       [chartWidthForReports]="chartWidth"
                       [type]="chartTypeEnum.report"></app-chart>
            <ng-template #emptyChart>
                <div class="report-print__empty" [translate]="'reports.empty.chart'"></div>
            </ng-template>
        </div>


        <div *ngIf="reportSettings.isShowTable" class="report-print__group-table">
            <div class="report-print__title">
                <span [translate]="'reports.tableTitle'"></span>
                <span *ngIf="tableGroup?.isError"> (<span [translate]="'reports.itemsMore'"
                                                          [translateParams]="{from: tableGroup.allItems, to: tableGroup.maxItems}"></span>)</span>

            </div>
            <ng-container *ngIf="reportSettings.isSplitTable; else defaultTable">

                <ng-container *ngFor="let item of uniqIds; let idx = index">
                    <div [style]="{fontSize: '18px', fontWeight: 700, margin: '15px 10px'}">
                        <span [class]="'report-print__name' + idx"></span>
                    </div>
                    <div [class]="'container-table-report' + idx"></div>
                </ng-container>
            </ng-container>

            <ng-template #defaultTable>
                <div [class]="'container-table-report'"></div>
            </ng-template>
            <div *ngIf="tableGroup?.allItems === 0" class="report-print__empty"
                 [translate]="'reports.empty.table'"></div>

        </div>

        <div *ngIf="reportSettings.isShowEventLogs" class="report-print__event-table">
            <div class="report-print__title">
                <span [translate]="'reports.eventTableTitle'"></span>
            </div>

            <table>
                <thead *ngIf="reportService.eventRows && reportService.eventRows.length;">
                <tr>
                    <th *ngFor="let td of reportService.eventsTableColumns">
                        <ng-container>
                            <span>{{td.title | translate}}</span>
                        </ng-container>
                    </th>
                </tr>
                </thead>

                <ng-container *ngIf="reportService.eventRows && reportService.eventRows.length;">
                    <tr *ngFor="let row of reportService.eventRows">
                        <ng-container *ngFor="let td of reportService.eventsTableColumns">
                            <td>
                                <span>
                                    <ng-container *ngIf="!td.isTranslate; else translate">
                                        {{row[td.name]}}
                                    </ng-container>
                                    <ng-template #translate>
                                        {{row[td.name] | translate}}
                                    </ng-template>
                                </span>
                            </td>
                        </ng-container>

                    </tr>
                </ng-container>

            </table>
            <div *ngIf="!reportService.eventRows || !reportService.eventRows.length" class="report-print__empty">
                <span [ngSwitch]="reportSettings.isShowOnlyAlarm">
                    <ng-template [ngSwitchCase]="true">{{'reports.empty.alarmEvents' | translate}}</ng-template>
                    <ng-template [ngSwitchCase]="false">{{'reports.empty.events' | translate}}</ng-template>
                    <ng-template ngSwitchDefault>{{'reports.empty.events' | translate}}</ng-template>
                </span>
            </div>


        </div>

        <div *ngIf="reportSettings.isShowSummaryTable" class="report-print__summary-table">
            <div class="report-print__title">
                <span [translate]="'reports.summaryTable'"></span>
            </div>

            <table>
                <thead *ngIf="reportService.summaryTableRows && reportService.summaryTableRows.length;">
                <tr>
                    <th *ngFor="let td of reportService.summaryTableColumns">
                        <ng-container>
                            <span>{{td.title | translate}}</span>
                        </ng-container>
                    </th>
                </tr>
                </thead>

                <ng-container *ngIf="reportService.summaryTableRows && reportService.summaryTableRows.length;">
                    <tr *ngFor="let row of reportService.summaryTableRows">
                        <ng-container *ngFor="let td of reportService.summaryTableColumns; let i = index">
                            <td [class.right]="i !== 0">
                                <span>
                                    <ng-container *ngIf="!td.isTranslate; else translate">
                                        {{row[td.name]}}
                                    </ng-container>
                                    <ng-template #translate>
                                        {{row[td.name] | translate}}
                                    </ng-template>
                                </span>
                            </td>
                        </ng-container>

                    </tr>
                </ng-container>

            </table>
            <div *ngIf="!reportService.summaryTableRows || !reportService.summaryTableRows.length"
                 class="report-print__empty" [translate]="'reports.empty.summary'"></div>


        </div>

        <div *ngIf="reportSettings.isShowUserLogs" class="report-print__user-table">
            <div class="report-print__title" [translate]="'reports.userTableTitle'">
                <span [translate]="'reports.userTableTitle'"></span>
                <span *ngIf="!reportService.userLogsRows || !reportService.userLogsRows.length"
                      [translate]="'table.noData'"></span>
            </div>

            <table>
                <thead>
                <tr>
                    <th *ngFor="let td of usersTableColumns">
                        <ng-container>
                            {{td.title | translate}}
                        </ng-container>
                    </th>
                </tr>
                </thead>

                <ng-container *ngIf="reportService.userLogsRows">
                    <tr *ngFor="let row of reportService.userLogsRows; let i = index;">
                        <ng-container *ngFor="let td of usersTableColumns; let i = index;">
                            <td>
                                {{getTdValue(row, td)}}
                            </td>
                        </ng-container>

                    </tr>
                </ng-container>

            </table>


        </div>

        <div class="report-print__signature">
            <span class="report-print__signature-item" [translate]="'reports.signature'"></span>
            <span class="report-print__signature-item report-print__signature-item--field"></span>
            <!-- <span class="report-print__signature-item">{{reportSettings.userName}}</span> -->
        </div>

        <div *ngIf="configurationServer$ | async" class="report-print__date">
            <span>{{now | luxonDate
                : reportSettings.dateFormat || dataTypeService.dateFormat
                    : reportSettings.timezone || dataTypeService.GMT
                    : reportSettings.language}}</span>
            <span>{{reportSettings.timezone?.replace('Kiev', 'Kyiv')}}{{gmt}}</span>

        </div>

    </div>
</div>

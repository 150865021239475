import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { TableOperationInterface } from '../../tables/_interfaces/table-operation.interface';
import { TableNamesEnum } from '../../_enums/table-names.enum';
import { TableService } from '../../_services/table.service';
import {
    AddApplyTableEditionConfig,
    InitTableGroupOperations,
    RemoveApplyTableEditionConfig,
    SetIsEditMode,
    SetMainOperation,
    SetOperation,
    SetSkeleton,
} from '../actions/table.actions';

export interface TableStateModel {
    applyEditionConfig: TableNamesEnum[];
    isEditMode: boolean;
    isSkeleton: boolean;
    tableGroupOperations: TableOperationInterface[];
    disableIdsForGroupOperation: string[];
    isMainGroupOperation: boolean;
    // valuesForAutocomplite: CustomInputItem[];
}

const TABLE_STATE = new StateToken<TableStateModel>('tableState');

@State<TableStateModel>({
    name: TABLE_STATE,
    defaults: {
        applyEditionConfig: [],
        isEditMode: false,
        isSkeleton: false,
        tableGroupOperations: [],
        disableIdsForGroupOperation: [],
        isMainGroupOperation: false,
        // valuesForAutocomplite: []
    },
})
@Injectable()
export class TableState {
    constructor(private tableService: TableService) {}

    @Selector()
    static getApplyEditionConfig(state: TableStateModel): TableNamesEnum[] {
        return state.applyEditionConfig;
    }

    @Selector()
    static getIsEditMode(state: TableStateModel): boolean {
        return state.isEditMode;
    }

    @Selector()
    static getSkeleton(state: TableStateModel): boolean {
        return state.isSkeleton;
    }

    @Selector()
    static getTableGroupOperations(state: TableStateModel): TableOperationInterface[] {
        return state.tableGroupOperations;
    }

    @Selector()
    static getTableDisableIdsForGroupOperations(state: TableStateModel): string[] {
        return state.disableIdsForGroupOperation;
    }

    @Selector()
    static getIsMainGroupOperation(state: TableStateModel): boolean {
        return state.isMainGroupOperation;
    }

    // @Selector()
    // static getValuesForAutocomplote(state: TableStateModel): CustomInputItem[] {
    //     return state.valuesForAutocomplite;
    // }

    @Action(AddApplyTableEditionConfig)
    addApplyTableEditionConfig(ctx: StateContext<TableStateModel>, payload: AddApplyTableEditionConfig): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            applyEditionConfig: [...state.applyEditionConfig, payload.tableName],
        });
    }

    @Action(RemoveApplyTableEditionConfig)
    removeTableEditionConfig(ctx: StateContext<TableStateModel>, payload: RemoveApplyTableEditionConfig): void {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            applyEditionConfig: state.applyEditionConfig.filter((item) => item !== payload.tableName),
        });
    }

    @Action(SetIsEditMode)
    setIsEditMode(ctx: StateContext<TableStateModel>, payload: SetIsEditMode): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isEditMode: payload.value,
        });
    }

    @Action(SetSkeleton)
    setSkeleton(ctx: StateContext<TableStateModel>, payload: SetSkeleton): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isSkeleton: payload.value,
        });
    }

    @Action(InitTableGroupOperations)
    setTableGroupOperations(ctx: StateContext<TableStateModel>, payload: InitTableGroupOperations): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            tableGroupOperations: payload.tableGroupOperations,
        });
    }

    @Action(SetOperation)
    setOperation(ctx: StateContext<TableStateModel>, payload: SetOperation): void {
        this.tableService.tableGroupOperationEvent.emit(payload.tableGroupOperation);
    }

    @Action(SetMainOperation)
    setMainOperation(ctx: StateContext<TableStateModel>, payload: SetMainOperation): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isMainGroupOperation: payload.value,
        });
    }

    // @Action(SetDisableIdsForGroupOperation)
    // setDisableIdsForGroupOperation(ctx: StateContext<TableStateModel>, payload: SetDisableIdsForGroupOperation): void {
    //     const state = ctx.getState();

    //     ctx.setState({
    //         ...state,
    //         disableIdsForGroupOperation: payload.ids
    //     });
    // }
}

<div class="column"
     #settings
     [class.column--border]="!isBorder"
>
    <div class="column__wrapper" (click)="actionClick()"
         [class.column__wrapper--message-table]="router.url.includes('event-messages') || router.url.includes('admins')
         || router.url.includes('users') || router.url.includes('services') || router.url.includes('control/mail-message')"
         [class.column__wrapper--pre-icons]="cell?.preIcons"
         [class.column__wrapper--post-icons]="cell?.postIcons"
         [class.column__wrapper--edit]="row.isEditing && !router.url.includes('control/mail-message') && !router.url.includes('devices-control')"
         [class.column__wrapper--checkbox]="router.url.includes('users') && row.isEditing || router.url.includes('company-staff') && row.isEditing"
         [class.column__wrapper--units-table]="cell.title.includes('units')"
         [class.column__wrapper--transport]="cell.mailingFieldType === mailingCellFieldTypeEnum.transport
         || cell.mailingFieldType === mailingCellFieldTypeEnum.textarea">

        <div *ngIf="cell.preIcons && row?.preIcons && row?.preIcons.length" class="column__pre-icons">
            <ng-container *ngFor="let icon of row.preIcons">
                <div *ngIf="icon.cellNames.includes(cell.name)"
                     class="column__pre-icons-item"
                     [class.column__pre-icons-item--margin]="icon.cellNames.includes('type')"
                     [class.column__pre-icons-item--action]="icon.action"
                     (click)="iconClick(icon, row, cell)">
                    <svg-icon [src]="icon.path" [svgStyle]="icon.styles"></svg-icon>

                    <div *ngIf="icon.tooltip && icon.tooltip.length" class="column__pre-icons-tooltip">
                        <app-tooltip [side]="icon?.tooltipSide || tooltipSideEnum.right" [value]="icon.tooltip"
                                     [width]="'120px'"></app-tooltip>
                    </div>
                </div>

            </ng-container>

        </div>

        <ng-container *ngIf="!cell.mailingFieldType">
            <div class="column__value">{{ value | translate }}</div>
        </ng-container>

        <ng-container *ngIf="cell.mailingFieldType === mailingCellFieldTypeEnum.inputSelect">
            <div *ngIf="!row.isEditing; else editingInputSelect" class="column__value column__value--input-select">
                {{ value }}
            </div>
            <ng-template #editingInputSelect>
                <div class="column__edit column__edit--input-select">
                    <app-custom-input
                        [placeholder]="cell.inputPlaceholderValue ?? ''"
                        [items]="row.autocompleteValues"
                        (valueEvent)="row[cell.name] = $event; updateEditingRow()"></app-custom-input>
                </div>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="cell.mailingFieldType === mailingCellFieldTypeEnum.input">

            <div *ngIf="!row.isEditing; else editingInput" class="column__value"
                 [class.column__value--pointer]="cell?.isClick"
                 [class.column__value--underline]="cell?.underline"
                 [ngStyle]="cell?.styles && row.styles ? row.styles[cell.name] : ''"
                 (click)="moveToSmth()"
            >{{ value }}

                <div *ngIf="cell?.tooltip && row?.tooltipValue
                && row?.tooltipValue[cell.name] && row?.tooltipValue[cell.name].length && value && !row.isEditing"
                     class="column__value-tooltip">
                    <div class="column__value-tooltip-value">
                        {{ row.tooltipValue[cell.name] | translate }}
                    </div>
                </div>
            </div>

            <ng-template #editingInput>
                <div class="column__edit"
                     [class.column__edit--checkbox]="cell.isInputCheckbox"
                     [class.column__edit--read-only]="cell?.isReadOnly && row?.isReadOnly"
                >
                    <ng-container *ngIf="cell.isInputConstructor">
                        <div class="column-input__wrapper"
                             [class.column-input__wrapper--wrap]="cell.isInputConstructor"
                        >
                            <ng-container
                                *ngIf="cell.isCustomInputConstructorList && row?.subjectInputList">
                                <ng-container *ngFor="let list of row.subjectInputList">

                                    <div class="column-input__tag-item">
                                        <app-input-constructor
                                            [label]="list.title"
                                            [isWithoutInput]="list.isWithoutInput"
                                            [disabled]="message?.length > list.disableLength"
                                            [type]="list.type"
                                            (newValueEvent)="insertData($event)"
                                        ></app-input-constructor>

                                    </div>

                                </ng-container>
                            </ng-container>
                        </div>
                    </ng-container>

                    <div class="column-input__edit"
                    >
                        <input [(ngModel)]="row[cell.name]"
                               (input)="updateEditingRow()"
                               type="text"
                               [maxlength]="cell.isCustomInputConstructorList ? null : 250"
                               class="column__input input"
                               [class.column__input--edit]="cell.isInputConstructor && cell.isCustomInputConstructorList && row?.subjectInputList"
                               [class.column__input--error]="row && row.require && row?.require[cell.name] && (!row[cell.name]?.length || (row.email && !row.email.includes('@') && cell.name === 'email'))
                           || row.login && !row?.login?.match(emailRegEx) && cell.name === 'login'"
                               [placeholder]="cell.title === 'mailing.users.table.name' ? ('input.placeholders.userName' | translate) : ('input.placeholders.' + cell.name) | translate">

                        <ng-container>
                            <div
                                *ngIf="row && row.require && row.require[cell.name] && (!row[cell.name]?.length || (row.email && !row.email.includes('@') && cell.name === 'email'))
                            || row.login && !row.login.match(emailRegEx) && cell.name === 'login'"
                                class="column__error"
                                [translate]="'mailing.users.requireValid'"></div>
                        </ng-container>
                    </div>

                    <ng-container *ngIf="cell.isInputCheckbox && row[cell.checkboxProperty] !== null">
                        <div class="column__input-checkbox">
                            <app-checkbox
                                [title]="'mailing.users.' + cell.checkboxProperty"
                                [value]="row[cell.checkboxProperty]"
                                (changeEvent)="row[cell.checkboxProperty] = $event; updateEditingRow()"
                            ></app-checkbox>
                        </div>
                    </ng-container>
                </div>
            </ng-template>

        </ng-container>

        <ng-container *ngIf="cell.mailingFieldType === mailingCellFieldTypeEnum.inputPassword">

            <div class="column__password" *ngIf="!row.isEditing; else editingInput">
                <div class="column__value column__value--password">{{ row.placeholder | translate }}</div>
            </div>

            <ng-template #editingInput>
                <div class="column__edit column__password"
                     [class.column__password--middle]="strength === 1"
                     [class.column__password--error]="strength !== -1 && strength < 1"
                     [class.column__password--strong]="strength > 1"
                >
                    <input [(ngModel)]="row[cell.name]"
                           (input)="updateEditingRow($event.target.value, row[cell.name], 'password')"
                           type="text"
                           maxlength="60"
                           class="column__input input column__input--password"
                           [placeholder]="(row.require[cell.name] ? ('input.placeholders.' + cell.name + 'Require') : ('input.placeholders.' + cell.name)) | translate">

                    <ng-container *ngIf="strength !== -1">
                        <div
                            class="column__error"
                            [class.column__error--middle]="strength === 1"
                            [class.column__error--strong]="strength > 1">
                        <span *ngIf="strength < 1"  [translate]="'auth.registration.passwordNote'"></span>
                        <span *ngIf="strength === 1"  [translate]="'auth.registration.passwordBetter'"></span>
                        <span *ngIf="strength === 2"  [translate]="'auth.registration.passwordBest'"></span>

                        </div>

                    </ng-container>
                </div>
            </ng-template>

        </ng-container>

        <ng-container *ngIf="cell.mailingFieldType === mailingCellFieldTypeEnum.textarea">
            <div *ngIf="!row.isEditing; else editingTextarea" class="column__value">{{ value }}</div>

            <ng-template #editingTextarea>
                <div class="column-textarea__wrapper">
                    <ng-container *ngIf="cell.isInputConstructor">
                        <div class="column-input__wrapper"
                             [class.column-input__wrapper--wrap]="cell.isInputConstructor"
                        >

                            <ng-container
                                *ngIf="cell.isCustomInputConstructorList && row?.inputConstructorList; else defaultConstructor">
                                <ng-container *ngFor="let list of row.inputConstructorList">

                                    <div class="column-input__tag-item">
                                        <app-input-constructor
                                            [label]="list.title"
                                            [isWithoutInput]="list.isWithoutInput"
                                            [disabled]="message?.length > list.disableLength"
                                            [type]="list.type"
                                            (newValueEvent)="insertData($event)"
                                        ></app-input-constructor>

                                    </div>

                                </ng-container>
                            </ng-container>

                            <ng-template #defaultConstructor>
                                <div class="column-input__tag-item">
                                    <app-input-constructor
                                        [isWithoutInput]="true"
                                        [label]="'Device name'"
                                        [disabled]="row[cell.name].length > 234"
                                        [type]="inputConstructorTypeEnum.deviceName"
                                        (newValueEvent)="insertData($event)"
                                    ></app-input-constructor>

                                </div>

                                <div class="column-input__tag-item">
                                    <app-input-constructor
                                        [isWithoutInput]="true"
                                        [label]="'Tag name'"
                                        [disabled]="row[cell.name].length > 237"
                                        [type]="inputConstructorTypeEnum.tagName"
                                        (newValueEvent)="insertData($event)"
                                    ></app-input-constructor>

                                </div>
                            </ng-template>
                        </div>
                    </ng-container>
                    <div class="column__edit">
                    <textarea #textarea
                              class="column__textarea"
                              [class.column__textarea--error]="!row[cell.name].length"
                              [(ngModel)]="row[cell.name]"
                              (input)="updateEditingRow($event.target.value, row[cell.name]); setCursorPosition()"
                              (click)="setCursorPosition()"
                              [placeholder]="'events.messagesCatalog.placeholderName' | translate"
                              [maxlength]="cell.isCustomInputConstructorList ? null : 250"
                    ></textarea>
                        <div *ngIf="!cell.isCustomInputConstructorList"
                             class="column__textarea-note"> {{ 'events.messagesCatalog.maxSymbols' | translate }} 250.
                            <span>{{ 250 - row[cell.name].length }} {{ 'events.messagesCatalog.symbolsLeft' | translate }}</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="cell.mailingFieldType === mailingCellFieldTypeEnum.select">

            <div class="column__password" *ngIf="!row.isEditing; else editingSelect">
                <div class="column__value">{{ row[cell.name] | translate }}</div>
            </div>

            <ng-template #editingSelect>
                <div class="column__edit">
                    <app-column-select

                        [options]="row[cell.optionsName]"
                        [value]="row[cell.currentValueForSelect]"
                        [row]="row"
                        [cell]="cell"
                        (dataChanged)="row[cell.currentValueForSelect] = $event; updateEditingRow()"></app-column-select>
                </div>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="cell.mailingFieldType === mailingCellFieldTypeEnum.inputAdd">
            <div *ngIf="!row.isEditing; else editingInputAdd" class="column__value">{{ value }}</div>

            <ng-template #editingInputAdd>

                <div class="column__content">
                    <div class="column__add-input">
                        <input [(ngModel)]="inputAddValue"
                               #addInput
                               (input)="updateEditingRow($event.target.value, row[cell.name])"
                               (change)="inputAddValue = $event?.target?.value"
                               minlength="4"
                               maxlength="36"
                               [appInputValidate]="variableValueTypeEnum.numbers"
                               [placeholder]="'devicesControl.serialNumberPlaceholder' | translate"
                               class="input" type="text">

                        <svg-icon
                            [class.column--disabled]="currentRow?.includes(addInput.value) || addInput.value.length < 4 || addInput.value.length > 36"
                            (click)="addValue()" [src]="INPUT_ADD_ICON_PATH"/>
                    </div>

                    <div class="column__content-list">
                        <ng-container *ngFor="let item of row[cell.name]">
                            <div class="column__content-item">
                                <div class="column__value">{{ item }}</div>
                                <svg-icon (click)="deleteValue(item)" [src]="INPUT_DELETE_ICON_PATH"></svg-icon>

                            </div>
                        </ng-container>
                    </div>
                </div>

            </ng-template>


        </ng-container>

        <ng-container *ngIf="cell.mailingFieldType === mailingCellFieldTypeEnum.transport">

            <div *ngIf="!row.isEditing" class="column__value column__value--transport">
                <ul class="column__value-transport-list">
                    <li *ngFor="let item of transportValues; let i = index" class="column__value-transport-item">

                        <div [ngSwitch]="item.transportType" class="column__value-transport-icon">

                            <ng-template [ngSwitchCase]="notificationTransportType.VIBER">
                                <svg-icon
                                    [src]="item?.savedStatus === userNotificationTransportStatusEnum.SAVED && item?.isActive ? VIBER_ICON_PATH : VIBER_DISABLED_ICON_PATH"></svg-icon>


                            </ng-template>

                            <ng-template [ngSwitchCase]="notificationTransportType.TELEGRAM">
                                <svg-icon
                                    [src]="item?.savedStatus === userNotificationTransportStatusEnum.SAVED && item?.isActive ? TELEGRAM_ICON_PATH : TELEGRAM_DISABLED_ICON_PATH"></svg-icon>


                            </ng-template>

                            <ng-template [ngSwitchCase]="notificationTransportType.EMAIL">
                                <svg-icon
                                    [src]="item?.savedStatus === userNotificationTransportStatusEnum.SAVED && item?.isActive ? EMAIL_ICON_PATH : EMAIL_DISABLED_ICON_PATH"></svg-icon>

                            </ng-template>
                        </div>

                        <div class="column__value-transport-text"
                             [class.column__value-item-text--disable]="item?.savedStatus !== userNotificationTransportStatusEnum.SAVED || !item?.isActive">
                            {{ item.transportType }}
                        </div>
                        <div *ngIf="item?.savedStatus !== userNotificationTransportStatusEnum.SAVED || !item?.isActive"
                             class="column__value-transport-tooltip">
                            <app-tooltip
                                [value]="!item.isActive ? 'mailing.recipient.unActiveTransport' : 'mailing.recipient.acknTransport'"></app-tooltip>
                        </div>
                    </li>
                </ul>
            </div>

        </ng-container>

        <div *ngIf="cell.postIcons && !row?.isEditing && row?.postIcons && row?.postIcons.length"
             class="column__pre-icons column__pre-icons--post">
            <ng-container *ngFor="let icon of row.postIcons">
                <div *ngIf="icon.cellNames.includes(cell.name)"
                     class="column__pre-icons-item column__pre-icons-item--post"
                     [class.column-text__pre-icons-item--action]="icon.action"
                     (click)="iconClick(icon, row, cell)">
                    <svg-icon [src]="icon.path" [svgStyle]="icon.styles"></svg-icon>

                    <!-- <div *ngIf="icon.tooltip && icon.tooltip.length"
                         class="column-text__pre-icons-tooltip column-text__pre-icons-tooltip--post"> -->
                    <app-tooltip *ngIf="icon.tooltip && icon.tooltip.length" [value]="icon.tooltip"
                                 [side]="icon.tooltipSide" [width]="'120px'"></app-tooltip>
                    <!-- </div> -->
                </div>

            </ng-container>

        </div>

        <ng-container *ngIf="cell.mailingFieldType === mailingCellFieldTypeEnum.btns">

            <ng-container *ngIf="!row.isEditing; else editingBtns">
                <ng-container *ngIf="cell.actionBtns && cell.actionBtns.length > 1; else icon">
                    <div class="column__value column__value--icon"
                         appClickOutside
                         [isActiveClickOutside]="isAction"
                         (clickOutside)="isAction = false">
                        <svg-icon [src]="ACTION_ICON_PATH"></svg-icon>

                        <div class="column__setting"
                             [class.column__setting--above]="listAbove ?? false"
                             [class.block]="isAction">

                            <ng-container *ngIf="cell.actionBtns && cell.actionBtns.length">
                                <ng-container *ngFor="let btn of tableService.columnActionList">
                                    <ng-container *ngIf="cell.actionBtns.includes(btn.property)">
                                        <div
                                            *ngIf="!row?.disabledActions || !row?.disabledActions.includes(btn.property)"
                                            class="column__setting-item"
                                            (click)="actionBtns(btn)">
                                            <svg-icon [src]="btn.property"></svg-icon>
                                            <span class="column__setting-item--text" [translate]="btn.title"
                                                  [ngStyle]="btn.styles"></span>
                                        </div>
                                    </ng-container>
                                </ng-container>

                            </ng-container>

                        </div>
                    </div>
                </ng-container>

                <ng-template #icon>

                    <ng-container *ngIf="cell.actionBtns && cell.actionBtns.length">
                        <ng-container *ngFor="let btn of tableService.columnActionList">
                            <ng-container *ngIf="cell.actionBtns.includes(btn.property)">
                                <div class="column__icon">
                                    <svg-icon (click)="actionBtns(btn)" [src]="btn.property"></svg-icon>
                                </div>
                            </ng-container>
                        </ng-container>

                    </ng-container>

                </ng-template>
            </ng-container>
            <ng-template #editingBtns>
                <div class="column__edit column__edit--btns">
                    <div class="column__btns"
                         [class.column__btns--full-wrapper]="cell?.isFullWrapperBtn"
                    >
                        <button (click)="save()"
                                class="column__btn btn btn--red"
                                [appDisabled]="{type: disableType, data: disableType !== disabledTypeEnum.statusMessageTable ? liveEditingRow : {editingRow: liveEditingRow, isEdit: (isEdit$ | async)}}"
                                [translate]="row?.isNew ? 'btns.create' : 'btns.save'"></button>
                        <button (click)="cancel()" class="column__btn btn btn--light"
                                [translate]="'btns.cancel'"></button>
                    </div>
                </div>
            </ng-template>


        </ng-container>
    </div>
</div>

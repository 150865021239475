import {
    ChangeActiveDataloggerInterface,
    CreateDataloggerInterface,
    RegenerateDataloggerInterface,
} from '../../_interfaces/devices-control.interface';
import { DevicesControlRowInterface } from '../../_interfaces/devices-control-row.interface';
import { Params } from '../../../../app-shared-elements/_interfaces/params.interface';
import { GroupOperationActionInterface } from '../../../../app-shared-elements/tables/_interfaces/group-operation-action.interface';

export class GetDevicesControl {
    static readonly type = '[DevicesControl] GetDevicesControl';
}

export class GetDevicesControlRegistrators {
    static readonly type = '[DevicesControl] GetDevicesControlRegistrators';
}

export class GetDevicesControlRows {
    static readonly type = '[DevicesControl] GetDevicesControlRows';
}

export class CreateDatalogger {
    static readonly type = '[DevicesControl] CreateDatalogger';

    constructor(public data: CreateDataloggerInterface) {}
}

export class CreateNewDatalogger {
    static readonly type = '[DevicesControl] CreateNewDatalogger';
}

export class RegenerateDatalogger {
    static readonly type = '[DevicesControl] RegenerateDatalogger';

    constructor(public data: RegenerateDataloggerInterface) {}
}

export class DeleteDatalogger {
    static readonly type = '[DevicesControl] DeleteDatalogger';

    constructor(public id: string) {}
}

export class ChangeActiveDatalogger {
    static readonly type = '[DevicesControl] ChangeActiveDatalogger';

    constructor(public data: ChangeActiveDataloggerInterface) {}
}

export class ExportDatalogger {
    static readonly type = '[DevicesControl] ExportDatalogger';

    constructor(
        public ids: string[],
        public name: string,
    ) {}
}

export class CloseEditingDevicesControlRow {
    static readonly type = '[DevicesControl] CloseEditingDevicesControlRow';

    constructor(
        public id: string,
        public savedRow?: DevicesControlRowInterface,
    ) {}
}

export class SetDevicesControlFilter {
    static readonly type = '[DevicesControl] SetDevicesControlFilter';

    constructor(public params: Params) {}
}

export class SetRowsForGroupOperation {
    static readonly type = '[DevicesControl] SetRowsFroGroupOperation';

    constructor(public event: GroupOperationActionInterface) {}
}

import { Injectable } from '@angular/core';
import { RegistrationTypeEnum } from 'src/app/app-shared-elements/_enums/user.enum';
import { ColumnsTableInterface, ColumnTypeEnum, ColumnValueAlignEnum } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { AdminInterface } from '../../admins/_interface/admins-list.interface';

@Injectable({
    providedIn: 'root',
})
export class AdminProfileService {
    deviceColumns: ColumnsTableInterface[] = [
        {
            title: 'devices.type',
            grow: false,
            small: true,
            maxWidth: '8%',
            minWidth: '8%',
            type: ColumnTypeEnum.icon,
            name: 'type',
        },
        {
            title: 'devices.name',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'name',
            align: ColumnValueAlignEnum.left,
            isClick: true,
        },
        {
            title: 'devices.login',
            grow: false,
            small: true,
            maxWidth: '200px',
            minWidth: '200px',
            type: ColumnTypeEnum.text,
            name: 'owner',
            tooltip: true,
            styles: true,
        },
        {
            title: 'devices.statusOn',
            grow: false,
            small: true,
            maxWidth: '140px',
            minWidth: '85px',
            type: ColumnTypeEnum.text,
            name: 'connect',
            styles: true,
        },
        {
            title: 'devices.signal',
            grow: false,
            small: true,
            maxWidth: '180px',
            minWidth: '100px',
            type: ColumnTypeEnum.text,
            name: 'signal',
            styles: true,
        },
        {
            title: 'devices.battery',
            grow: false,
            small: true,
            maxWidth: '160px',
            minWidth: '120px',
            type: ColumnTypeEnum.text,
            name: 'battery',
            styles: true,
        },
        {
            title: 'devices.status',
            grow: false,
            small: true,
            maxWidth: '180px',
            minWidth: '120px',
            type: ColumnTypeEnum.text,
            name: 'statusString',
            isMultilang: true,
            styles: true,
        },
        {
            title: 'devices.lastAct',
            grow: false,
            small: false,
            maxWidth: '200px',
            minWidth: '200px',
            type: ColumnTypeEnum.date,
            name: 'lastActive',
            // isSettings: true
        },
        {
            title: 'devices.actions',
            maxWidth: '100px',
            minWidth: '100px',
            type: ColumnTypeEnum.text,
            name: 'actions',
        },
    ];

    constructor() {}

    parseAdminToUser(admin: AdminInterface): User {
        return {
            created: admin.created,
            id: admin.id,
            isActive: admin.isActive,
            isConfirmEmail: true,
            login: admin.login,
            name: admin.name,
            phone: admin.phone,
            timeBlocked: admin.timeBlocked,
            available_devices: [],
            available_registrators: [],
            type: RegistrationTypeEnum.LOCAL,
            updated: admin.updated,
            lifePassword: 99,
            sessionLife: admin.sessionLife,
            dateFormat: admin.dateFormat,
            dateTimeZone: admin.dateTimeZone,
            companyManagerId: null,
            passwordUpdate: null,
            userAgent: '',
            isConfirmByAdmin: true,
            isDeleted: false,
            access_token: null,
            isOnline: true,
            statusTwoFactorAuthentication: admin.statusTwoFactorAuthentication,
            isAlarmSound: false,
            companyId: null,
            lastActive: null,
            lang: null,

            // for admin
            roleId: admin.roleId,
        };
    }
}

import { Injectable } from '@angular/core';
import { BillingPersonalAccountInterface } from '../../../billing/_interfaces/billing-personal-account.interface';
import { BillingAccountInfoInterface } from '../../../billing/_interfaces/billing-account-info.interface';
import { UserProfileInfoTypeElementEnum } from '../../../profile/_enums/user-profile-info-type.enum';
import { BillingAccountInfoTypeEnum } from '../../../billing/_enums/billing-account-info-type.enum';
import { BillingAccountService } from '../../../billing/_services/billing-account.service';

@Injectable({
    providedIn: 'root',
})
export class FinancePayInfoService {
    constructor(private billingAccountService: BillingAccountService) {}

    getBillingProviderInfo(info?: BillingPersonalAccountInterface): BillingAccountInfoInterface[] {
        const accountInfoArray: BillingAccountInfoInterface[] = [
            {
                title: 'billing.info.company',
                value: info?.name,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.name,
                inputPlaceholder: 'billing.info.placeholders.name',
            },
            {
                title: 'billing.info.edrpou',
                value: info?.edrpou,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.edrpou,
                inputPlaceholder: 'billing.info.placeholders.edrpou',
            },
            {
                title: 'billing.info.account',
                value: info?.paymentAccount,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.paymentAccount,
                inputPlaceholder: 'billing.info.placeholders.account',
            },
            {
                title: 'billing.info.email',
                value: info?.email,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.email,
                inputPlaceholder: 'billing.info.placeholders.email',
            },
            {
                title: 'billing.info.phone',
                value: info?.phone,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.phone,
                inputPlaceholder: 'billing.info.placeholders.phone',
            },
            {
                title: 'billing.info.country',
                value: info?.country,
                elementType: UserProfileInfoTypeElementEnum.select,
                type: BillingAccountInfoTypeEnum.country,
                inputPlaceholder: 'billing.info.placeholders.country',
            },
            {
                title: 'billing.info.index',
                value: info?.postcode,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.postcode,
                inputPlaceholder: 'billing.info.placeholders.index',
            },
            {
                title: 'billing.info.region',
                value: info?.region,
                elementType: UserProfileInfoTypeElementEnum.select,
                type: BillingAccountInfoTypeEnum.region,
                inputPlaceholder: 'billing.info.placeholders.region',
            },
            {
                title: 'billing.info.city',
                value: info?.city,
                elementType: UserProfileInfoTypeElementEnum.select,
                type: BillingAccountInfoTypeEnum.city,
                inputPlaceholder: 'billing.info.placeholders.city',
            },
            {
                title: 'billing.info.address',
                value: info?.address,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.address,
                inputPlaceholder: 'billing.info.placeholders.address',
            },
            {
                title: 'billing.info.director',
                value: info?.director,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.director,
                inputPlaceholder: 'billing.info.placeholders.director',
            },
            {
                title: 'billing.info.currency',
                value: info?.currency,
                elementType: UserProfileInfoTypeElementEnum.input,
                type: BillingAccountInfoTypeEnum.currency,
                inputPlaceholder: 'billing.info.placeholders.currency',
            },
            {
                title: 'billing.info.signature',
                value: info?.signature,
                elementType: UserProfileInfoTypeElementEnum.download,
                type: BillingAccountInfoTypeEnum.signature,
            },
        ];

        return this.billingAccountService.parseInfo(accountInfoArray);
    }
}

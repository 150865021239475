<div class="column">
    <div class="column__datepicker datepicker">
        <p-calendar
            [showTime]="true"
            [dateFormat]="dateFormat"
            [hourFormat]="(user$ | async)?.hourFormat === userProfileInfoTypeDateEnum.ddMMyyyy ? '24' : '12'"
            class="datepicker__wrapper"
            placeholder="{{'filters.placeholderDate' | translate}}"
            (onSelect)="datepickerEvent($event)"
            (onInput)="datepickerEvent($event)"
            [showIcon]="false"
            [hideOnDateTimeSelect]="true">

            <ng-template pTemplate="date" let-date>
                                <span class="datepicker__day">
                                        {{date.day}}</span>
            </ng-template>
        </p-calendar>
    </div>
</div>

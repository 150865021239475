import {AuthSignUpInterface} from '../../_interfaces/auth.interface';

export class Logout {
    static readonly type = '[Auth] Logout';

}

export class ResetPassword {
    static readonly type = '[Auth] ResetPassword';

    constructor(public data: { token: string, password: string, tokenTwoFactor?: string }, public isAdmin: boolean) {
    }

}

export class ForgotPassword {
    static readonly type = '[Auth] ForgotPassword';

    constructor(public email: string) {
    }

}

export class ForgotAdminPassword {
    static readonly type = '[Auth] ForgotAdminPassword';

    constructor(public email: string) {
    }
}

export class CheckToken {
    static readonly type = '[Auth] CheckToken';

    constructor(public token: string, public isAdmin: boolean) {
    }
}

export class AuthSignUp {
    static readonly type = '[Auth] AuthSignUp';

    constructor(public user: AuthSignUpInterface) {
    }

}

export class AuthSingIn {
    static readonly type = '[Auth] AuthSingIn';

    constructor(public data: { login: string, password: string, token?: string }, public isAdmin: boolean) {
    }
}

export class AuthSingInControl {
    static readonly type = '[Auth] AuthSingInControl';

    constructor(public data: { login: string, password: string, token: string, recoveryPassword?: string }) {
    }
}


export class SetUserToken {
    static readonly type = '[Auth] SetUserToken';

    constructor(public token: string) {

    }
}

export class ConfirmEmail {
    static readonly type = '[Auth] ConfirmEmail';

    constructor(public email: string, public isAdmin: boolean) {

    }
}

export class ConfirmToken {
    static readonly type = '[Auth] ConfirmToken';

    constructor(public token: string, public isAdmin: boolean) {

    }
}

export class SetIsAdmin {
    static readonly type = '[Auth] SetIsAdmin';

    constructor(public value: boolean) {

    }
}

export class GetConstantForClient {
    static readonly type = '[Auth] GetConstantForClient';
}

export class InitXSRFProtected {
    static readonly type = '[Auth] InitXSRFProtected';

}

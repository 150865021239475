import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { InviteInterface } from '../_interfaces/InviteInterfaces';
import { Select, Store } from '@ngxs/store';
import { ApplyInvite, CancelInvite } from '../_store/actions/invite.actions';
import { Observable, Subject } from 'rxjs';
import { GetDevices, InitUserDevicesArray } from 'src/app/device-dashboard/_store/actions/user-devices.actions';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { UserState } from '../_store/states/user.state';
import { DataTypeService } from '../_services/data-type.service';
import { LangEnum } from '../_enums/lang.enum';
import { LanguageState } from '../_store/states/language.state';
import { DeviceService } from 'src/app/device-dashboard/_services/device.service';
import { CreationType } from '../_enums/registrator-sync-status.enu';

@Component({
    selector: 'app-invite',
    templateUrl: './invite.component.html',
    styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnInit, OnDestroy {
    @Input() invite: InviteInterface;

    @Output() closeInviteEvent = new EventEmitter<any>();

    @Select(UserState.getUser) user$: Observable<User>;
    @Select(UserState.getUserTimezoneGMT) gmt$: Observable<string>;
    @Select(LanguageState.getLanguage) ln$: Observable<LangEnum>;

    creationType = CreationType;

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        public dataTypeService: DataTypeService,
        private store: Store,
        private deviceService: DeviceService,
    ) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }

    async applyInvite(): Promise<void> {
        await this.store.dispatch(new ApplyInvite(this.invite)).toPromise();
        await this.store.dispatch(new GetDevices(false)).toPromise();
        await this.store.dispatch(new InitUserDevicesArray()).toPromise();
        this.deviceService.updateDeviceLength.emit();
    }

    async cancelInvite(): Promise<void> {
        await this.store.dispatch(new CancelInvite(this.invite)).toPromise();

        this.closeInvite();
    }

    closeInvite(): void {
        this.closeInviteEvent.emit();
    }
}

import { ParamsFilterTypeEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { AlarmTypeEnum } from 'src/app/events/_enums/alarm.enum';

export const initialOriginFilterTransports = [
    // {
    //     title: 'transportDashboard.filters.registrators',
    //     property: 'type',
    //     value: true,
    //     type: ParamsFilterTypeEnum.BOOLEAN,
    //     isRadio: true,
    //     relationTrue: [
    //         {
    //             property: 'creationType',
    //             value: CreationType.ORIGIN,
    //             type: ParamsFilterTypeEnum.TEXT,
    //         }
    //     ],
    // },
    // {
    //     title: 'transportDashboard.filters.vRegistrators',
    //     property: 'type',
    //     value: false,
    //     type: ParamsFilterTypeEnum.BOOLEAN,
    //     isRadio: true,
    //     relationTrue: [
    //         {
    //             property: 'creationType',
    //             value: CreationType.VIRTUAL,
    //             type: ParamsFilterTypeEnum.TEXT,
    //         }
    //     ],
    // },
    {
        title: 'transportDashboard.filters.online',
        property: 'isConnect',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
    },
    {
        title: 'transportDashboard.filters.ok',
        property: 'status',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'status',
                value: AlarmTypeEnum.ok,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ],
    },
    {
        title: 'transportDashboard.filters.alarm',
        property: 'status',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'status',
                value: AlarmTypeEnum.alarm,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ],
    },
    {
        title: 'transportDashboard.filters.attention',
        property: 'status',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'status',
                value: AlarmTypeEnum.attention,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ],
    },
];

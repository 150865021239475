import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { ApiResponse } from 'src/app/app-shared-elements/_interfaces/ApiRequest';
import { ColumnsTableInterface, ColumnTypeEnum, ColumnValueAlignEnum } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { SelectOptionInterface } from 'src/app/app-shared-elements/_interfaces/select-option.interface';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { MailingCellFieldTypeEnum } from 'src/app/mailing/_enums/mailing-cell-field-type.enum';
import { CompanyInterface, CompanyRowInterface } from '../../_interfaces/company.interface';
import { AddCompany, CloseEditingCompanyRow, CreateCompany, DeleteCompany, GetCompanies, GetCurrentCompany, InitCompanyRows, OperationOnCompany, SetEditingCompanyRow, UpdateCompany } from '../actions/company.actions';
import { DisabledTypeEnum } from 'src/app/app-shared-elements/_enums/disabled-type.enum';
import { CompanyService } from '../../_services/company.service';
import { UsersState } from '../../../users/_store/states/users.state';

export interface CompanyStateModel {
    companies: CompanyInterface[];
    changingCompany: CompanyInterface;
    companyRows: CompanyRowInterface[];
    companyColumns: ColumnsTableInterface[];
    isEditingMode: boolean;
    currentCompany: CompanyInterface;
}

const COMPANY_STATE_TOKEN = new StateToken<CompanyStateModel>('companyState');

const columns: ColumnsTableInterface[] = [
    {
        title: 'company.table.name',
        name: 'name',
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.input,
        align: ColumnValueAlignEnum.left,
    },
    {
        title: 'company.table.admins',
        name: 'managerId',
        type: ColumnTypeEnum.select,
        isSelectEditable: true,
        optionsName: 'userOptions',
        currentValueForSelect: 'userName',
        placeholderForSelectColumn: 'company.table.adminsPlaceholder',
    },
    {
        title: 'company.table.comment',
        name: 'comment',
        grow: true,
        type: ColumnTypeEnum.mailingEditor,
        mailingFieldType: MailingCellFieldTypeEnum.input,
        align: ColumnValueAlignEnum.left,
    },
    {
        title: 'company.table.actions',
        grow: false,
        small: false,
        type: ColumnTypeEnum.mailingEditor,
        maxWidth: '100px',
        minWidth: '85px',
        mailingFieldType: MailingCellFieldTypeEnum.btns,
        name: 'edit',
        actionBtns: [ColumnsActionTypeEnum.actionBtnsEdit, ColumnsActionTypeEnum.users, ColumnsActionTypeEnum.devices, ColumnsActionTypeEnum.actionBtnsDelete],
    },
];

@State<CompanyStateModel>({
    name: COMPANY_STATE_TOKEN,
    defaults: {
        companies: [],
        changingCompany: null,
        companyRows: [],
        companyColumns: columns,
        isEditingMode: false,
        currentCompany: null,
    },
})
@Injectable()
export class CompanyState {
    constructor(
        private http: HttpClient,
        private notificationsService: NotificationsService,
        private translateService: TranslateService,
        private companyService: CompanyService,
        private store: Store,
    ) {}

    @Selector()
    static getCompanies(state: CompanyStateModel): CompanyInterface[] {
        return state.companies.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    }

    @Selector()
    static getCompanyColumns(state: CompanyStateModel): ColumnsTableInterface[] {
        return state.companyColumns;
    }

    @Selector()
    static getCurrentCompany(state: CompanyStateModel): CompanyInterface {
        return state.currentCompany;
    }

    @Selector()
    static getChangingCompany(state: CompanyStateModel): CompanyInterface {
        return state.changingCompany;
    }

    @Selector()
    static getCompanyRows(state: CompanyStateModel): CompanyRowInterface[] {
        return state.companyRows;
    }

    @Selector()
    static getIsEditingMode(state: CompanyStateModel): boolean {
        return state.isEditingMode;
    }

    @Selector()
    static getCompaniesOptions(state: CompanyStateModel): SelectOptionInterface<string, string, CompanyInterface>[] {
        return state.companies
            .filter((c) => c.id)
            .map((c) => {
                return {
                    key: c.id,
                    property: c,
                    value: c.name,
                };
            });
    }

    @Action(GetCompanies)
    async getCompanies(ctx: StateContext<CompanyStateModel>): Promise<void> {
        const result: ApiResponse<CompanyInterface[]> = (await this.http
            .get('/api/control/company')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<CompanyInterface[]>;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                companies: result.data,
            });

            ctx.dispatch(new InitCompanyRows());
            return;
        }
    }

    @Action(GetCurrentCompany)
    async getCurrentCompany(ctx: StateContext<CompanyStateModel>): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/company')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                currentCompany: result.data,
            });
        }
    }

    @Action(AddCompany)
    addCompany(ctx: StateContext<CompanyStateModel>): void {
        const state = ctx.getState();

        const users = this.store.selectSnapshot(UsersState.getUsers);

        const emptyCompany: CompanyRowInterface = {
            name: '',
            comment: '',
            isNew: true,
            isEditing: true,
            userOptions: this.companyService.getUserOptions(users),
            userName: null,
            managerId: null,
            require: {
                name: true,
                contactPerson: true,
                contactData: true,
                comment: false,
            },
            disabledType: DisabledTypeEnum.companyTable,
        };

        ctx.setState({
            ...state,
            companyRows: [emptyCompany, ...state.companyRows],
            isEditingMode: true,
        });
    }

    @Action(CreateCompany)
    async createCompany(ctx: StateContext<CompanyStateModel>, payload: CreateCompany): Promise<void> {
        const result: ApiResponse<CompanyInterface> = (await this.http
            .post('/api/control/company', { ...payload.company })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<CompanyInterface>;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                companies: [...state.companies, result.data],
                isEditingMode: false,
            });
            const message: string = await this.translateService
                .get('company.successCreated', { companyName: result.data.name })
                .toPromise()
                .catch((e) => console.log(e));
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, message);

            ctx.dispatch(new InitCompanyRows());
            return;
        }
    }

    @Action(UpdateCompany)
    async updateCompany(ctx: StateContext<CompanyStateModel>, payload: UpdateCompany): Promise<void> {
        const result: ApiResponse<CompanyInterface> = (await this.http
            .put('/api/control/company', { ...payload.company })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<CompanyInterface>;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                companies: state.companies.map((c) => {
                    if (c.id === result.data.id) {
                        return {
                            ...result.data,
                        };
                    }
                    return c;
                }),
                isEditingMode: false,
            });

            const message: string = await this.translateService
                .get('company.successUpdated', { companyName: result.data.name })
                .toPromise()
                .catch((e) => console.log(e));
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, message);
            ctx.dispatch(new InitCompanyRows());
            return;
        }
    }

    @Action(DeleteCompany)
    async deleteCompany(ctx: StateContext<CompanyStateModel>, payload: DeleteCompany): Promise<void> {
        const result: ApiResponse<void> = (await this.http
            .delete('/api/control/company', { params: { id: payload.id } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<void>;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            const currentCompany = state.companies.find((c) => c.id === payload.id);
            const message: string = await this.translateService
                .get('company.successDeleted', { companyName: currentCompany.name })
                .toPromise()
                .catch((e) => console.log(e));

            ctx.setState({
                ...state,
                companies: state.companies.filter((c) => c.id !== payload.id),
            });

            this.notificationsService.onEmit(TooltipStatusEnum.update, false, message);
            ctx.dispatch(new InitCompanyRows());
            return;
        }
    }

    @Action(OperationOnCompany)
    async operationOnCompany(ctx: StateContext<CompanyStateModel>, payload: OperationOnCompany): Promise<void> {
        const result: ApiResponse<void> = (await this.http
            .post('/api/control/company/user', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<void>;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
            });

            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        }
    }

    @Action(SetEditingCompanyRow)
    setEditingCompanyRow(ctx: StateContext<CompanyStateModel>, payload: SetEditingCompanyRow): void {
        const state = ctx.getState();

        if (state.companyRows.find((r) => r.isEditing)) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false, 'admin.units.alreadyEditing');
            return;
        }

        ctx.setState({
            ...state,
            companyRows: state.companyRows.map((row) => ({ ...row, isEditing: row.id === payload.id })),
            isEditingMode: true,
        });
    }

    @Action(CloseEditingCompanyRow)
    closeEditingRow(ctx: StateContext<CompanyStateModel>, payload: CloseEditingCompanyRow): void {
        const state = ctx.getState();

        if (state.companyRows.length === 1 && state.companyRows[0].isNew) {
            ctx.setState({
                ...state,
                companyRows: [],
                isEditingMode: false,
            });

            return;
        }

        ctx.setState({
            ...state,
            companyRows: state.companyRows
                .map((row) => ({
                    ...(payload.savedRow && payload.savedRow.id === row.id ? payload.savedRow : row),
                    isEditing: false,
                }))
                .filter((r) => r.id),
            isEditingMode: false,
        });

        // ctx.dispatch(GetUnits);
    }

    @Action(InitCompanyRows)
    initCompanyRows(ctx: StateContext<CompanyStateModel>): void {
        const state = ctx.getState();
        const users = this.store.selectSnapshot(UsersState.getUsers);

        ctx.setState({
            ...state,
            companyRows: state.companies.map((c) => {
                const currentUser = users.find((f) => f.id === c.managerId);
                return {
                    ...c,
                    isNew: false,
                    isEditing: false,
                    userOptions: this.companyService.getUserOptions(users),
                    userName: currentUser ? currentUser.name : '',
                    require: {
                        name: true,
                        contactPerson: true,
                        contactData: true,
                        comment: false,
                    },
                    disabledType: DisabledTypeEnum.companyTable,
                };
            }),
        });
    }
}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    constructor() {}

    emailValidation(value: string, isRequire?: boolean): boolean {
        if ((!value || !value.length) && !isRequire) {
            return true;
        }
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return !!value.match(reg);
    }

    numberValidation(value: string, min?: number, max?: number): boolean {
        return;
    }

    phoneValidation(value: string): boolean {
        return;
    }

    telegramValidation(value: string): boolean {
        return;
    }
}

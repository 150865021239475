import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Tabs } from 'src/app/app-shared-elements/_interfaces/Tabs';
import { AdminService } from '../_services/admin.service';
import { Select, Store } from '@ngxs/store';
import { SetCurrentQueryParams, SetTabs } from 'src/app/app-shared-elements/_store/actions/tabs.actions';
import { UserState } from 'src/app/app-shared-elements/_store/states/user.state';
import { Observable, Subject } from 'rxjs';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { first, takeUntil } from 'rxjs/operators';
import { SetCurrentProfileUser, SetUserProfileInfo } from 'src/app/profile/_store/actions/profile.actions';
import { GetAdminSessions, GetUserSessions } from 'src/app/profile/_store/actions/sessions.actions';
import { StateReset } from 'ngxs-reset-plugin';
import { ProfileState } from 'src/app/profile/_store/states/profile.state';
import { GetAdmins } from '../admins/_store/actions/admins-list.actions';
import { AdminsListState } from '../admins/_store/states/admins-list.state';
import { AdminProfileService } from './_services/admin-profile.service';
import { ConfigurationState } from 'src/app/app-shared-elements/_store/states/configuration.state';
import { ConfigurationServerInterface } from 'src/app/app-shared-elements/_interfaces/configuration-server.interface';
import { GetCurrentUser } from '../../app-shared-elements/_store/actions/user.actions';

@Component({
    selector: 'app-admin-profile',
    templateUrl: './admin-profile.component.html',
    styleUrls: ['./admin-profile.component.scss'],
})
export class AdminProfileComponent implements OnInit, OnDestroy {
    tabs: Tabs[];

    @Select(ProfileState.getProfileUser) user$: Observable<User>;
    @Select(ConfigurationState.getConfigurationServer) configuration$: Observable<ConfigurationServerInterface>;

    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        private adminService: AdminService,
        private route: ActivatedRoute,
        private router: Router,
        private store: Store,
        private adminProfileService: AdminProfileService,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.configuration$
            .pipe(first((c) => !!c))
            .toPromise()
            .catch((e) => console.log(e));
        this.route.queryParamMap.pipe(takeUntil(this.destroy)).subscribe(async (param: ParamMap) => {
            const userId = param.get('id');
            const adminId = param.get('adminId');

            if (userId) {
                await this.store.dispatch(new GetCurrentUser(userId)).toPromise();
                const currentUser = this.store.selectSnapshot(UserState.getCurrentUser);
                this.store.dispatch(new SetCurrentProfileUser(currentUser));
                this.store.dispatch(new GetUserSessions(userId));
                this.store.dispatch(new SetCurrentQueryParams({ id: userId }));
                this.getTabsUser();
            } else if (adminId) {
                await this.store.dispatch(new GetAdmins()).toPromise();
                const admin: User = this.adminProfileService.parseAdminToUser(
                    this.store.selectSnapshot(AdminsListState.getAdmins).find((a) => a.id === adminId),
                );
                // console.log(admin);
                this.store.dispatch(new SetCurrentProfileUser(admin));
                this.store.dispatch(new SetUserProfileInfo(admin, false));

                this.store.dispatch(new GetAdminSessions(admin.id));
                this.store.dispatch(new SetCurrentQueryParams(null));
            } else {
                const currentUser = this.store.selectSnapshot(UserState.getUser);
                this.store.dispatch(new SetCurrentProfileUser(currentUser));
                this.store.dispatch(new SetUserProfileInfo(currentUser, false));

                this.store.dispatch(new GetAdminSessions());
                this.store.dispatch(new SetCurrentQueryParams(null));
                // this.getTabsAdmin();
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
        this.store.dispatch(new SetCurrentQueryParams(null));
        this.store.dispatch(new StateReset(ProfileState));
    }

    getTabsUser(): any {
        this.tabs = this.adminService.getTabsUser();
        this.setCurrentFirstTab();
        this.store.dispatch(new SetTabs(this.tabs));
    }

    setCurrentFirstTab(): void {
        this.tabs = this.tabs.map((tab) => {
            tab.active = this.router.url.includes(tab.path);

            return tab;
        });
    }
}

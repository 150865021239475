import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Pagination} from 'src/app/app-shared-elements/_interfaces/params.interface';
import {MobileCardInterface} from '../../_interfaces/mobile-card.interface';

@Component({
    selector: 'app-mobile-card',
    templateUrl: './mobile-card.component.html',
    styleUrls: ['./mobile-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileCardComponent<T> implements OnInit {

    @Input() card: MobileCardInterface;
    @Input() configPagination: Pagination;

    constructor() {}

    ngOnInit(): void {}
}

import {Params} from 'src/app/app-shared-elements/_interfaces/params.interface';
import {MailingRecipientRowInterface} from '../../_interfaces/mailing-recipient-row.interface';

export class GetRecipients {
    static readonly type = '[MailingRecipient] GetRecipients';
}

export class InitRecipientsRows {
    static readonly type = '[MailingRecipient] InitRecipientsRows';
    // constructor(public recipient: RecipientsUserInterface) {

    // }
}

export class SetRecipientParams {
    static readonly type = '[MailingRecipient] SetRecipientParams';

    constructor(public params: Params) {

    }
}

export class AddNewRecipient {
    static readonly type = '[MailingRecipient] AddNewRecipient';
}

export class CreateNewRecipient {
    static readonly type = '[MailingRecipient] CreateNewRecipient';

    constructor(public recipientRow: MailingRecipientRowInterface) {

    }
}

export class DeleteRecipient {
    static readonly type = '[MailingRecipient] DeleteRecipient';

    constructor(public id: string) {

    }
}

export class SetEditingRecipientRow {
    static readonly type = '[MailingRecipient] SetEditingRecipientRow';

    constructor(public id: string) {

    }
}

export class CloseEditingRecipientRow {
    static readonly type = '[MailingRecipient] CloseEditingRecipientRow';

    constructor(public id: string, public row: MailingRecipientRowInterface) {

    }
}

export class ChangeActivityRecipient {
    static readonly type = '[MailingRecipient] ChangeActivityRecipient';

    constructor(public data: { recipientUserId: string; isActive: boolean }) {

    }
}

export class GetUsersByCompanies {
    static readonly type = '[MailingRecipient] GetUsersByCompanies';
}

export class SetIsDeleteRecipientPopup {
    static readonly type = '[MailingRecipient] SetIsDeleteRecipientPopup';

    constructor(public value: boolean) {
    }

}


import {Pipe, PipeTransform} from '@angular/core';
import {Store} from '@ngxs/store';
import {LogicalEventRowInterface} from 'src/app/events/_interfaces/LogicalEventRow';
import {DevicesState} from 'src/app/device-dashboard/_store/states/user-devices.state';
import {ReportRowInterface} from 'src/app/reports/_interfaces/ReportRow';
import {ReportsState} from 'src/app/reports/_store/states/reports.state';
import {DeviceTypeEnum} from '../_enums/device-type.enum';
import {FilterByPermissionsEnum} from '../_enums/filter-by-permissions-type.enum';
import {Device} from '../_interfaces/Device';
import {FilterByPermissionsInterface} from '../_interfaces/FilterByPermissionsInterface';
import {Group} from '../_interfaces/Group';
import {RoleInterface} from '../_interfaces/role.interface';
import {PermissionService} from '../_services/permission.service';
import {TableService} from '../_services/table.service';
import {PermissionsState} from '../_store/states/permissions.state';

@Pipe({
    name: 'filterByPermissions'
})
export class FilterByPermissionsPipe implements PipeTransform {

    devices: Device[];
    registrators: Device[];
    roles: RoleInterface[];

    constructor(
        private store: Store,
        private permissionService: PermissionService,
        private tableService: TableService
    ) {
    }

    transform(values: any[], args: FilterByPermissionsInterface): any[] {

        this.getData();

        switch (args.type) {
            case FilterByPermissionsEnum.groupsByPermission:
                return this.filterGroupsByPermissions(values, args);
            case FilterByPermissionsEnum.logicalEventsByPermission:
                return this.filterLogicalEventsByPermissions(values, args);
            case FilterByPermissionsEnum.registratorOptionsByPermission:
                return this.filterRegistratorOptionsByPermission(values, args);
            case FilterByPermissionsEnum.registratorsByPermission:
                return this.filterRegistratorsByPermission(values, args);
            case FilterByPermissionsEnum.reportsByPermission:
                return this.filterReportsByPermission(values, args);
            case FilterByPermissionsEnum.groupOptionsByPermission:
                return this.filterGroupOptionsByPermission(values, args);
            case FilterByPermissionsEnum.groupDeviceByPermission:
                return this.filterDeviceOptionsByPermission(values, args);
            // case FilterByPermissionsEnum.deviceLogsByPermission:
            //     return this.filterDeviceLogsByPermission(values, args)
            default:
                return values;
        }
    }

    getData(): void {
        this.devices = this.store.selectSnapshot(DevicesState.getDevices);
        this.registrators = this.devices.filter(device => device.type === DeviceTypeEnum.registrator);

        // if (this.store.selectSnapshot(PermissionsState.getIsLoadRoles)) {
        //     await this.store.dispatch(new GetRoles).toPromise();
        // }

        this.roles = this.store.selectSnapshot(PermissionsState.getRoles);


    }

    private filterGroupsByPermissions(groups: Group[], args: FilterByPermissionsInterface): Group[] {

        return groups.filter(group => {
            const currentRegistrator = this.registrators.find(registrator => registrator.id === group.deviceId);

            if (!currentRegistrator.roleId) {
                return group;
            }
            const currentRole: RoleInterface = this.roles.find(role => role.id === currentRegistrator.roleId);


            const data = {
                registrator: currentRegistrator,
                action: args.action,
                permissions: args.methods
            };

            if (this.permissionService.checkPermission(currentRole.permissions, data)) {
                return group;
            }

        });
    }

    private filterLogicalEventsByPermissions(rows: LogicalEventRowInterface[], args: FilterByPermissionsInterface): LogicalEventRowInterface[] {
        if (!rows) {
            return rows;
        }

        const result = rows.filter(row => {
            const currentRegistrator = this.registrators.find(registrator => registrator.id === row.registratorId);

            // if roleId === null, that it is owner
            if (!currentRegistrator || !currentRegistrator.roleId) {
                return row;
            }

            const currentRole: RoleInterface = this.roles.find(role => role.id === currentRegistrator.roleId);
            if (!currentRole) {
                return row;
            }
            const data = {
                registrator: currentRegistrator,
                action: args.action,
                permissions: args.methods
            };
            if (this.permissionService.checkPermission(currentRole.permissions, data)) {
                return row;
            }
        });
        // this.tableService.rowsLengthAfterFilter.emit(result.length);
        return result;
    }

    private filterRegistratorOptionsByPermission(options, args: FilterByPermissionsInterface): any[] {
        if (!options) {
            return [];
        }

        return options.filter(option => {
            if ((option.key as string).includes('all')) {
                return option;
            }

            const currentRegistrator = this.registrators.find(registrator => registrator.id === option.key);
            // if roleId === null, that it is owner
            if (!currentRegistrator.roleId) {
                return option;
            }

            const currentRole: RoleInterface = this.roles.find(role => role.id === currentRegistrator.roleId);
            if (!currentRole) {
                return option;
            }
            const data = {
                registrator: currentRegistrator,
                action: args.action,
                permissions: args.methods
            };

            if (this.permissionService.checkPermission(currentRole.permissions, data)) {
                return option;
            }
        });
    }

    private filterRegistratorsByPermission(registrators: Device[], args: FilterByPermissionsInterface): Device[] {
        if (!registrators) {
            return [];
        }

        return registrators.filter(registrator => {
            if (!registrator.roleId) {
                return registrator;
            }

            const currentRole: RoleInterface = this.roles.find(role => role.id === registrator.roleId);
            if (!currentRole) {
                return registrator;
            }
            const data = {
                registrator,
                action: args.action,
                permissions: args.methods
            };

            if (this.permissionService.checkPermission(currentRole.permissions, data)) {
                return registrator;
            }
        });
    }

    private filterReportsByPermission(rows: ReportRowInterface[], args: FilterByPermissionsInterface): ReportRowInterface[] {
        const devices = args.devices;
        if (!devices || !devices.length) {
            return [];
        }
        const reports = this.store.selectSnapshot(ReportsState.getReportsList);
        const registrators = devices.filter(device => device.type === DeviceTypeEnum.registrator);

        return rows.filter(row => {

            const currentReport = reports.find(report => report.id === row.id);

            const currentDevice = devices.find(device => device.id === currentReport.deviceId);
            if (currentDevice) {
                const currentRegistrator = currentDevice.type === DeviceTypeEnum.registrator ? currentDevice : registrators.find(registrator => registrator.id === currentDevice.registratorId);

                if (!currentRegistrator.roleId) {
                    return row;
                }

                const currentRole: RoleInterface = this.roles.find(role => role.id === currentRegistrator.roleId);
                const data = {
                    registrator: currentRegistrator,
                    action: args.action,
                    permissions: args.methods
                };

                if (this.permissionService.checkPermission(currentRole.permissions, data)) {
                    return row;
                }
            }


        });
    }

    private filterGroupOptionsByPermission(options: {value: string, property: Group, key: number}[], args: FilterByPermissionsInterface): {
        value: string,
        property: Group,
        key: number
    }[] {

        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        const registrators = devices.filter(device => device.type === DeviceTypeEnum.registrator);

        return options.filter(option => {
            const currentDevice = devices.find(device => device.id === option.property.deviceId);

            const currentRegistrator = currentDevice.type === DeviceTypeEnum.registrator ? currentDevice : registrators.find(registrator => registrator.id === currentDevice.registratorId);

            if (!currentRegistrator.roleId) {
                return option;
            }

            const currentRole: RoleInterface = this.roles.find(role => role.id === currentRegistrator.roleId);
            const data = {
                registrator: currentRegistrator,
                action: args.action,
                permissions: args.methods
            };

            if (this.permissionService.checkPermission(currentRole.permissions, data)) {
                return option;
            }
        });
    }

    private filterDeviceOptionsByPermission(options: {value: string, property: Device, key: number}[], args: FilterByPermissionsInterface): {value: string, property: Device, key: number}[] {
        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        const registrators = devices.filter(device => device.type === DeviceTypeEnum.registrator);

        return options.filter(option => {
            const currentRegistrator = option.property.type === DeviceTypeEnum.registrator ? option.property : registrators.find(r => r.id === option.property.registratorId);

            if (!currentRegistrator.roleId) {
                return option;
            }

            const currentRole: RoleInterface = this.roles.find(role => role.id === currentRegistrator.roleId);
            if (!currentRole) {
                return option;
            }
            const data = {
                registrator: currentRegistrator,
                action: args.action,
                permissions: args.methods
            };

            if (this.permissionService.checkPermission(currentRole.permissions, data)) {
                return option;
            }
        });
    }
}

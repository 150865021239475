import {TableOperationInterface} from '../../tables/_interfaces/table-operation.interface';
import {TableNamesEnum} from '../../_enums/table-names.enum';

export class AddApplyTableEditionConfig {
    static readonly type = '[Table] AddApplyTableEditionConfig';
    constructor(public tableName: TableNamesEnum) {

    }
}

export class RemoveApplyTableEditionConfig {
    static readonly type = '[Table] RemoveApplyTableEditionConfig';
    constructor(public tableName: TableNamesEnum) {
    }
}

export class SetIsEditMode {
    static readonly type = '[Table] SetIsEditMode';
    constructor(public value: boolean) {
    }
}
export class SetSkeleton {
    static readonly type = '[Table] SetSkeleton';
    constructor(public value: boolean) {
    }
}

export class InitTableGroupOperations {
    static readonly type = '[Table] InitTableGroupOperations';
    constructor(public tableGroupOperations: TableOperationInterface[]) {}
}

export class SetOperation {
    static readonly type = '[Table] SetOperation';
    constructor(public tableGroupOperation: TableOperationInterface) {}
}

export class SetMainOperation {
    static readonly type = '[Table] SetMainOperation';
    constructor(public value: boolean) {}


}

// export class SetDisableIdsForGroupOperation {
//     static readonly type = '[Table] SetDisableIdsForGroupOperation';
//     constructor(public ids: string[]) {}
// }

export enum ColumnsActionTypeEnum {
    isGenerationKey = './assets/design/icons/actions/regeneration.svg',
    actionBtnsEdit = './assets/design/icons/actions/edit.svg',
    actionBtnsCopy = './assets/design/icons/actions/copy.svg',
    actionBtnsDuplicate = './assets/design/icons/actions/duplicate.svg',
    actionBtnsDelete = './assets/design/icons/actions/delete.svg',
    actionBtnsReport = './assets/design/icons/actions/report.svg',
    actionBtnsSend = './assets/design/icons/actions/send.svg',
    isShare = './assets/design/icons/actions/share.svg',
    recovery = './assets/design/icons/actions/recovery.svg',
    view = './assets/design/icons/actions/view.svg',
    users = './assets/design/icons/actions/users.svg',
    devices = './assets/design/icons/actions/devices.svg',
    download = './assets/design/icons/actions/download.svg',
    settings = './assets/design/icons/actions/settings.svg',
    regenerationPassword = './assets/design/icons/actions/regenerationPassword.svg',
    export = './assets/design/icons/export-user.svg',
    passwordRecovery = './assets/design/icons/password-recovery.svg',
}

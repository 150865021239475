import {Injectable} from '@angular/core';
import {ParamsFilter, ParamsFilterForClient, ParamsFilterTypeEnum, ParamsTime} from '../_interfaces/params.interface';

@Injectable({
    providedIn: 'root'
})
export class ParamsService {

    constructor() { }

    parseParamsFilterForServer(filter: ParamsFilterForClient[], dateTime?: ParamsTime): ParamsFilter[] {
        const result: ParamsFilter[] = [];
        // if (!filter) {
        //     return [];
        // }
        filter.forEach(item => {
            // if (!item.value) {
            //     return;
            // }
            if (item.type !== ParamsFilterTypeEnum.NULLABLE && item.value === null) {
                return;
            }

            if (item.value && item.relationTrue) {
                item.relationTrue.forEach(rel => result.push(rel));
            } else if (!item.value && item.relationFalse) {
                item.relationFalse.forEach(rel => result.push(rel));
            } else if (!item.relationFalse && !item.relationTrue) {
                result.push({property: item.property, value: item.value, type: item.type});
            }
        });

        const dateTimeFilterIndex = result.findIndex(f => f.type === ParamsFilterTypeEnum.DATETIME);
        if (dateTimeFilterIndex !== -1 && dateTime) {
            result[dateTimeFilterIndex].value = dateTime;
        }

        return result;
    }
}

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { DisabledPermissionDirectiveInterface } from '../_interfaces/permission-directive-data.interface';
import { RoleInterface } from '../_interfaces/role.interface';
import { Store } from '@ngxs/store';
import { PermissionsState } from '../_store/states/permissions.state';
import { PermissionService } from '../_services/permission.service';

@Directive({
    selector: '[appDisabledPermission]',
})
export class DisabledPermissionDirective {
    private _data: DisabledPermissionDirectiveInterface;
    roles: RoleInterface[];

    isPermissionAllowed = false;

    @Input('appDisabledPermission')
    set data(data: DisabledPermissionDirectiveInterface) {
        this._data = data;

        this.init();
    }

    get data(): DisabledPermissionDirectiveInterface {
        return this._data;
    }

    constructor(
        private store: Store,
        private permissionService: PermissionService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    async init(): Promise<void> {
        const result = [];
        this.roles = this.store.selectSnapshot(PermissionsState.getRoles);
        this.data.registrator.forEach((registrator) => {
            const currentRole = this.roles.find((f) => f.id === registrator.roleId);

            const isCheck = this.permissionService.checkPermission(
                currentRole?.permissions,
                {
                    registrator: registrator,
                    action: this.data.action,
                    permissions: this.data.permissions,
                },
                true,
            );

            if (!registrator.roleId) {
                result.push(true);
            }

            if (currentRole && isCheck) {
                result.push(true);
            }
        });

        if (!this.isPermissionAllowed) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }

        if (result && result.length) {
            this.isPermissionAllowed = true;
        } else {
            this.viewContainer.clear();
        }
    }
}

import { MnemonicRowInterface } from '../../_interfaces/mnemonic-row.interface';

export class CreateMnemonic {
    static readonly type = '[MnemonicDashboard] CreateMnemonic';

    constructor(public row: MnemonicRowInterface) {}
}

export class InitMnemonicRow {
    static readonly type = '[MnemonicDashboard] InitMnemonicRow';
}

export class UpdateMnemonicRow {
    static readonly type = '[MnemonicDashboard] UpdateMnemonicRow';

    constructor(public row: MnemonicRowInterface) {}
}

export class CreateNewMnemonic {
    static readonly type = '[MnemonicDashboard] CreateNewMnemonic';
}

export class SetEditingMnemonicRow {
    static readonly type = '[MnemonicDashboard] SetEditingMnemonicRow';

    constructor(public id: string) {}
}

export class CloseEditingMnemonicRow {
    static readonly type = '[MnemonicDashboard] CloseEditingMnemonicRow';

    constructor(public id: string, public savedRow?: any) {}
}

export class GetMnemonicComment {
    static readonly type = '[MnemonicDashboard] GetMnemonicComment';

    constructor(public registratorIds: string[]) {}
}

export class UpdateMnemonicComment {
    static readonly type = '[MnemonicDashboard] UpdateMnemonicComment';

    constructor(public data: { id: string; description: string }) {}
}

import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import {
    AddNewAdmin,
    ChangeAdminActivity,
    CloseEditingAdminsRows,
    CreateAdmin,
    DeleteAdmin,
    GetAdmins,
    GetAdminsRows,
    SetEditingAdminsRows,
    UpdateAdmin,
} from '../actions/admins-list.actions';
import { ApiResponse } from '../../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../../app-shared-elements/_enums/status.enum';
import { AdminsListRowsInterface } from '../../_interface/admins-list-rows.interface';
import { AdminsService } from '../../_services/admins.service';
import { NotificationsService } from '../../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../../app-shared-elements/_enums/tooltip-status.enum';
// import {AdminInterface} from '../../_interface/admins-list.interface';
import { DataTypeService } from '../../../../app-shared-elements/_services/data-type.service';
import { PermissionsState } from 'src/app/app-shared-elements/_store/states/permissions.state';
import { AdminInterface } from '../../_interface/admins-list.interface';
import { DisabledTypeEnum } from 'src/app/app-shared-elements/_enums/disabled-type.enum';
import { UserState } from '../../../../app-shared-elements/_store/states/user.state';
import { ColumnsActionTypeEnum } from '../../../../app-shared-elements/_enums/columns-action-type.enum';

export interface AdminsListStateModel {
    admins: AdminInterface[];
    adminsRows: AdminsListRowsInterface[];
    isEditingMode: boolean;
}

const ADMINS_LIST_TOKEN = new StateToken<AdminsListStateModel>('adminsList');

const ROOT_ROLE = 'Root';

@State({
    name: ADMINS_LIST_TOKEN,
    defaults: {
        admins: [],
        adminsRows: [],
        isEditingMode: false,
    },
})
@Injectable()
export class AdminsListState {
    constructor(
        private http: HttpClient,
        private notificationService: NotificationsService,
        private dateTypeService: DataTypeService,
        private store: Store,
        private adminsService: AdminsService,
    ) {}

    @Selector()
    static getAdmins(state: AdminsListStateModel): AdminInterface[] {
        return state.admins;
    }

    @Selector()
    static getAdminsRows(state: AdminsListStateModel): AdminsListRowsInterface[] {
        return state.adminsRows;
    }

    @Selector()
    static getIsEditingMode(state: AdminsListStateModel): boolean {
        return state.isEditingMode;
    }

    @Action(GetAdmins)
    async getAdmins(ctx: StateContext<AdminsListStateModel>): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .get('api/control/admins')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                admins: result.data,
            });
        }
    }

    @Action(GetAdminsRows)
    async getAdminsRows(ctx: StateContext<AdminsListStateModel>): Promise<void> {
        await ctx.dispatch(new GetAdmins()).toPromise();
        const state = ctx.getState();
        // const user = this.store.selectSnapshot(UserState.getUser);
        // const placegolderStr: string = await this.translateService.get('admin.adminsList.placeholder').toPromise().catch(e => console.log(e));
        const roles = this.store.selectSnapshot(PermissionsState.getRoles);
        if (!roles || !roles.length) {
            ctx.setState({
                ...state,
            });

            return;
        }
        // console.log(this.store.selectSnapshot(UserState.getUser));

        ctx.setState({
            ...state,
            adminsRows: state.admins
                .map((admin) => {
                    const currentRole = roles.find((r) => r.id === admin.roleId);
                    // console.log(admin);
                    return {
                        ...admin,
                        id: admin.id,
                        // userId: user.id,
                        isActive: admin.isActive,
                        email: admin.login,
                        name: admin.name,
                        role: currentRole.name,
                        selectOptions: this.adminsService.getAdminRolesOptions(roles, ROOT_ROLE),
                        password: '',
                        created: admin.created,
                        update: new Date(admin.updated).getTime(),
                        placeholder: this.dateTypeService.reformatShortDate(new Date(admin.created).getTime()),
                        isReadOnly: admin.login !== null,
                        isHideActiveIcon: admin?.id === this.store.selectSnapshot(UserState.getUser)?.id,
                        require: {
                            email: true,
                            name: true,
                            password: true,
                        },
                        disabledType: DisabledTypeEnum.adminsListTable,
                        disabledActions:
                            admin?.id === this.store.selectSnapshot(UserState.getUser)?.id ? [ColumnsActionTypeEnum.actionBtnsDelete] : [],
                    };
                })
                .sort((a, b) => (new Date(b.created).getTime() > new Date(a.created).getTime() ? 1 : -1)),
        });
    }

    @Action(AddNewAdmin)
    async addNewAdmin(ctx: StateContext<AdminsListStateModel>): Promise<void> {
        const state = ctx.getState();
        const roles = this.store.selectSnapshot(PermissionsState.getRoles);

        const emptyRows: AdminsListRowsInterface = {
            isActive: true,
            email: '',
            name: '',
            role: '',
            roleId: '',
            selectOptions: this.adminsService.getAdminRolesOptions(roles, ROOT_ROLE),
            password: '',
            isNew: true,
            isEditing: true,
            require: {
                email: true,
                name: true,
                password: true,
            },
            login: '',
            phone: '',
            dateFormat: '',
            dateTimeZone: '',
            statusTwoFactorAuthentication: null,
            isNeedChangePassword: null,
            timeBlocked: null,
            sessionLife: null,
            created: null,
            updated: null,
            disabledType: DisabledTypeEnum.adminsListTable,
        };

        ctx.setState({
            ...state,
            adminsRows: [emptyRows, ...state.adminsRows],
            isEditingMode: true,
        });
    }

    @Action(CreateAdmin)
    async createAdmin(ctx: StateContext<AdminsListStateModel>, payload: CreateAdmin): Promise<void> {
        const state = ctx.getState();
        const roles = this.store.selectSnapshot(PermissionsState.getRoles);
        // const currentRole = roles.find(r => r.name === payload.data.roleId);

        const result: ApiResponse = (await this.http
            .post('api/control/admins', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                admins: [result.data, ...state.admins],
                adminsRows: [{ ...result.data, isEditing: false }, ...state.adminsRows.filter((r) => !r.isNew)],
                isEditingMode: false,
            });

            await ctx.dispatch(GetAdminsRows).toPromise();
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(UpdateAdmin)
    async updateAdmin(ctx: StateContext<AdminsListStateModel>, payload: UpdateAdmin): Promise<void> {
        const result: ApiResponse = (await this.http
            .post('api/control/admins', payload.data)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        const roles = this.store.selectSnapshot(PermissionsState.getRoles);

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                admins: state.admins.map((a) => (a.id === result.data.id ? result.data : a)),
                adminsRows: state.adminsRows.map((r) => {
                    if (r.id === result.data.id) {
                        return {
                            ...result.data,
                            id: result.data.id,
                            // userId: user.id,
                            isActive: result.data.isActive,
                            email: result.data.login,
                            name: result.data.name,
                            role: result.data.role ? result.data.nameRole : null,
                            selectOptions: this.adminsService.getAdminRolesOptions(roles, ROOT_ROLE),
                            password: '',
                            created: result.data.created,
                            update: result.data.update,
                            placeholder: this.dateTypeService.reformatShortDate(result.data.created),
                        };
                    }

                    return r;
                }),
                isEditingMode: false,
            });

            await ctx.dispatch(GetAdminsRows).toPromise();
        } else {
            // this.notificationService.onEmit(TooltipStatusEnum.error, false);
            ctx.setState({
                ...state,
                adminsRows: state.adminsRows.map((r) => ({ ...r, isEditing: false })),
                isEditingMode: false,
            });
        }
    }

    @Action(DeleteAdmin)
    async deleteAdmin(ctx: StateContext<AdminsListStateModel>, payload: DeleteAdmin): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .delete('api/control/admins', {
                params: {
                    id: payload.id,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                admins: state.admins.filter((r) => r.id !== payload.id),
                adminsRows: state.adminsRows.filter((r) => r.id !== payload.id),
            });
        }
    }

    @Action(ChangeAdminActivity)
    async changeAdminActivity(ctx: StateContext<AdminsListStateModel>, payload: ChangeAdminActivity): Promise<void> {
        const state = ctx.getState();

        const result: ApiResponse = (await this.http
            .post('/api/control/admins/change-active', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
            });
        }

        ctx.dispatch(GetAdminsRows);
    }

    @Action(CloseEditingAdminsRows)
    closeEditingAdminsRows(ctx: StateContext<AdminsListStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            adminsRows: state.adminsRows.map((row) => ({ ...row, isEditing: false })),
            isEditingMode: false,
        });

        ctx.dispatch(GetAdminsRows);
    }

    @Action(SetEditingAdminsRows)
    setEditingAdminsRows(ctx: StateContext<AdminsListStateModel>, payload: SetEditingAdminsRows): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            adminsRows: state.adminsRows.map((row) => ({ ...row, isEditing: row.id === payload.id })),
            isEditingMode: true,
        });
    }
}

import {ParamsFilterForClient, ParamsFilterTypeEnum} from 'src/app/app-shared-elements/_interfaces/params.interface';
import {StatusNotificationShare} from '../_enums/status-notification-share.enum';

export const mailingRecipientCheckboxes: ParamsFilterForClient[] = [
    {
        title: 'filters.mailingUsersFilter.act',
        property: 'isActive',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'isActive',
                value: true,
                type: ParamsFilterTypeEnum.BOOLEAN,
            }
        ]
    },
    {
        title: 'filters.mailingUsersFilter.ackn',
        property: 'status',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'status',
                value: StatusNotificationShare.ACCEPTED,
                type: ParamsFilterTypeEnum.TEXT,
            }
        ]
    },
    {
        title: 'filters.mailingUsersFilter.rejected',
        property: 'status',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'status',
                value: StatusNotificationShare.REJECT,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ]
    },
    {
        title: 'filters.mailingUsersFilter.wait',
        property: 'status',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'status',
                value: StatusNotificationShare.PROCESS,
                type: ParamsFilterTypeEnum.TEXT,
            }
        ]
    }
];

import { Injectable } from '@angular/core';
import { VariableChangeArchive } from '../_interfaces/Variable';

@Injectable({
    providedIn: 'root',
})
export class LimitService {
    constructor() {}

    isLimitByHours(archivePeriods: VariableChangeArchive[], horus: number) {
        const start = Date.now() - horus;
        const end = Date.now();

        return this.isLimit(archivePeriods, start, end);
    }

    isLimit(archivePeriods: VariableChangeArchive[], start: number, end: number) {
        const period: number = end - start;
        const periodValue: number = period / 800;

        const actualArchivePeriods: VariableChangeArchive[] = this.getArchivePeriodsByRange(archivePeriods, start, end);

        const valuesArchivePeriod: number[] = [...new Set(actualArchivePeriods.map((item) => +item.value))];
        const minArchivePeriod: number = Math.min(...valuesArchivePeriod);

        const normal: number = period / 1000 / minArchivePeriod;

        return periodValue / 1000 > minArchivePeriod * 2 || normal >= 800;
    }

    getArchivePeriodsByRange(archivePeriods: VariableChangeArchive[], start: number, end: number): VariableChangeArchive[] {
        const actualArchivePeriods: VariableChangeArchive[] = [];
        let isNotIncludeStart = null;
        let isNotIncludeEnd = null;

        archivePeriods.sort((a, b) => +a.ts - +b.ts);

        for (let i = 0; i < archivePeriods.length; i++) {
            const changeTime = +archivePeriods[i].ts;
            if (changeTime >= start && changeTime <= end) {
                actualArchivePeriods.push(archivePeriods[i]);
            } else if (changeTime < start) {
                if (!isNotIncludeStart || changeTime > +isNotIncludeStart?.ts) {
                    isNotIncludeStart = archivePeriods[i];
                }
            } else if (end > changeTime) {
                isNotIncludeEnd = archivePeriods[i];
                break;
            }
        }

        if (isNotIncludeStart) {
            actualArchivePeriods.unshift(isNotIncludeStart);
        }

        if (isNotIncludeEnd) {
            actualArchivePeriods.push(isNotIncludeEnd);
        }

        if (actualArchivePeriods.length === 0) {
            actualArchivePeriods.push(archivePeriods[archivePeriods.length - 1]);
        }

        actualArchivePeriods.sort((a, b) => +a.ts - +b.ts);
        return actualArchivePeriods;
    }
}

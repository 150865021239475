import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { VariablesNameEnum } from 'src/app/app-shared-elements/_enums/variables-name.enum';
import { AlarmTypeEnum } from 'src/app/events/_enums/alarm.enum';
import { EventMessage } from 'src/app/events/_interfaces/EventMessage';
import { LogicalEventRowInterface } from 'src/app/events/_interfaces/LogicalEventRow';
import { GPS } from 'src/app/groups/container-registrators/device-map/_enum/gps.enum';
import { UserRegistratorInterface } from 'src/app/device-dashboard/user-devices/_interface/user-devices.interface';
import { DevicesState } from 'src/app/device-dashboard/_store/states/user-devices.state';
import { ReportRowInterface } from 'src/app/reports/_interfaces/ReportRow';
import { DeviceTypeEnum } from '../_enums/device-type.enum';
import { FilterTypePipeEnum } from '../_enums/filter-type-pipe.enum';
import { FilterPipeArgs } from '../_interfaces/FilterPipeArgs';
import { Group } from '../_interfaces/Group';
import { MailingGroupRows } from '../../mailing/_interfaces/mailing-groups.interfaces';
import { TableService } from '../_services/table.service';
import { EventModeEnum, LogicEventType } from 'src/app/events/logical-events/_interface/LogicEvent';
import { DeletedDevicesRowsInterface } from '../../deleted-devices/_interfaces/deleted-devices-rows.interface';
import { SelectOptionInterface } from '../_interfaces/select-option.interface';
import { Variable } from '../_interfaces/Variable';
import { Device } from '../_interfaces/Device';
import { MailingAddUserItemInterface } from '../../mailing/_interfaces/mailing-groups-edit.interface';
import { RegistratorTypeEnum } from '../_enums/registrator-type.enum';

@Pipe({
    name: 'filterBySmth',
})
export class FilterPipe implements PipeTransform {
    constructor(
        private store: Store,
        private tableService: TableService,
    ) {}

    transform(value: any[], args: FilterPipeArgs, data?: any): any[] {
        if (!args.type) {
            return value;
        }

        switch (args.type) {
            case FilterTypePipeEnum.variablesForTransportSelect:
                return this.filterVariablesForTransportSelect(value, args.comparedValue);
            case FilterTypePipeEnum.groupByRegistratorId:
                return this.filterGroupsByRegistratorId(value, args.comparedValue);
            case FilterTypePipeEnum.variablesForExpressionSelect:
                return this.filterVariablesForExpressionSelect(value, args.comparedValue);
            case FilterTypePipeEnum.variablesForExpressionSelectDevice:
                return this.variablesForExpressionSelectDevice(value, args.comparedValue);
            case FilterTypePipeEnum.logicalEventsRowByRegistratorId:
                return this.filterLogicalEventsRowByRegistratorId(value, args.comparedValue);
            case FilterTypePipeEnum.groupsByGPSMode:
                return this.filterGroupsByGPSMode(value, args.comparedValue);
            case FilterTypePipeEnum.logicalMessagesByRegistratorId:
                return this.filterLogicalMessagesByRegistratorId(value, args.comparedValue);
            case FilterTypePipeEnum.filterDeviceByRegistratorId:
                return this.filterDeviceByRegistratorId(value, args.comparedValue);
            case FilterTypePipeEnum.filterDeviceByOwner:
                return this.filterDeviceByOwner(value, args.comparedValue);
            case FilterTypePipeEnum.filterDeviceByConnect:
                return this.filterDeviceByConnect(value, args.comparedValue);
            case FilterTypePipeEnum.filterDeviceByStatus:
                return this.filterDeviceByStatus(value, args.comparedValue);
            case FilterTypePipeEnum.filterDeviceByCompany:
                return this.filterDeviceByCompany(value, args.comparedValue);
            case FilterTypePipeEnum.filterReportsByName:
                return this.filterReportsByName(value, args.comparedValue);
            case FilterTypePipeEnum.filterReportsByOwner:
                return this.filterReportsByOwner(value, args.comparedValue);
            case FilterTypePipeEnum.filterReportsByGroup:
                return this.filterReportsByGroup(value, args.comparedValue);
            case FilterTypePipeEnum.filterOptionsByValue:
                return this.filterOptionsByValue(value, args.comparedValue);
            case FilterTypePipeEnum.filterMailingGroupByValue:
                return this.filterMailingGroupByValue(value, args.comparedValue);
            case FilterTypePipeEnum.filterMailingGroupByNameOfGroup:
                return this.filterMailingGroupByNameOfGroup(value, args.comparedValue);
            case FilterTypePipeEnum.filterMailingGroupByName:
                return this.filterMailingGroupByName(value, args.comparedValue);
            case FilterTypePipeEnum.filterDeviceLogByRegistratorName:
                return this.filterDeviceLogByRegistratorName(value, args.comparedValue);
            case FilterTypePipeEnum.filterLogicalEventByName:
                return this.filterLogicalEventByName(value, args.comparedValue);
            case FilterTypePipeEnum.filterLogicalEventByStatus:
                return this.filterLogicalEventByStatus(value, args.comparedValue);
            case FilterTypePipeEnum.filterLogicalEventByRegistratorId:
                return this.filterLogicalEventByRegistratorId(value, args.comparedValue);
            case FilterTypePipeEnum.filterMessageCatalogByName:
                return this.filterMessageCatalogByName(value, args.comparedValue);
            case FilterTypePipeEnum.filterMessageCatalogByRegistratorId:
                return this.filterMessageCatalogByRegistratorId(value, args.comparedValue);
            case FilterTypePipeEnum.filterDeletedDeviceById:
                return this.filterDeletedDeviceById(value, args.comparedValue);
            case FilterTypePipeEnum.filterCopyConfigurator:
                return this.filterCopyConfigurator(value, args.comparedValue);
            case FilterTypePipeEnum.filterConstructorType:
                return this.filterConstructorType(value, args.comparedValue);
            case FilterTypePipeEnum.filterHideCoordinatorForGroups:
                return this.filterHideCoordinatorForGroups(value, args.comparedValue);
            case FilterTypePipeEnum.filterHideRegistratorForGroups:
                return this.filterHideRegistratorForGroups(value, args.comparedValue);
            case FilterTypePipeEnum.logicalEventFilterByMultiEventActive:
                return this.logicalEventFilterByMultiEventActive(value);
            // case FilterTypePipeEnum.filterNavigation:
            //     return this.filterNavigation(value);
            default:
                return value;
        }
    }

    private filterVariablesForTransportSelect(
        value: SelectOptionInterface<string, string, Variable>[],
        comparedValue,
    ): SelectOptionInterface<string, string, Variable>[] {
        return value.filter((item) => item.property.deviceId === comparedValue);
    }

    private filterGroupsByRegistratorId(groups: Group[], comparedValue: string): Group[] {
        if (!comparedValue) {
            return groups;
        }

        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        return groups.filter((group) => {
            const currentDevice = devices.find((device) => device.id === group.deviceId);
            if (
                currentDevice &&
                comparedValue === (currentDevice.type === DeviceTypeEnum.registrator ? currentDevice.id : currentDevice.registratorId)
            ) {
                return group;
            }
        });
    }

    private filterVariablesForExpressionSelect(
        values: SelectOptionInterface<string, string, Variable>[],
        comparedValue: string,
    ): SelectOptionInterface<string, string, Variable>[] {
        if (!comparedValue) {
            return values;
        }
        const ids = [];
        this.store.selectSnapshot(DevicesState.getDevices).forEach((device) => {
            if (device.type === DeviceTypeEnum.registrator && device.id === comparedValue) {
                ids.push(device.id);
            }

            if (device.type !== DeviceTypeEnum.registrator && device.registratorId === comparedValue) {
                ids.push(device.id);
            }
        });

        return values.filter((value) => ids.includes(value.property.deviceId));
    }

    private variablesForExpressionSelectDevice(
        values: SelectOptionInterface<string, string, Variable>[],
        comparedValue: string,
    ): SelectOptionInterface<string, string, Variable>[] {
        if (!comparedValue) {
            return values;
        }
        const ids = [];
        this.store.selectSnapshot(DevicesState.getDevices).forEach((device) => {
            if (device.id === comparedValue) {
                ids.push(device.id);
            }
        });

        return values.filter((value) => ids.includes(value.property.deviceId));
    }

    private filterLogicalEventsRowByRegistratorId(values: LogicalEventRowInterface[], comparedValue: string): LogicalEventRowInterface[] {
        if (!comparedValue) {
            this.tableService.rowsLengthAfterFilter.emit(
                values.filter((e) => !!e && (!e.logicEventType || e.logicEventType === LogicEventType.default)).length,
            );
            return values;
        }

        const result = values.filter((value) => value.registratorId === comparedValue);
        this.tableService.rowsLengthAfterFilter.emit(
            result.filter((e) => !!e && (!e.logicEventType || e.logicEventType === LogicEventType.default)).length,
        );
        return result;
    }

    private filterGroupsByGPSMode(values: Group[], comparedValue: GPS): Group[] {
        const result = values.filter((group) => {
            const currentRegistrator = this.store.selectSnapshot(DevicesState.getRegistrators).find((r) => r.id === group.deviceId);
            if (
                currentRegistrator &&
                currentRegistrator.variables.find((v) => v.name === VariablesNameEnum.GPSMode) &&
                +currentRegistrator.variables.find((v) => v.name === VariablesNameEnum.GPSMode).currentValue !== comparedValue
            ) {
                return group;
            }
        });
        return result;
    }

    private filterLogicalMessagesByRegistratorId(values: EventMessage[], comparedValue: string): EventMessage[] {
        if (!comparedValue) {
            return values;
        }

        const result = values.filter((v) => v.registratorId === comparedValue);

        return result;
    }

    private filterDeviceByRegistratorId(values: UserRegistratorInterface[], comparedValue: any): UserRegistratorInterface[] {
        if (!comparedValue || !comparedValue.length) {
            this.tableService.setEmptyTableAfterFilter(false);
            this.tableService.rowsLengthAfterFilter.emit(values.length);
            return values;
        }

        const result = values.filter((item) => item.registrator.id === comparedValue);

        if (!result.length) {
            this.tableService.setEmptyTableAfterFilter(true);
            return [];
        }
        this.tableService.rowsLengthAfterFilter.emit(result.length);
        this.tableService.setEmptyTableAfterFilter(false);
        return result;
    }

    private filterDeviceByOwner(values: UserRegistratorInterface[], comparedValue: string): UserRegistratorInterface[] {
        if (!comparedValue || !comparedValue.length) {
            this.tableService.setEmptyTableAfterFilter(false);
            this.tableService.rowsLengthAfterFilter.emit(values.length);
            return values;
        }
        const result = values.filter((item) => {
            if (item.registrator.login.toLocaleLowerCase().includes(comparedValue.toLowerCase())) {
                return item;
            }
        });

        if (!result.length) {
            this.tableService.setEmptyTableAfterFilter(true);
            return [];
        }
        this.tableService.rowsLengthAfterFilter.emit(result.length);
        this.tableService.setEmptyTableAfterFilter(false);
        return result;
    }

    private filterDeviceByConnect(values: UserRegistratorInterface[], comparedValue: boolean): UserRegistratorInterface[] {
        if (comparedValue === null || comparedValue === undefined) {
            this.tableService.setEmptyTableAfterFilter(false);
            this.tableService.rowsLengthAfterFilter.emit(values.length);
            return values;
        }

        const result = values.filter((item) => {
            if (item.registrator.isConnect === comparedValue) {
                return values;
            }
        });

        if (!result.length) {
            this.tableService.setEmptyTableAfterFilter(true);
            return [];
        }
        this.tableService.rowsLengthAfterFilter.emit(result.length);
        this.tableService.setEmptyTableAfterFilter(false);
        return result;
    }

    private filterDeviceByStatus(values: UserRegistratorInterface[], comparedValue: AlarmTypeEnum): UserRegistratorInterface[] {
        if (comparedValue === null || comparedValue === undefined) {
            this.tableService.setEmptyTableAfterFilter(false);
            this.tableService.rowsLengthAfterFilter.emit(values.length);
            return values;
        }
        const result = values.filter((v) => {
            if (v.registrator.status === comparedValue || v.devices.find((d) => d.status === comparedValue)) {
                return {
                    ...v,
                };
            }
        });

        if (!result.length) {
            this.tableService.setEmptyTableAfterFilter(true);
            return [];
        }
        this.tableService.rowsLengthAfterFilter.emit(result.length);
        this.tableService.setEmptyTableAfterFilter(false);
        return result;
    }

    private filterDeviceByCompany(values: UserRegistratorInterface[], comparedValue: string): UserRegistratorInterface[] {
        if (comparedValue === null || comparedValue === undefined) {
            this.tableService.setEmptyTableAfterFilter(false);
            this.tableService.rowsLengthAfterFilter.emit(values.length);
            return values;
        }
        const result = values.filter((v) => {
            if (v.registrator.companyId === comparedValue || v.devices.find((d) => d.companyId === comparedValue)) {
                return {
                    ...v,
                };
            }
        });

        if (!result.length) {
            this.tableService.setEmptyTableAfterFilter(true);
            return [];
        }
        this.tableService.rowsLengthAfterFilter.emit(result.length);
        this.tableService.setEmptyTableAfterFilter(false);
        return result;
    }

    private filterReportsByName(values: ReportRowInterface[], comparedValue: string): ReportRowInterface[] {
        if (!comparedValue || !comparedValue.length) {
            this.tableService.rowsLengthAfterFilter.emit(values.length);
            return values;
        }
        const result = values.filter((value) => value.name.toLowerCase().includes(comparedValue.toLocaleLowerCase()));
        this.tableService.rowsLengthAfterFilter.emit(result.length);
        return result;
    }

    private filterReportsByOwner(values: ReportRowInterface[], comparedValue: string): ReportRowInterface[] {
        if (!comparedValue || !comparedValue.length) {
            this.tableService.rowsLengthAfterFilter.emit(values.length);
            return values;
        }
        const result = values.filter((value) => value.owner.toLowerCase().includes(comparedValue.toLocaleLowerCase()));
        this.tableService.rowsLengthAfterFilter.emit(result.length);
        return result;
    }

    private filterReportsByGroup(values: ReportRowInterface[], comparedValue: string): ReportRowInterface[] {
        if (!comparedValue || !comparedValue.length) {
            this.tableService.rowsLengthAfterFilter.emit(values.length);
            return values;
        }
        const result = values.filter((value) => value.deviceId.toLowerCase().includes(comparedValue.toLocaleLowerCase()));
        this.tableService.rowsLengthAfterFilter.emit(result.length);
        return result;
    }

    private filterOptionsByValue(options: any[], comparedValue: string): any[] {
        if (!comparedValue || !comparedValue.length) {
            return options;
        }

        return options.filter((option) => {
            if (option.value.toLowerCase().includes(comparedValue.toLowerCase())) {
                return option;
            }
        });
    }

    private filterMailingGroupByValue(values: MailingAddUserItemInterface[], comparedValue: string): any {
        if (!comparedValue) {
            return values;
        }

        return values.filter((value) =>
            value?.email?.toLowerCase().includes(comparedValue.toLowerCase())
                ? value?.email?.toLowerCase().includes(comparedValue.toLowerCase())
                : value?.name?.toLowerCase().includes(comparedValue.toLowerCase()),
        );
    }

    private filterMailingGroupByNameOfGroup(values: MailingGroupRows[], comparedValue: string): MailingGroupRows[] {
        if (!comparedValue || !comparedValue.length) {
            this.tableService.rowsLengthAfterFilter.emit(values.length);
            return values;
        }
        const result = values.filter((value) => value.name.toLowerCase().includes(comparedValue.toLowerCase()));
        this.tableService.rowsLengthAfterFilter.emit(result.length);
        return result;
    }

    private filterMailingGroupByName(values: MailingGroupRows[], comparedValue: string): MailingGroupRows[] {
        if (!comparedValue || !comparedValue.length) {
            this.tableService.rowsLengthAfterFilter.emit(values.length);
            return values;
        }
        const result = values.filter((value) => value.users.toLowerCase().includes(comparedValue.toLocaleLowerCase()));
        this.tableService.rowsLengthAfterFilter.emit(result.length);
        return result;
    }

    private filterDeviceLogByRegistratorName(values: any[], comparedValue: string): any[] {
        if (!comparedValue) {
            return values;
        }
        const ids = [];
        this.store.selectSnapshot(DevicesState.getDevices).forEach((device) => {
            if (device.id === comparedValue) {
                ids.push(device.id);
            }
        });

        return values.filter((value) => ids.includes(value.id));
    }

    private filterLogicalEventByName(values: LogicalEventRowInterface[], comparedValue: string): LogicalEventRowInterface[] {
        if (!comparedValue || !comparedValue.length) {
            this.tableService.rowsLengthAfterFilter.emit(
                values.filter((e) => !!e && (!e.logicEventType || e.logicEventType === LogicEventType.default)).length,
            );

            return values;
        }

        const result = values.filter((value) => value.name.toLowerCase().includes(comparedValue.toLocaleLowerCase()));
        this.tableService.rowsLengthAfterFilter.emit(
            result.filter((e) => !!e && (!e.logicEventType || e.logicEventType === LogicEventType.default)).length,
        );

        return result;
    }

    private filterLogicalEventByRegistratorId(values: LogicalEventRowInterface[], comparedValue: string): LogicalEventRowInterface[] {
        if (!comparedValue || !comparedValue.length) {
            this.tableService.rowsLengthAfterFilter.emit(
                values.filter((e) => !!e && (!e.logicEventType || e.logicEventType === LogicEventType.default)).length,
            );
            return values;
        }

        const result = values.filter((value) => value.registratorId === comparedValue);
        this.tableService.rowsLengthAfterFilter.emit(
            result.filter((e) => !!e && (!e.logicEventType || e.logicEventType === LogicEventType.default)).length,
        );

        return result;
    }

    private filterLogicalEventByStatus(values: LogicalEventRowInterface[], comparedValue: string): LogicalEventRowInterface[] {
        if (!comparedValue || !comparedValue.length) {
            this.tableService.rowsLengthAfterFilter.emit(
                values.filter((e) => !!e && (!e.logicEventType || e.logicEventType === LogicEventType.default)).length,
            );
            return values;
        }

        const result = values.filter((f) => f.state.includes(comparedValue));
        this.tableService.rowsLengthAfterFilter.emit(
            result.filter((e) => !!e && (!e.logicEventType || e.logicEventType === LogicEventType.default)).length,
        );

        return result;
    }

    private filterMessageCatalogByName(values: EventMessage[], comparedValue: string): EventMessage[] {
        if (!comparedValue) {
            return values;
        }
        const result = values.filter((value) => value.message.toLowerCase().includes(comparedValue.toLocaleLowerCase()));
        this.tableService.rowsLengthAfterFilter.emit(result.length);
        return result;
    }

    private filterMessageCatalogByRegistratorId(values: EventMessage[], comparedValue: string): EventMessage[] {
        if (!comparedValue) {
            return values;
        }
        const result = values.filter((value) => value.registratorId === comparedValue);
        this.tableService.rowsLengthAfterFilter.emit(result.length);
        return result;
    }

    private filterDeletedDeviceById(values: DeletedDevicesRowsInterface[], comparedValue: string): DeletedDevicesRowsInterface[] {
        if (!comparedValue) {
            return values;
        }

        return values.filter((value) => value.id === comparedValue);
    }

    private filterConstructorType(
        values: SelectOptionInterface<EventModeEnum, string>[],
        comparedValue: SelectOptionInterface<string, string, Device>,
    ): SelectOptionInterface<EventModeEnum, string>[] {
        if (!comparedValue) {
            return values;
        }

        return values.map((value) => {
            return {
                ...value,
                disabled: value.key === EventModeEnum.advance && comparedValue.property.registratorType === RegistratorTypeEnum.docker,
            };
        });
    }

    private filterCopyConfigurator(
        values: SelectOptionInterface<string, string, Device>[],
        comparedValue: SelectOptionInterface<string, string, Device>,
    ): SelectOptionInterface<string, string, Device>[] {
        if (!comparedValue) {
            return values;
        }

        if (comparedValue?.property.type === DeviceTypeEnum.datalogger || comparedValue?.property.type === DeviceTypeEnum.sensor) {
            return values
                .map((value) => {
                    if (value.property.type === DeviceTypeEnum.registrator) {
                        return {
                            ...value,
                            disabled: true,
                        };
                    }

                    if (value.property.type === comparedValue?.property.type) {
                        return value;
                    }
                })
                .filter((f) => !!f)
                .filter((value) => value.key !== comparedValue.key);
        }

        if (comparedValue?.property?.type === DeviceTypeEnum.registrator) {
            return values.filter((value) => value.property.type === DeviceTypeEnum.registrator && value.key !== comparedValue.key);
        }

        return values.filter((value) => value.key !== comparedValue.key);
    }

    private filterHideCoordinatorForGroups(values: Group[], comparedValue: boolean): Group[] {
        if (!comparedValue) {
            const devices = this.store.selectSnapshot(DevicesState.getDevices);

            return values.filter((value) => {
                const currentDevice = devices.find((d) => d.id === value.deviceId);
                if (currentDevice.type !== DeviceTypeEnum.coordinator) {
                    return value;
                }
            });
        }

        return values;
    }

    private filterHideRegistratorForGroups(values: Group[], comparedValue: boolean): Group[] {
        if (!comparedValue) {
            const devices = this.store.selectSnapshot(DevicesState.getDevices);

            return values.filter((value) => {
                const currentDevice = devices.find((d) => d.id === value.deviceId);
                if (currentDevice.type !== DeviceTypeEnum.registrator) {
                    return value;
                }
            });
        }

        return values;
    }

    private logicalEventFilterByMultiEventActive(values: LogicalEventRowInterface[]): LogicalEventRowInterface[] {
        if (!values) {
            return;
        }

        return values.map((value) => {
            if (value.accordionChildRows?.length && value.isMainAcordion) {
                return {
                    ...value,
                    accordionChildRows: value.accordionChildRows.filter((e) => e.limitIsActive),
                };
            }
            return value;
        });
    }

    // private filterNavigation(values: NavigationItemInterface[]): NavigationItemInterface[] {
    //     const user = this.store.selectSnapshot(UserState.getUser);
    //     return values.map((value) => {
    //         if (!user?.available_devices || !user?.available_devices?.length) {
    //             return {
    //                 ...value,
    //                 children: value.children.filter((f) => !f.isAvailableDevices),
    //             };
    //         }
    //
    //         return value;
    //     });
    // }
}

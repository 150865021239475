import { Injectable } from '@angular/core';
import {
    ColumnsTableInterface,
    ColumnTypeEnum,
    ColumnValueAlignEnum,
    IconInterface,
} from '../../app-shared-elements/_interfaces/ColumnsTable';
import { DropdownFilterOptionInterface } from '../../app-shared-elements/filters/interfaces/filter-option.interface';
import { TypeFilterEnum } from '../../app-shared-elements/filters/enums/type-filter.enum';
import { Device } from '../../app-shared-elements/_interfaces/Device';
import { DeviceTypeEnum } from '../../app-shared-elements/_enums/device-type.enum';
import { TooltipSideEnum } from '../../app-shared-elements/_enums/tooltip-side.enum';
import { DeletedDeviceIconAction } from '../_interfaces/deleted-devices-rows.interface';
import { User } from '../../app-shared-elements/_interfaces/user.interface';

@Injectable({
    providedIn: 'root',
})
export class DeletedDevicesService {
    readonly DELETE_ON_PATH = './assets/design/icons/deleted-devices/delete-on.svg';
    readonly DELETE_OFF_PATH = './assets/design/icons/deleted-devices/delete-off.svg';

    columnTable: ColumnsTableInterface[] = [
        {
            title: 'devices.type',
            grow: false,
            small: true,
            type: ColumnTypeEnum.text,
            name: 'iconType',
            preIcons: true,
            isMultilang: true,
            styles: true,
            align: ColumnValueAlignEnum.center,
        },
        {
            title: 'devices.name',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'name',
            align: ColumnValueAlignEnum.left,
        },
        {
            title: 'deletedDevices.table.deleted',
            grow: false,
            small: false,
            maxWidth: '200px',
            minWidth: '200px',
            type: ColumnTypeEnum.date,
            name: 'deleted',
        },
        {
            title: 'devices.lastAct',
            grow: false,
            small: false,
            maxWidth: '200px',
            minWidth: '200px',
            type: ColumnTypeEnum.date,
            name: 'lastActive',
        },
        {
            title: 'deletedDevices.table.recovery',
            grow: false,
            small: true,
            maxWidth: '120px',
            minWidth: '100px',
            type: ColumnTypeEnum.text,
            name: 'action',
            preIcons: true,
            isClick: true,
            align: ColumnValueAlignEnum.center,
        },
    ];

    dropDownFilterOptions: DropdownFilterOptionInterface[] = [
        { key: 'registratorId', value: 'deletedDevices.dropdown.device', type: TypeFilterEnum.select, property: 'registratorId' },
    ];

    constructor() {}

    getPreIcons(device: Device, user: User, registrator?: Device): IconInterface<DeletedDeviceIconAction>[] {
        switch (device.type) {
            case DeviceTypeEnum.registrator:
                return [
                    {
                        path: 'assets/design/icons/deleted-devices/registrator.svg',
                        styles: {
                            fill: device.isActive ? '#0FA579' : '#AAAAAA',
                        },
                        cellNames: ['iconType'],
                    },
                    {
                        path: device.isDeleted && user.id === device.userId ? this.DELETE_ON_PATH : this.DELETE_OFF_PATH,
                        cellNames: ['action'],
                        action: DeletedDeviceIconAction.recovery,
                    },
                ];
            case DeviceTypeEnum.sensor:
                return [
                    {
                        path: device.isActive
                            ? './assets/design/icons/deleted-devices/sensor-on.svg'
                            : './assets/design/icons/deleted-devices/sensor-off.svg',
                        cellNames: ['iconType'],
                    },
                    {
                        path:
                            !registrator.isDeleted && device.isDeleted && user.id === device.userId
                                ? this.DELETE_ON_PATH
                                : this.DELETE_OFF_PATH,
                        cellNames: ['action'],
                        tooltip: !registrator.isDeleted && device.isDeleted ? '' : 'deletedDevices.tooltip',
                        tooltipSide: TooltipSideEnum.left,
                        action: DeletedDeviceIconAction.recovery,
                    },
                ];
            case DeviceTypeEnum.coordinator:
                return [
                    {
                        path: './assets/design/icons/deleted-devices/coordinator.svg',
                        styles: {
                            fill: device.isActive ? '#0FA579' : '#AAAAAA',
                        },
                        cellNames: ['iconType'],
                    },
                    {
                        path:
                            !registrator.isDeleted && device.isDeleted && user.id === device.userId
                                ? this.DELETE_ON_PATH
                                : this.DELETE_OFF_PATH,
                        cellNames: ['action'],
                        tooltip: !registrator.isDeleted && device.isDeleted ? '' : 'deletedDevices.tooltip',
                        tooltipSide: TooltipSideEnum.left,
                        action: DeletedDeviceIconAction.recovery,
                    },
                ];
            case DeviceTypeEnum.datalogger:
                return [
                    {
                        path: './assets/design/icons/deleted-devices/datalogger.svg',
                        styles: {
                            fill: device.isActive ? '#0FA579' : '#AAAAAA',
                        },
                        cellNames: ['iconType'],
                    },
                    {
                        path:
                            !registrator.isDeleted && device.isDeleted && user.id === device.userId
                                ? this.DELETE_ON_PATH
                                : this.DELETE_OFF_PATH,
                        cellNames: ['action'],
                        tooltip: !registrator.isDeleted && device.isDeleted ? '' : 'deletedDevices.tooltip',
                        tooltipSide: TooltipSideEnum.left,
                        action: DeletedDeviceIconAction.recovery,
                    },
                ];
        }
    }

    getConnectStyle(device: Device): any {
        if (device.isActive) {
            return {
                color: '#0FA579',
            };
        } else {
            return {
                color: '#aaa',
            };
        }
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { SafePipeEnum } from '../_enums/safe-pipe.enum';

@Pipe({
    name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
    constructor(protected sanitized: DomSanitizer) {}

    transform(value: any, type?: SafePipeEnum): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case SafePipeEnum.html:
                return this.sanitized.bypassSecurityTrustHtml(value);
            case SafePipeEnum.style:
                return this.sanitized.bypassSecurityTrustStyle(value);
            case SafePipeEnum.script:
                return this.sanitized.bypassSecurityTrustScript(value);
            case SafePipeEnum.url:
                return this.sanitized.bypassSecurityTrustUrl(value);
            case SafePipeEnum.resourceUrl:
                return this.sanitized.bypassSecurityTrustResourceUrl(value);
            default:
                return this.sanitized.bypassSecurityTrustHtml(value);
        }
    }
}

import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { DeviceKey, DeviceKeyDto } from '../../_interfaces/generation-key.interface';
import { DataTypeService } from '../../../app-shared-elements/_services/data-type.service';
import { ColumnsTableInterface, ColumnTypeEnum } from '../../../app-shared-elements/_interfaces/ColumnsTable';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { AcknPropertyBtnTypeEnum } from 'src/app/app-shared-elements/_interfaces/acknowldge-property.inteface';
import {
    CreateDeviceKeyRow,
    DeleteGenerationRow,
    DownloadKey,
    GetDeviceKey,
    GetDeviceKeyRows,
    InitDeviceKeyArray,
    RegenerationDeviceKey,
} from '../actions/generation-key.actions';

export interface GenerationKeyStateModel {
    deviceKeyArray: DeviceKey[];
    deviceKey: DeviceKey;
    generationKeyColumns: ColumnsTableInterface[];
    deviceKeyRows: DeviceKeyDto[];
    updateDeviceKey: DeviceKey;
}

const GENERATION_STATE_TOKEN = new StateToken<GenerationKeyStateModel>('generationKey');

const initGenerationKeyColumns: ColumnsTableInterface[] = [
    {
        title: 'generationKey.showKey',
        maxWidth: '160px',
        minWidth: '160px',
        grow: true,
        type: ColumnTypeEnum.text,
        name: 'key',
    },
    {
        title: 'generationKey.used',
        maxWidth: '160px',
        minWidth: '160px',
        grow: true,
        type: ColumnTypeEnum.text,
        name: 'used',
        isMultilang: true,
    },
    {
        title: 'generationKey.registratorId',
        grow: true,
        type: ColumnTypeEnum.text,
        name: 'registratorId',
    },
    {
        title: 'generationKey.registratorName',
        maxWidth: '280px',
        minWidth: '280px',
        grow: true,
        type: ColumnTypeEnum.text,
        name: 'registratorName',
    },
    {
        title: 'generationKey.key',
        maxWidth: '140px',
        minWidth: '140px',
        isBtnShow: true,
        type: ColumnTypeEnum.edit,
        // type: ColumnTypeEnum.acknowledge,
        name: 'btn',
    },
    {
        title: 'devices.actions',
        maxWidth: '100px',
        minWidth: '85px',
        type: ColumnTypeEnum.action,
        name: 'action',
        actionBtns: [ColumnsActionTypeEnum.actionBtnsDelete, ColumnsActionTypeEnum.isGenerationKey],
    },
];

@State<GenerationKeyStateModel>({
    name: GENERATION_STATE_TOKEN,
    defaults: {
        deviceKeyArray: [],
        deviceKeyRows: [],
        generationKeyColumns: initGenerationKeyColumns,
        deviceKey: null,
        updateDeviceKey: null,
    },
})
@Injectable()
export class GenerationKeyState {
    constructor(
        private notificationsService: NotificationsService,
        private dataTypeService: DataTypeService,
        private http: HttpClient,
        private store: Store,
    ) {}

    @Selector()
    static getDeviceKeyArray(state: GenerationKeyStateModel): DeviceKey[] {
        return state.deviceKeyArray;
    }

    @Selector()
    static getDeviceKey(state: GenerationKeyStateModel): DeviceKey {
        return state.deviceKey;
    }

    @Selector()
    static getGenerationKeyColumns(state: GenerationKeyStateModel): ColumnsTableInterface[] {
        return state.generationKeyColumns;
    }

    @Selector()
    static getDeviceKeyRow(state: GenerationKeyStateModel): DeviceKeyDto[] {
        return state.deviceKeyRows;
    }

    @Selector()
    static getCreateDeviceKey(state: GenerationKeyStateModel): DeviceKey {
        return state.updateDeviceKey;
    }

    @Action(InitDeviceKeyArray)
    async InitDeviceKeyArray(ctx: StateContext<GenerationKeyStateModel>): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('api/device-key')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                deviceKeyArray: result.data,
            });
        } else {
            ctx.setState({
                ...state,
                deviceKeyArray: [],
            });
        }
    }

    @Action(GetDeviceKey)
    async GetDeviceKey(ctx: StateContext<GenerationKeyStateModel>, action: GetDeviceKey): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .get(`api/device-key/${action.id}`)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                deviceKey: result.data,
            });
        }
    }

    @Action(GetDeviceKeyRows)
    async GetDeviceKeyRows(ctx: StateContext<GenerationKeyStateModel>): Promise<void> {
        const state = ctx.getState();

        const deviceKeyRows: DeviceKeyDto[] = [];

        state.deviceKeyArray.forEach((device) => {
            deviceKeyRows.push({
                id: device.id,
                registratorId:
                    !device.registratorId && !device.internalId
                        ? device.registratorId
                        : device.registratorId + ' ' + '/' + ' ' + device.internalId,
                used: device.lastUse ? this.dataTypeService.reformatDate(new Date(device.lastUse)) : 'generationKey.notUsed',
                key: device.key + '...',
                registratorName: device.registratorName,
                btn: {
                    ts: null,
                    isAcknowledgeable: false,
                    acknowledgedByUser: '',
                    btnType: AcknPropertyBtnTypeEnum.ligth,
                    title: 'btns.view',
                },
            });
        });

        ctx.setState({
            ...state,
            deviceKeyRows,
        });
    }

    @Action(CreateDeviceKeyRow)
    async CreateDeviceKeyRow(ctx: StateContext<GenerationKeyStateModel>): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .post(`api/device-key`, {})
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            const newRow: DeviceKeyDto = {
                id: result.data.id,
                registratorId: result.data.registratorId,
                used: 'generationKey.notUsed',
                key: result.data.key,
                registratorName: '',
            };

            ctx.setState({
                ...state,
                deviceKeyRows: [newRow, ...state.deviceKeyRows],
                updateDeviceKey: result.data,
            });
        }
    }

    @Action(DeleteGenerationRow)
    async DeleteGenerationRow(ctx: StateContext<GenerationKeyStateModel>, action: DeleteGenerationRow): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .delete(`/api/device-key/${action.id}`)
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result || result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                deviceKeyRows: state.deviceKeyRows.filter((f) => f.id !== action.id),
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(RegenerationDeviceKey)
    async RegenerationDeviceKey(ctx: StateContext<GenerationKeyStateModel>, action: RegenerationDeviceKey): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .post(`api/device-key/regenerate/${action.id}`, { id: action.id })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result || result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                deviceKeyRows: state.deviceKeyRows.map((row) => {
                    if (row.id === action.id) {
                        row.key = result.data.key.substr(0, 15);
                    }
                    return row;
                }),
            });
        } else {
            // this.notificationsService.onEmit(TooltipStatusEnum.error, false);
            ctx.setState({
                ...state,
            });
        }
    }

    @Action(DownloadKey)
    downloadKey(ctx: StateContext<GenerationKeyStateModel>, payload: DownloadKey): void {
        const state = ctx.getState();

        const blob = new Blob([payload.key], { type: 'key' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'sensorscloud.key';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

        ctx.setState({
            ...state,
        });
    }

    // @Action(SetRegistratorNames)
    // setRegistratorNames(ctx: StateContext<GenerationKeyStateModel>): void {
    //     const state = ctx.getState();
    //     const registrators = this.store.selectSnapshot(DevicesState.getRegistrators);
    //
    //     ctx.setState({
    //         ...state,
    //         deviceKeyRows: state.deviceKeyRows
    //             .map((row) => {
    //                 if (!row) {
    //                     return;
    //                 }
    //                 const currentRegistrator = registrators.find((r) => r.id === row.registratorId);
    //
    //                 return {
    //                     ...row,
    //                     registratorName: row.registratorName ?? currentRegistrator?.name ?? currentRegistrator?.defaultName,
    //                 };
    //             })
    //             .filter((r) => !!r),
    //     });
    // }
}

import {
    BillingPersonalAccountInterface,
    UpdateBillingAccountInfoInterface,
} from '../../../../billing/_interfaces/billing-personal-account.interface';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { GetProviderAccount, GetProviderAccountInfo, UpdateProviderAccount } from '../actions/finance-pay-info.actions';
import { ApiResponse } from '../../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { HTTP_STATUS } from '../../../../app-shared-elements/_enums/status.enum';
import { BillingAccountInfoInterface } from '../../../../billing/_interfaces/billing-account-info.interface';
import { FinancePayInfoService } from '../../_services/finance-pay-info.service';
import { NotificationsService } from '../../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../../app-shared-elements/_enums/tooltip-status.enum';

export interface FinancePayInfoStateModel {
    providerAccount: BillingPersonalAccountInterface;
    accountInfo: BillingAccountInfoInterface[];
    currentCountrySelect: string;
}

const FINANCE_PAY_INFO_STATE = new StateToken<FinancePayInfoStateModel>('financePayInfo');

@State<FinancePayInfoStateModel>({
    name: FINANCE_PAY_INFO_STATE,
    defaults: {
        providerAccount: null,
        accountInfo: [],
        currentCountrySelect: null,
    },
})
@Injectable()
export class FinancePayInfoState {
    constructor(
        private http: HttpClient,
        private financePayInfoService: FinancePayInfoService,
        private notificationService: NotificationsService,
    ) {}

    @Selector()
    static getProviderAccount(state: FinancePayInfoStateModel): BillingPersonalAccountInterface {
        return state.providerAccount;
    }

    @Selector()
    static getAccountInfo(state: FinancePayInfoStateModel): BillingAccountInfoInterface[] {
        return state.accountInfo;
    }

    @Action(GetProviderAccount)
    async getProviderAccount(ctx: StateContext<FinancePayInfoStateModel>): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/control/provider-account')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                providerAccount: result.data,
            });
        }
    }

    @Action(GetProviderAccountInfo)
    getProviderAccountInfo(ctx: StateContext<FinancePayInfoStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            accountInfo: this.financePayInfoService.getBillingProviderInfo(state.providerAccount),
        });
    }

    @Action(UpdateProviderAccount)
    async updateProviderAccount(
        ctx: StateContext<FinancePayInfoStateModel>,
        payload: UpdateProviderAccount,
    ): Promise<void> {
        const state = ctx.getState();
        const data: UpdateBillingAccountInfoInterface = {
            ...state.providerAccount,
            ...payload.data,
        };

        const result: ApiResponse = (await this.http
            .put('/api/control/provider-account', { ...data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                providerAccount: result.data,
            });
        }
    }
}

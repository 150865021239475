import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { HTTP_STATUS } from 'src/app/app-shared-elements/_enums/status.enum';
import { TooltipStatusEnum } from 'src/app/app-shared-elements/_enums/tooltip-status.enum';
import { NotificationsService } from 'src/app/app-shared-elements/_services/notifications.service';
import { MailingGroupRows, NotificationGroup, NotificationGroupUpdateDto } from '../../_interfaces/mailing-groups.interfaces';
import { MailingGroupEditService } from '../../_services/mailing-group-edit.service';
import { MailingGroupsService } from '../../_services/mailing-groups.service';
import {
    CreateMailingGroup,
    DeleteMailingGroup,
    GetMailingGroupById,
    GetMailingGroups,
    InitRowsMailingGroup,
    SetMailingGroupParams,
    SetUpdatingGroup,
    UpdateMailingGroup,
} from '../actions/mailing-group.actions';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { Pagination, Params, ParamsFilterTypeEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { CloneDeepService } from 'src/app/app-shared-elements/_services/clone-deep.service';
import { SetIsMailingGroupEdit } from '../actions/mailing-group-edit.actions';

export interface MailingGroupStateModel {
    groupsRows: MailingGroupRows[];
    updatingGroup: NotificationGroup;
    groups: NotificationGroup[];
    params: Params;
}

const MAILING_GROUP_TOKEN = new StateToken<MailingGroupStateModel>('mailingGroup');

const initialPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

const initialParams: Params = {
    sorted: null,
    pagination: initialPagination,
    filter: [
        // drop down
        {
            property: 'name',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true,
        },
        {
            property: 'usersId',
            value: null,
            type: ParamsFilterTypeEnum.TEXT,
            isDropdown: true,
            isArray: true,
        },
    ],
};

@State<MailingGroupStateModel>({
    name: MAILING_GROUP_TOKEN,
    defaults: {
        groupsRows: [],
        updatingGroup: null,
        groups: [],
        params: initialParams,
    },
})
@Injectable()
export class MailingGroupState {
    constructor(
        private mailingGroupsService: MailingGroupsService,
        private notificationsService: NotificationsService,
        private cloneDeepService: CloneDeepService,
        private mailingGroupEditService: MailingGroupEditService,
        private http: HttpClient,
    ) {}

    @Selector()
    static getMailingGroupRows(state: MailingGroupStateModel): MailingGroupRows[] {
        return state.groupsRows.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    }

    @Selector()
    static getMailingGroups(state: MailingGroupStateModel): NotificationGroup[] {
        return state.groups;
    }

    @Selector()
    static getUpdatingGroup(state: MailingGroupStateModel): NotificationGroup {
        return state.updatingGroup;
    }

    @Selector()
    static getParams(state: MailingGroupStateModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }

    @Selector()
    static getMailingGroupEditDto(state: MailingGroupStateModel): NotificationGroupUpdateDto {
        return {
            id: state.updatingGroup.id,
            name: state.updatingGroup.name,
            isActive: state.updatingGroup.isActive,
            startDelay: state.updatingGroup.startDelay,
            acknowledgmentDelay: state.updatingGroup.acknowledgmentDelay,
        };
    }

    @Action(GetMailingGroups)
    async getMailingGroups(ctx: StateContext<MailingGroupStateModel>): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse<NotificationGroup[]> = (await this.http
            .get('api/notification-group/groups')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<NotificationGroup[]>;
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                groups: result.data,
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.length,
                    },
                },
            });

            ctx.dispatch(new InitRowsMailingGroup());
        }
    }

    @Action(GetMailingGroupById)
    async getMailingGroupById(ctx: StateContext<MailingGroupStateModel>, payload: GetMailingGroupById): Promise<void> {
        const result: ApiResponse<NotificationGroup> = (await this.http
            .get('/api/notification-group', { params: { id: payload.id } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<NotificationGroup>;
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                updatingGroup: result.data,
            });

            ctx.dispatch(new SetIsMailingGroupEdit(true));
        }
    }

    @Action(InitRowsMailingGroup)
    async getMailingGroupRows(ctx: StateContext<MailingGroupStateModel>): Promise<void> {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            groupsRows: state.groups
                .map((g) => {
                    return {
                        ...g,
                        isActive: g.isActive,
                        id: g.id,
                        users: this.mailingGroupsService.parseToUserEmails(g),
                        name: g.name,
                    };
                })
                .sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()),
        });
    }

    @Action(CreateMailingGroup)
    async createMailingGroup(ctx: StateContext<MailingGroupStateModel>): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse<NotificationGroup> = (await this.http
            .post('api/notification-group', {})
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<NotificationGroup>;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);

            ctx.setState({
                ...state,
                groups: [result.data, ...state.groups],
                updatingGroup: result.data,
            });

            ctx.dispatch(new SetIsMailingGroupEdit(true));
        } else {
            ctx.setState({
                ...state,
            });
        }

        ctx.dispatch(new InitRowsMailingGroup());
    }

    @Action(UpdateMailingGroup)
    async updateMailingGroup(ctx: StateContext<MailingGroupStateModel>, payload: UpdateMailingGroup): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse<NotificationGroup> = (await this.http
            .put('api/notification-group', { ...payload.groupsDto })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<NotificationGroup>;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                groups: state.groups.map((group) => {
                    if (result.data.id === group.id) {
                        return {
                            ...result.data,
                        };
                    }

                    return group;
                }),
                updatingGroup: payload.isEditGroup ? result.data : state.updatingGroup,
            });

            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
            ctx.setState({
                ...state,
            });
        }

        ctx.dispatch(new InitRowsMailingGroup());
    }

    @Action(DeleteMailingGroup)
    async deleteMailingGroup(ctx: StateContext<MailingGroupStateModel>, payload: DeleteMailingGroup): Promise<void> {
        const state = ctx.getState();
        const result: ApiResponse = (await this.http
            .delete('api/notification-group', { params: { id: payload.id } })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationsService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                groups: state.groups.filter((group) => payload.id !== group.id),
            });
        } else {
            this.notificationsService.onEmit(TooltipStatusEnum.error, false);
            ctx.setState({
                ...state,
            });
        }

        ctx.dispatch(new InitRowsMailingGroup());
    }

    @Action(SetUpdatingGroup)
    setUpdatingGroup(ctx: StateContext<MailingGroupStateModel>, payload: SetUpdatingGroup): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            updatingGroup: payload.nGroup,
        });
    }

    @Action(SetMailingGroupParams)
    setMailingGroupParams(ctx: StateContext<MailingGroupStateModel>, payload: SetMailingGroupParams): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: this.cloneDeepService.cloneObject(payload.params),
        });
    }
}

import { DataTypeService } from './../../../_services/data-type.service';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ColumnsTableInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { Select } from '@ngxs/store';
import { UserState } from 'src/app/app-shared-elements/_store/states/user.state';
import { Observable } from 'rxjs';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { LanguageState } from 'src/app/app-shared-elements/_store/states/language.state';
import { LangEnum } from 'src/app/app-shared-elements/_enums/lang.enum';

@Component({
    selector: 'app-columns-date',
    templateUrl: './columns-date.component.html',
    styleUrls: ['./columns-date.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnsDateComponent implements OnInit {
    private _value: any;

    @Input()
    set value(value) {
        this._value = value;
    }

    get value(): any {
        return this._value;
    }

    @Input() cell: ColumnsTableInterface;
    @Input() row;

    @Select(UserState.getUser) user$: Observable<User>;
    @Select(UserState.getUserTimezoneGMT) gmt$: Observable<string>;
    @Select(LanguageState.getLanguage) ln$: Observable<LangEnum>;

    constructor(public dataTypeService: DataTypeService) {}

    ngOnInit(): void {}
}

import { Device } from '../../../../app-shared-elements/_interfaces/Device';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    ClearAdminDevicesState,
    DeleteAdminDevice,
    GetAdminDevices,
    GetAllDevices,
    GetRegistratorsOptions,
    InitSharedDevices,
    SetAdminDevicesFilter,
    ToggleAccessPopup,
    ToggleDeletePopup,
    UpdateAdminDevicesRows,
} from '../actions/admin-devices.actions';
import { ApiResponse } from '../../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP_STATUS } from '../../../../app-shared-elements/_enums/status.enum';
import { DeviceTypeEnum } from '../../../../app-shared-elements/_enums/device-type.enum';
import { DeviceService } from '../../../../device-dashboard/_services/device.service';
import { ColumnsTableInterface, ColumnTypeEnum, ColumnValueAlignEnum } from '../../../../app-shared-elements/_interfaces/ColumnsTable';
import { Pagination, Params, ParamsFilterForClient } from '../../../../app-shared-elements/_interfaces/params.interface';
import { UserRegistratorInterface } from '../../../../device-dashboard/user-devices/_interface/user-devices.interface';
import { initialAdminDevicesFilterData } from '../../_data/admin-device-filter';
import { ActiveEvents } from '../../../../events/_interfaces/ActiveEvents';
import { PerformDeviceService } from '../../../../app-shared-elements/_services/dynamic/perform-device.service';
import { compareFilters } from '../../../../app-shared-elements/_services/table.service';
import { SelectOptionInterface } from '../../../../app-shared-elements/_interfaces/select-option.interface';
import { SetSkeleton } from '../../../../app-shared-elements/_store/actions/table.actions';
import { Router } from '@angular/router';
import { UpdateDevicesState } from '../../../../device-dashboard/_store/actions/user-devices.actions';
import { AdminDevicesService } from '../../_services/admin-devices.service';
import { NotificationsService } from '../../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../../app-shared-elements/_enums/tooltip-status.enum';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SetActiveEventsFromAdmin } from '../../../../app-shared-elements/_store/actions/active-events.actions';
import { VariablesNameEnum } from '../../../../app-shared-elements/_enums/variables-name.enum';
import { SharedUserInterface } from '../../../../device-dashboard/user-devices/_interface/shared-user.interface';

export interface AdminDevicesStateModel {
    devices: Device[];
    adminDevicesArray: UserRegistratorInterface[];
    adminDevicesTable: ColumnsTableInterface[];
    params: Params;
    currentRegistratorIds: string[];
    registratorsOptions: SelectOptionInterface[];
    activeEvents: ActiveEvents[];
    isLoadAdminDevices: boolean;
    isTogglePopup: boolean;
    sharedDevices: SharedUserInterface[];
    isOpenAccessPopup: boolean;
}

const ADMIN_DEVICES_TOKEN = new StateToken<AdminDevicesStateModel>('adminDevices');

const initDevicesTableColumns: ColumnsTableInterface[] = [
    {
        title: 'devices.type',
        grow: false,
        small: true,
        type: ColumnTypeEnum.text,
        name: 'iconType',
        styles: true,
        isMultilang: true,
        preIcons: true,
        align: ColumnValueAlignEnum.center,
    },
    {
        title: 'devices.name',
        grow: true,
        small: false,
        type: ColumnTypeEnum.text,
        name: 'name',
        align: ColumnValueAlignEnum.left,
        isClick: true,
    },
    {
        title: 'devices.login',
        grow: false,
        small: true,
        maxWidth: '200px',
        minWidth: '200px',
        type: ColumnTypeEnum.text,
        name: 'login',
        tooltip: true,
    },
    {
        title: 'devices.statusOn',
        grow: false,
        small: true,
        maxWidth: '140px',
        minWidth: '85px',
        type: ColumnTypeEnum.text,
        name: 'connect',
        styles: true,
        isMultilang: true,
    },
    {
        title: 'devices.signal',
        grow: false,
        small: true,
        maxWidth: '180px',
        minWidth: '100px',
        type: ColumnTypeEnum.text,
        name: 'signal',
    },
    {
        title: 'devices.battery',
        grow: false,
        small: true,
        maxWidth: '160px',
        minWidth: '120px',
        type: ColumnTypeEnum.text,
        name: 'battery',
    },
    {
        title: 'devices.status',
        grow: false,
        small: true,
        maxWidth: '180px',
        minWidth: '120px',
        type: ColumnTypeEnum.text,
        name: 'status',
        isClick: true,
    },
    {
        title: 'devices.lastAct',
        grow: false,
        small: false,
        maxWidth: '200px',
        minWidth: '200px',
        type: ColumnTypeEnum.date,
        name: 'lastActive',
    },
    {
        title: 'devices.actions',
        maxWidth: '100px',
        minWidth: '100px',
        type: ColumnTypeEnum.text,
        name: 'actions',
        // isSettings: true
    },
];

const initialPagination: Pagination = {
    itemsPerPage: JSON.parse(localStorage.getItem('itemPerPageDevices')) || 20,
    currentPage: 1,
    totalItems: null,
};

const initialFilterCheckboxes: ParamsFilterForClient[] =
    JSON.parse(localStorage.getItem('adminDevicesFilterParams')) &&
    JSON.parse(localStorage.getItem('adminDevicesFilterParams')).length &&
    compareFilters(
        initialAdminDevicesFilterData.filter((f) => !f.isDropdown),
        JSON.parse(localStorage.getItem('adminDevicesFilterParams')),
    )
        ? initialAdminDevicesFilterData.map((f) => {
              const savedParam = JSON.parse(localStorage.getItem('adminDevicesFilterParams')).find((p) => p.title === f.title);
              if (savedParam) {
                  return savedParam;
              }
              return f;
          })
        : initialAdminDevicesFilterData;

export const initialParams: Params = {
    filter: initialFilterCheckboxes,
    sorted: null,
    pagination: initialPagination,
};

@State<AdminDevicesStateModel>({
    name: ADMIN_DEVICES_TOKEN,
    defaults: {
        devices: [],
        adminDevicesArray: [],
        adminDevicesTable: initDevicesTableColumns,
        params: initialParams,
        currentRegistratorIds: [],
        registratorsOptions: [],
        activeEvents: [],
        isLoadAdminDevices: false,
        isTogglePopup: false,
        sharedDevices: [],
        isOpenAccessPopup: false,
    },
})
@Injectable()
export class AdminDevicesState {
    constructor(
        private http: HttpClient,
        private deviceService: DeviceService,
        private performDeviceService: PerformDeviceService,
        private router: Router,
        private adminDevicesService: AdminDevicesService,
        private notificationService: NotificationsService,
        private translateService: TranslateService,
    ) {}

    @Selector()
    static getAdminDevices(state: AdminDevicesStateModel): Device[] {
        return state.devices;
    }

    @Selector()
    static getAdminRegistrators(state: AdminDevicesStateModel): Device[] {
        return state.devices.filter((d) => d.type === DeviceTypeEnum.registrator);
    }

    @Selector()
    static getAdminDevicesArray(state: AdminDevicesStateModel): UserRegistratorInterface[] {
        // console.log(state.adminDevicesArray);
        return state.adminDevicesArray;
    }

    @Selector()
    static getAdminDevicesTable(state: AdminDevicesStateModel): ColumnsTableInterface[] {
        return state.adminDevicesTable;
    }

    @Selector()
    static getParams(state: AdminDevicesStateModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }

    @Selector()
    static getRegistratorsOptions(state: AdminDevicesStateModel): SelectOptionInterface[] {
        return state.registratorsOptions;
    }

    @Selector()
    static getActiveEvents(state: AdminDevicesStateModel): ActiveEvents[] {
        return state.activeEvents;
    }

    @Selector()
    static isLoadAdminDevices(state: AdminDevicesStateModel): boolean {
        return state.isLoadAdminDevices;
    }

    @Selector()
    static isToggleDeletePopup(state: AdminDevicesStateModel): boolean {
        return state.isTogglePopup;
    }

    @Selector()
    static getSharedDevices(state: AdminDevicesStateModel): SharedUserInterface[] {
        return state.sharedDevices;
    }

    @Selector()
    static getIsOpenAccessPopup(state: AdminDevicesStateModel): boolean {
        return state.isOpenAccessPopup;
    }

    @Action(GetAllDevices)
    async getAllDevices(ctx: StateContext<AdminDevicesStateModel>): Promise<void> {
        const result: ApiResponse = (await this.http
            .get('/api/control/devices')
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            const registratorsId: string = result.data
                .filter((d) => d.type === DeviceTypeEnum.registrator)
                .map((r) => r.id)
                .join(',');

            const resultActiveEvents = (await this.http
                .get('api/control/logic-events-log/active-log/', {
                    params: {
                        registratorsId,
                    },
                })
                .toPromise()
                .catch((e) => console.log(e))) as ApiResponse;

            let devices: Device[] = await this.performDeviceService.setValue(result.data);
            devices = (await this.performDeviceService.setStatus(devices, resultActiveEvents.data as ActiveEvents[], true)).all;
            devices = (await this.performDeviceService.setConnect(devices)).all;

            ctx.setState({
                ...state,
                devices,
                activeEvents: resultActiveEvents.data as ActiveEvents[],
                isLoadAdminDevices: true,
            });

            await ctx.dispatch(new UpdateDevicesState(devices)).toPromise();
            ctx.dispatch(new SetActiveEventsFromAdmin(resultActiveEvents.data as ActiveEvents[]));
        } else {
            ctx.setState({
                ...state,
                activeEvents: [],
                isLoadAdminDevices: false,
            });
            ctx.dispatch(new SetActiveEventsFromAdmin([]));
        }
    }

    @Action(GetAdminDevices)
    async getAdminDevices(ctx: StateContext<AdminDevicesStateModel>): Promise<void> {
        await ctx.dispatch(new GetAllDevices()).toPromise();
        const state = ctx.getState();

        if (state.devices && state.devices.length) {
            const totalItems = state.devices.filter((d) => d.type === DeviceTypeEnum.registrator).length;

            ctx.setState({
                ...state,
                adminDevicesArray: state.devices
                    .filter((d) => d.type === DeviceTypeEnum.registrator)
                    .map((device, index) => {
                        return {
                            registrator: {
                                ...device,
                                name: device.name ?? device.defaultName,
                                isKioskMode: device.variables?.find((v) => v.name === VariablesNameEnum.KioskMode)?.currentValue ?? false,
                                isTransportMode:
                                    device.variables?.find((v) => v.name === VariablesNameEnum.ExpectExpedition)?.currentValue ?? false,
                                isVisible: this.deviceService.getIsVisibleDevice(device, state.params),
                            },
                            devices: state.devices
                                .filter((d) => d.registratorId === device.id)
                                .map((d) => {
                                    return {
                                        ...d,
                                        name: d.name ?? d.defaultName,
                                        isAction: false,
                                        listAction: this.adminDevicesService.getListAction(d),
                                        position: index,
                                        isVisible: this.deviceService.getIsVisibleDevice(d, state.params),
                                    };
                                })
                                .sort((a, b) => (a.type > b.type ? 1 : -1)),
                            isActionRegistrator: false,
                            position: index,
                            listAction: this.adminDevicesService.getListAction(device),
                        };
                    })
                    .filter((f) => !!f)
                    .sort((a, b) => (a.registrator.defaultName > b.registrator.defaultName ? 1 : -1)),
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems,
                    },
                },
            });
        } else {
            ctx.setState({
                ...state,
                adminDevicesArray: [],
            });
        }

        ctx.dispatch(new SetSkeleton(false));
    }

    @Action(UpdateAdminDevicesRows)
    updateAdminDevicesRows(ctx: StateContext<AdminDevicesStateModel>): void {
        const state = ctx.getState();

        const companyIdFilter = state.params.filter.find((f) => f.property === 'companyId');
        this.router.navigate([], {
            queryParams: {
                companyId: companyIdFilter && companyIdFilter.value ? companyIdFilter.value : null,
            },
            queryParamsHandling: 'merge',
        });

        ctx.setState({
            ...state,
            adminDevicesArray: state.adminDevicesArray.map((item) => {
                return {
                    ...item,
                    registrator: {
                        ...item.registrator,
                        isVisible: this.deviceService.getIsVisibleDevice(item.registrator, state.params),
                    },
                    devices: item.devices.map((d) => {
                        return {
                            ...d,
                            isVisible: this.deviceService.getIsVisibleDevice(d, state.params),
                        };
                    }),
                };
            }),
        });
    }

    @Action(SetAdminDevicesFilter)
    async setAdminDevicesFilter(ctx: StateContext<AdminDevicesStateModel>, payload: SetAdminDevicesFilter): Promise<void> {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            params: payload.params,
        });
    }

    @Action(GetRegistratorsOptions)
    getRegistratorsOptions(ctx: StateContext<AdminDevicesStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            registratorsOptions: state.devices
                .filter((d) => d.type === DeviceTypeEnum.registrator)
                .map((r) => {
                    return {
                        key: r.id,
                        value: r.name ?? r.defaultName,
                        type: 'text',
                        property: r.id,
                    };
                })
                .sort((a, b) =>
                    a.value.toLowerCase() < b.value.toLowerCase() ? -1 : a.value.toLowerCase() > b.value.toLowerCase() ? 1 : 0,
                ),
        });
    }

    @Action(DeleteAdminDevice)
    async deleteAdminDevice(ctx: StateContext<AdminDevicesStateModel>, action: DeleteAdminDevice): Promise<void> {
        this.notificationService.onEmit(TooltipStatusEnum.load, false, 'devices.startDelete');
        const result: ApiResponse = (await this.http
            .delete('/api/control/devices/full-delete', {
                params: {
                    id: action.device.id,
                },
            })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.translateService
                .get('devices.delete', { name: action.device.name ?? action.device.defaultName })
                .pipe(first())
                .subscribe((message) => {
                    this.notificationService.onEmit(TooltipStatusEnum.update, false, message);
                });

            ctx.setState({
                ...state,
                devices: state.devices.filter((device) => device.id !== action.device.id),
                adminDevicesArray: state.adminDevicesArray
                    .filter((item) => {
                        if (item.registrator.id !== action.device.id) {
                            return {
                                ...item,
                                devices: item.devices.filter((d) => d.id !== action.device.id).filter((d) => !!d),
                            };
                        }
                    })
                    .filter((item) => !!item),
                isTogglePopup: false,
            });
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);

            ctx.setState({
                ...state,
            });
        }
    }

    @Action(ToggleDeletePopup)
    toggleDeletePopup(ctx: StateContext<AdminDevicesStateModel>, payload: ToggleDeletePopup): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isTogglePopup: payload.toggle,
        });
    }

    @Action(InitSharedDevices)
    async initSharedDevices(ctx: StateContext<AdminDevicesStateModel>, payload: InitSharedDevices): Promise<void> {
        const headers = new HttpHeaders({ registratorId: payload.registratorId, currentUserId: payload.userId });
        const result: ApiResponse = (await this.http
            .get('api/share-registrators/share', { params: { registratorId: payload.registratorId }, headers })
            .toPromise()) as ApiResponse;
        // console.log(result);
        const state = ctx.getState();
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                sharedDevices: result.data.shared,
                isOpenAccessPopup: true,
            });
        }
    }

    @Action(ToggleAccessPopup)
    toggleAccessPopup(ctx: StateContext<AdminDevicesStateModel>, payload: ToggleAccessPopup): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            isOpenAccessPopup: payload.toggle,
        });
    }

    @Action(ClearAdminDevicesState)
    clear(ctx: StateContext<AdminDevicesStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            registratorsOptions: [],
            params: initialParams,
            adminDevicesArray: [],
        });
    }
}

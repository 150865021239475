import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Pagination, Params, ParamsSorted, ParamsSortedOrderEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { MailingService } from '../../_services/mailing.service';
import { GetMailingLogs, GetMailingMessagesLogsRows, SetMailingLogsFilter } from '../actions/mailing-messages-log.actions';
import { MailingRowLogInterface, NotificationLogInterface } from '../../../mailing/_interfaces/notification-log.interface';

import { CloneDeepService } from 'src/app/app-shared-elements/_services/clone-deep.service';
import { initialFilterMailingLogs } from '../../_data/mailing-logs-data';
import { TimeFilterState } from 'src/app/app-shared-elements/_store/states/time-filter.state';
import { ParamsService } from 'src/app/app-shared-elements/_services/params.service';
import { SetSkeleton } from 'src/app/app-shared-elements/_store/actions/table.actions';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';

export interface MailingMessagesLogsStateModel {
    messageLogs: { items: NotificationLogInterface[]; total: 0 };
    messageRows: MailingRowLogInterface[];
    params: Params;
}

const MAILING_MESSAGES_LOGS_TOKEN = new StateToken<MailingMessagesLogsStateModel>('messageRows');

const initialCurrentSort: ParamsSorted[] = [
    {
        property: 'created',
        order: ParamsSortedOrderEnum.DESC,
    },
];

const initialPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

export const initialParams: Params = {
    filter: initialFilterMailingLogs,
    sorted: initialCurrentSort,
    pagination: initialPagination,
};

@State<MailingMessagesLogsStateModel>({
    name: MAILING_MESSAGES_LOGS_TOKEN,
    defaults: {
        messageLogs: { items: [], total: 0 },
        messageRows: [],
        params: initialParams,
    },
})
@Injectable()
export class MailingMessageLogsState {
    constructor(
        private mailingService: MailingService,
        private cloneDeepService: CloneDeepService,
        private store: Store,
        private paramsService: ParamsService,
        private http: HttpClient,
    ) {}

    @Selector()
    static getMailingMessagesLogs(state: MailingMessagesLogsStateModel): MailingRowLogInterface[] {
        return state.messageRows;
    }

    @Selector()
    static getMailingParams(state: MailingMessagesLogsStateModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }

    @Action(GetMailingLogs)
    async getMailingLogs(ctx: StateContext<MailingMessagesLogsStateModel>): Promise<void> {
        const state = ctx.getState();

        const datetime = this.store.selectSnapshot(TimeFilterState.getTimeObj);
        const params: Params = {
            ...state.params,
            filter: this.paramsService.parseParamsFilterForServer(state.params.filter, datetime),
            pagination: {
                currentPage: state.params.pagination.currentPage,
                itemsPerPage: state.params.pagination.itemsPerPage,
            },
            sorted: state.params.sorted && state.params.sorted.length ? state.params.sorted : initialCurrentSort,
        };

        const result: ApiResponse = (await this.http
            .get('/api/notification-logs', {
                headers: {
                    params: encodeURIComponent(JSON.stringify(params)),
                },
                responseType: 'json',
            })
            .toPromise()) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                messageLogs: result.data as { items: NotificationLogInterface[]; total: 0 },
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.total,
                    },
                    sorted: params.sorted && params.sorted.length ? params.sorted : initialCurrentSort,
                },
            });

            ctx.dispatch(new GetMailingMessagesLogsRows());
        }
    }

    @Action(GetMailingMessagesLogsRows)
    async getMailingMessagesLogsRows(ctx: StateContext<MailingMessagesLogsStateModel>): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            messageRows: state.messageLogs.items.map((m) => {
                return {
                    ...m,
                    transportValue: this.mailingService.getTransportForLogRow(m),
                    styles: {
                        statusValue: this.mailingService.getStyleForLogRow(m),
                    },
                    date: m.created,
                    userName: '', // m.userName,
                    type: this.mailingService.getLogType(m),
                    statusValue: this.mailingService.getStatusForLogRow(m),
                };
            }),
        });

        this.store.dispatch(new SetSkeleton(false));
    }

    @Action(SetMailingLogsFilter)
    async setMailingLogsFilter(ctx: StateContext<MailingMessagesLogsStateModel>, payload: SetMailingLogsFilter): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: this.cloneDeepService.cloneObject(payload.params),
        });

        await ctx.dispatch(new GetMailingLogs()).toPromise();
    }
}

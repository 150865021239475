export enum TableNamesEnum {
    controlGroupTable = 'controlGroupTable',
    projectSettingsTable = 'projectSettingsTable',
    axisTable = 'axisTable',
    deviceLogTable = 'deviceLogTable',
    detailsDeviceTable = 'detailsDeviceTable',
    unitsTable = 'unitsTable',
    mailingUserTable = 'mailingUserTable',
    mailingRecipientTable = 'mailingRecipientTable',
    statusMessages = 'statusMessages',
    adminsListTable = 'adminsListTable',
    usersListTable = 'usersListTable',
}

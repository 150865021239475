import {Action, Selector, State, StateContext, StateToken, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {BillingAccountInfoInterface} from '../../_interfaces/billing-account-info.interface';
import {BillingAccountService} from '../../_services/billing-account.service';
import {
    ChangeAccountInfoOptions,
    ChangeAccountInfoUpdateValue,
    GetBillingAccount,
    GetBillingAccountInfo,
    UpdateAdminBillingInfo,
    UpdateBillingAccountBalance,
    UpdateBillingAccountInfo
} from "../actions/billing-account-info.actions";
import {BillingAccountInfoTypeEnum, BillingPayerOptionsTypeEnum} from "../../_enums/billing-account-info-type.enum";
import {ApiResponse} from "../../../app-shared-elements/_interfaces/ApiRequest";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {
    BillingPersonalAccountInterface,
    UpdateBillingAccountInfoInterface
} from "../../_interfaces/billing-personal-account.interface";
import {HTTP_STATUS} from "../../../app-shared-elements/_enums/status.enum";
import {NotificationsService} from "../../../app-shared-elements/_services/notifications.service";
import {TooltipStatusEnum} from "../../../app-shared-elements/_enums/tooltip-status.enum";
import {UserState} from "../../../app-shared-elements/_store/states/user.state";
import {AuthState} from "../../../auth/_store/states/auth.state";

export interface BillingAccountInfoStateModel {
    personalAccount: BillingPersonalAccountInterface;
    accountInfo: BillingAccountInfoInterface[];
    updateAccountInfo: UpdateBillingAccountInfoInterface;
}

const BILLING_ACCOUNT_INFO_STATE = new StateToken<BillingAccountInfoStateModel>('billingAccountInfoState');

@State<BillingAccountInfoStateModel>({
    name: BILLING_ACCOUNT_INFO_STATE,
    defaults: {
        personalAccount: null,
        accountInfo: [],
        updateAccountInfo: null,
    }
})

@Injectable()
export class BillingAccountInfoState {
    constructor(
        private http: HttpClient,
        private billingAccountInfoService: BillingAccountService,
        private notificationService: NotificationsService,
        private store: Store
    ) {
    }

    @Selector()
    static getBillingAccountInfo(state: BillingAccountInfoStateModel): BillingAccountInfoInterface[] {
        return state.accountInfo;
    }

    @Selector()
    static getBillingInfoUpdateValue(state: BillingAccountInfoStateModel): UpdateBillingAccountInfoInterface {
        return state.updateAccountInfo;
    }

    @Selector()
    static getBillingAccount(state: BillingAccountInfoStateModel): BillingPersonalAccountInterface {
        return state.personalAccount;
    }

    @Action(GetBillingAccount)
    async getBillingAccount(ctx: StateContext<BillingAccountInfoStateModel>): Promise<void> {

        const currentUserId = this.store.selectSnapshot(UserState.getAdminUserId);

        let result: ApiResponse;

        if (currentUserId) {
            const headers = new HttpHeaders().set('currentUserId', currentUserId);
            result = await this.http.get('/api/personal-account', {headers}).toPromise().catch(e => console.log(e)) as ApiResponse;
        } else {
            result = await this.http.get('/api/personal-account').toPromise().catch(e => console.log(e)) as ApiResponse;

        }

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                personalAccount: result.data
            });
        }
    }

    @Action(GetBillingAccountInfo)
    async getBillingAccountInfo(ctx: StateContext<BillingAccountInfoStateModel>): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            accountInfo: !this.store.selectSnapshot(AuthState.getIsAdmin)
                ? this.billingAccountInfoService.getBillingAccountInfo(state.personalAccount).filter(f => !f.isAdmin)
                : this.billingAccountInfoService.getBillingAccountInfo(state.personalAccount)
        });
    }

    @Action(ChangeAccountInfoUpdateValue)
    changeAccountInfoUpdateValue(ctx: StateContext<BillingAccountInfoStateModel>, payload: ChangeAccountInfoUpdateValue): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            updateAccountInfo: payload.data
        });
    }

    @Action(ChangeAccountInfoOptions)
    changeAccountInfoOptions(ctx: StateContext<BillingAccountInfoStateModel>, payload: ChangeAccountInfoOptions): void {
        const state = ctx.getState();

        switch (payload.type) {
            case BillingAccountInfoTypeEnum.payer:
                ctx.setState({
                    ...state,
                    accountInfo: state.accountInfo.map(item => {
                        if (item.type === BillingAccountInfoTypeEnum.name) {
                            return {
                                ...item,
                                title: +payload.value as BillingPayerOptionsTypeEnum === BillingPayerOptionsTypeEnum.INDIVIDUAL
                                    ? 'billing.info.payerName' : 'billing.info.company'
                            };
                        }

                        return item;
                    })
                });
                break;
            case BillingAccountInfoTypeEnum.country:
                ctx.setState({
                    ...state,
                    accountInfo: state.accountInfo.map(item => {
                        if (item.type === BillingAccountInfoTypeEnum.region) {
                            return {
                                ...item,
                                options: this.billingAccountInfoService.initRegionOptions()
                            };
                        }

                        if (item.type === BillingAccountInfoTypeEnum.city) {
                            return {
                                ...item,
                                options: [],
                                currentOption: null
                            };
                        }

                        return item;
                    })
                });
                break;
            case BillingAccountInfoTypeEnum.region:
                ctx.setState({
                    ...state,
                    accountInfo: state.accountInfo.map(item => {
                        if (item.type === BillingAccountInfoTypeEnum.city) {
                            return {
                                ...item,
                                options: this.billingAccountInfoService.initCityOptions(),
                            };
                        }

                        return item;
                    })
                });
                break;
        }
    }

    @Action(UpdateBillingAccountInfo)
    async updateBillingAccountInfo(ctx: StateContext<BillingAccountInfoStateModel>, payload: UpdateBillingAccountInfo): Promise<void> {
        const state = ctx.getState();
        const data: UpdateBillingAccountInfoInterface = {
            ...state.personalAccount,
            ...payload.data
        };

        const result: ApiResponse = await this.http.put('/api/personal-account', {...data}).toPromise().catch(e => console.log(e)) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                personalAccount: result.data
            });

            ctx.dispatch(new ChangeAccountInfoUpdateValue({
                ...state.updateAccountInfo
            }));
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(UpdateAdminBillingInfo)
    async updateAdminBillingInfo(ctx: StateContext<BillingAccountInfoStateModel>, payload: UpdateAdminBillingInfo): Promise<void> {
        const result: ApiResponse = await this.http.put('api/control/personal-account/discount', {...payload.data}).toPromise().catch(e => console.log(e)) as ApiResponse;
        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                personalAccount: result.data
            });
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
        }
    }

    @Action(UpdateBillingAccountBalance)
    updateBillingAccountBalance(ctx: StateContext<BillingAccountInfoStateModel>, payload: UpdateBillingAccountBalance): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            personalAccount: {
                ...state.personalAccount,
                balance: payload.balance ? payload.balance : state.personalAccount.balance
            }
        });
    }
}

import { CloneDeepService } from '../../../_services/clone-deep.service';
import { Observable, Subject } from 'rxjs';
import { MailingUserService } from '../../../../mailing/_services/mailing-user.service';
import { MailingIconSelectOptionInterface } from '../../../_interfaces/MailingIconSelect';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MailingCellFieldTypeEnum } from 'src/app/mailing/_enums/mailing-cell-field-type.enum';
import { NotificationTransportType } from 'src/app/mailing/_interfaces/notification-log.interface';
import { takeUntil } from 'rxjs/operators';
import { ColumnsTableInterface, IconInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { TableService } from 'src/app/app-shared-elements/_services/table.service';
import { DisabledTypeEnum } from '../../../_enums/disabled-type.enum';
import { Router } from '@angular/router';
import { InputConstructorTypeEnum } from '../../../_enums/input-constructor-type.enum';
import { TooltipSideEnum } from '../../../_enums/tooltip-side.enum';
import { Select } from '@ngxs/store';
import { StatusMessagesState } from '../../../../admin/event-messages/_store/states/status-messages.state';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { MailingRecipientRowInterface } from 'src/app/mailing/_interfaces/mailing-recipient-row.interface';
import { UserNotificationTransport } from 'src/app/profile/_interfaces/user-notification-transport.interface';
import { UserNotificationTransportStatusEnum } from 'src/app/profile/_enums/user-notification-transport-status.enum';
import { IconEventInterface } from '../../_interfaces/icon-event.interface';
import { VariableValueTypeEnum } from '../../../../groups/_enums/variable-value-type.enum';
import { PasswordValidateService } from '../../../_services/password-validate.service';

@Component({
    selector: 'app-column-mailing-editor',
    templateUrl: './column-mailing-editor.component.html',
    styleUrls: ['./column-mailing-editor.component.scss'],
})
export class ColumnMailingEditorComponent implements OnInit, OnDestroy, AfterViewInit {
    @Select(StatusMessagesState.getIsEditStatusMessage) isEdit$: Observable<boolean>;

    private _cell: ColumnsTableInterface;
    private _row: any; // MailingUserRowInterface | UnitRowInterface;
    private _value: any;

    @Input()
    set cell(cell: ColumnsTableInterface) {
        this.btnsNumbers = cell?.actionBtns?.length;
        this._cell = cell;
    }

    get cell(): ColumnsTableInterface {
        return this._cell;
    }

    @Input()
    set row(row) {
        this._row = row;
    }

    get row(): any {
        return this._row;
    }

    @Input()
    set value(value) {
        this._value = value;
    }

    get value(): any {
        return this._value;
    }

    @Input() transportValues: UserNotificationTransport[];
    @Input() disableType: DisabledTypeEnum;
    @Input() isBorder = true;

    @Output() saveEvent = new EventEmitter<MailingRecipientRowInterface>();
    @Output() deleteEvent = new EventEmitter<MailingRecipientRowInterface>();
    @Output() goToEditEvent = new EventEmitter<void>();
    @Output() actionBtnEvent: EventEmitter<ColumnsActionTypeEnum> = new EventEmitter<ColumnsActionTypeEnum>();
    @Output() iconEvent: EventEmitter<IconEventInterface<any>> = new EventEmitter<IconEventInterface<any>>();
    @Output() moveToSmthEvent = new EventEmitter<any>();

    @ViewChild('textarea') textarea: ElementRef;
    @ViewChild('settings') settings: ElementRef;

    currentCursorPosition: number;
    message: string;

    listAbove: boolean;
    btnsNumbers: number;

    mailingCellFieldTypeEnum = MailingCellFieldTypeEnum;
    userNotificationTransportStatusEnum = UserNotificationTransportStatusEnum;
    variableValueTypeEnum = VariableValueTypeEnum;

    strength: any = -1;

    isAction = false;

    isDisabledClick = false;

    emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    transportOptions: MailingIconSelectOptionInterface[] = [
        {
            key: 0,
            value: {
                text: 'viber',
                iconType: NotificationTransportType.VIBER,
            },
        },
        {
            key: 1,
            value: {
                text: 'telegram',
                iconType: NotificationTransportType.TELEGRAM,
            },
        },
        {
            key: 3,
            value: {
                text: 'email',
                iconType: NotificationTransportType.EMAIL,
            },
        },
    ];

    disabledTypeEnum = DisabledTypeEnum;
    type: DisabledTypeEnum;
    tooltipSideEnum = TooltipSideEnum;
    notificationTransportType = NotificationTransportType;
    currentRow;

    destroy: Subject<boolean> = new Subject<boolean>();
    liveEditingRow;
    savedRow;
    inputConstructorTypeEnum = InputConstructorTypeEnum;
    inputAddList: string[] = [];
    inputAddValue: string;

    readonly VIBER_ICON_PATH = './assets/design/icons/mailing/transport-viber.svg';
    readonly VIBER_DISABLED_ICON_PATH = './assets/design/icons/mailing/transport-viber-disabled.svg';
    readonly TELEGRAM_ICON_PATH = './assets/design/icons/mailing/transport-telegram.svg';
    readonly TELEGRAM_DISABLED_ICON_PATH = './assets/design/icons/mailing/transport-telegram-disabled.svg';
    readonly EMAIL_ICON_PATH = './assets/design/icons/mailing/transport-email.svg';
    readonly EMAIL_DISABLED_ICON_PATH = './assets/design/icons/mailing/transport-email-disabled.svg';
    readonly ACTIVITY_ICON_PATH = './assets/design/icons/table-operations/activity.svg';
    readonly ADD_ICON_PATH = './assets/design/icons/table-operations/add.svg';
    readonly DELETE_ICON_PATH = './assets/design/icons/table-operations/delete.svg';
    readonly DELETE_INPUT_ICON_PATH = './assets/design/icons/table-operations/delete-input.svg';
    readonly ACTION_ICON_PATH = './assets/design/icons/table-operations/action.svg';
    readonly EDIT_ICON_PATH = './assets/design/icons/table-operations/edit.svg';
    readonly INPUT_ADD_ICON_PATH = './assets/design/icons/input-add.svg';
    readonly INPUT_DELETE_ICON_PATH = './assets/design/icons/delete.svg';

    constructor(
        private mailingUserService: MailingUserService,
        public tableService: TableService,
        private cloneDeepService: CloneDeepService,
        public router: Router,
        private passwordValidateService: PasswordValidateService,
    ) {}

    ngOnInit(): void {
        this.savedRow = this.row;

        if (this.cell.mailingFieldType && this.cell.mailingFieldType === MailingCellFieldTypeEnum.btns) {
            this.tableService.editingRow$.pipe(takeUntil(this.destroy)).subscribe((editingRow) => {
                if (!editingRow) {
                    return;
                }
                this.liveEditingRow = this.cloneDeepService.cloneObject(editingRow);
            });
        }
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }

    save(): void {
        this.isDisabledClick = true;
        if (this.router.url.includes('event-messages/status-messages')) {
            this.saveEvent.emit(this.row);
            return;
        } else {
            this.row?.isNew ? this.saveEvent.emit(this.row) : this.tableService.updateUserEvent.emit(this.row);
            this.tableService.updateEditingRow(null);
        }

        setTimeout(() => {
            this.isDisabledClick = false;
        }, 100);
    }

    actionClick(): void {
        if (this.isDisabledClick) {
            return;
        }
        this.isAction = !this.isAction;
    }

    cancel(): void {
        this.tableService.closeEditingEvent.emit(this.savedRow);
        this.tableService.updateEditingRow(null);
    }

    updateEditingRow(value?: string | boolean, property?: string, type?: string, index?: number, transportProperty?: string): void {
        this.currentRow = property;
        this.tableService.updateEditingRow(this.row);
        if (type === 'password') {
            this.strength = this.passwordValidateService.validatePassword(value);
        }
    }

    delete(): void {
        this.deleteEvent.emit(this.row);
    }

    changeActivityTransport(transportIndex: number): void {
        this.mailingUserService.changeActivityTransportEvent.emit({
            row: this.row,
            index: transportIndex,
        });
    }

    insertData(event: { type: InputConstructorTypeEnum; value: number | string }): void {
        if ((this.row[this.cell.name].length > 233 && event.type === this.inputConstructorTypeEnum.deviceName) || (this.row[this.cell.name].length > 236 && event.type === this.inputConstructorTypeEnum.tagName)) {
            return;
        }

        const data = `{{${event.type}}}`;
        if (this.currentCursorPosition) {
            const firstStr = this.row[this.cell.name].slice(0, this.currentCursorPosition);
            const secondStr = this.row[this.cell.name].slice(this.currentCursorPosition);
            this.row[this.cell.name] = firstStr + ' ' + data + ' ' + secondStr;
        } else {
            this.row[this.cell.name] = this.row[this.cell.name] + ' ' + data;
        }
    }

    setCursorPosition(): void {
        this.currentCursorPosition = this.textarea.nativeElement.selectionStart;
    }

    actionBtns(action: { title: string; property: ColumnsActionTypeEnum }): void {
        this.actionBtnEvent.emit(action.property);
    }

    iconClick(icon: IconInterface<any>, row: any, cell: ColumnsTableInterface): void {
        this.iconEvent.emit({ icon, row, cell });
    }

    moveToSmth(): void {
        if (!this.cell.isClick) {
            return;
        }

        this.moveToSmthEvent.emit(this.row);
    }

    computingWrapperPosition(): void {
        if (!this.btnsNumbers) {
            return;
        }
        const topPositionRelatedDocument = this.settings.nativeElement.getBoundingClientRect().y - 40;
        this.listAbove = topPositionRelatedDocument - this.btnsNumbers * 45 < this.btnsNumbers * 45;
    }

    ngAfterViewInit(): void {
        this.computingWrapperPosition();
    }

    addValue(): void {
        this.row[this.cell.name].push(this.inputAddValue);
        this.inputAddValue = null;
        this.updateEditingRow(this.inputAddValue, this.row[this.cell.name]);
    }

    deleteValue(item: string): void {
        this.row[this.cell.name] = this.row[this.cell.name].filter((f) => f !== item);
        this.updateEditingRow(this.inputAddValue, this.row[this.cell.name]);
    }
}

import { DeviceTypeEnum } from 'src/app/app-shared-elements/_enums/device-type.enum';
import { CreationType } from 'src/app/app-shared-elements/_enums/registrator-sync-status.enu';
import { ParamsFilterForClient, ParamsFilterTypeEnum } from 'src/app/app-shared-elements/_interfaces/params.interface';

const checkboxes: ParamsFilterForClient[] = [
    {
        title: 'filters.userDeviceFilter.act',
        property: 'isActive',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'isActive',
                value: true,
                type: ParamsFilterTypeEnum.BOOLEAN,
            },
        ],
    },
    {
        title: 'filters.userDeviceFilter.registrator',
        property: 'type',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'type',
                value: DeviceTypeEnum.registrator,
                type: ParamsFilterTypeEnum.TEXT,
            },
            {
                property: 'creationType',
                value: CreationType.ORIGIN,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ],
    },
    {
        title: 'filters.userDeviceFilter.sensor',
        property: 'type',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'type',
                value: DeviceTypeEnum.sensor,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ],
    },
    {
        title: 'filters.userDeviceFilter.coordinator',
        property: 'type',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'type',
                value: DeviceTypeEnum.coordinator,
                type: ParamsFilterTypeEnum.TEXT,
            },
        ],
    },
    {
        title: 'filters.userDeviceFilter.deleted',
        property: 'isDeleted',
        value: false,
        type: ParamsFilterTypeEnum.BOOLEAN,
        relationTrue: [
            {
                property: 'isDeleted',
                value: true,
                type: ParamsFilterTypeEnum.BOOLEAN,
            },
        ],
    },
];

export const initialAdminDevicesFilterData: ParamsFilterForClient[] = [
    ...checkboxes,

    // drop down
    {
        property: 'registratorId',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true,
    },
    {
        property: 'owner',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true,
    },
    {
        property: 'isConnect',
        value: null,
        type: ParamsFilterTypeEnum.BOOLEAN,
        isDropdown: true,
    },
    {
        property: 'status',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true,
    },
    {
        property: 'companyId',
        value: null,
        type: ParamsFilterTypeEnum.TEXT,
        isDropdown: true,
    },
];

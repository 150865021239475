export enum FilterByPermissionsEnum {
    groupsByPermission = 'groupsByPermission',
    logicalEventsByPermission = 'logicalEventsByPermission',
    registratorOptionsByPermission = 'registratorOptionsByPermission',
    registratorsByPermission = 'registratorsByPermission',
    reportsByPermission = 'reportsByPermission',
    groupOptionsByPermission = 'groupOptionsByPermission',
    groupDeviceByPermission = 'groupDeviceByPermission',
    deviceLogsByPermission = 'deviceLogsByPermission'
}

import { BillingTransactionsInterface, BillingTransactionsRowInterface } from '../../_interfaces/billing-transactions.interface';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    GetBillingTransactions,
    InitBillingTransactionsRows,
    MoneyTransactions,
    SetBillingTransactionsParams,
} from '../actions/billing-transactions.actions';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { Pagination, Params, ParamsSorted, ParamsSortedOrderEnum } from '../../../app-shared-elements/_interfaces/params.interface';
import { BillingTransactionsService } from '../../_services/billing-transactions.service';
import { UserState } from '../../../app-shared-elements/_store/states/user.state';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { TranslateService } from '@ngx-translate/core';
import { UpdateBillingAccountBalance } from '../actions/billing-account-info.actions';
import { CurrencyState } from '../../../app-shared-elements/_store/states/currency.state';

export interface BillingTransactionsStateModel {
    transactions: BillingTransactionsInterface[];
    transactionsRows: BillingTransactionsRowInterface[];
    params: Params;
}

const BILLING_TRANSACTIONS_STATE = new StateToken<BillingTransactionsStateModel>('billingTransactions');

const initialPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

const initialCurrentSort: ParamsSorted[] = [
    {
        property: 'created',
        order: ParamsSortedOrderEnum.DESC,
    },
];

export const initialParams: Params = {
    filter: [],
    sorted: initialCurrentSort,
    pagination: initialPagination,
};

@State<BillingTransactionsStateModel>({
    name: BILLING_TRANSACTIONS_STATE,
    defaults: {
        transactions: [],
        transactionsRows: [],
        params: initialParams,
    },
})
@Injectable()
export class BillingTransactionsState {
    constructor(
        private http: HttpClient,
        private billingTransactionsService: BillingTransactionsService,
        private store: Store,
        private notificationService: NotificationsService,
        private translateService: TranslateService,
    ) {}

    @Selector()
    static getBillingTransactions(state: BillingTransactionsStateModel): BillingTransactionsInterface[] {
        return state.transactions;
    }

    @Selector()
    static getBillingTransactionsRows(state: BillingTransactionsStateModel): BillingTransactionsRowInterface[] {
        return state.transactionsRows;
    }

    @Selector()
    static getParams(state: BillingTransactionsStateModel): Params {
        return state.params;
    }

    @Action(GetBillingTransactions)
    async getBillingTransactions(ctx: StateContext<BillingTransactionsStateModel>): Promise<void> {
        const state = ctx.getState();

        const params: Params = {
            ...state.params,
            pagination: {
                itemsPerPage: state.params.pagination.itemsPerPage,
                currentPage: state.params.pagination.currentPage,
            },
            sorted: state.params.sorted && state.params.sorted.length ? state.params.sorted : initialCurrentSort,
        };

        const currentUserId = this.store.selectSnapshot(UserState.getAdminUserId);

        let headers;

        if (currentUserId) {
            headers = new HttpHeaders({
                params: encodeURIComponent(JSON.stringify(params)),
            }).set('currentUserId', currentUserId);
        } else {
            headers = new HttpHeaders({
                params: encodeURIComponent(JSON.stringify(params)),
            });
        }

        const result: ApiResponse = (await this.http
            .get('/api/personal-account/transactions', { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            ctx.setState({
                ...state,
                transactions: result.data.items as BillingTransactionsInterface[],
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.total,
                    },
                },
            });
        }
    }

    @Action(InitBillingTransactionsRows)
    async initBillingTransactionsRows(ctx: StateContext<BillingTransactionsStateModel>): Promise<void> {
        await ctx.dispatch(new GetBillingTransactions()).toPromise();
        const state = ctx.getState();

        const currency = this.store.selectSnapshot(CurrencyState.getCurrency);

        const transactionsRows: BillingTransactionsRowInterface[] = [];

        (state.transactions as BillingTransactionsInterface[]).map(async (item) => {
            const details = await this.billingTransactionsService.getDetailsMessage(item);

            transactionsRows.push({
                ...item,
                details,
                amountMoney: item.amountMoney + ' ' + currency,
                totalMoney: item.totalMoney + ' ' + currency,
                styles: {
                    amountMoney: this.billingTransactionsService.getAmountMoneyColor(item),
                },
            });
        });

        ctx.setState({
            ...state,
            transactionsRows,
        });
    }

    @Action(SetBillingTransactionsParams)
    async setBillingTransactionsFilter(
        ctx: StateContext<BillingTransactionsStateModel>,
        payload: SetBillingTransactionsParams,
    ): Promise<void> {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: { ...payload.params },
        });

        await ctx.dispatch(new InitBillingTransactionsRows()).toPromise();
    }

    @Action(MoneyTransactions)
    async moneyTransactions(ctx: StateContext<BillingTransactionsStateModel>, payload: MoneyTransactions): Promise<void> {
        const result: ApiResponse = (await this.http
            .put('/api/control/personal-account/operation', { ...payload.data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        const state = ctx.getState();

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            this.notificationService.onEmit(TooltipStatusEnum.update, false);
            ctx.setState({
                ...state,
                transactions: [result.data, ...state.transactions],
            });

            ctx.dispatch(new UpdateBillingAccountBalance(result.data.totalMoney));
            ctx.dispatch(new InitBillingTransactionsRows());
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
        }
    }
}

<div class="filters">
    <div class="filters__wrapper">
        <ng-container
            *ngIf="(config$ | async) && !(config$ | async).disableFunctions.includes(disabledFilterOptionsEnum.timeFilter)">
            <div class="filters__row filters__row--gray">

                <div class="filters__item filters__item--type-map"
                     *ngIf="!(config$ | async).disableFunctions || !(config$ | async).disableFunctions.includes(disabledFilterOptionsEnum.current) || type === filterItemTypeEnum.map">
                    <div class="filters__radio-label"
                         [class.filters__radio-label--choose]="(timeType$ | async) === paramsTimeTypeEnum.ALL_TIME"
                         (click)="setCurrent()">
                        <span></span>
                    </div>
                    <p class="filters__radio-title"
                       [class.filters__radio-title--active]="paramsTimeTypeEnum.ALL_TIME"
                       [translate]="'filters.current'"></p>
                </div>

                <div class="filters__item filters__item--type-events"
                     *ngIf="!(config$ | async).disableFunctions || !(config$ | async).disableFunctions.includes(disabledFilterOptionsEnum.allTime) || type === filterItemTypeEnum.event">
                    <div class="filters__radio-label"
                         [class.filters__radio-label--choose]="(timeType$ | async) === paramsTimeTypeEnum.ALL_TIME"
                         (click)="setBeginingFromNow()">
                        <span></span>
                    </div>
                    <p class="filters__radio-title"
                       [class.filters__radio-title--active]="(timeType$ | async) === paramsTimeTypeEnum.ALL_TIME"
                       [translate]="'filters.allTime'"></p>
                </div>

                <div
                    *ngIf="!(config$ | async).disableFunctions || !(config$ | async).disableFunctions.includes(disabledFilterOptionsEnum.time)"
                    class="filters__item filters__item--type-chart">
                    <div class="filters__radio-label"
                         [class.filters__radio-label--choose]="(timeType$ | async) === paramsTimeTypeEnum.TIME"
                         (click)="setLastTime()">
                        <span></span>
                    </div>
                    <app-custom-select
                        class="last-time"
                        [class.last-time--disabled]="(timeType$ | async) !== paramsTimeTypeEnum.TIME"
                        [isDisable]="(timeType$ | async) !== paramsTimeTypeEnum.TIME"
                        [isReset]="false"
                        [isRepeat]="false"
                        [value]="currentTimeOption$ | async"
                        [currentOption]="currentTimeOption$ | async"
                        [options]="timeOptions$ | async"
                        (emmitNewValue)="changeLastTimeSelect($event);"></app-custom-select>
                </div>

                <div
                    *ngIf="!(config$ | async).disableFunctions || !(config$ | async).disableFunctions.includes(disabledFilterOptionsEnum.range)"
                    class="filters__item">
                    <div class="filters__radio-label"
                         [class.filters__radio-label--choose]="(timeType$ | async) === paramsTimeTypeEnum.RANGE"
                         (click)="setDefaultRange();
                                isApply = true;">
                        <span></span>
                    </div>
                    <div class="filters__datepicker datepicker">
                        <p-calendar [(ngModel)]="dateFrom"
                                    [showTime]="true"
                                    [dateFormat]="dateFormat"
                                    [hourFormat]="hourFormat === userProfileInfoTypeDateEnum.ddMMyyyy ? '24' : '12'"
                                    class="datepicker__wrapper"
                                    [class.datepicker__wrapper--active]="(timeType$ | async) === paramsTimeTypeEnum.RANGE"
                                    [class.datepicker__wrapper--choosen]="dateFrom"
                                    [class.datepicker__wrapper--disabled]="(timeType$ | async) !== paramsTimeTypeEnum.RANGE"
                                    placeholder="{{'filters.placeholderFrom' | translate}}"
                                    (click)="dateObject.from = dateFrom.getTime()"
                                    (onSelect)="selectCalendar(fromToTypeCalendar.from)"
                                    (onInput)="dateObject.from = dateFrom.getTime(); selectCalendar(fromToTypeCalendar.from)"
                                    [showIcon]="true"
                                    [hideOnDateTimeSelect]="false"
                                    [disabled]="(timeType$ | async) !== paramsTimeTypeEnum.RANGE"
                                    [maxDate]="dateNow">

                            <ng-template pTemplate="dateFrom" let-date>

                                <span class="datepicker__day"
                                      [appDatepickerDisabledDate]="{dateTo: dateTo, dateFrom: dateFrom, currentCalendarDate: date, fromToTypeCalendar: fromToTypeCalendar.from}"
                                      [class.datepicker__day--disabled]="+date > dateNow.getDate()">


                                        {{date.day}}</span>
                            </ng-template>
                        </p-calendar>
                    </div>

                    <div class="filters__datepicker datepicker">
                        <p-calendar [(ngModel)]="dateTo"
                                    [showTime]="true"
                                    [dateFormat]="dateFormat"
                                    [hourFormat]="hourFormat === userProfileInfoTypeDateEnum.ddMMyyyy ? '24' : '12'"
                                    class="datapicker__wrapper"
                                    [class.datepicker__wrapper--active]="(timeType$ | async) === paramsTimeTypeEnum.RANGE && dateFrom"
                                    [class.datepicker__wrapper--choosen]="dateTo"
                                    [class.datepicker__wrapper--disabled]="(timeType$ | async) !== paramsTimeTypeEnum.RANGE || !dateFrom"
                                    placeholder="{{'filters.placeholderTo' | translate}}"
                                    (onSelect)="dateObject.to = dateTo.getTime(); selectCalendar(fromToTypeCalendar.to)"
                                    (onInput)="dateObject.to = dateTo.getTime(); selectCalendar(fromToTypeCalendar.to)"
                                    [showIcon]="true"
                                    [defaultDate]="dateNow"
                                    [hideOnDateTimeSelect]="false"
                                    [minDate]="minDateTo"
                                    [maxDate]="maxDateTo || dateNow"
                                    [disabled]="(timeType$ | async) !== paramsTimeTypeEnum.RANGE && !dateFrom">

                            <ng-template pTemplate="dateTo" let-date>

                                <span class="datepicker__day"
                                      [appDatepickerDisabledDate]="{dateTo: dateTo, dateFrom: dateFrom, currentCalendarDate: date, fromToTypeCalendar: fromToTypeCalendar.to}"
                                      [class.datepicker__day--disabled]="(date.day < dateFrom.getDate() && date.month <= dateFrom.getMonth())
                                        || (date.day > dateNow.getDate() && date.month === dateNow.getMonth())">{{date.day}}</span>
                            </ng-template>

                        </p-calendar>
                    </div>
                </div>
                <!-- <div class="filters__datepicker">
                    <app-datepicker [dateFrom]="dateFrom" [dateTo]="dateTo"></app-datepicker>
                </div> -->

                <div
                    *ngIf="!(config$ | async).disableFunctions || !(config$ | async).disableFunctions.includes(disabledFilterOptionsEnum.chartType)"
                    class="filters__select-icon">
                    <app-custom-select
                        [isDisable]="(timeType$ | async) !== paramsTimeTypeEnum.RANGE"
                        [type]="selectTypeEnum.chartGroup"
                        [isRepeat]="false"
                        [isReset]="false"
                        [width]="'180px'"
                        [isIconList]="true"
                        [side]="customSelectSideEnum.left"
                        [options]="iconOptions"
                        [currentOption]="currentIconOption"
                        (emmitNewValue)="selectChartOption($event)">
                    </app-custom-select>
                </div>

                <div
                    *ngIf="!(config$ | async).disableFunctions || !(config$ | async).disableFunctions.includes(disabledFilterOptionsEnum.range)"
                    class="filters__item">
                    <button class="filters__btn filters__btn--apply btn"
                            [disabled]="!isApply || (timeType$ | async) !== paramsTimeTypeEnum.RANGE || (!dateObject.from || !dateObject.to)"
                            (click)="applyFilters()" [translate]="'btns.apply'"></button>

                </div>

                <ng-container *ngIf="printPdfBtn?.value">
                    <div (click)="printPdf()"
                         class="filters__item filters__item--reset">
                        <svg-icon [src]="PRINT_PDF_ICON_PATH"></svg-icon>
                    </div>
                </ng-container>

                <ng-container *ngIf="csvPrintBtn">
                    <div class="filters__item">
                        <svg-icon (click)="exportCsv()" [src]="EXPORT_CSV_ICON_PATH"></svg-icon>
                    </div>
                </ng-container>

                <div
                    *ngIf="!(config$ | async).disableFunctions || !(config$ | async).disableFunctions.includes(disabledFilterOptionsEnum.resetBtn)"
                    class="filters__item"
                    [class.filters__item--reset]="!printPdfBtn.value"
                >
                    <button class="filters__btn filters__btn--reset btn" [translate]="'btns.cancel'"
                            [disabled]="!navigatorService.rangeBox"
                            (click)="resetSmth()"></button>
                </div>

                <ng-container *ngIf="isBtns">
                    <div class="filters__btns filters__btns--create">
                        <button (click)="onEditBtn()"
                                [disabled]="isBtnDisabled"
                                class="btn" [translate]="btnEditTitle ? btnEditTitle : 'btns.create'"></button>
                    </div>
                </ng-container>
            </div>

        </ng-container>


        <div *ngIf="filter || optionsDropDown" class="filters__row filters__row--check">
            <ng-container *ngIf="filter && filter.length">

                <div class="filters__row-checks">

                    <ng-container *ngFor="let item of filter; let i = index">


                        <div *ngIf="item.type === paramsFilterTypeEnum.BOOLEAN && !item.isRadio && !item.isDropdown"
                             class="filters__item checkbox">
                            <app-checkbox [value]="item.value"
                                          [title]="item.title"
                                          (changeEvent)="item.value = !item.value; emmitFilterGroup()"></app-checkbox>
                            <span *ngIf="item.isBorder" class="filters__item--border"></span>
                        </div>

                        <div *ngIf="item.type === paramsFilterTypeEnum.BOOLEAN && item.isRadio" class="filters__item">
                            <div class="radio">
                                <div class="radio__wrapper">
                                    <input type="radio" [id]="item.title"
                                           name="radio"
                                           [checked]="item.value"
                                           (change)="item.value = !item.value; emmitFilterGroup(item)">
                                    <label [for]="item.title"></label>
                                </div>
                            </div>
                            <p class="filters__checkbox-title filters__checkbox-title--active"
                               (click)="item.value = true; emmitFilterGroup(item)">{{item.title | translate}}</p>

                        </div>
                    </ng-container>


                </div>


            </ng-container>

            <ng-container *ngIf="isBtns">

                <ng-container *ngIf="isBtnPermission && isBtnPermission.registrator; else notPermission">

                    <div *appDisabledPermission="{registrator: isBtnPermission.registrator,
                            action: isBtnPermission.action,
                            permissions: isBtnPermission.permissions}"
                         class="filters__btns filters__btns--create"
                         [class.filters__btns--left]="btnsSide === btnsSideEnum.left"
                    >
                        <button (click)="onEditBtn()"
                                [disabled]="isBtnDisabled"
                                class="btn" [translate]="btnEditTitle"></button>
                        <div *ngIf="isBtnDisabled && btnDisabledTooltip" class="filters__tooltip">
                            <app-tooltip [template]="true" [side]="tooltipSideEnum.left">
                                <span>{{ btnDisabledTooltip | translate }}</span>
                            </app-tooltip>
                        </div>
                    </div>

                </ng-container>

                <ng-template #notPermission>
                    <div class="filters__btns filters__btns--create"
                         [class.filters__btns--left]="btnsSide === btnsSideEnum.left"
                    >
                        <button (click)="onEditBtn()"
                                [disabled]="isBtnDisabled"
                                class="btn" [translate]="btnEditTitle"></button>
                        <div *ngIf="isBtnDisabled && btnDisabledTooltip" class="filters__tooltip">
                            <app-tooltip [template]="true" [side]="tooltipSideEnum.left">
                                <span>{{ btnDisabledTooltip | translate }}</span>
                            </app-tooltip>
                        </div>
                    </div>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="journalEditBtn">
                <div class="filters__btns filters__btns--edit-journal">
                    <svg-icon [src]="EDIT_JOURNAL_ICON_PATH" (click)="onEditBtn()"></svg-icon>

                    <div class="filters__tooltip">
                        <app-tooltip [template]="true" [side]="tooltipSideEnum.left">
                            <span [translate]="'filters.journalTooltip'"></span>
                        </app-tooltip>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="iconOpenBtns">

                <div class="filters__items-btns">

                    <ng-container *ngFor="let item of iconsArray">
                        <div class="filters__btns filters__btns--edit-journal">
                            <svg-icon [src]="item.path" (click)="onIconBtn(item)"></svg-icon>

                            <div class="filters__tooltip">
                                <app-tooltip [template]="true" [side]="tooltipSideEnum.left">
                                    <span [translate]="item.tooltip"></span>
                                </app-tooltip>
                            </div>
                        </div>

                    </ng-container>

                </div>

            </ng-container>


            <ng-container *ngIf="optionsDropDown">
                <div class="filters__dropdown-container"
                     [class.filters__dropdown-container--margin]="isBtns || journalEditBtn">
                    <ng-container *ngIf="currentTypeFromDropdownSelect">
                        <ng-container [ngSwitch]="currentTypeFromDropdownSelect">
                            <ng-template [ngSwitchCase]="typeFilterEnum.select">
                                <div class="filters__dropdown-select">
                                    <app-custom-select
                                        [isRepeat]="true"
                                        [isReset]="false"
                                        [isSearch]="isHighlightRegistrators ?? true"
                                        [type]="selectTypeEnum.report"
                                        [value]="currentValueForDropDownFilter || 'filters.customFilter.defaultValue'"
                                        [options]="optionsFoDropDownSelect"
                                        [isHighlightRegistrators]="isHighlightRegistrators"
                                        (emmitNewValue)="onChangeDropDownValue($event)"
                                        (emmitReset)="resetDropDownFilter()"></app-custom-select>
                                </div>
                            </ng-template>
                            <ng-template [ngSwitchCase]="typeFilterEnum.text">
                                <input
                                    class="filters__input input"
                                    [placeholder]="'filters.search' | translate"
                                    (input)="onChangeDropDownValue($event.target.value)" type="text"
                                    [(ngModel)]="selectedDropDown.filterValue">
                            </ng-template>
                            <ng-template [ngSwitchCase]="typeFilterEnum.datetime">
                                <div class="filters__datepicker datepicker">
                                    <p-calendar
                                        [showTime]="true"
                                        [class.datepicker__wrapper--active]="dateFilterDropDown.from"
                                        [class.datepicker__wrapper--choosen]="dateFrom"
                                        [dateFormat]="dateFormat"
                                        [hourFormat]="hourFormat === userProfileInfoTypeDateEnum.ddMMyyyy ? '24' : '12'"
                                        [(ngModel)]="dateFilterDropDown.from"
                                        class="datapicker__wrapper"
                                        placeholder="{{'filters.placeholderFrom' | translate}}"
                                        (onSelect)="onChangeDropDownValue($event, fromToTypeCalendar.from)"
                                        [showIcon]="true"
                                        [maxDate]="dateFilterDropDown.to || dateNow"

                                    >

                                        <ng-template pTemplate="dateFrom" let-date>

                                <span class="datepicker__day"
                                      [appDatepickerDisabledDate]="{dateTo: dateTo, dateFrom: dateFrom, currentCalendarDate: date, fromToTypeCalendar: fromToTypeCalendar.from}"
                                      [class.datepicker__day--disabled]="+date > dateNow.getDate()">


                                        {{date.day}}</span>
                                        </ng-template>

                                    </p-calendar>
                                </div>
                                <div class="filters__datepicker datepicker">
                                    <p-calendar
                                        [showTime]="true"
                                        [class.datepicker__wrapper--active]="dateFilterDropDown.to"
                                        [class.datepicker__wrapper--choosen]="dateFrom"
                                        [dateFormat]="dateFormat"
                                        [hourFormat]="hourFormat === userProfileInfoTypeDateEnum.ddMMyyyy ? '24' : '12'"
                                        [(ngModel)]="dateFilterDropDown.to"
                                        class="datapicker__wrapper"
                                        placeholder="{{'filters.placeholderTo' | translate}}"
                                        (onSelect)="onChangeDropDownValue($event, fromToTypeCalendar.to)"
                                        [showIcon]="true"
                                        [minDate]="dateFilterDropDown.from"
                                        [maxDate]="dateNow"
                                        selectOtherMonths="true"
                                    >

                                        <ng-template pTemplate="dateFrom" let-date>

                                <span class="datepicker__day"
                                      [appDatepickerDisabledDate]="{dateTo: dateTo, dateFrom: dateFrom, currentCalendarDate: date, fromToTypeCalendar: fromToTypeCalendar.from}"
                                      [class.datepicker__day--disabled]="+date > dateNow.getDate()">


                                        {{date.day}}</span>
                                        </ng-template>

                                    </p-calendar>
                                </div>
                            </ng-template>
                        </ng-container>

                    </ng-container>

                    <app-custom-select class="filters__main-select"
                                       [style.minWidth]="'180px'"
                                       [options]="optionsDropDown"
                                       [isRepeat]="true"
                                       [value]="defaultDropdownValue ? defaultDropdownValue : 'filters.customFilter.defaultValue'"
                                       (emmitNewValue)="selectOptionDropDown($event)"
                                       (emmitReset)="resetDropDownFilter()"></app-custom-select>
                </div>
            </ng-container>
        </div>


    </div>
</div>

<app-popup *ngIf="isShowErrorPopup"
           [title]="'filters.popupTitle'"
           [type]="popupTypeEnum.error"
           (closeEmit)="isShowErrorPopup = false"
>

    <div class="error-popup">
        <span [translate]="'filters.errorTime'"></span>

        <div class="error-popup__btns">
            <button (click)="isShowErrorPopup = false"
                    class="btn btn--light"
                    [translate]="'btns.close'"></button>
        </div>
    </div>

</app-popup>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {DeviceDashboardComponent} from './device-dashboard.component';
import {UserAuthGuardService} from '../auth/user-auth-guard.service';

const userRoutes: Routes = [
    {
        path: '',
        data: { title: 'User Devices' },
        component: DeviceDashboardComponent,
        canActivate: [UserAuthGuardService],
        children: [
            // {
            //     path: '',
            //     data: { title: 'User Profile' },
            //     component: UserProfileComponent,
            //     canActivate: [UserAuthGuardService],
            // },
            // {
            //     path: 'user-devices',
            //     data: {title: 'User Devices'},
            //     component: UserDevicesComponent,
            //     canActivate: [UserAuthGuardService],
            // }
        ],
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(userRoutes),
    ],
    declarations: [],
})
export class DeviceDashboardRoutingModule {}

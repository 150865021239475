import { Injectable } from '@angular/core';
import { Device } from 'src/app/app-shared-elements/_interfaces/Device';
import { Variable } from 'src/app/app-shared-elements/_interfaces/Variable';
import { LogicEvent } from '../logical-events/_interface/LogicEvent';
import { ConditionType } from '../_enums/condition-type.enum';
import { ValueTagExpressionInterface } from '../_interfaces/Expression';

@Injectable({
    providedIn: 'root',
})
export class ParseMessageService {
    constructor() {}

    performMessage(logicEvent: LogicEvent, devices: Device[], eventMessage: string, setValue: boolean = true): string {
        const currentTags: Variable[] = this.getValuesFromExpression<Variable>(logicEvent, devices, ConditionType.Tag);
        const currentConstant: string[] = this.getValuesFromExpression<string>(logicEvent, devices, ConditionType.Constant);
        const registrator: Device = devices.find((device) => device.id === logicEvent.registratorId);
        if (!registrator) {
            return '';
        }
        currentTags.forEach((tag, index) => {
            if (!tag) {
                return;
            }
            const num = index + 1;
            const tagValue = tag.currentValue;
            const tagName = tag.customName || tag.name;
            const unitName = tag.unitName;
            eventMessage = eventMessage.replace(`{{tagIndex${num}}}`, tagName || tag.name);
            if (setValue) {
                eventMessage = eventMessage.replace(`{{tagValue${num}}}`, unitName ? tagValue + unitName : tagValue);
            }
        });
        currentConstant.forEach((constantValue, index) => {
            const num = index + 1;
            eventMessage = eventMessage.replace(`{{constantValue${num}}}`, constantValue);
        });
        eventMessage = eventMessage.replace(`{{registrator}}`, registrator.name);
        return eventMessage;
    }

    private getValuesFromExpression<T extends Variable | string>(
        event,
        devices: Device[],
        type: ConditionType.Tag | ConditionType.Constant,
    ): T[] {
        const expression = event.expression;
        const currentValues: T[] = [];
        for (let i = 0; i < expression.conditions.length; i++) {
            const condition = expression.conditions[i];
            currentValues.push(...this.getValueFromCondition<T>(condition, devices, type));
        }
        return currentValues;
    }

    private getValueFromCondition<T extends Variable | string>(
        condition,
        devices: Device[],
        type: ConditionType.Tag | ConditionType.Constant,
    ): T[] {
        const currentValues: T[] = [];
        if (condition.type === type) {
            switch (type) {
                case ConditionType.Tag:
                    const conditionValue: ValueTagExpressionInterface = condition.value as ValueTagExpressionInterface;
                    const tag: Variable = this.getVariable(devices, conditionValue.deviceInternalId, conditionValue.variableInternalId);
                    currentValues.push(tag as T);
                    break;
                case ConditionType.Constant:
                    currentValues.push(condition.value);
                    break;
            }
        } else {
            if (condition.value) {
                for (let i = 0; i < condition.value.length; i++) {
                    currentValues.push(...this.getValueFromCondition<T>(condition.value[i], devices, type));
                }
            }
        }
        return currentValues;
    }

    private getVariable(devices: Device[], internalDeviceId: string, internalVariableId: string): Variable {
        const device = devices.find((d) => d.internalId === internalDeviceId);
        if (!device) {
            return null;
        }
        return device.variables.find((variable) => variable.internalId === internalVariableId);
    }
}

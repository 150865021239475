import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ClearParams, GetFinanceDebtors, SetFinanceDebtorsParams } from '../actions/finance-debtors.actions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pagination, Params } from '../../../../app-shared-elements/_interfaces/params.interface';
import { ApiResponse } from '../../../../app-shared-elements/_interfaces/ApiRequest';
import { HTTP_STATUS } from '../../../../app-shared-elements/_enums/status.enum';
import { DropdownFilterOptionInterface } from '../../../../app-shared-elements/filters/interfaces/filter-option.interface';
import { TypeFilterEnum } from '../../../../app-shared-elements/filters/enums/type-filter.enum';
import { financeDebtorsDropdown } from '../../_data/finance-debtors-data';
import { ParamsService } from '../../../../app-shared-elements/_services/params.service';
import { FinanceDebtorsInterface } from '../../_interfaces/finance-debtors.interface';
import { FinanceDebtorsService } from '../../_services/finance-debtors.service';
import { BillingInvoiceService } from '../../../../billing/_services/billing-invoice.service';
import { CurrencyState } from '../../../../app-shared-elements/_store/states/currency.state';

export interface FinanceDebtorsStateModel {
    debtors: FinanceDebtorsInterface[];
    params: Params;
    dropDownFilterOptions: DropdownFilterOptionInterface[];
}

const FINANCE_DEBTORS_STATE = new StateToken<FinanceDebtorsStateModel>('financeDebtors');

const initialPagination: Pagination = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: null,
};

export const initialParams: Params = {
    filter: financeDebtorsDropdown,
    sorted: [],
    pagination: initialPagination,
};

const dropDownFilterOptions: DropdownFilterOptionInterface[] = [
    {
        key: 'accountNumber',
        value: 'adminFinances.debtors.dropDown.invoice',
        type: TypeFilterEnum.text,
        property: 'accountNumber',
    },
    { key: 'name', value: 'adminFinances.debtors.dropDown.debtors', type: TypeFilterEnum.text, property: 'name' },
];

@State<FinanceDebtorsStateModel>({
    name: FINANCE_DEBTORS_STATE,
    defaults: {
        debtors: [],
        params: initialParams,
        dropDownFilterOptions,
    },
})
@Injectable()
export class FinanceDebtorsState {
    constructor(
        private http: HttpClient,
        private paramsService: ParamsService,
        private financeDebtorsService: FinanceDebtorsService,
        private billingInvoiceService: BillingInvoiceService,
        private store: Store,
    ) {}

    @Selector()
    static getFinanceDebtors(state: FinanceDebtorsStateModel): FinanceDebtorsInterface[] {
        return state.debtors;
    }

    @Selector()
    static getParams(state: FinanceDebtorsStateModel): Params {
        return JSON.parse(JSON.stringify(state.params));
    }

    @Selector()
    static getDropDownFilterOptions(state: FinanceDebtorsStateModel): DropdownFilterOptionInterface[] {
        return state.dropDownFilterOptions;
    }

    @Action(GetFinanceDebtors)
    async getFinanceDebtors(ctx: StateContext<FinanceDebtorsStateModel>): Promise<void> {
        const state = ctx.getState();

        const params: Params = {
            filter: this.paramsService.parseParamsFilterForServer(state.params.filter),
            pagination: {
                itemsPerPage: state.params.pagination.itemsPerPage,
                currentPage: state.params.pagination.currentPage,
            },
            sorted: [],
        };

        const headers = new HttpHeaders({
            params: encodeURIComponent(JSON.stringify(params)),
        });

        const result: ApiResponse = (await this.http
            .get('/api/control/personal-account/debitors', { headers })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            const currency = this.store.selectSnapshot(CurrencyState.getCurrency);

            ctx.setState({
                ...state,
                debtors: result.data.items.map((item) => {
                    return {
                        ...item,
                        balance: item.balance + currency,
                        debt: item.debt + currency,
                        status: this.financeDebtorsService.getDebtorsStatus(item),
                        rowStyles: this.billingInvoiceService.getInvoiceRowStyle(item),
                        styles: {
                            status: this.billingInvoiceService.getInvoiceStatusColor(item),
                        },
                    };
                }),
                params: {
                    ...state.params,
                    pagination: {
                        ...state.params.pagination,
                        totalItems: result.data.total,
                    },
                },
            });
        }
    }

    @Action(SetFinanceDebtorsParams)
    setFinanceDebtorsParams(ctx: StateContext<FinanceDebtorsStateModel>, payload: SetFinanceDebtorsParams): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: { ...payload.params },
        });

        ctx.dispatch(new GetFinanceDebtors());
    }

    @Action(ClearParams)
    clear(ctx: StateContext<FinanceDebtorsStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            params: initialParams,
        });
    }
}

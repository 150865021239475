import {ReportSettingsInterface} from '../../_interfaces/ReportSettings';
import {Params} from 'src/app/app-shared-elements/_interfaces/params.interface';
import {Device} from 'src/app/app-shared-elements/_interfaces/Device';
import {RegistratorTaskTypeEnum} from 'src/app/app-shared-elements/_enums/registrator-task-type.enum';

export class GetReports {
    static readonly type = '[Reports] GetReports';
}

export class InitRowsReports {
    static readonly type = '[Reports] InitRowsReports';
}

export class SetCurrentReport {
    static readonly type = '[Reports] SetCurrentReport';

    constructor(public report: ReportSettingsInterface) {
    }
}

export class CreateReport {
    static readonly type = '[Reports] CreateReport';

    constructor(public report: ReportSettingsInterface) {
    }
}

export class GetReportById {
    static readonly type = '[Reports] GetReportById';

    constructor(public id: string) {
    }


}

export class UpdateReport {
    static readonly type = '[Reports] UpdateReport';

    constructor(public report: ReportSettingsInterface) {
    }
}

export class GetReportsLogs {
    static readonly type = '[Reports] GetReportsLogs';

    constructor() {
    }
}

export class InitReportsLogsRows {
    static readonly type = '[Reports] InitReportsLogsRows';

    constructor() {
    }
}


export class ChangeActivityReport {
    static readonly type = '[Reports] ChangeActivityReport';

    constructor(public reportId: string, public isActive: boolean) {

    }
}

export class DeleteReport {
    static readonly type = '[Reports] DeleteReport';

    constructor(public reportId: string) {
    }

}

export class TogglePopupLoad {
    static readonly type = '[Reports] TogglePopupLoad';

    constructor(public value: boolean) {
    }
}

export class SetMessageInLoadPopup {
    static readonly type = '[Reports] SetMessageInLoadPopup';

    constructor(public message: string) {
    }
}

export class SetReportPdf {
    static readonly type = '[Reports] SetReportPdf';

    constructor(public base64: string) {
    }
}

export class ClearStateReports {
    static readonly type = '[Reports] ClearStateReports';
}

export class SetReportsLogsParams {
    static readonly type = '[Reports] SetReportsLogsParams';

    constructor(public params: Params) {

    }
}

export class SetReportsListParams {
    static readonly type = '[Reports] SetReportsListParams';

    constructor(public params: Params) {

    }
}

export class ChangeCurrentReportSettings {
    static readonly type = '[Reports] ChangeCurrentReportSettings';

    constructor(public reportSettings: ReportSettingsInterface) {
    }
}

export class InitVariablesList {
    static readonly type = '[Reports] InitVariablesOptions';

    constructor(public device: Device) {

    }
}

export class SetReportModeConstructor {
    static readonly type = '[Reports] SetReportModeConstructor';

    constructor(public mode: RegistratorTaskTypeEnum) {

    }
}

export class CancelReports {
    static readonly type = '[Reports] CancelReports';
}

export class DownloadReport {
    static readonly type = '[Reports] DownloadReport';
}

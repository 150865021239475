import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { TypeClient } from '../_enum/type-client.enum';
import { TokenPayloadInterface } from '../../../auth/_interfaces/token-payload.interface';
import { ColumnsTableInterface, ColumnTypeEnum, IconInterface } from 'src/app/app-shared-elements/_interfaces/ColumnsTable';
import { Store } from '@ngxs/store';
import { AuthState } from '../../../auth/_store/states/auth.state';
import { User } from 'src/app/app-shared-elements/_interfaces/user.interface';
import { AcknPropertyInterface } from 'src/app/app-shared-elements/_interfaces/acknowldge-property.inteface';
import { ColumnsIconDataEnum } from 'src/app/app-shared-elements/tables/_interfaces/columns-icon-data.enum';
import { ColumnsActionTypeEnum } from 'src/app/app-shared-elements/_enums/columns-action-type.enum';
import { UsersRowInterface } from '../_interfaces/users-row.interface';
import { Params, ParamsFilterForClient } from 'src/app/app-shared-elements/_interfaces/params.interface';
import { StatusTwoFactorAuthenticationEnum } from 'src/app/app-shared-elements/_enums/status-two-factor-authentication.enum';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { CompanyInterface } from '../../company/_interfaces/company.interface';
import { RegistrationTypeEnum } from '../../../app-shared-elements/_enums/user.enum';
import { DisabledTypeEnum } from '../../../app-shared-elements/_enums/disabled-type.enum';
import { SetTypeClient } from '../../../app-shared-elements/_store/actions/user.actions';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    public user: User;

    sessionTableColumns: ColumnsTableInterface[] = [
        {
            title: 'table.userProfile.date',
            grow: false,
            small: false,
            maxWidth: '260px',
            minWidth: '200px',
            type: ColumnTypeEnum.date,
            name: 'created',
        },
        {
            title: 'table.userProfile.info',
            grow: true,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'userAgent',
        },
        {
            title: 'table.userProfile.ip',
            grow: false,
            small: false,
            type: ColumnTypeEnum.text,
            name: 'ip',
        },
        {
            title: 'devices.actions',
            grow: false,
            small: true,
            maxWidth: '160px',
            minWidth: '160px',
            type: ColumnTypeEnum.action,
            name: 'edit',
            actionBtns: [ColumnsActionTypeEnum.actionBtnsDelete],
        },
    ];
    public typeClient: TypeClient;

    constructor(
        private httpClient: HttpClient,
        private store: Store,
    ) {
        this.typeClient = this.getTypeClient();
    }

    getUserFromToken(): TokenPayloadInterface {
        const token = this.store.selectSnapshot(AuthState.getUserToken) ?? localStorage.getItem('access_token');
        if (!token) {
            return null;
        }
        return JSON.parse(atob(token.split('.')[1]));
    }

    getTypeClient(): TypeClient {
        const tokenPayload: TokenPayloadInterface = this.getUserFromToken();
        if (!tokenPayload) {
            return null;
        }

        this.store.dispatch(new SetTypeClient(tokenPayload.typeClient));
        this.typeClient = tokenPayload.typeClient;
        return tokenPayload.typeClient;
    }

    async getCurrentUser(): Promise<User> {
        const result: ApiResponse = (await this.httpClient.get('/api/users').toPromise()) as ApiResponse;
        if (result && result.status === HTTP_STATUS.SUCCESS) {
            return result.data;
        } else {
            console.error('error get user');
            return;
        }
    }

    initTable(users: User[], companies: CompanyInterface[]): UsersRowInterface[] {
        const result: UsersRowInterface[] = [];

        users.forEach((u) => {
            const currentCompany = companies.find((f) => f.managerId === u.id);
            result.push({
                ...u,
                userName: u.name,
                lastActive: this.getLastActiveForAdminUsersTable(u),
                activeStatus: this.getActiveStatusForAdminUsersTable(u),
                disabledActions: this.getDisabledBtns(u),
                isActive: u.isDeleted ? false : u.isActive,
                status: u.isOnline ? 'online' : 'offline',
                isReadOnly: u.isConfirmEmail,
                columnType: currentCompany ? ColumnTypeEnum.text : ColumnTypeEnum.select,
                company: 'companyStaff.companyOwner',
                styles: {
                    status: this.getStatusStyle(u),
                    login: this.getEmailStyle(u),
                },
                require: {
                    login: false,
                    userName: false,
                },
                preIcons: this.getPreIcons(u),
                companyOptions: this.getCompanyOptions(companies),
                companyName: this.getCompanyName(u, companies),
                postIcons: this.getPostIcons(u),
                tooltipValue: {
                    login: u.type === RegistrationTypeEnum.ADMIN ? 'table.users.adminTooltip' : null,
                },
                disabledType: DisabledTypeEnum.usersTable,
            });
        });

        return result;
    }

    public getPostIcons(u: User): IconInterface<void>[] {
        if (u.isDisableWebAuth) {
            return [
                {
                    path: './assets/design/icons/users/web-auth.svg',
                    tooltip: 'admin.users.webAuth',
                    cellNames: ['login'],
                },
            ];
        }

        return null;
    }

    private getCompanyName(user: User, companies: CompanyInterface[]): string {
        const currentCompany = companies.find((c) => c.id === user.companyId);
        return currentCompany ? currentCompany.name : '';
    }

    getDisabledBtns(user: User): ColumnsActionTypeEnum[] {
        const result: ColumnsActionTypeEnum[] = [];

        if (user.isConfirmEmail && user.lastActive) {
            result.push(ColumnsActionTypeEnum.actionBtnsEdit);
        }

        if (user.isDeleted) {
            result.push(ColumnsActionTypeEnum.actionBtnsDelete);
        }

        if (!user.isDeleted) {
            result.push(ColumnsActionTypeEnum.recovery);
        }

        return result;
    }

    getCompanyOptions(companies: CompanyInterface[]): SelectOptionInterface<
        string,
        string,
        {
            cellName: string;
            data: CompanyInterface;
        }
    >[] {
        const options: SelectOptionInterface<string, string, { cellName: string; data: CompanyInterface }>[] = [];

        companies.map((c) => {
            options.push({
                key: c.id,
                property: {
                    cellName: 'companyName',
                    data: c,
                },
                value: c.name,
            });
        });

        const placeholder = {
            key: null,
            value: 'company.chooseCompany',
            type: 'text',
            property: { cellName: 'companyName', data: null },
        };

        return [placeholder, ...options];
    }

    private getPreIcons(u: User): IconInterface<void>[] {
        if (u.isDeleted) {
            return [];
        }

        return [
            {
                path: u.isConfirmEmail ? './assets/design/icons/users/confirmation-ok.svg' : './assets/design/icons/users/confirmation.svg',
                tooltip: u.isConfirmEmail ? 'admin.users.confirmEmail' : 'admin.users.unConfirmEmail',
                cellNames: ['login'],
            },
            {
                path: u.statusTwoFactorAuthentication === StatusTwoFactorAuthenticationEnum.ENABLED ? './assets/design/icons/users/two-factor-ok.svg' : './assets/design/icons/users/two-factor.svg',
                tooltip: 'admin.users.twoFA',
                cellNames: ['login'],
            },
        ];
    }

    public getEmailStyle(u: User): { color: string } {
        if (!u.isActive && u.type === RegistrationTypeEnum.ADMIN) {
            return {
                color: '#5048E4A3',
            };
        }

        return {
            color: u.type === RegistrationTypeEnum.ADMIN ? '#5048E4' : 'var(--baseFontColor)',
        };
    }

    public getStatusStyle(user: User): { fontWeight: number; color: string } {
        switch (user.isOnline) {
            case true:
                return {
                    fontWeight: 600,
                    color: 'var(--successColor)',
                };
            case false:
                return {
                    fontWeight: 600,
                    color: 'var(--eventLogAlarmColor)',
                };
        }
    }

    private getLastActiveForAdminUsersTable(u: User): AcknPropertyInterface {
        return {
            ts: u.isConfirmByAdmin ? u.lastActive?.toString() ?? u.updated.toString() : null,
            isAcknowledgeable: true,
            acknowledgedByUser: '',
            title: 'btns.accept',
        };
    }

    public getActiveStatusForAdminUsersTable(u: User): ColumnsIconDataEnum {
        if (u.isDeleted) {
            return ColumnsIconDataEnum.deleted;
        }

        return u.isActive ? ColumnsIconDataEnum.active : ColumnsIconDataEnum.inActive;
    }

    filterRowsByParams(rows: UsersRowInterface[], params: Params): UsersRowInterface[] {
        const checkBoxes = params.filter.filter((f) => f.value && !f.isDropdown);
        const dropDownFilter = params.filter.find((f) => f.isDropdown && f.value);
        if ((!checkBoxes || !checkBoxes.length) && !dropDownFilter) {
            return rows;
        }

        let currentFlatFilters: ParamsFilterForClient[] = [];
        checkBoxes.forEach((filter) => {
            if (!filter.relationTrue) {
                return;
            }

            currentFlatFilters = [...currentFlatFilters, ...filter.relationTrue];
        });

        if (!currentFlatFilters || !currentFlatFilters.length) {
            return rows.filter((r) => {
                if (!dropDownFilter) {
                    return r;
                }
                if ((r[dropDownFilter.property] as string).toLowerCase().includes((dropDownFilter.value as string).toLowerCase())) {
                    return r;
                }
            });
        }

        return rows
            .filter((r) => {
                if (currentFlatFilters.find((f) => r[f.property] === f.value)) {
                    return r;
                }
            })
            .filter((r) => {
                if (!dropDownFilter) {
                    return r;
                }
                if ((r[dropDownFilter.property] as string).toLowerCase().includes((dropDownFilter.value as string).toLowerCase())) {
                    return r;
                }
            });
    }
}
